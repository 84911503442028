import {
  FILTER_KEY_APPLICATION,
  FILTER_KEY_ASSET,
  FILTER_KEY_CAMPAIGN,
  FILTER_KEY_BID_TYPE,
  FILTER_KEY_CAMPAIGN_STATUS,
  FILTER_KEY_COUNTRY,
  FILTER_KEY_CREATIVE,
  FILTER_KEY_CREATIVE_LIST_FORMAT,
  FILTER_KEY_LANGUAGE,
  FILTER_KEY_PLATFORM,
  FILTER_KEY_PUBLISHER,
  FILTER_KEY_STATUS,
} from 'app/constants/filters';

export const REPORTS_KEY_REFRESH = 'refresh';
export const REPORTS_KEY_RESET = 'reset';

export const CHART_VIEW_BY_KEY_DAY = 'day';
export const CHART_VIEW_BY_KEY_WEEK = 'week';
export const CHART_VIEW_BY_KEY_MONTH = 'month';

export const CHART_TOTAL_TEXT = 'Total';

export const DATE_KEY = 'date';
export const COMPARE_KEY = 'compare';
export const COMBINE_KEY = 'combine';

export const REPORTS_MORE_OPTS = [
  {
    key: REPORTS_KEY_REFRESH,
    name: 'Refresh Report',
  },
  {
    key: REPORTS_KEY_RESET,
    name: 'Reset Filters',
  },
];

export const CHART_VIEW_BY_OPTS = [
  {
    key: CHART_VIEW_BY_KEY_DAY,
    name: 'Day',
  },
  {
    key: CHART_VIEW_BY_KEY_WEEK,
    name: 'Week',
  },
  {
    key: CHART_VIEW_BY_KEY_MONTH,
    name: 'Month',
  },
];

export const GRAPHQL_QUERIES = {
  [FILTER_KEY_APPLICATION]: {
    getKey: 'resource',
    detailQuery: 'applications',
    filterQuery: 'filter_applications',
    comparisonQuery: 'comparison_applications',
  },
  [FILTER_KEY_CAMPAIGN]: {
    getKey: 'resource',
    detailQuery: 'campaigns',
    filterQuery: 'filter_campaigns',
    comparisonQuery: 'comparison_campaigns',
  },
  [FILTER_KEY_COUNTRY]: {
    getKey: 'country',
    detailQuery: 'countries',
    filterQuery: 'filter_countries',
    comparisonQuery: 'comparison_countries',
  },
  [FILTER_KEY_CREATIVE]: {
    getKey: 'resource',
    detailQuery: 'creatives',
    filterQuery: 'filter_creatives',
    comparisonQuery: 'comparison_creatives',
  },
  [FILTER_KEY_PLATFORM]: {
    getKey: 'platform',
    detailQuery: 'platforms',
    filterQuery: 'filter_platforms',
    comparisonQuery: 'comparison_platforms',
  },
  [FILTER_KEY_PUBLISHER]: {
    getKey: 'resource',
    detailQuery: 'publishers',
    filterQuery: 'filter_publishers',
    comparisonQuery: 'comparison_publishers',
  },
  [FILTER_KEY_STATUS]: {
    getKey: 'status',
    detailQuery: 'status',
    filterQuery: 'status',
  },
  [FILTER_KEY_CAMPAIGN_STATUS]: {
    getKey: 'status',
    detailQuery: 'status',
    filterQuery: 'status',
  },
  [FILTER_KEY_LANGUAGE]: {
    getKey: 'language',
    detailQuery: 'languages',
    filterQuery: 'filter_languages',
    comparisonQuery: 'comparison_languages',
  },
  [FILTER_KEY_CREATIVE_LIST_FORMAT]: {
    getKey: 'creativeType',
    detailQuery: 'creativeTypes',
    filterQuery: 'filter_creative_types',
    comparisonQuery: 'comparison_creative_types',
  },
  [FILTER_KEY_ASSET]: {
    getKey: 'resource',
    detailQuery: 'assets',
    filterQuery: 'filter_assets',
    comparisonQuery: 'comparison_assets',
  },
  [FILTER_KEY_BID_TYPE]: {
    getKey: 'campaignType',
    detailQuery: 'campaignTypes',
    filterQuery: 'campaignTypes',
  },
};

export const REPORTING_INCOMPLETE_BODY = 'Time zone is based on UTC. Your reports for today may be incomplete before 23:59:59 UTC.';

export const COMPARISON_LIMIT_BODY = 'Select up to (5) items for comparison.';
