import Cache from './Cache';
import {
  CTRL_CAMPAIGN_PAGE_SIZE_KEY,
  USER_PAGE_SIZE_KEY,
  CREATIVE_QA_PAGE_SIZE_KEY,
  REPORT_PAGE_SIZE_KEY,
  ASSET_PAGE_SIZE_KEY,
  AC_DEPLOYMENT_PAGE_SIZE_KEY,
  GROW_CAMPAIGN_PAGE_SIZE_KEY,
  GROW_APPLICATION_PAGE_SIZE_KEY,
  GROW_CREATIVE_PAGE_SIZE_KEY,
  CTRL_APPLICATION_PAGE_SIZE_KEY,
  CTRL_CREATIVE_PAGE_SIZE_KEY,
} from './constant';

export const defaultPageSize = 10;

class PageSizeCache extends Cache {
  constructor(userId, storageKey, defaultValue) {
    super(userId, storageKey, defaultValue || defaultPageSize);
  }

  save = (pageSize) => {
    localStorage.setItem(this.storageKey, JSON.stringify(pageSize));
  }

  get = () => {
    let pageSize = this.defaultValue || defaultPageSize;
    try {
      const _pageSize = Number(localStorage.getItem(this.storageKey));
      if (Number.isInteger(_pageSize) && _pageSize > 0) {
        pageSize = _pageSize;
      }
    } catch (e) {
      // do nothing
    }
    return pageSize;
  }
}

export default PageSizeCache;

export function getGrowApplicationPageSizeCache(userId, defaultValue = 10) {
  return new PageSizeCache(userId, GROW_APPLICATION_PAGE_SIZE_KEY, defaultValue);
}

export function getCtrlApplicationPageSizeCache(userId, defaultValue = 10) {
  return new PageSizeCache(userId, CTRL_APPLICATION_PAGE_SIZE_KEY, defaultValue);
}

export function getGrowCampaignPageSizeCache(userId, defaultValue = 10) {
  return new PageSizeCache(userId, GROW_CAMPAIGN_PAGE_SIZE_KEY, defaultValue);
}

export function getCtrlCampaignPageSizeCache(userId, defaultValue = 10) {
  return new PageSizeCache(userId, CTRL_CAMPAIGN_PAGE_SIZE_KEY, defaultValue);
}

export function getGrowCreativePageSizeCache(userId, defaultValue = 10) {
  return new PageSizeCache(userId, GROW_CREATIVE_PAGE_SIZE_KEY, defaultValue);
}

export function getCtrlCreativePageSizeCache(userId, defaultValue = 10) {
  return new PageSizeCache(userId, CTRL_CREATIVE_PAGE_SIZE_KEY, defaultValue);
}

export function getUserPageSizeCache(userId) {
  return new PageSizeCache(userId, USER_PAGE_SIZE_KEY);
}

export function getCreativeQAPageSizeCache(userId) {
  return new PageSizeCache(userId, CREATIVE_QA_PAGE_SIZE_KEY);
}

export function getReportingPageSizeCache(userId) {
  return new PageSizeCache(userId, REPORT_PAGE_SIZE_KEY);
}

export function getAssetPageSizeCache(userId) {
  return new PageSizeCache(userId, ASSET_PAGE_SIZE_KEY);
}

export function getACDeploymentPageSizeCache(userId, defaultValue) {
  return new PageSizeCache(userId, AC_DEPLOYMENT_PAGE_SIZE_KEY, defaultValue);
}

export function clearAllPageSizeCache(userId) {
  getGrowApplicationPageSizeCache(userId).remove();
  getCtrlApplicationPageSizeCache(userId).remove();
  getGrowCampaignPageSizeCache(userId).remove();
  getCtrlCampaignPageSizeCache(userId).remove();
  getGrowCreativePageSizeCache(userId).remove();
  getCtrlCreativePageSizeCache(userId).remove();
  getUserPageSizeCache(userId).remove();
  getCreativeQAPageSizeCache(userId).remove();
  getReportingPageSizeCache(userId).remove();
  getAssetPageSizeCache(userId).remove();
  getACDeploymentPageSizeCache(userId).remove();
}
