import React from 'react';
import classNames from 'classnames';
import Button from '../../../../../components/Button/Button';
import Icon from '../../../../../components/Icon/Icon';
import CreativeTooltip from '../../../CreativeBuilder/CreativeTooltip/CreativeTooltip';

const AbTestingControlButtons = ({
  show,
  orientation,
  handleShow,
  handleStop,
  refresh,
  toggleFullscreen,
}) => (
  (
    <div className={classNames('DeviceFrame-toolbar', orientation)}>
      <div className="DeviceFrame-toolbar-wrapper">
        {
          !show
            ? (
              <Button
                className="DeviceFrame-toolbar-wrapper-button"
                onClick={handleShow}
              >
                <Icon.Play />
              </Button>
            )
            : (
              <Button
                className="DeviceFrame-toolbar-wrapper-button"
                onClick={handleStop}
              >
                <Icon.Stop />
              </Button>
            )
        }
        <Button
          className="DeviceFrame-toolbar-wrapper-button"
          onClick={refresh}
        >
          <Icon.Refresh />
        </Button>
      </div>
      <div className="DeviceFrame-toolbar-wrapper">
        <Button
          className="DeviceFrame-toolbar-wrapper-button"
          onClick={() => toggleFullscreen(true)}
        >
          <Icon.Fullscreen />
        </Button>
        <Button className="DeviceFrame-toolbar-wrapper-button">
          <CreativeTooltip
            title="Creative Previewer"
            body="Heads up, this previewer shows an estimate of what your&nbsp;
                  creative will look like. This might not be up to actual scale on device."
          />
        </Button>
      </div>
    </div>
  )
);

export default AbTestingControlButtons;
