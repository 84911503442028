import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ReactSortable } from 'react-sortablejs';
import { cloneDeep } from 'lodash';

import Input from '../../Input/Input';
import Checkbox from '../../Checkbox/Checkbox';
import { Tree, TreeNode } from '../../TreeSelect/TreeSelect';
import Menu, { MenuItem } from '../../Dropdown/Menu/Menu';

import { METRIC_TYPES } from '../../../../lib/cache/constant';
import MetricCache from '../../../../lib/cache/MetricCache';

import './metric.scss';

export const defaultPrefixCls = 'v2_component_table-metric';

const defaultRenderCount = (amount) => (`Sort your ${amount} selected columns`);

class Metric extends React.Component {
  constructor(props) {
    super(props);
    const { metricUserId, metricType, metricGroup } = props;
    if (!Array.isArray(metricGroup)) {
      throw Error('Must provide `metricGroup`');
    }
    this.cacheInstance = new MetricCache(metricUserId, metricType);
    this.checkableMetrics = metricGroup.reduce((prev, group) => prev.concat(group.metrics), []);
    this.state = {
      metricsTree: cloneDeep(metricGroup),
      expandedKeys: [],
      checkedMetrics: [],
      keyword: '',
    };
  }

  onTreeExpand = (expandedKeys) => {
    this.setState({ expandedKeys });
  }

  // tree change handler
  onTree = (treeMetrics) => {
    const { checkedMetrics: prevCheckedMetrics } = this.state;
    const checkedMetrics = [];

    // clear unchecked metrics from checkedMetrics
    prevCheckedMetrics.forEach((checkedMetric) => {
      if (treeMetrics.find((treeMetric) => treeMetric.dataKey === checkedMetric.key)) {
        checkedMetrics.push(checkedMetric);
      }
    });

    // add new checked metrics
    treeMetrics.forEach(({ deep, dataKey, node }) => {
      if (deep === 0) return;
      const { metric } = node.props;
      const index = checkedMetrics.findIndex((currentCheckedMetric) => currentCheckedMetric.key === dataKey);
      if (index === -1) {
        checkedMetrics.push(metric);
      }
    });

    this.setState({ checkedMetrics });
  }

  onMenu = (metric, checked) => {
    const { checkedMetrics } = this.state;
    const index = checkedMetrics.findIndex((checkedMetric) => checkedMetric.key === metric.key);
    if (checked && index === -1) {
      checkedMetrics.push(metric);
    }
    if (!checked && index !== -1) {
      checkedMetrics.splice(index, 1);
    }
    this.setState({ checkedMetrics });
  }

  // select all or not
  toggleTree = (checked) => {
    const { checkedMetrics } = this.state;
    if (checked) { // check all
      this.checkableMetrics.forEach((metric) => {
        const index = checkedMetrics.findIndex((cm) => cm.key === metric.key);
        if (index === -1) {
          checkedMetrics.push(metric);
        }
      });
      this.setState({ checkedMetrics });
    } else { // clear all
      this.setState({ checkedMetrics: [] });
    }
  };

  onSort = (sortedMetrics) => {
    this.setState({ checkedMetrics: sortedMetrics });
  };

  onDelete = (metric) => {
    // delete the sortable item means uncheck the item
    this.onMenu(metric, false);
  };

  onApply = (onMetricsChange, close) => {
    const { checkedMetrics } = this.state;
    // cache the result
    this.cacheInstance.save(checkedMetrics);
    // call table to refresh columns
    onMetricsChange(checkedMetrics);
    // hide the dropdown
    close();
  };

  onReset = () => {
    const { metricGroup, defaultKey } = this.props;
    const checkedMetrics = [];
    metricGroup.forEach(({ metrics }) => {
      metrics.forEach((metric) => {
        if (metric[defaultKey]) {
          checkedMetrics.push(metric);
        }
      });
    });
    this.setState({ checkedMetrics });
  };

  onClear = () => {
    this.setState({ checkedMetrics: [] });
  }

  onSearch = (e) => {
    this.setState({ keyword: e.target.value });
  };

  initCheckedMetrics = () => {
    const { metricGroup } = this.props;
    const checkedMetrics = this.cacheInstance.get();
    if (checkedMetrics) {
      const validCheckedMetrics = checkedMetrics.filter(({ key }) => metricGroup.some((g) => g.metrics.some((m) => m.key === key)));
      this.setState({ checkedMetrics: validCheckedMetrics });
    } else {
      this.onReset();
    }
  };

  renderTree = () => {
    const { metricsTree, expandedKeys, checkedMetrics } = this.state;
    const { prefixCls, maxSelected } = this.props;
    const treePrefixCls = `${prefixCls}-tree`;

    return (
      <Tree
        disabledTitle="Limit Reached"
        disabledContent={`You have selected ${maxSelected} metrics.`}
        className={treePrefixCls}
        checkedKeys={checkedMetrics.map((metric) => metric.key)}
        expandedKeys={expandedKeys}
        onChange={this.onTree}
        onExpand={this.onTreeExpand}
        maxSelected={maxSelected}
      >
        {
          metricsTree.map((group) => (
            <TreeNode
              className={`${treePrefixCls}-group`}
              key={group.name}
              dataKey={group.name}
              title={group.name}
            >
              {
                group.metrics.map((metric) => (
                  <TreeNode
                    key={metric.key}
                    dataKey={metric.key}
                    metric={metric}
                    title={metric.name}
                    isLeaf
                  />
                ))
              }
            </TreeNode>
          ))
        }
      </Tree>
    );
  };

  renderMenu = () => {
    const { keyword, checkedMetrics } = this.state;
    const { prefixCls, maxSelected } = this.props;
    const filteredMetrics = [];
    const filteredCheckedMetrics = [];
    const isCheckedMax = maxSelected && checkedMetrics.length >= maxSelected;
    this.checkableMetrics.forEach((checkableMetric) => {
      if (checkableMetric.name.toLowerCase().includes(keyword.toLowerCase())) {
        filteredMetrics.push(checkableMetric);
        if (checkedMetrics.find((checkedMetric) => checkedMetric.key === checkableMetric.key)) {
          filteredCheckedMetrics.push(checkableMetric.key);
        }
      }
    });

    return (
      <Menu onItemChange={this.onMenu} checkedKeys={filteredCheckedMetrics} className={`${prefixCls}-menu`}>
        {filteredMetrics.map((metric) => (
          <MenuItem
            key={metric.key}
            dataKey={metric.key}
            data={metric}
            disabled={!filteredCheckedMetrics.includes(metric.key) && isCheckedMax}
          >
            {metric.name}
          </MenuItem>
        ))}
      </Menu>
    );
  };

  renderCheckAll = () => {
    const { prefixCls } = this.props;
    const { checkedMetrics } = this.state;
    const props = {
      onChange: this.toggleTree,
      label: 'Select All',
      className: `${prefixCls}-all-checkbox`,
    };
    if (checkedMetrics.length === this.checkableMetrics.length) {
      props.checked = true;
    } else if (checkedMetrics.length === 0) {
      props.checked = false;
    } else {
      props.indeterminate = true;
    }
    return <Checkbox {...props} />;
  };

  renderSortable = (checkedMetrics) => {
    const { prefixCls } = this.props;
    const sortablePrefixCls = `${prefixCls}-sortable`;
    const sortableItemPrefixCls = `${prefixCls}-sortable-item`;

    return (
      <div className={sortablePrefixCls}>
        <ReactSortable
          forceFallback
          filter={`.${sortableItemPrefixCls}-delete`}
          dragClass={`${sortableItemPrefixCls}-dragging`}
          ghostClass={`${sortableItemPrefixCls}-ghost`}
          list={checkedMetrics}
          setList={this.onSort}
        >
          {checkedMetrics.map((metric) => (
            <div className={sortableItemPrefixCls} key={metric.key}>
              <div className={`${sortableItemPrefixCls}-inner`}>
                <i
                  className={classnames(`${sortableItemPrefixCls}-drag`, 'material-icons')}
                >
                  open_with
                </i>
                <span className={`${sortableItemPrefixCls}-title`}>{metric.name}</span>
                <i
                  className={classnames(`${sortableItemPrefixCls}-delete`, 'material-icons')}
                  onClick={() => this.onDelete(metric)}
                >
                  delete
                </i>
              </div>
            </div>
          ))}
        </ReactSortable>
      </div>
    );
  };

  componentDidMount() {
    this.initCheckedMetrics();
  }

  render() {
    const {
      prefixCls, className, renderCount, searchPlaceholder, showSelectAll,
    } = this.props;
    const { checkedMetrics, keyword } = this.state;

    return (
      <div className={classnames(prefixCls, className, !showSelectAll && `${prefixCls}-no-select-all`)}>
        <div className={`${prefixCls}-left`}>
          <div className={`${prefixCls}-head`}>
            <div className={`${prefixCls}-search`}>
              <Input.Search
                className={`${prefixCls}-search-input`}
                value={keyword}
                onChange={this.onSearch}
                placeholder={searchPlaceholder}
              />
            </div>
            {showSelectAll && (
              <div className={`${prefixCls}-all`}>
                {this.renderCheckAll()}
              </div>
            )}
          </div>
          <div className={`${prefixCls}-body`}>
            {keyword.length ? this.renderMenu() : this.renderTree()}
          </div>
        </div>
        <div className={`${prefixCls}-right`}>
          <div className={`${prefixCls}-head`}>
            <div className={`${prefixCls}-count`}>
              {renderCount(checkedMetrics.length)}
            </div>
          </div>
          <div className={`${prefixCls}-body`}>
            {this.renderSortable(checkedMetrics)}
          </div>
        </div>
      </div>
    );
  }
}

Metric.propTypes = {
  /**
   * prefixCls
   */
  prefixCls: PropTypes.string,

  /**
   * className
   */
  className: PropTypes.string,

  /**
   * user id for cache metrics
   */
  metricUserId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),

  /**
   * table used in the type page for cache
   */
  metricType: PropTypes.oneOf(METRIC_TYPES),

  /**
   * metric tree data
   */
  metricGroup: PropTypes.arrayOf(
    PropTypes.object,
  ),

  /**
   * render the count text
   */
  renderCount: PropTypes.func,

  /**
   * the search placeholder text
   */
  searchPlaceholder: PropTypes.string,

  /**
   * should render select all checkbox
   */
  showSelectAll: PropTypes.bool,

  /**
   * limit of checked metric
   */
  maxSelected: PropTypes.number,

  /**
   * default key of metric default value
   */
  defaultKey: PropTypes.string,
};

Metric.defaultProps = {
  prefixCls: defaultPrefixCls,
  className: '',
  metricUserId: 0,
  metricType: METRIC_TYPES[0],
  metricGroup: [],
  renderCount: defaultRenderCount,
  searchPlaceholder: 'Search',
  showSelectAll: true,
  maxSelected: null,
  defaultKey: 'default',
};

export default Metric;
