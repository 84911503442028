import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import MultiPanel from './MultiPanel';

const renderItemLabel = (prefixCls, { text }) => (
  <div className={`${prefixCls}-item`}>
    <span>{text}</span>
  </div>
);

const CommonMultiPickerPanel = ({
  data,
  width,
  prefixCls,
  defaultCheckedNodes,
  onVisibleChange,
  onChange,
  isSelectAllHidden,
  isLinkHidden,
  single,
}) => {
  const handleChange = useCallback((selected, isAllSelected) => {
    onVisibleChange(false);
    onChange(selected, isAllSelected);
  }, [onVisibleChange, onChange]);
  return (
    <MultiPanel
      width={width}
      style={{ maxHeight: '500px' }}
      data={data}
      single={single}
      renderItemLabel={(item) => renderItemLabel(prefixCls, item)}
      defaultCheckedKeys={defaultCheckedNodes.map(({ id }) => id)}
      onVisibleChange={onVisibleChange}
      onChange={handleChange}
      isSelectAllHidden={isSelectAllHidden}
      isLinkHidden={isLinkHidden}
    />
  );
};

CommonMultiPickerPanel.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  prefixCls: PropTypes.string,
  defaultCheckedNodes: PropTypes.arrayOf(PropTypes.any),
  onVisibleChange: PropTypes.func,
  onChange: PropTypes.func,
  isSelectAllHidden: PropTypes.bool,
  isLinkHidden: PropTypes.bool,
};

CommonMultiPickerPanel.defaultProps = {
  data: [],
  width: 270,
  prefixCls: 'v2_component_filter_common_multi_picker',
  defaultCheckedNodes: [],
  onVisibleChange: noop,
  onChange: noop,
  isSelectAllHidden: false,
  isLinkHidden: false,
};

export default React.memo(CommonMultiPickerPanel);
