import React, { F } from '../../app/app';
import './pagination.scss';

const Pagination = ({
  pages = 0, page = 1, perPage = 10, onChangePage = () => {},
}) => (
  <div className="template__pagination">
    <nav className="f fh fv">
      {page > 1 && (
        <i className="material-icons" onClick={() => onChangePage(page - 1, perPage)}>keyboard_arrow_left</i>
      )}
      {page > 1 && (
        <div className="next-page f fh fv p2" onClick={() => onChangePage(1, perPage)}><span>{1}</span></div>
      )}
      {(page - 2) > 1 && (
        <span className="elip p2">...</span>
      )}
      {(page - 1) > 1 && (
        <div className="next-page f fh fv p2" onClick={() => onChangePage(page - 1, perPage)}><span>{page - 1}</span></div>
      )}
      <div className="current-page f fh fv"><span>{page}</span></div>
      {(page + 1) < pages && (
        <div className="next-page f fh fv p2" onClick={() => onChangePage(page + 1, perPage)}><span>{page + 1}</span></div>
      )}
      {(page + 2) < pages && (
        <span className="elip p2">...</span>
      )}
      {page < pages && (
        <F>
          <div className="next-page f fh fv p2" onClick={() => onChangePage(pages, perPage)}><span>{pages}</span></div>
          <i className="material-icons" onClick={() => onChangePage(page + 1, perPage)}>keyboard_arrow_right</i>
        </F>
      )}
    </nav>
    <div className="page-select">
      <F>
        <select value={perPage} onChange={(e) => onChangePage(1, parseInt(e.target.value, 10))}>
          <option>10</option>
          <option>25</option>
          <option>50</option>
          <option>100</option>
        </select>
        <i className="material-icons">keyboard_arrow_down</i>
      </F>
    </div>
  </div>
);

export default Pagination;
