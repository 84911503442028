import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../../Icon/Icon';
import './roundPlatformIcon.scss';

const RoundPlatformIcon = ({ prefixCls, className, platform }) => (
  <div className={classnames(className, prefixCls, `${prefixCls}-${platform}`)}>
    {Icon.forPlatform(platform)}
  </div>
);

RoundPlatformIcon.propTypes = {
  prefixCls: PropTypes.string,
  className: PropTypes.string,
  /**
   * platform
   */
  platform: PropTypes.oneOf(['android', 'ios', 'windows', 'amazon']),
};

RoundPlatformIcon.defaultProps = {
  prefixCls: 'v2_component_round_platform_icon',
  className: '',
  platform: 'ios',
};

export default RoundPlatformIcon;
