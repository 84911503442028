import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Dropzone from 'react-dropzone';
import formats from '../../../Uploads/formats';
import './uploader.scss';

const noop = () => { };

const Uploader = ({
  prefixCls, className, disabled, type = 'default', onDrop, dragging,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  return (
    <div className={classnames(prefixCls, { disabled }, className)}>
      <Dropzone
        disabled={disabled}
        onDrop={onDrop}
        onDragEnter={() => setIsDragging(true)}
        onDragLeave={() => setIsDragging(false)}
      >
        {
          ({ getRootProps, getInputProps }) => (
            <div className={`instructions ${isDragging && 'dragging'}`} {...getRootProps()}>
              <input {...getInputProps()} {...formats[type]} accept=".csv" />
              <i className="material-icons">cloud_upload</i>
              <p className="p2">Drag & Drop</p>
              {!dragging && (
                <p className="p2">
                  {formats[type].description}
                  <span className="p3">or browse</span>
                </p>
              )}
            </div>
          )
        }
      </Dropzone>
    </div>
  );
};

Uploader.propTypes = {
  /*
   * class name string.
   */
  prefixCls: PropTypes.string,
  /**
   * Class name of uploader component.
   */
  className: PropTypes.string,
  /*
   * Indicate if uploader is disabled, default is `false`.
   */
  disabled: PropTypes.bool,
  /**
   * Name of uploader component.
   */
  name: PropTypes.string,
  /*
   * Accepted file type, default accept type if image/video.
  */
  type: PropTypes.string,
  /*
   * Called when user drop files.
  */
  onDrop: PropTypes.func,
  /*
   * Tell if component if in dragging status.
  */
  dragging: PropTypes.bool,
  /*
   * Errors uploading.
  */
  fileErrors: PropTypes.arrayOf(PropTypes.string),
  /*
   * Display content.
  */
  icon: PropTypes.node,
};

Uploader.defaultProps = {
  prefixCls: 'v2_component_uploader',
  className: null,
  disabled: false,
  name: 'asset-uploader',
  type: 'default',
  onDrop: noop,
  dragging: false,
  fileErrors: [],
  icon: null,
};

export default Uploader;
