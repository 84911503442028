import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '../Spinner/Spinner';
import { track } from '../../lib/tracker';
import './button.scss';

const make = (names, props) => {
  const baseClass = 'component__button';
  const classes = [baseClass];
  if (props.disabled || props.loading) {
    classes.push(`${baseClass}--disabled`);
  }

  if (props.dull || props.cancel) {
    classes.push(`${baseClass}--cancel`);
  }

  if (props.inverse || props.ghost) {
    classes.push(`${baseClass}--ghost`);
  }

  if (props.continue) {
    classes.push(`${baseClass}--continue`);
  }

  if (props.rect) {
    classes.push(`${baseClass}--rect`);
  }

  if (!props.secondary) {
    classes.push(`${baseClass}--common`);
  }

  if (props.secondary) {
    classes.push(`${baseClass}--secondary`);
  }
  if (names) {
    classes.push(names);
  }
  return classes.join(' ');
};
const Button = ({
  children, className, passedRef, onClick = () => {}, ...props
}) => {
  const onBtnClick = (e) => {
    const v = { value: children.toString() };
    if (props.disabled || props.loading) {
      track('Button Clicked - Disabled', v);
      return;
    }
    track('Button Clicked', v);
    onClick(e);
  };
  return (
    <button
      style={props.buttonStyle}
      className={make(className, props)}
      ref={passedRef}
      onClick={onBtnClick}
      type="submit"
    >
      {props.loading && <Spinner />}
      <span
        className="component__button-wrapper"
        style={props.textStyle}
      >
        {children}
      </span>
    </button>
  );
};

Button.propTypes = {
  disabled: PropTypes.bool,
  cancel: PropTypes.bool,
  dull: PropTypes.bool,
  inverse: PropTypes.bool,
  ghost: PropTypes.bool,
  continue: PropTypes.bool,
  secondary: PropTypes.bool,
  buttonStyle: PropTypes.objectOf(PropTypes.string),
  textStyle: PropTypes.objectOf(PropTypes.string),
};

Button.defaultProps = {
  disabled: false,
  cancel: false,
  dull: false,
  inverse: false,
  ghost: false,
  continue: false,
  secondary: false,
  buttonStyle: {},
  textStyle: {},
};

export default Button;
