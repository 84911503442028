import messageCode from 'config/messageCode';
import React, {
  compose, namespace, splash, Redirect,
} from '../../../../../app/app';
import User from '../../../../../models/User';
import { getAuthUser } from '../../../../../lib/session';
import Invite from './Invite';
import './invite.scss';

const ns = 'views.team.invite';
const updateState = namespace(ns, { validations: {} });
const props = (state) => ({ ...state[ns], authUser: state.authUser });

class InviteContainer extends React.Component {
  componentDidMount() {
    this.props.actions.createUser();
  }

  componentWillUnmount() {
    this.props.actions.destroyView();
  }

  render() {
    if (this.props.userInvited) {
      return <Redirect to="/team" />;
    }
    if (!this.props.user) {
      return null;
    }
    const { user } = this.props;
    user.onChange(this.props.actions.inputChange);
    return <Invite {...this.props} user={user} />;
  }
}

const createUser = ({ state }) => updateState(state, { user: new User() });
const inputChange = ({ state, values }) => updateState(state, { user: values });
const destroyView = ({ state }) => updateState(state, { validations: {}, user: null, userInvited: false });

const inviteUser = (() => {
  const action = (user) => {
    user.set('account.id', getAuthUser().account);
    return User.invite(user);
  };
  const start = () => splash({ start: true, text: 'Inviting User' });
  const end = ({ state, result }) => {
    const { status, response } = result;
    if (status === 409) {
      return {
        ...splash({ show: false }),
        ...updateState(state, { validations: { email: true } }),
      };
    }
    if (status === 400 && response.messages[0]?.code === messageCode.exceedMaximumUserInviteLimitation) {
      return {
        ...splash({ show: false }),
        ...updateState(state, { validations: { limit: true } }),
      };
    }
    if (result.status === 400 && result.response.messages[0] === 'invalid_email') {
      return {
        ...splash({ show: false }),
        ...updateState(state, { validations: { invalidEmail: true } }),
      };
    }
    if (result.status === 400 && result.response.messages[0] === 'invalid_name') {
      return {
        ...splash({ show: false }),
        ...updateState(state, { validations: { invalidName: true } }),
      };
    }
    return {
      ...splash({ finish: true, text: 'Success!' }),
      ...updateState(state, { userInvited: true }),
    };
  };
  return { action, start, end };
})();

const actions = {
  createUser, destroyView, inputChange, inviteUser,
};

export default compose(InviteContainer, { props, actions });
