import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import './pickerMonth.scss';

export const prefixCls = 'v2_component_report_calendar_picker_month';

const yearFormatString = 'YYYY';
const monthFormatString = 'MMM';

function getYearData(initDate) {
  const now = moment();
  const sixMonthAgo = now.clone().subtract(6, 'months');
  const currentYear = {
    year: initDate.format(yearFormatString),
    yearDate: initDate.clone(),
    month: [],
  };
  let monthArr = [];
  for (let i = 0; i < 12; i++) {
    const currentMonth = initDate.clone().startOf('year');
    const date = currentMonth.subtract(-i, 'month').startOf('month');

    if (i % 4 === 0) {
      monthArr = [];
      currentYear.month.push(monthArr);
    }

    monthArr.push({
      date,
      disable: !date.isBetween(sixMonthAgo, now, 'month', '(]'),
    });
  }
  return currentYear;
}

function initYear(date = moment()) {
  return [
    getYearData(date.clone().subtract(1, 'year')),
    getYearData(date.clone()),
  ];
}

function PickerMonth({
  initDate,
  onChange,
}) {
  const [year, setYear] = React.useState([]);

  React.useEffect(() => {
    setYear(initYear(initDate));
  }, [initDate]);

  return (
    <div className={prefixCls}>
      {
        year.map((_year, yearKey) => (
          <div key={yearKey} className={`${prefixCls}-year`}>
            <div className={`${prefixCls}-year-text`}>{_year.year}</div>
            <div className={`${prefixCls}-year-wrapper`}>
              {
                _year.month.map((monthGroup, monthGroupKey) => (
                  <div className={`${prefixCls}-line`} key={monthGroupKey}>
                    {
                      monthGroup.map((month, monthKey) => (
                        <div
                          key={monthKey}
                          className={classnames(
                            `${prefixCls}-month`,
                            {
                              [`${prefixCls}-month-disable`]: month.disable,
                            },
                          )}
                          onClick={() => onChange(month)}
                        >
                          {month.date.format(monthFormatString)}
                        </div>
                      ))
                    }
                  </div>
                ))
              }
            </div>
          </div>
        ))
      }
    </div>
  );
}

PickerMonth.propTypes = {
  initDate: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func.isRequired,
};

PickerMonth.defaultProps = {
  initDate: null,
};

export default React.memo(PickerMonth);
