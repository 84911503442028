import React, {
  useState, useCallback, useEffect,
} from 'react';
import moment from 'moment';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Dropdown from '../../../Dropdown/Dropdown';
import FiltersCache from '../../../../../lib/cache/FiltersCache';
import { REPORTS_CACHE_KEY } from '../../../../../lib/cache/constant';
import { setCacheValue } from '../../../../../services/Templates/Reports/helper';
import { DATE_KEY } from '../../../../../services/Templates/Reports/constant';
import {
  QUICK_KEY_LAST_7_DAYS, QUICK_KEY_CUSTOM, QUICK_MAP, QUICK_KEY_CUSTOM_TEXT,
} from '../../Calendar/PickerQuick';
import { formatString } from '../../Calendar/PickerInput';
import Calendar from '../../Calendar/Calendar';
import Filter from '../../../Filter/Filter';

export function initDate() {
  return {
    quickKey: QUICK_KEY_LAST_7_DAYS,
    ...QUICK_MAP[QUICK_KEY_LAST_7_DAYS].generateDate(moment()),
  };
}

const DatePickerFilter = ({
  className,
  date,
  visible,
  onVisibleChange,
  onChange,
}) => {
  const authUser = useSelector((state) => state.authUser);
  const reportsCache = new FiltersCache(authUser.id, REPORTS_CACHE_KEY);

  const [checkedDate, setCheckedDate] = useState(initDate());

  useEffect(() => {
    if (date) {
      setCheckedDate(date);
    }
  }, [date]);

  const onApply = useCallback((item) => {
    setCacheValue(reportsCache, DATE_KEY, item);
    setCheckedDate(item);
    onChange(item);
    onVisibleChange(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange, onVisibleChange]);

  const getFormatDate = useCallback(() => {
    const { quickKey, startDate, endDate } = checkedDate;
    if (!quickKey) {
      return QUICK_KEY_CUSTOM_TEXT;
    }
    if (quickKey === QUICK_KEY_CUSTOM || !QUICK_MAP[quickKey]) {
      if (startDate && endDate) {
        return `${startDate.format(formatString)} - ${endDate.format(formatString)}`;
      }
      return QUICK_KEY_CUSTOM_TEXT;
    }
    return QUICK_MAP[quickKey].text;
  }, [checkedDate]);

  const renderOverlay = useCallback(() => {
    const { startDate, endDate, quickKey } = checkedDate;
    return (
      <Calendar
        quickKey={quickKey}
        startDate={startDate}
        endDate={endDate}
        onCancel={() => onVisibleChange(false)}
        onChange={onApply}
      />
    );
  }, [checkedDate, onApply, onVisibleChange]);

  return (
    <Dropdown
      fixed
      renderOverlay={renderOverlay}
      onVisibleChange={onVisibleChange}
      visible={visible}
      style={{
        padding: 0,
      }}
    >
      <Filter className={className} type="date" open={visible}>
        {getFormatDate()}
      </Filter>
    </Dropdown>
  );
};

DatePickerFilter.propTypes = {
  className: PropTypes.string,
  date: PropTypes.shape({
    quickKey: PropTypes.string,
    // Object of moment type.
    startDate: PropTypes.objectOf(PropTypes.any),
    // Object of moment type.
    endDate: PropTypes.objectOf(PropTypes.any),
  }),
  visible: PropTypes.bool,
  onChange: PropTypes.func,
  onVisibleChange: PropTypes.func,
};

DatePickerFilter.defaultProps = {
  className: '',
  date: null,
  visible: false,
  onChange: noop,
  onVisibleChange: noop,
};

export default DatePickerFilter;
