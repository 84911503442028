import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Toggler from 'components/Form/Toggler/Toggler';
import InputGroupContainer from 'templates/input-group/InputGroupContainer';
import Campaign from 'models/Campaign';
import { compose } from 'app/app';
import triggerConfirm from 'components/Modals/ConfirmAction/triggerConfirm';
import EnableSKAdNetworkAttribution from 'components/EnableSKAdNetworkAttribution/EnableSKAdNetworkAttribution';
import './EnableSKAdNetwork.scss';

const { Field } = InputGroupContainer;
const createNamespace = 'views.campaigns.edit';
const props = (state) => ({
  ...state[createNamespace],
});
const DESCRIPTION = `
    This indicates whether this campaign will be used for SKAdNetwork CampaignId mapping 
    and will automatically lock the campaign to minimum iOS 14. Once the campaign is saved, 
    the setting cannot be changed.
`;

export const TEST_ID = 'EnableSKAdNetwork';

export const EnableSKAdNetworkUnWrapped = ({
  match: {
    params: {
      edit: id,
    },
    path: route = '',
  },
  campaign,
  disabled,
}) => {
  const isChecked = campaign.isSKAdNetworkEnabled();
  const isDisabled = !campaign.isSKAdNetworkCapabilityChangeable() || disabled;

  useEffect(() => {
    if (id === 'create' && !campaign.isSKAdNetworkEnabled() && campaign.get('application').isSKAdNetworkEnabled()) {
      campaign.refreshApplication(campaign.get('application'));
      campaign.set('attribution_method', 'mmp');
      if (!route.endsWith('confirm')) {
        campaign.enableSKAdNetwork();
      }
    }
  }, []);

  const setSKAdNetwork = (toEnable) => {
    const header = toEnable ? 'Enable SKAdNetwork' : 'Disable SKAdNetwork';
    const message = `Once you ${toEnable ? 'enable' : 'disable'} SKAdNetwork and save the campaign, the change is permanent. Do you want to continue?`;
    triggerConfirm({
      type: toEnable ? 'ENABLE_SKADNETWORK_CONFIRM_ACTION' : 'DISABLE_SKADNETWORK_CONFIRM_ACTION',
      header,
      message,
      cancelText: 'Cancel',
      confirmText: 'Yes',
      className: 'v2_confirmation_modal',
      onConfirm() {
        if (toEnable) {
          campaign.refreshApplication(campaign.get('application'));
          campaign.enableSKAdNetwork();
        } else {
          campaign.restoreSKAdNetworkValues();
        }
      },
    });

    campaign.set('attribution_method', 'mmp');
  };
  const billingToggleDisabled = campaign.get('budget.publisher_rates').length > 0;

  return campaign.isSKAdNetworkCapable()
    ? (
      <div
        className="views__advertiser__campaigns__edit__enable_skadnetwork"
      >
        <InputGroupContainer
          title="SKAdNetwork"
          description="Choose to use SKAdNetwork."
        >
          <Field
            name="SKAdNetwork Enabled"
            description={DESCRIPTION}
          >
            <Toggler
              checked={isChecked}
              disabled={isDisabled}
              onChange={setSKAdNetwork}
              data-testid={TEST_ID}
            />
          </Field>
          <EnableSKAdNetworkAttribution
            campaign={campaign}
            disabled={billingToggleDisabled}
          />
        </InputGroupContainer>
      </div>
    )
    : null;
};

EnableSKAdNetworkUnWrapped.propTypes = {
  campaign: PropTypes.instanceOf(Campaign).isRequired,
  disabled: PropTypes.bool,
};

EnableSKAdNetworkUnWrapped.defaultProps = {
  disabled: false,
};

export default compose(EnableSKAdNetworkUnWrapped, { props });
