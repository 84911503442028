import React from 'react';
import MultiTreeSelected from 'templates/MultiTreeSelector/MultiTreeSelected';

const prefixCls = 'selected';

const CountrySelected = ({
  selectedItems,
  onSelectedChange,
  onPaste,
}) => (
  <MultiTreeSelected
    shouldShowCopy
    shouldShowPaste
    prefixCls={prefixCls}
    selectedItems={selectedItems}
    onSelectedChange={onSelectedChange}
    onPaste={onPaste}
    placeholder="Paste ISO codes"
    copyMapper={(country) => country.code}
    deleteMapper={(change) => (selected) => selected.id !== change.id}
    filterKeyGetter={(country) => country.id}
  />
);

export default CountrySelected;
