import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';
import Dropdown from '../../../Dropdown/Dropdown';
import Menu, { MenuAll, MenuItem } from '../../../Dropdown/Menu/Menu';
import DropdownWrapper from '../../../Dropdown/DropdownBase/DropdownWrapper';
import { dimensions } from './constant';
import './downloadCsvFilter.scss';

const classPrefix = 'v2_component_download_csv_filter';
const wrapperClassPrefix = `${classPrefix}_dropdown_wrapper`;

const DownloadCsvFilter = ({
  visible: visibleProp,
  defaultCheckedKeys,
  onVisibleChange,
  onChange,
}) => {
  const [checkedKeys, setCheckedKeys] = useState(defaultCheckedKeys || []);
  const [visible, setVisible] = useState(visibleProp);

  const onClick = useCallback(() => {
    const toggled = !visible;
    setVisible(toggled);
    onVisibleChange(toggled);
  }, [visible, onVisibleChange]);

  const onCheckedChange = useCallback((_, _checkedKeys) => {
    setCheckedKeys(_checkedKeys);
  }, []);

  const onApply = useCallback(() => {
    const toggled = !visible;
    const checkedValues = dimensions.filter(({ id }) => checkedKeys.indexOf(id) !== -1);
    const isAllChecked = checkedKeys.length === dimensions.length;
    onChange(checkedValues, checkedKeys, isAllChecked);
    onVisibleChange(toggled);
    setVisible(toggled);
  }, [visible, checkedKeys, onVisibleChange, onChange]);

  const onClear = useCallback(() => {
    setCheckedKeys([]);
  }, []);

  const renderOverlay = () => (
    <DropdownWrapper
      className={wrapperClassPrefix}
      data={dimensions}
      width={250}
      linkText="Clear All"
      cancelText="Cancel"
      applyText="Download"
      applyDisabled={!checkedKeys.length}
      onLink={onClear}
      onCancel={onClick}
      onApply={onApply}
    >
      <Menu checkedKeys={checkedKeys} onChange={onCheckedChange}>
        <div className={`${classPrefix}-head`}>
          <MenuAll>Select All</MenuAll>
        </div>
        <div className={`${classPrefix}-body`}>
          {dimensions.map((item) => (
            <MenuItem
              key={item.id}
              dataKey={item.id}
              data={item}
            >
              {item.text}
            </MenuItem>
          ))}
        </div>
      </Menu>
    </DropdownWrapper>
  );

  return (
    <div className={classPrefix}>
      <Dropdown
        fixed
        visible={visible}
        placement="right"
        renderOverlay={renderOverlay}
        onVisibleChange={setVisible}
        style={{
          overflow: 'hidden',
          padding: 0,
        }}
      >
        <div
          className={classnames(
            'button', {
              [`${classPrefix} button-open`]: visible,
            },
          )}
          onClick={onClick}
        >
          Download CSV
          <i className="material-icons">keyboard_arrow_down</i>
        </div>
      </Dropdown>
    </div>
  );
};

DownloadCsvFilter.propTypes = {
  /**
   * Indicate whether filter component is visible, default value is `false`.
  */
  visible: PropTypes.bool,
  /**
   * Indicate default checked dimensions, default value is `null`.
  */
  defaultCheckedKeys: PropTypes.arrayOf(PropTypes.string),
  /**
   * This method will be called when filter component visible changed.
  */
  onVisibleChange: PropTypes.func,
  /**
   * This method will be called when Download button on filter component clicked.
  */
  onChange: PropTypes.func,
};

DownloadCsvFilter.defaultProps = {
  visible: false,
  defaultCheckedKeys: null,
  onVisibleChange: noop,
  onChange: noop,
};

export default DownloadCsvFilter;
