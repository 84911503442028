import React, { useEffect } from 'react';
import { config, compose, namespace } from '../../../../../../app/app';
import Checkbox from '../../../../../../components/Form/Checkbox/CheckboxContainer';
import Radio from '../../../../../../components/Form/Radio/Radio';
import Group from '../../../../../../templates/input-group/InputGroupContainer';
import ToggleBox from '../../../../../../templates/ToggleBox/ToggleBox';
import MinMax from '../../../../../../templates/MinMax/MinMax';
import PublisherTargeting from './PublisherTargeting';
import CollapseSection from '../../../../../../components/V2/Collapse/CollapseSection';
import DeviceModelTargeting from '../../../../../../components/Campaigns/DeviceModelTargeting/DeviceModelTargeting';
import DeviceModelTargetingCsv from '../../../../../../components/DeviceModelTargetingCsv/DeviceModelTargetingCsv';
import './targeting.scss';

const { skadnetworkMinOsVersion } = config.get('campaigns');

const createNamespace = 'views.campaigns.edit';
const ns = 'views.campaigns.edit.targeting';
const updateCampaignsEditState = namespace(createNamespace);
const props = (state) => ({
  ...state[ns],
  ...state[createNamespace],
});

const updatePageContinue = ({ state, values: { pageContinue } }) => updateCampaignsEditState(state, { pageContinue });

const actions = {
  updatePageContinue,
};

const { Field } = Group;
const classPrefix = 'views_campaign_targeting';

const isOsMinValid = (campaign) => {
  const minVersion = campaign.get('targeting.versions.application.min');
  if (campaign.get('is_skadnetwork_enabled')) {
    if (minVersion < skadnetworkMinOsVersion && minVersion !== 0 && minVersion !== '') {
      return false;
    }
  }
  return campaign.validateMinOsVersion();
};

export const TargetingUnwrapped = ({ campaign, updatePageContinue: onPageContinue }) => {
  useEffect(() => () => {
    onPageContinue({ pageContinue: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const targetingMinOSVersion = campaign.getMinOSVersion();
  return (
    <div className={`${classPrefix}`}>
      <CollapseSection title="Device" description="Select what device options you wish to target.">
        <Field name="Device" description="Target specific device types">
          {[
            ['targeting.devices.phone', 'Phone', true],
            ['targeting.devices.tablet', 'Tablet', true],
          ].map(([device, label, show]) => show && (
            <Checkbox
              key={device}
              label={label}
              checked={campaign.get(device)}
              onChange={(v) => campaign.set(device, v)}
            />
          ))}
        </Field>
        {campaign.isIOS() && DeviceModelTargeting({ campaign })}
        <Field name="Connection" description="Target specific device connections">
          <Radio
            name="campaign_targeting_connection"
            value={campaign.get('targeting.network')}
            onChange={(v) => campaign.set('targeting.network', v)}
          >
            <Radio.Option value="all" label="All Connection Types" />
            <Radio.Option value="wifi" label="Wifi Only" />
          </Radio>
        </Field>
        <Field name="OS Version Range" description="Target a range of operating system versions">
          <ToggleBox
            checked={campaign.get('targeting.versions.application.is_enabled')}
            onToggle={(enabled) => {
              campaign.set('targeting.versions.application.is_enabled', enabled);
              campaign.set('targeting.versions.application.min', enabled ? targetingMinOSVersion : null);
            }}
            data-testid="targeting-settings-os-version-range-toggle"
          >
            {campaign.get('targeting.versions.application.is_enabled') && (
              <MinMax
                textType="Text"
                minValid={isOsMinValid(campaign)}
                minValue={targetingMinOSVersion}
                onMinChange={(v) => campaign.set('targeting.versions.application.min', v)}
                maxValue={campaign.get('targeting.versions.application.max')}
                onMaxChange={(v) => campaign.set('targeting.versions.application.max', v)}
                minAllowed="11.0"
                toggler
              />
            )}
          </ToggleBox>
        </Field>
        <DeviceModelTargetingCsv
          campaign={campaign}
        />
      </CollapseSection>
      <PublisherTargeting campaign={campaign} onPageContinue={onPageContinue} />
    </div>
  );
};

export default compose(TargetingUnwrapped, { props, actions });
