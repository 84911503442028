export const METRIC_IMPRESSIONS = 'views';
export const METRIC_CLICKS = 'clicks';
export const METRIC_MMP_INSTALLS = 'mmpInstalls';
export const METRIC_SKAN_INSTALLS = 'skanInstalls';
export const METRIC_SPENDS = 'spend';
export const METRIC_ECPM = 'ecpm';
export const METRIC_ECPI = 'eCPI';
export const METRIC_CTR = 'CTR';
export const METRIC_IR = 'IR';
export const METRIC_IPM = 'IPM';

export const METRIC_D1_REVENUE = 'd1rev';
export const METRIC_D3_REVENUE = 'd3rev';
export const METRIC_D7_REVENUE = 'd7rev';
export const METRIC_D14_REVENUE = 'd14rev';
export const METRIC_D30_REVENUE = 'd30rev';

export const METRIC_D1_ROAS = 'd1roas';
export const METRIC_D3_ROAS = 'd3roas';
export const METRIC_D7_ROAS = 'd7roas';
export const METRIC_D14_ROAS = 'd14roas';
export const METRIC_D30_ROAS = 'd30roas';
export const METRIC_D7_ARPU = 'd7_arpu';
export const METRIC_D7_ARPPU = 'd7_arppu';
export const METRIC_D7_PAYING_USERS = 'd7_paying_users';
export const METRIC_D7_PAYING_USERS_PERCENT = 'd7_paying_users_percent';
export const METRIC_D7_ECPPU = 'd7_ecppu';
export const METRIC_D7_ECPE = 'd7_ecpe';
export const METRIC_CPE_EVENT_NAME = 'cpe_event_name';
export const METRIC_D1_RETENTION = 'd1ret';
export const METRIC_D3_RETENTION = 'd3ret';
export const METRIC_D7_RETENTION = 'd7ret';
export const METRIC_D14_RETENTION = 'd14ret';
export const METRIC_D30_RETENTION = 'd30ret';
