const prefix = 'Vungle';

class Cache {
  constructor(userId, storageKey, defaultValue) {
    this.storageKey = [prefix, userId, storageKey].join('_');
    this.defaultValue = defaultValue;
  }

  save = (value, cb) => {
    localStorage.setItem(this.storageKey, JSON.stringify(value));
    if (typeof cb === 'function') {
      cb();
    }
  }

  remove = () => {
    localStorage.removeItem(this.storageKey);
  }

  get = () => {
    let value;
    try {
      value = JSON.parse(localStorage.getItem(this.storageKey));
    } catch (e) {
      // do nothing
    }
    return value || this.defaultValue;
  }
}

export default Cache;
