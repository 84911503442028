import React from 'react';
import {
  camelCase, snakeCase,
} from 'lodash';
import { isURL } from 'validator';

// type checks
const { isArray } = Array;
const isObject = (v) => v !== null && typeof v === 'object' && !isArray(v);
const isEmptyArray = (v) => isArray(v) && v.length === 0;
const isEmptyObject = (v) => isObject(v) && Object.keys(v).length === 0;
const isFunction = (v) => typeof v === 'function';
const isInteger = (v) => {
  if (!!v || v === 0) {
    const i = Math.floor(v);
    return typeof v !== 'object' && Number.isInteger(Number(v)) && !Number.isNaN(i) && typeof i === 'number';
  }
  return false;
};
const isNumeric = (v) => {
  const f = parseFloat(v);
  return !Number.isNaN(parseFloat(f)) && Number.isFinite(f);
};
const isString = (v) => typeof v === 'string';
const isUndefined = (v) => typeof v === 'undefined';
const isClass = (v, Class) => v != null && typeof v === 'object' && Class != null && v instanceof Class;
const checkForHexRegExp = new RegExp('^[0-9a-fA-F]{24}$');
const isHexId = (v) => !!v && isString(v) && !v.includes('_') && checkForHexRegExp.test(v);
const isUrlValid = (v) => isString(v) && isURL(v);
const isFalsey = (v) => v === '' || v === null || v === undefined;

const pattern = new RegExp('^((http|https|itms|itm)?:\\/\\/)?' // protocol
  + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name and extension
  + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
  + '(\\:\\d+)?' // port
  // eslint-disable-next-line no-useless-escape
  + '(\\/[\-a-z\\d%@_.~+&:#]*)*' // path
  // eslint-disable-next-line no-useless-escape
  + '(\\?[;&a-z\\-\\d%@_.,~+&:=\-{}]*)?' // query string
  + '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

// this pattern is used for the protocols market, market1, amzn, ms-windows-store
const specialPattern = new RegExp('^((market|market1|amzn|ms-windows-store)?:\\/\\/)?' // protocol
  + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)*[a-z]{2,}|' // domain name and extension (may not contain parts separated by .)
  + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
  + '(\\:\\d+)?' // port
  // eslint-disable-next-line no-useless-escape
  + '(\\/[\-a-z\\d%@_.~+&:]*)*' // path
  // eslint-disable-next-line no-useless-escape
  + '(\\?[;&a-z\\d%@_.,~+&:=\-{}]*)?' // query string
  + '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

function isCTAURLValid(str) {
  if (str === null || str === undefined || !str) {
    return false;
  }
  if (str.startsWith('market') || str.startsWith('market1') || str.startsWith('amzn') || str.startsWith('ms-windows-store')) {
    return specialPattern.test(str);
  }
  return pattern.test(str);
}

// misc checks
const contains = (string = '', search = '') => {
  if (search === null || string === null) {
    return true;
  }
  return string.toLowerCase().indexOf(search.toLowerCase()) !== -1;
};

// manipulations
const pushOrPop = (array = [], value, cb = () => false) => {
  const newArray = [...array];
  const index = array.findIndex(cb);
  if (index === -1) {
    newArray.push(value);
  } else {
    newArray.splice(index, 1);
  }
  return newArray;
};
const dedupe = (array) => {
  const newArray = [];
  array.forEach((value) => {
    if (newArray.includes(value)) {
      return;
    }
    newArray.push(value);
  });
  return newArray;
};

// casts
const toInteger = (v) => Math.floor(v);
const toNumberOrNull = (v) => {
  const f = parseFloat(v);
  if (Number(v) === f) {
    return f;
  }
  return null;
};

// splits
const chunk = (arr, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    chunks.push(arr.slice(i, i + chunkSize));
  }
  return chunks;
};
const split = (v, r = /[\s,]+/) => v.split(r);

// loops
const each = (object, cb) => {
  Object.keys(object).forEach((key) => {
    cb(object[key], key);
  });
};

// conversions
const replaceRateToNumber = (rate) => Number(rate.replace(/[$]+/g, ''));

const toPercent = (v) => {
  if (isArray(v)) {
    return v.map((x) => toPercent(x));
  }
  const f = parseFloat(v);
  if (Number.isNaN(f)) {
    return '';
  }
  return `${f}%`;
};

// formats a long number
const longNumberToShort = (num, fixedLen = 1) => {
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(fixedLen).replace(/\.0$/, '')}m`;
  }
  if (num >= 1000) {
    return `${(num / 1000).toFixed(fixedLen).replace(/\.0$/, '')}k`;
  }
  return num;
};
// takes string `a.b.c` and returns value of c from the nested objects (a: { b: { c: value } } })
const stringToObject = (string, source) => string.split('.').reduce((o, i) => o[i], source);
// takes object and flattens to dot notation string
const flattenObject = (object) => {
  const newObj = {};
  const flatten = (obj, nested = []) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      const string = [...nested];
      string.push(key);
      if (isArray(value) || !isObject(value)) {
        newObj[string.join('.')] = value;
      } else {
        flatten(value, string);
      }
    });
  };
  flatten(object);
  return newObj;
};

/** URL functions **/
const buildNewURLSearchParams = (filterType, newValue, path, search) => {
  const urlSearchParams = new URLSearchParams(search);
  const snakeCaseFilterType = snakeCase(filterType);

  // search can be any arbitrary string, but other filters use 'all' to denote no filter e.g. 'select all'
  if ((filterType === 'search' && newValue.length === 0) || (filterType !== 'search' && newValue.match('all|^$'))) {
    urlSearchParams.delete(snakeCaseFilterType);
  } else {
    urlSearchParams.set(snakeCaseFilterType, newValue);
  }
  return `${path}?${urlSearchParams.toString()}`;
};

const pushNewURLWithSearchParams = (filterType, newValue, router) => {
  //eslint-disable-next-line
  router.history.push(buildNewURLSearchParams(filterType, newValue, location.pathname, location.search));
};

const buildQueryObject = (search) => {
  const urlSearchParams = new URLSearchParams(search);
  const queryParamsObject = {};

  [...urlSearchParams.entries()].forEach((entry) => {
    const queryParam = entry[1];
    queryParamsObject[camelCase(entry[0])] = queryParam;
  });
  return queryParamsObject;
};

const csvToJson = (csv) => {
  const result = [];
  const headers = csv[0];
  for (let i = 1; i < csv.length; i++) {
    const obj = {};
    const currentLine = csv[i];
    for (let j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentLine[j];
    }
    result.push(obj);
  }
  return result;
};

const groupBy = (items, key) => items.reduce(
  (result, item) => ({
    ...result,
    [item[key]]: [
      ...(result[item[key]] || []),
      item,
    ],
  }),
  {},
);

/**
 * Converts the boolean/number values to strings, returns empty string if the value is null/undefined.
 * It is needed because the API expects all values of the template replacements string.
 * There must be another function in the backend side to convert these values into boolean and number.
 *
 * @param value
 * @returns {string|*}
 */
const convertValuesToString = (value) => {
  if (value === '' || value === undefined || value === null) {
    return '';
  }
  if (typeof value === 'boolean') {
    return value.toString();
  }
  if (typeof value === 'number') {
    return `${value}`;
  }
  return value;
};

/**
 * Returns a modified string with first character uppercase.
 *
 * @param value
 * @returns {string|*}
 */
const firstLetterUpper = (value) => value.charAt(0).toUpperCase() + value.slice(1);
const formatTwoDecimalsWithoutRounding = (value) => (parseInt(value * 100, 10) / 100).toFixed(2);

const getPageTop = (node) => node.getBoundingClientRect().top + window.scrollY;

/**
 * Replace links in string with hyperlinks
 *
 * @param string
 * @param linkClassName
 */
const hyperlinkURL = (string = '', linkClassName = '') => {
  if (!string.trim()) return '';
  const reg = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
  const html = string.replace(reg, (website) => `<a class='${linkClassName}' href='${website}' target='_blank'>${website}</a>`);
  // eslint-disable-next-line react/no-danger
  return <span dangerouslySetInnerHTML={{ __html: html }} />;
};

export default {};
export {
  buildNewURLSearchParams,
  buildQueryObject,
  chunk,
  contains,
  convertValuesToString,
  csvToJson,
  dedupe,
  each,
  flattenObject,
  formatTwoDecimalsWithoutRounding,
  isArray,
  isClass,
  isEmptyArray,
  isEmptyObject,
  isFalsey,
  isFunction,
  isHexId,
  isInteger,
  isNumeric,
  isObject,
  isString,
  isUndefined,
  isUrlValid,
  isCTAURLValid,
  longNumberToShort,
  pushOrPop,
  replaceRateToNumber,
  split,
  stringToObject,
  toInteger,
  toNumberOrNull,
  toPercent,
  pushNewURLWithSearchParams,
  groupBy,
  firstLetterUpper,
  getPageTop,
  hyperlinkURL,
};
