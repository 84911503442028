import React from 'react';
import PropTypes from 'prop-types';
import { debounce, isEqual } from 'lodash';
import { FILTER_KEY_DATE_RANGE } from 'app/constants/filters';
import { Router } from '../../../CustomPropTypes';
import Filters from './Filters';
import {
  returnfitlerQueryParams, returnFilterParams, returnInitChecked,
} from './help';

class FiltersContainer extends React.Component {
  constructor(props) {
    super(props);
    this.previousQueryParams = null;
    this.state = {
      filters: props.filters || [],
    };
  }

  componentDidMount() {
    const account = this.props.acctHier?.account;
    const { queryParams } = this.props.router;
    const defaultParams = {};
    const shouldAddDefaultDateRange = !queryParams.start
      && this.props.filters?.find((i) => i.key === FILTER_KEY_DATE_RANGE);
    if (shouldAddDefaultDateRange) {
      defaultParams.days = '7';
    }
    if (account) {
      this.handleRefreshList({
        ...defaultParams,
        ...queryParams,
        account,
      });
    } else {
      this.handleRefreshList({ ...defaultParams, ...queryParams });
    }
    this.modalRoot = document.getElementById('filtersList_modal_root');
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextPropsAcct = nextProps.router.queryParams;
    if ((nextProps.location.pathname === '/accounts') && nextProps.acctHier?.account) {
      this.props.router.queryParams.search = nextProps.acctHier.account;
      this.handleRefreshList({ search: nextProps.acctHier.account });
    } else if (nextProps.acctHier !== undefined && nextProps.acctHier.account !== undefined) {
      const currAcct = nextProps.acctHier.account;
      nextPropsAcct.account = currAcct;
      this.handleRefreshList(nextPropsAcct);
    } else if (nextProps.acctHier !== undefined && nextProps.acctHier.account === undefined) {
      delete nextPropsAcct.account;
      this.handleRefreshList(nextPropsAcct);
    } else {
      this.handleRefreshList(nextProps.router.queryParams);
    }
  }

  handleRefreshList(currentQueryParams) {
    if (isEqual(this.previousQueryParams, currentQueryParams)) {
      return;
    }
    this.previousQueryParams = currentQueryParams;
    this.fetchDataForList(currentQueryParams);
  }

  fetchDataForList = debounce(
    (currentQueryParams) => {
      this.props.refreshList(returnfitlerQueryParams(currentQueryParams));
    },
    500,
  );

  /**
   * newFilters: {
   *  [key: string]:
   *    { id: string, name: string }[]
   *    | { quickPickValue: string, startString: string, endString: string }
   *    | string
   * }
   */
  onFilterChange = (newFilters) => {
    const params = returnFilterParams(newFilters);
    const query = Object.entries(params).map(([key, filterValue]) => `${key}=${filterValue}`).join('&');
    this.props.router.history.push(`${window.location.pathname}?${query}`);
  };

  initCheckedFilters = () => {
    const defaultChecked = { ...this.props.defaultCheckedFilters };
    const params = this.props.router.queryParams;
    const initChecked = returnInitChecked(params, defaultChecked);
    return { ...defaultChecked, ...initChecked };
  }

  render() {
    const { filters } = this.state;
    return (
      <div className="list_filters_container">
        <div className="list_filters_wrapper">
          <Filters
            {...this.props}
            filters={filters}
            onFilterChange={this.props.onFilterChange || this.onFilterChange}
            defaultCheckedConditions={this.initCheckedFilters()}
          />
        </div>
      </div>
    );
  }
}

FiltersContainer.propTypes = {
  router: Router.isRequired,
  refreshList: PropTypes.func.isRequired,
};

export default FiltersContainer;
