import React from 'react';
import Metric from '../component/MetricDropdown';
import TableMenu from '../component/TableMenu';

export default (Component) => {
  class ActionTable extends React.Component {
    renderHeader = () => {
      const { prefixCls, ...rest } = this.props;
      return <Metric prefixCls={`${prefixCls}-metric`} {...rest} />;
    };

    renderCell = ({ original }) => {
      const {
        actionList, actionLabelKey, onActionChange, renderActionCell, actionClassName,
      } = this.props;
      if (Array.isArray(actionList) || actionList instanceof Function) {
        return !original.isTotalRow ? (
          <TableMenu
            className={actionClassName}
            data={typeof actionList === 'function' ? actionList(original) : actionList}
            onChange={(action, index) => onActionChange(original, action, index)}
            labelKey={actionLabelKey}
          />
        ) : null;
      }
      if (typeof renderActionCell === 'function') {
        return renderActionCell(original);
      }
      return null;
    };

    render() {
      const { columns, ...rest } = this.props;
      const actionColumn = {
        Header: this.renderHeader,
        Cell: this.renderCell,
        width: 40,
        sortable: false,
        resizable: false,
        fixed: 'right',
        style: { textAlign: 'center' },
      };
      const _columns = [...columns, actionColumn];
      return <Component {...rest} columns={_columns} />;
    }
  }

  return ActionTable;
};
