import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './tab.scss';

const classPrefix = 'v2_component_tabs_tab';

const Tab = ({
  className,
  icon,
  title,
  selected,
  padding,
  onClick,
}) => (
  <div className={classnames(classPrefix, className)} onClick={onClick}>
    <div className="title-wrapper" style={{ padding }}>
      {icon || (
        <span
          className={classnames(
            'p2', {
              [`${classPrefix} title-wrapper-selected`]: selected,
            },
          )}
        >
          {title}
        </span>
      )}
    </div>
    <div
      className={classnames(
        'track', {
          [`${classPrefix} track-selected`]: selected,
        },
      )}
    />
  </div>
);

Tab.propTypes = {
  /**
   * Class name string.
   */
  className: PropTypes.string,
  /**
   * Render an icon or node, if set icon prop, `title` and `selected` will not work.
   */
  icon: PropTypes.node,
  /**
   * Tab label.
   */
  title: PropTypes.node,
  /**
   * Tell if tab is selected.
   */
  selected: PropTypes.bool,
  /**
   * Tab inner padding.
   */
  padding: PropTypes.string,
  /**
   * Click tab event.
   */
  onClick: PropTypes.func,
};

Tab.defaultProps = {
  className: null,
  icon: null,
  title: null,
  selected: false,
  padding: null,
  onClick: () => {},
};

export default Tab;
