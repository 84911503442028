import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import Label from '../../../Label/Label';
import { skadnetworkStatuses } from '../../constant';
import MultiPanel from './MultiPanel';

const renderItemLabel = (prefixCls, { pulsationStatus, text }) => (
  <div className={`${prefixCls}-item`}>
    <Label type={pulsationStatus}>{text}</Label>
  </div>
);
const SkAdNetworkPanel = ({
  prefixCls,
  defaultCheckedNodes,
  onVisibleChange,
  onChange,
}) => {
  const handleChange = React.useCallback((_selectedAds, isAllSelected) => {
    onVisibleChange(false);
    onChange(_selectedAds, isAllSelected);
  }, [onVisibleChange, onChange]);

  return (
    <MultiPanel
      height={196}
      data={skadnetworkStatuses}
      renderItemLabel={(item) => renderItemLabel(prefixCls, item)}
      defaultCheckedKeys={(defaultCheckedNodes || []).map(({ id }) => id)}
      onVisibleChange={onVisibleChange}
      onChange={handleChange}
    />
  );
};

SkAdNetworkPanel.propTypes = {
  prefixCls: PropTypes.string,
  defaultCheckedNodes: PropTypes.arrayOf(PropTypes.any),
  onVisibleChange: PropTypes.func,
  onChange: PropTypes.func,
};

SkAdNetworkPanel.defaultProps = {
  prefixCls: '',
  defaultCheckedNodes: [],
  onVisibleChange: noop,
  onChange: noop,
};

export default React.memo(SkAdNetworkPanel);
