import React, { useEffect, useState } from 'react';
import { GROW_CAMPAIGN_METRIC_KEY, CTRL_CAMPAIGN_METRIC_KEY } from 'lib/cache/constant';
import { Table } from 'components/V2';
import { cleanupNonExistentColumnsFromCache } from 'components/V2/List/ListFilter/helper';
import {
  metricsGroup,
  attributeMetrics,
  columnsFromMetrics,
  initMetrics,
  execTableAction,
  makeTableActionList,
  allColumns,
} from './helper';
import { defaultSort } from './constants';
import './list.scss';

const CampaignsList = ({
  router,
  props: {
    authUser,
    campaigns = [],
    loadingCampaigns,
    pagination = {
      page: 1, pages: 0, perPage: 10, total: 0,
    },
  },
  actions: {
    execChangeCampaignStatus,
  },
  onClone,
  isMissionControl = false,
  refreshList,
  sortCache,
  metricCache,
  pageSizeCache,
}) => {
  const [metrics, setMetrics] = useState([]);

  useEffect(() => {
    cleanupNonExistentColumnsFromCache({
      metricCache,
      sortCache,
      columns: allColumns(isMissionControl),
    });

    setMetrics(initMetrics(metricCache, attributeMetrics(isMissionControl)));
  }, []);

  return (
    <Table
      className="table-with-total-container"
      maxHeight="100%"
      manual
      resizable
      loading={loadingCampaigns}
      showPagination
      actionable
      page={pagination.page}
      total={pagination.total}
      pageSize={pagination.perPage}
      data={campaigns}
      columns={columnsFromMetrics(metrics, isMissionControl)}
      actionList={(e) => makeTableActionList(e.campaign, isMissionControl)}
      defaultSort={sortCache.get()[0] || defaultSort}
      metricType={isMissionControl ? CTRL_CAMPAIGN_METRIC_KEY : GROW_CAMPAIGN_METRIC_KEY}
      metricGroup={metricsGroup(isMissionControl)}
      metricUserId={authUser.id}
      metricOverlayHeight={560}
      onMetricsChange={setMetrics}
      onActionChange={(c, a) => execTableAction(c, a, router.history, onClone, execChangeCampaignStatus, isMissionControl)}
      onPageSizeChange={(page, perPage) => {
        if (loadingCampaigns) {
          return;
        }
        pageSizeCache.save(perPage);
        refreshList({ page, perPage });
      }}
      onSorted={(v) => {
        sortCache.save(v);
        refreshList({ page: pagination.page });
      }}
    />
  );
};

export default CampaignsList;
