import React, { F } from '../../../app/app';
import DeviceUploader from '../DeviceUploader/DeviceUploaderContainer';
import ApplicationFinder from '../../ApplicationFinder/ApplicationFinder';
import Button from '../../Button/Button';
import Radio from '../../Form/Radio/Radio';
import TextArea from '../../Form/TextArea/TextArea';
import Labels from '../../../templates/Labels/LabelsContainer';
import AccountFinder from '../../AccountFinder/AccountFinderContainer';

const AllowDenyList = ({
  target,
  includeRadio = true,
  onPasteSelect,
  clearIds,
  copyPubs,
  onComplete,
  onSelect,
  platform,
  visible,
  supportedTemplateType,
  radio: {
    name: radioName,
    onChange: radioOnChange = () => {},
    value: radioValue,
    option: {
      allow: {
        radioIncludeOptionAllow = true,
        allowDisabled = false,
      } = {},
    } = {},
  } = {},
  selectedAccounts,
  selectedAccountsOnRemove,
  selectedApplications,
  selectedApplicationsOnRemove,
  selectedPlacements,
  selectedPlacementsOnRemove,
  processAccountContent,
  processPlacementContent,
  processApplicationContent,
  throwError,
  errorMsg,
  whitelistedModels,
  blacklistedModels,
  excludedApplications = [],
  excludedAccounts = [],
  filterInfo,
}) => (
  <div className="components__applications__allow_deny_list">
    {includeRadio && (
      <Radio
        name={radioName}
        value={radioValue}
        onChange={radioOnChange}
      >
        <Radio.Option value="none" label="Do not target" />
        {radioIncludeOptionAllow && <Radio.Option value="allow" label="Allow" disabled={allowDisabled} />}
        <Radio.Option value="deny" label="Deny" />
      </Radio>
    )}
    {(!includeRadio || radioValue !== 'none') && target === 'account' && (
      <F>
        <AccountFinder
          onSelect={onSelect}
          filterInfo={filterInfo}
          accountType="publisher"
          excludedAccounts={excludedAccounts}
        />
        <TextArea
          onChange={(v) => processAccountContent(v, onPasteSelect, excludedApplications, excludedAccounts)}
          placeholder="Paste comma separated Account Mongo IDs here..."
        />
        <Labels
          alert={throwError}
          labels={selectedAccounts.map((account, i) => ({
            key: `${account.get('id')}_${i}`,
            label: account.get('name'),
            onClick: () => selectedAccountsOnRemove(account),
          }))}
        />
      </F>
    )}
    {(!includeRadio || radioValue !== 'none') && target === 'application' && (
      <F>
        <ApplicationFinder
          onPasteSelect={onPasteSelect}
          onSelect={onSelect}
          platform={platform}
          supportedTemplateType={supportedTemplateType}
          type="publisher"
          filterInfo={filterInfo}
          excludedApplications={excludedApplications}
          excludedAccounts={excludedAccounts}
        />
        <TextArea
          onChange={(v) => processApplicationContent(v, onPasteSelect, excludedApplications, excludedAccounts)}
          placeholder="Paste Application Mongo IDs here..."
        />
        <Labels
          alert={throwError}
          labels={selectedApplications.map((application, i) => ({
            key: `${application.get('id')}_${i}`,
            label: application.get('name'),
            onClick: () => selectedApplicationsOnRemove(application),
          }))}
        />
      </F>
    )}
    {(!includeRadio || radioValue !== 'none') && target === 'placement' && (
      <F>
        <TextArea
          onChange={(v) => processPlacementContent(v, onPasteSelect)}
          placeholder="Paste comma separated Placement Mongo IDs here..."
        />
        <Labels
          alert={throwError}
          labels={selectedPlacements.map((pid, i) => ({
            key: `${pid}_${i}`,
            label: `${pid}`,
            onClick: () => selectedPlacementsOnRemove(pid),
          }))}
        />
      </F>
    )}
    {(!includeRadio || radioValue !== 'none') && target === 'device' && (
      <DeviceUploader
        whitelistedModels={whitelistedModels}
        blacklistedModels={blacklistedModels}
        onComplete={onComplete}
      />
    )}
    {throwError ? (<div className="pasteError">{errorMsg}</div>) : null}
    {(!includeRadio || radioValue !== 'none') && (selectedApplications || selectedPlacements || selectedAccounts) && (
      <div className="pub-buttons">
        <Button onClick={clearIds}>Clear All</Button>
        <Button onClick={copyPubs}>Copy All</Button>
        <span className={`copy-message ${visible ? 'fadeIn' : 'fadeOut'}`}>Copied to clipboard</span>
      </div>
    )}
  </div>
);

export default AllowDenyList;
