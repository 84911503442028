import React from 'react';
import PropTypes from 'prop-types';
import Link from '../Link/Link';

const Crumb = ({
  name,
  active,
  complete,
  to,
}) => {
  const radio = active ? 'radio_button_checked' : 'radio_button_unchecked';
  const className = `crumb ${active ? 'crumb--active' : ''} ${(active || complete) ? 'crumb--complete' : ''}`;

  return (
    <Link
      to={to}
      className={className}
    >
      {
        complete
          ? (
            <i className="material-icons mi-check-circle">check_circle</i>
          )
          : (
            <i className="material-icons">{radio}</i>
          )
      }
      <p className="p2">{name}</p>
    </Link>
  );
};

Crumb.propTypes = {
  name: PropTypes.string.isRequired,
  active: PropTypes.shape({ isExact: PropTypes.bool }),
  complete: PropTypes.bool,
  to: PropTypes.string,
};

Crumb.defaultProps = {
  active: null,
  complete: false,
  to: null,
};

export default Crumb;
