import React, {
  Component,
} from 'react';
import {
  compose,
  namespace,
  splash,
} from '../../../../../app/app';
import Application from '../../../../../models/Application';
import CampaignCreativeMappings from './CampaignCreativeMappings';

class CampaignCreativeMappingsContainer extends Component {
  componentDidMount() {
    const {
      match: {
        params: {
          appId,
        },
      },
      actions: {
        getData,
      },
    } = this.props;

    getData(appId);
  }

  componentWillUnmount() {
    this.props.actions.resetState();
  }

  render() {
    const {
      application,
      mappings,
      router,
    } = this.props;

    return (
      <CampaignCreativeMappings
        application={application}
        mappings={mappings}
        router={router}
      />
    );
  }
}

const ns = 'views.applications.mappings';
const updateState = namespace(ns);

const props = (state) => ({
  ...state[ns],
  authUser: state.authUser,
});
const resetState = ({ state }) => updateState(
  state,
  {
    mappings: undefined,
    application: undefined,
  },
);

const getData = (() => {
  const action = (id) => Application
    .get(id)
    .then(({ response }) => ({
      mappings: response?.skAdNetworkMappings,
      application: new Application(response),
    }));

  const end = ({ state, result }) => updateState(state, result);
  const start = splash({ start: true });

  return {
    action,
    end,
    start,
  };
})();

const actions = {
  getData,
  resetState,
};

export default compose(CampaignCreativeMappingsContainer, {
  props,
  actions,
});
