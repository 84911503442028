import config from '../../../lib/config';

// for multi-bidding
export const KEY_BID = 'rate';
export const KEY_GEO = 'geo';
export const KEY_PUB_ID = 'pub_app_id';
export const KEY_NAME = 'name';
export const KEY_INVALID = 'invalid';
export const KEY_BOARD_ENTER = 'Enter';
export const KEY_BOARD_ESC = 'Escape';
export const KEY_ROW_MSG = 'row_msg';
export const GEO_ERROR = 'Invalid or not in the white list';
export const DELETE_BIDDING_MSG = 'Heads up, you are requesting to delete all the multi-bidding rows.'
  + 'Would you like to continue with this action?';
export const DELETE_BIDDING_TITLE = 'Remove All Multi-Bids';

export const ERRORS = {
  EMPTY_BID_GEO: 'Please add a valid ISO Country Code and/or Bid to save this multi-bid',
  EMPTY_PUBID_GEO: 'Please add a valid Publisher/App ID and/or ISO Country Code to save this multi-bid',
  EMPTY_PUBID_BID: 'Please add a valid Publisher/App ID and/or Bid to save this multi-bid',
  EMPTY_BID: 'Please add a valid Bid to save this multi-bid',
  GEO_INVALID: 'Invalid or not in the white list',
  PUB_ID_HEX_ERROR: 'The ID is not valid ObjectID',
  PUB_ID_DUPLICATE: 'Duplicate ID',
  PUB_ID_INVALID: 'Don\'t correspond any Publisher App',
};

export const INLINE_EDIT_PLACEHOLDER = {
  [KEY_PUB_ID]: 'Publisher ID',
  [KEY_GEO]: 'ISO Country Code',
  [KEY_BID]: 'Bid',
  [KEY_NAME]: 'Generated Automatically',
};
export const DEFAULT_PAGINATION = {
  page: 1,
  perPage: 10,
};

// eslint-disable-next-line max-len
export const MAX_BIDDING_ERROR = `You have exceeded the maximum ${config.get('multibidding.maxBidding') / 1000}K amount of rows. Please delete an existing row to add a new one.`;
