import { config } from 'app/app';
import {
  isArray,
  isHexId,
  isInteger,
  pushOrPop,
  split,
} from 'lib/lib';
import {
  campaignFromSalesforce,
  campaignsFromSalesforce,
} from 'app/graphql/utils/campaigns';
import { format } from 'lib/currency';
import { getSelectedCarriers } from 'lib/helpers/campaigns/campaignIndex';
import Campaign from './Campaign';
import ExchangeConnection from './ExchangeConnection';
import Account from './Account';
import { moment, ISO_8601_FORMAT } from '../lib/date';

const { countries, cities } = config.get('countryData');

class AdminCampaign extends Campaign {
  constructor(attrs = {}) {
    super(attrs);
    const { targeting } = attrs;

    if (targeting) {
      const { bundles_offnetwork } = targeting;
      // eslint-disable-next-line camelcase
      if (bundles_offnetwork && bundles_offnetwork.applications) {
        const ids = bundles_offnetwork.applications.map(({ bundle_id: id }) => id).join(',');
        this.set('targeting.bundles_offnetwork.applications.ids', ids);
      }
    }
    if (targeting && targeting.placement && targeting.placement.ids) {
      this.set('targeting.placement.ids', targeting.placement.ids.map(({ id }) => id), false);
    }
    this.map('budget.objective.bid_default', 'budget.objective.bid_max', 'budget.objective.bid_min', format);
    this.map('targeting.exchanges_offnetwork', this.getArrayRelation('targeting.exchanges_offnetwork', ExchangeConnection).bind(this));
    this.map('targeting.account.accounts', this.getArrayRelation('targeting.account.accounts', Account).bind(this));

    this.default('ad_type', 'vungle');
    this.default('settings.ad_type', 'vungle');
    this.default('budget.budget_multiplier.is_enabled', false);
    this.default('budget.budget_multiplier.override', 1);
    this.default('budget.objective.type', 'static');
    this.default('budget.objective.events', []);
    this.default('targeting.geo.cities', []);
    this.default('targeting.reward_type', 'both');
    this.default('salesforce.campaign', false);
    this.default('settings.exchange.protocol', 'vast', [null]);
    this.default('settings.win_notice_source', 'sdk');
    this.default('status', 'active');
    this.default('attribution_method', null);
    // we send these 2 fields even though the back-end does not process them, to get around validation
    this.default('tracking.provider.name', 'adjust', [null]);
    this.default('tracking.provider.customer', 'placeholder', [null]);
    this.default('targeting.account.accounts', []);
    this.default('targeting.account.type', 'none');
    this.default('targeting.placement.ids', []);
    this.default('targeting.placement.type', 'none');
    this.default('targeting.placement_type_whitelist.fullscreen', true);
    this.default('targeting.placement_type_whitelist.mrec', true);
    this.default('targeting.placement_type_whitelist.banner', true);
    this.default('targeting.placement_type_whitelist.native', true);
    this.default('targeting.lat', 'none');
  }

  setSalesForceCampaign = (v) => {
    this.set('name', v.name);
    this.set('sfid', v.sfid);
    this.set('dates.is_indefinite', !!v.runIndefinitely);
    this.set('dates.start', `${v.startDate}T08:00:00.000Z`);
    this.set('dates.end', `${v.endDate}T08:00:00.000Z`);
    this.set('budget.daily', v.dailySpendLimit);
    this.set('budget.total', v.total);
    this.set('budget.type', v.type);
    this.set('budget.bid', v.bid);
    this.set('tracking.url', v.trackingUrl);
    this.map('budget.total', 'budget.daily', 'budget.spent', format);
    this.map('budget.bid_override.value', 'budget.total_override.value', 'budget.daily_override.value', format);
    this.map('budget.type', this.getRateType);
    this.map('budget.bid', this.getRate.bind(this));
    if (v.countryWhitelist.length > 1) {
      const sfCountries = countries.filter((i) => v.countryWhitelist.indexOf(i.code) !== -1);
      this.set('targeting.geo.countries', sfCountries);
    }

    if (v.dailyViewLimit > 0) {
      this.set('settings.daily_view_limit_enabled', true);
      this.set('settings.daily_view_limit', v.dailyViewLimit);
    }

    if (v.sdkTargetingMin) {
      this.set('targeting.versions.sdk.is_enabled', true);
      this.set('targeting.versions.sdk.min', v.sdkTargetingMin);
    }

    if (v.sdkTargetingMax) {
      this.set('targeting.versions.sdk.is_enabled', true);
      this.set('targeting.versions.sdk.max', v.sdkTargetingMax);
    }

    if (v.connectionType === 'Wifi') {
      this.set('targeting.network', v.connectionType.toLowerCase());
    }

    if (v.appWhitelist) {
      this.set('targeting.publisher.type', 'allow');
      this.set('targeting.publisher.applications', v.appWhitelist);
    }

    if (v.appBlacklist) {
      this.set('targeting.publisher.type', 'deny');
      this.set('targeting.publisher.applications', v.appBlacklist);
    }

    if (v.deviceType.Phone) {
      this.set('targeting.devices.phone', true);
    } else {
      this.set('targeting.devices.phone', false);
    }

    if (v.deviceType.Tablet) {
      this.set('targeting.devices.tablet', true);
    } else {
      this.set('targeting.devices.tablet', false);
    }

    if (v.osLevelMin || v.osLevelMax) {
      this.set('targeting.versions.application.is_enabled', true);
      this.set('targeting.versions.application.max', v.osLevelMax);
      this.set('targeting.versions.application.min', v.osLevelMin);
    }
    if (v.totalViewLimit > 0) {
      this.set('settings.total_view_limit_enabled', true);
      this.set('settings.total_view_limit', v.totalViewLimit);
    }

    if (v.destinationUrl) {
      this.set('targeting.destination', v.destinationUrl);
    }

    if (v.optimizationType) {
      this.set('budget.objective.type', v.optimizationType.toLowerCase());
    }

    if (v.cityTargeting) {
      this.set('targeting.geo.region', 'city');
      const sfCities = cities.filter((i) => v.cityList.indexOf(i.name) !== -1);
      this.set('targeting.geo.cities', sfCities);
    }

    if (v.campaignAdType !== 'vungle' && v.campaignAdType !== null) {
      this.set('settings.ad_type', v.campaignAdType);
      this.set('settings.exchange.protocol', v.exchangeProtocol);
      this.set('settings.exchange.delivery_type', v.allowedAdDeliveryType);
    }
  };

  toggleSalesforce = () => {
    if (this.raw('salesforce.campaign') === false) {
      this.set('salesforce.campaign', true);
    } else {
      this.set('salesforce.campaign', false);
      this.set('name', '');
      this.set('dates.is_indefinite', false);
      this.set('dates.start', '');
    }
  };

  setTargetEvent(index, field, value) {
    const events = this.get('budget.objective.events');
    const event = events[index];
    if (event) {
      event[field] = value;
    }
    this.set('budget.objective.events', events);
  }

  addTargetEvent() {
    const events = this.get('budget.objective.events');
    events.push({ name: '', value: '' });
    this.set('budget.objective.events', events);
  }

  removeTargetEvent(index) {
    const events = this.get('budget.objective.events');
    delete events[index];
    this.set('budget.objective.events', events);
  }

  addTargetingAccount(acc) {
    if (acc === undefined) {
      return;
    }
    const accounts = this.get('targeting.account.accounts');
    if (isArray(acc)) {
      acc.forEach((a) => {
        const index = accounts.findIndex((p) => p.get('id') === a.get('id'));
        if (accounts.length === 0 || index === -1) {
          accounts.push(a);
        }
      });
    } else {
      const index = accounts.findIndex((p) => p.get('id') === acc.get('id'));
      if (accounts.length === 0 || index === -1) {
        accounts.push(acc);
      }
    }
    this.set('targeting.account.accounts', accounts);
  }

  removeTargetingAccount(acc) {
    const accounts = this.get('targeting.account.accounts');
    const index = accounts.findIndex((p) => p.get('id') === acc.get('id'));
    accounts.splice(index, 1);
    this.set('targeting.account.accounts', accounts);
  }

  addTargetingPublisher(app) {
    const publishers = this.get('targeting.publisher.applications');
    if (isArray(app)) {
      app.forEach((a) => {
        const index = publishers.findIndex((p) => p.get('id') === a.get('id'));
        if (publishers.length === 0 || index === -1) {
          publishers.push(a);
        }
      });
    } else {
      const index = publishers.findIndex((p) => p.get('id') === app.get('id'));
      if (publishers.length === 0 || index === -1) {
        publishers.push(app);
      }
    }
    this.set('targeting.publisher.applications', publishers);
  }

  removeTargetingPublisher(app) {
    const publishers = this.get('targeting.publisher.applications');
    const index = publishers.findIndex((p) => p.get('id') === app.get('id'));
    publishers.splice(index, 1);
    this.set('targeting.publisher.applications', publishers);
  }

  addPlacement(pids) {
    const placements = [...this.get('targeting.placement.ids'), ...pids];
    this.set('targeting.placement.ids', placements);
  }

  removePlacementId(id) {
    const placements = this.get('targeting.placement.ids');
    const index = placements.findIndex((p) => p === id);
    placements.splice(index, 1);
    this.set('targeting.placement.ids', placements);
  }

  hasActiveExchange(exchange) {
    return this.get('targeting.exchanges_offnetwork').filter((c) => c.get('id') === exchange.get('id')).length > 0;
  }

  toggleExchange(exchange) {
    this.set(
      'targeting.exchanges_offnetwork',
      pushOrPop(this.get('targeting.exchanges_offnetwork'), exchange, (e) => e.get('id') === exchange.get('id')),
    );
  }

  toggleCarrier(carrier, isChecked) {
    const selectedCarriers = getSelectedCarriers(this);
    const carrierExists = !!selectedCarriers.find((c) => c === carrier);

    const carrierListKey = this.get('targeting.carrier.mode') === 'allow'
      ? 'targeting.carrier.carrierAllowList'
      : 'targeting.carrier.carrierDenyList';

    if (isChecked && !carrierExists) {
      this.set(
        carrierListKey,
        [...selectedCarriers, carrier],
      );
    } else if (!isChecked && carrierExists) {
      this.set(
        carrierListKey,
        selectedCarriers.filter((c) => c !== carrier),
      );
    }
  }

  toggleGenre({ id, name }, isChecked) {
    const selectedGenres = this.get('targeting.genre.genreList');
    const genreExists = !!selectedGenres.find((genre) => genre.id === id);
    if (isChecked && !genreExists) {
      this.set(
        'targeting.genre.genreList',
        [...selectedGenres, { id, name }],
      );
    } else if (!isChecked && genreExists) {
      this.set(
        'targeting.genre.genreList',
        selectedGenres.filter((genre) => genre.id !== id),
      );
    }
  }

  setCloneDate() {
    const start = this.raw('dates.start');
    const end = this.raw('dates.end');
    const currentDate = Date.now();

    const isPastStartDate = moment(start).isBefore(moment(currentDate));
    const isPastEndDate = moment(end).isBefore(moment(currentDate));
    if (isPastStartDate) {
      this.set('dates.start', moment(currentDate).utc().format(ISO_8601_FORMAT));
    }
    if (isPastEndDate) {
      this.set('dates.end', '');
    }
  }

  validateTotalViewLimit() {
    const totalViewLimitEnabled = this.get('settings.total_view_limit_enabled');
    const totalViewLimit = this.get('settings.total_view_limit');
    if (totalViewLimitEnabled && totalViewLimit) {
      if (!isInteger(totalViewLimit)) {
        return false;
      }
      if (totalViewLimit < 0) {
        return false;
      }
    }
    return true;
  }

  validateDailyViewLimit() {
    const dailyViewLimitEnabled = this.get('settings.daily_view_limit_enabled');
    const dailyViewLimit = this.get('settings.daily_view_limit');
    if (dailyViewLimitEnabled && dailyViewLimit) {
      if (!isInteger(dailyViewLimit)) {
        return false;
      }
      if (dailyViewLimit < 0) {
        return false;
      }
    }
    return true;
  }

  isFilterLoggingEnabled() {
    const filterLoggingEnabledAt = this.get('settings.filter_logging_enabled_at');
    if (!filterLoggingEnabledAt) {
      return false;
    }
    const now = moment();
    const lastFifthDay = now.subtract(5, 'days');
    return moment(filterLoggingEnabledAt).isAfter(lastFifthDay);
  }

  toServerObject(create = false) {
    const obj = super.toServerObject(this.raw('status') === 'active', create);
    obj.admin_status = this.raw('admin_status');

    // budget
    obj.budget.budget_multiplier = {
      is_enabled: this.raw('budget.budget_multiplier.is_enabled'),
      override: parseFloat(this.raw('budget.budget_multiplier.override')),
    };

    if (this.raw('sfid')) {
      obj.sfId = this.raw('sfid');
    } else {
      obj.sfId = 'false';
    }

    if (this.get('budget.daily_spend_limit_type')) {
      const dailySpendLimitGeos = this.get('budget.daily_spend_limit_geos');
      if (dailySpendLimitGeos && dailySpendLimitGeos.length > 0) {
        obj.budget.daily_spend_limit_type = this.raw('budget.daily_spend_limit_type');
        obj.budget.daily_spend_limit_geos = dailySpendLimitGeos.map((r) => ({
          rate: r.rate === '' ? 0 : r.rate,
          geo: r.geo,
        }));
      }
    }

    // settings
    const settings = this.pluck({}, 'settings', [
      'ad_type',
      'creative_override',
      'creative_timer_override',
      'carrier_targeting',
      'daily_view_limit',
      'daily_view_limit_enabled',
      'erpm_override',
      'erpm_override_enabled',
      'free_form',
      'targeted_environment',
      'total_view_limit',
      'total_view_limit_enabled',
      'win_notice_source',
      'filter_logging_enabled_at',
    ]);
    if (this.isIOS() || this.isAndroid()) {
      settings.skoverlay_auto_show = this.get('settings.skoverlay_auto_show');
    }
    if (this.raw('settings.ad_type') === 'exchange') {
      settings.exchange = this.pluck({}, 'settings.exchange', [
        'delivery_type',
        'exchange_url',
        'expiry',
        'max_duration',
        'min_bitrate',
        'protocol',
      ]);
    } else {
      settings.exchange = this.pluck({}, 'settings.exchange', [
        'protocol',
      ]);
    }
    obj.settings = settings;

    // tracking
    obj.tracking = this.pluck(obj.tracking, 'tracking', [
      'click',
      'destination',
      'moat',
      'refresh_destination',
      'url',
      'events',
    ]);

    // targeting
    const targeting = {
      ...obj.targeting,
      versions: this.pluck(obj.targeting.versions, 'targeting.versions', [
        'phone_max',
        'phone_min',
        'tablet_max',
        'tablet_min',
      ]),
    };
    targeting.versions.sdk = this.pluck({}, 'targeting.versions.sdk', [
      'is_enabled',
      'max',
      'min',
    ]);

    targeting.lat = this.get('targeting.lat');

    if (this.raw('settings.ad_type') === 'offnetwork') {
      targeting.bundles_offnetwork = {
        type: this.get('targeting.bundles_offnetwork.type'),
        applications: [],
      };
      targeting.exchanges_offnetwork = this.get('targeting.exchanges_offnetwork').map((e) => ({ id: e.get('id'), name: e.get('name') }));

      if (this.get('targeting.bundles_offnetwork.type') !== 'none') {
        const bundleIds = this.get('targeting.bundles_offnetwork.applications.ids') || '';
        const bundleIdArray = split(bundleIds);
        targeting.bundles_offnetwork.applications = bundleIdArray.map((b) => ({ bundle_id: b }));
      }
    }

    if (this.raw('settings.ad_type') !== 'offnetwork') {
      targeting.publisher.applications = this.raw('targeting.publisher.applications').map((a) => ({ id: a.get('id') }));
    }

    targeting.account = {
      type: this.get('targeting.account.type'),
      accounts: [],
    };
    if (this.get('targeting.account.type') !== 'none') {
      const accounts = this.get('targeting.account.accounts') || [];
      targeting.account.accounts = accounts.map((acc) => ({ id: acc.get('id') }));
    }

    targeting.placement = {
      type: this.get('targeting.placement.type'),
      ids: [],
    };
    if (this.get('targeting.placement.type') !== 'none') {
      const placementIds = this.get('targeting.placement.ids');
      const placements = placementIds.filter(isHexId);
      targeting.placement.ids = placements.map((p) => ({ id: p }));
    }
    targeting.placement_type_whitelist = {
      mrec: this.get('targeting.placement_type_whitelist.mrec'),
      banner: this.get('targeting.placement_type_whitelist.banner'),
      fullscreen: this.get('targeting.placement_type_whitelist.fullscreen'),
      native: this.get('targeting.placement_type_whitelist.native'),
    };

    targeting.reward_type = this.get('targeting.reward_type');
    targeting.geo.countries = targeting.geo.countries.filter((country) => Object.keys(country).length !== 0);

    obj.targeting = targeting;
    return obj;
  }
}

AdminCampaign.getSalesForceCamp = (sfid) => campaignFromSalesforce(sfid);
AdminCampaign.getSalesForceList = () => campaignsFromSalesforce();
AdminCampaign.new = Campaign.new;
AdminCampaign.save = Campaign.save;
AdminCampaign.historySettings = Campaign.historySettings;

export default AdminCampaign;
