import React, { useState, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import DropdownWrapper from '../../../Dropdown/DropdownBase/DropdownWrapper';
import MultiTreeWrapper from '../MultiTreeWrapper/MultiTreeWrapper';

const Panel = ({
  className,
  prefixCls,
  tree,
  nodes,
  visible,
  loading,
  pagination,
  renderNode,
  renderLeaf,
  menuNodes,
  menuLoading,
  placeholder,
  maxSelected,
  disabledTitle,
  disabledContent,
  menuPagination,
  checkableNodes,
  defaultCheckedNodes,
  treeChildrenKey,
  onVisibleChange,
  onLoadChildren,
  onLoadMore,
  onLoadMenu,
  onChange,
}) => {
  const [checkedNodes, setCheckedNodes] = useState([]);

  useEffect(() => {
    if (visible) {
      setCheckedNodes(defaultCheckedNodes);
    }
  }, [visible, defaultCheckedNodes]);

  const handleCancel = useCallback(() => {
    onVisibleChange(false);
  }, [onVisibleChange]);

  const handleApply = useCallback(() => {
    onChange(checkedNodes);
    onVisibleChange(false);
  }, [checkedNodes, onChange, onVisibleChange]);

  const handleClear = useCallback(() => {
    setCheckedNodes([]);
  }, [setCheckedNodes]);

  return (
    <DropdownWrapper
      width={700}
      style={{ maxHeight: '500px' }}
      linkText="Clear All"
      onLink={handleClear}
      onApply={handleApply}
      onCancel={handleCancel}
    >
      <MultiTreeWrapper
        wrapperCls={prefixCls}
        prefixCls={classnames('v2_component_filters_tree', className)}
        tree={tree}
        nodes={nodes}
        loading={loading}
        menuNodes={menuNodes}
        pagination={pagination}
        renderNode={renderNode}
        renderLeaf={renderLeaf}
        maxSelected={maxSelected}
        menuLoading={menuLoading}
        placeholder={placeholder}
        checkedNodes={checkedNodes}
        disabledTitle={disabledTitle}
        disabledContent={disabledContent}
        checkableNodes={checkableNodes}
        treeChildrenKey={treeChildrenKey}
        menuPagination={menuPagination}
        onLoadChildren={onLoadChildren}
        onChange={setCheckedNodes}
        onLoadMore={onLoadMore}
        onLoadMenu={onLoadMenu}
      />
    </DropdownWrapper>
  );
};

export default React.memo(Panel);
