import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';
import './collapseSummary.scss';

const expandedIcon = <i className="material-icons">expand_more</i>;

const classPrefix = 'v2_component_collapse_summary';

const CollapseSummary = ({
  children, className, prefixIcon, expandIcon, expanded: expandedProp,
  disabled, hiddenSeparator, onClick,
}) => {
  const [expanded, setExpanded] = React.useState(expandedProp);

  useEffect(() => {
    setExpanded(expandedProp);
  }, [expandedProp]);

  const handleClick = () => {
    if (!disabled) setExpanded(!expanded);
    onClick(!expanded);
  };
  const classes = classnames(
    classPrefix,
    {
      [`${classPrefix}--expanded`]: expanded,
      [`${classPrefix}--separator`]: expanded && !hiddenSeparator,
      [`${classPrefix}--disabled`]: disabled,
    },
    className,
  );
  return (
    <div className={classes} onClick={handleClick}>
      {prefixIcon && (
        <div className="prefix__wrapper">{prefixIcon}</div>
      )}
      <div className="wrapper">{children}</div>
      {expandIcon && (
        <div className="expand__wrapper">{expandIcon}</div>
      )}
    </div>
  );
};

CollapseSummary.propTypes = {
  /**
   * `CollapseSummary` content children view.
   */
  children: PropTypes.node,
  /**
   * Class name of root component.
   */
  className: PropTypes.string,
  /**
   * Tell if collapse summary if expanded.
   */
  expanded: PropTypes.bool,
  /**
   * Tell if collapse summary if disabled.
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, summary component will not show separator line bottom.
   */
  hiddenSeparator: PropTypes.bool,
  /**
   * Icon displayed prefix.
   */
  prefixIcon: PropTypes.node,
  /**
   * Icon displayed as expand.
   */
  expandIcon: PropTypes.node,
  /**
   * Called when click summary component.
   */
  onClick: PropTypes.func,
};

CollapseSummary.defaultProps = {
  children: null,
  className: null,
  prefixIcon: null,
  expandIcon: expandedIcon,
  expanded: false,
  disabled: false,
  hiddenSeparator: false,
  onClick: noop,
};

export default CollapseSummary;
