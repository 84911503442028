import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './drawer.scss';

export const KEYBOARD_ESC_CODE = 27;

export default class Drawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animation: false,
    };
  }

  componentDidMount() {
    document.onkeydown = (event) => {
      const e = event || window.event;
      if (e && e.keyCode === KEYBOARD_ESC_CODE) {
        if (this.props.open) {
          this.props.onOpenChange(false);
        }
      }
    };
  }

  static getDerivedStateFromProps(props, prevState) {
    const newState = {};
    if (props.open && !prevState.animation) {
      newState.animation = true;
    }
    return newState;
  }

  onOverlayClicked = () => {
    if (this.props.open) {
      this.props.onOpenChange(false);
    }
  }

  onDrawerTransitionEnd = () => {
    if (!this.props.open) {
      this.setState({ animation: false });
    }
  }

  render() {
    const {
      className, style, prefixCls, position, children, open, sidebarStyle, overlayStyle, sidebarClassName,
    } = this.props;
    const { animation } = this.state;

    const rootCls = {
      [className]: !!className,
      [prefixCls]: true,
      [`${prefixCls}-${position}`]: true,
      [`${prefixCls}-open`]: open || animation,
    };
    const rootProps = { style };
    const sidebarCls = classNames(`${prefixCls}-sidebar`, {
      [sidebarClassName]: !!sidebarClassName,
      [`${prefixCls}-open-sidebar`]: open,
    });
    const overlayCls = classNames(`${prefixCls}-overlay`, {
      [`${prefixCls}-overlay-show`]: open,
    });
    return (
      <div className={classNames(rootCls)} {...rootProps}>
        <div
          className={overlayCls}
          style={overlayStyle}
          role="presentation"
          onClick={this.onOverlayClicked}
        />
        <div
          className={sidebarCls}
          style={sidebarStyle}
          onTransitionEnd={this.onDrawerTransitionEnd}
        >
          {animation && children}
        </div>
      </div>
    );
  }
}

Drawer.propTypes = {
  /**
   * prefix class name for the Drawer
   */
  prefixCls: PropTypes.string,
  /**
   * add classname to Drawer
   */
  className: PropTypes.string,
  /**
   * add style to Drawer
   */
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  /**
   * add style to sidebar
   */
  sidebarStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  /**
   * add style to overlay
   */
  overlayStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  /**
   * main content to render
   */
  children: PropTypes.node,
  /**
   * boolean if sidebar should slide open
   */
  open: PropTypes.bool,
  /**
   * where to place the sidebar
   */
  position: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
  /**
   * callback called when the overlay is clicked
   */
  onOpenChange: PropTypes.func,
  /**
   * add classname to container
   */
  sidebarClassName: PropTypes.string,
};
Drawer.defaultProps = {
  className: null,
  style: null,
  prefixCls: 'v2_component_drawer',
  sidebarStyle: null,
  overlayStyle: null,
  open: false,
  position: 'left',
  onOpenChange: () => { },
  children: null,
  sidebarClassName: null,
};
