import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import Dropdown from '../../../Dropdown/Dropdown';
import Filter from '../../../Filter/Filter';
import utils from '../../utils';

const MultiTree = ({
  className,
  dropdownFixed,
  visible,
  filterKey,
  isBackendTree,
  renderOverlay,
  checkableNodes,
  defaultCheckedNodes,
  onVisibleChange,
  onRemove,
  filterLabel,
  disableDropdown,
}) => (
  <Dropdown
    fixed={dropdownFixed}
    transitionOffset={4}
    className={className}
    renderOverlay={renderOverlay}
    onVisibleChange={onVisibleChange}
    visible={visible}
    preventDropdown={disableDropdown}
  >
    <Filter
      type="category"
      open={visible}
      onClickIcon={onRemove}
      disablePrefixIcon={!!filterLabel}
      disableSuffixIcon={!!filterLabel}
    >
      {utils.getFilterLabel(filterKey, checkableNodes, defaultCheckedNodes, isBackendTree, filterLabel)}
    </Filter>
  </Dropdown>
);

MultiTree.propTypes = {
  className: PropTypes.string,
  dropdownFixed: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
  filterKey: PropTypes.string.isRequired,
  isBackendTree: PropTypes.bool,
  renderOverlay: PropTypes.func.isRequired,
  checkableNodes: PropTypes.arrayOf(PropTypes.any).isRequired,
  defaultCheckedNodes: PropTypes.arrayOf(PropTypes.any).isRequired,
  onVisibleChange: PropTypes.func,
  onRemove: PropTypes.func,
  filterLabel: PropTypes.string,
  disableDropdown: PropTypes.bool,
};

MultiTree.defaultProps = {
  className: '',
  dropdownFixed: false,
  isBackendTree: false,
  onVisibleChange: noop,
  onRemove: noop,
  filterLabel: '',
  disableDropdown: false,
};

export default React.memo(MultiTree);
