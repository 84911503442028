import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import Asset from '../../../../../../models/Asset';
import IconComponent from '../../../../../../components/Icon/Icon';
import SideModal from '../../../../../../components/SideModal/side-modal';
import Spinner from '../../../../../../components/Spinner/Spinner';
import { convert } from '../../../../../../lib/date';
import AssetPreviewerImage from './AssetPreviewerImage';
import AssetPreviewerVideo from './AssetPreviewerVideo';
import AssetPreviewerBundle from './AssetPreviewerBundle';
import AssetInfo from './AssetInfo';
import { getAssetOrientation, prettifySize } from '../utils';
import './AssetPreviewer.scss';

const getDimension = (asset) => {
  if (asset.get('exif.width') && asset.get('exif.height')) {
    return `${asset.get('exif.width')}x${asset.get('exif.height')}`;
  }
  return '-';
};

const ASSET_STATUS_TYPES = ['bundle_adaptive_creative'];
const ASSET_STATUS_EXTERNAL_SERVICES = ['jira_ticket'];

const MetricLabel = ({ label, value }) => (
  <div className="label-value-pair">
    <span className="p2">{label}</span>
    {': '}
    <span className="value">{value}</span>
  </div>
);

class AssetPreviewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  closeModal = () => {
    this.props.onClose();
  };

  componentDidMount = () => {
    this.setState({
      loading: false,
    });
  }

  refresh = () => {
    this.setState({
      loading: true,
    }, () => {
      this.setState({
        loading: false,
      });
    });
  }

  componentDidUpdate = (prevProps) => {
    const { asset: prevAsset } = prevProps;
    const { asset } = this.props;

    if (asset && prevAsset && asset.get('id') !== prevAsset.get('id')) {
      this.refresh();
    }
  }

  doShowAssetStatus = () => {
    const { asset } = this.props;

    return asset.get('status')
      && ASSET_STATUS_TYPES.includes(asset.get('type'))
      && asset.get('external_services')
      && asset.get('external_services').find((service) => ASSET_STATUS_EXTERNAL_SERVICES.includes(service.key));
  }

  render() {
    const {
      asset, onPrevAsset, onNextAsset,
    } = this.props;
    const { loading } = this.state;

    if (!asset) {
      return null;
    }

    const application = asset.get('application');

    return (
      <div className="asset-previewer">
        <SideModal
          isOpen
          overlayClassName="asset-previewer-overlay"
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          title=""
          closeTimeoutMS={200}
          onPrevious={onPrevAsset}
          onNext={onNextAsset}
          arrows
        >
          {loading && (
          <div className="asset-previewer-loading">
            <Spinner />
          </div>
          ) }
          { !loading && (
          <div className="modal-content">
            <div className="preview-content">
              <div className="media-preview-container">
                {this.renderPreviewer()}
              </div>
              <div className="info-container">
                <div className="application-and-asset-type-info">
                  <div className="application-info-container">
                    <div className="application-info">
                      <img className="thumbnail" src={application.get('store.thumbnail')} />

                      <div className={`platform ${application.get('platform')?.toLowerCase()}`}>
                        {IconComponent.forPlatform(application.get('platform'))}
                      </div>

                      <span className="p2">{application.get('name')}</span>
                    </div>
                  </div>

                  <AssetInfo asset={asset} onAssetNameChange={this.props.onAssetNameChange} />
                </div>

                <div className="asset-details">
                  <div className="info-column">
                    <MetricLabel label="Date added" value={convert(asset.get('created'), 'Do MMM \'YY')} />
                    <MetricLabel label="Orientation" value={getAssetOrientation(asset)} />
                    <MetricLabel label="Size" value={prettifySize(asset.get('exif.size'))} />
                  </div>
                  <div className="info-column">
                    <MetricLabel label="Added by" value={asset.get('account.name')} />
                    <MetricLabel label="Dimension" value={getDimension(asset)} />
                    <MetricLabel label="Original Name" value={asset.get('original_name')} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          ) }
        </SideModal>
      </div>
    );
  }

  renderPreviewer() {
    const { asset } = this.props;

    switch (asset.get('type')) {
      case 'image':
        return <AssetPreviewerImage asset={this.props.asset} />;
      case 'video':
        return <AssetPreviewerVideo asset={this.props.asset} />;
      case 'bundle_adaptive_creative':
        return <AssetPreviewerBundle asset={this.props.asset} />;
      default:
        return 'Unknown asset type';
    }
  }
}

AssetPreviewer.propTypes = {
  asset: PropTypes.instanceOf(Asset).isRequired,
  onClose: PropTypes.func.isRequired,
  onPrevAsset: PropTypes.func.isRequired,
  onNextAsset: PropTypes.func.isRequired,
  onAssetNameChange: PropTypes.func,
};

AssetPreviewer.defaultProps = {
  onAssetNameChange: noop,
};

export default AssetPreviewer;
