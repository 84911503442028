/* eslint-disable react/display-name */
import React, {
  useRef, useEffect, useState, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import QRCode from '../QRCode/QRCode';
import Copy from '../../V2/Copy/Copy';
import './CreativeIdWithQRCode.scss';

const CreativeIdWithQRCode = ({
  isMissionControl, creativeId, isQRCodeVisible, setIsQRCodeVisible, adjustLeftPosition, adjustTopPosition,
}) => {
  const creativeIdRef = useRef(null);
  const [modalRoot, setModalRoot] = useState(null);

  useEffect(() => {
    setModalRoot(creativeIdRef.current);
  }, []);

  const showQRCode = useCallback(() => setIsQRCodeVisible(true), []);
  const hideQRCode = useCallback(() => setIsQRCodeVisible(false), []);

  if (!isMissionControl) return <Copy value={creativeId} />;

  return (
    <div ref={creativeIdRef} onClick={showQRCode}>
      <span className={classNames('creative-id-with-qr-code', { 'creative-id-with-qr-code--show-qr-code': isQRCodeVisible })}>
        ID:
        {' '}
        {creativeId}
      </span>
      {isQRCodeVisible && creativeId
        && (
        <QRCode
          creativeId={creativeId}
          isVisible={isQRCodeVisible}
          hide={hideQRCode}
          modalRoot={modalRoot}
          adjustLeftPosition={adjustLeftPosition}
          adjustTopPosition={adjustTopPosition}
        />
        )}
    </div>
  );
};

CreativeIdWithQRCode.propTypes = {
  creativeId: PropTypes.string.isRequired,
  isMissionControl: PropTypes.bool,
  isQRCodeVisible: PropTypes.bool,
  setIsQRCodeVisible: PropTypes.func,
  adjustLeftPosition: PropTypes.number,
  adjustTopPosition: PropTypes.number,
};

CreativeIdWithQRCode.defaultProps = {
  isMissionControl: false,
  isQRCodeVisible: false,
  setIsQRCodeVisible: () => {},
  adjustLeftPosition: 0,
  adjustTopPosition: 0,
};

export default CreativeIdWithQRCode;
