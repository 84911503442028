/* eslint-disable react/display-name */
import React from 'react';
import { firstLetterUpper } from 'lib/lib';
import { convert } from 'lib/date';
import {
  getCtrlCampaignFiltersCache,
  getGrowCampaignFiltersCache,
  getCtrlCreativeFiltersCache,
  getGrowCreativeFiltersCache,
} from 'lib/cache/FiltersCache';
import Link from 'components/Link/Link';
import {
  Pulsation, TooltipCutoff,
} from 'components/V2';
import { TableNameColumn } from 'components/V2/List';
import { LTVMetrics, standardMetrics } from 'components/V2/Metrics/Metrics';
import { makeColumn, makeWithMetricColumns } from 'components/V2/List/Columns/columns';
import { makeListFilterQuery } from 'components/V2/List/ListFilter/helper';
import { defaultFilterCache as defaultCampaignFilterCache } from 'services/Templates/Campaigns/List/constants';
import { defaultFilterCache as defaultCreativeFilterCache } from 'services/Templates/Creatives/List/constants';
import {
  defaultSort,
  EXTRA_QUERY_KEYS,
  COLUMN_APP_STORE_ID,
  COLUMN_APPLICATION_NAME,
  COLUMN_CAMPAIGNS,
  COLUMN_CREATIVES,
  COLUMN_DATE_CREATED,
  COLUMN_DATE_UPDATED,
  COLUMN_APPLICATION_STATUS,
  TABLE_ACTION_EDIT_APPLICATION,
  TABLE_ACTION_VIEW_REPORTS,
  COLUMN_ACCOUNT,
} from './constants';

export const makeAttributeMetrics = (isMissionControl) => {
  const metrics = [
    {
      key: COLUMN_APP_STORE_ID,
      name: 'App Store ID',
      default: true,
    },
    {
      key: COLUMN_APPLICATION_STATUS,
      name: 'Status',
      default: true,
    },
    {
      key: COLUMN_CAMPAIGNS,
      name: 'Campaigns',
      default: true,
    },
    {
      key: COLUMN_CREATIVES,
      name: 'Creatives',
      default: true,
    },
    {
      key: COLUMN_DATE_UPDATED,
      name: 'Date Updated',
      default: true,
    },
    {
      key: COLUMN_DATE_CREATED,
      name: 'Date Created',
      default: true,
    },
  ];

  if (isMissionControl) {
    metrics.push({
      key: COLUMN_ACCOUNT,
      name: 'Account',
      default: false,
    });
  }

  return {
    name: 'Attributes',
    metrics,
  };
};

export const makeApplicationNameColumn = () => ({
  ...makeColumn({
    fixed: 'left',
    minWidth: 290,
    title: 'Application',
    accessor: COLUMN_APPLICATION_NAME,
  }),
  Cell: ({ original }) => {
    if (original.isTotalRow) {
      return 'Total';
    }
    const {
      id, name, platform, store,
    } = original.application;
    return (
      <TableNameColumn
        name={name}
        id={id}
        platform={platform}
        thumbnail={store.thumbnail}
        to={`/applications/${id}`}
      />
    );
  },
});

export const makeApplicationAccountColumn = () => ({
  ...makeColumn({
    title: 'Account',
    accessor: COLUMN_ACCOUNT,
  }),
  Cell: ({ original }) => {
    if (original.isTotalRow) {
      return '-';
    }
    const { account } = original.application;

    return (
      <TableNameColumn
        hidePlatform
        hideThumbnail
        id={account?.id}
        name={account?.name}
        to={`/accounts/${account?.id}`}
      />
    );
  },
});

export const makeAttributeColumns = (isMissionControl, authUser) => {
  const columns = [
    {
      ...makeColumn({
        title: 'Status',
        accessor: COLUMN_APPLICATION_STATUS,
      }),
      Cell: ({ original }) => {
        const { application, isTotalRow } = original;
        if (isTotalRow) {
          return '-';
        }
        const adminStatus = application.admin_status || 'approved';
        const statusMap = {
          approved: 'active',
          pending: 'test',
          rejected: 'inactive',
        };
        return (
          <div className="status_container">
            <Pulsation status={statusMap[adminStatus]} labelText={firstLetterUpper(adminStatus)} />
          </div>
        );
      },
    },
    {
      ...makeColumn({
        title: 'Campaigns',
        accessor: COLUMN_CAMPAIGNS,
      }),
      Cell: ({ original }) => {
        if (original.isTotalRow) {
          return '-';
        }
        const { id, name, campaign_count: campaignCount } = original.application;
        const setCampaignFilterCache = () => {
          const filterCache = isMissionControl
            ? getCtrlCampaignFiltersCache(authUser.id, defaultCampaignFilterCache)
            : getGrowCampaignFiltersCache(authUser.id, defaultCampaignFilterCache);
          filterCache.save({
            ...filterCache.get(),
            application: [{ id, name }],
          });
        };

        return (
          <TooltipCutoff
            title={campaignCount}
            titleWrapper={() => (
              campaignCount ? (
                <Link to="/campaigns" className="body__link" onClick={setCampaignFilterCache}>{campaignCount}</Link>
              ) : campaignCount
            )}
          />
        );
      },
    },
    {
      ...makeColumn({
        title: 'Creatives',
        accessor: COLUMN_CREATIVES,
      }),
      Cell: ({ original }) => {
        if (original.isTotalRow) {
          return '-';
        }
        const { id, name, creative_count: creativeCount } = original.application;
        const setCreativeFilterCache = () => {
          const filterCache = isMissionControl
            ? getCtrlCreativeFiltersCache(authUser.id, defaultCreativeFilterCache)
            : getGrowCreativeFiltersCache(authUser.id, defaultCreativeFilterCache);
          filterCache.save({
            ...filterCache.get(),
            application: [{ id, name }],
          });
        };
        return (
          <TooltipCutoff
            title={creativeCount}
            titleWrapper={() => (
              creativeCount ? (
                <Link to="/creatives" className="body__link" onClick={setCreativeFilterCache}>{creativeCount}</Link>
              ) : creativeCount
            )}
          />
        );
      },
    },
    {
      ...makeColumn({
        title: 'Date Created',
        accessor: COLUMN_DATE_CREATED,
      }),
      Cell: ({ original }) => {
        const { application, isTotalRow } = original;
        if (isTotalRow) {
          return '-';
        }
        return <TooltipCutoff title={convert(application.created, 'DD MMM YYYY')} />;
      },
    },
    {
      ...makeColumn({
        title: 'Date Updated',
        accessor: COLUMN_DATE_UPDATED,
      }),
      Cell: ({ original }) => {
        const { application, isTotalRow } = original;
        if (isTotalRow) {
          return '-';
        }
        return <TooltipCutoff title={convert(application.updated, 'DD MMM YYYY')} />;
      },
    },
    {
      ...makeColumn({
        title: 'App Store ID',
        accessor: COLUMN_APP_STORE_ID,
      }),
      Cell: ({ original }) => {
        if (original.isTotalRow) {
          return '-';
        }
        const { store } = original.application;
        return (
          <TooltipCutoff
            title={store.id}
            titleWrapper={() => (
              <div className="store_id_wrapper">
                <Link to={store.url} external>
                  <i className="material-icons">store</i>
                </Link>
                <span>{store.id}</span>
              </div>
            )}
          />
        );
      },
    },
  ];

  if (isMissionControl) {
    columns.push(makeApplicationAccountColumn());
  }

  return columns;
};

export const initMetrics = (metricCache, attributeMetrics) => {
  const value = metricCache.get();
  if (value) {
    return value;
  }

  const defaultMetrics = attributeMetrics.metrics.filter((m) => m.default);
  /*
  * Save default metrics if current user not do any selection.
  * This will prevent the metric dropdown render empty selection.
  */
  metricCache.save(defaultMetrics);

  return defaultMetrics;
};

export const makeGetApplicationsQuery = (
  caches,
  query = {},
) => makeListFilterQuery({
  dimension: 'application',
  caches,
  query,
  defaultSort,
  extraQueryKeys: EXTRA_QUERY_KEYS,
});

// TODO: some actions remains to be done, some of them need API support which are not ready yet.
// PR will be created first in case of block other tickets.
const tableActionList = [
  {
    key: TABLE_ACTION_EDIT_APPLICATION,
    action: ({ application, history }) => {
      history.push(`/applications/${application.id}`);
    },
    validate: ({ isAdmin }) => isAdmin,
  },
  {
    key: TABLE_ACTION_VIEW_REPORTS,
    action: ({ application, history }) => {
      history.push(`/applications/${application.id}`);
    },
    validate: ({ isAdmin }) => !isAdmin,
  },
];

export const makeTableActions = (isAdmin) => {
  const validActions = tableActionList.filter((a) => {
    const { validate } = a;
    return (
      !validate
      || validate({ isAdmin })
    );
  });

  return validActions.map((a) => a.key);
};

export const execTableAction = (key, row, history) => {
  const { application } = row;
  const context = tableActionList.find((a) => a.key === key);
  context.action({ application, history });
};

const attributeColumns = makeAttributeColumns;

export const attributeMetrics = makeAttributeMetrics;

export const allColumns = (admin, authUser) => makeWithMetricColumns(attributeColumns(admin, authUser));

export const columnsFromMetrics = (metrics, admin, authUser) => ([
  makeApplicationNameColumn(),
  ...metrics.map((m) => allColumns(admin, authUser).find((c) => (m.key === c.id))),
]);

export const metricsGroup = (isMissionControl) => [
  attributeMetrics(isMissionControl),
  standardMetrics,
  LTVMetrics,
];
