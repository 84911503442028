import React from 'react';
import { isUndefined } from '../../../lib/lib';
import Text from './Text';
import './text.scss';

class TextContainer extends React.Component {
  constructor(p) {
    super(p);
    this.state = { isFocused: false };
  }

  onFocus = () => {
    this.setState({ isFocused: true });
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  }

  onCopy = () => {
    const reportingKey = this.props.value;
    const keyNode = document.createElement('textArea');
    document.body.appendChild(keyNode);
    keyNode.value = reportingKey;
    keyNode.select();
    document.execCommand('copy');
    keyNode.className = 'copy_text';
  }

  onBlur = (value) => {
    this.setState({ isFocused: false });
    if (this.props.onBlur) {
      this.props.onBlur(value);
    }
  }

  render() {
    const { staticPlaceholder = null, textRef, ...restProps } = this.props;
    let { value } = this.props;
    if (isUndefined(value) || value === null) {
      value = '';
    }
    value = value.toString();
    let staticPlaceholderContent;
    if (staticPlaceholder) {
      if (staticPlaceholder === 'date') {
        staticPlaceholderContent = (<i className="material-icons">date_range</i>);
      } else if (staticPlaceholder === 'money') {
        staticPlaceholderContent = (<i className="material-icons">attach_money</i>);
      } else {
        staticPlaceholderContent = (<span>{staticPlaceholder}</span>);
      }
    }
    const props = {
      ...restProps,
      staticPlaceholder,
      staticPlaceholderContent,
      value,
      isFocused: this.state.isFocused,
      onBlur: this.onBlur,
      onFocus: this.onFocus,
      onCopy: this.onCopy,
    };
    return <Text ref={textRef} {...props} />;
  }
}

TextContainer.defaultProps = {
  onChange: () => {},
};

const Currency = (p) => <TextContainer noIcon numeric staticPlaceholder="money" {...p} />;
const Search = (p) => <TextContainer componentType="input" className="p6" noIcon={p.noIcon} icon="search" placeholder="Search..." {...p} />;
const PercentMultiplier = (p) => (
  <TextContainer noIcon staticPlaceholder={<i className="material-icons icon_percent_multiplier">shuffle</i>} {...p} />
);
const NameValidation = (p) => <TextContainer {...p} />;

TextContainer.NameValidation = NameValidation;
TextContainer.Currency = Currency;
TextContainer.Search = Search;
TextContainer.PercentMultiplier = PercentMultiplier;

export default TextContainer;

export {
  Search, NameValidation, PercentMultiplier, Currency,
};
