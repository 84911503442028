import moment from 'moment';
import { size } from 'lodash';
import { isEmptyArray } from 'lib/lib';
import { FILTER_KEY_DATE, FILTER_KEY_SEARCH } from 'app/constants/filters';

export const getCacheValue = (cache, key) => {
  const { date, search, ...combine } = cache.get();

  if (key === FILTER_KEY_DATE) {
    const { startDate, endDate } = date;
    return {
      ...date,
      startDate: moment(startDate),
      endDate: moment(endDate),
    };
  }

  if (key === FILTER_KEY_SEARCH) {
    return search;
  }

  return combine;
};

export const setCacheValue = (cache, value, key) => {
  const values = cache.get();

  switch (key) {
    case FILTER_KEY_DATE:
    case FILTER_KEY_SEARCH:
      cache.save({ ...values, [key]: value });
      break;
    default: {
      const { date, search } = values;
      cache.save({
        date,
        search,
        ...value,
      });
    }
      break;
  }
};

/**
 * This method will return an array with two element.
 * The first one is an object used for get campaigns api,
 * and the second one is a bool value that indicate whether the first element contains filter conditions.
 * The second element is used to help to tell whether the empty state component should be shown.
 */
export const makeListFilterQuery = ({
  dimension,
  caches,
  query,
  defaultSort,
  extraQueryKeys,
}) => {
  const {
    sortCache,
    filterCache,
    pageSizeCache,
  } = caches;

  const sortValue = sortCache.get()[0] || defaultSort;

  /**
   * `filterCache` only used for list filter container which includes three component:
   * search input, date picker and combined filter.
   */
  const {
    date,
    search,
    ...combine
  } = filterCache.get();

  const filters = Object.keys(combine)
    .filter((key) => !isEmptyArray(combine[key]))
    .reduce((prev, cur) => ({
      ...prev,
      [cur]: combine[cur].map((item) => item.id),
    }), {});

  const { startDate, endDate } = date;

  const finalQuery = {
    ...query,
    ...filters,
    dimension,

    // sortKey and isSortAsc are used for reporting api
    sortKey: sortValue.id,
    isSortAsc: !sortValue.desc,

    // sort is used for campaign/application/creative list api
    sort: {
      key: sortValue.id,
      asc: !sortValue.desc,
    },

    start: startDate,
    end: endDate,
    perPage: pageSizeCache.get(),
  };

  if (search) {
    finalQuery.search = search;
  }

  return [
    finalQuery,
    Object.keys(finalQuery).some((key) => extraQueryKeys.includes(key)),
  ];
};

export function cleanupNonExistentColumnsFromCache({
  metricCache,
  sortCache,
  columns,
}) {
  const columnKeys = columns.map(({ id }) => id);
  const columnsInCache = (metricCache.get() || []).filter(({ key }) => columnKeys.includes(key));
  if (size(columnsInCache) > 0) {
    metricCache.save(columnsInCache);
  } else {
    metricCache.remove();
  }

  const sortInCache = sortCache.get() || [];
  if (size(sortInCache) > 0 && !columnKeys.includes(sortInCache[0].id)) {
    sortCache.remove();
  }
}
