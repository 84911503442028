import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from 'react';
import { FILTER_KEY_CAMPAIGN, FILTER_KEY_APPLICATION } from 'app/constants/filters';
import { set } from '../../../../../../lib/session';
import Trend from '../../../../../Templates/Reports/Trend/Trend';
import Empty from '../../../../../Templates/Reports/Empty/Empty';
import FiltersCache from '../../../../../../lib/cache/FiltersCache';
import { metricsGroup } from '../../../../../../components/V2/Report/data/Metrics';
import { QUICK_KEY_LAST_7_DAYS } from '../../../../../../components/V2/Report/Calendar/PickerQuick';
import {
  getCacheValue,
  setCacheValue,
  combineRequestBody,
  getDefaultCacheValues,
} from '../../../../../Templates/Reports/helper';
import { reportsExport } from '../../../../../../app/graphql/utils/reporting';
import {
  DATE_KEY, COMPARE_KEY, COMBINE_KEY, GRAPHQL_QUERIES, REPORTS_KEY_REFRESH, REPORTS_KEY_RESET,
} from '../../../../../Templates/Reports/constant';
import CombinedFilters from '../../../../../../components/V2/Filters/CombinedFilters/CombinedFilters';
import { APPLICATION_CAMPAIGN_LIST_CACHE_KEY } from '../../../../../../lib/cache/constant';
import { CREATE_CAMPAIGN_SOURCE_KEY } from '../../../campaigns/Edit/constant';
import { getCampaignFlowStartPage } from '../../../../paths';
import DateRangePicker from '../Components/DateRangePicker/DateRangePicker';
import CampaignDataTable, { initMetrics } from '../Components/DataTable/CampaignDataTable/CampaignDataTable';
import { METRIC_KEY_ID } from '../Components/DataTable/constants';
import {
  initCheckedDate, initCheckedCombine, initCombineFilters, initCompareFilters, combineFiltersCacheData, detectDataEmpty,
} from '../helper';
import './campaignList.scss';

const dimension = 'campaign';

export const defaultCacheValues = {
  [COMPARE_KEY]: {},
  [COMBINE_KEY]: initCheckedCombine(dimension),
  [DATE_KEY]: initCheckedDate(QUICK_KEY_LAST_7_DAYS),
};

const CampaignList = ({
  node,
  authUser,
  match,
  history,
  actions: {
    addCampaign: addCampaignAction,
    changeCampaignStatus,
  },
  reportsCache,
}, ref) => {
  const trendRef = useRef();
  const tableRef = useRef();

  const cache = useMemo(() => new FiltersCache(authUser.id, APPLICATION_CAMPAIGN_LIST_CACHE_KEY, defaultCacheValues), [authUser.id]);

  const [dataEmpty, setDataEmpty] = useState(false);
  const [checkedDate, setCheckedDate] = useState(getCacheValue(cache, DATE_KEY));
  const [checkedCombine, setCheckedCombine] = useState(getCacheValue(cache, COMBINE_KEY));
  const [checkedCompare, setCheckedCompare] = useState(getCacheValue(cache, COMPARE_KEY));

  const refresh = useCallback(() => {
    trendRef.current.onLoadChartData();
    tableRef.current.onLoadTableData();
  }, []);

  const addCampaign = useCallback(() => {
    const path = getCampaignFlowStartPage(node);

    addCampaignAction({
      application: node,
      account: authUser.account,
      [CREATE_CAMPAIGN_SOURCE_KEY]: `/applications/${node.get('id')}`,
    });
    history.push(path);
  }, [node, addCampaignAction, authUser.account, history]);

  const viewReport = () => {
    const prevCache = getCacheValue(reportsCache, COMBINE_KEY) || getDefaultCacheValues()[COMBINE_KEY];
    const cacheData = combineFiltersCacheData(prevCache, node, checkedCombine);
    setCacheValue(reportsCache, COMBINE_KEY, cacheData);
    setCacheValue(reportsCache, DATE_KEY, checkedDate);
    set('cache', true);
    history.push('/reports');
  };

  const downloadCSV = useCallback(async () => {
    const { id } = match.params;
    const metrics = initMetrics(authUser.id);
    const body = combineRequestBody({
      checkedDate,
      checkedCompare,
      checkedCombine,
      dimensions: [dimension],
      filter_applications: [id],
      // reporting export api doesn't support id field
      metrics: metrics.map((metric) => metric.key).filter((key) => key !== METRIC_KEY_ID),
    });
    const resp = await reportsExport(body, dimension);
    window.open(resp.location, '_blank', 'noopener');
  }, [match.params, authUser.id, checkedDate, checkedCompare, checkedCombine]);

  const clearCache = useCallback(() => {
    cache.save(defaultCacheValues);
  }, [cache]);

  const executeDropdown = useCallback((e) => {
    if (REPORTS_KEY_RESET === e.key) {
      clearCache();
      setCheckedDate(defaultCacheValues[DATE_KEY]);
      setCheckedCompare(defaultCacheValues[COMPARE_KEY]);
      setCheckedCombine(defaultCacheValues[COMBINE_KEY]);
    }
    if (REPORTS_KEY_REFRESH === e.key) {
      refresh();
    }
  }, [clearCache, refresh]);

  const onFilterCampaign = (data) => {
    setCheckedCombine({
      ...initCheckedCombine,
      [FILTER_KEY_CAMPAIGN]: [{
        node,
        id: node.get('id'),
        name: node.get('name'),
        children: data,
      }],
    });
  };

  /**
   * Mostly these methods are exposed to use in parent component (CampaignCreativeList).
   * To keep parent component clearn and separate the logic I added the functions in this component.
   * Same as CreativeList component.
  */
  useImperativeHandle(ref, () => ({
    clearCache,
    addCampaign,
    refresh,
    viewReport,
    downloadCSV,
    executeDropdown,
  }));

  const onCombineChange = useCallback((e) => {
    setCheckedCombine(e);
    setCacheValue(cache, COMBINE_KEY, e);
  }, [cache]);

  const onCompareChange = useCallback((e) => {
    setCheckedCompare(e);
    setCacheValue(cache, COMPARE_KEY, e);
  }, [cache]);

  const onDateChange = useCallback((e) => {
    setCheckedDate(e);
    setCacheValue(cache, DATE_KEY, e);
  }, [cache]);

  useEffect(() => {
    const { account } = authUser;
    const application = node.get('id');
    detectDataEmpty(dimension, { account, application })
      .then((empty) => {
        setDataEmpty(empty);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser.account]);

  return (
    <div className="campaign_list_container">
      <div className="filter_container">
        <CombinedFilters
          dropdownFixed
          onChange={onCombineChange}
          filters={initCombineFilters(node, dimension)}
          defaultCheckedFilters={checkedCombine}
          checkedFilters={checkedCombine}
        />
        <DateRangePicker checkedDate={checkedDate} onChange={onDateChange} />
      </div>
      {dataEmpty ? <Empty onAdd={addCampaign} description="Your application looks a little empty. Let’s add our first campaign." /> : (
        <div className="content">
          <Trend
            ref={trendRef}
            checkedDate={checkedDate}
            checkedCombine={checkedCombine}
            checkedCompare={checkedCompare}
            compareFilters={initCompareFilters(dimension)}
            metricOptionGroup={[metricsGroup[0]]}
            searchParams={{ application: node.get('id') }}
            requestParams={{ [GRAPHQL_QUERIES[FILTER_KEY_APPLICATION].filterQuery]: [node.get('id')] }}
            onCompareChange={(key, data) => onCompareChange({ key, data })}
            onCompareClear={() => onCompareChange(defaultCacheValues[COMPARE_KEY])}
            dimension={dimension}
          />
          <CampaignDataTable
            ref={tableRef}
            node={node}
            dimension={dimension}
            checkedDate={checkedDate}
            checkedCombine={checkedCombine}
            changeCampaignStatus={changeCampaignStatus}
            onFilterCampaign={onFilterCampaign}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(forwardRef(CampaignList));
