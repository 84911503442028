import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import classNames from 'classnames';
import Checkbox from '../../Checkbox/Checkbox';
import Tooltip from '../../Tooltip/Tooltip';

const DisabledSelector = ({ prefixCls, disabled }) => {
  const [visible, setVisible] = React.useState(false);
  let title = 'Bulk Edit';
  let body = 'You are limited to selecting up to 10 \n rows maximum to bulk edit at once.';
  if (!disabled) {
    title = 'Disabled';
    body = 'Cannot attach creatives on old SKAdnetwork Campaigns';
  }
  return (
    <Tooltip
      title={title}
      body={body}
      onVisibleChange={setVisible}
    >
      <i className={classNames(`${prefixCls}-selector-disabled`, 'material-icons')}>
        {visible ? 'block' : 'check_box_outline_blank'}
      </i>
    </Tooltip>
  );
};

export const defaultSelectorRender = ({
  indeterminate = false,
  checked,
  onSelect,
  row,
  prefixCls,
  disabled,
  disableUnselected,
}) => (
  <div className={`${prefixCls}-selector-checkbox-wrapper`}>
    {
      disabled || (disableUnselected && !checked)
        ? <DisabledSelector prefixCls={prefixCls} disabled={disabled} />
        : (
          <Checkbox
            className={`${prefixCls}-selector-checkbox`}
            indeterminate={indeterminate}
            checked={checked}
            disabled={disabled}
            onChange={() => onSelect(row)}
          />
        )
    }
  </div>
);

export default (Component) => {
  class SelectorTable extends React.Component {
    getSelectedRowIndex = (row) => {
      const { rowSelectKey, selectedRows } = this.props;
      let { rowSelector } = this.props;
      if (!(rowSelector instanceof Function)) {
        rowSelector = (r) => r[rowSelectKey];
      }
      return selectedRows.findIndex((selectedRow) => Object.is(rowSelector(selectedRow), rowSelector(row)));
    };

    onRowSelect = (row) => {
      const { onSelectedChange, selectedRows: selectedRowsFromProps } = this.props;
      const selectedRows = [...selectedRowsFromProps];
      const rowIndex = this.getSelectedRowIndex(row);
      const checked = rowIndex === -1;
      if (checked) {
        selectedRows.push(row);
      } else {
        selectedRows.splice(rowIndex, 1);
      }
      onSelectedChange(selectedRows, row);
    }

    onToggleAll = (checked) => {
      const { onSelectedChange, data } = this.props;
      onSelectedChange(checked ? [...data] : []);
    };

    isUnselected = (row) => {
      const { disableRowSelector } = this.props;
      return disableRowSelector(row);
    }

    renderRow = (row) => {
      const {
        renderRow,
        prefixCls,
        maxSelected,
        selectedRows,
        disableUnselected,
      } = this.props;

      // unselected row
      if (this.isUnselected(row)) {
        return null;
      }
      const rowIndex = this.getSelectedRowIndex(row);
      const checked = rowIndex !== -1;
      const props = {
        row,
        checked,
        onSelect: this.onRowSelect,
        prefixCls,
        disabled: false,
        disableUnselected,
      };
      // unselected row
      if (this.isUnselected(row)) {
        props.disabled = true;
      }
      // when `maxSelected` and selectedRows equal `maxSelected`, the unchecked row should uncheckable
      if (maxSelected > 0 && !checked && maxSelected === selectedRows.length) {
        props.disabled = true;
      }
      return renderRow(props);
    };

    renderAll = () => {
      const {
        renderAll,
        data,
        selectedRows,
        prefixCls,
        maxSelected,
      } = this.props;
      const props = {
        prefixCls,
      };
      // when `maxSelected` hide select all
      if (maxSelected > 0) {
        return null;
      }
      if (selectedRows.length === 0) {
        props.checked = false;
      } else if (selectedRows.length === data.length) {
        props.checked = true;
      } else {
        props.indeterminate = true;
      }
      props.onSelect = () => this.onToggleAll(!props.checked);
      return renderAll(props);
    };

    render() {
      const { columns, ...rest } = this.props;
      const selectColumn = {
        Header: this.renderAll,
        Cell: (ci) => this.renderRow(ci.original),
        width: 53,
        sortable: false,
        resizable: false,
        fixed: 'left',
        style: { textAlign: 'center' },
      };
      const _columns = [selectColumn, ...columns];
      return <Component {...rest} columns={_columns} />;
    }
  }
  SelectorTable.propTypes = {
    columns: PropTypes.arrayOf(
      PropTypes.object,
    ).isRequired,
    selectedRows: PropTypes.arrayOf(
      PropTypes.object,
    ).isRequired,
    rowSelectKey: PropTypes.string,
    rowSelector: PropTypes.func,
    onSelectedChange: PropTypes.func,
    maxSelected: PropTypes.number,
    renderRow: PropTypes.func,
    renderAll: PropTypes.func,
  };
  SelectorTable.defaultProps = {
    rowSelectKey: null,
    rowSelector: null,
    onSelectedChange: noop,
    maxSelected: 0,
    renderRow: defaultSelectorRender,
    renderAll: defaultSelectorRender,
  };

  return SelectorTable;
};
