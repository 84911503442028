import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import MetricMenu from '../../../../components/V2/Report/MetricMenu/MetricMenu';
import { getMetricsGroupByTab, getMetricsListByTab } from '../../../../components/V2/Report/data/Metrics';
import { REPORT_PANEL_METIC_KEY } from '../../../../lib/cache/constant';
import Tooltip from '../../../../components/V2/Tooltip/Tooltip';
import MetricCache from '../../../../lib/cache/MetricCache';
import { returnMetricsList, getMetricData } from '../helper';
import './aggregates.scss';

const prefixCls = 'reports-aggregate';

export const metricType = REPORT_PANEL_METIC_KEY;

const Aggregate = ({
  total,
  checkedMetric,
  onPanelClick,
  onMetricsVisible,
  panelMetricsKey = metricType,
  currentTab,
}) => {
  const authUser = useSelector((state) => state.authUser);
  const [metrics, setMetrics] = useState([]);
  const metricOptionGroup = getMetricsGroupByTab(currentTab);

  const onMetricsChange = (checkedMetrics) => {
    setMetrics(returnMetricsList({
      metricsData: checkedMetrics,
      tab: currentTab,
    }));
  };
  useEffect(() => {
    const cacheInstance = new MetricCache(authUser.id, panelMetricsKey);
    let checkedMetrics = cacheInstance.get();
    if (!checkedMetrics) {
      checkedMetrics = getMetricsListByTab(currentTab).filter((i) => i.default);
    }
    setMetrics(returnMetricsList({
      metricsData: checkedMetrics,
      tab: currentTab,
    }));
  }, [authUser.id, panelMetricsKey, currentTab]);
  useEffect(() => {
    if (metrics.length) {
      onPanelClick(metrics[0]);
    }
    onMetricsVisible(!!metrics.length);
  }, [metrics, onMetricsVisible, onPanelClick]);
  return (
    <div className={`${prefixCls}`}>
      <div className={`${prefixCls}-panels`}>
        {metrics.map((metric) => (
          <div
            className={classNames({
              [`${prefixCls}-panels-item`]: true,
              active: metric.key === checkedMetric,
            })}
            key={metric.key}
            onClick={() => onPanelClick(metric)}
          >
            <div className={`${prefixCls}-panels-item-container`}>
              <div className={`${prefixCls}-panels-item-wrapper`}>
                <div className={`${prefixCls}-panels-item-content`}>
                  <div className={`${prefixCls}-panels-item-content-title`}>{metric.name}</div>
                  <div className={`${prefixCls}-panels-item-content-value`}>
                    {metric.render(getMetricData(total, metric))}
                  </div>
                </div>
                <div className={`${prefixCls}-panels-item-tooltip`}>
                  <Tooltip className="v2_reporting_page_tooltip" title={metric.name} body={metric.content}>
                    <i className="material-icons">help_outline</i>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={`${prefixCls}-setting`}>
        <MetricMenu
          maxSelected={10}
          showSelectAll={false}
          metricType={panelMetricsKey}
          metricUserId={authUser.id}
          metricGroup={metricOptionGroup}
          onMetricsChange={onMetricsChange}
        />
      </div>
    </div>
  );
};

export default Aggregate;
