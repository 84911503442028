import React from 'react';
import PropTypes from 'prop-types';
import LoadingImg from 'assets/img/loading.svg';
import './spinner.scss';

const prefixCls = 'v2_component_spinner';

const Spinner = ({ showText, showBar }) => (
  <div className={prefixCls}>
    <img className={`${prefixCls}-icon`} src={LoadingImg} alt="loading" />
    {showText && <div className={`${prefixCls}-text`}>Just a second, loading data...</div>}
    {showBar && <div className={`${prefixCls}-bar`} />}
  </div>
);

Spinner.propTypes = {
  showText: PropTypes.bool,
  showBar: PropTypes.bool,
};

Spinner.defaultProps = {
  showText: true,
  showBar: true,
};

export const ListSpinner = () => (
  <div className="v2_component_list_spinner">
    <Spinner />
  </div>
);

export default Spinner;
