import React, {
  useState, useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import classnames from 'classnames';
import useDebounce from '../../../lib/hooks/useDebounce';
import AdminAccount from '../../../models/AdminAccount';
import AdminApplication from '../../../models/AdminApplication';
import Campaign from '../../../models/Campaign';
import InfoBar from '../../InfoBar/InfoBar';
import AccountFinder from '../../AccountFinder/AccountFinderContainer';
import ApplicationFinder from '../../ApplicationFinder/ApplicationFinder';
import RadioGroup from '../Radio/RadioGroup';
import Collapse from '../Collapse/Collapse';
import CollapseSection from '../Collapse/CollapseSection';
import {
  KEY_NO_TARGETING, KEY_WHITELIST, KEY_BLACKLIST, COPY_SUCCESS_INFO,
} from './constants';
import EditArea from './EditArea/EditArea';
import { getDedupeNewIds } from './targetingHelper';
import AppTable from './AppTable/AppTable';
import Message from '../FlagMessage/Message';
import './targeting.scss';

const classPrefix = 'views__page__targeting__overview';

const Targeting = ({
  adminOnly,
  id,
  platform,
  searchable,
  targeting,
  template,
  resource,
  onPageContinue = noop,
  type,
  onTypeChange = noop,
  onDataChange = noop,
  getIds = () => [],
  fetchPasteData,
  getAvailableApps,
  excludedAccounts,
  excludedApplications,
  radioGroup = [],
  onError,
}) => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [idString, setIdString] = useState('');
  const [apps, setApps] = useState([]);
  const [errorParams, setErrorParams] = useState({});

  useEffect(() => {
    onPageContinue({ pageContinue: type === KEY_NO_TARGETING || !hasError });
    // --> potential bug inside this useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasError, type]);

  useEffect(() => {
    const ids = getIds();
    (async () => {
      if (ids.length) {
        const result = await fetchPasteData(ids);
        setInitialLoading(false);
        setApps(result);
      } else {
        setInitialLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRadioChange = useCallback((item) => {
    onTypeChange(item.id);
  }, [onTypeChange]);

  const onPaste = useCallback(async (ids) => {
    const appIds = getIds();
    const newIds = getDedupeNewIds(appIds, ids);
    setLoading(true);
    const result = await fetchPasteData(newIds);
    setLoading(false);
    const newAvailableApps = getAvailableApps(result);
    const newApps = [...newAvailableApps, ...apps];
    setApps(newApps);
    onDataChange(newApps);
    setIdString('');
    // --> potential bug inside this useCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resource, apps]);

  const onSelect = useCallback(async (data) => {
    const appIds = getIds();
    if (!data || appIds.includes(data.get('id'))) {
      return;
    }
    const newApps = [{ id: data.get('id'), name: data.get('name'), is_deleted: data.get('is_deleted') }, ...apps];
    setApps(newApps);
    onDataChange(newApps);
    setIdString('');
    // --> potential bug inside this useCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resource, apps]);

  const handleDeleteAll = useCallback((listData) => {
    const restList = apps.filter((app) => !listData.some((x) => x.id === app.id));
    onDataChange(restList);
    setApps(restList);
    // --> potential bug inside this useCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apps]);

  const handleDelete = useCallback(({ id: deleteId }) => {
    const arr = [...apps];
    const idx = arr.findIndex((x) => x.id === deleteId);
    arr.splice(idx, 1);
    onDataChange(arr);
    setApps(arr);
    // --> potential bug inside this useCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apps]);

  const handleError = useCallback((isErrorExist, error) => {
    setErrorParams({ isErrorExist, error });
  }, []);

  useDebounce(() => {
    const { isErrorExist, error } = errorParams;
    setHasError(isErrorExist);
    const errorMessage = error || null;
    onError(errorMessage);
    onPageContinue({ pageContinue: !isErrorExist });
  }, 300, [resource, errorParams]);

  return (
    <CollapseSection
      adminOnly={adminOnly}
      className={classPrefix}
      title={`${template.headerTitle} (${type !== KEY_NO_TARGETING ? getIds().length : 0})`}
      description={template.headerDesc}
      id={id}
    >
      {adminOnly && template.warningInfo && (
        <Collapse
          className="warning-collapse"
          state="warning"
          rows={[{
            summary: 'Good to Know',
            prefixIcon: <i className="material-icons">info_outline</i>,
            detail: template.warningInfo,
          }]}
        />
      )}
      {
        template.headsUp && <InfoBar type="info" text={template.headsUp} />
      }
      <div className="adaptor-wrapper">
        <div className="info-container">
          <p className="p1">{template.radioTitle}</p>
          {/* eslint-disable-next-line react/no-danger */}
          <div className="p6 description" dangerouslySetInnerHTML={{ __html: template.radioDesc }} />
        </div>
        <div className="pick-container">
          <RadioGroup
            checkedId={type}
            rows={radioGroup}
            onChange={handleRadioChange}
          />
          <div className={classnames({
            [`${classPrefix}-textarea-wrapper`]: type === KEY_NO_TARGETING,
          })}
          >
            {
              searchable && targeting === 'publisher' && (
                <ApplicationFinder
                  onSelect={onSelect}
                  accountType="publisher"
                  platform={platform}
                  type="publisher"
                  placeholder="Search applications by name or by id..."
                  excludedApplications={excludedApplications}
                  excludedAccounts={excludedAccounts}
                />
              )
            }
            {
              searchable && targeting === 'accounts' && (
                <AccountFinder
                  onSelect={onSelect}
                  accountTypes={['publisher', 'both']}
                  placeholder="Search accounts..."
                  excludedAccounts={excludedAccounts}
                />
              )
            }
            <EditArea
              placeholder={template.inputPlaceholder}
              value={idString}
              platform={platform}
              onChange={setIdString}
              loading={loading}
              onSave={onPaste}
              onError={handleError}
            />
          </div>
        </div>
      </div>
      <div
        className={classnames('table-container', {
          hidden: type === KEY_NO_TARGETING || apps.length === 0,
        })}
      >
        <div className="divider" />
        <AppTable
          template={template}
          data={apps}
          loading={initialLoading}
          onDeleteAll={handleDeleteAll}
          onDelete={handleDelete}
          onCopy={() => Message.success(COPY_SUCCESS_INFO)}
        />
      </div>
    </CollapseSection>
  );
};

Targeting.propTypes = {
  // Is admin only or not
  adminOnly: PropTypes.bool,
  // Support search or not
  searchable: PropTypes.bool,
  // Target type
  targeting: PropTypes.oneOf(['publisher', 'accounts']).isRequired,
  // Template string
  template: PropTypes.objectOf(PropTypes.any).isRequired,
  // Resource
  resource: PropTypes.oneOfType([
    PropTypes.instanceOf(AdminAccount),
    PropTypes.instanceOf(AdminApplication),
    PropTypes.instanceOf(Campaign),
  ]).isRequired,
  // Target radio type
  type: PropTypes.oneOf([KEY_NO_TARGETING, KEY_WHITELIST, KEY_BLACKLIST]).isRequired,
  // Radio type change handler
  onTypeChange: PropTypes.func.isRequired,
  // Data change handler
  onDataChange: PropTypes.func.isRequired,
  // Get data info by paste ids
  fetchPasteData: PropTypes.func.isRequired,
  // Get the ids of campaign
  getIds: PropTypes.func.isRequired,
  getAvailableApps: PropTypes.func.isRequired,
  // Radio group config
  radioGroup: PropTypes.arrayOf(PropTypes.any).isRequired,
  // Self-serve side page continue
  onPageContinue: PropTypes.func,
  excludedAccounts: PropTypes.arrayOf(PropTypes.string),
  excludedApplications: PropTypes.arrayOf(PropTypes.string),
  onError: PropTypes.func,
  // Id for targeting
  id: PropTypes.string,
  // Platform string for application search and error collapse display
  platform: PropTypes.string,
};

Targeting.defaultProps = {
  adminOnly: false,
  searchable: true,
  onPageContinue: noop,
  excludedAccounts: [],
  excludedApplications: [],
  onError: noop,
  id: '',
  platform: '',
};

export default Targeting;
