import React from 'react';
import { isArray, isEmptyArray } from '../../../lib/lib';
import { isAdminService } from '../../../lib/serviceType';
import Pagination from '../../../templates/pagination/pagination';
import Table from '../../../templates/table/table';
import FiltersContainer from '../../ListFilters/Filters/FiltersContainer';
import { returnfitlerQueryParams } from '../../ListFilters/Filters/help';
import { getPageTitle } from './helper';

const ListView = ({
  acctHier,
  Filter = null,
  filters,
  loading = false,
  pagination = {},
  refreshList = null,
  results = null,
  renderResult = null,
  tableTitles = null,
  router = {},
  location = {},
  history,
  includeStatus = false,
  showTitles = false,
  includeActions = true,
  rowHeight = 128,
  noResults = false,
  searchPlaceholderText = 'Search...',
  defaultCellWidth = 120,
  renderInstead = null,
  onFilterChange,
  ...rest
} = {}) => (
  <div className="components__templates__list-view">
    <div className="top_bar">
      {isAdminService() && <p className="h4">{getPageTitle(history)}</p>}
      {!Filter && (
        <FiltersContainer
          onFilterChange={onFilterChange}
          acctHier={acctHier}
          filters={filters}
          searchPlaceholderText={searchPlaceholderText}
          pagination={pagination}
          refreshList={refreshList}
          router={router}
          location={location}
          history={history}
          {...rest}
        />
      )}
      { Filter && <Filter router={router} refreshList={refreshList} /> }
    </div>
    {!renderInstead && (
      <div className="results-list">
        <Table
          {...{
            defaultCellWidth,
            includeActions,
            includeStatus,
            noResults,
            rowHeight,
            showTitles,
          }}
          loading={loading || !isArray(results)}
          titles={tableTitles}
          rows={isArray(results) && !isEmptyArray(results) && results.map((result) => renderResult(result))}
        />
      </div>
    )}
    {renderInstead && (
      renderInstead
    )}
    {(!noResults || !isEmptyArray(results)) && (
      <Pagination
        page={pagination.page}
        pages={pagination.pages}
        perPage={pagination.perPage}
        onChangePage={(page, perPage) => refreshList(returnfitlerQueryParams({ page, perPage, ...router.queryParams }))}
      />
    )}
  </div>
);

export default ListView;
