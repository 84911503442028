import React from 'react';
import triggerConfirm from 'components/Modals/ConfirmAction/triggerConfirm';
import Dropdown from 'components/V2/Dropdown/Dropdown';
import { DropdownMenuItem } from 'components/V2/Dropdown/DropdownMenu/DropdownMenu';
import ServingStatusContainer from 'components/Creatives/ServingStatus/ServingStatus';
import CreativeStatus from 'services/Templates/CreativeBuilder/CreativeTable/CreativeStatus';
import CreativePreview from 'services/Templates/CreativeBuilder/CreativeTable/CreativePreview';
import previewCreative from 'components/Creatives/Preview/previewCreative';
import TooltipCutoff from 'components/V2/Tooltip/TooltipCutoff';
import BaseTable from 'services/Templates/CreativeBuilder/BaseTable/BaseTable';
import './creativeTable.scss';
import { execChangeCreativeStatus } from 'services/advertiser/views/creatives/List/ListContainer';
import { CREATIVE_FORMAT_MAP } from 'app/constants/campaign';
import { canModifyCreatives } from '../../../../lib/helpers/authUser';

const makeColumn = (title, accessor, width = 0, fixed = null, sortable = false) => {
  const opts = {
    Header: title,
    id: accessor,
    accessor: (c) => c.raw(accessor),
  };
  if (width) {
    opts.minWidth = width;
  }
  if (fixed) {
    opts.fixed = fixed;
  }
  if (sortable) {
    opts.sortable = sortable;
  }
  return opts;
};

const prefixCls = 'creative__table';

const getColumns = (campaign, canEdit, deletable, selectedIds, selectCreative, isShowEditCreativeAction) => () => {
  const showTooltip = canModifyCreatives();
  const columns = [
    {
      ...makeColumn('Status', 'status', 128, null, true),
      className: `${prefixCls}-table-status`,
      Cell: function Cell({ original }) {
        return (
          <ServingStatusContainer
            mcList={false}
            creative={original}
            changeStatus={() => {
              triggerConfirm({
                type: 'ACTIVATE_CREATIVE_CONFIRM_ACTION',
                header: 'Activate Creative',
                message: 'Are you sure you want to activate this creative?',
                name: original.get('name'),
                onConfirm: () => execChangeCreativeStatus.action({ creative: original, status: 'activate' }),
              });
            }}
            showTooltip={showTooltip}
            resource="Creative"
          >
            <CreativeStatus creative={original} />
          </ServingStatusContainer>
        );
      },
    },
    {
      ...makeColumn('Creative', 'name', 280, null, true),
      Cell: function Cell({ original }) {
        return (
          <CreativePreview
            canEdit={canEdit}
            creative={original}
            previewCreative={(creative) => previewCreative(creative, isShowEditCreativeAction)}
            prefixCls={prefixCls}
          />
        );
      },
    },
  ];

  columns.push({
    ...makeColumn('Creative Format', 'template.format', 0, null, true),
    Cell: function Cell({ original }) {
      const format = original.get('template.format');
      const formatText = CREATIVE_FORMAT_MAP[format]?.text || '';
      return <TooltipCutoff title={formatText}>{formatText}</TooltipCutoff>;
    },
  });

  columns.push({
    ...makeColumn('Creative Type', 'template.name', 0, null, true),
    Cell: function Cell({ original }) {
      const templateName = original.get('template.name');
      return <TooltipCutoff title={templateName}>{templateName}</TooltipCutoff>;
    },
  });

  if (campaign?.supportsSKANABTesting() && campaign?.get('creative_type') === 'fullscreen') {
    columns.push({
      ...makeColumn('Orientation', 'video.orientation', 0, null, true),
      Cell: function Cell({ original }) {
        const orientation = original.get('video.orientation');
        return <TooltipCutoff title={orientation}>{orientation}</TooltipCutoff>;
      },
    });
  }

  columns.push(
    {
      ...makeColumn('Language', 'language.name', 0, null, true),
      Cell: function Cell({ original }) {
        const language = original.get('language.name');
        return <TooltipCutoff title={language}>{language}</TooltipCutoff>;
      },
    },
    {
      ...makeColumn('Date Created', 'created', 0, null, true),
      Cell: function Cell({ original }) {
        const createdDate = original.getDate('created', 'DD MMM YYYY');
        return <TooltipCutoff title={createdDate}>{createdDate}</TooltipCutoff>;
      },
    },
  );

  if (deletable) {
    columns.push(
      {
        ...makeColumn('...', 'delete', 40, null, false),
        Cell: function Cell({ original }) {
          const onClick = () => {
            const newSelectedIds = selectedIds
              .filter((creativeId) => creativeId !== original.get('id'));

            selectCreative(newSelectedIds);
          };
          return <i className="material-icons" onClick={onClick}>delete</i>;
        },
        Header: function Header() {
          const onClick = () => {
            selectCreative([]);
          };
          return (
            <Dropdown
              renderOverlay={() => (
                <DropdownMenuItem onClick={onClick}>Detach All</DropdownMenuItem>
              )}
              placement="right"
              closeOnClick
            >
              <i className="material-icons">more_vert</i>
            </Dropdown>
          );
        },
      },
    );
  }

  return columns;
};

const itemNameMatchFn = (resource, searchTerm) => {
  const processForMatch = (name) => name?.toLocaleLowerCase() || '';

  const name = processForMatch(resource.get('name'));
  const vungleName = processForMatch(resource.get('vungle_name'));
  const processedSearchTerm = processForMatch(searchTerm);

  return name.includes(processedSearchTerm) || vungleName.includes(processedSearchTerm);
};

const CreativeTable = ({
  campaign,
  data = [],
  loading = false,
  selectedIds = [],
  canEdit = false,
  hideFilter,
  hideHeader,
  selectable,
  filterTypes,
  placeholder,
  shouldShowRemoveAll,
  upgradeSKANComponent,
  selectCreative = () => {},
  disableRowSelector = () => {},
  disableUnselected = false,
  disabledOptions,
  hiddenOptions,
  filterLabels,
  disabledDropDownFilters,
  renderRadio,
  deletable,
  hideOnNoData,
  onClearAll,
  isShowEditCreativeAction = true,
}) => (
  <BaseTable
    selectable={selectable}
    hideFilter={hideFilter}
    hideHeader={hideHeader}
    data={data}
    loading={loading}
    selectedIds={selectedIds}
    selectResource={selectCreative}
    getColumns={getColumns(campaign, canEdit, deletable, selectedIds, selectCreative, isShowEditCreativeAction)}
    disableRowSelector={disableRowSelector}
    filterTypes={filterTypes}
    placeholder={placeholder}
    shouldShowRemoveAll={shouldShowRemoveAll}
    itemNameMatchFn={itemNameMatchFn}
    upgradeSKANComponent={upgradeSKANComponent}
    disableUnselected={disableUnselected}
    disabledOptions={disabledOptions}
    filterLabels={filterLabels}
    disabledDropDownFilters={disabledDropDownFilters}
    hiddenOptions={hiddenOptions}
    renderRadio={renderRadio}
    hideOnNoData={hideOnNoData}
    onClearAll={onClearAll}
  />
);

export default CreativeTable;
