import React from 'react';
import Button from '../Button/Button';
import './confirm.scss';

const defaultMessage = 'Are you sure you want to complete this action?';
const Confirm = ({
  header = '',
  name = '',
  message = defaultMessage,
  confirmButtonProps = {},
  onCancel = () => {},
  onConfirm = () => {},
}) => (
  <div className="body__confirm">
    <div className="body__confirm__content" onClick={onCancel}>
      <div className="body__confirm__content__container" onClick={(e) => { e.stopPropagation(); }}>
        <div className="body__confirm__content__container__header">
          <span>{header}</span>
        </div>
        <div className="body__confirm__content__container__body">
          <p className="h3">{name}</p>
          <p className="p2">{message}</p>
        </div>
        <div className="body__confirm__content__container__buttons">
          <Button cancel onClick={onCancel}>No</Button>
          <Button {...confirmButtonProps} onClick={onConfirm}>Yes</Button>
        </div>
      </div>
    </div>
  </div>
);

export default Confirm;
