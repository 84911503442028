import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { config } from 'app/app';
import Campaign from 'models/Campaign';
import {
  isCampaignObjectiveCPEIap,
  isCampaignObjectiveDynamic,
} from 'lib/capabilities/campaigns';
import {
  DYNAMIC_GEOS_REQUEST_START,
  DYNAMIC_GEOS_REQUEST_END,
  pieCountryStatsForTargetCPEEventKey,
  dynamicGeosKey,
} from 'app/constants/campaign';
import {
  DYNAMIC_GEO_STATES,
  getDynamicGeoStatus,
  getDynamicTierGeos,
  getTierTreeData,
  getDynamicGeoStatusForEvent,
} from 'lib/capabilities/dynamic-geos';
import Pulsation from 'components/V2/Pulsation/Pulsation';
import { sortByName } from 'templates/MultiTreeSelector/treeUtils';
import MultiTreeSelector from 'templates/MultiTreeSelector/MultiTreeSelector';
import './countrySelector.scss';

const { countries, regions } = config.get('countryData');

export const getTypicalTreeData = (availableCountries) => {
  const regionsInTree = cloneDeep(regions);
  const result = regionsInTree.map((region) => ({ ...region, children: [] }));
  availableCountries.forEach((country) => {
    const region = result.find((r) => r.id === country.region);
    if (region && region.children) {
      region.children.push({
        ...country, parentId: region.id,
      });
    }
  });
  return result;
};

function getTierUiStatus(isCPE, node) {
  const statusMappings = {
    [DYNAMIC_GEO_STATES.VALID]: 'active',
    [DYNAMIC_GEO_STATES.INVALID]: 'inactive',
  };
  const status = isCPE ? getDynamicGeoStatusForEvent(node.children) : getDynamicGeoStatus(node.children);
  return statusMappings[status];
}

export function TreeTitle({ isDynamicCampaign, isCPE, node }) {
  const prefix = 'campaign_geo_tree_title';

  return (
    <div className={prefix}>
      <span className={`${prefix}-name`}>{node.name}</span>
      { isDynamicCampaign && <Pulsation status={getTierUiStatus(isCPE, node)} /> }
    </div>
  );
}

export function getGeoData(resource, dynamicGeos, isDynamicCampaign) {
  if (!isDynamicCampaign) {
    return getTypicalTreeData(countries);
  }
  const pieCountryStats = resource.get(pieCountryStatsForTargetCPEEventKey) || [];
  dynamicGeos.forEach(({ children }) => {
    children.forEach((geo) => {
      const geoStats = pieCountryStats.find((stats) => stats.code === geo.code);
      const totalEvents = geoStats?.totalEvents || 0;
      Object.assign(geo, { totalEvents });
    });
  });
  return dynamicGeos;
}

const CountrySelector = ({
  resource,
  selectedItems,
  onSelectedChange,
  onPaste,
}) => {
  const [dynamicGeos, setDynamicGeos] = useState([]);
  const isCampaignPage = resource instanceof Campaign;
  const applicationID = resource?.get('application')?.get('id');
  const isDynamicCampaign = isCampaignPage && isCampaignObjectiveDynamic(resource);
  const isCPE = isCampaignPage && isCampaignObjectiveCPEIap(resource);
  const geoData = getGeoData(resource, dynamicGeos, isDynamicCampaign);

  const availableCountries = isDynamicCampaign
    ? dynamicGeos.reduce((prev, geo) => [...prev, ...geo.children], [])
    : countries;

  useEffect(() => {
    if (!isDynamicCampaign) {
      return;
    }
    if (!applicationID) {
      return;
    }
    const tempDynamicGeos = resource.get(dynamicGeosKey) || [];
    if (tempDynamicGeos.length > 0) {
      setDynamicGeos(getTierTreeData(tempDynamicGeos));
      return;
    }
    getDynamicTierGeos(applicationID, DYNAMIC_GEOS_REQUEST_START, DYNAMIC_GEOS_REQUEST_END)
      .then((rg) => {
        setDynamicGeos(getTierTreeData(rg));
        // resource is campaign in this case
        resource.set(dynamicGeosKey, rg);
      });
  }, [applicationID, isDynamicCampaign, resource]);

  return (
    <MultiTreeSelector
      data={geoData}
      selectedItems={selectedItems}
      dataKeyPrefix="region"
      generateFilterAttrs={(country) => ({
        key: country.id,
        checked: resource.hasCountry(country),
        label: country.name,
        onChange: (isChecked) => resource.toggleCountry(isChecked, country),
      })}
      onChange={(nodes) => {
        onSelectedChange(
          countries
            .filter((country) => (nodes.some((node) => node.dataKey === country.id)))
            .sort(sortByName),
        );
        resource.geoDailySpend();
      }}
      onPaste={onPaste}
      onCheckAll={(isChecked, searchValue) => {
        resource.toggleAllCountries(isChecked, searchValue, availableCountries);
      }}
      checkedKeyMapper={(country) => country.id}
      titleRender={(node) => (<TreeTitle isDynamicCampaign={isDynamicCampaign} isCPE={isCPE} node={node} />)}
    />
  );
};

export default CountrySelector;
