import moment from 'moment';
import { isNumber } from 'lodash';
import {
  FILTER_KEY_APPLICATION,
  FILTER_KEY_CAMPAIGN,
  FILTER_KEY_BID_TYPE,
  FILTER_KEY_COUNTRY,
  FILTER_KEY_CREATIVE,
  FILTER_KEY_PLATFORM,
  FILTER_KEY_PUBLISHER,
} from 'app/constants/filters';
import { getFilters as combinedFilters } from 'components/V2/Filters/filters';
import { getFilters as compareFilters } from 'components/V2/Report/Filters/CompareFilter/helper';
import { getMetricsListByTab } from 'components/V2/Report/data/Metrics';
import {
  QUICK_MAP,
  QUICK_KEY_LAST_7_DAYS,
} from 'components/V2/Report/Calendar/PickerQuick';
import { ISO_8601_FORMAT } from 'lib/date';
import { isEmptyObject } from 'lib/lib';
import {
  CHART_TOTAL_TEXT,
  CHART_VIEW_BY_KEY_DAY,
  CHART_VIEW_BY_KEY_MONTH,
  CHART_VIEW_BY_KEY_WEEK,
  COMBINE_KEY,
  COMPARE_KEY,
  DATE_KEY,
  GRAPHQL_QUERIES,
} from './constant';

export function initCombinedFilters() {
  const filters = [
    FILTER_KEY_APPLICATION,
    FILTER_KEY_CAMPAIGN,
    FILTER_KEY_COUNTRY,
    FILTER_KEY_CREATIVE,
    FILTER_KEY_PLATFORM,
    FILTER_KEY_PUBLISHER,
    FILTER_KEY_BID_TYPE,
  ];

  return combinedFilters(filters);
}

export function initCompareFilters() {
  return compareFilters([
    FILTER_KEY_APPLICATION,
    FILTER_KEY_CAMPAIGN,
    FILTER_KEY_COUNTRY,
    FILTER_KEY_CREATIVE,
    FILTER_KEY_PLATFORM,
    FILTER_KEY_PUBLISHER,
  ]);
}

export function initCheckedDate(key) {
  return {
    quickKey: key,
    ...QUICK_MAP[key].generateDate(moment()),
  };
}

export function initCheckedCombine(account) {
  return initCombinedFilters(account).reduce((acc, cur) => ({
    ...acc,
    [cur.key]: [],
  }), {});
}

/**
 * For some of selected filter data there's a children filed that contains the real node which we should use for
 * e.g. campaign filter select data with format like this:
 * {
 *    campaign: [{ id: '1', children: [{ id: '2' }] }]
 * }
 * But some filter data not contains children field, so we just use the id field in the parent node
 * e.g. orientation filter select data like this:
 * { asset_orientation: [{ id: 'landscape' }] }
 */
export function getRequestNodes(checkedCombine, isFilter) {
  if (!checkedCombine) {
    return {};
  }
  const body = {};
  Object.keys(checkedCombine).forEach((key) => {
    const values = [];
    checkedCombine[key].forEach((node) => {
      if (node.children && node.children.length) {
        values.push(...node.children.map((e) => e.id));
      } else {
        values.push(node.id);
      }
    });
    if (values.length) {
      const nodeKey = isFilter ? GRAPHQL_QUERIES[key].filterQuery : GRAPHQL_QUERIES[key].detailQuery;
      body[nodeKey] = values;
    }
  });

  return body;
}

export function combineRequestBody({
  checkedDate,
  checkedCombine,
  checkedCompare,
  ...query
}) {
  const { startDate, endDate } = checkedDate;
  const start = startDate.format(ISO_8601_FORMAT);
  const end = endDate.format(ISO_8601_FORMAT);

  const body = {
    start,
    end,
    ...getRequestNodes(checkedCombine, true),
    ...query,
  };
  if (checkedCompare && !isEmptyObject(checkedCompare)) {
    const { data, key } = checkedCompare;
    const ids = data.map((node) => node.id);
    body[GRAPHQL_QUERIES[key].comparisonQuery] = ids;
  }

  return body;
}

const VIEW_BY_STEP = {
  [CHART_VIEW_BY_KEY_DAY]: 1,
  [CHART_VIEW_BY_KEY_WEEK]: 7,
  [CHART_VIEW_BY_KEY_MONTH]: 30,
};

export const getMetricData = (data, metric) => (metric.getter ? metric.getter(data) : data[metric.key]);

const plusMetrics = (metrics1, metrics2) => {
  const metrics = {};
  Object.keys(metrics2).forEach((key) => {
    if (key === 'skan') {
      metrics.skan = plusMetrics(metrics1.skan || {}, metrics2.skan || {});
    } else {
      const m1 = metrics1[key] || 0;
      const m2 = metrics2[key] || 0;
      metrics[key] = m1 + m2;
    }
  });
  return metrics;
};

function safeDivide(a, b) {
  if (!isNumber(a) || !isNumber(b) || b === 0) return 0;
  return a / b;
}

/**
 * since api only returns aggregates by day, when view by week/month
 * we should calculate some special aggregates by the follow formula.
 *
 * 1000 * (spend / views)            AS ecpm,
 * 1000 * (spend / views)            AS eCPM,
 * spend / installs                  AS eCPI,
 * clicks    / impressions           AS CTR,
 * installs  / clicks                AS IR,
 * 1000 * (installs / impressions)   AS IPM,
 * d1rev  / spend                    AS d1roas,
 * d3rev  / spend                    AS d3roas,
 * d7rev  / spend                    AS d7roas,
 * d14rev / spend                    AS d14roas,
 * d15rev / spend                    AS d15roas,
 * d30rev / spend                    AS d30roas,
 * d1au  / installs                  AS d1ret,
 * d3au  / installs                  AS d3ret,
 * d7au  / installs                  AS d7ret,
 * d14au / installs                  AS d14ret,
 * d15au / installs                  AS d15ret,
 * d30au / installs                  AS d30ret,
 */
export function calculateSpecialAggregates(metrics) {
  const newMetrics = { ...metrics };
  const {
    spend,
    views,
    impressions,
    installs,
    clicks,
    d1rev,
    d3rev,
    d7rev,
    d14rev,
    d15rev,
    d30rev,
    d1au,
    d3au,
    d7au,
    d14au,
    d15au,
    d30au,
  } = metrics;
  newMetrics.ecpm = 1000 * safeDivide(spend, views);
  newMetrics.eCPI = 1000 * safeDivide(spend, installs);
  newMetrics.CTR = safeDivide(clicks, impressions);
  newMetrics.IR = safeDivide(installs, clicks);
  newMetrics.IPM = 1000 * safeDivide(installs, impressions);

  newMetrics.d1roas = safeDivide(d1rev, spend);
  newMetrics.d3roas = safeDivide(d3rev, spend);
  newMetrics.d7roas = safeDivide(d7rev, spend);
  newMetrics.d14roas = safeDivide(d14rev, spend);
  newMetrics.d15roas = safeDivide(d15rev, spend);
  newMetrics.d30roas = safeDivide(d30rev, spend);

  newMetrics.d1ret = safeDivide(d1au, installs);
  newMetrics.d3ret = safeDivide(d3au, installs);
  newMetrics.d7ret = safeDivide(d7au, installs);
  newMetrics.d14ret = safeDivide(d14au, installs);
  newMetrics.d15ret = safeDivide(d15au, installs);
  newMetrics.d30ret = safeDivide(d30au, installs);
  return newMetrics;
}

export function combineDataByDate(data, byKey) {
  const result = {};
  data.forEach(({ date, metrics }) => {
    if (byKey === CHART_VIEW_BY_KEY_WEEK) {
      const has = Object.keys(result).find((key) => {
        const start = moment(key);
        const end = moment(key).clone().add(VIEW_BY_STEP[byKey], 'day');
        return moment(date).isBetween(start, end, 'day', '[)');
      });
      if (has) {
        result[has].push(metrics);
      } else {
        result[date] = [metrics];
      }
    } else if (byKey === CHART_VIEW_BY_KEY_MONTH) {
      const monthKey = moment(date).format('YYYY-MM');
      if (result[monthKey]) {
        result[monthKey].push(metrics);
      } else {
        result[monthKey] = [metrics];
      }
    } else {
      result[date] = [metrics];
    }
  });
  return Object.keys(result).map((date) => (
    result[date].reduce((a, b) => {
      const metrics = plusMetrics(a.metrics, b);
      if (byKey === CHART_VIEW_BY_KEY_WEEK || byKey === CHART_VIEW_BY_KEY_MONTH) {
        return { date, metrics: calculateSpecialAggregates(metrics) };
      }
      return { date, metrics };
    }, { metrics: {} })
  ));
}

export function combineChartData(response, checkedCompare, checkedMetric, checkedViewBy) {
  const { comparisons } = response;
  const { key, data } = checkedCompare || {};
  if (key && data && comparisons) {
    const { getKey } = GRAPHQL_QUERIES[key];
    return comparisons.map((item) => ({
      name: data.find(({ id }) => item[getKey] === id)?.name || item[getKey],
      data: combineDataByDate(item.data, checkedViewBy.key).map(({ date, metrics }) => ({
        x: date,
        y: parseFloat(getMetricData(metrics, checkedMetric)).toFixed(4),
      })),
    }));
  }

  return [{
    name: CHART_TOTAL_TEXT,
    data: combineDataByDate(response.data, checkedViewBy.key).map(({ date, metrics }) => ({
      x: date,
      y: parseFloat(getMetricData(metrics, checkedMetric)).toFixed(4),
    })),
  }];
}

export function isChartDataEmpty(response) {
  if (!response) {
    return true;
  }
  const { __typename: removedKey, ...metrics } = { ...response.total };
  const keys = Object.keys(metrics);
  if (!keys.length) {
    return true;
  }
  return keys.every((key) => !metrics[key]);
}

export const returnMetricsList = ({
  metricsData,
  tab,
  needCheckCPE = false,
}) => metricsData.reduce((metricsList, data) => {
  const metric = getMetricsListByTab(tab, needCheckCPE).find((m) => m.key === data.key);
  if (metric) {
    metricsList.push({ ...metric });
  }
  return metricsList;
}, []);

export function getDetailQueryNodeIds(checkedCombine) {
  return getRequestNodes(checkedCombine, false);
}

export const getCacheValue = (cache, key) => {
  const values = cache.get();

  if (key === DATE_KEY) {
    const value = values[key];
    const { startDate, endDate } = value;
    values[key] = {
      ...value,
      startDate: moment(startDate),
      endDate: moment(endDate),
    };
  }

  return values[key];
};

export const setCacheValue = (cache, key, value) => {
  const values = cache.get();

  cache.save({
    ...values,
    [key]: value,
  });
};

export function getDefaultCacheValues(account) {
  return {
    [COMPARE_KEY]: {},
    [COMBINE_KEY]: initCheckedCombine(account),
    [DATE_KEY]: initCheckedDate(QUICK_KEY_LAST_7_DAYS),
  };
}
