import React from 'react';
import classNames from 'classnames';
import Collapse from 'components/V2/Collapse/Collapse';
import Button from 'components/V2/Button/Button';
import './infoBars.scss';

const prefix = 'ab-testing-info-banner';

const InProgressDetail = () => (
  <div className={classNames(`${prefix}-detail-text`)}>
    A/B test in progress. You can still make edits, but changes to settings such as targeting, bidding, or creatives will reset the A/B test.
  </div>
);

const CompleteDetail = () => (
  <div className={classNames(`${prefix}-detail-text`)}>
    <p>Your A/B test is complete. We have determined Set B as the winner.</p>
    <Button size="small" type="primary">Call to Action</Button>
  </div>
);

const InfoBarInProgress = () => (
  <div className={classNames(prefix)}>
    <Collapse
      className="pending-collapse"
      rows={[{
        summary: 'Good to Know',
        prefixIcon: <i className="material-icons">info_outline</i>,
        detail: <InProgressDetail />,
      }]}
    />
  </div>
);

const InfoBarCompleted = () => (
  <div className={classNames(prefix)}>
    <Collapse
      className="success-collapse"
      rows={[{
        summary: 'Good to Know',
        prefixIcon: <i className="material-icons">info_outline</i>,
        detail: <CompleteDetail />,
      }]}
    />
  </div>
);

export {
  InfoBarInProgress,
  InfoBarCompleted,
};
