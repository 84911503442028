import React from 'react';
import classnames from 'classnames';
import { uniq } from 'lodash';
import config from '../../../../../../lib/config';
import Icon from '../../../../../../components/Icon/Icon';
import Link from '../../../../../../components/Link/Link';
import Text from '../../../../../../components/Form/Text/TextContainer';
import ApplicationOverview from '../../../../../../components/Templates/ApplicationOverview/ApplicationOverview';
import Group from '../../../../../../templates/input-group/InputGroupContainer';
import CollapseSection from '../../../../../../components/V2/Collapse/CollapseSection';
import StartEndDate from '../../../../../../components/V2/StartAndEndDate/StartEndDate';
import EnableSKAdNetwork from '../EnableSKAdNetwork/EnableSKAdNetwork';
import './confirm.scss';

const { impressionEvent } = config.get('campaigns');

const { Field } = Group;

const getDailyBudget = (campaign) => {
  if (campaign.get('budget.daily_spend_limit_type') === 'geo') {
    return `$${campaign.get('budget.daily_spend_limit_geos').reduce((prev, next) => prev + Number(next.rate), 0)}`;
  }
  return campaign.get('budget.daily');
};

const ConfirmBox = ({ fields, values, style = {} }) => (
  <div className="confirm_box">
    <div className="titles">
      {fields.map((title, i) => (
        <p key={i} className="p1" style={style}>{title}</p>
      ))}
    </div>
    <div className="values">
      {values.map((value, i) => (React.isValidElement(value) ? value : (
        <p key={i} className="p2" style={style}>{value}</p>
      )))}
    </div>
  </div>
);

const mkLink = (to) => (
  <div style={{ textAlign: 'right' }}>
    <Link to={to}>
      <Icon.Edit />
    </Link>
  </div>
);

const Confirm = ({ campaign }) => {
  const additionalEvents = campaign.getAdditionalEvents();
  return (
    <div className="views__advertiser__campaigns__edit__confirm">
      <CollapseSection title="Details" description="">
        <Field name="Name" description="Choose a unique name for your Campaign">
          <Text
            noIcon
            placeholder="Name"
            value={campaign.get('name')}
            onChange={(v) => campaign.set('name', v)}
          />
        </Field>
        <Field
          name="Campaign Start &amp; End Dates"
          description='Select when your campaign starts and stops serving.
            Select "Run Indefinitely" for your campaign to serve until there is no budget remaining.'
        >
          <StartEndDate campaign={campaign} />
        </Field>
      </CollapseSection>
      <CollapseSection
        title="Review"
        description="Now that you've configured your campaign, let's review and submit it together"
      >
        <ApplicationOverview application={campaign.get('application')} />
      </CollapseSection>
      <EnableSKAdNetwork
        campaign={campaign}
        disabled
      />
      <CollapseSection title="MMP Measurement URLs" description="" tip={mkLink('tracking')}>
        <ConfirmBox
          fields={['Click URL']}
          values={[
            <div className={classnames('p2', 'events_container')} key={0}>
              {campaign.getClickURL() || (
                <p className="p2">No click url</p>
              )}
            </div>,
          ]}
        />
        <ConfirmBox
          fields={['Impression URL']}
          values={[
            <div className={classnames('p2', 'events_container')} key={0}>
              {campaign.getEventURL(impressionEvent.name) || (
                <p className="p2">No impression url</p>
              )}
            </div>,
          ]}
        />
        <ConfirmBox
          fields={['Additional Events']}
          values={[
            <div className="events_container" key={0}>
              {additionalEvents.length ? (
                additionalEvents.map((event, i) => (
                  <div key={i}>
                    <p className="p2">{event.description}</p>
                    <p className="p2">{event.url}</p>
                    {i < additionalEvents.length - 1 && (
                      <p className="event_divider" />
                    )}
                  </div>
                ))
              ) : (
                <p className="p2">No events selected</p>
              )}
            </div>,
          ]}
        />
      </CollapseSection>
      <CollapseSection title="Targeting" tip={mkLink('targeting')}>
        <ConfirmBox
          fields={['Device', 'Connection', 'Operating System']}
          values={[
            <div key={0}>
              {campaign.get('targeting.devices.phone') && (
                <p className="p2">Phone</p>
              )}
              {campaign.get('targeting.devices.tablet') && (
                <p className="p2">Tablet</p>
              )}
            </div>,
            campaign.get('targeting.network') === 'all' ? 'All' : 'Wifi Only',
            !campaign.get('targeting.versions.application.is_enabled') ? 'All' : (
              <p className="p2" key={4}>
                {`${
                  campaign.get('targeting.versions.application.min') || 'All'
                } through ${campaign.get('targeting.versions.application.max') || 'All'}`}
              </p>
            ),
          ]}
        />
        {campaign.isIOS() && !campaign.isTargetingSpecificDevices() && (
          <ConfirmBox
            fields={['Device Model Targeting']}
            values={[
              'Not targeting specific device models',
            ]}
          />
        )}
        {campaign.isIOS() && campaign.isTargetingSpecificDevices() && (
          <ConfirmBox
            fields={['Device Model Targeting']}
            values={[
              campaign.get('targeting.device_models.type') === 'allow'
                ? `Allow: ${uniq(campaign.get('targeting.device_models.device_whitelist').map((device) => device.name)).join(', ')}`
                : `Deny: ${uniq(campaign.get('targeting.device_models.device_blacklist').map((device) => device.name)).join(', ')}`,
            ]}
          />
        )}
        {campaign.isIOS() && !campaign.isTargetingNewDevices() && (
          <ConfirmBox
            fields={['Target All New Makes/Models']}
            values={[
              'Not targeting new makes/models',
            ]}
          />
        )}
        {campaign.isIOS() && campaign.isTargetingNewDevices() && (
        <ConfirmBox
          fields={['Target All New Makes/Models']}
          values={[
            <div key={[0]}>
              {campaign.get('targeting.device_models.target_new_tablet_models') && (
              <p className="p2">iPad</p>
              )}
              {campaign.get('targeting.device_models.target_new_phone_models') && (
              <p className="p2">iPhone</p>
              )}
            </div>,
          ]}
        />
        )}
        {campaign.get('targeting.publisher.type') === 'none' && (
          <ConfirmBox
            fields={['Publishers']}
            values={[
              'Not targeting specific publishers',
            ]}
          />
        )}
        {campaign.get('targeting.publisher.type') !== 'none' && (
          <ConfirmBox
            fields={['Publishers']}
            values={[
              <div key={0}>
                <p className="p2">Targeting the following publishers:</p>
                <p
                  className="p2"
                  style={{
                    wordWrap: 'break-word',
                    marginTop: 8,
                  }}
                >
                  {campaign.get('targeting.publisher.applications.ids')}
                </p>
              </div>,
            ]}
          />
        )}
      </CollapseSection>
      <CollapseSection title="Bidding &amp; Budget" tip={mkLink('bidding')}>
        {campaign.get('targeting.geo.region') === 'country' && (
          <ConfirmBox
            fields={['Countries']}
            values={[
              <div key={0}>
                {campaign.get('targeting.geo.countries').map(({ code }, i) => (
                  <span key={code} className="p2">
                    {code}
                    {i + 1 < campaign.get('targeting.geo.countries').length ? ', ' : ''}
                  </span>
                ))}
              </div>,
            ]}
          />
        )}
        {campaign.get('targeting.geo.region') === 'city' && (
          <ConfirmBox
            fields={['Cities']}
            values={[
              <div key={0}>
                {campaign.get('targeting.geo.cities').map((c, i) => (
                  <span key={campaign.makeCityValue(c)} className="p2">
                    {campaign.makeCityValue(c)}
                    {i + 1 < campaign.get('targeting.geo.cities').length ? ', ' : ''}
                  </span>
                ))}
              </div>,
            ]}
          />
        )}
        {campaign.get('targeting.geo.region') === 'subdivision' && (
          <ConfirmBox
            fields={['Regions']}
            values={[
              <div key={0}>
                {campaign.get('targeting.geo.subdivisions').map(({ code }, i) => (
                  <span key={code} className="p2">
                    {code}
                    {i + 1 < campaign.get('targeting.geo.subdivisions').length ? ', ' : ''}
                  </span>
                ))}
              </div>,
            ]}
          />
        )}
        <ConfirmBox
          fields={['Type', 'Bid Amount', 'Daily Budget', 'Total Budget', 'MultiBidding']}
          values={[
            campaign.getBudgetType(),
            campaign.get('budget.bid'),
            getDailyBudget(campaign),
            campaign.get('budget.total'),
            `${campaign.get('budget.publisher_rates').length} Publishers`,
          ]}
        />
      </CollapseSection>
      <CollapseSection title="Creatives" tip={mkLink('creatives')}>
        <p className="p2" style={{ marginTop: 8 }}>
          {campaign.get('creatives').map((c, i) => `${c.getFriendlyName()}${i + 1 < campaign.get('creatives').length ? ', ' : ''}`)}
        </p>
      </CollapseSection>
    </div>
  );
};

export default Confirm;
