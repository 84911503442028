import defaultOptions from './default';
import productionConfig from './production';
import betaConfig from './beta';
import { generateServiceUrls } from './service-urls';

// infers the target env from hostname
function getEnvironmentFromUrl(hostname) {
  // Rule 1: adv-ctrl-${env}vungle.io or adv-grow-${env}vungle.io
  const rule1 = /adv-(ctrl|grow)-([a-zA-Z0-9-]+)\.vungle\.io/;
  const rule1Match = hostname.match(rule1);
  if (rule1Match) {
    return { env: rule1Match[2] };
  }

  // Rule 2: milton-admin-demand-PR-${num}-milton-admin.feature.vungle.io or milton-advertiser-demand-PR-${num}-milton-advertiser.feature.vungle.io
  const rule2 = /milton-(admin|advertiser)-demand-PR-([0-9]+)-milton-(admin|advertiser)\.feature\.vungle\.io/;
  const rule2Match = hostname.match(rule2);
  if (rule2Match) {
    return { env: 'verraform', prNumber: parseInt(rule2Match[2], 10) };
  }

  // Rule 3: ctrl.vungle.com or advertiser.vungle.com
  const rule3 = /(ctrl|advertiser)\.vungle\.com/;
  const rule3Match = hostname.match(rule3);
  if (rule3Match) {
    return { env: 'production' };
  }

  if (hostname === 'localhost') {
    return { env: 'development' };
  }

  // If no rule matches, return {}
  return {};
}

function generate() {
  const { hostname } = window?.location || {};
  const { env, prNumber } = getEnvironmentFromUrl(hostname);
  if (env === 'production') {
    return productionConfig;
  }
  if (env === 'beta') {
    return betaConfig;
  }

  return {
    ...defaultOptions,
    loggerDisabled: false,
    ...generateServiceUrls(env, prNumber),
  };
}

export {
  generate,
  getEnvironmentFromUrl,
};

export default {
  generate,
};
