import React, { useState, useCallback, useEffect } from 'react';
import classnames from 'classnames';
import SideModal from '../../../../../components/SideModal/side-modal';
import Button from '../../../../../components/V2/Button/Button';
import Message from '../../../../../components/V2/FlagMessage/Message';
import Asset from '../../../../../models/Asset';
import HistoryList from './HistoryList';
import ReplaceAsset from './ReplaceAsset';
import './ReviewHistory.scss';

const defaultPagination = {
  page: 0, pages: 0, perPage: 10, total: 0,
};

const classPrefix = 'creativeQA-history';

const ReviewHistory = ({
  asset = {},
  onClose,
  refreshList,
}) => {
  const [showReplaceModal, setShowReplaceModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statusChanged, setStatusChanged] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [pagination, setPagination] = useState(defaultPagination);

  const getQAHistory = useCallback(async (isRefreshing = false) => {
    setLoading(true);
    try {
      const result = await Asset.getQAHistory(asset.get('id'), isRefreshing ? 1 : pagination.page + 1);
      setHistoryData((prevState) => (isRefreshing ? [...result.response] : prevState.concat(result.response)));
      setPagination(result.pagination);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.dir(error);
      Message.error({
        title: 'Error',
        content: 'Get History failed, please try again later.',
      });
    }
    setLoading(false);
  }, [asset, pagination.page]);

  useEffect(() => {
    getQAHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshHistory = () => {
    getQAHistory(true);
    setStatusChanged(true);
  };

  const onRequestClose = () => {
    if (statusChanged) refreshList();
    onClose();
  };

  return (
    <div className={classPrefix}>
      <SideModal
        isOpen
        overlayClassName={classnames(`${classPrefix}-overlay`, { [`${classPrefix}-overlay-transparent`]: showReplaceModal })}
        onRequestClose={onRequestClose}
        ariaHideApp={false}
        title=""
        closeTimeoutMS={200}
      >
        <div className="modal-content">
          <div className="title">
            <p>Review History</p>
            <p>
              View all the
              {' '}
              {asset.get('name')}
              {' '}
              Playable (.zip format) submission and review history below.
            </p>
          </div>
          <div className="history-list">
            <HistoryList
              loading={loading}
              assetId={asset.get('id')}
              data={historyData}
              total={pagination.total}
              onFetchMoreData={getQAHistory}
              refreshHistory={refreshHistory}
              onAssetReplacement={() => setShowReplaceModal(true)}
            />
          </div>
          <div className="history-bottom">
            <Button className="history-bottom-button" onClick={onRequestClose}>Close</Button>
          </div>
        </div>
      </SideModal>
      {showReplaceModal && (
        <ReplaceAsset
          asset={asset}
          onClose={() => setShowReplaceModal(false)}
          refreshHistory={refreshHistory}
        />
      )}
    </div>
  );
};

export default ReviewHistory;
