// Target return of ad spend to be returned in post-install user event value (min: 1%, max: 200%).
export const ROAS_TARGET_MIN = 0.01;
export const ROAS_TARGET_MAX = 2;
export const CPPU_TARGET_MIN = 0.01;
export const CPPU_TARGET_MAX = 1000;
export const CPE_TARGET_MIN = 0.01;
export const CPE_TARGET_MAX = 1000;

export const DYNAMIC_BID_MAX_MIN = 0.01;
export const DYNAMIC_BID_MAX_MAX = 200.00;
export const DYNAMIC_TARGET_MIN = 0.01;
export const DYNAMIC_TARGET_MAX = 200.00;

export const SUBSIDIZATION_MULTIPLIER_MIN = -0.5;
export const SUBSIDIZATION_MULTIPLIER_MAX = 0.5;

export const PACING_METHOD_DYNAMIC = 'spend_v2';
export const PACING_METHOD_DYNAMIC_LABEL = 'Spend Pacer';
export const PACING_METHOD_DYNAMIC_DESCRIPTION = '- Pacing with upgraded spend estimates that are near real-time.';

export const BUDGET_OBJECTIVE_STATIC = 'static';
export const BUDGET_OBJECTIVE_DYNAMIC = 'dynamic';
export const BUDGET_OBJECTIVE_ROAS = 'roas';
export const BUDGET_OBJECTIVE_CPPU = 'cppu';
export const BUDGET_OBJECTIVE_CPE = 'cpe';

export const BUDGET_TARGET_HORIZON_DYNAMIC_IAP = 7;

// The data represents the D7 paying users for the last 30 days, however, the targeting period
// will be between the last 44 and 8 days due to how the calculation works.
export const DYNAMIC_GEOS_REQUEST_START = 44;
export const DYNAMIC_GEOS_REQUEST_END = 8;
export const NUM_OF_PAYING_USERS_THRESHOLD = 20;
export const NUM_OF_EVENTS_THRESHOLD = 25;
export const EVENTS_METRIC = 'Conversion Events';
export const PAYING_USERS_METRIC = 'Paying Users';
export const EVENTS_METRIC_ACTION = 'Acquired';
export const PAYING_USERS_METRIC_ACTION = 'Received';

export const TARGET_GOALS = [BUDGET_OBJECTIVE_ROAS, BUDGET_OBJECTIVE_CPPU, BUDGET_OBJECTIVE_CPE];
export const TARGET_GOALS_DESCRIPTIONS = {
  [BUDGET_OBJECTIVE_ROAS]: 'Return on Ad Spend',
  [BUDGET_OBJECTIVE_CPPU]: 'Cost per Paying User',
  [BUDGET_OBJECTIVE_CPE]: 'Cost per Event',
};
export const TARGET_GOALS_DETAILS = {
  [BUDGET_OBJECTIVE_ROAS]: 'Campaign optimizes for a target percentage return on spend for a given time horizon.',
  [BUDGET_OBJECTIVE_CPPU]: 'Campaign optimizes for a target cost per paying user over the given time horizon.',
  [BUDGET_OBJECTIVE_CPE]: 'Campaign optimizes for a target cost per event over the given time horizon.',
};

export const RATE_TYPES_DESCRIPTIONS = {
  install: 'CPI - Cost Per Install',
  target_cpi: 'tCPI - Target Cost Per Install',
  complete: 'CPCV - Cost Per Completed View',
  impression: 'CPM - Cost Per Thousand Impressions',
  view: 'CPV - Cost Per View',
  click: 'CPC - Cost Per Click (Only for pops users)',
  cppu: 'CPPU - Cost Per Paying User',
  cpe: 'CPE - Cost Per Event',
};

export const RATE_TYPES = Object
  .keys(RATE_TYPES_DESCRIPTIONS)
  .reduce(
    (accum, key) => ({
      ...accum,
      [key]: key,
    }),
    {},
  );
export const FORMAT_TYPE_FULLSCREEN = 'fullscreen';
export const FORMAT_TYPE_MREC = 'mrec';
export const FORMAT_TYPE_BANNER = 'banner';
export const FORMAT_TYPE_NATIVE = 'native';
export const CAMPAIGN_CREATIVE_FORMAT_TYPE_DESCRIPTIONS = {
  [FORMAT_TYPE_BANNER]: {
    text: 'Banner',
  },
  [FORMAT_TYPE_MREC]: {
    text: 'MREC',
  },
  [FORMAT_TYPE_FULLSCREEN]: {
    text: 'Full Screen',
  },
  [FORMAT_TYPE_NATIVE]: {
    text: 'Native',
    isAdminOnly: true,
  },
};

export function getCampaignCreativeFormatTypeDescription(formatType) {
  return CAMPAIGN_CREATIVE_FORMAT_TYPE_DESCRIPTIONS[formatType].text;
}

export const CREATIVE_FORMAT_MAP = {
  single_page_fullscreen: {
    type: FORMAT_TYPE_FULLSCREEN,
    text: 'Full Screen (Single Page)',
  },
  multi_page_fullscreen: {
    type: FORMAT_TYPE_FULLSCREEN,
    text: 'Full Screen (Multi Page)',
  },
  single_page_flexview: {
    type: FORMAT_TYPE_MREC,
    text: 'MREC',
  },
  multi_page_flexview: {
    type: FORMAT_TYPE_MREC,
    text: 'MREC',
  },
  single_page_flexfeed: {
    type: FORMAT_TYPE_MREC,
    text: 'MREC',
  },
  multi_page_flexfeed: {
    type: FORMAT_TYPE_MREC,
    text: 'MREC',
  },
  single_page_mrec: {
    type: FORMAT_TYPE_MREC,
    text: 'MREC',
  },
  multi_page_mrec: {
    type: FORMAT_TYPE_MREC,
    text: 'MREC',
  },
  image_mrec: {
    type: FORMAT_TYPE_MREC,
    text: 'MREC',
  },
  banner: {
    type: FORMAT_TYPE_BANNER,
    text: 'Banner',
  },
  native: {
    type: FORMAT_TYPE_NATIVE,
    text: 'Native',
  },
};

export const getFormatsType = (formatType) => Object.entries(CREATIVE_FORMAT_MAP)
  .reduce((prev, [key, { type }]) => {
    if (type === formatType) {
      prev.push(key);
    }
    return prev;
  }, []);

export const fullscreenFormats = getFormatsType(FORMAT_TYPE_FULLSCREEN);
export const mrecFormats = getFormatsType(FORMAT_TYPE_MREC);
export const bannerFormats = getFormatsType(FORMAT_TYPE_BANNER);
export const nativeFormats = getFormatsType(FORMAT_TYPE_NATIVE);

export const formatsMap = {
  [FORMAT_TYPE_FULLSCREEN]: fullscreenFormats,
  [FORMAT_TYPE_MREC]: mrecFormats,
  [FORMAT_TYPE_BANNER]: bannerFormats,
  [FORMAT_TYPE_NATIVE]: nativeFormats,
};

export const exchangeProtocol = {
  vast: 'vast',
  openrtb: 'openrtb',
  jaeger: 'jaeger',
};

export const MAX_CPI_DESCRIPTION_ROAS = `The maximum CPI you will spend to acquire any single user. For example, you set a Maximum CPI of 
                                          $20, a Target ROAS of 10%, and a Horizon Date of D7. For a user predicted to generate $300 by D7, 
                                          your Target ROAS would dictate bidding $30 (10% of 300 = $30), but your Maximum CPI will cap the 
                                          bid at $20.`;
export const MAX_CPI_DESCRIPTION_CPPU = `The maximum CPI you will spend to acquire any single user. For example, you set a Maximum CPI of $40 and 
                                          Target CPPU of $50. For a user who we predict will make a purchase with 90% confidence, we would only bid 
                                          $40 instead of $45 (90% * $50 = $45). Note: Setting Max CPI higher than Target CPPU will have no effect.`;
export const MAX_CPI_DESCRIPTION_CPE = `The maximum CPI you will spend to acquire any single user. For example, you set a Maximum CPI of $40 
                                        and Target CPE of $50. For a user who we predict will generate an event with 90% confidence, we would only 
                                        bid $40 instead of $45 (90% * $50 = $45). Note: Setting Max CPI higher than Target CPE will have no effect.`;

export const pieCountryStatsForTargetCPEEventKey = 'application.pieCountryStatsForTargetCPEEvent';
export const dynamicGeosKey = 'dynamicGeos';
