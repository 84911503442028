import React from 'react';
import classNames from 'classnames';
import CreativeTooltip from '../../../../../Templates/CreativeBuilder/CreativeTooltip/CreativeTooltip';

const Field = ({
  children, name, separateLine, tooltipConfig, filled, required,
}) => (
  <div
    className={classNames('input-group', {
      filled,
    })}
  >
    <div className="p2 field-title">
      <span className={
          classNames('title-text', {
            'not-required': !required,
          })
        }
      >
        {name}
      </span>
      {tooltipConfig && <CreativeTooltip title={tooltipConfig.title} body={tooltipConfig.content} />}
    </div>
    {children}
    { separateLine && <hr className="separate-line" />}
  </div>
);

export default Field;
