import React from 'react';
import PropTypes from 'prop-types';
import csv from 'papaparse';
import { noop } from 'lodash';
import { upload } from 'lib/http';
import { replaceRateToNumber } from 'lib/lib';
import Uploader from './Component/Uploader';

class UploaderContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
    };
  }

  uploadFiles() {
    const { files } = this.state;
    files.forEach(async ({ file, index }) => {
      const body = new FormData();
      body.append(this.props.fileName, file);
      this.props.formData.forEach((formData) => {
        const { name, value } = formData;
        if (typeof value === 'function') {
          body.append(name, value(file));
          return;
        }
        body.append(name, value);
      });
      let complete = true;
      let error;
      let result;
      try {
        result = await upload(this.props.uploadUrl, body);
      } catch (ex) {
        complete = false;
        error = 'unexpected';
      }

      if (!error && result && result.response && result.response.messages && result.response.messages[0]) {
        // eslint-disable-next-line prefer-destructuring
        error = result.response.messages[0];
      }
      const retFiles = [...files];
      retFiles[index] = { ...files[index], complete, error: error || !result.ok };
      this.setState({ files: retFiles }, () => {
        if (complete && result.ok) {
          const newFiles = [...this.state.files];
          newFiles.splice(index, 1);
          const newFilesLength = newFiles.length;
          this.setState({ files: newFilesLength > 0 ? newFiles : [] }, () => {
            if (newFilesLength === 0) {
              this.props.onComplete(result.response, file);
            }
          });
        }
      });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  readFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsText(file);
    });
  }

  onDrop = async (files) => {
    const {
      onStart,
      onComplete,
      onError,
      type,
    } = this.props;
    if (files[0].name.split('.').pop().toLowerCase() !== type) {
      onError(files[0], { invalidFilename: [type] });
      return;
    }
    if (type === 'csv') {
      onStart(files[0]);
      const result = await this.readFile(files[0]);
      const { data } = csv.parse(result, { header: true });
      if (!data || !data.length) {
        onError();
        return;
      }
      data.forEach((pub, i) => {
        if (pub.rate) {
          data[i].rate = replaceRateToNumber(pub.rate);
        }
      });
      onComplete(data, files[0]);
    } else {
      this.setState({
        files: files.map((file, index) => ({ file, index, complete: false })),
      }, this.uploadFiles);
    }
  };

  onReset = () => {
    this.setState({ files: [] });
  };

  render() {
    const {
      prefixCls,
      className,
      children,
      disabled,
      name,
      type,
      dragging,
      icon,
      text,
    } = this.props;
    const { files } = this.state;
    const fileErrors = files
      .filter((file) => !!file && !!file.error)
      .map((file) => file.error);

    return React.createElement(children || Uploader, {
      prefixCls,
      className,
      disabled,
      name,
      type,
      dragging,
      icon,
      text,
      fileErrors,
      hasFiles: files.length > 0,
      files,
      onReset: this.onReset,
      onDrop: this.onDrop,
    });
  }
}

UploaderContainer.propTypes = {
  /**
   *  Called when files upload start.
   */
  onStart: PropTypes.func,
  /**
   *  Called when files upload finished.
   */
  onComplete: PropTypes.func,
  /**
   *  Called when files upload error happends.
   */
  onError: PropTypes.func,
  /**
   * Upload form data.
   */
  formData: PropTypes.arrayOf(PropTypes.object),
  /**
   *  Upload file name.
   */
  fileName: PropTypes.string,
  /**
   *  Upload url
   */
  uploadUrl: PropTypes.string,
};

UploaderContainer.defaultProps = {
  fileName: 'file',
  formData: [{}],
  onStart: noop,
  onComplete: noop,
  onError: noop,
  uploadUrl: '',
};

export default UploaderContainer;
