import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {
  PENDING,
  NEEDS_ATTENTION,
  VISUAL_QA,
  PENDING_FULL_QA,
  FULL_QA,
  ACTIVE,
  creativeQAStatusDisplay,
} from 'app/constants/asset';
import Tooltip from '../../V2/Tooltip/Tooltip';
import { statusTooltip } from './constants';
import './servingStatus.scss';

const classPrefix = 'assets_serving_status';

export const getToolTipBody = (status = PENDING) => (
  <div className="toolTipContent">
    {statusTooltip[status]}
  </div>
);

const ServingStatus = ({
  status,
  className,
  withHistory,
  onHistoryClick,
  showTooltip,
  fixedWidth,
  stopPropagation,
}) => {
  const title = creativeQAStatusDisplay[status];
  const content = showTooltip ? (
    <Tooltip
      title={title}
      body={getToolTipBody(status)}
      placement="bottomLeft"
    >
      {title}
    </Tooltip>
  ) : title;

  const statusIcon = status === ACTIVE ? (
    <span className="activeStatusIcon">
      <i className="activeIcon" />
      {content}
    </span>
  ) : (
    <span className={classnames('statusIcon', {
      pending: status === PENDING,
      error: status === NEEDS_ATTENTION,
      success: [VISUAL_QA, FULL_QA, PENDING_FULL_QA].includes(status),
    })}
    >
      {content}
    </span>
  );

  return (
    <div
      className={classnames(classPrefix, className, { fixedWidth })}
      onClick={stopPropagation ? (e) => e.stopPropagation() : undefined}
    >
      {statusIcon}
      {withHistory && <span onClick={onHistoryClick} className="status_history">Review History</span>}
    </div>
  );
};

ServingStatus.propTypes = {
  status: PropTypes.string,
  className: PropTypes.string,
  withHistory: PropTypes.bool,
  onHistoryClick: PropTypes.func,
  showTooltip: PropTypes.bool,
  fixedWidth: PropTypes.bool,
  stopPropagation: PropTypes.bool,
};

ServingStatus.defaultProps = {
  status: PENDING,
  className: undefined,
  withHistory: false,
  onHistoryClick: Function.prototype,
  showTooltip: false,
  fixedWidth: false,
  stopPropagation: false,
};

export default ServingStatus;
