/* eslint react/prop-types: 0 */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './pagination.scss';

const Pager = ({
  rootPrefixCls,
  page,
  active,
  onClick,
  className,
  itemRender,
  disabled,
}) => {
  const prefixCls = `${rootPrefixCls}-item`;
  const cls = classNames(prefixCls, {
    [`${prefixCls}-active`]: active,
    [className]: !!className,
    [`${prefixCls}-disabled`]: disabled,
  });

  const handleClick = () => {
    onClick(page);
  };
  return (
    <button
      className={cls}
      type="button"
      onClick={handleClick}
      tabIndex="0"
    >
      {itemRender(page, 'page', <span>{page}</span>)}
    </button>
  );
};

Pager.propTypes = {
  /**
   * Prefix class from super
   */
  rootPrefixCls: PropTypes.string.isRequired,
  /**
   * Add classname to pager
   */
  className: PropTypes.string,
  /**
   * Current page
   */
  page: PropTypes.number,
  /**
   * Function to run when the pager render
   */
  itemRender: PropTypes.func.isRequired,
  /**
   * Function to run when the pager is clicked
   */
  onClick: PropTypes.func.isRequired,
  /**
   * Disable pager
   */
  disabled: PropTypes.bool,
};

Pager.defaultProps = {
  className: null,
  page: 1,
  disabled: false,
};

export default Pager;
