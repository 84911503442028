import React from 'react';
import EditTable from './EditTable';

// TODO: This needs to be re-written with less complexity
const makeEditObj = (data, columns) => data.reduce((values, b) => ({
  ...values,
  [b.pub_app_id]: columns.reduce((obj, c) => ({ ...obj, [c.key]: b[c.key] }), {}),
}), {});

class EditTableContainer extends React.Component {
  constructor(p) {
    super(p);
    if (this.props.data && this.props.editColumns && this.props.setupEditValues) {
      this.editValues = makeEditObj(this.props.data, this.props.editColumns);
      this.props.setupEditValues(this.editValues);
    }
  }

  render() {
    return (
      <EditTable {...this.props} {...this.state} />
    );
  }
}

export default EditTableContainer;
