import React, { useCallback, useEffect, useState } from 'react';
import config from 'lib/config';
import {
  BUDGET_OBJECTIVE_STATIC,
  BUDGET_OBJECTIVE_DYNAMIC,
  CREATIVE_FORMAT_MAP,
  FORMAT_TYPE_FULLSCREEN,
} from 'app/constants/campaign';
import useDebounce from 'lib/hooks/useDebounce';
import { canModifyCampaigns } from 'lib/helpers/authUser';
import Campaign from 'models/Campaign';
import Table from 'components/V2/Table/Table';
import Dropdown from 'components/V2/Dropdown/Dropdown';
import TooltipCutoff from 'components/V2/Tooltip/TooltipCutoff';
import { DropdownMenuItem } from 'components/V2/Dropdown/DropdownMenu/DropdownMenu';
import { ServingStatusComponent } from 'components/Campaigns/ServingStatus/ServingStatus';
import { execChangeCampaignStatus } from '../../../advertiser/views/campaigns/List/ListContainer';
import Filters from '../BaseTable/Filters';
import ColumnCampaignName from './ColumnCampaignName';
import ColumnStatus from './ColumnStatus';
import './campaignTable.scss';

const makeColumn = (
  title,
  accessor,
  width = 0,
  fixed = null,
  sortable = false,
) => {
  const opts = {
    Header: title,
    id: accessor,
    accessor: (c) => c.raw(accessor),
  };
  if (width) {
    opts.minWidth = width;
  }
  if (fixed) {
    opts.fixed = fixed;
  }
  if (sortable) {
    opts.sortable = sortable;
  }
  return opts;
};

const getColumns = (application, deletable, selectedIds, selectCampaign, disableRowSelector) => {
  const showTooltip = canModifyCampaigns();
  const columns = [
    {
      ...makeColumn('Status', 'status', 70, null, true),
      className: 'status-column',
      Cell: function Cell({ original }) {
        return (
          <ServingStatusComponent
            campaign={original}
            changeServingStatus={execChangeCampaignStatus}
            showTooltip={showTooltip}
          >
            <ColumnStatus
              original={original}
            />
          </ServingStatusComponent>
        );
      },
    },
    {
      ...makeColumn('Campaign', 'name', 242, null, true),
      Cell: function Cell({ original }) {
        return <ColumnCampaignName original={original} />;
      },
    },
    {
      ...makeColumn('ID', 'id', 190, null, true),
      Cell: function Cell({ original }) {
        const id = original.get('id');
        return <TooltipCutoff title={id}>{id}</TooltipCutoff>;
      },
    },
    {
      ...makeColumn('Date Created', 'created', 95, null, true),
      Cell: function Cell({ original }) {
        const created = original.getDate('created', 'DD MMM YYYY');
        return <TooltipCutoff title={created}>{created}</TooltipCutoff>;
      },
    },
  ];
  const skadnetworkColumn = {
    ...makeColumn('SkAdNetwork', 'is_skadnetwork_enabled', 70, null, true),
    Cell: function Cell({ original }) {
      const title = original.get('is_skadnetwork_enabled') ? 'Yes' : 'No';
      return <TooltipCutoff title={title}>{title}</TooltipCutoff>;
    },
  };
  if (application && application.get('skadnetwork_settings.is_enabled')) {
    columns.push(skadnetworkColumn);
  }

  if (deletable) {
    const disabledCampaignIds = [];
    columns.push(
      {
        ...makeColumn('...', 'delete', 40, null, true),
        Cell: function Cell({ original }) {
          const isCampaignDeleteDisabled = disableRowSelector(original);
          if (isCampaignDeleteDisabled) {
            disabledCampaignIds.push(original.get('id'));
            return '';
          }
          const onClick = () => {
            const newSelectedIds = selectedIds
              .filter((creativeId) => creativeId !== original.get('id'));

            selectCampaign(newSelectedIds);
          };
          return <i className="material-icons" onClick={onClick}>delete</i>;
        },
        Header: function Header() {
          const onClick = () => {
            // prevent disabled campaigns from deleting
            selectCampaign(disabledCampaignIds);
          };
          return (
            <Dropdown
              renderOverlay={() => (
                <DropdownMenuItem onClick={onClick}>Detach All</DropdownMenuItem>
              )}
              placement="right"
              closeOnClick
            >
              <i className="material-icons">more_vert</i>
            </Dropdown>
          );
        },
      },
    );
  }

  return columns;
};

const CampaignTable = ({
  selectedIds = [],
  filterTypes = [],
  disableRowSelector,
  creative,
  handleRemoveAllColumn,
  selectCampaign,
  label,
  setChangedCampaigns = () => {},
  queryParams,
  unselectable,
  selectable = true,
  deletable,
}) => {
  const application = creative.get('application');
  const creativeMaximum = creative.get('account').get('creativeMaximum') || config.get('accounts.defaultCreativeMaximum');
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1, total: undefined, perPage: 10, pages: 0,
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState([]);
  const [overlayAutoShow, setOverlayAutoShow] = useState([]);
  const [sort, setSort] = useState(null);

  const getCampaigns = useCallback(({
    paginationParams = {},
  } = {}) => {
    const applicationId = application?.get('id');
    if (!applicationId) {
      return;
    }
    setIsLoading(true);

    const params = {
      ...paginationParams,
      application: applicationId,
      sort,
      search,
      attachedCreativesMaxLength: creativeMaximum - 1,
    };

    if (status.length === 1) {
      [params.campaignStatus] = status;
    }

    if (overlayAutoShow.length === 1) {
      params.skOverlayAutoShow = overlayAutoShow[0].id;
    }

    const format = CREATIVE_FORMAT_MAP[creative.get('template.format')]?.type;
    if (format !== FORMAT_TYPE_FULLSCREEN) {
      params.budgetObjectives = [BUDGET_OBJECTIVE_STATIC, BUDGET_OBJECTIVE_DYNAMIC].join(',');
    }

    if (queryParams) {
      Object.assign(params, queryParams);
    }

    Campaign.getCampaignsForCreativeAttachment(params).then((result) => {
      setData(result.response.map((c) => new Campaign(c)));
      setPagination(result.pagination);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application, queryParams?.idIn, creative, search, overlayAutoShow, sort, status]);

  useDebounce(() => {
    getCampaigns();
  }, 300, [search, sort, status, overlayAutoShow]);

  const handleSort = (sortParams) => {
    const { desc, id } = sortParams[0];
    const sortParam = {
      key: id,
      asc: !desc,
    };
    setSort(sortParam);
  };

  const handleRefresh = ({
    search: searchFilter,
    campaignStatus = [],
    skOverlayAutoShow,
    videoOverlayAutoShow,
  }) => {
    const statuses = campaignStatus.map((s) => s.id);

    if (search !== searchFilter) {
      setSearch(searchFilter);
    }

    setOverlayAutoShow(skOverlayAutoShow || videoOverlayAutoShow || []);
    setStatus(statuses);
  };

  const handlePageSizeChange = (page, perPage) => getCampaigns({ paginationParams: { page, perPage } });

  const handleSelectChange = useCallback((selectedCampaignList) => {
    setSelectedRows(selectedCampaignList);

    const campaignsToSelect = data.reduce((acc, campaign) => {
      const id = campaign.get('id');
      const selectedResource = selectedCampaignList.find((c) => c.get('id') === id);
      if (selectedResource) {
        if (!acc.includes(id)) {
          acc.push(id);
        }
      } else {
        const index = acc.findIndex((selectedId) => id === selectedId);
        if (index !== -1) {
          acc.splice(index, 1);
        }
      }
      return acc;
    }, [...selectedIds]);

    selectCampaign(campaignsToSelect);
  }, [data, selectCampaign, selectedIds]);

  useEffect(() => {
    getCampaigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (unselectable) {
      getCampaigns();
    }
  }, [getCampaigns, selectedIds, unselectable]);

  useEffect(() => {
    const rowsToSelect = [];
    data.forEach((campaign) => {
      if (selectedIds.includes(campaign.get('id'))) {
        rowsToSelect.push(campaign);
      }
    });
    setSelectedRows(rowsToSelect);
  }, [selectedIds, data]);

  const RemoveAllColumn = () => (
    <button
      className="vg-styleless-button"
      type="button"
      onClick={() => {
        setSelectedRows([]);
        handleRemoveAllColumn();
      }}
    >
      <i className="material-icons">close</i>
    </button>
  );

  return (
    <>
      <div className="table-filters">
        <Filters
          placeholder="Search by campaign name or ID..."
          perPage={pagination.perPage}
          onRefresh={handleRefresh}
          filterTypes={filterTypes}
        >
          { label }
        </Filters>
      </div>

      <Table
        manual
        resizable
        onSorted={handleSort}
        selectable={selectable}
        showPagination
        page={pagination.page}
        total={pagination.total}
        pageSize={pagination.perPage}
        onPageSizeChange={handlePageSizeChange}
        data={data}
        columns={getColumns(application, deletable, selectedIds, selectCampaign, disableRowSelector)}
        loading={isLoading}
        rowSelector={(campaign) => campaign.get('id')}
        disableRowSelector={disableRowSelector}
        selectedRows={selectedRows}
        onSelectedChange={(list, row) => {
          handleSelectChange(list);
          setChangedCampaigns(row);
        }}
        renderAll={RemoveAllColumn}
      />
    </>
  );
};

export default CampaignTable;
