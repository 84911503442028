import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';
import utils from './utils';
import TreeContext from './context';
import TreeNode from './TreeNode';

class Tree extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keyEntities: new Map(), // key => { node, dataKey, deep, children, parent }
      checkedKeys: [],
      halfCheckedKeys: [],
      expandedKeys: [],
      prevProps: null,
    };
  }

  static getDerivedStateFromProps(props, prevState) {
    const { prevProps } = prevState;
    const newState = { prevProps: props };

    // init keyEntities
    if ((!prevProps && 'children' in props) || (prevProps && prevProps.children !== props.children)) {
      newState.keyEntities = utils.convertChildrenToEntities(props.children);
    }

    // handle checkedKeys
    const keyEntities = newState.keyEntities || prevState.keyEntities;
    let checkedKeyEntity;

    if ((!prevProps && 'checkedKeys' in props) || (prevProps && prevProps.checkedKeys !== props.checkedKeys)) {
      checkedKeyEntity = utils.conductCheck(props.checkedKeys, true, keyEntities);
    } else if (!prevProps && Array.isArray(props.defaultCheckedKeys)) {
      checkedKeyEntity = utils.conductCheck(props.defaultCheckedKeys, true, keyEntities);
    }

    if (checkedKeyEntity) {
      newState.checkedKeys = checkedKeyEntity.checkedKeys;
      newState.halfCheckedKeys = checkedKeyEntity.halfCheckedKeys;
    }

    // handle expandedKeys
    if ((!prevProps && 'expandedKeys' in props) || (prevProps && prevProps.expandedKeys !== props.expandedKeys)) {
      newState.expandedKeys = utils.conductExpand(props.expandedKeys, keyEntities);
    } else if (!prevProps && Array.isArray(props.defaultExpandedKeys)) {
      newState.expandedKeys = utils.conductExpand(props.defaultExpandedKeys, keyEntities);
    }
    return newState;
  }

  onNodeCheck = async (treeNodeProps, checked) => {
    const { keyEntities, checkedKeys: oriCheckedKeys, halfCheckedKeys: oriHalfCheckedKeys } = this.state;
    const { onChange, maxSelected, checkedKeys: nodeCheckedKeys } = this.props;
    const { dataKey, isLeaf, children } = treeNodeProps;

    const { checkedKeys, halfCheckedKeys } = utils.conductCheck(
      [dataKey],
      checked,
      keyEntities,
      {
        checkedKeys: oriCheckedKeys,
        halfCheckedKeys: oriHalfCheckedKeys,
      },
    );
    let newCheckedKeys = checkedKeys;
    if (!isLeaf) {
      if (maxSelected) {
        if (oriHalfCheckedKeys.includes(dataKey)) {
          newCheckedKeys = newCheckedKeys.filter((i) => i !== dataKey && !children.some((child) => child.key === i));
        } else if (newCheckedKeys.includes(dataKey)) {
          const restSelected = maxSelected - nodeCheckedKeys.length;
          const extraChildren = children.slice(restSelected, children.length);
          newCheckedKeys = newCheckedKeys.filter((i) => !extraChildren.some((child) => child.key === i));
        }
      }
    }
    this.setState({
      checkedKeys: newCheckedKeys,
      halfCheckedKeys,
    });

    if (onChange) {
      const checkedNodes = [];
      newCheckedKeys.forEach((key) => {
        const entity = keyEntities.get(key);
        if (!entity) return;

        checkedNodes.push(entity);
      });
      onChange(checkedNodes);
    }
  };

  onNodeExpand = (treeNodeProps) => {
    const { onExpand } = this.props;
    let { expandedKeys } = this.state;
    const { dataKey, expanded } = treeNodeProps;

    const targetExpanded = !expanded;

    if (targetExpanded) {
      expandedKeys = utils.arrAdd(expandedKeys, dataKey);
    } else {
      expandedKeys = utils.arrDel(expandedKeys, dataKey);
    }

    if (!('expandedKeys' in this.props)) {
      this.setState({ expandedKeys });
    }
    onExpand(expandedKeys, targetExpanded, treeNodeProps);
  };

  renderTreeNode = (child) => {
    const {
      maxSelected, checkedKeys: nodeCheckedKeys, disabledTitle, disabledContent, onLoadChildren,
    } = this.props;
    const {
      keyEntities, checkedKeys, expandedKeys, halfCheckedKeys,
    } = this.state;
    const { dataKey, isLeaf } = child.props;
    const entity = keyEntities.get(dataKey);
    const isCheckedMax = maxSelected && nodeCheckedKeys.length >= maxSelected;
    if (child.type !== TreeNode) {
      return null;
    }
    const checked = checkedKeys.indexOf(dataKey) !== -1;
    const halfChecked = halfCheckedKeys.indexOf(dataKey) !== -1;
    const disabled = (!checked && !halfChecked && isCheckedMax)
      || child.props.disabled;
    return React.cloneElement(child, {
      deep: entity.deep,
      expanded: expandedKeys.indexOf(dataKey) !== -1,
      checked,
      halfChecked,
      onLoadChildren,
      disabled,
      disabledTitle,
      disabledContent,
      isLeaf,
    });
  };

  render() {
    const {
      prefixCls, className, style, children,
    } = this.props;

    return (
      <TreeContext.Provider
        value={{
          prefixCls,
          onNodeCheck: this.onNodeCheck,
          onNodeExpand: this.onNodeExpand,
          renderTreeNode: this.renderTreeNode,
        }}
      >
        <div className={classnames(prefixCls, className)} style={style}>
          {React.Children.map(children, this.renderTreeNode)}
        </div>
      </TreeContext.Provider>
    );
  }
}

Tree.propTypes = {
  /**
   * className prefix of tree
   */
  prefixCls: PropTypes.string,

  /**
   * className of tree
   */
  className: PropTypes.string,

  /**
   * style of tree
   */
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),

  /**
   * default checked keys
   */
  // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
  defaultCheckedKeys: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  ),

  /**
   * checked keys
   */
  // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
  checkedKeys: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  ),

  /**
   * default expanded keys
   */
  // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
  defaultExpandedKeys: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  ),

  /**
   * expanded keys
   */
  // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
  expandedKeys: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  ),

  /**
   * node check handler
   */
  onChange: PropTypes.func,

  /**
   * node expand handler
   */
  onExpand: PropTypes.func,

  /**
   * limit of checked options
   */
  maxSelected: PropTypes.number,

  /**
   * title of disabled tooltip
   */
  disabledTitle: PropTypes.string,

  /**
   * content of disabled tooltip
   */
  disabledContent: PropTypes.string,
  /*
  * Load children methods, used for campaign and creative filter.
  */
  onLoadChildren: PropTypes.func,
};

Tree.defaultProps = {
  prefixCls: 'v2_component_tree',
  className: '',
  style: {},
  onChange: noop,
  onExpand: noop,
  maxSelected: null,
  disabledTitle: '',
  disabledContent: '',
  onLoadChildren: noop,
};

export default Tree;
