import React from 'react';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import Pulsation from '../V2/Pulsation/Pulsation';
import './aplicationFinder.scss';

const Option = ({
  innerRef, innerProps, data: {
    thumbnail, platform, label, id,
  },
}) => (
  <div
    className="Option"
    {...innerProps}
    ref={innerRef}
  >
    <div className="Option__ThumbnailWrap">
      <img
        className="Option__Thumbnail"
        src={thumbnail}
      />
    </div>
    <div
      className={classNames('Option__IconWrap', `Option__IconWrap_${platform}`)}
    >
      {Icon.forPlatform(platform)}
    </div>
    <Pulsation
      editIcon="play_arrow"
      prefixCls="pulsation v2_component_pulsation"
      status="active"
    />
    <div className="Option__TextWrap">
      <div className="Option__Text">{label}</div>
      <div className="Option__Id">{id}</div>
    </div>
  </div>
);

export default Option;
