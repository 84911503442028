const categories = [
  'Battle Royale',
  'Bingo',
  'Books & Reference',
  'Brick Breaker',
  'Bubble Shooter',
  'Builder',
  'Business',
  'Card',
  'Casino: Blackjack',
  'Casino: Poker',
  'Casino: Slots',
  'Casino: Social Casino',
  'Catalogs',
  'Coloring',
  'Comics',
  'Communication',
  'Cooking',
  'Coupon',
  'Dating',
  'Education',
  'Entertainment',
  'Finance',
  'Food & Drink',
  'Games',
  'Health & Fitness',
  'Hidden Object',
  'Hypercasual: Ball',
  'Hypercasual: Io',
  'Hypercasual: Music',
  'Hypercasual: Other',
  'Hypercasual: Puzzle',
  'Hypercasual: Racing',
  'Hypercasual: Shooter',
  'Hypercasual: Sports',
  'Idle',
  'Jigsaw',
  'Lifestyle',
  'Live Wallpaper',
  'Match3',
  'Medical',
  'Music',
  'Navigation',
  'News',
  'Non-Gaming',
  'Other',
  'Personalization',
  'Photo & Video',
  'Productivity',
  'Puzzle: Board',
  'Puzzle: Number',
  'Puzzle: Other',
  'PvP',
  'RPG',
  'Racing',
  'Runner',
  'Scratcher & Sweepstakes',
  'Shooter',
  'Shopping',
  'Simulation',
  'Social',
  'Social Networking',
  'Solitaire',
  'Sports',
  'Story Based Apps',
  'Tower Defense',
  'Travel',
  'Trivia',
  'Utilities',
  'Weather',
  'Widgets',
  'Word',
];
const orientations = [
  'portrait',
  'landscape',
  'both',
];

export {
  categories,
  orientations,
};

export default {
  categories,
  orientations,
};
