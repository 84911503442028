import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon/Icon';
import Link from 'components/Link/Link';
import { Copy, TooltipCutoff } from 'components/V2';
import './tableNameColumn.scss';

const TableNameColumn = ({
  to,
  id,
  name,
  platform,
  thumbnail,
  hideThumbnail,
  renderName,
  hidePlatform,
  customIdComponent,
}) => (
  <div className="list_widgets_table_column_name">
    {!hideThumbnail && (
      <div className="thumbnailWrap">
        <img className="thumbnail" src={thumbnail} />
      </div>
    )}
    {!hidePlatform && (
      <div className={`iconWrap iconWrap_${platform?.toLowerCase()}`}>
        {Icon.forPlatform(platform?.toLowerCase())}
      </div>
    )}
    <div className="table_name">
      {renderName ? renderName() : (
        <TooltipCutoff
          title={name}
          placement="bottomLeft"
          titleWrapper={(title) => (
            to ? <Link to={to} className="body__link">{name}</Link> : title
          )}
        />
      )}
      {customIdComponent || <Copy value={id} />}
    </div>
  </div>
);

TableNameColumn.propTypes = {
  name: PropTypes.string,
  platform: PropTypes.string,
  thumbnail: PropTypes.string,
  renderName: PropTypes.func,
  to: PropTypes.string,
};

TableNameColumn.defaultProps = {
  name: undefined,
  platform: undefined,
  thumbnail: undefined,
  to: undefined,
  renderName: undefined,
};

export default TableNameColumn;
