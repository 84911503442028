const currency = '$';

const addTwoDecimals = (number) => {
  const value = parseFloat(Number(number));
  const numberString = value.toString();
  const nums = numberString.split('.');
  if (!nums[1] || nums[1].length < 2) {
    return value.toFixed(2);
  }
  return numberString;
};

// https://stackoverflow.com/a/14428340
const format = (number, noDecimalLimit) => {
  if (number === undefined) {
    return `${currency}0.00`;
  }
  const n = noDecimalLimit ? addTwoDecimals(number) : parseFloat(Number(number)).toFixed(2);
  const formatted = n.replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
  if (Number.isNaN(n)) {
    return `${currency}0.00`;
  }
  return `${currency}${formatted}`;
};

const formatTwoDecimals = (number) => `${parseFloat(number).toFixed(2)}%`;

const parseCurrency = (currencyStr) => {
  const out = parseFloat(currencyStr.replace(/[,\s$]/g, ''));
  return Number.isNaN(out) ? 0 : out;
};

export default {};
export {
  addTwoDecimals,
  format,
  formatTwoDecimals,
  parseCurrency,
};
