import { TYPE_IMAGE, TYPE_PLAYABLE, TYPE_VIDEO } from '../../../../../services/admin/views/assets/list_2/constant';

export const AD_TYPES = [
  {
    name: 'Banner',
    key: 'banner',
    id: '0',
  },
  {
    name: 'Full Screen',
    key: 'fullscreen',
    id: '1',
  },
  {
    name: 'Mrec',
    key: 'mrec',
    id: '2',
  },
];

export const ASSET_TYPES = [
  {
    id: TYPE_IMAGE,
    name: 'Image',
    children: [],
  },
  {
    id: TYPE_VIDEO,
    name: 'Video',
    children: [],
  },
  {
    id: TYPE_PLAYABLE,
    name: 'Playable',
    children: [],
  },
];
