import React from 'react';
import { Tooltip } from 'components/V2';
import './Pill.scss';

const Pill = ({
  children,
  beta,
  disabled,
  tooltipTitle,
  tooltipBody,
}) => {
  const pillContent = (
    <span className={`components__pill ${beta ? 'components__pill--beta' : ''} ${disabled ? 'is-disabled' : ''}`}>
      {children}
    </span>
  );

  return tooltipBody
    ? (
      <Tooltip
        {
          ...(
            tooltipTitle
              ? { title: tooltipTitle }
              : undefined
          )
        }
        body={tooltipBody}
        placement="bottomLeft"
        transitionOffset={1}
      >
        {pillContent}
      </Tooltip>
    )
    : pillContent;
};

export default Pill;
