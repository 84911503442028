import { validTrackingMacros } from 'config/campaign-tracking-providers';

const replaceStrings = (string, reverse = false) => {
  const { validStrings } = validTrackingMacros;
  let ns = string;
  validStrings.forEach(([o, n]) => {
    if (!reverse) {
      ns = ns.replace(o, n);
      return;
    }
    ns = ns.replace(n, o);
  });
  return ns;
};

/// match {{{abc}}} or {{{uppercase abc}}}
const isEndFormatValid = (end) => end && /^{{{.+}}}$/.test(end);

const endTest = (end) => {
  if (!isEndFormatValid(end)) {
    return false;
  }

  let macroList = [];
  macroList = end.replace(/^{{{/g, '').replace(/}}}$/g, '').split(' ');

  if (macroList.length === 1 && !validTrackingMacros.macros[macroList[0]]) {
    return false;
  }
  if (macroList.length === 2 && (!validTrackingMacros.macros[macroList[1]] || !validTrackingMacros.helpers[macroList[0]])) {
    return false;
  }
  if (macroList.length > 2) {
    return false;
  }

  return true;
};

const startAndEndTest = (start, end) => {
  const isStartValid = /\w/.test(start);
  if (!isStartValid) {
    return false;
  }
  if (end.indexOf('http') === 0) {
    return true;
  }
  const ends = end.match(/{+[^{}]*}+/g);
  return !ends || ends.every((x) => endTest(x));
};

const isValidMacroURL = (incomingUrl) => {
  try {
    const url = replaceStrings(incomingUrl);
    const nlsr = /[\n\r\s]+/g;
    const newLineSpaceRegex = new RegExp(nlsr);
    if (newLineSpaceRegex.test(url)) {
      return false;
    }
    const baseRest = url.split('://')[1];
    const queryRest = baseRest.split('?')[1];
    const params = queryRest.split('&');
    const isValid = (param) => {
      const [start, endPre] = param.split('=');
      const startCurlyTest = new RegExp(/[{}]/);
      const startHasCurlies = startCurlyTest.test(start);
      if (startHasCurlies) {
        return false;
      }
      const end = decodeURIComponent(endPre);
      if (end.indexOf('http') === 0) {
        return true;
      }
      return startAndEndTest(start, end);
    };
    let isUrlValid = true;
    params.forEach((p) => {
      isUrlValid = isUrlValid ? isValid(p) : false;
    });
    return isUrlValid;
  } catch (ex) {
    return false;
  }
};

export {
  replaceStrings,
  startAndEndTest,
  isValidMacroURL,
  isEndFormatValid,
};
