import React from 'react';
import PropTypes from 'prop-types';
import Group from 'templates/input-group/InputGroupContainer';
import {
  getOptimizationTypeDescription,
  getOptimizationTypeLabel,
  getOptimizationTypes,
  isCampaignObjectiveCPEIap,
  isCampaignObjectiveCPPUIap,
  isCampaignObjectiveDynamic,
  isCampaignObjectiveRoasIap,
  hasCPEIapOptimizationTypeAccess,
  hasCPPUIapOptimizationTypeAccess,
  hasRoasIapOptimizationTypeAccess,
} from 'lib/capabilities/campaigns';
import {
  hasTiersMeetingPayingUsersRequirement,
  hasTiersMeetingTargetCPEEventRequirement,
} from 'lib/capabilities/dynamic-geos';
import {
  BUDGET_OBJECTIVE_DYNAMIC,
  BUDGET_OBJECTIVE_STATIC,
  DYNAMIC_GEOS_REQUEST_START,
  NUM_OF_PAYING_USERS_THRESHOLD,
  NUM_OF_EVENTS_THRESHOLD,
  EVENTS_METRIC,
  PAYING_USERS_METRIC,
  EVENTS_METRIC_ACTION,
  PAYING_USERS_METRIC_ACTION,
} from 'app/constants/campaign';
import Campaign from 'models/Campaign';
import InfoBar from 'components/InfoBar/InfoBar';
import Link from 'components/Link/Link';
import radio from '../radio';
import TargetGoal from '../../../../../../components/BudgetFieldDynamicIap/TargetGoal';
import './OptimizationTypeSelection.scss';

const { Field } = Group;

const HELP_LINK_URL = 'https://support.vungle.com/hc/en-us/articles/115004827347#introduction-0-0';

export function getIsShowInsufficientPayingUsersInfo(campaign, isShowNotEnoughPostInstallInfo) {
  const isROAS = isCampaignObjectiveRoasIap(campaign) && hasRoasIapOptimizationTypeAccess(campaign);
  const isCPPU = isCampaignObjectiveCPPUIap(campaign) && hasCPPUIapOptimizationTypeAccess(campaign);
  const isCPE = isCampaignObjectiveCPEIap(campaign) && hasCPEIapOptimizationTypeAccess(campaign);
  const countryStats = campaign.get('application.pieCountryStatsForTargetCPEEvent') ?? [];
  const dynamicTierGeos = campaign.get('dynamicGeos') ?? [];

  if (isShowNotEnoughPostInstallInfo) return false;
  if (isCPE && countryStats.length > 0) return !hasTiersMeetingTargetCPEEventRequirement(countryStats);
  if ((isROAS || isCPPU) && dynamicTierGeos.length > 0) return !hasTiersMeetingPayingUsersRequirement(dynamicTierGeos);

  return false;
}

export default function OptimizationTypeSelection({
  campaign,
}) {
  const optimizationTypes = getOptimizationTypes(campaign);
  const application = campaign.get('application');
  const notEnoughPostInstallEvents = application?.get('mmpIntegrationStatus.dynamicIapIntegration.notEnoughPostInstallEvents');
  const isROAS = isCampaignObjectiveRoasIap(campaign) && hasRoasIapOptimizationTypeAccess(campaign);
  const isCPPU = isCampaignObjectiveCPPUIap(campaign) && hasCPPUIapOptimizationTypeAccess(campaign);
  const isCPE = isCampaignObjectiveCPEIap(campaign) && hasCPEIapOptimizationTypeAccess(campaign);
  const isShowNotEnoughPostInstallInfo = (isROAS || isCPPU || isCPE) && notEnoughPostInstallEvents;
  const isShowInsufficientPayingUsersInfo = getIsShowInsufficientPayingUsersInfo(campaign, isShowNotEnoughPostInstallInfo);
  const minRequiredCount = isCPE ? NUM_OF_EVENTS_THRESHOLD : NUM_OF_PAYING_USERS_THRESHOLD;
  const metricName = isCPE ? EVENTS_METRIC : PAYING_USERS_METRIC;
  const metricAction = isCPE ? EVENTS_METRIC_ACTION : PAYING_USERS_METRIC_ACTION;
  const optimizationType = campaign.get('budget.objective.type') === BUDGET_OBJECTIVE_STATIC
    ? BUDGET_OBJECTIVE_STATIC
    : BUDGET_OBJECTIVE_DYNAMIC;
  const optimizationTypeLabel = campaign.get('budget.objective.type')?.toUpperCase();
  const id = application?.get('id');
  const name = application?.get('name');

  const AppLink = () => (
    <Link
      to={`/applications/${id}`}
    >
      {name}
    </Link>
  );

  function onOptTypeSelection(type) {
    campaign.updateOptimizationType(type);
  }

  return (
    <div
      className="budget-optimization"
    >
      <Field
        name="Optimization Type"
        description={`Manual bid campaigns can target various outcomes and offer multi-bidding as an option. Automatic 
                      campaigns dynamically optimize bids based on the selected Target Goal.`}
      >
        {radio(
          campaign,
          'budget.objective.type',
          optimizationTypes.map(
            (type) => ([
              type,
              (
                <span
                  key={`optimization-type-${type}`}
                  className="budget-optimization-type"
                >
                  <span
                    className="budget-optimization-type--label"
                  >
                    {getOptimizationTypeLabel(type)}
                  </span>
                  {' '}
                  {getOptimizationTypeDescription(type, campaign)}
                </span>
              ),
            ]),
          ),
          true,
          false,
          onOptTypeSelection,
          false,
          optimizationType,
        )}
        {
          isShowNotEnoughPostInstallInfo && (
            <InfoBar
              type="error"
              text={(
                <div className="budget-optimization-type">
                  <div className="dynamic-int-error--heading">
                    MMP Integration Required for
                    {' '}
                    {optimizationTypeLabel}
                    {' '}
                    Optimization
                  </div>
                  <p className="dynamic-int-error--body">
                    {optimizationTypeLabel}
                    {' '}
                    optimization cannot be used for this Campaign because its parent Application
                    {' '}
                    <AppLink />
                    {' '}
                    is not currently receiving Post-Install Events from a qualified MMP.
                    Note that once you begin sending postbacks from your MMP, it may take up to 24 hours before MMP
                    Integration is registered.
                  </p>
                  <Link
                    to={HELP_LINK_URL}
                    external
                  >
                    Learn About Sending Post-Install Events.
                  </Link>
                </div>
              )}
            />
          )
        }
        {
          isShowInsufficientPayingUsersInfo && (
            <InfoBar
              type="warning"
              text={(
                <div className="budget-optimization-type">
                  <div className="dynamic-int-error--heading">
                    Insufficient
                    {' '}
                    {metricName}
                  </div>
                  <div className="dynamic-int-error--body">
                    Over the last
                    {' '}
                    {DYNAMIC_GEOS_REQUEST_START + 1}
                    {' '}
                    days, the Application
                    {' '}
                    <AppLink />
                    ,
                    {' '}
                    has not received the minimum number of
                    {' '}
                    {minRequiredCount}
                    {' '}
                    {metricName.toLowerCase()}
                    {' '}
                    in at least 1 Geo Tier required to optimize Campaigns toward
                    {' '}
                    {optimizationTypeLabel}
                    {' '}
                    objectives.
                    {' '}
                    {optimizationTypeLabel}
                    {' '}
                    optimization may not
                    perform correctly until sufficient
                    {' '}
                    {metricName.toLowerCase()}
                    {' '}
                    are
                    {' '}
                    {metricAction.toLowerCase()}
                    .
                    <br />
                    <ul>
                      <li>
                        To correct this, you can try increasing the budget for this Campaign or any other Campaign under
                        {' '}
                        <AppLink />
                        .
                      </li>
                    </ul>
                  </div>
                  <Link
                    to={HELP_LINK_URL}
                    external
                  >
                    Learn More About How to Increase
                    {' '}
                    {metricAction}
                    {' '}
                    {metricName}
                    .
                  </Link>
                </div>
              )}
            />
          )
        }
      </Field>
      {isCampaignObjectiveDynamic(campaign) && (
        <TargetGoal
          campaign={campaign}
        />
      )}
    </div>
  );
}

OptimizationTypeSelection.propTypes = {
  campaign: PropTypes.instanceOf(Campaign).isRequired,
};
