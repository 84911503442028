import React from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { initPagination } from '../../constant';
import Panel from './Panel';

const BackendPanel = ({
  className,
  tree,
  visible,
  nodes,
  loading,
  pagination,
  menuNodes,
  menuLoading,
  menuPagination,
  renderNode,
  renderLeaf,
  placeholder,
  maxSelected,
  disabledTitle,
  disabledContent,
  checkableNodes,
  onLoadChildren,
  defaultCheckedNodes,
  onVisibleChange,
  onLoadNodes,
  onLoadMenu,
  onChange,
}) => (
  <Panel
    className={className}
    tree={tree}
    visible={visible}
    menuNodes={menuNodes}
    loading={loading}
    nodes={nodes}
    renderNode={renderNode}
    renderLeaf={renderLeaf}
    placeholder={placeholder}
    maxSelected={maxSelected}
    disabledTitle={disabledTitle}
    disabledContent={disabledContent}
    pagination={pagination}
    menuLoading={menuLoading}
    checkableNodes={checkableNodes}
    menuPagination={menuPagination}
    defaultCheckedNodes={defaultCheckedNodes}
    onVisibleChange={onVisibleChange}
    onLoadChildren={onLoadChildren}
    onChange={onChange}
    onLoadMore={onLoadNodes}
    onLoadMenu={onLoadMenu}
  />
);

BackendPanel.propTypes = {
  className: PropTypes.string,
  tree: PropTypes.bool,
  visible: PropTypes.bool,
  renderNode: PropTypes.func,
  renderLeaf: PropTypes.func,
  placeholder: PropTypes.string,
  maxSelected: PropTypes.number,
  menuNodes: PropTypes.arrayOf(PropTypes.any),
  menuLoading: PropTypes.bool,
  menuPagination: PropTypes.objectOf(PropTypes.any),
  disabledTitle: PropTypes.string,
  disabledContent: PropTypes.string,
  checkableNodes: PropTypes.arrayOf(PropTypes.any),
  defaultCheckedNodes: PropTypes.arrayOf(PropTypes.any),
  onVisibleChange: PropTypes.func,
  onLoadChildren: PropTypes.func,
  onLoadMenu: PropTypes.func,
  onChange: PropTypes.func,
};

BackendPanel.defaultProps = {
  className: '',
  tree: false,
  visible: false,
  placeholder: '',
  disabledTitle: '',
  disabledContent: '',
  maxSelected: 0,
  menuNodes: [],
  menuLoading: false,
  menuPagination: initPagination,
  renderNode: noop,
  renderLeaf: noop,
  checkableNodes: [],
  defaultCheckedNodes: [],
  onVisibleChange: noop,
  onLoadChildren: noop,
  onLoadMenu: noop,
  onChange: noop,
};

export default React.memo(BackendPanel);
