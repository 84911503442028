import React from 'react';
import classNames from 'classnames';
import { isFunction } from 'lodash';
import Button from '../../Button/Button';

const defaultMessage = 'Are you sure you want to complete this action?';
const Confirm = ({
  header = '',
  name = '',
  cancelText = 'No',
  confirmText = 'Yes',
  message = defaultMessage,
  bodyAdditional,
  onCancel = () => {},
  onConfirm = () => {},
  onConfirmAlternate,
  confirmTextAlternate,
  confirmOnly = false,
  className = '',
  confirmLoading = false,
}) => (
  <div className={classNames('components__modals__confirm_action', className)}>
    <div className="components__modals__confirm_action__content" onClick={onCancel}>
      <div className="components__modals__confirm_action__content__container" onClick={(e) => { e.stopPropagation(); }}>
        <div className="components__modals__confirm_action__content__container__header">
          <span>{header}</span>
        </div>
        <div className="components__modals__confirm_action__content__container__body">
          <p>{name}</p>
          <p>
            {
              isFunction(message)
                ? message()
                : message
            }
          </p>
          {bodyAdditional && (
            <p className="components__modals__confirm_action__content__container__body_additional">
              {bodyAdditional}
            </p>
          )}
        </div>
        <div className="components__modals__confirm_action__content__container__buttons">
          { confirmOnly === true ? (
            <Button onClick={onConfirm}>Got it!</Button>
          ) : (
            <>
              <Button cancel onClick={onCancel}>{cancelText}</Button>
              <Button loading={confirmLoading} onClick={onConfirm}>{confirmText}</Button>
              {
                onConfirmAlternate && confirmTextAlternate && (
                  <Button onClick={onConfirmAlternate}>{confirmTextAlternate}</Button>
                )
              }
            </>
          )}
        </div>
      </div>
    </div>
  </div>
);

export default Confirm;
