import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { TYPE_VIDEO, TYPE_PLAYABLE } from '../../../../../services/admin/views/assets/list_2/constant';
import { ns as accountHierNs } from '../../../../../services/Templates/AccountHier/actions';
import AssetTypeImage from '../../../../../assets/img/asset_type_image.svg';
import AssetTypeVideo from '../../../../../assets/img/asset_type_video.svg';
import AssetTypeGame from '../../../../../assets/img/asset_type_game.svg';
import { initPagination, onePagePagination } from '../../constant';
import {
  getAssetLoadMenu,
  getAssetNodes,
  getCheckedAssets,
  filterTreeNodes,
} from './helper';
import BackendPanel from './BackendPanel';
import './assetPanel.scss';

const prefixClass = 'v2_component_filter_panel_asset';

const renderNode = ({ node }) => {
  const { id, name } = node;
  let thumbnail = AssetTypeImage;
  if (id === TYPE_VIDEO) {
    thumbnail = AssetTypeVideo;
  } else if (id === TYPE_PLAYABLE) {
    thumbnail = AssetTypeGame;
  }

  return (
    <div className={classNames(`${prefixClass}-header`)}>
      <img src={thumbnail} />
      <span>{name}</span>
    </div>
  );
};

const AssetPanel = ({
  visible,
  filterKey,
  maxSelected,
  availableNodes,
  defaultCheckedNodes,
  searchParams,
  onVisibleChange,
  onChange,
}) => {
  const [nodes, setNodes] = useState([]);
  const [pagination, setPagination] = useState(initPagination);

  const [menuNodes, setMenuNodes] = useState([]);
  const [menuLoading, setMenuLoading] = useState(false);
  const [menuPagination, setMenuPagination] = useState(initPagination);

  const [checkableNodes, setCheckableNodes] = useState([]);
  const [checkedNodes, setCheckedNodes] = useState(defaultCheckedNodes);

  const authUser = useSelector((state) => state.authUser);
  const accountHier = useSelector((state) => state[accountHierNs]) || {};

  const account = useMemo(() => accountHier.account || authUser.account, [accountHier, authUser]);

  const onLoadNodes = useCallback(async () => {
    const result = await getAssetNodes({ ...searchParams, account });
    setNodes(result.response);
    setPagination(result.pagination);
  }, [searchParams, account]);

  useEffect(() => {
    if (availableNodes.length) {
      setNodes(availableNodes);
      setPagination(onePagePagination);
    } else if (visible) {
      onLoadNodes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, availableNodes]);

  useEffect(() => {
    setCheckableNodes(nodes.reduce((acc, app) => acc.concat(app.children), []));
  }, [nodes]);

  const onLoadMenu = useCallback(async ({ search, page }) => {
    if (availableNodes.length) {
      setMenuNodes(filterTreeNodes(availableNodes, search));
      setMenuPagination(onePagePagination);
    } else {
      const prevNodes = page === 1 ? [] : menuNodes;
      setMenuLoading(true);
      const result = await getAssetLoadMenu({
        prevNodes,
        params: {
          ...searchParams,
          account,
          search,
          page,
        },
      });
      setMenuNodes(result.response);
      setMenuPagination(result.pagination);
      setMenuLoading(false);
    }
  }, [menuNodes, searchParams, account, availableNodes]);

  const onCheckedChange = useCallback((_checkedNodes) => {
    setCheckedNodes(_checkedNodes);
    onChange(getCheckedAssets(nodes, _checkedNodes));
  }, [nodes, onChange]);

  return (
    <BackendPanel
      tree
      visible={visible}
      nodes={nodes}
      pagination={pagination}
      menuNodes={menuNodes}
      menuLoading={menuLoading}
      menuPagination={menuPagination}
      checkableNodes={checkableNodes}
      renderLeaf={(node) => <div className={classNames(`${prefixClass}-content`)}>{node.name}</div>}
      renderNode={(node) => renderNode({ filterKey, node })}
      maxSelected={maxSelected}
      disabledTitle="Limit Reached"
      disabledContent={`You have selected (${maxSelected}) ${filterKey}s.`}
      placeholder={`Search ${filterKey} name or id...`}
      defaultCheckedNodes={checkedNodes}
      onVisibleChange={onVisibleChange}
      onChange={onCheckedChange}
      onLoadMenu={onLoadMenu}
      onLoadNodes={noop}
    />
  );
};

AssetPanel.propTypes = {
  visible: PropTypes.bool,
  maxSelected: PropTypes.number,
  filterKey: PropTypes.string.isRequired,
  availableNodes: PropTypes.arrayOf(PropTypes.any),
  defaultCheckedNodes: PropTypes.arrayOf(PropTypes.any),
  onVisibleChange: PropTypes.func,
  onChange: PropTypes.func,
};

AssetPanel.defaultProps = {
  visible: false,
  maxSelected: 0,
  availableNodes: [],
  defaultCheckedNodes: [],
  onVisibleChange: noop,
  onChange: noop,
};

export default React.memo(AssetPanel);
