import React from 'react';
import { constants } from 'accesscontrol';
import Button from '../../../../../components/Button/Button';
import Link from '../../../../../components/Link/Link';
import Select from '../../../../../components/Form/Select/SelectContainer';
import Text from '../../../../../components/Form/Text/TextContainer';
import Group from '../../../../../templates/input-group/InputGroupContainer';
import Status from '../../../../../templates/status/status';

const { Field } = Group;
const { Option } = Select;

const Edit = ({ props: { user }, actions: { saveUser } }) => (
  <div className="views__team__member-edit">
    <Group title="Edit Team Member">
      <Field name="Status" description="The user's account status">
        <Status status={user.get('status')} />
      </Field>
      <Field name="Full name" description="The user's full name">
        <Text style={{ fontSize: 14 }} noIcon value={user.get('name')} onChange={(v) => user.set('name', v)} />
      </Field>
      <Field name="Email" description="The user's email address">
        <Text disabled style={{ fontSize: 14 }} noIcon value={user.get('email')} />
      </Field>
      <Field name="Access" description="Access level for this user">
        <Select value={user.raw('role')} onChange={(v) => user.set('role', v)}>
          <Option value={constants.CustomerAccountAdmin}>Customer Account Admin</Option>
          <Option value={constants.CustomerAccountReader}>Customer Account Reader</Option>
          <Option value={constants.CustomerAccountManager}>Customer Account Manager</Option>
        </Select>
      </Field>
      <div className="save-button">
        <Link to="/team">
          <Button cancel>Go back</Button>
        </Link>
        <Button
          disabled={!user.get('name') || !user.get('email') || !user.get('role')}
          onClick={() => saveUser(user)}
        >
          Save
        </Button>
      </div>
    </Group>
  </div>
);

export default Edit;
