import React, { useRef } from 'react';
import Text from '../../components/Form/Text/TextContainer';
import Toggler from '../../components/Form/Toggler/Toggler';
import { getTestId } from '../../lib/testSupport';
import './togglebox.scss';

export const TEST_ID = 'ToggleBox';

const ToggleBox = ({
  children,
  checked,
  disabled,
  onChange,
  onToggle,
  value,
  placeholder = 'Enter value',
  numeric,
  isValid,
  validationMsg,
  'data-testid': parentTestId,
}) => {
  const textRef = useRef();
  const onToggleChange = (toggleValue) => {
    onToggle(toggleValue);
    if (textRef.current) {
      textRef.current.clear();
    }
  };
  return (
    <div className="template__toggle-box">
      <div className="toggler">
        <Toggler
          checked={checked}
          disabled={disabled}
          onChange={onToggleChange}
          data-testid={getTestId(parentTestId, TEST_ID)}
        />
      </div>
      <div className="children">
        {children && (
          children
        )}
        {!children && (checked && !disabled) && (
          <Text
            noIcon
            textRef={textRef}
            value={checked ? (value || '') : ''}
            onChange={onChange}
            isValid={isValid}
            placeholder={checked ? placeholder : 'DISABLED'}
            validationMsg={validationMsg}
            numeric={numeric}
          />
        )}
        {!isValid && (
          <p className="p2 invalid_text">{validationMsg}</p>
        )}
      </div>
    </div>
  );
};

export default ToggleBox;
