import React from 'react';
import Link from '../Link/Link';

const ContextMenuLinkOption = ({
  goBack, external = false, icon, image, text, to, suffixIcon,
}) => (
  <Link to={to} goBack={goBack} external={external}>
    <div className="components__context_menu_option">
      <div className="content-wrapper">
        {icon && (
          <i className="material-icons">{icon}</i>
        )}
        {
          image && (
            <img className="image" src={image} />
          )
        }
        <span>{text}</span>
      </div>
      {suffixIcon && (
        <i className="material-icons suffix-icon">{suffixIcon}</i>
      )}
    </div>
  </Link>
);

export default ContextMenuLinkOption;
