import React, {
  compose, Switch, Route, Redirect,
} from '../../../../app/app';
import List from './List/ListContainer';
import './index.scss';

const baseRoute = '/campaigns';

const Campaigns = () => (
  <div className="views__campaigns">
    <Switch>
      <Route exact path={baseRoute} component={List} />
      <Redirect to={baseRoute} />
    </Switch>
  </div>
);

export default compose(Campaigns);
