import React from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import MultiTree from '../MultiTree/MultiTree';

const MultiTreeBackend = ({
  dropdownFixed,
  visible,
  filterKey,
  renderOverlay,
  checkableNodes,
  defaultCheckedNodes,
  onVisibleChange,
  onRemove,
}) => (
  <MultiTree
    isBackendTree
    dropdownFixed={dropdownFixed}
    visible={visible}
    filterKey={filterKey}
    onVisibleChange={onVisibleChange}
    checkableNodes={checkableNodes}
    defaultCheckedNodes={defaultCheckedNodes}
    renderOverlay={renderOverlay}
    onRemove={onRemove}
  />
);

MultiTreeBackend.propTypes = {
  dropdownFixed: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
  filterKey: PropTypes.string.isRequired,
  renderOverlay: PropTypes.func.isRequired,
  checkableNodes: PropTypes.arrayOf(PropTypes.any),
  defaultCheckedNodes: PropTypes.arrayOf(PropTypes.any),
  onVisibleChange: PropTypes.func,
  onRemove: PropTypes.func,
};

MultiTreeBackend.defaultProps = {
  checkableNodes: [],
  defaultCheckedNodes: [],
  dropdownFixed: false,
  onVisibleChange: noop,
  onRemove: noop,
};

export default React.memo(MultiTreeBackend);
