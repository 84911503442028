import React from 'react';
import { RATE_TYPES_DESCRIPTIONS } from 'app/constants/campaign';
import Group from 'templates/input-group/InputGroupContainer';
import Radio from 'components/Form/Radio/Radio';

const { Field } = Group;

function BudgetType({
  campaign,
  bidBudgetValuesModified,
}) {
  const type = campaign.raw('budget.type');
  if (type === 'install') {
    return null;
  }

  const budgetTypes = [];
  if (campaign.isSkadnetworkAttribution()) {
    if (type === 'target_cpi') {
      return null;
    }
    budgetTypes.push({ id: 'target_cpi' });
  } else {
    budgetTypes.push({ id: 'install' });
  }
  budgetTypes.push({ id: type });

  return (
    <Field name="Bid Type" description="Select a bidding model for this campaign">
      <Radio
        name="campaign_bid_type"
        value={campaign.raw('budget.type')}
        disabled={campaign.get('budget.publisher_rates').length > 0}
        onChange={(v) => {
          bidBudgetValuesModified();
          campaign.setBudgetType(v);
        }}
      >
        {
          budgetTypes.map(({ id }) => (
            <Radio.Option
              key={id}
              value={id}
              label={RATE_TYPES_DESCRIPTIONS[id]}
            />
          ))
        }
      </Radio>
    </Field>
  );
}

export default BudgetType;
