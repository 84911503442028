const init = (user, cb = () => {}) => {
  try {
    window.analytics.identify(user.username, {
      userId: user.id,
      accountId: user.account,
      accountName: user.account_name,
      username: user.username,
    }, {}, cb);
  } catch (ex) {
    // do nothing
  }
};

const track = (event, props) => {
  try {
    window.analytics.track(event, props);
  } catch (ex) {
    // do nothing
  }
};

export { init, track };
