import React from 'react';
import PropTypes from 'prop-types';
import Thumbnail from '../../../../../components/V2/Application/Thumbnail';
import {
  CampaignCreativeMapping as Mapping,
} from '../../../../../CustomPropTypes';
import Application from '../../../../../models/Application';
import './CampaignCreativeMappings.scss';

const CampaignCreativeMappings = ({
  application,
  mappings,
}) => {
  if (!application || !mappings) {
    return null;
  }

  const name = application.get('name');
  const platform = application.get('platform');
  const thumbnail = application.get('store.thumbnail');

  return (
    <div
      className="campaign_creative_map"
    >
      <div
        className="campaign_creative_map__app_header"
      >
        <Thumbnail
          source={thumbnail}
          platform={platform}
        />
        <span
          className="campaign_creative_map__app_header__name"
        >
          {name}
        </span>
      </div>
    </div>
  );
};

CampaignCreativeMappings.propTypes = {
  application: PropTypes.instanceOf(Application),
  mappings: PropTypes.arrayOf(Mapping),
};

CampaignCreativeMappings.defaultProps = {
  application: undefined,
  mappings: undefined,
};

export default CampaignCreativeMappings;
