import React from 'react';
import { format } from 'lib/currency';
import BudgetOverrideField from 'services/advertiser/views/campaigns/Edit/Strategy/BudgetOverrideField';
import {
  getBidLimits,
  getBudgetLimits,
  setGeoBudget,
} from 'lib/helpers/campaigns/campaignIndex';
import Radio from 'components/Form/Radio/Radio';
import Group from 'templates/input-group/InputGroupContainer';
import {
  CollapseSection,
  MultiBidding,
} from 'components/V2';
import Link from 'components/Link/Link';
import InfoBar from 'components/InfoBar/InfoBar';
import {
  hasCPEIapOptimizationTypeAccess,
  hasCPPUIapOptimizationTypeAccess,
  hasRoasIapOptimizationTypeAccess,
  isCampaignObjectiveStatic,
  isCampaignObjectiveCPEIap,
  isCampaignObjectiveCPPUIap,
  isCampaignObjectiveRoasIap,
  isInvalidCPCUser,
  supportsMultiBidding,
  getRateTypeSettingsByName,
} from 'lib/capabilities/campaigns';
import OptimizationTypeSelection
from 'services/admin/views/campaigns/edit/OptimizationTypeSelection/OptimizationTypeSelection';
import TargetEventName from 'components/BudgetFieldDynamicIap/TargetEventName';
import TargetHorizon from 'components/BudgetFieldDynamicIap/TargetHorizon';
import TargetReturn from 'components/BudgetFieldDynamicIap/TargetReturn';
import Alert from 'components/Alert/Alert';
import {
  CPE_TARGET_MAX,
  CPE_TARGET_MIN,
  CPPU_TARGET_MAX,
  CPPU_TARGET_MIN,
  ROAS_TARGET_MAX,
  ROAS_TARGET_MIN,
} from 'app/constants/campaign';
import GeoTarget from 'services/Templates/Campaigns/GeoTarget/GeoTarget';
import GeoDailyBudget from '../../../../../Templates/GeoDailyBudget/GeoDailyBudget';
import BudgetType from './BudgetType';

const { Field } = Group;
const percentMultiplier = 100;

const targetCpiText = (
  <div>
    <span>Learn more about </span>
    <Link
      external
      to="https://support.vungle.com/hc/en-us/articles/360058595652"
    >
      best practices and information on Target CPI.
    </Link>
  </div>
);

const getBidDescription = (campaign) => {
  const { max, min } = getBidLimits(campaign);
  return `(Min: ${format(min, true)} - Max: ${format(max, true)})`;
};

const getDailyDescription = (campaign) => {
  const { max, min } = getBudgetLimits('daily', campaign);
  return (
    <>
      Set a target maximum budget per day. A null or $0 value means there is no daily limit.
      Combining a high bid and low limit may cause an overshoot of this target.
      <br />
      {`(Min: ${format(min)} - Max: ${format(max)})`}
    </>
  );
};

const getTotalDescription = (campaign) => {
  const { min } = getBudgetLimits('total', campaign);
  return (
    <>
      Target budget for the entire campaign.
      <br />
      {`Min: ${format(min)}`}
    </>
  );
};

const Strategy = ({
  campaign,
  actions: { bidBudgetValuesModified, setupMultiBiddingEditValues, updatePageContinue },
}) => {
  const isGeoBudgetType = campaign.get('budget.daily_spend_limit_type') !== 'geo';
  const showMultiBidding = supportsMultiBidding(campaign);
  const isRoasIapCampaign = isCampaignObjectiveRoasIap(campaign);
  const isCPPUIapCampaign = isCampaignObjectiveCPPUIap(campaign);
  const isCPEIapCampaign = isCampaignObjectiveCPEIap(campaign);
  const hasRoasAccess = hasRoasIapOptimizationTypeAccess(campaign);
  const hasCPPUAccess = hasCPPUIapOptimizationTypeAccess(campaign);
  const hasCPEAccess = hasCPEIapOptimizationTypeAccess(campaign);
  const canNotAlterRoas = !hasRoasAccess && isRoasIapCampaign;
  const canNotAlterCPPU = !hasCPPUAccess && isCPPUIapCampaign;
  const canNotAlterCPE = !hasCPEAccess && isCPEIapCampaign;
  const cppuRateTypeSettings = getRateTypeSettingsByName(campaign, 'CPPU');
  const cpeRateTypeSettings = getRateTypeSettingsByName(campaign, 'CPE');

  return (
    <div className="views__advertiser__campaigns__edit__strategy">
      <CollapseSection
        title="Bidding"
        description="Further enhance your campaign strategy by selecting your bidding model."
      >
        {
          (canNotAlterRoas || canNotAlterCPPU || canNotAlterCPE)
            ? <Alert.Warn>You cannot alter this Campaign&apos;s bidding parameters because it is an Automated Bidding Campaign.</Alert.Warn>
            : <OptimizationTypeSelection campaign={campaign} />
        }
        {isCampaignObjectiveStatic(campaign) && (
          <div
            className="objective_fields__static"
          >
            {
              campaign.showTcpiBanner() && (
                <InfoBar
                  text={targetCpiText}
                  type="info"
                />
              )
            }
            <BudgetType
              campaign={campaign}
              bidBudgetValuesModified={bidBudgetValuesModified}
            />
            <BudgetOverrideField
              campaign={campaign}
              description={getBidDescription(campaign)}
              disabled={isInvalidCPCUser(campaign.get('budget.type'))}
              numeric
              name={campaign.get('budget.type') === 'TARGET_CPI' ? 'Target CPI' : 'Bid Amount'}
              type="bid"
              bidBudgetValuesModified={bidBudgetValuesModified}
            />
          </div>
        )}
        {hasRoasAccess && isRoasIapCampaign && (
          <>
            <TargetHorizon />
            <TargetReturn
              campaign={campaign}
              name="Target ROAS"
              description={`The cumulative amount of revenue you wish to return by the end of the Target Horizon Date. 
                            For example if you want to spend $100 and to earn at least $5 by the end of the Target Horizon 
                            Date, set Target ROAS to "5".`}
              type="PercentMultiplier"
              min={ROAS_TARGET_MIN * percentMultiplier}
              max={ROAS_TARGET_MAX * percentMultiplier}
              metric="%"
              targetOverrideLabel="Target ROAS"
              targetOverrideTooltipMessage={`Override Target ROAS when actual campaign performance deviates from intended 
                                            goals. This hidden metric can be adjusted to fine-tune outcomes and align with 
                                            customer expectations.`}
            />
          </>
        )}
        {hasCPPUAccess && isCPPUIapCampaign && (
          <>
            <TargetHorizon />
            <TargetReturn
              campaign={campaign}
              name="Target Cost per Paying User"
              description={`The target cost you are willing to pay in order to acquire a Paying User by the end of the Target 
                          Horizon Date. For example if you are willing to spend $100 and to earn a user that has made at least 
                          one paying transaction by the end of the Target Horizon Date, set Target Cost per Paying User to "100".`}
              type="number"
              min={cppuRateTypeSettings?.min || CPPU_TARGET_MIN}
              max={cppuRateTypeSettings?.max || CPPU_TARGET_MAX}
              metric="$"
              targetOverrideLabel="Target CPPU"
              targetOverrideTooltipMessage={`Override Target CPPU when actual campaign performance deviates from intended goals. 
                                            This hidden metric can be adjusted to fine-tune outcomes and align with customer 
                                            expectations.`}
            />
          </>
        )}
        {hasCPEAccess && isCPEIapCampaign && (
          <>
            <TargetEventName campaign={campaign} />
            <TargetHorizon />
            <TargetReturn
              campaign={campaign}
              name="Target CPE"
              description={`The target cost you are willing to pay in order to acquire a User who generates a designated event by 
                            the end of the Target Horizon Date. For example if you are willing to spend $100 to acquire a user who 
                            generates a designated event by the end of the Target Horizon Date, set Target CPE to "100".`}
              type="number"
              min={cpeRateTypeSettings?.min || CPE_TARGET_MIN}
              max={cpeRateTypeSettings?.max || CPE_TARGET_MAX}
              metric="$"
              targetOverrideLabel="Target CPE"
              targetOverrideTooltipMessage={`Override Target CPE when actual campaign performance deviates from intended goals. 
                                            This hidden metric can be adjusted to fine-tune outcomes and align with customer 
                                            expectations.`}
            />
          </>
        )}
      </CollapseSection>
      <GeoTarget campaign={campaign} />
      {showMultiBidding && (
        <MultiBidding
          campaign={campaign}
          setupMultiBiddingEditValues={setupMultiBiddingEditValues}
          updatePageContinue={updatePageContinue}
        />
      )}
      <CollapseSection title="Daily & Total Budget" description="Daily budget options available for your campaign">
        <BudgetOverrideField
          campaign={campaign}
          description={getTotalDescription(campaign)}
          numeric
          name="Total Budget"
          type="total"
        />
        <Field name="Daily Budget Option" description="Daily Budget Options are available">
          <Radio
            value={campaign.get('budget.daily_spend_limit_type')}
            onChange={(value) => {
              campaign.set('budget.daily_spend_limit_type', value);
              setGeoBudget(campaign);
              if (value === 'geo') {
                campaign.geoDailySpend();
              }
            }}
          >
            <Radio.Option value="campaign" label="Single daily budget for all Countries" />
            <Radio.Option value="geo" label="Daily Budget by Country" />
          </Radio>
        </Field>
        {
          isGeoBudgetType && (
            <BudgetOverrideField
              campaign={campaign}
              description={getDailyDescription(campaign)}
              numeric
              name="Daily Budget"
              placeholder="Daily Budget"
              type="daily"
              bidBudgetValuesModified={bidBudgetValuesModified}
            />
          )
        }
      </CollapseSection>
      <GeoDailyBudget campaign={campaign} />
    </div>
  );
};

export default Strategy;
