import React from 'react';
import PropTypes from 'prop-types';

function TableHeader({
  sortable,
  column,
  sortedColumns,
}) {
  const isColumnSortable = typeof column.sortable === 'boolean' ? column.sortable : sortable;
  const Header = column.Header instanceof Function ? column.Header(column) : column.Header;
  // Current column not sortable
  if (!isColumnSortable) {
    return Header;
  }
  const sortedColumn = sortedColumns.find(({ id }) => id === column.id);
  if (sortedColumn) {
    return (
      <span className="p9">
        {Header}
        {' '}
        <i className="material-icons -sort-icon">{`expand_${sortedColumn.desc ? 'more' : 'less'}`}</i>
      </span>
    );
  }
  // Current column sortable but not sorted
  return (
    <span className="p8">{Header}</span>
  );
}

TableHeader.propTypes = {
  /**
   * All columns is sortable or not
   */
  sortable: PropTypes.bool.isRequired,
  /**
   * column of the table
   */
  column: PropTypes.shape({
    Header: PropTypes.oneOfType([
      PropTypes.elementType,
      PropTypes.node,
    ]),
  }).isRequired,
  /**
   * sorted columns
   */
  sortedColumns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.bool,
    }),
  ).isRequired,
};

export default TableHeader;
