import React from 'react';
import ReactDOM from 'react-dom';
import Tooltip from './Tooltip';
import './tooltip.scss';

class TooltipContainer extends React.Component {
  constructor(p) {
    super(p);
    this.state = {
      renderTooltip: false,
      showMenu: false,
    };
    this.containerElement = null;
  }

  scrollPosition = (e) => {
    e.preventDefault();
  }

  clickListener = () => {
    this.hideMenu();
  }

  addEventListener = () => {
    document.addEventListener('wheel', this.scrollPosition);
    document.addEventListener('mousewheel', this.scrollPosition);
    document.addEventListener('click', this.clickListener);
  }

  removeEventListener = () => {
    document.removeEventListener('wheel', this.scrollPosition);
    document.removeEventListener('mousewheel', this.scrollPosition);
    document.removeEventListener('click', this.clickListener);
  }

  showMenu = () => {
    this.setState({ showMenu: true }, this.addEventListener);
  }

  hideMenu = () => {
    this.setState({ containerElement: null, showMenu: false }, this.removeEventListener);
  }

  componentWillUnmount() {
    this.removeEventListener();
  }

  render = () => {
    const { children, style } = this.props;
    const renders = [(
      <div
        key={1}
        ref={(node) => { this.iconElement = node; }}
        onMouseEnter={this.showMenu}
        onMouseLeave={this.hideMenu}
        style={{
          cursor: 'pointer',
          ...style,
        }}
      >
        {children}
      </div>
    )];
    if (this.state.showMenu) {
      const {
        top, left, width, height,
      } = this.iconElement.getBoundingClientRect();
      const portalNode = document.getElementById('tooltip');
      renders.push(
        ReactDOM.createPortal(
          <Tooltip
            {...this.props}
            key={2}
            onMouseEnter={this.showMenu}
            onMouseLeave={this.hideMenu}
            componentDidMount={() => this.setState({ renderTooltip: true })}
            makeRef={(node) => {
              if (!this.state.containerElement) {
                this.setState({ containerElement: node });
              }
            }}
          />,
          portalNode,
        ),
      );

      if (this.state.renderTooltip && !this.props.newStyle) {
        const middlePosition = (left + (width / 2)) - 137;
        portalNode.style.left = `${middlePosition}px`;
        portalNode.style.top = `${top + height}px`;
      }

      if (this.state.containerElement && this.props.newStyle) {
        portalNode.style.left = `${left}px`;
        portalNode.style.top = `${top + height}px`;

        const offsetPx = 0;
        const containerHeight = this.state.containerElement.offsetHeight;
        const containerWidth = this.state.containerElement.offsetWidth;
        const windowHeight = window.innerHeight;
        const windowWidth = window.innerWidth;
        const iconHeight = this.iconElement.offsetHeight + offsetPx;
        const iconWidth = this.iconElement.offsetWidth;

        const renderRight = (windowWidth - left) > containerWidth;
        const renderLeft = this.props.position === 'bottom-right' || !renderRight;
        const renderBelow = (windowHeight - top) - iconHeight > containerHeight;
        const renderAbove = !renderBelow;

        if (renderRight) {
          portalNode.style.left = `${left}px`;
        }
        if (renderLeft) {
          portalNode.style.left = `${(left - containerWidth) + iconWidth}px`;
        }
        if (renderBelow) {
          portalNode.style.top = `${(top + iconHeight) - this.props.offsetTop}px`;
        }
        if (renderAbove) {
          const topPos = Math.max(0, (top - containerHeight) - offsetPx);
          portalNode.style.top = !topPos ? '0px' : `${topPos}px`;
        }
      }
    }
    return renders;
  }
}

TooltipContainer.defaultProps = {
  bottomOffset: 4,
};

export default TooltipContainer;
