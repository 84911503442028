import React, { F } from '../../app/app';
import './pagination.scss';

const PaginationComponent = ({
  canNext,
  canPrevious,
  onPageChange,
  onPageSizeChange,
  page,
  pages,
  pageSize,
  pageSizeOptions,
  data,
}) => {
  const currentPage = page + 1;
  return (
    <div className="template__pagination">
      <nav className="f fh fv p2">
        {canPrevious && (
          <i className="material-icons" onClick={() => onPageChange(page - 1, pageSize)}>keyboard_arrow_left</i>
        )}
        {currentPage > 1 && (
          <div className="next-page f fh fv p2" onClick={() => onPageChange(0, pageSize)}><span>{1}</span></div>
        )}
        {(currentPage - 2) > 1 && (
          <span className="elip p2">...</span>
        )}
        {(currentPage - 1) > 1 && (
          <div className="next-page f fh fv p2" onClick={() => onPageChange(page - 1, pageSize)}><span>{currentPage - 1}</span></div>
        )}
        <div className="current-page f fh fv p2"><span>{currentPage}</span></div>
        {(currentPage + 1) < pages && (
          <div className="next-page f fh fv p2" onClick={() => onPageChange(page + 1, pageSize)}><span>{currentPage + 1}</span></div>
        )}
        {(currentPage + 2) < pages && (
          <span className="elip p2">...</span>
        )}
        {canNext && (
          <i className="material-icons" onClick={() => onPageChange(page + 1, pageSize)}>keyboard_arrow_right</i>
        )}
        {currentPage < pages && (
          <div className="next-page f fh fv p2" onClick={() => onPageChange(pages - 1, pageSize)}><span>{pages}</span></div>
        )}
      </nav>
      <div className="page-select">
        {data.length > 10 && (
          <F>
            <select value={pageSize} onChange={(e) => onPageSizeChange(e.target.value)}>
              {pageSizeOptions.map((size) => (
                <option key={size}>{size}</option>
              ))}
            </select>
            <i className="material-icons">keyboard_arrow_down</i>
          </F>
        )}
      </div>
    </div>
  );
};

export default PaginationComponent;
