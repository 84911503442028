import React from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { getCountries, getCheckedRegionNodes } from './helper';
import FrontendPanel from './FrontendPanel';

const CountryPanel = ({
  visible,
  className,
  maxSelected,
  availableNodes,
  defaultCheckedNodes,
  onVisibleChange,
  onChange,
}) => (
  <FrontendPanel
    tree
    nodes={availableNodes.length ? availableNodes : getCountries()}
    className={className}
    visible={visible}
    maxSelected={maxSelected}
    disabledTitle="Limit Reached"
    disabledContent={`You have selected (${maxSelected}) countries.`}
    placeholder="Search Countries..."
    defaultCheckedNodes={defaultCheckedNodes}
    onVisibleChange={onVisibleChange}
    onChange={(checkedNodes) => onChange(getCheckedRegionNodes(getCountries(), checkedNodes))}
  />
);

CountryPanel.propTypes = {
  visible: PropTypes.bool,
  maxSelected: PropTypes.number,
  availableNodes: PropTypes.arrayOf(PropTypes.any),
  defaultCheckedNodes: PropTypes.arrayOf(PropTypes.any),
  onVisibleChange: PropTypes.func,
  onChange: PropTypes.func,
};

CountryPanel.defaultProps = {
  visible: false,
  maxSelected: 0,
  availableNodes: [],
  defaultCheckedNodes: [],
  onVisibleChange: noop,
  onChange: noop,
};

export default React.memo(CountryPanel);
