import React, {
  useState, memo, useEffect, useCallback, useRef,
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';
import TrackingURL from './TrackingURL';
import './urlValidator.scss';

const classPrefix = 'v2_component_url_validator';

const URLValidator = ({
  className,
  value: valueProp,
  error,
  placeholder,
  onChange,
}) => {
  const [value, setValue] = useState(valueProp);
  const [active, setActive] = useState(false);

  const inputRef = useRef();

  const updateInputHeight = () => {
    inputRef.current.style.height = ''; // reset height to avoid glitches: https://bit.ly/3sdEREw
    inputRef.current.style.height = `${inputRef.current.scrollHeight}px`; // set textarea height as input text height
  };

  useEffect(() => {
    setValue(valueProp);

    if (valueProp && valueProp.length) {
      // calculate and update textarea height, not work without setTimeout
      setTimeout(() => {
        updateInputHeight();
      });
    }
  }, [valueProp]);

  const handleChange = useCallback(({ target }) => {
    const urlString = (target.value || '').replace(/[\r\n]/g, '');
    updateInputHeight();
    setValue(urlString);
  }, []);

  const handleReset = () => {
    inputRef.current.style.height = '100%';
    setValue('');
    if (onChange) onChange('');
  };

  return (
    <div className={classnames(classPrefix,
      {
        [`${classPrefix}--active`]: active,
        [`${classPrefix}--error`]: error,
      },
      className)}
    >
      <textarea
        ref={inputRef}
        className={classnames({ active })}
        value={value}
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="off"
        placeholder={placeholder}
        onChange={handleChange}
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
      />
      {value && active && <i className={`material-icons ${classPrefix}-clear-icon`} onMouseDown={handleReset}>clear</i>}
      <TrackingURL url={value} onChange={onChange} />
    </div>
  );
};

URLValidator.propTypes = {
  /**
   * Class name string
   */
  className: PropTypes.string,
  /**
   * Text content displayed inner component
   */
  value: PropTypes.string,
  /**
   * If `true` will show error style.
   */
  error: PropTypes.bool,
  /**
   * Textarea placeholder
   */
  placeholder: PropTypes.string,
  /**
   * When textarea input change will call this method
   */
  onChange: PropTypes.func,
};

URLValidator.defaultProps = {
  className: null,
  value: '',
  error: false,
  placeholder: null,
  onChange: noop,
};

export default memo(URLValidator);
