import classnames from 'classnames';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Asset.scss';
import Icon from '../../../../../../../../components/Icon/Icon';
import AssetPicker from './AssetPicker';
import Asset from '../../../../../../../../models/Asset';
import { Tooltip } from '../../../../../../../../components/V2';
import Copy from '../../../../../../../../components/V2/Copy/Copy';
import imgVideogameThumbnail from '../../../../../../../../assets/img/videogame_thumbnail.svg';
import { assetTypeMap } from '../../../../../../../../lib/helpers/Assets/contants';

const typeIcons = {
  banner: Icon.Image,
  banner_video: Icon.Video,
  bundle_adaptive_creative: Icon.Game,
  image: Icon.Image,
  video: Icon.Video,
};

const AssetInput = ({
  creative,
  onChange,
  replacementKey,
  template,
  type,
  value,
  accept,
  shouldHidePlayable,
  getEndcardStatus,
}) => {
  // used to show asset name once the asset is found with the URL
  // no name or url is shown until the details are fetched
  const [assetName, setAssetName] = useState(' ');
  const [assetId, setAssetId] = useState('');
  const [assetURL, setAssetURL] = useState();

  // thumbnail url is same as asset url (value) for image assets
  const [thumbnailURL, setThumbnailURL] = useState(type === 'image' ? value : undefined);

  React.useEffect(() => {
    const fetchAssetDetail = async () => {
      if (type === 'video' || type === 'banner_video') {
        if (value && value.url !== assetURL) {
          setAssetURL(value.url);
          setAssetName(' ');

          const result = await Asset.getAll({ videoId: value.id });
          const asset = result.response[0];
          setAssetName(asset?.name || '');
          setThumbnailURL(asset?.thumbnails?.landscape[0] || '');
          setAssetId(asset?.id);
        }
      } else if (value && value !== assetURL) {
        // for image and bundle assets
        setAssetURL(value);
        setThumbnailURL(value);
        setAssetName(' ');

        const result = await Asset.getAll({ assetUrl: value });
        const asset = result.response[0];
        setAssetName(asset?.name || '');
        setAssetId(asset?.id);
        if (type === 'bundle_adaptive_creative') {
          setThumbnailURL(asset?.thumbnails?.landscape[0] || imgVideogameThumbnail);
        }
        if (type === 'bundle_adaptive_creative' && getEndcardStatus) {
          getEndcardStatus(result?.response[0]?.status);
        }
      }
    };
    fetchAssetDetail();
  });

  const [isPickerShown, setIsPickerShown] = useState(false);

  const hasApplication = !!creative.get('application')?.get('id')
    || creative.get('application')?.get('attrs.id');

  const IconTag = typeIcons[type];
  const name = assetName === '' ? (value?.url || value) : assetName;

  const onDelete = () => {
    if (type === 'video' || type === 'banner_video') {
      onChange(undefined);
    } else {
      onChange('', new Asset());
    }
    setAssetURL('');
    setAssetId('');
    setThumbnailURL('');
    setAssetName(' ');
  };

  return (
    <div className="replacement-image">
      {value
        ? (
          <div className="image-thumbnail-container">
            <div className={`thumbnail_container thumbnail_container-${assetTypeMap[type]}`}>
              {
                thumbnailURL && (
                  <img
                    className="thumbnail"
                    src={thumbnailURL}
                    alt="Thumbnail"
                  />
                )
              }
            </div>
            <div className="asset-info">
              <Tooltip body={name}>{name}</Tooltip>
              {!!assetId && <Copy value={assetId} className="asset-info-copy" />}
            </div>
            <div className="delete-icon">
              <Icon.Delete onClick={onDelete} />
            </div>
          </div>
        )
        : (
          <div
            className={classnames('empty-thumbnail', { disabled: !hasApplication })}
            onClick={() => {
              if (hasApplication) {
                setIsPickerShown(true);
              }
            }}
          >
            {hasApplication ? <IconTag /> : 'Please select an application before choosing an asset.'}
          </div>
        )}

      {isPickerShown
      && (
        <AssetPicker
          application={creative.get('application')}
          onClose={() => setIsPickerShown(false)}
          template={template}
          type={type}
          onSelectAsset={(asset) => {
            setIsPickerShown(false);
            if (type === 'video' || type === 'banner_video') {
              onChange({
                id: asset.get('video.id'),
                url: asset.get('url'),
                assetId: asset.get('id'),
              });
            } else {
              // for image and bundle assets
              onChange(asset.get('url'), asset);
            }
          }}
          replacementKey={replacementKey}
          accept={accept}
          shouldHidePlayable={shouldHidePlayable}
        />
      )}
    </div>
  );
};

AssetInput.propTypes = {
  type: PropTypes.oneOf(Object.keys(typeIcons)).isRequired,
};

export default AssetInput;
