import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';
import './checkbox.scss';

const prefixClsx = 'v2_component_form_checkbox';

const checkboxIconRender = (type) => <i className="material-icons">{type}</i>;
const checkboxRender = (state, icon) => <span className={classnames(`${prefixClsx}-icon`, state)}>{icon}</span>;

const checkboxIndeterminateIcon = checkboxIconRender('indeterminate_check_box');
const checkboxBlankIcon = checkboxIconRender('check_box_outline_blank');
const checkboxIcon = checkboxIconRender('check_box');

const Checkbox = (props) => {
  const {
    className,
    id,
    checked,
    disabled,
    label,
    indeterminate,
    indeterminateIcon,
    checkedIcon,
    icon,
    onChange,
    readOnly,
    ...other
  } = props;

  const handleChange = (event) => {
    if (readOnly) return;
    const newChecked = event.target.checked;
    onChange(newChecked, event);
  };

  const renderIcon = () => {
    if (indeterminate) return checkboxRender('active', indeterminateIcon);
    if (checked) return checkboxRender('active', checkedIcon);
    return checkboxRender('inactive', icon);
  };

  return (
    <div
      className={classnames(
        prefixClsx, {
          [`${prefixClsx} disabled`]: disabled,
        },
        className,
      )}
    >
      <label htmlFor={id}>
        <input
          id={id}
          checked={checked}
          disabled={disabled}
          onChange={handleChange}
          readOnly={readOnly}
          type="checkbox"
          {...other.inputProps}
        />
        {renderIcon()}
        {label && (
          <span className={`${prefixClsx} option ${!checked && 'inactive'}`}>
            {label}
          </span>
        )}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  /**
   * class name.
   */
  className: PropTypes.string,
  /**
   * The id of the `input` and `label` element.
   */
  id: PropTypes.string,
  /**
   * If `true`, the component is checked.
   */
  checked: PropTypes.bool,
  /**
   * If `true`, the component display indeterminate.
   */
  indeterminate: PropTypes.bool,
  /**
   * If `true`, the component will be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * The text to display right `input` element.
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * The icon to display when the component is indeterminate.
   */
  indeterminateIcon: PropTypes.node,
  /**
   * The icon to display when the component is checked.
   */
  checkedIcon: PropTypes.node,
  /**
   * The icon to display when the component is unchecked.
   */
  icon: PropTypes.node,
  /**
   * It prevents the user from changing the value of the field
   * (not from interacting with the field).
   */
  readOnly: PropTypes.bool,
  /**
   * Callback fired when the state is changed.
   *
   * @param {object} event The event source of the callback.
   * You can pull out the new checked state by accessing `event.target.checked` (boolean).
   */
  onChange: PropTypes.func,
  autofocus: PropTypes.bool,
};

Checkbox.defaultProps = {
  className: null,
  id: null,
  autofocus: false,
  checked: false,
  indeterminateIcon: checkboxIndeterminateIcon,
  checkedIcon: checkboxIcon,
  icon: checkboxBlankIcon,
  indeterminate: false,
  disabled: false,
  label: null,
  readOnly: false,
  onChange: noop,
};

export default Checkbox;
