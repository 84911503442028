import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import { noop } from 'lodash';
import './pickerQuick.scss';

export const prefixCls = 'v2_component_report_calendar_picker_quick';

function getDateRange(date, num) {
  return {
    startDate: date.clone().subtract(num, 'days').startOf('day'),
    endDate: date.clone().subtract(1, 'days').startOf('day'),
  };
}

export const QUICK_KEY_CUSTOM = 'CUSTOM';
export const QUICK_TITLE_CUSTOM = 'CUSTOM';
export const QUICK_KEY_TODAY = 'TODAY';
export const QUICK_KEY_YESTERDAY = 'YESTERDAY';
export const QUICK_KEY_LAST_7_DAYS = 'LAST_7_DAYS';
export const QUICK_KEY_LAST_14_DAYS = 'LAST_14_DAYS';
export const QUICK_KEY_LAST_30_DAYS = 'LAST_30_DAYS';
export const QUICK_KEY_MONTH = 'MONTH';
export const QUICK_KEY_LAST_MONTH = 'LAST_MONTH';

export const QUICK_KEY_CUSTOM_TEXT = 'Custom';

export const QUICK_MAP = {
  [QUICK_KEY_TODAY]: {
    text: 'Today',
    generateDate(today) {
      return {
        startDate: today.clone().startOf('day'),
        endDate: today.clone().startOf('day'),
      };
    },
  },
  [QUICK_KEY_YESTERDAY]: {
    text: 'Yesterday',
    generateDate(today) {
      return getDateRange(today, 1);
    },
  },
  [QUICK_KEY_LAST_7_DAYS]: {
    text: 'Last 7 Days',
    generateDate(today) {
      return getDateRange(today, 7);
    },
  },
  [QUICK_KEY_LAST_14_DAYS]: {
    text: 'Last 14 Days',
    generateDate(today) {
      return getDateRange(today, 14);
    },
  },
  [QUICK_KEY_LAST_30_DAYS]: {
    text: 'Last 30 Days',
    generateDate(today) {
      return getDateRange(today, 30);
    },
  },
  [QUICK_KEY_MONTH]: {
    text: 'This Month',
    generateDate(today) {
      return {
        startDate: today.clone()
          .startOf('month'),
        endDate: today.clone().startOf('day'),
      };
    },
  },
  [QUICK_KEY_LAST_MONTH]: {
    text: 'Last Month',
    generateDate(today) {
      const lastMonth = today.clone()
        .subtract(1, 'months');
      return {
        startDate: lastMonth.clone()
          .startOf('month'),
        endDate: lastMonth.clone()
          .endOf('month'),
      };
    },
  },
};

function PickerQuick({
  quickKey: quickKeyProps,
  onChange,
}) {
  const [quickKey, setQuickKey] = React.useState(quickKeyProps);

  React.useEffect(() => {
    setQuickKey(quickKeyProps);
  }, [quickKeyProps]);

  const getPickerClassName = React.useCallback((key) => classnames({
    [`${prefixCls}-picker`]: true,
    [`${prefixCls}-picker-selected`]: key === quickKey,
  }), [quickKey]);

  const onQuickChange = React.useCallback((key) => {
    if (key === quickKey) return;
    setQuickKey(key);
    const quick = QUICK_MAP[key];
    onChange({
      quickKey: key,
      ...quick.generateDate(moment()),
    });
  }, [quickKey, onChange]);

  return (
    <div className={prefixCls}>
      <div className={`${prefixCls}-custom`}>
        <div className={getPickerClassName(QUICK_KEY_CUSTOM)}>
          Custom*
        </div>
      </div>
      <div className={`${prefixCls}-wrapper`}>
        {
          Object.keys(QUICK_MAP)
            .map((key) => (
              <div
                key={key}
                className={getPickerClassName(key)}
                onClick={() => onQuickChange(key)}
              >
                {QUICK_MAP[key].text}
              </div>
            ))
        }
      </div>
    </div>
  );
}

PickerQuick.propTypes = {
  quickKey: PropTypes.string,
  onChange: PropTypes.func,
};

PickerQuick.defaultProps = {
  quickKey: QUICK_KEY_TODAY,
  onChange: noop,
};

export default React.memo(PickerQuick);
