import React from 'react';
import SpinnerWithText from '../../../../../../../../components/Spinner/SpinnerWithText';
import './loading.scss';

const Loading = ({ content }) => (
  <div className="loading_container">
    <SpinnerWithText text={content} />
  </div>
);

export default Loading;
