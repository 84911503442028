import React from 'react';
import { Route } from 'react-router-dom';

let keyDown = null;
try {
  window.onkeydown = (e) => {
    keyDown = e.which;
  };
  window.onkeyup = () => {
    keyDown = null;
  };
} catch (ex) {
  // do nothing
}

const Link = ({
  children, to, onClick = () => {}, className = '', external = false, goBack = false, includeLink = true, style = {},
}) => {
  if (!includeLink) {
    return children;
  }
  if (external) {
    const Child = () => (
      <a
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={className}
      >
        {children}
      </a>
    );
    return (
      <Route render={Child} />
    );
  }
  const Child = ({ history, location }) => (
    <a
      className={className}
      href={to}
      style={style}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
        if (!goBack && (keyDown === 91 || keyDown === 224)) { // os x cmd
          try {
            window.open(to, '_blank');
            return;
          } catch (ex) {
            // do nothing
          }
        }
        if (goBack) {
          history.goBack();
        } else if (to && `${location.pathname}/${location.search}` !== to) {
          history.push(to);
          try {
            window.analytics.page(to);
          } catch (ex) {
            // do nothing
          }
        }
      }}
    >
      {children}
    </a>
  );
  return (
    <Route render={Child} />
  );
};

export default Link;
