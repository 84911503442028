import React, { useEffect } from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { FILTER_KEY_APPLICATION } from 'app/constants/filters';
import Application from '../../../../models/Application';
import MultiTreeBackend from '../Base/MultiTreeBackend/MultiTreeBackend';
import ApplicationOrPublisherPanel from '../Base/Panels/ApplicationOrPublisherPanel';

const getDefaultApplicationNames = async (defaultCheckedNodes, handleChange) => {
  const ids = defaultCheckedNodes.map((i) => i.id).join(',');
  const results = await Application.getAll({ idIn: ids, perPage: defaultCheckedNodes.length });
  const filterOptions = results.response.map((res) => ({ name: res.name, id: res.id }));
  handleChange(filterOptions);
};

const MultiTreeApplication = ({
  dropdownFixed,
  visible,
  availableNodes,
  defaultCheckedNodes,
  onVisibleChange,
  onChange,
  onRemove,
}) => {
  useEffect(() => {
    if (defaultCheckedNodes.some((i) => !i.name)) {
      getDefaultApplicationNames(defaultCheckedNodes, onChange);
    }
  }, [defaultCheckedNodes, onChange]);
  return (
    <MultiTreeBackend
      dropdownFixed={dropdownFixed}
      visible={visible}
      filterKey={FILTER_KEY_APPLICATION}
      defaultCheckedNodes={defaultCheckedNodes}
      renderOverlay={() => (
        React.createElement(ApplicationOrPublisherPanel, {
          availableNodes,
          filterKey: FILTER_KEY_APPLICATION,
          visible,
          defaultCheckedNodes,
          onVisibleChange,
          onChange,
        })
      )}
      onVisibleChange={onVisibleChange}
      onRemove={onRemove}
    />
  );
};

MultiTreeApplication.propTypes = {
  dropdownFixed: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
  availableNodes: PropTypes.arrayOf(PropTypes.any),
  defaultCheckedNodes: PropTypes.arrayOf(PropTypes.any),
  onVisibleChange: PropTypes.func,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};

MultiTreeApplication.defaultProps = {
  availableNodes: [],
  dropdownFixed: false,
  defaultCheckedNodes: [],
  onVisibleChange: noop,
  onChange: noop,
  onRemove: noop,
};

export default React.memo(MultiTreeApplication);
