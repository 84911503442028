import React from 'react';
import {
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { compose } from 'app/app';
import config from 'lib/config';
import AssetListContainer from 'services/admin/views/assets/list_2/AssetListContainer';
import { ssoLogout } from 'lib/auth';
import supplyIcon from 'assets/img/supply-icon.svg';
import { remove } from '../../../lib/session';
import {
  getAppConfig,
  isAppLoaded,
} from '../../../lib/helpers';
import Button from '../../../components/V2/Button/Button';
import ContextMenu from '../../../components/ContextMenu/ContextMenuContainer';
import FiltersCache from '../../../lib/cache/FiltersCache';
import { APPLICATION_CAMPAIGN_LIST_CACHE_KEY, APPLICATION_CREATIVE_LIST_CACHE_KEY, REPORTS_CACHE_KEY } from '../../../lib/cache/constant';
import Account from './account/index';
import AccountModel from '../../../models/Account';
import Applications from './applications/index';
import Campaigns from './campaigns/index';
import Creatives from './creatives/index';
import Home from './home/home';
import Reports from './reports/index';
import Team from './team/index';
import TranslationSelector from '../../../components/Form/TranslationSelector/TranslationSelector';
import paths from '../paths';
import CampaignCreativeMappingsContainer from './applications/CampaignCreativeMappings/CampaignCreativeMappingsContainer';
import CampaignCreativeList from './applications/CampaignCreativeList/CampaignCreativeList';
import { canInviteNewUsersToReadonlyRole } from '../../../lib/helpers/authUser';

const {
  APPLICATION_CAMPAIGN_CREATIVE_LIST: {
    pathString: PATH_APP_CAMPAIGN_CREATIVE_LIST,
  },
  APPLICATION_CAMPAIGN_TO_CREATIVE_MAPPINGS: {
    pathString: PATH_APP_CAMPAIGN_TO_CREATIVE_MAPPING,
  },
  ASSETS: {
    pathString: PATH_LIST_ASSETS,
  },
} = paths;

const navHeaders = {
  '/applications': 'Applications',
  '/campaigns': 'Campaigns',
  '/creatives': 'Creatives',
  '/assets': 'Assets',
  '/reports': 'Reports',
  '/account': 'My Account',
  '/team': 'My Team',
};

const Container = ({
  actions: { logOut },
  props: { user },
  history,
}) => {
  // This is a fix in case a user enters /team, /account in URL and has access, we allow it until APP is loaded and we know if it is admin or not.
  const canInviteUsers = canInviteNewUsersToReadonlyRole();
  const showTeamLink = (canInviteNewUsersToReadonlyRole() && isAppLoaded()) || !isAppLoaded();
  const accountModel = new AccountModel({ ...user, permissions: user.accountFeatureFlags });
  const hasPermissionForPlayable = accountModel.hasPermissionForPlayable();

  let headerText = navHeaders[history.location.pathname];
  if (history.location.pathname.includes('/applications')) {
    headerText = navHeaders['/applications'];
  }
  return (
    <div className="container">
      <div className="context">
        <span className="header">{headerText}</span>
        <nav className="breadcrumbs">
          { showTeamLink && <Route path="/team" />}
          <Route path="/account" />
        </nav>
        <div className="profile">
          <ContextMenu
            position="bottom-right"
            offsetTop={26}
            ClickItem={(
              <div className="user_menu">
                <div className="user_info">
                  <p className="p1" style={{ textTransform: 'capitalize' }}>
                    Advertiser:&nbsp;
                    {getAppConfig('account', (a) => a.get('name'), null)}
                  </p>
                  <span className="p3">{getAppConfig('user', (u) => u.get('email'), null)}</span>
                </div>
                <i className="material-icons">more_vert</i>
              </div>
          )}
          >
            <TranslationSelector subMenu />
            <>
              <ContextMenu.LinkOption to="/account" text="My Account" icon="account_circle" />
              {canInviteUsers && (
                <ContextMenu.LinkOption to="/team" text="My Team" icon="group" />
              )}
            </>
            <hr />
            {user.account_type === 'both' && (
            <>
              <ContextMenu.LinkOption
                to={`${config.get('supplyUrl')}auth?token=${user.token}&username=${user.username}`}
                external
                text="Publisher Account"
                image={supplyIcon}
              />
              <hr />
            </>
            )}
            <Button
              buttonStyles={{
                flex: 'none',
                height: 28,
              }}
              onClick={logOut}
              type="ghost"
            >
              Log Out
            </Button>
          </ContextMenu>
        </div>
      </div>
      <div className="list-container">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/account" component={Account} />
          <Route
            path={PATH_APP_CAMPAIGN_TO_CREATIVE_MAPPING}
            exact
            component={CampaignCreativeMappingsContainer}
          />
          <Route path={PATH_APP_CAMPAIGN_CREATIVE_LIST} component={CampaignCreativeList} />
          <Route path="/applications" component={Applications} />
          <Route path="/campaigns" component={Campaigns} />
          <Route path="/creatives" component={Creatives} />
          <Route path="/reports" component={Reports} />
          { canInviteUsers && <Route path="/team" component={Team} />}
          {
            hasPermissionForPlayable && (
              <Route
                path={PATH_LIST_ASSETS}
                component={AssetListContainer}
              />
            )
          }
          <Redirect to="/" />
        </Switch>
      </div>
    </div>
  );
};

const props = (state) => ({
  accountName: state.authUser.account_name,
  username: state.authUser.username,
  ...state.setup,
  user: state.authUser,
});

export const logOut = (state) => {
  const { authUser: { id } } = state.state;
  const reportsCache = new FiltersCache(id, REPORTS_CACHE_KEY);
  const campaignListCache = new FiltersCache(id, APPLICATION_CAMPAIGN_LIST_CACHE_KEY);
  const creativeListCache = new FiltersCache(id, APPLICATION_CREATIVE_LIST_CACHE_KEY);
  reportsCache.remove();
  campaignListCache.remove();
  creativeListCache.remove();
  remove('authUser');
  ssoLogout();
  return state;
};

export default compose(Container, { props, actions: { logOut } });
