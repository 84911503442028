import React from 'react';
import classnames from 'classnames';
import ReactTable from 'react-table';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import TableHeader from './TableHeader';
import Loading from './component/Loading';
import NoData from './component/NoData';
import withSelector from './hoc/withSelector';
import withAction from './hoc/withAction';

const FixedColumnsTable = withFixedColumns(ReactTable);

class TableInner extends React.PureComponent {
  constructor(props) {
    super(props);
    /**
     * Init the component hoc.
     */
    let Component = FixedColumnsTable;
    if (props.selectable) {
      Component = withSelector(Component);
    }
    if (props.actionable) {
      Component = withAction(Component);
    }
    this.Component = Component;

    this.reactTableRef = React.createRef();
  }

  formatColumns = (columns) => {
    // the last column should non't resizable
    const lastColumn = columns[columns.length - 1];
    lastColumn.resizable = false;

    // remove the last column resized state
    const { resized } = this.props;
    for (let i = 0; i < resized.length; i++) {
      if (resized[i].id === lastColumn.id) {
        resized.splice(i, 1);
        break;
      }
    }
    return columns.map((column) => {
      const { sortable, sortedColumns } = this.props;
      return {
        ...column,
        Header: <TableHeader sortedColumns={sortedColumns} sortable={sortable} column={column} />,
      };
    });
  };

  render() {
    const {
      sortedColumns,
      selectedRows,
      resized,
      loading,
      prefixCls,
      height,
      maxHeight,
      className,
      data,
      columns: originalColumns,
      hideOnNoData,
      onSortedChange,
      onResizedChange,
      onSelectedChange,
      onMetricsChange,
      setResizerLineStyle,
      tableRef,
      ...rest
    } = this.props;

    // no data
    if (data.length === 0) {
      // no data and ajax loading. just render `Loading`
      if (loading) {
        return <Loading loading={loading} prefixCls={prefixCls} />;
      }
      // no data and ajax loaded. `hideOnNoData` render null
      if (hideOnNoData) {
        return null;
      }
      // no data and ajax loaded. not `hideOnNoData` just render `NoData`
      return <NoData prefixCls={prefixCls} loading={loading} />;
    }

    const columns = this.formatColumns(originalColumns);

    const classes = classnames(prefixCls, className);

    return React.createElement(this.Component, {
      prefixCls,
      className: classes,
      style: { height, maxHeight },
      loading,
      data,
      columns,
      sorted: sortedColumns,
      resized,
      setResizerLineStyle,
      tableRef,
      innerRef: this.reactTableRef,
      selectedRows,
      onSortedChange,
      onResizedChange,
      onSelectedChange,
      onMetricsChange,
      ...rest,
    });
  }
}

export default TableInner;
