import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';
import './radio.scss';

const classPrefix = 'v2_component_radio';

const Radio = ({
  className, children, checked, disabled, onChange,
}) => (
  <div
    className={classnames(classPrefix, { [`${classPrefix}--disabled`]: disabled }, className)}
    onClick={() => {
      if (!disabled) {
        onChange();
      }
    }}
  >
    <i className={classnames('material-icons', { checked, unchecked: !checked })}>
      {checked ? 'radio_button_checked' : 'radio_button_unchecked'}
    </i>
    {children && (
      <span className="label">
        {children}
      </span>
    )}
  </div>
);

Radio.propTypes = {
  /**
   * Class name string.
   */
  className: PropTypes.string,
  /**
   * Tell if radio is checked, default is false.
   */
  checked: PropTypes.bool,
  /**
   * Tell if radio is disabled, default is false.
   */
  disabled: PropTypes.bool,
  /**
   * Radio value change event.
   */
  onChange: PropTypes.func,
};

Radio.defaultProps = {
  className: null,
  checked: false,
  disabled: false,
  onChange: noop,
};

export default Radio;
