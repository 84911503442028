import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './collapseDetail.scss';
import AnimateHeight from 'react-animate-height';

const classPrefix = 'v2_component_collapse_detail';

const CollapseDetail = ({ children, className, expanded }) => (
  <div className={`${classPrefix}`}>
    <AnimateHeight duration={300} height={expanded ? 'auto' : 0}>
      <div className={classnames(`${classPrefix}-body`, className)}>
        {children}
      </div>
    </AnimateHeight>
  </div>
);

CollapseDetail.propTypes = {
  /**
   * `CollapseDetail` content children view.
   */
  children: PropTypes.node,
  /**
   * Class name of root component.
   */
  className: PropTypes.string,
};

CollapseDetail.defaultProps = {
  children: null,
  className: null,
};

export default CollapseDetail;
