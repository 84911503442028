import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

/**
 * check if component had child in deep.
 * @param component
 * @param childKey: the key of child component.
 * @param childValues: array of child values.
 * @returns {boolean}
 */
const componentHasChildByKey = (component, childKey, childValues = []) => {
  let hasChild = false;
  const loop = (e, parents) => {
    if (childValues.includes(e?.type && e.type[childKey])) {
      hasChild = true;
      return;
    }
    const newParents = [...parents, e];
    React.Children.toArray(e?.props?.children).forEach((c) => {
      loop(c, newParents);
    });
  };
  loop(component, []);
  return hasChild;
};

/**
 * get Component full width
 * @param Component
 * @param calculatedStyles
 * @returns {number}
 */
const getComponentSize = (Component, calculatedStyles) => {
  const container = document.createElement('div');
  container.style.width = 'auto';
  container.style.position = 'absolute';
  container.style.whiteSpace = 'nowrap';
  Object.assign(container.style, calculatedStyles);
  document.body.appendChild(container);
  const wrapper = <BrowserRouter>{Component}</BrowserRouter>;
  return new Promise((resolve) => {
    ReactDOM.render(wrapper, container, () => {
      resolve({
        width: container.offsetWidth,
        height: container.offsetHeight,
      });
      ReactDOM.unmountComponentAtNode(container);
      document.body.removeChild(container);
    });
  });
};

export default {};

export {
  componentHasChildByKey,
  getComponentSize,
};
