import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Spinner from './Spinner';
import './spinnerWithText.scss';

const SpinnerWithText = ({
  className,
  text,
  spinnerSize,
}) => (
  <div className={classnames('components__spinner__with__text', className)}>
    <Spinner size={spinnerSize} />
    <span>
      {text}
    </span>
  </div>
);

SpinnerWithText.propTypes = {
  className: PropTypes.string,
  text: PropTypes.node,
  spinnerSize: PropTypes.oneOf(['sm', 'md', 'lg']),
};

SpinnerWithText.defaultProps = {
  className: '',
  text: 'Just a second, loading data...',
  spinnerSize: 'sm',
};

export default SpinnerWithText;
