import React from 'react';
import PropTypes from 'prop-types';
import Group from '../../templates/input-group/InputGroupContainer';
import { supportsDeviceTargetingCsv } from '../../lib/capabilities/devices';
import LinkDownloadNonIOSDevices from '../LinkDownloadNonIOSDevices/LinkDownloadNonIOSDevices';
import AllowDenyList from '../Applications/AllowDenyList/AllowDenyListContainer';
import Campaign from '../../models/Campaign';
import Link from '../Link/Link';

const { Field } = Group;

const DeviceModelTargetingCsv = ({ campaign }) => (
  supportsDeviceTargetingCsv(campaign)
    ? (
      <Field
        name="Device Model Targeting"
        description={(
          <>
            Download our list below. Choose to allow or deny devices. Edit, and then upload your finalized list on the right.
            {' '}
            <Link
              external
              to="https://support.vungle.com/hc/en-us/articles/360050826112"
            >
              Learn More
            </Link>
          </>
        )}
        link={(
          <LinkDownloadNonIOSDevices
            platform={campaign.getPlatform()}
          />
        )}
      >
        <AllowDenyList
          target="device"
          onComplete={(v) => campaign.addDeviceModels(v, campaign.get('targeting.device_models.type'))}
          radio={{
            name: 'targeting_device_models.type',
            onChange: (v) => campaign.set('targeting.device_models.type', v),
            value: campaign.get('targeting.device_models.type'),
            option: {
              allow: {
                radioIncludeOptionAllow: !campaign.get('targeting.msrp.enabled'),
              },
            },
          }}
          whitelistedModels={campaign.get('targeting.device_models.device_whitelist')}
          blacklistedModels={campaign.get('targeting.device_models.device_blacklist')}
        />
      </Field>
    )
    : null
);

DeviceModelTargetingCsv.propTypes = {
  campaign: PropTypes.instanceOf(Campaign).isRequired,
};

export default DeviceModelTargetingCsv;
