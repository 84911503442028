import React from 'react';
import { Button, Drawer } from '../../../../components/V2';
import { numberFormat } from '../../../../components/V2/Metrics/Metrics';
import ProgressBar from '../Components/ProgressBar/ProgressBar';
import InfoBar from '../Components/InfoBar/InfoBar';
import {
  SET_KEY_A,
  STATUS_KEY_COMPLETE,
  STATUS_KEY_IN_PROGRESS_WITHOUT_ENOUGH_CONFIDENCE,
  STATUS_KEY_IN_PROGRESS_WITHOUT_ENOUGH_IMPRESSIONS,
} from '../constants';
import './abTestReportingDrawer.scss';

const classPrefix = 'ab_test_report_drawer';

const ABTestReportingDrawer = ({
  abSets,
  status,
  winner,
  onOpenChange,
  onRunWinningSet,
}) => (
  <Drawer
    open
    position="right"
    onOpenChange={onOpenChange}
    sidebarClassName={classPrefix}
  >
    <div className="content">
      <div className="header">
        <span className="p4">A/B Test Reporting</span>
      </div>
      <br />
      {status === STATUS_KEY_COMPLETE && (
        <InfoBar
          headsUp={`Your A/B test is complete. We have determined Set ${winner === SET_KEY_A ? 'A' : 'B'} as the winner.`}
          buttonText="Run winning set"
          onClick={onRunWinningSet}
        />
      )}
      {status === STATUS_KEY_IN_PROGRESS_WITHOUT_ENOUGH_CONFIDENCE && (
        <InfoBar
          headsUp={`
            Your A/B test does not yet have enough confidence to reliably determine a winner.
            You should wait until more data is received to make changes.
          `}
        />
      )}
      {(status === STATUS_KEY_IN_PROGRESS_WITHOUT_ENOUGH_IMPRESSIONS) && (
        <InfoBar headsUp="Your A/B test does not yet have enough impressions to reliably determine a winner." />
      )}
      <div className="sets_intro">
        {abSets.map((set, index) => (
          <div key={index}>
            <span className="set_wrapper">
              <p className="p4">{set.title}</p>
            </span>
            <ProgressBar
              leftKey="Total Impressions"
              rightKey="IPM"
              tintColor="#03BDB8"
              total={set.total}
              totalText={numberFormat(set.total)}
              current={set.impressions}
              currentText={numberFormat(set.impressions)}
              rightValue={set.IPM}
            />
          </div>
        ))}
      </div>
    </div>
    <div className="footer">
      <Button type="primary" onClick={() => onOpenChange(false)}>OK</Button>
    </div>
  </Drawer>
);

export default ABTestReportingDrawer;
