/* eslint-disable prefer-template */
import React, { useRef, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Apexcharts from 'react-apexcharts';
import { CHART_VIEW_BY_KEY_MONTH } from '../../../../services/Templates/Reports/constant';
import './chart.scss';

export const prefixCls = 'v2_component_report_chart';

const dateFormat = (date, viewBy, defaultFormat = 'DD MMM') => {
  let format = defaultFormat;
  if (viewBy === CHART_VIEW_BY_KEY_MONTH) {
    format = 'MMM YYYY';
  }
  return moment(date).format(format);
};

export const defaultOptions = (series, viewBy, metric) => ({
  chart: {
    offsetY: 0,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  grid: {
    borderColor: '#ecf0f4',
    row: {
      colors: ['#f2f4f9'],
      opacity: 0.5,
    },
  },
  fill: {
    opacity: 1,
  },
  colors: ['#555FFF', '#FFCB05', '#F91974', '#00BF56', '#4BB5F7', '#FF760D'],
  stroke: {
    width: 4,
  },
  xaxis: {
    tickAmount: Math.ceil(window.innerWidth / 100),
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    labels: {
      rotate: 0,
      style: {
        fontSize: 12,
        colors: '#273143',
      },
      formatter(date) {
        return dateFormat(date, viewBy);
      },
    },
    tooltip: {
      enabled: true,
      formatter(_, opts) {
        return dateFormat(series[0].data[opts.dataPointIndex].x, viewBy);
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        fontSize: 12,
        colors: '#273143',
      },
      formatter: (value) => (metric.render ? metric.render(parseFloat(value)) : value),
    },
  },
  markers: {
    size: 4,
    strokeColors: '#fff',
    strokeWidth: 2,
    hover: {
      size: 8,
    },
  },
  legend: {
    show: false,
  },
  tooltip: {
    custom({ series: s, dataPointIndex, w }) {
      const date = dateFormat(series[0].data[dataPointIndex].x, viewBy, 'DD MMM YYYY');
      const seriesData = w.globals.seriesNames.map((item, index) => ({
        name: item,
        value: s[index][dataPointIndex],
        color: w.globals.colors[index],
      }));
      const sortedData = seriesData.sort((a, b) => b.value - a.value);
      const formattedData = sortedData.map((item) => ({
        ...item,
        value: metric.render(parseFloat(item.value)),
      }));
      return (
        '<div class="box">'
          + '<div class="date">' + date + '</div>'
          + '<div class="series">'
            + formattedData.map(({ name, value, color }) => (
              `<div class="item">
                <div class="color" style="background-color: ${color}"></div>
                <div class="name">${name}</div>
                :
                <div class="value">${value}</div>
                </div>`
            )).join(' ')
          + '</div>'
      + '</div>'
      );
    },
  },
});

const Chart = ({
  height, viewBy, options, series, metric,
}) => {
  const chartRef = useRef();
  const onChartResize = () => {
    const prevOptions = chartRef.current.props.options;
    chartRef.current.chart.updateOptions({
      xaxis: { ...prevOptions.xaxis, tickAmount: Math.ceil(window.innerWidth / 100) },
    });
  };
  useEffect(() => {
    window.addEventListener('resize', onChartResize);
    return () => window.removeEventListener('resize', onChartResize);
  }, []);
  return (
    <div className={prefixCls}>
      <Apexcharts
        type="line"
        options={{ ...defaultOptions(series, viewBy, metric), ...options }}
        series={series}
        height={height}
        ref={chartRef}
      />
    </div>
  );
};

Chart.propTypes = {
  series: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    data: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  })).isRequired,
  options: PropTypes.objectOf(PropTypes.any),
  metric: PropTypes.objectOf(PropTypes.any).isRequired,
  viewBy: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

Chart.defaultProps = {
  options: {},
  height: 270,
};

export default React.memo(Chart);
