import React from 'react';
import {
  compose, Switch, Route, Redirect,
} from 'app/app';
import ReportsComponent from '../../../Templates/Reports/Reports';
import './index.scss';

const baseRoute = '/reports';

const Reports = () => (
  <div className="views__reports">
    <Switch>
      <Route exact path={baseRoute} component={ReportsComponent} />
      <Redirect to={baseRoute} />
    </Switch>
  </div>
);

export default compose(Reports);
