import { pickBy } from 'lodash';
import {
  BUDGET_OBJECTIVE_CPPU,
  BUDGET_OBJECTIVE_CPE,
  BUDGET_OBJECTIVE_ROAS,
  BUDGET_OBJECTIVE_DYNAMIC,
  BUDGET_OBJECTIVE_STATIC,
  CAMPAIGN_CREATIVE_FORMAT_TYPE_DESCRIPTIONS,
  FORMAT_TYPE_FULLSCREEN,
  FORMAT_TYPE_NATIVE,
  formatsMap,
} from 'app/constants/campaign';
import {
  FILTER_KEY_APPLICATION,
  FILTER_KEY_ASSET_ORIENTATION,
  FILTER_KEY_BID_TYPE,
  FILTER_KEY_CAMPAIGN_STATUS,
  FILTER_KEY_CREATIVE_LIST_FORMAT,
  FILTER_KEY_DELETION_STATUS,
  FILTER_KEY_LANGUAGE,
  FILTER_KEY_NETWORK_TYPE,
  FILTER_KEY_PLATFORM,
  FILTER_KEY_SKOVERLAY_AUTO_SHOW,
  FILTER_KEY_VIDEO_OVERLAY_AUTO_SHOW,
  FILTER_KEY_STATUS,
} from 'app/constants/filters';
import { isAdminService } from 'lib/serviceType';
import { getAppConfig } from 'lib/helpers';
import { canModifyBidTypeToCpc } from 'lib/helpers/authUser';
import Account from '../../models/Account';

export const FILTER_KEYS_ADMIN = [
  FILTER_KEY_APPLICATION,
  FILTER_KEY_BID_TYPE,
  FILTER_KEY_DELETION_STATUS,
  FILTER_KEY_NETWORK_TYPE,
  FILTER_KEY_PLATFORM,
  FILTER_KEY_CAMPAIGN_STATUS,
];

export const FILTER_KEYS_SSUI = [
  FILTER_KEY_APPLICATION,
  FILTER_KEY_PLATFORM,
  FILTER_KEY_CAMPAIGN_STATUS,
  FILTER_KEY_BID_TYPE,
];

export function getCampaignListFilterKeys() {
  if (isAdminService()) {
    return FILTER_KEYS_ADMIN;
  }

  return FILTER_KEYS_SSUI;
}

export function isCampaignObjectiveStatic(campaign) {
  return campaign.get('budget.objective.type') === BUDGET_OBJECTIVE_STATIC;
}

export function isCampaignObjectiveDynamic(campaign) {
  return campaign.get('budget.objective.type') !== BUDGET_OBJECTIVE_STATIC;
}

export function isCampaignObjectiveCPPUIap(campaign) {
  return campaign.get('budget.objective.type') === BUDGET_OBJECTIVE_CPPU;
}

export function isCampaignObjectiveCPEIap(campaign) {
  return campaign.get('budget.objective.type') === BUDGET_OBJECTIVE_CPE;
}

export function isCampaignObjectiveRoasIap(campaign) {
  return campaign.get('budget.objective.type') === BUDGET_OBJECTIVE_ROAS;
}

export function getOptimizationTypeLabel(type) {
  switch (type) {
    case BUDGET_OBJECTIVE_STATIC:
      return 'Manual';

    case BUDGET_OBJECTIVE_DYNAMIC:
    case BUDGET_OBJECTIVE_CPE:
    case BUDGET_OBJECTIVE_CPPU:
    case BUDGET_OBJECTIVE_ROAS:
      return 'Automatic';

    default:
      return undefined;
  }
}

function getCampaignAccount(campaign) {
  if (isAdminService()) {
    return new Account(campaign.get('account.accountConfig'));
  }
  return getAppConfig()?.account;
}

export function getRateTypeSettingsByName(campaign, name) {
  const account = getCampaignAccount(campaign);
  const rateTypesFieldName = isAdminService() ? 'campaigns.budget.rateTypes' : 'settings.campaigns.budget.rateTypes';
  if (account && account.attrs) {
    return account.attrs[rateTypesFieldName]?.find((rateType) => rateType?.name === name);
  }
  return undefined;
}

export function hasRoasIapOptimizationTypeAccess(campaign) {
  if (isAdminService()) {
    return campaign.isIOS() || campaign.isAndroid();
  }
  const account = getCampaignAccount(campaign);
  const supportsPlatform = (campaign.isIOS() && account.isROASEnabledIOS()) || (campaign.isAndroid() && account.isROASEnabledAndroid());
  return account?.hasRoasCampaignAccess() && supportsPlatform;
}

export function hasCPPUIapOptimizationTypeAccess(campaign) {
  if (isAdminService()) {
    return campaign.isIOS() || campaign.isAndroid();
  }
  const account = getCampaignAccount(campaign);
  const supportsPlatform = (campaign.isIOS() && account.isCPPUEnabledIOS()) || (campaign.isAndroid() && account.isCPPUEnabledAndroid());
  return account?.hasCPPUCampaignAccess() && supportsPlatform;
}

export function hasCPEIapOptimizationTypeAccess(campaign) {
  if (isAdminService()) {
    return campaign.isIOS() || campaign.isAndroid();
  }
  const account = getCampaignAccount(campaign);
  const supportsPlatform = (campaign.isIOS() && account.isCPEEnabledIOS()) || (campaign.isAndroid() && account.isCPEEnabledAndroid());
  return account?.hasCPECampaignAccess() && supportsPlatform;
}

export function hasDynamicIapOptimizationTypeAccess(campaign) {
  return hasRoasIapOptimizationTypeAccess(campaign) || hasCPPUIapOptimizationTypeAccess(campaign) || hasCPEIapOptimizationTypeAccess(campaign);
}

export function getOptimizationTypeDescription(type, campaign) {
  const budgetType = campaign.get('budget.type');
  switch (type) {
    case BUDGET_OBJECTIVE_STATIC:
      if (isAdminService()) {
        if (campaign.isSkadnetworkAttribution()) {
          return '(tCPI, CPCV, CPM, CPV, CPC)';
        }
        return '(CPI, tCPI, CPCV, CPM, CPV, CPC)';
      }
      if (budgetType === 'CPI') {
        return '(CPI)';
      }
      if (campaign.isSkadnetworkAttribution()) {
        if (budgetType === 'TARGET_CPI') {
          return '(tCPI)';
        }
        return `(tCPI, ${budgetType})`;
      }
      return `(CPI, ${budgetType})`;

    case BUDGET_OBJECTIVE_DYNAMIC:
    case BUDGET_OBJECTIVE_CPE:
    case BUDGET_OBJECTIVE_CPPU:
    case BUDGET_OBJECTIVE_ROAS: {
      const objectiveTypes = [];
      if (hasRoasIapOptimizationTypeAccess(campaign)) objectiveTypes.push(BUDGET_OBJECTIVE_ROAS);
      if (hasCPPUIapOptimizationTypeAccess(campaign)) objectiveTypes.push(BUDGET_OBJECTIVE_CPPU);
      if (hasCPEIapOptimizationTypeAccess(campaign)) objectiveTypes.push(BUDGET_OBJECTIVE_CPE);
      return objectiveTypes.length > 0 ? `(${objectiveTypes.join(', ').toUpperCase()})` : '';
    }

    default:
      return undefined;
  }
}

export function getTargetGoals(campaign) {
  const targetGoals = [];
  if (hasRoasIapOptimizationTypeAccess(campaign)) targetGoals.push(BUDGET_OBJECTIVE_ROAS);
  if (hasCPPUIapOptimizationTypeAccess(campaign)) targetGoals.push(BUDGET_OBJECTIVE_CPPU);
  if (hasCPEIapOptimizationTypeAccess(campaign)) targetGoals.push(BUDGET_OBJECTIVE_CPE);
  return targetGoals;
}

export function getOptimizationTypes(campaign) {
  return hasDynamicIapOptimizationTypeAccess(campaign) ? [BUDGET_OBJECTIVE_STATIC, BUDGET_OBJECTIVE_DYNAMIC] : [BUDGET_OBJECTIVE_STATIC];
}

export const isInvalidCPCUser = (type) => (
  (type === 'click' || type === 'CPC') && !canModifyBidTypeToCpc()
);

export const canChangeOptimizationType = (campaign) => {
  const isCampaignStaticOnlyCapable = campaign.isDSP() || campaign.isMultiBidding();
  return isCampaignStaticOnlyCapable ? false : hasDynamicIapOptimizationTypeAccess(campaign);
};

export function getAvailableCampaignCreativeFormats(campaign) {
  return pickBy(CAMPAIGN_CREATIVE_FORMAT_TYPE_DESCRIPTIONS, (_, format) => {
    if (isCampaignObjectiveDynamic(campaign)) {
      return format === FORMAT_TYPE_FULLSCREEN;
    }

    return isAdminService()
      ? true
      : format !== FORMAT_TYPE_NATIVE;
  });
}

export const getCreativesByCreativeType = (campaign, creativeType, creatives) => {
  const validCreativeType = isCampaignObjectiveDynamic(campaign)
    ? FORMAT_TYPE_FULLSCREEN
    : creativeType;
  const formats = formatsMap[validCreativeType] || [];
  return creatives?.filter((c) => formats.includes(c.raw('template.format')));
};

export const getCreativeAttachFilters = (campaign, creativeType, platform) => {
  const filters = [];

  if (!campaign || !isCampaignObjectiveDynamic(campaign)) {
    filters.push(FILTER_KEY_CREATIVE_LIST_FORMAT);
  }

  filters.push(
    FILTER_KEY_STATUS,
    FILTER_KEY_LANGUAGE,
  );

  if (creativeType === FORMAT_TYPE_FULLSCREEN) {
    filters.push(FILTER_KEY_ASSET_ORIENTATION);
  }

  if (platform === 'ios') {
    filters.push(FILTER_KEY_SKOVERLAY_AUTO_SHOW);
  }

  if (platform === 'android') {
    filters.push(FILTER_KEY_VIDEO_OVERLAY_AUTO_SHOW);
  }

  return filters;
};

export const supportsMultiBidding = (campaign) => (
  isAdminService()
    ? isCampaignObjectiveStatic(campaign)
      && campaign.get('account.accountConfig') !== undefined
    : !isCampaignObjectiveDynamic(campaign)
);
