import React from 'react';
import { CSVLink } from 'react-csv';
import { compose, namespace, config } from 'app/app';
import { triggerModal } from 'components/Modals/Content/triggerModal';
import { getBidLimits, makeEditObj, validatePublisherRate } from 'lib/helpers/campaigns/campaignIndex';
import MultiBidding from 'components/Campaigns/Multibidding/MultiBiddingContainer';
import Strategy from './Strategy';
import './strategy.scss';

const createNamespace = 'views.campaigns.edit';
const multiBiddingNamespace = 'views.campaigns.edit.multibidding';
const ns = 'views.campaigns.edit.strategy';
const authUser = 'authUser';
const updateCampaignsEditState = namespace(createNamespace);
const updateMultiBiddingState = namespace(multiBiddingNamespace);
const props = (state) => ({
  ...state[ns],
  ...state[createNamespace],
  ...state[multiBiddingNamespace],
  authUser: { ...state[authUser] },
});
const { editColumns, csvHeaders } = config.get('multibidding');

class StrategyContainer extends React.Component {
  constructor(p) {
    super(p);
    this.state = {};
  }

  toggleModal = () => {
    const { campaign } = this.props;
    // const betaCamp = campaign.get('budget.publisher_rates').reduce((result, obj) => {
    //   let entry = {};
    //   if (obj.geo === '') {
    //     entry = { name: obj.name, pub_app_id: obj.pub_app_id, rate: obj.rate };
    //   } else {
    //     entry = { ...obj };
    //   }
    //   result.push(entry);
    //   return result; // beta
    // }, []);
    const { max = 0, min = 0 } = getBidLimits(campaign);
    const enrichedEditColumns = editColumns.map((c) => ({
      ...c, validateFunc: validatePublisherRate, min, max,
    }));
    this.props.actions.setupMultiBiddingEditValues({ data: campaign.get('budget.publisher_rates'), columns: enrichedEditColumns });
    triggerModal({
      title: `Multi-Bidding Publishers (${campaign.get('budget.publisher_rates').length})`,
      link: (
        <CSVLink
          filename={`CampaignId_${campaign.get('id')}_publisher_rates.csv`}
          headers={csvHeaders}
          data={campaign.get('budget.publisher_rates')}
        >
          Download CSV
        </CSVLink>
      ),
      render: (campaign.get('budget.publisher_rates') && (
        <MultiBidding
          campaign={campaign}
          bid={campaign.get('budget.bid')}
          bidType={campaign.get('budget.type')}
          editColumns={enrichedEditColumns}
          publisherRates={campaign.get('budget.publisher_rates')}
          setupMultiBiddingEditValues={this.props.actions.setupMultiBiddingEditValues}
          min={min}
          max={max}
        />
      )),
    });
  }

  render() {
    return (
      <Strategy
        {...this.props}
        toggleModal={this.toggleModal}
      />
    );
  }
}

const bidBudgetValuesModified = ({ state }) => updateCampaignsEditState(state, { bidBudgetAlertSeen: false });
const setupMultiBiddingEditValues = ({ state, values: { data, columns } }) => {
  const editValues = makeEditObj({ data, columns });
  return updateMultiBiddingState(state, { editValues });
};
const updatePageContinue = ({ state, values: { enabled } }) => updateCampaignsEditState(state, { pageContinue: enabled });

const actions = {
  bidBudgetValuesModified,
  setupMultiBiddingEditValues,
  updatePageContinue,
};

export default compose(StrategyContainer, { props, actions });
