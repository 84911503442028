import React from 'react';
import Link from '../../../../../components/Link/Link';
import Group from '../../../../../templates/input-group/InputGroupContainer';

const { Field } = Group;

const IO = ({ account, toggleTos }) => (
  <div className="io">
    <Group title="IO Agreement" style={{ height: 250 }}>
      {account.get('io.signed') && (
        <Field name="Accepted &amp; Signed" description="">
          <Link onClick={toggleTos} style={{ cursor: 'pointer' }}>
            <i className="material-icons">remove_red_eye</i>
          </Link>
        </Field>
      )}
      {!account.get('io.signed') && (
        <div className="not-signed">
          <p className="p2">You have not yet signed your I/O, to start serving campaigns, talk to your Account Manager</p>
        </div>
      )}
    </Group>
  </div>
);

export default IO;
