import React, { compose, namespace } from '../../../../../../app/app';
import { isUndefined } from '../../../../../../lib/lib';
import Creative from '../../../../../../models/Creative';
import Creatives from './Creatives';
import './creatives.scss';

const createNamespace = 'views.campaigns.edit';
const ns = 'views.campaigns.edit.creatives';
const updateState = namespace(ns);
const props = (state) => ({
  ...state[ns],
  ...state[createNamespace],
  isDragging: state.window.isDragging,
});

class CreativesContainer extends React.Component {
  constructor(p) {
    super(p);
    this.state = {
      addedCreativeIds: {},
      setSkcids: false,
    };
    if (this.props.campaign.get('application').get('skadnetwork_settings.usage.remaining')) {
      this.state.setSkcids = true;
    }
    this.creativeIds = this.props.campaign.get('creatives').map((c) => c.get('id'));
  }

  static getDerivedStateFromProps(p, state) {
    if (p.refreshCreatives) {
      p.actions.getCreatives({ campaign: p.campaign });
    }
    if (state.setSkcids === false && p.campaign.get('application').get('skadnetwork_settings.usage.remaining')) {
      return {
        setSkcids: true,
      };
    }
    return null;
  }

  componentDidMount() {
    this.props.actions.getCreatives({ campaign: this.props.campaign });
  }

  updateSkcid = (c, v) => {
    const { addedCreativeIds } = this.state;
    // if toggling on and it was not an existing attached creative
    if (v && this.creativeIds.indexOf(c.get('id')) === -1) {
      const newCreativeIds = { [c.get('id')]: true, ...addedCreativeIds };
      this.setState({ addedCreativeIds: newCreativeIds });
    }
    // if toggling off and it was not an existing attached creative
    if (!v && this.creativeIds.indexOf(c.get('id')) === -1) {
      const newCreativeIds = { ...addedCreativeIds };
      delete newCreativeIds[c.get('id')];
      this.setState({ addedCreativeIds: newCreativeIds });
    }
  }

  render() {
    if (isUndefined(this.props.creatives)) {
      return null;
    }
    return (
      <Creatives
        {...this.props}
        {...this.state}
        creativeIds={this.creativeIds}
        updateSkcid={this.updateSkcid}
      />
    );
  }
}

const toggleAllTemplates = ({ state, values: show }) => updateState(state, {
  creativesNs: {
    showAllTemplates: show,
  },
});

const selectTemplate = ({ state, values: { template } }) => updateState(state, { selectedTemplate: template });

const getCreatives = (() => {
  const action = ({ campaign }) => Creative.getAllWithTemplateReplacements({
    application: campaign.get('application').get('id'),
    perPage: 5000,
  });
  const end = ({ state, result }) => updateState(state, {
    creatives: Creative.make(result.response),
    refreshCreatives: false,
  });
  const start = ({ state }) => updateState(state, {
    refreshCreatives: false,
  });
  return { action, end, start };
})();

const previewCreative = ({ state, values }) => updateState(state, { creativeToPreview: values.creative });

const actions = {
  getCreatives,
  previewCreative,
  selectTemplate,
  toggleAllTemplates,
};

export default compose(CreativesContainer, { actions, props });
