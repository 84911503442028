import { map, isArray } from 'lodash';
import { getClient } from '../setup';
import Campaign from '../../../models/Campaign';
import Creative from '../../../models/Creative';
import { getSearchAndIDsFilter, getGraphQLRateType, getStatusFilter } from './helper';
import {
  getReportsTrend,
  getReportsExport,
  GetReportingDetail,
  GetReportingApplicationCampaignDetail,
  GetReportingApplicationCreativeDetail,
  GetReportingAbTest,
  GetReportingResources,
} from '../operations_reporting.graphql';
import handleGraphQLError from './handle-graphql-error';
import { getGraphQLPlatform } from './get-graphql-platform';
import { convertToRestFormat as convertApplicationToRestFormat } from './application';
import { convertToRestFormat as convertCampaignToRestFormat } from './campaigns';
import { convertToRestFormat as convertCreativeToRestFormat } from './creative';

export const handleReportingDetailResponse = ({
  data: {
    reportingDetail,
  } = {},
}) => ({
  ...reportingDetail,
  data: reportingDetail.data.map((row) => {
    const temp = row;
    if (temp.campaign) {
      const servingStatus = temp.campaign.servingStatus ? {
        ...temp.campaign.servingStatus,
        has_account_budget: temp.campaign.servingStatus.hasAccountBudget,
        has_budget: temp.campaign.servingStatus.hasBudget,
        io_signed: temp.campaign.servingStatus.ioSigned,
        not_expired: temp.campaign.servingStatus.notExpired,
      } : null;
      const campaignModel = new Campaign(convertCampaignToRestFormat(temp.campaign));
      temp.campaign = {
        ...temp.campaign,
        is_archived: temp.campaign.isArchived,
        thumbnail: temp.campaign.application?.store?.thumbnail,
        platform: temp.campaign.application?.platform,
        isServer: campaignModel.isEligibleToServe(),
        isActive: campaignModel.isActive(),
        serving_status: servingStatus,
        settings: {
          ad_type: temp.campaign.settings?.adType,
        },
        dataModel: campaignModel,
      };
    } else if (temp.creative) {
      const creativeModel = new Creative(convertCreativeToRestFormat(temp.creative));
      temp.creative = {
        ...temp.creative,
        name: creativeModel.getFriendlyName(),
        isServer: creativeModel.isEligibleToServe(),
        isActive: creativeModel.isActive(),
        platform: temp.creative.application?.platform,
        thumbnail: temp.creative.application?.store?.thumbnail,
        dataModel: creativeModel,
      };
    } else if (temp.application) {
      temp.application = {
        ...temp.application,
        updated: temp.application.dateModified,
        thumbnail: temp.application.store?.thumbnail,
        isActive: temp.application.adminStatus === 'approved',
      };
    } else if (temp.publisher) {
      temp.publisher = {
        ...temp.publisher,
        thumbnail: temp.publisher.store?.thumbnail,
      };
    }
    return temp;
  }),
});

export const handleTrendDataResponse = ({
  data: {
    reportingTrend,
  } = {},
}) => reportingTrend;

export const handleReportsExportResponse = ({
  data: {
    reportingExport,
  } = {},
}) => reportingExport;

export const getTrendData = (query = {}, dimension) => getClient()
  .query({
    query: getReportsTrend,
    variables: {
      ...query,
      ...getStatusFilter(query, dimension),
      campaignTypes: query.campaignTypes?.map(getGraphQLRateType),
    },
  })
  .then(handleTrendDataResponse)
  .catch(handleGraphQLError);

export const reportsExport = (query = {}, dimension) => getClient()
  .query({
    query: getReportsExport,
    variables: {
      ...query,
      ...getStatusFilter(query, dimension),
      campaignTypes: query.campaignTypes?.map(getGraphQLRateType),
    },
  })
  .then(handleReportsExportResponse)
  .catch(handleGraphQLError);

export const getReportingDetail = (query = {}) => getClient()
  .query({
    query: GetReportingDetail,
    variables: {
      ...query,
      campaignTypes: query.campaignTypes?.map(getGraphQLRateType),
      page: parseInt(query.page, 10),
      per_page: parseInt(query.perPage, 10),
    },
  })
  .then(handleReportingDetailResponse)
  .catch(handleGraphQLError);

export const getReportingApplicationCampaignDetail = (query = {}) => getClient()
  .query({
    query: GetReportingApplicationCampaignDetail,
    variables: {
      ...query,
      ...getStatusFilter(query, 'campaign'),
      page: parseInt(query.page, 10),
      per_page: parseInt(query.perPage, 10),
    },
  })
  .then(handleReportingDetailResponse)
  .catch(handleGraphQLError);

export const getReportingApplicationCreativeDetail = (query = {}) => getClient()
  .query({
    query: GetReportingApplicationCreativeDetail,
    variables: {
      ...query,
      ...getStatusFilter(query, 'creative'),
      page: parseInt(query.page, 10),
      per_page: parseInt(query.perPage, 10),
    },
  })
  .then(handleReportingDetailResponse)
  .catch(handleGraphQLError);

function handleReportingAbTestResponse({
  data: {
    reportingAbTest,
  } = {},
}) {
  return {
    ok: true,
    status: 200,
    response: map(reportingAbTest, (t) => ({ ...t, ...(t.metrics || {}) })),
  };
}

export const getReportingAbTest = (query = {}) => getClient()
  .query({
    query: GetReportingAbTest,
    variables: query,
  })
  .then(handleReportingAbTestResponse)
  .catch(handleGraphQLError);

// Reporting resources handler.

export function makeGetReportingResourceVariables(filters) {
  const {
    application, platform, dimension, campaignTypes, ...other
  } = filters;

  const variables = {
    dimension,
    ...other,
  };

  const { ids, search } = getSearchAndIDsFilter(other);
  variables.search = search;
  if (dimension === 'application') {
    variables.applications = ids;
  }
  if (dimension === 'campaign') {
    variables.campaigns = ids;
  }
  if (dimension === 'creative') {
    variables.creatives = ids;
  }

  Object.assign(variables, getStatusFilter(filters, dimension));

  if (isArray(filters.deletionStatus) && filters.deletionStatus.length > 0) {
    [variables.deletionStatus] = filters.deletionStatus;
  }

  if (application) {
    variables.applications = application;
  }

  if (platform) {
    variables.platforms = platform.map(getGraphQLPlatform);
  }

  if (isArray(campaignTypes)) {
    variables.campaignTypes = campaignTypes.map(getGraphQLRateType);
  }

  return variables;
}

export const handleGetReportingResourcesResponse = ({
  data: {
    reportingDetail,
  } = {},
}) => ({
  ...reportingDetail,
  data: reportingDetail.data.map((row) => ({
    ...row,
    application: convertApplicationToRestFormat(row.application),
    campaign: new Campaign(convertCampaignToRestFormat(row.campaign)),
    creative: new Creative(convertCreativeToRestFormat(row.creative)),
  })),
});

export const getReportingResources = (query = {}) => getClient()
  .query({
    query: GetReportingResources,
    variables: makeGetReportingResourceVariables(query),
  })
  .then(handleGetReportingResourcesResponse)
  .catch(handleGraphQLError);
