import React from 'react';
import Tooltip from '../../components/Tooltip/TooltipContainer';
import Label from '../../components/V2/Label/Label';
import './pill.scss';

const tooltips = {
  pending: 'Awaiting approval from an administrator',
  active: 'Serving',
  paused: 'Not Serving',
  rejected: 'Your resource was rejected and will not serve, please contact your account manager',
  approved: 'Your resource was approved, and will serve when Active',
  audit: 'Awaiting validation from Admin',
  inactive: 'Not Active',
  test: 'Test',
  decommissioned: 'Decommissioned',
};

const Pill = ({ className, status }) => (
  <div className={className}>
    <Tooltip title={status} body={tooltips[status]}>
      <Label className={`template__pill__status--${status}`}>{status}</Label>
    </Tooltip>
  </div>
);

export default Pill;
