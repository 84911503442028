import React from 'react';
import PropTypes from 'prop-types';
import TextContainer from './TextContainer';
import './copy.scss';

const Copy = (props) => {
  const { value, disabled } = props;
  return (
    <div className="form__copy__container">
      <TextContainer className="p6 copy" icon="content_copy" disabled={disabled} value={value} />
    </div>
  );
};

Copy.propTypes = {
  /**
   * copy value
   */
  value: PropTypes.string,
  /**
   * disabled
   */
  disabled: PropTypes.bool,
};

Copy.defaultProps = {
  value: '',
  disabled: false,
};

export default Copy;
