import moment from 'moment';
import {
  FILTER_KEY_COUNTRY,
  FILTER_KEY_CAMPAIGN,
  FILTER_KEY_CREATIVE,
  FILTER_KEY_PUBLISHER,
  FILTER_KEY_CAMPAIGN_STATUS,
  FILTER_KEY_LANGUAGE,
  FILTER_KEY_ASSET,
  FILTER_KEY_STATUS,
  FILTER_KEY_CREATIVE_LIST_FORMAT,
} from 'app/constants/filters';
import Campaign from '../../../../../models/Campaign';
import Creative from '../../../../../models/Creative';
import { getFilter } from '../../../../../components/V2/Filters/filters';
import { getFilters } from '../../../../../components/V2/Report/Filters/CompareFilter/helper';
import { DATE_RANGE_QUICK_MAP } from './Components/DateRangePicker/helper';
import { QUICK_PICKERS } from './Components/DateRangePicker/constants';

export const FILTER_KEY_MAPPING = {
  campaign: [
    FILTER_KEY_CAMPAIGN,
    FILTER_KEY_CAMPAIGN_STATUS,
  ],
  creative: [
    FILTER_KEY_ASSET,
    FILTER_KEY_CAMPAIGN,
    FILTER_KEY_COUNTRY,
    FILTER_KEY_CREATIVE,
    FILTER_KEY_CREATIVE_LIST_FORMAT,
    FILTER_KEY_LANGUAGE,
    FILTER_KEY_PUBLISHER,
    FILTER_KEY_STATUS,
  ],
};

export const COMPARE_KEY_MAPPING = {
  campaign: [
    FILTER_KEY_CAMPAIGN,
    FILTER_KEY_COUNTRY,
    FILTER_KEY_CREATIVE,
    FILTER_KEY_PUBLISHER,
  ],
  creative: [
    FILTER_KEY_CAMPAIGN,
    FILTER_KEY_COUNTRY,
    FILTER_KEY_CREATIVE,
    FILTER_KEY_CREATIVE_LIST_FORMAT,
    FILTER_KEY_LANGUAGE,
    FILTER_KEY_PUBLISHER,
  ],
};

export const isCampaignDimension = (dimension) => dimension === 'campaign';

export const initCombineFilters = (node, dimension) => (
  FILTER_KEY_MAPPING[dimension].reduce((acc, cur) => [
    ...acc,
    {
      ...getFilter(cur),
      ...({
        searchParams: {
          application: node.get('id'),
        },
      }),
    },
  ],
  [])
);

export const initCompareFilters = (dimension) => getFilters(COMPARE_KEY_MAPPING[dimension]).sort((a, b) => (a.name > b.name ? 1 : -1));

export const initCheckedDate = (quickKey) => ({
  quickKey,
  key: QUICK_PICKERS.find((item) => item.quickKey === quickKey)?.key,
  ...DATE_RANGE_QUICK_MAP[quickKey].generateDate(moment()),
});

export const initCheckedCombine = (dimension) => FILTER_KEY_MAPPING[dimension].reduce((acc, cur) => ({
  ...acc,
  [cur]: [],
}), {});

export function combineFiltersCacheData(cacheData, node, checkedCombine) {
  const checkedNodes = {
    application: [{
      id: node.get('id'),
      loading: false,
      name: node.get('name'),
      children: [],
      childrenKeys: [],
      node,
    }],
    ...checkedCombine,
  };
  const wrapper = { ...cacheData };
  Object.keys(cacheData).forEach((cacheKey) => {
    if (Object.keys(checkedNodes).indexOf(cacheKey) !== -1) {
      const nodes = [];
      const itemData = cacheData[cacheKey];
      checkedNodes[cacheKey].forEach((checkedNode) => {
        if (itemData.findIndex((data) => data.id === checkedNode.id) === -1) {
          nodes.push(checkedNode);
        }
      });
      wrapper[cacheKey] = [
        ...itemData,
        ...nodes,
      ];
    }
  });
  return wrapper;
}

export const detectDataEmpty = async (dimension, params) => {
  let func = Creative.getAll;
  if (isCampaignDimension(dimension)) {
    func = Campaign.getAll;
  }
  const {
    pagination: {
      total,
    },
  } = await func(params);

  return total === 0;
};
