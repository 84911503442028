import React, {
  useState, useCallback, useEffect,
} from 'react';
import { formatsMap } from 'app/constants/campaign';
import { getPlacementAllowList } from 'lib/helpers/campaigns/campaignIndex';
import usePagination from '../../../../lib/hooks/usePagination';
import { defaultSelectorRender } from '../../../../components/V2/Table/hoc/withSelector';
import Table from '../../../../components/V2/Table/Table';
import Filters from './Filters';
import { isAfter } from '../../../../lib/date';
import { getCreativeTypeTargeting } from '../../../../lib/helpers/creatives/creativeIndex';
import '../../../../styles/_components.scss';

const getTargetingCharCode = (resource) => (
  getCreativeTypeTargeting(getPlacementAllowList(resource)).charCodeAt()
);

const getNormalStringCharCode = (resource, sortKey) => (
  resource.get(sortKey)?.charCodeAt()
);

const isCreativeMatchedFormat = (creative, formats) => {
  if (formats.length === 0) return true;
  const tempFormats = formats.reduce((prev, format) => [...prev, ...(formatsMap[format] || [format])], []);
  return tempFormats.includes(creative.get('template.name'));
};

const BaseTable = ({
  data = [],
  selectable = true,
  placeholder,
  loading,
  selectedIds = [],
  selectResource = () => {},
  disableRowSelector = () => {},
  getColumns = () => {},
  hideFilter = false,
  hideHeader = false,
  filterTypes = [],
  shouldShowRemoveAll,
  upgradeSKANComponent,
  itemNameMatchFn = (resource, searchTerm) => {
    const name = resource.get('name')?.toLocaleLowerCase() || '';
    return name.includes(searchTerm.toLocaleLowerCase());
  },
  disableUnselected = false,
  disabledOptions,
  filterLabels,
  disabledDropDownFilters,
  hiddenOptions,
  renderRadio,
  hideOnNoData,
  onClearAll,
}) => {
  const [pagination, setPagination] = useState({
    page: 1, pages: 1, perPage: 10, total: 0,
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const [queryData, setQueryData] = useState([]);

  const [pageData] = usePagination(
    queryData,
    pagination.page,
    pagination.perPage,
    (page) => setPagination({ ...pagination, page }),
  );

  useEffect(() => {
    setQueryData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setPagination({
      ...pagination,
      page: 1,
      total: queryData.length,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData]);

  useEffect(() => {
    const pageSelectedRows = [];
    pageData.forEach((resource) => {
      if (selectedIds.includes(resource.get('id'))) {
        pageSelectedRows.push(resource);
      }
    });
    setSelectedRows(pageSelectedRows);
  }, [selectedIds, pageData]);

  const refreshList = useCallback((query) => {
    const {
      search = '',
      status = [],
      campaignStatus = [],
      creativeType = [],
      language = [],
      adType = [],
      is_skadnetwork_enabled: isSkAdNetworkEnabled = [],
      creativeListFormat = [],
      asset_orientation: assetOrientation = [],
      skOverlayAutoShow,
      videoOverlayAutoShow,
    } = query;

    const queryStatus = status.map((s) => s.status);
    const queryCampaignStatus = campaignStatus.map((s) => s.status);
    const queryType = creativeType.map((t) => t.name);
    const queryIds = creativeType.map((t) => t.id);
    const queryLanguage = language.map((l) => l.name.toLowerCase());
    const queryAdType = adType.map((t) => t.key.toLowerCase());
    const querySkadnetwork = isSkAdNetworkEnabled.map((s) => s.status);
    const queryCreativeFormat = creativeListFormat.reduce((cur, next) => cur.concat(next.children.map((t) => t.id)), []);
    const queryAssetOrientation = assetOrientation.map((s) => s.id);
    const queryOverlayAutoShow = (skOverlayAutoShow || videoOverlayAutoShow || []).map((s) => s.id);

    const eligibleData = data.filter((resource) => {
      const id = resource.get('id');
      if (id !== search && !itemNameMatchFn(resource, search)) {
        return false;
      }

      if (queryStatus.length > 0) {
        const resourceStatus = resource.isEligibleToServe() ? 'active' : 'paused';
        const adminStatus = resource.get('admin_status');

        if (!queryStatus.includes(resourceStatus)
          && !queryStatus.includes(adminStatus)) {
          return false;
        }
      }

      if (queryCampaignStatus.length > 0) {
        const resourceStatus = resource.get('status');
        if (!queryCampaignStatus.includes(resourceStatus)) {
          return false;
        }
      }

      if (queryType.length > 0
        && !queryType.includes(resource.get('template.name'))
        && !queryIds.includes(resource.get('template.id'))
        && !queryIds.includes(resource.get('template.originalTemplateID'))) {
        return false;
      }

      if (queryLanguage.length > 0
        && !queryLanguage.includes(resource.get('language.name').toLowerCase())) {
        return false;
      }

      if (queryAdType.length > 0
        && !queryAdType.some((t) => resource.get(`targeting.placement_type_whitelist.${t}`))) {
        return false;
      }

      if (!isCreativeMatchedFormat(resource, queryCreativeFormat)) {
        return false;
      }

      if (queryAssetOrientation.length > 0
        && !queryAssetOrientation.includes(resource.get('video.orientation'))) {
        return false;
      }

      if (querySkadnetwork.length > 0) {
        const statusSkAdNetwork = resource.get('is_skadnetwork_enabled') ? 'enabled' : 'disabled';
        return querySkadnetwork.includes(statusSkAdNetwork);
      }

      if (queryOverlayAutoShow.length === 1) {
        const replacements = resource.get('replacements');
        const shouldSKOverlayAutoShow = queryOverlayAutoShow[0];
        if (shouldSKOverlayAutoShow) {
          return replacements.SKOVERLAY_AUTO === 'true';
        }
        return !replacements.SKOVERLAY_AUTO || replacements.SKOVERLAY_AUTO === 'false';
      }

      return true;
    });

    setQueryData(eligibleData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSelectedChange = useCallback((selectedResourceList, row) => {
    setSelectedRows(selectedResourceList);

    const tempSelectedIds = [...selectedIds];
    pageData.forEach((resource) => {
      const id = resource.get('id');
      const selectedResource = selectedResourceList.find((c) => c.get('id') === resource.get('id'));
      if (selectedResource) {
        if (!tempSelectedIds.includes(id)) {
          tempSelectedIds.push(id);
        }
      } else {
        const index = tempSelectedIds.findIndex((selectedId) => id === selectedId);
        if (index !== -1) {
          tempSelectedIds.splice(index, 1);
        }
      }
    });
    selectResource(tempSelectedIds, row);
    // --> potential bug inside this useCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData, selectedIds]);

  const getCompareFunction = (sortOrder, sortKey) => {
    const truthyConditionValue = sortOrder ? -1 : 1;
    const falsyConditionValue = sortOrder ? 1 : -1;

    if (['created', 'updated'].includes(sortKey)) {
      return (prev, next) => (
        isAfter(next.get(sortKey), prev.get(sortKey))
          ? truthyConditionValue
          : falsyConditionValue
      );
    }

    if (['targeting'].includes(sortKey)) {
      return (prev, next) => (
        sortOrder
          ? getTargetingCharCode(next) - getTargetingCharCode(prev)
          : getTargetingCharCode(prev) - getTargetingCharCode(next)
      );
    }

    return (prev, next) => (
      sortOrder
        ? getNormalStringCharCode(next, sortKey) - getNormalStringCharCode(prev, sortKey)
        : getNormalStringCharCode(prev, sortKey) - getNormalStringCharCode(next, sortKey)
    );
  };

  const handleSortDate = ((sortArray) => {
    const sortKey = sortArray[0].id;
    const sortedData = [...queryData];
    sortedData.sort(getCompareFunction(sortArray[0].desc, sortKey));
    setQueryData(sortedData);
  });

  const onPageSizeChange = useCallback((page, perPage) => {
    setPagination({ ...pagination, page, perPage });
  }, [pagination]);

  const RemoveAllColumn = () => (
    <button
      className="vg-styleless-button"
      type="button"
      onClick={() => {
        setSelectedRows([]);
        if (onClearAll) {
          onClearAll();
        } else {
          selectResource([]);
        }
      }}
    >
      <i className="material-icons">close</i>
    </button>
  );

  return (
    <>
      {
        !hideFilter && (
          <div className="table-filters">
            <Filters
              placeholder={placeholder}
              perPage={pagination.perPage}
              onRefresh={refreshList}
              filterTypes={filterTypes}
              disabledOptions={disabledOptions}
              filterLabels={filterLabels}
              disabledDropDownFilters={disabledDropDownFilters}
              hiddenOptions={hiddenOptions}
            />
            { upgradeSKANComponent }
          </div>
        )
      }
      <Table
        manual
        resizable
        onSorted={handleSortDate}
        className={hideHeader ? 'table-with-no-header' : ''}
        selectable={selectable}
        showPagination
        page={pagination.page}
        total={pagination.total}
        pageSize={pagination.perPage}
        onPageSizeChange={onPageSizeChange}
        data={pageData}
        columns={getColumns()}
        loading={loading}
        rowSelector={(resource) => resource.get('id')}
        disableRowSelector={disableRowSelector}
        selectedRows={selectedRows}
        onSelectedChange={onSelectedChange}
        renderAll={shouldShowRemoveAll ? RemoveAllColumn : defaultSelectorRender}
        disableUnselected={disableUnselected}
        renderRow={renderRadio}
        hideOnNoData={hideOnNoData}
      />
    </>
  );
};

export default BaseTable;
