import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './message.scss';

const Icon = ({ type }) => {
  let iconname = '';
  switch (type) {
    case 'success':
      iconname = 'done';
      break;
    case 'error':
      iconname = 'info_outline';
      break;
    default:
      iconname = 'info_outline';
  }
  return <i className="material-icons">{iconname}</i>;
};

const OneMessage = ({ msg, prefixCls, className }) => (
  <div className={classnames(prefixCls, className, 'f', 'fv')}>
    {msg.closeBtn && (
      <div
        className="flag-close-button"
        onClick={() => msg.closeCb(msg.id)}
      >
        <i className="material-icons">clear</i>
      </div>
    )}
    <div className={`flag-left flag-${msg.type}`}>
      <Icon type={msg.type} />
    </div>
    <div className="flag-right">
      <div className="flag-title p7">{msg.title}</div>
      <div className="flag-text p6">{msg.content}</div>
    </div>
  </div>
);

OneMessage.propTypes = {
  prefixCls: PropTypes.string,
  className: PropTypes.string,
  msg: PropTypes.shape({
    title: PropTypes.node,
    content: PropTypes.node,
    type: PropTypes.oneOf(['success', 'error']),
    closeBtn: PropTypes.bool,
    timeout: PropTypes.number,
    closeCb: PropTypes.func,
  }).isRequired,
};

OneMessage.defaultProps = {
  prefixCls: 'v2_component_flag-message',
  className: null,
};

export default OneMessage;
