import React from 'react';
import classnames from 'classnames';

const makeStyle = (show, count, main = null, optionHeight = 36) => {
  if (!show) {
    return { style: { minHeight: `${optionHeight}px` } };
  }
  return { style: { minHeight: `${(count * optionHeight) + (main && optionHeight)}px` } };
};

const Option = ({
  className = 'select-options-option',
  children,
  value,
  hidden = false,
  onClick,
  optionHeight,
  checked = false,
}) => (
  <div
    className={classnames(className, { [`${className}-hidden`]: hidden, [`${className}-checked`]: checked })}
    onClick={() => onClick(value)}
    {...makeStyle(true, 1, false, optionHeight)}
  >
    {children}
  </div>
);

export default Option;
