import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import { noop } from 'lodash';
import PickerInput from './PickerInput';
import PickerDate from './PickerDate';
import PickerMonth from './PickerMonth';
import './pickerCustom.scss';

export const prefixCls = 'v2_component_report_calendar_picker_custom';

export const monthFormatString = 'MMMM YYYY';

const now = moment();

const sixMonthAgo = now.clone().subtract(6, 'months');

function getMonthData(initDate) {
  const currentMonthDaysCount = initDate.daysInMonth();
  const currentMonth = {
    month: initDate.format(monthFormatString),
    monthDate: initDate.clone(),
    days: [],
  };
  for (let i = 0; i < currentMonthDaysCount; i++) {
    const currentDay = initDate.clone().startOf('month');
    const date = currentDay.subtract(-i, 'day');
    const week = date.day();
    if (i === 0) {
      for (let j = 0; j < week; j++) {
        currentMonth.days.push({ day: null });
      }
    }
    currentMonth.days.push({
      date,
      week,
      day: date.date(),
      today: date.isSame(now, 'day'),
      disable: !date.isBetween(sixMonthAgo, now, 'day', '(]') || date.isSame(now, 'day'),
    });
    if (i === currentMonthDaysCount - 1) {
      for (let j = week + 1; j < 7; j++) {
        currentMonth.days.push({ day: null });
      }
    }
  }
  return currentMonth;
}

export function initMonth(date = moment()) {
  return [
    getMonthData(date.clone().subtract(1, 'month')),
    getMonthData(date.clone()),
  ];
}

function PickerCustom({
  startDate,
  endDate,
  onChange,
}) {
  const [month, setMonth] = React.useState(initMonth());
  const [start, setStart] = React.useState(null);
  const [end, setEnd] = React.useState(null);
  const [preview, setPreview] = React.useState(null);
  const [isShowMonthPicker, toggleMonthPicker] = React.useState(false);
  const isPrevDisabled = month[0].monthDate.format('YYYY-MM') === sixMonthAgo.format('YYYY-MM');
  const isNextDisabled = month[1].monthDate.format('YYYY-MM') === now.format('YYYY-MM');

  React.useEffect(() => {
    if (moment.isMoment(startDate) && moment.isMoment(endDate)) {
      setStart(startDate);
      setEnd(endDate);
      if (
        endDate.isBefore(month[0].monthDate, 'month')
        || endDate.isAfter(month[1].monthDate, 'month')
      ) {
        setMonth(initMonth(endDate));
      }
    }
    // --> potential bug inside this useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  // select date range
  const onSelect = React.useCallback(({ date, disable }) => {
    if (!moment.isMoment(date) || disable) {
      return;
    }
    setPreview(null); // when select clear preview
    if (!moment.isMoment(start) || moment.isMoment(end)) {
      setStart(date);
      setEnd(null);
      return;
    }
    if (date.isAfter(start)) {
      setEnd(date);
      onChange({ startDate: start, endDate: date });
    } else {
      setStart(date);
      setEnd(start);
      onChange({ startDate: date, endDate: start });
    }
  }, [start, end, onChange]);

  const onMonthChange = React.useCallback(({ date, disable }) => {
    if (!disable && moment.isMoment(date)) {
      setMonth(initMonth(date.clone().subtract(-1, 'month')));
      toggleMonthPicker(false);
    }
  }, []);

  const onMouseEnter = React.useCallback(({ date, disable }) => {
    if (disable || !moment.isMoment(date)) {
      return;
    }
    if (!moment.isMoment(start) || moment.isMoment(end)) {
      return;
    }
    setPreview(date);
  }, [start, end]);

  const onMouseLeave = React.useCallback(() => {
    setPreview(null);
  }, []);

  const onInputChange = React.useCallback((range) => {
    setStart(range.startDate);
    setEnd(range.endDate);
    onChange(range);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPrevMonth = React.useCallback(() => {
    if (isPrevDisabled) return;
    setMonth(initMonth(month[1].monthDate.subtract(1, 'month')));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month]);

  const onNextMonth = React.useCallback(() => {
    if (isNextDisabled) return;
    setMonth(initMonth(month[1].monthDate.subtract(-1, 'month')));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month]);

  return (
    <div className={prefixCls}>
      <PickerInput
        startDate={start}
        endDate={end}
        onChange={onInputChange}
      />
      <div className={`${prefixCls}-switch`}>
        <div className={`${prefixCls}-switch-month`}>
          <span className={`${prefixCls}-switch-month-text`}>
            {month[0].monthDate.format(monthFormatString)}
          </span>
          <i
            className={classnames(
              'material-icons',
              `${prefixCls}-switch-month-down`,
              {
                [`${prefixCls}-switch-month-down-active`]: isShowMonthPicker,
              },
            )}
            onClick={() => toggleMonthPicker((visible) => !visible)}
          >
            {isShowMonthPicker ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
          </i>
        </div>
        <div className={`${prefixCls}-switch-arrows`}>
          <i className={classnames('material-icons', `${prefixCls}-switch-prev`, isPrevDisabled && `${prefixCls}-switch-disable`)} onClick={onPrevMonth}>
            keyboard_arrow_left
          </i>
          <i className={classnames('material-icons', `${prefixCls}-switch-next`, isNextDisabled && `${prefixCls}-switch-disable`)} onClick={onNextMonth}>
            keyboard_arrow_right
          </i>
        </div>
      </div>
      <div className={`${prefixCls}-picker`}>
        <PickerDate
          month={month}
          startDate={start}
          endDate={end}
          previewDate={preview}
          onChange={onSelect}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
        {
          isShowMonthPicker && <PickerMonth initDate={now} onChange={onMonthChange} />
        }
      </div>
    </div>
  );
}

PickerCustom.propTypes = {
  startDate: PropTypes.objectOf(PropTypes.any),
  endDate: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
};

PickerCustom.defaultProps = {
  startDate: null,
  endDate: null,
  onChange: noop,
};

export default React.memo(PickerCustom);
