import React from 'react';
import Select from './Select';
import Option from './Option';
import './select.scss';

class SelectContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { active: false, searchValue: '' };
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleBlur);
  }

  handleBlur = () => {
    this.setState({ active: false });
    document.removeEventListener('click', this.handleBlur);
  }

  toggle = () => {
    this.setState((prevState) => {
      const active = !prevState.active;
      if (active) {
        document.addEventListener('click', this.handleBlur);
      } else {
        document.removeEventListener('click', this.handleBlur);
      }
      return ({ active });
    });
  }

  render() {
    const { children, value } = this.props;
    const componentChildren = React.Children.toArray(children);
    const isSingleOption = componentChildren && componentChildren.length === 1 && value;
    return (
      <Select
        {...this.props}
        showOptions={!isSingleOption && this.state.active}
        isSingleOption={isSingleOption}
        onSearchValueChange={(v) => this.setState({ searchValue: v })}
        onToggle={this.toggle}
        searchValue={this.state.searchValue}
      />
    );
  }
}

SelectContainer.defaultProps = {
  value: null,
  onChange: () => {},
};

SelectContainer.Option = Option;

export default SelectContainer;
