import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { getTestId } from '../../../lib/testSupport';
import './toggler.scss';

export const TEST_ID = 'TOGGLER_INPUT';

const Toggler = ({
  checked,
  disabled,
  enabledLabel,
  disabledLabel,
  onChange,
  'data-testid': parentTestId,
}) => (
  <div
    className={cn({
      components__input__toggler: true,
      disabled,
    })}
  >
    <input
      type="checkbox"
      checked={checked}
      onChange={(e) => onChange(e.target.checked, e)}
      disabled={disabled}
      data-testid={getTestId(parentTestId, TEST_ID)}
    />
    <div className="toggler">
      <div className={`first-child${!checked ? '--off' : ''}`} />
      <div className={`second-child${!checked ? '--off' : ''}`}>
        <div><span className={`second-inner${!checked ? '--off' : ''}`} /></div>
        <div className="second-inner-two" />
      </div>
      <div className="third-child">{checked ? enabledLabel : disabledLabel}</div>
    </div>
  </div>
);

Toggler.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  enabledLabel: PropTypes.string,
  disabledLabel: PropTypes.string,
  onChange: PropTypes.func,
};

Toggler.defaultProps = {
  checked: false,
  disabled: false,
  enabledLabel: 'Enabled',
  disabledLabel: 'Disabled',
  onChange: () => {},
};

export default Toggler;
