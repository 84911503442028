import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import useRxjsDebounce from 'lib/hooks/useRxjsDebounce';
import { buildURI } from 'react-csv/lib/core';
import { noop } from 'lodash';
import config from '../../../../lib/config';
import Input from '../../Input/Input';
import UploaderContainer from '../../Upload/UploaderContainer';
import './filters.scss';

const { csvHeaders } = config.get('multibidding');

export const CSVLinkUploader = ({ id, onDrop, text }) => (
  <label htmlFor={id}>
    <input
      id={id}
      className="input_files"
      multiple
      type="file"
      accept=".csv"
      onChange={(event) => onDrop(event.target.files)}
    />
    <span className="link_button">{text}</span>
  </label>
);

const Filters = ({
  className,
  filename,
  data,
  onInputChange,
  onAddRow,
  onStart,
  onComplete,
  onError,
  addRowDisable,
  hideAddRow,
  showLinks,
  headers,
  placeholder,
}) => {
  const searchInput$ = useRxjsDebounce((value) => {
    onInputChange({ target: { value } }); // we want to keep the API of onInputChange unchanged
  }, 250);

  const handleInputChange = useCallback((event) => {
    searchInput$.next(event.target.value);

    // --> potential bug inside this useCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onInputChange, searchInput$]);

  const download = (e) => {
    e.target.download = filename;
    // <CsvLink /> generates download url if data is changed
    // if 100k the generation is slow
    // so only when the end-uer clicks download link, to generate the url
    e.target.href = buildURI(data, false, headers || csvHeaders);
  };

  return (
    <div className={classnames('views__publishers_table_filters', className)}>
      <Input.Search placeholder={placeholder} onChange={handleInputChange} />
      {showLinks && (
        <div className="wrapper">
          {
            !hideAddRow && (
              <>
                <button
                  disabled={addRowDisable}
                  type="button"
                  className={classnames('link_button', 'add__row', { disabled: addRowDisable })}
                  onClick={onAddRow}
                >
                  Add Row
                </button>
                <div className="separator" />
              </>
            )
          }
          <UploaderContainer
            id="multi-bidder_filter_csv_link--override"
            className="upload__overide"
            type="csv"
            onStart={onStart}
            onComplete={(rates) => onComplete({ rates, override: true })}
            onError={onError}
            text="Upload/Override List Below"
          >
            {CSVLinkUploader}
          </UploaderContainer>
          <div className="separator" />
          <UploaderContainer
            id="multi-bidder_filter_csv_link--append"
            className="upload__overide"
            type="csv"
            onStart={onStart}
            onComplete={(rates) => onComplete({ rates, override: false })}
            onError={onError}
            text="Add/Append List Below"
          >
            {CSVLinkUploader}
          </UploaderContainer>
          <div className="separator" />
          <a
            href="#"
            className="download_list"
            onClick={download}
          >
            Download List Below
          </a>
        </div>
      )}
    </div>
  );
};

Filters.propTypes = {
  /**
   * Class name string.
   */
  className: PropTypes.string,
  /**
   * Download file name.
   */
  filename: PropTypes.string.isRequired,
  /**
   * Download data.
   */
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * Trig when input search value change.
   */
  onInputChange: PropTypes.func,
  /**
   * Trig when click `Add Row` link.
   */
  onAddRow: PropTypes.func,
  /**
   * Start to upload.
   */
  onStart: PropTypes.func,
  /**
   * upload complete.
   */
  onComplete: PropTypes.func,
  /**
   * show right menus
   */
  showLinks: PropTypes.bool,
};

Filters.defaultProps = {
  className: null,
  onInputChange: noop,
  onAddRow: noop,
  onStart: noop,
  onComplete: noop,
  showLinks: true,
};

export default React.memo(Filters, (p, n) => p.data === n.data);
