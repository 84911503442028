import React, { Component } from 'react';
import DeviceFrame from '../../../../../../components/DeviceFrame/DeviceFrame';
import PreviewModel from '../../../../../../models/Preview';
import { ERRORS, CHAMPA_FORMATS, AC_DEFAULT_TOKENS } from '../../../../../../components/DeviceFrame/settings';
import './AssetPreviewerBundle.scss';

class AssetPreviewerBundle extends Component {
  constructor(p) {
    super(p);
    this.state = {
      bundlePreviewURL: null,
    };

    this.unmounted = false;
  }

  componentWillUnmount = () => {
    this.unmounted = true;
  }

  componentDidMount = async () => {
    this.unmounted = false;

    const { asset } = this.props;

    if (!asset) {
      return;
    }

    this.setPreviewURL();
  }

  async setPreviewURL() {
    const { asset } = this.props;
    const bundleS3URL = await this.getBundlePreviewURL();

    if (this.unmounted) {
      return;
    }

    const replacements = encodeURIComponent(JSON.stringify(AC_DEFAULT_TOKENS));

    this.setState({
      bundlePreviewURL: `${bundleS3URL}?platform=${asset.raw('application.platform')}&replacements=${replacements}`,
    });
  }

  async getBundlePreviewURL() {
    const { asset } = this.props;
    if (!asset) {
      return ERRORS.assetUrl;
    }

    const assetId = asset.get('id');
    const cdnUrl = asset.get('url');
    const format = CHAMPA_FORMATS.adaptiveCreativeAsset;

    if (!assetId || !cdnUrl) {
      return ERRORS.assetUrl;
    }

    try {
      const { response } = await PreviewModel.getCdnURL({
        id: assetId,
        format,
        url: cdnUrl,
      });
      return response.url || ERRORS.assetUrl;
    } catch (x) {
      return ERRORS.assetUrl;
    }
  }

  render() {
    const { bundlePreviewURL } = this.state;

    return (
      <div className="device-frame-wrapper">
        <DeviceFrame
          assetURL={bundlePreviewURL}
          placementType="single_page_fullscren"
          format="vungle_mraid"
        />
      </div>
    );
  }
}

export default AssetPreviewerBundle;
