import { noop } from 'lodash';
import React from 'react';
import { AutoSizer, InfiniteLoader, List } from 'react-virtualized';
import ServingStatus from '../../../../../../../../components/Assets/ServingStatus/ServingStatus';
import imgVideogameThumbnail from '../../../../../../../../assets/img/videogame_thumbnail.svg';
import IconComponent from '../../../../../../../../components/Icon/Icon';
import Copy from '../../../../../../../../components/V2/Copy/Copy';
import NoData from '../../../../../../../../components/V2/Table/component/NoData';
import { convert } from '../../../../../../../../lib/date';
import { getAssetOrientation, prettifySize, formatDuration } from '../../../../../assets/list_2/utils';
import './SelectableAssetList.scss';

const getThumbnailAndIcon = (asset) => {
  switch (asset.get('type')) {
    case 'video':
      return {
        thumbnail: asset.get('thumbnails.landscape'),
        icon: <IconComponent.Video />,
      };
    case 'bundle_adaptive_creative':
      return {
        thumbnail: asset.get('thumbnails.landscape') || imgVideogameThumbnail,
        icon: <IconComponent.Game />,
      };
    default:
      return {
        thumbnail: asset.get('url'),
        icon: <IconComponent.Image />,
      };
  }
};

export const AssetInfoCell = ({ asset, className }) => {
  const type = asset.get('type');
  const { thumbnail } = getThumbnailAndIcon(asset);
  return (
    <div className={`asset_info_cell ${className}`}>
      <div className={`thumbnail_container thumbnail_container-${type}`}>
        <img className="thumbnail" src={thumbnail} />
      </div>
      <div className="name_and_id">
        <span className="name">
          {asset.get('name')}
        </span>
        <div onClick={(e) => e.stopPropagation()}><Copy value={asset.get('id')} /></div>
      </div>
    </div>
  );
};

export const SimpleCell = ({ children, width }) => (
  <span className="simple_cell" style={{ width }}>
    {children}
  </span>
);

const SelectableAssetList = ({
  type,
  assets = [],
  loadingAssets = false,
  onSelectAsset,
  onScrollEnd,
  totalAssetCount,
  onReviewHistoryClick,
}) => {
  const loadMoreRows = loadingAssets ? noop : onScrollEnd;
  const isRowLoaded = ({ index }) => index < assets.length;
  const rowRenderer = ({ index, key, style }) => {
    if (!isRowLoaded({ index })) {
      return (
        <div key={key} style={style}>
          <div className="asset-row" />
        </div>
      );
    }

    const asset = assets[index];
    const duration = asset.get('exif.duration');

    if (type === 'bundle_adaptive_creative') {
      return (
        <div key={key} onClick={() => onSelectAsset(asset)} style={style}>
          <div className="asset-row">
            <AssetInfoCell className="asset_info_cell-bundle" asset={asset} />
            <ServingStatus status={asset.get('status')} withHistory onHistoryClick={() => onReviewHistoryClick(asset)} showTooltip stopPropagation />
            <SimpleCell>
              {convert(asset.get('created'), 'DD MMM YYYY')}
            </SimpleCell>
            <SimpleCell>
              {prettifySize(asset.get('exif.size'))}
            </SimpleCell>
          </div>
        </div>
      );
    }

    return (
      <div key={key} onClick={() => onSelectAsset(asset)} style={style}>
        <div className="asset-row">
          <AssetInfoCell asset={asset} />
          <SimpleCell width="100px">
            {convert(asset.get('created'), 'DD MMM YYYY')}
          </SimpleCell>
          <SimpleCell width="100px">
            {getAssetOrientation(asset)}
          </SimpleCell>
          <SimpleCell width="100px">
            {(asset.get('exif.width') && asset.get('exif.height'))
              ? `${asset.get('exif.width')}x${asset.get('exif.height')}`
              : '-'}
          </SimpleCell>
          <SimpleCell width="80px">
            {prettifySize(asset.get('exif.size'))}
          </SimpleCell>
          <SimpleCell width="80px">
            {duration ? formatDuration(duration, true) : '-'}
          </SimpleCell>
        </div>
      </div>
    );
  };

  return (
    <div className="views__selectable_assets__list_2">
      <AutoSizer>
        {({ height, width }) => (
          <InfiniteLoader
            isRowLoaded={isRowLoaded}
            loadMoreRows={() => loadMoreRows(false)}
            rowCount={totalAssetCount}
          >
            {({ onRowsRendered, registerChild }) => (
              <List
                ref={registerChild}
                onRowsRendered={onRowsRendered}
                rowRenderer={rowRenderer}
                height={height}
                rowHeight={70}
                rowCount={totalAssetCount}
                width={width}
                noRowsRenderer={() => <NoData prefixCls="views__selectable_assets__list_2" />}
              />
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    </div>
  );
};

export default SelectableAssetList;
