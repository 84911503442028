import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import Multi from '../Base/Multi/Multi';
import CommonMultiPickerPanel from '../Base/Panels/CommonMultiPickerPanel';
import './CommonMultiPicker.scss';

const CommonMultiPicker = ({
  visible,
  defaultCheckedNodes,
  filterLabel,
  onVisibleChange,
  onChange,
  onRemove,
  data,
  panelWidth,
  filterKey,
  filterValueCls,
  isSelectAllHidden,
  isLinkHidden,
  single,
}) => (
  <Multi
    data={data}
    visible={visible}
    filterKey={filterKey}
    filterLabel={filterLabel}
    filterValueCls={filterValueCls}
    defaultCheckedKeys={defaultCheckedNodes}
    renderOverlay={() => (
      <CommonMultiPickerPanel
        isSelectAllHidden={isSelectAllHidden}
        isLinkHidden={isLinkHidden}
        single={single}
        data={data}
        width={panelWidth}
        defaultCheckedNodes={defaultCheckedNodes}
        onVisibleChange={onVisibleChange}
        onChange={onChange}
      />
    )}
    onVisibleChange={onVisibleChange}
    onRemove={onRemove}
  />
);

CommonMultiPicker.propTypes = {
  visible: PropTypes.bool,
  panelWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultCheckedNodes: PropTypes.arrayOf(PropTypes.any),
  onVisibleChange: PropTypes.func,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.any),
  filterKey: PropTypes.string,
  isSelectAllHidden: PropTypes.bool,
  isLinkHidden: PropTypes.bool,
};

CommonMultiPicker.defaultProps = {
  visible: false,
  panelWidth: 270,
  defaultCheckedNodes: [],
  onVisibleChange: noop,
  onChange: noop,
  onRemove: noop,
  data: [],
  filterKey: '',
  isSelectAllHidden: false,
  isLinkHidden: false,
};

export default CommonMultiPicker;
