import cn from 'classnames';
import React from 'react';
import Dropzone from 'react-dropzone';
import { compose } from '../../../../../../app/app';
import './AssetDropZone.scss';

export const props = (state) => ({
  isDragging: (state.window || {}).isDragging || false,
});

export const AssetDropZoneUnwrapped = ({
  disabled,
  name = 'asset-uploader',
  onDrop,
  isDragging,
  fileErrors,
  icon,
  accept = 'image/*, video/mp4, application/zip, application/x-zip-compressed',
  multiple,
  shouldHidePlayable,
  dropZoneText,
}) => (
  <div className="asset_drop_zone_container">
    <Dropzone
      accept={accept}
      className={cn({
        wrapper: true,
        dragging: isDragging,
      })}
      multiple={multiple !== false}
      disabled={disabled}
      disableClick={(fileErrors && fileErrors.length > 0) || disabled}
      activeClassName="active"
      rejectClassName="reject"
      name={name}
      onDrop={onDrop}
    >
      {({ getRootProps, getInputProps }) => (
        <div className={`wrapper ${isDragging ? 'dragging' : ''}`} {...getRootProps()}>
          {icon || (
            <div className="instructions">
              <i className="material-icons">cloud_upload</i>
              <div className="sentence_container">
                <p className="p2">Drag & drop</p>
                <span className="p2">
                  {dropZoneText || `images, videos${shouldHidePlayable ? '' : ' & playables'}`}
                  <span className="body__link"> or browse</span>
                </span>
                <input name={name} {...getInputProps()} />
              </div>
            </div>
          )}
        </div>
      )}
    </Dropzone>
  </div>
);

export default compose(AssetDropZoneUnwrapped, { props });
