import download from 'lib/download';
import config from 'lib/config';
import { isEmptyObject } from 'lib/lib';
import {
  getAssets,
  getAssetsForFilter,
  getAssetByID,
  getAssetByVideoID,
  getVisualQANames,
  modifyAsset,
  deleteAsset,
  editUploadComments,
  sendFeedback,
  startVisualQA,
  submitVisualQA,
  submitFullQA,
  uploadAsset,
  replaceAsset,
  submitScreenshots,
  assetQAHistory,
  uploadIssueRecords,
  sendToVisualQA,
} from 'app/graphql/utils/asset';
import { ACTIVE } from 'app/constants/asset';
import {
  post, catchFetchError,
} from '../lib/http';
import Model from './BaseModel';
import Application from './Application';

class Asset extends Model {
  constructor(attrs = {}) {
    super(attrs);
    this.map('application', this.getRelation('application', Application).bind(this));
    this.map('thumbnails.landscape', () => this.raw('thumbnails.landscape') && this.raw('thumbnails.landscape')[0]);
    this.map('thumbnails.square', () => this.raw('thumbnails.square') && this.raw('thumbnails.square')[0]);

    const isBundle = this.get('type') === 'bundle_adaptive_creative';
    if (!this.get('status') && isBundle) {
      this.set('status', ACTIVE);
    }

    if (attrs.linkedAssets !== null && !isEmptyObject(attrs.linkedAssets)) {
      this.set('linkedAssets', attrs.linkedAssets);
    }
    this.default('linkedAssets', undefined);
  }

  toServerObject() {
    const obj = {
      name: this.raw('name'),
      tags: this.raw('tags'),
    };
    return obj;
  }

  toCommentServerObject = () => ({
    upload_comments: this.raw('upload_comments'),
  })

  toValidVariationsObject = () => ({
    aggressive: this.get('visualQA.validVariations.aggressive'),
    complete: this.get('visualQA.validVariations.complete'),
    off: this.get('visualQA.validVariations.off'),
  })
}

const baseUrl = `${config.get('manageUrl')}assets`;

Asset.make = Model.make(Asset);
Asset.getAll = (filters) => catchFetchError(getAssets({ page: 1, perPage: 10, ...filters }));
Asset.getAssetsForFilter = (filters) => catchFetchError(getAssetsForFilter({ page: 1, perPage: 10, ...filters }));
Asset.get = (id) => catchFetchError(getAssetByID(id));
Asset.getByVideoId = (id) => catchFetchError(getAssetByVideoID(id));
Asset.getQAHistory = (id, page) => catchFetchError(assetQAHistory(id, page));
Asset.getASOIUsers = (id) => catchFetchError(getVisualQANames(id));
Asset.sendToTestApp = (id, data) => catchFetchError(sendToVisualQA(id, data));
Asset.updateQAStatus = (id, data) => catchFetchError(post(`${baseUrl}/creativeQA/${id}`, data));
Asset.startVisualQA = (id) => catchFetchError(startVisualQA(id));
Asset.submitVisualQA = (id, data) => catchFetchError(submitVisualQA(id, data));
Asset.submitFullQA = (id, data) => catchFetchError(submitFullQA(id, data));
Asset.sendFeedback = (id, data) => catchFetchError(sendFeedback(id, { uploadComments: data }));
Asset.submitScreenshots = (id, data) => catchFetchError(submitScreenshots(id, data));
Asset.uploadRecordings = (id, data) => catchFetchError(uploadIssueRecords(id, data));
Asset.save = (asset) => catchFetchError(modifyAsset(asset.get('id'), asset.toServerObject()));
Asset.saveComment = (asset) => catchFetchError(editUploadComments(asset.get('id'), { uploadComments: asset.get('upload_comments') || '' }));
Asset.deleteAsset = (id) => catchFetchError(deleteAsset(id));
Asset.uploadAsset = (input) => catchFetchError(uploadAsset(input));
Asset.replaceAsset = (id, input) => catchFetchError(replaceAsset(id, input));

// this function is using 'fetch' directly because it is downloading raw file data and the response is not a JSON
// Asset.get function wraps the request and prepares it for JSON, so it cannot be used here
Asset.download = (name, id) => {
  download(`${config.get('madmenUrl')}assets/${id}/download`, name);
};

export default Asset;
