import { getUserConfig } from 'app/graphql/utils/user';
import config from 'lib/config';
import React, {
  compose, Redirect, Route, Switch, namespace, splash as splashFn,
} from '../../../app/app';
import {
  parseQueryString, post, addAuthHeader,
} from '../../../lib/http';
import { set } from '../../../lib/session';
import { getLanguages } from '../../../lib/translator';
import { init, track } from '../../../lib/tracker';
import ErrorContainer from '../../../components/ErrorContainer/ErrorContainerContainer';
import ZendeskWidget from '../../../components/ZendeskWidget/ZendeskWidget';
import App from './app';
import Auth from './public/auth/auth';
import Splash from './splash';
import SSO from './public/sso/sso';
import { MessageContainer } from '../../../components/V2';
import { PlatformDownModal } from '../../../components/V2/PlatformDownModal/PlatformDownModal';
import './setup.scss';

const ns = 'setup';
const updateState = namespace(ns);

const props = (state) => ({
  ...(state[ns] ? state[ns] : {}),
  isAuthenticated: !!state.authUser,
  splash: state.splash,
  redirectFromConfirmation: state.redirectFromConfirmation,
  isDragging: state.window.isDragging,
  token: state.token,
  email: state.email,
});

const Setup = ({
  confirmingAccount = false,
  isAuth = false,
  props: {
    isAuthenticated,
    email = null,
    redirectFromConfirmation = false,
    splash = {
      show: false,
    },
    token = null,
  },
  location = {},
}) => {
  const encodedPath = encodeURIComponent(`${location.pathname}${location.search}`);
  return (
    <div id="react-root">
      <MessageContainer />
      {window.zE && <ZendeskWidget />}
      {isAuth && (
        <Route exact path="/auth" component={Auth} />
      )}
      {!isAuth && confirmingAccount && redirectFromConfirmation && (
        <Redirect to="/" />
      )}
      {!isAuth && confirmingAccount && redirectFromConfirmation && token && email && (
        <Redirect to={`/reset?token=${token}&email=${encodeURIComponent(email)}`} />
      )}
      {!isAuth && !confirmingAccount && isAuthenticated && (
        <Switch>
          <Route exact path="/platform_down" component={PlatformDownModal} />
          <Route path="/" component={App} />
        </Switch>
      )}
      {!isAuth && !confirmingAccount && !isAuthenticated && (
        <Switch>
          <Route exact path="/login" component={SSO} />
          <Redirect to={`/login?to=${encodedPath}`} />
        </Switch>
      )}
      {splash.show && <Splash />}
    </div>
  );
};

class Container extends React.Component {
  constructor(p) {
    super(p);
    this.changedInnerWidth = false;
    this.removedSplasher = false;
    this.startedTimestamp = Date.now();

    this.state = {
      dragEnterCount: 0,
    };
  }

  componentDidMount() {
    this.props.actions.setupApp(this.props.isAuthenticated);
    const s = this.props.location.search;
    const confirmation = parseQueryString(s, 'confirmation');
    const token = parseQueryString(s, 'token');
    const email = parseQueryString(s, 'email');
    if (confirmation) {
      this.props.actions.confirmAccount({ confirmation, token, email });
    }

    document.addEventListener('dragenter', () => {
      this.setState((state) => ({
        dragEnterCount: state.dragEnterCount + 1,
      }));
    });

    document.addEventListener('drop', () => {
      this.setState({ dragEnterCount: 0 });
      this.props.actions.dragging(false);
    });

    document.addEventListener('dragleave', () => {
      this.setState((state) => ({
        dragEnterCount: state.dragEnterCount - 1,
      }));
    });
  }

  componentDidUpdate() {
    if (this.state.dragEnterCount > 0 && !this.props.isDragging) {
      this.props.actions.dragging(true);
    } else if (this.state.dragEnterCount <= 0 && this.props.isDragging) {
      this.props.actions.dragging(false);
    }
  }

  UNSAFE_componentWillReceiveProps(p) {
    if (!this.changedInnerWidth && p.loadingApp) {
      this.changedInnerWidth = true;
    }
    if (!this.removedSplasher && p.loadedApp) {
      this.removedSplasher = true;
      setTimeout(() => {
        const splash = document.getElementById('loading-splasher');
        splash.parentElement.removeChild(splash);
      }, 1200 - (Date.now() - this.startedTimestamp));
    }
    if (!this.props.isAuthenticated && p.isAuthenticated) {
      this.props.actions.setupApp(true);
    }
  }

  render() {
    const isAuth = (this.props.location.pathname === '/auth');
    const confirmation = parseQueryString(this.props.location.search, 'confirmation');
    // wait for setup app
    if (!this.props.loadedApp) {
      return null;
    }
    return (
      <ErrorContainer>
        <Setup {...this.props} confirmingAccount={!!confirmation} isAuth={isAuth} />
      </ErrorContainer>
    );
  }
}

const confirmAccount = (() => {
  const start = () => splashFn({ start: true, text: 'confirming your account' });
  const end = ({ result, props: p }) => {
    if (result.ok) {
      const splashContent = { finish: true, text: 'Success! Welcome to Liftoff!' };
      if (p.token && p.email) {
        return {
          ...splashFn(splashContent),
          redirectFromConfirmation: true,
          token: p.token,
          email: p.email,
        };
      }
      const user = result.response;
      set('authUser', user);
      addAuthHeader(user);
      init(user);
      track('Account Confirmed', { id: user.account.id });
      return {
        ...splashFn(splashContent),
        authUser: user,
        redirectFromConfirmation: true,
      };
    }
    return splashFn({ text: 'Something went wrong. Please contact support' });
  };
  const action = ({ confirmation }) => post(`${config.get('authUrl')}confirm`, {
    token: confirmation,
  });
  return { action, start, end };
})();

const dragging = ({ state, values }) => ({ ...state, window: { isDragging: values } });

const setupApp = (() => {
  const action = async (isAuthed) => {
    const promises = [getLanguages()];
    if (isAuthed) {
      promises.push(getUserConfig());
    }
    const [languages, appConfig] = await Promise.all(promises);
    return { languages, appConfig: appConfig ? appConfig.response : {} };
  };
  const end = ({ state, result: { languages, appConfig } }) => updateState(state, {
    languages,
    appConfig,
    loadedApp: true,
    loadingApp: false,
  });
  const error = ({ state }) => updateState(state, {
    appConfig: {}, languages: [], loadingApp: false, loadedApp: true,
  });
  const start = ({ state }) => updateState(state, { loadingApp: true });
  return {
    action, error, end, start,
  };
})();

export default compose(Container, { props, actions: { confirmAccount, dragging, setupApp } });
export { Setup as SetupContainer, Container as ContainerClass };
