import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';
import Radio from './Radio';
import './radioGroup.scss';

const classPrefix = 'v2_component_radio_group';

const RadioGroup = ({
  className, radioClassName, rows, checkedId: checkedIdProp, onChange,
}) => {
  const [checkedId, setCheckedId] = useState(checkedIdProp);

  useEffect(() => {
    setCheckedId(checkedIdProp);
  }, [checkedIdProp]);

  const handleChange = React.useCallback((item) => {
    const { id, disabled } = item;
    if (disabled) return;
    setCheckedId(id);
    onChange(item);
  }, [onChange]);
  return (
    <div className={classnames(classPrefix, className)}>
      {rows.map((item) => (
        <Radio
          className={classnames('radio', radioClassName)}
          key={item.id}
          checked={item.id === checkedId}
          disabled={item.disabled}
          onChange={() => handleChange(item)}
        >
          {item.label}
        </Radio>
      ))}
    </div>
  );
};

RadioGroup.propTypes = {
  /**
   * Class name string.
   */
  className: PropTypes.string,
  /**
   * Child node of radio name string.
   */
  radioClassName: PropTypes.string,
  /**
   * Radio group data source.
   */
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.node,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  /**
   * Default checked index.
   */
  checkedId: PropTypes.oneOfType([
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ]),
  /**
   * RadioGroup value change event.
   */
  onChange: PropTypes.func,
};

RadioGroup.defaultProps = {
  className: null,
  radioClassName: null,
  checkedId: null,
  onChange: noop,
};

export default RadioGroup;
