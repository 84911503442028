import React, { memo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import ErrorsCollapse from '../../../../../components/V2/Collapse/ErrorsCollapse/ErrorsCollapse';
import URLValidator from '../../../../../components/V2/URLValidator/URLValidator';
import './collapseURLValidator.scss';

const classPrefix = 'views_pages_mmp_collapse_url_validator';

const CollapseURLValidator = ({
  className,
  value,
  deletable,
  error,
  onChange,
  onDelete,
  placeholder,
}) => (
  <div className={classnames(classPrefix, className)}>
    <div className="validator_wrapper">
      <URLValidator
        value={value}
        placeholder={placeholder || 'Add your generated URL here'}
        error={!!value && !!Object.keys(error).length}
        onChange={onChange}
      />
      {
        deletable && (
          <div className="trash_wrapper">
            <i className="material-icons" onClick={onDelete}>delete</i>
          </div>
        )
      }
    </div>
    {
      value && Object.keys(error).length > 0 && (
        <ErrorsCollapse className={classnames({ [`${classPrefix}-trash`]: deletable })} errors={error} />
      )
    }
  </div>
);

CollapseURLValidator.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  deletable: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};

CollapseURLValidator.defaultProps = {
  className: '',
  value: '',
  deletable: false,
  error: {},
  onChange: noop,
  onDelete: noop,
};

export default memo(CollapseURLValidator);
