import React from 'react';
import Link from '../../Link/Link';
import Tooltip from '../../Tooltip/TooltipContainer';
import './servingStatus.scss';

const ServingStatus = ({
  complete, link, onClick = () => {}, text,
}) => {
  if (complete) {
    return null;
  }

  const renderInner = (
    <div className="text servingMessage" onClick={onClick}>
      <p className="p6">{text}</p>
      {link && <Link className="p9" to={link}>here.</Link>}
    </div>
  );

  return (
    <div className="components__campaigns__serving_status">
      <div className={`icon icon--${complete ? 'green' : 'red'}`}>
        <i className="material-icons">{complete ? 'check_circle' : 'error_outline' }</i>
      </div>
      {renderInner}
    </div>
  );
};

const InnerContent = ({
  campaign, disableLinks, editUrl, getName,
}) => (
  <div className="components__campaigns__serving_status_wrapper">
    <div className={`status_dot status_dot--${campaign.isEligibleToServe() ? 'serving' : 'not_serving'}`} />
    <Link to={disableLinks ? '' : editUrl}>
      <p className="body__link">{getName ? getName(campaign) : campaign.get('name')}</p>
    </Link>
  </div>
);

export const generateTitle = (campaign) => {
  if (campaign.isEligibleToServe()) {
    return 'Active: Serving';
  }
  return 'Inactive: Not Serving';
};

export const generateBody = ({
  campaign,
  changeServingStatus,
  mcList,
  disableLinks,
  includeCreativeApproval,
  includeExpiration,
  includeRemainingViews,
  includeIO,
  includeFunds,
}) => {
  const handleLink = (link) => (disableLinks ? '' : link);
  const getActivateLink = () => {
    if (mcList) {
      return handleLink(`/campaigns/${campaign.get('id')}`);
    }
    return campaign.supportsSKANABTesting()
        && campaign.get('schema_version') ? null : handleLink(`/campaigns/${campaign.get('id')}/confirm`);
  };
  return (
    <div>
      <ServingStatus
        complete={campaign.get('status') === 'active'}
        icon={campaign.get('status') === 'active' ? 'pause' : 'play_arrow'}
        text="Paused: Your Campaign is set to paused, please change this"
        link={getActivateLink()}
      />
      {campaign.get('admin_status') === 'pending' && (
      <ServingStatus
        icon={campaign.get('admin_status') === 'pending' ? 'pause' : 'play_arrow'}
        onClick={changeServingStatus}
        text="Pending Review: Your campaign is still being review by our internal team"
      />
      )}
      {campaign.get('admin_status') !== 'rejected' && (
      <ServingStatus
        complete={campaign.get('admin_status') === 'approved'}
        icon="edit"
        text="Awaiting Approval"
      />
      )}
      {campaign.get('admin_status') === 'rejected' && (
      <ServingStatus
        complete={false}
        icon="edit"
        text="Your campaign has been rejected"
      />
      )}
      {includeCreativeApproval && (
      <ServingStatus
        complete={campaign.get('serving_status.creatives')}
        icon="photo_library"
        text="No Approved Creatives: No creatives attached to this campaign have been approved and/or are still review, view those creatives"
        link={handleLink('/creatives')}
      />
      )}
      {includeExpiration && mcList ? (
        <ServingStatus
          complete={campaign.get('serving_status.not_expired')}
          icon="photo_library"
          text="Past Campaign End Date: Change your campaign's end date"
          link={handleLink(`/campaigns/${campaign.get('id')}`)}
        />
      ) : (
        <ServingStatus
          complete={campaign.get('serving_status.not_expired')}
          icon="photo_library"
          text="Past Campaign End Date: Change your campaign's end date"
          link={handleLink(`/campaigns/${campaign.get('id')}/confirm`)}
        />
      )}
      {includeRemainingViews && (
      <ServingStatus
        complete={campaign.get('serving_status.remainingViewLimit')}
        icon="photo_library"
        text="This Campaign has exceeded its view limit. Update view limit "
        link={handleLink(`/campaigns/${campaign.get('id')}`)}
      />
      )}
      {includeIO && (
      <ServingStatus
        complete={campaign.get('serving_status.io_signed')}
        icon="remove_red_eye"
        link={handleLink(`/accounts/${campaign.get('account.id')}`)}
        text="TOS Not Signed: Your TOS needs to be signed"
      />
      )}
      {includeFunds && mcList ? (
        <>
          <ServingStatus
            complete={campaign.get('serving_status.has_budget')}
            icon="edit"
            link={handleLink(`/campaigns/${campaign.get('id')}`)}
            text="No More Campaign Budget: Your campaign set budget has been depleted, please change this via adding to Total Spend"
          />
          <ServingStatus
            complete={campaign.get('serving_status.has_account_budget')}
            icon="remove_red_eye"
            link={handleLink('/account')}
            text="Insufficient Funds: Your Pre-pay Account needs more funds added"
          />
        </>
      ) : (
        <>
          <ServingStatus
            complete={campaign.get('serving_status.has_budget')}
            icon="edit"
            link={handleLink(`/campaigns/${campaign.get('id')}/confirm`)}
            text="No More Campaign Budget: Your campaign set budget has been depleted, please change this via adding to Total Spend"
          />
          <ServingStatus
            complete={campaign.get('serving_status.has_account_budget')}
            icon="remove_red_eye"
            link={handleLink('/account')}
            text="Insufficient Funds: Your Pre-pay Account needs more funds added"
          />
        </>
      )}
    </div>
  );
};

export const ServingStatusComponent = ({
  campaign,
  mcList,
  children,
  changeServingStatus,
  disableLinks = false,
  includeRemainingViews = true,
  includeExpiration = true,
  includeCreativeApproval = true,
  includeFunds = true,
  includeIO = true,
  showTooltip = true,
}) => {
  if (!showTooltip || campaign.isEligibleToServe()) {
    return children;
  }
  return (
    <div className="serving-tooltip">
      <Tooltip
        newStyle
        title={generateTitle(campaign)}
        body={generateBody({
          campaign,
          changeServingStatus,
          mcList,
          disableLinks,
          includeCreativeApproval,
          includeExpiration,
          includeRemainingViews,
          includeIO,
          includeFunds,
        })}
        padding={8}
        style={{ display: 'inline-flex' }}
      >
        {children}
      </Tooltip>
    </div>
  );
};

const MCListServingStatusComponent = ({
  campaign,
  mcList,
  changeServingStatus,
  disableLinks = false,
  editUrl,
  getName,
  includeRemainingViews = true,
  includeExpiration = true,
  includeCreativeApproval = true,
  includeFunds = true,
  includeIO = true,
  showTooltip = true,
}) => (
  <ServingStatusComponent
    campaign={campaign}
    mcList={mcList}
    changeServingStatus={changeServingStatus}
    disableLinks={disableLinks}
    includeRemainingViews={includeRemainingViews}
    includeExpiration={includeExpiration}
    includeCreativeApproval={includeCreativeApproval}
    includeFunds={includeFunds}
    includeIO={includeIO}
    showTooltip={showTooltip}
  >
    <InnerContent campaign={campaign} disableLinks={disableLinks} editUrl={editUrl} getName={getName} />
  </ServingStatusComponent>
);

export default MCListServingStatusComponent;
