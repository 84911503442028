import React from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { AD_TYPES } from './constant';
import FrontendPanel from './FrontendPanel';

const AdTypePanel = ({
  visible,
  defaultCheckedNodes,
  onVisibleChange,
  onChange,
}) => (
  <FrontendPanel
    nodes={AD_TYPES}
    visible={visible}
    placeholder="Search Ad Type Targeting..."
    defaultCheckedNodes={defaultCheckedNodes}
    onVisibleChange={onVisibleChange}
    onChange={onChange}
  />
);

AdTypePanel.propTypes = {
  visible: PropTypes.bool,
  defaultCheckedNodes: PropTypes.arrayOf(PropTypes.any),
  onVisibleChange: PropTypes.func,
  onChange: PropTypes.func,
};

AdTypePanel.defaultProps = {
  visible: false,
  defaultCheckedNodes: [],
  onVisibleChange: noop,
  onChange: noop,
};

export default React.memo(AdTypePanel);
