import React, { useState } from 'react';
import classNames from 'classnames';
import DropdownMenu from '../../Dropdown/DropdownMenu/DropdownMenu';
import './tableMenu.scss';

const TableMenu = ({
  data,
  onChange,
  labelKey,
  className,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const onVisibleChange = (value) => setMenuOpen(value);
  return (
    <DropdownMenu
      className={classNames('table_context_menu', className)}
      placement="right"
      horizontalOffset={10}
      data={data}
      onChange={onChange}
      labelKey={labelKey}
      onVisibleChange={onVisibleChange}
    >
      <i className={classNames('material-icons', menuOpen && 'table_menu_opened')}>more_vert</i>
    </DropdownMenu>
  );
};

export default TableMenu;
