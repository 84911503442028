import React from 'react';
import Button from '../../Button/Button';
import EditTable from '../../EditTable/EditTableContainer';
import { hideModal } from '../../Modals/Content/triggerModal';
import './multiBidding.scss';

const minMax = (min, max) => min >= 0 && max >= 0;

const formatPublisherName = (name) => (
  <span className="pub_name">{name}</span>
);

const makeColumn = (title, width = 0) => {
  const opts = {
    title,
    filterable: false,
    resizable: false,
    sortable: false,
    flex: 1,
  };
  if (width) {
    opts.minWidth = width;
  }
  return opts;
};

const MultiBidding = ({
  campaign,
  editValues = {},
  changeEditValue,
  publisherRates = [],
  readOnly,
  min,
  max,
  bid,
  bidType,
  editColumns = [],
  //...props
}) => (
  <div className="component__multi_bidding">
    <div className="header">
      <div className="header_buttons">
        <Button dull>
          Default Bid:
          {`${bid} ${bidType}`}
        </Button>
        {minMax(min, max) && (
          <Button dull>
            Bid
            {`Min: ${min} - Max: ${max}`}
          </Button>
        )}
        {/*<Icon.Backup />*/}
      </div>
    </div>
    <EditTable
      changeEditValue={changeEditValue}
      data={publisherRates}
      editValues={editValues}
      editColumns={editColumns}
      refColumns={['pub_app_id', 'name', 'index', 'geo']}
      message={publisherRates.length > 5000 && !readOnly ? 'Inline bid editing is supported for 5k rows or fewer.' : ''}
      columns={[
        {
          ...makeColumn('Publisher Name', 300),
          render: (d) => formatPublisherName(d.name),
          maxWidth: 400,
        },
        {
          ...makeColumn('ID', 200),
          value: (d) => d.pub_app_id,
          padding: 8,
        },
        {
          ...makeColumn('Geo', 100),
          value: (d) => d.geo,
          padding: 8,
        },
      ]}
      readOnly={readOnly}
      // {...props}
    />
    <div className="buttons">
      <Button cancel onClick={hideModal}>Cancel</Button>
      {!readOnly && (
        <Button
          onClick={() => {
            hideModal();
            campaign.saveMultiBidding(editValues);
          }}
        >
          Looks Good
        </Button>
      )}
    </div>
  </div>
);

export default MultiBidding;
