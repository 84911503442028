import React from 'react';
import classNames from 'classnames';
import SingleAssetUploader from './SingleAssetUploader';
import './UploadProgressList.scss';

const UploadProgressList = ({
  mode,
  account,
  application,
  asset,
  files,
  onUploadAsset,
  onUploadStart,
  onDiscardAsset,
  onCancelUpload,
  onCommentUpdate,
  onSearchAsset,
}) => (
  <div className={classNames('upload_progress_list', { margin_top_16: mode === 'replacer' })}>
    {files.map((file) => (
      <SingleAssetUploader
        key={file.name}
        account={account}
        application={application}
        asset={asset}
        file={file}
        onUploadStart={onUploadStart}
        onUploadAsset={onUploadAsset}
        onDiscardAsset={() => onDiscardAsset(file)}
        onCancelUpload={() => onCancelUpload(file)}
        onCommentUpdate={onCommentUpdate}
        onSearchAsset={onSearchAsset}
      />
    ))}
  </div>
);

export default UploadProgressList;
