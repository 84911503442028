import React from 'react';
import triggerConfirm from 'components/Modals/ConfirmAction/triggerConfirm';
import Campaign from 'models/Campaign';
import { isAdminService, isAdvertiserService } from 'lib/serviceType';
import radio from 'services/admin/views/campaigns/edit/radio';
import InputGroupContainer from 'templates/input-group/InputGroupContainer';

const { Field } = InputGroupContainer;

const adjustBillingType = (campaign, disabled, attributionMethod) => {
  if (campaign.get('attribution_method') === attributionMethod) {
    return;
  }
  const isMMP = attributionMethod === 'mmp';
  const message = isMMP
    ? 'With MMP billing, we use MMP postbacks to bill you by CPI as for any traditional campaigns. Once you select MMP as the billing type and save the campaign, the change is permanent. Do you want to continue?  '
    : 'With SKAN billing, you will not be billed by CPI based on MMP postbacks as you would be with a traditional campaign. Once you selectSKAN as the billing type and save the campaign, the change is permanent. Do you want to continue?';
  triggerConfirm({
    type: 'CHANGE_BILLING_TYPE_CONFIRM_ACTION',
    header: 'Change Billing Type',
    message,
    cancelText: 'Cancel',
    confirmText: 'Yes',
    className: 'v2_confirmation_modal',
    onConfirm: () => {
      const schemaVersion = isMMP ? Campaign.CAMPAIGN_SCHEMA_VERSION_V0 : Campaign.CAMPAIGN_SCHEMA_VERSION_V1;

      campaign.set('attribution_method', attributionMethod);
      campaign.set('schema_version', schemaVersion);

      // Not sure why this conditional is necessary, but two different code paths did this before
      if (isAdminService()) {
        if (!disabled && campaign.isMmpAttribution()) {
          campaign.set('budget.budget_multiplier.is_enabled', false);
          if (campaign.get('is_skadnetwork_enabled')) {
            campaign.set('budget.type', 'install');
          }
        }
        if (!disabled && campaign.isSkadnetworkAttribution() && campaign.get('is_skadnetwork_enabled')) {
          campaign.set('budget.type', 'target_cpi');
        }

        if (campaign.get('budget.type') === 'TARGET_CPI' && campaign.isMmpAttribution()) {
          campaign.set('budget.type', 'install');
        }

        if (campaign.get('budget.type') === 'CPI' && campaign.isSkadnetworkAttribution()) {
          if (!disabled && !isAdvertiserService()) {
            campaign.set('budget.budget_multiplier.override', 1);
            campaign.set('budget.budget_multiplier.is_enabled', true);
          }
          campaign.set('budget.type', 'target_cpi');
        }
      } else if (isAdvertiserService()) {
        if (campaign.get('budget.type') === 'TARGET_CPI' && campaign.get('attribution_method') === 'mmp') {
          campaign.set('budget.type', 'install');
        }

        if (campaign.get('budget.type') === 'CPI' && campaign.get('attribution_method') === 'skadnetwork') {
          campaign.set('budget.type', 'target_cpi');
        }
      }

      if (!campaign.get('creative_type')) {
        campaign.set('creative_type', 'fullscreen');
      }
    },
  });
};

export default function EnableSKAdNetworkAttribution({
  campaign,
  description = `SKAdNetwork should be selected in cases where MMP attribution is no longer viable. 
Please consult your Account Manager or Support for guidance.`,
  disabled,
}) {
  return campaign?.get('is_skadnetwork_enabled')
    ? (
      <Field
        name="Billing / Reporting on iOS 14"
        description={description}
        linkUrl="https://support.vungle.com/hc/en-us/requests/new"
        linkText="Contact Us"
      >
        {radio(campaign, 'attribution_method', [
          ['mmp', 'MMP'],
          ['skadnetwork', 'SKAdNetwork'],
        ], true, disabled, (option) => adjustBillingType(campaign, disabled, option), true)}
      </Field>
    )
    : null;
}
