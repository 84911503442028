import { isArray, isString, isUndefined } from 'lodash';
import { isMongoID } from 'services/admin/views/assets/list_2/utils';
import {
  FILTER_STATUS_ACTIVE,
  FILTER_STATUS_PAUSED,
  FILTER_STATUS_PENDING,
  FILTER_STATUS_REJECTED,
  FILTER_KEY_STATUS,
  FILTER_KEY_CAMPAIGN_STATUS,
} from 'app/constants/filters';

export function getIds(idIn) {
  if (!idIn) {
    return undefined;
  }
  if (isArray(idIn)) {
    return idIn;
  }
  return [idIn];
}

export function getSearchAndIDsFilter(filter) {
  const { idIn, search } = filter;
  const ids = getIds(idIn);
  if (isArray(ids) && ids.length > 0) {
    return { ids }; // if with idIn, ignore search.
  }

  if (!isString(search) || search === '') {
    return {};
  }

  const searchIDs = search.split(',');
  if (searchIDs.every(isMongoID)) {
    return { ids: searchIDs };
  }

  return { search };
}

export function getArrayQueryVariables(v) {
  if (isUndefined(v) || v === '') {
    return undefined;
  }
  if (isString(v)) {
    return v.split(',');
  }
  if (isArray(v)) {
    return v;
  }
  return undefined;
}

const statusKeys = {
  campaign: 'campaignStatus',
  creative: 'creativeStatus',
};

export function getStatusFilter(filter, dimension) {
  const status = [];
  if (isArray(filter[FILTER_KEY_STATUS])) {
    status.push(...filter[FILTER_KEY_STATUS]);
  }
  if (isArray(filter[FILTER_KEY_CAMPAIGN_STATUS])) {
    status.push(...filter[FILTER_KEY_CAMPAIGN_STATUS]);
  }
  const statusList = [];
  const adminStatusList = [];
  status.forEach((s) => {
    switch (s) {
      case FILTER_STATUS_ACTIVE:
        statusList.push(FILTER_STATUS_ACTIVE);
        break;
      case FILTER_STATUS_PAUSED:
        if (dimension === 'creative') {
          statusList.push('inactive'); // creative supported status: active/inactive
        } else {
          statusList.push(FILTER_STATUS_PAUSED);
        }
        break;
      case FILTER_STATUS_PENDING:
        adminStatusList.push(FILTER_STATUS_PENDING);
        break;
      case FILTER_STATUS_REJECTED:
        adminStatusList.push(FILTER_STATUS_REJECTED);
        break;
      default:
        break;
    }
  });
  const result = {};
  if (statusList.length > 0) {
    result[statusKeys[dimension]] = statusList;
  }
  if (adminStatusList.length > 0) {
    result.adminStatus = adminStatusList;
  }
  return result;
}

export function getGraphQLRateType(rateType) {
  return {
    roas: 'roas',
    cppu: 'cppu',
    cpe: 'cpe',
    cpi: 'install',
    cpc: 'click',
    cpcv: 'complete',
    cpm: 'impression',
    cpv: 'view',
    tcpi: 'target_cpi',
  }[rateType];
}
