/* eslint-disable react/display-name */
import { getGrowCreativeMetricCache } from 'lib/cache/MetricCache';
import { getGrowCreativeSortCache } from 'lib/cache/SortCache';
import { getGrowCreativeFiltersCache } from 'lib/cache/FiltersCache';
import { getGrowCreativePageSizeCache } from 'lib/cache/PageSizeCache';
import {
  defaultSort,
  defaultFilterCache,
} from 'services/Templates/Creatives/List/constants';

export const getListCaches = (userId) => ({
  metricCache: getGrowCreativeMetricCache(userId),
  pageSizeCache: getGrowCreativePageSizeCache(userId),
  sortCache: getGrowCreativeSortCache(userId, [defaultSort]),
  filterCache: getGrowCreativeFiltersCache(userId, defaultFilterCache),
});

export default {};
