import React from 'react';
import { FILTER_KEY_DELETION_STATUS } from 'app/constants/filters';
import CommonMultiPicker from '../CommonMultiPicker/CommonMultiPicker';
import { deletionStatus } from '../constant';

const DEFAULT_LABEL = 'Hide';
const defaultSelectedOptions = [
  { id: 'hide', text: 'Hide' },
];

const MultiDeletionStatus = (props) => {
  const { defaultCheckedNodes } = props;
  const nodeInfo = defaultCheckedNodes?.length ? defaultCheckedNodes[0] : defaultSelectedOptions[0];
  const filterLabel = deletionStatus.find((s) => s.id === nodeInfo.id)?.text;

  return (
    <CommonMultiPicker
      isSelectAllHidden
      isLinkHidden
      data={deletionStatus}
      filterKey={FILTER_KEY_DELETION_STATUS}
      filterLabel={filterLabel}
      filterValueCls={filterLabel === DEFAULT_LABEL ? 'default' : ''}
      single
      {...props}
      defaultCheckedNodes={defaultCheckedNodes.length > 0 ? defaultCheckedNodes : defaultSelectedOptions}
    />
  );
};

export default MultiDeletionStatus;
