import React from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import MultiTree from '../MultiTree/MultiTree';

const MultiTreeFrontend = ({
  dropdownFixed,
  nodes,
  visible,
  filterKey,
  renderOverlay,
  defaultCheckedNodes,
  onVisibleChange,
  onRemove,
  filterLabel,
  disableDropdown,
}) => (
  <MultiTree
    dropdownFixed={dropdownFixed}
    visible={visible}
    isBackendTree={false}
    filterKey={filterKey}
    checkableNodes={nodes}
    defaultCheckedNodes={defaultCheckedNodes}
    renderOverlay={renderOverlay}
    onVisibleChange={onVisibleChange}
    onRemove={onRemove}
    filterLabel={filterLabel}
    disableDropdown={disableDropdown}
  />
);

MultiTreeFrontend.propTypes = {
  dropdownFixed: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
  filterKey: PropTypes.string.isRequired,
  renderOverlay: PropTypes.func.isRequired,
  nodes: PropTypes.arrayOf(PropTypes.any).isRequired,
  defaultCheckedNodes: PropTypes.arrayOf(PropTypes.any),
  onVisibleChange: PropTypes.func,
  onRemove: PropTypes.func,
};

MultiTreeFrontend.defaultProps = {
  defaultCheckedNodes: [],
  dropdownFixed: false,
  onVisibleChange: noop,
  onRemove: noop,
};

export default React.memo(MultiTreeFrontend);
