import countryData from './countries-and-languages';
import appStoreOptions from './app-store-options';
import {
  bidsBudgets,
  bidsBudgetsAlertThresholds,
  bidsBudgetsAlertContent,
  geoBudget,
  multibidding,
  publisherBuyouts,
} from './bids-budgets';
import { creativeTags, creativeBlurbs } from './creative-settings';
import applicationTags from './application-tags';

const skadnetworkMinOsVersion = 11;
const skadnetworkMinSdkVersion = '6.8';
const skAdNetworkPostbackDestinationOptions = [
  {
    name: 'Adjust', url: 'https://app.adjust.com/skadnetwork/19kyaoxduxla8?campaign={{{campaign_name}}}&campaign_id={{{campaign_id}}}&creative={{{creative}}}&creative_id={{{creative_id}}}',
  },
  {
    name: 'AppsFlyer', url: 'https://skadredirect.appsflyer.com/api/redirects?ad-network-campaign-id={{{campaign_id}}}&ad-network-campaign-name={{{campaign_name}}}&ad-network-ad-id={{{creative_id}}}&ad-network-ad-name={{{creative}}}',
  },
  {
    name: 'Branch', url: 'https://api2.branch.io/v1/skadnetwork?partner_campaign_name={{{campaign_name}}}&partner_campaign_id={{{campaign_id}}}&partner_creative_name={{{creative}}}&partner_creative_id={{{creative_id}}}',
  },
  {
    name: 'Kochava', url: 'https://vungle.adsmeasurement.com/v1/skad/postback?partner_campaign_name={{{campaign_name}}}&partner_campaign_id={{{campaign_id}}}&partner_creative_name={{{creative}}}&partner_creative_id={{{creative_id}}}',
  },
  {
    name: 'Singular', url: 'https://skadnetwork.singular.net/api/v1/skan_postback?adn_campaign_id={{{campaign_id}}}&adn_campaign_name={{{campaign_name}}}&adn_creative_id={{{creative_id}}}&adn_creative_name={{{creative}}}',
  },
  {
    name: 'Tenjin', url: 'https://track.tenjin.com/v0/sk-ad-network/conversions',
  },
  {
    name: 'Bytebrew', url: 'https://api.bytebrew.io/api/skadnetwork/postback',
  },
];

const defaultEvent = { name: 'play_start', description: 'Creative Start (0%)' };
const supportedEvents = [
  defaultEvent,
  { name: 'play_25%', description: '1st Quartile (25%)' },
  { name: 'play_50%', description: 'Midpoint (50%)' },
  { name: 'play_75%', description: '3rd Quartile (75%)' },
  { name: 'play_finish', description: 'Creative Complete (100%)' },
  { name: 'postroll_view', description: 'Endcard View' },
];
const allEvents = [
  ...supportedEvents,
  { name: 'mute', description: 'Mute' },
  { name: 'unmute', description: 'Unmute' },
  { name: 'video_close', description: 'Video close' },
  { name: 'postroll_click', description: 'Endcard Click', unselectable: true },
];

const impressionEvent = { name: 'play_start', description: 'Creative Start (0%)' };
const clickEvent = { name: 'postroll_click', description: 'Endcard Click' };
const additionalEvents = [
  { name: 'play_25%', description: '1st Quartile' },
  { name: 'play_50%', description: 'Midpoint' },
  { name: 'play_75%', description: '3rd Quartile' },
  { name: 'play_finish', description: 'Creative Complete' },
  { name: 'postroll_view', description: 'Endcard View' },
];

const rateTypes = [
  {
    id: 'install', name: 'CPI', definition: 'Cost Per Install', min: 0.01, max: 30,
  },
  {
    id: 'click', name: 'CPC', definition: 'Cost Per Click', min: 0.10, max: 5,
  },
  {
    id: 'complete', name: 'CPCV', definition: 'Cost Per Completed View', min: 0.001, max: 0.05,
  },
  {
    id: 'impression', name: 'CPM', definition: 'Cost Per Thousand Impressions', min: 0.50, max: 25,
  },
  {
    id: 'view', name: 'CPV', definition: 'Cost Per View', min: 0.001, max: 0.05,
  },
  {
    id: 'target_cpi', name: 'TARGET_CPI', definition: 'Target CPI', min: 0.01, max: 30,
  },
  {
    id: 'cppu', name: 'Cost Per Paying User', definition: 'CPPU', min: 0.01, max: 1000,
  },
  {
    id: 'cpe', name: 'Cost Per Event', definition: 'CPE', min: 0.01, max: 1000,
  },
];

const adFormats = [
  { id: 'vungle_local', name: 'Vungle Local' },
  { id: 'vungle_mraid', name: 'Vungle MRAID' },
  { id: 'vungle_short_form', name: 'Vungle Short Form' },
  { id: 'third_party', name: 'Third Party Ads' },
  { id: 'third_party_mraid', name: 'Third Party MRAID' },
];

const supportedImppressionTypes = [
  { name: 'video', label: 'Video' },
  { name: 'banner', label: 'Banner' },
];

const replacementNames = {
  CTA_BUTTON_TEXT: 'CTA Text',
  CTA_BUTTON_BACKGROUND: 'CTA Background Color',
  CTA_BUTTON_URL: 'CTA URL',
  APP_NAME: 'App Name',
  APP_DESCRIPTION: 'Company',
  APP_ICON: 'App Icon',
  APP_RATING: 'AppRating',
  POWERED_BY_VUNGLE: 'Vungle Logo',
  DOWNLOAD_BUTTON_DELAY_SECONDS: 'Video CTA Delay',
  ENDCARD_SHOW_CTA: 'Encard CTA',
  VIDEO_SHOW_CTA: 'Video CTA',
  LANDSCAPE_BACKGROUND: 'Landscape Image',
  PORTRAIT_BACKGROUND: 'Portrait Image',
  CAROUSEL_IMAGE_1: 'Carousel Image 1',
  CAROUSEL_IMAGE_2: 'Carousel Image 2',
  CAROUSEL_IMAGE_3: 'Carousel Image 3',
  CAROUSEL_IMAGE_4: 'Carousel Image 4',
  CAROUSEL_IMAGE_5: 'Carousel Image 5',
  THEME: 'Template Theme',
  MAIN_VIDEO: 'Video',
};

const msrpOptions = {
  durations: [
    { text: 'Any', value: null },
    { text: '12 months', value: 12 },
    { text: '24 months', value: 24 },
    { text: '36 months', value: 36 },
  ],
};

const accountMemberLimitation = 100;

export default {
  adFormats,
  countryData,
  applicationTags,
  creativeTags,
  creativeBlurbs,
  replacementNames,
  appStoreOptions,
  bidsBudgets,
  bidsBudgetsAlertThresholds,
  bidsBudgetsAlertContent,
  msrpOptions,
  geoBudget,
  multibidding,
  publisherBuyouts,
  supportedImppressionTypes,
  disableErrorStackTrace: false,
  logger: {
    enabled: false,
  },
  alerts: {
    1: 'Add additional funds to account',
    2: 'Add additional funds to campaign',
    3: 'Campaign may not be tracking installs',
    4: 'Win rate',
    5: 'Spend',
    6: 'Upload new creatives',
  },
  stripe: {
    key: 'pk_test_yDiH7FOrKDis7zcrql6OqxNC',
  },
  sentry: {
    dsn: {
      admin: 'https://c7e08281d2324b8680c3998171f84140@o315280.ingest.sentry.io/5552699',
      advertiser: 'https://ae4ea83a13b145fe98d754d6f22cbbc8@o315280.ingest.sentry.io/5549782',
    },
    debug: false, //enable for debugging
    autoSessionTracking: true,
    sampleRate: 1,
    tracesSampleRate: 0.3,
  },
  applications: {
    surgeOverrideThresholds: {
      min: 0,
      max: 100,
    },
    skAdNetworkPostbackDestinationOptions,
  },
  campaigns: {
    defaultEvent,
    supportedEvents,
    allEvents,
    impressionEvent,
    clickEvent,
    additionalEvents,
    rateTypes,
    skadnetworkMinOsVersion,
    skadnetworkMinSdkVersion,
    allRateTypes: [
      ...rateTypes,
      { id: 'view', name: 'CPV', definition: 'Cost Per View' },
    ],
    defaultRateType: 'install',
    cvrMultiplierCap: 2,
  },
  accounts: {
    accountMemberLimitation,
    defaultCreativeMaximum: 8,
  },
  androidDeviceCsv: {
    url: 'https://vungle2-cdn-qa.s3.amazonaws.com/devices/devices.csv',
  },
  version: process.env.npm_package_version,
  deviceListCsvUrl: {
    android: 'https://vungle2-cdn-qa.s3.amazonaws.com/devices/devices_android.csv',
    windows: 'https://vungle2-cdn-qa.s3.amazonaws.com/devices/devices_windows.csv',
    amazon: 'https://vungle2-cdn-qa.s3.amazonaws.com/devices/devices_amazon.csv',
  },
  globalSettings: {
    budgetMultiplierCap: 4.5,
  },
};
