import React, {
  useState, useCallback, forwardRef, useImperativeHandle, useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { history } from '../../../../../../../../app/app';
import Campaign from '../../../../../../../../models/Campaign';
import { ISO_8601_FORMAT } from '../../../../../../../../lib/date';
import useDebounce from '../../../../../../../../lib/hooks/useDebounce';
import { getReportingPageSizeCache } from '../../../../../../../../lib/cache/PageSizeCache';
import { getDetailQueryNodeIds } from '../../../../../../../Templates/Reports/helper';
import { initPagination } from '../../../../../../../../components/V2/Filters/constant';
import ABTestReportingDrawerContainer from '../../../../../../../Templates/AbTesting/ABTestReportingDrawer/ABTestReportingDrawerContainer';
import { getReportingApplicationCampaignDetail } from '../../../../../../../../app/graphql/utils/reporting';
import { APPLICATION_CAMPAIGN_LIST_METRIC_KEY } from '../../../../../../../../lib/cache/constant';
import Loading from '../../../../../../../../components/V2/Report/Loading/Loading';
import { METRIC_SPENDS } from '../../../../../../../../components/V2/Metrics/constants';
import MetricCache from '../../../../../../../../lib/cache/MetricCache';
import { Table } from '../../../../../../../../components/V2';
import { renderMetricColumns } from '../helper';
import withAction from '../renderAction';
import {
  renderColumns, attributesMetrics, defaultMetrics, returnMetricsList, generateContextMenu,
} from './helper';
import '../dataTable.scss';
import './campaignDataTable.scss';
import { metricsGroup } from '../../../../../../../../components/V2/Report/data/Metrics';

export const dimension = 'campaign';

export function initMetrics(userId) {
  const metricsCache = new MetricCache(userId, APPLICATION_CAMPAIGN_LIST_METRIC_KEY);
  const metrics = metricsCache.get();
  if (metrics) {
    return returnMetricsList(metrics);
  }
  return defaultMetrics.filter((i) => i.tableDefault);
}

const CampaignDataTable = ({
  node,
  checkedDate,
  checkedCombine,
  onFilterCampaign,
  changeCampaignStatus,
}, ref) => {
  const authUser = useSelector((state) => state.authUser);
  const pageSizeCache = getReportingPageSizeCache(authUser.account);

  const [metrics, setMetrics] = useState(() => initMetrics(authUser.id));
  const [sort, setSort] = useState({ sortKey: METRIC_SPENDS, isSortAsc: false });
  const [tableData, setTableData] = useState([]);
  const [tableDataTotal, setTableDataTotal] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState({
    ...initPagination,
    perPage: pageSizeCache.get(),
  });
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [checkedCampaign, setCheckedCampaign] = useState(null);

  const tableAllData = tableDataTotal ? [tableDataTotal, ...tableData] : tableData;

  const onLoadTableData = useCallback(async () => {
    setTableLoading(true);
    try {
      const {
        data, pagination, total,
      } = await getReportingApplicationCampaignDetail({
        ...sort,
        dimension,
        page: tablePagination.page,
        perPage: tablePagination.perPage,
        start: checkedDate.startDate.format(ISO_8601_FORMAT),
        end: checkedDate.endDate.format(ISO_8601_FORMAT),
        ...getDetailQueryNodeIds(checkedCombine),
        applications: [node.get('id')],
      });
      setTableData(data);
      setTableDataTotal({ total: 'Total', ...total });
      setTablePagination(pagination);
    } finally {
      setTableLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node.get('id'), checkedDate, checkedCombine, tablePagination.page, tablePagination.perPage, sort]);

  useDebounce(() => {
    onLoadTableData();
  }, 50, [onLoadTableData]);

  useImperativeHandle(ref, () => ({
    onLoadTableData: () => onLoadTableData(),
  }));

  const handlePageChange = useCallback((page) => {
    if (tableLoading) {
      return;
    }
    setTablePagination((prevState) => ({
      ...prevState,
      page,
    }));
  }, [tableLoading]);

  const handlePageSizeChange = useCallback((page, perPage) => {
    if (tableLoading) {
      return;
    }
    pageSizeCache.save(perPage);
    setTablePagination((prevState) => ({
      ...prevState,
      perPage,
      page,
    }));
  }, [tableLoading, pageSizeCache]);

  const onContextMenuChange = useCallback((item, original) => {
    const row = original[dimension];
    if (item.action) {
      item.action({
        campaign: new Campaign(row),
        onFilterCampaign,
      });
    }
    if (item.router) {
      history.push(item.router(row.id, row));
    }
  }, [onFilterCampaign]);

  const onMetricsChange = useCallback((e) => setMetrics(returnMetricsList(e)), []);

  const onSorted = useCallback((col) => {
    const { id, desc } = col[0];
    setSort({
      sortKey: id,
      isSortAsc: !desc,
    });
  }, []);

  const onOpenABTestReporting = useCallback((campaign) => {
    setDrawerOpen(true);
    setCheckedCampaign(campaign);
  }, []);

  useEffect(() => {
    setTablePagination(initPagination);
  }, [checkedDate, checkedCombine]);

  return (
    <div className="application_data_table campaign">
      {React.createElement(withAction(Table), {
        authUser,
        dimension,
        manual: true,
        height: '100%',
        data: tableAllData,
        resizable: true,
        sortable: true,
        attributesMetrics,
        hideOnNoData: true,
        showPagination: true,
        loading: tableLoading,
        page: tablePagination.page,
        total: tablePagination.total,
        pageSize: tablePagination.perPage,
        tableSorted: [{ id: sort.sortKey, desc: !sort.isSortAsc }],
        columns: [...renderColumns({ changeCampaignStatus, onOpenABTestReporting }), ...renderMetricColumns(metrics, dimension)],
        metricGroup: [attributesMetrics, metricsGroup[0]],
        metricType: APPLICATION_CAMPAIGN_LIST_METRIC_KEY,
        onPageChange: handlePageChange,
        onPageSizeChange: handlePageSizeChange,
        onSorted,
        onMetricsChange,
        generateContextMenu,
        changeCampaignStatus,
        onContextMenuChange,
      })}
      {tableLoading && <Loading content="Just a second, loading data..." />}
      {drawerOpen && (
        <ABTestReportingDrawerContainer
          campaign={checkedCampaign}
          start={checkedDate.startDate.format(ISO_8601_FORMAT)}
          end={checkedDate.endDate.format(ISO_8601_FORMAT)}
          onOpenChange={setDrawerOpen}
        />
      )}
    </div>
  );
};

export default forwardRef(CampaignDataTable);
