import React from 'react';
import { isFunction } from 'lodash';
import {
  compose,
  namespace,
  splash,
} from 'app/app';
import { track } from 'lib/tracker';
import Account from 'models/Account';
import Creative from 'models/Creative';
import Create from '../../../../creatives/Edit/Create/Create';

class CreativeContainer extends React.Component {
  constructor(p) {
    super();
    this.application = p.campaign.get('application');
    this.templates = null;
  }

  UNSAFE_componentWillMount() {
    this.props.actions.setupCreativeView(this.application);
  }

  componentWillUnmount() {
    this.props.actions.destroyView();
  }

  UNSAFE_componentWillReceiveProps(p) {
    p.creative.onChange(() => p.actions.creativeChangeAction(this.props.creative));
  }

  render() {
    if (!this.props.creative) {
      return null;
    }

    return (
      <Create
        createType="campaign"
        {...this.props}
      />
    );
  }
}

const campaignNamespace = 'views.campaigns.edit';
const updateCampaignState = namespace(campaignNamespace);

const ns = 'views.campaigns.edit.creatives';
const updateState = namespace(ns);
const props = (state) => ({
  ...state[campaignNamespace],
  ...state[ns],
});

const setupCreativeView = ({ state, values }) => {
  const creative = new Creative({ application: values });
  const { account } = state.setup.appConfig;
  creative.set('account', new Account(account));
  return updateState(state, { creative, creativeCreated: false });
};

const creativeChangeAction = ({ state, values }) => updateState(state, { creative: values });

const destroyView = ({ state }) => updateState(state, { creativeCreated: false, creative: null });
const setCreativeCreated = ({ state }) => updateState(state, { creativeCreated: true });
const createCreative = (() => {
  const action = ({ campaign, creative }) => Creative.create(creative.toServerObject(campaign));
  const start = () => ({
    ...splash({ start: true, text: 'saving creative' }),
  });
  const end = ({ state, result, props: { callback } }) => {
    if (!result.ok) {
      return {
        ...splash({ finish: true, text: 'Something wrong happens!' }),
      };
    }
    if (callback && isFunction(callback)) {
      callback(result.response.id);
    }

    const application = state[campaignNamespace].campaign.get('application');
    const { creative } = setupCreativeView({ state, values: application })[ns];

    return {
      ...track('Creative Created', { id: result.response.id }),
      ...updateState(state, { creativeCreated: false, refreshCreatives: true, creative }),
      ...updateCampaignState(state, { newlyAddedCreative: result.response }),
      ...splash({ finish: true, text: 'success!' }),
    };
  };
  return { action, start, end };
})();

const actions = {
  createCreative,
  creativeChangeAction,
  destroyView,
  setupCreativeView,
  setCreativeCreated,
};

export default compose(CreativeContainer, { props, actions });
