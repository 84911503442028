import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ApplicationNode from '../Nodes/Application';
import CreativeOrCampaign from '../Nodes/CreativeOrCampaign';
import { ns as accountHierNs } from '../../../../../services/Templates/AccountHier/actions';
import { initPagination, onePagePagination } from '../../constant';
import {
  getLoadNodes,
  getLoadChildren,
  getLoadMenu,
  getCheckedNodes,
  filterTreeNodes,
} from './helper';
import BackendPanel from './BackendPanel';

function renderNode({ filterKey, node }) {
  return <ApplicationNode filterKey={filterKey} node={node} countable />;
}

function renderLeaf(node) {
  return <CreativeOrCampaign node={node} />;
}

const CampaignOrCreativePanel = ({
  visible,
  filterKey,
  maxSelected,
  availableNodes,
  defaultCheckedNodes,
  searchParams,
  onVisibleChange,
  onChange,
}) => {
  const [nodes, setNodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState(initPagination);

  const [menuNodes, setMenuNodes] = useState([]);
  const [menuLoading, setMenuLoading] = useState(false);
  const [menuPagination, setMenuPagination] = useState(initPagination);

  const [checkableNodes, setCheckableNodes] = useState([]);
  const [checkedNodes, setCheckedNodes] = useState(defaultCheckedNodes);

  const authUser = useSelector((state) => state.authUser);
  const accountHier = useSelector((state) => state[accountHierNs]) || {};

  const account = useMemo(() => accountHier.account || authUser.account,
    [accountHier, authUser]);

  const onLoadNodes = useCallback(async (params) => {
    setLoading(true);
    const result = await getLoadNodes({
      filterKey,
      prevNodes: nodes,
      params: {
        ...searchParams,
        ...params,
        account,
      },
    });
    setNodes(result.response);
    setPagination(result.pagination);
    setLoading(false);
  }, [nodes, filterKey, account, searchParams]);

  useEffect(() => {
    // Only load available nodes if availableNodes contains something.
    if (availableNodes.length) {
      setNodes(availableNodes);
      setPagination(onePagePagination);
    } else if (visible) {
      onLoadNodes({ page: 1 });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, availableNodes]);

  useEffect(() => {
    setCheckableNodes(nodes.reduce((acc, app) => acc.concat(app.children), []));
  }, [nodes]);

  const onLoadChildren = useCallback(async (node) => {
    const index = nodes.findIndex(({ id }) => id === node.id);
    const prevNodes = [
      ...nodes.slice(0, index),
      {
        ...node,
        loading: true,
      },
      ...nodes.slice(index + 1),
    ];
    setNodes(prevNodes);
    const result = await getLoadChildren({
      filterKey,
      nodes,
      node,
      params: {
        ...searchParams,
        account,
      },
    });
    setNodes(result);
  }, [nodes, filterKey, account, searchParams]);

  const onLoadMenu = useCallback(async (params) => {
    if (availableNodes.length) {
      setMenuNodes(filterTreeNodes(availableNodes, params.search));
      setMenuPagination(onePagePagination);
    } else {
      setMenuLoading(true);
      const result = await getLoadMenu({
        prevNodes: params.page === 1 ? [] : menuNodes,
        filterKey,
        params: {
          ...searchParams,
          ...params,
          account,
        },
      });
      setMenuNodes(result.response);
      setMenuPagination(result.pagination);
      setMenuLoading(false);
    }
  }, [menuNodes, account, filterKey, availableNodes, searchParams]);

  const onCheckedChange = useCallback((_checkedNodes) => {
    setCheckedNodes(_checkedNodes);
    onChange(getCheckedNodes(_checkedNodes));
  }, [onChange]);

  return (
    <BackendPanel
      tree
      visible={visible}
      nodes={nodes}
      loading={loading}
      pagination={pagination}
      menuNodes={menuNodes}
      menuLoading={menuLoading}
      menuPagination={menuPagination}
      checkableNodes={checkableNodes}
      renderLeaf={renderLeaf}
      renderNode={(node) => renderNode({ filterKey, node })}
      maxSelected={maxSelected}
      disabledTitle="Limit Reached"
      disabledContent={`You have selected (${maxSelected}) ${filterKey}s.`}
      placeholder={`Search ${filterKey} name or id...`}
      defaultCheckedNodes={checkedNodes}
      onVisibleChange={onVisibleChange}
      onLoadChildren={onLoadChildren}
      onChange={onCheckedChange}
      onLoadNodes={onLoadNodes}
      onLoadMenu={onLoadMenu}
    />
  );
};

CampaignOrCreativePanel.propTypes = {
  visible: PropTypes.bool,
  maxSelected: PropTypes.number,
  filterKey: PropTypes.string.isRequired,
  availableNodes: PropTypes.arrayOf(PropTypes.any),
  defaultCheckedNodes: PropTypes.arrayOf(PropTypes.any),
  onVisibleChange: PropTypes.func,
  onChange: PropTypes.func,
};

CampaignOrCreativePanel.defaultProps = {
  visible: false,
  maxSelected: 0,
  availableNodes: [],
  defaultCheckedNodes: [],
  onVisibleChange: noop,
  onChange: noop,
};

export default React.memo(CampaignOrCreativePanel);
