import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import { noop } from 'lodash';
import useDebounce from '../../../../lib/hooks/useDebounce';
import Input from '../../Input/Input';
import './pickerInput.scss';

export const prefixCls = 'v2_component_report_calendar_picker_input';

export const formatString = 'DD MMM YYYY';

export const formatSingleDigitDay = 'D MMM YYYY';

const now = moment();

const sixMonthAgo = now.clone().subtract(6, 'months');

const isDateValid = (date, format) => moment(date, format, true).isValid();

function validate(startDate, endDate) {
  if (!isDateValid(startDate, formatString) && !isDateValid(startDate, formatSingleDigitDay)) {
    return { message: 'Invalid range', valid: false };
  }
  if (!isDateValid(endDate, formatString) && !isDateValid(endDate, formatSingleDigitDay)) {
    return { message: 'Invalid range', valid: false };
  }
  if (moment(startDate).isAfter(moment(endDate))) {
    return { message: 'start > end', valid: false };
  }
  if (!sixMonthAgo.isBefore(startDate)) {
    return { message: 'Invalid range', valid: false };
  }
  if (!now.isAfter(endDate) || moment(endDate).isSame(now, 'day')) {
    return { message: 'Invalid range', valid: false };
  }
  return { message: '', valid: true };
}

function PickerInput({
  startDate,
  endDate,
  onChange,
}) {
  const [start, setStart] = React.useState('');
  const [end, setEnd] = React.useState('');
  const [startError, setStartError] = React.useState('');
  const [endError, setEndError] = React.useState('');

  React.useEffect(() => {
    if (moment.isMoment(startDate) && moment.isMoment(endDate)) {
      setStart((date) => (startDate.isSame(date) ? date : startDate.format(formatString)));
      setEnd((date) => (endDate.isSame(date) ? date : endDate.format(formatString)));
      setStartError('');
      setEndError('');
    }
  }, [startDate, endDate]);

  const onStartChange = React.useCallback((e) => {
    const { value = '' } = e.target;
    setStart(value);
    const { valid, message } = validate(value, end);
    if (valid) {
      setStartError('');
      onChange({
        startDate: moment(value),
        endDate: moment(end),
      });
    } else {
      setStartError(message);
    }
  }, [end, onChange]);

  const onEndChange = React.useCallback((e) => {
    const { value = '' } = e.target;
    setEnd(value);
    const { valid, message } = validate(start, value);
    if (valid) {
      setEndError('');
      onChange({
        startDate: moment(start),
        endDate: moment(value),
      });
    } else {
      setEndError(message);
    }
  }, [start, onChange]);

  useDebounce(() => {
    if (!isDateValid(start, formatString) && isDateValid(start, formatSingleDigitDay)) {
      setStart(`0${start}`);
    }
  }, 500, [start]);

  useDebounce(() => {
    if (!isDateValid(end, formatString) && isDateValid(end, formatSingleDigitDay)) {
      setEnd(`0${end}`);
    }
  }, 500, [end]);

  return (
    <div className={prefixCls}>
      <span className={`${prefixCls}-rect`} />
      <div className={`${prefixCls}-wrapper`}>
        <Input
          className={classnames(`${prefixCls}-input`, {
            [`${prefixCls}-input-error`]: startError,
          })}
          placeholder="start"
          value={start}
          onChange={onStartChange}
        />
        <span className={`${prefixCls}-error`}>{startError}</span>
      </div>
      <span className={`${prefixCls}-separate`}>-</span>
      <div className={`${prefixCls}-wrapper`}>
        <Input
          className={classnames(`${prefixCls}-input`, {
            [`${prefixCls}-input-error`]: endError,
          })}
          placeholder="end"
          value={end}
          onChange={onEndChange}
        />
        <span className={`${prefixCls}-error`}>{endError}</span>
      </div>
    </div>
  );
}

PickerInput.propTypes = {
  startDate: PropTypes.objectOf(PropTypes.any),
  endDate: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
};

PickerInput.defaultProps = {
  startDate: null,
  endDate: null,
  onChange: noop,
};

export default React.memo(PickerInput);
