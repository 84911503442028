import React from 'react';
import AttachedCreativesTestingWrapper from 'services/Templates/Campaigns/AttachedCreatives/AttachedCreativesTestingWrapper';
import {
  FORMAT_TYPE_BANNER,
  FORMAT_TYPE_FULLSCREEN,
  FORMAT_TYPE_MREC,
  FORMAT_TYPE_NATIVE,
} from 'app/constants/campaign';
import './attatchedCreatives.scss';

const prefix = 'templates_campaigns_AttachedABCreatives';

const AttachedCreativesWithTestingBase = ({
  campaign,
  creatives = [],
  updateSkcid,
  selectedCreativeIds,
  setSelectedCreativeIds,
  selectedCreativeBIds,
  setSelectedCreativeBIds,
  allCreatives,
}) => (
  <div className={prefix}>
    <AttachedCreativesTestingWrapper
      isAbTesting={campaign.get('is_ab_testing')}
      campaign={campaign}
      creatives={creatives}
      updateSkcid={updateSkcid}
      selectedCreativeIds={selectedCreativeIds}
      setSelectedCreativeIds={setSelectedCreativeIds}
      selectedCreativeBIds={selectedCreativeBIds}
      setSelectedCreativeBIds={setSelectedCreativeBIds}
      allCreatives={allCreatives}
    />
  </div>
);

const AttachedCreativesWithTestingMREC = (props) => <AttachedCreativesWithTestingBase {...props} />;
const AttachedCreativesWithTestingBanner = (props) => <AttachedCreativesWithTestingBase {...props} />;
const AttachedCreativesWithTestingFullscreen = (props) => <AttachedCreativesWithTestingBase {...props} />;
const AttachedCreativesWithTestingNative = (props) => <AttachedCreativesWithTestingBase {...props} />;

const getAttachedCreativesWithTesting = (campaign) => {
  const attachedCreativesComponents = {
    [FORMAT_TYPE_MREC]: AttachedCreativesWithTestingMREC,
    [FORMAT_TYPE_BANNER]: AttachedCreativesWithTestingBanner,
    [FORMAT_TYPE_FULLSCREEN]: AttachedCreativesWithTestingFullscreen,
    [FORMAT_TYPE_NATIVE]: AttachedCreativesWithTestingNative,
  };

  const creativeType = campaign.get('creative_type');
  return attachedCreativesComponents[creativeType];
};

const AttachedCreativesWithTesting = (props) => {
  const { campaign } = props;
  const AttachedCreativesForABTesting = getAttachedCreativesWithTesting(campaign);
  return <AttachedCreativesForABTesting {...props} />;
};

export default AttachedCreativesWithTesting;
