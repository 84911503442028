import React, {
  useRef, useEffect, useState, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';
import Tooltip from '../V2/Tooltip/Tooltip';
import CreativeTooltip from '../../services/Templates/CreativeBuilder/CreativeTooltip/CreativeTooltip';
import QRCode from '../Creatives/QRCode/QRCode';
import { TABLE_ACTION_EDIT_CREATIVE, TABLE_ACTION_PREVIEW_IN_CREATIVE_TESTING_TOOL } from '../../services/Templates/Creatives/List/constants';
import { canModifyCreatives } from '../../lib/helpers/authUser';
import { getEditUrl } from '../../lib/helpers/creatives/creativeIndex';
import TableMenu from '../V2/Table/component/TableMenu';
import Creative from '../../models/Creative';

const ControlButtonsTooltip = (props) => <Tooltip className="DeviceFrame__ControlButtonsWrapper__Tooltip" {...props} />;

const ControlButtons = ({
  shouldDisplayDeviceToggle,
  shouldDisplayTooltip,
  isMissionControl,
  isShowEditCreativeAction,
  creativeId,
  history,
  resize,
  rotate,
  refresh,
  close,
}) => {
  const showQRCodeRef = useRef(null);
  const [modalRoot, setModalRoot] = useState(null);
  const [isQRCodeVisible, setIsQRCodeVisible] = useState(false);
  const moreActions = useMemo(() => {
    const actions = [];
    if (isShowEditCreativeAction && creativeId && canModifyCreatives()) actions.push(TABLE_ACTION_EDIT_CREATIVE);
    if (isMissionControl && creativeId) actions.push(TABLE_ACTION_PREVIEW_IN_CREATIVE_TESTING_TOOL);
    return actions;
  }, [isMissionControl, creativeId]);

  useEffect(() => {
    setModalRoot(showQRCodeRef.current);
  }, []);

  const showQRCode = useCallback(() => setIsQRCodeVisible(true), []);

  const hideQRCode = useCallback(() => setIsQRCodeVisible(false), []);

  const onMenuActionClick = useCallback((actionKey) => {
    switch (actionKey) {
      case TABLE_ACTION_EDIT_CREATIVE: {
        if (close) close();
        if (isMissionControl) {
          history.push(`/creatives/${creativeId}`);
        } else {
          history.push(getEditUrl(new Creative({ id: creativeId })));
        }
        break;
      }
      case TABLE_ACTION_PREVIEW_IN_CREATIVE_TESTING_TOOL: {
        showQRCode();
        break;
      }
      default: break;
    }
  }, [isMissionControl, history, creativeId, close]);

  return (
    <div className="DeviceFrame__ControlButtonsWrapper">
      <ControlButtonsTooltip body="Restart Creative">
        <Icon.SkipPrevious onClick={refresh} />
      </ControlButtonsTooltip>
      {
        shouldDisplayDeviceToggle && (
        <ControlButtonsTooltip body="Resize Phone/Tablet">
          <Icon.AspectRatio onClick={resize} />
        </ControlButtonsTooltip>
        )
      }
      <ControlButtonsTooltip body="Rotate Orientation">
        <Icon.RotateRight onClick={rotate} />
      </ControlButtonsTooltip>
      {
        shouldDisplayTooltip && (
          <CreativeTooltip
            title="Creative Previewer"
            body="Heads up, this previewer shows an estimate of what your&nbsp;
                  creative will look like. This might not be up to actual scale on device."
          />
        )
      }
      {moreActions.length > 0 && (
      <div ref={showQRCodeRef}>
        <TableMenu
          data={moreActions}
          onChange={onMenuActionClick}
          labelKey="label"
        />
        {isQRCodeVisible && creativeId && modalRoot
        && (
        <QRCode
          creativeId={creativeId}
          isVisible={isQRCodeVisible}
          hide={hideQRCode}
          modalRoot={modalRoot}
          adjustLeftPosition={60}
          adjustTopPosition={-170}
        />
        )}
      </div>
      )}
    </div>
  );
};

ControlButtons.propTypes = {
  shouldDisplayDeviceToggle: PropTypes.bool,
  shouldDisplayTooltip: PropTypes.bool,
  isMissionControl: PropTypes.bool,
  creativeId: PropTypes.string.isRequired,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  resize: PropTypes.func,
  rotate: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  isShowEditCreativeAction: PropTypes.bool,
};

ControlButtons.defaultProps = {
  shouldDisplayDeviceToggle: false,
  shouldDisplayTooltip: false,
  isMissionControl: false,
  resize: () => {},
  isShowEditCreativeAction: false,
};

export default ControlButtons;
