/* global Stripe */

import React, { config } from '../../app/app';
import Payments from './Payments';

// stripe stuff
let stripe;
let stripeElements;
let stripeCard;
try {
  stripe = Stripe(config.get('stripe.key'));
  stripeElements = stripe.elements();
  stripeCard = stripeElements.create('card', {
    style: {
      base: {
        color: '#32325d',
        lineHeight: '24px',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '14px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  });
} catch (ex) {
  // no internet connection, could not download Stripe SDK
}

class PaymentsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: null,
      bankDetails: {
        routingNumber: '110000000',
        accountNumber: '000123456789',
        accountHolderName: 'what ever',
        accountHolderType: 'company',
      },
      cardComplete: false,
      paymentError: null,
      paymentType: 'credit',
      redirect: false,
      selectedCountry: null,
    };
  }

  componentDidMount() {
    if (!Stripe) {
      return;
    }

    stripeCard.addEventListener('change', this.changeCardDetails);
    try {
      stripeCard.mount('#stripe-mountable-payment-capture');
    } catch (ex) {
      // do nothing, component is not mounted
    }
  }

  componentWillUnmount() {
    if (!Stripe) {
      return;
    }
    stripeCard.removeEventListener('change', this.changeCardDetails);
  }

  changeBankDetail = (field, value) => {
    this.setState((prevState) => ({
      bankDetails: {
        ...prevState.bankDetails,
        [field]: value,
      },
    }));
  }

  changeCardDetails = (values) => {
    this.setState({ cardComplete: values.complete });
  }

  changePaymentType = (value) => {
    this.setState({ paymentType: value });
  }

  changeValue = (stateKey, value) => {
    this.setState({ [stateKey]: value });
  }

  processPayment = async ({ amount, country }) => {
    const { paymentType } = this.state;
    const { account, user, onProcessPaymentStart } = this.props;
    onProcessPaymentStart();
    let result;
    if (paymentType === 'credit') {
      result = await stripe.createSource(stripeCard, {});
    }
    if (paymentType === 'alipay') {
      result = await stripe.createSource({
        type: 'alipay',
        amount: amount * 100,
        currency: 'usd',
        redirect: {
          return_url: `${window.location.origin}/account`,
        },
        metadata: {
          account: account.get('id'),
          Country: country,
          User: user,
        },
      });
    }
    // ACH payment type
    // else {
    //   const b = this.state.bankDetails;
    //   result = await stripe.createToken('bank_account', {
    //     country: 'US',
    //     currency: 'usd',
    //     routing_number: b.routingNumber,
    //     account_number: b.accountNumber,
    //     account_holder_name: b.accountHolderName,
    //     account_holder_type: b.accountHolderType,
    //   });
    // }
    if (result && result.error) {
      this.setState({ paymentError: true });
      this.props.onProcessPaymentError();
      return;
    }
    if (result && result.source.redirect) {
      window.location = result.source.redirect.url;
    } else {
      this.props.onProcessPaymentFinish({ source: result.source || result.token, amount, country });
    }
  }

  render() {
    return (
      <Payments
        {...this.props}
        {...this.state}
        changeAmount={(v) => this.changeValue('amount', v)}
        changeBankDetail={this.changeBankDetail}
        changeCountry={(v) => this.changeValue('selectedCountry', v)}
        changePaymentType={this.changePaymentType}
        processPayment={this.processPayment}
      />
    );
  }
}

PaymentsContainer.defaultProps = {
  onProcessPaymentError: () => {},
  onProcessPaymentFinish: () => {},
  onProcessPaymentStart: () => {},
};

export default PaymentsContainer;
