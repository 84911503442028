import React from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { FILTER_KEY_COUNTRY } from 'app/constants/filters';
import MultiTreeFrontend from '../Base/MultiTreeFrontend/MultiTreeFrontend';
import CountryPanel from '../Base/Panels/CountryPanel';
import { getCountries } from '../Base/Panels/helper';
import './multiTreeCountry.scss';

const MultiTreeCountry = ({
  visible,
  className,
  availableNodes,
  defaultCheckedNodes,
  dropdownFixed,
  onVisibleChange,
  smallSizeHeader,
  onChange,
  onRemove,
}) => {
  const checkedNodes = defaultCheckedNodes.map((node) => node.children).flat();
  return (
    <MultiTreeFrontend
      dropdownFixed={dropdownFixed}
      visible={visible}
      nodes={getCountries()}
      filterKey={FILTER_KEY_COUNTRY}
      defaultCheckedNodes={checkedNodes}
      renderOverlay={() => (
        React.createElement(CountryPanel, {
          visible,
          className,
          availableNodes,
          defaultCheckedNodes: checkedNodes,
          onVisibleChange,
          smallSizeHeader,
          onChange,
        })
      )}
      onVisibleChange={onVisibleChange}
      onRemove={onRemove}
    />
  );
};

MultiTreeCountry.propTypes = {
  visible: PropTypes.bool.isRequired,
  availableNodes: PropTypes.arrayOf(PropTypes.any),
  defaultCheckedNodes: PropTypes.arrayOf(PropTypes.any),
  dropdownFixed: PropTypes.bool,
  onVisibleChange: PropTypes.func,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};

MultiTreeCountry.defaultProps = {
  availableNodes: [],
  defaultCheckedNodes: [],
  dropdownFixed: false,
  onVisibleChange: noop,
  onChange: noop,
  onRemove: noop,
};

export default React.memo(MultiTreeCountry);
