import React from 'react';

export const SkAlertHeader = 'SkAdNetwork Campaign Limit Reached';
export const SkAlertText = (
  <p>
    You have reached the campaign limit for this application.
    <br />
    <br />
    If you want to enable SKAdNetwork for this campaign, you need to pause or archive an
    upgraded SKAdNetwork campaign within this application.
  </p>
);

export const SkResetAlertHeader = 'Confirm Changes';
export const SkResetAlertText = (
  <p>
    Your A/B test will be reset by saving these campaign changes. Changes such as targeting, bidding, or creatives will trigger this reset.
  </p>
);
