import React from 'react';
import classnames from 'classnames';
import Dropdown from '../../Dropdown/Dropdown';
import DropdownWrapper from '../../Dropdown/DropdownBase/DropdownWrapper';
import Metric from './Metric';

class MetricDropdown extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.metricRef = React.createRef();
  }

  onApply = (close) => {
    const { onMetricsChange } = this.props;
    this.metricRef.current.onApply(onMetricsChange, close);
  }

  onLink = () => {
    if (this.props.executeLinkClear) {
      this.metricRef.current.onClear();
    } else {
      this.metricRef.current.onReset();
    }
  }

  renderOverlay = (close) => {
    const {
      metricUserId,
      metricType,
      metricGroup,
      metricOverlayWidth,
      metricOverlayHeight,
      prefixCls,
      renderCount,
      searchPlaceholder,
      showSelectAll,
      linkText = 'Reset Columns',
      maxSelected,
      defaultKey,
    } = this.props;
    return (
      <DropdownWrapper
        width={metricOverlayWidth}
        height={metricOverlayHeight}
        onLink={this.onLink}
        linkText={linkText}
        onApply={() => this.onApply(close)}
        onCancel={close}
      >
        <Metric
          ref={this.metricRef}
          prefixCls={prefixCls}
          metricUserId={metricUserId}
          metricType={metricType}
          metricGroup={metricGroup}
          renderCount={renderCount}
          searchPlaceholder={searchPlaceholder}
          showSelectAll={showSelectAll}
          maxSelected={maxSelected}
          defaultKey={defaultKey}
        />
      </DropdownWrapper>
    );
  };

  render() {
    const { dropdownClassName } = this.props;
    const { open } = this.state;
    return (
      <Dropdown
        renderOverlay={this.renderOverlay}
        placement="right"
        transitionOffset={5}
        horizontalOffset={5}
        className={dropdownClassName}
        onVisibleChange={(visible) => this.setState({ open: visible })}
      >
        <i
          className={classnames(
            `${dropdownClassName}-setting`,
            'material-icons',
            {
              [`${dropdownClassName}-setting-open`]: open,
            },
          )}
        >
          settings
        </i>
      </Dropdown>
    );
  }
}

export default MetricDropdown;
