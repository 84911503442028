import { format } from 'lib/currency';
import config from 'lib/config';

const maxBidForLongTail = config.get('countryData.maxBidForLongTail');

export const LONG_TAIL_ERROR_MESSAGE = {
  invalidLongTailGeos: `Remove the following geos in targeting or change bid to less than ${format(maxBidForLongTail)}`,
};

export const CSV_ERROR_MESSAGE = {
  missingHeaders: 'These expected headers were missing',
  unexpectedHeaders: 'These unexpected headers were found',
  invalidHexIDs: 'These IDs are not valid ObjectIDs',
  invalidPubIDs: 'These IDs do not correspond to any Publisher Apps',
  duplicatePubIDs: 'These IDs have duplicate entries in the CSV',
  invalidRateEntry: 'These IDs have rates that aren\'t valid numbers between',
  invalidGeoEntry: 'These Geos are invalid. Either not targeted or invalid geo code',
  invalidFilename: 'The file name must be in the following format',
  rateLimitReached: 'Could not upload',
  publiserTargetingIdError: 'These are not valid IDs',
  iOSPubliserTargetingIdError: 'These are not valid ios IDs',
  androidPubliserTargetingIdError: 'These are not valid android IDs',
  windowsPubliserTargetingIdError: 'These are not valid windows IDs',
  amazonPubliserTargetingIdError: 'These are not valid amazon IDs',
  invalidDailyGeoEntry: 'These Geos are invalid. Either not targeted or invalid geo code',
  invalidDailyRateEntry: 'These Geos have rates that aren\'t valid numbers',
  rateOutOfRange: 'Default bid error',
  ...LONG_TAIL_ERROR_MESSAGE,
};

export const MMP_ERROR_MESSAGE = {
  invalidURL: 'Invalid url',
  missingApplication: 'Missing application',
  invalidMacros: 'Invalid macro',
  missedRequiredMacros: 'Missing one of the required parameters.',
};

export const SKADNETWORK_ERROR_MESSAGE = {
  invalidSKAdNetworkIDs: 'These IDs are not valid SKAdNetworkIDs',
};

export default {
  ...CSV_ERROR_MESSAGE,
  ...MMP_ERROR_MESSAGE,
  ...LONG_TAIL_ERROR_MESSAGE,
  ...SKADNETWORK_ERROR_MESSAGE,
};
