import React from 'react';
import classNames from 'classnames';
import { noop } from 'lodash';
import CreativeStatus from 'services/Templates/CreativeBuilder/CreativeTable/CreativeStatus';
import previewCreative from 'components/Creatives/Preview/previewCreative';
import Tooltip from 'components/V2/Tooltip/Tooltip';
import CreativePreview from 'services/Templates/CreativeBuilder/CreativeTable/CreativePreview';
import './CreativeSelector.scss';

const prefix = 'templates_campaigns_CreativeSelector';
export const generateTooltipBody = (title) => {
  if (!title) {
    return '';
  }
  const templateStrings = title.slice(0, -1).split(' - '); // Get template name and orientation.
  templateStrings.reverse();
  templateStrings[0] = templateStrings[0].toLowerCase();
  return `Please select a ${templateStrings.join(' ')} creative.`;
};

const CreativeSelector = ({
  formatInfo,
  openDrawer,
  selectedIds,
  selectCreative,
  isAbTesting,
}) => {
  const { available, creative, title } = formatInfo;
  const creativeId = formatInfo.creative?.get('id');
  const removeCreative = (e) => {
    e.stopPropagation();
    selectCreative(selectedIds.filter((id) => id !== creativeId));
  };

  if (!available) {
    return (
      <div className={classNames(prefix)}>
        <span className={classNames(`${prefix}-title`)}>{title}</span>
        <div className={classNames(`${prefix}-selector`, 'inactive')}>
          <Tooltip
            title="Creative Slot Empty"
            body={generateTooltipBody(title)}
          >
            <i className="material-icons disabled">add_circle_outline</i>
          </Tooltip>
        </div>
      </div>
    );
  }

  if (!creative) {
    return (
      <div onClick={() => openDrawer(true, formatInfo)} className={classNames(prefix)}>
        <span className={classNames(`${prefix}-title`)}>{title}</span>
        <div className={classNames(`${prefix}-selector`, 'no-creative')}>
          <i className="material-icons">add_circle_outline</i>
          { isAbTesting && (
            <Tooltip
              title="Creative Slot Empty"
              body={generateTooltipBody(title)}
            >
              <i className="material-icons warning">warning</i>
            </Tooltip>
          )}
        </div>
      </div>
    );
  }

  return (
    <div onClick={() => openDrawer(true, formatInfo)} className={classNames(prefix)}>
      <span className={classNames(`${prefix}-title`)}>{title}</span>
      <div className={classNames(`${prefix}-selector`)}>
        <i
          className="material-icons"
          onClick={(e) => removeCreative(e)}
        >
          delete
        </i>
        <div className={classNames(`${prefix}-creative-info`)}>
          <CreativeStatus creative={creative} hideLabel />
          <CreativePreview
            canEdit
            creative={creative}
            previewCreative={previewCreative}
            prefixCls={prefix}
            resizeClassName={`${prefix}-creative-info`}
            titleEvent={noop}
          />
        </div>
      </div>
    </div>
  );
};

export default CreativeSelector;
