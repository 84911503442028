import PropTypes from 'prop-types';

// eslint-disable-next-line import/prefer-default-export
export const Router = PropTypes.shape({
  // eslint-disable-next-line react/forbid-prop-types
  queryParams: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
});

export const CampaignCreativeMapping = PropTypes.shape({
  campaign: PropTypes.shape({ id: PropTypes.string.isRequired }),
  creative: PropTypes.shape({ id: PropTypes.string.isRequired }),
});

export const Pagination = PropTypes.shape({
  page: PropTypes.number,
  pages: PropTypes.number,
});
