import React from 'react';
import { isEmpty } from 'lodash';
import { compose } from 'app/app';
import {
  FILTER_KEY_APPLICATION,
  FILTER_KEY_ASSET_STATUS,
  FILTER_KEY_ASSET_TYPE,
} from 'app/constants/filters';
import { ListEmptyState } from 'components/V2/EmptyState/EmptyState';
import Button from '../../../../../components/Button/Button';
import Message from '../../../../../components/V2/FlagMessage/Message';
import Asset from '../../../../../models/Asset';
import { isAdminService } from '../../../../../lib/serviceType';
import { getAssetPageSizeCache } from '../../../../../lib/cache/PageSizeCache';
import { getAssetFiltersCache } from '../../../../../lib/cache/FiltersCache';
import { getAssetSortCache } from '../../../../../lib/cache/SortCache';
import { ns as accountHierNS } from '../../../../Templates/AccountHier/actions';
import ReviewHistory from '../../creativeQA/reviewHistory/ReviewHistory';
import AssetList from './AssetList';
import AssetUploader from './AssetUploader/AssetUploader';
import AssetPreviewer from './AssetPreviewer/AssetPreviewer';
import Filters from './Filters';
import { isMongoID } from './utils';
import { assetDefaultSort, SEARCH_PARAM_ASSET_ID } from './constant';
import './AssetListContainer.scss';
import { canModifyAssets } from '../../../../../lib/helpers/authUser';

export const defaultPagination = {
  page: 1, pages: 0, perPage: 10, total: 0,
};

class AssetListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.filtersCache = getAssetFiltersCache(props.authUser.account);
    if (props.history.action === 'PUSH') {
      this.filtersCache.remove();
    }
    this.state = {
      // used for showing the details of the selected asset
      asset: undefined,

      // asset list related data
      previewAssets: undefined,
      assets: [],
      loadingAssets: false,
      pagination: defaultPagination,
      previewPagination: undefined,

      // used to show uploader
      isUploaderShown: false,
      isPreviewerShown: false,

      // playable history data
      showHistory: false,
      historyAsset: null,

      dataEmpty: false,
    };
  }

  componentDidMount() {
    const { queryParams: { id, mode } } = this.props.router;
    const showHistory = mode === 'review_history' && !!id;
    if (showHistory) {
      this.showHistoryWithHistoryParameter(id);
    }
    this.checkDataEmpty();
    this.setDefaultSort();
    this.getAssets({ page: 1 });
  }

  componentDidUpdate(previousProps, previousState) {
    if (previousProps.accountId !== this.props.accountId) {
      // fetch assets if account filter is changed
      this.getAssets();
    }

    this.showDetailWithDetailParameter(previousState);
  }

  checkDataEmpty = async () => {
    const { response } = await Asset.getAll({ perPage: 1 });

    this.setState({
      dataEmpty: isEmpty(response),
    });
  };

  /**
   * Opens the asset detail automatically if the
   * page url contains 'detail' and 'search' query parameters.
   *
   * @param previousState is required to check if it was open and just closed.
   */
  showDetailWithDetailParameter(previousState) {
    const { queryParams } = this.props.router;
    if (!queryParams || !('detail' in queryParams) || !('search' in queryParams)) {
      // abort if detail or search parameters are not present
      return;
    }

    if (!!this.state.asset || !!previousState.asset) {
      // abort if it's already displaying details or it was displaying details and it's just closed
      return;
    }

    const searchValue = queryParams.search || '';
    if (!searchValue || !isMongoID(searchValue)) {
      // abort if there is no search value or the search value is not a valid mongo object id
      return;
    }

    const searchedAsset = this.state.assets
      ? this.state.assets.find((asset) => asset.get('id') === searchValue)
      : undefined;

    if (!searchedAsset) {
      // abort if the asset is not in the list
      return;
    }

    this.setState((prevState) => ({ asset: prevState.assets[0], isPreviewerShown: true }));
  }

  renderListContent = (content) => {
    const {
      asset,
      isUploaderShown,
      isPreviewerShown,
      pagination,
      showHistory,
      historyAsset,
    } = this.state;

    const {
      accountHier,
      authUser,
    } = this.props;

    const urlSearchParams = new URLSearchParams(window.location.search);
    const searchValue = urlSearchParams.get(SEARCH_PARAM_ASSET_ID);

    return (
      <div className="views__assets__list_container_2">
        {isAdminService() && <p className="h4">Assets</p>}
        <div className="filter-container">
          <Filters
            account={authUser.account}
            onRefresh={this.getAssets}
            className="views__assets__list-filter"
            searchValue={searchValue}
          />
          { canModifyAssets() && (
          <Button onClick={this.showAssetUploader}>
            Add Assets
          </Button>
          )}
        </div>

        {asset && isPreviewerShown
          && (
            <AssetPreviewer
              asset={asset}
              onClose={this.onAssetPreviewerClose}
              onPrevAsset={this.onPrevAsset}
              onNextAsset={this.onNextAsset}
            />
          )}

        {isUploaderShown
          && <AssetUploader accountHier={accountHier} authUser={authUser} onClose={this.onAssetUploaderClose} />}

        {content}

        {showHistory && !!historyAsset
          && (
            <ReviewHistory
              asset={historyAsset}
              onClose={this.onReviewHistoryClose}
              refreshList={() => this.getAssets({ page: pagination.page })}
            />
          )}
      </div>
    );
  };

  render() {
    const {
      assets,
      loadingAssets,
      pagination,
      dataEmpty,
    } = this.state;

    const { authUser } = this.props;

    if (dataEmpty) {
      return this.renderListContent(
        <ListEmptyState type="asset" onClick={this.showAssetUploader} />,
      );
    }

    return this.renderListContent(
      <AssetList
        assets={assets}
        getAssets={this.getAssets}
        loading={loadingAssets}
        onDetailClick={this.onDetailClick}
        onDownloadAssetClick={this.onDownloadAssetClick}
        pagination={pagination}
        authUser={authUser}
        onReviewHistoryClick={this.onReviewHistoryClick}
        onDeleteAsset={this.onDeleteAsset}
      />,
    );
  }

  getApiParams = (query = {}) => {
    const { authUser, accountHier } = this.props;
    const { account } = authUser;
    const pageSizeCache = getAssetPageSizeCache(account).get();
    const sortCache = getAssetSortCache(account).get();
    const filtersCache = getAssetFiltersCache(account).get();
    const sort = sortCache.map(({ id, desc }) => ({ key: id, asc: !desc })).pop();

    const {
      [FILTER_KEY_APPLICATION]: application,
      [FILTER_KEY_ASSET_STATUS]: qaStatus,
      [FILTER_KEY_ASSET_TYPE]: type,
      ...filter
    } = filtersCache;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const assetID = urlSearchParams.get(SEARCH_PARAM_ASSET_ID);

    if (application?.length) {
      Object.assign(filter, { applications: application.map((item) => item.id) });
    }

    if (qaStatus?.length) {
      Object.assign(filter, { qaStatus: qaStatus.map((item) => item.id) });
    }

    if (type?.length) {
      Object.assign(filter, { typeIn: type.map((item) => item.id) });
    }

    if (assetID) {
      Object.assign(filter, { search: assetID });
    }
    return {
      ...query,
      ...filter,
      sort,
      perPage: pageSizeCache,
      accounts: isAdminService() ? accountHier?.account || '' : account,
    };
  }

  getAssets = (query = {}) => {
    const params = this.getApiParams(query);
    this.setState({ loadingAssets: true });
    Asset.getAll(params).then(
      (result) => {
        const _assets = Asset.make(result.response);
        this.setState({
          assets: _assets,
          previewAssets: _assets,
          pagination: result.pagination,
          previewPagination: result.pagination,
          loadingAssets: false,
        });
      },
      () => {
        this.setState({
          loadingAssets: false,
        });
      },
    );
  }

  getAssetData = async (page) => {
    const params = this.getApiParams({ page });
    const result = await Asset.getAll(params);
    this.setState({
      previewAssets: Asset.make(result.response),
      previewPagination: result.pagination,
    });
  }

  getAssetIndex = () => {
    const { previewAssets, asset } = this.state;
    return previewAssets.findIndex((item) => asset.get('id') === item.get('id'));
  }

  onPrevAsset = async () => {
    const { previewPagination } = this.state;
    let assetIndex = this.getAssetIndex();

    if (assetIndex === 0 && previewPagination.page === 1) {
      return;
    }

    if (assetIndex === 0 && previewPagination.page > 1) {
      await this.getAssetData(previewPagination.page - 1);
      assetIndex = previewPagination.perPage;
    }

    this.setState((prevState) => ({
      asset: prevState.previewAssets[assetIndex - 1],
    }));
  }

  onNextAsset = async () => {
    const { previewPagination } = this.state;
    let assetIndex = this.getAssetIndex();

    if (assetIndex + 1 === previewPagination.perPage && previewPagination.page === previewPagination.pages) {
      return;
    }

    if (assetIndex + 1 === previewPagination.perPage && previewPagination.page < previewPagination.pages) {
      await this.getAssetData(previewPagination.page + 1);
      assetIndex = -1;
    }

    this.setState((prevState) => ({
      asset: prevState.previewAssets[assetIndex + 1],
    }));
  }

  onAssetPreviewerClose = () => {
    const { assets, pagination } = this.state;
    this.setState({
      asset: undefined,
      isPreviewerShown: false,
      previewAssets: assets,
      previewPagination: pagination,
    });
  }

  onAssetPreviewerHide = () => {
    this.setState({
      isPreviewerShown: false,
    });
  }

  onAssetUploaderClose = (invalidate) => {
    this.setState({
      asset: undefined,
      isUploaderShown: false,
    }, () => {
      if (invalidate) {
        this.checkDataEmpty();
        this.getAssets();
      }
    });
  }

  onDetailClick = (asset) => {
    this.setState({ asset, isPreviewerShown: true });
  }

  onDownloadAssetClick = (asset) => Asset.download(asset.get('name'), asset.get('id'));

  showAssetUploader = () => {
    const { isPreviewerShown } = this.state;
    if (isPreviewerShown) {
      this.onAssetPreviewerHide();
    }

    this.setState({ isUploaderShown: true });
  };

  onReviewHistoryClose = () => {
    this.setState({
      showHistory: false,
      historyAsset: null,
    });
  }

  onReviewHistoryClick = (historyAsset) => {
    this.setState({
      showHistory: true,
      historyAsset,
    });
  }

  setDefaultSort = () => {
    const { authUser } = this.props;
    const sortCache = getAssetSortCache(authUser.account);
    const sortValues = sortCache.get();
    if (!sortValues.length) {
      sortCache.save([assetDefaultSort]);
    }
  }

  onDeleteAsset = async (asset) => {
    try {
      await Asset.deleteAsset(asset.get('id'));
      Message.success({
        title: 'Asset Deleted',
        content: 'Your asset has been deleted successfully.',
      });
      this.getAssets({ page: this.state.pagination.page });
    } catch (error) {
      Message.error({
        title: 'Error',
        content: error.response?.messages || 'error',
      });
    }
  };

  showHistoryWithHistoryParameter = async (id) => {
    const result = await Asset.get(id);
    const historyAsset = new Asset(result.response);
    this.setState({
      historyAsset,
      showHistory: true,
    });
  };
}

const props = (state) => ({
  // 'accountHier' contains globally selected account ID
  accountId: state.accountHier ? state.accountHier.account : undefined,
  accountHier: state[accountHierNS],
  authUser: state.authUser,
});

export default compose(AssetListContainer, { props });

// until we figure out how to use composed elements in tests, we will have an additional export
export { AssetListContainer as AsstListContainerClass };
