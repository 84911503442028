import { compose } from '../../../../../../app/app';
import MobileMeasurementPartner from '../../../../../Templates/Campaigns/MMP/MobileMeasurementPartner';

const createNamespace = 'views.campaigns.edit';
const ns = 'views.campaigns.edit.tracking';
const props = (state) => ({
  ...state[ns],
  ...state[createNamespace],
});

export default compose(MobileMeasurementPartner, { props });
