import React from 'react';
import classNames from 'classnames';
import Label from '../../components/V2/Label/Label';
import './list.scss';

const ListItem = ({
  name,
  onClick,
  cancelable,
  inactive,
}) => (
  <div className="template__list_container" onClick={onClick}>
    <Label
      type={
        inactive
          ? 'inactive'
          : 'option'
      }
    >
      {name}
      {cancelable && (
        <i className="material-icons">cancel</i>
      )}
    </Label>
  </div>
);

const ScrollableList = ({
  items,
  onClick,
  header,
  cancelable,
  inactive,
}) => (
  <div className="template__list_container flex">
    {header && <p className="p1">{header}</p>}
    <div
      className={classNames('list', {
        inactive: !!inactive,
      })}
    >
      {items && items.map((item, i) => (
        <ListItem
          inactive={inactive}
          key={i}
          name={item.name}
          onClick={() => {
            if (onClick) {
              return onClick(item);
            }
            return null;
          }}
          cancelable={cancelable}
        />
      ))}
    </div>
  </div>
);

export default ScrollableList;
