import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { noop } from 'lodash';
import DropdownWrapper from '../../../Dropdown/DropdownBase/DropdownWrapper';
import Menu, { MenuAll, MenuDivider, MenuItem } from '../../../Dropdown/Menu/Menu';
import utils from '../../utils';
import '../Multi/multi.scss';

const MultiPanel = ({
  prefixCls,
  className,
  data,
  width,
  height,
  single,
  style,
  dividerIndex,
  renderItemLabel,
  defaultCheckedKeys,
  onVisibleChange,
  onChange,
  isSelectAllHidden,
  isLinkHidden,
}) => {
  const [checkedKeys, setCheckedKeys] = useState(defaultCheckedKeys || []);

  const onSelectChange = useCallback((_checkedValues, _checkedKeys) => {
    setCheckedKeys(_checkedKeys);
  }, []);

  const onClear = useCallback(() => {
    setCheckedKeys([]);
  }, []);

  const onCancel = useCallback(() => {
    onVisibleChange(false);
    setCheckedKeys(defaultCheckedKeys);
  }, [onVisibleChange, defaultCheckedKeys]);

  const onApply = useCallback(() => {
    const checkedValues = data.filter(({ id }) => checkedKeys.indexOf(id) !== -1);
    onChange(checkedValues, utils.isAllSelected(data, checkedValues));
  }, [onChange, checkedKeys, data]);

  const renderItems = () => {
    const items = [];
    data.forEach((item, index) => {
      items.push(
        <MenuItem
          className={`${prefixCls}-item`}
          key={item.id}
          data={item}
          dataKey={item.id}
          disabled={item.disabled}
          single={single}
        >
          {renderItemLabel(item)}
        </MenuItem>,
      );
      if (dividerIndex !== -1 && index === dividerIndex) {
        items.push(<MenuDivider key={`divider-${index}`} />);
      }
    });
    return items;
  };

  return (
    <DropdownWrapper
      data={data}
      onApply={onApply}
      linkText="Clear All"
      onLink={onClear}
      onCancel={onCancel}
      height={height}
      width={width}
      style={style}
      isLinkHidden={isLinkHidden}
    >
      <Menu
        onChange={onSelectChange}
        className={
          classNames(
            `${prefixCls}-menu`,
            className,
          )
        }
        checkedKeys={checkedKeys}
      >
        {
          !isSelectAllHidden && (
            <div className={`${prefixCls}-head`}>
              <MenuAll>Select All</MenuAll>
            </div>
          )
        }
        <div className={`${prefixCls}-body`}>
          {renderItems()}
        </div>
      </Menu>
    </DropdownWrapper>
  );
};

MultiPanel.propTypes = {
  prefixCls: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
    ]),
  ).isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  dividerIndex: PropTypes.number,
  renderItemLabel: PropTypes.func.isRequired,
  defaultCheckedKeys: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.any,
    ]),
  ),
  onVisibleChange: PropTypes.func,
  onChange: PropTypes.func,
};

MultiPanel.defaultProps = {
  prefixCls: 'v2_component_filter_multi',
  width: 270,
  height: 'auto',
  style: {},
  dividerIndex: null,
  defaultCheckedKeys: [],
  onVisibleChange: noop,
  onChange: noop,
};

export default React.memo(MultiPanel);
