import { getStore } from '../../../app/storeprovider';
import { ns } from './PreviewContainer';

// Mission control preview per creative
const preview = (creative, isShowEditCreativeAction = false) => {
  const store = getStore();
  store.dispatch({
    type: 'PREVIEW_CREATIVE_ACTION',
    end: ({ state }) => {
      const newState = { ...state };
      newState[ns] = {
        creative,
        isShowEditCreativeAction,
        templateId: null,
        replacements: null,
        platform: null,
      };
      return newState;
    },
  });
};

// Grow preview used in create creative
const generateTemplatePreview = (templateId, replacements, platform = '') => {
  const store = getStore();
  store.dispatch({
    type: 'GENERATE_TEMPLATE_PREVIEW_ACTION',
    end: ({ state }) => {
      const newState = { ...state };
      newState[ns] = {
        creative: null,
        templateId,
        replacements,
        platform,
      };
      return newState;
    },
  });
};

export default preview;
export {
  generateTemplatePreview,
};
