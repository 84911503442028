import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { isFalsey } from '../../lib/lib';
import MinMaxText from './MinMaxText';
import './minmax.scss';

const MinMax = ({
  textType = 'Currency',
  disabled = false,
  minValue,
  onMinChange,
  maxValue,
  onMaxChange,
  toggler = false,
  minLocked = false,
  minLockedText = null,
  maxLocked = false,
  maxLockedText = null,
  leftPlaceholder = 'Enter minimum value',
  rightPlaceholder = 'Enter maximum value',
  isValid = () => true,
  minValid = true,
  textMinInvalid,
  textMaxInvalid,
  showValidityOnRender = false,
}) => {
  const [displayValidation, setDisplayValidation] = useState(showValidityOnRender);
  const isMinValueLessMaxValue = () => {
    if (!isFalsey(maxValue) && !isFalsey(minValue)) {
      if (Number(minValue) > Number(maxValue)) {
        return false;
      }
    }
    return true;
  };
  const onValueChange = (onChange) => (v) => {
    setDisplayValidation(true);
    onChange(v);
  };
  const isMinLessMax = isMinValueLessMaxValue();
  const isAllValid = isValid(minValue) && isValid(maxValue) && isMinLessMax && minValid;
  let validationMsg = '';
  if (!minValid) {
    validationMsg = 'SKAN enabled campaigns must target OS version 11 and higher.';
  }
  if (!isMinLessMax) {
    validationMsg = 'Maximum value must be greater than minimum value.';
  }
  const isMinValid = !displayValidation || (isValid(minValue) && isMinLessMax && minValid);
  const isMaxValid = !displayValidation || (isValid(maxValue) && isMinLessMax);
  const isValidMsgShow = displayValidation && !isAllValid && validationMsg;
  useEffect(() => {
    setDisplayValidation(showValidityOnRender);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);
  return (
    <div className="template__min-max">
      <div className={classNames('min-max-input', { 'min-max-input--noIcon': textType !== 'Currency' })}>
        {(!disabled || toggler) && (<div className="p8">Minimum</div>)}
        <MinMaxText
          textType={textType}
          isValid={isMinValid}
          value={minValue || ''}
          disabledText={minLockedText}
          disabled={disabled || minLocked}
          onChange={onValueChange(onMinChange)}
          placeholder={disabled ? 'DISABLED' : leftPlaceholder}
          validationMsg={!isValidMsgShow && textMinInvalid}
        />
      </div>
      <div className={classNames('min-max-input', { 'min-max-input--noIcon': textType !== 'Currency' })}>
        {(!disabled || toggler) && (<div className="p8">Maximum</div>)}
        <MinMaxText
          textType={textType}
          isValid={isMaxValid}
          value={maxValue || ''}
          disabledText={maxLockedText}
          disabled={disabled || maxLocked}
          onChange={onValueChange(onMaxChange)}
          placeholder={disabled ? 'DISABLED' : rightPlaceholder}
          validationMsg={!isValidMsgShow && textMaxInvalid}
        />
      </div>
      <div className="info_messages">
        {isValidMsgShow && (
          <p className="p4 invalid_text">{validationMsg}</p>
        )}
      </div>
    </div>
  );
};

export default MinMax;
