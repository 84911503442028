import React, { useState } from 'react';
import classnames from 'classnames';
import { Dropdown } from '../index';
import { platformSelectors } from './constants';
import DirectLogo from '../../../assets/img/direct_logo.svg';
import { isAdminService } from '../../../lib/serviceType';
import './platformSelector.scss';

const classPrefix = 'platform_selector';
const rowClassPrefix = 'platform_selector_row';

const Row = ({
  link, logo, title, platform, selected,
}) => (
  <a
    href={link}
    target="_blank"
    rel="noreferrer"
    className={classnames(
      rowClassPrefix,
      {
        [`${rowClassPrefix}-selected`]: selected,
      },
    )}
  >
    <img src={logo} />
    <span className="title">{title}</span>
    <span className="platform">
      /
      &nbsp;
      {platform}
    </span>
  </a>
);

const PlatformSelector = ({ isAdmin }) => {
  const [visible, setVisible] = useState(false);

  const renderOverlay = () => (
    <div>
      {platformSelectors(isAdminService()).map((item) => (
        <Row
          key={item.key}
          {...item}
        />
      ))}
    </div>
  );

  return (
    <Dropdown
      className={classPrefix}
      closeOnClick
      placement="right"
      horizontalOffset={isAdmin ? 264 : 278}
      transitionOffset={isAdmin ? -30 : -35}
      renderOverlay={renderOverlay}
      onVisibleChange={setVisible}
    >
      {isAdmin ? (
        <div
          className={classnames(
            `${classPrefix}-admin`,
            {
              [`${classPrefix}-visible`]: visible,
            },
          )}
        >
          <img src={DirectLogo} />
        </div>
      ) : (
        <div
          className={classnames(
            `${classPrefix}-advertiser`,
            {
              [`${classPrefix}-visible`]: visible,
            },
          )}
        >
          <img src={DirectLogo} />
          <span>Direct</span>
        </div>
      )}
    </Dropdown>
  );
};

export default PlatformSelector;
