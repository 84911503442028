import React from 'react';
import AccountFinder from '../../../../../../components/AccountFinder/AccountFinderContainer';
import ApplicationFinder from '../../../../../../components/ApplicationFinder/ApplicationFinder';
import Account from '../../../../../../models/Account';
import './AccountAndApplicationSelector.scss';

const AccountAndApplicationSelector = ({
  account,
  application,
  onSelectAccount,
  onSelectApplication,
  disabled,
  showAccountFinder = true,
  authUser,
}) => (
  <div className="account_and_application_selector">
    {
      showAccountFinder && (
        <div className="accountSelector-wrapper">
          <div className="label">
            Account
          </div>
          <AccountFinder
            acctHier={{
              account: account?.get('id'),
              name: account?.get('name'),
            }}
            onSelect={(v) => {
              if (v?.get('id') !== account?.get('id')) {
                onSelectApplication(undefined);
              }
              onSelectAccount(v);
            }}
            placeholder="Search for an account"
            disabled={disabled}
          />
        </div>
      )
    }
    <div className="applicationFinder-wrapper">
      <div className="label">
        Application
      </div>
      <ApplicationFinder
        key={account?.get('id')}
        authUser={authUser}
        accountHier={{ account: account?.get('id') }}
        application={application}
        fetchOnMount
        onSelect={(v) => {
          onSelectApplication(v);
          onSelectAccount(new Account({
            id: v.get('account.id'),
            name: v.get('account.name'),
          }));
        }}
        placeholder="Search for an application"
        disabled={disabled}
      />
    </div>
  </div>
);

export default AccountAndApplicationSelector;
