import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './collapse.scss';
import CollapseBase from './CollapseBase';

const classPrefix = 'v2_component_collapse';

const Collapse = ({ className, state, rows }) => (
  <div className={classnames(classPrefix, className, `${classPrefix}-${state}`)}>
    {rows.map((row, index) => (
      <CollapseBase
        key={index}
        summary={row.summary}
        detail={row.detail}
        prefixIcon={row.prefixIcon}
        state={state}
      />
    ))}
  </div>
);

Collapse.propTypes = {
  /**
   * Class name of root component.
   */
  className: PropTypes.string,
  // Collapse state with different skin.
  state: PropTypes.oneOf(['info', 'warning', 'error']),
  /**
   * Column configuration.
   */
  rows: PropTypes.arrayOf(
    PropTypes.shape(
      {
        /**
         * `summary` component content.
         */
        summary: PropTypes.node,
        /**
         * `detail` component content.
         */
        detail: PropTypes.node,
        /**
         * `summary` component prefix icon.
         */
        prefixIcon: PropTypes.node,
        /**
         * `summary` component expand icon.
         */
        expandIcon: PropTypes.node,
        /**
         * Tell if this row is expanded.
         */
        expanded: PropTypes.bool,
        /**
         * Called when click `summary` component.
         */
        onExpand: PropTypes.func,
      },
    ),
  ).isRequired,
};

Collapse.defaultProps = {
  className: null,
  state: 'info',
};

export default Collapse;
