import React, {
  useState, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import privacy from 'assets/img/privacy.svg';
import privacyText from 'assets/img/privacy-text.svg';
import AppStoreFrame from '../../DeviceFrame/AppStoreFrame';
import ControlButtons from '../../DeviceFrame/ControlButtons';
import Icon from '../../Icon/Icon';
import Button from '../../Button/Button';
import { getTranslatedCtaText } from '../../../lib/translator';
import PrivacyDialog from './PrivacyDialog/PrivacyDialog';
import PrivacyFrame from '../../DeviceFrame/PrivacyFrame';
import './nativePreview.scss';

const PHONE = 'phone';
const TABLET = 'tablet';
const LANDSCAPE = 'landscape';
const PORTRAIT = 'portrait';
const VIDEO_REPLACEMENT_KEY = 'MAIN_VIDEO';
const CTA_TEXT_KEY = 'CTA_BUTTON_TEXT';
const sizes = {
  phone: {
    landscape: {
      width: '568px',
      height: '320px',
      overflow: 'scroll',
    },
    portrait: {
      width: '320px',
      height: '568px',
      overflow: 'hidden',
    },
  },
  tablet: {
    landscape: {
      width: '748px',
      height: '420px',
      overflow: 'scroll',
    },
    portrait: {
      width: '420px',
      height: '748px',
      overflow: 'hidden',
    },
  },
};

const getNativeReplacements = (oldReplacements, video, language) => {
  const newReplacements = {};
  if (oldReplacements) {
    oldReplacements.forEach((replacement) => {
      if (replacement.key === CTA_TEXT_KEY) {
        newReplacements[replacement.key] = getTranslatedCtaText(replacement.value?.trim(), language);
        return;
      }
      newReplacements[replacement.key] = replacement.value;
    });
  }

  if (video) {
    newReplacements[VIDEO_REPLACEMENT_KEY] = video;
  }
  return newReplacements;
};

const NativePreview = ({
  replacements,
  videoUrl,
  languageCode,
  creativeId,
  isMissionControl,
  isShowEditCreativeAction,
  isShowCloseBtn,
  history,
  onClose,
}) => {
  const [isAutoPlay, setIsAutoPlay] = useState(false);
  const [device, setDevice] = useState(PHONE);
  const [appStoreOpened, setAppStoreOpened] = useState(false);
  const [orientation, setOrientation] = useState(PORTRAIT);
  const [isShowPrivacyDialog, setIsShowPrivacyDialog] = useState(false);
  const [isPrivacyPageOpened, setIsPrivacyPageOpened] = useState(false);
  const [isExtendedPrivacyButton, setIsExtendedPrivacyButton] = useState(false);
  const [nativeReplacements, setNativeReplacements] = useState(getNativeReplacements(replacements, videoUrl, languageCode));
  const previewContentRef = useRef();
  const videoRef = useRef();
  const privacyButtonTimerRef = useRef(null);

  const isPhone = device === PHONE;
  const isPortrait = orientation === PORTRAIT;

  const setMaxSize = (property) => {
    previewContentRef.current.style.width = property.width;
    previewContentRef.current.style.height = property.height;
    previewContentRef.current.style.overflow = property.overflow;
    previewContentRef.current.scrollTop = 0;
  };

  useEffect(() => {
    setNativeReplacements(getNativeReplacements(replacements, videoUrl, languageCode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(replacements), videoUrl, languageCode]);

  useEffect(() => {
    setMaxSize(sizes[device][orientation]);
  }, [device, orientation]);

  const handleDownload = () => {
    videoRef.current?.pause();
    setAppStoreOpened(true);
  };

  const handleCloseStore = () => {
    videoRef.current?.play();
    setAppStoreOpened(false);
  };

  const refresh = () => {
    videoRef.current?.load();
    setIsShowPrivacyDialog(false);
    setIsPrivacyPageOpened(false);
    setIsExtendedPrivacyButton(false);
  };

  const handleCancel = () => {
    videoRef.current?.play();
    setIsShowPrivacyDialog(false);
  };

  const handleReadPolicy = () => {
    setIsShowPrivacyDialog(false);
    setIsPrivacyPageOpened(true);
  };
  const handlePrivacyPageClose = () => {
    videoRef.current?.play();
    setIsPrivacyPageOpened(false);
  };

  const setPrivacyButtonTimer = () => {
    privacyButtonTimerRef.current = setTimeout(() => {
      setIsExtendedPrivacyButton(false);
    }, 3000);
  };

  const handlePrivacyButton = () => {
    if (isExtendedPrivacyButton) {
      videoRef.current?.pause();
      setIsShowPrivacyDialog(true);
      if (privacyButtonTimerRef.current !== null) {
        clearTimeout(privacyButtonTimerRef.current);
      }
    } else {
      setIsExtendedPrivacyButton(true);
    }
    setPrivacyButtonTimer();
  };

  const rotate = () => setOrientation(isPortrait ? LANDSCAPE : PORTRAIT);

  const resize = () => setDevice(isPhone ? TABLET : PHONE);

  return (
    <div className="native_preview_wrapper">
      <div className="native_preview">
        <div className="native_preview__content_wrapper" ref={previewContentRef}>

          <div className="native_preview__content_wrapper_top">
            <div />
          </div>

          {!isAutoPlay && (
            <div onClick={() => setIsAutoPlay(true)} className="native_preview__content_wrapper_play_button">
              <Icon.Play />
            </div>
          )}

          <div className="native_preview__content_wrapper_header">
            <div className="native_preview__content_wrapper_icon">
              <img src={nativeReplacements?.APP_ICON} />
            </div>
            <div className="native_preview__content_wrapper_info">
              <div className="name">{nativeReplacements?.APP_NAME}</div>
              <div className="rating">
                Rating:
                {' '}
                {nativeReplacements?.APP_RATING_VALUE}
              </div>
              <div className="sponsored">{nativeReplacements?.SPONSORED_BY}</div>
            </div>
            <div
              className={classNames('native_preview__content_wrapper_privacy', { extended: isExtendedPrivacyButton })}
              onClick={handlePrivacyButton}
            >
              <img src={privacy} className="lock-icon" />
              <span>
                <img src={privacyText} className="privacy-icon" />
              </span>
            </div>
          </div>

          <div className="native_preview__content_wrapper_media">
            {nativeReplacements?.MAIN_IMAGE && (
              <img src={nativeReplacements?.MAIN_IMAGE} />
            )}
            {nativeReplacements?.MAIN_VIDEO && isAutoPlay && (
              <video key={nativeReplacements?.MAIN_VIDEO} autoPlay loop ref={videoRef}>
                <source src={nativeReplacements?.MAIN_VIDEO} type="video/mp4" />
              </video>
            )}
            <div className="description">{nativeReplacements?.APP_DESCRIPTION}</div>
          </div>

          <div className="native_preview__content_wrapper_actions">
            <Button onClick={handleDownload}>
              {nativeReplacements?.CTA_BUTTON_TEXT}
            </Button>
            <div />
          </div>

          <div className="native_preview__content_wrapper_footer">

            <div />
          </div>

          {
            appStoreOpened && (
              <div className="native_preview__content_wrapper__store_wrapper">
                <AppStoreFrame
                  onClose={handleCloseStore}
                />
              </div>
            )
          }

          {isShowPrivacyDialog && (
            <PrivacyDialog onClose={handleCancel} onReadPolicy={handleReadPolicy} orientation={orientation} />
          )}

          {isPrivacyPageOpened && (
          <div className="native_preview__content_wrapper_privacy_frame">
            <PrivacyFrame privacyPage="https://privacy.vungle.com/" onClose={handlePrivacyPageClose} />
          </div>
          )}
        </div>

        <ControlButtons
          shouldDisplayDeviceToggle
          isMissionControl={isMissionControl}
          creativeId={creativeId}
          resize={resize}
          rotate={rotate}
          refresh={refresh}
          isShowEditCreativeAction={isShowEditCreativeAction}
          close={onClose}
          history={history}
        />

        {
          isShowCloseBtn && (
            <div className="native_preview__CloseButton">
              <Button type="ghost" onClick={onClose}>Close</Button>
            </div>
          )
        }
      </div>
    </div>
  );
};

NativePreview.propTypes = {
  replacements: PropTypes.arrayOf(PropTypes.object),
  videoUrl: PropTypes.string,
  languageCode: PropTypes.string,
};

NativePreview.defaultProps = {
  replacements: [],
  videoUrl: '',
  languageCode: '',
};

export default NativePreview;
export {
  getNativeReplacements,
};
