import React, { compose, namespace } from '../../../app/app';
import MultiBidding from './MultiBidding';

const ns = 'views.campaigns.edit.multibidding';
const updateState = namespace(ns);
const props = (state) => ({
  ...state[ns],
});

class MultiBiddingContainer extends React.Component {
  constructor(p) {
    super(p);
    this.state = {};
  }

  render() {
    return (
      <MultiBidding
        {...this.props}
        {...this.state}
        changeEditValue={this.props.changeMultiBiddingEditValues}
        editValues={this.props.props.editValues}
      />
    );
  }
}

const changeMultiBiddingEditValues = ({
  state, values: {
    id, geo, value, column,
  },
}) => {
  const oldValues = { ...state[ns].editValues };
  if (oldValues[id + geo]) {
    const editValues = { ...oldValues, [id + geo]: { ...oldValues[id + geo], [column]: Number(value) } };
    return updateState(state, { editValues });
  }
  return state;
};

const actions = {
  changeMultiBiddingEditValues,
};

export default compose(MultiBiddingContainer, { props, actions });
