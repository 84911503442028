import { size } from 'lodash';
import React, {
  compose, namespace,
} from 'app/app';
import { isEmptyArray } from 'lib/lib';
import { canModifyApps } from 'lib/helpers/authUser';
import Campaign from 'models/Campaign';
import { getReportingResources } from 'app/graphql/utils/reporting';
import { filterKeys } from 'services/Templates/Applications/List/constants';
import { makeGetApplicationsQuery } from 'services/Templates/Applications/List/helper';
import ListFilterContainer from 'components/V2/List/ListFilter/ListFilterContainer';
import { ListEmptyState } from 'components/V2/EmptyState/EmptyState';
import List from 'services/Templates/Applications/List/List';
import { getListCaches } from './helper';

class ListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      containsExtraQuery: false,
    };
    this.listCaches = getListCaches(props.authUser.id);
  }

  getApplications = (query = {}) => {
    const { actions, authUser } = this.props;
    const [
      finalQuery,
      containsExtraQuery,
    ] = makeGetApplicationsQuery(
      this.listCaches,
      {
        ...query,
        accounts: [authUser.account],
      },
    );
    this.setState({
      containsExtraQuery,
    }, () => actions.getApplications(finalQuery));
  }

  componentDidMount() {
    this.getApplications();
  }

  render() {
    const { loadingApplications, applications } = this.props;
    const { containsExtraQuery } = this.state;
    if (
      !loadingApplications
      && !containsExtraQuery
      && isEmptyArray(applications)
    ) {
      return <ListEmptyState type="application" to="/campaigns/create/application" />;
    }

    const props = {
      ...this.props,
      ...this.listCaches,
      refreshList: this.getApplications,
    };

    const ctaProps = {};
    if (canModifyApps()) {
      ctaProps.cta = 'Add Application';
      ctaProps.to = '/campaigns/create/application';
    }

    return (
      <div className="views_applications_container">
        <ListFilterContainer {...props} {...ctaProps} filterKeys={filterKeys} />
        <List {...props} />
      </div>
    );
  }
}

const ns = 'data.applications.list';
const updateState = namespace(ns);
const props = (state) => ({
  ...state[ns],
  authUser: state.authUser,
});

const getApplications = (() => {
  const action = (query) => getReportingResources({ ...query, perPage: query.perPage || 10 })
    .then(({ data, total, ...rest }) => {
      if (size(data) > 0) {
        data.unshift({ ...total, isTotalRow: true });
      }
      return {
        ...rest,
        data,
      };
    });
  const end = ({ state, result }) => updateState(state, {
    applications: result.data,
    pagination: result.pagination,
    loadingApplications: false,
  });
  const start = ({ state, result }) => updateState(state, {
    loadingApplications: true,
    filters: {
      account: state.authUser.account,
      platform: result.platform,
      search: result.search,
    },
  });
  return { action, end, start };
})();

export const addCampaign = ({ values }) => {
  const campaign = new Campaign({
    account: { id: values.account },
  });
  campaign.setApplication(values.application);
  return {
    'views.campaigns.edit': {
      ...values,
      campaign,
    },
  };
};

export const addCreative = ({ values }) => ({
  'views.creatives.edit': {
    ...values,
    creative: values.creative,
  },
});

const actions = {
  addCampaign,
  addCreative,
  getApplications,
};

export default compose(ListContainer, { props, actions });
