import React from 'react';
import { compose, namespace } from '../../../app/app';
import Confirm from './ConfirmAction';
import './confirm.scss';

class ConfirmActionContainer extends React.Component {
  constructor(p) {
    super(p);
    this.state = {
      confirmLoading: false,
    };
  }

  onCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
    this.props.actions.close();
  }

  onConfirm = async () => {
    if (this.props.isAsyncConfirm) {
      this.setState({ confirmLoading: true });
      await this.props.onConfirm();
      this.setState({ confirmLoading: false });
    } else {
      this.props.onConfirm();
    }
    this.props.actions.close();
  }

  onConfirmAlternate = () => {
    this.props.onConfirmAlternate();
    this.props.actions.close();
  }

  render() {
    if (!this.props.onConfirm) {
      return null;
    }
    return (
      <Confirm
        {...this.props}
        onCancel={this.onCancel}
        onConfirm={this.onConfirm}
        onConfirmAlternate={this.onConfirmAlternate}
        confirmLoading={this.state.confirmLoading}
      />
    );
  }
}

const ns = 'components.modals.confirmAction';
const updateState = namespace(ns);
const props = (state) => state[ns];

const close = ({ state }) => updateState(state, {
  onCancel: null,
  onConfirm: null,
});

const actions = { close };

export default compose(ConfirmActionContainer, { actions, props });
export {
  ns,
};
