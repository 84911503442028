import classNames from 'classnames';
import React from 'react';
import { isAdminService, isAdvertiserService } from 'lib/serviceType';
import Asset from '../../../../../../models/Asset';
import Icon from '../../../../../../components/Icon/Icon';
import { isFileSizeAboveThreshold } from '../../../../../../lib/uploader';
import { getFileOrientation, prettifySize } from '../utils';
import ProgressBar from './ProgressBar';
import AssetLogBox from '../AssetLogBox';
import { ERROR_MSG, WARNING_MSG, ERRORS } from '../errors';
import './SingleAssetUploader.scss';
import Text from '../../../../../../components/Form/Text/TextContainer';
import Button from '../../../../../../components/Button/Button';
import errors from '../../../../../../components/Uploads/errors';

import { SEARCH_PARAM_ASSET_ID } from '../constant';

const {
  SAME_ASSET_EXISTS_UNDER_ANOTHER_ACCOUNT,
  SAME_ASSET_EXISTS_UNDER_THIS_APPLICATION,
} = errors;
const UNDER_SAME_APPLICATION = 'under-same-application';
const UNDER_ANOTHER_ACCOUNT = 'under-another-account';
const FILE_TYPE_IMAGE = 'image';
const FILE_TYPE_BUNDLE = 'bundle';
const FILE_TYPE_VIDEO = 'video';
const FILE_TYPE_UNKNOWN = 'unknown';
const WARNING_IMAGE_TYPE_REQUIRED = 'image-type-required';
const IMAGE_ASSET_TAGS = {
  banner: 'banner',
  other: 'other',
};
const creativeManagerLandingUrl = 'https://vungle.zendesk.com/hc/en-us/articles/360035348091';

const UPLOAD_PROGRESS = {
  [FILE_TYPE_VIDEO]: {
    max: 8,
    min: 2,
  },
  [FILE_TYPE_IMAGE]: {
    max: 12,
    min: 4,
  },
  [FILE_TYPE_UNKNOWN]: {
    max: 10,
    min: 4,
  },
  [FILE_TYPE_BUNDLE]: {
    max: 10,
    min: 4,
  },
};

function getFileType(file) {
  switch (file.type) {
    case 'application/zip':
    case 'application/x-zip-compressed':
      return FILE_TYPE_BUNDLE;
    case 'video/mp4':
      return FILE_TYPE_VIDEO;
    case 'image/png':
    case 'image/jpg':
    case 'image/jpeg':
    case 'image/gif':
      return FILE_TYPE_IMAGE;
    default:
      return FILE_TYPE_UNKNOWN;
  }
}

function getAssetTypeNameAndIcon(file) {
  switch (getFileType(file)) {
    case FILE_TYPE_BUNDLE:
      return {
        icon: <Icon.Game />,
        name: 'Playable',
      };
    case FILE_TYPE_VIDEO:
      return {
        icon: <Icon.Video />,
        name: 'Video',
      };
    case FILE_TYPE_IMAGE:
      return {
        icon: <Icon.Image />,
        name: 'Image',
      };
    case FILE_TYPE_UNKNOWN:
    default:
      return {
        icon: null,
        name: null,
      };
  }
}

function getWarningMessage(warning) {
  return WARNING_MSG[warning] || warning || 'Unexpected error occurred.';
}

function getErrorMessage(error) {
  return ERROR_MSG[error] || error || 'Unexpected error occurred.';
}

function isBundleType(file) {
  return getFileType(file) === FILE_TYPE_BUNDLE;
}

class SingleAssetUploader extends React.Component {
  constructor(props) {
    super(props);
    this.request = null;
    this.state = {
      assetType: null,
      assetId: null,
      duplicateType: null,
      uploadComment: '',
      isCommentSubmissionDisabled: true,
      latestUpdatedComment: '',
      height: '-',
      orientation: '-',
      percentage: 0,
      showImageAssetTypeSelection: false,
      errors: [],
      warnings: [],
      width: '-',
      isVungleBundle: false,
    };
  }

  progressInterval = null

  UNSAFE_componentWillMount() {
    // load the image in Image object to get dimension info before sending it to server
    this.img = new Image();
    this.img.onload = () => {
      this.setState({
        orientation: getFileOrientation(this.img),
        height: this.img.height,
        width: this.img.width,
      });
    };
    this.img.src = (window.URL || window.webkitURL).createObjectURL(this.props.file);
  }

  componentDidMount() {
    const { asset, file } = this.props;

    if (!file) {
      return;
    }

    if (asset) { // if asset is already present, then we are replacing a pending asset
      this.replaceFile();
      return;
    }

    // start uploading file right away if there is no user input required
    this.uploadFile();
  }

  // eslint-disable-next-line class-methods-use-this
  getImageAssetTypeOfSuggestedTags(tags) {
    if (tags.indexOf(IMAGE_ASSET_TAGS.banner) !== -1) {
      return IMAGE_ASSET_TAGS.banner;
    }
    return IMAGE_ASSET_TAGS.other;
  }

  async callUploadService(uploadAction) {
    const { file, onUploadStart } = this.props;

    if (isFileSizeAboveThreshold(file.size)) {
      this.setState({
        errors: [getErrorMessage(ERRORS.FILE_TOO_LARGE)],
        percentage: 100,
      });

      this.onUploadAsset({
        name: file.name,
        status: 'error',
      });
      return;
    }

    if (onUploadStart) {
      onUploadStart(file);
    }

    const fileType = getFileType(file);

    if (fileType === FILE_TYPE_UNKNOWN) {
      this.setState({
        errors: ['unknown-file-type'],
        percentage: 100,
      });
      return;
    }

    // fake progress that will imitate upload process
    this.updateProgress(UPLOAD_PROGRESS[fileType]);

    try {
      this.request = uploadAction();
      const result = await this.request;

      this.setState({
        percentage: 100,
        assetId: result.response.id,
        isVungleBundle: result.response.isVungleBundle,
      });

      if (fileType === FILE_TYPE_IMAGE) {
        // request user to select the image asset type (banner, other)
        this.showImageAssetTypeSelection();

        const suggestedTag = this.getImageAssetTypeOfSuggestedTags(result.response.tags);
        this.onImageAssetTypeSelect(suggestedTag);
      }

      this.onUploadAsset({
        name: file.name,
        status: 'success',
      });
    } catch (messages) {
      const errorMessages = [];
      const warningMessages = [];
      const getAssetExistErr = (id) => `Your asset already exists under Asset ID: ${id}`;
      messages?.forEach((msg) => {
        if (WARNING_MSG[msg]) {
          return warningMessages.push(msg);
        }
        try {
          const { code, body: { assetID } } = JSON.parse(msg);
          const isUnderAnotherAccount = code === SAME_ASSET_EXISTS_UNDER_ANOTHER_ACCOUNT;
          const isUnderSameApplication = code === SAME_ASSET_EXISTS_UNDER_THIS_APPLICATION;
          if (isUnderAnotherAccount || isUnderSameApplication) {
            this.setState({
              assetId: assetID,
              duplicateType: isUnderAnotherAccount ? UNDER_ANOTHER_ACCOUNT : UNDER_SAME_APPLICATION,
            });
            return errorMessages.push(getAssetExistErr(assetID));
          }
        } catch (err) {
          // Error doesn't matter in this case. Mostly msg is a plain string,
          // and when JSON.parse is applied to such msg, it definitely fails.
          // But, it's okay to proceed without handling the exception.
        }
        return errorMessages.push(msg);
      });

      const status = errorMessages.length ? 'error' : 'success';

      this.setState({
        errors: errorMessages,
        warnings: warningMessages,
        percentage: 100,
      });

      this.onUploadAsset({
        name: file.name,
        status,
      });
    } finally {
      this.clearUpdateProgressInterval();
    }
  }

  onUploadAsset = (obj) => {
    if (this.props.onUploadAsset) {
      this.props.onUploadAsset(obj);
    }
  }

  async replaceFile() {
    const { file, asset } = this.props;

    return this.callUploadService(() => Asset.replaceAsset(asset.get('id'), { file }));
  }

  async uploadFile() {
    const { application, file } = this.props;
    const body = {
      file,
      title: file.name,
      applicationID: application.get('id') || this.props.application.get('attrs.id'),
    };

    return this.callUploadService(() => Asset.uploadAsset(body));
  }

  clearUpdateProgressInterval() {
    if (this.progressInterval) {
      clearInterval(this.progressInterval);
      this.progressInterval = null;
    }
  }

  updateProgress(steps) {
    this.clearUpdateProgressInterval();

    this.progressInterval = setInterval(() => {
      const percentage = this.state.percentage + Math.floor(Math.random() * (steps.max - steps.min + 1)) + steps.min;
      if (percentage >= 80) {
        this.clearUpdateProgressInterval();
        return;
      }
      this.setState({ percentage: parseInt(percentage, 10) });
    }, 450);
  }

  /**
   * Shows image asset type select input and shows a warning.
   */
  showImageAssetTypeSelection() {
    this.setState({
      showImageAssetTypeSelection: true,
      warnings: [WARNING_IMAGE_TYPE_REQUIRED],
    });
  }

  imageAssetTypeUpdate = async () => {
    try {
      const result = await Asset.get(this.state.assetId);
      if (result.status === 200) {
        const asset = new Asset(result.response);
        if (this.state.assetType === IMAGE_ASSET_TAGS.other) {
          asset.set('tags', []);
        } else {
          asset.set('tags', [this.state.assetType]);
        }

        await Asset.save(asset);

        return;
      }

      this.setState({
        errors: [getErrorMessage()],
      });
    } catch (x) {
      this.setState({
        errors: [getErrorMessage()],
      });
    }
  }

  /**
   * Called when user selects the image type, banner or other.
   * Hides the warning and starts the upload process.
   * @param assetType
   */
  onImageAssetTypeSelect(newAssetType) {
    const { assetType } = this.state;
    this.setState({ assetType: newAssetType, warnings: [] });
    if (!assetType) {
      return;
    }

    this.imageAssetTypeUpdate();
  }

  getButtonConfig() {
    const buttonText = 'Click here to see it';
    const { assetId, duplicateType } = this.state;
    const { onSearchAsset } = this.props;
    if (isAdvertiserService() && duplicateType === UNDER_ANOTHER_ACCOUNT) return null;
    if (duplicateType === UNDER_SAME_APPLICATION) {
      return {
        text: buttonText,
        clickHandler: () => {
          if (onSearchAsset) {
            onSearchAsset(assetId);
          } else {
            window.open(`${window.location.origin}/assets?${SEARCH_PARAM_ASSET_ID}=${assetId}`);
          }
        },
      };
    }
    if (duplicateType === UNDER_ANOTHER_ACCOUNT) {
      return {
        text: buttonText,
        clickHandler: () => {
          window.open(`${window.location.origin}/assets?${SEARCH_PARAM_ASSET_ID}=${assetId}`);
        },
      };
    }
    return null;
  }

  onAssetCommentChange = (note) => {
    const { latestUpdatedComment } = this.state;
    const trimmedNote = note.trim();
    this.setState({
      uploadComment: trimmedNote,
      isCommentSubmissionDisabled: latestUpdatedComment === trimmedNote,
    });
  }

  onUpdateAssetComment = async () => {
    const {
      assetId, uploadComment,
    } = this.state;
    this.setState({ isCommentSubmissionDisabled: true });
    const asset = new Asset({ id: assetId });
    asset.set('upload_comments', uploadComment);
    const result = await Asset.saveComment(asset);
    if (result?.status === 200) {
      const { onCommentUpdate, onDiscardAsset } = this.props;
      this.setState({ latestUpdatedComment: uploadComment });
      [onCommentUpdate, onDiscardAsset].forEach((handler) => {
        if (typeof handler === 'function') handler();
      });
    }
  }

  render() {
    const { onDiscardAsset, file } = this.props;

    if (!file) {
      return null;
    }

    const {
      showImageAssetTypeSelection,
      errors: errorMessages,
      height,
      assetType,
      orientation,
      percentage,
      warnings,
      width,
      uploadComment,
    } = this.state;
    const hasError = errorMessages.length > 0;
    const hasWarning = warnings.length > 0;
    const showMaxLengthWarning = uploadComment.length > 256;
    let percentageOutput = `${percentage}%`;

    if (percentage === 100 && hasError) {
      percentageOutput = 'Error';
    }

    return (
      <div
        className={classNames({
          single_asset_uploader: true,
          has_error: hasError,
        })}
      >
        <div className="detail_container">
          <div className="asset_info">
            <div className="asset_type">
              <span className="icon">{getAssetTypeNameAndIcon(file).icon}</span>
              <span className="name">{getAssetTypeNameAndIcon(file).name}</span>
            </div>

            <span className="asset_name">{file.name}</span>
          </div>

          <div className="metrics">
            {showImageAssetTypeSelection
            && (
              <span className="metric">
                {assetType}
              </span>
            )}
            {orientation !== '-'
              && <span className="metric">{orientation}</span>}
            {width !== '-' && height !== '-'
            && (
              <span className="metric">
                {width}
                x
                {height}
              </span>
            )}
            <span className="metric">{prettifySize(file.size)}</span>
            <span className="metric">{percentageOutput}</span>
          </div>
          <div className="asset_upload_status">
            {(!hasError && percentage < 100)
              && (
                <div
                  className="uploading-box"
                  onClick={
                    () => {
                      if (this.request) {
                        this.request.cancel();
                      }
                      onDiscardAsset();
                    }
                  }
                >
                  <i className="material-icons">close</i>
                </div>
              )}
            {(!hasError && percentage === 100)
              && (
              <div className="success_box">
                <i className="material-icons success_icon">done</i>
                <i className="material-icons close_icon" onClick={onDiscardAsset}>close</i>
              </div>
              )}
            { hasError
              && (
              <div className="error_box" onClick={onDiscardAsset}>
                <i className="material-icons">close</i>
              </div>
              )}
          </div>
        </div>
        <div className="progress_container">
          <ProgressBar percentage={percentage} hasError={hasError} />
        </div>
        {(hasError || hasWarning)
          && (
          <div className="error_and_warning_list_container">
            {warnings.map((warning) => (
              <AssetLogBox key={warning} status="warning" message={getWarningMessage(warning)} />
            ))}
            {errorMessages.map((error) => (
              <AssetLogBox key={error} status="error" message={getErrorMessage(error)} buttonConfig={this.getButtonConfig(error)} />
            ))}
            <a className="help_link" href={creativeManagerLandingUrl} target="blank">
              More help here
              <Icon.OpenInNew />
            </a>
          </div>
          )}
        {!hasError
          && percentage === 100
          && isBundleType(this.props.file)
          && !this.state.isVungleBundle
          && (
          <div className="comment_container">
            <p className="comment_description">
              Playable (.zip format) has been submitted successfully, is being reviewed, and is unable to serve.
              {`${isAdminService() ? 'You can still assign to a creative. ' : ' '}`}
              Add any notes about
              <a className="link" href="https://support.vungle.com/hc/en-us/articles/360056671952" target="blank">
                App Store on Interaction (ASOI) preference
              </a>
              or anything else below.
              By default we will proceed with ASOI level aggressive for this Playable (.zip format). (256 characters maximum)
            </p>
            <Text
              value={uploadComment}
              className="asset_comments_box"
              noIcon
              placeholder="Optionally add notes for our internal team to be aware of for this Playable (.zip format)’s review…"
              onChange={this.onAssetCommentChange}
              hasError={showMaxLengthWarning}
              hideClearButton
            />
            {showMaxLengthWarning && (
              <p className="warning-input-text">256 characters maximum</p>
            )}
            <Button
              className="submit_comment_btn"
              onClick={this.onUpdateAssetComment}
              disabled={showMaxLengthWarning || this.state.isCommentSubmissionDisabled}
            >
              Sounds Good
            </Button>
          </div>
          )}
      </div>
    );
  }
}

export default SingleAssetUploader;
export { isBundleType };
