import React from 'react';
import { contains } from '../../lib/lib';
import Text from '../../components/Form/Text/TextContainer';

const Labels = ({
  labels, onSearch, alert = false, searchValue, hideCancel, includeSearch = true,
}) => (
  <div className="template__labels">
    {includeSearch && (
      <div className="input__search"><Text.Search value={searchValue} onChange={onSearch} /></div>
    )}
    <div className="labels">
      {labels.filter(({ label }) => contains(label, searchValue)).map(({ key, label, onClick }, index) => (
        <div key={key || index} className={`label ${alert ? 'alert' : ''}`} onClick={onClick}>
          <span>{label}</span>
          {!hideCancel
            && <i className="material-icons">cancel</i>}
        </div>
      ))}
    </div>
  </div>
);

export default Labels;
