import Cache from './Cache';
import {
  ASSET_METRIC_KEY,
  CREATIVE_QA_METRIC_KEY,
  CTRL_APPLICATION_METRIC_KEY,
  CTRL_CAMPAIGN_METRIC_KEY,
  GROW_APPLICATION_METRIC_KEY,
  GROW_CAMPAIGN_METRIC_KEY,
  GROW_CREATIVE_METRIC_KEY,
  CTRL_CREATIVE_METRIC_KEY,
} from './constant';

class MetricCache extends Cache {
  save = (metrics, cb) => {
    if (Array.isArray(metrics)) {
      localStorage.setItem(this.storageKey, JSON.stringify(metrics));
      if (typeof cb === 'function') {
        cb();
      }
    }
  }

  get = () => {
    let metrics = this.defaultValue;
    try {
      const metricsStr = localStorage.getItem(this.storageKey);
      const metricsArr = JSON.parse(metricsStr);
      if (Array.isArray(metricsArr)) {
        metrics = metricsArr;
      }
    } catch (e) {
      // do nothing
    }
    return metrics;
  }
}

export default MetricCache;

export function getGrowApplicationMetricCache(userId) {
  return new MetricCache(userId, GROW_APPLICATION_METRIC_KEY, null);
}

export function getCtrlApplicationMetricCache(userId) {
  return new MetricCache(userId, CTRL_APPLICATION_METRIC_KEY, null);
}

export function getGrowCampaignMetricCache(userId) {
  return new MetricCache(userId, GROW_CAMPAIGN_METRIC_KEY, null);
}

export function getCtrlCampaignMetricCache(userId) {
  return new MetricCache(userId, CTRL_CAMPAIGN_METRIC_KEY, null);
}

export function getGrowCreativeMetricCache(userId) {
  return new MetricCache(userId, GROW_CREATIVE_METRIC_KEY, null);
}

export function getCtrlCreativeMetricCache(userId) {
  return new MetricCache(userId, CTRL_CREATIVE_METRIC_KEY, null);
}

export function getAssetMetricCache(userId, defaultValue) {
  return new MetricCache(userId, ASSET_METRIC_KEY, defaultValue);
}

export function getCreativeQAMetricCache(userId, defaultValue) {
  return new MetricCache(userId, CREATIVE_QA_METRIC_KEY, defaultValue);
}
