import React, {
  compose, Switch, Route, Redirect,
} from '../../../../app/app';
import Edit from './member/EditContainer';
import List from './list/ListContainer';
import Invite from './invite/InviteContainer';
import './index.scss';

const baseRoute = '/team';

const Team = () => (
  <div className="views__team">
    <Switch>
      <Route exact path={baseRoute} component={List} />
      <Route exact path={`${baseRoute}/invite`} component={Invite} />
      <Route exact path={`${baseRoute}/:id`} component={Edit} />
      <Redirect to={baseRoute} />
    </Switch>
  </div>
);

export default compose(Team);
