import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import ServingStatus from '../../../../Assets/ServingStatus/ServingStatus';
import MultiPanel from './MultiPanel';

const renderItemLabel = (prefixCls, { id }) => (
  <div className={`${prefixCls}-item`}>
    <ServingStatus status={id} />
  </div>
);

const CreativeQAStatusPanel = ({
  data,
  prefixCls,
  defaultCheckedNodes,
  onVisibleChange,
  onChange,
  height,
}) => {
  const handleChange = useCallback((_selectedStatuses, isAllSelected) => {
    onVisibleChange(false);
    onChange(_selectedStatuses, isAllSelected);
  }, [onVisibleChange, onChange]);

  return (
    <MultiPanel
      height={height}
      data={data}
      renderItemLabel={(item) => renderItemLabel(prefixCls, item)}
      defaultCheckedKeys={defaultCheckedNodes.map(({ id }) => id)}
      onVisibleChange={onVisibleChange}
      onChange={handleChange}
    />
  );
};

CreativeQAStatusPanel.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  height: PropTypes.number,
  prefixCls: PropTypes.string,
  defaultCheckedNodes: PropTypes.arrayOf(PropTypes.any),
  onVisibleChange: PropTypes.func,
  onChange: PropTypes.func,
};

CreativeQAStatusPanel.defaultProps = {
  data: [],
  height: 275,
  prefixCls: '',
  defaultCheckedNodes: [],
  onVisibleChange: noop,
  onChange: noop,
};

export default React.memo(CreativeQAStatusPanel);
