import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { initializeDates, selections } from '../../../../lib/date';
import Dropdown from '../../Dropdown/Dropdown';
import DateRangePicker from '../../DatePicker/DateRangePicker';
import Filter from '../../Filter/Filter';

const formatDateAsUTC = (dateString) => dateString && `${dateString} 00:00:00 Z`;

function initDateRange(defaultCheckedNodes) {
  if (defaultCheckedNodes) {
    return defaultCheckedNodes;
  }
  return {
    quickPickValue: selections.LAST_7_DAYS.value,
    start: selections.LAST_7_DAYS.value,
    end: 0,
  };
}

function DatePickerFilter({
  onChange,
  onRemove,
  visible,
  onVisibleChange,
  defaultCheckedNodes,
}) {
  const [checkedDateRange, setCheckedDateRange] = React.useState(initDateRange(defaultCheckedNodes));

  const onApply = React.useCallback((dateRange, initial) => {
    if (!initial) {
      onChange(dateRange);
      setCheckedDateRange(dateRange);
      onVisibleChange(false);
    }
  }, [onChange, onVisibleChange]);

  const getFormatDate = React.useCallback(() => {
    const { quickPickValue = false, startString, endString } = checkedDateRange;
    const { displayedDateRange } = initializeDates(
      quickPickValue !== selections.CUSTOM.value && quickPickValue,
      formatDateAsUTC(startString),
      formatDateAsUTC(endString),
    );
    return displayedDateRange;
  }, [checkedDateRange]);

  const renderOverlay = React.useCallback(() => (
    <DateRangePicker
      onDateRangeChange={onApply}
      startDateString={formatDateAsUTC(checkedDateRange.startString)}
      endDateString={formatDateAsUTC(checkedDateRange.endString)}
      quickPickValue={checkedDateRange.quickPickValue}
    />
  ), [checkedDateRange, onApply]);

  return (
    <Dropdown
      fixed
      renderOverlay={renderOverlay}
      visible={visible}
      onVisibleChange={onVisibleChange}
    >
      <Filter
        type="date"
        open={visible}
        onClickIcon={onRemove}
      >
        {getFormatDate()}
      </Filter>
    </Dropdown>
  );
}

DatePickerFilter.propTypes = {
  visible: PropTypes.bool.isRequired,
  onVisibleChange: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  defaultCheckedNodes: PropTypes.objectOf(PropTypes.any),
};

DatePickerFilter.defaultProps = {
  onChange: noop,
  onRemove: noop,
  defaultCheckedNodes: null,
};

export default React.memo(DatePickerFilter);
