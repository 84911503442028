import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Tab from './Tab/Tab';
import './tabs.scss';

const classPrefix = 'v2_component_tabs';

const Tabs = ({
  className, columns, onClick, extra,
}) => (
  <div className={classnames(classPrefix)}>
    {columns.map((col) => (
      <Tab
        className={className}
        key={`${col.key}`}
        {...col}
        onClick={() => onClick(col)}
      />
    ))}
    {extra}
  </div>
);

Tabs.propTypes = {
  /**
   * Class name string.
   */
  className: PropTypes.string,
  /**
   * Render tab data source.
   */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
      selected: PropTypes.bool,
    }),
  ).isRequired,
  /**
   * Click tab event.
   */
  onClick: PropTypes.func,
  /**
   * Render extra component.
   */
  extra: PropTypes.element,
};

Tabs.defaultProps = {
  className: null,
  onClick: () => {},
  extra: null,
};

export default Tabs;
