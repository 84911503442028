import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { toDataURL } from 'qrcode';
import Popover from '../../Popover/Popover';
import Icon from '../../Icon/Icon';
import Copy from '../../V2/Copy/Copy';
import Link from '../../Link/Link';
import './QRCode.scss';

const QR_CODE_OPTIONS = {
  margin: 0,
  padding: 0,
  width: 140,
  height: 140,
};

const QRCode = ({
  modalRoot,
  isVisible,
  creativeId,
  hide,
  adjustLeftPosition,
  adjustTopPosition,
}) => {
  const [qrCodeUrl, setQRCodeUrl] = useState(null);

  useEffect(() => {
    const generateQRCode = async () => {
      const url = await toDataURL(creativeId, QR_CODE_OPTIONS);
      setQRCodeUrl(url);
    };

    if (creativeId) generateQRCode();
  }, [creativeId]);

  if (modalRoot && isVisible && creativeId) {
    return (
      <Popover
        className="QRCode-popover"
        modalRoot={modalRoot}
        isOpen={isVisible}
        isTooltip={false}
        adjustLeftPosition={adjustLeftPosition}
        adjustTopPosition={adjustTopPosition}
        adjustToScreen
        contentWidth={300}
        contentHeight={358}
        hide={noop}
        onClickOutside={hide}
      >
        <div
          className="QRCode"
          onClick={(e) => {
            e.nativeEvent.stopImmediatePropagation();
            e.stopPropagation();
          }}
        >
          <div className="QRCode__header">
            <div className="QRCode__header__title">Creative ID</div>
            <Icon.Close onClick={hide} />
          </div>
          <div className="QRCode__body">
            <Copy value={creativeId} className="QRCode__body__creative-id" />
            {qrCodeUrl ? <img className="QRCode__body__qr-code" src={qrCodeUrl} alt="QR Code" /> : <p className="QRCode__body__qr-code">Loading QR code...</p>}
            <div className="QRCode__body__description">
              Scan QR Code with
              {' '}
              <Link
                external
                to="https://vungle.atlassian.net/wiki/spaces/TRE/pages/3076096001/Creative+Testing+Tool+iDSP+fullscreen"
                className="body__link"
              >
                Creative Testing Tool
              </Link>
              {' '}
              to view Creative directly on a mobile device.
            </div>
          </div>
        </div>
      </Popover>
    );
  }
  return null;
};

QRCode.propTypes = {
  modalRoot: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }).isRequired,
  isVisible: PropTypes.bool.isRequired,
  creativeId: PropTypes.string.isRequired,
  hide: PropTypes.func.isRequired,
  adjustLeftPosition: PropTypes.number,
  adjustTopPosition: PropTypes.number,
};

QRCode.defaultProps = {
  adjustLeftPosition: 0,
  adjustTopPosition: 0,
};

export default QRCode;
