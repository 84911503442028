import { uniq } from 'lodash';
import {
  bannerFormats,
  FORMAT_TYPE_BANNER,
  FORMAT_TYPE_MREC,
  FORMAT_TYPE_NATIVE,
  fullscreenFormats,
  mrecFormats,
  nativeFormats,
} from 'app/constants/campaign';

export const updateCreative = ({
  campaign,
  creatives,
  updateSkcid,
  newSelectedIds,
  selectedCreativeIds,
  setSelectedCreativeIds,
  isSetB = false,
}) => {
  const allIds = uniq(selectedCreativeIds.concat(newSelectedIds));

  allIds.forEach((id) => {
    let shouldBeSelected = true;
    if (!newSelectedIds.includes(id) && selectedCreativeIds.includes(id)) {
      shouldBeSelected = false;
    }
    const operatingCreative = creatives.find((creative) => creative.get('id') === id);
    if (operatingCreative) {
      if (campaign.get('is_skadnetwork_enabled')) {
        updateSkcid(operatingCreative, shouldBeSelected);
      }
      campaign.toggleCreative(operatingCreative, shouldBeSelected, true, isSetB);
    }
  });

  const ids = creatives
    .filter((creative) => campaign.hasActiveCreative(creative, isSetB))
    .map((creative) => creative.get('id'));

  setSelectedCreativeIds(ids);
};

export const getNativeCreatives = (creatives) => creatives.filter((c) => c.get('template.name') === 'native');

export const getVideoFormat = (format, orientation) => {
  if (fullscreenFormats.includes(format)) {
    return `${format}-${orientation}`;
  }
  if (bannerFormats.includes(format)) {
    return FORMAT_TYPE_BANNER;
  }
  if (mrecFormats.includes(format)) {
    return FORMAT_TYPE_MREC;
  }
  if (nativeFormats.includes(format)) {
    return FORMAT_TYPE_NATIVE;
  }
  return '';
};

export const getCreativeVideoFormat = (creative) => getVideoFormat(creative.raw('template.format'), creative.get('video.orientation'));

export const canAttach = ({
  creative,
  selectedCreativeIds,
  isSetB = false,
  enabledFormats,
  selectedFormats,
}) => {
  const format = getCreativeVideoFormat(creative);
  if (selectedCreativeIds.includes(creative.get('id'))) {
    return true;
  }

  if (isSetB && !enabledFormats.includes(format)) {
    return false;
  }

  return !selectedFormats.includes(format);
};

export const formatCreativesA = ({
  creatives,
  selectedCreativeIds,
}) => {
  const selectedCreatives = creatives.filter((c) => selectedCreativeIds.includes(c.get('id')));
  const selectedFormats = selectedCreatives.map(getCreativeVideoFormat);

  creatives.forEach((creative) => {
    const enabled = canAttach({
      creative,
      selectedCreativeIds,
      selectedFormats,
    });
    creative.set('enable_attach_a', enabled);
  });
};

export const formatCreativesB = ({
  creatives,
  selectedCreativeIds,
  selectedCreativeBIds,
}) => {
  const selectedCreativesA = creatives.filter((c) => selectedCreativeIds.includes(c.get('id')));
  const enabledFormats = selectedCreativesA.map(getCreativeVideoFormat);
  const selectedCreativesB = creatives.filter((c) => selectedCreativeBIds.includes(c.get('id')));
  const selectedFormats = selectedCreativesB.map(getCreativeVideoFormat);

  creatives.forEach((creative) => {
    const enabled = canAttach({
      creative,
      selectedCreativeIds: selectedCreativeBIds,
      isSetB: true,
      enabledFormats,
      selectedFormats,
    });
    creative.set('enable_attach_b', enabled);
  });
};

export const getEmptyFormatInfosByCreativeType = (creativeType) => {
  const formatGroup = {
    fullscreen: [
      {
        key: 'multi_page_landscape',
        title: 'Full Screen (Multi-Page) - Landscape:',
        format: 'multi_page_fullscreen-landscape',
        creativeTypeName: 'Full Screen (Multi-Page)',
        creative: null,
      },
      {
        key: 'multi_page_portrait',
        title: 'Full Screen (Multi-Page) - Portrait:',
        format: 'multi_page_fullscreen-portrait',
        creativeTypeName: 'Full Screen (Multi-Page)',
        creative: null,
      },
      {
        key: 'single_page_landscape',
        title: 'Full Screen (Single-Page) - Landscape:',
        format: 'single_page_fullscreen-landscape',
        creativeTypeName: 'Full Screen (Single-Page)',
        creative: null,
      },
      {
        key: 'single_page_portrait',
        title: 'Full Screen (Single-Page) - Portrait:',
        format: 'single_page_fullscreen-portrait',
        creativeTypeName: 'Full Screen (Single-Page)',
        creative: null,
      },
    ],
    banner: [
      {
        key: 'banner',
        title: 'Banner:',
        format: FORMAT_TYPE_BANNER,
        creativeTypeName: 'Banner',
        creative: null,
      },
    ],
    mrec: [
      {
        key: 'mrec',
        title: 'MREC:',
        format: FORMAT_TYPE_MREC,
        creativeTypeName: 'MREC',
        creative: null,
      },
    ],
    native: [
      {
        key: 'native',
        title: 'Native:',
        format: FORMAT_TYPE_NATIVE,
        creativeTypeName: 'Native',
        creative: null,
      },
    ],
  };
  return formatGroup[creativeType];
};

export const getFormatInfosByCreativeType = ({
  creativeType,
  creatives,
  selectedCreativeIds,
  isSetB = false,
  enabledCreativeIds,
}) => {
  const formatInfos = getEmptyFormatInfosByCreativeType(creativeType);
  const selectedCreatives = creatives.filter((c) => selectedCreativeIds.includes(c.get('id')));

  return formatInfos.map((formatInfo) => ({
    ...formatInfo,
    available: (() => {
      if (!isSetB) {
        return true;
      }
      const availableVideoFormats = creatives.filter((c) => enabledCreativeIds.includes(c.get('id'))).map(getCreativeVideoFormat);
      return availableVideoFormats.includes(formatInfo.format);
    })(),
    creative: selectedCreatives.find((c) => formatInfo.format === getCreativeVideoFormat(c)),
  }));
};

export const getAvailableCreativeBIds = ({
  creatives,
  selectedCreativeIds,
  selectedCreativeBIds,
}) => {
  const creativeFormats = creatives
    .filter((c) => selectedCreativeIds.includes(c.get('id')))
    .map(getCreativeVideoFormat);
  return creatives
    .filter((c) => selectedCreativeBIds.includes(c.get('id')))
    .filter((c) => creativeFormats.includes(getCreativeVideoFormat(c)))
    .map((c) => c.get('id'));
};
