import React, { useMemo } from 'react';
import { firstLetterUpper } from '../../../../lib/lib';

const visualQACheckBoxData = [{
  label: 'Asset works end to end as expected',
  notWorkingLabel: 'Asset DOES NOT work end to end as expected',
  value: 'endToEnd',
}, {
  label: 'Landscape works properly',
  notWorkingLabel: 'Landscape DOES NOT work properly',
  value: 'landscape',
}, {
  label: 'Portrait works properly',
  notWorkingLabel: 'Portrait DOES NOT work properly',
  value: 'portrait',
}, {
  label: 'No close button shows',
  notWorkingLabel: 'Close button shows. Remove please.',
  value: 'noCloseButton',
}];

const functionalityCheckBoxData = [{
  label: 'Ad is playable End to End',
  notWorkingLabel: 'Ad is NOT playable End to End',
  value: 'endToEnd',
}, {
  label: 'Pause & Resume is working correctly (on privacy, on close, and ad on background)',
  notWorkingLabel: 'Pause & Resume is NOT working correctly (on privacy, on close, and ad on background)',
  value: 'pauseAndResume',
}, {
  label: 'Audio experience is consistent between preview and device testing',
  notWorkingLabel: 'Audio experience is NOT consistent between preview and device testing',
  value: 'audio',
}];

const contentCheckBoxData = [{
  label: 'Web elements are fully responsive and/or there are no resolution issues',
  notWorkingLabel: 'Web elements are NOT fully responsive and/or there are no resolution issues',
  value: 'responsive',
}, {
  label: 'No blank screen and unit is playable',
  notWorkingLabel: 'There is a blank screen and the unit is NOT playable',
  value: 'playable',
}, {
  label: 'No Rendering issues, elements, loading, or delays in load time',
  notWorkingLabel: 'There are rendering issues, elements, loading, or delays in load time',
  value: 'performance',
}];

const asoiCheckBoxData = [{
  label: 'ASOI Aggressive is working',
  value: 'aggressive',
}, {
  label: 'ASOI Off is working',
  value: 'off',
}, {
  label: 'ASOI On Complete is working',
  value: 'complete',
}];

const historyActions = {
  statusChange: 'status change',
  replace: 'replace asset',
  feedback: 'feedback',
  rename: 'rename',
  uploadComments: 'update upload comments',
};

const inputMaxLength = 256;

const ASOI_SUPPORT_LINK = 'https://support.vungle.com/hc/en-us/articles/360056671952-Call-the-Vungle-SDK-and-ASO';

const defaultFullQATip = `Please make sure to perform all the tests above. 
Based on selections above we’ll default to a certain status, 
however you may change it based on the unique scenario.`;

const failedAssetTip = `This asset is failing our Full QA testing. 
Please address the problems listed above and resubmit the asset for review.`;

const getAsoiTip = (asoi) => (
  <>
    This Playable Asset works as expected. The ASOI
    {' '}
    {firstLetterUpper(asoi)}
    {' '}
    setting will be applied when delivering any Creative containing this asset.
    <a href={ASOI_SUPPORT_LINK} target="blank"> Learn more about ASOI here. </a>
  </>
);

const fullQABaseContentTest = (content) => Object.values(content || {}).filter((val) => typeof val === 'boolean').every((val) => val);

const useFullQATestResult = (finalStatus, fullQAState) => useMemo(() => {
  if (!finalStatus) return defaultFullQATip;
  const { asoi, functionality, content } = fullQAState;
  const passBaseTest = fullQABaseContentTest(functionality) && fullQABaseContentTest(content);
  if (!passBaseTest) return failedAssetTip;
  const bestAsoi = Object.keys(asoi || {}).filter((key) => typeof asoi[key] === 'boolean' && asoi[key])[0];
  return bestAsoi ? getAsoiTip(bestAsoi) : failedAssetTip;
}, [finalStatus, fullQAState]);

export {
  functionalityCheckBoxData,
  contentCheckBoxData,
  asoiCheckBoxData,
  visualQACheckBoxData,
  historyActions,
  inputMaxLength,
  failedAssetTip,
  getAsoiTip,
  defaultFullQATip,
  useFullQATestResult,
};
