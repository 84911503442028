import React from 'react';
import { F } from '../../../app/app';
import Checkbox from '../../Form/Checkbox/CheckboxContainer';
import Radio from '../../Form/Radio/Radio';
import Group from '../../../templates/input-group/InputGroupContainer';
import IOSSelector from '../../../templates/IOSSelector/IOSSelectorContainer';

const { Field } = Group;

const DeviceModelTargeting = ({ campaign }) => (
  <F>
    <Field name="Device Model Targeting" description="Target specific Device Models">
      <div>
        <Radio
          name="targeting_device_models.type"
          value={campaign.get('targeting.device_models.type')}
          onChange={(v) => campaign.set('targeting.device_models.type', v)}
        >
          <Radio.Option value="none" label="Do not target" />
          <Radio.Option value="allow" label="Allow" />
          <Radio.Option value="deny" label="Deny" />
        </Radio>
      </div>
    </Field>
    {campaign.isTargetingDevices() && (
    <F>
      <Field name="Target All New Makes/Models" description="Opt-in to allow or deny new devices as they roll out from Apple">
        <Checkbox
          checked={campaign.get('targeting.device_models.target_new_tablet_models')}
          onChange={(v) => campaign.set('targeting.device_models.target_new_tablet_models', v)}
          label="iPad"
        />
        <Checkbox
          checked={campaign.get('targeting.device_models.target_new_phone_models')}
          onChange={(v) => campaign.set('targeting.device_models.target_new_phone_models', v)}
          label="iPhone"
        />
      </Field>
      <IOSSelector
        resource={campaign}
        selectedModels={campaign.get('targeting.device_models.type') === 'allow'
          ? campaign.get('targeting.device_models.device_whitelist')
          : campaign.get('targeting.device_models.device_blacklist')}
      />
    </F>
    )}
  </F>
);

export default DeviceModelTargeting;
