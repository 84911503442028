import React from 'react';
import classNames from 'classnames';
import {
  isDirty,
  isHigherThanMax,
  isLowerThanMin,
} from 'lib/validation';
import ErrorsCollapse from 'components/V2/Collapse/ErrorsCollapse/ErrorsCollapse';
import Text, {
  Search, Currency, NameValidation, PercentMultiplier,
} from '../Text/TextContainer';
import './validatedText.scss';

export const textTypes = {
  Currency, Search, NameValidation, PercentMultiplier,
};

const getSelectedValue = (disabled, disabledText, value) => {
  if (disabled) {
    return !disabledText ? '' : disabledText;
  }
  return value;
};

/**
 * Set limits, rangeErrors, and ensure numericValue == value
 */
const ValidatedText = ({
  textType,
  isValid,
  extraLabel,
  numeric,
  value,
  // will be overriden if using limits & rangeErrors
  validationMsg,
  validationErrors,
  defaultMsg,
  disabled,
  disabledText,
  limits,
  rangeErrors,
  numericValue,
  ...props
}) => {
  const TextTag = textTypes[textType] || Text;
  const shouldUseRangeValidation = limits && rangeErrors;
  let appliedIsValid = isValid;
  let appliedValidationMsg = validationMsg;

  if (shouldUseRangeValidation) {
    appliedIsValid = true;
    const { min, max } = limits || {};
    const { lowerBoundMsg, upperBoundMsg } = rangeErrors || {};

    if (isDirty(value) && isLowerThanMin(min, numericValue)) {
      appliedValidationMsg = lowerBoundMsg;
      appliedIsValid = false;
    }
    if (isDirty(value) && isHigherThanMax(max, numericValue)) {
      appliedValidationMsg = upperBoundMsg;
      appliedIsValid = false;
    }
  }

  return (
    <div className={`components__form__text__currency_validator${!appliedIsValid ? '--invalid' : ''}`}>
      <TextTag
        {...props}
        noIcon
        numeric={numeric}
        extraLabel={!appliedIsValid ? (
          <span className={classNames('material-icons', 'invalid_icon')}>
            error
          </span>
        ) : extraLabel}
        value={getSelectedValue(disabled, disabledText, value)}
        disabled={disabled}
      />
      {appliedIsValid && defaultMsg && (
        <p className="p4 default_text">{defaultMsg}</p>
      )}
      {!appliedIsValid && appliedValidationMsg && (
        <p className="p4 invalid_text">{appliedValidationMsg}</p>
      )}
      {!appliedIsValid && validationErrors && (
        <ErrorsCollapse separator=", " errors={validationErrors} />
      )}
    </div>
  );
};

export default ValidatedText;
