import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { isAdminService } from 'lib/serviceType';
import Account from 'models/Account';
import { get, remove } from '../../../lib/session';
import Campaign from '../../../models/Campaign';
import FiltersCache, { isFiltersEqual } from '../../../lib/cache/FiltersCache';
import { reportsExport } from '../../../app/graphql/utils/reporting';
import { REPORTS_CACHE_KEY } from '../../../lib/cache/constant';
import triggerConfirm from '../../../components/Modals/ConfirmAction/triggerConfirm';
import Filters from './Filters/Filters';
import Trend from './Trend/Trend';
import Empty from './Empty/Empty';
import {
  combineRequestBody,
  getCacheValue,
  getDefaultCacheValues,
  initCompareFilters,
  setCacheValue,
} from './helper';
import DataBreakdown, { returnInitMetrics } from './DataBreakdown/DataBreakdown';
import {
  COMBINE_KEY,
  COMPARE_KEY,
  DATE_KEY,
  REPORTS_KEY_REFRESH,
  REPORTS_KEY_RESET,
} from './constant';
import './reports.scss';
import dataTabs from '../../../components/V2/Report/data/Tabs';

const classPrefix = 'v2_pages_reports';

const defaultTabKey = 'application';

const initTableData = {
  data: [], total: {}, tabKey: defaultTabKey,
};

const initialTabs = dataTabs.map((i) => ({
  ...i,
  selected: i.key === defaultTabKey,
  padding: '0 14px',
}));

const Reports = ({ history, account }) => {
  const trendRef = useRef();
  const dataBreakdownRef = useRef();
  const isFirstLoadRef = useRef(true);

  const authUser = useSelector((state) => state.authUser);
  const accountModel = new Account(authUser);
  const defaultCacheValues = getDefaultCacheValues(accountModel);
  const cache = useMemo(() => new FiltersCache(authUser.id, REPORTS_CACHE_KEY, defaultCacheValues), [authUser.id, defaultCacheValues]);

  const clearCache = useCallback(() => cache.save(defaultCacheValues), [cache, defaultCacheValues]);

  if (history.action === 'PUSH' && isFirstLoadRef.current) {
    if (get('cache')) {
      remove('cache');
    } else {
      clearCache();
    }
  }
  isFirstLoadRef.current = false;

  const [dataEmpty, setDataEmpty] = useState(false);
  const [checkedDate, setCheckedDate] = useState(getCacheValue(cache, DATE_KEY));
  const [checkedCombine, setCheckedCombine] = useState(getCacheValue(cache, COMBINE_KEY));
  const [checkedCompare, setCheckedCompare] = useState(getCacheValue(cache, COMPARE_KEY));
  const [tabs, setTabs] = useState(initialTabs);
  const currentTab = tabs.find((i) => i.selected);

  const onLoadReport = async () => {
    dataBreakdownRef.current.onLoadTableData();
    // Only wait until trend request response back.
    await trendRef.current.onLoadChartData();
  };

  const getCampaigns = useCallback(async () => {
    const response = await Campaign.getAll({ account: isAdminService() ? account : authUser.account });
    setDataEmpty(response.pagination.total === 0);
  }, [authUser.account, account]);

  const exportReports = useCallback(async (checkedKeys) => {
    const metrics = returnInitMetrics(authUser.id, { key: 'campaign' });
    const body = combineRequestBody({
      checkedDate,
      checkedCompare,
      checkedCombine,
      dimensions: checkedKeys,
      metrics: metrics.map((metric) => metric.key),
    });
    if (isAdminService()) {
      body.accounts = [account];
    }
    const resp = await reportsExport(body);
    if (checkedKeys.length === 1) window.open(resp.location, '_blank', 'noopener');
  }, [checkedDate, checkedCombine, checkedCompare, authUser.id, account]);

  const onExport = useCallback(async (_, checkedKeys) => {
    if (checkedKeys.length > 1) {
      triggerConfirm({
        type: 'SEND_EMAIL_FOR_LARGE_FILE_EXPORT_CONFIRM_ACTION',
        header: 'Large File Size',
        cancelText: 'Cancel',
        confirmText: 'Send Email',
        // eslint-disable-next-line react/display-name
        message: () => (
          <span>
            {'Your report is too large to download. Would you like it emailed to you when it\'s'}
            <br />
            ready?
          </span>
        ),
        onConfirm: () => exportReports(checkedKeys),
      });
    } else {
      exportReports(checkedKeys);
    }
  }, [exportReports]);

  const onOptsChange = useCallback((action) => {
    if (REPORTS_KEY_RESET === action.key) {
      clearCache();
      setCheckedDate(defaultCacheValues[DATE_KEY]);
      setCheckedCompare(defaultCacheValues[COMPARE_KEY]);
      setCheckedCombine(defaultCacheValues[COMBINE_KEY]);
    }
    if (REPORTS_KEY_REFRESH === action.key) {
      onLoadReport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCompareChange = useCallback((e) => {
    setCheckedCompare(e);
    setCacheValue(cache, COMPARE_KEY, e);
  }, [cache]);

  const onCombineChange = useCallback((e) => {
    const prevCacheValue = getCacheValue(cache, COMBINE_KEY);
    setCacheValue(cache, COMBINE_KEY, e);
    if (!isFiltersEqual(prevCacheValue, e)) {
      setCheckedCombine(e);
    }
  }, [cache]);

  const onDateChange = useCallback((e) => {
    setCheckedDate(e);
    setCacheValue(cache, DATE_KEY, e);
  }, [cache]);

  useEffect(() => {
    getCampaigns();
  }, [getCampaigns]);

  useEffect(() => {
    window.addEventListener('beforeunload', clearCache);
    return () => window.removeEventListener('beforeunload', clearCache);
  }, [clearCache]);

  return (
    <div className={classPrefix}>
      <Filters
        checkedDate={checkedDate}
        checkedFilters={checkedCombine}
        onDateChange={onDateChange}
        onCombineChange={onCombineChange}
        onOperation={onOptsChange}
        onExport={onExport}
        account={accountModel}
      />
      <div className="content">
        <Trend
          ref={trendRef}
          account={account}
          className={classnames({
            [`${classPrefix}-empty`]: dataEmpty,
          })}
          compareFilters={initCompareFilters()}
          checkedDate={checkedDate}
          checkedCombine={checkedCombine}
          checkedCompare={checkedCompare}
          onCompareChange={(key, data) => onCompareChange({ key, data })}
          onCompareClear={() => onCompareChange(defaultCacheValues[COMPARE_KEY])}
          currentTab={currentTab}
        />
        <DataBreakdown
          account={account}
          className={classnames({
            [`${classPrefix}-empty`]: dataEmpty,
          })}
          checkedDate={checkedDate}
          checkedCombine={checkedCombine}
          tabs={tabs}
          currentTab={currentTab}
          setTabs={setTabs}
          initTableData={initTableData}
          ref={dataBreakdownRef}
        />
        {dataEmpty && <Empty />}
      </div>
    </div>
  );
};

export default Reports;
