import { size } from 'lodash';
import React from 'react';
import { getAppConfig } from 'lib/helpers';
import { isEmptyArray, buildQueryObject } from 'lib/lib';
import { compose, namespace, splash } from 'app/app';
import { getReportingResources } from 'app/graphql/utils/reporting';
import {
  errNotEnoughAvailableSkanIDs,
  errTooManyUnarchivedUnpausedSkanCampaigns,
} from 'app/graphql/errorCodes/campaign';
import { getCampaignListFilterKeys } from 'lib/capabilities/campaigns';
import { ListEmptyState } from 'components/V2/EmptyState/EmptyState';
import ListFilterContainer from 'components/V2/List/ListFilter/ListFilterContainer';
import { makeGetCampaignsQuery } from 'services/Templates/Campaigns/List/helper';
import List from 'services/Templates/Campaigns/List/List';
import { getListCaches } from './helper';
import triggerSkanAlert from '../../../../../components/Modals/SkanAlert/triggerSkanAlert';

const ns = 'data.campaigns.list';
const updateState = namespace(ns);

class CampaignsListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      containsExtraQuery: false,
    };
    this.listCaches = getListCaches(props.authUser.id);
  }

  getCampaigns = (query = {}) => {
    const { actions, authUser } = this.props;
    const [
      finalQuery,
      containsExtraQuery,
    ] = makeGetCampaignsQuery(
      this.listCaches,
      {
        ...query,
        account: authUser.account,
      },
    );
    this.setState({
      containsExtraQuery,
    }, () => actions.getCampaigns(finalQuery));
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.refreshCampaigns) {
      this.getCampaigns(buildQueryObject(props.location.search));
    }
  }

  componentDidUpdate() {
    if (this.props.showSkanAlert) {
      this.props.actions.deactivateModal();
      triggerSkanAlert();
    }
  }

  componentDidMount() {
    this.getCampaigns();
  }

  render() {
    const { account } = getAppConfig();
    const { campaigns, loadingCampaigns } = this.props;
    const { containsExtraQuery } = this.state;
    if (
      !loadingCampaigns
      && !containsExtraQuery
      && isEmptyArray(campaigns)
    ) {
      return <ListEmptyState type="campaign" to="/campaigns/create" />;
    }

    const props = {
      ...this.props,
      ...this.listCaches,
      refreshList: this.getCampaigns,
    };

    const filterKeys = getCampaignListFilterKeys(account);

    return (
      <div className="views__campaigns__list">
        <ListFilterContainer
          {...props}
          cta="Add Campaign"
          to="/campaigns/create"
          filterKeys={filterKeys}
        />
        <List {...props} />
      </div>
    );
  }
}

const getCampaigns = (() => {
  const action = (query) => getReportingResources(query)
    .then(({ data, total, ...rest }) => {
      if (size(data) > 0) {
        data.unshift({ ...total, isTotalRow: true });
      }
      return {
        ...rest,
        data,
      };
    });
  const end = ({ result, state }) => updateState(state, {
    loadingCampaigns: false,
    pagination: result.pagination,
    campaigns: result.data,
  });
  const start = ({ state }) => updateState(state, {
    loadingCampaigns: true,
    refreshCampaigns: false,
  });
  return { action, end, start };
})();

export const execChangeCampaignStatus = (() => {
  const action = ({ campaign, status }) => campaign.changeStatus(status);
  const start = () => splash({ start: true, text: 'changing campaign state' });
  const end = ({ state, result }) => {
    if (result.code === errTooManyUnarchivedUnpausedSkanCampaigns || result.code === errNotEnoughAvailableSkanIDs) {
      return {
        ...updateState(state, { showSkanAlert: true }),
        ...splash({ finish: false }),
      };
    }

    return {
      ...updateState(state, { refreshCampaigns: true }),
      ...splash({ finish: true, text: 'success!' }),
    };
  };
  return { action, end, start };
})();

const deactivateModal = ({ state }) => updateState(state, { showSkanAlert: false });

const props = (state) => (!state[ns] ? { authUser: state.authUser } : {
  authUser: state.authUser,
  campaigns: state[ns].campaigns,
  loadingCampaigns: state[ns].loadingCampaigns,
  pagination: state[ns].pagination,
  refreshCampaigns: state[ns].refreshCampaigns,
  showSkanAlert: state[ns].showSkanAlert,
});

const actions = {
  execChangeCampaignStatus,
  getCampaigns,
  deactivateModal,
};

export default compose(CampaignsListContainer, { props, actions });
