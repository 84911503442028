import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import MenuContext from './context';
import './Menu.scss';

export { default as MenuAll } from './MenuAll';
export { default as MenuItem } from './MenuItem';
export { default as MenuDivider } from './MenuDivider';

class Menu extends React.Component {
  static getDerivedStateFromProps(nextProps) {
    if ('checkedKeys' in nextProps) {
      return {
        checkedKeys: nextProps.checkedKeys || [],
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      checkedKeys: props.checkedKeys || props.defaultCheckedKeys || [],
      registeredValues: new Map(),
    };
  }

  unregisterValue = (dataKey) => {
    this.setState((state) => {
      const tmpRegisterValued = new Map(state.registeredValues);
      if (tmpRegisterValued.has(dataKey)) {
        tmpRegisterValued.delete(dataKey);
      }
      return { registeredValues: tmpRegisterValued };
    });
  };

  registerValue = (dataKey, data) => {
    this.setState((state) => {
      const tmpRegisterValued = new Map(state.registeredValues);
      if (!tmpRegisterValued.has(dataKey) && !data.disabled) {
        tmpRegisterValued.set(dataKey, data);
      }
      return { registeredValues: tmpRegisterValued };
    });
  };

  getAllStatus = () => {
    const { checkedKeys, registeredValues } = this.state;
    const some = checkedKeys.length > 0 && checkedKeys.length < registeredValues.size;
    const checked = checkedKeys.length === registeredValues.size;

    return { some, checked };
  };

  toggleAll = () => {
    const { registeredValues, checkedKeys: prevCheckedKeys } = this.state;
    let checkedKeys = [];
    if (prevCheckedKeys.length < registeredValues.size) {
      checkedKeys = [...registeredValues.keys()];
    }
    this.onChange(checkedKeys);
  };

  toggleItem = (option) => {
    const { dataKey, single } = option;

    if (single) {
      this.onChange([dataKey]);
      return;
    }

    const { onItemChange } = this.props;
    const { checkedKeys: prevCheckedKeys, registeredValues } = this.state;
    const optionIndex = prevCheckedKeys.indexOf(dataKey);
    const itemChecked = optionIndex === -1;
    const checkedKeys = [...prevCheckedKeys];
    if (itemChecked) {
      checkedKeys.push(dataKey);
    } else {
      checkedKeys.splice(optionIndex, 1);
    }
    onItemChange(registeredValues.get(dataKey), itemChecked);
    this.onChange(checkedKeys);
  };

  onChange = (checkedKeys) => {
    const { onChange } = this.props;
    const { registeredValues } = this.state;

    if (!('checkedKeys' in this.props)) {
      this.setState({ checkedKeys });
    }

    onChange(checkedKeys.map((val) => registeredValues.get(val)), checkedKeys);
  };

  render() {
    const { prefixCls, className, children } = this.props;
    return (
      <MenuContext.Provider
        value={{
          prefixCls,
          checkedKeys: this.state.checkedKeys,
          toggleAll: this.toggleAll,
          getAllStatus: this.getAllStatus,
          toggleItem: this.toggleItem,
          registerValue: this.registerValue,
          unregisterValue: this.unregisterValue,
        }}
      >
        <div className={classnames(prefixCls, className)}>
          {children}
        </div>
      </MenuContext.Provider>
    );
  }
}

const noop = () => { };

Menu.propTypes = {
  /**
   * className prefix
   */
  prefixCls: PropTypes.string,

  /**
   * className of Menu
   */
  className: PropTypes.string,

  /**
   * default checked value
   */
  // eslint-disable-next-line react/require-default-props
  defaultCheckedKeys: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
    ]),
  ),

  /**
   * controlled value
   */
  // eslint-disable-next-line react/require-default-props
  checkedKeys: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.object,
    ]),
  ),

  /**
   * value change handler
   */
  onChange: PropTypes.func,

  /**
   * item value change handler
   */
  onItemChange: PropTypes.func,
};

Menu.defaultProps = {
  prefixCls: 'v2_component_menu',
  className: '',
  onChange: noop,
  onItemChange: noop,
};

export default Menu;
