import React, {
  compose, Switch, Route, Redirect,
} from '../../../../../app/app';
import Info from './Info';
import HistoryC from './History';

const baseRoute = '/account/payments';

const Account = () => (
  <div className="views__account__payments">
    <Switch>
      <Route exact path={baseRoute} component={Info} />
      <Route exact path={`${baseRoute}/history`} component={HistoryC} />
      <Redirect to="/account" />
    </Switch>
  </div>
);

export default compose(Account);
