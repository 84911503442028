import React from 'react';
import { cloneDeep } from 'lodash';
import { sortByName } from 'templates/MultiTreeSelector/treeUtils';
import { config } from 'app/app';
import MultiTreeSelector from 'templates/MultiTreeSelector/MultiTreeSelector';

const { countries, subdivisions } = config.get('countryData');

const getSubdivisionTreeData = () => cloneDeep(countries)
  .filter((c) => subdivisions.some((s) => s.country === c.code))
  .map((c) => {
    const relatedSubdivisions = subdivisions
      .filter((subdivision) => subdivision.country === c.code)
      .map((s) => ({ ...s, id: s.code, parentId: c.code }));

    return Object.assign(c, { id: c.code, children: relatedSubdivisions });
  });

const RegionSelector = ({
  resource,
  selectedItems,
  onSelectedChange,
  onPaste,
}) => {
  const treeData = getSubdivisionTreeData(resource);
  return (
    <MultiTreeSelector
      data={treeData}
      selectedItems={selectedItems}
      generateFilterAttrs={(subdivision) => {
        const rawSubdivision = subdivisions.find((c) => c.code === subdivision.code);
        return {
          key: rawSubdivision.code,
          checked: resource.hasSubdivision(rawSubdivision),
          label: subdivision.name,
          onChange: (include) => {
            if (rawSubdivision) {
              resource.toggleSubdivision(include, rawSubdivision);
            }
          },
        };
      }}
      onChange={(nodes) => {
        onSelectedChange(
          subdivisions
            .filter((subdivision) => nodes.some((node) => node.dataKey === subdivision.code))
            .sort(sortByName),
        );
      }}
      onPaste={onPaste}
      onCheckAll={(include, searchValue) => {
        resource.toggleAllSubdivisions(include, searchValue);
      }}
      checkedKeyMapper={(subdivision) => subdivision.code}
    />
  );
};

export default RegionSelector;
