import React from 'react';
import ReactDOM from 'react-dom';
import ContextMenu from './ContextMenu';
import ContextMenuLinkOption from './ContextMenuLinkOption';
import ContextMenuCheckOption from './ContextMenuCheckOption';
import ContextMenuClickOption from './ContextMenuClickOption';
import ContextMenuWrapper from './ContextMenuWrapper';
import './contextMenu.scss';

class ContextMenuContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showMenu: false };
    this.iconElement = null;
    this.containerElement = null;
    this.id = `context-menu-${Date.now()}`;
  }

  scrollPosition = (e) => {
    e.preventDefault();
  }

  outListener = (e) => {
    const event = e.nativeEvent || e;
    let to = event.target;
    while (to) {
      if (
        to.classList
        && (to.classList.contains('components__context_menu_options')
          || to.classList.contains('components__context_menu'))
        && to.classList.contains(this.id)
      ) {
        return;
      }
      to = to.parentNode;
    }
    this.hideMenu();
  };

  clickListener = (e) => {
    let { target } = e;
    const portal = document.getElementById('contextmenu');
    let isMenu = false;
    let isPortal = false;
    while (target) {
      if (
        target.classList
        && target.classList.contains('components__context_menu')
      ) {
        isMenu = true;
      }
      if (target.parentNode === portal) {
        isPortal = true;
      }
      target = target.parentNode;
    }
    if (!isMenu || this.props.subMenu || !isPortal) {
      this.hideMenu();
    }
  }

  addEventListener = () => {
    document.addEventListener('wheel', this.scrollPosition);
    document.addEventListener('mousewheel', this.scrollPosition);
    document.addEventListener('click', this.clickListener);
    if (this.props.subMenu) {
      document.addEventListener('mouseout', this.outListener);
    }
  }

  removeEventListener = () => {
    document.removeEventListener('wheel', this.scrollPosition);
    document.removeEventListener('mousewheel', this.scrollPosition);
    document.removeEventListener('click', this.clickListener);
    if (this.props.subMenu) {
      document.removeEventListener('mouseout', this.outListener);
    }
  }

  showMenu = () => {
    this.setState({ showMenu: true }, this.addEventListener);
  }

  hideMenu = () => {
    this.setState({ containerElement: null, showMenu: false }, this.removeEventListener);
  }

  componentWillUnmount() {
    this.removeEventListener();
  }

  render() {
    const toRender = [
      <ContextMenu
        key={0}
        {...this.props}
        {...this.state}
        toggleMenu={this.showMenu}
        overMenu={this.props.subMenu && this.showMenu}
        menuID={this.id}
        makeRef={(node) => { this.iconElement = node; }}
      />,
    ];
    if (this.state.showMenu) {
      const { left, top } = this.iconElement.getBoundingClientRect();
      const portalNode = document.getElementById('contextmenu');
      const style = this.props.subMenu
        ? {
          top: top - 4,
          left,
          width: this.props.width,
          position: 'fixed',
        }
        : {};
      if (this.props.subMenu && this.state.containerElement) {
        const { width: containerWidth } = this.state.containerElement.getBoundingClientRect();
        if (this.props.position === 'left') {
          style.left -= containerWidth;
        } else {
          style.left += containerWidth;
        }
      }
      toRender.push(ReactDOM.createPortal(
        <ContextMenuWrapper
          key={1}
          {...this.props}
          style={style}
          menuID={this.id}
          closeOnClick={this.props.closeOnClick || this.props.subMenu}
          makeRef={(node) => this.setState({ containerElement: node })}
        />,
        portalNode,
      ));
      // where do we want to place the tooltip according to the screen?
      // default is top left of the anchor element "iconElement"
      if (!this.props.subMenu && this.state.containerElement) {
        const offsetPx = 4;
        const containerHeight = this.state.containerElement.offsetHeight;
        const containerWidth = this.state.containerElement.offsetWidth;
        const windowHeight = window.innerHeight;
        const windowWidth = window.innerWidth;
        const iconHeight = this.iconElement.offsetHeight + offsetPx;
        const iconWidth = this.iconElement.offsetWidth;

        const renderRight = (windowWidth - left) > containerWidth;
        const renderLeft = this.props.position === 'bottom-right' || !renderRight;
        const renderBelow = (windowHeight - top) - iconHeight > containerHeight;
        const renderAbove = !renderBelow;

        if (renderRight) {
          portalNode.style.left = `${left}px`;
        }
        if (renderLeft) {
          portalNode.style.left = `${(left - containerWidth) + iconWidth}px`;
        }
        if (renderBelow) {
          portalNode.style.top = `${(top + iconHeight) - this.props.offsetTop}px`;
        }
        if (renderAbove) {
          const topPos = Math.max(0, (top - containerHeight) - offsetPx);
          portalNode.style.top = !topPos ? '0px' : `${topPos}px`;
        }
      }
    }
    return toRender;
  }
}

ContextMenuContainer.defaultProps = {
  offsetTop: 0,
};

ContextMenuContainer.LinkOption = ContextMenuLinkOption;
ContextMenuContainer.CheckOption = ContextMenuCheckOption;
ContextMenuContainer.ClickOption = ContextMenuClickOption;

export default ContextMenuContainer;
