/* eslint-disable react/display-name */
import { getGrowCampaignPageSizeCache } from 'lib/cache/PageSizeCache';
import { getGrowCampaignSortCache } from 'lib/cache/SortCache';
import { getGrowCampaignFiltersCache } from 'lib/cache/FiltersCache';
import { getGrowCampaignMetricCache } from 'lib/cache/MetricCache';
import {
  defaultSort,
  defaultFilterCache,
} from 'services/Templates/Campaigns/List/constants';

export const getListCaches = (userId) => ({
  metricCache: getGrowCampaignMetricCache(userId),
  pageSizeCache: getGrowCampaignPageSizeCache(userId),
  sortCache: getGrowCampaignSortCache(userId, [defaultSort]),
  filterCache: getGrowCampaignFiltersCache(userId, defaultFilterCache),
});

export default {};
