import React from 'react';
import classnames from 'classnames';
import Icon from '../Icon/Icon';

class ContextMenuWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      makeRef, showMenu, toggleMenu, ClickItem, icon, overMenu, menuWrapperClassName, menuID,
    } = this.props;
    return (
      <div
        className={classnames('components__context_menu', menuID, menuWrapperClassName)}
        onClick={toggleMenu}
        onMouseOver={overMenu}
        ref={makeRef}
      >
        {icon !== 'settings' ? (
          <div className={classnames({ showMenu })}>{ClickItem}</div>
        ) : (
          <Icon.Settings />
        )}
      </div>
    );
  }
}

ContextMenuWrapper.defaultProps = {
  ClickItem: <Icon.More />,
  showMenu: false,
};

export default ContextMenuWrapper;
