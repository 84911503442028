import React from 'react';
import CollapseSection from '../../../components/V2/Collapse/CollapseSection';
import DailyCountryBudget from './DailyCountryBudget/DailyCountryBudget';

const GeoDailyBudget = ({ campaign, isMissionControl }) => {
  if (isMissionControl && !campaign.get('account.accountConfig')) {
    return null;
  }
  return campaign.get('budget.daily_spend_limit_type') === 'geo' && (
    <CollapseSection
      title="Daily Country Budgets"
      description="Add a daily budget to your targeted countries automatically pulled from geo targeting."
      id="dailyCountryBudgets"
    >
      <DailyCountryBudget
        campaign={campaign}
        isMissionControl={isMissionControl}
      />
    </CollapseSection>
  );
};

export default GeoDailyBudget;
