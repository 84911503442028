import React from 'react';
import { debounce } from 'lodash';
import AccountFinder from './AccountFinder';
import Account from '../../models/Account';
import './finder.scss';

class AccountFinderContainer extends React.Component {
  constructor(p) {
    super(p);
    this.state = {
      loading: false,
      results: null,
      searchValue: '',
    };
  }

  componentDidMount() {
    const { accountId, fetchSelectedOnRefresh, acctHier } = this.props;

    if (fetchSelectedOnRefresh && accountId) {
      this.fetchSelectedAccountOnRefresh(accountId);
    }
    if (acctHier?.account) {
      this.loadAccountByAccountFilter(acctHier);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.acctHier?.account !== prevProps.acctHier?.account) {
      this.loadAccountByAccountFilter(this.props.acctHier);
    }
  }

  loadAccountByAccountFilter = (acctHier) => {
    if (acctHier?.account) {
      this.fetchSelectedAccount(acctHier.account);
      this.setState({ searchValue: acctHier.name, results: null });
    } else {
      this.props.onSelect(undefined);
      this.setState({ searchValue: '' });
    }
  }

  // @TODO: Tidy by adding a loading spinner
  // provide selected application back to container on page reload if url search ?application=applicationId
  fetchSelectedAccountOnRefresh = async (accountId) => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(this.fetchSelectedAccount(accountId), 500);
  };

  fetchSelectedAccount = async (accountId) => {
    const result = await Account.get(accountId);
    const selectedAccount = Account.make([result.response]);
    this.props.onSelect(selectedAccount[0], true);
  };

  onSearch = (accountTypes) => async (searchValue) => {
    if (!searchValue) {
      this.setState({ loading: false, results: null, searchValue });
      this.props.onSelect(undefined);
      return;
    }
    // abort last request, if controller exists
    this.debounced?.cancel();
    this.setState({
      searchValue, loading: true, results: null,
    });

    this.debounced = debounce(async () => {
      let results = [];
      if (accountTypes) {
        results = await Account.list({
          type: accountTypes, search: searchValue, perPage: 100, page: 1,
        });
      } else {
        results = await Account.list({ search: searchValue, perPage: 100, page: 1 });
      }
      const accounts = Account.make(results.accounts);
      const excludedAccountIDs = new Set(this.props.excludedAccounts);
      const filteredAccounts = accounts.filter((a) => !excludedAccountIDs.has(a.get('id')));
      this.setState({
        loading: false,
        results: filteredAccounts,
      });
    }, 1e3);
    this.debounced();
  };

  onSelect = (account) => {
    this.setState({ searchValue: account.get('name') });
    this.props.onSelect(account);
  };

  render() {
    return (
      <AccountFinder
        {...this.props}
        {...this.state}
        onSearch={this.onSearch}
        onSelect={this.onSelect}
      />
    );
  }
}

export default AccountFinderContainer;
