import MessageManager from './MessageManager';

// Message default value
const MessageObject = {
  title: 'Message Title',
  content: 'Message Content',
  type: 'error',
  closeBtn: true,
  timeout: 5000,
  closeCb: (id) => MessageManager.delete(id),
};

const Message = {
  success: (msg) => {
    const id = msg.id || Date.now().toString();
    const newMsg = {
      ...MessageObject,
      ...msg,
      type: 'success',
      id,
    };
    MessageManager.add(newMsg);
  },

  error: (msg) => {
    const id = msg.id || Date.now().toString();
    const newMsg = {
      ...MessageObject,
      timeout: -1,
      ...msg,
      id,
    };
    MessageManager.add(newMsg);
  },
};

export default Message;
