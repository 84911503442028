import {
  map, omitBy, isNull, isArray,
} from 'lodash';
import { getServiceType } from 'lib/serviceType';
import { getClient, getUploadClient } from '../setup';
import {
  GetTemplate,
  GetTemplates,
  GetTemplateSummary,
  GetTemplateAdvReplacements,
  GetTemplateVersions,
  GetTemplateDeployments,
  CreateTemplateDeployment,
  CreateTemplate,
  ModifyTemplate,
  CreateTemplateVersion,
  UploadTemplate,
} from '../operations_template.graphql';
import { getSearchAndIDsFilter } from './helper';
import handleGraphQLError from './handle-graphql-error';

function getTemplateQueryVariables(filters) {
  const variables = {
    ...getSearchAndIDsFilter(filters),
    page: Number(filters.page),
    perPage: Number(filters.perPage),
  };
  const { templateStatus, format, templateProtocol } = filters;
  if (templateStatus) {
    variables.status = templateStatus.split(',');
  }
  if (format) {
    variables.format = format.split(',');
  }
  if (templateProtocol) {
    variables.protocol = templateProtocol.split(',');
  }
  return variables;
}

function convertReplacementToRestFormat(replacement) {
  if (!replacement) {
    return undefined;
  }
  const result = {
    key: replacement.key,
    name: replacement.name,
    type: replacement.type,
    value: replacement.value,
    group: replacement.group,
    description: replacement.description,
    cacheable: replacement.cacheable,
    active: replacement.active,
    shows: replacement.shows || [],
    hides: replacement.hides || [],
    ignoredPlatforms: replacement.ignoredPlatforms || [],
    children: replacement.children || [],
    enum: map(replacement.enum, (e) => ({ name: e.name, value: e.value })),
  };

  const { role } = replacement;
  if (role) {
    result.role = {
      name: role.name,
      valueRequired: role.valueRequired,
      overrides: map(role.overrides, (o) => ({ name: o.name, priority: o.priority })),
    };
  }

  const { validation } = replacement;
  if (validation) {
    result.validation = omitBy({
      min: validation.min,
      max: validation.max,
      precision: validation.precision,
      maxLength: validation.maxLength,
    }, isNull);
  }

  return result;
}

function convertToRestFormat(template) {
  if (!template) {
    return undefined;
  }
  const result = {
    id: template.id,
    name: template.name,
    version: template.version,
    created: template.created,
    updated: template.updated,
    status: template.status,
    cdn_url: template.cdnURL,
    format: template.format,
    is_custom_creative: template.isCustomCreative,
    mraid_url: template.mraidURL,
    opportunity: template.opportunity,
    supported_template_protocol: template.supportedTemplateProtocol,
    originalTemplateID: template.originalTemplateID,
    description: template.description,
    creativeCount: template.creativeCount,
    applications: template.applications || [],
    adUnit_config: template.adUnitConfig,
    replacements: map(template.replacements, convertReplacementToRestFormat),
    advReplacements: map(template.advReplacements, convertReplacementToRestFormat),
    dimensions: {},
    template_category: {},
    filters: {},
  };

  const { dimensions } = template;
  if (dimensions) {
    result.dimensions = omitBy({
      minHeight: dimensions.minHeight,
      minWidth: dimensions.minWidth,
    }, isNull);
  }

  const { osWhitelist } = template;
  if (osWhitelist) {
    result.os_whitelist = omitBy({
      amazon: osWhitelist.amazon,
      android: osWhitelist.android,
      ios: osWhitelist.ios,
      windows: osWhitelist.windows,
    }, isNull);
  }

  const { templateCategory } = template;
  if (templateCategory) {
    result.template_category = omitBy({
      'adaptive-creative': templateCategory.adaptiveCreative,
      banner: templateCategory.banner,
      'dynamic-template': templateCategory.dynamicTemplate,
      mrec: templateCategory.mrec,
      native: templateCategory.native,
    }, isNull);
  }

  const { filters } = template;
  if (filters) {
    result.filters = omitBy({
      target_display_manager_type: filters.targetDisplayManagerType,
      target_display_manager_fallback_type: filters.targetDisplayManagerFallbackType,
      target_os_type: filters.targetOSType,
      target_os_fallback_type: filters.targetOSFallbackType,
      target_browser_type: filters.targetBrowserType,
      target_browser_fallback_type: filters.targetBrowserFallbackType,
    }, isNull);
    if (isArray(filters.targetDisplayManagerIDs)) {
      result.filters.target_display_manager_ids = filters.targetDisplayManagerIDs.map(({ displayManager, displayManagerVer }) => ({
        display_manager: displayManager, display_manager_ver: displayManagerVer,
      }));
    }
    if (isArray(filters.targetOSIDs)) {
      result.filters.target_os_ids = filters.targetOSIDs.map(({ osName, osVer }) => ({
        os_name: osName, os_ver: osVer,
      }));
    }
    if (isArray(filters.targetBrowserIDs)) {
      result.filters.target_display_manager_ids = filters.targetBrowserIDs.map(({ browserName, browserVer }) => ({
        browser_name: browserName, browser_ver: browserVer,
      }));
    }
  }

  return result;
}

export function handleTemplateGraphQLResponse({
  data: {
    template,
  } = {},
}) {
  return {
    ok: true,
    status: 200,
    response: convertToRestFormat(template),
  };
}

export function getTemplate(id) {
  return getClient()
    .query({
      query: GetTemplate,
      variables: { id },
    })
    .then(handleTemplateGraphQLResponse)
    .catch(handleGraphQLError);
}

export function getTemplateSummary(id) {
  return getClient()
    .query({
      query: GetTemplateSummary,
      variables: { id },
    })
    .then(handleTemplateGraphQLResponse)
    .catch(handleGraphQLError);
}

export function getTemplateAdvReplacements(id) {
  return getClient()
    .query({
      query: GetTemplateAdvReplacements,
      variables: { id },
    })
    .then(handleTemplateGraphQLResponse)
    .catch(handleGraphQLError);
}

function handleTemplateVersionsGraphQLResponse({
  data: {
    templateVersions,
  } = {},
}) {
  return {
    ok: true,
    status: 200,
    response: templateVersions.map((t) => ({ ...t, creativeCount: t.creativeCountForVersion })),
  };
}

export function getTemplateVersions(id) {
  return getClient()
    .query({
      query: GetTemplateVersions,
      variables: { id },
    })
    .then(handleTemplateVersionsGraphQLResponse)
    .catch(handleGraphQLError);
}

function handleTemplateDeploymentsGraphQLResponse({
  data: {
    templateDeployments,
  } = {},
}) {
  return {
    ok: true,
    status: 200,
    response: templateDeployments,
  };
}

export function getTemplateDeployments(id) {
  return getClient()
    .query({
      query: GetTemplateDeployments,
      variables: { id },
    })
    .then(handleTemplateDeploymentsGraphQLResponse)
    .catch(handleGraphQLError);
}

function handleTemplatesGraphQLResponse({
  data: {
    templates,
  } = {},
}) {
  return {
    ok: true,
    status: 200,
    response: templates.templates.map(convertToRestFormat),
    pagination: templates.pagination,
  };
}

export function getTemplates(filters = {}) {
  return getClient()
    .query({
      query: GetTemplates,
      variables: {
        ...getTemplateQueryVariables(filters),
        client: getServiceType(),
      },
    })
    .then(handleTemplatesGraphQLResponse)
    .catch(handleGraphQLError);
}

function handleCreateTemplateDeploymentGraphQLResponse({
  data: {
    createTemplateDeployment: deployment,
  } = {},
}) {
  return {
    ok: true,
    status: 200,
    response: deployment,
  };
}

export function createTemplateDeployment(templateDeployment) {
  return getClient()
    .mutate({
      mutation: CreateTemplateDeployment,
      variables: {
        input: templateDeployment,
      },
    })
    .then(handleCreateTemplateDeploymentGraphQLResponse)
    .catch(handleGraphQLError);
}

function toGraphQLCreateTemplateRequest(template) {
  const {
    name,
    status,
    format,
    opportunity,
    is_custom_creative,
    supported_template_protocol,
    mraid_url,
    cdn_url,
    applications,
    replacements,
  } = template;
  const payload = {
    name,
    status,
    format,
    opportunity,
    replacements,
    isCustomCreative: is_custom_creative,
    supportedTemplateProtocol: supported_template_protocol,
    mraidURL: mraid_url,
    cdnURL: cdn_url,
    applications: map(applications, ({ id }) => id),
  };
  return payload;
}

function handleCreateTemplateGraphQLResponse({
  data: {
    createTemplate: template,
  } = {},
}) {
  return {
    ok: true,
    status: 200,
    response: template,
  };
}

export function createTemplate(template) {
  return getClient()
    .mutate({
      mutation: CreateTemplate,
      variables: {
        input: toGraphQLCreateTemplateRequest(template),
      },
    })
    .then(handleCreateTemplateGraphQLResponse)
    .catch(handleGraphQLError);
}

function toGraphQLModifyTemplateRequest(template) {
  const {
    name,
    mraid_url,
    is_custom_creative,
    supported_template_protocol,
    status,
    applications,
    template_category: templateCategory,
    os_whitelist: osWhitelist,
    dimensions,
  } = template;
  const payload = {
    name,
    status,
    isCustomCreative: is_custom_creative,
    supportedTemplateProtocol: supported_template_protocol,
    mraidURL: mraid_url,
    publisherWhitelist: map(applications, ({ id }) => id),
  };
  if (templateCategory) {
    payload.templateCategory = {
      adaptiveCreative: templateCategory['adaptive-creative'],
      banner: templateCategory.banner,
      dynamicTemplate: templateCategory['dynamic-template'],
      mrec: templateCategory.mrec,
      native: templateCategory.native,
    };
  }
  if (osWhitelist) {
    payload.osWhitelist = {
      amazon: osWhitelist.amazon,
      android: osWhitelist.android,
      ios: osWhitelist.ios,
      windows: osWhitelist.windows,
    };
  }
  if (dimensions) {
    payload.dimensions = {
      minWidth: dimensions.minWidth,
      minHeight: dimensions.minHeight,
    };
  }
  return payload;
}

function handleModifyTemplateGraphQLResponse({
  data: {
    modifyTemplate: template,
  } = {},
}) {
  return {
    ok: true,
    status: 200,
    response: template,
  };
}

export function modifyTemplate(id, template) {
  return getClient()
    .mutate({
      mutation: ModifyTemplate,
      variables: {
        id,
        input: toGraphQLModifyTemplateRequest(template),
      },
    })
    .then(handleModifyTemplateGraphQLResponse)
    .catch(handleGraphQLError);
}

function handleCreateTemplateVersionGraphQLResponse({
  data: {
    createTemplateVersion: template,
  } = {},
}) {
  return {
    ok: true,
    status: 200,
    response: template,
  };
}

export function createTemplateVersion(templateVersion) {
  return getClient()
    .mutate({
      mutation: CreateTemplateVersion,
      variables: {
        input: templateVersion,
      },
    })
    .then(handleCreateTemplateVersionGraphQLResponse)
    .catch(handleGraphQLError);
}

function handleTemplateUploadGraphQLResponse({ data }) {
  const template = data.uploadTemplate || {};
  const result = {
    cdnUrl: template.cdnURL,
    replacements: template.replacements,
  };
  return {
    ok: true,
    status: 200,
    response: result,
  };
}

export function uploadTemplate(input) {
  return getUploadClient()
    .mutate({
      mutation: UploadTemplate,
      variables: {
        input,
      },
    })
    .then(handleTemplateUploadGraphQLResponse)
    .catch(handleGraphQLError);
}
