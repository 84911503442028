import React, {
  compose, namespace, splash, Redirect,
} from '../../../../../app/app';
import { track } from '../../../../../lib/tracker';
import Account from '../../../../../models/Account';
import Fund from './Fund';
import './fund.scss';

const ns = 'views.account.fund';
const updateState = namespace(ns);
const props = (state) => ({
  ...state[ns],
  account: state['views.account.overview'] && state['views.account.overview'].account,
  user: state.authUser.id,
});

class FundContainer extends React.Component {
  componentWillUnmount() {
    this.props.actions.resetState();
  }

  render() {
    if (!this.props.account || this.props.processedPayment) {
      return <Redirect to="/account" />;
    }
    return <Fund {...this.props} />;
  }
}

const resetState = ({ state }) => updateState(state, {
  amount: null, cardComplete: false, paymentError: false, processedPayment: false,
});

const processPayment = (() => {
  const action = async ({
    account, amount, country, source,
  }) => {
    const fundResult = await Account.fund(account, source.id, amount, country);
    if (!fundResult.ok) {
      return { serverError: true };
    }
    return {};
  };
  const start = () => splash({ start: true, text: 'processing your payment' });
  const end = ({ state, result }) => {
    if (result.serverError) {
      return {
        ...splash({ show: false }),
        ...updateState(state, { paymentError: true }),
      };
    }
    track('Funds Added');
    return {
      ...splash({ finish: true, text: 'processing your payment' }),
      ...updateState(state, { processedPayment: true }),
    };
  };
  return { action, start, end };
})();

const processPaymentState = ({ state, values: { error, start } }) => {
  const opt = {};
  if (start) {
    opt.start = true;
  }
  if (error) {
    opt.show = false;
  }
  return {
    ...splash(opt),
    ...updateState(state, { paymentError: !!error }),
  };
};

const actions = {
  processPayment,
  processPaymentState,
  resetState,
};

export default compose(FundContainer, { props, actions });
