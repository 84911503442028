import { mapStackTrace } from 'sourcemapped-stacktrace';
import React, { config } from '../../app/app';
import ErrorContainer from './ErrorContainer';
import './errorContainer.scss';

const disableTrace = !!config.get('disableErrorStackTrace');

class ErrorContainerContainer extends React.Component {
  constructor(p) {
    super(p);
    this.state = {};
  }

  componentDidCatch(error, info) {
    if (!disableTrace) {
      mapStackTrace(error.stack, (stack) => {
        const firstLine = stack[0].replace('webpack://', '');
        this.setState({
          error: {
            error,
            info,
            firstLine,
            stack: stack.join('\n'),
          },
        });
      });
    } else {
      this.setState({ error });
    }
  }

  render() {
    if (!this.state.error) {
      return this.props.children;
    }
    return (
      <ErrorContainer {...this.state} disableTrace={disableTrace} />
    );
  }
}

export default ErrorContainerContainer;
