import React from 'react';
import { FILTER_KEY_CAMPAIGN_BUDGET_OBJECTIVES } from 'app/constants/filters';
import CommonMultiPicker from '../CommonMultiPicker/CommonMultiPicker';
import { campaignObjectives } from '../constant';

const MultiCampaignBudgetObjective = (props) => (
  <CommonMultiPicker
    data={campaignObjectives}
    filterKey={FILTER_KEY_CAMPAIGN_BUDGET_OBJECTIVES}
    panelWidth={300}
    {...props}
  />
);

export default MultiCampaignBudgetObjective;
