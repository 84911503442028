import React from 'react';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import { buildQueryObject } from 'lib/lib';
import { getPermissions } from 'accesscontrol';
import config from 'lib/config';
import {
  compose, namespace, splash, Redirect,
} from '../../../../../app/app';
import {
  parseQueryString, addAuthHeader, post,
} from '../../../../../lib/http';
import { set, remove } from '../../../../../lib/session';
import { init, track } from '../../../../../lib/tracker';

const ns = 'views.public.login';
const updateState = namespace(ns);

class UpdateContainer extends React.Component {
  UNSAFE_componentWillMount() {
    const url = window.location.href;
    const index = url.indexOf(this.props.location.pathname);
    const param = url.substring(index + this.props.location.pathname.length);

    let token = '';
    token = Cookies.get('token');
    if (!token) {
      token = parseQueryString(param, 'token');
    }

    let username = Cookies.get('username');
    if (!username) {
      username = parseQueryString(param, 'username');
    }

    if (token && token !== '') {
      this.props.actions.auth({ token, username });
    } else {
      window.location.href = '/';
    }
  }

  render() {
    if (this.props.isAuthenticated) {
      const queryObject = buildQueryObject(window.location.search);
      const { redirect = '/' } = queryObject;
      return <Redirect to={redirect} />;
    }
    return null;
  }
}

const auth = (() => {
  const action = ({ token }) => post(`${config.get('authUrl')}auth`, { token }, {'vungle-source': 'grow'});
  const start = ({ state, result: { username } }) => {
    remove('authUser');
    return {
      authUser: null,
      ...updateState(state, { username, isAuthenticated: false }),
      ...splash({ start: true, text: 'Logging in' }),
    };
  };
  const error = ({ result: err }) => ({ globalError: err });
  const end = ({ result, state }) => {
    const [authUser, loginFailed] = result.status === 200 ? [result.response, false] : [null, true];
    if (authUser) {
      const decoded = jwt_decode(result.response.token);
      const user = { ...authUser, username: state[ns].username, permissions: getPermissions(decoded.role) };
      set('authUser', user);
      addAuthHeader(user);
      init(user);
      track('User Logged In', { id: user.account.id });
      return {
        ...splash({ finish: true, text: 'Success! Welcome back' }),
        authUser: user,
        [ns]: { isAuthenticated: true },
      };
    }
    if (result.status === 403) {
      window.location.href = `/register?verifyEmail=${encodeURIComponent(state[ns].username)}`;
    } else {
      window.location.href = '/';
    }
    return {
      ...updateState(state, { authUser, loginFailed, notActivated: result.status === 403 }),
      ...splash({ show: false }),
    };
  };
  return {
    action, start, error, end,
  };
})();

const actions = {
  auth,
};

const props = (state) => state[ns];

export default compose(UpdateContainer, { actions, props });
