import React from 'react';

const learnMoreLink = 'https://support.vungle.com/hc/en-us/articles/360035348091';

const LearnMore = ({ link, children }) => (
  <div>
    <a
      className="vg-link"
      href={link || learnMoreLink}
      rel="noreferrer"
      target="_blank"
    >
      {children || 'Learn more here'}
    </a>
  </div>
);

const EndCardTooltipContent = () => (
  <div>
    Add your bundled HTML Playable (.zip format) files here.
    <LearnMore link="https://support.vungle.com/hc/en-us/categories/360005482471-Creative">
      Additional
    </LearnMore>
    <LearnMore link="https://support.vungle.com/hc/en-us/articles/360056663752-Building-for-Vungle-Creative-Requirements">
      requirements
    </LearnMore>
  </div>
);

const VideoTooptipContent = () => (
  <div>
    <p>Ratio: (16:9) or (9:16)</p>
    <p>Dimensions: 1920x1080px or 1280x720px</p>
    <p>File Size: 30MB to 60MB recommended</p>
    <p>Duration: 15-30 second recommended; 61second maximum</p>
    <p>Format: MP4 or MOV</p>
    <LearnMore />
  </div>
);

const LandScapeImageContent = () => (
  <div>
    <p>Ratio: (16:9)</p>
    <p>Dimensions: 960x1707px recommended</p>
    <p>File Size: Under 150KB recommended</p>
    <p>Format: PNG or JPG</p>
    <LearnMore />
  </div>
);

const PortraitImageContent = () => (
  <div>
    <p>Ratio: (9:16)</p>
    <p>Dimensions: 1707x960px recommended</p>
    <p>File Size: Under 150KB recommended</p>
    <p>Format: PNG or JPG</p>
    <LearnMore />
  </div>
);

const CarouselImageContent = () => (
  <div>
    <p>Ratio: (16:9)</p>
    <p>Dimensions: 390x695px recommended</p>
    <p>File Size: Under 150KB recommended</p>
    <p>Format: PNG or JPG</p>
    <LearnMore />
  </div>
);

const MRECVideoContent = () => (
  <div>300x250px video is recommended for this video asset.</div>
);

// eslint-disable-next-line camelcase
const MREC6_5ImageContent = () => (
  <div>
    <p>Ratio: (6:5)</p>
    <p>Dimensions: 300x250px</p>
    <p>File Size: as small as possible recommended</p>
    <p>Format: PNG, JPG, or GIF</p>
    <LearnMore />
  </div>
);

// eslint-disable-next-line camelcase
const Banner6_1ImageContent = () => (
  <div>
    <p>Ratio: (6:1)</p>
    <p>Dimensions: 320x50px or 300x50px</p>
    <p>File Size: as small as possible recommended</p>
    <p>Format: PNG, JPG, or GIF</p>
    <LearnMore />
  </div>
);

// eslint-disable-next-line camelcase
const Banner8_1ImageContent = () => (
  <div>
    <p>Ratio: (8:1)</p>
    <p>Dimensions: 728x90px</p>
    <p>File Size: as small as possible recommended</p>
    <p>Format: PNG, JPG, or GIF</p>
    <LearnMore />
  </div>
);

const NativeImageContent = () => (
  <div>
    <p>Ratio: 1.91x1.00.</p>
    <p>Strict Dimension Requirement: 800 pixels (width) x 417 pixels (height) or higher.</p>
    <p>Format: Animated images (.GIF) are not supported for native ads.</p>
    <LearnMore />
  </div>
);

const ContentTypeMap = {
  landscape_image: LandScapeImageContent,
  portrait_image: PortraitImageContent,
  mrec_image_6_5: MREC6_5ImageContent,
  banner_image_6_1: Banner6_1ImageContent,
  banner_image_8_1: Banner8_1ImageContent,
  carousel: CarouselImageContent,
  mrecVideo: MRECVideoContent,
  normalVideo: VideoTooptipContent,
  endCard: EndCardTooltipContent,
  native_image: NativeImageContent,
};

const getTooltipContent = (type) => {
  const Content = ContentTypeMap[type];
  return Content && <Content />;
};

export default getTooltipContent;

export {
  EndCardTooltipContent,
  VideoTooptipContent,
  PortraitImageContent,
  LandScapeImageContent,
  CarouselImageContent,
  MRECVideoContent,
  // eslint-disable-next-line camelcase
  MREC6_5ImageContent,
  // eslint-disable-next-line camelcase
  Banner6_1ImageContent,
  // eslint-disable-next-line camelcase
  Banner8_1ImageContent,
};
