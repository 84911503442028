import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import Icon from '../Icon/Icon';
import Spinner from '../Spinner/Spinner';
import Button from '../Button/Button';
import AdFrame from './AdFrame';
import AppStoreFrame from './AppStoreFrame';
import PrivacyFrame from './PrivacyFrame';
import generateVideoBlob from './videoBlob';
import generateWebPageBlob from './webpageBlob';
import {
  error404Blob,
  error404VideoBlob,
  error404ReplacementsBlob,
} from './errorBlob';
import {
  PREVIEW_SETTINGS,
  PHONE_DEFAULT_POSITIONS_SIZES,
  TABLET_DEFAULT_POSITIONS_SIZES,
  VUNGLE_PLACEMENT_TYPE_FORMATS_MAP,
  VUNGLE_TYPE_VERSION_MAP,
  MRAID_ACTIONS,
  LEGACY_ACTIONS,
  ERRORS,
  PHONE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
  BANNER_SMALL_SIZE,
  BANNER_BIG_SIZE,
} from './settings';

import './deviceFrame.scss';
import ControlButtons from './ControlButtons';
import AbTestingControlButtons from '../../services/Templates/AbTesting/Components/Previewer/AbTestingControlButtons';

const defPrivacyPageUrl = generateWebPageBlob();

class DeviceFrame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blobVideo: null,
      adFrameURL: null,
      orientation: PREVIEW_SETTINGS.ORIENTATIONS.PORTRAIT,
      show: false,
      loading: false,
      device: 'phone',
      appStoreOpened: false,
      privacyPageOpened: false,
      privacyPageUrl: defPrivacyPageUrl,
      shouldDisplayDeviceToggle: true,
      originalSizes: {},
    };

    this.startListeningToMessages();
  }

  startListeningToMessages = () => {
    window.addEventListener('message', this.handleMessage, false);
  }

  stopListeningToMessages = () => {
    window.removeEventListener('message', this.handleMessage, false);
  }

  // this means it is singleton message, one preview per page
  handleMessage = (e) => {
    const { data } = e;

    const container = e.source.parent.frameElement;
    const creativeId = container?.dataset?.creative;

    if (data === LEGACY_ACTIONS.closeVideo) {
      this.setState({
        adFrameURL: this.props.assetURL,
      });

      return;
    }

    try {
      const msg = JSON.parse(e.data);
      const { event } = msg;

      if (this.props.creativeId && this.props.creativeId !== creativeId) {
        return;
      }

      if (event === PREVIEW_SETTINGS.EVENTS.MRAID_ACTION) {
        switch (msg.message.action) {
          case MRAID_ACTIONS.open:
            if (this.state.privacyPageOpened) {
              return;
            }
            this.setState({
              appStoreOpened: true,
            });
            break;
          case MRAID_ACTIONS.close:
            this.setState({
              appStoreOpened: false,
              adFrameURL: 'about:blank',
            });
            break;

          case MRAID_ACTIONS.openPrivacy:
            if (this.state.appStoreOpened) {
              return;
            }

            this.setState({
              privacyPageOpened: true,
              privacyPageUrl: msg.message.arguments[0] || defPrivacyPageUrl,
            });
            break;
          default:
            break;
        }
      }

      if (event === PREVIEW_SETTINGS.EVENTS.POST_MESSAGE) {
        switch (msg.message) {
          case LEGACY_ACTIONS.download:
            this.setState({
              appStoreOpened: true,
            });
            break;
          case LEGACY_ACTIONS.close:
            this.setState({
              appStoreOpened: false,
              adFrameURL: 'about:blank',
            });
            break;
          default:
            break;
        }
      }
    } catch (x) {} //eslint-disable-line
  }

  handleShow = () => {
    this.props.onShow();
    this.setState({
      show: true,
      loading: true,
    });
  };

  handleStop = () => {
    this.setState({
      show: false,
      loading: false,
    });
  }

  handleClose = () => {
    this.setState({
      show: false,
    });
  };

  handleAppStoreClose = () => {
    this.setState({
      appStoreOpened: false,
    });
  }

  handlePrivacyPageClose = () => {
    this.setState({
      privacyPageOpened: false,
      privacyPageUrl: defPrivacyPageUrl,
    });
  }

  onLoadContent = () => {
    if (this.state.isFullscreen && this.state.loading) {
      this.toggleFullscreen(true);
    }

    this.setState({
      loading: false,
    });
  };

  togglePhone = () => {
    this.setState({
      device: 'phone',
    });
  }

  toggleTablet = () => {
    this.setState({
      device: 'tablet',
    });
  }

  toggleFullscreen = (isFullscreen) => {
    const maxArea = document.querySelector('#adWindow').contentWindow.document.querySelector('#maxArea');
    const adContainer = maxArea.querySelector('#adContainer');
    const drawerSideBar = document.querySelector('.v2_component_drawer-sidebar.v2_component_drawer-open-sidebar');

    if (isFullscreen) {
      const originalSizes = {
        maxAreaHeight: maxArea.offsetHeight,
        maxAreaWidth: maxArea.offsetWidth,
        maxAreaTop: maxArea.offsetTop,
        maxAreaLeft: maxArea.offsetLeft,
        adContainerHeight: adContainer.offsetHeight,
        adContainerWidth: adContainer.offsetWidth,
        drawerSideBarWidth: drawerSideBar.offsetWidth,
      };

      this.setState({ isFullscreen: true, originalSizes }, () => {
        maxArea.style.height = '100%';
        maxArea.style.width = '100%';
        maxArea.style.top = '0';
        maxArea.style.left = '0';
        adContainer.style.height = '100%';
        adContainer.style.width = '100%';
        drawerSideBar.style.width = '100vw';
      });
    } else {
      const {
        maxAreaHeight,
        maxAreaWidth,
        maxAreaTop,
        maxAreaLeft,
        adContainerHeight,
        adContainerWidth,
        drawerSideBarWidth,
      } = this.state.originalSizes;

      this.setState({ isFullscreen: false }, () => {
        maxArea.style.height = `${maxAreaHeight}px`;
        maxArea.style.width = `${maxAreaWidth}px`;
        maxArea.style.top = `${maxAreaTop}px`;
        maxArea.style.left = `${maxAreaLeft}px`;
        adContainer.style.height = `${adContainerHeight}px`;
        adContainer.style.width = `${adContainerWidth}px`;
        drawerSideBar.style.width = `${drawerSideBarWidth}px`;
      });
    }
  };

  // size of the the ad is defined by the placement type
  getSize = () => {
    const { orientation, device } = this.state;

    if (this.getPlacementType() !== 'banner') {
      return device === 'phone'
        ? PHONE_DEFAULT_POSITIONS_SIZES[this.getPlacementType()]
        : TABLET_DEFAULT_POSITIONS_SIZES[this.getPlacementType()];
    }

    const isPortrait = orientation === PREVIEW_SETTINGS.ORIENTATIONS.PORTRAIT;

    if (device === 'phone') {
      return {
        ...PHONE_DEFAULT_POSITIONS_SIZES.banner,
        adMaxLeft: isPortrait
          ? (PHONE_SCREEN_SIZE.width - BANNER_SMALL_SIZE.width) / 2
          : (PHONE_SCREEN_SIZE.height - BANNER_SMALL_SIZE.width) / 2,
      };
    }
    return {
      ...TABLET_DEFAULT_POSITIONS_SIZES.banner,
      adMaxLeft: isPortrait
        ? (TABLET_SCREEN_SIZE.width - BANNER_BIG_SIZE.width / 2) / 2
        : (TABLET_SCREEN_SIZE.height - BANNER_BIG_SIZE.width) / 2,
    };
  }

  getPlacementType = () => {
    const placementType = PREVIEW_SETTINGS.PLACEMENTS.FULLSCREEN;
    const keys = Object.keys(VUNGLE_PLACEMENT_TYPE_FORMATS_MAP);
    for (let i = 0, len = keys.length; i < len; i++) {
      const key = keys[i];
      if (VUNGLE_PLACEMENT_TYPE_FORMATS_MAP[key].indexOf(this.props.placementType) !== -1) {
        return key;
      }
    }

    return placementType;
  }

  getVersion = () => {
    const version = PREVIEW_SETTINGS.VERSIONS.NONE;
    const keys = Object.keys(VUNGLE_TYPE_VERSION_MAP);

    for (let i = 0, len = keys.length; i < len; i++) {
      const key = keys[i];
      if (VUNGLE_TYPE_VERSION_MAP[key].indexOf(this.props.format) !== -1) {
        return key;
      }
    }

    return version;
  }

  initializePlayStatus(p) {
    if (p.autoplay && !p.shouldHideFrame && !this.state.show) {
      this.handleShow();
    }
  }

  initializeDeviceToggle(template) {
    if (template?.isBannerTemplate) {
      const isPhoneSize = template.name.indexOf(BANNER_SMALL_SIZE.height) !== -1;
      const isTabletSize = template.name.indexOf(BANNER_BIG_SIZE.height) !== -1;
      if (isPhoneSize || isTabletSize) {
        if (isTabletSize) {
          this.toggleTablet();
        } else {
          this.togglePhone();
        }
        this.setState({
          shouldDisplayDeviceToggle: false,
        });
        return;
      }
    }
    this.setState({
      shouldDisplayDeviceToggle: true,
    });
  }

  UNSAFE_componentWillMount() {
    if (this.props.orientation && this.props.orientation === PREVIEW_SETTINGS.ORIENTATIONS.LANDSCAPE) {
      this.setState({ orientation: PREVIEW_SETTINGS.ORIENTATIONS.LANDSCAPE });
    }
  }

  componentWillUnmount = () => {
    this.stopListeningToMessages();
  }

  componentDidMount() {
    this.initializePlayStatus(this.props);
    this.initializeDeviceToggle(this.props.template);
  }

  componentDidUpdate(prevProps) {
    const { template } = this.props;
    if (!isEqual(prevProps.template, template)) {
      this.initializeDeviceToggle(template);
    }
  }

  UNSAFE_componentWillReceiveProps(p) {
    const obj = {
      adFrameURL: p.assetURL,
      blobVideo: null,
    };

    if (p.shouldHideFrame) {
      this.setState({
        loading: false,
        show: false,
      });
      return;
    }

    this.initializePlayStatus(p);

    const videoUrl = this.getVideoUrl(p);

    if (videoUrl) {
      obj.blobVideo = videoUrl;
      obj.adFrameURL = obj.blobVideo;
    }

    this.setState(obj);
  }

  resize = () => {
    if (this.state.device === 'phone') {
      this.toggleTablet();
    } else {
      this.togglePhone();
    }
  }

  rotate = () => {
    if (this.state.orientation === PREVIEW_SETTINGS.ORIENTATIONS.PORTRAIT) {
      this.setState({
        orientation: PREVIEW_SETTINGS.ORIENTATIONS.LANDSCAPE,
      });
      return;
    }

    this.setState({
      orientation: PREVIEW_SETTINGS.ORIENTATIONS.PORTRAIT,
    });
  }

  getVideoUrl = (p) => {
    let videoBlobUrl = null;
    const videoErrorRx = new RegExp(ERRORS.videoUrl, 'i');
    if (p.mainVideo && p.mainVideo.match(videoErrorRx) === null) {
      videoBlobUrl = this.videoBlobURLOfBiggestVideo(p);
    } else if (p.mainVideo && p.mainVideo.match(videoErrorRx) !== null) {
      videoBlobUrl = error404VideoBlob;
    }

    return videoBlobUrl;
  }

  refresh = () => {
    const tmp = this.state.blobVideo || this.props.assetURL;

    this.setState({
      adFrameURL: null,
    });

    const { onRefresh } = this.props;

    setTimeout(() => {
      const error = Object.keys(ERRORS).find((key) => tmp?.match(new RegExp(ERRORS[key], 'i')));
      const obj = {
        appStoreOpened: false,
        privacyPageOpened: false,
        privacyPageUrl: defPrivacyPageUrl,
      };

      if (error) {
        onRefresh();
      } else {
        obj.adFrameURL = tmp;
      }

      this.setState(obj);
    });
  }

  videoBlobURLOfBiggestVideo = (p) => generateVideoBlob(p.mainVideo)

  render() {
    const {
      orientation, adFrameURL, device, appStoreOpened, privacyPageOpened,
    } = this.state;
    let adURL = adFrameURL;
    const props = ({
      ...this.getSize(),
      ...{
        device,
        creativeId: this.props.creativeId,
        version: this.getVersion(),
        placementType: this.getPlacementType(),
        supports: PREVIEW_SETTINGS.SUPPORTS,
        orientation: this.props.orientation || orientation,
        otherAppOpened: appStoreOpened || privacyPageOpened,
        shouldHideFrame: this.props.shouldHideFrame,
        // `${adUrl}?platform=iOS&replacements=${encodeURIComponent(JSON.stringify(tplReplacements1))}`
      },
    });

    // if state show is true, set asset url to ad url
    if (this.state.show) {
      if (adURL && adURL.match(new RegExp(ERRORS.assetUrl, 'i')) !== null && !this.props.isCreativeSetPreviewer) {
        adURL = error404Blob;
      } else if (adURL && adURL.match(new RegExp(ERRORS.replacementsUrl, 'i')) !== null && !this.props.isCreativeSetPreviewer) {
        adURL = error404ReplacementsBlob;
      }
      // set adUrl as assetUrl
      props.assetURL = adURL;
    }

    // const ignoreRepaint = ['orientation'];
    // const qs = [];

    // Object.keys(props).forEach((key) => {
    //   if (ignoreRepaint.indexOf(key) !== -1) {
    //     return;
    //   }
    //   qs.push(`${key}=${encodeURIComponent(props[key])}`);
    // });

    return (
      <div
        className="DeviceFrame"
      >
        <div className="DeviceFrame__Holder">
          <div className={classNames('DeviceFrame__ContentWrapper', { fullscreen: this.state.isFullscreen })}>
            {
              !this.state.show
              && (
              <div
                className="DeviceFrame__PlayButtonWrap"
                onClick={this.handleShow}
              >
                {
                  this.props.shouldHideFrame ? (
                    <div
                      className="previewer-placeholder"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div className="text-wrapper">
                        <i className="material-icons">color_lens</i>
                        <p className="content">Build your creative by selecting options on the left.</p>
                      </div>
                    </div>
                  )
                    : <Icon.Play />
                }
              </div>
              )
            }

            {
              this.state.loading && (
                <div className="DeviceFrame__Loader">
                  <Spinner />
                </div>
              )
            }

            <div className="DeviceFrame__AdFrame">
              {
                this.props.thumbnail && !this.state.show && (
                  <div className={classNames('qa-creative-preview-thumbnail', orientation)}>
                    <img src={this.props.thumbnail} alt="thumbnail" />
                  </div>
                )
              }
              <AdFrame {...props} onLoadContent={this.onLoadContent} />
              {
                this.state.isFullscreen && (
                  <Button
                    className="fullscreen-exit-button"
                    onClick={() => this.toggleFullscreen(false)}
                  >
                    <i className="material-icons">fullscreen_exit</i>
                  </Button>
                )
              }
            </div>

            {
              this.state.appStoreOpened && (
                <div className="DeviceFrame__AppStoreFrame">
                  <AppStoreFrame showClose={props.version !== PREVIEW_SETTINGS.VERSIONS.NONE} onClose={this.handleAppStoreClose} />
                </div>
              )
            }
            {
              this.state.privacyPageOpened && (
                <div className="DeviceFrame__PrivacyFrame">
                  <PrivacyFrame privacyPage={this.state.privacyPageUrl} onClose={this.handlePrivacyPageClose} />
                </div>
              )
            }
          </div>
        </div>

        {
          !this.props.isCreativeSetPreviewer
            ? (
              <ControlButtons
                shouldDisplayDeviceToggle={this.state.shouldDisplayDeviceToggle}
                shouldDisplayTooltip={this.props.shouldDisplayTooltip}
                isMissionControl={this.props.isMissionControl}
                creativeId={this.props.creativeId}
                history={this.props.history}
                resize={this.resize}
                rotate={this.rotate}
                refresh={this.refresh}
                close={this.props.onClose}
                isShowEditCreativeAction={this.props.isShowEditCreativeAction}
              />
            )
            : (
              <AbTestingControlButtons
                show={this.state.show}
                orientation={orientation}
                handleShow={this.handleShow}
                handleStop={this.handleStop}
                refresh={this.refresh}
                toggleFullscreen={this.toggleFullscreen}
              />
            )
        }
        {
          this.props.isShowCloseBtn && (
            <div className="DeviceFrame__CloseButton">
              <Button type="ghost" onClick={this.props.onClose}>Close</Button>
            </div>
          )
        }
      </div>
    );
  }
}

DeviceFrame.propTypes = {
  qs: PropTypes.string,
  assetURL: PropTypes.string,
  mainVideo: PropTypes.string,
  placementType: PropTypes.string,
  format: PropTypes.string,
  autoplay: PropTypes.bool,
  onShow: PropTypes.func,
  onClose: PropTypes.func,
  template: PropTypes.objectOf(PropTypes.any),
  creativeId: PropTypes.string,
  isMissionControl: PropTypes.bool,
  history: PropTypes.shape({ push: PropTypes.func }),
  isShowEditCreativeAction: PropTypes.bool,
  isShowCloseBtn: PropTypes.bool,
};

DeviceFrame.defaultProps = {
  qs: '',
  assetURL: '',
  mainVideo: '',
  placementType: '',
  format: '',
  autoplay: true,
  onShow: Function.prototype,
  onClose: Function.prototype,
  template: null,
  creativeId: '',
  isMissionControl: false,
  history: { push: () => {} },
  isShowEditCreativeAction: false,
  isShowCloseBtn: false,
};

export default DeviceFrame;
