import { getGrowApplicationSortCache } from 'lib/cache/SortCache';
import { getGrowApplicationMetricCache } from 'lib/cache/MetricCache';
import { getGrowApplicationFiltersCache } from 'lib/cache/FiltersCache';
import { getGrowApplicationPageSizeCache } from 'lib/cache/PageSizeCache';
import {
  defaultSort,
  defaultFilterCache,
} from 'services/Templates/Applications/List/constants';

export const getListCaches = (userId) => ({
  metricCache: getGrowApplicationMetricCache(userId),
  pageSizeCache: getGrowApplicationPageSizeCache(userId),
  sortCache: getGrowApplicationSortCache(userId, [defaultSort]),
  filterCache: getGrowApplicationFiltersCache(userId, defaultFilterCache),
});

export default {};
