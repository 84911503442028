import {
  METRIC_CLICKS,
  METRIC_CTR,
  METRIC_ECPI,
  METRIC_ECPM,
  METRIC_IMPRESSIONS,
  METRIC_IPM,
  METRIC_IR,
  METRIC_MMP_INSTALLS,
  METRIC_SKAN_INSTALLS,
  METRIC_SPENDS,
  METRIC_D1_REVENUE,
  METRIC_D14_RETENTION,
  METRIC_D14_REVENUE,
  METRIC_D14_ROAS,
  METRIC_D1_RETENTION,
  METRIC_D1_ROAS,
  METRIC_D30_RETENTION,
  METRIC_D30_REVENUE,
  METRIC_D30_ROAS,
  METRIC_D3_RETENTION,
  METRIC_D3_REVENUE,
  METRIC_D3_ROAS,
  METRIC_D7_RETENTION,
  METRIC_D7_REVENUE,
  METRIC_D7_ROAS,
  METRIC_D7_ARPU,
  METRIC_D7_ARPPU,
  METRIC_D7_PAYING_USERS,
  METRIC_D7_PAYING_USERS_PERCENT,
} from './constants';

export const formatter = new Intl.NumberFormat();

export const numberFormat = (v) => (v ? formatter.format(v) : '0');

export const truncateValue = (v, num = 4) => (v > 0 ? v.toFixed(num) : '0.00');

export const currencyFormat = (value) => new Intl.NumberFormat(undefined, { minimumFractionDigits: 2 }).format(value);

export const currencyFn = (value) => (typeof value === 'number' ? `$${currencyFormat(truncateValue(value, 2))}` : '$0.00');

export const currencyWithNegativeFn = (value) => {
  if (typeof value === 'number') {
    return value >= 0 ? `$${currencyFormat(value.toFixed(2))}` : `-$${currencyFormat(Math.abs(value).toFixed(2))}`;
  }
  return '$0.00';
};

export const percentageFn = (value) => `${truncateValue(value * 100)}%`;

export const recommendationPercentageFn = (value) => (typeof value === 'number' ? `${truncateValue((value * 100), 2)}%` : '0%');

export const standardMetrics = {
  name: 'Standard Metrics',
  metrics: [
    {
      key: METRIC_IMPRESSIONS,
      name: 'Impressions',
      default: false,
      render: numberFormat,
      content: 'When a user enters the ad experience (video may not have started).',
    },
    {
      key: METRIC_CLICKS,
      name: 'Clicks',
      default: false,
      render: numberFormat,
      content: 'When a user clicks on the call to action (CTA) button.',
    },
    {
      key: METRIC_MMP_INSTALLS,
      name: 'MMP Installs',
      default: false,
      render: numberFormat,
      content: 'Number of user installs of an advertised application.',
    },
    {
      key: METRIC_SKAN_INSTALLS,
      name: 'SKAN Installs ',
      default: false,
      render: numberFormat,
      getter: (metric) => metric?.skanInstalls || 0,
      content: 'Number of user SKAN installs of an advertised application.',
    },
    {
      key: METRIC_SPENDS,
      name: 'Spend',
      default: false,
      render: currencyFn,
      content: 'Gross advertiser spend.',
    },
    {
      key: METRIC_ECPM,
      name: 'eCPM',
      default: false,
      render: currencyFn,
      content: `Effective Cost Per Mille:
(advertiser spend/impressions)*1000`,
    },
    {
      key: METRIC_ECPI,
      name: 'eCPI',
      default: false,
      render: currencyFn,
      content: 'Effective Cost Per Install: total spend/total installs.',
    },
    {
      key: METRIC_CTR,
      name: 'CTR',
      default: false,
      render: recommendationPercentageFn,
      content: 'Click-Through Rate: clicks/impressions as a percentage.',
    },
    {
      key: METRIC_IR,
      name: 'IR',
      default: false,
      render: recommendationPercentageFn,
      content: 'Install Rate: install/clicks as a percentage.',
    },
    {
      key: METRIC_IPM,
      name: 'IPM',
      default: false,
      render: numberFormat,
      content: 'Installs Per Mille: (installs/impressions)*1000.',
    },
  ],
};

export const LTVMetrics = {
  name: 'LTV Metrics',
  metrics: [
    {
      key: METRIC_D1_REVENUE,
      name: 'D1 Revenue',
      default: false,
      render: currencyFn,
      content: 'Revenue generated in the 1 day since install.',
    },
    {
      key: METRIC_D3_REVENUE,
      name: 'D3 Revenue',
      default: false,
      render: currencyFn,
      content: 'Revenue generated in the 3 days since install.',
    },
    {
      key: METRIC_D7_REVENUE,
      name: 'D7 Revenue',
      default: false,
      render: currencyFn,
      content: 'Revenue generated in the 7 days since install.',
    },
    {
      key: METRIC_D14_REVENUE,
      name: 'D14 Revenue',
      default: false,
      render: currencyFn,
      content: 'Revenue generated in the 14 days since install.',
    },
    {
      key: METRIC_D30_REVENUE,
      name: 'D30 Revenue',
      default: false,
      render: currencyFn,
      content: 'Revenue generated in the 30 days since install.',
    },
    {
      key: METRIC_D1_ROAS,
      name: 'D1 ROAS',
      default: false,
      render: percentageFn,
      content: 'The dollars earned per dollars spent on the corresponding advertising over a 1-day period.',
    },
    {
      key: METRIC_D3_ROAS,
      name: 'D3 ROAS',
      default: false,
      render: percentageFn,
      content: 'The dollars earned per dollars spent on the corresponding advertising over a 3-day period.',
    },
    {
      key: METRIC_D7_ROAS,
      name: 'D7 ROAS',
      default: false,
      render: percentageFn,
      content: 'The dollars earned per dollars spent on the corresponding advertising over a 7-day period.',
    },
    {
      key: METRIC_D14_ROAS,
      name: 'D14 ROAS',
      default: false,
      render: percentageFn,
      content: 'The dollars earned per dollars spent on the corresponding advertising over a 14-day period.',
    },
    {
      key: METRIC_D30_ROAS,
      name: 'D30 ROAS',
      default: false,
      render: percentageFn,
      content: 'The dollars earned per dollars spent on the corresponding advertising over a 30-day period.',
    },
    {
      key: METRIC_D1_RETENTION,
      name: 'D1 Retention',
      default: false,
      render: percentageFn,
      content: 'How many users come back to the application 3 days after installing: D1 users/D0 users.',
    },
    {
      key: METRIC_D3_RETENTION,
      name: 'D3 Retention',
      default: false,
      render: percentageFn,
      content: 'How many users come back to the application 3 days after installing: D3 users/D0 users.',
    },
    {
      key: METRIC_D7_RETENTION,
      name: 'D7 Retention',
      default: false,
      render: percentageFn,
      content: 'How many users come back to the application 7 days after installing: D7 users/D0 users.',
    },
    {
      key: METRIC_D14_RETENTION,
      name: 'D14 Retention',
      default: false,
      render: percentageFn,
      content: 'How many users come back to the application 14 days after installing: D14 users/D0 users.',
    },
    {
      key: METRIC_D30_RETENTION,
      name: 'D30 Retention',
      default: false,
      render: percentageFn,
      content: 'How many users come back to the application 30 days after installing: D30 users/D0 users.',
    },
    {
      key: METRIC_D7_ARPU,
      name: 'D7 IAP ARPU',
      default: false,
      render: percentageFn,
      content: '',
    },
    {
      key: METRIC_D7_ARPPU,
      name: 'D7 IAP ARPPU',
      default: false,
      render: percentageFn,
      content: '',
    },
    {
      key: METRIC_D7_PAYING_USERS,
      name: 'D7 # Users Paying',
      default: false,
      render: currencyFn,
      content: '',
    },
    {
      key: METRIC_D7_PAYING_USERS_PERCENT,
      name: 'D7 % Users Paying',
      default: false,
      render: currencyFn,
      content: '',
    },
  ],
};
