import React from 'react';
import CampaignApi from './CampaignApi';
import Details from './Details';
import Funds from './Funds';
import Transactions from './Transactions';
import IO from './IO';
import TermsOfService from '../../../../../components/TermsOfService/TermsOfServiceContainer';
import './overview.scss';

const Overview = ({
  props: {
    account,
    users,
    showTos,
    authUser,
  },
  actions: {
    fundAccount,
    saveAccount,
    toggleTos,
    regenerateApiKey,
  },
}) => (
  <div className="views__account__overview">
    {showTos && (
      <TermsOfService hideAccept onClose={toggleTos} />
    )}
    <Funds account={account} fundAccount={fundAccount} />
    {account.isPrePay() && (
      <div className="details-io">
        <Transactions account={account} />
        <IO account={account} toggleTos={toggleTos} />
      </div>
    )}
    <CampaignApi
      authUser={authUser}
      regenerateApiKey={regenerateApiKey}
    />
    <Details
      account={account}
      users={users}
      saveAccount={saveAccount}
    />
  </div>
);

export default Overview;
