/* eslint-disable react/display-name */
import React, { useRef, useEffect, useState } from 'react';
import imgVideogameThumbnail from 'assets/img/videogame_thumbnail.svg';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { identity, noop } from 'lodash';
import { isAdminService, isAdvertiserService } from 'lib/serviceType';
import { ACTIVE } from 'app/constants/asset';
import Tooltip from '../../../../../components/V2/Tooltip/Tooltip';
import CreativeQAStatus from '../../../../../components/Assets/ServingStatus/ServingStatus';
import triggerConfirm from '../../../../../components/Modals/ConfirmAction/triggerConfirm';
import TooltipCutoff from '../../../../../components/V2/Tooltip/TooltipCutoff';
import Table from '../../../../../components/V2/Table/Table';
import Copy from '../../../../../components/V2/Copy/Copy';
import { convert } from '../../../../../lib/date';
import { getPageTop } from '../../../../../lib/lib';
import { getAssetPageSizeCache } from '../../../../../lib/cache/PageSizeCache';
import { getAssetSortCache } from '../../../../../lib/cache/SortCache';
import { getAssetMetricCache } from '../../../../../lib/cache/MetricCache';
import { ASSET_METRIC_KEY } from '../../../../../lib/cache/constant';
import ApplicationThumbnail from '../../../../../templates/application/thumbnail';
import {
  getAssetOrientation, prettifySize, formatDuration, useMetricsColumns,
} from './utils';
import {
  DOWNLOAD_ASSET,
  EDIT_ASSET,
  DELETE_ASSET,
  TYPE_PLAYABLE,
  TYPE_VIDEO,
  columnKeys,
  assetMetricGroup,
  assetDefaultSort,
  deleteAssetActionTooltip,
} from './constant';
import './AssetList.scss';

const AssetInfoCell = ({ asset, onDetailClick }) => {
  let assetThumbnail = asset.get('url');
  const type = asset.get('type');
  switch (type) {
    case TYPE_VIDEO:
      assetThumbnail = asset.get('thumbnails.landscape');
      break;
    case TYPE_PLAYABLE:
      assetThumbnail = asset.get('thumbnails.landscape') || imgVideogameThumbnail;
      break;
    default:
      break;
  }

  const name = asset.get('name');
  const titleWrapper = (title) => <span className="name" onClick={() => onDetailClick(asset)}>{title}</span>;

  return (
    <div className="asset_info_cell">
      <div className={classNames('thumbnail_container', `thumbnail_container-${type}`)} onClick={() => onDetailClick(asset)}>
        <img className="thumbnail" src={assetThumbnail} />
      </div>
      <div className="name_and_id">
        <TooltipCutoff title={name} titleWrapper={titleWrapper} />
        <Copy value={asset.get('id')} />
      </div>
    </div>
  );
};
// No tooltip for AssetInfoCell
AssetInfoCell.noWrapInTooltip = true;

const ApplicationInfoCell = ({ application }) => {
  const applicationName = application.get('name');
  const titleWrapper = () => (
    <Link to={`/applications/${application.get('id')}`}>
      <span className="name">{applicationName}</span>
    </Link>
  );

  return (
    <div className="application_info_cell">
      <Link to={`/applications/${application.get('id')}`}>
        <ApplicationThumbnail application={application} />
      </Link>
      <div className="name_and_id">
        <TooltipCutoff title={applicationName} titleWrapper={titleWrapper} />
        <Copy value={application.get('id')} />
      </div>
    </div>
  );
};

const SimpleCell = ({ className, children }) => <span className={className}>{children}</span>;

export const DeleteAssetAction = ({ asset }) => {
  const isAttached = asset.get('is_attached');
  if (isAttached === false) return DELETE_ASSET;

  return (
    <Tooltip title={deleteAssetActionTooltip.title} body={deleteAssetActionTooltip.body}>
      <span className="disable-action-item">{DELETE_ASSET}</span>
    </Tooltip>
  );
};

const getDeleteConfirmMessage = (asset) => (
  <div>
    <p className="delete-asset-confirm-line">You are requesting to permanently delete the following asset:</p>
    <p className="delete-asset-confirm-line">
      <strong>Asset Name: </strong>
      {asset.get('name')}
    </p>
    <p className="delete-asset-confirm-line">
      <strong>Asset ID: </strong>
      {asset.get('id')}
    </p>
    <p className="delete-asset-confirm-line">This action cannot be undone. Are you sure you want to do this?</p>
  </div>
);

const makeColumn = (title, accessor) => ({
  Header: title,
  id: accessor,
  accessor: (c) => c.raw(accessor),
  sortable: true,
});

const isNotAccountColumn = ({ Header }) => (Header !== columnKeys.account.title);
const getMetricGroupForService = () => {
  if (isAdminService()) {
    return assetMetricGroup;
  }

  const [group] = assetMetricGroup;
  const { metrics } = group;

  return [{
    ...group,
    metrics: metrics.filter(
      ({ name }) => name !== columnKeys.account.title,
    ),
  }];
};

const AssetList = ({
  assets,
  getAssets,
  loading,
  onDetailClick,
  onDownloadAssetClick,
  pagination,
  authUser,
  onReviewHistoryClick,
  onDeleteAsset,
}) => {
  const pageSizeCache = getAssetPageSizeCache(authUser.account);
  const sortCache = getAssetSortCache(authUser.account);
  const metricCache = getAssetMetricCache(authUser.account);
  const listRef = useRef(null);
  const [metrics, setMetrics] = useState(metricCache.get());
  const defaultSort = sortCache.get()[0] || assetDefaultSort;

  useEffect(() => {
    const list = listRef.current;
    const top = getPageTop(list);
    list.style.setProperty('--height', `calc(100vh - ${top}px)`);
  }, []);

  const contextMenuActionMap = (asset) => ({
    ...(
      isAdminService()
        ? { [DOWNLOAD_ASSET]: () => onDownloadAssetClick(asset) }
        : undefined
    ),
    [EDIT_ASSET]: () => onDetailClick(asset),
    [DELETE_ASSET]: () => {
      if (asset.get('is_attached') === false) {
        triggerConfirm({
          type: 'DELETE_ASSET_CONFIRM_ACTION',
          header: 'Delete Asset',
          message: getDeleteConfirmMessage(asset),
          onConfirm: () => onDeleteAsset(asset),
          className: 'delete-asset-confirm',
          isAsyncConfirm: true,
        });
      }
    },
  });

  const actions = (asset) => {
    // Download Asset is not available in Self-Serve.
    const actionList = [EDIT_ASSET, { key: DELETE_ASSET, label: <DeleteAssetAction key={asset.get('id')} asset={asset} /> }];
    if (isAdminService()) {
      // If is Admin-Serve, the first action is Download Asset.
      actionList.unshift(DOWNLOAD_ASSET);
    }
    return actionList;
  };

  const onSorted = (sortValues) => {
    sortCache.save(sortValues);
    getAssets({ page: pagination.page });
  };

  const simpleCellClassName = isAdminService() ? 'p2' : 'p2';

  const baseColumns = [
    {
      ...makeColumn(columnKeys.asset.title, columnKeys.asset.key),
      width: 350,
      Cell: ({ original }) => <AssetInfoCell onDetailClick={onDetailClick} asset={original} />,
    },
    {
      ...makeColumn(columnKeys.status.title, columnKeys.status.key),
      width: 160,
      Cell: ({ original }) => {
        const isPlayable = original.get('type') === TYPE_PLAYABLE;
        const status = isPlayable ? original.get('status') : ACTIVE;
        return (
          <CreativeQAStatus
            status={status}
            withHistory={isPlayable}
            onHistoryClick={isPlayable ? () => onReviewHistoryClick(original) : noop}
          />
        );
      },
    },
    {
      ...makeColumn(columnKeys.app.title, columnKeys.app.key),
      Cell: ({ original }) => <ApplicationInfoCell application={original.get('application')} />,
    },
    {
      ...makeColumn(columnKeys.created.title, columnKeys.created.key),
      width: 150,
      Cell: ({ original }) => <SimpleCell className={simpleCellClassName}>{convert(original.get('created'), 'DD MMM YYYY')}</SimpleCell>,
    },
    {
      ...makeColumn(columnKeys.account.title, columnKeys.account.key),
      Cell: ({ original }) => {
        const accountName = original.get('account.name');
        const titleWrapper = () => (
          <Link className="name" to={`/accounts/${original.get('account.id')}`}>
            {accountName}
          </Link>
        );

        return (
          <SimpleCell className="asset_account">
            <TooltipCutoff title={accountName} titleWrapper={titleWrapper} />
            <Copy value={original.get('account.id')} />
          </SimpleCell>
        );
      },
    },
    {
      ...makeColumn(columnKeys.orientation.title, columnKeys.orientation.key),
      width: 100,
      Cell: ({ original }) => <SimpleCell className={simpleCellClassName}>{getAssetOrientation(original)}</SimpleCell>,
    },
    {
      ...makeColumn(columnKeys.dimension.title, columnKeys.dimension.key),
      width: 100,
      Cell: ({ original }) => (
        <SimpleCell className={simpleCellClassName}>
          {original.get('exif.width') && original.get('exif.height') ? `${original.get('exif.width')}x${original.get('exif.height')}` : '-'}
        </SimpleCell>
      ),
    },
    {
      ...makeColumn(columnKeys.size.title, columnKeys.size.key),
      width: 80,
      Cell: ({ original }) => <SimpleCell className={simpleCellClassName}>{prettifySize(original.get('exif.size'))}</SimpleCell>,
    },
    {
      ...makeColumn(columnKeys.duration.title, columnKeys.duration.key),
      width: 80,
      Cell: ({ original }) => {
        const duration = original.get('exif.duration');
        return (
          <SimpleCell className={simpleCellClassName}>
            {duration ? formatDuration(duration, true) : '-'}
          </SimpleCell>
        );
      },
    },
  ].filter(
    isAdvertiserService()
      ? isNotAccountColumn
      : identity,
  );

  const metricGroup = getMetricGroupForService();
  const columns = useMetricsColumns({ baseColumns, metrics, metricGroup });

  return (
    <div className="views__assets__list_2" ref={listRef}>
      <Table
        maxHeight="100%"
        containerHeight="100%"
        manual
        resizable
        onSorted={onSorted}
        loading={loading}
        showPagination
        actionable
        page={pagination.page}
        total={pagination.total}
        pageSize={pagination.perPage}
        onPageSizeChange={(page, perPage) => {
          if (loading) return;
          pageSizeCache.save(perPage);
          getAssets({ page, perPage });
        }}
        data={assets}
        columns={columns}
        actionList={actions}
        onActionChange={(asset, action) => contextMenuActionMap(asset)[action.key || action]()}
        defaultSort={defaultSort}
        metricType={ASSET_METRIC_KEY}
        metricUserId={authUser.account}
        metricGroup={metricGroup}
        onMetricsChange={(val) => setMetrics(val)}
        metricOverlayHeight={560}
      />
    </div>
  );
};

export default AssetList;
