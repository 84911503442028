import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import AnimateHeight from 'react-animate-height';
import './MiniCollapse.scss';

const classPrefix = 'v2_component_mini_collapse';

const MiniCollapse = ({
  className,
  expanded: expandedProp,
  defaultExpanded,
  onChange,
  header,
  children,
  collapsible,
  duration,
  onAnimationEnd,
  animateHeightStyle,
}) => {
  const [privateExpanded, setPrivateExpanded] = useState(defaultExpanded);
  const isStateControl = useMemo(() => typeof expandedProp === 'boolean', [expandedProp]);
  const expanded = useMemo(() => (isStateControl ? expandedProp : privateExpanded),
    [isStateControl, expandedProp, privateExpanded]);

  const onExpand = () => {
    if (!collapsible) return;
    if (!isStateControl) setPrivateExpanded(!expanded);
    onChange(!expanded);
  };

  return (
    <div className={classnames(classPrefix, className)}>
      <div className={`${classPrefix}-header`} onClick={onExpand}>
        <div className="header-content">
          {header}
        </div>
        <div className="header-icon">
          {collapsible && (
            <i className={classnames('material-icons', 'icon', {
              expandedIcon: expanded,
            })}
            >
              expand_less
            </i>
          )}
        </div>
      </div>
      <div className={classnames(`${classPrefix}-detail`)}>
        <AnimateHeight
          duration={duration}
          height={expanded ? 'auto' : 0}
          onAnimationEnd={onAnimationEnd}
          style={animateHeightStyle}
        >
          <div className="detail-body">
            {children}
          </div>
        </AnimateHeight>
      </div>
    </div>
  );
};

MiniCollapse.propTypes = {
  className: PropTypes.string,
  defaultExpanded: PropTypes.bool,
  expanded: PropTypes.bool,
  onChange: PropTypes.func,
  header: PropTypes.node,
  children: PropTypes.node,
  collapsible: PropTypes.bool,
  duration: PropTypes.number,
  onAnimationEnd: PropTypes.func,
};

MiniCollapse.defaultProps = {
  className: null,
  expanded: null,
  defaultExpanded: false,
  onChange: Function.prototype,
  header: null,
  children: null,
  collapsible: true,
  duration: 300,
  onAnimationEnd: Function.prototype,
};

export default MiniCollapse;
