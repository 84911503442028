import React from 'react';
import PropTypes from 'prop-types';
import { noop, map } from 'lodash';
import { assetOrientation } from '../../constant';
import MultiPanel from './MultiPanel';

const renderItemLabel = (prefixCls, { text }) => (
  <div className={`${prefixCls}-item`}>{text}</div>
);
const AssetOrientationPanel = ({
  prefixCls,
  defaultCheckedNodes,
  onVisibleChange,
  onChange,
}) => {
  const handleChange = React.useCallback((_selectedAds, isAllSelected) => {
    onVisibleChange(false);
    onChange(_selectedAds, isAllSelected);
  }, [onVisibleChange, onChange]);

  return (
    <MultiPanel
      height={196}
      data={assetOrientation}
      renderItemLabel={(item) => renderItemLabel(prefixCls, item)}
      defaultCheckedKeys={map(defaultCheckedNodes, 'id')}
      onVisibleChange={onVisibleChange}
      onChange={handleChange}
    />
  );
};

AssetOrientationPanel.propTypes = {
  prefixCls: PropTypes.string,
  defaultCheckedNodes: PropTypes.arrayOf(PropTypes.any),
  onVisibleChange: PropTypes.func,
  onChange: PropTypes.func,
};

AssetOrientationPanel.defaultProps = {
  prefixCls: '',
  defaultCheckedNodes: [],
  onVisibleChange: noop,
  onChange: noop,
};

export default React.memo(AssetOrientationPanel);
