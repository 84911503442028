import { map } from 'lodash';
import { getClient } from 'app/graphql/setup';
import handleGraphQLError from 'app/graphql/utils/handle-graphql-error';
import { getSearchAndIDsFilter } from 'app/graphql/utils/helper';
import {
  GetExchangeConnections,
} from '../operations_exchangeconnection.js.graphql';

export function convertToRestFormat(ec) {
  if (!ec) {
    return undefined;
  }

  return {
    id: ec.id,
    is_handle_nbr: ec.noBidReasonEnabled,
    name: ec.name,
    secret_key: ec.secretKey,
    status: ec.status,
    targeting: {
      target_bundle_ids: map(ec.targetBundleIDs, (tbi) => ({
        bundle_id: tbi.bundleID,
      })),
      target_bundle_type: ec.targetBundleType,
    },
  };
}

function handleEcResponse({
  data: {
    exchangeConnections,
  } = {},
}) {
  return {
    ok: true,
    status: 200,
    response: exchangeConnections.exchangeConnections.map(convertToRestFormat),
    pagination: exchangeConnections.pagination,
  };
}

export function getExchangeConnections(filters) {
  const variables = {
    ...getSearchAndIDsFilter(filters),
    page: Number(filters.page),
    per_page: Number(filters.perPage),
  };

  return getClient()
    .query({
      query: GetExchangeConnections,
      variables,
    })
    .then(handleEcResponse)
    .catch(handleGraphQLError);
}
