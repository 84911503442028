import { getStore } from '../../../app/storeprovider';
import { isUndefined } from '../../lib';
import Account from '../../../models/Account';
import User from '../../../models/User';

const getAppConfig = (model, cb, defaultValue) => {
  const state = getStore()?.getState();
  try {
    const config = { ...state.setup.appConfig };
    config.account = new Account(config.account);
    config.user = new User(config.user);
    if (model && cb) {
      const rtValue = cb(config[model]);
      return isUndefined(rtValue) ? defaultValue : rtValue;
    }
    return config;
  } catch (ex) {
    return isUndefined(defaultValue) ? null : defaultValue;
  }
};

export const checkIsFree = (price) => Number(price || 0) === 0;

export default getAppConfig;
