import { QUICK_MAP } from '../../../../../../../components/V2/Report/Calendar/PickerQuick';
import { QUICK_KEY_LAST_60_DAYS } from './constants';

export const DATE_RANGE_QUICK_MAP = {
  ...QUICK_MAP,
  [QUICK_KEY_LAST_60_DAYS]: {
    text: 'Last Two Months',
    generateDate(today) {
      const yesterday = today.clone()
        .subtract(1, 'days').hours(23).minutes(59)
        .seconds(59);
      const sixDaysAgo = yesterday.clone()
        .subtract(60, 'days').hours(0).minutes(0)
        .seconds(0);
      return {
        startDate: sixDaysAgo,
        endDate: yesterday,
      };
    },
  },
};

export default {};
