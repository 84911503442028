export default [
  '360',
  '5s',
  '10s',
  '15s',
  '30s',
  '3page',
  '45s',
  'action',
  'alcohol',
  'android_content-everyone',
  'android_content-everyone 10+',
  'android_content-general',
  'android_content-high maturity',
  'android_content-low maturity',
  'android_content-mature 17+',
  'android_content-medium maturity',
  'android_content-pegi 12',
  'android_content-pegi 18',
  'android_content-pegi 3',
  'android_content-pegi 7',
  'android_content-teen',
  'android_content-undefined',
  'android_content-unrated',
  'android_content-usk: ages 6+',
  'arcade',
  'asoi',
  'asoi_low',
  'bingo',
  'blood',
  'board',
  'brand',
  'bubble shooter',
  'builder',
  'card',
  'casino',
  'casual',
  'certified',
  'client',
  'creative_tag_1',
  'creative_tag_2',
  'creative_tag_3',
  'creative_tag_4',
  'creative_testing',
  'cooking',
  'dating',
  'dice',
  'direct',
  'dynamic localization',
  'ea prohibited',
  'either orientation',
  'endcard only',
  'exchange',
  'experimental',
  'farmer',
  'fullscreen',
  'fullscreenclickable_video',
  'gold rush!',
  'hard-core',
  'harry potter approved',
  'iec',
  'incentivizer',
  'ios_content-12+',
  'ios_content-17+',
  'ios_content-4+',
  'ios_content-9+',
  'ios_content-g',
  'ios_content-nr',
  'ios_content-pg',
  'ios_content-unrated',
  'kakao',
  'managed',
  'managed-vpn',
  'matching',
  'mid-core',
  'mmorpg',
  'no timer',
  'non-gaming',
  'noncoppa',
  'NRO',
  'N00_V',
  'N01_V',
  'N02_V',
  'N03_V',
  'N04_V',
  'N05_V',
  'N06_V',
  'N07_V',
  'N08_V',
  'N09_V',
  'N10_V',
  'N00_EC',
  'N01_EC',
  'N02_EC',
  'N03_EC',
  'N04_EC',
  'N05_EC',
  'N06_EC',
  'N07_EC',
  'N08_EC',
  'N09_EC',
  'N10_EC',
  'open',
  'openrtb',
  'piec',
  'poker',
  'puzzle',
  'racing',
  'real money gambling',
  'rpg',
  'runner',
  'sexuality',
  'sexually suggestive',
  'shooting',
  'simulation',
  'slots',
  'social casino',
  'solitaire',
  'strategy',
  'storekit',
  'subscription',
  'tactical',
  'timer',
  'unassigned tag 1',
  'unassigned tag 2',
  'unassigned tag 3',
  'unassigned app tag 1',
  'unassigned app tag 2',
  'unassigned app tag 3',
  'unassigned app tag 4',
  'unassigned app tag 5',
  'unassigned creative tag 1',
  'unassigned creative tag 2',
  'unassigned creative tag 3',
  'unassigned creative tag 4',
  'unassigned creative tag 5',
  'vetted',
  'violence',
  'virtualreality',
  'vungle',
  'windows_content-alle altersgruppen',
  'windows_content-everyone',
  'windows_content-everyone 10+',
  'windows_content-for ages 10 and up',
  'windows_content-for ages 12 and up',
  'windows_content-for ages 13 and up',
  'windows_content-for ages 17 and up',
  'windows_content-for ages 3 and up',
  'windows_content-for ages 6 and up',
  'windows_content-for ages 7 and up',
  'windows_content-for all ages',
  'windows_content-mature 17+',
  'windows_content-teen',
  'windows_content-unrated',
];
