import React from 'react';
import PropTypes from 'prop-types';
import Menu from '../../../../Dropdown/Menu/Menu';
import Tooltip from '../../../../Tooltip/Tooltip';
import MenuItem from '../../../../Dropdown/Menu/MenuItem';
import './menuContainer.scss';

const prefixCls = 'v2_component_filters_multiTree-wrapper-menu';

function initNodes(nodes, checkedNodes, maxSelected) {
  if (maxSelected) {
    return nodes.map((node) => ({
      ...node,
      disabled: checkedNodes.length >= maxSelected && checkedNodes.findIndex((checkedNode) => checkedNode.id === node.id) === -1,
    }));
  }
  return nodes;
}

function MenuContainer({
  menuNodes,
  checkedNodes,
  maxSelected,
  disabledTitle,
  disabledContent,
  renderMenu,
  onChange,
}) {
  const nodes = initNodes(menuNodes, checkedNodes, maxSelected);

  const onMenuChange = React.useCallback((_checkedNodes) => {
    const newCheckedNodes = [];

    checkedNodes.forEach((checkedNode) => {
      // only add not menu nodes
      if (menuNodes.findIndex((mn) => mn.id === checkedNode.id) === -1) {
        newCheckedNodes.push(checkedNode);
      }
    });

    onChange(newCheckedNodes.concat(_checkedNodes));
  }, [checkedNodes, menuNodes, onChange]);

  const getCheckedKeys = React.useCallback(() => {
    const checkedKeys = [];
    checkedNodes.forEach((checkedNode) => {
      // only add menu nodes
      if (menuNodes.findIndex((mn) => mn.id === checkedNode.id) !== -1) {
        checkedKeys.push(checkedNode.id);
      }
    });
    return checkedKeys;
  }, [checkedNodes, menuNodes]);

  return (
    <div className={`${prefixCls}`}>
      <Menu onChange={onMenuChange} checkedKeys={getCheckedKeys()}>
        {
          nodes.map((menu) => (
            menu.disabled ? (
              <Tooltip key={menu.id} placement="bottomLeft" title={disabledTitle} body={disabledContent}>
                <MenuItem
                  key={menu.id}
                  data={menu}
                  dataKey={menu.id}
                  disabled={menu.disabled}
                >
                  {renderMenu(menu)}
                </MenuItem>
              </Tooltip>
            ) : (
              <MenuItem
                key={menu.id}
                data={menu}
                dataKey={menu.id}
              >
                {renderMenu(menu)}
              </MenuItem>
            )
          ))
        }
      </Menu>
    </div>
  );
}

MenuContainer.propTypes = {
  checkedNodes: PropTypes.arrayOf(
    PropTypes.any,
  ).isRequired,
  renderMenu: PropTypes.func,
};

MenuContainer.defaultProps = {
  renderMenu: (menu) => menu.name,
};

export default React.memo(MenuContainer);
