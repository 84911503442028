import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  identity,
  isEmpty,
  noop,
} from 'lodash';
import { withRouter } from 'react-router';
import { ReactTableDefaults } from 'react-table';
import PaginationComponent from '../Table2/Components/Pagination';
import TableContainer from '../Table/TableContainer';
import ListViewContainer from '../Templates/ListView/ListViewContainer';
import { buildQueryObject } from '../../lib/lib';
import './DeviceListSearchable.scss';

const getFilteredDeviceList = (data, search) => {
  const models = data
    .map(
      ({ model = '' }) => model,
    );
  const isMatchForSearch = (model) => model
    .toLowerCase()
    .indexOf(search.toLowerCase()) > -1;

  return search
    ? models.filter(isMatchForSearch)
    : models;
};

const TableWithPagination = ({ models }) => {
  /**
   * The components/Table[2] components are so hacky!
   * They do an Object.assign on ReactTableDefaults,
   * so the most recent importer of a table
   * determines which defaults descendent views get.
   *
   * Without this re-setting of defaults, this component
   * does not have control over which pagination component
   * it ends up rendering.
   *
   * This is definitely poor practice, but necessary
   * until all uses of the table components can be
   * accounted for and re-written.
   */
  Object.assign(ReactTableDefaults, {
    PaginationComponent,
    getPaginationProps: () => ({}),
  });

  return (
    <TableContainer
      defaultPageSize={10}
      showPagination
      showPageSizeOptions={false}
      data={models}
      columns={[
        {
          filterable: false,
          resizable: false,
          sortable: false,
          value: identity,
          minWidth: 400,
        },
      ]}
      makeActions={() => null}
    />
  );
};

const DeviceListSearchable = ({
  deviceList,
  location,
  match,
  history,
}) => {
  const queryParams = buildQueryObject();
  const router = {
    queryParams,
    location,
    match,
    history,
  };
  const [filteredDeviceList, setFilteredDeviceList] = useState(getFilteredDeviceList(deviceList, ''));

  return (
    <div
      className="device-list-searchable"
    >
      <ListViewContainer
        cta=""
        to=""
        include={{
          search: true,
        }}
        searchPlaceholderText="Search by model name..."
        router={router}
        location={router.location}
        history={router.history}
        refreshList={noop}
        results={filteredDeviceList}
        rowHeight={18}
        includeActions={false}
        noResults={isEmpty(filteredDeviceList)}
        loading={false}
        tableTitles={[]}
        renderResult={() => ({})}
        renderInstead={(
          <TableWithPagination
            models={filteredDeviceList}
          />
        )}
        onFilterChange={({ search }) => {
          setFilteredDeviceList(getFilteredDeviceList(deviceList, search));
        }}
      />
    </div>
  );
};

DeviceListSearchable.propTypes = {
  deviceList: PropTypes.arrayOf(
    PropTypes.shape({
      model: PropTypes.string.isRequired,
    }),
  ),
};

DeviceListSearchable.defaultProps = {
  deviceList: [],
};

export default withRouter(DeviceListSearchable);
