import React from 'react';
import { FILTER_KEY_TEMPLATE_PROTOCOL } from 'app/constants/filters';
import CommonMultiPicker from '../CommonMultiPicker/CommonMultiPicker';
import { templateProtocol } from '../constant';

const MultiTemplateProtocol = (props) => (
  <CommonMultiPicker data={templateProtocol} filterKey={FILTER_KEY_TEMPLATE_PROTOCOL} {...props} />
);

export default MultiTemplateProtocol;
