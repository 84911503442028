const generateVideoBlob = (mainVideo) => {
  const html = /*html*/`<html>
    <head>
      <style>
        body {
          margin: 0;
          padding: 0;
          background: #00081C;
        }
        video {
          width: 100%;
          height: 100%;
        }

        header {
          position: fixed;
          width: 100%;
          height: 50px;
          z-index: 101;
        }

        .hidden {
          display: none;
        }
        header .unmute-video-wrap,
        header .mute-video-wrap {
          width: 40px;
          height: 40px;
          padding: 10px 20px 20px 10px;
          cursor: pointer;
          position: relative;
          float: left;
        }

        header .close {
          width: 25px;
          height: 25px;
          padding: 10px 10px 20px 20px;
          cursor: pointer;
          position: relative;
          float: right;
        }

        header .close svg,
        header .unmute-video-wrap svg,
        header .mute-video-wrap svg {
          width: 100%;
          height: 100%;
        }

        #closeBtn {
          fill: #fff;
          stroke: #6C6D6D;
          stroke-width: 10;
          stroke-miterlimit: 10;
        }

        .progress-bar {
          width: 100%;
          position: absolute;
          height: 3px;
          bottom: 0;
        }

        .progress-bar span {
          height: 100%;
          background: #01b27a;
          position: absolute;
        }
      </style>
    </head>
    <body>

      <header class="header">
        <div class="sound">
          <div id="unmuteVideoBtn" class="unmute-video-wrap hidden">
            <svg data-vgl-gesture="unmute-video" class="unmute-video">
              <use xlink:href="#unmute-video"></use>
            </svg>
          </div>
          <div id="muteVideoBtn" class="mute-video-wrap">
            <svg data-vgl-gesture="mute-video" class="mute-video">
              <use xlink:href="#mute-video"></use>
            </svg>
          </div>
        </div>
        <div id="closeBtn" class="close">
          <svg data-vgl-gesture="close-icon" class="close-btn">
            <use xlink:href="#close-icon"></use>
          </svg>
        </div>
      </header>

      <video id="videoPlayer" autoplay muted class="video" ad-outlet="video" data-vgl-gesture="video" playsinline>
        <source src=${mainVideo} />
      </video>

      <div class="progress-bar"><span></span></div>

      <div id="icons" style="display: none;" >
        <svg aria-hidden="true" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <defs>

            <symbol id="close-icon" viewBox="0 0 525.4 525.5" style="enable-background:new 0 0 525.4 525.5;" xml:space="preserve">
            <title>close-icon</title>
            <polygon 
                class="closeBtn" 
                points="467.6,8.9 262.9,213.6 58.1,8.9 9,58 213.8,262.7 9,467 58.1,516.1 262.9,311.8 467.6,516.1     516.7,467 311.9,262.7 516.7,58"/>
            </symbol>

            <symbol id="mute-video" viewBox="0 0 500 500">
            <title>mute-video</title>
            <style>
              .st01{opacity:.8}
              .st11,.st21{fill:#fff}
              .st21{display:none}
            </style>
            <circle class="st01" cx="249.9" cy="249.9" r="247"/>
            <path class="st11" d="M418.9 195.8c-3.2-3.2-8.2-3.2-11.4 0l-45.6 45.6-45.6-45.6c-3.2-3.2-8.2-3.2-11.4 0-3.2 3.2-3.2 8.2 0 11.4l45.6 45.6-45.6 45.6c-3.2 3.2-3.2 8.2 0 11.4 1.8 1.8 4.1 2.3 5.9 2.3s4.1-.9 5.9-2.3l45.6-45.6 45.6 45.6c1.8 1.8 4.1 2.3 5.9 2.3s4.1-.9 5.9-2.3c3.2-3.2 3.2-8.2 0-11.4l-45.6-45.6 45.6-45.6c2.9-2.3 2.9-8.2-.8-11.4zM268.2 89.2c-2.3-1.7-6.8-.9-9.1.9l-106.7 79.5H95.2c-5 0-8.2 2.9-8.2 7.6v144c0 4.6 3.2 7.6 8.2 7.6h57.2l106.7 80c1.8.9 3.2 1.7 5 1.7.9 0 2.3 0 3.2-.9 2.3-1.7 5-3.8 5-6.8V95.5c.9-1.7-.9-4.6-4.1-6.3zM148.2 314h-44.8V186.1h44.8V314zm108.5 72.9l-92.1-69v-136l91.7-69v274h.4z"/>
            <path class="st21" d="M268.5 89c-2.3-1.7-6.8-.9-9.2.9l-107 79.9H94.9c-5 0-8.2 3-8.2 7.7V322c0 4.6 3.2 7.7 8.2 7.7h57.4L259.4 410c1.8.9 3.2 1.7 5 1.7.9 0 2.3 0 3.2-.9 2.3-1.7 5-3.8 5-6.8V95.3c1-1.7-.8-4.6-4.1-6.3zM148.2 314.6h-44.9V186.2h44.9v128.4zm108.9 73.1l-92.4-69.3V182l92-69.2v274.9h.4zm149-127.2h-90.5c-4.2 0-7.7-3.5-7.7-7.9s3.4-7.9 7.7-7.9h90.5c4.2 0 7.7 3.5 7.7 7.9s-3.4 7.9-7.7 7.9zm.9-94.6l-88.6 37.6c-3.9 1.6-8.4-.3-10-4.3-1.6-4 .3-8.6 4.1-10.2l88.6-37.6c3.9-1.6 8.4.3 10 4.3 1.6 3.9-.2 8.6-4.1 10.2zm4.7 182.9c-1.6 4-6.1 5.9-10 4.3l-88.6-37.6c-3.9-1.6-5.8-6.2-4.1-10.2 1.6-4 6.1-5.9 10-4.3l88.6 37.6c3.9 1.6 5.8 6.2 4.1 10.2z"/>
            </symbol>

            <symbol id="unmute-video" viewBox="0 0 500 500">
            <title>unmute-video</title>
            <style>
              .st01{opacity:.8}
              .st31{display:none}
              .st31,.st41{fill:#fff}
            </style>
            <circle class="st01" cx="249.9" cy="249.9" r="247"/>
            <path class="st31" d="M418.9 195.8c-3.2-3.2-8.2-3.2-11.4 0l-45.6 45.6-45.6-45.6c-3.2-3.2-8.2-3.2-11.4 0-3.2 3.2-3.2 8.2 0 11.4l45.6 45.6-45.6 45.6c-3.2 3.2-3.2 8.2 0 11.4 1.8 1.8 4.1 2.3 5.9 2.3s4.1-.9 5.9-2.3l45.6-45.6 45.6 45.6c1.8 1.8 4.1 2.3 5.9 2.3s4.1-.9 5.9-2.3c3.2-3.2 3.2-8.2 0-11.4l-45.6-45.6 45.6-45.6c2.9-2.3 2.9-8.2-.8-11.4zM268.2 89.2c-2.3-1.7-6.8-.9-9.1.9l-106.7 79.5H95.2c-5 0-8.2 2.9-8.2 7.6v144c0 4.6 3.2 7.6 8.2 7.6h57.2l106.7 80c1.8.9 3.2 1.7 5 1.7.9 0 2.3 0 3.2-.9 2.3-1.7 5-3.8 5-6.8V95.5c.9-1.7-.9-4.6-4.1-6.3zM148.2 314h-44.8V186.1h44.8V314zm108.5 72.9l-92.1-69v-136l91.7-69v274h.4z"/>
            <path class="st41" d="M268.5 89c-2.3-1.7-6.8-.9-9.2.9l-107 79.9H94.9c-5 0-8.2 3-8.2 7.7V322c0 4.6 3.2 7.7 8.2 7.7h57.4L259.4 410c1.8.9 3.2 1.7 5 1.7.9 0 2.3 0 3.2-.9 2.3-1.7 5-3.8 5-6.8V95.3c1-1.7-.8-4.6-4.1-6.3zM148.2 314.6h-44.9V186.2h44.9v128.4zm108.9 73.1l-92.4-69.3V182l92-69.2v274.9h.4zm149-127.2h-90.5c-4.2 0-7.7-3.5-7.7-7.9s3.4-7.9 7.7-7.9h90.5c4.2 0 7.7 3.5 7.7 7.9s-3.4 7.9-7.7 7.9zm.9-94.6l-88.6 37.6c-3.9 1.6-8.4-.3-10-4.3-1.6-4 .3-8.6 4.1-10.2l88.6-37.6c3.9-1.6 8.4.3 10 4.3 1.6 3.9-.2 8.6-4.1 10.2zm4.7 182.9c-1.6 4-6.1 5.9-10 4.3l-88.6-37.6c-3.9-1.6-5.8-6.2-4.1-10.2 1.6-4 6.1-5.9 10-4.3l88.6 37.6c3.9 1.6 5.8 6.2 4.1 10.2z"/>
            </symbol>

          </defs>
        </svg>
      </div>
      <script>
        var video = document.getElementById("videoPlayer");
        var muteBtn = document.getElementById("muteVideoBtn");
        var unmuteBtn = document.getElementById("unmuteVideoBtn");
        var closeBtn = document.getElementById("closeBtn");

        function closeVideo() {
          parent.window.parent.window.postMessage('close_video', '*')
        }

        video.addEventListener("timeupdate", function (evt) {
          document.querySelector(".progress-bar span").style.width = ((video.currentTime / video.duration) * 100) + "%";

          if (video.currentTime >= video.duration) {
            closeVideo();
          }
        });

        closeBtn.addEventListener("click" , closeVideo);

        unmuteBtn.addEventListener("click", function() {
          unmuteBtn.style.display = "none";
          muteBtn.style.display = "block";
          video.muted = true;
        });

        muteBtn.addEventListener("click", function() {
          muteBtn.style.display = "none";
          unmuteBtn.style.display = "block";
          video.muted = false;
        });

        // in case no duration is send for 2s, close the video frame
        setTimeout(function () {
          if (Number.isNaN(video.duration)) {
            closeVideo()
          }
        }, 2e3);
      </script>
    </body>
  </html>`;

  return URL.createObjectURL(new Blob([html], { type: 'text/html' }));
};

export default generateVideoBlob;
