import { format } from '../../../lib/currency';
import { isHexId } from '../../../lib/lib';
import config from '../../../lib/config';
import Application from '../../../models/Application';
import {
  validateGeoRate,
  isValidLongTailBid,
  isValidLongTailGeo,
  validatePublisherRate,
} from '../../../lib/helpers/campaigns/campaignIndex';
import {
  KEY_BID, KEY_GEO, KEY_PUB_ID, KEY_ROW_MSG, KEY_NAME, KEY_INVALID, ERRORS,
} from './constant';

const maxBidForLongTail = config.get('countryData.maxBidForLongTail');

export function getRateErrorMsg(min, max, isLongTailValid) {
  const minStr = isLongTailValid || isValidLongTailBid(min)
    ? format(min)
    : format(0);
  const maxStr = isLongTailValid
    ? format(max)
    : format(maxBidForLongTail);

  return `Min: ${minStr} - Max: ${maxStr}`;
}

export const validatePublisherId = async (data, newBid) => {
  let hasHexError = false;
  let hasDuplicateError = false;
  let hasInvalidPubIdError = false;
  const errors = {};
  const multiBid = [...data];
  multiBid.splice(0, 1, newBid);
  const ids = [];
  const geoIds = {};
  for (let i = 0; i < multiBid.length; i++) {
    const bid = multiBid[i];
    // Fix issue: check duplicate id when the same id has same geo
    const geos = geoIds[bid.pub_app_id] || [];
    // check for valid Hex ID
    if (isHexId(bid.pub_app_id) || bid.pub_app_id === '*') {
      // check for duplicate id
      if (bid.pub_app_id === '*') {
        if (bid.geo && bid.geo !== '') {
          // geo_rates.push(bid);
        } else {
          hasHexError = true;
          break;
        }
        if (geos.includes(bid.geo)) {
          hasDuplicateError = true;
          break;
        }
      } else if (ids.includes(bid.pub_app_id)) {
        if (geos.includes(bid.geo || '')) {
          hasDuplicateError = true;
          break;
        }
      } else {
        ids.push(bid.pub_app_id);
      }
      geos.push(bid.geo);
      geoIds[bid.pub_app_id] = geos;
    } else if (bid.pub_app_id || (bid.pub_app_id === '' && bid.rate)) {
      hasHexError = true;
      break;
    }
  }
  if (!hasHexError && !hasDuplicateError && newBid.pub_app_id !== '*') {
    const result = await Application.getAllPubs({ idIn: newBid.pub_app_id });
    const { response } = result;
    hasInvalidPubIdError = true;
    if (response && response.length) {
      const validPubList = response.filter((bid) => bid.id === newBid.pub_app_id);
      if (validPubList.length) {
        const bid = newBid;
        bid[KEY_NAME] = validPubList[0][KEY_NAME];
      }
      hasInvalidPubIdError = !validPubList.length;
    }
  }
  if (hasHexError) {
    errors[KEY_PUB_ID] = ERRORS.PUB_ID_HEX_ERROR;
  } else if (hasDuplicateError) {
    errors[KEY_PUB_ID] = ERRORS.PUB_ID_DUPLICATE;
  } else if (hasInvalidPubIdError) {
    errors[KEY_PUB_ID] = ERRORS.PUB_ID_INVALID;
  }
  return errors;
};
export const validateBiddingRow = async (multiData, campaign, bid, min, max) => {
  const errors = {};
  let isRateValid = true;
  let isLongTailValid = true;
  let isGeoValid = true;
  let pubIdError = {};
  const pubIdHasValue = bid[KEY_PUB_ID] && bid[KEY_PUB_ID].length;
  const geoHasValue = bid[KEY_GEO] && bid[KEY_GEO].length;
  const rateHasValue = bid[KEY_BID] && bid[KEY_BID].length;
  // combination 1: pub_id, bid, geo
  if (pubIdHasValue && geoHasValue && rateHasValue) {
    isRateValid = validatePublisherRate(bid[KEY_BID], min, max);
    isLongTailValid = isValidLongTailGeo(campaign, bid[KEY_GEO], bid[KEY_BID]);
    isGeoValid = validateGeoRate(campaign.get('targeting.geo.countries'), bid[KEY_GEO]);
    pubIdError = await validatePublisherId(multiData, bid);
    // combination 2: pub_id, bid
  } else if (pubIdHasValue && rateHasValue) {
    isRateValid = validatePublisherRate(bid[KEY_BID], min, max);
    pubIdError = await validatePublisherId(multiData, bid);
    // combination 3: country, bid
  } else if (geoHasValue && rateHasValue) {
    isRateValid = validatePublisherRate(bid[KEY_BID], min, max);
    isLongTailValid = isValidLongTailGeo(campaign, bid[KEY_GEO], bid[KEY_BID]);
    isGeoValid = validateGeoRate(campaign.get('targeting.geo.countries'), bid[KEY_GEO]);
    pubIdError = await validatePublisherId(multiData, { ...bid, [KEY_PUB_ID]: '*' });
  }
  // some require fields are empty
  if (!Object.keys(errors).length) {
    const pubIdEmpty = !bid[KEY_PUB_ID] || !bid[KEY_PUB_ID].length;
    const geoEmpty = !bid[KEY_GEO] || !bid[KEY_GEO].length;
    const rateEmpty = !bid[KEY_BID] || !bid[KEY_BID].length;
    if (pubIdEmpty && geoEmpty && rateHasValue) {
      errors[KEY_ROW_MSG] = ERRORS.EMPTY_PUBID_GEO;
    } else if (pubIdEmpty && geoHasValue && rateEmpty) {
      errors[KEY_ROW_MSG] = ERRORS.EMPTY_PUBID_BID;
    } else if (pubIdHasValue && geoEmpty && rateEmpty) {
      errors[KEY_ROW_MSG] = ERRORS.EMPTY_BID_GEO;
    } else if (pubIdHasValue && geoHasValue && rateEmpty) {
      errors[KEY_ROW_MSG] = ERRORS.EMPTY_BID;
    } else if (pubIdEmpty && geoEmpty && rateEmpty) {
      errors[KEY_ROW_MSG] = ERRORS.EMPTY_PUBID_GEO;
    }
  }
  if (!isRateValid || !isLongTailValid) {
    errors[KEY_BID] = getRateErrorMsg(min, max, isLongTailValid);
  }
  if (!isGeoValid) {
    errors[KEY_GEO] = ERRORS.GEO_INVALID;
  }
  const errorObject = { ...errors, ...pubIdError };
  campaign.set('multibidding.errorMessage', Object.keys(errorObject).map((key) => errorObject[key]).join(';'));
  return { ...errors, ...pubIdError };
};
export const campaignMultiDataValidate = (value, attri, min, max) => {
  if (attri === KEY_BID) {
    return validatePublisherRate(value, min, max);
  }
  return true;
};

export const saveCampaignMultiData = (multiData, campaign) => {
  const list = [...multiData];
  for (let i = 0; i < list.length; i++) {
    const item = list[i];
    item.rate = Number(item.rate);
    if (!item[KEY_PUB_ID].length && !item[KEY_INVALID]) {
      item[KEY_PUB_ID] = '*';
      item[KEY_NAME] = '*';
    }
  }
  campaign.set('budget.publisher_rates', list);
};

// Rule: `name` filed can be insensitive, `pub_app_id` should be strict matching.
export const filterRatesData = (data, keyword) => {
  if (!data) return [];
  if (!keyword) return data;
  const upperCaseKeyword = keyword.toUpperCase();
  return data.filter(
    (item) => item[KEY_PUB_ID].toUpperCase() === upperCaseKeyword
    || item[KEY_NAME].toUpperCase().includes(upperCaseKeyword),
  );
};

export const shouldDisableAddRowButton = (multiData) => {
  if (multiData.length) {
    const [firstRow] = multiData;
    if (firstRow[KEY_INVALID]) return true;
  }
  return false;
};

export const shouldShowFilter = (multiData) => {
  if (Array.isArray(multiData) && multiData.length) {
    const [firstRow] = multiData;
    // if user add first row then need to hide filter
    if (multiData.length === 1 && firstRow[KEY_INVALID]) {
      return false;
    }
    return true;
  }
  return false;
};

export function isSameBid(bid1, bid2) {
  return bid1[KEY_PUB_ID] === bid2[KEY_PUB_ID] && bid1[KEY_GEO] === bid2[KEY_GEO];
}

export function getCampaignBiddingIndex(bids, bid) {
  return bids.findIndex((b) => isSameBid(b, bid));
}

// get the key of `bidding`
function getBiddingInputKey(bid) {
  if (!bid.pub_app_id || bid.pub_app_id === '*') {
    return `geo_rates.${bid.geo}`;
  }
  if (!bid.geo || bid.geo === '*') {
    return `app_rates.${bid.pub_app_id}`;
  }
  return `app_geo_rates.${bid.pub_app_id}.${bid.geo}`;
}

export function handleBiddingInputWhenUpload(campaign) {
  // when upload bidding, we can add the upload flag
  campaign.set('budget.publisher_rates_uploaded', true);
}

export function handleBiddingInputWhenUpdate(campaign, bid) {
  if (campaign.get('budget.publisher_rates_uploaded')) return;
  let biddingInput = campaign.get('budget.publisher_rates_input');
  if (!biddingInput) {
    biddingInput = {};
  }
  const bidKey = getBiddingInputKey(bid);
  biddingInput[bidKey] = Number(bid.rate);
  campaign.set('budget.publisher_rates_input', biddingInput);
}

export function handleBiddingInputWhenDelete(campaign, bids) {
  if (campaign.get('budget.publisher_rates_uploaded')) return;
  const biddingInput = campaign.get('budget.publisher_rates_input') || {};
  bids.forEach((bid) => {
    const bidKey = getBiddingInputKey(bid);
    biddingInput[bidKey] = null;
  });
  campaign.set('budget.publisher_rates_input', biddingInput);
}

export function handleBiddingInputWhenDeleteAll(campaign) {
  // when delete all bidding, we can add the upload flag
  handleBiddingInputWhenUpload(campaign);
}
