import React from 'react';
import Select from 'components/Form/Select/Select';
import { BUDGET_TARGET_HORIZON_DYNAMIC_IAP } from 'app/constants/campaign';
import Option from 'components/Form/Select/Option';
import Group from 'templates/input-group/InputGroupContainer';

const { Field } = Group;
export const HUMAN_READABLE_BUDGET_TARGET_HORIZON_DYNAMIC_IAP = `D${BUDGET_TARGET_HORIZON_DYNAMIC_IAP}`;
export const DISABLED_TEXT = `${HUMAN_READABLE_BUDGET_TARGET_HORIZON_DYNAMIC_IAP} - 
                      Day ${BUDGET_TARGET_HORIZON_DYNAMIC_IAP} Return on Ad Spend after Install`;
export const NAME = 'Target Horizon Date';
export const DESCRIPTION = 'Time window after install in which you seek to earn revenue on your ad spend.';

export default function TargetHorizon() {
  return (
    <Field
      name={NAME}
      description={DESCRIPTION}
    >
      <Select
        noIcon
        disabled
        disabledText={DISABLED_TEXT}
        value={HUMAN_READABLE_BUDGET_TARGET_HORIZON_DYNAMIC_IAP}
      >
        <Option
          value={HUMAN_READABLE_BUDGET_TARGET_HORIZON_DYNAMIC_IAP}
        />
      </Select>
    </Field>
  );
}
