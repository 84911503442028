import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import Filter from 'components/V2/Filter/Filter';
import Input from 'components/V2/Input/Input';
import { copy } from 'components/V2/Copy/Copy';
import Message from 'components/V2/FlagMessage/Message';

const MultiTreeSelected = ({
  shouldHideSelectAll,
  prefixCls,
  className,
  selectedItems,
  shouldShowCopy,
  shouldShowPaste,
  onSelectedChange,
  placeholder = 'Search',
  onPaste,
  copyMapper = (item) => item.id,
  deleteMapper = (change) => (selected) => selected.id !== change.id,
  filterKeyGetter = (item) => item.id,
}) => {
  const [focusedLabel, setFocusedLabel] = useState();
  const copyAll = () => {
    const text = selectedItems.map(copyMapper).join(',');
    copy(text, Message.success({ title: 'Success', content: 'Copied to clipboard.' }));
  };
  const clearAll = () => onSelectedChange([]);
  const onSelectedDelete = (c) => {
    const newSelected = selectedItems.filter(deleteMapper(c));
    onSelectedChange(newSelected);
  };
  return (
    <div className={prefixCls}>
      <div className={classNames(`${prefixCls}-header`, { 'select-all-disabled': shouldHideSelectAll })}>
        <div className={`${prefixCls} p2`}>{`(${selectedItems.length}) Selected`}</div>
        <div className={`${prefixCls}-actions`}>
          {shouldShowCopy && (
            <>
              <span className={`p9 ${prefixCls}-action`} onClick={copyAll}>Copy All</span>
              <span className={`${prefixCls}-actions-divider`} />
            </>
          )}
          <span className={`p9 ${prefixCls}-action`} onClick={clearAll}>Clear All</span>
        </div>
      </div>
      {shouldShowPaste && (
        <div className={`${prefixCls}-paste`}>
          <Input placeholder={placeholder} className={`${prefixCls}-paste-input`} onPaste={onPaste} />
        </div>
      )}
      <div className={classNames(`${prefixCls}-wrapper`, className)}>
        {selectedItems.map((c) => (
          <Filter
            className={`${prefixCls}-item`}
            type="advanced"
            subType="option"
            key={filterKeyGetter(c)}
            onClickIcon={() => onSelectedDelete(c)}
            focused={filterKeyGetter(c) === focusedLabel}
            setFocused={() => setFocusedLabel(filterKeyGetter(c))}
          >
            {c.name}
          </Filter>
        ))}
      </div>
    </div>
  );
};

MultiTreeSelected.propTypes = {
  /**
   * Prefix in class names.
   */
  prefixCls: PropTypes.string,
  /**
   * Class name.
   */
  className: PropTypes.string,
  /**
   * Items which should be selected in parent component.
   */
  selectedItems: PropTypes.arrayOf(PropTypes.object),
  /**
   * If true, dom for copy functionality should be displayed.
   */
  shouldShowCopy: PropTypes.bool,
  /**
   * If true, dom for paste functionality should be displayed.
   */
  shouldShowPaste: PropTypes.bool,
  /**
   * Callback fired when the options are selected or unselected.
   *
   * @param {array} updated item list.
   */
  onSelectedChange: PropTypes.func,
  /**
   * Callback fired when copying text into textarea`.
   *
   * @param {object} event object.
   */
  onPaste: PropTypes.func,
  /**
   * Callback for processing selectedItems when copying.
   *
   * @param {object} current item in looping.
   */
  copyMapper: PropTypes.func,
  /**
   * Callback for processing selectedItems when deleting items.
   *
   * @param {object} current item in looping.
   */
  deleteMapper: PropTypes.func,
  /**
   * Callback for processing selectedItems when filtering eligible items.
   *
   * @param {object} current item in looping.
   */
  filterKeyGetter: PropTypes.func,
};

MultiTreeSelected.defaultProps = {
  prefixCls: null,
  className: null,
  selectedItems: [],
  shouldShowCopy: false,
  shouldShowPaste: false,
  onSelectedChange: noop,
  onPaste: noop,
  copyMapper: (item) => item.id,
  deleteMapper: (change) => (selected) => selected.id !== change.id,
  filterKeyGetter: (item) => item.id,
};

export default MultiTreeSelected;
