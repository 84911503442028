import React, { useMemo } from 'react';
import {
  FILTER_KEY_APPLICATION,
  FILTER_KEY_ASSET_STATUS,
  FILTER_KEY_ASSET_TYPE,
} from 'app/constants/filters';
import { getAssetFiltersCache, isFiltersEqual } from 'lib/cache/FiltersCache';
import { getFilters } from 'components/V2/Filters/filters';
import { CombinedFiltersWithSearch } from 'components/V2';

const filtersKeys = [
  FILTER_KEY_APPLICATION, FILTER_KEY_ASSET_STATUS, FILTER_KEY_ASSET_TYPE,
];

const filters = getFilters(filtersKeys);

const Filters = ({
  onRefresh, account, className, children, searchValue,
}) => {
  const assetFiltersCache = getAssetFiltersCache(account);

  const onChange = (cache) => {
    const prevCacheValue = assetFiltersCache.get();
    assetFiltersCache.save(cache);
    if (!isFiltersEqual(prevCacheValue, cache)) {
      onRefresh({ page: 1 });
    }
  };

  const defaultCheckedConditions = useMemo(() => {
    const conditions = filtersKeys.reduce((prev, key) => ({
      ...prev,
      [key]: [],
    }), {});
    return {
      ...conditions,
      ...assetFiltersCache.get(),
    };
  }, [assetFiltersCache]);

  return (
    <div className={className}>
      <CombinedFiltersWithSearch
        filters={filters}
        onChange={onChange}
        placeholder="Search by asset name or id..."
        defaultCheckedConditions={defaultCheckedConditions}
        searchValue={searchValue}
      />
      {children}
    </div>
  );
};

export default Filters;
