import React from 'react';
import { getNextRoute } from 'services/advertiser/paths';
import Campaign from 'models/Campaign';
import Link from '../../../Link/Link';

export const returnLabelLink = (id, isMissionControl, original) => {
  if (isMissionControl) {
    return {
      application: `/applications/${id}`,
      campaign: `/campaigns/${id}`,
      creative: `/creatives/${id}`,
    };
  }
  return {
    application: `/applications/${id}`,
    campaign: `/campaigns/${id}/${getNextRoute(new Campaign(original.campaign || {}), 'confirm', null, true)}`,
    creative: `/creatives/${id}/campaigns`,
  };
};

export const renderName = (type, id, name, isMissionControl = false, original) => {
  const labelLinkConfig = returnLabelLink(id, isMissionControl, original);
  const link = labelLinkConfig[type];
  if (link) {
    return <Link to={link} className="body__link">{name}</Link>;
  }
  return name;
};
