import { cloneDeep } from 'lodash';
import {
  METRIC_IMPRESSIONS,
  METRIC_CLICKS,
  METRIC_MMP_INSTALLS,
  METRIC_SPENDS,
  METRIC_ECPM,
  METRIC_CTR,
  METRIC_IR,
  METRIC_IPM,
  METRIC_ECPI,
  METRIC_D1_REVENUE,
  METRIC_D3_REVENUE,
  METRIC_D7_REVENUE,
  METRIC_D14_REVENUE,
  METRIC_D30_REVENUE,
  METRIC_D1_ROAS,
  METRIC_D3_ROAS,
  METRIC_D7_ROAS,
  METRIC_D14_ROAS,
  METRIC_D30_ROAS,
  METRIC_D1_RETENTION,
  METRIC_D3_RETENTION,
  METRIC_D7_RETENTION,
  METRIC_D14_RETENTION,
  METRIC_D30_RETENTION,
  METRIC_SKAN_INSTALLS,
  METRIC_D7_ARPU,
  METRIC_D7_ARPPU,
  METRIC_D7_PAYING_USERS,
  METRIC_D7_PAYING_USERS_PERCENT,
  METRIC_D7_ECPPU,
  METRIC_D7_ECPE,
  METRIC_CPE_EVENT_NAME,
} from '../../Metrics/constants';

import {
  numberFormat,
  currencyFn,
  percentageFn,
  recommendationPercentageFn,
} from '../../Metrics/Metrics';

const cpeMetricKeys = [
  METRIC_D7_ECPE,
  METRIC_CPE_EVENT_NAME,
];

export const metricsGroup = [
  {
    name: 'Standard Metrics',
    metrics: [
      {
        key: METRIC_IMPRESSIONS,
        name: 'Impressions',
        default: true,
        tableDefault: true,
        render: numberFormat,
        content: 'When a user enters the ad experience (video may not have started).',
      },
      {
        key: METRIC_CLICKS,
        name: 'Clicks',
        default: true,
        tableDefault: true,
        render: numberFormat,
        content: 'When a user clicks on the call to action (CTA) button.',
      },
      {
        key: METRIC_MMP_INSTALLS,
        name: 'MMP Installs',
        default: true,
        tableDefault: true,
        render: numberFormat,
        getter: (metric) => metric?.mmpInstalls || 0,
        content: 'Number of user installs of an advertised application.',
      },
      {
        key: METRIC_SKAN_INSTALLS,
        name: 'SKAN Installs ',
        default: false,
        tableDefault: false,
        render: numberFormat,
        getter: (metric) => metric?.skanInstalls || 0,
        content: 'Number of user SKAN installs of an advertised application.',
      },
      {
        key: METRIC_SPENDS,
        name: 'Spend',
        default: true,
        tableDefault: true,
        render: currencyFn,
        content: 'Gross advertiser spend.',
      },
      {
        key: METRIC_ECPM,
        name: 'eCPM',
        default: true,
        tableDefault: true,
        render: currencyFn,
        content: `Effective Cost Per Mille:
(advertiser spend/impressions)*1000`,
      },
      {
        key: METRIC_CTR,
        name: 'CTR',
        default: false,
        tableDefault: false,
        render: recommendationPercentageFn,
        content: 'Click-Through Rate: clicks/impressions as a percentage.',
      },
      {
        key: METRIC_IR,
        name: 'IR',
        default: false,
        tableDefault: false,
        render: recommendationPercentageFn,
        content: 'Install Rate: install/clicks as a percentage.',
      },
      {
        key: METRIC_IPM,
        name: 'IPM',
        default: false,
        tableDefault: true,
        render: numberFormat,
        content: 'Installs Per Mille: (installs/impressions)*1000.',
      },
      {
        key: METRIC_ECPI,
        name: 'eCPI',
        default: false,
        tableDefault: true,
        render: currencyFn,
        content: 'Effective Cost Per Install: total spend/total installs.',
      },
    ],
  },
  {
    name: 'LTV Metrics',
    metrics: [
      {
        key: METRIC_D1_REVENUE,
        name: 'D1 Revenue',
        default: false,
        tableDefault: false,
        render: currencyFn,
        content: 'Revenue generated in the 1 day since install.',
      },
      {
        key: METRIC_D3_REVENUE,
        name: 'D3 Revenue',
        default: false,
        tableDefault: false,
        render: currencyFn,
        content: 'Revenue generated in the 3 days since install.',
      },
      {
        key: METRIC_D7_REVENUE,
        name: 'D7 IAP Revenue',
        default: false,
        tableDefault: true,
        render: currencyFn,
        content: 'Revenue generated in the 7 days since install. Note: Data shown for the last 7 days are incomplete.',
      },
      {
        key: METRIC_D14_REVENUE,
        name: 'D14 Revenue',
        default: false,
        tableDefault: false,
        render: currencyFn,
        content: 'Revenue generated in the 14 days since install.',
      },
      {
        key: METRIC_D30_REVENUE,
        name: 'D30 Revenue',
        default: false,
        tableDefault: false,
        render: currencyFn,
        content: 'Revenue generated in the 30 days since install.',
      },
      {
        key: METRIC_D1_ROAS,
        name: 'D1 ROAS',
        default: false,
        tableDefault: false,
        render: percentageFn,
        content: 'The dollars earned per dollars spent on the corresponding advertising over a 1-day period.',
      },
      {
        key: METRIC_D3_ROAS,
        name: 'D3 ROAS',
        default: false,
        tableDefault: false,
        render: percentageFn,
        content: 'The dollars earned per dollars spent on the corresponding advertising over a 3-day period.',
      },
      {
        key: METRIC_D7_ROAS,
        name: 'D7 ROAS%',
        default: false,
        tableDefault: true,
        render: percentageFn,
        content: 'Percent of revenue recouped for every dollar spent on a campaign 7 days after an install:  D7 $ Revenue / Cohort $ Spend. Note: Data shown for the last 7 days are incomplete.',
      },
      {
        key: METRIC_D14_ROAS,
        name: 'D14 ROAS',
        default: false,
        tableDefault: false,
        render: percentageFn,
        content: 'The dollars earned per dollars spent on the corresponding advertising over a 14-day period.',
      },
      {
        key: METRIC_D30_ROAS,
        name: 'D30 ROAS',
        default: false,
        tableDefault: false,
        render: percentageFn,
        content: 'The dollars earned per dollars spent on the corresponding advertising over a 30-day period.',
      },
      {
        key: METRIC_D7_ARPU,
        name: 'D7 IAP ARPU',
        default: false,
        tableDefault: true,
        render: currencyFn,
        content: 'Average in-app purchase (IAP) revenue per user (ARPU) from all users within 7 days after an install. Note: Data shown for the last 7 days are incomplete.',
      },
      {
        key: METRIC_D7_ARPPU,
        name: 'D7 IAP ARPPU',
        default: false,
        tableDefault: true,
        render: currencyFn,
        content: 'Average in-app purchase (IAP) revenue per paying user (ARPU) from users who have made at least one in-app purchase within 7 days after an install. Note: Data shown for the last 7 days are incomplete.',
      },
      {
        key: METRIC_D7_PAYING_USERS,
        name: 'D7 # of Paying Users',
        default: false,
        tableDefault: true,
        render: numberFormat,
        content: 'Total number of users who have made at least one in-app purchase within 7 days after an install. Note: Data shown for the last 7 days are incomplete.',
      },
      {
        key: METRIC_D7_PAYING_USERS_PERCENT,
        name: 'D7 % of Paying Users',
        default: false,
        tableDefault: true,
        render: percentageFn,
        content: 'Percentage of users who have made at least one in-app purchase within 7 days after an install. Note: Data shown for the last 7 days are incomplete.',
      },
      {
        key: METRIC_D7_ECPPU,
        name: 'D7 eCPPU',
        default: false,
        tableDefault: true,
        render: currencyFn,
        content: 'Effective Cost per Paying User who has made at least one paying transaction within 7 days after install.',
      },
      {
        key: METRIC_D7_ECPE,
        name: 'D7 eCPE',
        default: false,
        tableDefault: true,
        render: currencyFn,
        content: 'D7 Effective Cost per Event is the observed cost of generating an event in the first 7 days after install based on campaign spend and user activity post install.',
      },
      {
        key: METRIC_CPE_EVENT_NAME,
        name: 'CPE Event Name',
        default: false,
        tableDefault: true,
        render: (name) => name,
        content: 'CPE Event Name.',
      },
      {
        key: METRIC_D1_RETENTION,
        name: 'D1 Retention',
        default: false,
        tableDefault: false,
        render: percentageFn,
        content: 'How many users come back to the application 3 days after installing: D1 users/D0 users.',
      },
      {
        key: METRIC_D3_RETENTION,
        name: 'D3 Retention',
        default: false,
        tableDefault: false,
        render: percentageFn,
        content: 'How many users come back to the application 3 days after installing: D3 users/D0 users.',
      },
      {
        key: METRIC_D7_RETENTION,
        name: 'D7 Retention',
        default: false,
        tableDefault: true,
        render: percentageFn,
        content: 'Percent of users who return to the application on the 7th day after installing. Note: Data shown for the last 7 days are incomplete.',
      },
      {
        key: METRIC_D14_RETENTION,
        name: 'D14 Retention',
        default: false,
        tableDefault: false,
        render: percentageFn,
        content: 'How many users come back to the application 14 days after installing: D14 users/D0 users.',
      },
      {
        key: METRIC_D30_RETENTION,
        name: 'D30 Retention',
        default: false,
        tableDefault: false,
        render: percentageFn,
        content: 'How many users come back to the application 30 days after installing: D30 users/D0 users.',
      },
    ],
  },
];

export const standardMetricsList = [...metricsGroup[0].metrics];

export const getMetricsGroupByTab = (tab, needCheckCPE = false) => {
  const groups = cloneDeep(metricsGroup);
  if (needCheckCPE && tab.key === 'campaign') {
    return groups;
  }
  const [, ltvMetrics] = groups;
  ltvMetrics.metrics = ltvMetrics.metrics.filter((m) => !cpeMetricKeys.includes(m.key));
  return groups;
};

export const getMetricsListByTab = (tab, needCheckCPE = false) => {
  const metricsList = [...metricsGroup[0].metrics, ...metricsGroup[1].metrics];
  if (needCheckCPE && tab.key === 'campaign') {
    return metricsList;
  }
  return metricsList.filter((m) => !cpeMetricKeys.includes(m.key));
};
