import React from 'react';
import { isEmptyArray, isArray } from '../../lib/lib';
import Alert from '../../components/Alert/Alert';
import Button from '../../components/Button/Button';
import Checkmark from '../../components/Checkmark/Checkmark';
import Icon from '../../components/Icon/Icon';
import Link from '../../components/Link/Link';
import Spinner from '../../components/Spinner/Spinner';
import Text from '../../components/Form/Text/TextContainer';
import { searchPlaceholder } from './constant';

const Store = ({
  name, component, store, selectStore, savedApplication,
}) => {
  const StoreIcon = Icon[component];
  const n = name.toLowerCase();
  return (
    <div
      className={`store ${store === n ? 'store--active shadow' : ''}`}
      onClick={() => !savedApplication && selectStore(n)}
    >
      <StoreIcon size="80" />
    </div>
  );
};

const StoreApplication = ({
  thumbnail, name, company, id, showCancel = false, selectApplication,
}) => (
  <div key={id} className="store_application f">
    <img src={thumbnail} />
    <div className="name flex f fc">
      <p className="title flex">{name}</p>
      <p className="info flex">{company}</p>
    </div>
    <div className="id flex f fc">
      <p className="title flex">App Store ID</p>
      <p className="info flex">{id}</p>
    </div>
    {showCancel && (
      <i className="material-icons" onClick={() => selectApplication({ id: -1 })}>delete</i>
    )}
  </div>
);

const SearchResults = ({ results, loading, onClick }) => (
  <div className={`results ${loading || isEmptyArray(results) ? 'results--loading f fh fv' : ''}`}>
    {loading && (
      <Spinner />
    )}
    {!loading && isEmptyArray(results) && (
      <span>no results found</span>
    )}
    {!loading && (isArray(results) && results.map((application) => (
      <div key={application.id} className="result_wrapper" onClick={() => onClick(application)}>
        <div className="selector f fv fh">
          <div className="button_wrapper">
            <Button>Select</Button>
          </div>
        </div>
        <StoreApplication {...application} />
      </div>
    )))}
  </div>
);

const AppSearch = ({
  store,
  searchValue,
  searchAppStore,
  searchResults,
  loadingResults,
  onClick,
  autoFocus = true,
  noIcon = false,
}) => (
  <div className="search_wrapper">
    <div className="search">
      <div className="windows-search">
        <p className="p2">{searchPlaceholder[store]}</p>
      </div>
      <Text.Search
        disabled={!store}
        autoFocus={autoFocus && !!store}
        value={searchValue}
        onChange={(v) => searchAppStore({ store, v })}
        noIcon={noIcon}
      />
    </div>
    <SearchResults results={searchResults} loading={loadingResults} onClick={onClick} />
  </div>
);

const ApplicationCreator = ({
  props: {
    application = null,
    applicationNeedsApproval = false,
    couldNotSaveApplication = false,
    errMsg = undefined,
    loadingResults = false,
    savingApplication = false,
    savedApplication = false,
    searchResults = null,
    store = null,
    searchValue = '',
  },
  actions: {
    createApplication = null,
    searchAppStore = null,
    selectApplication = null,
    selectStore = null,
  },
}) => (
  <div className="views__advertiser__campaigns__edit__application-creator">
    <div className="header">
      <p className="h3">Add Your Application</p>
      <p className="p2">Choose your app store, then locate the application you wish to create a campaign for</p>
    </div>
    <div className="application">
      <div className="stores">
        <Store name="iOS" component="Apple" {...{ store, selectStore, savedApplication }} />
        <Store name="Android" component="Android" {...{ store, selectStore, savedApplication }} />
        <Store name="Windows" component="Windows" {...{ store, selectStore, savedApplication }} />
        <Store name="Amazon" component="Amazon" {...{ store, selectStore, savedApplication }} />
      </div>
      {application && (
        <StoreApplication {...application} showCancel={!savedApplication} selectApplication={selectApplication} />
      )}
      {application && (
        <div className="create-application f fh fv">
          {couldNotSaveApplication && !!errMsg && (
            <Alert>{errMsg}</Alert>
          )}
          {applicationNeedsApproval && (
            <div className="f fc fh fv">
              <Alert.Warn>
                Your app is currently being reviewed internally. Please wait until we confirm access.
              </Alert.Warn>
              <div className="f fh fv" style={{ marginTop: 16 }}>
                <Link to="/applications">
                  <Button secondary>Sounds Good</Button>
                </Link>
              </div>
            </div>
          )}
          {!applicationNeedsApproval && !savingApplication && !savedApplication && !couldNotSaveApplication && (
            <Button onClick={() => createApplication({ store, application })}>Save Application</Button>
          )}
          {savingApplication && (
            <Checkmark complete={savedApplication} />
          )}
        </div>
      )}
      {!application && !!store && (
        <AppSearch
          store={store}
          searchValue={searchValue}
          searchAppStore={searchAppStore}
          searchResults={searchResults}
          loading={loadingResults}
          onClick={selectApplication}
        />
      )}
    </div>
  </div>
);

export default ApplicationCreator;
export { AppSearch, StoreApplication };
