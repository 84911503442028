import { now } from 'lib/date';
import { getReportingAbTest } from 'app/graphql/utils/reporting';
import {
  THRESHOLD_TOTAL,
  THRESHOLD_P_VALUE,
  STATUS_KEY_COMPLETE,
  STATUS_KEY_IN_PROGRESS_WITHOUT_ENOUGH_CONFIDENCE,
  STATUS_KEY_IN_PROGRESS_WITHOUT_ENOUGH_IMPRESSIONS,
} from './constants';

export const getReportsStatus = (abSets) => {
  if (!abSets || !abSets.length) {
    return null;
  }

  const enoughConfidence = abSets.every((item) => item.pValue < THRESHOLD_P_VALUE);
  const enoughImpressions = abSets.every((item) => item.impressions > THRESHOLD_TOTAL);

  if (!enoughConfidence) {
    return STATUS_KEY_IN_PROGRESS_WITHOUT_ENOUGH_CONFIDENCE;
  }

  if (!enoughImpressions) {
    return STATUS_KEY_IN_PROGRESS_WITHOUT_ENOUGH_IMPRESSIONS;
  }

  return STATUS_KEY_COMPLETE;
};

export const getReportsWinner = (abSets) => {
  if (getReportsStatus(abSets) !== STATUS_KEY_COMPLETE) {
    return null;
  }

  return abSets.sort((a, b) => a.ipm > b.ipm)[0].key;
};

export const getReportsData = async (campaign, abSets, start, end) => {
  const body = {
    campaignId: campaign.get('id'),
    campaignABTestId: campaign.get('abTestID'),
    aggregates: ['IPM', 'impressions'],
    start: start || campaign.get('abTestStart'),
    end: end || now(),
    accountId: campaign.get('account.id'),
  };

  const { response } = await getReportingAbTest(body);

  const responseSetResults = abSets.map((set) => ({
    ...set,
    ...((response || []).find((item) => set.key === item.set) || {}),
  }));

  return {
    abSets: responseSetResults,
    status: getReportsStatus(responseSetResults),
    winner: getReportsWinner(responseSetResults),
  };
};
