import { reduce, assign, forOwn } from 'lodash';
import { FILTER_KEY_SEARCH, FILTER_KEY_DATE_RANGE } from 'app/constants/filters';
import { isArray, isObject } from '../../../lib/lib';

export const dateFilterParams = ['days', 'start', 'end'];

export const returnDateRangeParams = (dateData) => {
  const tempParams = {};
  if (dateData.quickPickValue === 'custom') {
    tempParams.start = dateData.startString;
    tempParams.end = dateData.endString;
  } else if (dateData.quickPickValue !== 'all') {
    tempParams.days = dateData.quickPickValue;
  }
  return tempParams;
};

export const returnfitlerQueryParams = (params) => {
  const requestQuery = { ...params };
  if (requestQuery.adminStatus) {
    requestQuery.adminStatus = requestQuery.adminStatus.split(',');
  }
  return requestQuery;
};

export const returnFilterParams = (filters) => reduce(filters, (result, value, key) => {
  let tempParam = {};
  if (isArray(value)) {
    if (value.length > 0) {
      tempParam[key] = value.map((o) => o.id).join(',');
    }
  } else if (isObject(value)) {
    if (key === FILTER_KEY_DATE_RANGE) {
      tempParam = returnDateRangeParams(value);
    }
  } else {
    tempParam[key] = value;
  }
  return assign(result, tempParam);
}, {});

export const returnInitChecked = (params, defaultChecked) => {
  const initChecked = {};
  forOwn(params, (value, key) => {
    if (value) {
      if (!defaultChecked[key]) initChecked[key] = {};
      if (key === FILTER_KEY_SEARCH) {
        initChecked[key] = value;
      } else if (key === dateFilterParams[1] || key === dateFilterParams[2]) {
        initChecked[FILTER_KEY_DATE_RANGE] = {
          quickPickValue: 'custom',
          startString: params[dateFilterParams[1]],
          endString: params[dateFilterParams[2]],
        };
        delete initChecked[key];
      } else if (key === dateFilterParams[0]) {
        initChecked[FILTER_KEY_DATE_RANGE] = {
          quickPickValue: value,
        };
        delete initChecked[key];
      } else {
        initChecked[key] = value.split(',').map((v) => ({ id: v }));
      }
    }
  });
  return initChecked;
};
