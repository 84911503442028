import React from 'react';
import { splash, compose } from '../../../../app/app';
import namespace from '../../../../app/namespace';
import Campaign from '../../../../models/Campaign';
import { SET_KEY_B, abTestReportsSets } from '../constants';
import { getReportsData } from '../helper';
import ABTestReportingDrawer from './ABTestReportingDrawer';

const ns = 'views.application.abTestReports';
const updateState = namespace(ns);
const applicationDetailsNS = 'views.applications.details';
const updateApplicationsDetailsState = namespace(applicationDetailsNS);

class ABTestReportingDrawerContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      abSets: abTestReportsSets,
      status: null,
      winner: null,
    };
  }

  onRunWinningSet = async () => {
    const { winner } = this.state;
    const { campaign, saveCampaign } = this.props;
    campaign.set('is_ab_testing', false);
    if (winner === SET_KEY_B) {
      campaign.set('creatives', campaign.get('creatives_b'));
    }

    saveCampaign(campaign);
  };

  componentDidUpdate(prevProps) {
    const {
      closeDrawer, onOpenChange,
    } = this.props;
    if (prevProps.closeDrawer !== closeDrawer) {
      onOpenChange(!closeDrawer);
    }
  }

  async componentDidMount() {
    const { campaign, start, end } = this.props;
    const reportData = await getReportsData(campaign, this.state.abSets, start, end);
    this.setState({
      ...reportData,
    });
  }

  render() {
    const { onOpenChange } = this.props;
    const { abSets, status, winner } = this.state;
    return (
      <ABTestReportingDrawer
        abSets={abSets}
        status={status}
        winner={winner}
        onOpenChange={onOpenChange}
        onRunWinningSet={this.onRunWinningSet}
      />
    );
  }
}

const props = (state) => ({
  closeDrawer: false,
  ...state[ns],
});

const saveCampaign = (() => {
  const action = (campaign) => Campaign.updateCampaign(campaign, {
    is_ab_testing: campaign.get('is_ab_testing'),
    creatives: campaign.get('creatives').map((c) => ({ id: c.get('id') })),
    creatives_b: campaign.get('creatives_b').map((c) => ({ id: c.get('id') })),
  });
  const start = () => splash({ start: true, text: 'Saving changes' });
  const end = ({ result, state }) => {
    if (result.status === 200) {
      return {
        ...splash({ finish: true, text: 'Success' }),
        ...updateState(state, { closeDrawer: true }),
        ...updateApplicationsDetailsState(state, { refreshCampaigns: true }),
      };
    }
    return splash({ finish: true, text: 'Failed' });
  };
  return { action, end, start };
})();

const actions = { saveCampaign };

export default compose(ABTestReportingDrawerContainer, { props, actions });
