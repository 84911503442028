export const KEY_NAME = 'name';
export const KEY_ID = 'id';

export const LABEL_WHITELIST = 'Allow';
export const LABEL_BLACKLIST = 'Deny';

export const KEY_NO_TARGETING = 'none';
export const KEY_WHITELIST = 'allow';
export const KEY_BLACKLIST = 'deny';

export const PUB_TEXTAREA_PLACEHOLDER = 'Comma, space, or new line separated list of Publisher App IDs.'
  + ' Maximum 500 IDs can be pasted at once.';

export const PUB_TEXTAREA_LOADING = 'Just a second, we are adding your ids.';

export const PUB_TEXTAREA_ERROR_MAX = 'Exceeded maximum of 500 pasted IDs.';
export const COPY_SUCCESS_INFO = {
  title: 'Success',
  content: 'Copied to clipboard.',
};

export const PLATFORM_KEYS = {
  ios: 'iOSPubliserTargetingIdError',
  android: 'androidPubliserTargetingIdError',
  windows: 'windowsPubliserTargetingIdError',
  amazon: 'amazonPubliserTargetingIdError',
};

export default {};
