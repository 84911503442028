import React from 'react';

function convert(entities, child, parent, deep) {
  const { dataKey, children } = child.props;
  const entity = { node: child, dataKey, deep };
  entities.set(dataKey, entity);

  if (parent) {
    entity.parent = parent;
  }

  if (React.Children.count(children)) {
    entity.children = [];
    React.Children.forEach(children, (subChild) => {
      entity.children.push(convert(entities, subChild, entity, deep + 1));
    });
  }

  return entity;
}

/**
 * convert children node list into keyEntities
 * @param children
 * @returns {Map<any, any>}
 */
function convertChildrenToEntities(children) {
  const entities = new Map();
  React.Children.forEach(children, (child) => {
    convert(entities, child, null, 0);
  });

  return entities;
}

/**
 * add value to list
 * @param list
 * @param value
 * @returns {*}
 */
function arrAdd(list, value) {
  const clone = list.slice();
  if (clone.indexOf(value) === -1) {
    clone.push(value);
  }
  return clone;
}

/**
 * delete value from list
 * @param list
 * @param value
 * @returns {*}
 */
function arrDel(list, value) {
  const clone = list.slice();
  const index = clone.indexOf(value);
  if (index >= 0) {
    clone.splice(index, 1);
  }
  return clone;
}

/**
 * conduct check status for current node
 * @param keyList
 * @param checked
 * @param keyEntities
 * @param checkedStatus
 * @returns {{halfCheckedKeys: [], checkedKeys: []}}
 */
function conductCheck(keyList, checked, keyEntities, checkedStatus = {}) {
  const checkedKeys = new Map();
  const halfCheckedKeys = new Map();

  (checkedStatus.checkedKeys || []).forEach((key) => {
    checkedKeys.set(key, true);
  });

  (checkedStatus.halfCheckedKeys || []).forEach((key) => {
    halfCheckedKeys.set(key, true);
  });

  // Conduct up
  function conductUp(dataKey) {
    if (checkedKeys.get(dataKey) === checked) return;

    const entity = keyEntities.get(dataKey);
    if (!entity) return;

    const { children, parent } = entity;

    // Check child node checked status
    let everyChildChecked = true;
    let someChildChecked = false; // Child checked or half checked

    (children || [])
      .forEach(({ dataKey: childKey }) => {
        const childChecked = checkedKeys.get(childKey);
        const childHalfChecked = halfCheckedKeys.get(childKey);

        if (childChecked || childHalfChecked) someChildChecked = true;
        if (!childChecked) everyChildChecked = false;
      });

    // Update checked status
    if (checked) {
      checkedKeys.set(dataKey, everyChildChecked);
    } else {
      checkedKeys.set(dataKey, false);
    }
    halfCheckedKeys.set(dataKey, someChildChecked);

    if (parent) {
      conductUp(parent.dataKey);
    }
  }

  // Conduct down
  function conductDown(dataKey) {
    if (checkedKeys.get(dataKey) === checked) return;

    const entity = keyEntities.get(dataKey);
    if (!entity) return;

    const { children } = entity;

    checkedKeys.set(dataKey, checked);

    (children || []).forEach((child) => {
      conductDown(child.dataKey);
    });
  }

  function conduct(dataKey) {
    const entity = keyEntities.get(dataKey);

    if (!entity) return;

    const { children, parent } = entity;
    checkedKeys.set(dataKey, checked);

    // Conduct down
    (children || [])
      .forEach((child) => {
        conductDown(child.dataKey);
      });

    // Conduct up
    if (parent) {
      conductUp(parent.dataKey);
    }
  }

  (keyList || []).forEach((key) => {
    conduct(key);
  });

  [...keyEntities.values()].forEach(({ node }) => {
    const { nodeData, isLeaf, dataKey } = node.props;
    if (!nodeData) return;

    const { childrenKeys = [], children } = nodeData;
    const total = childrenKeys.length;
    if (!isLeaf && total > 0 && children.length === 0) {
      const count = childrenKeys.filter((c) => keyList.includes(c)).length;
      if (count === total) {
        checkedKeys.set(dataKey, true);
      } else if (count > 0 && count < total) {
        halfCheckedKeys.set(dataKey, true);
      }
    }
  });

  const checkedKeyList = [];
  const halfCheckedKeyList = [];

  // Fill checked list
  // eslint-disable-next-line no-restricted-syntax
  Array.from(checkedKeys.keys()).forEach((key) => {
    if (checkedKeys.get(key)) {
      checkedKeyList.push(key);
    }
  });

  // Fill half checked list
  Array.from(halfCheckedKeys.keys()).forEach((key) => {
    if (!checkedKeys.get(key) && halfCheckedKeys.get(key)) {
      halfCheckedKeyList.push(key);
    }
  });

  return {
    checkedKeys: checkedKeyList,
    halfCheckedKeys: halfCheckedKeyList,
  };
}

function conductExpand(keyList, keyEntities) {
  const expandedKeys = new Map();

  function conductUp(dataKey) {
    if (expandedKeys.get(dataKey)) return;

    const entity = keyEntities.get(dataKey);
    if (!entity) return;

    expandedKeys.set(dataKey, true);

    const { parent } = entity;

    if (parent) {
      conductUp(parent.dataKey);
    }
  }

  (keyList || []).forEach((dataKey) => {
    conductUp(dataKey);
  });

  return Array.from(expandedKeys.keys());
}

export default {
  arrAdd,
  arrDel,
  conductCheck,
  conductExpand,
  convertChildrenToEntities,
};
