import {
  QUICK_KEY_YESTERDAY,
  QUICK_KEY_LAST_7_DAYS,
  QUICK_KEY_LAST_14_DAYS,
  QUICK_KEY_LAST_30_DAYS,
} from '../../../../../../../components/V2/Report/Calendar/PickerQuick';

export const QUICK_KEY_LAST_60_DAYS = 'LAST_60_DAYS';

export const QUICK_TAB_KEY_YESTERDAY = 'TAB_YESTERDAY';
export const QUICK_TAB_KEY_LAST_WEEK = 'TAB_LAST_WEEK';
export const QUICK_TAB_KEY_LAST_TWO_WEEKS = 'TAB_TWO_WEEKS';
export const QUICK_TAB_KEY_LAST_MONTH = 'TAB_LAST_MONTH';
export const QUICK_TAB_KEY_LAST_TWO_MONTHS = 'TAB_LAST_TWO_MONTHS';
export const QUICK_TAB_KEY_CUSTOM = 'TAB_CUSTOM';

export const QUICK_PICKERS = [
  {
    key: QUICK_TAB_KEY_YESTERDAY,
    quickKey: QUICK_KEY_YESTERDAY,
    title: '1D',
  },
  {
    key: QUICK_TAB_KEY_LAST_WEEK,
    quickKey: QUICK_KEY_LAST_7_DAYS,
    title: '1W',
  },
  {
    key: QUICK_TAB_KEY_LAST_TWO_WEEKS,
    quickKey: QUICK_KEY_LAST_14_DAYS,
    title: '2W',
  },
  {
    key: QUICK_TAB_KEY_LAST_MONTH,
    quickKey: QUICK_KEY_LAST_30_DAYS,
    title: '1M',
  },
  {
    key: QUICK_TAB_KEY_LAST_TWO_MONTHS,
    quickKey: QUICK_KEY_LAST_60_DAYS,
    title: '2M',
  },
];
