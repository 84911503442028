const DOWNLOAD_ASSET = 'Download Asset';
const EDIT_ASSET = 'Edit Asset';
const DELETE_ASSET = 'Delete Asset';
const TYPE_PLAYABLE = 'bundle_adaptive_creative';
const TYPE_VIDEO = 'video';
const TYPE_IMAGE = 'image';
const SEARCH_PARAM_ASSET_ID = 'assetID';

const columnKeys = {
  asset: {
    title: 'Asset',
    key: 'asset',
  },
  status: {
    title: 'Status',
    key: 'status',
  },
  app: {
    title: 'App',
    key: 'application',
  },
  created: {
    title: 'Date Created',
    key: 'created',
  },
  account: {
    title: 'Account',
    key: 'account',
  },
  orientation: {
    title: 'Orientation',
    key: 'orientation',
  },
  dimension: {
    title: 'Dimension',
    key: 'dimension',
  },
  size: {
    title: 'Size',
    key: 'size',
  },
  duration: {
    title: 'Duration',
    key: 'duration',
  },
};

const assetMetricGroup = [
  {
    name: 'Attributes',
    metrics: [
      { key: columnKeys.app.key, name: columnKeys.app.title, default: true },
      { key: columnKeys.created.key, name: columnKeys.created.title, default: true },
      { key: columnKeys.account.key, name: columnKeys.account.title, default: true },
      { key: columnKeys.orientation.key, name: columnKeys.orientation.title, default: true },
      { key: columnKeys.dimension.key, name: columnKeys.dimension.title, default: true },
      { key: columnKeys.size.key, name: columnKeys.size.title, default: true },
      { key: columnKeys.duration.key, name: columnKeys.duration.title, default: true },
    ],
  },
];

const assetDefaultSort = {
  id: columnKeys.created.key,
  desc: true,
};

const deleteAssetActionTooltip = {
  title: 'Deleting is Unavailable',
  body: 'Unfortunately this asset is already being used by one or more Creatives and cannot be deleted.',
};

export {
  DOWNLOAD_ASSET,
  EDIT_ASSET,
  DELETE_ASSET,
  TYPE_PLAYABLE,
  TYPE_VIDEO,
  TYPE_IMAGE,
  SEARCH_PARAM_ASSET_ID,
  columnKeys,
  assetMetricGroup,
  assetDefaultSort,
  deleteAssetActionTooltip,
};
