import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './listHeader.scss';

const classPrefix = 'replacements_Asset_ListHeader';

const ListHeader = ({
  columns = [],
  defaultSort,
  onSort,
  haveScrollBar,
}) => {
  const [sortColumn, setSortColumn] = useState(defaultSort);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (isMounted) {
      if (onSort) onSort(sortColumn);
    } else {
      setIsMounted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortColumn, onSort]);

  const onClickSort = (id) => {
    if (!sortColumn || sortColumn.id !== id) {
      setSortColumn({
        id,
        sort: 'desc',
      });
    } else {
      setSortColumn((prevState) => ({
        id: prevState.id,
        sort: prevState.sort === 'desc' ? 'asc' : 'desc',
      }));
    }
  };

  return (
    <div className={classnames(classPrefix, { haveScrollBar })}>
      {
        columns.map(({
          id, label, flex, sortable, justifyContent,
        }, index) => (
          <div
            className={classnames('labelItem', {
              sortItem: sortable && sortColumn?.id === id,
              assetItem: index === 0,
            })}
            key={id}
            style={{ flex, justifyContent }}
            onClick={sortable ? () => onClickSort(id) : undefined}
          >
            <span className="label">{label}</span>
            {sortable && sortColumn?.id === id && <i className="material-icons">{`expand_${sortColumn.sort === 'desc' ? 'more' : 'less'}`}</i>}
          </div>
        ))
      }
    </div>
  );
};

ListHeader.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      flex: PropTypes.number,
      sortable: PropTypes.bool,
      justifyContent: PropTypes.string,
    }),
  ).isRequired,
  defaultSort: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sort: PropTypes.oneOf(['desc', 'asc']),
  }),
  onSort: PropTypes.func,
  haveScrollBar: PropTypes.bool,
};

ListHeader.defaultProps = {
  defaultSort: null,
  onSort: Function.prototype,
  haveScrollBar: false,
};

export default ListHeader;
