import React from 'react';
import Checkbox from '../Form/Checkbox/CheckboxContainer';

const ContextMenuCheckOption = ({ checked, onChange, text }) => (
  <div
    className="components__context_menu_option"
    onClick={(e) => {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      onChange(!checked);
    }}
  >
    <div style={{ pointerEvents: 'none' }}>
      <Checkbox
        checked={checked}
        label={text}
      />
    </div>
  </div>
);

export default ContextMenuCheckOption;
