import React from 'react';
import { FILTER_KEY_TEMPLATE_STATUS } from 'app/constants/filters';
import CommonMultiPicker from '../CommonMultiPicker/CommonMultiPicker';
import { templateStatus } from '../constant';

const MultiTemplateStatus = (props) => (
  <CommonMultiPicker data={templateStatus} filterKey={FILTER_KEY_TEMPLATE_STATUS} {...props} />
);

export default MultiTemplateStatus;
