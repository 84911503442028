import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { setServiceType } from 'lib/serviceType';
import authUserVar from 'services/reactivevariables/authuservar';
import { create, history } from '../../app/app';
import { get } from '../../lib/session';
import config from '../../lib/config';
import { addAuthHeader, onStatus, addHeader } from '../../lib/http';
import { unauthorizedLogout } from '../../lib/auth';
import Setup from './views/setup';
import 'react-dates/initialize'; // Component requires init at higher level of view hierarchy
import '../../styles/base.scss';

const SERVICE_TYPE = 'grow';

// this is only used in auth app. Auth app still needs vungle-source
addHeader('Vungle-Source', SERVICE_TYPE);
setServiceType(SERVICE_TYPE);

const startState = {
  authUser: get('authUser'),
  window: { isDragging: false },
};

if (startState.authUser) {
  addAuthHeader(startState.authUser);
  authUserVar(get('authUser'));
}

onStatus(401, unauthorizedLogout);

Sentry.init({
  dsn: config.get('sentry').dsn.advertiser,
  autoSessionTracking: config.get('sentry').autoSessionTracking,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['localhost', /^\//, config.get('manageUrl')],
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  sampleRate: config.get('sentry').sampleRate,
  tracesSampleRate: config.get('sentry').tracesSampleRate,
  debug: config.get('sentry').debug,
  environment: config.get('environment'),
  release: config.get('version'),
});

ReactDOM.render(
  create(startState, Setup, SERVICE_TYPE),
  document.getElementById('root'),
);
