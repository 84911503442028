import React, {
  useState, useCallback, forwardRef, useImperativeHandle, useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { history } from '../../../../../../../../app/app';
import Creative from '../../../../../../../../models/Creative';
import { ISO_8601_FORMAT } from '../../../../../../../../lib/date';
import useDebounce from '../../../../../../../../lib/hooks/useDebounce';
import { getReportingPageSizeCache } from '../../../../../../../../lib/cache/PageSizeCache';
import { getDetailQueryNodeIds } from '../../../../../../../Templates/Reports/helper';
import { initPagination } from '../../../../../../../../components/V2/Filters/constant';
import previewCreative from '../../../../../../../../components/Creatives/Preview/previewCreative';
import { getReportingApplicationCreativeDetail } from '../../../../../../../../app/graphql/utils/reporting';
import { APPLICATION_CREATIVE_LIST_METRIC_KEY } from '../../../../../../../../lib/cache/constant';
import { metricsGroup } from '../../../../../../../../components/V2/Report/data/Metrics';
import Loading from '../../../../../../../../components/V2/Report/Loading/Loading';
import { METRIC_SPENDS } from '../../../../../../../../components/V2/Metrics/constants';
import MetricCache from '../../../../../../../../lib/cache/MetricCache';
import { Table } from '../../../../../../../../components/V2';
import { renderMetricColumns } from '../helper';
import withAction from '../renderAction';
import {
  renderColumns, defaultMetrics, returnMetricsList, generateContextMenu, attributesMetrics,
} from './helper';
import AssetStack from './AssetStack';
import '../dataTable.scss';
import './creativeDataTable.scss';

export function initMetrics(userId) {
  const metricsCache = new MetricCache(userId, APPLICATION_CREATIVE_LIST_METRIC_KEY);
  const metrics = metricsCache.get();
  if (metrics) {
    return returnMetricsList(metrics);
  }
  return defaultMetrics;
}

export const dimension = 'creative';

const CreativeDataTable = (
  {
    node,
    checkedDate,
    checkedCombine,
    changeCreativeStatus,
  },
  ref,
) => {
  const authUser = useSelector((state) => state.authUser);
  const pageSizeCache = getReportingPageSizeCache(authUser.account);

  const [metrics, setMetrics] = useState(() => initMetrics(authUser.id));
  const [sort, setSort] = useState({ sortKey: METRIC_SPENDS, isSortAsc: false });
  const [tableData, setTableData] = useState([]);
  const [tableExpanded, setTableExpanded] = useState({});
  const [tableLoading, setTableLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState({
    ...initPagination,
    perPage: pageSizeCache.get(),
  });

  const onLoadTableData = useCallback(async () => {
    setTableLoading(true);
    try {
      const {
        data, pagination, total,
      } = await getReportingApplicationCreativeDetail({
        ...sort,
        dimension,
        page: tablePagination.page,
        perPage: tablePagination.perPage,
        start: checkedDate.startDate.format(ISO_8601_FORMAT),
        end: checkedDate.endDate.format(ISO_8601_FORMAT),
        ...getDetailQueryNodeIds(checkedCombine),
        applications: [node.get('id')],
      });
      setTableData([{ total: 'Total', ...total }, ...data]);
      setTablePagination(pagination);
    } finally {
      setTableLoading(false);
    }
  }, [sort, tablePagination.page, tablePagination.perPage, checkedDate.startDate, checkedDate.endDate, checkedCombine, node]);

  useDebounce(() => {
    onLoadTableData();
  }, 50, [onLoadTableData]);

  useImperativeHandle(ref, () => ({
    onLoadTableData: () => onLoadTableData(),
  }));

  const handlePageChange = useCallback((page) => {
    if (tableLoading) {
      return;
    }
    setTablePagination((prevState) => ({
      ...prevState,
      page,
    }));
  }, [tableLoading]);

  const handlePageSizeChange = useCallback((page, perPage) => {
    if (tableLoading) {
      return;
    }
    pageSizeCache.save(perPage);
    setTablePagination((prevState) => ({
      ...prevState,
      perPage,
      page,
    }));
  }, [tableLoading, pageSizeCache]);

  const onContextMenuChange = (item, original) => {
    const row = original[dimension];
    if (item.action) {
      item.action({
        history,
        creative: row.dataModel,
      });
    }
    if (item.router) {
      history.push(item.router(row.id, row));
    }
  };

  const onMetricsChange = useCallback((e) => setMetrics(returnMetricsList(e)), []);

  const onSorted = useCallback((col) => {
    const { id, desc } = col[0];
    setSort({
      sortKey: id,
      isSortAsc: !desc,
    });
  }, []);

  const onTableRowGroupProps = (state, rowInfo) => {
    const { expanded } = state;
    const { viewIndex } = rowInfo;
    const isExpanded = expanded[viewIndex] || false;
    return {
      className: {
        isExpanded,
      },
    };
  };

  const onTableRowClick = (rowInfo) => {
    const { original, viewIndex } = rowInfo;
    if (original.total) {
      return;
    }
    const expanded = { ...tableExpanded };
    expanded[viewIndex] = !tableExpanded[viewIndex];
    setTableExpanded(expanded);
  };

  const onPreviewCreative = async (id) => {
    const { response } = await Creative.get(id);
    const creative = new Creative(response);
    previewCreative(creative);
  };

  const renderSubComponent = ({ original }) => (
    <div id="expander" className="expander_wrapper">
      <AssetStack node={node} creative={original.creative} />
    </div>
  );

  useEffect(() => {
    setTablePagination(initPagination);
  }, [checkedDate, checkedCombine]);

  return (
    <div className="application_data_table creative">
      {React.createElement(withAction(Table), {
        authUser,
        dimension,
        manual: true,
        resizable: true,
        sortable: true,
        hideOnNoData: true,
        showPagination: true,
        height: '100%',
        loading: tableLoading,
        page: tablePagination.page,
        total: tablePagination.total,
        pageSize: tablePagination.perPage,
        onPageChange: handlePageChange,
        onPageSizeChange: handlePageSizeChange,
        columns: [...renderColumns({ onPreviewCreative, changeCreativeStatus }), ...renderMetricColumns(metrics, dimension)],
        tableSorted: [{ id: sort.sortKey, desc: !sort.isSortAsc }],
        metricGroup: [attributesMetrics, metricsGroup[0]],
        metricType: APPLICATION_CREATIVE_LIST_METRIC_KEY,
        expanded: tableExpanded,
        data: tableData,
        onSorted,
        onMetricsChange,
        generateContextMenu,
        changeCreativeStatus,
        onContextMenuChange,
        onPreviewCreative,
        SubComponent: renderSubComponent,
        onRowGroupProps: onTableRowGroupProps,
        onRowClick: onTableRowClick,
      })}
      {tableLoading && <Loading content="Just a second, loading data..." />}
    </div>
  );
};

export default forwardRef(CreativeDataTable);
