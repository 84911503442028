/* global Transifex */
import { config } from '../app/app';

const getLanguages = () => new Promise((resolve) => {
  try {
    Transifex.live.onReady(() => Transifex.live.onFetchLanguages((languages) => resolve(languages)));
  } catch (ex) {
    resolve([]);
  }
});

const translateTo = (language) => Transifex.live.translateTo(language);

const languageFile = config.get('countryData');
const getTranslation = (code) => (
  languageFile.languages.find((l) => l.code === code)?.translations?.download || 'Download'
);
const getTranslatedCtaText = (text = '', languageCode = 'en') => {
  if (text === '' || text.toLowerCase() === 'download') {
    return getTranslation(languageCode);
  }
  return text;
};

export {
  getLanguages,
  translateTo,
  getTranslation,
  getTranslatedCtaText,
};
