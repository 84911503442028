import React, { compose, namespace } from '../../../../../../app/app';
import Application from '../../../../../../models/Application';
import ApplicationSelector from './ApplicationSelector';
import './selector.scss';

const createNamespace = 'views.campaigns.edit';
const createCreativeNamespace = 'views.creatives.edit';
const ns = 'views.campaigns.edit.application.selector';
const updateState = namespace(ns);
const props = (state) => ({
  authUser: state.authUser,
  ...state[ns],
  ...state[createNamespace],
  ...state[createCreativeNamespace],
});

class ApplicationSelectorContainer extends React.Component {
  componentDidMount() {
    this.props.actions.getApplications(this.props.authUser);
    if (this.props.renderProps.type === 'creative') {
      this.props.creative.set('application', null);
    } else {
      this.props.campaign.setApplication(null);
    }
  }

  render() {
    if (!this.props.applications) {
      return null;
    }
    return <ApplicationSelector {...this.props} type={this.props.renderProps.type} />;
  }
}

const getApplications = (() => {
  const action = (authUser) => Application.getAll({ account: authUser.account, perPage: 200, status: 'approved' });
  const end = ({ state, result }) => updateState(state, { applications: Application.make(result.response) });
  return { action, end };
})();

export default compose(ApplicationSelectorContainer, { actions: { getApplications }, props });
