import moment from 'moment';
import { chain, sumBy, isEmpty } from 'lodash';
import { getDynamicGeos } from 'app/graphql/utils/campaigns';
import {
  DYNAMIC_GEOS_REQUEST_START, DYNAMIC_GEOS_REQUEST_END, NUM_OF_PAYING_USERS_THRESHOLD, NUM_OF_EVENTS_THRESHOLD,
} from 'app/constants/campaign';

export const DYNAMIC_GEO_STATES = {
  VALID: 'VALID',
  INVALID: 'INVALID',
};

// Between 44 and 8 days represents the D7 Paying users in the last 45 days.
export async function getDynamicTierGeos(applicationID, start = DYNAMIC_GEOS_REQUEST_START, end = DYNAMIC_GEOS_REQUEST_END) {
  return getDynamicGeos({
    applicationID,
    startDate: moment().subtract(start, 'days'),
    endDate: moment().subtract(end, 'days'),
  });
}

export const getTierTreeData = (tierGeos) => {
  const tierNames = chain(tierGeos).map('tierName').uniq().sort()
    .value();
  const treeData = tierNames.map((name, index) => ({ name, id: index + 1, children: [] }));
  const specialGeos = [];

  tierGeos.forEach((tierGeo) => {
    specialGeos.push(tierGeo.code);
    const index = tierNames.indexOf(tierGeo.tierName);
    treeData[index].children.push(tierGeo);
  });

  return treeData;
};

// this function assumes that selectedTierGeos belong to the same tier
export function getDynamicGeoStatus(selectedTierGeos) {
  const numOfPayingUsers = sumBy(selectedTierGeos, 'numOfPayingUsers');
  if (numOfPayingUsers >= NUM_OF_PAYING_USERS_THRESHOLD) {
    return DYNAMIC_GEO_STATES.VALID;
  }
  return DYNAMIC_GEO_STATES.INVALID;
}

// this function assumes that selectedTierGeos belong to the same tier
export function getDynamicGeoStatusForEvent(selectedTierGeos) {
  const totalEvents = sumBy(selectedTierGeos, 'totalEvents');
  if (totalEvents >= NUM_OF_EVENTS_THRESHOLD) {
    return DYNAMIC_GEO_STATES.VALID;
  }
  return DYNAMIC_GEO_STATES.INVALID;
}

// joins an array following English grammar.
// ex: ["a", "b"] => "a, b"
// ex: ["a", "b", "c"] => "a, b and c"
export function joinWithGrammar(arr) {
  if (arr.length === 0) {
    return '';
  } if (arr.length === 1) {
    return arr[0];
  } if (arr.length === 2) {
    return arr.join(' and ');
  }
  return `${arr.slice(0, -1).join(', ')} and ${arr.slice(-1)}`;
}

/*
example selectedCountries: [{"id":40,"code":"CA","name":"Canada","region":5},{"id":236,"code":"US","name":"United States of America","region":5}]
example dynamicGeos:
[
   {
      "id":236,
      "code":"US",
      "name":"United States of America",
      "tierName":"Tier 1",
      "numOfPayingUsers":2,
      "__typename":"DynamicGeo"
   },
   {
      "id":66,
      "code":"EG",
      "name":"Egypt",
      "tierName":"Tier 2",
      "numOfPayingUsers":1,
      "__typename":"DynamicGeo"
   },
   {
      "id":100,
      "code":"HK",
      "name":"Hong Kong",
      "tierName":"Tier 3",
      "numOfPayingUsers":4,
      "__typename":"DynamicGeo"
   }
]
 */
export function getSelectedTierDynamicGeos(selectedCountries, dynamicGeos) {
  // represents tiers of selected countries
  const selectedTiers = chain(selectedCountries)
    .map((c) => dynamicGeos.find((rg) => rg.code === c.code)) // find corresponding dynamicGeo
    .filter((x) => !isEmpty(x))// do not keep undefined values
    .map('tierName')
    .value();

  return dynamicGeos.filter((rg) => selectedTiers.includes(rg.tierName)); // keep only dynamicGeos that have a tier in selected countries
}

export function getInvalidTiersFromSelectedCountries(selectedTierDynamicGeos) {
  return chain(selectedTierDynamicGeos)
    .groupBy('tierName')
    .reduce((acc, selectedTierGeos, tierName) => {
      acc[tierName] = getDynamicGeoStatus(selectedTierGeos);
      return acc;
    }, {})
    .pickBy((v) => v === DYNAMIC_GEO_STATES.INVALID) // pick valid tiers
    .keys() // extract tier name
    .sort() // sort by tier name
    .value();
}

export function getInvalidTiersFromSelectedCountriesForEvent(selectedTierDynamicGeos) {
  return chain(selectedTierDynamicGeos)
    .groupBy('tierName')
    .reduce((acc, selectedTierGeos, tierName) => {
      acc[tierName] = getDynamicGeoStatusForEvent(selectedTierGeos);
      return acc;
    }, {})
    .pickBy((v) => v === DYNAMIC_GEO_STATES.INVALID) // pick valid tiers
    .keys() // extract tier name
    .sort() // sort by tier name
    .value();
}

export function getTierCountryNames(selectedTierDynamicGeos) {
  return chain(selectedTierDynamicGeos)
    .groupBy('tierName')
    .reduce((acc, selectedTierGeos, tierName) => {
      acc[tierName] = selectedTierGeos.map((c) => c.name.trim()).sort().join(', ');
      return acc;
    }, {})
    .value();
}

export function hasTiersMeetingPayingUsersRequirement(dynamicGeos) {
  if (!Array.isArray(dynamicGeos) || dynamicGeos.length === 0) {
    return false;
  }

  const payingUsersInTiers = dynamicGeos.reduce((acc, dg) => {
    if (dg.tierName) {
      acc[dg.tierName] = (acc[dg.tierName] || 0) + dg.numOfPayingUsers;
    }
    return acc;
  }, {});

  return Object.values(payingUsersInTiers).some((payingUsers) => payingUsers >= NUM_OF_PAYING_USERS_THRESHOLD);
}

export function hasTiersMeetingTargetCPEEventRequirement(countryStats) {
  if (!Array.isArray(countryStats) || countryStats.length === 0) {
    return false;
  }

  const totalEventsInTiers = countryStats.reduce((acc, cs) => {
    if (cs.tierName) {
      acc[cs.tierName] = (acc[cs.tierName] || 0) + cs.totalEvents;
    }
    return acc;
  }, {});

  return Object.values(totalEventsInTiers).some((totalEvents) => totalEvents >= NUM_OF_EVENTS_THRESHOLD);
}
