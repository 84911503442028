import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { FILTER_KEY_PLATFORM } from 'app/constants/filters';
import Multi from '../Base/Multi/Multi';
import { platforms } from '../constant';
import './platform.scss';

import PlatformPanel from '../Base/Panels/PlatformPanel';

const Platform = ({
  dropdownFixed,
  visible,
  availableNodes,
  defaultCheckedNodes,
  onVisibleChange,
  onChange,
  onRemove,
}) => (
  <Multi
    dropdownFixed={dropdownFixed}
    data={platforms}
    visible={visible}
    filterKey={FILTER_KEY_PLATFORM}
    defaultCheckedKeys={defaultCheckedNodes}
    renderOverlay={() => (
      React.createElement(PlatformPanel, {
        availableNodes,
        defaultCheckedNodes,
        onVisibleChange,
        onChange,
      })
    )}
    onVisibleChange={onVisibleChange}
    onRemove={onRemove}
  />
);

Platform.propTypes = {
  dropdownFixed: PropTypes.bool,
  visible: PropTypes.bool,
  availableNodes: PropTypes.arrayOf(PropTypes.any),
  defaultCheckedNodes: PropTypes.arrayOf(PropTypes.any),
  onVisibleChange: PropTypes.func,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};

Platform.defaultProps = {
  dropdownFixed: false,
  visible: false,
  availableNodes: [],
  defaultCheckedNodes: [],
  onVisibleChange: noop,
  onChange: noop,
  onRemove: noop,
};

export default Platform;
