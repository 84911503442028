import React from 'react';
import Spinner from '../../Spinner/Spinner';

const Loading = ({ prefixCls, loading }) => (
  loading
  && (
  <div className={`${prefixCls}-loading`}>
    <Spinner />
  </div>
  )
);

export default Loading;
