import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import Dropdown from '../Dropdown/Dropdown';
import './tooltip.scss';

const prefixCls = 'v2_component_tooltip';

const InnerTooltip = ({ title, body, overlayClassName }) => (
  <div className={`${prefixCls}-container ${overlayClassName}`}>
    <div className="outer">
      <div className="inner">
        {title && (
          <div className="header">
            <p>{title}</p>
          </div>
        )}
        <div className="body">
          {body}
        </div>
      </div>
    </div>
  </div>
);

const Tooltip = ({
  children, title, body, placement, onVisibleChange, className, overlayClassName,
}) => (
  <Dropdown
    className={classNames(`${prefixCls}`, className)}
    renderOverlay={() => <InnerTooltip overlayClassName={overlayClassName} title={title} body={body} />}
    transitionOffset={0}
    placement={placement}
    onVisibleChange={onVisibleChange}
    trigger="hover"
  >
    {children}
  </Dropdown>
);

Tooltip.propTypes = {
  /**
   * Title for tooltip.
   */
  title: PropTypes.node,
  /**
   * Body for tooltip, can custom body to display on tooltip.
   */
  body: PropTypes.node,
  /**
   * Placement for tooltip can use dropdown placement.
   */
  placement: PropTypes.string,
  /**
   * when visible changed will trigger this call back
   */
  onVisibleChange: PropTypes.func,
};

Tooltip.defaultProps = {
  title: null,
  body: null,
  placement: 'top',
  onVisibleChange: noop,
};

export default Tooltip;
