import config from '../../config';

const { countries } = config.get('countryData');

const getCountryFromCode = (countryCode = '', pluck = null) => {
  const index = countries.findIndex(({ code }) => code === countryCode.toUpperCase());
  const country = index === -1 ? { code: countryCode, name: 'Unknown' } : countries[index];
  if (!pluck) {
    return country;
  }
  return country[pluck];
};

export default getCountryFromCode;
