import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DatePickerFilter from '../../../../components/V2/Report/Filters/DatePickerFilter/DatePickerFilter';
import DownloadCsvFilter from '../../../../components/V2/Report/Filters/DownloadCsvFilter/DownloadCsvFilter';
import CombinedFilters from '../../../../components/V2/Filters/CombinedFilters/CombinedFilters';
import { DropdownMenu, Tooltip } from '../../../../components/V2';
import { initCombinedFilters } from '../helper';
import {
  REPORTING_INCOMPLETE_BODY, REPORTS_MORE_OPTS,
} from '../constant';
import './filters.scss';

const Filters = ({
  checkedDate,
  checkedFilters,
  onCombineChange,
  onDateChange,
  onOperation,
  onExport,
  account,
}) => {
  const [isOptsOpen, setIsOptsOpen] = useState(false);
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const wrapperRef = useRef();
  const blankRef = useRef();
  const changeBlankHeight = () => {
    if (wrapperRef.current && blankRef.current) {
      blankRef.current.style.setProperty('--height', `${wrapperRef.current.clientHeight}px`);
    }
  };
  const onFilterListChange = useCallback(() => {
    setTimeout(() => {
      changeBlankHeight();
    }, 10);
  }, []);
  useEffect(() => {
    changeBlankHeight();
  }, [checkedDate, checkedFilters]);
  useEffect(() => {
    window.addEventListener('resize', changeBlankHeight);
    return () => window.removeEventListener('resize', changeBlankHeight);
  }, []);
  return (
    <div className="v2_pages_reports_filters">
      <div className="v2_report_filters_fixed" ref={wrapperRef}>
        <div className="v2_report_filters_wrapper">
          <div className="filters">
            <CombinedFilters
              dropdownFixed
              filters={initCombinedFilters(account)}
              checkedFilters={checkedFilters}
              defaultCheckedFilters={checkedFilters}
              renderLeft={() => (
                <DatePickerFilter
                  date={checkedDate}
                  visible={datePickerVisible}
                  onVisibleChange={setDatePickerVisible}
                  onChange={onDateChange}
                />
              )}
              onChange={onCombineChange}
              onFilterListLengthChange={onFilterListChange}
            />
          </div>
          <div className="opts">
            <DownloadCsvFilter onChange={onExport} />
            <Tooltip className="v2_reporting_page_tooltip" title="Reporting Information" body={REPORTING_INCOMPLETE_BODY}>
              <i className="material-icons">help_outline</i>
            </Tooltip>
            <DropdownMenu
              fixed
              labelKey="name"
              horizontalOffset={-176}
              data={REPORTS_MORE_OPTS}
              onVisibleChange={setIsOptsOpen}
              onChange={onOperation}
            >
              <i className={classNames({ 'material-icons': true, menu_open: isOptsOpen })}>more_vert</i>
            </DropdownMenu>
          </div>
        </div>
      </div>
    </div>
  );
};

Filters.propTypes = {
  onDateChange: PropTypes.func,
  onCombineChange: PropTypes.func,
  onOperation: PropTypes.func,
  onExport: PropTypes.func,
};

Filters.defaultProps = {
  onDateChange: noop,
  onCombineChange: noop,
  onOperation: noop,
  onExport: noop,
};

export default Filters;
