import {
  getTemplate,
  getTemplates,
  getTemplateSummary,
  getTemplateAdvReplacements,
  modifyTemplate,
  uploadTemplate,
} from 'app/graphql/utils/templates';
import config from 'lib/config';
import { catchFetchError } from 'lib/http';
import { isEmptyObject } from '../lib/lib';
import { locale } from '../lib/date';
import Model from './BaseModel';
import Application from './Application';

const manageUrl = config.get('manageUrl');
const baseUrl = `${manageUrl}templates`;

const PLAYABLE_CATEGORY = 'adaptive-creative';
const FULLSCREEN_CATEGORY = 'dynamic-template';
const NATIVE_CATEGORY = 'native';
const CATEGORIES = [
  {
    id: 0,
    name: 'Playables',
    value: PLAYABLE_CATEGORY,
    tooltipContent: 'Full-Screen ad type. Reserved for users who have an HTML Playable (.zip format) to use.',
    isAdminOnly: true,
    shouldHideInSSUI: false,
  },
  {
    id: 1,
    name: 'Full Screen',
    value: FULLSCREEN_CATEGORY,
    tooltipContent: 'Full-Screen ad type. Use a predefined template container and add your Video or accompanying images.',
    isAdminOnly: false,
    shouldHideInSSUI: false,
  },
  {
    id: 2,
    name: 'MREC',
    value: 'mrec',
    tooltipContent: 'Popular industry standard rectangular ad (300x250) that incorporates video or images for higher engagement.',
    isAdminOnly: true,
    shouldHideInSSUI: false,
  },
  {
    id: 3,
    name: 'Banner',
    value: 'banner',
    tooltipContent: 'A slim static or animated ad that remains on screen. 320x50, 300x50, 728x90 (tablet).',
    isAdminOnly: true,
    shouldHideInSSUI: false,
  },
  {
    id: 4,
    name: 'Native',
    value: 'native',
    tooltipContent: 'Ads that match the form and function of the publisher app.',
    isAdminOnly: false,
    shouldHideInSSUI: false,
  },
];

const TEMPLATES = [
  {
    name: 'Dynamic Interstitial Modern',
    creative_type: 'fullscreen',
    creativeFormat: 'Full Screen(Single-Page)',
  },
  {
    name: 'Create Template Test from legacy.vungle.com',
    creative_type: 'fullscreen',
    creativeFormat: 'Full Screen(Single-Page)',
  },
  {
    name: 'Peek',
    creative_type: 'fullscreen',
    creativeFormat: 'Full Screen(Single-Page)',
  },
  {
    name: 'Native Cinematic Feed',
    creative_type: 'fullscreen',
    creativeFormat: 'Full Screen(Single-Page)',
  },
  {
    name: 'Swipe_click2play',
    creative_type: 'fullscreen',
    creativeFormat: 'Full Screen(Single-Page)',
  },
  {
    name: 'Native Cinematic',
    creative_type: 'fullscreen',
    creativeFormat: 'Full Screen(Single-Page)',
  },
  {
    name: 'Native Looping Feed',
    creative_type: 'fullscreen',
    creativeFormat: 'Full Screen(Single-Page)',
  },
  {
    name: 'Tiles_click2play',
    creative_type: 'fullscreen',
    creativeFormat: 'Full Screen(Single-Page)',
  },
  {
    name: 'Native Looping',
    creative_type: 'fullscreen',
    creativeFormat: 'Full Screen(Single-Page)',
  },
  {
    name: 'Adaptive Creative Single-Page',
    creative_type: 'fullscreen',
    creativeFormat: 'Full Screen(Single-Page)',
  },
  {
    name: 'DONOTUSE-CREATIVETESTING-AC Single-Page',
    creative_type: 'fullscreen',
    creativeFormat: 'Full Screen(Single-Page)',
  },
  {
    name: 'Dynamic Interstitial',
    creative_type: 'fullscreen',
    creativeFormat: 'Full Screen(Single-Page)',
  },
  {
    name: 'Square Dynamic Interstitial',
    creative_type: 'fullscreen',
    creativeFormat: 'Full Screen(Single-Page)',
  },
  {
    name: 'Swipe',
    creative_type: 'fullscreen',
    creativeFormat: 'Full Screen(Single-Page)',
  },
  {
    name: 'Looping Video',
    creative_type: 'fullscreen',
    creativeFormat: 'Full Screen(Single-Page)',
  },
  {
    name: 'Square Video Overlay',
    creative_type: 'fullscreen',
    creativeFormat: 'Full Screen(Single-Page)',
  },
  {
    name: 'Subscription Looping Video',
    creative_type: 'fullscreen',
    creativeFormat: 'Full Screen(Single-Page)',
  },
  {
    name: 'Custom',
    creative_type: 'fullscreen',
    creativeFormat: 'Full Screen(Multi-Page)',
  },
  {
    name: 'Carousel Modern',
    creative_type: 'fullscreen',
    creativeFormat: 'Full Screen(Multi-Page)',
  },
  {
    name: 'Native ShowcaseFeed',
    creative_type: 'fullscreen',
    creativeFormat: 'Full Screen(Multi-Page)',
  },
  {
    name: 'Native Filmstrip Feed',
    creative_type: 'fullscreen',
    creativeFormat: 'Full Screen(Multi-Page)',
  },
  {
    name: 'Adaptive Creative Multi-Page',
    creative_type: 'fullscreen',
    creativeFormat: 'Full Screen(Multi-Page)',
  },
  {
    name: 'DONOTUSE-CREATIVETESTING-ACMulti-page',
    creative_type: 'fullscreen',
    creativeFormat: 'Full Screen(Multi-Page)',
  },
  {
    name: 'Carousel',
    creative_type: 'fullscreen',
    creativeFormat: 'Full Screen(Multi-Page)',
  },
  {
    name: 'ShowcaseNon-Brand',
    creative_type: 'fullscreen',
    creativeFormat: 'Full Screen(Multi-Page)',
  },
  {
    name: 'Gradient',
    creative_type: 'fullscreen',
    creativeFormat: 'Full Screen(Multi-Page)',
  },
  {
    name: 'Store Endcard',
    creative_type: 'fullscreen',
    creativeFormat: 'Full Screen(Multi-Page)',
  },
  {
    name: 'MREC Looping',
    creative_type: 'mrec',
    creativeFormat: 'MREC',
  },
  {
    name: 'MREC Looping Video',
    creative_type: 'mrec',
    creativeFormat: 'MREC',
  },
  {
    name: 'Image MREC',
    creative_type: 'mrec',
    creativeFormat: 'MREC',
  },
  {
    name: 'Video Banner 300x50',
    creative_type: 'banner',
    creativeFormat: 'Banner',
  },
  {
    name: 'Video Banner 320x50',
    creative_type: 'banner',
    creativeFormat: 'Banner',
  },
  {
    name: 'Video Banner 728x90',
    creative_type: 'banner',
    creativeFormat: 'Banner',
  },
  {
    name: 'Dynamic Banner',
    creative_type: 'banner',
    creativeFormat: 'Banner',
  },
  {
    name: 'Image Banner',
    creative_type: 'banner',
    creativeFormat: 'Banner',
  },
  {
    name: 'Image Native',
    creative_type: 'native',
    creativeFormat: 'Native',
  },
];

class Template extends Model {
  static getCategoriesOptions() {
    return CATEGORIES;
  }

  static isCategoryPlayable(templateCategory) {
    return templateCategory.value === PLAYABLE_CATEGORY;
  }

  static isCategoryNative(templateCategory) {
    return templateCategory.value === NATIVE_CATEGORY;
  }

  static isCategoryFullscreen(templateCategory) {
    return templateCategory.value === FULLSCREEN_CATEGORY;
  }

  static getTemplates() {
    return TEMPLATES;
  }

  static getAllowListOptions() {
    return [
      {
        name: 'iOS',
        value: 'ios',
      },
      {
        name: 'Android',
        value: 'android',
      },
      {
        name: 'Amazon',
        value: 'amazon',
      },
      {
        name: 'Windows',
        value: 'windows',
      },
    ];
  }

  constructor(attrs) {
    super(attrs);
    if (attrs.adUnit_config !== null && !isEmptyObject(attrs.adUnit_config)) {
      this.set('adUnit_config', attrs.adUnit_config);
    }
    if (attrs.template_category !== null && !isEmptyObject(attrs.template_category)) {
      this.set('template_category', attrs.template_category);
    }
    if (attrs.os_whitelist !== null && !isEmptyObject(attrs.os_whitelist)) {
      this.set('os_whitelist', attrs.os_whitelist);
    }
    this.map('created', locale);
    this.map('updated', locale);
    this.map('applications', this.getArrayRelation('applications', Application).bind(this));
    this.default('template_category', {});
    this.default('os_whitelist', {});
  }

  toServerObject() {
    const template = {};

    template.name = this.raw('name');
    template.supported_template_protocol = this.get('supported_template_protocol');
    template.mraid_url = this.get('mraid_url');
    template.template_category = this.get('template_category') || {};
    template.os_whitelist = this.get('os_whitelist') || {};

    return template;
  }
}

Template.make = Model.make(Template);
Template.get = (id) => getTemplate(id);
Template.getTemplateSummary = (id) => getTemplateSummary(id);
Template.getReplacements = (id) => getTemplateAdvReplacements(id);
Template.getAll = (filters) => getTemplates(filters);
Template.getTemplatesForSSUI = () => getTemplates();
Template.save = (template) => modifyTemplate(template.get('id'), template.toServerObject());
Template.upload = (input) => catchFetchError(uploadTemplate(input));

export const REPLACEMENT_TYPE = {
  BOOLEAN: 'BOOLEAN',
  BUNDLE: 'BUNDLE',
  FLOAT: 'FLOAT',
  IMAGE: 'IMAGE',
  INT: 'INT',
  STRING: 'STRING',
  URL: 'URL',
  RADIO: 'RADIO',
};

export default Template;
export {
  baseUrl,
};
