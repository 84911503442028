import { getCreativeReplacements, getCreativeBiggestVideo } from 'app/graphql/utils/creative';
import config from 'lib/config';
import Model from './BaseModel';
import { post, catchFetchError } from '../lib/http';

// Modal
class Preview extends Model {}

const baseUrl = `${config.get('champaUrl')}`; // NB: process.env cannot be destructured

// API methods
Preview.getCdnURL = ({ id, url, format }) => post(
  `${baseUrl}asset/preview`,
  { id, url, format },
);
Preview.getExistingCreativeReplacements = (id) => catchFetchError(getCreativeReplacements(id));
Preview.getExistingCreativeBiggestVideo = (id) => catchFetchError(getCreativeBiggestVideo(id));
Preview.validateAssetByUrl = ({ url, format }) => post(
  `${baseUrl}asset/v2/validate/url`,
  { url, format },
);

// Export Preview
export default Preview;
