import React, { useCallback } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import './filter.scss';

const classPrefix = 'v2_component_reports_compare_filter';

const Filter = ({
  open,
  className,
  clearable,
  children,
  onClear,
}) => {
  const handleClear = useCallback((event) => {
    event.stopPropagation();
    onClear(event, open);
  }, [onClear, open]);

  return (
    <div
      className={classnames(
        classPrefix, {
          [`${classPrefix}-open`]: open,
        },
        className,
      )}
    >
      <i className="material-icons">filter_list</i>
      {clearable && (
        <i className="material-icons" onClick={handleClear}>cancel</i>
      )}
      <span>{children}</span>
      <i className="material-icons">keyboard_arrow_down</i>
    </div>
  );
};

Filter.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  clearable: PropTypes.bool,
  onClear: PropTypes.func,
};

Filter.defaultProps = {
  className: null,
  open: false,
  clearable: false,
  onClear: noop,
};

export default Filter;
