import {
  ApolloClient,
  InMemoryCache,
  from,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { Deflate } from 'pako';
import { getServiceType } from 'lib/serviceType';
import config from 'lib/config';
import { getAuthUser, get } from '../../lib/session';
import { unauthorizedLogout } from '../../lib/auth';

const madmenURL = `${config.get('madmenUrl')}graphql`;
const uploadURL = `${config.get('uploadUrl')}graphql`;
const supplyGraphQLUrl = `${config.get('slydellUrl')}manage/query`;

let madmenClient;
let uploadClient;
let supplyClient;

export const gzipFetch = (resource, options) => {
  const disableGZip = get('disable_gzip');

  if (!disableGZip) {
    const deflator = new Deflate({
      gzip: true,
    });
    const p = options;
    deflator.push(options.body, true);
    p.headers['Content-Encoding'] = 'gzip';

    p.body = deflator.result;
    return fetch(resource, p);
  }
  return fetch(resource, options);
};

const madmenLink = createHttpLink({
  uri: madmenURL,
  fetch: gzipFetch,
});

const supplyHttpLink = createHttpLink({
  uri: supplyGraphQLUrl,
});

// After enable gzip, upload action will get this error in ticket https://vungle.atlassian.net/browse/DB-9079.
// In addition, uploaded file is sent to backend through binary data, so I disable gzip for upload action.
const uploadLink = createUploadLink({
  uri: uploadURL,
});

const authLink = setContext((_, { headers }) => {
  const { token } = getAuthUser() || { token: '' };

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const vungleSourceLink = (serviceType) => setContext((_, { headers }) => ({
  headers: {
    ...headers,
    'Vungle-source': serviceType,
  },
}));

const unauthorizedLink = onError(({ networkError }) => {
  if (networkError?.statusCode === 401) unauthorizedLogout();
});

const getBaseClient = (...specialLinks) => new ApolloClient({
  link: from([
    authLink,
    unauthorizedLink,
    ...specialLinks,
  ]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
    mutate: {
      fetchPolicy: 'no-cache',
    },
  },
});

export const getClient = () => {
  if (!madmenClient) {
    madmenClient = getBaseClient(vungleSourceLink(getServiceType()), madmenLink);
  }
  return madmenClient;
};

export const getUploadClient = () => {
  if (!uploadClient) {
    uploadClient = getBaseClient(vungleSourceLink(getServiceType()), uploadLink);
  }
  return uploadClient;
};

// eslint-disable-next-line import/prefer-default-export
export const getSlydellClient = () => {
  if (!supplyClient) {
    supplyClient = getBaseClient(vungleSourceLink('publisher'), supplyHttpLink);
  }
  return supplyClient;
};
