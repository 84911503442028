import React, {
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import config from '../../../../../lib/config';
import Select from '../../../../../components/Form/Select/SelectContainer';
import Button from '../../../../../components/V2/Button/Button';
import CollapseSection from '../../../../../components/V2/Collapse/CollapseSection';
import CollapseURLValidator from '../CollapseURLValidator/CollapseURLValidator';
import { allEventsValid, validateMacroURL } from '../helper';
import './additionalEvents.scss';

const { additionalEvents } = config.get('campaigns');

export const prefixCls = 'v2_campaign_mmp_events';

function AdditionalEvents({ currentEvents, platform, onChange }) {
  const [events, setEvents] = useState(
    currentEvents && currentEvents.length ? currentEvents : [{ ...additionalEvents[0], url: '' }],
  );

  useEffect(() => {
    const newEvents = events.map((event) => ({
      ...event,
      error: validateMacroURL(event.url, platform),
    }));
    setEvents(newEvents);
    // --> potential bug inside this useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platform]);

  const addEvent = useCallback(() => {
    const newEvent = additionalEvents.find((supportedEvent) => {
      const eventIndex = events.findIndex((event) => event.name === supportedEvent.name);
      return eventIndex === -1;
    });
    newEvent.url = '';
    const newEvents = [...events, newEvent];
    setEvents(newEvents);
    onChange(newEvents);
  }, [events, onChange]);

  const changeEventType = useCallback((eventName, eventIndex) => {
    const newEvents = [...events];
    const originalEvent = events[eventIndex];
    const supportedEvent = additionalEvents.find(({ name }) => name === eventName);
    const duplicateEventIndex = newEvents.findIndex(({ name }) => name === eventName);
    if (duplicateEventIndex !== -1) {
      const duplicateEvent = events[duplicateEventIndex];
      newEvents[duplicateEventIndex] = {
        ...duplicateEvent,
        name: originalEvent.name,
        description: originalEvent.description,
      };
    }
    newEvents[eventIndex] = {
      ...originalEvent,
      name: eventName,
      description: supportedEvent.description,
    };
    setEvents(newEvents);
    onChange(newEvents);
  }, [events, onChange]);

  const changeEvent = useCallback((url, index) => {
    const newEvent = events[index];
    newEvent.url = url;
    newEvent.error = validateMacroURL(url, platform);
    const newEvents = [
      ...events.slice(0, index),
      newEvent,
      ...events.slice(index + 1),
    ];
    setEvents(newEvents);
    onChange(newEvents);
  }, [events, onChange, platform]);

  const removeEvent = useCallback((index) => {
    const newEvents = [
      ...events.slice(0, index),
      ...events.slice(index + 1),
    ];
    setEvents(newEvents);
    onChange(newEvents);
    // --> potential bug inside this useCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platform, events, onChange]);

  return (
    <CollapseSection
      className={prefixCls}
      title="Additional MMP Event Tracking (Optional)"
      id="additionalEvents"
      description="Additional engagements available for tracking. Select an event from the list, then add your URL."
      expanded={false}
    >
      {
        events.map((event, index) => (
          <div className="event_container" key={event.name}>
            <div className="select_wrapper">
              <Select className="select" value={event.name} onChange={(type) => changeEventType(type, index)}>
                {
                  additionalEvents.map(({ name, description }) => (
                    <Select.Option key={name} value={name} hidden={events.some((x) => x.name === name) || event.name === name}>
                      {description}
                    </Select.Option>
                  ))
                }
              </Select>
            </div>
            <div className="validator">
              <CollapseURLValidator
                value={event.url}
                error={event.error}
                deletable
                onChange={(url) => changeEvent(url, index)}
                onDelete={() => removeEvent(index)}
              />
            </div>
          </div>
        ))
      }
      {
        (!events.length || ((additionalEvents.length !== events.length) && allEventsValid(events))) && (
          <Button size="small" type="ghost" onClick={addEvent}>Add Another Event</Button>
        )
      }
    </CollapseSection>
  );
}

export default memo(AdditionalEvents);
