const imageVideoColumns = [
  {
    label: 'Asset',
    id: 'asset',
    flex: 5.5,
  },
  {
    label: 'Date Added',
    id: 'created',
    sortable: true,
    flex: 1,
  },
  {
    label: 'Orientation',
    id: 'orientation',
    flex: 1,
  },
  {
    label: 'Dimension',
    id: 'dimension',
    flex: 1,
  },
  {
    label: 'size',
    id: 'size',
    flex: 1,
  },
  {
    label: 'Duration',
    id: 'duration',
    flex: 1,
    justifyContent: 'flex-end',
  },
];

const playableColumns = [
  {
    label: 'Asset',
    id: 'asset',
    flex: 5,
  },
  {
    label: 'Status',
    id: 'status',
    flex: 1,
    justifyContent: 'flex-start',
  },
  {
    label: 'Date Added',
    id: 'created',
    sortable: true,
    flex: 1,
  },
  {
    label: 'Size',
    id: 'size',
    flex: 1,
  },
];

// eslint-disable-next-line import/prefer-default-export
export const getListHeaderColumns = (type) => {
  if (type === 'bundle_adaptive_creative') {
    return playableColumns;
  }
  if (type === 'image' || type === 'video') {
    return imageVideoColumns;
  }
  return [];
};
