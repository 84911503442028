class Ad {
  constructor({ src, frame }) {
    this.src = src;
    this.frame = frame;
  }

  onLoad = () => { }

  // eslint-disable-next-line class-methods-use-this
  polyfill() { }

  // eslint-disable-next-line class-methods-use-this
  togglePortrait() {}

  // eslint-disable-next-line class-methods-use-this
  toggleLandscape() { }

  // eslint-disable-next-line class-methods-use-this
  toggleView() {}
}

export default Ad;
