import { getNextRoute, getPath, pathConfig } from 'services/advertiser/paths';
import { isFunction } from 'lib/lib';
import { validateBidding } from 'lib/helpers/campaigns/campaignIndex';
import Button from 'components/Button/Button';
import React from 'react';

const campaignGoTo = (router, goTo) => {
  if (goTo) {
    router.history.push(goTo);
    try {
      window.analytics.page(goTo);
    } catch (ex) {
      // do nothing
    }
  }
};

const ConfirmAndContinue = ({
  location,
  campaign,
  changingValue,
  editing,
  router,
  pageContinue,
}) => {
  const { path } = getPath(location);
  const conf = pathConfig[path] || {};
  let enableContinue = true;
  let enableConfirm = false;
  if (isFunction(campaign[conf.final])) {
    enableContinue = campaign[conf.final]();
  } else if (isFunction(conf.enabled)) {
    enableContinue = conf.enabled(campaign);
  } else if (isFunction(campaign[conf.enabled])) {
    enableContinue = campaign[conf.enabled]();
  }
  if (changingValue && validateBidding(campaign) && campaign.canConfirm()) {
    enableConfirm = true;
  }
  const nextRoute = getNextRoute(campaign, conf.to, location && location.pathname);
  if (!campaign.isValidDailySpend()) {
    enableContinue = false;
    enableConfirm = false;
  }

  if (nextRoute !== 'details' && !campaign.validateDetail()) {
    enableContinue = false;
    enableConfirm = false;
  }

  if (!campaign.validateSkAdNetworkSettings()) {
    enableContinue = false;
    enableConfirm = false;
  }

  const confirmGoTo = !enableConfirm ? null : 'confirm';
  const continueGoTo = !enableContinue ? null : nextRoute;

  return (
    <>
      {editing && (
        <Button
          ghost
          onClick={() => campaignGoTo(router, confirmGoTo)}
          disabled={!enableConfirm || !pageContinue}
        >
          Review
        </Button>
      )}
      <p className="p2">&nbsp;</p>
      <Button
        continue
        onClick={() => campaignGoTo(router, continueGoTo)}
        disabled={!enableContinue || !pageContinue}
      >
        Continue
      </Button>
    </>
  );
};

export default ConfirmAndContinue;
