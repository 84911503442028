import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { cloneDeep, noop } from 'lodash';
import { isEndCard, VIDEO_REPLACEMENT_KEYS } from 'lib/helpers/creatives/creativeIndex';
import { filterTemplates } from 'services/admin/views/creatives/form/sections/CreativeOptions';
import {
  getCategoriesOptionsForCreativeManager,
} from 'lib/capabilities/templates';
import { Tabs } from 'components/V2';
import NativePreview from 'components/Creatives/NativePreview/NativePreview';
import Button from '../../../../../../components/V2/Button/Button';
import Select from '../../../../../../components/Form/Select/SelectContainer';
import CreativePanel from '../../../../../Templates/CreativeBuilder/CreativePanel/CreativePanel';
import Field from './Field';
import Replacements from './Replacements';
import PreviewContent from '../../../../../admin/views/creatives/form/sections/PreviewContent/PreviewContent';
import LanguageSelector from '../../../../../../components/Form/LanguageSelector/LanguageSelector';
import Template from '../../../../../../models/Template';
import TreeSpinner from '../../../../../../components/V2/TreeSelect/TreeSpinner';
import CreativeTooltip from '../../../../../Templates/CreativeBuilder/CreativeTooltip/CreativeTooltip';
import ValidatedText from '../../../../../../components/Form/ValidatedText/ValidatedText';
import { isEmptyObject } from '../../../../../../lib/lib';
import './Create.scss';

const { Option } = Select;

const requests = {
  getTemplateReplacements: null,
  getTemplateSummary: null,
};

const getCreateTooltipContent = (type) => (
  type === 'campaign'
    ? `This creative will be added to your list of creatives for this app.
      Please select creatives to attach in the section below.`
    : `This creative will be added to your list of creatives for this app.
      Please select campaigns to attach in the next section.`
);

const Create = ({
  creative,
  campaign,
  createCreative,
  history,
  createType,
  creativeCreated,
  setCreativeCreated = noop,
}) => {
  const templateCategories = getCategoriesOptionsForCreativeManager(campaign, creative);
  const application = creative.get('application');
  const platform = application.get('platform') || application.get('attrs.platform');
  const [templates, setTemplates] = useState([]);
  const [templateCategory, setTemplateCategory] = useState(templateCategories[0]);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [requiredKeys, setRequiredKeys] = useState([]);
  const [allReplacements, setAllReplacements] = useState([]);
  const [replacementsByGroups, setReplacementsByGroups] = useState({});
  const [replacementsLoading, setReplacementsLoading] = useState(false);

  const updateTemplateOptions = useCallback(filterTemplates, [templateCategory]);
  const isACTemplate = selectedTemplate?.get('is_custom_creative');

  const onImageChange = (replacement, value, assetId, order) => {
    creative.setReplacementValue(replacement, value);
    creative.setReplacementData({
      order,
      assetId,
      ...replacement,
    });
  };
  const endCardKey = 'endCard';

  const onBundleChange = useCallback((url, endcard) => {
    creative.set('endcard', endcard.get('id'));
    creative.set('endcardUrl', url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplate, creative]);

  const getCreativeTypes = useCallback(() => (
    updateTemplateOptions(templates, templateCategory.value, selectedTemplate?.get('id'))
    // --> potential bug inside this useCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [templates, templateCategory, selectedTemplate]);

  const getTemplates = () => {
    setReplacementsLoading(true);
    Template.getTemplatesForSSUI().then((result) => {
      const ssUITemplates = result.response.map((data) => new Template(data));
      const allTemplates = ssUITemplates.filter((template) => {
        const osWhitelist = template.get('os_whitelist');
        if (isEmptyObject(osWhitelist)) {
          return true;
        }
        return osWhitelist[platform] === true;
      });
      setTemplates(allTemplates);
      setReplacementsLoading(false);
    });
  };

  const setRequests = (request, type) => {
    requests[type] = request;
  };

  const cancelRequests = () => {
    Object.keys(requests).forEach((type) => {
      const request = requests[type];
      if (request && request.cancel) {
        request.cancel();
        requests[type] = null;
      }
    });
  };

  const getTemplateReplacements = useCallback(() => {
    const request = Template.getReplacements(selectedTemplate.get('id'));
    setRequests(request, 'getTemplateReplacements');
    return request;
  }, [selectedTemplate]);

  const getTemplateSummary = useCallback(() => {
    const request = Template.getTemplateSummary(selectedTemplate.get('id'));
    setRequests(request, 'getTemplateSummary');
    return request;
  }, [selectedTemplate]);

  const getReplacementsHandler = (result) => {
    const newReplacementsByGroups = {};
    const replacements = result.response.advReplacements;

    creative.set('replacements', []);

    replacements.forEach((replacement) => {
      let value = isEndCard(replacement.type) ? undefined : replacement.value;

      if (replacement.key === 'APP_NAME') {
        value = application.get('name')
          || creative.get('application.attrs.name');
      }

      if (replacement.key === 'APP_ICON') {
        value = creative.get('application.attrs.store.thumbnail')
          || application.get('store.thumbnail');
      }

      if (replacement.key === 'APP_DESCRIPTION') {
        value = application.get('store.company')
          || creative.get('application.attrs.store.company');
      }

      if (replacement.key === 'CTA_BUTTON_URL') {
        value = application.get('store.url')
          || application.get('application.attr.store.url');
      }

      creative.setReplacementValue(replacement, value);

      newReplacementsByGroups[replacement.group] = [
        ...(newReplacementsByGroups[replacement.group] || []),
        replacement,
      ];
    });
    setReplacementsByGroups(newReplacementsByGroups);
  };

  const getTemplateSummaryHandler = (result) => {
    const { replacements } = result.response;
    const keys = [];
    const isVideoRequired = !!replacements.find(({ group, key, role }) => (
      group === 'Product Description'
        && VIDEO_REPLACEMENT_KEYS.indexOf(key) !== -1 && role.valueRequired
    ));
    const isEndCardRequired = replacements.find(({ key, value = '' }) => key === 'HAS_ENDCARD' && value.toLowerCase() === 'true');

    if (isVideoRequired) {
      keys.push('video');
    }

    if (isEndCardRequired) {
      keys.push(endCardKey);
    }

    setRequiredKeys(keys);
    setAllReplacements(replacements);
  };

  const reset = (responseId) => {
    const shouldReset = !!responseId;
    if (shouldReset) {
      setTemplateCategory(templateCategories[0]);
      setSelectedTemplate(cloneDeep(selectedTemplate));
    }
  };

  useEffect(() => {
    if (!creativeCreated) {
      getTemplates();
    }
  }, [creativeCreated]);

  useEffect(() => {
    const currentOptions = getCreativeTypes();
    if (currentOptions.length > 0) {
      const template = templates.find((t) => t.get('id') === currentOptions[0].get('id'));
      setSelectedTemplate(template);
    }
    // --> potential bug inside this useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates, templateCategory]);

  useEffect(() => {
    if (selectedTemplate) {
      cancelRequests();

      Promise.all([getTemplateReplacements(), getTemplateSummary()])
        .then(([replacements, summaries]) => {
          creative.selectTemplate(selectedTemplate);
          getReplacementsHandler(replacements);
          getTemplateSummaryHandler(summaries);
          setReplacementsLoading(false);
        });
    }
    // --> potential bug inside this useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplate]);

  const showCreativeTypeSelector = !Template.isCategoryPlayable(templateCategory)
    && !Template.isCategoryNative(templateCategory);

  const isNativeTemplate = !!selectedTemplate?.raw('template_category.native');

  return (
    <div className="views__advertiser__campaigns__edit__creatives">
      <CreativePanel>
        <Field>
          <div className="creative-format-note">
            <div className="creative-format-note__title">Creative Format</div>
            <div className="creative-format-note__description">What template category do you wish to use?</div>
          </div>
        </Field>
        <Field>
          <Tabs
            className="template-tabs"
            columns={templateCategories.map(({ id, name }) => ({
              key: id,
              title: name,
              selected: templateCategory.id === id,
            }))}
            onClick={({ key: id }) => {
              if (templateCategory.id === id) {
                return;
              }
              setReplacementsLoading(true);
              setTemplateCategory(templateCategories.find((tc) => tc.id === id));
            }}
          />
        </Field>
        <div className="creative-builder-container">
          <div className="creative-options-wrapper">
            {
              showCreativeTypeSelector && (
              <Field
                name="Creative Type"
                tooltipConfig={{
                  title: 'Creative Type',
                  content: 'Further drill down on what creative you would like to build.',
                }}
              >
                <Select
                  value={selectedTemplate?.get('id')}
                  disabled={templates.length === 0}
                  onChange={(v) => {
                    if (v === selectedTemplate.get('id')) {
                      return;
                    }
                    setReplacementsLoading(true);
                    const template = templates.find((t) => t.get('id') === v);
                    setSelectedTemplate(template);
                  }}
                >
                  {
                    getCreativeTypes().map((template) => {
                      const templateId = template.get('id');
                      const templateName = template.get('name');
                      return (
                        <Option
                          key={templateId}
                          value={templateId}
                        >
                          {templateName}
                        </Option>
                      );
                    })
                  }
                </Select>
              </Field>
              )
            }
            {
              replacementsLoading ? (
                <div className="replacements-loading-wrapper">
                  <TreeSpinner />
                </div>
              ) : (
                selectedTemplate && (
                  <Replacements
                    creative={creative}
                    application={application}
                    template={selectedTemplate}
                    replacementsByGroups={replacementsByGroups}
                    onImageChange={onImageChange}
                    onBundleChange={onBundleChange}
                    requiredKeys={requiredKeys}
                  />
                )
              )
            }
            <Field
              name="Language"
              tooltipConfig={{
                title: 'Language',
                content: 'Identifies the language of the ad.',
              }}
            >
              <LanguageSelector
                value={creative.get('language.code')}
                onChange={(v) => {
                  creative.set('language.code', v);
                }}
              />
            </Field>
            <Field
              name="Creative Name"
              tooltipConfig={{
                title: 'Creative Name',
                content: 'Friendly name that will display as default for identifying purposes.',
              }}
            >
              <ValidatedText
                noIcon
                isValid
                value={creative.get('name') || ''}
                onChange={(value) => creative.set('name', value)}
                placeholder="Provide a unique name"
              />
            </Field>

            <Field>
              <Note />
            </Field>

            <div className="button-wrapper">
              <Button
                className="submit-button"
                type="primary"
                disabled={
                  !selectedTemplate
                  || replacementsLoading
                  || !creative.isValid(requiredKeys)
                  || (requiredKeys.includes(endCardKey) && !creative.get('endcard'))
                }
                onClick={() => {
                  setCreativeCreated();
                  createCreative({
                    creative,
                    campaign,
                    history,
                    callback: reset,
                  });
                }}
                size="small"
              >
                Create Creative
              </Button>
              <CreativeTooltip
                title="Create Creative"
                body={getCreateTooltipContent(createType)}
              />
            </div>
          </div>
          <div className="previewer-wrapper">
            {isNativeTemplate
              ? (
                <NativePreview
                  replacements={creative.raw('replacements')}
                  videoUrl={creative.get('video')?.url}
                  languageCode={creative.get('language.code')}
                  creativeId={creative.get('id')}
                  isMissionControl={false}
                  isShowEditCreativeAction={false}
                  history={history}
                />
              )
              : (
                <PreviewContent
                  shouldDisplayTooltip
                  shouldAutoPlay={!isACTemplate}
                  shouldHideFrame={
                    replacementsLoading
                    || !creative.isReadyForPreview(requiredKeys)
                  }
                  allReplacements={allReplacements}
                  creativeId={creative.get('id')}
                  platform={platform}
                  replacements={creative.raw('replacements')}
                  template={selectedTemplate?.toObject()}
                  videoReplacementKeys={VIDEO_REPLACEMENT_KEYS}
                  videoUrl={creative.get('video')?.url}
                  endcard={creative.get('endcard')}
                  endcardUrl={creative.get('endcardUrl')}
                />
              )}
          </div>
        </div>
      </CreativePanel>
    </div>
  );
};

const Note = () => (
  <div className="vg-note">
    <p className="p6">
      <span className="p7">Creative Approval:&nbsp;</span>
      Heads up, after your creative is built, our team will need to do a quick review. Learn more about creative approval&nbsp;
      <a
        href="https://support.vungle.com/hc/en-us/articles/360035210552"
        onClick={(e) => e.stopPropagation()}
        target="_blank"
        rel="noreferrer"
      >
        here
      </a>
    </p>
  </div>
);

export default Create;
