import { getStore } from 'app/storeprovider';
import { ns } from './ConfirmActionContainer';

const triggerConfirm = ({
  type = 'ACTION',
  header,
  message,
  name,
  cancelText,
  confirmText,
  bodyAdditional,
  onCancel,
  onConfirm,
  onConfirmAlternate,
  confirmTextAlternate,
  confirmOnly,
  className,
  isAsyncConfirm,
}) => {
  const store = getStore();
  store.dispatch({
    type,
    end: ({ state }) => {
      const newState = { ...state };
      newState[ns] = {
        header,
        message,
        name,
        cancelText,
        confirmText,
        bodyAdditional,
        onCancel,
        onConfirm,
        onConfirmAlternate,
        confirmTextAlternate,
        confirmOnly,
        className,
        isAsyncConfirm,
      };
      return newState;
    },
  });
};

export default triggerConfirm;
