import {
  PENDING,
  NEEDS_ATTENTION,
  VISUAL_QA,
  FULL_QA,
  ACTIVE,
} from 'app/constants/asset';
import { isAdminService } from 'lib/serviceType';

const PENDING_TIP = `Your Playable (.zip format) is still in the “Under Visual QA” phase and being review by our internal team. 
Use the “Review History” link to view the entire history of the asset.`;

const NEEDS_ATTENTION_TIP = `Your Playable (.zip format) is unable to serve.
${isAdminService() ? 'You may assign to a creative, however we need your input.' : 'We need your input.'} 
Use the “Review History” link to view the entire history of the asset and add input on how to move forward.`;

const VISUAL_QA_TIP = `Playable (.zip format) is being reviewed and is unable to serve. 
${isAdminService() ? 'You can still assign to a creative' : ''}. 
Use the “Review History” link below to view the entire history of the asset.`;

const FULL_QA_TIP = `Playable (.zip format) is being reviewed and is unable to serve. 
${isAdminService() ? 'You can still assign to a creative.' : ''} 
Use the “Review History” link to view the entire history of the asset.`;

const ACTIVE_TIP = `Playable (.zip format) is ready to serve. 
Use the “Review History” link to view the entire history of the asset.`;

const statusTooltip = {
  [PENDING]: PENDING_TIP,
  [NEEDS_ATTENTION]: NEEDS_ATTENTION_TIP,
  [VISUAL_QA]: VISUAL_QA_TIP,
  [FULL_QA]: FULL_QA_TIP,
  [ACTIVE]: ACTIVE_TIP,
};

export {
  PENDING_TIP,
  NEEDS_ATTENTION_TIP,
  VISUAL_QA_TIP,
  FULL_QA_TIP,
  ACTIVE_TIP,
  statusTooltip,
};
