import React, { createRef } from 'react';
import {
  compose, namespace, splash,
} from '../../../../../app/app';
import Application from '../../../../../models/Application';
import Cache from '../../../../../lib/cache/Cache';
import FiltersCache from '../../../../../lib/cache/FiltersCache';
import {
  APPLICATION_CAMPAIGN_CREATIVE_TAB_KEY, APPLICATION_CAMPAIGN_LIST_CACHE_KEY, APPLICATION_CREATIVE_LIST_CACHE_KEY, REPORTS_CACHE_KEY,
} from '../../../../../lib/cache/constant';
import { addCampaign, addCreative } from '../List/ListContainer';
import CampaignList, { defaultCacheValues as defaultCampaignCacheValues } from './CampaignList/CampaignList';
import CreativeList, { defaultCacheValues as defaultCreativeCacheValues } from './CreativeList/CreativeList';
import TopBar from './Components/TopBar/TopBar';
import { isCampaignDimension } from './helper';
import './campaignCreativeList.scss';

const ns = 'views.applications.details';
const updateState = namespace(ns);

const isCreativesRoute = (props) => props.location.pathname.endsWith('/creatives');

class CampaignCreativeList extends React.Component {
  constructor(p) {
    super(p);
    this.campaignListRef = createRef();
    this.creativeListRef = createRef();
    const userId = p.authUser.id;
    this.reportsCache = new FiltersCache(userId, REPORTS_CACHE_KEY);
    this.tabCache = new Cache(userId, APPLICATION_CAMPAIGN_CREATIVE_TAB_KEY, 'campaign');
    this.campaignCache = new FiltersCache(userId, APPLICATION_CAMPAIGN_LIST_CACHE_KEY, defaultCampaignCacheValues);
    this.creativeCache = new FiltersCache(userId, APPLICATION_CREATIVE_LIST_CACHE_KEY, defaultCreativeCacheValues);
    this.state = {
      node: null,
      dimension: isCreativesRoute(p) ? 'creative' : this.tabCache.get(),
    };
  }

  componentDidUpdate() {
    const {
      refreshCampaigns, refreshCreatives, resetRefreshState,
    } = this.props;
    if (refreshCampaigns || refreshCreatives) {
      this.currentRef().refresh();
      resetRefreshState();
    }
  }

  clearCache = () => {
    this.tabCache.save('campaign');
    this.campaignCache.save(defaultCampaignCacheValues);
    this.creativeCache.save(defaultCreativeCacheValues);
  };

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.clearCache);
  }

  async componentDidMount() {
    const {
      history,
      match,
    } = this.props;

    if (!isCreativesRoute(this.props) && history.action === 'PUSH') {
      this.clearCache();
      this.setState({
        dimension: 'campaign',
      });
    }

    const { id } = match.params;
    const { response } = await Application.getApplicationForReporting(id);
    const node = new Application(response);
    this.setState({ node });

    window.addEventListener('beforeunload', this.clearCache);
  }

  onTabClick = (dimension) => {
    this.setState({ dimension });
    this.tabCache.save(dimension);
  };

  add = () => {
    const { dimension } = this.state;
    if (isCampaignDimension(dimension)) {
      this.currentRef().addCampaign();
      return;
    }
    this.currentRef().addCreative();
  };

  currentRef = () => {
    const { dimension } = this.state;
    const { campaignListRef, creativeListRef } = this;
    return isCampaignDimension(dimension) ? campaignListRef.current : creativeListRef.current;
  }

  dropdownAction = (e) => this.currentRef().executeDropdown(e);

  downloadCSV = () => this.currentRef().downloadCSV();

  viewReport = () => this.currentRef().viewReport();

  render() {
    const { node } = this.state;
    if (!node) {
      return null;
    }

    const { dimension } = this.state;
    const { reportsCache } = this;
    const props = {
      ...this.props,
      node,
      reportsCache,
    };
    return (
      <div className="application_details">
        <TopBar
          node={node}
          dimension={dimension}
          onAdd={this.add}
          onViewReport={this.viewReport}
          onTabClick={this.onTabClick}
          onDropdownOptions={this.dropdownAction}
          onDownloadCSV={this.downloadCSV}
          onBack={this.props.history.goBack}
        />
        {
          isCampaignDimension(dimension) ? <CampaignList ref={this.campaignListRef} {...props} />
            : <CreativeList ref={this.creativeListRef} {...props} />
        }
      </div>
    );
  }
}

const changeCampaignStatus = (() => {
  const action = ({ campaign, status }) => campaign.changeStatus(status);
  const start = () => splash({ start: true, text: 'changing campaign state' });
  const end = ({
    state,
    result: {
      ok,
    } = {},
    props: {
      status,
    },
  }) => {
    if (!ok && status === 'pause') {
      return {
        ...splash({ finish: true, text: 'Unable to pause campaign' }),
      };
    }
    return ({
      ...updateState(state, { refreshCampaigns: true }),
      ...splash({ finish: true, text: 'success!' }),
    });
  };
  return { action, end, start };
})();

const changeCreativeStatus = (() => {
  const action = ({ creative, status, shouldDetachAllCreatives }) => (
    status === 'pause'
      ? creative.pause(!!shouldDetachAllCreatives)
      : creative.changeStatus(status)
  );
  const start = () => splash({ start: true, text: 'changing creative state' });
  const end = ({
    state,
    result: {
      ok,
    } = {},
    props: {
      status,
      shouldDetachAllCreatives,
    },
  }) => {
    if (!ok && status === 'pause') {
      const text = shouldDetachAllCreatives
        ? 'Unable to pause creative - failed to detach creatives'
        : 'Unable to pause creative';
      return {
        ...splash({ finish: true, text }),
      };
    }
    return ({
      ...updateState(state, { refreshCreatives: true }),
      ...splash({ finish: true, text: 'success!' }),
    });
  };
  return { action, end, start };
})();

const resetRefreshState = ({ state }) => updateState(state, { refreshCampaigns: false, refreshCreatives: false });

const props = (state) => ({
  ...state[ns],
  authUser: state.authUser,
});

const actions = {
  addCampaign,
  addCreative,
  changeCampaignStatus,
  changeCreativeStatus,
  resetRefreshState,
};

export default compose(CampaignCreativeList, { props, actions });
