import Cookies from 'js-cookie';
import { history } from '../app/app';
import { isFunction } from './lib';

const headers = new Headers();
headers.append('Accept', 'application/json, text/plain, */*');
headers.append('Vungle-Version', '1');

const statusActions = {
  500: () => {
    history.push('/platform_down');
  },
};

const addHeader = (name, content) => {
  headers.delete(name);
  headers.append(name, content);
};

const addAuthHeader = (user) => {
  if (!user.token) {
    return;
  }
  if (headers.get('Authorization')) {
    headers.delete('Authorization');
  }
  const cookieToken = Cookies.get('token');
  if (!cookieToken || cookieToken !== user.token) {
    // see https://vungle.atlassian.net/browse/DB-5855 for details
    document.cookie = 'token=; domain=.vungle.com; expires=Thu, 01 Jan 1970 00:00:00 UTC;';
    // if cookieToken is empty, we set the cookie token to user.token
    // if cookieToken differentfrom user.token, set to user.token
    Cookies.set('token', user.token);
  }
  addHeader('Authorization', `Bearer ${user.token}`);
  // this is for auth app
  addHeader('token', user.token);
};

const makeOptions = ({ headers: headerOptions = {} } = {}) => {
  Object.entries(headerOptions).forEach((option) => addHeader(...option));

  return ({
    headers,
  });
};

const makeResponseHeaders = (h) => ({
  requestId: h.get('Vungle-Request-ID'),
  link: h.get('Link'),
  newAuthToken: h.get('Vungle-Auth-Token'),
});

const makeResponsePagination = (h) => ({
  page: Number(h.get('Vungle-Pagination-Page')),
  pages: Number(h.get('Vungle-Pagination-Pages')),
  perPage: Number(h.get('Vungle-Pagination-Per-Page')),
  total: Number(h.get('Vungle-Pagination-Total')),
});

const doJsonRequest = () => headers.set('Content-Type', 'application/json');
const doNormalRequest = () => headers.delete('Content-Type');

const _makeCancelable = (promise, onCancel = () => { }) => {
  let rejectFn;

  const wrappedPromise = new Promise((resolve, reject) => {
    rejectFn = reject;

    Promise.resolve(promise)
      .then(resolve)
      .catch(reject);
  });

  wrappedPromise.cancel = () => {
    onCancel();
    rejectFn({ canceled: true });
  };

  return wrappedPromise;
};

const doRequest = (url, requestOptions, { json = true } = {}) => {
  if (json) {
    doJsonRequest();
  } else {
    doNormalRequest();
  }
  const controller = new AbortController();
  const { signal } = controller;
  const request = new Request(url, {
    ...makeOptions(),
    ...requestOptions,
    signal,
  });

  return _makeCancelable(new Promise(async (resolve, reject) => { //eslint-disable-line
    try {
      const result = await fetch(request);
      const { status } = result;
      const action = statusActions[status];
      if (isFunction(action)) {
        action();
      }
      const response = await result.json();

      const responseHeaders = makeResponseHeaders(result.headers);
      if (responseHeaders.newAuthToken) {
        addAuthHeader({ token: responseHeaders.newAuthToken });
      }

      resolve({
        response,
        status,
        ok: result.ok,
        headers: responseHeaders,
        pagination: makeResponsePagination(result.headers),
      });
    } catch (x) {
      reject(x);
    }
  }), () => {
    controller.abort();
  });
};

const get = (url) => doRequest(url);

const post = (url, data, options = {}) => {
  const requestOptions = makeOptions(options);
  requestOptions.method = 'POST';
  requestOptions.body = JSON.stringify(data);
  return doRequest(url, requestOptions);
};

const put = (url, data) => {
  const requestOptions = makeOptions();
  requestOptions.method = 'PUT';
  requestOptions.body = JSON.stringify(data);
  return doRequest(url, requestOptions);
};

const patch = (url, data) => {
  const requestOptions = makeOptions();
  requestOptions.method = 'PATCH';
  requestOptions.body = JSON.stringify(data);
  return doRequest(url, requestOptions);
};

const deleteResource = async (url) => {
  const requestOptions = makeOptions();
  requestOptions.method = 'DELETE';
  const response = await doRequest(url, requestOptions);
  if (!response.ok) {
    return Promise.reject(response);
  }
  return Promise.resolve(response);
};

const upload = (url, data) => {
  const requestOptions = {
    method: 'POST',
    body: data,
  };
  return doRequest(url, requestOptions, { json: false });
};

const fake = (status = 200, response = {}, timeout = 2000) => new Promise((resolve) => {
  setTimeout(() => resolve({ status, ok: true, response }), timeout);
});

const onStatus = (status, cb) => {
  statusActions[status] = cb;
};

const parseQueryString = (string, grab = null, def = null) => {
  const query = new URLSearchParams(string);
  if (!grab) {
    return query;
  }
  if (query.has(grab)) {
    return query.get(grab);
  }
  if (def) {
    return def;
  }
  return null;
};

const catchFetchError = async (promise) => {
  const response = await promise;
  if (response.ok === false) {
    const messages = [];
    if (response.message) { // handle madmen error message
      messages.push(response.message);
    } else if (response.response?.messages) { // handle lumbergh error messages
      messages.push(...response.response?.messages);
    } else {
      messages.push('error');
    }
    return Promise.reject(messages);
  }
  return Promise.resolve(response);
};

export default fetch;

export {
  addAuthHeader,
  addHeader,
  deleteResource,
  get,
  fake,
  onStatus,
  parseQueryString,
  patch,
  post,
  put,
  upload,
  doRequest,
  catchFetchError,
};
