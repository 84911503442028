import React from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { FILTER_KEY_SEARCH } from 'app/constants/filters';
import { isObject, isEmptyObject } from '../../../../lib/lib';
import { selections } from '../../../../lib/date';
import Input from '../../Input/Input';
import CombinedFilters from '../CombinedFilters/CombinedFilters';
import './CombinedFiltersWithSearch.scss';

const { Search } = Input;

const prefixCls = 'v2_component_filters_combined';

function initFilters(conditions) {
  const keys = Object.keys(conditions);
  const filtersResult = {};
  const defaultCheckedFilters = {};
  let keyword = '';
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    const value = conditions[key];
    if (key !== FILTER_KEY_SEARCH) {
      filtersResult[key] = conditions[key];
      defaultCheckedFilters[key] = conditions[key];
    } else {
      keyword = value;
    }
  }
  return { keyword, filtersResult, defaultCheckedFilters };
}

class CombinedFiltersWithSearch extends React.PureComponent {
  constructor(props) {
    super(props);
    const { defaultCheckedConditions } = props;
    const { keyword, filtersResult, defaultCheckedFilters } = initFilters(defaultCheckedConditions);
    this.filtersResult = filtersResult;
    this.defaultCheckedFilters = defaultCheckedFilters;
    this.state = {
      keyword,
    };
  }

  apply = () => {
    const { onChange, searchValue } = this.props;
    const { keyword } = this.state;
    const cacheData = {};
    if (keyword) {
      cacheData[FILTER_KEY_SEARCH] = keyword;
    }
    Object.keys(this.filtersResult).forEach((key) => {
      const data = this.filtersResult[key];
      if ((Array.isArray(data) && data.length) || (isObject(data) && !isEmptyObject(data))) {
        Object.assign(cacheData, { [key]: data });
      }
      cacheData[key] = data;
    });
    if (searchValue) cacheData.search = searchValue;
    onChange(cacheData);
  }

  applyForSearch = debounce(this.apply, 300);

  onSearchChange = (e) => {
    this.setState({ keyword: e.target.value }, () => {
      this.applyForSearch(false);
    });
  }

  onFiltersChange = (filtersResult) => {
    this.filtersResult = filtersResult;
    this.apply();
  }

  render() {
    const { placeholder, filters, searchValue } = this.props;
    const { keyword } = this.state;
    return (
      <div className={`${prefixCls}`}>
        <Search
          className={`${prefixCls}-search`}
          placeholder={placeholder}
          defaultValue={keyword}
          onChange={this.onSearchChange}
          value={searchValue?.trim() || keyword}
          disabled={!!searchValue}
        />
        <CombinedFilters
          filters={searchValue ? [] : filters}
          onChange={this.onFiltersChange}
          defaultCheckedFilters={searchValue ? [] : this.defaultCheckedFilters}
        />
      </div>
    );
  }
}

CombinedFiltersWithSearch.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
  defaultCheckedConditions: PropTypes.objectOf(PropTypes.any),
  placeholder: PropTypes.string,
};

CombinedFiltersWithSearch.defaultProps = {
  defaultCheckedConditions: {
    dateRange: {
      quickPickValue: selections.LAST_7_DAYS.value,
    },
  },
  placeholder: '',
};

export default CombinedFiltersWithSearch;
