import React from 'react';
import Checkbox from './Checkbox';
import './checkbox.scss';

class CheckboxContainer extends React.Component {
  constructor(props) {
    super(props);
    this.id = `generated_checkbox_id_${this.props.label}_${Math.random()}`;
  }

  render() {
    return (
      <Checkbox {...this.props} id={this.id} />
    );
  }
}

export default CheckboxContainer;
