function generateServiceUrls(env, prNumber) {
  if (['development', 'test'].includes(env)) {
    return {
      authUrl: 'http://localhost:5102/',
      ssoUrl: 'http://localhost:3000/',
      champaUrl: 'http://localhost:5000/',
      manageUrl: 'http://localhost:4000/',
      uploadUrl: 'http://localhost:8889/',
      supplyUrl: 'http://localhost:3040/',
      madmenUrl: 'http://localhost:8888/',
      slydellUrl: 'https://pub-gateway-qa-api.vungle.io/',
    };
  }

  if (env === 'verraform') {
    return {
      authUrl: 'https://auth-qa-api.vungle.io/',
      ssoUrl: 'https://app-qa.vungle.io/',
      champaUrl: `https://milton-admin-demand-PR-${prNumber}-milton-admin.feature.vungle.io/`,
      manageUrl: `https://lumbergh-manage-demand-PR-${prNumber}-lumbergh-manage.feature.vungle.io/`,
      uploadUrl: `https://lumbergh-upload-demand-PR-${prNumber}-lumbergh-upload.feature.vungle.io/`,
      supplyUrl: 'https://pub-qa.vungle.com/',
      madmenUrl: `https://madmen-demand-PR-${prNumber}-madmen.feature.vungle.io/`,
      slydellUrl: 'https://pub-gateway-stage-api.vungle.io/',
    };
  }

  if (['qa', 'qa1', 'qa2', 'qa3', 'qa4', 'qa5', 'qa6', 'qa7'].includes(env)) {
    // This wll be uncommented when all qa auth apps use sanitized Db.
    // const isQa6 = env === 'qa6';
    const isQa6 = true;
    return {
      authUrl: isQa6 ? 'https://auth-qa6-api.vungle.io/' : 'https://auth-qa-api.vungle.io/',
      ssoUrl: isQa6 ? 'https://app-qa6.vungle.io/' : 'https://app-qa.vungle.io/',
      champaUrl: 'https://creative-views-qa.vungle.io/',
      manageUrl: `https://adv-manage-${env}-api.vungle.io/`,
      uploadUrl: `https://adv-upload-${env}-api.vungle.io/`,
      supplyUrl: `https://pub-${env}.vungle.com/`,
      madmenUrl: `https://madmen-${env}-api-qa.vungle.io/`,
      slydellUrl: 'https://pub-gateway-stage-api.vungle.io/',
    };
  }
  if (env.includes('feat')) {
    return {
      authUrl: 'https://auth-qa6-api.vungle.io/',
      ssoUrl: 'https://app-qa6.vungle.io/',
      champaUrl: `https://creative-views-${env}.vungle.io/`,
      manageUrl: `https://adv-manage-api-${env}.vungle.io/`,
      uploadUrl: `https://adv-upload-${env}.vungle.io/`,
      supplyUrl: `https://pub-${env}.vungle.com/`,
      madmenUrl: `https://advertiser-api-${env}.vungle.io/`,
      slydellUrl: `https://metadata-api-${env}.vungle.io/`,
    };
  }
  if (['production', 'beta'].includes(env)) {
    const isBeta = env === 'beta';
    return {
      authUrl: isBeta ? 'https://auth-beta-api.vungle.io/' : 'https://auth-api.vungle.com/',
      ssoUrl: isBeta ? 'https://app-beta.vungle.io/' : 'https://app.vungle.com/',
      champaUrl: 'https://creative-views.vungle.com/',
      manageUrl: 'https://manage.api.vungle.com/',
      uploadUrl: isBeta ? 'https://upload-beta.api.vungle.com/' : 'https://upload-madmen.api.vungle.com/',
      supplyUrl: 'https://publisher.vungle.com/',
      madmenUrl: isBeta ? 'https://advertiser-beta-api.vungle.io/' : 'https://advertiser-api.vungle.com/',
      slydellUrl: 'https://pub-gateway-api.vungle.com/',
    };
  }

  return {};
}

export {
  generateServiceUrls,
};

export default {
  generateServiceUrls,
};
