import React from 'react';

const Alert = ({
  alertText, title, hideModal, props: { closeFunc },
}) => (
  <div
    className="component__modals__alert"
    onClick={() => {
      hideModal();
      if (closeFunc) {
        closeFunc();
      }
    }}
  >
    <div className="alert_content_container" onClick={(e) => e.stopPropagation()}>
      <div className="error_icon">
        <i className="material-icons">error</i>
      </div>
      <div className="alert_content">
        <div className="header">
          <p>{title}</p>
        </div>
        <div className="body">
          {alertText}
        </div>
      </div>
      <div
        className="clear_field"
        onClick={() => {
          hideModal();
          if (closeFunc) {
            closeFunc();
          }
        }}
      >
        <i className="material-icons">clear</i>
      </div>
    </div>
  </div>
);

export default Alert;
