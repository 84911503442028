import { isEmpty } from 'lodash';
import {
  each, flattenObject, isArray, isEmptyArray, isObject, isUndefined,
} from '../lib/lib';
import { get } from '../lib/http';
import { createDateRange, convert } from '../lib/date';

class BaseModel {
  constructor(attrs = {}) {
    this.attrs = flattenObject(attrs);
    this.transforms = {};
    this.mapKeys = [];
    this.onChangeFn = () => {};
  }

  default(attr, value, nullValues = []) {
    if (isArray(value) && value.length > 0) {
      if (isUndefined(this.attrs[attr]) || isEmptyArray(this.attrs[attr])) {
        this.attrs[attr] = value;
      }
    } else if (isUndefined(this.attrs[attr]) || (nullValues.length > 0 && nullValues.indexOf(this.attrs[attr]) !== -1)) {
      this.attrs[attr] = value;
    }
  }

  set(attr, value, callOnChangeFn = true) {
    if (isArray(attr) && isArray(value)) {
      attr.forEach((a, i) => {
        this.attrs[a] = value[i];
      });
    } else {
      this.attrs[attr] = value;
    }
    if (callOnChangeFn) {
      this.onChangeFn(this);
    }
  }

  onChange(fn = () => {}) {
    this.onChangeFn = fn;
  }

  raw(attr) {
    return this.get(attr, true);
  }

  get(attr, raw = false) {
    const value = this.attrs[attr];
    if (!raw && value !== null && this.mapKeys.indexOf(attr) !== -1) {
      return this.transforms[attr](value);
    }
    return value;
  }

  getNumber(attr) {
    const value = this.get(attr, true);
    return value && !Number.isNaN(value) ? value : 0;
  }

  map(...args) {
    if (args.length < 2) {
      throw new Error('Model.map requires at least 2 arguments, a string and a callback');
    }
    const cloneArgs = [...args];
    const cb = cloneArgs.pop();
    if (typeof cb !== 'function') {
      throw new Error('last argument in Model.map should be a function');
    }
    cloneArgs.forEach((arg) => {
      this.transforms[arg] = cb;
    });
    this.mapKeys = Object.keys(this.transforms);
  }

  toObject() {
    return this.attrs;
  }

  getRelation(string, Class) {
    const fn = () => {
      const model = this.raw(string);
      if (model instanceof Class) {
        return model;
      }
      const obj = this.makeObject(string);
      if (!obj) {
        return null;
      }
      this.set(string, new Class(obj), false);
      return this.raw(string);
    };
    fn();
    return fn;
  }

  getArrayRelation(string, Class) {
    const fn = () => {
      const models = this.raw(string);
      if (!models || (isArray(models) && models.length === 0)) {
        return [];
      }
      if (isArray(models) && models.length > 0 && models[0] instanceof Class) {
        return models;
      }
      this.set(string, models.map((m) => new Class(m)), false);
      return this.raw(string);
    };
    fn();
    return fn;
  }

  makeObject(name) {
    let attrs = null;
    each(this.toObject(), (value, key) => {
      const [n, ...prop] = key.split('.');
      if (n === name) {
        if (!attrs) {
          attrs = {};
        }
        attrs[prop.join('.')] = value;
      }
    });
    return attrs;
  }

  pluck(obj, base, fields) {
    const returnObj = { ...obj };
    fields.forEach((field) => {
      returnObj[field] = this.raw(`${base}.${field}`);
    });
    return returnObj;
  }

  getDate = (date, toFormat = 'Do MMM \'YY') => convert(this.raw(date), toFormat);
}

BaseModel.make = (Resource) => (data = [], ...rest) => {
  if (!Array.isArray(data)) {
    return [];
  }
  return data.map((d) => (
    new Resource(d, ...rest)
  ));
};

BaseModel.makeGetAll = (baseUrl, platformString = 'application.platform') => ({
  page = 1,
  perPage = 10,
  account = null,
  application = null,
  adminApplication = null,
  budgetType = null,
  adType = null,
  networkType = null,
  accountType = null,
  search = null,
  start = null,
  end = null,
  days = null,
  platform = null,
  devicePlatform = null,
  status = null,
  qaStatus = null,
  campaignStatus = null,
  memberStatus = null,
  isArchived = null,
  templateStatus = null,
  format = null,
  templateProtocol = null,
  creativeFormat = null,
  type = null,
  typeIn = null,
  idIn = null,
  marketId = null,
  videoId = null,
  supportedTemplateType = null,
  expandDetails = null,
  previewUrl = null,
  fields = null,
  assetUrl = null,
  sort = null,
  // eslint-disable-next-line camelcase
  is_skadnetwork_enabled = null,
  resourceType = null,
} = {}) => {
  let url = `${baseUrl}?page=${page}&per_page=${perPage}`;
  const dateRange = createDateRange(start, end, days);
  if (account) {
    url = `${url}&filter[account.id:in]=${isArray(account) ? account.join(',') : account}`;
  }
  if (marketId && isArray(marketId)) {
    url = `${url}&filter[market.id:in]=${marketId.join(',')}`;
  }
  if (search) {
    url = `${url}&filter[*:search]=${encodeURIComponent(search)}`;
  }
  if (dateRange.start) {
    url = `${url}&filter[start:gte]=${dateRange.start}`;
  }
  if (dateRange.end) {
    url = `${url}&filter[end:lte]=${dateRange.end}`;
  }
  if (platform) {
    url = `${url}&filter[${platformString}:in]=${platform}`;
  }
  if (devicePlatform) {
    url = `${url}&filter[platform:eq]=${devicePlatform}`;
  }
  if (type) {
    url = `${url}&filter[type:eq]=${type}`;
  }
  if (typeIn) {
    url = `${url}&filter[type:in]=${isArray(typeIn) ? typeIn.join(',') : typeIn}`;
  }
  if (videoId) {
    url = `${url}&filter[video.id:eq]=${videoId}`;
  }
  if (application || adminApplication) {
    url = `${url}&filter[application.id:in]=${application || adminApplication}`;
  }
  if (budgetType) {
    url = `${url}&filter[budget_type:in]=${budgetType}`;
  }
  if (adType) {
    url = `${url}&filter[ad_type:eq]=${adType}`;
  }
  if (networkType) {
    url = `${url}&filter[ad_type:in]=${networkType}`;
  }
  if (accountType) {
    if (accountType === 'publisher') {
      url = `${url}&filter[account_type:ne]=advertiser`;
    } else if (accountType === 'advertiser') {
      url = `${url}&filter[account_type:ne]=publisher`;
    }
  }
  if (supportedTemplateType) {
    url = `${url}&filter[supported_template_types:in]=${supportedTemplateType}`;
  }
  if (status) {
    const adminStatuses = [];
    const statuses = [];
    const statusList = status.split(',');
    statusList.forEach((s) => {
      // 'Approved', 'Pending', and 'Rejected' controls overall serve permission
      if (s === 'approved' || s === 'pending' || s === 'rejected') {
        adminStatuses.push(s);
        // 'Active' or 'Paused' overrides other serving params like start date, end date, etc.
      } else {
        statuses.push(s);
      }
    });
    if (adminStatuses.length > 0) {
      url = `${url}&filter[status.admin:in]=${adminStatuses.join(',')}`;
    }
    if (statuses.length > 0) {
      url = `${url}&filter[status:in]=${statuses.join(',')}`;
    }
  }
  if (campaignStatus) {
    url = `${url}&filter[status:in]=${campaignStatus}`;
  }
  if (qaStatus === 'notApproved') {
    url = `${url}&filter[status:ne]=approved`;
  } else if (qaStatus) {
    url = `${url}&filter[status:in]=${qaStatus}`;
  }
  if (!isEmpty(memberStatus)) {
    url = `${url}&filter[status:in]=${memberStatus}`;
  }
  if (isArchived !== null) {
    url = `${url}&filter[is_deleted:in]=${isArchived}`;
  }
  if (templateStatus !== null) {
    url = `${url}&filter[templateStatus:in]=${isArray(templateStatus) ? templateStatus.join(',') : templateStatus}`;
  }
  if (format !== null) {
    url = `${url}&filter[format:in]=${format}`;
  }
  if (templateProtocol !== null) {
    url = `${url}&filter[templateProtocol:in]=${templateProtocol}`;
  }
  if (creativeFormat !== null) {
    url = `${url}&filter[creativeFormat:in]=${creativeFormat}`;
  }
  if (idIn) {
    url = `${url}&filter[id:in]=${isArray(idIn) ? idIn.join(',') : idIn}`;
  }
  if (assetUrl) {
    url = `${url}&filter[url:eq]=${assetUrl}`;
  }
  // eslint-disable-next-line camelcase
  if (is_skadnetwork_enabled !== null) {
    // eslint-disable-next-line camelcase
    url = `${url}&filter[is_skadnetwork_enabled:eq]=${is_skadnetwork_enabled}`;
  }
  if (resourceType !== null) {
    url = `${url}&filter[resource_type:eq]=${resourceType}`;
  }
  if (sort) {
    let sortString = sort;
    if (isObject(sort)) {
      sortString = Object.entries(sort).map(([key, value]) => `${key}:${value}`).join(',');
    }
    if (isArray(sort)) {
      sortString = sort.join(',');
    }
    url = `${url}&sort=${sortString}`;
  }

  // 'expandDetails' is boolean
  // checking with `if (expandDetails)` will result not sending that parameter when it is false
  if (expandDetails !== null && expandDetails !== undefined) {
    url = `${url}&expand_details=${expandDetails}`;
  }

  if (previewUrl) {
    url = `${url}&filter[preview_url:exists]=true`;
  }

  if (fields !== null && fields !== undefined && Array.isArray(fields)) {
    const fieldString = fields.map((i) => `fields[]=${i}`).join('&');
    url = `${url}&${fieldString}`;
  }
  return get(url);
};

export default BaseModel;
