import React from 'react';
import MultiTreeSelected from 'templates/MultiTreeSelector/MultiTreeSelected';

const prefixCls = 'selected';

const CitySelected = ({
  selectedItems,
  onSelectedChange,
  onPaste,
  resource,
}) => (
  <MultiTreeSelected
    className="city-selected-wrapper"
    prefixCls={prefixCls}
    selectedItems={selectedItems}
    onSelectedChange={onSelectedChange}
    onPaste={onPaste}
    copyMapper={(city) => city.code}
    deleteMapper={(change) => (selected) => resource.makeCityValue(selected) !== resource.makeCityValue(change)}
    filterKeyGetter={(city) => resource.makeCityValue(city)}
  />
);

export default CitySelected;
