/* eslint global-require: "off" */

if (typeof Promise === 'undefined') {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  require('promise/lib/rejection-tracking').enable();
  // eslint-disable-next-line import/extensions
  window.Promise = require('promise/lib/es6-extensions.js');
}

// fetch() polyfill for making API calls.
require('whatwg-fetch');

// Object.assign() is commonly used with React.
// It will use the native implementation if it's present and isn't buggy.
Object.assign = require('object-assign');

require('url-search-params-polyfill');

// required to work around strict mode bug: https://github.com/adamhalasz/uniqid/issues/20
window.process = {
  env: { DEBUG: undefined },
};
