import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CollapseSummary from './CollapseSummary';
import CollapseDetail from './CollapseDetail';
import './showmoreCollapse.scss';

const classPrefix = 'v2_component_show_more_section';

const ShowMoreCollapse = ({
  title,
  titleExpanded = title,
  description,
  expanded: expandedProp = true,
  children,
  className,
  hideCollapseSummary,
  id,
}) => {
  const [expanded, setExpanded] = React.useState(expandedProp);
  return (
    <div {...(id && { id })} className={classnames(`${classPrefix}`, className)}>
      {
          !hideCollapseSummary && (
            <CollapseSummary
              expanded={expanded}
              className={classnames('summary', `${classPrefix}-header`, { [`${classPrefix}-header-expand`]: !expanded })}
              onClick={(open) => setExpanded(open)}
              hiddenSeparator
              prefixIcon={false}
              expandIcon={false}
            >
              <div className={`${classPrefix}-header-info`}>
                <div className={`${classPrefix}-header-title`}>
                  {expanded ? titleExpanded : title}
                </div>
              </div>
              {expanded && (
              <div className={`${classPrefix}-header-description`}>{description}</div>
              )}
            </CollapseSummary>
          )
        }
      <CollapseDetail
        className={classnames('detail', `${classPrefix}-detail`)}
        expanded={expanded}
      >
        {children}
      </CollapseDetail>
    </div>
  );
};

ShowMoreCollapse.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  expanded: PropTypes.bool,
  className: PropTypes.string,
  adminOnly: PropTypes.bool,
  // Summary tip in right
  tip: PropTypes.node,
  // If hide tip when collapsed
  hideTipWhenCollapsed: PropTypes.bool,
  // Id of section
  id: PropTypes.string,
};

ShowMoreCollapse.defaultProps = {
  description: '',
  expanded: true,
  className: '',
  adminOnly: false,
  tip: null,
  hideTipWhenCollapsed: true,
  id: '',
};

export default ShowMoreCollapse;
