import React from 'react';
import imgPoweredByStripe from '../../assets/img/powered_by_stripe.svg';
import { isInteger } from '../../lib/lib';
import Alert from '../Alert/Alert';
import Link from '../Link/Link';
import CountrySelect from '../Form/CountrySelectBox/CountrySelectBox';
import Radio from '../Form/Radio/Radio';
import Text from '../Form/Text/TextContainer';
import Group from '../../templates/input-group/InputGroupContainer';
import './payments.scss';

const { Field, Buttons } = Group;

const isValidForm = (paymentType, cardComplete, amount, country, bankDetails) => {
  if (!country) {
    return false;
  }
  if (paymentType === 'credit' && !cardComplete) {
    return false;
  }
  if (paymentType === 'ach' && (!bankDetails.routingNumber || !bankDetails.accountNumber || !bankDetails.accountHolderName)) {
    return false;
  }
  return isInteger(amount) && amount >= 5000 && amount <= 100000;
};

const Payments = ({
  amount,
  bankDetails,
  cardComplete,
  changeAmount,
  changeBankDetail,
  changeCountry,
  changePaymentType,
  paymentError,
  processPayment,
  paymentType,
  selectedCountry,
}) => (
  <div className="components__payments">
    <Group title="Add Funds" flair={<Link external to="https://stripe.com"><img src={imgPoweredByStripe} /></Link>}>
      <Field name="Payment Type" description="The payment type you wish to add funds from">
        <Radio name="payment_type" value={paymentType} onChange={changePaymentType}>
          <Radio.Option label="Card" value="credit" />
          <Radio.Option label="Alipay" value="alipay" />
          {/*<Radio.Option label="ACH (US Only)" value="ach" />*/}
        </Radio>
      </Field>
      <Field name="Amount" description="The amount of funds you wish to add to your account. Minimum Payment Amount: $5000 USD">
        <Text noIcon staticPlaceholder="money" value={amount} onChange={changeAmount} numeric />
      </Field>
      <div className={`card_details ${paymentType !== 'credit' && 'card_details--hide'}`}>
        <Field name="Card Details" description="Details for the credit card you wish to charge">
          <div className="payment-container">
            <div id="stripe-mountable-payment-capture" />
          </div>
        </Field>
      </div>
      <div className={`bank_details ${paymentType !== 'ach' && 'bank_details--hide'}`}>
        <Field name="Bank Details" description="Your bank details you would like to transfer from">
          <Text noIcon onChange={(v) => changeBankDetail('routingNumber', v)} value={bankDetails.routingNumber} placeholder="Routing Number" />
          <Text noIcon onChange={(v) => changeBankDetail('accountNumber', v)} value={bankDetails.accountNumber} placeholder="Account Number" />
          <Text
            noIcon
            onChange={(v) => changeBankDetail('accountHolderName', v)}
            value={bankDetails.accountHolderName}
            placeholder="Account Holder Name"
          />
          <Radio name="bank_detail_type" value={bankDetails.accountHolderType} onChange={(v) => changeBankDetail('accountHolderType', v)}>
            <Radio.Option label="Company" value="company" />
            <Radio.Option label="Individual" value="individual" />
          </Radio>
        </Field>
      </div>
      <Field name="Billing Country" description="">
        <CountrySelect value={selectedCountry} onChange={changeCountry} />
      </Field>
      {paymentError && (
        <div className="payment_error">
          <Alert>
            Your funds request could not be processed. Please double check your payment information or contact support at
            <a href="mailto:dashboard.support@vungle.com">dashboard.support@vungle.com</a>
            .
          </Alert>
        </div>
      )}
      <Buttons
        cancelUrl="/account"
        confirm={() => processPayment({ amount, country: selectedCountry })}
        confirmText="Process Payment"
        confirmDisabled={!isValidForm(paymentType, cardComplete, amount, selectedCountry, bankDetails)}
      />
    </Group>
  </div>
);

export default Payments;
