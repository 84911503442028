import React from 'react';
import { FILTER_KEY_TEMPLATE_FORMAT } from 'app/constants/filters';
import CommonMultiPicker from '../CommonMultiPicker/CommonMultiPicker';
import { templateFormat } from '../constant';

const MultiTemplateFormat = (props) => (
  <CommonMultiPicker data={templateFormat} filterKey={FILTER_KEY_TEMPLATE_FORMAT} {...props} />
);

export default MultiTemplateFormat;
