export const KEY_ADJUST_HOST = 'adjust.com';
export const KEY_APPS_FLYER_HOST = 'appsflyer.com';
export const KEY_KOCHAVA_HOST = 'kochava.com';
export const KEY_SINGULAR_HOST = 'singular.net';
export const KEY_TENJIN_HOST = 'tenjin.io';
export const KEY_TINK_HOST = 'tlnk.io';

export const DETERMINANT_ADJUST = KEY_ADJUST_HOST;
export const DETERMINANT_APPS_FLYER = KEY_APPS_FLYER_HOST;
export const DETERMINANT_KOCHAVA = 'control.kochava.com';
export const DETERMINANT_SINGULAR = KEY_SINGULAR_HOST;
export const DETERMINANT_TENJIN = 'track.tenjin.io';
export const DETERMINANT_TINK = 'tlnk.io/serve';

// eslint-disable-next-line camelcase
export const KEY_iOS = 'ios';
export const KEY_ANDROID = 'android';
export const KEY_WINDOWS = 'windows';
export const KEY_AMAZON = 'amazon';

export const MEASUREMENT_URL_TYPES = {
  destination: 'DESTINATION_URL',
  click: 'CLICK_URL',
  impression: 'IMPRESSION_URL',
};

export const validator = (url, match) => url.host.endsWith(match);

export const tinkValidator = (url) => validator(url, KEY_TINK_HOST) && url.pathname.startsWith('/serve');

export const DETERMINANT_MACROS_FACTORY = {
  [KEY_ADJUST_HOST]: {
    validator: (url) => validator(url, DETERMINANT_ADJUST),
    [KEY_iOS]: {
      idfa: 'ifa',
      vungle_click_id: 'id',
    },
    [KEY_ANDROID]: {
      gps_adid: 'aaid',
      vungle_click_id: 'id',
    },
    [KEY_WINDOWS]: {
      win_adid: 'ifa',
      vungle_click_id: 'id',
    },
    [KEY_AMAZON]: {
      fire_adid: 'aaid',
      vungle_click_id: 'id',
    },
  },
  [KEY_APPS_FLYER_HOST]: {
    validator: (url) => validator(url, DETERMINANT_APPS_FLYER),
    [KEY_iOS]: {
      idfa: 'ifa',
      clickid: 'id',
    },
    [KEY_ANDROID]: {
      advertising_id: 'aaid',
      clickid: 'id',
    },
    [KEY_WINDOWS]: {
      advertising_id: 'ifa',
      clickid: 'id',
    },
    [KEY_AMAZON]: {
      advertising_id: 'aaid',
      clickid: 'id',
    },
  },
  [KEY_KOCHAVA_HOST]: {
    validator: (url) => validator(url, DETERMINANT_KOCHAVA),
    [KEY_iOS]: {
      device_id: 'ifa',
    },
    [KEY_ANDROID]: {
      device_id: 'aaid',
    },
    [KEY_WINDOWS]: {
      device_id: 'ifa',
    },
    [KEY_AMAZON]: {
      device_id: 'aaid',
    },
  },
  [KEY_SINGULAR_HOST]: {
    validator: (url) => validator(url, DETERMINANT_SINGULAR),
    [KEY_iOS]: {
      idfa: 'ifa',
      cl: 'id',
    },
    [KEY_ANDROID]: {
      aifa: 'aaid',
      cl: 'id',
    },
    [KEY_WINDOWS]: {
      cl: 'id',
    },
    [KEY_AMAZON]: {
      cl: 'id',
    },
  },
  [KEY_TENJIN_HOST]: {
    validator: (url) => validator(url, DETERMINANT_TENJIN),
    [KEY_iOS]: {
      advertising_id: 'ifa',
      click_id: 'id',
    },
    [KEY_ANDROID]: {
      advertising_id: 'aaid',
      click_id: 'id',
    },
    [KEY_WINDOWS]: {
      click_id: 'id',
    },
    [KEY_AMAZON]: {
      click_id: 'id',
    },
  },
  [KEY_TINK_HOST]: {
    validator: tinkValidator,
    [KEY_iOS]: {
      ios_ifa: 'ifa',
      tracking_id: 'id',
    },
    [KEY_ANDROID]: {
      google_aid: 'aaid',
      tracking_id: 'id',
    },
    [KEY_WINDOWS]: {
      windows_aid: 'ifa',
      tracking_id: 'id',
    },
    [KEY_AMAZON]: {
      tracking_id: 'id',
    },
  },
};

export const INVALID_URL_ERROR = {
  invalidURL: ['URL format is invalid'],
};

export const MISSING_APPLICATION_ERROR = {
  missingApplication: ['Please select an application first'],
};
