import React from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import FrontendPanel from './FrontendPanel';
import { getCheckedRegionNodes } from './helper';

const CreativeFormatPanel = ({
  visible,
  maxSelected,
  availableNodes,
  defaultCheckedNodes,
  onVisibleChange,
  onChange,
  pagination,
}) => (
  <FrontendPanel
    tree
    nodes={availableNodes}
    visible={visible}
    maxSelected={maxSelected}
    disabledTitle="Format Mismatch"
    disabledContent="This format does not match current creative type."
    placeholder="Search Creative Types..."
    defaultCheckedNodes={defaultCheckedNodes}
    onVisibleChange={onVisibleChange}
    pagination={pagination}
    onChange={(checkedNodes) => onChange(getCheckedRegionNodes(availableNodes, checkedNodes))}
  />
);

CreativeFormatPanel.propTypes = {
  visible: PropTypes.bool,
  maxSelected: PropTypes.number,
  availableNodes: PropTypes.arrayOf(PropTypes.any),
  defaultCheckedNodes: PropTypes.arrayOf(PropTypes.any),
  onVisibleChange: PropTypes.func,
  onChange: PropTypes.func,
};

CreativeFormatPanel.defaultProps = {
  visible: false,
  maxSelected: 0,
  availableNodes: [],
  defaultCheckedNodes: [],
  onVisibleChange: noop,
  onChange: noop,
};

export default React.memo(CreativeFormatPanel);
