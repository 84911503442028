import React from 'react';
import Pulsation from '../../../../components/V2/Pulsation/Pulsation';
import Label from '../../../../components/V2/Label/Label';

export const ACTIVE_STATUS = 'active';
export const INACTIVE_STATUS = 'inactive';

const CreativeStatus = ({ creative, hideLabel = false }) => {
  const status = creative.isEligibleToServe() ? ACTIVE_STATUS : INACTIVE_STATUS;
  const statusText = creative.get('status') === 'active' ? ACTIVE_STATUS : INACTIVE_STATUS;
  const labelStatus = creative.get('admin_status');
  return (
    <div className="status-wrapper">
      <Pulsation
        labelText={statusText}
        status={status}
      />
      {
        labelStatus !== 'approved' && !hideLabel && (
          <Label
            type={labelStatus}
          >
            {labelStatus}
          </Label>
        )
      }
    </div>
  );
};

export default CreativeStatus;
