import React from 'react';
import Logo from 'assets/img/liftoff-logo.svg';
import { compose, splash } from '../../../app/app';
import Checkmark from '../../../components/Checkmark/Checkmark';
import './splash.scss';

const Splash = ({ isLoading = false, text = '' }) => (
  <div className="body__splash">
    <div className="content">
      <div className="loading">
        <img src={Logo} alt="logo" />
        <span>{text}</span>
        <Checkmark complete={!isLoading} />
      </div>
    </div>
  </div>
);

class SplashContainer extends React.Component {
  UNSAFE_componentWillReceiveProps(p) {
    if (p.show && !p.isLoading) {
      setTimeout(this.props.actions.hide, 1250);
    }
  }

  render() {
    return (
      <Splash {...this.props} />
    );
  }
}

const hide = () => splash({ start: false, finish: false });

const props = (state) => state.splash;

export default compose(SplashContainer, { props, actions: { hide } });
