import React from 'react';
import { FILTER_KEY_CREATIVE_FORMAT } from 'app/constants/filters';
import CommonMultiPicker from '../CommonMultiPicker/CommonMultiPicker';
import { creativeFormat } from '../constant';

const CreativeFormatPicker = (props) => (
  <CommonMultiPicker data={creativeFormat} filterKey={FILTER_KEY_CREATIVE_FORMAT} {...props} />
);

export default CreativeFormatPicker;
