import { getStore } from '../../../app/storeprovider';

const isAppLoaded = () => {
  const state = getStore().getState();
  let isLoaded;
  try {
    isLoaded = state.setup.loadedApp || false;
  } catch (ex) {
    isLoaded = false;
  }
  return isLoaded;
};

export default isAppLoaded;
