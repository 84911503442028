import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import RoundPlatformIcon from '../../../RoundPlatformIcon/RoundPlatformIcon';
import { platforms } from '../../constant';
import MultiPanel from './MultiPanel';
import '../../MultiPlatform/platform.scss';

const renderItemLabel = (prefixCls, { icon, text }) => (
  <div className={`${prefixCls}-item`}>
    <RoundPlatformIcon className={`${prefixCls}-item-icon`} platform={icon} />
    <span>{text}</span>
  </div>
);

const PlatformPanel = ({
  prefixCls,
  availableNodes,
  defaultCheckedNodes,
  onVisibleChange,
  onChange,
}) => {
  const handleChange = useCallback((_selectedPlatforms, isAllSelected) => {
    onVisibleChange(false);
    onChange(_selectedPlatforms, isAllSelected);
  }, [onVisibleChange, onChange]);

  return (
    <MultiPanel
      data={availableNodes.length ? availableNodes : platforms}
      renderItemLabel={(item) => renderItemLabel(prefixCls, item)}
      defaultCheckedKeys={(defaultCheckedNodes || []).map(({ id }) => id)}
      onVisibleChange={onVisibleChange}
      onChange={handleChange}
    />
  );
};

PlatformPanel.propTypes = {
  prefixCls: PropTypes.string,
  availableNodes: PropTypes.arrayOf(PropTypes.any),
  defaultCheckedNodes: PropTypes.arrayOf(PropTypes.any),
  onVisibleChange: PropTypes.func,
  onChange: PropTypes.func,
};

PlatformPanel.defaultProps = {
  prefixCls: 'v2_component_filter_platform',
  availableNodes: [],
  defaultCheckedNodes: [],
  onVisibleChange: noop,
  onChange: noop,
};

export default React.memo(PlatformPanel);
