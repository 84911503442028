import React from 'react';
import classNames from 'classnames';
import Button from 'components/V2/Button/Button';
import './infoBar.scss';

const prefix = 'ab_test_reporting_info_bar_container';

const InfoBar = ({ headsUp, buttonText, onClick }) => (
  <div className={classNames(prefix)}>
    <div className="icon_wrapper">
      <i className="material-icons">info_outline</i>
    </div>
    <div className="heads_up">
      <p>{headsUp}</p>
      {buttonText && (
        <Button type="primary" onClick={onClick}>
          {buttonText}
        </Button>
      )}
    </div>
  </div>
);

export default InfoBar;
