import React, {
  useState, useCallback,
} from 'react';
import moment from 'moment';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import DatePickerFilter from '../../../../../../../components/V2/Report/Filters/DatePickerFilter/DatePickerFilter';
import { QUICK_PICKERS, QUICK_TAB_KEY_CUSTOM } from './constants';
import { DATE_RANGE_QUICK_MAP } from './helper';
import './dateRangePicker.scss';

const classPrefix = 'v2_data_range_picker';

const DateRangePicker = ({ checkedDate, onChange }) => {
  const [visible, setVisible] = useState(false);

  const onTabClick = useCallback((item) => {
    const { key, quickKey } = item;
    const {
      startDate, endDate,
    } = DATE_RANGE_QUICK_MAP[quickKey].generateDate(moment());
    onChange({
      key, quickKey, startDate, endDate,
    });
  }, [onChange]);

  const handleChange = useCallback((date) => {
    onChange({
      ...date,
      key: QUICK_TAB_KEY_CUSTOM,
    });
  }, [onChange]);

  return (
    <div className={classPrefix}>
      <p className="p2">Date Range</p>
      {QUICK_PICKERS.map((item) => (
        <div
          key={item.key}
          onClick={() => onTabClick(item)}
          className={classnames('tab', {
            'tab-checked': item.key === checkedDate.key,
          })}
        >
          {item.title}
        </div>
      ))}
      <DatePickerFilter
        visible={visible}
        date={(checkedDate.key === QUICK_TAB_KEY_CUSTOM) ? checkedDate : {}}
        onVisibleChange={setVisible}
        onChange={handleChange}
      />
    </div>
  );
};

DateRangePicker.propTypes = {
  checkedDate: PropTypes.shape({
    key: PropTypes.string,
    quickKey: PropTypes.string,
    // Object of moment type and optional.
    startDate: PropTypes.objectOf(PropTypes.any),
    // Object of moment type and optional.
    endDate: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  onChange: PropTypes.func,
};

DateRangePicker.defaultProps = {
  onChange: noop,
};

export default DateRangePicker;
