import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import {
  FILTER_KEY_STATUS,
  FILTER_KEY_CAMPAIGN_STATUS,
  FILTER_KEY_APPLICATION_STATUS,
  FILTER_KEY_MEMBER_STATUS,
} from 'app/constants/filters';
import Multi from '../Base/Multi/Multi';
import {
  statuses,
  campaignStatuses,
  applicationStatus,
  memberStatus,
} from '../constant';
import './status.scss';

import StatusPanel from '../Base/Panels/StatusPanel';

const getStatusesForType = (type) => {
  if (type === FILTER_KEY_CAMPAIGN_STATUS) {
    return campaignStatuses;
  }
  if (type === FILTER_KEY_APPLICATION_STATUS) {
    return applicationStatus;
  }
  if (type === FILTER_KEY_MEMBER_STATUS) {
    return memberStatus;
  }
  return statuses;
};

const Status = ({
  dropdownFixed,
  prefixCls,
  visible,
  subType,
  defaultCheckedNodes,
  onVisibleChange,
  onChange,
  onRemove,
}) => {
  const data = getStatusesForType(subType);
  return (
    <Multi
      data={data}
      dropdownFixed={dropdownFixed}
      visible={visible}
      filterKey={FILTER_KEY_STATUS}
      defaultCheckedKeys={defaultCheckedNodes}
      renderOverlay={() => (
        React.createElement(StatusPanel, {
          prefixCls,
          subType,
          data,
          defaultCheckedNodes,
          onVisibleChange,
          onChange,
        })
      )}
      onVisibleChange={onVisibleChange}
      onRemove={onRemove}
    />
  );
};

Status.propTypes = {
  dropdownFixed: PropTypes.bool,
  prefixCls: PropTypes.string,
  visible: PropTypes.bool,
  subType: PropTypes.string.isRequired,
  defaultCheckedNodes: PropTypes.arrayOf(PropTypes.any),
  onVisibleChange: PropTypes.func,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};

Status.defaultProps = {
  dropdownFixed: false,
  prefixCls: 'v2_component_filter_status',
  visible: false,
  defaultCheckedNodes: [],
  onVisibleChange: noop,
  onChange: noop,
  onRemove: noop,
};

export default Status;
