import { namespace } from '../../../app/app';

const ns = 'accountHier';
const updateState = namespace(ns);

const setAcctHier = ({ state, values }) => updateState(state, { account: values.get('id'), name: values.get('name') });
const removeAcctHier = ({ state }) => updateState(state, { account: undefined, name: undefined });

export {
  ns,
  removeAcctHier,
  setAcctHier,
  updateState,
};
