import React from 'react';
import SpinnerWithText from '../../Spinner/SpinnerWithText';
import './treeSpinner.scss';

const prefixCls = 'v2_component_tree_spinner';

const TreeSpinner = () => (
  <div className={`${prefixCls}`}>
    <SpinnerWithText text="Just a second, loading data..." />
  </div>
);

export default React.memo(TreeSpinner);
