import Cookies from 'js-cookie';
import { isObject } from './lib';

const set = (key, value) => {
  let v = isObject(value) ? { ...value } : value;
  // if key is authUser, we remove token before storing
  if (key === 'authUser') {
    // eslint-disable-next-line no-param-reassign
    delete v.token;
  }
  if (typeof v !== 'string') {
    v = JSON.stringify(v);
  }
  localStorage.setItem(key, v);
};

const setSession = (key, value) => {
  let v = value;
  if (typeof v !== 'string') {
    v = JSON.stringify(v);
  }
  sessionStorage.setItem(key, v);
};

const get = (key) => {
  let v = localStorage.getItem(key);
  try {
    v = JSON.parse(v);
    // if key is authUser, we fetch the token from cookie
    if (key === 'authUser') {
      v.token = Cookies.get('token');
    }
  } catch (ex) {
    // do nothing, it is not a serialized object
  }
  return v;
};

const getAuthUser = () => get('authUser');

const remove = (key) => {
  // if key is authUser, remove cookie token and username
  if (key === 'authUser') {
    Cookies.remove('token');
    Cookies.remove('username');
  }
  localStorage.removeItem(key);
};

const removeAuthUser = () => remove('authUser');

export default {};
export {
  get,
  getAuthUser,
  remove,
  removeAuthUser,
  set,
  setSession,
};
