import { getClient } from '../setup';
import { GetDeviceInfos } from '../operations_deviceinfo.graphql';
import handleGraphQLError from './handle-graphql-error';

function handleDeviceInfoResponse({
  data: {
    deviceInfos,
  } = {},
}) {
  return {
    ok: true,
    status: 200,
    response: deviceInfos,
  };
}

export function getDeviceInfos(filters) {
  return getClient()
    .query({
      variables: filters,
      query: GetDeviceInfos,
      fetchPolicy: 'network-only',
    })
    .then(handleDeviceInfoResponse)
    .catch(handleGraphQLError);
}

export default {};
