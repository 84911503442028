import React from 'react';
import {
  FILTER_KEY_ASSET_ORIENTATION,
  FILTER_KEY_CREATIVE_LIST_FORMAT,
  FILTER_KEY_LANGUAGE,
  FILTER_KEY_STATUS,
  FILTER_KEY_SKOVERLAY_AUTO_SHOW,
  FILTER_KEY_VIDEO_OVERLAY_AUTO_SHOW,
} from 'app/constants/filters';
import {
  FORMAT_TYPE_BANNER,
  FORMAT_TYPE_FULLSCREEN,
  FORMAT_TYPE_MREC,
  FORMAT_TYPE_NATIVE,
} from 'app/constants/campaign';
import AttachedCreatives from './AttachedCreatives';

const baseFilterTypes = [
  FILTER_KEY_CREATIVE_LIST_FORMAT,
  FILTER_KEY_STATUS,
  FILTER_KEY_LANGUAGE,
];

const getFilterTypes = (campaign) => {
  const filterTypes = [...baseFilterTypes];
  const platform = campaign.get('application')?.get('platform');
  if (platform === 'ios') {
    filterTypes.push(FILTER_KEY_SKOVERLAY_AUTO_SHOW);
  } else if (platform === 'android') {
    filterTypes.push(FILTER_KEY_VIDEO_OVERLAY_AUTO_SHOW);
  }
  if (!campaign.supportsSKANABTesting()) {
    return filterTypes;
  }

  if (campaign.get('creative_type') === FORMAT_TYPE_FULLSCREEN) {
    filterTypes.push(FILTER_KEY_ASSET_ORIENTATION);
  }

  return filterTypes;
};

export const AttachedCreativesWithoutTestingMREC = (props) => <AttachedCreatives {...props} />;
export const AttachedCreativesWithoutTestingBanner = (props) => <AttachedCreatives {...props} />;
export const AttachedCreativesWithoutTestingFullscreen = (props) => <AttachedCreatives {...props} />;
export const AttachedCreativesWithoutTestingNative = (props) => <AttachedCreatives {...props} />;

const attachedCreativesComponents = {
  [FORMAT_TYPE_MREC]: AttachedCreativesWithoutTestingMREC,
  [FORMAT_TYPE_BANNER]: AttachedCreativesWithoutTestingBanner,
  [FORMAT_TYPE_FULLSCREEN]: AttachedCreativesWithoutTestingFullscreen,
  [FORMAT_TYPE_NATIVE]: AttachedCreativesWithoutTestingNative,
};

const getAttachedCreativesWithoutTesting = (campaign) => {
  if (!campaign.supportsSKANABTesting()) {
    return AttachedCreatives;
  }

  const creativeType = campaign.get('creative_type');
  return attachedCreativesComponents[creativeType];
};

const AttachedCreativesWithoutTesting = (props) => {
  const { campaign } = props;
  const AttachedCreativesForNonABTesting = getAttachedCreativesWithoutTesting(campaign);
  return <AttachedCreativesForNonABTesting {...props} filterTypes={getFilterTypes(campaign)} />;
};

export default AttachedCreativesWithoutTesting;
