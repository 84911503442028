import { upperFirst } from 'lodash';
import iso3166 from 'iso-3166-2';
import { isCampaignObjectiveRoasIap, isCampaignObjectiveCPEIap, isCampaignObjectiveDynamic } from 'lib/capabilities/campaigns';
import getCityString from 'lib/helpers/getCityString/getCityString';
import getCountryFromCode from 'lib/helpers/getCountryFromCode/getCountryFromCode';

const exchangeOnly = (c) => c.get('settings.ad_type') === 'exchange';

const isModel = true;

const categories = [
  {
    name: 'Details',
    fields: [
      {
        name: 'Archived',
        field: 'is_deleted',
        transform: (v) => (v ? 'Archived' : 'Unarchived'),
      },
      {
        name: 'Status',
        field: 'status',
        transform: upperFirst,
      },
      {
        name: 'Name',
        field: 'name',
      },
      {
        name: 'Start Date',
        field: 'dates.start',
        type: 'Date',
      },
      {
        name: 'Run indefinitely',
        field: 'dates.is_indefinite',
        type: 'Boolean',
      },
      {
        name: 'End Date',
        field: 'dates.end',
        type: 'Date',
      },
      {
        name: 'SKAdNetwork Enablement',
        field: 'is_skadnetwork_enabled',
        type: 'Boolean',
        optional: true,
      },
    ],
  },
  {
    name: 'Budget',
    fields: [
      {
        name: 'Total',
        field: 'budget.total',
      },
      {
        name: 'Daily Budget',
        field: 'budget.daily',
      },
      {
        name: 'Type',
        field: 'budget.type',
      },
      {
        name: 'Bid',
        field: 'budget.bid',
      },
      {
        name: 'Pacing Method',
        field: 'budget.pacing.method',
      },
      {
        name: 'Multibidding rates',
        field: 'budget.publisher_rates',
        type: 'Array',
        multipleAccessors: ['rate', 'name', 'geo'],
        transform: (v) => `${v.name || '*'} (rate: $${v.rate}) (geo: ${v.geo || '*'})`,
      },
      {
        name: 'Total Override',
        field: 'budget.total_override.is_enabled',
        type: 'Boolean',
      },
      {
        name: 'Total Override Value',
        field: 'budget.total_override.value',
      },
      {
        name: 'Bid Override',
        field: 'budget.bid_override.is_enabled',
        type: 'Boolean',
      },
      {
        name: 'Bid Override Value',
        field: 'budget.bid_override.value',
      },
      {
        name: 'Daily Budget Override',
        field: 'budget.daily_override.is_enabled',
        type: 'Boolean',
      },
      {
        name: 'Daily Budget Override Value',
        field: 'budget.daily_override.value',
      },
      {
        name: 'Budget-Multiplier',
        field: 'budget.budget_multiplier.is_enabled',
        type: 'Boolean',
      },
      {
        name: 'Budget-Multiplier: Override',
        field: 'budget.budget_multiplier.override',
      },
      {
        name: 'Optimization Type',
        field: 'budget.objective.type',
        transform: upperFirst,
      },
      {
        name: 'Dynamic: Default Bid',
        field: 'budget.objective.bid_default',
        onlyIf: isCampaignObjectiveRoasIap,
      },
      {
        name: 'Dynamic: Minimum Bid',
        field: 'budget.objective.bid_min',
        onlyIf: isCampaignObjectiveRoasIap,
      },
      {
        name: 'Dynamic: Maximum Bid',
        field: 'budget.objective.bid_max',
        onlyIf: isCampaignObjectiveDynamic,
      },
      {
        name: 'Dynamic: Target Return',
        field: 'budget.objective.target_return',
        onlyIf: isCampaignObjectiveDynamic,
      },
      {
        name: 'Dynamic: Target Override',
        field: 'budget.objective.target_override',
        onlyIf: isCampaignObjectiveDynamic,
      },
      {
        name: 'Campaign Subsidization',
        field: 'budget.objective.targetSubsidizationMultiplier',
        onlyIf: isCampaignObjectiveDynamic,
      },
      {
        name: 'Target Conversion Event Name',
        field: 'budget.objective.cpe_target_event_name',
        onlyIf: isCampaignObjectiveCPEIap,
      },
      {
        name: 'Dynamic: Horizon',
        field: 'budget.objective.horizon',
        onlyIf: isCampaignObjectiveDynamic,
      },
      {
        name: 'Dynamic: Training Window',
        field: 'budget.objective.training_window',
        onlyIf: isCampaignObjectiveDynamic,
      },
      {
        name: 'Dynamic: Install Threshold',
        field: 'budget.objective.n_install_threshold',
        onlyIf: isCampaignObjectiveDynamic,
      },
      {
        name: 'Dynamic: Target Events',
        field: 'budget.objective.events',
        onlyIf: isCampaignObjectiveDynamic,
        type: 'Array',
        accessor: 'name',
        transform: (v) => `${v.name} - ${v.value}`,
      },
    ],
  },
  {
    name: 'Creatives',
    fields: [
      {
        name: 'Creatives',
        field: 'creatives',
        type: 'Array',
        accessor: 'id',
        isModel: true,
        transform: (v) => v.getFriendlyName(),
      },
    ],
  },
  {
    name: 'Targeting',
    fields: [
      {
        name: 'Device: iPod',
        field: 'targeting.devices.ipod',
        type: 'Boolean',
      },
      {
        name: 'Device: Phone',
        field: 'targeting.devices.phone',
        type: 'Boolean',
      },
      {
        name: 'Device: Tablet',
        field: 'targeting.devices.tablet',
        type: 'Boolean',
      },
      {
        name: 'Geo. Region',
        field: 'targeting.geo.region',
        transform: (r) => ({ country: 'Country', subdivision: 'Region', city: 'City' }[r]),
      },
      {
        name: 'Geo. Countries',
        field: 'targeting.geo.countries',
        type: 'Array',
        accessor: 'code',
        transform: (v) => getCountryFromCode(v.code, 'name'),
      },
      {
        name: 'Geo. Region',
        field: 'targeting.geo.subdivisions',
        type: 'Array',
        accessor: 'code',
        transform: (v) => iso3166.subdivision(v.code)?.name,
      },
      {
        name: 'Geo. Cities',
        field: 'targeting.geo.cities',
        type: 'Array',
        accessor: 'name',
        transform: getCityString,
      },
      {
        name: 'Carrier Targeting',
        field: 'settings.carrier_targeting',
        type: 'Boolean',
      },
      {
        name: 'Carrier. AllowList',
        field: 'targeting.carrier.carrierAllowList',
        type: 'Array',
        isPlain: true,
      },
      {
        name: 'Carrier. DenyList',
        field: 'targeting.carrier.carrierDenyList',
        type: 'Array',
        isPlain: true,
      },
      {
        name: 'Network',
        field: 'targeting.network',
        transform: upperFirst,
      },
      {
        name: 'Pub. Type',
        field: 'targeting.publisher.type',
        transform: upperFirst,
      },
      {
        isModel,
        name: 'Pub. IDs',
        field: 'targeting.publisher.applications',
        type: 'Array',
        accessor: 'id',
        transform: (v) => v.get('name'),
      },
      {
        name: 'Off-Network: Bundle Type',
        field: 'targeting.bundles_offnetwork.type',
        transform: upperFirst,
      },
      {
        name: 'Off-Network: Bundle IDs',
        field: 'targeting.bundles_offnetwork.applications',
        type: 'Array',
        accessor: 'bundle_id',
        transform: (v) => v.bundle_id,
      },
      {
        isModel,
        name: 'Off-Network: Exchange IDs',
        field: 'targeting.exchanges_offnetwork',
        type: 'Array',
        accessor: 'id',
        transform: (v) => v.get('name'),
      },
      {
        name: 'OS Version Limit',
        field: 'targeting.versions.application.is_enabled',
        type: 'Boolean',
      },
      {
        name: 'OS Version Limit: Max',
        field: 'targeting.versions.application.max',
      },
      {
        name: 'OS Version Limit: Min',
        field: 'targeting.versions.application.min',
      },
      {
        name: 'SDK Version Limit',
        field: 'targeting.versions.sdk.is_enabled',
        type: 'Boolean',
      },
      {
        name: 'SDK Version Limit: Max',
        field: 'targeting.versions.sdk.max',
      },
      {
        name: 'SDK Version Limit: Min',
        field: 'targeting.versions.sdk.min',
      },
      {
        name: 'Rewarded Targeting',
        field: 'targeting.reward_type',
      },
      {
        name: 'MSRP Enabled',
        field: 'targeting.msrp.enabled',
        type: 'Boolean',
      },
      {
        name: 'MSRP Max',
        field: 'targeting.msrp.max',
      },
      {
        name: 'MSRP Min',
        field: 'targeting.msrp.min',
      },
      {
        name: 'MSRP Duration',
        field: 'targeting.msrp.duration',
      },
    ],
  },
  {
    name: 'Tracking',
    fields: [
      {
        name: 'Tracking URL',
        field: 'tracking.url',
      },
      {
        name: 'Destination URL',
        field: 'tracking.destination',
      },
      {
        name: 'Refresh',
        field: 'tracking.refresh_destination',
        type: 'Boolean',
      },
      {
        name: 'Click Callback URL',
        field: 'tracking.click',
      },
      {
        name: 'MOAT',
        field: 'tracking.moat',
        type: 'Boolean',
      },
      {
        name: 'Events',
        field: 'tracking.events',
        type: 'Array',
        accessor: 'name',
        transform: (v) => v.name,
      },
      {
        name: 'SKAdNetwork Attribution Methodology',
        field: 'attribution_method',
        optional: true,
        transform: (v) => ({ mmp: 'MMP', skadnetwork: 'SKAdNetwork' }[v]),
      },
    ],
  },
  {
    name: 'Advanced',
    fields: [
      {
        name: 'Ad Type',
        field: 'settings.ad_type',
        transform: upperFirst,
      },
      {
        name: 'Exchange: Protocol',
        field: 'settings.exchange.protocol',
        onlyIf: exchangeOnly,
      },
      {
        name: 'Exchange: Allowed ad delivery type',
        field: 'settings.exchange.delivery_type',
        onlyIf: exchangeOnly,
      },
      {
        name: 'Exchange: Third-party URL',
        field: 'settings.exchange.exchange_url',
        onlyIf: exchangeOnly,
      },
      {
        name: 'Exchange: Third-party expiry',
        field: 'settings.exchange.expiry',
        onlyIf: exchangeOnly,
      },
      {
        name: 'Exchange: Duration',
        field: 'settings.exchange.max_duration',
        onlyIf: exchangeOnly,
      },
      {
        name: 'Exchange: Bitrate',
        field: 'settings.exchange.min_bitrate',
        onlyIf: exchangeOnly,
      },
      {
        name: 'Daily View Limit',
        field: 'settings.daily_view_limit_enabled',
        type: 'Boolean',
      },
      {
        name: 'Daily View Limit',
        field: 'settings.daily_view_limit',
      },
      {
        name: 'Total View Limit',
        field: 'settings.total_view_limit_enabled',
        type: 'Boolean',
      },
      {
        name: 'Total View Limit',
        field: 'settings.total_view_limit',
      },
      {
        name: 'eRPM Multiplier',
        field: 'settings.erpm_override_enabled',
        type: 'Boolean',
      },
      {
        name: 'eRPM Multiplier',
        field: 'settings.erpm_override',
      },
      {
        name: 'Creative Optimization Override',
        field: 'settings.creative_override',
        type: 'Boolean',
      },
      {
        name: 'Creative Timer Override',
        field: 'settings.creative_timer_override',
        type: 'Boolean',
      },
      {
        name: 'Win Notice Source',
        field: 'settings.win_notice_source',
        transform: (v) => (v === 'sdk' ? 'SDK' : upperFirst(v)),
      },
      {
        name: 'SKOverlay Auto Show',
        field: 'settings.skoverlay_auto_show',
        type: 'Boolean',
      },
    ],
  },
];

export default categories;
