import React from 'react';
import { config } from 'app/app';
import { dynamicGeosKey } from 'app/constants/campaign';
import { setGeoBudget } from 'lib/helpers/campaigns/campaignIndex';
import { isCampaignObjectiveDynamic } from 'lib/capabilities/campaigns';
import Campaign from 'models/Campaign';
import CitySelector from './CitySelector';
import RegionSelector from './RegionSelector';
import CountrySelector from './CountrySelector';
import CitySelected from './CitySelected';
import RegionSelected from './RegionSelected';
import CountrySelected from './CountrySelected';
import 'templates/MultiTreeSelector/selector.scss';

const { countries, subdivisions } = config.get('countryData');

const geoTargetingKeys = {
  city: 'cities',
  subdivision: 'subdivisions',
  country: 'countries',
};

const geoSelectedComponents = {
  city: CitySelected,
  subdivision: RegionSelected,
  country: CountrySelected,
};

const geoSelectorComponents = {
  city: CitySelector,
  subdivision: RegionSelector,
  country: CountrySelector,
};

const GeoSelectorContainer = ({ resource, isMissionControl }) => {
  const geoRegion = resource.get('targeting.geo.region');
  const selectedGeos = resource.get(`targeting.geo.${geoTargetingKeys[geoRegion]}`);
  const isCampaignPage = resource instanceof Campaign;
  const isDynamicCampaign = isCampaignPage && isCampaignObjectiveDynamic(resource);
  const onSelectedChange = (geos) => {
    resource.set(`targeting.geo.${geoTargetingKeys[geoRegion]}`, geos);
    setGeoBudget(resource, isMissionControl);
  };
  const onPaste = (e) => {
    e.preventDefault();
    const text = e.clipboardData.getData('text');
    const copiedData = text.replace(/\s+/g, ',').split(',');
    if (copiedData.length === 0) {
      return;
    }

    if (geoRegion === 'country') {
      const availableCountries = isDynamicCampaign ? resource.get(dynamicGeosKey) : countries;
      const countriesFromClipboard = availableCountries?.filter(
        (country) => copiedData.includes(country.code)
          && selectedGeos.every((selected) => selected.id !== country.id),
      );
      const newCountries = selectedGeos.concat(countriesFromClipboard);
      onSelectedChange(newCountries);
      resource.geoDailySpend();
      return;
    }

    if (geoRegion === 'subdivision') {
      const subdivisionsFromClipboard = subdivisions.filter(
        (subdivision) => copiedData.includes(subdivision.code)
          && selectedGeos.every((selected) => selected.code !== subdivision.code),
      );
      const newSubdivisions = selectedGeos.concat(subdivisionsFromClipboard);
      onSelectedChange(newSubdivisions);
    }
  };

  const Selector = geoSelectorComponents[geoRegion];
  const Selected = geoSelectedComponents[geoRegion];

  return (
    <div className="campaigns__multi-tree-selector">
      <Selector
        resource={resource}
        selectedItems={selectedGeos}
        onSelectedChange={onSelectedChange}
        onPaste={onPaste}
      />
      <Selected
        resource={resource}
        selectedItems={selectedGeos}
        onSelectedChange={onSelectedChange}
        onPaste={onPaste}
      />
    </div>
  );
};

export default GeoSelectorContainer;
