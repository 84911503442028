import React from 'react';
import { locale } from '../../../../../lib/date';
import { format } from '../../../../../lib/currency';
import Label from '../../../../../components/V2/Label/Label';
import Group from '../../../../../templates/input-group/InputGroupContainer';

const StatusPill = ({ status }) => {
  switch (status) {
    case 'charge.succeeded':
    case 'complete':
      return <Label className="pill pill--active">Successful</Label>;
    case 'charge.failed':
    case 'failed':
      return <Label className="pill pill--error">Rejected</Label>;
    case 'charge.refunded':
    case 'refunded':
      return <Label className="pill pill--refunded">Refunded</Label>;
    default:
      return <Label className="pill pill--processing">Pending</Label>;
  }
};

const Transactions = ({ account }) => (
  <div className="transactions">
    <Group
      title="Most Recent Transactions"
      style={{ height: 250, overflow: 'hidden' }}
      info
      icon="info"
      infoMessage="Refresh page to see payment status updates"
    >
      <div className="container">
        {account.get('transactions').length === 0 && (
          <div className="no-transactions flex f fh fv">
            <p className="p2">You have not completed any transactions yet</p>
          </div>
        )}
        {account.get('transactions').map(({ date, amount, status }) => (
          <div className="transaction" key={date}>
            <div className="date">
              <p className="p2">Date</p>
              <p className="p2">{locale(date)}</p>
            </div>
            <div className="amount">
              <p className="p2">Amount</p>
              <p className="p2">{format(amount)}</p>
            </div>
            <div className="pills">
              <p className="p2">Status</p>
              <StatusPill status={status} />
            </div>
          </div>
        ))}
      </div>
    </Group>
  </div>
);

export default Transactions;
