import React from 'react';
import empty from 'assets/img/empty_state_no_creatives.svg';
import Template from 'models/Template';
import { FILTER_KEY_CREATIVE_LIST_FORMAT, FILTER_KEY_CREATIVE_TYPE } from 'app/constants/filters';
import CreativeTable from 'services/Templates/CreativeBuilder/CreativeTable/CreativeTable';
import {
  FORMAT_TYPE_BANNER,
  FORMAT_TYPE_FULLSCREEN,
  FORMAT_TYPE_MREC,
  FORMAT_TYPE_NATIVE,
} from 'app/constants/campaign';
import Link from '../../../../components/Link/Link';

const templates = [...Template.getTemplates()];

const disabledFilterOptionsMap = {
  [FORMAT_TYPE_FULLSCREEN]: { [FILTER_KEY_CREATIVE_LIST_FORMAT]: ['Banner', 'MREC', 'Native'] },
  [FORMAT_TYPE_BANNER]: { [FILTER_KEY_CREATIVE_LIST_FORMAT]: ['MREC', 'Full Screen(Multi-Page)', 'Full Screen(Single-Page)', 'Native'] },
  [FORMAT_TYPE_MREC]: { [FILTER_KEY_CREATIVE_LIST_FORMAT]: ['Banner', 'Full Screen(Multi-Page)', 'Full Screen(Single-Page)', 'Native'] },
  [FORMAT_TYPE_NATIVE]: { [FILTER_KEY_CREATIVE_LIST_FORMAT]: ['Banner', 'Full Screen(Multi-Page)', 'Full Screen(Single-Page)', 'MREC'] },
};

const getTemplatesByCreativeType = (creativeType) => templates.filter(({ creative_type: c }) => c !== creativeType).map(({ id }) => id);

const hiddenFilterOptionsMap = {
  [FORMAT_TYPE_FULLSCREEN]: { [FILTER_KEY_CREATIVE_TYPE]: getTemplatesByCreativeType(FORMAT_TYPE_FULLSCREEN) },
  [FORMAT_TYPE_BANNER]: { [FILTER_KEY_CREATIVE_TYPE]: getTemplatesByCreativeType(FORMAT_TYPE_BANNER) },
  [FORMAT_TYPE_MREC]: { [FILTER_KEY_CREATIVE_TYPE]: getTemplatesByCreativeType(FORMAT_TYPE_MREC) },
  [FORMAT_TYPE_NATIVE]: { [FILTER_KEY_CREATIVE_TYPE]: getTemplatesByCreativeType(FORMAT_TYPE_NATIVE) },
};

const AttachedCreativesBase = ({
  canEdit,
  campaign,
  creatives = [],
  disableRowSelector,
  selectedIds,
  selectCreative,
  filterTypes,
  upgradeSKANComponent,
  disableUnselected = false,
  filterLabels,
  disabledDropDownFilters,
  renderRadio,
  selectable,
  deletable,
  hideOnNoData,
  hideFilter,
  onClearAll,
  shouldDisplayNoCreativeMessage,
}) => {
  const creativeType = campaign.get('creative_type');

  if (shouldDisplayNoCreativeMessage) {
    return (
      <div className="active-creatives">
        <div className="no-creatives f fc fh fv">
          <img className="no-creatives-image" src={empty} alt="" />
          <p className="no-creatives-title h2">We didn’t find any creatives that match this format.</p>
          <p className="p1">
            Read about our&nbsp;
            <Link external to="https://support.vungle.com/hc/en-us/articles/360057064312#h_01F2SGPJVVCEBA4ETXVY6TPBJW" className="body__link">
              creative asset requirements
            </Link>
            &nbsp;to learn more.
          </p>
        </div>
      </div>
    );
  }

  if (creatives.length === 0 && !hideOnNoData) {
    return (
      <div className="active-creatives">
        <div className="no-creatives f fc fh fv">
          <img className="no-creatives-image" src={empty} alt="" />
          <p className="no-creatives-title h2">You currently have no creatives</p>
          <p className="p2">Looks a little empty here, choose a recommended template from above to add a creative to your application</p>
        </div>
      </div>
    );
  }

  return (
    <div className="creatives-wrapper creative_builder-creative-table-wrapper">
      <CreativeTable
        canEdit={canEdit}
        campaign={campaign}
        disableRowSelector={disableRowSelector}
        data={creatives}
        shouldShowRemoveAll
        placeholder="Search creative name or id..."
        loading={false}
        selectedIds={selectedIds}
        selectCreative={selectCreative}
        filterTypes={filterTypes}
        upgradeSKANComponent={upgradeSKANComponent}
        disableUnselected={disableUnselected}
        disabledOptions={campaign.supportsSKANABTesting() ? disabledFilterOptionsMap[creativeType] : {}}
        filterLabels={filterLabels}
        disabledDropDownFilters={disabledDropDownFilters}
        hiddenOptions={campaign.supportsSKANABTesting() ? hiddenFilterOptionsMap[creativeType] : {}}
        renderRadio={renderRadio}
        selectable={selectable}
        deletable={deletable}
        hideOnNoData={hideOnNoData}
        hideFilter={hideFilter}
        onClearAll={onClearAll}
      />
    </div>
  );
};

export default AttachedCreativesBase;
