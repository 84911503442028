import React, { compose, namespace } from '../../../app/app';
import Content from './Content';
import './content.scss';

const ns = 'components.modals.content';
const updateState = namespace(ns);
const props = (state) => state[ns];

class ContentContainer extends React.Component {
  constructor(p) {
    super(p);
    this.state = {};
  }

  render() {
    if (!this.props.show) {
      return null;
    }
    return (
      <Content {...this.props} hideModal={this.props.actions.hideModal} />
    );
  }
}

const hideModal = ({ state }) => updateState(state, { show: false, render: null });

const actions = { hideModal };

export default compose(ContentContainer, { actions, props });
export {
  updateState,
};
