import moment from 'moment';
import {
  FILTER_KEY_DATE,
  FILTER_KEY_STATUS,
  FILTER_KEY_APPLICATION,
  FILTER_KEY_CREATIVE_FORMAT,
  FILTER_KEY_LANGUAGE,
  FILTER_KEY_SEARCH,
} from 'app/constants/filters';
import {
  QUICK_MAP,
  QUICK_KEY_LAST_7_DAYS,
} from 'components/V2/Report/Calendar/PickerQuick';

export const COLUMN_CREATIVE_NAME = 'name';
export const COLUMN_CREATIVE_STATUS = 'status';
export const COLUMN_CREATIVE_ID = 'id';
export const COLUMN_TEMPLATE = 'creativeType';
export const COLUMN_ACCOUNT = 'accountName';
export const COLUMN_APPROVAL = 'adminStatus';
export const COLUMN_AD_FORMAT = 'creativeFormat';
export const COLUMN_LANGUAGE = 'language';
export const COLUMN_APPLICATION = 'applicationName';
export const COLUMN_ATTACHED_CAMPAIGNS = 'campaignCount';
export const COLUMN_DATE_CREATED = 'created';
export const COLUMN_DATE_UPDATED = 'updated';
export const COLUMN_TAGS = 'tags';

export const TABLE_ACTION_APPROVE_CREATIVE = 'Approve Creative';
export const TABLE_ACTION_CLONE_CREATIVE = 'Clone Creative';
export const TABLE_ACTION_EDIT_CREATIVE = 'Edit Creative';
export const TABLE_ACTION_PREVIEW_CREATIVE = 'Preview Creative';
export const TABLE_ACTION_PREVIEW_IN_CREATIVE_TESTING_TOOL = 'Preview in Creative Testing Tool';
export const TABLE_ACTION_REJECT_CREATIVE = 'Reject Creative';
export const TABLE_ACTION_ACTIVATE_CREATIVE = 'Activate Creative';
export const TABLE_ACTION_PAUSE_CREATIVE = 'Pause Creative';
export const TABLE_ACTION_DELETE_CREATIVE = 'Delete Creative';

export const defaultSort = {
  id: COLUMN_DATE_CREATED,
  desc: true,
};

export const defaultFilterCache = {
  [FILTER_KEY_DATE]: {
    quickKey: QUICK_KEY_LAST_7_DAYS,
    ...QUICK_MAP[QUICK_KEY_LAST_7_DAYS].generateDate(moment()),
  },
  [FILTER_KEY_APPLICATION]: [],
  [FILTER_KEY_CREATIVE_FORMAT]: [],
  [FILTER_KEY_STATUS]: [],
  [FILTER_KEY_LANGUAGE]: [],
};

export const filterKeys = [
  FILTER_KEY_APPLICATION,
  FILTER_KEY_CREATIVE_FORMAT,
  FILTER_KEY_STATUS,
  FILTER_KEY_LANGUAGE,
];

export const EXTRA_QUERY_KEYS = [
  ...filterKeys,
  FILTER_KEY_SEARCH,
];
