import React from 'react';
import { SearchEmptyState } from '../../EmptyState/EmptyState';

const NoData = ({ prefixCls, loading, style }) => (
  !loading
  && (
  <div className={`${prefixCls}-nodata`} style={style}>
    <SearchEmptyState />
  </div>
  )
);

export default NoData;
