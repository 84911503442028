import { isEmpty } from 'lodash';

export default function handleGraphQLError(err) {
  if (isEmpty(err.graphQLErrors)) {
    return {
      ok: false,
      message: err.message,
    };
  }

  const systemError = err.graphQLErrors.find(({ extensions }) => extensions?.code);
  if (systemError) {
    return {
      ok: false,
      code: systemError.extensions.code,
      message: systemError.message,
    };
  }

  return {
    ok: false,
    message: err.graphQLErrors[0].message,
  };
}
