import React from 'react';
import { calculatePage, PAGE_SIZE_OPTIONS } from '../../Pagination/Pagination';
import PaginationInner from '../../../../templates/pagination/pagination';

import '../../Pagination/pagination.scss';
import './pagination.scss';

const Pagination = ({
  pageSize,
  data,
  page,
  total,
  onPageSizeChange,
}) => (
  total > PAGE_SIZE_OPTIONS[0]
  && (
    <PaginationInner
      page={page}
      pages={calculatePage(pageSize, undefined, { total: total || data.length })}
      perPage={pageSize}
      onChangePage={onPageSizeChange}
    />
  )
);

export default Pagination;
