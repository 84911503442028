import React, {
  useEffect, useRef, useState, useCallback,
} from 'react';
import { parseInt } from 'lodash';
import classNames from 'classnames';
import Creative from 'models/Creative';
import Asset from '../../../../../../../../models/Asset';
import Link from '../../../../../../../../components/Link/Link';
import Message from '../../../../../../../../components/V2/FlagMessage/Message';
import { Copy, Tooltip, TooltipCutoff } from '../../../../../../../../components/V2';
import AssetPreviewer from '../../../../../../../admin/views/assets/list_2/AssetPreviewer/AssetPreviewer';
import { TYPE_PLAYABLE, TYPE_VIDEO } from '../../../../../../../admin/views/assets/list_2/constant';
import imgVideogameThumbnail from '../../../../../../../../assets/img/videogame_thumbnail.svg';
import { getAssetsForPreview } from '../../../../../../../../app/graphql/utils/asset';
import { getCreativeAssets } from './helper';
import Loading from './Loading';
import './assetStack.scss';

export const AssetInfoCell = ({ item, className, onPreview }) => {
  let assetThumbnail = item.url;
  if (item.type === TYPE_VIDEO) {
    assetThumbnail = item.thumbnail;
  } else if (item.type === TYPE_PLAYABLE) {
    assetThumbnail = imgVideogameThumbnail;
  }

  const titleWrapper = (title) => <span className="name" onClick={() => onPreview(item)}>{title}</span>;

  return (
    <div className={classNames('assert_info_container', className)}>
      <div className={classNames('thumbnail_container', `thumbnail_container-${item.type}`)} onClick={() => onPreview(item)}>
        <img className="thumbnail" src={assetThumbnail} />
      </div>
      <div className="info_container">
        <TooltipCutoff title={item.name} titleWrapper={titleWrapper} />
        {!!item.id && <Copy value={item.id} />}
        <span className="p2">{item.kind}</span>
      </div>
    </div>
  );
};

export const foldedAssets = (assets) => assets.reduce((acc, cur, index) => {
  if (index % 2) {
    acc[parseInt(index / 2)].push(cur);
  } else {
    acc.push([cur]);
  }
  return acc;
}, []);

function getTableWidth(dom) {
  if (!dom.parentNode || !dom.parentNode.classList) {
    return 0;
  }
  if (dom.parentNode.classList.contains('rt-table')) {
    return dom.parentNode.clientWidth;
  }
  return getTableWidth(dom.parentNode);
}

const AssetStack = ({ node, creative }) => {
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [previewAsset, setPreviewAsset] = useState();
  const [previewAssets, setPreviewAssets] = useState([]);
  const [selectedAssetIndex, setSelectedAssetIndex] = useState(0);
  const wrapper = useRef();

  const getAssets = useCallback(async () => {
    setLoading(true);
    const result = await Creative.getCreativeById(creative.id);
    const creativeAssets = getCreativeAssets(result.original);
    setAssets(creativeAssets);
    setLoading(false);
  }, [creative.id]);

  const onPreviewAsset = async (item) => {
    const index = assets.indexOf(item);
    setSelectedAssetIndex(index);

    if (!item.id) {
      Message.error({
        title: 'Preview Asset',
        content: 'Asset not found',
        timeout: 3e3,
      });
      return;
    }

    if (previewAssets.length) {
      const asset = previewAssets.find((a) => a.get('id') === item.id);
      setPreviewAsset(asset);

      return;
    }

    const ids = assets.filter((asset) => asset.id).map((asset) => asset.id);
    const { response } = await getAssetsForPreview(ids);
    const responseAssets = response.map((asset) => new Asset({
      ...asset,
      account: {
        name: node.get('account.name'),
      },
      application: {
        id: node.get('id'),
        name: node.get('name'),
        platform: node.get('platform'),
        store: {
          thumbnail: node.get('store.thumbnail'),
        },
      },
    }));

    setPreviewAssets(responseAssets);

    const asset = responseAssets.find((a) => a.get('id') === item.id);
    setPreviewAsset(asset);
  };

  const onPreviewPrevAsset = async () => {
    const index = selectedAssetIndex - 1;
    if (index < 0) {
      return;
    }

    onPreviewAsset(assets[index]);
  };

  const onPreviewNextAsset = async () => {
    const index = selectedAssetIndex + 1;
    if (index >= assets.length) {
      return;
    }

    onPreviewAsset(assets[index]);
  };

  const onAssetNameChange = (assetName) => {
    assets[selectedAssetIndex].name = assetName;
    setAssets(assets);
  };

  const setContainerWidth = () => {
    wrapper.current.style.width = `${getTableWidth(wrapper.current)}px`;
  };

  useEffect(() => {
    getAssets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setContainerWidth();
    window.addEventListener('resize', setContainerWidth);
    return () => window.removeEventListener('resize', setContainerWidth);
  }, []);

  return (
    <div className="asset_stack_container" ref={wrapper}>
      {!!assets.length && (
        <div>
          <div className="heads_up">
            <span className="p1">Asset</span>
            <Tooltip
              title="Assets"
              placement="left"
              body={(
                <div>
                  Learn more about assets
                  {' '}
                  <Link className="p8" external to="https://support.vungle.com/hc/en-us/articles/360057064312">here</Link>
                </div>
              )}
            >
              <i className="material-icons">info_outline</i>
            </Tooltip>
          </div>
          <div className="stack">
            {foldedAssets(assets).map((row) => (
              <div key={row.id} className="row_container">
                {row.map((item) => <AssetInfoCell item={item} key={item.id} onPreview={onPreviewAsset} />)}
              </div>
            ))}
          </div>
        </div>
      )}
      {loading && <Loading content="Just a second, we are loading more..." />}
      {
        (!loading && !assets.length) && <span>No assets are required for this Creative Type.</span>
      }
      {previewAsset && (
        <AssetPreviewer
          asset={previewAsset}
          onPrevAsset={onPreviewPrevAsset}
          onNextAsset={onPreviewNextAsset}
          onAssetNameChange={onAssetNameChange}
          onClose={() => {
            setPreviewAsset(null);
          }}
        />
      )}
    </div>
  );
};

export default AssetStack;
