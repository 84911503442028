import React from 'react';
import InputGroup from './InputGroup';
import './group.scss';

class InputGroupContainer extends React.Component {
  constructor(p) {
    super(p);
    this.state = { expanded: false };
  }

  toggleExpando = () => {
    this.setState((prevState) => ({ expanded: !prevState.expanded }));
  }

  render() {
    const style = { ...this.props.style };
    if (this.state.expanded) {
      delete style.maxHeight;
    }
    return <InputGroup {...this.props} style={style} toggleExpando={this.toggleExpando} />;
  }
}

InputGroupContainer.defaultProps = { style: {} };

InputGroupContainer.Buttons = InputGroup.Buttons;
InputGroupContainer.Field = InputGroup.Field;

export default InputGroupContainer;
