import React, { compose } from '../../../../../../app/app';
import Confirm from './Confirm';

const createNamespace = 'views.campaigns.edit';
const ns = 'views.campaigns.edit.confirm';
const props = (state) => ({
  ...state[ns],
  ...state[createNamespace],
});

class ConfirmContainer extends React.Component {
  constructor(p) {
    super(p);
    this.state = {};
  }

  render() {
    return (
      <Confirm {...this.props} />
    );
  }
}

const actions = {};

export default compose(ConfirmContainer, { actions, props });
