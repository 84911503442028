import React from 'react';
import { debounce } from 'lodash';
import './AssetPicker.scss';
import Asset from '../../../../../../../../models/Asset';
import ReviewHistory from '../../../../../creativeQA/reviewHistory/ReviewHistory';
import Spinner from '../../../../../../../../components/V2/Spinner/Spinner';
import ListHeader from './ListHeader';
import SelectableAssetList from './SelectableAssetList';
import { getListHeaderColumns } from './ListHeaderConfig';
import { formatTwoDecimalsWithoutRounding } from '../../../../../../../../lib/lib';
import { assetTypeMap } from '../../../../../../../../lib/helpers/Assets/contants';

const KEY_BANNER_IMAGE_8_1 = 'BANNER_IMAGE_8_1';
const KEY_BANNER_IMAGE_6_1 = 'BANNER_IMAGE_6_1';

const maxMinAspectRatios = {
  [KEY_BANNER_IMAGE_8_1]: {
    max: 8.5,
    min: 7.5,
  },
  [KEY_BANNER_IMAGE_6_1]: {
    max: 6.5,
    min: 5.5,
  },
};

const defaultSort = {
  id: 'created',
  sort: 'desc',
};

function isAssetAspectRatioInRange(asset, aspectRatioLimits = {}) {
  const aspectRatio = asset.raw('exif.width') / asset.raw('exif.height');
  return aspectRatio <= aspectRatioLimits.max && aspectRatio >= aspectRatioLimits.min;
}

function isAssetDimensionInRange(asset, dimensionLimits = { minWidth: 0, minHeight: 0 }) {
  if (dimensionLimits.minHeight === 0 || dimensionLimits.minWidth === 0) {
    return true;
  }
  return asset.raw('exif.width') >= dimensionLimits.minWidth && asset.raw('exif.height') >= dimensionLimits.minHeight;
}

function isAssetRatioApproximateRequiredRatio(asset, requiredRatio) {
  const aspectRatio = formatTwoDecimalsWithoutRounding(asset.raw('exif.width') / asset.raw('exif.height'));
  return aspectRatio === requiredRatio;
}

export function filterAssetsForNative(template, type, assets) {
  let requiredRatio = 0;
  const minWidth = template.raw('dimensions.minWidth');
  const minHeight = template.raw('dimensions.minHeight');
  if (!minWidth || !minHeight) {
    return assets;
  }
  requiredRatio = formatTwoDecimalsWithoutRounding(minWidth / minHeight);
  const unsupportedFormat = 'gif';
  return assets.filter((asset) => {
    const isRatioSuitable = (x) => isAssetRatioApproximateRequiredRatio(x, requiredRatio);
    const isDimensionSuitable = (x) => isAssetDimensionInRange(x, { minWidth, minHeight });
    const isTypeSuitable = (x) => x.get('type') === type;
    const isFormatSuitable = (x) => x.raw('exif.ext') !== unsupportedFormat;
    return isRatioSuitable(asset) && isDimensionSuitable(asset) && isTypeSuitable(asset) && isFormatSuitable(asset);
  });
}

function filterAssets(template, type, replacementKey, assets) {
  if (type === 'banner') {
    return assets.filter((asset) => {
      const isImageWithTagBanner = asset.get('type') === 'image' && asset.get('tags') && asset.get('tags').includes('banner');
      if (!isImageWithTagBanner) {
        // return if the asset type is not suitable
        return false;
      }

      return isAssetAspectRatioInRange(asset, maxMinAspectRatios[replacementKey]);
    });
  }

  if (type === 'banner_video') {
    // aspect ratio of the video is determined from the dimension values on the template
    let ratio = 0;
    if (template.raw('dimensions.minWidth') && template.raw('dimensions.minHeight')) {
      ratio = template.raw('dimensions.minWidth') / template.raw('dimensions.minHeight');
    } else {
      // select all assets if the dimensions are not specified
      return assets;
    }

    return assets.filter((asset) => {
      const isVideoWithTagBanner = asset.get('type') === 'video' && asset.get('tags')?.includes('banner');
      if (!isVideoWithTagBanner) {
        // return if the asset type is not suitable
        return false;
      }
      return isAssetAspectRatioInRange(asset, { max: ratio, min: ratio });
    });
  }

  if (template && template.raw('format') === 'native') {
    return filterAssetsForNative(template, type, assets);
  }

  if (type === 'image') {
    // remove the banner images
    return assets.filter((asset) => (asset.get('type') === 'image' && (!asset.get('tags') || !asset.get('tags').includes('banner'))));
  }

  if (type === 'bundle_adaptive_creative' && template?.get('template_category')['dynamic-template']) {
    // remove the bundle assets without linkedAssets if selecting bundle for dynamic template (AC to DT conversion)
    return assets.filter((asset) => (asset.get('type') === type && !!asset.get('linkedAssets')));
  }

  // filter for video and bundle_adaptive_creative assets
  return assets.filter((asset) => (asset.get('type') === type));
}

export async function loadFilteredAssets({
  application,
  replacementKey,
  template,
  type,
  playableStatusFilters,
  query = {},
  sort = {},
  currentPage = 1,
  perPage = 20,
}) {
  const applicationId = application.get('id') || application.get('attrs.id');
  const filters = {
    applications: [applicationId],
    perPage,
    search: query.search,
    sort: {
      key: sort.id,
      asc: sort.sort === 'asc',
    },
    qaStatus: playableStatusFilters?.length ? playableStatusFilters : '',
    typeIn: assetTypeMap[type],
  };

  let allAssets = [];
  let filteredAssets = [];
  let pagination = {};

  const loadAssets = async (page) => {
    filters.page = page;

    const result = await Asset.getAll(filters);
    pagination = result.pagination;

    const assets = Asset.make(result.response);
    allAssets = [...allAssets, ...assets];
    filteredAssets = [...filteredAssets, ...filterAssets(template, type, replacementKey, assets)];

    if (result.pagination.pages <= page) {
      return;
    }
    if (filteredAssets.length >= perPage) {
      return;
    }
    await loadAssets(pagination.page + 1);
  };

  await loadAssets(currentPage);

  return {
    pagination,
    assets: allAssets,
    filteredAssets,
  };
}

class AssetPickerList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assets: undefined,
      filteredAssets: undefined,
      loadingAssets: false,
      pagination: undefined,
      sort: defaultSort,
      showHistory: false,
      historyAsset: null,
    };
  }

  componentDidMount = () => {
    this.getAssets();
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.query.search !== prevProps.query.search
      || this.props.playableStatusFilters?.join(',') !== prevProps.playableStatusFilters?.join(',')) {
      this.debounceUpdateAssets();
    }
  };

  debounceUpdateAssets = debounce(() => {
    this.setState(
      { assets: undefined, filteredAssets: undefined, pagination: undefined },
      () => this.getAssets(),
    );
  }, 500);

  getAssets = async (isSorting = false) => {
    if (this.state.loadingAssets) {
      return;
    }

    this.setState({ loadingAssets: true });
    try {
      const page = isSorting ? 1 : (this.state.pagination?.page || 0) + 1;
      const {
        application,
        replacementKey,
        template,
        type,
        query,
        playableStatusFilters,
      } = this.props;
      const result = await loadFilteredAssets({
        application,
        replacementKey,
        template,
        type,
        query,
        playableStatusFilters,
        sort: this.state.sort,
        currentPage: page,
      });

      this.setState((state) => {
        const assets = isSorting ? [...result.assets] : [...(state.assets || []), ...result.assets];
        const filteredAssets = isSorting ? [...result.filteredAssets] : [...(state.filteredAssets || []), ...result.filteredAssets];
        return {
          assets,
          filteredAssets,
          totalAssetCount: result.pagination.total,
          loadingAssets: false,
          pagination: result.pagination,
        };
      });
    } catch (e) {
      this.setState({
        loadingAssets: false,
      });
    }
  }

  onSort = (sortColumn) => {
    this.setState({ sort: sortColumn }, () => this.getAssets(true));
  }

  onReviewHistoryClick = (asset) => {
    this.setState({
      historyAsset: asset,
      showHistory: true,
    });
  };

  render() {
    const {
      assets, filteredAssets, loadingAssets, totalAssetCount, showHistory, historyAsset,
    } = this.state;

    if (!assets) {
      return (
        <div className="asset_picker_list-loading">
          <Spinner />
        </div>
      );
    }
    const { type } = this.props;

    const totalCount = totalAssetCount - (assets.length - filteredAssets.length);

    const haveFilteredAssets = !!filteredAssets.length;

    // In order to make ListHeader and List have the same width, need to calculate whether List has a scroll bar.
    let haveScrollBar = false;
    if (haveFilteredAssets) {
      const listWrapper = document.querySelector('.ReactVirtualized__List');
      const listHeight = listWrapper?.offsetHeight || 0;
      const listScrollHeight = listWrapper?.scrollHeight || 0;
      if (listScrollHeight > listHeight) {
        haveScrollBar = true;
      }
    }

    return (
      <>
        <ListHeader
          defaultSort={defaultSort}
          columns={getListHeaderColumns(type)}
          onSort={this.onSort}
          haveScrollBar={haveScrollBar}
        />
        <SelectableAssetList
          type={type}
          assets={filteredAssets}
          loadingAssets={loadingAssets}
          onScrollEnd={this.getAssets}
          onSelectAsset={(asset) => this.props.onSelectAsset(asset)}
          totalAssetCount={totalCount}
          onReviewHistoryClick={this.onReviewHistoryClick}
        />
        {showHistory
          && (
            <ReviewHistory
              asset={historyAsset}
              onClose={() => this.setState({ showHistory: false })}
              refreshList={() => this.getAssets(true)}
            />
          )}
      </>
    );
  }
}

export default AssetPickerList;
