import React from 'react';
import ListView from './ListView';
import './listView.scss';

const ListViewContainer = (props) => (
  <ListView
    {...props}
    refreshList={props.refreshList}
  />
);

export default ListViewContainer;
