import React from 'react';
import { isEmpty } from 'lodash';
import Link from 'components/Link/Link';
import { isCampaignObjectiveDynamic, isCampaignObjectiveCPEIap, hasCPEIapOptimizationTypeAccess } from 'lib/capabilities/campaigns';
import Select from 'components/Form/Select/SelectContainer';
import Group from 'templates/input-group/InputGroupContainer';
import {
  getInvalidTiersFromSelectedCountries,
  getInvalidTiersFromSelectedCountriesForEvent,
  joinWithGrammar,
  getSelectedTierDynamicGeos,
  getTierCountryNames,
} from 'lib/capabilities/dynamic-geos';
import {
  NUM_OF_PAYING_USERS_THRESHOLD,
  NUM_OF_EVENTS_THRESHOLD,
  EVENTS_METRIC,
  PAYING_USERS_METRIC,
  EVENTS_METRIC_ACTION,
  PAYING_USERS_METRIC_ACTION,
} from 'app/constants/campaign';
import { Collapse, CollapseSection } from '../../../../components/V2';
import GeoSelector from '../GeoSelector/GeoSelectorContainer';
import { AdminOnlyLabel } from '../../../../components/V2/Label/Label';
import './geoTarget.scss';

const { Field } = Group;
const { Option } = Select;
const HELP_LINK_URL = 'https://support.vungle.com/hc/en-us/articles/4404750326171';

function getDynamicMessageDetails(invalidDynamicTiersStr, invalidDynamicTiers, tierCountryNames, objectiveType, isCPE) {
  const days = NUM_OF_PAYING_USERS_THRESHOLD + 1;
  const minRequiredCount = isCPE ? NUM_OF_EVENTS_THRESHOLD : NUM_OF_PAYING_USERS_THRESHOLD;
  const metricName = isCPE ? EVENTS_METRIC : PAYING_USERS_METRIC;
  const metricAction = isCPE ? EVENTS_METRIC_ACTION : PAYING_USERS_METRIC_ACTION;

  return (
    <div>
      <p>
        Over the last
        {' '}
        {days}
        {' '}
        days, fewer than
        {' '}
        {minRequiredCount}
        {' '}
        {metricName.toLowerCase()}
        {' '}
        have been
        {' '}
        {metricAction.toLowerCase()}
        {' '}
        across
        {' '}
        {invalidDynamicTiersStr}
        {' '}
        countries.
        {objectiveType}
        {' '}
        campaigns cannot be optimized effectively within a tier of countries unless the application has
        {' '}
        {metricAction.toLowerCase()}
        {' '}
        at least
        {' '}
        {minRequiredCount}
        {metricName.toLowerCase()}
        {' '}
        from the given country tier in the last
        {NUM_OF_PAYING_USERS_THRESHOLD + 1}
        {' '}
        days.
      </p>
      <ul>
        {invalidDynamicTiers.map((tierName, index) => (
          <li className="geo_targeting_collapse__item" key={index}>
            {tierName}
            {' '}
            Countries cannot be targeted using
            {' '}
            {objectiveType}
            {' '}
            Optimization.
            {tierName}
            {' '}
            Countries include:
            <span className="geo_targeting_collapse__item__countrynames">{tierCountryNames[tierName]}</span>
          </li>
        ))}

      </ul>
      <p className="geo_targeting_collapse__item__learnmore">
        <Link
          to={HELP_LINK_URL}
          external
        >
          Learn More About How to Increase
          {' '}
          {metricAction}
          {' '}
          {metricName}
        </Link>
      </p>
    </div>
  );
}

const geoOptions = [
  {
    value: 'country',
    name: 'Country',
    isAdminOnly: false,
  },
  {
    value: 'subdivision',
    name: 'Region',
    isAdminOnly: true,
  },
  {
    value: 'city',
    name: 'City',
    isAdminOnly: true,
  },
];

const mapTotalTargetCPEEventsToGeo = (geos, countryStatsForTargetCPEEvent) => geos.map((geo) => ({
  ...geo,
  totalEvents: countryStatsForTargetCPEEvent.find((c) => c.code === geo.code)?.totalEvents || 0,
}));

const GeoTarget = ({ campaign, isMissionControl }) => {
  const isCPE = isCampaignObjectiveCPEIap(campaign) && hasCPEIapOptimizationTypeAccess(campaign);
  const selectedTierDynamicGeos = getSelectedTierDynamicGeos(campaign.get('targeting.geo.countries'), campaign.get('dynamicGeos')) ?? [];
  const countryStatsForTargetCPEEvent = campaign.raw('application.pieCountryStatsForTargetCPEEvent') ?? [];
  const selectedGeosWithTargetCPEEventStats = mapTotalTargetCPEEventsToGeo(selectedTierDynamicGeos, countryStatsForTargetCPEEvent);
  const invalidDynamicTiers = isCPE
    ? getInvalidTiersFromSelectedCountriesForEvent(selectedGeosWithTargetCPEEventStats)
    : getInvalidTiersFromSelectedCountries(selectedTierDynamicGeos);
  const invalidDynamicTiersStr = joinWithGrammar(invalidDynamicTiers);
  const tierCountryNames = getTierCountryNames(selectedTierDynamicGeos);
  const objectiveType = campaign.get('budget.objective.type')?.toUpperCase() || 'Automatic';
  const metricName = isCPE ? EVENTS_METRIC : PAYING_USERS_METRIC;

  return (
    <CollapseSection
      className="geo_targeting_collapse"
      title="Geographic"
      id="geographic"
      description="Utilizing these targeting options will reduce your available impressions."
    >
      {isMissionControl && (
        <Field name="Geo Type" description="Select what geographic option you wish to use for targeting.">
          <Select
            name="targeting_geo_region"
            value={campaign.get('targeting.geo.region')}
            onChange={(value) => campaign.set('targeting.geo.region', value)}
            className="geo-options"
          >
            {geoOptions.map(({ value, name, isAdminOnly }) => (
              <Option
                key={value}
                value={value}
              >
                {name}
                {isAdminOnly && <AdminOnlyLabel className="geo-options__admin-only" />}
              </Option>
            ))}
          </Select>
        </Field>
      )}
      {isCampaignObjectiveDynamic(campaign) && campaign.get('targeting.geo.region') === 'country' && !isEmpty(invalidDynamicTiers) && (
        <Collapse
          state="warning"
          rows={[{
            summary: <span className="geo_targeting_collapse__summary">{`Insufficient ${metricName} in ${invalidDynamicTiersStr} Countries`}</span>,
            prefixIcon: <i className="material-icons">info_outline</i>,
            detail: getDynamicMessageDetails(invalidDynamicTiersStr, invalidDynamicTiers, tierCountryNames, objectiveType, isCPE),
          }]}
        />
      )}
      <GeoSelector resource={campaign} isMissionControl={isMissionControl} />
    </CollapseSection>
  );
};

export default GeoTarget;
