import React, { useState } from 'react';
import classNames from 'classnames';
import CreativeSelector from 'services/Templates/Campaigns/AttachedCreatives/CreativeSelector';
import Drawer from 'components/V2/Drawer/Drawer';
import AttachedCreativesSlider from 'services/Templates/Campaigns/AttachedCreatives/AttachedCreativesSlider';
import './attachedCreativesList.scss';
import { getCreativeVideoFormat } from './helper';

const AttachedCreativesList = ({
  prefix,
  className,
  campaign,
  creatives,
  selectedASetCreativeIds,
  selectedBSetCreativeIds,
  formatASetInfos,
  formatBSetInfos,
  selectASetCreative,
  selectBSetCreative,
  disableRowSelector,
  creativeType,
  isAbTesting,
  renderRadio,
  onConfirm,
  onCancel,
  setSelectedASetIds,
  setSelectedBSetIds,
  allCreatives = [],
}) => {
  const [isBSet, setIsBSet] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [filteredCreatives, setFilteredCreatives] = useState([]);

  const setCreatives = (formatInfo) => {
    const sameFormatCreatives = creatives.filter(
      (c) => getCreativeVideoFormat(c) === formatInfo.format,
    );
    setFilteredCreatives(sameFormatCreatives);
  };

  return (
    <div className={classNames('ab-testing-content', className)}>
      <Drawer
        sidebarClassName={`${prefix}-drawer`}
        open={isDrawerOpen}
        onOpenChange={(isOpen) => {
          setIsDrawerOpen(isOpen);
          if (!isOpen) {
            onCancel();
          }
        }}
        position="right"
      >
        <AttachedCreativesSlider
          campaign={campaign}
          creatives={filteredCreatives}
          disableRowSelector={disableRowSelector}
          selectedIds={isBSet ? selectedBSetCreativeIds : selectedASetCreativeIds}
          selectCreative={isBSet ? selectBSetCreative : selectASetCreative}
          creativeType={creativeType}
          modalType={modalType}
          renderRadio={(params) => renderRadio({ ...params, isBSet })}
          onConfirm={(ids) => {
            onConfirm(ids, isBSet);
            setIsDrawerOpen(false);
          }}
          onCancel={() => {
            onCancel();
            setIsDrawerOpen(false);
          }}
          hideOnNoData={creatives.length !== 0}
          setSelectedIds={isBSet ? setSelectedBSetIds : setSelectedASetIds}
          shouldDisplayNoCreativeMessage={
            allCreatives.length > 0 && filteredCreatives.length === 0
          }
        />
      </Drawer>
      {
        formatASetInfos.map((f, index) => (
          <div className="creative-selector-row" key={f.key}>
            <div className="creative-selector-wrapper">
              <CreativeSelector
                formatInfo={f}
                selectedIds={selectedASetCreativeIds}
                selectCreative={selectASetCreative}
                openDrawer={(isOpen, formatInfo) => {
                  setIsDrawerOpen(isOpen);
                  setIsBSet(false);
                  setModalType(formatInfo.creativeTypeName);
                  setCreatives(f);
                }}
              />
            </div>
            {
              isAbTesting && (
                <div className="creative-selector-wrapper">
                  <CreativeSelector
                    isAbTesting
                    key={formatBSetInfos[index].key}
                    formatInfo={formatBSetInfos[index]}
                    selectedIds={selectedBSetCreativeIds}
                    selectCreative={selectBSetCreative}
                    openDrawer={(isOpen, formatInfo) => {
                      setIsDrawerOpen(isOpen);
                      setIsBSet(true);
                      setModalType(formatInfo.creativeTypeName);
                      setCreatives(formatBSetInfos[index]);
                    }}
                  />
                </div>
              )
            }
          </div>
        ))
      }
    </div>
  );
};

export default AttachedCreativesList;
