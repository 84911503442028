import React from 'react';
import { getNextRoute } from 'services/advertiser/paths';
import Link from '../../../../../../../../components/Link/Link';
import { currencyFn } from '../../../../../../../../components/V2/Metrics/Metrics';
import { ServingStatusComponent } from '../../../../../../../../components/Campaigns/ServingStatus/ServingStatus';
import triggerConfirm from '../../../../../../../../components/Modals/ConfirmAction/triggerConfirm';
import { Tooltip, TooltipCutoff, Pulsation } from '../../../../../../../../components/V2';
import {
  makeColumn, renderDateCreated, getMetricsList, getDefaultMetrics,
} from '../helper';
import {
  METRIC_KEY_ID,
  METRIC_KEY_DAILY_SPEND,
  METRIC_ACCESSOR_DAILY_SPEND,
  METRIC_KEY_DAILY_BUDGET,
  METRIC_KEY_DAILY,
  METRIC_KEY_REMAINING_BUDGET,
  METRIC_ACCESSOR_REMAINING_BUDGET,
  METRIC_KEY_DATE_CREATED,
  METRIC_ACCESSOR_DATE_CREATED,
  CONTEXT_MENU_KEY_EDIT_CAMPAIGN,
  CONTEXT_MENU_KEY_PAUSE_CAMPAIGN,
  CONTEXT_MENU_KEY_EDIT_BID,
  CONTEXT_MENU_KEY_EDIT_DAILY_BUDGET,
  CONTEXT_MENU_KEY_FILTER_BY_CAMPAIGN,
} from '../constants';
import { canModifyCampaigns } from '../../../../../../../../lib/helpers/authUser';
import { dimension } from '../../../../../../../../lib/helpers/campaigns/constants';

export function renderColumns({ changeCampaignStatus, onOpenABTestReporting }) {
  return [{
    ...makeColumn('Name', 'name'),
    width: 310,
    fixed: 'left',
    Cell: function Cell({ original }) {
      if (original.total) {
        return 'Total';
      }
      const {
        id, name, isServer, dataModel, isAbTesting,
      } = original[dimension];
      return (
        <div className="table_name">
          <ServingStatusComponent
            campaign={dataModel}
            showTooltip={canModifyCampaigns()}
            disableLinks={!dataModel.isValidForEditing()}
            changeServingStatus={() => {
              triggerConfirm({
                type: 'ACTIVATE_CAMPAIGN_CONFIRM_ACTION',
                header: 'Activate Campaign',
                message: 'Are you sure you want to activate this Campaign?',
                name: dataModel.get('name'),
                onConfirm: () => changeCampaignStatus({ campaign: dataModel, status: 'activate' }),
              });
            }}
          >
            <Pulsation
              editIcon="play_arrow"
              prefixCls="pulsation v2_component_pulsation"
              status={isServer ? 'active' : 'inactive'}
            />
          </ServingStatusComponent>
          {isAbTesting && (
            <>
              <span className="ab_tag">A/B</span>
              <i
                className="material-icons"
                onClick={() => onOpenABTestReporting(dataModel)}
              >
                call_split
              </i>
            </>
          )}
          <TooltipCutoff
            title={name}
            placement="bottomLeft"
            titleWrapper={(title) => (
              <Link
                to={`/campaigns/${id}/${getNextRoute(dataModel, 'confirm', null, true)}`}
                className="body__link"
              >
                {title}
              </Link>
            )}
          />
        </div>
      );
    },
  }];
}

const renderTooltipCutoff = (name, title, body) => (
  <div className="budget_wrapper">
    <TooltipCutoff title={name} placement="bottomLeft" />
    <Tooltip title={title} body={body}>
      <i className="material-icons">help_outline</i>
    </Tooltip>
  </div>
);

export const renderDailyBudget = (node) => {
  const { daily, dailyOverride, dailySpendLimitType } = node[dimension].budget;
  if (dailySpendLimitType === 'geo') {
    return renderTooltipCutoff(
      'By Country',
      'Daily Budget by Country',
      `This campaign has its daily budgets defined by
        country.`,
    );
  }
  if (dailyOverride.isEnabled) {
    return renderTooltipCutoff(
      currencyFn(dailyOverride.value),
      'Overridden by Admin',
      `Your original value has been overridden by
        our internal team.`,
    );
  }

  return currencyFn(daily);
};

export const renderRemainingBudget = (node) => {
  const { total, spent, totalOverride } = node[dimension].budget;
  if (totalOverride.isEnabled) {
    return renderTooltipCutoff(
      currencyFn(totalOverride.value - spent),
      'Overridden by Admin',
      `Your original value has been overridden by
        our internal team.`,
    );
  }

  return currencyFn(total - spent);
};

export const attributesMetrics = {
  name: 'Attributes',
  metrics: [
    {
      default: true,
      isBudgetKey: false,
      isDimensionKey: true,
      showTooltipCutoff: true,
      key: METRIC_KEY_ID,
      accessor: METRIC_KEY_ID,
      name: 'ID',
      tableDefault: true,
      render: (item) => item[dimension].id,
    },
    {
      default: true,
      isBudgetKey: true,
      isDimensionKey: true,
      showTooltipCutoff: true,
      key: METRIC_KEY_DAILY_SPEND,
      accessor: METRIC_ACCESSOR_DAILY_SPEND,
      name: 'Daily Spend',
      tableDefault: true,
      render: (item) => currencyFn(item.metrics.dailySpend),
    },
    {
      default: true,
      isBudgetKey: true,
      isDimensionKey: true,
      showTooltipCutoff: false,
      key: METRIC_KEY_DAILY_BUDGET,
      accessor: METRIC_KEY_DAILY,
      name: 'Daily Budget',
      tableDefault: true,
      render: renderDailyBudget,
    },
    {
      default: true,
      isBudgetKey: true,
      isDimensionKey: true,
      showTooltipCutoff: false,
      key: METRIC_KEY_REMAINING_BUDGET,
      accessor: METRIC_ACCESSOR_REMAINING_BUDGET,
      name: 'Remaining Budget',
      tableDefault: true,
      render: renderRemainingBudget,
    },
    {
      default: true,
      isBudgetKey: false,
      isDimensionKey: true,
      showTooltipCutoff: true,
      key: METRIC_KEY_DATE_CREATED,
      accessor: METRIC_ACCESSOR_DATE_CREATED,
      name: 'Date Created',
      tableDefault: true,
      render: renderDateCreated,
    },
  ],
};

const attributesMetricsList = attributesMetrics.metrics;

export const defaultMetrics = getDefaultMetrics(attributesMetricsList);

export const returnMetricsList = (metricsData) => getMetricsList(metricsData, attributesMetricsList);

export function generateContextMenu({ original, changeCampaignStatus }) {
  return [
    {
      key: CONTEXT_MENU_KEY_EDIT_CAMPAIGN,
      name: 'Edit Campaign',
      router: (id, { application }) => `/campaigns/${id}/confirm?application=${application.id}`,
    },
    {
      key: CONTEXT_MENU_KEY_PAUSE_CAMPAIGN,
      name: original[dimension].isActive ? 'Pause Campaign' : 'Activate Campaign',
      action: ({ campaign }) => {
        const [text, status, msg] = original[dimension].isActive
          ? ['Pause Campaign', 'pause', 'Are you sure you want to stop this Campaign from serving?']
          : ['Activate Campaign', 'activate', 'Are you sure you want to activate this Campaign?'];
        triggerConfirm({
          type: original[dimension].isActive ? 'PAUSE_CAMPAIGN_CONFIRM_ACTION' : 'ACTIVATE_CAMPAIGN_CONFIRM_ACTION',
          header: text,
          message: msg,
          name: campaign.get('name'),
          onConfirm: () => changeCampaignStatus({ campaign, status }),
        });
      },
    },
    {
      key: CONTEXT_MENU_KEY_EDIT_BID,
      name: 'Edit Bid',
      router: (id, { application }) => `/campaigns/${id}/bidding?application=${application.id}`,
    },
    {
      key: CONTEXT_MENU_KEY_EDIT_DAILY_BUDGET,
      name: 'Edit Daily Budget',
      router: (id, { application }) => `/campaigns/${id}/bidding?application=${application.id}`,
    },
    {
      key: CONTEXT_MENU_KEY_FILTER_BY_CAMPAIGN,
      name: 'Filter by Campaign',
      action: ({ campaign, onFilterCampaign }) => {
        const node = {
          id: campaign.get('id'),
          name: campaign.get('name'),
          menuNode: campaign,
        };
        onFilterCampaign([node]);
      },
    },
  ];
}
