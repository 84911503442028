import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';
import triggerConfirm from '../../../Modals/ConfirmAction/triggerConfirm';
import Input from '../../Input/Input';
import Table from '../../Table/Table';
import { copy } from '../../Copy/Copy';
import { KEY_NAME, KEY_ID } from '../constants';
import { filterAppsData } from '../targetingHelper';
import makeColumn from './columns';
import './appTable.scss';

const classPrefix = 'views__publisher__targeting__app__table';

const AppTable = ({
  className,
  template,
  data: dataProp,
  loading,
  onDeleteAll,
  onDelete,
  onCopy,
}) => {
  const [data, setData] = useState(dataProp);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [pageData, setPageData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    if (searchKeyword) {
      setData(filterAppsData(dataProp, searchKeyword));
    } else {
      setData(dataProp);
    }
  }, [dataProp, searchKeyword]);

  useEffect(() => {
    const _pageData = data.slice((page - 1) * pageSize, page * pageSize);
    if (page > 1 && _pageData.length === 0) {
      setPage(page - 1);
    } else {
      setPageData(_pageData);
    }
  }, [data, page, pageSize]);

  const handleSearch = useCallback((event) => {
    if (!dataProp.length) return;
    const keyword = event.target.value;
    if (keyword) {
      const filteredData = filterAppsData(dataProp, keyword);
      setData(filteredData);
    } else {
      setData(dataProp);
    }
    setSearchKeyword(keyword);
    setPage(1);
  }, [dataProp]);

  const handlePageSizeChange = useCallback((_page, _pageSize) => {
    setPage(_page);
    setPageSize(_pageSize);
  }, []);

  const handleCopy = useCallback(() => {
    copy(data.map((row) => row[KEY_ID]).join(','), onCopy);
    // --> potential bug inside this useCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProp, onCopy, data]);

  const handleDeleteAll = useCallback(() => {
    triggerConfirm({
      type: 'DELETE_ALL_CONFIRM_ACTION',
      header: template.deleteAllTitle,
      message: template.deleteAllMessage,
      onConfirm: () => onDeleteAll(data),
    });
    // --> potential bug inside this useCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onDeleteAll, data]);

  const makeCell = useCallback((bid, attr) => {
    const archived = bid.is_deleted;
    if (KEY_NAME === attr) {
      return (
        <div className={archived ? 'p10' : 'p5'}>
          <span>{`${bid[attr]} ${archived ? '[Archived]' : ''}`}</span>
        </div>
      );
    }
    return (
      <div className={archived ? 'p10' : 'p5'}>
        <span>{bid[attr]}</span>
      </div>
    );
    // --> potential bug inside this useCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const makeActionHeader = useCallback(() => (
    <div className={`${classPrefix}--actions__header`} onClick={handleDeleteAll}>
      <i className="material-icons">delete_sweep</i>
    </div>
  ), [handleDeleteAll]);

  const makeActionCell = useCallback((bid) => (
    <div className={`${classPrefix}--actions__cell`} onClick={() => onDelete(bid)}>
      <i className="material-icons">delete</i>
    </div>
  ), [onDelete]);

  return (
    <div className={classPrefix}>
      <div className="filters">
        <Input.Search
          className="search"
          placeholder={template.searchPlaceholder}
          onChange={handleSearch}
        />
        {
          data.length > 0 && (
            <span className="p8 copy" onClick={handleCopy}>{template.copy}</span>
          )
        }
      </div>
      <Table
        className={classnames(
          className,
          classPrefix,
          { [`${classPrefix}-fixed`]: pageData.length >= 10 },
        )}
        manual
        data={pageData}
        loading={loading}
        showPagination
        shadowScroll={false}
        page={page}
        total={data.length}
        pageSize={pageSize}
        onPageSizeChange={handlePageSizeChange}
        columns={[
          {
            ...makeColumn(template.columnName, KEY_NAME, 335),
            Cell: ({ original }) => makeCell(original, KEY_NAME),
            minResizeWidth: 100,
            resizable: true,
          },
          {
            ...makeColumn(template.columnId, KEY_ID),
            Cell: ({ original }) => makeCell(original, KEY_ID),
          },
          {
            ...makeColumn(makeActionHeader, 'actions', 40),
            Cell: ({ original }) => makeActionCell(original),
            fixed: 'right',
          },
        ]}
      />
    </div>
  );
};

AppTable.propTypes = {
  /*
   * Class name string.
   */
  className: PropTypes.string,
  /**
   * Table datas.
   */
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * Table loading status.
   */
  loading: PropTypes.bool,
  /**
   * Will call when click action row header icon.
   */
  onDeleteAll: PropTypes.func,
  /**
   * Will call when delete one row.
   */
  onDelete: PropTypes.func,
  onCopy: PropTypes.func,
};

AppTable.defaultProps = {
  className: null,
  loading: false,
  onDeleteAll: noop,
  onDelete: noop,
  onCopy: noop,
};

export default AppTable;
