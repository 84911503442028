import React from 'react';
import ApplicationThumbnail from '../../../templates/application/thumbnail';
import './overview.scss';

const ApplicationOverview = ({ application }) => (
  <div className="components__templates__application_overview">
    <div className="overview">
      <ApplicationThumbnail application={application} />
    </div>
    <div className="info-wrapper">
      <div className="name">
        <p className="p3">{application.get('name')}</p>
        <p className="p2">{application.get('store.company')}</p>
      </div>
      <div className="id">
        <p className="p2">Store ID</p>
        <p className="p2">{application.get('store.id')}</p>
      </div>
    </div>
  </div>
);

export default ApplicationOverview;
