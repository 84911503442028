import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { FILTER_KEY_ASSET_ORIENTATION } from 'app/constants/filters';
import Multi from '../Base/Multi/Multi';
import AssetOrientationPanel from '../Base/Panels/AssetOrientationPanel';
import { assetOrientation } from '../constant';

const AssetOrientation = ({
  prefixCls,
  visible,
  defaultCheckedNodes,
  onVisibleChange,
  onChange,
  onRemove,
}) => (
  <Multi
    visible={visible}
    data={assetOrientation}
    filterKey={FILTER_KEY_ASSET_ORIENTATION}
    defaultCheckedKeys={defaultCheckedNodes}
    renderOverlay={() => (
      React.createElement(AssetOrientationPanel, {
        prefixCls,
        defaultCheckedNodes,
        onVisibleChange,
        onChange,
      })
    )}
    onVisibleChange={onVisibleChange}
    onRemove={onRemove}
  />
);

AssetOrientation.propTypes = {
  prefixCls: PropTypes.string,
  visible: PropTypes.bool,
  defaultCheckedNodes: PropTypes.arrayOf(PropTypes.any),
  onVisibleChange: PropTypes.func,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};

AssetOrientation.defaultProps = {
  prefixCls: 'v2_component_filter_asset_orientation',
  visible: false,
  defaultCheckedNodes: [],
  onVisibleChange: noop,
  onChange: noop,
  onRemove: noop,
};

export default AssetOrientation;
