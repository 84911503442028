import defaultOptions from './default';
import { generateServiceUrls } from './service-urls';

const config = {
  ...defaultOptions,
  disableErrorStackTrace: true,
  stripe: {
    key: 'pk_live_ZZ3DGZcVder8GC6Ngo0VNwlh',
  },
  sentry: {
    dsn: {
      admin: 'https://df987e3c32fb444badb478408164fb1d@o315280.ingest.sentry.io/5552700',
      advertiser: 'https://a7c209fdac594f4ab8b50703285cb855@o315280.ingest.sentry.io/5549791',
    },
    debug: false,
    autoSessionTracking: true,
    sampleRate: 1,
    tracesSampleRate: 0.3,
  },
  deviceListCsvUrl: {
    android: 'https://vungle2-cdn-prod.s3.amazonaws.com/devices/devices_android.csv',
    windows: 'https://vungle2-cdn-prod.s3.amazonaws.com/devices/devices_windows.csv',
    amazon: 'https://vungle2-cdn-prod.s3.amazonaws.com/devices/devices_amazon.csv',
  },
  ...generateServiceUrls('beta'),
};
export default config;
