import React from 'react';
import moment from 'moment';
import { isNumber } from 'lodash';
import { Tooltip, TooltipCutoff } from '../../../../../../../components/V2';
import {
  getMetricsListByTab,
  standardMetricsList,
} from '../../../../../../../components/V2/Report/data/Metrics';
import {
  currencyFn,
  currencyWithNegativeFn,
} from '../../../../../../../components/V2/Metrics/Metrics';
import { METRIC_KEY_REMAINING_BUDGET } from './constants';

export function makeColumn(title, accessor) {
  return ({
    id: accessor,
    Header: title,
    accessor: (c) => (c.raw ? c.raw(accessor) : c),
  });
}

function renderRemainingBudgetValue(budget) {
  const value = currencyWithNegativeFn(budget);
  if (isNumber(budget) && budget < 0) {
    return <span className="negative_number">{value}</span>;
  }
  return value;
}

export function renderMetricColumns(metrics, dimension) {
  return metrics.map((metric) => ({
    // `accessor` field only exists in ID/Daily Spend/Daily Budget/Remaining Budget/Date Created column
    // use `accessor` if it exists, otherwise use `key`.
    ...makeColumn(metric.name, metric.accessor || metric.key),
    defaultSortDesc: true,
    Header: metric.isDimensionKey ? metric.name
      : (
        <Tooltip className="v2_reporting_page_tooltip" title={metric.name} body={metric.content}>
          {metric.name}
        </Tooltip>
      ),
    Cell: function Cell({ original }) {
      let renderData = original.metrics ? original.metrics[metric.key] : original[metric.key];
      if (metric.isDimensionKey) {
        if (original.total) {
          if (metric.isBudgetKey) {
            const value = original[metric.accessor];
            const title = metric.key === METRIC_KEY_REMAINING_BUDGET ? renderRemainingBudgetValue(value) : currencyFn(value);
            return (
              <TooltipCutoff
                placement="bottomLeft"
                title={title}
              />
            );
          }
          return '';
        }
        renderData = original;
      }
      if (metric.showTooltipCutoff) {
        return (
          <TooltipCutoff
            placement="bottomLeft"
            title={metric.render(renderData, dimension)}
          />
        );
      }
      return metric.render(renderData, dimension);
    },
  }));
}

export const renderDateCreated = (item, dimension) => moment(item[dimension].created).format('DD MMM YYYY');

export const getDefaultMetrics = (attributesMetricsList) => [
  ...attributesMetricsList.slice(0, attributesMetricsList.length - 1),
  ...standardMetricsList.map((e) => ({ ...e, showTooltipCutoff: true })),
  attributesMetricsList[attributesMetricsList.length - 1],
];

export const getMetricsList = (metricsData, attributesMetricsList) => metricsData.map(
  (i) => ([
    ...attributesMetricsList,
    ...getMetricsListByTab(null, false).map((e) => ({ ...e, showTooltipCutoff: true })),
  ]).find((metric) => metric.key === i.key),
);
