import { last, xor, uniqBy } from 'lodash';

const KEY_BANNER_IMAGE_8_1 = 'BANNER_IMAGE_8_1';
const KEY_BANNER_IMAGE_6_1 = 'BANNER_IMAGE_6_1';
const PRODUCT_DESCRIPTION = 'Product Description';
const VIDEO_REPLACEMENT_KEYS = ['MAIN_VIDEO'];

const creativeTypeNames = {
  banner: 'Banner',
  fullscreen: 'Full-Screen',
  mrec: 'MREC',
  native: 'Native',
};

const isEndCard = (replacement) => (
  replacement.type === 'BUNDLE'
);

const getEditUrl = (creative) => `/creatives/${creative.get('id')}/campaigns`;

const isBannerImage = (replacement) => (
  replacement.key === KEY_BANNER_IMAGE_8_1 || replacement.key === KEY_BANNER_IMAGE_6_1
);

const isCarousel = (imageReplacements) => imageReplacements.length >= 5;

const getCreativeTypeTargeting = (allowList) => (
  Object.keys((allowList || {}))
    .filter((pt) => (
      !!(allowList || {})[pt]
    ))
    .map((pt) => creativeTypeNames[pt])
    .join(', ')
);

/**
 * This is used to get replacements for Self-Serve UI.
 * @param {Array} replacements
 */
const getSSImageReplacements = (replacements) => (
  replacements
    .filter((replacement) => (
      replacement.type === 'IMAGE'
      && replacement.group === 'Product Description'
      && !['APP_ICON', 'APP_RATING', 'POWERED_BY_VUNGLE'].includes(replacement.key)
    ))
);

const filterCallback = ({ role: { valueRequired }, value }) => !valueRequired || !!value;

const validateImages = (replacements) => {
  const imageReplacements = getSSImageReplacements(replacements);
  const imageTypes = imageReplacements.map((image) => image.key);
  // Image Banner is an exception which only one of the two image replacements is required
  const isImageBanner = imageTypes.length > 0 && imageTypes
    .every((imageType) => ['BANNER_IMAGE_6_1', 'BANNER_IMAGE_8_1'].includes(imageType));

  return isImageBanner
    ? imageReplacements.some(filterCallback)
    : imageReplacements.every(filterCallback);
};

const getAssetName = (type, replacementKey) => {
  let reminderType = '';
  if (type === 'image') {
    reminderType = 'Images';
  } else if (type === 'video') {
    reminderType = 'Videos';
  } else if (type === 'banner') {
    if (replacementKey === 'BANNER_IMAGE_6_1') {
      reminderType = 'Images (Banner 6:1)';
    }
    if (replacementKey === 'BANNER_IMAGE_8_1') {
      reminderType = 'Images (Banner 8:1)';
    }
  } else if (type === 'bundle_adaptive_creative') {
    reminderType = 'Playable (.zip format) assets';
  }
  return reminderType;
};

const isImageBanner = (imageReplacements) => (
  imageReplacements.some(isBannerImage)
);

/*
* Determine the corresponding creative formats according to the given templates.
**/
const categoryOrder = {
  'Full Screen (Multi Page)': 0,
  'Full Screen (Single Page)': 1,
  MREC: 2,
  Banner: 3,
  Native: 4,
};
const getCreativeFormats = (templates) => {
  const result = [];
  const generateTemplate = (t) => ({
    name: t.name,
    id: t.name,
    creativeFormat: t.creativeFormat,
  });
  uniqBy(templates, 'name').forEach((t) => {
    const { creativeFormat } = t;
    if (!creativeFormat) {
      return;
    }
    const templateWithSameFormat = result.find((p) => p.name === t.creativeFormat);
    if (templateWithSameFormat) {
      templateWithSameFormat.children.push(generateTemplate(t));
    } else {
      result.push({
        name: creativeFormat,
        id: creativeFormat,
        children: [],
      });
      const lastResultElement = last(result);
      lastResultElement.children.push(generateTemplate(t));
    }
  });

  const sortedCategories = [];
  result.forEach((c) => {
    sortedCategories[categoryOrder[c.name]] = c;
  });

  return sortedCategories;
};

const isVideoRequiredInReplacements = (replacements) => replacements.some(({ group, key, role }) => (
  group === PRODUCT_DESCRIPTION
  && VIDEO_REPLACEMENT_KEYS.indexOf(key) !== -1
  && role.valueRequired
));

const diffCreatives = (newCreatives, oldCreatives) => {
  if ((oldCreatives?.length || 0) !== (newCreatives?.length || 0)) {
    return true;
  }

  const oldCreativeIds = oldCreatives?.map((c) => c.id);
  const newCreativesIds = newCreatives?.map((c) => c.id);

  return xor(oldCreativeIds, newCreativesIds).length > 0;
};

export {
  PRODUCT_DESCRIPTION,
  VIDEO_REPLACEMENT_KEYS,
  isEndCard,
  isBannerImage,
  isCarousel,
  isImageBanner,
  creativeTypeNames,
  getSSImageReplacements,
  getCreativeTypeTargeting,
  validateImages,
  getAssetName,
  getEditUrl,
  isVideoRequiredInReplacements,
  getCreativeFormats,
  diffCreatives,
};
