import React from 'react';
import classNames from 'classnames';
import './ProgressBar.scss';

const ProgressBar = ({ percentage, hasError = false }) => (
  <div
    className={classNames({
      primary: true,
      complete: percentage === 100,
      'vdc-progress': true,
      'has-error': hasError,
    })}
    role="progressbar"
  >
    <span className="progress-value" style={{ width: `${percentage}%` }} />
  </div>
);

export default ProgressBar;
