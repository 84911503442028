export const FILTER_STATUS_ACTIVE = 'active';
export const FILTER_STATUS_PAUSED = 'paused';
export const FILTER_STATUS_PENDING = 'pending';
export const FILTER_STATUS_REJECTED = 'rejected';

// keys
export const FILTER_KEY_DATE_RANGE = 'dateRange';
export const FILTER_KEY_PLATFORM = 'platform';
export const FILTER_KEY_STATUS = 'status';
export const FILTER_KEY_CAMPAIGN_STATUS = 'campaignStatus';
export const FILTER_KEY_APPLICATION_STATUS = 'adminStatus';
export const FILTER_KEY_MEMBER_STATUS = 'memberStatus';
export const FILTER_KEY_LANGUAGE = 'languages';
export const FILTER_KEY_CREATIVE_TYPE = 'creativeType';
export const FILTER_KEY_AD_TYPE = 'adType';
export const FILTER_KEY_APPLICATION = 'application';
export const FILTER_KEY_CAMPAIGN = 'campaign';
export const FILTER_KEY_COUNTRY = 'country';
export const FILTER_KEY_SEARCH = 'search';
export const FILTER_KEY_CREATIVEQA_STATUS = 'creativeQAStatus';
export const FILTER_KEY_CREATIVE = 'creative';
export const FILTER_KEY_PUBLISHER = 'publisher';
export const FILTER_KEY_CAMPAIGN_SKADNETWORK = 'is_skadnetwork_enabled';
export const FILTER_KEY_ASSET_ORIENTATION = 'asset_orientation';
export const FILTER_KEY_ASSET_TYPE = 'assetType';
export const FILTER_KEY_ASSET_STATUS = 'assetStatus';
export const FILTER_KEY_CREATIVE_FORMAT = 'adFormat';
export const FILTER_KEY_CREATIVE_LIST_FORMAT = 'creativeListFormat';
export const FILTER_KEY_BID_TYPE = 'campaignTypes';
export const FILTER_KEY_DELETION_STATUS = 'deletionStatus';
export const FILTER_KEY_NETWORK_TYPE = 'adTypes';
export const FILTER_KEY_TEMPLATE_STATUS = 'templateStatus';
export const FILTER_KEY_TEMPLATE_FORMAT = 'format';
export const FILTER_KEY_TEMPLATE_PROTOCOL = 'templateProtocol';
export const FILTER_KEY_ASSET = 'asset';
export const FILTER_KEY_SKOVERLAY_AUTO_SHOW = 'skOverlayAutoShow';
export const FILTER_KEY_VIDEO_OVERLAY_AUTO_SHOW = 'videoOverlayAutoShow';
export const FILTER_KEY_CAMPAIGN_BUDGET_OBJECTIVES = 'budgetObjectives';

export const FILTER_KEY_DATE = 'date';
export const FILTER_KEY_COMBINE = 'combine';
