import React, {
  useState, useEffect, useCallback, useImperativeHandle, forwardRef, useRef,
} from 'react';
import classnames from 'classnames';
import useDebounce from '../../../../lib/hooks/useDebounce';
import Chart from '../../../../components/V2/Report/Chart/Chart';
import CompareFilter from '../../../../components/V2/Report/Filters/CompareFilter/CompareFilter';
import { DropdownMenu, Filter, Tooltip } from '../../../../components/V2';
import { getTrendData } from '../../../../app/graphql/utils/reporting';
import Loading from '../../../../components/V2/Report/Loading/Loading';
import Aggregates from '../Aggregates/Aggregates';
import {
  COMPARISON_LIMIT_BODY, CHART_VIEW_BY_KEY_DAY, CHART_VIEW_BY_OPTS,
} from '../constant';
import { combineRequestBody, combineChartData } from '../helper';
import './trend.scss';

const classPrefix = 'v2_pages_reports_trend';

function initViewBy(viewKey) {
  return CHART_VIEW_BY_OPTS.find(({ key }) => viewKey === key);
}

const initialState = {
  chartSeries: [],
  checkedMetric: {},
  chartResponse: null,
  checkedViewBy: initViewBy(CHART_VIEW_BY_KEY_DAY),
};

const Trend = ({
  account,
  className,
  compareFilters,
  checkedDate,
  checkedCombine,
  checkedCompare,
  metricOptionGroup,
  panelMetricsKey,
  requestParams = {},
  searchParams = {},
  onCompareChange,
  onCompareClear,
  dimension,
  currentTab,
}, ref) => {
  const filterRef = useRef();
  const [
    {
      chartSeries,
      chartResponse,
      checkedMetric,
      checkedViewBy,
    },
    setState,
  ] = useState(initialState);
  const [viewByOpen, setViewByOpen] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [metricsVisible, setMetricsVisible] = useState(false);

  const onPanelClick = useCallback((metric) => {
    setState((prevState) => ({
      ...prevState,
      checkedMetric: metric,
    }));
  }, []);

  const onLoadChartData = useCallback(async (query) => {
    const body = combineRequestBody({
      checkedDate,
      checkedCompare,
      checkedCombine,
      ...requestParams,
      ...query,
    });
    if (dimension) {
      body.collection = dimension;
    }
    if (account) {
      body.accounts = [account];
    }
    try {
      setDataLoading(true);
      const response = await getTrendData(body, dimension);
      setState((prevState) => ({
        ...prevState,
        chartResponse: response,
      }));
      setDataLoading(false);
    } catch (error) {
      setDataLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, checkedDate, checkedCombine, checkedCompare]);

  useEffect(() => {
    if (chartResponse) {
      const newSeries = combineChartData(chartResponse, checkedCompare, checkedMetric, checkedViewBy);
      setState((prevState) => ({
        ...prevState,
        chartSeries: metricsVisible ? newSeries : [],
      }));
    }
  }, [chartResponse, checkedCompare, checkedMetric, checkedViewBy, metricsVisible]);

  useDebounce(() => {
    onLoadChartData();
  }, 50, [onLoadChartData]);

  useImperativeHandle(ref, () => ({
    onLoadChartData: () => onLoadChartData(),
  }));

  const onComparedChange = useCallback((key, data) => {
    onCompareChange(key, data);
  }, [onCompareChange]);

  return (
    <div className={classnames(classPrefix, className)}>
      <div className="aggregates">
        <Aggregates
          total={chartResponse?.total || {}}
          checkedMetric={checkedMetric.key}
          metricOptionGroup={metricOptionGroup}
          panelMetricsKey={panelMetricsKey}
          onMetricsVisible={setMetricsVisible}
          onPanelClick={onPanelClick}
          currentTab={currentTab}
        />
      </div>
      <div className="chart">
        <div className="filters">
          <CompareFilter
            filters={compareFilters}
            checkedCombine={checkedCombine}
            defaultCheckedFilter={checkedCompare}
            searchParams={searchParams}
            onChange={onComparedChange}
            onRemove={onCompareClear}
          />
          <Tooltip className="v2_reporting_page_tooltip" title="Comparison Limit" body={COMPARISON_LIMIT_BODY}>
            <i className="material-icons">help_outline</i>
          </Tooltip>
          <DropdownMenu
            className={`${classPrefix}-dropdown`}
            style={{ width: filterRef.current ? `${filterRef.current.getBoundingClientRect().width}px` : 'auto' }}
            labelKey="name"
            horizontalOffset={0}
            data={CHART_VIEW_BY_OPTS}
            activeIndex={CHART_VIEW_BY_OPTS.findIndex(({ name }) => checkedViewBy.name === name)}
            onVisibleChange={setViewByOpen}
            onChange={(value) => (
              setState((prevState) => ({
                ...prevState,
                checkedViewBy: value,
              }))
            )}
          >
            <Filter type="date" open={viewByOpen} filterRef={filterRef}>
              {`View By: ${checkedViewBy.name}`}
            </Filter>
          </DropdownMenu>
        </div>
        <Chart viewBy={checkedViewBy.key} series={chartSeries} metric={checkedMetric} />
      </div>
      {dataLoading && <Loading content="Just a second, loading data..." />}
    </div>
  );
};

export default forwardRef(Trend);
