import React from 'react';
import cn from 'classnames';
import Option from './Option';
import './radio.scss';

const Radio = ({
  children, name, value = '', disabled = false, onChange = () => {}, stateStyle = 'vertical',
}) => {
  const formRadioClass = cn({
    component__form__radio: true,
    vertical: stateStyle === 'vertical',
    horizontal: stateStyle === 'horizontal',
    disabled,
  });
  return (
    <div className={formRadioClass}>
      {React.Children.map(children, (child) => (!React.isValidElement(child) ? undefined : React.cloneElement(child, {
        name,
        checkedValue: value,
        onChange: (v) => !disabled && onChange(v),
      })))}
    </div>
  );
};

Radio.Option = Option;

export default Radio;
