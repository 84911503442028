/*
 * Defined by Product here: https://vungle.atlassian.net/browse/DB-7051
 */
export const MaxLimitCpi = 500;
export const MaxLimitCpc = 50;
export const MaxLimitCpcv = 50;
export const MaxLimitCpm = 50;
export const MaxLimitCpv = 50;
export const MaxLimitCppu = 1000;
export const MaxLimitCpe = 1000;
export const MaxLimitTargetCpi = 500;
export const MaxLimitDaily = 1000000;
export const MaxLimitTotal = 100000000;

export const getMaxLimit = (limitType) => {
  if (!limitType) {
    throw new Error('Must provide limitType');
  }

  switch (limitType.toLowerCase()) {
    case 'install':
    case 'cpi':
      return MaxLimitCpi;
    case 'click':
    case 'cpc':
      return MaxLimitCpc;
    case 'complete':
    case 'cpcv':
      return MaxLimitCpcv;
    case 'impression':
    case 'cpm':
      return MaxLimitCpm;
    case 'view':
    case 'cpv':
      return MaxLimitCpv;
    case 'cppu':
      return MaxLimitCppu;
    case 'cpe':
      return MaxLimitCpe;
    case 'target_cpi':
      return MaxLimitTargetCpi;
    case 'daily':
      return MaxLimitDaily;
    case 'total':
      return MaxLimitTotal;
    default:
      throw new Error('Unrecognized limit type');
  }
};
