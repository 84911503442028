/**
 * csv file uploading status
 */
export default {
  // initial status, display file dropzone
  INITIAL: 'INITIAL',
  // file is uploading, display file processing view.
  UPLOADING: 'UPLOADING',
  // file has been uploaded, display apps table and filters view.
  UPLOADED: 'UPLOADED',
  // error hanppens when uploading, should display errors.
  ERROR: 'ERROR',
};
