import React from 'react';
import Datetime from 'react-datetime';
import moment from 'moment-timezone';
import './datepicker.scss';

export const ISO_8601_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';

const isValidDate = (current, after) => moment(current).add(1, 'day').isAfter(moment(after));

const DatePicker = ({
  after,
  err,
  noIcon,
  format = 'MMM Do, YYYY',
  onChange = () => {},
  placeholder = 'Date',
  useTime = true,
  value = '',
  inputRef,
  disabled = false,
}) => (
  <div className={`components__form__input ${err === undefined || err ? '' : 'dateErr'}`}>
    <div className="input datepicker_wrapper">
      {!noIcon && (
        <div className="placeholder">
          <i className="material-icons">date_range</i>
        </div>
      )}
      <Datetime
        closeOnSelect
        isValidDate={(c) => !after || isValidDate(c, after)}
        timeConstraints={{ minutes: { step: 15 } }}
        dateFormat={format}
        timeFormat={useTime && 'hh:mm A'}
        inputProps={{ placeholder, disabled, ref: inputRef }}
        onChange={(v) => {
          if (moment.isMoment(v)) {
            const date = moment(v).tz('UTC').format(ISO_8601_FORMAT);
            onChange(date);
          }
        }}
        value={moment(value)}
      />
    </div>
  </div>
);

export default DatePicker;
