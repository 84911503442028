import React from 'react';

const Content = ({
  render, title, hideModal, link,
}) => (
  <div className="components__modals__content" onClick={hideModal}>
    <div className="content_container views__body_editor" onClick={(e) => e.stopPropagation()}>
      <div className="header">
        <div className="header--left" />
        <div className="header--main">
          <p className="h2">{title}</p>
        </div>
        <div className="header--right">
          { link }
        </div>
      </div>
      <div className="content">
        {render}
      </div>
    </div>
  </div>
);

export default Content;
