import { validTrackingMacros } from 'config/campaign-tracking-providers';
import { isEndFormatValid, startAndEndTest } from '../../../../lib/helpers';
import { DETERMINANT_MACROS_FACTORY, INVALID_URL_ERROR, MISSING_APPLICATION_ERROR } from './constants';

export const isEventValid = ({ url, error }) => !!url && (!error || Object.keys(error).length === 0);

export const allEventsValid = (events) => {
  if (!events) {
    return true;
  }
  return events.every(isEventValid);
};

export const findRequiredMacros = (url, platform) => {
  const { host } = url;

  let requiredMacros = {};
  let key = '';

  Object.keys(DETERMINANT_MACROS_FACTORY).forEach((x) => {
    if (host.endsWith(x)) {
      key = x;
    }
  });

  if (key) {
    const that = DETERMINANT_MACROS_FACTORY[key];
    if (that.validator(url)) {
      requiredMacros = that[platform];
    }
  }

  return requiredMacros;
};

export const validateURL = (urlString, platform) => {
  const url = new URL(urlString);
  const { searchParams } = url;

  const params = [];
  searchParams.forEach((value, key) => {
    params.push({
      [key]: value,
    });
  });

  const error = {};
  const invalidMacros = [];
  const missedRequiredMacros = [];
  const requiredMacros = findRequiredMacros(url, platform);

  Object.entries(requiredMacros)
    .forEach(([key, value]) => {
      let requiredValid = false;
      params.forEach((p) => {
        const [k, v] = Object.entries(p)[0];
        // macro format should strictly match `{{{value}}}`.
        if (isEndFormatValid(v)) {
          // get inner content of macro, for example if macro is `{{{uppercase id}}}`/`{{{id}}}`, this will get `uppercase id`/`id`
          // because will need to validate `uppercase` and `id` both
          const splits = v.replace(/^{{{/g, '')
            .replace(/}}}$/g, '')
            .split(' ');
          if (splits.length === 2) {
            if (k === key && validTrackingMacros.helpers[splits[0]] && splits[1] === value) {
              requiredValid = true;
            }
          } else if (splits.length === 1) {
            if (k === key && splits[0] === value) {
              requiredValid = true;
            }
          }
        }
      });
      if (!requiredValid) {
        missedRequiredMacros.push(`${key}={{{${value}}}}`);
      }
    });
  if (missedRequiredMacros.length) {
    error.missedRequiredMacros = missedRequiredMacros;
  }
  params.forEach((param) => {
    Object.entries(param)
      .forEach(([key, value]) => {
        if (!startAndEndTest(key, value)) {
          invalidMacros.push(value);
        }
      });
  });
  if (invalidMacros.length) {
    error.invalidMacros = invalidMacros;
  }

  return error;
};

export const validateMacroURL = (urlString, platform) => {
  if (!urlString) {
    return {};
  }
  if (!platform) {
    return MISSING_APPLICATION_ERROR;
  }
  const urls = urlString.split(',');
  try {
    const error = {};
    const invalidMacros = [];
    const missedRequiredMacros = [];
    urls.forEach((url) => {
      const e = validateURL(url, platform);
      if (Array.isArray(e.invalidMacros)) {
        invalidMacros.push(...e.invalidMacros);
      }
      if (Array.isArray(e.missedRequiredMacros)) {
        missedRequiredMacros.push(...e.missedRequiredMacros);
      }
    });
    if (invalidMacros.length) {
      error.invalidMacros = invalidMacros;
    }
    if (missedRequiredMacros.length) {
      error.missedRequiredMacros = missedRequiredMacros;
    }
    return error;
  } catch (e) {
    return INVALID_URL_ERROR;
  }
};
