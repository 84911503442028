import {
  toLower, map, isNumber, isArray,
} from 'lodash';
import { isMongoID } from 'services/admin/views/assets/list_2/utils';
import handleGraphQLError from './handle-graphql-error';
import getSkadnetworkPostbackName from './get-skadnetwork-postback-name';
import { getClient } from '../setup';
import {
  GetApplication,
  GetApplicationForReporting,
  GetApplications,
  GetPubApps,
  GetPubAppsWithoutAccount,
  ModifyApplication,
  CreateApplication,
  ApproveApplication,
  RejectApplication,
  RefreshApplication,
  GetReportingPubApps,
  GetApplicationsWithCampaign,
  GetApplicationsWithCreative,
  GetPostInstallEvents,
  GetPostInstallEventCountryStats,
} from '../operations_application.graphql';
import { getGraphQLPlatform } from './get-graphql-platform';
import { getServiceType, isAdminService } from '../../../lib/serviceType';
import { getSearchAndIDsFilter } from './helper';

function isSKAdNetworkCapable(platform) {
  return (!!platform && platform.toLowerCase() === 'ios');
}

function getLogging(application) {
  if (application.logInstalls) {
    return 'installs';
  }
  if (application.logRequests) {
    return 'requests';
  }
  return undefined;
}

function formatSurgeMultiplierForClient(v) {
  return (isNumber(v) && Number(v) >= 100 ? (v - 100).toFixed(2) : 0);
}

function formatSurgeMultiplierForDb(v) {
  const vStr = String(v);
  return Number((parseFloat(vStr) + 100).toFixed(2));
}

function formatSkAdNetworkMappings(skAdNetworkMappings) {
  return map(skAdNetworkMappings, (mapping) => {
    const result = {
      model_id: mapping.modelID,
      sk_campaign_id: mapping.skCampaignID,
      version: mapping.version,
      campaign: mapping.campaign,
      creative: {
        status: '',
      },
    };
    if (mapping.creative) {
      result.creative = {
        ...mapping.creative,
        admin_status: mapping.creative.adminStatus,
        name: mapping.creative.vungleName,
      };
    }
    return result;
  });
}

export function convertToRestFormat(application) {
  if (!application) {
    return undefined;
  }

  const store = application.store || {};
  const skAdNetworkSettings = application.skAdNetworkSettings || {};
  const { account, skAdNetworkMappings } = application;
  const { targeting } = application;

  const result = {
    id: application.id,
    created: application.dateCreated,
    updated: application.dateModified,
    is_deleted: application.isDeleted,
    admin_status: application.adminStatus,
    campaign_count: application.campaignCount,
    creative_count: application.creativeCount,
    name: application.name,
    category: application.category,
    logging: getLogging(application),
    platform: toLower(application.platform),
    tags: map(application.tags, (t) => ({ value: t })),
    targeting: targeting ? {
      tags: {
        type: targeting.tags.type,
        tags: map(targeting.tags.tags, (t) => ({ value: t })),
      },
      publisher: {
        type: targeting.publisher.type,
        applications: targeting.publisher.applications || [],
      },
    } : undefined,
    account: account ? {
      id: account.id,
      name: account.name,
      accounts: account.denyAccount,
      creativeMaximum: account.creativeMaximum,
      applications: account.denyApplication,
    } : {},
    settings: {
      click_look_back_period: {
        value: application.clickLookBackPeriod,
      },
      view_look_back_period: {
        value: application.viewLookBackPeriod,
      },
    },
    screenshots: application.screenshots,
    store: {
      id: store.id,
      company: store.company,
      rating: store.rating,
      thumbnail: store.thumbnail,
      url: store.url,
      iab: store.iab,
      content_tag: store.contentTag,
      original_category: store.originalCategory,
      original_name: store.originalName,
      is_free: store.isFree,
      marketplace: store.marketplace,
      orientation: store.orientation,
      is_coppa_restricted: store.isCoppaRestricted,
      is_coppa_restricted_override: store.isCoppaRestrictedOverride,
      description: store.description,
      short_description: store.shortDescription,
      rating_count: store.ratingCount,
      download_count: store.downloadCount,
    },
    surge_override_settings: {
      is_enabled: application.isSurgeOverrideEnabled,
      value: formatSurgeMultiplierForClient(application.surgeOverride),
    },
    skadnetwork_settings: {
      is_capable: isSKAdNetworkCapable(application.platform),
      is_enabled: skAdNetworkSettings.isEnabled,
      is_changeable: skAdNetworkSettings.isEnabled !== true,
      is_redownload_billed: skAdNetworkSettings.isRedownloadBilled,
      skadnetwork_postback_destination: skAdNetworkSettings.skAdNetworkPostBackDestination,
      usage: skAdNetworkSettings.usage,
    },
    skAdNetworkMappings: formatSkAdNetworkMappings(skAdNetworkMappings),
    campaigns: application.campaigns,
    creatives: application.creatives,
    mmpIntegrationStatus: application.mmpIntegrationStatus,
    appsFlyerEnrichedEngagements: application.appsFlyerEnrichedEngagements,
    // legacy fields
    ok: true,
  };
  return result;
}

export function handleApplicationGraphQLResponse({
  data: {
    applications,
  } = {},
}) {
  const result = {
    response: convertToRestFormat(applications.applications[0]),
  };
  return result;
}

export function handleModifyApplicationGraphQLResponse({
  data: {
    modifyApplication: modifyApplicationResponse,
  } = {},
}) {
  return convertToRestFormat(modifyApplicationResponse);
}

export function handleCreateApplicationGraphQLResponse({
  data: {
    createApplication: createApplicationResponse,
  } = {},
}) {
  return convertToRestFormat(createApplicationResponse);
}

export function handleRefreshApplicationGraphQLResponse({
  data: {
    refreshApplication: refreshApplicationResponse,
  } = {},
}) {
  const result = {
    ok: true,
    status: 200,
    response: convertToRestFormat(refreshApplicationResponse),
  };
  return result;
}

export function toGraphQLApplicationRequest(application, isCreate) {
  const tagValues = (xs) => map(xs, (x) => x.value);
  const {
    name,
    admin_status,
    account,
    category,
    store,
    tags,
    targeting,
    logging,
    platform,
    skadnetwork_settings: {
      is_enabled,
      is_redownload_billed,
      skadnetwork_postback_destination,
    },
    surge_override_settings,
    settings: {
      click_look_back_period,
      view_look_back_period,
    },
  } = application;

  const result = {
    name,
    accountID: account ? account.id : undefined,
    category,
    store: {
      ...store,
      orientation: store.orientation,
    },
    logInstalls: logging === 'installs',
    logRequests: logging === 'requests',
    platform: getGraphQLPlatform(platform),
    tags: tagValues(tags),
    targeting: {
      tags: {
        type: targeting.tags.type,
        tags: tagValues(targeting.tags.tags),
      },
      publisher: {
        type: targeting.publisher.type,
        applicationIDs: map(targeting.publisher.applications, (app) => app.id),
      },
    },
    skAdNetworkSettings: {
      isEnabled: is_enabled,
      isRedownloadBilled: is_redownload_billed,
      skAdNetworkPostBackName: getSkadnetworkPostbackName(skadnetwork_postback_destination),
    },
    clickLookBackPeriod: click_look_back_period.value,
    viewLookBackPeriod: view_look_back_period.value,
    surgeOverride: formatSurgeMultiplierForDb(surge_override_settings.value),
    isSurgeOverrideEnabled: surge_override_settings.is_enabled,
    appsFlyerEnrichedEngagements: application.appsFlyerEnrichedEngagements,
  };

  if (!isCreate) {
    // backend ignores adminStatus in create application request, so the status is set according to backend logic and not the value of this field.
    // eslint-disable-next-line camelcase
    result.adminStatus = admin_status;
  }
  return result;
}

export function handleApplicationsGraphQLResponse({
  data: {
    applications,
    applicationsWithResource,
  } = {},
}) {
  const resp = applications || applicationsWithResource;
  const result = {
    ok: true,
    status: 200,
    response: resp.applications.map(convertToRestFormat),
    pagination: resp.pagination,
  };
  return result;
}

export function handlePubAppsGraphQLResponse({
  data: {
    pubApps,
  } = {},
}) {
  const result = {
    ok: true,
    status: 200,
    response: pubApps.applications.map(convertToRestFormat),
    pagination: pubApps.pagination,
  };
  return result;
}

export function getApplication(id) {
  return getClient()
    .query({
      query: GetApplication,
      variables: {
        id,
      },
    })
    .then(handleApplicationGraphQLResponse)
    .catch(handleGraphQLError);
}

export function getApplicationForReporting(id) {
  return getClient()
    .query({
      query: GetApplicationForReporting,
      variables: {
        id,
      },
    })
    .then(handleApplicationGraphQLResponse)
    .catch(handleGraphQLError);
}

function getStatusObj(status) {
  if (status === 'approved' || status === 'pending' || status === 'rejected') {
    return { adminStatus: [status] };
  } if (status) {
    return { status };
  }
  return {};
}

function getIds(idIn) {
  if (!idIn) {
    return undefined;
  }
  if (isArray(idIn)) {
    return idIn;
  }
  return [idIn];
}

function getSearchFilter(filters) {
  const { type } = filters;

  if (!filters.search) {
    return { ...filters, status: undefined };
  }

  if (isMongoID(filters.search)) {
    return {
      ids: [filters.search],
      type,
    };
  }
  return {
    search: filters.search,
    type,
  };
}

function getPlatformGraphqlVariable(filters) {
  if (filters.platform) {
    return getGraphQLPlatform(filters.platform).split(',');
  }
  return undefined;
}

function getAdminStatus(adminStatus) {
  if (!adminStatus) {
    return {};
  }

  if (isArray(adminStatus) && adminStatus.length) {
    return { adminStatus };
  }
  return { adminStatus: adminStatus.split(',') };
}

export function getApplications(filters = {}) {
  const variables = {
    ids: getIds(filters.idIn),
    page: Number(filters.page),
    per_page: Number(filters.perPage),
    accountID: filters.account,
    ...getSearchFilter(filters),
    ...getStatusObj(filters.status),
    ...getAdminStatus(filters.adminStatus),
  };

  variables.platform = getPlatformGraphqlVariable(filters);

  return getClient()
    .query({
      query: GetApplications,
      variables,
    })
    .then(handleApplicationsGraphQLResponse)
    .catch(handleGraphQLError);
}

function getPubAppQuery() {
  if (isAdminService()) {
    return GetPubApps;
  }
  return GetPubAppsWithoutAccount;
}

export function getPubApps(filters = {}) {
  const variables = {
    ids: getIds(filters.idIn),
    page: filters.page,
    per_page: filters.perPage,
    accountID: filters.account,
    ...getSearchFilter(filters),
    ...getStatusObj(filters.status),
    type: ['publisher', 'both'],
  };

  variables.platform = getPlatformGraphqlVariable(filters);

  return getClient()
    .query({
      query: getPubAppQuery(),
      variables,
    })
    .then(handlePubAppsGraphQLResponse)
    .catch(handleGraphQLError);
}

export function createApplication(applicationServerObject) {
  return getClient()
    .mutate({
      mutation: CreateApplication,
      variables: {
        input: {
          ...toGraphQLApplicationRequest(applicationServerObject, true),
          client: getServiceType(),
        },
      },
    })
    .then(handleCreateApplicationGraphQLResponse)
    .catch(handleGraphQLError);
}

export function refreshApplication(id, applicationServerObject) {
  const graphqlInput = toGraphQLApplicationRequest(applicationServerObject, false);
  return getClient()
    .mutate({
      mutation: RefreshApplication,
      variables: {
        id,
        input: {
          ...graphqlInput,
          adminStatus: undefined,
          client: getServiceType(),
        },
      },
    })
    .then(handleRefreshApplicationGraphQLResponse)
    .catch(handleGraphQLError);
}

export function modifyApplication(id, applicationServerObject) {
  return getClient()
    .mutate({
      mutation: ModifyApplication,
      variables: {
        id,
        input: {
          ...toGraphQLApplicationRequest(applicationServerObject, false),
          client: getServiceType(),
        },
      },
    })
    .then(handleModifyApplicationGraphQLResponse)
    .catch(handleGraphQLError);
}

export function changeStatus(id, status) {
  return getClient()
    .mutate({
      mutation: status === 'approve' ? ApproveApplication : RejectApplication,
      variables: {
        id,
      },
    })
    .then(handleModifyApplicationGraphQLResponse)
    .catch(handleGraphQLError);
}

function handleReportingPubAppsResponse({
  data: {
    reportingPubApps,
  } = {},
}) {
  return {
    ok: true,
    status: 200,
    response: reportingPubApps.applications.map(convertToRestFormat),
    pagination: reportingPubApps.pagination,
  };
}

export const getReportingPubApps = (query = {}) => getClient()
  .query({
    query: GetReportingPubApps,
    variables: {
      ...getSearchAndIDsFilter(query),
      page: Number(query.page || 1),
      perPage: Number(query.perPage || 10),
      account: query.account,
    },
  })
  .then(handleReportingPubAppsResponse)
  .catch(handleGraphQLError);

export function getApplicationsWithCampaign(filters = {}) {
  const variables = {
    ids: getIds(filters.application),
    page: Number(filters.page),
    per_page: Number(filters.perPage),
    accountID: filters.account,
  };

  return getClient()
    .query({
      query: GetApplicationsWithCampaign,
      variables,
    })
    .then(handleApplicationsGraphQLResponse)
    .catch(handleGraphQLError);
}

export function getApplicationsWithCreative(filters = {}) {
  const variables = {
    ids: getIds(filters.application),
    page: Number(filters.page),
    per_page: Number(filters.perPage),
    accountID: filters.account,
  };

  return getClient()
    .query({
      query: GetApplicationsWithCreative,
      variables,
    })
    .then(handleApplicationsGraphQLResponse)
    .catch(handleGraphQLError);
}

export function getPostInstallEvents(filters) {
  return getClient()
    .query({
      query: GetPostInstallEvents,
      variables: filters,
    })
    .then((res) => res.data.postInstallEvents)
    .catch(handleGraphQLError);
}

export function getPostInstallEventCountryStats(filters) {
  return getClient()
    .query({
      query: GetPostInstallEventCountryStats,
      variables: filters,
    })
    .then((res) => res.data.postInstallEventCountryStats)
    .catch(handleGraphQLError);
}
