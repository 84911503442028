const sortByName = (a, b) => (a.name < b.name ? -1 : 1);
const flattenMultiTreeData = (data) => data.reduce((prev, next) => {
  if (next.children && next.children.length) {
    return prev.concat(flattenMultiTreeData(next.children));
  }
  return prev.concat(next);
}, []);

export {
  sortByName,
  flattenMultiTreeData,
};
