import React from 'react';
import Multi from './MultiCheckbox';
import './multi.scss';

class MultiCheckboxContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showOptions: false,
    };
  }

  countCheckedChildren = () => {
    let checkedCount = 0;
    if (this.props.children) {
      React.Children.forEach(this.props.children, (child) => {
        if (child.props.checked) {
          checkedCount += 1;
        }
      });
    }
    return checkedCount;
  }

  massChange = () => {
    const value = this.countCheckedChildren() === 0;
    React.Children.forEach(this.props.children, (child) => {
      child.props.onChange(value);
    });
  }

  render() {
    const checkedCount = this.countCheckedChildren();
    return (
      <Multi
        {...this.props}
        onChange={this.props.onChange || this.massChange}
        checked={checkedCount > 0}
        className="config"
        some={checkedCount > 0 && checkedCount < this.props.children.length}
        toggleOptions={() => this.setState((prevState) => ({ showOptions: !prevState.showOptions }))}
        showOptions={this.state.showOptions}
      />
    );
  }
}

MultiCheckboxContainer.defaultProps = {
  onChange: false,
};

export default MultiCheckboxContainer;
