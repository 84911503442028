import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import useDebounce from '../../../lib/hooks/useDebounce';
import { componentHasChildByKey, getComponentSize } from '../../../lib/component';
import Tooltip from './Tooltip';
import './TooltipCutoff.scss';

/**
 * get parent element by className
 * @param e
 * @param resizeClassName
 * @returns {(() => (Node | null))|ActiveX.IXMLDOMNode|(Node & ParentNode)|null}
 */
function getResizeElement(e, resizeClassName) {
  const parent = e.parentNode;
  if (!parent || !parent.classList) {
    return null;
  }
  if (parent.classList?.contains(resizeClassName)) {
    return parent;
  }
  return getResizeElement(parent, resizeClassName);
}

const TooltipCutoff = ({
  title,
  resizeClassName,
  titleWrapper,
  overlayClassName,
  calculatedStyles,
}) => {
  const targetRef = useRef({});

  const [ellipsis, setEllipsis] = useState(false);
  const [needNoTooltip, setNeedNoTooltip] = useState(false);
  const [fullWidth, setFullWidth] = useState(0);

  useEffect(() => {
    const el = getResizeElement(targetRef.current || {}, resizeClassName);

    setEllipsis(fullWidth > targetRef.current?.offsetWidth);

    const transitionendHandler = () => {
      setEllipsis(fullWidth > targetRef.current?.offsetWidth);
    };

    if (el) {
      el.addEventListener('transitionend', transitionendHandler);
    }

    return () => {
      if (el) {
        el.removeEventListener('transitionend', transitionendHandler);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullWidth]);

  useDebounce(() => {
    // Ignore some children that need no tooltip, and use debounce to make good performance.
    const isNoTooltip = componentHasChildByKey(title, 'noWrapInTooltip', [true]);
    if (isNoTooltip) {
      setNeedNoTooltip(isNoTooltip);
    } else {
      // Should get original width of the component if need tooltip.
      getComponentSize(title, calculatedStyles).then(({ width }) => setFullWidth(width));
    }
  });

  if (needNoTooltip) {
    return titleWrapper(title);
  }

  const isLink = titleWrapper()?.type;
  const titleElement = (
    <div ref={targetRef} className={cn('text-ellipsis', { 'link-text': isLink, pointer: isLink })}>
      {titleWrapper(title)}
    </div>
  );

  if (ellipsis) {
    return (
      <Tooltip body={title} placement="bottomLeft" overlayClassName={overlayClassName}>
        {titleElement}
      </Tooltip>
    );
  }

  return titleElement;
};

TooltipCutoff.propTypes = {
  /**
   * resize className
   */
  resizeClassName: PropTypes.string,
  /**
   * wrapper of title
   */
  titleWrapper: PropTypes.func,
  /**
   * please set calculatedStyles if the rendered element size is affected by css
   * For example: fontSize, transform, letterSpacing, etc.
   */
  calculatedStyles: PropTypes.shape(React.CSSProperties),
};

TooltipCutoff.defaultProps = {
  resizeClassName: 'rt-td',
  titleWrapper: (title) => title,
  calculatedStyles: {},
};

TooltipCutoff.noWrapInTooltip = true;

export default TooltipCutoff;
