// our most commonly seen (10k+) world cities
const cities = [
  // Argentina
  { name: 'Buenos Aires', region: '07', country: 'AR' },
  { name: 'Cordoba', region: '05', country: 'AR' },
  { name: 'Córdoba', region: '05', country: 'AR' },
  { name: 'La Plata', region: '01', country: 'AR' },
  { name: 'Mar Del Plata', region: '01', country: 'AR' },
  { name: 'Rosario', region: '21', country: 'AR' },
  { name: 'Salta', region: '17', country: 'AR' },
  { name: 'San Miguel De Tucuman', region: '24', country: 'AR' },
  { name: 'Tucuman', region: '24', country: 'AR' },
  // Australia
  { name: 'Brisbane', region: '04', country: 'AU' },
  { name: 'Melbourne', region: '07', country: 'AU' },
  { name: 'Perth', region: '08', country: 'AU' },
  { name: 'Sydney', region: '02', country: 'AU' },
  { name: 'Australian Capital Territory', region: '01', country: 'AU' },
  { name: 'New South Wales State', region: '02', country: 'AU' },
  { name: 'Northern Territory', region: '03', country: 'AU' },
  { name: 'Queensland State', region: '04', country: 'AU' },
  { name: 'South Australia State', region: '05', country: 'AU' },
  { name: 'Tasmania State', region: '06', country: 'AU' },
  { name: 'Victoria State', region: '07', country: 'AU' },
  // Belgium
  { name: 'Antwerpen', region: '01', country: 'BE' },
  { name: 'Brussels', region: '11', country: 'BE' },
  // Bolivia
  { name: 'Cochabamba', region: '02', country: 'BO' },
  { name: 'La Paz', region: '04', country: 'BO' },
  { name: 'Santa Cruz De La Sierra', region: '08', country: 'BO' },
  // Brazil
  { name: 'Ananindeua', region: '16', country: 'BR' },
  { name: 'Aracaju', region: '28', country: 'BR' },
  { name: 'Belém', region: '16', country: 'BR' },
  { name: 'Belo Horizonte', region: '15', country: 'BR' },
  { name: 'Brasília', region: '07', country: 'BR' },
  { name: 'Campinas', region: '27', country: 'BR' },
  { name: 'Campo Grande', region: '11', country: 'BR' },
  { name: 'Contagem', region: '15', country: 'BR' },
  { name: 'Cuiabá', region: '14', country: 'BR' },
  { name: 'Curitiba', region: '18', country: 'BR' },
  { name: 'Duque de Caxias', region: '21', country: 'BR' },
  { name: 'Feira de Santana', region: '05', country: 'BR' },
  { name: 'Florianopolis', region: '26', country: 'BR' },
  { name: 'Fortaleza', region: '06', country: 'BR' },
  { name: 'Goiânia', region: '29', country: 'BR' },
  { name: 'Guarulhos', region: '27', country: 'BR' },
  { name: 'João Pessoa', region: '17', country: 'BR' },
  { name: 'Joinville', region: '26', country: 'BR' },
  { name: 'Jundiai', region: '27', country: 'BR' },
  { name: 'Juiz de Fora', region: '15', country: 'BR' },
  { name: 'Londrina', region: '18', country: 'BR' },
  { name: 'Manaus', region: '04', country: 'BR' },
  { name: 'Maceió', region: '02', country: 'BR' },
  { name: 'Natal', region: '22', country: 'BR' },
  { name: 'Nova Iguaçu', region: '21', country: 'BR' },
  { name: 'Osasco', region: '27', country: 'BR' },
  { name: 'Porto Alegre', region: '23', country: 'BR' },
  { name: 'Porto Velho', region: '18', country: 'BR' },
  { name: 'Recife', region: '30', country: 'BR' },
  { name: 'Ribeirão Preto', region: '27', country: 'BR' },
  { name: 'Rio De Janeiro', region: '21', country: 'BR' },
  { name: 'São Bernardo do Campo', region: '27', country: 'BR' },
  { name: 'São Gonçalo', region: '21', country: 'BR' },
  { name: 'São Luís', region: '13', country: 'BR' },
  { name: 'São José dos Campos', region: '27', country: 'BR' },
  { name: 'São Paulo', region: '27', country: 'BR' },
  { name: 'Salvador', region: '05', country: 'BR' },
  { name: 'Santo André', region: '27', country: 'BR' },
  { name: 'Sorocaba', region: '27', country: 'BR' },
  { name: 'Teresina', region: '20', country: 'BR' },
  { name: 'Vitoria', region: '08', country: 'BR' },
  { name: 'Uberlândia', region: '15', country: 'BR' },
  // Canada
  { name: 'Edmonton', region: 'AB', country: 'CA' },
  { name: 'Montreal', region: 'QC', country: 'CA' },
  { name: 'Montréal', region: 'QC', country: 'CA' },
  { name: 'Ottawa', region: 'ON', country: 'CA' },
  { name: 'Quebec', region: 'QC', country: 'CA' },
  { name: 'Québec', region: 'QC', country: 'CA' },
  { name: 'Toronto', region: 'ON', country: 'CA' },
  // China
  { name: 'Beijing', region: '22', country: 'CN' },
  { name: 'Changchun', region: '05', country: 'CN' },
  { name: 'Changsha', region: '11', country: 'CN' },
  { name: 'Chengdu', region: '32', country: 'CN' },
  { name: 'Chongqing', region: '33', country: 'CN' },
  { name: 'Dalian', region: '19', country: 'CN' },
  { name: 'Dongwan', region: '11', country: 'CN' },
  { name: 'Foshan', region: '30', country: 'CN' },
  { name: 'Fuzhou', region: '03', country: 'CN' },
  { name: 'Guangzhou', region: '30', country: 'CN' },
  { name: 'Haerbin', region: '08', country: 'CN' },
  { name: 'Hangzhou', region: '02', country: 'CN' },
  { name: 'Jinan', region: '25', country: 'CN' },
  { name: 'Nanjing', region: '04', country: 'CN' },
  { name: 'Ningbo', region: '02', country: 'CN' },
  { name: 'Qingdao', region: '25', country: 'CN' },
  { name: 'Shanghai', region: '02', country: 'CN' },
  { name: 'Shenyang', region: '04', country: 'CN' },
  { name: 'Shenzhen', region: '30', country: 'CN' },
  { name: 'Shijiazhuang', region: '10', country: 'CN' },
  { name: 'Suzhou', region: '04', country: 'CN' },
  { name: 'Taiyuan', region: '24', country: 'CN' },
  { name: 'Tianjin', region: '28', country: 'CN' },
  { name: 'Wuhan', region: '12', country: 'CN' },
  { name: 'Wuxi', region: '01', country: 'CN' },
  { name: 'Xiamen', region: '07', country: 'CN' },
  { name: 'Xi\'an', region: '10', country: 'CN' },
  { name: 'Yantai', region: '10', country: 'CN' },
  { name: 'Zhengzhou', region: '09', country: 'CN' },
  // Colombia
  { name: 'Barranquilla', region: '04', country: 'CO' },
  { name: 'Bogotá', region: '33', country: 'CO' },
  { name: 'Bucaramanga', region: '26', country: 'CO' },
  { name: 'Cali', region: '29', country: 'CO' },
  { name: 'Cartagena', region: '05', country: 'CO' },
  { name: 'Cúcuta', region: '21', country: 'CO' },
  { name: 'Ibagué', region: '28', country: 'CO' },
  { name: 'Medellín', region: '02', country: 'CO' },
  { name: 'Soacha', region: '33', country: 'CO' },
  { name: 'Soledad', region: '04', country: 'CO' },
  // Denmark
  { name: 'Aarhus', region: '18', country: 'DK' },
  { name: 'Copenhagen', region: '17', country: 'DK' },
  // France
  { name: 'Lyon', region: 'B9', country: 'FR' },
  { name: 'Marseille', region: 'B8', country: 'FR' },
  { name: 'Paris', region: 'A8', country: 'FR' },
  // India
  { name: 'Ahmedabad', region: '09', country: 'IN' },
  { name: 'Bengaluru', region: '19', country: 'IN' },
  { name: 'Chennai', region: '25', country: 'IN' },
  { name: 'Chandigarh', region: '05', country: 'IN' },
  { name: 'Delhi', region: '07', country: 'IN' },
  { name: 'Gurgaon', region: '10', country: 'IN' },
  { name: 'Hyderabad', region: '02', country: 'IN' },
  { name: 'Jaipur', region: '24', country: 'IN' },
  { name: 'Kanpur', region: '36', country: 'IN' },
  { name: 'Kolkata', region: '28', country: 'IN' },
  { name: 'Lucknow', region: '36', country: 'IN' },
  { name: 'Mumbai', region: '16', country: 'IN' },
  { name: 'New Delhi', region: '23', country: 'IN' },
  { name: 'Noida', region: '36', country: 'IN' },
  { name: 'Pune', region: '16', country: 'IN' },
  { name: 'Surat', region: '09', country: 'IN' },
  { name: 'Thane', region: '16', country: 'IN' },
  // Indonesia
  { name: 'Bandung', region: '30', country: 'ID' },
  { name: 'Bekasi', region: '30', country: 'ID' },
  { name: 'Denpasar', region: '02', country: 'ID' },
  { name: 'Jakarta', region: '04', country: 'ID' },
  { name: 'Jogjakarta', region: '10', country: 'ID' },
  { name: 'Medan', region: '26', country: 'ID' },
  { name: 'Semarang', region: '07', country: 'ID' },
  { name: 'Surabaya', region: '08', country: 'ID' },
  { name: 'Tangerang', region: '30', country: 'ID' },
  // Ireland
  { name: 'Dublin', region: '07', country: 'IE' },
  // Israel
  { name: 'Tel Aviv', region: '05', country: 'IL' },
  // Italy
  { name: 'Acerra', region: '04', country: 'IT' },
  { name: 'Afragola', region: '04', country: 'IT' },
  { name: 'Alessandria', region: '12', country: 'IT' },
  { name: 'Amantea', region: '03', country: 'IT' },
  { name: 'Ancona', region: '10', country: 'IT' },
  { name: 'Arezzo', region: '16', country: 'IT' },
  { name: 'Arre', region: '20', country: 'IT' },
  { name: 'Avellino', region: '04', country: 'IT' },
  { name: 'Asti', region: '12', country: 'IT' },
  { name: 'Bari', region: '13', country: 'IT' },
  { name: 'Bergamo', region: '09', country: 'IT' },
  { name: 'Bernate Ticino', region: '09', country: 'IT' },
  { name: 'Biella', region: '12', country: 'IT' },
  { name: 'Bologna', region: '05', country: 'IT' },
  { name: 'Bolzano', region: '17', country: 'IT' },
  { name: 'Brescia', region: '09', country: 'IT' },
  { name: 'Brindisi', region: '13', country: 'IT' },
  { name: 'Busto Arsizio', region: '09', country: 'IT' },
  { name: 'Cagliari', region: '14', country: 'IT' },
  { name: 'Carpi', region: '05', country: 'IT' },
  { name: 'Casalnuovo Di Napoli', region: '04', country: 'IT' },
  { name: 'Cascina', region: '16', country: 'IT' },
  { name: 'Caserta', region: '04', country: 'IT' },
  { name: 'Casoria', region: '04', country: 'IT' },
  { name: 'Casteldaccia', region: '15', country: 'IT' },
  { name: 'Castellammare Di Stabia', region: '04', country: 'IT' },
  { name: 'Catania', region: '15', country: 'IT' },
  { name: 'Catanzaro', region: '03', country: 'IT' },
  { name: 'Cava De\' Tirreni', region: '04', country: 'IT' },
  { name: 'Cesena', region: '05', country: 'IT' },
  { name: 'Cinisello Balsamo', region: '09', country: 'IT' },
  { name: 'Codroipo', region: '06', country: 'IT' },
  { name: 'Cologno Monzese', region: '09', country: 'IT' },
  { name: 'Como', region: '09', country: 'IT' },
  { name: 'Conegliano', region: '20', country: 'IT' },
  { name: 'Correggio', region: '05', country: 'IT' },
  { name: 'Corsico', region: '09', country: 'IT' },
  { name: 'Cosenza', region: '03', country: 'IT' },
  { name: 'Cremona', region: '09', country: 'IT' },
  { name: 'Curno', region: '09', country: 'IT' },
  { name: 'Dalmine', region: '09', country: 'IT' },
  { name: 'Ercolano', region: '04', country: 'IT' },
  { name: 'Faenza', region: '05', country: 'IT' },
  { name: 'Fermo', region: '10', country: 'IT' },
  { name: 'Ferrara', region: '05', country: 'IT' },
  { name: 'Florence', region: '16', country: 'IT' },
  { name: 'Foggia', region: '13', country: 'IT' },
  { name: 'Frosinone', region: '07', country: 'IT' },
  { name: 'Gallarate', region: '09', country: 'IT' },
  { name: 'Garbagnate Milanese', region: '09', country: 'IT' },
  { name: 'Genova', region: '08', country: 'IT' },
  { name: 'Giugliano In Campania', region: '04', country: 'IT' },
  { name: 'Giulianova', region: '01', country: 'IT' },
  { name: 'Giussano', region: '09', country: 'IT' },
  { name: 'Inzago', region: '09', country: 'IT' },
  { name: 'Lainate', region: '09', country: 'IT' },
  { name: 'La Spezia', region: '08', country: 'IT' },
  { name: 'Lecce', region: '13', country: 'IT' },
  { name: 'Legnano', region: '09', country: 'IT' },
  { name: 'Limbiate', region: '09', country: 'IT' },
  { name: 'Livorno', region: '16', country: 'IT' },
  { name: 'Lissone', region: '09', country: 'IT' },
  { name: 'Lodi', region: '09', country: 'IT' },
  { name: 'Lucca', region: '16', country: 'IT' },
  { name: 'Macerata', region: '10', country: 'IT' },
  { name: 'Mantova', region: '09', country: 'IT' },
  { name: 'Marano Di Napoli', region: '04', country: 'IT' },
  { name: 'Marsala', region: '15', country: 'IT' },
  { name: 'Mascalucia', region: '15', country: 'IT' },
  { name: 'Massa E Cozzile', region: '16', country: 'IT' },
  { name: 'Meda', region: '09', country: 'IT' },
  { name: 'Messina', region: '15', country: 'IT' },
  { name: 'Mestre', region: '20', country: 'IT' },
  { name: 'Milan', region: '09', country: 'IT' },
  { name: 'Modena', region: '05', country: 'IT' },
  { name: 'Modugno', region: '13', country: 'IT' },
  { name: 'Molfetta', region: '13', country: 'IT' },
  { name: 'Moncalieri', region: '12', country: 'IT' },
  { name: 'Montesilvano', region: '01', country: 'IT' },
  { name: 'Monza', region: '09', country: 'IT' },
  { name: 'Napoli', region: '04', country: 'IT' },
  { name: 'Nerviano', region: '09', country: 'IT' },
  { name: 'Nocera Inferiore', region: '04', country: 'IT' },
  { name: 'Nola', region: '04', country: 'IT' },
  { name: 'Novara', region: '12', country: 'IT' },
  { name: 'Osimo', region: '10', country: 'IT' },
  { name: 'Paderno Dugnano', region: '09', country: 'IT' },
  { name: 'Padova', region: '20', country: 'IT' },
  { name: 'Palagiano', region: '13', country: 'IT' },
  { name: 'Palermo', region: '15', country: 'IT' },
  { name: 'Parma', region: '05', country: 'IT' },
  { name: 'Pavia', region: '09', country: 'IT' },
  { name: 'Perugia', region: '18', country: 'IT' },
  { name: 'Pescara', region: '01', country: 'IT' },
  { name: 'Piacenza', region: '05', country: 'IT' },
  { name: 'Pioltello', region: '09', country: 'IT' },
  { name: 'Pisa', region: '16', country: 'IT' },
  { name: 'Pistoia', region: '16', country: 'IT' },
  { name: 'Poggiomarino', region: '04', country: 'IT' },
  { name: 'Pomigliano D\'arco', region: '04', country: 'IT' },
  { name: 'Pordenone', region: '06', country: 'IT' },
  { name: 'Portici', region: '04', country: 'IT' },
  { name: 'Potenza', region: '02', country: 'IT' },
  { name: 'Pozzuoli', region: '04', country: 'IT' },
  { name: 'Prato', region: '16', country: 'IT' },
  { name: 'Quartu Sant\'elena', region: '14', country: 'IT' },
  { name: 'Ravenna', region: '05', country: 'IT' },
  { name: 'Reggio Calabria', region: '03', country: 'IT' },
  { name: 'Reggio Nell\'emilia', region: '05', country: 'IT' },
  { name: 'Rende', region: '03', country: 'IT' },
  { name: 'Rho', region: '09', country: 'IT' },
  { name: 'Rimini', region: '05', country: 'IT' },
  { name: 'Rome', region: '07', country: 'IT' },
  { name: 'Rovigo', region: '20', country: 'IT' },
  { name: 'Rozzano', region: '09', country: 'IT' },
  { name: 'Rubano', region: '20', country: 'IT' },
  { name: 'Salerno', region: '04', country: 'IT' },
  { name: 'San Donato Milanese', region: '09', country: 'IT' },
  { name: 'San Giorgio A Cremano', region: '04', country: 'IT' },
  { name: 'San Giuliano Milanese', region: '09', country: 'IT' },
  { name: 'Saronno', region: '09', country: 'IT' },
  { name: 'Sassari', region: '14', country: 'IT' },
  { name: 'Savona', region: '08', country: 'IT' },
  { name: 'Sassuolo', region: '05', country: 'IT' },
  { name: 'Scafati', region: '04', country: 'IT' },
  { name: 'Segrate', region: '09', country: 'IT' },
  { name: 'Senigallia', region: '10', country: 'IT' },
  { name: 'Seregno', region: '09', country: 'IT' },
  { name: 'Sesto Fiorentino', region: '16', country: 'IT' },
  { name: 'Sesto San Giovanni', region: '09', country: 'IT' },
  { name: 'Siena', region: '16', country: 'IT' },
  { name: 'Siracusa', region: '15', country: 'IT' },
  { name: 'Somma Vesuviana', region: '04', country: 'IT' },
  { name: 'Spinea', region: '20', country: 'IT' },
  { name: 'Taranto', region: '13', country: 'IT' },
  { name: 'Teramo', region: '01', country: 'IT' },
  { name: 'Terni', region: '18', country: 'IT' },
  { name: 'Torre Del Greco', region: '04', country: 'IT' },
  { name: 'Torino', region: '12', country: 'IT' },
  { name: 'Tradate', region: '09', country: 'IT' },
  { name: 'Trecate', region: '12', country: 'IT' },
  { name: 'Treviso', region: '20', country: 'IT' },
  { name: 'Trento', region: '17', country: 'IT' },
  { name: 'Trieste', region: '06', country: 'IT' },
  { name: 'Turin', region: '12', country: 'IT' },
  { name: 'Udine', region: '06', country: 'IT' },
  { name: 'Varese', region: '09', country: 'IT' },
  { name: 'Venezia', region: '20', country: 'IT' },
  { name: 'Vercelli', region: '12', country: 'IT' },
  { name: 'Verona', region: '20', country: 'IT' },
  { name: 'Vicenza', region: '20', country: 'IT' },
  { name: 'Vigevano', region: '09', country: 'IT' },
  { name: 'Villaricca', region: '04', country: 'IT' },
  { name: 'Vimercate', region: '09', country: 'IT' },
  { name: 'Zola Predosa', region: '05', country: 'IT' },
  // Korea
  { name: 'Ansan-si', region: '41', country: 'KR' },
  { name: 'Anyang-si', region: '41', country: 'KR' },
  { name: 'Bucheon-si', region: '43', country: 'KR' },
  { name: 'Busan', region: '10', country: 'KR' },
  { name: 'Changwon', region: '20', country: 'KR' },
  { name: 'Daegu', region: '15', country: 'KR' },
  { name: 'Daejeon', region: '19', country: 'KR' },
  { name: 'Dongducheon-si', region: '45', country: 'KR' },
  { name: 'Gimpo-si', region: '45', country: 'KR' },
  { name: 'Goyang', region: '17', country: 'KR' },
  { name: 'Goyang-si', region: '43', country: 'KR' },
  { name: 'Gunpo-si', region: '11', country: 'KR' },
  { name: 'Guri-si', region: '46', country: 'KR' },
  { name: 'Gwacheon-si', region: '46', country: 'KR' },
  { name: 'Gwangju', region: '13', country: 'KR' },
  { name: 'Gwangmyeong-si', region: '46', country: 'KR' },
  { name: 'Hanam-si', region: '46', country: 'KR' },
  { name: 'Hwaseong-si', region: '11', country: 'KR' },
  { name: 'Incheon', region: '12', country: 'KR' },
  { name: 'Icheon-si', region: '11', country: 'KR' },
  { name: 'Jeju', region: '01', country: 'KR' },
  { name: 'Paju-si', region: '48', country: 'KR' },
  { name: 'Pocheon-si', region: '48', country: 'KR' },
  { name: 'Pyeongtaek-si', region: '27', country: 'KR' },
  { name: 'Sejong-si', region: '41', country: 'KR' },
  { name: 'Seongnam-si', region: '27', country: 'KR' },
  { name: 'Seoul', region: '11', country: 'KR' },
  { name: 'Siheung-si', region: '48', country: 'KR' },
  { name: 'Suwon', region: '17', country: 'KR' },
  { name: 'Uijeongbu-si', region: '48', country: 'KR' },
  { name: 'Uiwang-si', region: '48', country: 'KR' },
  { name: 'Ulsan', region: '21', country: 'KR' },
  { name: 'Yangp\'yong', region: '26', country: 'KR' },
  { name: 'Yongin-si', region: '26', country: 'KR' },
  // Lao People's Democratic Republic
  { name: 'Paksé', region: '02', country: 'LA' },
  { name: 'Vientiane', region: '11', country: 'LA' },
  // Malayasia
  { name: 'Kuala Lumpur', region: '14', country: 'MY' },
  // Mexico
  { name: 'Acapulco', region: '12', country: 'MX' },
  { name: 'Aguascalientes', region: '01', country: 'MX' },
  { name: 'Alvaro Obregon', region: '11', country: 'MX' },
  { name: 'Chihuahua', region: '06', country: 'MX' },
  { name: 'Coyoacan', region: '26', country: 'MX' },
  { name: 'Ecatepec', region: '15', country: 'MX' },
  { name: 'Guadalajara', region: '14', country: 'MX' },
  { name: 'Guadalupe', region: '19', country: 'MX' },
  { name: 'Gustavo A. Madero', region: '09', country: 'MX' },
  { name: 'Iztapalapa', region: '09', country: 'MX' },
  { name: 'Juarez', region: '15', country: 'MX' },
  { name: 'Mexico', region: '09', country: 'MX' },
  { name: 'Monterrey', region: '19', country: 'MX' },
  { name: 'Naucalpan', region: '15', country: 'MX' },
  { name: 'Potosi', region: '19', country: 'MX' },
  { name: 'Puebla', region: '21', country: 'MX' },
  { name: 'Saltillo', region: '07', country: 'MX' },
  { name: 'Santiago de Queretaro', region: '22', country: 'MX' },
  { name: 'Tijuana', region: '02', country: 'MX' },
  { name: 'Tlalnepantla', region: '15', country: 'MX' },
  { name: 'Zapopan', region: '14', country: 'MX' },
  // Myanmar
  { name: 'Mandalay', region: '08', country: 'MM' },
  { name: 'Nay Pyi Taw', region: '08', country: 'MM' },
  { name: 'Yangon', region: '17', country: 'MM' },
  // Netherlands
  { name: 'Almere', region: '16', country: 'NL' },
  { name: 'Amersfoort', region: '09', country: 'NL' },
  { name: 'Amsterdam', region: '07', country: 'NL' },
  { name: 'Arnhem', region: '03', country: 'NL' },
  { name: 'Den Haag', region: '11', country: 'NL' },
  { name: 'Eindhoven', region: '06', country: 'NL' },
  { name: 'Groningen', region: '04', country: 'NL' },
  { name: 'Haarlem', region: '07', country: 'NL' },
  { name: 'Rotterdam', region: '11', country: 'NL' },
  { name: 'Schiedam', region: '11', country: 'NL' },
  { name: 'Tilburg', region: '06', country: 'NL' },
  { name: 'Uitgeest', region: '07', country: 'NL' },
  { name: 'Utrecht', region: '09', country: 'NL' },
  { name: 'Zoetermeer', region: '11', country: 'NL' },
  // Norway
  { name: 'Oslo', region: '12', country: 'NO' },
  // Paraguay
  { name: 'Asunción', region: '06', country: 'PY' },
  // Peru
  { name: 'Arequipa', region: '04', country: 'PE' },
  { name: 'Callao', region: '07', country: 'PE' },
  { name: 'Chiclayo', region: '14', country: 'PE' },
  { name: 'Lima', region: '15', country: 'PE' },
  { name: 'Trujillo', region: '13', country: 'PE' },
  // Philippines
  { name: 'Cebu City', region: 'B7', country: 'PH' },
  { name: 'Caloocan', region: 'B4', country: 'PH' },
  { name: 'Davao City', region: 'C3', country: 'PH' },
  { name: 'Makati', region: 'D9', country: 'PH' },
  { name: 'Manila', region: 'D9', country: 'PH' },
  { name: 'Quezon City', region: 'F2', country: 'PH' },
  { name: 'San Juan', region: 'D9', country: 'PH' },
  { name: 'Zamboanga City', region: 'G7', country: 'PH' },
  // Russia
  { name: 'Moscow', region: '48', country: 'RU' },
  // Singapore
  { name: 'Singapore', region: '00', country: 'SG' },
  // Saudi Arabia
  { name: 'Jeddah', region: '14', country: 'SA' },
  { name: 'Riyadh', region: '10', country: 'SA' },
  // Spain
  { name: 'Barcelona', region: '56', country: 'ES' },
  { name: 'Madrid', region: '29', country: 'ES' },
  // Sweden
  { name: 'Stockholm', region: '26', country: 'SE' },
  // Switzerland
  { name: 'Full', region: '01', country: 'CH' },
  { name: 'Lugano', region: '20', country: 'CH' },
  { name: 'Zurich', region: '25', country: 'CH' },
  // Taiwan
  { name: 'Taipei', region: '03', country: 'TW' },
  // Thailand
  { name: 'Bangkok', region: '40', country: 'TH' },
  { name: 'Chiang Mai', region: '02', country: 'TH' },
  { name: 'Nakhon Ratchasima', region: '27', country: 'TH' },
  { name: 'Nonthaburi', region: '38', country: 'TH' },
  // United Kingdom
  { name: 'Birmingham', region: 'A7', country: 'GB' },
  { name: 'Coventry', region: 'C7', country: 'GB' },
  { name: 'London', region: 'H9', country: 'GB' },
  { name: 'Manchester', region: 'I2', country: 'GB' },
  { name: 'Nottingham', region: 'J8', country: 'GB' },
  // United States
  { name: 'Abilene', region: 'TX', country: 'US' },
  { name: 'Albany', region: 'GA', country: 'US' },
  { name: 'Albany', region: 'OR', country: 'US' },
  { name: 'Alhambra', region: 'CA', country: 'US' },
  { name: 'Aliso Viejo', region: 'CA', country: 'US' },
  { name: 'Amarillo', region: 'TX', country: 'US' },
  { name: 'Ames', region: 'IA', country: 'US' },
  { name: 'Anchorage municipality', region: 'AK', country: 'US' },
  { name: 'Ankeny', region: 'IA', country: 'US' },
  { name: 'Antioch', region: 'CA', country: 'US' },
  { name: 'Apple Valley town', region: 'CA', country: 'US' },
  { name: 'Apple Valley', region: 'MN', country: 'US' },
  { name: 'Appleton', region: 'WI', country: 'US' },
  { name: 'Arcadia', region: 'CA', country: 'US' },
  { name: 'Asheville', region: 'NC', country: 'US' },
  { name: 'Ashland', region: 'VA', country: 'US' },
  { name: 'Athens-Clarke County unified government', region: 'GA', country: 'US' },
  { name: 'Auburn', region: 'AL', country: 'US' },
  { name: 'Augusta-Richmond County consolidated government', region: 'GA', country: 'US' },
  { name: 'Aurora', region: 'CO', country: 'US' },
  { name: 'Aurora', region: 'IL', country: 'US' },
  { name: 'Abington', region: 'PA', country: 'US' },
  { name: 'Alameda', region: 'CA', country: 'US' },
  { name: 'Albany', region: 'NY', country: 'US' },
  { name: 'Altoona', region: 'PA', country: 'US' },
  { name: 'Atlanta', region: 'GA', country: 'US' },
  { name: 'Alexandria', region: 'VA', country: 'US' },
  { name: 'Anaheim', region: 'CA', country: 'US' },
  { name: 'Akron', region: 'OH', country: 'US' },
  { name: 'Allen', region: 'TX', country: 'US' },
  { name: 'Arlington', region: 'TX', country: 'US' },
  { name: 'Arlington', region: 'VA', country: 'US' },
  { name: 'Attleboro', region: 'MA', country: 'US' },
  { name: 'Allentown', region: 'PA', country: 'US' },
  { name: 'Ann Arbor', region: 'MI', country: 'US' },
  { name: 'Austin', region: 'TX', country: 'US' },
  { name: 'Annandale', region: 'VA', country: 'US' },
  { name: 'Ashford', region: 'CT', country: 'US' },
  { name: 'Arlington Heights', region: 'IL', country: 'US' },
  { name: 'Angier', region: 'NC', country: 'US' },
  { name: 'Azusa', region: 'CA', country: 'US' },
  { name: 'Augusta', region: 'GA', country: 'US' },
  { name: 'Athens', region: 'GA', country: 'US' },
  { name: 'Albuquerque', region: 'NM', country: 'US' },
  { name: 'Alpharetta', region: 'GA', country: 'US' },
  { name: 'Arlington', region: 'IN', country: 'US' },
  { name: 'Annandale', region: 'MN', country: 'US' },
  { name: 'Bakersfield', region: 'CA', country: 'US' },
  { name: 'Baldwin Park', region: 'CA', country: 'US' },
  { name: 'Bayonne', region: 'NJ', country: 'US' },
  { name: 'Baytown', region: 'TX', country: 'US' },
  { name: 'Beaverton', region: 'OR', country: 'US' },
  { name: 'Bellflower', region: 'CA', country: 'US' },
  { name: 'Berwyn', region: 'IL', country: 'US' },
  { name: 'Bethlehem', region: 'PA', country: 'US' },
  { name: 'Bismarck', region: 'ND', country: 'US' },
  { name: 'Blaine', region: 'MN', country: 'US' },
  { name: 'Bloomington', region: 'IL', country: 'US' },
  { name: 'Bloomington', region: 'MN', country: 'US' },
  { name: 'Blue Springs', region: 'MO', country: 'US' },
  { name: 'Boise City', region: 'ID', country: 'US' },
  { name: 'Bolingbrook village', region: 'IL', country: 'US' },
  { name: 'Boulder', region: 'CO', country: 'US' },
  { name: 'Bowling Green', region: 'KY', country: 'US' },
  { name: 'Brentwood', region: 'CA', country: 'US' },
  { name: 'Brockton', region: 'MA', country: 'US' },
  { name: 'Broken Arrow', region: 'OK', country: 'US' },
  { name: 'Brookhaven', region: 'GA', country: 'US' },
  { name: 'Brooklyn Park', region: 'MN', country: 'US' },
  { name: 'Broomfield', region: 'CO', country: 'US' },
  { name: 'Brownsville', region: 'TX', country: 'US' },
  { name: 'Bryan', region: 'TX', country: 'US' },
  { name: 'Buena Park', region: 'CA', country: 'US' },
  { name: 'Burlington', region: 'NC', country: 'US' },
  { name: 'Burnsville', region: 'MN', country: 'US' },
  { name: 'Beaver', region: 'PA', country: 'US' },
  { name: 'Berkeley', region: 'CA', country: 'US' },
  { name: 'Beverly Hills', region: 'CA', country: 'US' },
  { name: 'Bronx', region: 'NY', country: 'US' },
  { name: 'Burbank', region: 'CA', country: 'US' },
  { name: 'Burlingame', region: 'CA', country: 'US' },
  { name: 'Brooklyn', region: 'NY', country: 'US' },
  { name: 'Boston', region: 'MA', country: 'US' },
  { name: 'Baltimore', region: 'MD', country: 'US' },
  { name: 'Brighton', region: 'MI', country: 'US' },
  { name: 'Bridgeton', region: 'NJ', country: 'US' },
  { name: 'Birmingham', region: 'AL', country: 'US' },
  { name: 'Buffalo', region: 'NY', country: 'US' },
  { name: 'Belleville', region: 'NJ', country: 'US' },
  { name: 'Beaumont', region: 'TX', country: 'US' },
  { name: 'Battle Creek', region: 'MI', country: 'US' },
  { name: 'Boiling Springs', region: 'SC', country: 'US' },
  { name: 'Brunswick', region: 'OH', country: 'US' },
  { name: 'Barberton', region: 'OH', country: 'US' },
  { name: 'Boaz', region: 'AL', country: 'US' },
  { name: 'Bowie', region: 'MD', country: 'US' },
  { name: 'Baton Rouge', region: 'LA', country: 'US' },
  { name: 'Bellevue', region: 'OH', country: 'US' },
  { name: 'Blue Island', region: 'IL', country: 'US' },
  { name: 'Blair', region: 'NE', country: 'US' },
  { name: 'Brighton', region: 'MA', country: 'US' },
  { name: 'Bethel Park', region: 'PA', country: 'US' },
  { name: 'Caldwell', region: 'ID', country: 'US' },
  { name: 'Camarillo', region: 'CA', country: 'US' },
  { name: 'Camden', region: 'NJ', country: 'US' },
  { name: 'Carlsbad', region: 'CA', country: 'US' },
  { name: 'Carson City', region: 'NV', country: 'US' },
  { name: 'Carson', region: 'CA', country: 'US' },
  { name: 'Cary town', region: 'NC', country: 'US' },
  { name: 'Casper', region: 'WY', country: 'US' },
  { name: 'Castle Rock town', region: 'CO', country: 'US' },
  { name: 'Cedar Park', region: 'TX', country: 'US' },
  { name: 'Cedar Rapids', region: 'IA', country: 'US' },
  { name: 'Centennial', region: 'CO', country: 'US' },
  { name: 'Cerritos', region: 'CA', country: 'US' },
  { name: 'Champaign', region: 'IL', country: 'US' },
  { name: 'Chapel Hill town', region: 'NC', country: 'US' },
  { name: 'Charleston', region: 'WV', country: 'US' },
  { name: 'Chesapeake', region: 'VA', country: 'US' },
  { name: 'Cheyenne', region: 'WY', country: 'US' },
  { name: 'Chico', region: 'CA', country: 'US' },
  { name: 'Chicopee', region: 'MA', country: 'US' },
  { name: 'Chino Hills', region: 'CA', country: 'US' },
  { name: 'Chino', region: 'CA', country: 'US' },
  { name: 'Chula Vista', region: 'CA', country: 'US' },
  { name: 'Cicero town', region: 'IL', country: 'US' },
  { name: 'Citrus Heights', region: 'CA', country: 'US' },
  { name: 'Clifton', region: 'NJ', country: 'US' },
  { name: 'Clovis', region: 'CA', country: 'US' },
  { name: "Coeur d'Alene", region: 'ID', country: 'US' },
  { name: 'College Station', region: 'TX', country: 'US' },
  { name: 'Colton', region: 'CA', country: 'US' },
  { name: 'Commerce City', region: 'CO', country: 'US' },
  { name: 'Concord', region: 'NC', country: 'US' },
  { name: 'Conroe', region: 'TX', country: 'US' },
  { name: 'Coon Rapids', region: 'MN', country: 'US' },
  { name: 'Corpus Christi', region: 'TX', country: 'US' },
  { name: 'Corvallis', region: 'OR', country: 'US' },
  { name: 'Council Bluffs', region: 'IA', country: 'US' },
  { name: 'Cranston', region: 'RI', country: 'US' },
  { name: 'Cambridge', region: 'MA', country: 'US' },
  { name: 'Cathedral City', region: 'CA', country: 'US' },
  { name: 'Chicago', region: 'IL', country: 'US' },
  { name: 'Columbia', region: 'GA', country: 'US' },
  { name: 'Columbia', region: 'MO', country: 'US' },
  { name: 'Columbia', region: 'SC', country: 'US' },
  { name: 'Connellsville', region: 'PA', country: 'US' },
  { name: 'Concord', region: 'NH', country: 'US' },
  { name: 'Cortlandt Manor', region: 'NY', country: 'US' },
  { name: 'Costa Mesa', region: 'CA', country: 'US' },
  { name: 'Culver City', region: 'CA', country: 'US' },
  { name: 'Cupertino', region: 'CA', country: 'US' },
  { name: 'Charlotte', region: 'NC', country: 'US' },
  { name: 'Columbus', region: 'OH', country: 'US' },
  { name: 'Cleveland', region: 'OH', country: 'US' },
  { name: 'Cincinnati', region: 'OH', country: 'US' },
  { name: 'Compton', region: 'CA', country: 'US' },
  { name: 'Canton', region: 'OH', country: 'US' },
  { name: 'Columbus', region: 'GA', country: 'US' },
  { name: 'Chattanooga', region: 'TN', country: 'US' },
  { name: 'College Park', region: 'MD', country: 'US' },
  { name: 'Carleton', region: 'MI', country: 'US' },
  { name: 'Cartersville', region: 'GA', country: 'US' },
  { name: 'Chicago Heights', region: 'IL', country: 'US' },
  { name: 'Cedartown', region: 'GA', country: 'US' },
  { name: 'Cashiers', region: 'NC', country: 'US' },
  { name: 'Collettsville', region: 'NC', country: 'US' },
  { name: 'Cromwell', region: 'CT', country: 'US' },
  { name: 'Covington', region: 'GA', country: 'US' },
  { name: 'Corona', region: 'CA', country: 'US' },
  { name: 'Clermont', region: 'FL', country: 'US' },
  { name: 'Crofton', region: 'MD', country: 'US' },
  { name: 'Chaska', region: 'MN', country: 'US' },
  { name: 'Cherry Hill', region: 'NJ', country: 'US' },
  { name: 'Carrollton', region: 'TX', country: 'US' },
  { name: 'Childersburg', region: 'AL', country: 'US' },
  { name: 'Canonsburg', region: 'PA', country: 'US' },
  { name: 'Crown Point', region: 'IN', country: 'US' },
  { name: 'Colorado Springs', region: 'CO', country: 'US' },
  { name: 'Daly City', region: 'CA', country: 'US' },
  { name: 'Davenport', region: 'IA', country: 'US' },
  { name: 'Davis', region: 'CA', country: 'US' },
  { name: 'DeSoto', region: 'TX', country: 'US' },
  { name: 'Dearborn Heights', region: 'MI', country: 'US' },
  { name: 'Dearborn', region: 'MI', country: 'US' },
  { name: 'Decatur', region: 'AL', country: 'US' },
  { name: 'Decatur', region: 'IL', country: 'US' },
  { name: 'Delano', region: 'CA', country: 'US' },
  { name: 'Denton', region: 'TX', country: 'US' },
  { name: 'Des Plaines', region: 'IL', country: 'US' },
  { name: 'Diamond Bar', region: 'CA', country: 'US' },
  { name: 'Dothan', region: 'AL', country: 'US' },
  { name: 'Downey', region: 'CA', country: 'US' },
  { name: 'Dublin', region: 'CA', country: 'US' },
  { name: 'Duluth', region: 'MN', country: 'US' },
  { name: 'Dover', region: 'NH', country: 'US' },
  { name: 'Du Bois', region: 'PA', country: 'US' },
  { name: 'Dallas', region: 'TX', country: 'US' },
  { name: 'Decatur', region: 'GA', country: 'US' },
  { name: 'Detroit', region: 'MI', country: 'US' },
  { name: 'Denver', region: 'CO', country: 'US' },
  { name: 'Douglasville', region: 'GA', country: 'US' },
  { name: 'Dayton', region: 'OH', country: 'US' },
  { name: 'District Heights', region: 'MD', country: 'US' },
  { name: 'Dubuque', region: 'IA', country: 'US' },
  { name: 'Dublin', region: 'OH', country: 'US' },
  { name: 'Downers Grove', region: 'IL', country: 'US' },
  { name: 'Decatur', region: 'TN', country: 'US' },
  { name: 'Dallas', region: 'GA', country: 'US' },
  { name: 'Des Moines', region: 'IA', country: 'US' },
  { name: 'Duluth', region: 'GA', country: 'US' },
  { name: 'Durham', region: 'NC', country: 'US' },
  { name: 'Derry', region: 'NH', country: 'US' },
  { name: 'Dover Plains', region: 'NY', country: 'US' },
  { name: 'Eagan', region: 'MN', country: 'US' },
  { name: 'East Honolulu', region: 'HI', country: 'US' },
  { name: 'East Orange', region: 'NJ', country: 'US' },
  { name: 'Eastvale', region: 'CA', country: 'US' },
  { name: 'Eau Claire', region: 'WI', country: 'US' },
  { name: 'Eden Prairie', region: 'MN', country: 'US' },
  { name: 'Edina', region: 'MN', country: 'US' },
  { name: 'Edinburg', region: 'TX', country: 'US' },
  { name: 'Edmond', region: 'OK', country: 'US' },
  { name: 'El Cajon', region: 'CA', country: 'US' },
  { name: 'El Monte', region: 'CA', country: 'US' },
  { name: 'Elgin', region: 'IL', country: 'US' },
  { name: 'Elizabeth', region: 'NJ', country: 'US' },
  { name: 'Elk Grove', region: 'CA', country: 'US' },
  { name: 'Elyria', region: 'OH', country: 'US' },
  { name: 'Encinitas', region: 'CA', country: 'US' },
  { name: 'Enid', region: 'OK', country: 'US' },
  { name: 'Erie', region: 'PA', country: 'US' },
  { name: 'Escondido', region: 'CA', country: 'US' },
  { name: 'Eugene', region: 'OR', country: 'US' },
  { name: 'Euless', region: 'TX', country: 'US' },
  { name: 'Ewa Gentry', region: 'HI', country: 'US' },
  { name: 'Emeryville', region: 'CA', country: 'US' },
  { name: 'Evanston', region: 'IL', country: 'US' },
  { name: 'Essex', region: 'MD', country: 'US' },
  { name: 'Ellisville', region: 'MS', country: 'US' },
  { name: 'Easley', region: 'SC', country: 'US' },
  { name: 'Elmhurst', region: 'NY', country: 'US' },
  { name: 'El Paso', region: 'TX', country: 'US' },
  { name: 'Erie', region: 'IL', country: 'US' },
  { name: 'Enfield', region: 'CT', country: 'US' },
  { name: 'Fairburn', region: 'GA', country: 'US' },
  { name: 'Fairfield', region: 'CA', country: 'US' },
  { name: 'Fall River', region: 'MA', country: 'US' },
  { name: 'Fargo', region: 'ND', country: 'US' },
  { name: 'Farmington Hills', region: 'MI', country: 'US' },
  { name: 'Flint', region: 'MI', country: 'US' },
  { name: 'Florissant', region: 'MO', country: 'US' },
  { name: 'Flower Mound town', region: 'TX', country: 'US' },
  { name: 'Folsom', region: 'CA', country: 'US' },
  { name: 'Fontana', region: 'CA', country: 'US' },
  { name: 'Fort Collins', region: 'CO', country: 'US' },
  { name: 'Fountain Valley', region: 'CA', country: 'US' },
  { name: 'Fresno', region: 'CA', country: 'US' },
  { name: 'Frisco', region: 'TX', country: 'US' },
  { name: 'Fullerton', region: 'CA', country: 'US' },
  { name: 'Fremont', region: 'CA', country: 'US' },
  { name: 'Fort Lauderdale', region: 'FL', country: 'US' },
  { name: 'Fort Worth', region: 'TX', country: 'US' },
  { name: 'Falls Church', region: 'VA', country: 'US' },
  { name: 'Fayetteville', region: 'NC', country: 'US' },
  { name: 'Fairfax', region: 'VA', country: 'US' },
  { name: 'Fort Wayne', region: 'IN', country: 'US' },
  { name: 'Fort Mill', region: 'SC', country: 'US' },
  { name: 'Farmington', region: 'MI', country: 'US' },
  { name: 'Fortson', region: 'GA', country: 'US' },
  { name: 'Fayetteville', region: 'GA', country: 'US' },
  { name: 'Franklin', region: 'MA', country: 'US' },
  { name: 'Fairmont', region: 'MN', country: 'US' },
  { name: 'Galveston', region: 'TX', country: 'US' },
  { name: 'Garden Grove', region: 'CA', country: 'US' },
  { name: 'Gardena', region: 'CA', country: 'US' },
  { name: 'Garland', region: 'TX', country: 'US' },
  { name: 'Gastonia', region: 'NC', country: 'US' },
  { name: 'Georgetown', region: 'TX', country: 'US' },
  { name: 'Gilroy', region: 'CA', country: 'US' },
  { name: 'Glendale', region: 'CA', country: 'US' },
  { name: 'Glendora', region: 'CA', country: 'US' },
  { name: 'Grand Forks', region: 'ND', country: 'US' },
  { name: 'Grand Island', region: 'NE', country: 'US' },
  { name: 'Grand Junction', region: 'CO', country: 'US' },
  { name: 'Grand Prairie', region: 'TX', country: 'US' },
  { name: 'Grapevine', region: 'TX', country: 'US' },
  { name: 'Greeley', region: 'CO', country: 'US' },
  { name: 'Green Bay', region: 'WI', country: 'US' },
  { name: 'Greenville', region: 'NC', country: 'US' },
  { name: 'Gresham', region: 'OR', country: 'US' },
  { name: 'Gulfport', region: 'MS', country: 'US' },
  { name: 'Glenside', region: 'PA', country: 'US' },
  { name: 'Gaithersburg', region: 'MD', country: 'US' },
  { name: 'Grand Rapids', region: 'MI', country: 'US' },
  { name: 'Greer', region: 'SC', country: 'US' },
  { name: 'Greenville', region: 'SC', country: 'US' },
  { name: 'Greensboro', region: 'NC', country: 'US' },
  { name: 'Gainesville', region: 'FL', country: 'US' },
  { name: 'Grosse Ile', region: 'MI', country: 'US' },
  { name: 'Galloway', region: 'OH', country: 'US' },
  { name: 'Gilboa', region: 'NY', country: 'US' },
  { name: 'Glendale Heights', region: 'IL', country: 'US' },
  { name: 'Gary', region: 'IN', country: 'US' },
  { name: 'Hamilton', region: 'OH', country: 'US' },
  { name: 'Hampton', region: 'VA', country: 'US' },
  { name: 'Hanford', region: 'CA', country: 'US' },
  { name: 'Harlingen', region: 'TX', country: 'US' },
  { name: 'Harrisonburg', region: 'VA', country: 'US' },
  { name: 'Haverhill', region: 'MA', country: 'US' },
  { name: 'Hawthorne', region: 'CA', country: 'US' },
  { name: 'Hemet', region: 'CA', country: 'US' },
  { name: 'Hempstead village', region: 'NY', country: 'US' },
  { name: 'Henderson', region: 'NV', country: 'US' },
  { name: 'Hesperia', region: 'CA', country: 'US' },
  { name: 'High Point', region: 'NC', country: 'US' },
  { name: 'Highland', region: 'CA', country: 'US' },
  { name: 'Hillsboro', region: 'OR', country: 'US' },
  { name: 'Hilo', region: 'HI', country: 'US' },
  { name: 'Hoboken', region: 'NJ', country: 'US' },
  { name: 'Hoffman Estates village', region: 'IL', country: 'US' },
  { name: 'Honolulu', region: 'HI', country: 'US' },
  { name: 'Hoover', region: 'AL', country: 'US' },
  { name: 'Huntersville town', region: 'NC', country: 'US' },
  { name: 'Huntington Park', region: 'CA', country: 'US' },
  { name: 'Huntsville', region: 'AL', country: 'US' },
  { name: 'Harrisburg', region: 'PA', country: 'US' },
  { name: 'Hayward', region: 'CA', country: 'US' },
  { name: 'Hazleton', region: 'PA', country: 'US' },
  { name: 'Hollywood', region: 'FL', country: 'US' },
  { name: 'Huntington Beach', region: 'CA', country: 'US' },
  { name: 'Houston', region: 'TX', country: 'US' },
  { name: 'Hyattsville', region: 'MD', country: 'US' },
  { name: 'Hattiesburg', region: 'MS', country: 'US' },
  { name: 'Hialeah', region: 'FL', country: 'US' },
  { name: 'Hurleyville', region: 'NY', country: 'US' },
  { name: 'Heflin', region: 'AL', country: 'US' },
  { name: 'Harrisburg', region: 'NC', country: 'US' },
  { name: 'Havertown', region: 'PA', country: 'US' },
  { name: 'Hellertown', region: 'PA', country: 'US' },
  { name: 'Howell', region: 'MI', country: 'US' },
  { name: 'Hopewell Junction', region: 'NY', country: 'US' },
  { name: 'Hamilton', region: 'NY', country: 'US' },
  { name: 'Idaho Falls', region: 'ID', country: 'US' },
  { name: 'Inglewood', region: 'CA', country: 'US' },
  { name: 'Iowa City', region: 'IA', country: 'US' },
  { name: 'Indio', region: 'CA', country: 'US' },
  { name: 'Irvine', region: 'CA', country: 'US' },
  { name: 'Indianapolis', region: 'IN', country: 'US' },
  { name: 'Inman', region: 'SC', country: 'US' },
  { name: 'Irving', region: 'TX', country: 'US' },
  { name: 'Independence', region: 'MO', country: 'US' },
  { name: 'Ilion', region: 'NY', country: 'US' },
  { name: 'Jacksonville', region: 'NC', country: 'US' },
  { name: 'Janesville', region: 'WI', country: 'US' },
  { name: 'Johns Creek', region: 'GA', country: 'US' },
  { name: 'Joplin', region: 'MO', country: 'US' },
  { name: 'Jurupa Valley', region: 'CA', country: 'US' },
  { name: 'Jersey City', region: 'NJ', country: 'US' },
  { name: 'Jackson', region: 'MS', country: 'US' },
  { name: 'Jones', region: 'MI', country: 'US' },
  { name: 'Jacksonville', region: 'FL', country: 'US' },
  { name: 'Jackson', region: 'GA', country: 'US' },
  { name: 'Jamaica', region: 'NY', country: 'US' },
  { name: 'Joliet', region: 'IL', country: 'US' },
  { name: 'Kahului', region: 'HI', country: 'US' },
  { name: 'Kailu', region: 'HI', country: 'US' },
  { name: 'Kalamazoo', region: 'MI', country: 'US' },
  { name: 'Kaneohe', region: 'HI', country: 'US' },
  { name: 'Kenosha', region: 'WI', country: 'US' },
  { name: 'Kentwood', region: 'MI', country: 'US' },
  { name: 'Kettering', region: 'OH', country: 'US' },
  { name: 'Killeen', region: 'TX', country: 'US' },
  { name: 'Kansas City', region: 'KS', country: 'US' },
  { name: 'Kansas City', region: 'MO', country: 'US' },
  { name: 'Knoxville', region: 'TN', country: 'US' },
  { name: 'Kokomo', region: 'IN', country: 'US' },
  { name: 'King George', region: 'VA', country: 'US' },
  { name: 'Kent', region: 'OH', country: 'US' },
  { name: 'Kennesaw', region: 'GA', country: 'US' },
  { name: 'King William', region: 'VA', country: 'US' },
  { name: 'Kissimmee', region: 'FL', country: 'US' },
  { name: 'Katy', region: 'TX', country: 'US' },
  { name: 'La Crosse', region: 'WI', country: 'US' },
  { name: 'La Habra', region: 'CA', country: 'US' },
  { name: 'La Mesa', region: 'CA', country: 'US' },
  { name: 'Laguna Niguel', region: 'CA', country: 'US' },
  { name: 'Lake Elsinore', region: 'CA', country: 'US' },
  { name: 'Lake Forest', region: 'CA', country: 'US' },
  { name: 'Lakeville', region: 'MN', country: 'US' },
  { name: 'Lakewood', region: 'CA', country: 'US' },
  { name: 'Lakewood', region: 'CO', country: 'US' },
  { name: 'Lakewood', region: 'OH', country: 'US' },
  { name: 'Lancaster', region: 'CA', country: 'US' },
  { name: 'Laredo', region: 'TX', country: 'US' },
  { name: 'Las Cruces', region: 'NM', country: 'US' },
  { name: 'Lawrence', region: 'KS', country: 'US' },
  { name: 'Lawrence', region: 'MA', country: 'US' },
  { name: 'Lawton', region: 'OK', country: 'US' },
  { name: 'Layton', region: 'UT', country: 'US' },
  { name: 'League City', region: 'TX', country: 'US' },
  { name: "Lee's Summit", region: 'MO', country: 'US' },
  { name: 'Leesburg town', region: 'VA', country: 'US' },
  { name: 'Lehi', region: 'UT', country: 'US' },
  { name: 'Lewisville', region: 'TX', country: 'US' },
  { name: 'Lexington-Fayette urban county', region: 'KY', country: 'US' },
  { name: 'Livermore', region: 'CA', country: 'US' },
  { name: 'Lodi', region: 'CA', country: 'US' },
  { name: 'Logan', region: 'UT', country: 'US' },
  { name: 'Longmont', region: 'CO', country: 'US' },
  { name: 'Longview', region: 'TX', country: 'US' },
  { name: 'Lorain', region: 'OH', country: 'US' },
  { name: 'Louisville/Jefferson County metro government', region: 'KY', country: 'US' },
  { name: 'Loveland', region: 'CO', country: 'US' },
  { name: 'Lubbock', region: 'TX', country: 'US' },
  { name: 'Lynchburg', region: 'VA', country: 'US' },
  { name: 'Lynn', region: 'MA', country: 'US' },
  { name: 'Lynwood', region: 'CA', country: 'US' },
  { name: 'La Quinta', region: 'CA', country: 'US' },
  { name: 'Laguna Beach', region: 'CA', country: 'US' },
  { name: 'Long Beach', region: 'CA', country: 'US' },
  { name: 'Los Altos', region: 'CA', country: 'US' },
  { name: 'Los Angeles', region: 'CA', country: 'US' },
  { name: 'Lansing', region: 'MI', country: 'US' },
  { name: 'Lake Mary', region: 'FL', country: 'US' },
  { name: 'Louisville', region: 'KY', country: 'US' },
  { name: 'Las Vegas', region: 'NV', country: 'US' },
  { name: 'Livonia', region: 'MI', country: 'US' },
  { name: 'Lowell', region: 'MA', country: 'US' },
  { name: 'Lancaster', region: 'PA', country: 'US' },
  { name: 'Lexington', region: 'KY', country: 'US' },
  { name: 'Lakeland', region: 'FL', country: 'US' },
  { name: 'Lincolnton', region: 'NC', country: 'US' },
  { name: 'Lenexa', region: 'KS', country: 'US' },
  { name: 'Lawrenceville', region: 'GA', country: 'US' },
  { name: 'Laurel', region: 'MD', country: 'US' },
  { name: 'Lombard', region: 'IL', country: 'US' },
  { name: 'Lisbon', region: 'OH', country: 'US' },
  { name: 'Lincoln', region: 'NE', country: 'US' },
  { name: 'Landis', region: 'NC', country: 'US' },
  { name: 'Lexington Park', region: 'MD', country: 'US' },
  { name: 'Little Rock', region: 'AR', country: 'US' },
  { name: 'Live Oak', region: 'FL', country: 'US' },
  { name: 'Macon-Bibb County', region: 'GA', country: 'US' },
  { name: 'Madera', region: 'CA', country: 'US' },
  { name: 'Malden', region: 'MA', country: 'US' },
  { name: 'Manhattan', region: 'KS', country: 'US' },
  { name: 'Mansfield', region: 'TX', country: 'US' },
  { name: 'Maple Grove', region: 'MN', country: 'US' },
  { name: 'McAllen', region: 'TX', country: 'US' },
  { name: 'McKinney', region: 'TX', country: 'US' },
  { name: 'Medford', region: 'OR', country: 'US' },
  { name: 'Menifee', region: 'CA', country: 'US' },
  { name: 'Merced', region: 'CA', country: 'US' },
  { name: 'Meridian', region: 'ID', country: 'US' },
  { name: 'Mesquite', region: 'TX', country: 'US' },
  { name: 'Midland', region: 'TX', country: 'US' },
  { name: 'Midwest City', region: 'OK', country: 'US' },
  { name: 'Mililani Town', region: 'HI', country: 'US' },
  { name: 'Milpitas', region: 'CA', country: 'US' },
  { name: 'Minnetonka', region: 'MN', country: 'US' },
  { name: 'Mission Viejo', region: 'CA', country: 'US' },
  { name: 'Mission', region: 'TX', country: 'US' },
  { name: 'Missouri City', region: 'TX', country: 'US' },
  { name: 'Modesto', region: 'CA', country: 'US' },
  { name: 'Montebello', region: 'CA', country: 'US' },
  { name: 'Monterey Park', region: 'CA', country: 'US' },
  { name: 'Moore', region: 'OK', country: 'US' },
  { name: 'Moreno Valley', region: 'CA', country: 'US' },
  { name: 'Mount Prospect village', region: 'IL', country: 'US' },
  { name: 'Mount Vernon', region: 'NY', country: 'US' },
  { name: 'Murrieta', region: 'CA', country: 'US' },
  { name: 'Manchester', region: 'NH', country: 'US' },
  { name: 'Menlo Park', region: 'CA', country: 'US' },
  { name: 'Millbrae', region: 'CA', country: 'US' },
  { name: 'Mont Alto', region: 'PA', country: 'US' },
  { name: 'Mountain View', region: 'CA', country: 'US' },
  { name: 'Manassas', region: 'VA', country: 'US' },
  { name: 'Minneapolis', region: 'MN', country: 'US' },
  { name: 'Miami', region: 'FL', country: 'US' },
  { name: 'Milwaukee', region: 'WI', country: 'US' },
  { name: 'Marietta', region: 'GA', country: 'US' },
  { name: 'Madison', region: 'WI', country: 'US' },
  { name: 'Memphis', region: 'TN', country: 'US' },
  { name: 'Mcdonough', region: 'GA', country: 'US' },
  { name: 'Maryville', region: 'TN', country: 'US' },
  { name: 'Mesa', region: 'AZ', country: 'US' },
  { name: 'Murfreesboro', region: 'TN', country: 'US' },
  { name: 'Mooresville', region: 'NC', country: 'US' },
  { name: 'Mableton', region: 'GA', country: 'US' },
  { name: 'Middletown', region: 'CT', country: 'US' },
  { name: 'Mason', region: 'MI', country: 'US' },
  { name: 'Monroe', region: 'NC', country: 'US' },
  { name: 'Marion', region: 'OH', country: 'US' },
  { name: 'Melrose Park', region: 'IL', country: 'US' },
  { name: 'Manteca', region: 'CA', country: 'US' },
  { name: 'Mount Gilead', region: 'NC', country: 'US' },
  { name: 'Montgomery', region: 'AL', country: 'US' },
  { name: 'Middletown', region: 'OH', country: 'US' },
  { name: 'Macon', region: 'GA', country: 'US' },
  { name: 'Marlborough', region: 'MA', country: 'US' },
  { name: 'Mobile', region: 'AL', country: 'US' },
  { name: 'Meriden', region: 'CT', country: 'US' },
  { name: 'Medford', region: 'MA', country: 'US' },
  { name: 'Mantua', region: 'OH', country: 'US' },
  { name: 'Mound', region: 'MN', country: 'US' },
  { name: 'Monroe', region: 'NY', country: 'US' },
  { name: 'Moville', region: 'IA', country: 'US' },
  { name: 'Milner', region: 'GA', country: 'US' },
  { name: 'Nampa', region: 'ID', country: 'US' },
  { name: 'National City', region: 'CA', country: 'US' },
  { name: 'New Bedford', region: 'MA', country: 'US' },
  { name: 'New Braunfels', region: 'TX', country: 'US' },
  { name: 'New Brunswick', region: 'NJ', country: 'US' },
  { name: 'New Rochelle', region: 'NY', country: 'US' },
  { name: 'Newport News', region: 'VA', country: 'US' },
  { name: 'Newton', region: 'MA', country: 'US' },
  { name: 'Niagara Falls', region: 'NY', country: 'US' },
  { name: 'Normal town', region: 'IL', country: 'US' },
  { name: 'Norman', region: 'OK', country: 'US' },
  { name: 'North Las Vegas', region: 'NV', country: 'US' },
  { name: 'North Richland Hills', region: 'TX', country: 'US' },
  { name: 'Norwalk', region: 'CA', country: 'US' },
  { name: 'Novato', region: 'CA', country: 'US' },
  { name: 'Novi', region: 'MI', country: 'US' },
  { name: 'Napa', region: 'CA', country: 'US' },
  { name: 'New Kensington', region: 'PA', country: 'US' },
  { name: 'Newport Beach', region: 'CA', country: 'US' },
  { name: 'New York', region: 'NY', country: 'US' },
  { name: 'Newark', region: 'NJ', country: 'US' },
  { name: 'Naperville', region: 'IL', country: 'US' },
  { name: 'Newark', region: 'CA', country: 'US' },
  { name: 'Norfolk', region: 'VA', country: 'US' },
  { name: 'Newnan', region: 'GA', country: 'US' },
  { name: 'Nashville', region: 'TN', country: 'US' },
  { name: 'Newark', region: 'DE', country: 'US' },
  { name: 'New Orleans', region: 'LA', country: 'US' },
  { name: 'New Castle', region: 'DE', country: 'US' },
  { name: 'Nashua', region: 'NH', country: 'US' },
  { name: 'New Britain', region: 'CT', country: 'US' },
  { name: 'Nescopeck', region: 'PA', country: 'US' },
  { name: 'New Lenox', region: 'IL', country: 'US' },
  { name: 'North Fort Myers', region: 'FL', country: 'US' },
  { name: "O'Fallon", region: 'MO', country: 'US' },
  { name: 'Oak Lawn village', region: 'IL', country: 'US' },
  { name: 'Oak Park village', region: 'IL', country: 'US' },
  { name: 'Oceanside', region: 'CA', country: 'US' },
  { name: 'Odessa', region: 'TX', country: 'US' },
  { name: 'Ogden', region: 'UT', country: 'US' },
  { name: 'Olathe', region: 'KS', country: 'US' },
  { name: 'Ontario', region: 'CA', country: 'US' },
  { name: 'Orange', region: 'CA', country: 'US' },
  { name: 'Orem', region: 'UT', country: 'US' },
  { name: 'Orland Park village', region: 'IL', country: 'US' },
  { name: 'Oshkosh', region: 'WI', country: 'US' },
  { name: 'Overland Park', region: 'KS', country: 'US' },
  { name: 'Owensboro', region: 'KY', country: 'US' },
  { name: 'Oxnard', region: 'CA', country: 'US' },
  { name: 'Orlando', region: 'FL', country: 'US' },
  { name: 'Omaha', region: 'NE', country: 'US' },
  { name: 'Orrville', region: 'OH', country: 'US' },
  { name: 'Oklahoma City', region: 'OK', country: 'US' },
  { name: 'Oxford', region: 'MI', country: 'US' },
  { name: 'Oswego', region: 'IL', country: 'US' },
  { name: 'Oneonta', region: 'NY', country: 'US' },
  { name: 'Oakland', region: 'CA', country: 'US' },
  { name: 'Palatine village', region: 'IL', country: 'US' },
  { name: 'Palmdale', region: 'CA', country: 'US' },
  { name: 'Paramount', region: 'CA', country: 'US' },
  { name: 'Parker town', region: 'CO', country: 'US' },
  { name: 'Parma', region: 'OH', country: 'US' },
  { name: 'Pasadena', region: 'CA', country: 'US' },
  { name: 'Pasadena', region: 'TX', country: 'US' },
  { name: 'Passaic', region: 'NJ', country: 'US' },
  { name: 'Paterson', region: 'NJ', country: 'US' },
  { name: 'Pawtucket', region: 'RI', country: 'US' },
  { name: 'Peabody', region: 'MA', country: 'US' },
  { name: 'Pearl City', region: 'HI', country: 'US' },
  { name: 'Pearland', region: 'TX', country: 'US' },
  { name: 'Peoria', region: 'IL', country: 'US' },
  { name: 'Perris', region: 'CA', country: 'US' },
  { name: 'Perth Amboy', region: 'NJ', country: 'US' },
  { name: 'Pflugerville', region: 'TX', country: 'US' },
  { name: 'Pharr', region: 'TX', country: 'US' },
  { name: 'Pico Rivera', region: 'CA', country: 'US' },
  { name: 'Pittsburg', region: 'CA', country: 'US' },
  { name: 'Placentia', region: 'CA', country: 'US' },
  { name: 'Plainfield', region: 'NJ', country: 'US' },
  { name: 'Pocatello', region: 'ID', country: 'US' },
  { name: 'Pomona', region: 'CA', country: 'US' },
  { name: 'Pontiac', region: 'MI', country: 'US' },
  { name: 'Port Arthur', region: 'TX', country: 'US' },
  { name: 'Porterville', region: 'CA', country: 'US' },
  { name: 'Portsmouth', region: 'VA', country: 'US' },
  { name: 'Poway', region: 'CA', country: 'US' },
  { name: 'Provo', region: 'UT', country: 'US' },
  { name: 'Pueblo', region: 'CO', country: 'US' },
  { name: 'Petaluma', region: 'CA', country: 'US' },
  { name: 'Palm Desert', region: 'CA', country: 'US' },
  { name: 'Palm Springs', region: 'CA', country: 'US' },
  { name: 'Palo Alto', region: 'CA', country: 'US' },
  { name: 'Philadelphia', region: 'PA', country: 'US' },
  { name: 'Phoenix', region: 'AZ', country: 'US' },
  { name: 'Pittsburgh', region: 'PA', country: 'US' },
  { name: 'Portland', region: 'OR', country: 'US' },
  { name: 'Poughkeepsie', region: 'NY', country: 'US' },
  { name: 'Prattville', region: 'AL', country: 'US' },
  { name: 'Phenix City', region: 'AL', country: 'US' },
  { name: 'Plymouth', region: 'MN', country: 'US' },
  { name: 'Pompano Beach', region: 'FL', country: 'US' },
  { name: 'Plano', region: 'TX', country: 'US' },
  { name: 'Plymouth', region: 'MI', country: 'US' },
  { name: 'Pleasanton', region: 'CA', country: 'US' },
  { name: 'Princeton', region: 'IL', country: 'US' },
  { name: 'Paris', region: 'OH', country: 'US' },
  { name: 'Providence', region: 'RI', country: 'US' },
  { name: 'Port Republic', region: 'MD', country: 'US' },
  { name: 'Quincy', region: 'MA', country: 'US' },
  { name: 'Queens', region: 'NY', country: 'US' },
  { name: 'Racine', region: 'WI', country: 'US' },
  { name: 'Rancho Cordova', region: 'CA', country: 'US' },
  { name: 'Rancho Cucamonga', region: 'CA', country: 'US' },
  { name: 'Redding', region: 'CA', country: 'US' },
  { name: 'Redlands', region: 'CA', country: 'US' },
  { name: 'Redondo Beach', region: 'CA', country: 'US' },
  { name: 'Reno', region: 'NV', country: 'US' },
  { name: 'Revere', region: 'MA', country: 'US' },
  { name: 'Rialto', region: 'CA', country: 'US' },
  { name: 'Richardson', region: 'TX', country: 'US' },
  { name: 'Richmond', region: 'CA', country: 'US' },
  { name: 'Rio Rancho', region: 'NM', country: 'US' },
  { name: 'Roanoke', region: 'VA', country: 'US' },
  { name: 'Rochester Hills', region: 'MI', country: 'US' },
  { name: 'Rochester', region: 'MN', country: 'US' },
  { name: 'Rocklin', region: 'CA', country: 'US' },
  { name: 'Rocky Mount', region: 'NC', country: 'US' },
  { name: 'Rosemead', region: 'CA', country: 'US' },
  { name: 'Roseville', region: 'CA', country: 'US' },
  { name: 'Round Rock', region: 'TX', country: 'US' },
  { name: 'Rowlett', region: 'TX', country: 'US' },
  { name: 'Rancho Mirage', region: 'CA', country: 'US' },
  { name: 'Redwood City', region: 'CA', country: 'US' },
  { name: 'Rochester', region: 'NH', country: 'US' },
  { name: 'Richmond', region: 'VA', country: 'US' },
  { name: 'Riverdale', region: 'MD', country: 'US' },
  { name: 'Rushland', region: 'PA', country: 'US' },
  { name: 'Raleigh', region: 'NC', country: 'US' },
  { name: 'Robbins', region: 'NC', country: 'US' },
  { name: 'Rockford', region: 'IL', country: 'US' },
  { name: 'Rochester', region: 'NY', country: 'US' },
  { name: 'Roswell', region: 'GA', country: 'US' },
  { name: 'Romulus', region: 'MI', country: 'US' },
  { name: 'Rockville', region: 'VA', country: 'US' },
  { name: 'Rock Hill', region: 'SC', country: 'US' },
  { name: 'Royal Oak', region: 'MI', country: 'US' },
  { name: 'Riverside', region: 'CA', country: 'US' },
  { name: 'Reading', region: 'PA', country: 'US' },
  { name: 'Ridgewood', region: 'NY', country: 'US' },
  { name: 'Saginaw', region: 'MI', country: 'US' },
  { name: 'Saint Louis', region: 'MO', country: 'US' },
  { name: 'Salem', region: 'OR', country: 'US' },
  { name: 'Salinas', region: 'CA', country: 'US' },
  { name: 'San Angelo', region: 'TX', country: 'US' },
  { name: 'San Bernardino', region: 'CA', country: 'US' },
  { name: 'San Buenaventura (Ventura)', region: 'CA', country: 'US' },
  { name: 'San Clemente', region: 'CA', country: 'US' },
  { name: 'San Leandro', region: 'CA', country: 'US' },
  { name: 'San Marcos', region: 'CA', country: 'US' },
  { name: 'San Marcos', region: 'TX', country: 'US' },
  { name: 'San Rafael', region: 'CA', country: 'US' },
  { name: 'San Ramon', region: 'CA', country: 'US' },
  { name: 'Sandy Springs', region: 'GA', country: 'US' },
  { name: 'Sandy', region: 'UT', country: 'US' },
  { name: 'Santa Barbara', region: 'CA', country: 'US' },
  { name: 'Santa Clarita', region: 'CA', country: 'US' },
  { name: 'Santa Cruz', region: 'CA', country: 'US' },
  { name: 'Santa Fe', region: 'NM', country: 'US' },
  { name: 'Santa Maria', region: 'CA', country: 'US' },
  { name: 'Santee', region: 'CA', country: 'US' },
  { name: 'Schaumburg village', region: 'IL', country: 'US' },
  { name: 'Schenectady', region: 'NY', country: 'US' },
  { name: 'Scranton', region: 'PA', country: 'US' },
  { name: 'Shawnee', region: 'KS', country: 'US' },
  { name: 'Simi Valley', region: 'CA', country: 'US' },
  { name: 'Sioux City', region: 'IA', country: 'US' },
  { name: 'Skokie village', region: 'IL', country: 'US' },
  { name: 'Somerville', region: 'MA', country: 'US' },
  { name: 'South Gate', region: 'CA', country: 'US' },
  { name: 'South Jordan', region: 'UT', country: 'US' },
  { name: 'South San Francisco', region: 'CA', country: 'US' },
  { name: 'Southaven', region: 'MS', country: 'US' },
  { name: 'Sparks', region: 'NV', country: 'US' },
  { name: 'Springfield', region: 'OH', country: 'US' },
  { name: 'Springfield', region: 'OR', country: 'US' },
  { name: 'St. Charles', region: 'MO', country: 'US' },
  { name: 'St. Clair Shores', region: 'MI', country: 'US' },
  { name: 'St. Cloud', region: 'MN', country: 'US' },
  { name: 'St. George', region: 'UT', country: 'US' },
  { name: 'St. Joseph', region: 'MO', country: 'US' },
  { name: 'St. Louis', region: 'MO', country: 'US' },
  { name: 'St. Paul', region: 'MN', country: 'US' },
  { name: 'St. Peters', region: 'MO', country: 'US' },
  { name: 'Sterling Heights', region: 'MI', country: 'US' },
  { name: 'Suffolk', region: 'VA', country: 'US' },
  { name: 'Sugar Land', region: 'TX', country: 'US' },
  { name: 'Salem', region: 'NH', country: 'US' },
  { name: 'San Bruno', region: 'CA', country: 'US' },
  { name: 'San Mateo', region: 'CA', country: 'US' },
  { name: 'Santa Ana', region: 'CA', country: 'US' },
  { name: 'Santa Clara', region: 'CA', country: 'US' },
  { name: 'Santa Monica', region: 'CA', country: 'US' },
  { name: 'Santa Rosa', region: 'CA', country: 'US' },
  { name: 'Sonoma', region: 'CA', country: 'US' },
  { name: 'Springfield', region: 'IL', country: 'US' },
  { name: 'Springfield', region: 'MA', country: 'US' },
  { name: 'Springfield', region: 'MO', country: 'US' },
  { name: 'Staten Island', region: 'NY', country: 'US' },
  { name: 'Sunnyvale', region: 'CA', country: 'US' },
  { name: 'Seattle', region: 'WA', country: 'US' },
  { name: 'Southfield', region: 'MI', country: 'US' },
  { name: 'Saddle Brook', region: 'NJ', country: 'US' },
  { name: 'Stockton', region: 'CA', country: 'US' },
  { name: 'Stone Mountain', region: 'GA', country: 'US' },
  { name: 'Saint Paul', region: 'MN', country: 'US' },
  { name: 'San Antonio', region: 'TX', country: 'US' },
  { name: 'Silver Spring', region: 'MD', country: 'US' },
  { name: 'Syracuse', region: 'NY', country: 'US' },
  { name: 'South Lyon', region: 'MI', country: 'US' },
  { name: 'San Diego', region: 'CA', country: 'US' },
  { name: 'Spring', region: 'TX', country: 'US' },
  { name: 'Sevierville', region: 'TN', country: 'US' },
  { name: 'San Jose', region: 'CA', country: 'US' },
  { name: 'Stockbridge', region: 'GA', country: 'US' },
  { name: 'Salt Lake City', region: 'UT', country: 'US' },
  { name: 'Statesville', region: 'NC', country: 'US' },
  { name: 'San Francisco', region: 'CA', country: 'US' },
  { name: 'Sioux Falls', region: 'SD', country: 'US' },
  { name: 'Smyrna', region: 'GA', country: 'US' },
  { name: 'Sacramento', region: 'CA', country: 'US' },
  { name: 'Sylacauga', region: 'AL', country: 'US' },
  { name: 'Sharon', region: 'SC', country: 'US' },
  { name: 'Salisbury', region: 'NC', country: 'US' },
  { name: 'Spring Hill', region: 'FL', country: 'US' },
  { name: 'Saint Pauls', region: 'NC', country: 'US' },
  { name: 'Simpsonville', region: 'SC', country: 'US' },
  { name: 'Shreve', region: 'OH', country: 'US' },
  { name: 'Scottsdale', region: 'AZ', country: 'US' },
  { name: 'Spotsylvania', region: 'VA', country: 'US' },
  { name: 'Southbury', region: 'CT', country: 'US' },
  { name: 'Savannah', region: 'GA', country: 'US' },
  { name: 'Taunton', region: 'MA', country: 'US' },
  { name: 'Taylor', region: 'MI', country: 'US' },
  { name: 'Taylorsville', region: 'UT', country: 'US' },
  { name: 'Temecula', region: 'CA', country: 'US' },
  { name: 'Temple', region: 'TX', country: 'US' },
  { name: 'Thornton', region: 'CO', country: 'US' },
  { name: 'Thousand Oaks', region: 'CA', country: 'US' },
  { name: 'Tigard', region: 'OR', country: 'US' },
  { name: 'Tinley Park village', region: 'IL', country: 'US' },
  { name: 'Torrance', region: 'CA', country: 'US' },
  { name: 'Tracy', region: 'CA', country: 'US' },
  { name: 'Troy', region: 'MI', country: 'US' },
  { name: 'Tulare', region: 'CA', country: 'US' },
  { name: 'Turlock', region: 'CA', country: 'US' },
  { name: 'Tuscaloosa', region: 'AL', country: 'US' },
  { name: 'Tyler', region: 'TX', country: 'US' },
  { name: 'Tustin', region: 'CA', country: 'US' },
  { name: 'Tampa', region: 'FL', country: 'US' },
  { name: 'Taylors', region: 'SC', country: 'US' },
  { name: 'Toledo', region: 'OH', country: 'US' },
  { name: 'Terre Haute', region: 'IN', country: 'US' },
  { name: 'Troy', region: 'NY', country: 'US' },
  { name: 'Temperance', region: 'MI', country: 'US' },
  { name: 'Temple', region: 'GA', country: 'US' },
  { name: 'Tacoma', region: 'WA', country: 'US' },
  { name: 'Temple Hills', region: 'MD', country: 'US' },
  { name: 'Tallahassee', region: 'FL', country: 'US' },
  { name: 'Topeka', region: 'KS', country: 'US' },
  { name: 'Torrington', region: 'CT', country: 'US' },
  { name: 'Tucson', region: 'AZ', country: 'US' },
  { name: 'Tinley Park', region: 'IL', country: 'US' },
  { name: 'Trenton', region: 'NJ', country: 'US' },
  { name: 'Tempe', region: 'AZ', country: 'US' },
  { name: 'Tulsa', region: 'OK', country: 'US' },
  { name: 'Union City', region: 'CA', country: 'US' },
  { name: 'Union City', region: 'NJ', country: 'US' },
  { name: 'Upland', region: 'CA', country: 'US' },
  { name: 'Urban Honolulu CDP', region: 'HI', country: 'US' },
  { name: 'Utica', region: 'NY', country: 'US' },
  { name: 'Uniontown', region: 'PA', country: 'US' },
  { name: 'Vacaville', region: 'CA', country: 'US' },
  { name: 'Valdosta', region: 'GA', country: 'US' },
  { name: 'Victoria', region: 'TX', country: 'US' },
  { name: 'Victorville', region: 'CA', country: 'US' },
  { name: 'Vineland', region: 'NJ', country: 'US' },
  { name: 'Visalia', region: 'CA', country: 'US' },
  { name: 'Vista', region: 'CA', country: 'US' },
  { name: 'Vallejo', region: 'CA', country: 'US' },
  { name: 'Virginia Beach', region: 'VA', country: 'US' },
  { name: 'Valrico', region: 'FL', country: 'US' },
  { name: 'Vancouver', region: 'WA', country: 'US' },
  { name: 'Waco', region: 'TX', country: 'US' },
  { name: 'Waipahu', region: 'HI', country: 'US' },
  { name: 'Waltham', region: 'MA', country: 'US' },
  { name: 'Warner Robins', region: 'GA', country: 'US' },
  { name: 'Warren', region: 'MI', country: 'US' },
  { name: 'Warwick', region: 'RI', country: 'US' },
  { name: 'Waterloo', region: 'IA', country: 'US' },
  { name: 'Watsonville', region: 'CA', country: 'US' },
  { name: 'Waukesha', region: 'WI', country: 'US' },
  { name: 'Wesley Chapel', region: 'FL', country: 'US' },
  { name: 'West Allis', region: 'WI', country: 'US' },
  { name: 'West Covina', region: 'CA', country: 'US' },
  { name: 'West Des Moines', region: 'IA', country: 'US' },
  { name: 'West Jordan', region: 'UT', country: 'US' },
  { name: 'West New York town', region: 'NJ', country: 'US' },
  { name: 'West Sacramento', region: 'CA', country: 'US' },
  { name: 'West Valley City', region: 'UT', country: 'US' },
  { name: 'Westminster', region: 'CA', country: 'US' },
  { name: 'Westminster', region: 'CO', country: 'US' },
  { name: 'Weymouth Town', region: 'MA', country: 'US' },
  { name: 'Wheaton', region: 'IL', country: 'US' },
  { name: 'White Plains', region: 'NY', country: 'US' },
  { name: 'Whittier', region: 'CA', country: 'US' },
  { name: 'Wichita Falls', region: 'TX', country: 'US' },
  { name: 'Wilmington', region: 'NC', country: 'US' },
  { name: 'Winston-Salem', region: 'NC', country: 'US' },
  { name: 'Woodbury', region: 'MN', country: 'US' },
  { name: 'Woodland', region: 'CA', country: 'US' },
  { name: 'Wyoming', region: 'MI', country: 'US' },
  { name: 'Walnut Creek', region: 'CA', country: 'US' },
  { name: 'West Hollywood', region: 'CA', country: 'US' },
  { name: 'Wilmington', region: 'DE', country: 'US' },
  { name: 'Washington', region: 'DC', country: 'US' },
  { name: 'Woodstock', region: 'CT', country: 'US' },
  { name: 'Waukegan', region: 'IL', country: 'US' },
  { name: 'Wichita', region: 'KS', country: 'US' },
  { name: 'Westland', region: 'MI', country: 'US' },
  { name: 'Worcester', region: 'MA', country: 'US' },
  { name: 'Waxhaw', region: 'NC', country: 'US' },
  { name: 'Woodbridge', region: 'VA', country: 'US' },
  { name: 'West Palm Beach', region: 'FL', country: 'US' },
  { name: 'Worth', region: 'IL', country: 'US' },
  { name: 'Waterbury', region: 'CT', country: 'US' },
  { name: 'Weaver', region: 'AL', country: 'US' },
  { name: 'West Hartford', region: 'CT', country: 'US' },
  { name: 'Willingboro', region: 'NJ', country: 'US' },
  { name: 'White Lake', region: 'MI', country: 'US' },
  { name: 'Yonkers', region: 'NY', country: 'US' },
  { name: 'Yorba Linda', region: 'CA', country: 'US' },
  { name: 'Yuba City', region: 'CA', country: 'US' },
  { name: 'Yucaipa', region: 'CA', country: 'US' },
  { name: 'York', region: 'PA', country: 'US' },
  { name: 'Youngstown', region: 'OH', country: 'US' },
  { name: 'Ypsilanti', region: 'MI', country: 'US' },
  // Uruguay
  { name: 'Montevideo', region: '10', country: 'UY' },
  // Venezuela
  { name: 'Barquisimeto', region: '13', country: 'VE' },
  { name: 'Caracas', region: '25', country: 'VE' },
  { name: 'Ciudad Guayana', region: '06', country: 'VE' },
  { name: 'Maracaibo', region: '23', country: 'VE' },
  { name: 'Maturín', region: '16', country: 'VE' },
  { name: 'Valencia', region: '07', country: 'VE' },
  // Vietnam
  { name: 'Da Nang', region: '78', country: 'VN' },
  { name: 'Hai Phong', region: '13', country: 'VN' },
  { name: 'Hanoi', region: '44', country: 'VN' },
  { name: 'Ho Chi Minh', region: '20', country: 'VN' },
  // please add new cities above this line in alphabetical order by country code, then city name
  { name: 'Dubai', region: '03', country: 'AE' },
  { name: 'Istanbul', region: '34', country: 'TR' },
  { name: 'Santiago', region: '12', country: 'CL' },
  { name: 'Cairo', region: '11', country: 'EG' },
  { name: 'Tokyo', region: '40', country: 'JP' },
  { name: 'Beirut', region: '04', country: 'LB' },
  { name: 'Central District', region: '00', country: 'HK' },
  { name: 'Kuwait', region: '02', country: 'KW' },
  { name: 'Berlin', region: '16', country: 'DE' },
  { name: 'Munich', region: '02', country: 'DE' },
  { name: 'Vienna', region: '09', country: 'AT' },
  { name: 'Hamburg', region: '04', country: 'DE' },
  { name: 'Auckland', region: 'E7', country: 'NZ' },
  { name: 'Ramla', region: '02', country: 'IL' },
  { name: 'Stuttgart', region: '01', country: 'DE' },
  { name: 'San Juan', region: '00', country: 'PR' },
];

export default cities;
