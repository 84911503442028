/* eslint-disable react/display-name */
import React from 'react';
import { standardMetrics, LTVMetrics } from '../../Metrics/Metrics';

export const makeColumn = ({
  title,
  accessor,
  disableSort,
  ...other
}) => ({
  id: accessor,
  Header: title,
  sortable: !disableSort,
  accessor: (c) => (c.raw ? c.raw(accessor) : c),
  ...other,
});

export const makeWithMetricColumns = (columns) => {
  const metricsColumns = [
    ...standardMetrics.metrics,
    ...LTVMetrics.metrics,
  ].map((m) => ({
    ...makeColumn({ title: m.name, accessor: m.key }),
    Cell: ({ original }) => {
      const row = original.metrics || original;
      const content = m.getter ? m.getter(row) : row[m.key];
      return <span>{m.render(content)}</span>;
    },
  }));

  return columns.concat(metricsColumns);
};
