let serviceType;

export function setServiceType(type) {
  serviceType = type;
}

export function isAdvertiserService() {
  return serviceType === 'grow';
}

export function isAdminService() {
  return serviceType === 'ctrl';
}

export function getServiceType() {
  return serviceType;
}
