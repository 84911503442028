import {
  getExchangeConnections,
} from 'app/graphql/utils/exchangeconnection';
import Model from './BaseModel';

class ExchangeConnection extends Model {
}

ExchangeConnection.getAll = (filters) => getExchangeConnections({ ...{ page: 1, perPage: 10 }, ...filters });
ExchangeConnection.make = Model.make(ExchangeConnection);

export default ExchangeConnection;
