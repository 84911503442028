import React from 'react';
import { Redirect } from 'react-router-dom';
import { ListEmptyState } from 'components/V2/EmptyState/EmptyState';
import { compose, namespace } from '../../../../app/app';
import Application from '../../../../models/Application';
import './home.scss';

const ns = 'views.homepage.content';
const updateState = namespace(ns);
const props = (state) => state[ns];

const Home = () => (
  <div className="content__home">
    <ListEmptyState type="application" to="/campaigns/create/application" />
  </div>
);

class HomeContainer extends React.Component {
  componentDidMount() {
    this.props.actions.getApplications();
    try {
      window.analytics.page();
    } catch (ex) {
      // do nothing
    }
  }

  render() {
    if (this.props.numApps > 0) {
      return <Redirect to="/applications" />;
    }
    return <Home {...this.props} />;
  }
}

const getApplications = (() => {
  const action = () => Application.getAll();
  const end = ({ state, result }) => {
    const applicationsCount = result.response.length || 0;
    return {
      ...updateState(state, { numApps: applicationsCount }),
    };
  };
  return { action, end };
})();

const actions = {
  getApplications,
};

export default compose(HomeContainer, { props, actions });
