import React from 'react';
import ReactDOM from 'react-dom';
import './popover.scss';

const portalRoot = document.getElementById('portal-root');

export default class Popover extends React.Component {
  // instances are created on show, destroyed on hide
  constructor(props) {
    super(props);
    this.portalElement = document.createElement('div');

    const classNames = `${props.isTooltip ? 'component__tooltip' : 'component__popover'} ${props.className}`;
    this.portalElement.setAttribute('class', classNames);
  }

  componentDidMount() {
    portalRoot.appendChild(this.portalElement);
    if (this.props.isTooltip) {
      portalRoot.addEventListener('mouseover', this.show.bind(this));
    }
    this.portalElement.addEventListener('mouseleave', this.hide.bind(this));
    document.addEventListener('click', this.handleDocumentClick);
    if (this.props.adjustToScreen) {
      window.addEventListener('resize', this.calculatePosition.bind(this));
    }
  }

  componentWillUnmount() {
    // Remove the element from the DOM when we unmount
    if (this.props.isTooltip) {
      portalRoot.removeEventListener('mouseover', this.hide);
    }
    this.portalElement.removeEventListener('mouseleave', this.hide);
    portalRoot.removeChild(this.portalElement);
    document.removeEventListener('click', this.handleDocumentClick);
    if (this.props.adjustToScreen) {
      window.addEventListener('resize', this.calculatePosition);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  show() {
    // @TODO: Implement for tooltips
  }

  // Calls function that forces state change and re-render, which fires Portal.componentWillUnmount(), which prevents
  // portalRoot and portalElement from having components from multiple Popovers
  hide() {
    this.props.hide();
  }

  // Handle clicks on the document to check if they are outside the Popover
  handleDocumentClick = (event) => {
    if (!this.portalElement.contains(event.target) && this.props.onClickOutside) {
      this.props.onClickOutside();
    }
  };

  // This can be done in the constructor, modalRoot is used for popover so relative
  // position can be calculated from any arbitrary passed in node. For a tooltip,
  // position is always calculated from a child
  // @TODO: make generic lib functions, see: https://codepen.io/devhamsters/pen/yMProm
  calculatePosition() {
    const { modalRoot } = this.props;
    let { bottom, left } = modalRoot.getBoundingClientRect();
    const { width } = modalRoot.getBoundingClientRect();
    if (bottom === 0) {
      bottom = 137;
      left = 660;
    }
    const { adjustLeftPosition, adjustTopPosition } = this.props;
    let topPosition;
    let leftPosition;
    if (this.props.isTooltip) {
      topPosition = bottom + 16;
      leftPosition = (left + (width / 2)) - 125;
    } else {
      topPosition = bottom - 25 + adjustTopPosition;
      leftPosition = left + 10 + adjustLeftPosition;
      if (this.props.adjustToScreen) {
        const { innerHeight: windowHeight, innerWidth: windowWidth } = window;
        const { contentWidth, contentHeight } = this.props;
        if (topPosition + contentHeight > windowHeight) {
          topPosition = windowHeight - contentHeight - 10;
        }
        if (leftPosition + contentWidth > windowWidth) {
          leftPosition = windowWidth - contentWidth - 25;
        }
      }
    }
    portalRoot.style.top = `${topPosition}px`;
    portalRoot.style.left = `${leftPosition}px`;
  }

  render() {
    const { modalRoot, isOpen } = this.props;
    if (modalRoot && isOpen) {
      this.calculatePosition();
      // Use a portal to render the children into the element
      return ReactDOM.createPortal(
        // Any valid React child: JSX, strings, arrays, etc.
        this.props.children,
        // A DOM element (container)
        this.portalElement,
      );
    }
    return null;
  }
}

Popover.defaultProps = {
  isOpen: true,
  isTooltip: true,
  adjustLeftPosition: 0,
  adjustTopPosition: 0,
  adjustToScreen: false,
  contentWidth: 0,
  contentHeight: 0,
};
