export const CAMPAIGN_METRIC_KEY = 'campaign.metric';
export const APPLICATION_METRIC_KEY = 'application.metric';
export const APPLICATION_CAMPAIGN_LIST_METRIC_KEY = 'application.campaign.list.table.metric';
export const APPLICATION_CREATIVE_LIST_METRIC_KEY = 'application.creative.list.table.metric';
export const APPLICATION_CAMPAIGN_LIST_CACHE_KEY = 'application.campaign.list.cache';
export const APPLICATION_CREATIVE_LIST_CACHE_KEY = 'application.creative.list.cache';
export const APPLICATION_CAMPAIGN_LIST_PANEL_METIC_KEY = 'application.campaign.list.panel.metric';
export const APPLICATION_CAMPAIGN_CREATIVE_TAB_KEY = 'application.campaign.creative.tab';
export const CREATIVE_METRIC_KEY = 'creative.metric';
export const USER_FILTERS_KEY = 'user.filters';
export const USER_PAGE_SIZE_KEY = 'user.page.size';
export const CREATIVE_QA_FILTERS_KEY = 'creativeQA.filters';
export const CREATIVE_QA_PAGE_SIZE_KEY = 'creativeQA.page.size';
export const CREATIVE_QA_SORT_KEY = 'creativeQA.sort';
export const CREATIVE_QA_METRIC_KEY = 'creativeQA.metric';
export const REPORT_PANEL_METIC_KEY = 'report.panel.metric';
export const REPORT_TABLE_METIC_KEY = 'report.table.metric';
export const REPORT_PAGE_SIZE_KEY = 'report.page.size';
export const REPORT_CHART_COMPARE_KEY = 'report.chart.compare';
export const REPORTS_CACHE_KEY = 'reports.cache';
export const ASSET_FILTERS_KEY = 'asset.filters';
export const ASSET_PAGE_SIZE_KEY = 'asset.page.size';
export const ASSET_SORT_KEY = 'asset.sort';
export const ASSET_METRIC_KEY = 'asset.metric';
export const AC_DEPLOYMENT_PAGE_SIZE_KEY = 'acDeployment.page.size';
export const TOOLS_DEALS_FILTERS_KEY = 'tools.deals.filters';
export const TOOLS_EXCHANGE_FILTERS_KEY = 'tools.exchange.filters';
export const TOOLS_RTD_FILTERS_KEY = 'tools.rtb.filters';

// Cache keys used for grow.

export const GROW_APPLICATION_SORT_KEY = 'grow.application.sort.key';
export const GROW_APPLICATION_FILTER_KEY = 'grow.application.filter.key';
export const GROW_APPLICATION_PAGE_SIZE_KEY = 'grow.application.page.size.key';
export const GROW_APPLICATION_METRIC_KEY = 'grow.application.metric.key';

export const GROW_CAMPAIGN_SORT_KEY = 'grow.campaign.sort.key';
export const GROW_CAMPAIGN_FILTER_KEY = 'grow.campaign.filter.key';
export const GROW_CAMPAIGN_PAGE_SIZE_KEY = 'grow.campaign.page.size.key';
export const GROW_CAMPAIGN_METRIC_KEY = 'grow.campaign.metric.key';

export const GROW_CREATIVE_SORT_KEY = 'grow.creative.sort.key';
export const GROW_CREATIVE_FILTER_KEY = 'grow.creative.filter.key';
export const GROW_CREATIVE_PAGE_SIZE_KEY = 'grow.creative.page.size.key';
export const GROW_CREATIVE_METRIC_KEY = 'grow.creative.metric.key';

// Cache keys used for ctrl.

export const CTRL_APPLICATION_SORT_KEY = 'ctrl.application.sort.key';
export const CTRL_APPLICATION_FILTERS_KEY = 'ctrl.application.filters';
export const CTRL_APPLICATION_PAGE_SIZE_KEY = 'ctrl.application.page.size';
export const CTRL_APPLICATION_METRIC_KEY = 'ctrl.application.metric.key';

export const CTRL_CAMPAIGN_PAGE_SIZE_KEY = 'ctrl.campaign.page.size';
export const CTRL_CAMPAIGN_SORT_KEY = 'ctrl.campaign.sort';
export const CTRL_CAMPAIGN_FILTERS_KEY = 'ctrl.campaign.filters';
export const CTRL_CAMPAIGN_METRIC_KEY = 'ctrl.campaign.metric.key';

export const CTRL_CREATIVE_PAGE_SIZE_KEY = 'ctrl.creative.page.size';
export const CTRL_CREATIVE_SORT_KEY = 'ctrl.creative.sort';
export const CTRL_CREATIVE_FILTERS_KEY = 'ctrl.creative.filters';
export const CTRL_CREATIVE_METRIC_KEY = 'ctrl.creative.metric.key';

export const METRIC_TYPES = [
  CAMPAIGN_METRIC_KEY,
  APPLICATION_METRIC_KEY,
  CREATIVE_METRIC_KEY,
  REPORT_PANEL_METIC_KEY,
  REPORT_TABLE_METIC_KEY,
  REPORT_CHART_COMPARE_KEY,
  ASSET_METRIC_KEY,
  CREATIVE_QA_METRIC_KEY,
  GROW_APPLICATION_METRIC_KEY,
  GROW_CAMPAIGN_METRIC_KEY,
  GROW_CREATIVE_METRIC_KEY,
  CTRL_APPLICATION_METRIC_KEY,
  CTRL_CAMPAIGN_METRIC_KEY,
  CTRL_CREATIVE_METRIC_KEY,
];
