const tabs = [
  { title: 'Application', key: 'application' },
  { title: 'Campaign', key: 'campaign' },
  { title: 'Creative', key: 'creative' },
  { title: 'Country', key: 'country' },
  { title: 'Date', key: 'date' },
  { title: 'Platform', key: 'platform' },
  { title: 'Publisher Application', key: 'publisher' },
];

export default tabs;
