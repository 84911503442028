import React from 'react';
import Pill from '../pill/pill';
import Link from '../../components/Link/Link';
import './status.scss';

const Status = ({
  adminStatus, text, status, to, noBreak, onClick = () => {},
}) => (
  <div className="template__status">
    {text && to && (
      <Link to={to} onClick={onClick}>
        <p className={`p3 ${noBreak && 'no_break'}`}>{text}</p>
      </Link>
    )}
    {text && !to && (
      <p className="p2">{text}</p>
    )}
    <div className="pills">
      {adminStatus && adminStatus !== 'approved' && (
        <Pill status={adminStatus} />
      )}
      {(!adminStatus || adminStatus === 'approved') && (
        <Pill status={status} />
      )}
    </div>
  </div>
);

export default Status;
