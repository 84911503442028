import React, { config } from '../../../app/app';
import Select from '../Select/SelectContainer';
import './country.scss';

const { countries } = config.get('countryData');

const CountrySelect = ({ onChange, value, disabled = false }) => (
  <div className="components__form__country_select">
    <Select value={value} onChange={onChange} disabled={disabled} includeSearch>
      {countries.map(({ code, name }) => (
        <Select.Option key={code} value={code} searchValue={name}>{name}</Select.Option>
      ))}
    </Select>
  </div>
);

export default CountrySelect;
