import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Input from '../../Input/Input';
import Table from '../../Table/Table';
import {
  KEY_BID, KEY_GEO, KEY_PUB_ID, KEY_NAME,
  KEY_BOARD_ENTER, KEY_ROW_MSG, INLINE_EDIT_PLACEHOLDER,
  KEY_INVALID, DELETE_BIDDING_TITLE, DELETE_BIDDING_MSG, KEY_BOARD_ESC,
  MAX_BIDDING_ERROR,
} from '../constant';
import makeColumn from './columns';
import triggerConfirm from '../../../Modals/ConfirmAction/triggerConfirm';
import './editTable.scss';
import config from '../../../../lib/config';

const classPrefix = 'views__publishers__table';
const editCellPrefixCls = 'edit-cell';

const EditableCell = ({
  data, attri, onChange, validator, hasRowError,
  validateMsg, onInlineEdit, autoFocus: autoFocusProps,
  doesExceedMaxBiddingRows,
}) => {
  const isCurrency = attri === KEY_BID;
  const isAvailable = !data[KEY_INVALID];
  const [inputValue, setInputValue] = useState('');
  const [hasError, setHasError] = useState(false);
  const [inlineEdit, setInlineEdit] = useState(!isAvailable);
  const [autoFocus, setAutoFocus] = useState(!!autoFocusProps);
  const cellRef = React.useRef();

  useEffect(() => {
    const value = data[attri];
    setInputValue(value || '');
    if (doesExceedMaxBiddingRows) {
      setHasError(true);
      return;
    }
    if (!isAvailable) {
      const errors = validator(data, value, attri);
      // errors can be boolean if attri is rate
      if (typeof errors !== 'boolean') {
        // if has row error msg so need compare is value empty
        setHasError(errors[KEY_ROW_MSG] ? !value : errors[attri]);
      } else {
        setHasError(!errors);
      }
    }
    // --> potential bug inside this useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, attri, validator]);

  useEffect(() => {
    const groupNode = cellRef.current.closest('.rt-tr-group');
    const errorClassName = `${classPrefix}-group-error-${attri}`;
    if (hasError || hasRowError) {
      groupNode.classList.add(errorClassName);
    } else {
      groupNode.classList.remove(errorClassName);
    }
    if (isAvailable) {
      groupNode.classList.remove(
        `${classPrefix}-group-error-${KEY_PUB_ID}`,
        `${classPrefix}-group-error-${KEY_GEO}`,
      );
    }
    // --> potential bug inside this useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasError, hasRowError, isAvailable]);

  const saveInputValue = () => {
    const _data = data;
    if (isCurrency && isAvailable && hasError) {
      return _data;
    }
    _data[attri] = inputValue;
    return _data;
  };
  const handleOnBlur = () => {
    if (isAvailable && isCurrency) {
      onInlineEdit(false);
      setInputValue(data[attri]);
      setInlineEdit(false);
    }
    if (isAvailable) { // if not `new add row`, need to reset error to false
      setHasError(false);
    } else { // if `new add row` save input value to current (row) data
      Object.assign(data, { [attri]: inputValue });
    }
  };
  const handleEnterSave = (event) => {
    if (event.key === KEY_BOARD_ENTER) {
      const _data = saveInputValue();
      onChange(_data[attri]);
    } else if (event.key === KEY_BOARD_ESC) {
      handleOnBlur();
    }
  };
  const classes = classnames(`${editCellPrefixCls}-currency`, {
    [`${editCellPrefixCls}-valid`]: isAvailable,
    [`${editCellPrefixCls}-invalid`]: hasError && isCurrency,
  });
  const inputClasses = classnames(`${editCellPrefixCls}-input`, {
    [`${editCellPrefixCls}-input-invalid`]: hasError,
  });
  return (
    <div className={`${editCellPrefixCls}`} ref={cellRef}>
      {isCurrency && <span className={classes}>$</span>}
      {inlineEdit && (
        <Input
          className={inputClasses}
          placeholder={INLINE_EDIT_PLACEHOLDER[attri]}
          value={`${inputValue}`}
          onChange={(e) => {
            const { value } = e.target;
            if (attri === KEY_BID) {
              setHasError(!validator(data, value, attri));
            }
            setInputValue(value);
          }}
          onKeyDown={handleEnterSave}
          onBlur={handleOnBlur}
          autoFocus={autoFocus}
          disabled={doesExceedMaxBiddingRows}
        />
      )}
      {!inlineEdit && isAvailable && (
        <span
          className={`${editCellPrefixCls}-inline-edit-item`}
          onClick={() => {
            onInlineEdit(true);
            setAutoFocus(true);
            setInlineEdit(true);
          }}
        >
          <span className={`${editCellPrefixCls}-bid`}>
            {data[attri]}
          </span>
          <i className={`material-icons ${editCellPrefixCls}-icon`}>edit</i>
        </span>
      )}
      {!hasRowError && hasError && <div className={`${editCellPrefixCls}-error`}>{validateMsg(attri, data, inputValue)}</div>}
    </div>
  );
};
EditableCell.noWrapInTooltip = true;

export const ErrOfPublisherName = ({
  classes,
  bid,
  doesExceedMaxBiddingRows,
  inputErrors,
}) => (
  <div className={`${classPrefix}-pub-row`}>
    <span className={classes}>{bid[KEY_NAME]}</span>
    <div className={`${editCellPrefixCls}-error`}>
      {doesExceedMaxBiddingRows ? MAX_BIDDING_ERROR : inputErrors[KEY_ROW_MSG]}
    </div>
  </div>
);
ErrOfPublisherName.noWrapInTooltip = true;

EditableCell.noWrapInTooltip = true;

const EditTable = ({
  className,
  data: dataProp,
  campaign,
  pagination,
  onDelete,
  onPageSizeChange,
  onUpdateRowData,
  invalidMessage,
  inputValidator,
  rowValidator,
  onInlineEdit,
}) => {
  const [data, setData] = useState(dataProp);
  const [inputErrors, setInputErrors] = useState({});
  useEffect(() => {
    setData(dataProp);
  }, [dataProp]);

  const deleteMultiBiddingData = (isAll, bid) => {
    if (isAll) {
      triggerConfirm({
        type: 'DELETE_MULTI_BIDDING_CONFIRM_ACTION',
        header: DELETE_BIDDING_TITLE,
        message: DELETE_BIDDING_MSG,
        onConfirm: () => onDelete(true),
      });
    } else {
      // need remove error while deleting row
      setInputErrors({});
      onDelete(false, bid);
    }
  };
  const onEditSave = async (index, attri, value) => {
    const _data = [...data];
    const row = { ...data[index], [attri]: value };
    _data[index] = row;
    if (row[KEY_INVALID]) {
      const errors = await rowValidator(row);
      setInputErrors(errors);
      setData(_data);
      row[KEY_INVALID] = !!Object.keys(errors).length;
      if (!row[KEY_INVALID]) {
        onUpdateRowData(row, index);
      }
    } else if (inputValidator(data, value, attri)) {
      setData(_data);
      onUpdateRowData(row, index);
    }
  };
  const makeCell = (bid, attri, index) => {
    const isAvailable = !bid[KEY_INVALID];
    const doesExceedMaxBiddingRows = !isAvailable && campaign.get('budget.publisher_rates').length + 1 > config.get('multibidding.maxBidding');
    const hasRowError = !isAvailable && (doesExceedMaxBiddingRows || inputErrors[KEY_ROW_MSG]);
    if (attri === KEY_NAME) {
      const classes = classnames({
        [`${classPrefix}-pub-row-name`]: isAvailable,
      });
      if (!hasRowError) {
        return (
          <div className={`${classPrefix}-pub-row`}>
            <span className={classes}>{bid[attri]}</span>
          </div>
        );
      }
      return (
        <ErrOfPublisherName
          classes={classes}
          bid={bid}
          doesExceedMaxBiddingRows={doesExceedMaxBiddingRows}
          inputErrors={inputErrors}
        />
      );
    }
    if (isAvailable && attri !== KEY_BID) {
      return <div className={`${classPrefix}-pub-id`}>{bid[attri]}</div>;
    }
    const getKeyOfFocusInput = () => {
      if (inputErrors[KEY_GEO]) {
        return KEY_GEO;
      }
      if (inputErrors[KEY_BID]) {
        return KEY_BID;
      }
      return KEY_PUB_ID;
    };
    return (
      <EditableCell
        data={bid}
        attri={attri}
        onInlineEdit={onInlineEdit}
        onChange={(value) => doesExceedMaxBiddingRows || onEditSave(index, attri, value)}
        doesExceedMaxBiddingRows={doesExceedMaxBiddingRows}
        autoFocus={attri === getKeyOfFocusInput()}
        hasRowError={hasRowError}
        validator={(row, value, prop) => {
          if (prop === KEY_BID && typeof value === 'string') {
            return inputValidator(row, value, prop);
          }
          if (Object.keys(inputErrors).length) {
            return inputErrors;
          }
          return true;
        }}
        validateMsg={(prop, row, value) => {
          if (prop === KEY_BID && (isAvailable || !inputErrors[KEY_ROW_MSG])) {
            return invalidMessage(row, value);
          }
          if (inputErrors[prop]) {
            return inputErrors[prop];
          }
          return '';
        }}
      />
    );
  };
  const makeActionsHeader = useCallback(() => (
    <div className={`${classPrefix}--actions__header`} onClick={() => deleteMultiBiddingData(true)}>
      <i className="material-icons">delete_sweep</i>
    </div>
    // --> potential bug inside this useCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [data]);

  return (
    <div className={`${classPrefix}-container`}>
      <Table
        manual
        className={classnames(classPrefix, className, data.length >= 10 ? `${classPrefix}-fixed` : `${classPrefix}-auto`)}
        data={data}
        showPagination
        page={pagination.page}
        total={pagination.total}
        pageSize={pagination.perPage}
        onPageSizeChange={onPageSizeChange}
        columns={[
          {
            ...makeColumn('Publisher App Name', KEY_NAME, 250),
            Cell: ({ original, index }) => makeCell(original, KEY_NAME, index),
            resizable: true,
            minResizeWidth: 100,
          },
          {
            ...makeColumn('Publisher App Vungle ID', KEY_PUB_ID, 215),
            Cell: ({ original, index }) => makeCell(original, KEY_PUB_ID, index),
            resizable: true,
            minResizeWidth: 150,
          },
          {
            ...makeColumn('Country', KEY_GEO),
            Cell: ({ original, index }) => makeCell(original, KEY_GEO, index),
            resizable: true,
            minResizeWidth: 150,
          },
          {
            ...makeColumn('Bid', KEY_BID),
            Cell: ({ original, index }) => makeCell(original, KEY_BID, index),
            minResizeWidth: 150,
          },
          {
            ...makeColumn(makeActionsHeader, 'actions', 35),
            Cell: function render({ original }) {
              return (
                <div className={`${classPrefix}--actions__cell`} onClick={() => deleteMultiBiddingData(false, original)}>
                  <i className="material-icons">delete</i>
                </div>
              );
            },
            fixed: 'right',
          },
        ]}
      />
    </div>
  );
};

EditTable.propTypes = {
  /*
   * Class name string.
   */
  className: PropTypes.string,
  /**
   * Table datas.
   */
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * Pagination configuration.
   */
  pagination: PropTypes.shape({
    page: PropTypes.number,
    total: PropTypes.number,
    perPage: PropTypes.number,
  }).isRequired,
  /**
   * Will call when change multi data.
   */
  onDelete: PropTypes.func,
};

EditTable.defaultProps = {
  className: null,
  onDelete: () => {},
};

export default EditTable;
