import React from 'react';
import Pulsation from '../../../../components/V2/Pulsation/Pulsation';

const ColumnStatus = ({ original }) => {
  const status = original.isEligibleToServe() ? 'active' : 'inactive';
  return (
    <Pulsation
      labelText={status}
      status={status}
    />
  );
};

export default ColumnStatus;
