import React from 'react';
import MessageManager from './MessageManager';
import OneMessage from './OneMessage';

class MessageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      msgList: MessageManager.getAll(),
    };
  }

  componentDidMount() {
    MessageManager.reg(this.changeList);
  }

  changeList = () => {
    this.setState({
      msgList: MessageManager.getAll(),
    });
  }

  render() {
    const { prefixCls } = this.props;
    return (
      <div className={`${prefixCls}-wrapper`}>
        {this.state.msgList.map((msg) => <OneMessage key={msg.id} msg={msg} />)}
      </div>
    );
  }
}

MessageContainer.defaultProps = {
  prefixCls: 'v2_component_flag-message',
};

export default MessageContainer;
