import React, {
  compose, namespace, splash, Redirect,
} from '../../../../../app/app';
import User from '../../../../../models/User';
import Edit from './Edit';
import './edit.scss';

const ns = 'views.team.member.edit';
const updateState = namespace(ns);
const props = (state) => ({ ...state[ns], authUser: state.authUser });

class EditContainer extends React.Component {
  componentDidMount() {
    this.props.actions.getUser(this.props.match.params.id);
  }

  componentWillUnmount() {
    this.props.actions.destroyView();
  }

  render() {
    if (this.props.redirect) {
      return <Redirect to="/team" />;
    }
    if (!this.props.user) {
      return null;
    }
    this.props.user.onChange(this.props.actions.inputChange);
    return <Edit {...this.props} />;
  }
}

const inputChange = ({ state, values }) => updateState(state, { user: values });
const destroyView = ({ state }) => updateState(state, { user: false, redirect: false });

const getUser = (() => {
  const action = (id) => User.get(id);
  const end = ({ state, result }) => {
    if (!result.ok) {
      return updateState(state, { redirect: true });
    }
    return updateState(state, { user: new User(result.response) });
  };
  return { action, end };
})();

const saveUser = (() => {
  const action = (user) => User.save(user);
  const end = ({ state }) => ({
    ...splash({ finish: true, text: 'success' }),
    ...updateState(state, { redirect: true }),
  });
  const start = () => splash({ start: true, text: 'Saving User' });
  return { action, end, start };
})();

const actions = {
  destroyView, inputChange, getUser, saveUser,
};

export default compose(EditContainer, { props, actions });
