import React from 'react';
import PropTypes from 'prop-types';

export const MenuContextTypes = {
  /**
   * className prefix of menu
   */
  prefixCls: PropTypes.string.isRequired,

  /**
   * all check handler
   */
  toggleAll: PropTypes.func.isRequired,

  /**
   * get the status for check-all
   */
  getAllStatus: PropTypes.func.isRequired,

  /**
   * item check handler
   */
  toggleItem: PropTypes.func.isRequired,

  /**
   * when mount, menu item should register to menu
   */
  registerValue: PropTypes.func.isRequired,

  /**
   * when unmount, menu item should unregister from menu
   */
  unregisterValue: PropTypes.func.isRequired,
};

const MenuContext = React.createContext(null);

export default MenuContext;
