import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { assetTypesData } from '../../constant';
import MultiPanel from './MultiPanel';
import './assetTypesPanel.scss';

const renderItemLabel = (prefixCls, { text, id }) => (
  <div className={`${prefixCls}-item`}>
    <i className={`${prefixCls}-item-icon ${prefixCls}-item-icon-${id}`} />
    <span>{text}</span>
  </div>
);

const AssetTypesPanel = ({
  prefixCls,
  defaultCheckedNodes,
  onVisibleChange,
  onChange,
}) => {
  const handleChange = useCallback((_selectedStatuses, isAllSelected) => {
    onVisibleChange(false);
    onChange(_selectedStatuses, isAllSelected);
  }, [onVisibleChange, onChange]);

  return (
    <MultiPanel
      height={240}
      data={assetTypesData}
      renderItemLabel={(item) => renderItemLabel(prefixCls, item)}
      defaultCheckedKeys={defaultCheckedNodes.map(({ id }) => id)}
      onVisibleChange={onVisibleChange}
      onChange={handleChange}
    />
  );
};

AssetTypesPanel.propTypes = {
  prefixCls: PropTypes.string,
  defaultCheckedNodes: PropTypes.arrayOf(PropTypes.any),
  onVisibleChange: PropTypes.func,
  onChange: PropTypes.func,
};

AssetTypesPanel.defaultProps = {
  prefixCls: 'v2_component_filter_panel_asset_types',
  defaultCheckedNodes: [],
  onVisibleChange: noop,
  onChange: noop,
};

export default React.memo(AssetTypesPanel);
