import React from 'react';
import Button from '../../../../../components/Button/Button';
import Copy from '../../../../../components/Form/Text/Copy';
import Group from '../../../../../templates/input-group/InputGroupContainer';
import { canAccessManagementApi } from '../../../../../lib/helpers/authUser';
import triggerConfirm from '../../../../../components/Modals/ConfirmAction/triggerConfirm';

const { Field } = Group;

const CampaignApi = ({ authUser, regenerateApiKey }) => canAccessManagementApi() && (
  <Group
    title="Advertiser Management API"
    description="The Advertiser Management API allows you to manage your ad operations."
    link="https://support.vungle.com/hc/en-us/articles/360053494011"
    linkText="Learn more about the Advertiser Management API"
  >
    <Field name="API Key" description="Access or regenerate your API key">
      <div className="api_key">
        <Copy disabled value={authUser.secret_key} />
        <Button
          ghost
          onClick={() => {
            triggerConfirm({
              type: 'REGENERATE_API_KEY_CONFIRM_ACTION',
              header: 'Regenerate API Key',
              message: `
                Once regenerated, all tools using the API Key and Reporting API must be changed to work properly.
                Are you sure you want to regenerate your API Key?
              `,
              onConfirm: () => regenerateApiKey(authUser),
            });
          }}
        >
          Regenerate
        </Button>
      </div>
    </Field>
    <Field name="Liftoff Direct Secret Token" description="The Secret Token is used to generate auth token for advertiser self-serve API.">
      <Copy disabled value={authUser.refresh_token} />
    </Field>
  </Group>
);

export default CampaignApi;
