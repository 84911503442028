import _, { findIndex, isUndefined } from 'lodash';
import { locale } from 'lib/date';
import { validateImages } from 'lib/helpers/creatives/creativeIndex';
import {
  getCreatives, getCreative, getCreativeAttachmentList, handleCreativeAction, createCreative,
} from 'app/graphql/utils/creative';
import templates from 'config/templates';
import config from '../lib/config';

import Model from './BaseModel';
import Application from './Application';
import Account from './Account';

const { languages } = config.get('countryData');

class Creative extends Model {
  static isEligibleToServe(adminStatus, status) {
    return adminStatus === 'approved' && status === 'active';
  }

  constructor(attrs) {
    super(attrs);
    this.map('application', this.getRelation('application', Application).bind(this));
    if (attrs && attrs.account instanceof Account) {
      this.set('account', attrs.account);
    } else {
      this.map('account', this.getRelation('account', Account).bind(this));
    }
    this.map('created', locale);
    this.map('replacements', this.getTemplateReplacements);

    this.default('language.code', 'en'); // string {code<string>}
    this.default('language.name', 'English'); // string {name<string>}
    this.default('language.is_enforced', false); // string {is_enforced<boolean>}

    // attached campaigns (getAllCampaign -> filter for campaign[application.id] === creative.application.id -> return campaigns)
    this.default('campaigns', []);
    this.default('tags', []); // tags for attached campaigns
    this.default('replacements', []); // array objects { key<string>, name<string>, value<any> }
    this.default('cdn_url', ''); // url<string>
    this.default('assets', []); // array objects  { type:video|image, id<string>, url<string> } only 1 value in array (video information )

    this.default('targeting.devices.phone', true);
    this.default('targeting.devices.tablet', true);

    this.default('template.id', ''); // string
    this.default('template.name', ''); // string
    this.default('template.protocol', ''); // string
    this.default('template.format', ''); // string
    this.default('template.is_custom_creative', false); // bool
    this.default('template.templateType');

    this.default('language', 'en');
    this.default('postBundle', '');
    this.default('endcard', '');
    this.default('language.enforced', false);
    this.default('status', 'active', [null]);
    this.default('admin_status', 'pending', [null]);
  }

  isEligibleToServe() {
    return Creative.isEligibleToServe(
      this.raw('admin_status'),
      this.raw('status'),
    );
  }

  isApproved() {
    return this.raw('admin_status') === 'approved';
  }

  isActive() {
    return this.raw('status') === 'active';
  }

  hasPermissionForPlayable() {
    const account = this.get('account');
    return account?.hasPermissionForPlayable();
  }

  changeStatus(status) {
    return handleCreativeAction(this.raw('id'), status);
  }

  pause(shouldDetachAllCreatives) {
    return handleCreativeAction(this.raw('id'), 'pause', shouldDetachAllCreatives);
  }

  approve() {
    return handleCreativeAction(this.raw('id'), 'approve');
  }

  reject() {
    return handleCreativeAction(this.raw('id'), 'reject');
  }

  hasSupportedTemplate() {
    return templates.filter((t) => t.id === this.get('template.id')).length === 1;
  }

  selectTemplate = (template) => {
    this.selectedTemplateImages = [];
    this.set('template.id', template.get('id'));
    this.set('template.name', template.get('name'));
    this.set('template.format', template.get('format'));
    this.set('template.is_custom_creative', template.get('is_custom_creative'));
    this.set('template.cdn_url', template.get('cdn_url'));
    this.set('template.template_category', template.get('template_category'));
    this.set('video', null);
  }

  getTemplate = () => this.selectedTemplate

  getVungleName() {
    return this.get('vungle_name') || this.get('name');
  }

  getFriendlyName() {
    return this.get('name') || this.get('vungle_name');
  }

  resetReplacements() {
    this.set('replacements', []);
  }

  getTemplateReplacements = () => {
    const replacements = this.raw('replacements');
    const obj = {
      MAIN_VIDEO: this.raw('video.url'),
      CTA_BUTTON_BACKGROUND: '#01b27a',
    };
    replacements.forEach((r) => {
      obj[r.key] = r.value;
    });
    obj.POWERED_BY_VUNGLE = '';
    return obj;
  };

  getReplacementValue = (key) => {
    const replacements = this.raw('replacements');
    if (!replacements || !Array.isArray(replacements)) {
      return '';
    }

    for (let i = 0; i < replacements.length; i += 1) {
      if (replacements[i].key === key) {
        return replacements[i].value;
      }
    }
    return '';
  };

  hasPreviewableTemplate() {
    return templates.findIndex((t) => t.id === this.raw('template.id')) !== -1;
  }

  getImageValue = (image) => this.selectedTemplateImages.filter((i) => i.id === image.id).pop().asset

  setImageValue = (image) => {
    const images = this.selectedTemplateImages;
    const img = images.find((i) => i.id === image.id);
    if (img) {
      img.asset = image.asset;
    } else {
      images.push(image);
    }
    this.set('selectedTemplateImages', images);
  }

  isReadyForPreview(requiredKeys) {
    const videoInputRequired = requiredKeys.includes('video');
    const endCardRequired = requiredKeys.includes('endCard');
    const appNameRequired = requiredKeys.includes('appName');
    const replacements = this.raw('replacements');
    if (!replacements) {
      return false;
    }
    return (!videoInputRequired || !!this.raw('video'))
      && (!endCardRequired || !!this.raw('endcard'))
      && (!appNameRequired || !!this.getReplacementValue('APP_NAME'))
      && validateImages(replacements);
  }

  isValid(requiredKeys) {
    return !!this.raw('name')
    && !!this.raw('language')
    && this.isReadyForPreview(requiredKeys);
  }

  getActiveVideo(assets) {
    const asset = assets.filter((a) => a.get('video.id') === this.raw('video.id'));
    if (asset.length > 0) {
      return asset[0];
    }
    return new Creative();
  }

  /**
   * Updates the existing replacement data, keeps the value in place. Used when an existing
   * creative contains the replacement value but not the replacement config needed for validation.
   */
  setReplacementData = (replacement) => {
    const replacements = this.raw('replacements').map((existingReplacement) => {
      if (existingReplacement.key === replacement.key) {
        return {
          ...replacement,
          value: existingReplacement.value,
        };
      }
      return existingReplacement;
    });
    this.set('replacements', replacements);
  }

  setReplacementValue = (replacement, value) => {
    const replacements = this.raw('replacements');

    if (!replacements || !Array.isArray(replacements)) {
      // initialize the replacements array if absent
      this.set('replacements', [{
        ...replacement,
        value,
      }]);
      return;
    }

    for (let i = 0; i < replacements.length; i += 1) {
      if (replacements[i].key === replacement.key) {
        replacements[i].value = value;
        this.set('replacements', replacements);
        return;
      }
    }

    // add the new replacement if not in list
    this.set('replacements', [...replacements, {
      ...replacement,
      value,
    }]);
  };

  addTag = (tagName) => {
    const tags = [].concat(this.get('tags'));
    const index = findIndex(tags, ({ value }) => value === tagName);

    if (index < 0) {
      this.set('tags', tags.concat({ id: tagName, value: tagName }));
    }
  };

  removeTag = (tagName) => {
    const tags = [].concat(this.get('tags'));
    const index = findIndex(tags, ({ value }) => value === tagName);

    if (index >= 0) {
      tags.splice(index, 1);
      this.set('tags', tags);
    }
  };

  toServerObject = () => (
    {
      account: {
        id: this.get('account')?.get('account') || this.get('account')?.get('id'),
        name: this.get('account')?.get('username') || this.get('account')?.get('name'),
      },
      vungle_name: this.getVungleName(),
      status: this.raw('status'),
      admin_status: this.raw('admin_status'),
      application: {
        // TODO (guangpeng.liu): figure out why application attributes are not all the same in different containers.
        id: this.get('application')
          ?.get('id')
          || this.get('application')
            ?.get('attrs.id'),
        platform: this.get('application')
          .get('platform')
          || this.get('application')
            ?.get('attrs.platform'),
      },
      template: {
        id: this.raw('template.id'),
        name: this.raw('template.name'),
        format: this.raw('template.format'),
        is_custom_creative: this.raw('template.is_custom_creative'),
        cdn_url: this.raw('template.cdn_url'),
        template_category: this.raw('template.template_category'),
      },
      endcard: this.raw('endcard'),
      replacements: this.raw('replacements').map((r) => {
        if (isUndefined(r.value)) {
          Object.assign(r, { value: '' });
        }
        return r;
      }),
      targeting: {
        devices: {
          phone: this.raw('targeting.devices.phone'),
          tablet: this.raw('targeting.devices.tablet'),
        },
      },
      tracking: {
        events: this.raw('tracking.events'),
        url: this.raw('tracking.url'),
      },
      language: {
        code: this.raw('language.code'),
        name: _.get(languages.find((i) => i.code === this.raw('language.code')), 'name'),
        is_enforced: this.raw('language.is_enforced'),
      },
      name: this.raw('name'),
      format: this.raw('format'),
      campaigns: this.raw('campaigns')
        .map((i) => ({ id: i.id })),
      tags: this.raw('tags')
        .map((i) => ({ value: i.value })),
      assets: this.raw('assets'),
      cdn_url: this.raw('cdn_url'),
      ctaOverlay: this.raw('ctaOverlay') || {
        click_area: 1,
        time_show: 10,
        enabled: true,
      }, // ?? object {click_area<number>, time_show<number>, enabled<boolean>}
      video: {
        id: this.raw('video.id') || this.get('video')?.id || '',
        url: this.raw('video.url') || this.get('video')?.url || '',
      },
    }
  );
}

Creative.get = (id) => getCreative(id);
Creative.create = (obj) => createCreative(obj);
Creative.getAll = (filters) => getCreatives({ page: 1, perPage: 10, ...filters });
Creative.getCreativeById = (id) => getCreative(id);
Creative.getAllWithTemplateReplacements = (filters) => getCreativeAttachmentList({ page: 1, perPage: 10, ...filters });
Creative.make = Model.make(Creative);

export default Creative;
