/* eslint react/no-danger: "off" */
import React from 'react';
import imgLogo from 'assets/img/liftoff-logo.svg';
import Button from '../Button/Button';
import tosHTML from './TOS_9_11_2023.html';
// eslint-disable-next-line import/extensions,import/no-unresolved,import/no-webpack-loader-syntax
import urlToTosHtml from '!file-loader?name=tos/[name].[ext]!./TOS_9_11_2023.html';

const currentVersionToS = 'TOS_9_11_2023';

export {
  tosHTML,
  urlToTosHtml,
  currentVersionToS,
};

const TermsOfService = ({
  hideAccept, onClose, props: { appConfig }, actions: { acceptTerms },
}) => (
  <div className="components__terms_of_service">
    <div className="modal">
      <div className="terms-header">
        <div className="terms-header__logo components__logo">
          <img src={imgLogo} />
        </div>
        <p className="terms-header__company">
          Vungle SEA Pte. Ltd., a Liftoff Mobile, Inc. company
        </p>
        <p className="terms-header__order-terms">
          DIRECT INTERNATIONAL INSERTION ORDER TERMS
        </p>
        <p className="terms-header__company-adress">
          Vungle SEA Pte. Ltd.∙ 6 Shenton Way #38-01 OUE Downtown 1 ∙
          Singapore 068809 support@liftoff.io
        </p>
      </div>
      <div className="body">
        <div className="tos_content" dangerouslySetInnerHTML={{ __html: tosHTML }} />
      </div>
      <div className="footer">
        {!hideAccept && (
          <Button continue onClick={() => acceptTerms(appConfig)}>I accept</Button>
        )}
        {hideAccept && (
          <Button cancel onClick={onClose}>Close</Button>
        )}
      </div>
    </div>
  </div>
);

export default TermsOfService;
