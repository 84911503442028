import { FILE_SIZE_LIMIT_MB } from '../../lib/uploader';
import errors from './errors';

export default {
  default: {
    accept: 'image/*, video/mp4',
    description: 'Images & videos to unlock templates',
    error: 'Only images and videos can be uploaded. Videos must be at least 720p and .mp4.',
    errors: {
      [errors.ASSET_NAME_CONFLICT]: 'Asset with that name already exists.',
      [errors.UNEXPECTED]: 'Unexpected error, upload service offline.',
      [errors.FILE_TOO_LARGE]: `File size cannot exceed ${FILE_SIZE_LIMIT_MB}MB.`,
    },
  },
  video: {
    accept: 'video/mp4',
    description: 'Videos to unlock templates',
    error: 'Only videos can be uploaded. Videos must be at least 720p and .mp4.',
    errors: {
      [errors.ASSET_NAME_CONFLICT]: 'Asset with that name already exists.',
      [errors.FILE_TOO_LARGE]: `File size cannot exceed ${FILE_SIZE_LIMIT_MB}MB.`,
    },
  },
  image: {
    accept: 'image/*',
    description: 'Images to unlock templates',
    error: 'Only images can be uploaded.',
    errors: {
      [errors.ASSET_NAME_CONFLICT]: 'Asset with that name already exists.',
    },
  },
  csv: {
    accept: 'text/csv, application/vnd.ms-excel',
    description: 'your csv list',
    error: 'Only CSV files can be uploaded.',
  },
  zip: {
    accept: 'application/zip, application/x-zip-compressed',
    description: 'zip file upload',
    error: 'Only zip files can be uploaded.',
    errors: {
      [errors.ASSET_NAME_CONFLICT]: 'Asset with that name already exists.',
      [errors.ASSET_BUNDLE_VALIDATION_FAILED]: 'Bundle validation failed.',
      [errors.ASSET_BUNDLE_UPLOAD_FAILED]: 'Server error, upload of bundle failed.',
      [errors.ASSET_BUNDLE_VALIDATION_SAVE_FAILED]: 'Server error, unsuccessful saving file.',
      [errors.ASSET_BUNDLE_VALIDATION_WRONG_FORMAT_FAILED]: 'Invalid bundle format.',
      [errors.ASSET_BUNDLE_VALIDATION_MISSING_FORMAT_FAILED]: 'Missing asset format.',
      [errors.ASSET_BUNDLE_VALIDATION_EXTENSION_FAILED]: 'Only zip files can be uploaded.',
      [errors.ASSET_BUNDLE_VALIDATION_MISSING_FILE_FAILED]: 'No file selected to upload.',
      [errors.ASSET_BUNDLE_VALIDATION_UNRESPONSIVE]: 'Server error, unresponsive validation.',
      [errors.TEMPLATE_NO_CONFIG_FILE]: 'No config file is found in the template zip file',
    },
  },
  banner: {
    accept: 'image/*',
    description: 'Banner image upload',
    error: 'Unexpected error occurred.',
    errors: {
      [errors.ASSET_NAME_CONFLICT]: 'Another asset with the same name exists.',
      [errors.ASPECT_RATIO_NOT_IN_RANGE_75_85]: 'Aspect ratio must be between 7.5 and 8.5.',
      [errors.ASPECT_RATIO_NOT_IN_RANGE_55_65]: 'Aspect ratio must be between 5.5 and 6.5.',
    },
  },
};
