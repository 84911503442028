import { useEffect, useState } from 'react';

export default function usePagination(data = [], page, pageSize, setPage) {
  const [pageData, setPageData] = useState([]);
  useEffect(() => {
    const newPageData = data.slice((page - 1) * pageSize, page * pageSize);
    if (page > 1 && newPageData.length === 0) {
      setPage(page - 1);
    } else {
      setPageData(newPageData);
    }
    // --> potential bug inside this useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, page, pageSize]);
  return [pageData];
}
