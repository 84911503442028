import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PreviewModel from '../../../../../../../models/Preview';
import DeviceFrame from '../../../../../../../components/DeviceFrame/DeviceFrame';
import { ERRORS, CHAMPA_FORMATS } from '../../../../../../../components/DeviceFrame/settings';
import { getTranslatedCtaText } from '../../../../../../../lib/translator';
import './previewContent.scss';
import Asset from '../../../../../../../models/Asset';

class PreviewContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assetURL: null,
      s3URL: null,
      placementType: null,
      format: null,
      assetId: null,
      cdnURL: null,
    };
  }

  componentDidMount = () => {
    const { template, allReplacements } = this.props;
    if (template && allReplacements) {
      this.onRefresh();
    }
  }

  getAssetUrl = async (assetId, cdnUrl, format) => {
    if (!assetId || !cdnUrl || !format) {
      return ERRORS.assetUrl;
    }

    try {
      const { response } = await PreviewModel.getCdnURL({
        id: assetId,
        format,
        url: cdnUrl,
      });
      return response.url || ERRORS.assetUrl;
    } catch (x) {
      return ERRORS.assetUrl;
    }
  }

  getEndcardAssetId = async (assets, endcardUrl) => {
    if (assets) {
      return ((assets.find((asset) => asset.url === endcardUrl) || { id: null }).id);
    }
    const result = await Asset.getAll({ assetUrl: endcardUrl });
    return result.response[0]?.id;
  }

  isErrorPage = (url) => ((Object.keys(ERRORS).find((key) => ERRORS[key] === url)) || null);

  UNSAFE_componentWillReceiveProps = (p) => {
    if (!p.template || !p.allReplacements || !p.allReplacements.length) {
      return;
    }
    this.onRefresh(p);
  }

  onRefresh = async (parentProps) => {
    const p = parentProps || this.props;
    const replacements = this.getReplacements(p);

    if (Object.keys(replacements).length <= 1) {
      return;
    }

    let { s3URL } = this.state;
    let assetId = p.template.id;
    let assetFormat = CHAMPA_FORMATS.dynamicTemplate;
    let cdnURL = p.template.cdn_url;

    // Have to reset state, otherwise we load the previous preview
    this.setState({
      assetURL: null,
      placementType: p.template.format,
      format: 'vungle_mraid',
      s3URL: null,
    });

    const hasEndcard = replacements.HAS_ENDCARD && replacements.HAS_ENDCARD.toLowerCase() === 'true';
    if (hasEndcard) {
      cdnURL = p.endcardUrl;
      assetId = p.endcard;
      assetFormat = CHAMPA_FORMATS.adaptiveCreativeAsset;

      // This will generate data for AC creatives created in Legacy Dashboard, so we can show the preview
      if (assetId === null) {
        assetId = p.creativeId;
        assetFormat = CHAMPA_FORMATS.adaptiveCreativeUrl;
      }
    }

    if (this.state.cdnURL !== cdnURL || this.state.assetId !== assetId || !s3URL || this.isErrorPage(s3URL) !== null) {
      s3URL = await this.getAssetUrl(assetId, cdnURL, assetFormat);
    }

    const assetURL = `${s3URL}?platform=${this.props.platform}&replacements=${encodeURIComponent(JSON.stringify(replacements))}`;

    this.setState({
      assetURL,
      s3URL,
      assetId,
      cdnURL,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  getReplacements(p) {
    const {
      replacements = [], videoUrl, allReplacements = [], videoReplacementKeys = [], languageCode = 'en',
    } = p;
    const previewReplacements = [...allReplacements, ...replacements];

    // getTemplateReplacements function in Creatives model overwrites this values, why I have no idea
    const tplReplacements = {};

    previewReplacements.forEach((replacement) => {
      // if videoUrl is passed along, we use that value instead of default templates replacement value
      if (videoUrl && videoReplacementKeys.indexOf(replacement.key) !== -1) {
        tplReplacements[replacement.key] = videoUrl;
        return;
      }

      if (replacement.key === 'CTA_BUTTON_TEXT') {
        tplReplacements[replacement.key] = getTranslatedCtaText(replacement.value?.trim(), languageCode);
        return;
      }

      if (replacement.key === 'APP_LONG_DESCRIPTION' && replacement.value) {
        tplReplacements[replacement.key] = `${replacement.value.substring(0, 200)}...`;
        return;
      }

      if (replacement.key === 'APP_SCREENSHOTS' && replacement.value) {
        try {
          const screenshots = JSON.parse(replacement.value);
          tplReplacements[replacement.key] = JSON.stringify(screenshots.slice(0, 3));
        } catch (error) {
          tplReplacements[replacement.key] = '';
        }
        return;
      }

      tplReplacements[replacement.key] = replacement.value;
    });

    return tplReplacements;
  }

  render() {
    const { assetURL, placementType, format } = this.state;
    const {
      shouldAutoPlay,
      shouldHideFrame,
      shouldDisplayTooltip,
      template,
      isCreativeSetPreviewer,
      thumbnail,
      orientation,
    } = this.props;
    return (
      <div className="PreviewContent__DeviceFrameWrapper">
        <DeviceFrame
          assetURL={assetURL}
          placementType={placementType}
          format={format}
          autoplay={shouldAutoPlay || false}
          onRefresh={this.onRefresh}
          shouldHideFrame={shouldHideFrame}
          shouldDisplayTooltip={shouldDisplayTooltip}
          template={{
            isBannerTemplate: template?.template_category?.banner === true,
            name: template?.name,
          }}
          isCreativeSetPreviewer={isCreativeSetPreviewer}
          thumbnail={thumbnail}
          orientation={orientation}
          creativeId={this.props.creativeId}
          isMissionControl
        />
      </div>
    );
  }
}

PreviewContent.propTypes = {
  platform: PropTypes.string.isRequired,
  replacements: PropTypes.arrayOf(PropTypes.object), //eslint-disable-line
  template: PropTypes.object, // eslint-disable-line
  videoUrl: PropTypes.string, // eslint-disable-line
};

PreviewContent.defaultProps = {
  template: null,
  replacements: [],
  videoUrl: '',
};

export default PreviewContent;
