export default (title, accessory, width = 0, fixed = null) => {
  const opt = {
    Header: title,
    id: accessory,
    accessory,
  };
  if (width > 0) {
    opt.width = width;
  }
  opt.fixed = fixed;
  return opt;
};
