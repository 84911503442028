import React from 'react';
import CreativeCreator from './Create/CreateContainer';
import AttachedCreatives from '../../../../../Templates/Campaigns/AttachedCreatives/Creatives';

const Creatives = ({
  props: {
    campaign,
    creatives = [],
  } = {},
  creativeIds = [],
  updateSkcid,
}) => (
  <div className="views__advertiser__campaigns__edit__creatives">
    <div className="right">
      <CreativeCreator
        campaign={campaign}
      />
      <AttachedCreatives
        campaign={campaign}
        creatives={creatives}
        creativeIds={creativeIds}
        updateSkcid={updateSkcid}
      />
    </div>
  </div>
);

export default Creatives;
