import { uniqBy } from 'lodash';
import React from '../../app/app';
import { contains } from '../../lib/lib';
import Checkbox from '../../components/Form/Checkbox/CheckboxContainer';
import MultiCheckbox from '../../components/Form/MultiCheckbox/MultiCheckboxContainer';
import Text from '../../components/Form/Text/TextContainer';
import ScrollableList from '../ScrollableList/ScrollableList';

const deviceSizes = new Map([['tablet', 'iPad'], ['phone', 'iPhone']]);

const IOSSelector = ({
  onChangeSearch,
  resource,
  searchValue = '',
  selectedModels = [],
  models = [],
}) => {
  let checkboxes = [];
  if (searchValue.length > 0) {
    checkboxes = uniqBy(models, (d) => d.name)
      .filter((d) => contains(d.name, searchValue))
      .map((device, i) => (
        <Checkbox
          key={i}
          checked={resource.hasIOSDevice(device)}
          label={device.name}
          onChange={(include) => resource.toggleIOSDevices(include, models.filter((d) => d.name === device.name))}
        />
      ));
  } else {
    deviceSizes.forEach((name, type) => {
      checkboxes.push(
        <MultiCheckbox key={type} label={name}>
          {uniqBy(models.filter((d) => (d.type === type)), (d) => d.name).map((device) => (
            <Checkbox
              key={device.id}
              checked={resource.hasIOSDevice(device)}
              label={device.name}
              onChange={(include) => resource.toggleIOSDevices(include, models.filter((d) => d.name === device.name))}
            />
          ))}
        </MultiCheckbox>,
      );
    });
  }

  return (
    <div className="templates__device-selector">
      <div className="device_options">
        <div className="search-and-select">
          <div className="select-all">
            <Checkbox
              some={selectedModels.length > 0 && uniqBy(selectedModels, (d) => d.name).length < uniqBy(models, (d) => d.name).length}
              checked={selectedModels.length > 0}
              onChange={(include) => resource.toggleAllDevices(include, models, searchValue)}
            />
          </div>
          <div className="input__search">
            <Text.Search value={searchValue} onChange={onChangeSearch} />
          </div>
        </div>
        {checkboxes}
      </div>
      {selectedModels.length > 0 && (
        <ScrollableList
          cancelable
          items={uniqBy(selectedModels, (d) => d.name)}
          onClick={(device) => resource.toggleIOSDevices(false, models.filter((d) => d.name === device.name))}
        />
      )}
    </div>
  );
};

export default IOSSelector;
