import React from 'react';
import classNames from 'classnames';
import Link from 'components/Link/Link';
import { TooltipCutoff, Pulsation } from 'components/V2';
import CreativeServingStatus from 'components/Creatives/ServingStatus/ServingStatus';
import triggerConfirm from 'components/Modals/ConfirmAction/triggerConfirm';
import {
  getEditUrl,
  getSSImageReplacements,
  isVideoRequiredInReplacements,
} from 'lib/helpers/creatives/creativeIndex';
import { CREATIVE_FORMAT_MAP } from 'app/constants/campaign';
import { pauseCreative } from '../../../../../../../../components/Modals/PauseCreative/PauseCreative';
import Creative from '../../../../../../../../models/Creative';
import {
  makeColumn, renderDateCreated, getMetricsList, getDefaultMetrics,
} from '../helper';
import {
  CONTEXT_MENU_KEY_EDIT_CREATIVE,
  CONTEXT_MENU_KEY_PAUSE_CREATIVE,
  CONTEXT_MENU_KEY_PREVIEW_CREATIVE,
  METRIC_ACCESSOR_CREATIVE_FORMAT,
  METRIC_ACCESSOR_CREATIVE_TYPE,
  METRIC_ACCESSOR_DATE_CREATED,
  METRIC_KEY_CREATIVE_FORMAT,
  METRIC_KEY_CREATIVE_TYPE,
  METRIC_KEY_DATE_CREATED,
  METRIC_KEY_ID,
} from '../constants';
import { canModifyCreatives } from '../../../../../../../../lib/helpers/authUser';
import { dimension } from '../../../../../../../../lib/helpers/creatives/constants';

const creativeTemplateFormats = {
  ...CREATIVE_FORMAT_MAP,
  native: { text: 'Native' },
};

export function renderColumns({ onPreviewCreative, changeCreativeStatus }) {
  return [
    {
      width: 40,
      fixed: 'left',
      expander: true,
      Expander: function Expander({ isExpanded, original }) {
        if (original.total) {
          return null;
        }

        return (
          <div className="expand-wrapper">
            <i className={classNames('material-icons', { expanded: isExpanded })}>expand_more</i>
          </div>
        );
      },
    },
    {
      ...makeColumn('Status', 'status'),
      width: 90,
      fixed: 'left',
      Cell: function Cell({ original }) {
        if (original.total) {
          return 'Total';
        }

        const { isActive, dataModel } = original[dimension];
        return (
          <div className="status_container">
            <CreativeServingStatus
              creative={dataModel}
              changeStatus={(e) => {
                e.preventDefault();
                e.stopPropagation();
                triggerConfirm({
                  type: 'ACTIVATE_CREATIVE_CONFIRM_ACTION',
                  header: 'Activate Creative',
                  message: 'Are you sure you want to activate this Creative?',
                  name: dataModel.get('name'),
                  onConfirm: () => changeCreativeStatus({ creative: dataModel, status: 'activate' }),
                });
              }}
              showTooltip={canModifyCreatives()}
            >
              <Pulsation
                editIcon="play_arrow"
                prefixCls="pulsation v2_component_pulsation"
                status={isActive ? 'active' : 'inactive'}
              />
            </CreativeServingStatus>
            <span>{isActive ? 'Active' : 'Inactive'}</span>
          </div>
        );
      },
    },
    {
      ...makeColumn('Name', 'name'),
      width: 310,
      fixed: 'left',
      Cell: function Cell({ original }) {
        if (original.total) {
          return null;
        }

        const { id, name } = original[dimension];
        const creative = new Creative({ id });
        return (
          <div className="table_name">
            <i
              className="material-icons"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onPreviewCreative(id);
              }}
            >
              play_circle_outline
            </i>
            <TooltipCutoff
              title={name}
              placement="bottomLeft"
              titleWrapper={(title) => <Link to={getEditUrl(creative)} className="body__link">{title}</Link>}
            />
          </div>
        );
      },
    },
  ];
}

export const attributesMetrics = {
  name: 'Attributes',
  metrics: [
    {
      default: true,
      isDimensionKey: true,
      showTooltipCutoff: true,
      key: METRIC_KEY_ID,
      accessor: METRIC_KEY_ID,
      name: 'ID',
      tableDefault: true,
      render: (item, d) => item[d].id,
    },
    {
      default: true,
      isDimensionKey: true,
      showTooltipCutoff: true,
      key: METRIC_KEY_CREATIVE_FORMAT,
      accessor: METRIC_ACCESSOR_CREATIVE_FORMAT,
      name: 'Creative Format',
      tableDefault: true,
      render: (item) => creativeTemplateFormats[item[dimension].template.format].text,
    },
    {
      default: true,
      isDimensionKey: true,
      showTooltipCutoff: true,
      key: METRIC_KEY_CREATIVE_TYPE,
      accessor: METRIC_ACCESSOR_CREATIVE_TYPE,
      name: 'Creative Type',
      tableDefault: true,
      render: (item) => item[dimension].template.name,
    },
    {
      default: true,
      isDimensionKey: true,
      showTooltipCutoff: true,
      key: METRIC_KEY_DATE_CREATED,
      accessor: METRIC_ACCESSOR_DATE_CREATED,
      name: 'Date Created',
      tableDefault: true,
      render: renderDateCreated,
    },
  ],
};

const attributesMetricsList = attributesMetrics.metrics;

export const defaultMetrics = getDefaultMetrics(attributesMetricsList);

export const returnMetricsList = (metricsData) => getMetricsList(metricsData, attributesMetricsList);

export function generateContextMenu({
  original,
  onPreviewCreative,
  changeCreativeStatus,
}) {
  const contextMenu = [
    {
      key: CONTEXT_MENU_KEY_EDIT_CREATIVE,
      name: 'Edit Creative',
      action: ({ history, creative }) => history.push(getEditUrl(creative)),
    },
    {
      key: CONTEXT_MENU_KEY_PREVIEW_CREATIVE,
      name: 'Preview Creative',
      action: ({ creative }) => onPreviewCreative(creative.get('id')),
    },
  ];

  const { adminStatus } = original[dimension];
  if (adminStatus === 'approved') {
    contextMenu.push({
      key: CONTEXT_MENU_KEY_PAUSE_CREATIVE,
      name: original[dimension].isActive ? 'Pause Creative' : 'Activate Creative',
      action: ({ creative }) => {
        const { isActive } = original[dimension];
        const [text, status, msg] = isActive
          ? ['Pause Creative', 'pause', 'Are you sure you want to stop this Creative from serving?']
          : ['Activate Creative', 'activate', 'Are you sure you want to activate this Creative?'];
        if (isActive) {
          pauseCreative(creative, (args) => {
            changeCreativeStatus(args);
          })();
        } else {
          triggerConfirm({
            action: isActive ? 'PAUSE_CREATIVE_CONFIRM_ACTION' : 'ACTIVATE_CREATIVE_CONFIRM_ACTION',
            header: text,
            message: msg,
            name: creative.get('name'),
            onConfirm: () => changeCreativeStatus({ creative, status }),
          });
        }
      },
    });
  }

  return contextMenu;
}

// For Creative Type “Adaptive Creative Single-Page”, the video can't display in the accordion.
const ADAPTIVE_CREATIVE_SINGLE_PAGE_TEMPLATE = 'Adaptive Creative Single-Page';
export function getCreativeAssets(creative) {
  const results = [];
  const { replacements, name: templateName } = creative.template;

  const videoKey = 'MAIN_VIDEO';
  const isVideoRequired = isVideoRequiredInReplacements(replacements);
  if (templateName !== ADAPTIVE_CREATIVE_SINGLE_PAGE_TEMPLATE && isVideoRequired && creative.video) {
    const { url, asset } = creative.video;
    results.push({
      id: asset?.id,
      type: asset?.type || 'video',
      name: asset?.originalName || url,
      thumbnail: asset?.thumbnails.landscape[0] || '',
      kind: creative.template.replacements.find((replacement) => replacement.key === videoKey)?.name || 'Video',
    });
  }

  const endcardTemplateReplacement = creative.replacements?.find(({ key }) => key === 'ENDCARD');
  if (endcardTemplateReplacement) {
    const { asset } = endcardTemplateReplacement;
    results.push({
      id: asset?.id,
      type: asset?.type || 'bundle_adaptive_creative',
      name: asset?.originalName || asset?.url,
      kind: 'Playable (.zip format)',
    });
  }

  const imageReplacements = getSSImageReplacements(replacements);
  const replacementKeys = imageReplacements.map(({ key }) => key);
  const templateReplacements = creative.replacements?.filter((templateReplacement) => replacementKeys.includes(templateReplacement.key));
  if (templateReplacements?.length) {
    templateReplacements.forEach((templateReplacement) => {
      const { key, value, asset } = templateReplacement;
      if (!value) {
        return;
      }
      results.push({
        id: asset?.id,
        type: asset?.type || 'image',
        name: asset?.originalName || value,
        url: asset?.url || value,
        kind: imageReplacements.find((replacement) => replacement.key === key)?.name,
      });
    });
  }

  return results;
}
