import React, { useCallback } from 'react';
import { CombinedFiltersWithSearch } from '../../../../components/V2';
import { getFilters } from '../../../../components/V2/Filters/filters';

const Filters = ({
  onRefresh,
  placeholder = '',
  filterTypes = [],
  disabledOptions,
  filterLabels,
  disabledDropDownFilters,
  hiddenOptions,
  children,
}) => {
  const filters = getFilters(filterTypes, disabledOptions, hiddenOptions, filterLabels, disabledDropDownFilters);
  const getDefaultCheckedConditions = useCallback(() => (
    filterTypes.reduce((prev, next) => (
      { ...prev, [next]: [] }
    ), {})
  ), [filterTypes]);

  const onChange = useCallback((cache) => {
    onRefresh(cache);
  }, [onRefresh]);

  return (
    <div className="creative-builder-filter">
      <CombinedFiltersWithSearch
        filters={filters}
        onChange={onChange}
        placeholder={placeholder}
        defaultCheckedConditions={getDefaultCheckedConditions()}
      />
      {children}
    </div>
  );
};

export default Filters;
