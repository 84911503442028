import React from 'react';
import { FILTER_KEY_CAMPAIGN_STATUS } from 'app/constants/filters';
import CreativePanel from '../../../../../Templates/CreativeBuilder/CreativePanel/CreativePanel';
import Group from '../../../../../../templates/input-group/InputGroupContainer';
import CollapseSection from '../../../../../../components/V2/Collapse/CollapseSection';
import CreativeTable from '../../../../../Templates/CreativeBuilder/CreativeTable/CreativeTable';
import CampaignTable from '../../../../../Templates/CreativeBuilder/CampaignTable/CampaignTable';
import './Campaigns.scss';

const templateCategoryMap = {
  'dynamic-template': 'Full Screen',
  mrec: 'Mrec',
  banner: 'Banner',
};

const getTemplateCategory = (creative) => {
  const templateType = creative.raw('templateType');
  if (!templateType) {
    return '';
  }
  const [firstName] = Object.keys(templateType);
  return templateCategoryMap[firstName] || '';
};

const getFilters = () => [FILTER_KEY_CAMPAIGN_STATUS];

const Campaigns = ({
  creative,
  saveCreativeToCampaigns,
  loadingCampaigns,
  selectedIds = [],
  getCampaigns,
  selectCampaign = () => {},
  setCampaigns,
  setChangedCampaigns,
}) => {
  const templateType = getTemplateCategory(creative);

  return (
    <div className="creative-creator-campaign">
      <CreativePanel>
        <div className="creative_builder-creative-table-wrapper">
          <CreativeTable
            hideFilter
            hideHeader
            selectable={false}
            data={[creative]}
            isShowEditCreativeAction={false}
          />
        </div>
      </CreativePanel>
      <CollapseSection
        title={`Attached Campaigns (${selectedIds.length})`}
        description="Select what Campaigns you wish to attach to this Creative."
      >
        <div className="vg-note">
          <p className="p6">
            <span className="p6">Campaign Type:&nbsp;</span>
            { `Based on your template being ${templateType}, you only have access to attach the ${templateType} Campaign Types.` }
          </p>
        </div>
        <div className="creative_builder-campaign-table-wrapper">
          <CampaignTable
            creative={creative}
            selectedIds={selectedIds}
            selectCampaign={selectCampaign}
            loading={loadingCampaigns}
            getCampaigns={getCampaigns}
            handleRemoveAllColumn={() => {
              selectCampaign([]);
              setChangedCampaigns();
            }}
            onCampaignsChange={setCampaigns}
            setChangedCampaigns={setChangedCampaigns}
            filterTypes={getFilters()}
          />
        </div>
      </CollapseSection>
      <Group.Buttons
        cancelText="Save &amp; Finish"
        onClickCancel={() => saveCreativeToCampaigns()}
        confirm={() => saveCreativeToCampaigns(true)}
        confirmText="Save &amp; Add Another"
      />
    </div>
  );
};

export default Campaigns;
