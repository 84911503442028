import React from 'react';
import './AssetPreviewerImage.scss';

const AssetPreviewerImage = ({ asset }) => (
  <div className="asset-previewer-image">
    <img src={asset.get('url')} />
  </div>
);

export default AssetPreviewerImage;
