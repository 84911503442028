import React from 'react';
import imgVungleLogo from 'assets/img/liftoff-logo.svg';
import Button from '../Button/Button';
import './platformDownModal.scss';

const prefixCls = 'v2_component_platform_down';

const Container = ({ children }) => (
  <div className={prefixCls}>
    <div className="content">
      <img src={imgVungleLogo} alt="Vungle" />
      <div className="modal">
        {children}
      </div>
    </div>
  </div>
);

export const PlatformDownModal = () => (
  <Container>
    <div className="platform__down__wrapper">
      <div className="p2">Sorry for the inconvenience, our platform is down.</div>
      <div className="p2 platform__suggestions">
        We’re working on some site issues and service will be restored soon. Please email
        <a
          className="p3 support"
          href="mailto:support@vungle.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          support@vungle.com
        </a>
        if you have any questions.
      </div>
      <a className="p3 back" href="https://www.vungle.com">
        Back to Liftoff
      </a>
    </div>
  </Container>
);

export const AdvertiserBlockerModal = ({ onRefresh }) => (
  <Container>
    <div className="advertiser__blocker__wrapper">
      <div className="p2 blocker__suggestions">
        Hello there! Unfortunately you will not be able to use Vungle Advertiser Self-Serve if
        you are using an ad blocker on your web browser. Please disable your ad blocker
        and then click the button below. If you have any questions please contact us at
        <a
          className="p3 support"
          href="mailto:support@vungle.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          support@vungle.com
        </a>
      </div>
      <Button
        className="refresh"
        type="primary"
        onClick={onRefresh}
      >
        Refresh
      </Button>
    </div>
  </Container>
);

export const AccessDeniedModal = ({ accountId }) => (
  <Container>
    <div className="advertiser__denied__wrapper">
      <div className="p2 denied__suggestions">
        Your account ID: [
        {accountId}
        ] does not have access to the dashboard. Please contact Liftoff support at
        <a
          className="p3 support"
          href="mailto:grow@vungle.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          grow@vungle.com
        </a>
        {' '}
        for more information.
      </div>
    </div>
  </Container>
);
