import React, { useState, useEffect } from 'react';
import {
  PRODUCT_DESCRIPTION,
  isBannerImage,
  isCarousel,
  isImageBanner,
  getSSImageReplacements,
} from 'lib/helpers/creatives/creativeIndex';
import { isAdminService } from 'lib/serviceType';
import Asset from '../../../../../admin/views/creatives/form/sections/replacements/Asset/Asset';
import Field from './Field';
import getTooltipContent from '../../../../../../lib/helpers/creatives/getAssetTooltip';

const getReplacementInput = (template, creative, replacement, onChange) => {
  const { key } = replacement;
  const value = creative.getReplacementValue(replacement.key);

  let toolTipType = '';
  switch (key) {
    case 'LANDSCAPE_BACKGROUND':
      toolTipType = 'landscape_image';
      break;
    case 'PORTRAIT_BACKGROUND':
      toolTipType = 'portrait_image';
      break;
    case 'MREC_IMAGE_6_5':
      toolTipType = 'mrec_image_6_5';
      break;
    case 'BANNER_IMAGE_6_1':
      toolTipType = 'banner_image_6_1';
      break;
    case 'BANNER_IMAGE_8_1':
      toolTipType = 'banner_image_8_1';
      break;
    case 'MAIN_IMAGE':
      toolTipType = 'native_image';
      break;
    default:
      toolTipType = 'carousel';
  }

  return (
    <Field
      name={replacement.name}
      key={replacement.key}
      filled={value}
      required={replacement.role.valueRequired}
      tooltipConfig={{
        title: replacement.name,
        content: getTooltipContent(toolTipType),
      }}
    >
      {
        isBannerImage(replacement)
          ? (
            <Asset
              creative={creative}
              key={key}
              onChange={onChange}
              replacementKey={replacement.key}
              type="banner"
              value={value}
              accept="image/*, video/mp4"
              shouldHidePlayable
            />
          ) : (
            <Asset
              creative={creative}
              key={key}
              onChange={onChange}
              type="image"
              value={value}
              accept="image/*, video/mp4"
              shouldHidePlayable
              template={template}
            />
          )
      }
    </Field>
  );
};

export const ReplacementGroup = ({
  creative,
  onBundleChange,
  onImageChange,
  replacements = [],
  requiredKeys,
  template,
}) => {
  const imageReplacements = getSSImageReplacements(replacements);
  const hasPermissionForPlayable = creative.hasPermissionForPlayable() || isAdminService();

  let toolTipType = '';
  if (requiredKeys.includes('video')) {
    if (['MREC Looping Video', 'Image MREC'].includes(creative.get('template.name'))) {
      toolTipType = 'mrecVideo';
    } else {
      toolTipType = 'normalVideo';
    }
  }

  return (
    <>
      {
        imageReplacements.length > 0 && (
          <>
            <div className="images-wrapper">
              {
                imageReplacements.map((replacement, order) => (
                  getReplacementInput(
                    template,
                    creative,
                    replacement,
                    (v, asset) => onImageChange(replacement, v, asset.get('id'), (order + 1).toString()),
                  )
                ))
              }
            </div>
            {
              isCarousel(imageReplacements) && <p className="optional-prompt">*These assets are optional.</p>
            }
            {
              isImageBanner(imageReplacements) && <p className="optional-prompt">*Only one of these two banner image assets is required.</p>
            }
            <hr className="separate-line image-group" />
          </>
        )
      }
      {
        requiredKeys.includes('video') && (
          <Field
            name="Video"
            separateLine
            tooltipConfig={{
              title: 'Video',
              content: getTooltipContent(toolTipType),
            }}
          >
            <Asset
              creative={creative}
              onChange={(v) => creative.set('video', v)}
              type="video"
              value={creative.get('video')}
              accept="image/*, video/mp4"
              shouldHidePlayable
            />
          </Field>
        )
      }
      {
        requiredKeys.includes('endCard') && (
          <Field
            name="Playable"
            separateLine
            tooltipConfig={{
              title: 'Playable (.zip format)',
              content: getTooltipContent('endCard'),
            }}
          >
            <Asset
              creative={creative}
              onChange={onBundleChange}
              type="bundle_adaptive_creative"
              value={creative.get('endcardUrl')}
              accept={`image/*, video/mp4${hasPermissionForPlayable ? ', application/zip, application/x-zip-compressed' : ''}`}
              shouldHidePlayable={!hasPermissionForPlayable}
            />
          </Field>
        )
      }
    </>
  );
};

const Replacements = ({
  creative,
  application,
  onImageChange,
  onBundleChange,
  replacementsByGroups,
  requiredKeys,
  template,
}) => {
  const [productDescriptionReplacements, setProductDescriptionReplacements] = useState();

  useEffect(() => {
    setProductDescriptionReplacements(replacementsByGroups[PRODUCT_DESCRIPTION]);
  }, [replacementsByGroups]);

  return (
    <ReplacementGroup
      creative={creative}
      application={application}
      key={PRODUCT_DESCRIPTION}
      onBundleChange={onBundleChange}
      onImageChange={onImageChange}
      replacements={productDescriptionReplacements}
      requiredKeys={requiredKeys}
      title={PRODUCT_DESCRIPTION}
      template={template}
    />
  );
};

export default Replacements;
