import React, { compose, namespace, splash } from '../../../../../app/app';
import { set, get } from '../../../../../lib/session';
import Account from '../../../../../models/Account';
import User from '../../../../../models/User';
import Overview from './Overview';

const ns = 'views.account.overview';
const updateState = namespace(ns);
const updateAuthState = namespace('authUser');
const props = (state) => ({ ...state[ns], authUser: state.authUser });

class Container extends React.Component {
  componentDidMount() {
    const { account } = this.props.authUser;
    this.props.actions.getUsersAndAccount(account);
  }

  componentWillUnmount() {
    this.props.actions.resetState();
  }

  render() {
    if (!this.props.account || !this.props.users) {
      return null;
    }
    this.props.account.onChange(this.props.actions.inputChange);
    return <Overview {...this.props} />;
  }
}

const fetchPageData = async (id) => {
  const [accountResult, userResult] = await Promise.all([
    Account.get(id),
    User.getAll({ account: id }),
  ]);
  return { account: accountResult, users: userResult };
};

const updatePageData = ({
  state,
  result,
}) => updateState(state, {
  account: new Account(result.account.response),
  users: User.make(result.users.response),
});

const getUsersAndAccount = (() => {
  const action = fetchPageData;
  const end = updatePageData;
  return { action, end };
})();

const saveAccount = (() => {
  const action = (account) => Account.save(account)
    .then(() => fetchPageData(account.get('id')));
  const start = () => splash({ start: true, text: 'saving account details' });
  const end = ({ state, result }) => ({
    ...splash({
      finish: true,
      text: 'success',
    }),
    ...updatePageData({
      state,
      result,
    }),
  });
  return { action, end, start };
})();
const fundAccount = (() => {
  const action = (account) => Account.fund(account);
  const start = () => splash({ start: true, text: 'saving account details' });
  const end = () => splash({ finish: true, text: 'success' });
  return { action, end, start };
})();
const regenerateApiKey = (() => {
  const action = (user) => User.regenerateSecretKey(user);
  const start = () => splash({ start: true, text: 'generating new API key' });
  const end = ({ state, result }) => {
    const user = get('authUser');
    set(
      'authUser',
      {
        // save the fields in response and use the others in user
        ...user,
        ...result.response,
      },
    );
    return {
      ...updateAuthState(state, { secret_key: result.response.secret_key }),
      ...splash({ finish: true, text: 'success' }),
    };
  };
  return { action, end, start };
})();

const inputChange = ({ state, values }) => updateState(state, { account: values });
const resetState = ({ state }) => updateState(state, { users: null });
const toggleTos = ({ state }) => updateState(state, { showTos: !state[ns].showTos });

const actions = {
  inputChange,
  getUsersAndAccount,
  fundAccount,
  resetState,
  saveAccount,
  toggleTos,
  regenerateApiKey,
};

export default compose(Container, { props, actions });
