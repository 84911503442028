import { useMemo } from 'react';
import { isNumeric } from '../../../../../lib/lib';

const units = ['bytes', 'KB', 'MB', 'GB'];

// eslint-disable-next-line import/prefer-default-export
export function prettifySize(x) {
  if (x === 1) return '1 byte';

  let l = 0;
  let n = parseInt(x, 10) || 0;

  // eslint-disable-next-line no-plusplus
  while (n >= 1024 && ++l) {
    n /= 1024;
  }
  //include a decimal point and a tenths-place digit if presenting
  //less than ten of KB or greater units
  return (`${n.toFixed(n < 10 && l > 0 ? 1 : 0)} ${units[l]}`);
}

export function getAssetOrientation(asset) {
  if (!asset || (asset.get('type') !== 'image' && asset.get('type') !== 'video')) {
    return '-';
  }
  return asset.get('exif.height') >= asset.get('exif.width') ? 'Portrait' : 'Landscape';
}

export function getFileOrientation(file) {
  if (!file) {
    return '-';
  }
  return file.height > file.width ? 'Portrait' : 'Landscape';
}

const mongoIdTester = new RegExp('^[0-9a-fA-F]{24}$');

export function isMongoID(value) {
  if (!value) {
    return false;
  }
  return mongoIdTester.test(value);
}

const twoDigitsNumber = (number) => ((`0${number}`).slice(-2));

export const formatDuration = (duration, isMillisecond = false) => {
  if (!isNumeric(duration) || duration < 0) {
    return '-';
  }
  let totalSeconds = duration;
  if (isMillisecond) {
    totalSeconds = duration / 1000;
  }
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = Math.floor(totalSeconds % 60);
  return `${twoDigitsNumber(minutes)}:${twoDigitsNumber(seconds)}`;
};

export const useMetricsColumns = ({ baseColumns = [], metrics = null, metricGroup = [] }) => useMemo(() => {
  if (!metrics) return baseColumns;
  const metricsKeys = metrics.map((item) => item.key);
  const firstMetricsIndex = baseColumns.findIndex((column) => column.id === metricGroup[0].metrics[0].key);
  const fixedColumns = baseColumns.slice(0, firstMetricsIndex);
  const dynamicColumns = metricsKeys.map((key) => baseColumns.find((column) => column.id === key));
  return [...fixedColumns, ...dynamicColumns];
}, [baseColumns, metrics, metricGroup]);
