export const CONTEXT_MENU_KEY_EDIT_CAMPAIGN = 'edit_campaign';
export const CONTEXT_MENU_KEY_PAUSE_CAMPAIGN = 'pause_campaign';
export const CONTEXT_MENU_KEY_EDIT_BID = 'edit_bid';
export const CONTEXT_MENU_KEY_EDIT_DAILY_BUDGET = 'edit_daily_budget';
export const CONTEXT_MENU_KEY_FILTER_BY_CAMPAIGN = 'filter_by_campaign';
export const CONTEXT_MENU_KEY_EDIT_CREATIVE = 'edit_creative';
export const CONTEXT_MENU_KEY_PAUSE_CREATIVE = 'pause_creative';
export const CONTEXT_MENU_KEY_PREVIEW_CREATIVE = 'preview_creative';

export const METRIC_KEY_ID = 'id';
export const METRIC_KEY_DAILY = 'daily';
export const METRIC_KEY_DAILY_SPEND = 'dailySpend';
export const METRIC_ACCESSOR_DAILY_SPEND = 'dailySpend';
export const METRIC_KEY_DAILY_BUDGET = 'daily';
export const METRIC_KEY_REMAINING_BUDGET = 'remaining';
export const METRIC_ACCESSOR_REMAINING_BUDGET = 'remaining';
export const METRIC_KEY_DATE_CREATED = 'created';
export const METRIC_ACCESSOR_DATE_CREATED = 'created';
export const METRIC_KEY_CREATIVE_FORMAT = 'creativeFormat';
export const METRIC_ACCESSOR_CREATIVE_FORMAT = 'creativeFormat';
export const METRIC_KEY_CREATIVE_TYPE = 'creativeType';
export const METRIC_ACCESSOR_CREATIVE_TYPE = 'creativeType';
