import { modifyAccount, addAccountFund } from 'app/graphql/utils/account';
import Account from './Account';
import Application from './Application';
import { isHexId, isArray, isUndefined } from '../lib/lib';
import { format } from '../lib/currency';

class AdminAccount extends Account {
  constructor(attrs) {
    super(attrs);
    this.map('funds_to_add', (v) => format(parseFloat((v)).toFixed(2)));
    this.map('accounts', this.getArrayRelation('accounts', Account).bind(this));
    this.map('applications', this.getArrayRelation('applications', Application).bind(this));
    this.set('target_account_type', this.get('accounts').length === 0 ? 'none' : 'deny');
    this.set('target_app_type', this.get('applications').length === 0 ? 'none' : 'deny');

    this.default('io.email', '');
  }

  toServerObject() {
    const obj = super.toServerObject();
    if (this.get('target_app_type') !== 'none') {
      const applications = [];
      this.getApplicationIDs().filter(isHexId).forEach((v) => {
        if (applications.findIndex((a) => a.id === v) !== -1) {
          return;
        }
        applications.push({ id: v, type: 'deny' });
      });
      obj.applications = applications;
    }
    if (this.get('target_account_type') !== 'none') {
      const accounts = [];
      this.getAccountIDs().filter(isHexId).forEach((v) => {
        if (accounts.findIndex((a) => a.id === v) !== -1) {
          return;
        }
        accounts.push({ id: v });
      });
      obj.accounts = accounts;
    }
    obj.access_grow = this.raw('access_grow');
    obj.access_api = this.raw('access_api');
    obj.access_application_maximum = this.raw('access_application_maximum');
    obj.access_playable_adtype = this.raw('access_playable_adtype');
    obj.access_native_adtype = this.raw('access_native_adtype');
    obj.modify_roas_campaigns = this.raw('isROASEnabledIOS') || this.raw('isROASEnabledAndroid');
    obj.isROASEnabledIOS = this.raw('isROASEnabledIOS');
    obj.isROASEnabledAndroid = this.raw('isROASEnabledAndroid');
    obj.modify_cppu_campaigns = this.raw('isCPPUEnabledIOS') || this.raw('isCPPUEnabledAndroid');
    obj.isCPPUEnabledIOS = this.raw('isCPPUEnabledIOS');
    obj.isCPPUEnabledAndroid = this.raw('isCPPUEnabledAndroid');
    obj.modify_cpe_campaigns = this.raw('isCPEEnabledIOS') || this.raw('isCPEEnabledAndroid');
    obj.isCPEEnabledIOS = this.raw('isCPEEnabledIOS');
    obj.isCPEEnabledAndroid = this.raw('isCPEEnabledAndroid');
    obj.budget_pacing = {
      is_enabled: this.raw('budget_pacing.is_enabled'),
    };
    obj.budget_settings = {
      cpi: {
        enabled: this.raw('budget_settings.cpi.enabled'),
        min: this.raw('budget_settings.cpi.min'),
        max: this.raw('budget_settings.cpi.max'),
      },
      target_cpi: {
        enabled: this.raw('budget_settings.cpi.enabled'),
        min: this.raw('budget_settings.cpi.min'),
        max: this.raw('budget_settings.cpi.max'),
      },
      cpc: {
        enabled: this.raw('budget_settings.cpc.enabled'),
        min: this.raw('budget_settings.cpc.min'),
        max: this.raw('budget_settings.cpc.max'),
      },
      cpm: {
        enabled: this.raw('budget_settings.cpm.enabled'),
        min: this.raw('budget_settings.cpm.min'),
        max: this.raw('budget_settings.cpm.max'),
      },
      cpcv: {
        enabled: this.raw('budget_settings.cpcv.enabled'),
        min: this.raw('budget_settings.cpcv.min'),
        max: this.raw('budget_settings.cpcv.max'),
      },
      cpv: {
        enabled: this.raw('budget_settings.cpv.enabled'),
        min: this.raw('budget_settings.cpv.min'),
        max: this.raw('budget_settings.cpv.max'),
      },
      cppu: {
        enabled: this.raw('budget_settings.cppu.enabled'),
        min: this.raw('budget_settings.cppu.min'),
        max: this.raw('budget_settings.cppu.max'),
      },
      cpe: {
        enabled: this.raw('budget_settings.cpe.enabled'),
        min: this.raw('budget_settings.cpe.min'),
        max: this.raw('budget_settings.cpe.max'),
      },
      total: {
        enabled: this.raw('budget_settings.total.enabled'),
        min: this.raw('budget_settings.total.min'),
        max: this.raw('budget_settings.total.max'),
      },
      daily: {
        enabled: this.raw('budget_settings.daily.enabled'),
        min: this.raw('budget_settings.daily.min'),
        max: this.raw('budget_settings.daily.max'),
      },
    };
    obj.billing_entity = this.raw('billing_entity');
    obj.budget_type = this.raw('budget_type');
    obj.notifications_email = this.raw('notifications_email');
    obj.io = {
      signed: this.raw('io.signed'),
      type: this.raw('io.type'),
      url: this.raw('io.url'),
      version: this.raw('io.version'),
      email: this.raw('io.email'),
    };
    obj.is_approval_exempt_creatives = this.raw('is_approval_exempt_creatives');
    obj.application_maximum = this.raw('application_maximum');
    obj.creativeMaximum = this.raw('creativeMaximum');
    return obj;
  }

  getApplicationIDs() {
    return this.raw('applications').map((a) => a.get('id'));
  }

  addApplication(app) {
    if (isUndefined(app)) {
      return;
    }
    const applications = this.get('applications');
    if (isArray(app)) {
      const ids = new Set(this.get('applications').map((a) => a.get('id')));
      app.forEach((application) => {
        if (!ids.has(application.get('id'))) {
          applications.push(application);
        }
      });
    } else {
      const index = applications.findIndex((a) => a.get('id') === app.get('id'));
      if (applications.length === 0 || index === -1) {
        applications.push(app);
      }
    }
    this.set('applications', applications);
  }

  getAccountIDs() {
    return this.raw('accounts').map((a) => a.get('id'));
  }

  addAccount(acc) {
    if (isUndefined(acc)) {
      return;
    }
    const accounts = this.get('accounts');
    if (isArray(acc)) {
      const ids = new Set(this.get('accounts').map((a) => a.get('id')));
      acc.forEach((account) => {
        if (!ids.has(account.get('id'))) {
          accounts.push(account);
        }
      });
    } else {
      const index = accounts.findIndex((a) => a.get('id') === acc.get('id'));
      if (accounts.length === 0 || index === -1) {
        accounts.push(acc);
      }
    }
    this.set('accounts', accounts);
  }

  setBudgetType(type) {
    if (type === 'prepay') {
      this.set('budget_pacing.is_enabled', true, false);
    }
    this.set('budget_type', type);
  }

  toggleBudgetPacing(v) {
    if (this.raw('budget_type') === 'prepay') {
      return;
    }
    this.set('budget_pacing.is_enabled', v);
  }

  hasValidFundAmount() {
    const funds = parseFloat(this.raw('funds_to_add'));
    if (Number.isNaN(funds)) {
      return false;
    }
    return Number(funds) === funds;
  }
}

AdminAccount.save = (account) => modifyAccount(
  account.get('id'),
  account.toServerObject(),
);

AdminAccount.fund = (account) => addAccountFund(account.get('id'), parseFloat(account.raw('funds_to_add')).toFixed(2));

export default AdminAccount;
