import React from 'react';
import Checkbox from '../Checkbox/CheckboxContainer';

const MultiCheckbox = ({
  label, className, checked, some, children, showOptions, toggleOptions, onChange,
}) => (
  <div className="component__form__multi-checkbox">
    <div className={className}>
      <i className={`material-icons ${showOptions ? 'arrow_down' : ''}`} onClick={toggleOptions}>
        {!showOptions ? 'keyboard_arrow_right' : 'keyboard_arrow_down'}
      </i>
      <Checkbox label={label} checked={checked} onChange={onChange} some={some} />
    </div>
    <div className={`checkboxes ${showOptions && 'checkboxes--show'}`}>
      {children}
    </div>
  </div>
);

export default MultiCheckbox;
