import React from 'react';
import Spinner from '../Spinner/Spinner';
import Text from '../Form/ValidatedText/ValidatedText';

class AccountFinder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocused: false,
    };
  }

  render() {
    const {
      searchValue,
      onSearch,
      loading,
      results,
      onSelect,
      placeholder = 'Search for an Account',
      accountTypes = null,
      isValid = true,
      disabled = false,
    } = this.props;

    return (
      <div className="component__account-finder">
        <Text
          disabled={disabled}
          disabledText={searchValue}
          icon="search"
          isValid={isValid}
          placeholder={placeholder}
          value={searchValue}
          onChange={onSearch(accountTypes)}
          onFocus={() => this.setState({ isFocused: true })}
          onBlur={() => this.setState({ isFocused: false })}
        />
        {loading && (
          <div className="search-results search-results--loading">
            <Spinner />
          </div>
        )}
        {(results && this.state.isFocused) && (
          <div className="search-results">
            {results.map((account) => (
              <div
                key={account.get('id')}
                className="af-account"
                onMouseDown={() => {
                  this.setState({ isFocused: false });
                  onSelect(account);
                }}
              >
                <div>
                  <p className="p2">
                    {account.get('name')}
                    {account.get('is_deleted') && <span className="p2"> [Archived]</span>}
                  </p>
                  <p className="p2">{account.get('contact.email')}</p>
                </div>
                <div>
                  <p className="p2">{account.get('accountType')}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default AccountFinder;
