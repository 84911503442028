import React from 'react';
import Labels from './Labels';
import './labels.scss';

class LabelsContainer extends React.Component {
  constructor(p) {
    super(p);
    this.state = {
      searchValue: '',
    };
  }

  onSearch = (searchValue) => {
    this.setState({ searchValue });
  }

  render() {
    return (
      <Labels
        {...this.props}
        {...this.state}
        onSearch={this.onSearch}
      />
    );
  }
}

export default LabelsContainer;
