import React from 'react';
import { getAppConfig } from 'lib/helpers';
import {
  compose,
  namespace,
  splash,
} from '../../../../../../app/app';
import { track } from '../../../../../../lib/tracker';
import Asset from '../../../../../../models/Asset';
import Creative from '../../../../../../models/Creative';
import Create from './Create';
import Template from '../../../../../../models/Template';

const ns = 'views.creatives.edit';
const updateState = namespace(ns);
const props = (state) => ({
  ...state[ns],
  isDragging: state.window.isDragging,
});

class CreateCreativeContainer extends React.Component {
  constructor(p) {
    super(p);
    this.state = {};

    const { account } = getAppConfig();
    this.props.creative.set('account', account);
  }

  componentDidMount() {
    this.props.actions.getAssets({ creative: this.props.creative });
  }

  componentWillUnmount() {
    this.props.actions.resetState();
  }

  render() {
    return (
      <Create {...this.props} />
    );
  }
}

const resetState = ({ state }) => updateState(state, { selectedTemplate: null });

const getAssets = (() => {
  const action = ({ creative }) => Asset.getAll({ application: creative.get('application').get('id'), perPage: 100 });
  const end = ({ state, result }) => updateState(state, { assets: Asset.make(result.response) });
  return { action, end };
})();
const getCreatives = (() => {
  const action = ({ creative }) => Creative.getAll({ application: creative.get('application').get('id'), perPage: 100 });
  const end = ({ state, result }) => updateState(state, { creatives: Creative.make(result.response), refreshCreatives: false });
  const start = ({ state }) => updateState(state, { refreshCreatives: false });
  return { action, end, start };
})();
const getTemplates = (() => {
  const action = () => Template.getTemplatesForSSUI();
  const end = ({ state, result }) => updateState(state, { templates: Template.make(result.response) });
  return { action, end };
})();

const createCreative = (() => {
  const action = ({ creative }) => Creative.create(creative.toServerObject(creative));
  const start = ({ state }) => ({
    ...updateState(state, { creatingCreative: true }),
    ...splash({ start: true, text: 'saving creative' }),
  });
  const end = ({ state, result, props: p }) => {
    const { history } = p;

    if (!result.ok) {
      return {
        ...splash({ finish: true, text: 'Something wrong happens!' }),
      };
    }

    setTimeout(() => {
      history.push('/creatives/create/campaigns');
    }, 128);
    const creative = new Creative(result.response);
    creative.set('application', state[ns].creative.get('application'));
    creative.set('templateType', result.response.template.template_category);

    return {
      ...track('Creative Created', { id: result.response.id }),
      ...updateState(state, { creative }),
      ...splash({ finish: true, text: 'success!' }),
    };
  };
  return { action, start, end };
})();

const actions = {
  createCreative,
  getAssets,
  getCreatives,
  resetState,
};

export default compose(CreateCreativeContainer, { actions, props });
export {
  CreateCreativeContainer as CreateCreativeContainerUnwrapped,
  getTemplates,
};
