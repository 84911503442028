import React from 'react';
import Button from '../../components/Button/Button';
import Icon from '../../components/Icon/Icon';
import Link from '../../components/Link/Link';
import './application.scss';
import { canModifyCampaigns } from '../../lib/helpers/authUser';

const NavLink = ({ to, text, onClick }) => (
  <Link to={to} onClick={onClick}>
    <Button secondary>{text}</Button>
  </Link>
);

const AppLogo = ({
  application, showContextMenu, addCampaign, shadow,
}) => (
  <div className={`template__application__logo f fc ${shadow && 'template__application__logo--shadow'}`}>
    <div className={`banner banner--${application.get('platform')}`}>
      {Icon.forPlatform(application.get('platform'))}
    </div>
    <div className="image-container">
      <img src={application.get('store.thumbnail')} />
    </div>
    {!showContextMenu && (
      <div className="details f fh">
        <span className="p5 flex">{application.get('name')}</span>
      </div>
    )}
    {showContextMenu && (
      <nav>
        {canModifyCampaigns() && application.isValidForCampaignCreation() && (
          <NavLink text="Add Campaign" to="/campaigns/create/tracking" onClick={addCampaign} />
        )}
        <NavLink text="View Campaigns" to={`/campaigns?application=${application.get('id')}`} />
      </nav>
    )}
  </div>
);

const AppMenu = ({ application }) => (
  <div className="template__application__description">
    <div className="app-info">
      <div className="app-name">{application.get('name')}</div>
      <div>
        <b>Application ID: </b>
        {application.get('store.id')}
      </div>
      <div>{application.get('store.company')}</div>
    </div>
    <div className="app-info">
      <Link to={`/campaigns?application=${application.get('id')}`}>
        {`${application.get('campaign_count')} Total Campaigns`}
      </Link>
      <Link to={`/creatives?application=${application.get('id')}`}>
        {`${application.get('creative_count')} Total Creatives`}
      </Link>
    </div>
  </div>
);

const ApplicationTemplate = ({
  application, showContextMenu = false, addCampaign = () => {}, shadow = false,
}) => (
  <div className={`template__application ${showContextMenu && 'template__application--menu'}`}>
    <AppLogo
      application={application}
      showContextMenu={showContextMenu}
      addCampaign={addCampaign}
      shadow={shadow}
    />
    {showContextMenu && <AppMenu application={application} />}
  </div>
);

export default ApplicationTemplate;
