export const SET_KEY_A = 'a';
export const SET_KEY_B = 'b';

export const THRESHOLD_TOTAL = 50000;
export const THRESHOLD_P_VALUE = 0.05;

export const STATUS_KEY_COMPLETE = 'complete';
export const STATUS_KEY_IN_PROGRESS_WITHOUT_ENOUGH_CONFIDENCE = 'inProgressWithoutEnoughConfidence';
export const STATUS_KEY_IN_PROGRESS_WITHOUT_ENOUGH_IMPRESSIONS = 'inProgressWithoutEnoughImpressions';

export const abTestReportsSets = [
  {
    key: SET_KEY_A,
    title: 'Set A (Control)',
    total: THRESHOLD_TOTAL,
    impressions: 0,
    ipm: 0,
  },
  {
    key: SET_KEY_B,
    title: 'Set B (Test)',
    total: THRESHOLD_TOTAL,
    impressions: 0,
    ipm: 0,
  },
];
