import React from 'react';

const ContextMenuWrapper = ({
  children, makeRef, reportsOptions, closeOnClick = true, style, menuID,
}) => (
  <div
    className={`components__context_menu_options ${reportsOptions} ${menuID}`}
    ref={makeRef}
    style={style}
    onClick={(e) => {
      if (!closeOnClick) {
        e.nativeEvent.stopImmediatePropagation();
        e.stopPropagation();
      }
    }}
  >
    {children}
  </div>
);

class ContextMenuWrapperContainer extends React.Component {
  constructor(p) {
    super(p);
    this.state = {};
    this.element = null;
  }

  componentDidMount() {
    this.props.makeRef(this.element);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleBlur);
  }

  handleBlur = () => {
    this.setState({ active: false });
    document.removeEventListener('click', this.handleBlur);
  }

  toggle = () => {
    this.setState((prevState) => {
      const active = !prevState.active;
      if (active) {
        document.addEventListener('click', this.handleBlur);
      } else {
        document.removeEventListener('click', this.handleBlur);
      }
      return ({ active });
    });
  }

  render() {
    return (
      <ContextMenuWrapper {...this.props} makeRef={(node) => { this.element = node; }} />
    );
  }
}

export default ContextMenuWrapperContainer;
