export function toCommasFormat(number) {
  if (!number) {
    return number;
  }
  const integer = number.split('.')[0] || '0';
  const decimal = number.split('.')[1] || '';
  const val = parseFloat(number)?.toFixed(decimal.length > 2 ? decimal.length : 2) || number;
  const newInteger = integer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const newDecimal = val.split('.')[1] || '00';
  return `${newInteger}.${newDecimal}`;
}

export function toNumberFormat(number) {
  if (!number) {
    return number;
  }

  const decimal = number.split('.')[1] || '';
  if (Number.isNaN(parseFloat(number))) {
    return undefined;
  }

  return parseFloat(number)?.toFixed(decimal.length > 2 ? decimal.length : 2) || number;
}

export default {};
