import React from 'react';
import classnames from 'classnames';
import imgMeatballs from '../../../assets/img/liftoff-rocket.svg';
import {
  compose, Switch, Route, Redirect,
} from '../../../app/app';
import Link from '../../../components/Link/Link';
import Logo from '../../../components/Logo/Logo';
import Account from '../../../models/Account';
import Container from './container';
import CampaignEdit from './campaigns/Edit/EditContainer';
import ContentModal from '../../../components/Modals/Content/ContentContainer';
import CreativeEdit from './creatives/Edit/CreativesEditContainer';
import CreativePreview from '../../../components/Creatives/Preview/PreviewContainer';
import ConfirmAction from '../../../components/Modals/ConfirmAction/ConfirmActionContainer';
import TermsOfService from '../../../components/TermsOfService/TermsOfServiceContainer';
import AlertModal from '../../../components/Modals/Alert/AlertContainer';
import PlatformSelector from '../../../components/V2/PlatformSelector/PlatformSelector';
import paths from '../paths';
import { AccessDeniedModal } from '../../../components/V2/PlatformDownModal/PlatformDownModal';
import { remove } from '../../../lib/session';

const props = (state) => ({
  appConfig: state.setup && state.setup.appConfig, redirect: state.redirect || false, authUser: state.authUser,
});

const {
  CAMPAIGN: {
    pathString: PATH_CAMPAIGN_EDIT,
  },
  CREATIVE: {
    pathString: PATH_CREATIVES_EDIT,
  },
  ASSETS: {
    pathString: PATH_LIST_ASSETS,
  },
} = paths;

export const NavLink = ({
  to, icon, text, active,
}) => (
  <Link to={to}>
    <div className={`nav-link f fc fh fv ${active && 'nav-link--active'}`}>
      <i className="material-icons">{icon}</i>
      <span>{text}</span>
    </div>
  </Link>
);

function isSmallMenuPage(location) {
  // Currently, the campaign create/edit page and the creative create/edit page show small menu
  const smallPageReg = /^\/(campaigns|creatives)\/.+/;
  return smallPageReg.test(location.pathname);
}

const App = ({
  location,
  props: {
    appConfig,
    authUser,
  },
}) => {
  // old code used to combine user and account permissions. But user.permissions are not used anymore. Auth app
  // send accounts permissions in property accountFeatureFlags, so we need to send accountFeatureFlags as "permissions"
  // to initialize account. Note that authUser.permissions are set in milton based on "role" from jwt.
  const account = new Account(appConfig?.account);
  const hasPermissionForPlayable = account.hasPermissionForPlayable();

  if (authUser.accountFeatureFlags && authUser.accountFeatureFlags['access-grow-vungle-com'] === false) {
    remove('authUser');
    return (
      <>
        <AccessDeniedModal accountId={authUser.account} />
      </>
    );
  }

  return (
    <div className="app">
      <nav className={classnames('menu', { 'menu--small': isSmallMenuPage(location) })}>
        <Link to="/">
          {isSmallMenuPage(location) ? <img src={imgMeatballs} /> : <Logo />}
        </Link>
        <PlatformSelector isAdmin={false} />
        <NavLink
          text="Applications"
          icon="apps"
          to="/applications"
          active={location.pathname.indexOf('/applications') === 0}
        />
        <NavLink
          text="Campaigns"
          icon="format_list_bulleted"
          to="/campaigns"
          active={location.pathname.indexOf('/campaigns') === 0}
        />
        <NavLink
          text="Creatives"
          icon="palette"
          to="/creatives"
          active={location.pathname.indexOf('/creatives') === 0}
        />
        {
          hasPermissionForPlayable && (
            <NavLink
              text="Assets"
              icon="image"
              to={PATH_LIST_ASSETS}
              active={location.pathname.indexOf(PATH_LIST_ASSETS) === 0}
            />
          )
        }
        <NavLink
          text="Reports"
          icon="timeline"
          to="/reports"
          active={location.pathname.indexOf('/reports') === 0}
        />
      </nav>
      <Switch>
        <Route
          path={PATH_CAMPAIGN_EDIT}
          component={CampaignEdit}
        />
        <Route
          path={PATH_CREATIVES_EDIT}
          component={CreativeEdit}
        />
        <Route path="/" render={() => <Container />} />
      </Switch>
      <CreativePreview />
      <ConfirmAction />
      <AlertModal />
      <ContentModal />
      {appConfig && appConfig.account && !appConfig.account.tos_signed && (
        <TermsOfService config={appConfig} />
      )}
    </div>
  );
};

class AppContainer extends React.Component {
  componentDidMount() {
    if (this.props.redirect) {
      this.props.actions.disableRedirect();
    }
  }

  UNSAFE_componentWillReceiveProps() {
    if (this.props.redirect) {
      this.props.actions.disableRedirect();
    }
  }

  render() {
    if (this.props.redirect) {
      return <Redirect to={this.props.redirect} />;
    }
    return <App {...this.props} {...this.state} />;
  }
}

const disableRedirect = () => ({ redirect: false });

export default compose(AppContainer, { props, actions: { disableRedirect } });

export { App as AppInner };
