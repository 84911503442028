import React from 'react';
import { FILTER_KEY_MEMBER_STATUS } from 'app/constants/filters';
import ConfirmBox from '../../../../../components/Modals/Confirm';
import ContextMenu from '../../../../../components/ContextMenu/ContextMenuContainer';
import FiltersContainer from '../../../../../components/ListFilters/Filters/FiltersContainer';
import { getFilters, getDefaultChecked } from '../../../../../components/V2/Filters/filters';

const filterKeys = [FILTER_KEY_MEMBER_STATUS];

const filtersList = getFilters(filterKeys);

const defaultCheckedFilters = getDefaultChecked(filterKeys);

const List = ({ props: { users = [], userToDelete, authUser }, actions: { deleteUser, submitDeleteUser, getUsers }, router }) => (
  <div className="views__team__list">
    {userToDelete && (
      <ConfirmBox
        header="Delete User"
        name={userToDelete.get('email')}
        onCancel={() => deleteUser(null)}
        onConfirm={() => submitDeleteUser(userToDelete)}
      />
    )}
    <FiltersContainer
      searchPlaceholderText="Search..."
      filters={filtersList}
      defaultCheckedFilters={defaultCheckedFilters}
      refreshList={(filters) => getUsers({ ...filters, account: authUser.account })}
      router={router}
      location={router.location}
      history={router.history}
      cta="Add Team Member"
      to="/team/invite"
    />
    <div className="members">
      {users.map((user) => (
        <div key={user.get('id')} className="member">
          <div className="active">
            <div className={`activity-dot activity-dot--${user.get('status')}`} />
            <div className="options">
              <ContextMenu>
                <ContextMenu.LinkOption to={`/team/${user.get('id')}`} text="Edit Member" />
                { !user.get('is_primary') && <ContextMenu.ClickOption onClick={() => deleteUser(user)} text="Delete Member" /> }
              </ContextMenu>
            </div>
          </div>
          <div className="details">
            <p className="p2">{user.get('name')}</p>
            <p className="p2">{user.get('email')}</p>
            <p className="p6">{user.get('role')}</p>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default List;
