import React from 'react';
import Pill from '../../Pill/Pill';

const Option = ({
  disabled,
  label,
  name,
  value,
  checkedValue,
  onChange,
  description,
  bordered = false,
  adminOnly = false,
  androidOnly = false,
  beta = false,
}) => {
  const disabledClass = (disabled ? 'disabled' : '');
  const active = (value === checkedValue ? 'active' : '');
  const checkedStyle = (value === checkedValue ? 'bordered-checked' : 'bordered-unchecked');
  const checkedClassName = (bordered ? checkedStyle : '');
  return (
    <div
      className={`radio_wrapper ${disabledClass} ${checkedClassName}`}
      onClick={() => (!disabled ? onChange(value) : undefined)}
    >
      <label
        htmlFor={`generated_radio_id_${name}_${value}`}
        className={active}
      >
        <input
          id={`generated_radio_id_${name}_${value}`}
          disabled={disabled}
          type="radio"
          name={name}
          value={value}
          defaultChecked={value === checkedValue}
        />
        <span>
          <i className="material-icons">
            {value === checkedValue ? 'radio_button_checked' : 'radio_button_unchecked'}
          </i>
        </span>
      </label>
      <p className="label">
        {label}
        {!androidOnly && adminOnly && (<Pill>Admin Only Feature</Pill>)}
        {androidOnly && adminOnly && (<Pill>Admin & Android Only Feature</Pill>)}
        {beta && (<Pill beta>BETA</Pill>)}
      </p>

      {description
        && (
        <p className="text">
          <span>
            { description }
          </span>
        </p>
        )}

    </div>
  );
};

export default Option;
