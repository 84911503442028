/* eslint-disable react/display-name */
import React from 'react';
import { convert } from 'lib/date';
import { firstLetterUpper } from 'lib/lib';
import { canModifyCampaigns } from 'lib/helpers/authUser';
import { validateBidding } from 'lib/helpers/campaigns/campaignIndex';
import Link from 'components/Link/Link';
import { Pulsation, TooltipCutoff } from 'components/V2';
import { TableNameColumn } from 'components/V2/List';
import Label from 'components/V2/Label/Label';
import { makeColumn, makeWithMetricColumns } from 'components/V2/List/Columns/columns';
import { ServingStatusComponent } from 'components/Campaigns/ServingStatus/ServingStatus';
import { LTVMetrics, standardMetrics } from 'components/V2/Metrics/Metrics';
import { makeListFilterQuery } from 'components/V2/List/ListFilter/helper';
import triggerConfirm from 'components/Modals/ConfirmAction/triggerConfirm';
import { changeCampaignArchiveStatus } from 'components/Campaigns/ContextMenu/CampaignsContextMenu';
import {
  defaultSort,
  EXTRA_QUERY_KEYS,
  COLUMN_ACCOUNT,
  COLUMN_APPLICATION,
  COLUMN_BID,
  COLUMN_BID_TYPE,
  COLUMN_CAMPAIGN_NAME,
  COLUMN_CAMPAIGN_STATUS,
  COLUMN_CREATIVES,
  COLUMN_RUN_DATES,
  COLUMN_DATE_CREATED,
  COLUMN_DATE_UPDATED,
  COLUMN_DAILY_BUDGET,
  COLUMN_TOTAL_BUDGET,
  COLUMN_REMAINING_BUDGET,
  COLUMN_TAGS,
  TABLE_ACTION_EDIT_CAMPAIGN,
  TABLE_ACTION_PAUSE_CAMPAIGN,
  TABLE_ACTION_ACTIVATE_CAMPAIGN,
  TABLE_ACTION_CLONE_CAMPAIGN,
  TABLE_ACTION_VIEW_ACTIVITY,
  TABLE_ACTION_DELETE_CAMPAIGN,
  TABLE_ACTION_RESTORE_CAMPAIGN,
} from './constants';

const baseUrl = 'campaigns';
export const initMetrics = (metricCache, attributeMetrics) => {
  const value = metricCache.get();
  if (value) {
    return value;
  }

  const defaultMetrics = attributeMetrics.metrics.filter((m) => m.default);
  /*
  * Save default metrics if current user not do any selection.
  * This will prevent the metric dropdown render empty selection.
  */
  metricCache.save(defaultMetrics);

  return defaultMetrics;
};

const makeCampaignEditPath = (campaign, isMissionControl) => {
  if (isMissionControl) {
    return `/campaigns/${campaign.get('id')}`;
  }

  let to = 'confirm';
  if (campaign.get('creatives').length === 0) {
    to = 'creatives';
  }
  if (!validateBidding(campaign)) {
    to = 'bidding';
  }

  return `/campaigns/${campaign.get('id')}/${to}`;
};

const changeServingStatus = (campaign, execChangeCampaignStatus) => {
  const [
    header, status, message,
  ] = campaign.get('status') === 'paused'
    ? ['Activate Campaign', 'activate', 'Are you sure you want to activate this Campaign?']
    : ['Pause Campaign', 'pause', 'Are you sure you want to stop this Campaign from serving?'];
  triggerConfirm({
    type: 'CHANGE_CAMPAIGN_SERVING_CONFIRM_ACTION',
    header,
    message,
    name: campaign.get('name'),
    onConfirm: () => {
      execChangeCampaignStatus({ campaign, status });
    },
  });
};

const tableActionList = [
  {
    key: TABLE_ACTION_EDIT_CAMPAIGN,
    action: ({ history, campaign, isMissionControl }) => {
      history.push(makeCampaignEditPath(campaign, isMissionControl));
    },
  },
  {
    key: TABLE_ACTION_CLONE_CAMPAIGN,
    action: ({ campaign, onClone }) => {
      onClone(true, campaign.get('id'));
    },
    validate: ({ isAdmin }) => isAdmin,
  },
  {
    key: TABLE_ACTION_PAUSE_CAMPAIGN,
    validate: ({ campaign }) => campaign.isActive(),
    action: ({ campaign, execChangeCampaignStatus }) => {
      changeServingStatus(campaign, execChangeCampaignStatus);
    },
  },
  {
    key: TABLE_ACTION_ACTIVATE_CAMPAIGN,
    validate: ({ campaign }) => !campaign.isActive(),
    action: ({ campaign, execChangeCampaignStatus }) => {
      changeServingStatus(campaign, execChangeCampaignStatus);
    },
  },
  {
    key: TABLE_ACTION_VIEW_ACTIVITY,
    validate: ({ isAdmin }) => isAdmin,
    action: ({ history, campaign }) => {
      history.push(`campaigns/${campaign.get('id')}/history`);
    },
  },
  {
    key: TABLE_ACTION_DELETE_CAMPAIGN,
    validate: ({ isAdmin, campaign }) => isAdmin && !campaign.hasBeenDeleted(),
    action: ({ campaign }) => changeCampaignArchiveStatus(campaign),
  },
  {
    key: TABLE_ACTION_RESTORE_CAMPAIGN,
    validate: ({ isAdmin, campaign }) => isAdmin && campaign.hasBeenDeleted(),
    action: ({ campaign }) => changeCampaignArchiveStatus(campaign),
  },
];

export const makeTableActionList = (campaign, isAdmin) => {
  const validActions = tableActionList.filter(({ validate }) => (
    !campaign
    || !validate
    || validate({ campaign, isAdmin })
  ));
  return validActions.map((a) => a.key);
};

export const execTableAction = (
  row,
  action,
  history,
  onClone,
  execChangeCampaignStatus,
  isMissionControl,
) => {
  const { campaign } = row;
  const context = tableActionList.find((a) => a.key === action);
  context.action({
    isMissionControl,
    history,
    campaign,
    onClone,
    execChangeCampaignStatus,
  });
};

export const makeAttributeMetrics = (isMissionControl) => {
  const metrics = [
    {
      key: COLUMN_CAMPAIGN_STATUS,
      name: 'Status',
      default: true,
    },
    {
      key: COLUMN_APPLICATION,
      name: 'Application',
      default: true,
    },
    {
      key: COLUMN_BID,
      name: 'Bid',
      default: true,
    },
    {
      key: COLUMN_BID_TYPE,
      name: 'Type',
      default: true,
    },
    {
      key: COLUMN_TOTAL_BUDGET,
      name: 'Total Budget',
      default: true,
    },
    {
      key: COLUMN_REMAINING_BUDGET,
      name: 'Remaining Budget',
      default: true,
    },
    {
      key: COLUMN_DAILY_BUDGET,
      name: 'Daily Budget',
      default: true,
    },
    {
      key: COLUMN_RUN_DATES,
      name: 'Run Dates',
      default: true,
    },
    {
      key: COLUMN_DATE_UPDATED,
      name: 'Date Updated',
      default: true,
    },
    {
      key: COLUMN_CREATIVES,
      name: 'Creatives',
      default: false,
    },
    {
      key: COLUMN_DATE_CREATED,
      name: 'Date Created',
      default: false,
    },
  ];

  if (isMissionControl) {
    metrics.push({
      key: COLUMN_ACCOUNT,
      name: 'Account',
      default: false,
    });
    metrics.push({
      key: COLUMN_TAGS,
      name: 'Tags',
      default: true,
    });
  }

  return {
    name: 'Attributes',
    metrics,
  };
};

export const makeCampaignNameColumn = (isMissionControl) => ({
  ...makeColumn({
    fixed: 'left',
    minWidth: 290,
    title: 'Campaign',
    accessor: COLUMN_CAMPAIGN_NAME,
  }),
  Cell: ({ original }) => {
    const { campaign, isTotalRow } = original;
    if (isTotalRow) {
      return 'Total';
    }
    return (
      <TableNameColumn
        name={campaign.get('name')}
        id={campaign.get('id')}
        platform={campaign.get('application.platform')}
        thumbnail={campaign.get('application.store.thumbnail')}
        to={makeCampaignEditPath(campaign, isMissionControl)}
      />
    );
  },
});

export const makeCampaignTagsColumn = () => ({
  ...makeColumn({
    title: 'Tags',
    accessor: COLUMN_TAGS,
    disableSort: true,
  }),
  Cell: ({ original }) => {
    const { campaign, isTotalRow } = original;
    if (isTotalRow) {
      return null;
    }
    return (
      <div className="pills">
        {(campaign.get('status') === 'paused' || campaign.get('archived') === true) && (
          <Label className="pill pill--paused">paused</Label>
        )}
        {(campaign.get('admin_status') === 'pending' || campaign.get('has_pending_changes')) && (
        <Link includeLink to={`/${baseUrl}/${campaign.get('id')}/review`}>
          <Label className="pill pill--pending">needs review</Label>
        </Link>
        )}
        {campaign.get('admin_status') === 'rejected' && (
          <Label className="pill pill--rejected">rejected</Label>
        )}
        {campaign.get('is_deleted') && (
          <Label className="pill pill--archived">deleted</Label>
        )}
      </div>
    );
  },
});

export const makeCampaignAccountColumn = () => ({
  ...makeColumn({
    title: 'Account',
    accessor: COLUMN_ACCOUNT,
  }),
  Cell: ({ original }) => {
    const { campaign, isTotalRow } = original;
    if (isTotalRow) {
      return '-';
    }

    return (
      <TableNameColumn
        hidePlatform
        hideThumbnail
        id={campaign.get('account.id')}
        name={campaign.get('account.name')}
        to={`/accounts/${campaign.get('account.id')}`}
      />
    );
  },
});

export const makeAttributeColumns = (isMissionControl, execChangeCampaignStatus) => {
  const columns = [
    {
      ...makeColumn({
        title: 'Status',
        accessor: COLUMN_CAMPAIGN_STATUS,
      }),
      Cell: ({ original }) => {
        const { campaign, isTotalRow } = original;
        if (isTotalRow) {
          return '-';
        }
        const status = campaign.isEligibleToServe() ? 'active' : 'inactive';
        return (
          <div className="status_container">
            {campaign.get('has_pending_changes') && (
              <div className="components__flag">
                <div className="flag">
                  <i className="material-icons">flag</i>
                </div>
                <div className="text">
                  <p className="p2">Your Campaign changes are pending review.</p>
                </div>
              </div>
            )}
            <ServingStatusComponent
              mcList={isMissionControl}
              campaign={campaign}
              showTooltip={canModifyCampaigns()}
              changeServingStatus={() => changeServingStatus(campaign, execChangeCampaignStatus)}
            >
              <Pulsation status={status} labelText={firstLetterUpper(status)} />
            </ServingStatusComponent>
          </div>
        );
      },
    },
    {
      ...makeColumn({
        minWidth: 290,
        title: 'Application',
        accessor: COLUMN_APPLICATION,
      }),
      Cell: ({ original }) => {
        const application = original?.campaign?.get('application');
        const { isTotalRow } = original;
        if (isTotalRow) {
          return '-';
        }
        return (
          <TableNameColumn
            hidePlatform
            hideThumbnail
            name={application?.get('name')}
            id={application?.get('id')}
            to={`/applications/${application?.get('id')}`}
          />
        );
      },
    },
    {
      ...makeColumn({
        title: 'Run Dates',
        accessor: COLUMN_RUN_DATES,
      }),
      Cell: ({ original }) => {
        const { campaign, isTotalRow } = original;
        if (isTotalRow) {
          return '-';
        }
        return <TooltipCutoff title={campaign.getDateString()} />;
      },
    },
    {
      ...makeColumn({
        title: 'Daily Budget',
        accessor: COLUMN_DAILY_BUDGET,
      }),
      Cell: ({ original }) => {
        const { campaign, isTotalRow } = original;
        if (isTotalRow) {
          return '-';
        }
        return <TooltipCutoff title={campaign.getActualBudget('daily')} />;
      },
    },
    {
      ...makeColumn({
        title: 'Type',
        accessor: COLUMN_BID_TYPE,
      }),
      Cell: ({ original }) => {
        const { campaign, isTotalRow } = original;
        if (isTotalRow) {
          return '-';
        }
        return <TooltipCutoff title={campaign.getBudgetType()} />;
      },
    },
    {
      ...makeColumn({
        title: 'Bid',
        accessor: COLUMN_BID,
      }),
      Cell: ({ original }) => {
        const { campaign, isTotalRow } = original;
        if (isTotalRow) {
          return '-';
        }
        return <TooltipCutoff title={campaign.getActualBudget('bid')} />;
      },
    },
    {
      ...makeColumn({
        title: 'Creatives',
        accessor: COLUMN_CREATIVES,
      }),
      Cell: ({ original }) => {
        const { campaign, isTotalRow } = original;
        if (isTotalRow) {
          return '-';
        }
        let to = '';
        if (isMissionControl) {
          to = `/campaigns/${campaign.get('id')}`;
        } else if (canModifyCampaigns() && campaign.isValidForEditing()) {
          to = `/campaigns/${campaign.get('id')}/creatives`;
        }

        return (
          <Link to={to}>
            <p className="body__link">{campaign.get('creatives').length}</p>
          </Link>
        );
      },
    },
    {
      ...makeColumn({
        title: 'Date Created',
        accessor: COLUMN_DATE_CREATED,
      }),
      Cell: ({ original }) => {
        const { campaign, isTotalRow } = original;
        if (isTotalRow) {
          return '-';
        }
        return campaign.get('created')
          ? <TooltipCutoff title={convert(campaign.get('created'), 'DD MMM YYYY')} />
          : null;
      },
    },
    {
      ...makeColumn({
        title: 'Date Updated',
        accessor: COLUMN_DATE_UPDATED,
      }),
      Cell: ({ original }) => {
        const { campaign, isTotalRow } = original;
        if (isTotalRow) {
          return '-';
        }
        return campaign.get('updated')
          ? <TooltipCutoff title={convert(campaign.get('updated'), 'DD MMM YYYY')} />
          : null;
      },
    },
    {
      ...makeColumn({
        title: 'Total Budget',
        accessor: COLUMN_TOTAL_BUDGET,
      }),
      Cell: ({ original }) => {
        const { campaign, isTotalRow } = original;
        if (isTotalRow) {
          return '-';
        }
        return <TooltipCutoff title={campaign.getActualBudget('total')} />;
      },
    },
    {
      ...makeColumn({
        title: 'Remaining Budget',
        accessor: COLUMN_REMAINING_BUDGET,
      }),
      Cell: ({ original }) => {
        const { campaign, isTotalRow } = original;
        if (isTotalRow) {
          return '-';
        }
        return <TooltipCutoff title={campaign.getBudgetRemaining()} />;
      },
    },
  ];

  if (isMissionControl) {
    columns.push(makeCampaignAccountColumn());
    columns.push(makeCampaignTagsColumn());
  }

  return columns;
};

export const attributeMetrics = makeAttributeMetrics;

export const metricsGroup = (isMissionControl) => [
  attributeMetrics(isMissionControl),
  standardMetrics,
  LTVMetrics,
];

const attributeColumns = (isMissionControl) => makeAttributeColumns(isMissionControl);

export const allColumns = (isMissionControl) => makeWithMetricColumns(attributeColumns(isMissionControl));

export const columnsFromMetrics = (metrics, isMissionControl) => {
  const columns = [
    makeCampaignNameColumn(isMissionControl),
    ...metrics.map((m) => allColumns(isMissionControl).find((c) => (m.key === c.id))),
  ];
  return columns;
};

export const makeGetCampaignsQuery = (
  caches,
  query = {},
) => makeListFilterQuery({
  dimension: 'campaign',
  caches,
  query,
  defaultSort,
  extraQueryKeys: EXTRA_QUERY_KEYS,
});
