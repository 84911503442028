import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import useRxjsDebounce from 'lib/hooks/useRxjsDebounce';
import { Input } from 'components/V2';
import { getFilters } from 'components/V2/Filters/filters';
import CombinedFilters from 'components/V2/Filters/CombinedFilters/CombinedFilters';
import DatePickerFilter from 'components/V2/Report/Filters/DatePickerFilter/DatePickerFilter';
import './listFilter.scss';

const { Search } = Input;

const ListFilter = ({
  filterKeys,
  search,
  checkedDate,
  checkedFilters,
  onSearchChange,
  onDateChange,
  onChange,
}) => {
  const [value, setValue] = useState(search);
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const searchInput$ = useRxjsDebounce(onSearchChange, 500);

  const handleInputChange = useCallback((event) => {
    setValue(event.target.value);
    searchInput$.next(event.target.value);
  }, [searchInput$]);

  return (
    <div className="list_widgets_filter">
      <div className="search_wrapper">
        <Search
          value={value}
          placeholder="Search..."
          onChange={handleInputChange}
        />
      </div>
      <div className="filter_container">
        <CombinedFilters
          dropdownFixed
          filters={getFilters(filterKeys)}
          checkedFilters={checkedFilters}
          defaultCheckedFilters={checkedFilters}
          renderLeft={() => (
            <DatePickerFilter
              date={checkedDate}
              visible={datePickerVisible}
              onVisibleChange={setDatePickerVisible}
              onChange={onDateChange}
            />
          )}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

ListFilter.propTypes = {
  filterKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  search: PropTypes.string,
  checkedDate: PropTypes.objectOf(PropTypes.any),
  checkedFilters: PropTypes.objectOf(PropTypes.any),
  onSearchChange: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

ListFilter.defaultProps = {
  search: '',
  checkedDate: null,
  checkedFilters: null,
};

export default ListFilter;
