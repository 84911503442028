import {
  isBoolean, isArray, map, size, toInteger,
} from 'lodash';
import {
  FILTER_KEY_CAMPAIGN_STATUS,
  FILTER_KEY_APPLICATION,
  FILTER_KEY_PLATFORM,
  FILTER_KEY_BID_TYPE,
  FILTER_KEY_NETWORK_TYPE,
  FILTER_KEY_DELETION_STATUS,
  FILTER_KEY_SKOVERLAY_AUTO_SHOW,
  FILTER_KEY_CAMPAIGN_BUDGET_OBJECTIVES,
} from 'app/constants/filters';
import { exchangeProtocol } from 'app/constants/campaign';
import {
  canModifyCampaignBudgetMultiplier,
  canModifyCampaignCvrMultiplier,
} from 'lib/helpers/authUser';
import config from 'lib/config';
import { getServiceType } from 'lib/serviceType';
import { getClient } from '../setup';
import handleGraphQLError from './handle-graphql-error';
import { getGraphQLPlatform } from './get-graphql-platform';
import {
  GetCampaign,
  GetCampaigns,
  GetCampaignsForCreativeAttachment,
  GetLargeBiddingDiff,
  PauseCampaign,
  ActivateCampaign,
  DeleteCampaign,
  RestoreCampaign,
  CreateCampaign,
  ModifyCampaign,
  CampaignFromSalesforce,
  CampaignsFromSalesforce,
  GetDynamicGeos,
} from '../operations_campaign.graphql';
import { getGraphQLRateType, getSearchAndIDsFilter } from './helper';
import { convertToRestFormat as convertApplicationToRestFormat } from './application';

const { clickEvent } = config.get('campaigns');

const trackingEventNames = [
  { api: 'start', view: 'play_start' },
  { api: 'firstQuartile', view: 'play_25%' },
  { api: 'midpoint', view: 'play_50%' },
  { api: 'thirdQuartile', view: 'play_75%' },
  { api: 'complete', view: 'play_finish' },
  { api: 'mute', view: 'mute' },
  { api: 'unmute', view: 'unmute' },
  { api: 'videoClose', view: 'video_close' },
  { api: 'postrollView', view: 'postroll_view' },
  { api: 'postrollClose', view: 'postroll_close' },
];

function getTrackingEventApiName(name) {
  for (let i = 0; i < trackingEventNames.length; i++) {
    if (trackingEventNames[i].view === name) {
      return trackingEventNames[i].api;
    }
  }
  return name;
}

function getTrackingEventViewName(name) {
  for (let i = 0; i < trackingEventNames.length; i++) {
    if (trackingEventNames[i].api === name) {
      return trackingEventNames[i].view;
    }
  }
  return name;
}

export function getCampaignQueryVariables(filters) {
  const variables = {
    ...getSearchAndIDsFilter(filters),
    page: Number(filters.page),
    perPage: Number(filters.perPage),
    account: filters.account,
    applications: filters[FILTER_KEY_APPLICATION],
    adTypes: filters[FILTER_KEY_NETWORK_TYPE],
    budgetObjectives: filters[FILTER_KEY_CAMPAIGN_BUDGET_OBJECTIVES],
  };
  const status = filters[FILTER_KEY_CAMPAIGN_STATUS];
  if (size(status) === 1) {
    [variables.status] = status;
  }

  const platforms = filters[FILTER_KEY_PLATFORM];
  if (platforms) {
    variables.platforms = platforms.map(getGraphQLPlatform);
  }
  const rateTypes = filters[FILTER_KEY_BID_TYPE];
  if (rateTypes) {
    variables.campaignTypes = rateTypes.map(getGraphQLRateType);
  }

  const deletionStatus = filters[FILTER_KEY_DELETION_STATUS];
  if (isArray(deletionStatus) && deletionStatus.length > 0) {
    [variables.deletionStatus] = deletionStatus;
  }

  const skOverlayAutoShow = filters[FILTER_KEY_SKOVERLAY_AUTO_SHOW];
  if (isBoolean(skOverlayAutoShow)) {
    variables.skOverlayAutoShow = skOverlayAutoShow;
  }

  const { sort } = filters;
  if (sort) {
    variables.sort = sort;
  }

  const { isSkadnetworkEnabled } = filters;
  if (isBoolean(isSkadnetworkEnabled)) {
    variables.isSkadnetworkEnabled = isSkadnetworkEnabled;
  }

  const { attributionMethod } = filters;
  if (attributionMethod) {
    variables.attributionMethod = attributionMethod;
  }

  const budgetObjectives = filters[FILTER_KEY_CAMPAIGN_BUDGET_OBJECTIVES];
  if (budgetObjectives) {
    variables.budgetObjectives = budgetObjectives.split(',');
  }

  const { attachedCreativesMaxLength } = filters;
  if (attachedCreativesMaxLength) {
    variables.attachedCreativesMaxLength = attachedCreativesMaxLength;
  }

  return variables;
}

function formatCreative(c) {
  return Object.assign(c, { vungle_name: c.vungleName });
}

export function convertToRestFormat(campaign) {
  if (!campaign) {
    return undefined;
  }

  const result = {
    id: campaign.id,
    name: campaign.name,
    created: campaign.created,
    updated: campaign.updated,
    status: campaign.status,
    is_deleted: campaign.isDeleted,
    is_archived: campaign.isArchived,
    abTestID: campaign.abTestID,
    abTestStart: campaign.abTestStart,
    admin_status: campaign.adminStatus,
    attribution_method: campaign.attributionMethod,
    is_skadnetwork_enabled: campaign.isSkadnetworkEnabled,
    schema_version: campaign.schemaVersion,
    bidding_id: campaign.biddingID,
    creative_type: campaign.creativeType,
    is_ab_testing: campaign.isAbTesting,
    sfId: campaign.sfID,
    has_pending_changes: campaign.hasPendingChanges,
    account: {},
    application: {},
    bidding: {},
    dates: {},
    serving_status: {},
    budget: {},
    creatives: (campaign.creatives || []).map(formatCreative),
    creatives_b: (campaign.creativesB || []).map(formatCreative),
    settings: {},
    targeting: {},
    tracking: {},
  };

  const { account } = campaign;
  if (account) {
    result.account = {
      id: account.id,
      name: account.name,
      creativeMaximum: account.creativeMaximum,
    };
  }

  const { application } = campaign;
  if (application) {
    result.application = convertApplicationToRestFormat(application);
  }

  const { dates } = campaign;
  if (dates) {
    result.dates = {
      start: dates.start,
      end: dates.end,
      is_indefinite: dates.isIndefinite,
    };
  }

  const { servingStatus } = campaign;
  if (servingStatus) {
    result.serving_status = {
      approved: servingStatus.approved,
      creatives: servingStatus.creatives,
      has_account_budget: servingStatus.hasAccountBudget,
      has_budget: servingStatus.hasBudget,
      io_signed: servingStatus.ioSigned,
      not_expired: servingStatus.notExpired,
      remainingSpendLimit: servingStatus.remainingViewLimit,
      remainingViewLimit: servingStatus.remainingSpendLimit,
    };
  }

  const { bidding } = campaign;
  if (bidding) {
    result.bidding = {
      app_names: bidding.appNames,
      app_rates: bidding.appRate,
      geo_rates: bidding.geoRate,
      app_geo_rates: bidding.appGeoRate,
    };
  }

  const { biddingDiff } = campaign;
  if (biddingDiff) {
    result.bidding_diff = {
      type: biddingDiff.type,
      bidding_id_after: biddingDiff.biddingIDAfter,
      bidding_id_before: biddingDiff.biddingIDBefore,
      added_entries: (biddingDiff.addedEntries || []).map(({ pubAppID, ...rest }) => ({ pub_app_id: pubAppID, ...rest })),
      removed_entries: (biddingDiff.removedEntries || []).map(({ pubAppID, ...rest }) => ({ pub_app_id: pubAppID, ...rest })),
    };
  }

  const { budget } = campaign;
  if (budget) {
    result.budget = {
      bid: budget.bid,
      type: budget.type,
      daily: budget.daily,
      spent: budget.spent,
      total: budget.total,
      pacing: budget.pacing,
      daily_spend_limit_type: budget.dailySpendLimitType,
      daily_spend_limit_geos: budget.dailySpendLimitGeos || [],
      auto_pacing: {},
      bid_override: {},
      budget_multiplier: {},
      daily_override: {},
      total_override: {},
      objective: {
        events: [],
      },
    };
    if (budget.autoPacing) {
      result.budget.auto_pacing.is_enabled = budget.autoPacing.isEnabled;
      result.budget.auto_pacing.overage = budget.autoPacing.overage;
    }
    if (budget.bidOverride) {
      result.budget.bid_override.is_enabled = budget.bidOverride.isEnabled;
      result.budget.bid_override.value = budget.bidOverride.value;
    }
    if (budget.budgetMultiplier) {
      result.budget.budget_multiplier.is_enabled = budget.budgetMultiplier.isEnabled;
      result.budget.budget_multiplier.override = budget.budgetMultiplier.override;
    }
    if (budget.dailyOverride) {
      result.budget.daily_override.is_enabled = budget.dailyOverride.isEnabled;
      result.budget.daily_override.value = budget.dailyOverride.value;
    }
    if (budget.totalOverride) {
      result.budget.total_override.is_enabled = budget.totalOverride.isEnabled;
      result.budget.total_override.value = budget.totalOverride.value;
    }
    if (isArray(budget.objectives) && budget.objectives.length > 0) {
      const [objective] = budget.objectives;
      result.budget.objective.type = objective.type;
      result.budget.objective.bid_default = objective.bidDefault;
      result.budget.objective.bid_max = objective.bidMax;
      result.budget.objective.bid_min = objective.bidMin;
      result.budget.objective.horizon = objective.horizon;
      result.budget.objective.target_return = objective.targetReturn;
      result.budget.objective.target_override = objective.targetOverride;
      result.budget.objective.targetSubsidizationMultiplier = objective.targetSubsidizationMultiplier;
      result.budget.objective.cpe_target_event_name = objective.cpeTargetEventName;
      result.budget.objective.events = objective.events || [];
      result.budget.objective.cpe_target_event_name = objective.cpeTargetEventName;
    }
  }

  const { targeting } = campaign;
  if (targeting) {
    result.targeting = {
      lat: targeting.lat,
      network: targeting.network,
      reward_type: targeting.rewardType,
      devices: targeting.devices,
      exchanges_offnetwork: targeting.exchangesOffnetwork || [],
      msrp: targeting.msrp,
      placement_type_whitelist: targeting.placementTypeWhitelist,
      storeKitOverlayAutoShow: targeting.storeKitOverlayAutoShow,
      account: {},
      bundles_offnetwork: {},
      device_models: {},
      carrier: {},
      genre: {},
      geo: {},
      placement: {},
      publisher: {},
      versions: {},
    };
    if (targeting.account) {
      result.targeting.account.type = targeting.account.type;
      result.targeting.account.accounts = targeting.account.accounts || [];
    }
    if (targeting.bundlesOffnetwork) {
      result.targeting.bundles_offnetwork.type = targeting.bundlesOffnetwork.type;
      result.targeting.bundles_offnetwork.applications = (targeting.bundlesOffnetwork.applications || [])
        .map(({ bundleID }) => ({ bundle_id: bundleID }));
    }
    if (targeting.deviceModels) {
      result.targeting.device_models.type = targeting.deviceModels.type;
      result.targeting.device_models.target_new_phone_models = targeting.deviceModels.targetNewPhoneModels;
      result.targeting.device_models.target_new_tablet_models = targeting.deviceModels.targetNewTabletModels;
      result.targeting.device_models.device_blacklist = targeting.deviceModels.deviceBlacklist || [];
      result.targeting.device_models.device_whitelist = targeting.deviceModels.deviceWhitelist || [];
    }
    if (targeting.carrier) {
      result.targeting.carrier.carrierAllowList = targeting.carrier.carrierAllowList.map((carrier) => (carrier === '' ? 'ø' : carrier)) || [];
      result.targeting.carrier.carrierDenyList = targeting.carrier.carrierDenyList.map((carrier) => (carrier === '' ? 'ø' : carrier)) || [];
      if (result.targeting.carrier.carrierAllowList.length > 0) {
        result.targeting.carrier.mode = 'allow';
      } else if (result.targeting.carrier.carrierDenyList.length > 0) {
        result.targeting.carrier.mode = 'deny';
      } else {
        result.targeting.carrier.mode = 'allow';
      }
    }
    if (targeting.genre) {
      result.targeting.genre.mode = targeting.genre.mode;
      result.targeting.genre.genreList = targeting.genre.genreList || [];
    }
    if (targeting.geo) {
      result.targeting.geo.region = targeting.geo.region;
      result.targeting.geo.cities = targeting.geo.cities || [];
      result.targeting.geo.countries = targeting.geo.countries || [];
      result.targeting.geo.subdivisions = targeting.geo.subdivisions || [];
    }
    if (targeting.placement) {
      result.targeting.placement.type = targeting.placement.type;
      result.targeting.placement.ids = targeting.placement.ids || [];
    }
    if (targeting.publisher) {
      result.targeting.publisher.type = targeting.publisher.type;
      result.targeting.publisher.applications = targeting.publisher.applications || [];
    }
    if (targeting.versions) {
      result.targeting.versions.phone_max = targeting.versions.phoneMax;
      result.targeting.versions.phone_min = targeting.versions.phoneMin;
      result.targeting.versions.tablet_max = targeting.versions.tabletMax;
      result.targeting.versions.tablet_min = targeting.versions.tabletMin;
      result.targeting.versions.application = {};
      result.targeting.versions.sdk = {};
      if (targeting.versions.application) {
        result.targeting.versions.application.is_enabled = targeting.versions.application.isEnabled;
        result.targeting.versions.application.min = targeting.versions.application.min;
        result.targeting.versions.application.max = targeting.versions.application.max;
      }
      if (targeting.versions.sdk) {
        result.targeting.versions.sdk.is_enabled = targeting.versions.sdk.isEnabled;
        result.targeting.versions.sdk.min = targeting.versions.sdk.min;
        result.targeting.versions.sdk.max = targeting.versions.sdk.max;
      }
    }
  }

  const { settings } = campaign;
  if (settings) {
    result.settings = {
      ad_type: settings.adType,
      creative_override: settings.creativeOverride,
      creative_timer_override: settings.creativeTimerOverride,
      carrier_targeting: settings.carrierTargeting,
      daily_view_limit: settings.dailyViewLimit,
      daily_view_limit_enabled: settings.dailyViewLimitEnabled,
      erpm_override: settings.erpmOverride,
      erpm_override_enabled: settings.erpmOverrideEnabled,
      free_form: settings.freeForm,
      rate_margin: settings.rateMargin,
      targeted_environment: settings.targetedEnvironment,
      total_view_limit: settings.totalViewLimit,
      total_view_limit_enabled: settings.totalViewLimitEnabled,
      win_notice_source: settings.winNoticeSource,
      skoverlay_auto_show: settings.storeKitOverlayAutoShow,
      filter_logging_enabled_at: settings.filterLoggingEnabledAt,
      exchange: {},
    };
    if (settings.exchange) {
      result.settings.exchange.delivery_type = settings.exchange.deliveryType;
      result.settings.exchange.exchange_url = settings.exchange.exchangeUrl;
      result.settings.exchange.expiry = settings.exchange.expiry;
      result.settings.exchange.max_duration = settings.exchange.maxDuration;
      result.settings.exchange.min_bitrate = settings.exchange.minBitrate;
      result.settings.exchange.protocol = settings.exchange.protocol;
    }
  }

  const { tracking } = campaign;
  if (tracking) {
    result.tracking = {
      click: tracking.click,
      destination: tracking.destination,
      events_url: tracking.eventsURL,
      moat: tracking.moat,
      refresh_destination: tracking.refreshDestination,
      url: tracking.url,
      events: (tracking.events || []).map((e) => ({ ...e, name: getTrackingEventViewName(e.name) })),
    };
  }

  return result;
}

export function handleCampaignGraphQLResponse({
  data: {
    campaign,
  } = {},
}) {
  return {
    ok: true,
    status: 200,
    response: convertToRestFormat(campaign),
  };
}

export function handleCampaignsGraphQLResponse({
  data: {
    campaigns,
  } = {},
}) {
  return {
    ok: true,
    status: 200,
    response: campaigns.campaigns.map(convertToRestFormat),
    pagination: campaigns.pagination,
  };
}

export function getCampaign(id, original = false) {
  return getClient()
    .query({
      query: GetCampaign,
      variables: { id, original },
    })
    .then(handleCampaignGraphQLResponse)
    .catch(handleGraphQLError);
}

export function getCampaigns(filters = {}) {
  return getClient()
    .query({
      query: GetCampaigns,
      variables: getCampaignQueryVariables(filters),
    })
    .then(handleCampaignsGraphQLResponse)
    .catch(handleGraphQLError);
}

export function getCampaignsForCreativeAttachment(filters = {}) {
  return getClient()
    .query({
      query: GetCampaignsForCreativeAttachment,
      variables: getCampaignQueryVariables(filters),
    })
    .then(handleCampaignsGraphQLResponse)
    .catch(handleGraphQLError);
}

function handleBiddingDiffGraphQLResponse({
  data: {
    biddingDiff,
  } = {},
}) {
  return {
    ok: true,
    status: 200,
    response: {
      addedEntries: (biddingDiff.addedEntries || []).map(({ pubAppID, ...rest }) => ({ pub_app_id: pubAppID, ...rest })),
      removedEntries: (biddingDiff.removedEntries || []).map(({ pubAppID, ...rest }) => ({ pub_app_id: pubAppID, ...rest })),
    },
  };
}

export function getLargeBiddingDiff(campaignID, biddingIdAfter, biddingIdBefore) {
  return getClient()
    .query({
      query: GetLargeBiddingDiff,
      variables: {
        campaignID,
        biddingIdAfter,
        biddingIdBefore,
      },
    })
    .then(handleBiddingDiffGraphQLResponse)
    .catch(handleGraphQLError);
}

export function handleCampaignActionGraphQLResponse() {
  return {
    ok: true,
    status: 200,
  };
}

function handleCampaignFromSalesforceGraphQLResponse({
  data: {
    campaignFromSalesforce: salesforceCampaign,
  },
}) {
  const {
    deviceType: {
      phone: Phone,
      tablet: Tablet,
    },
    countryWhitelist,
    appBlacklist,
  } = salesforceCampaign;
  // very unfortunate legacy, but true
  const finalCountryAllowList = size(countryWhitelist) === 0
    ? ' '
    : countryWhitelist;
  const finalAppDenyList = size(appBlacklist) === 0
    ? ''
    : appBlacklist;

  return {
    response: {
      ...salesforceCampaign,
      deviceType: {
        Phone,
        Tablet,
      },
      countryWhitelist: finalCountryAllowList,
      appBlacklist: finalAppDenyList,
    },
  };
}

function handleCampaignsFromSalesforceGraphQLResponse({
  data: {
    campaignsFromSalesforce: response,
  },
}) {
  return {
    response,
  };
}

export function changeCampaignStatus(id, status) {
  return getClient()
    .mutate({
      mutation: status === 'pause' ? PauseCampaign : ActivateCampaign,
      variables: {
        id,
      },
    })
    .then(handleCampaignActionGraphQLResponse)
    .catch(handleGraphQLError);
}

export function deleteCampaign(id) {
  return getClient()
    .mutate({
      mutation: DeleteCampaign,
      variables: {
        id,
      },
    })
    .then(handleCampaignActionGraphQLResponse)
    .catch(handleGraphQLError);
}

export function restoreCampaign(id) {
  return getClient()
    .mutate({
      mutation: RestoreCampaign,
      variables: {
        id,
      },
    })
    .then(handleCampaignActionGraphQLResponse)
    .catch(handleGraphQLError);
}

export function toGraphQLRunWinnerSet(campaign) {
  const {
    is_ab_testing,
    creatives,
    creatives_b,
  } = campaign;

  const payload = {
    isAbTesting: is_ab_testing,
    creatives,
    creativesB: creatives_b,
  };

  return payload;
}

export function toGraphQLCampaignRequest(campaign, isCreate) {
  const {
    name,
    status,
    attribution_method,
    is_skadnetwork_enabled,
    schema_version,
    creative_type,
    is_ab_testing,
    sfId,
    creatives,
    creatives_b,
    account,
    application,
    dates,
    bidding,
    budget,
    settings,
    targeting,
    tracking,
  } = campaign;

  const payload = {
    name,
    status,
    attributionMethod: attribution_method,
    isSkadnetworkEnabled: is_skadnetwork_enabled,
    schemaVersion: schema_version,
    creativeType: creative_type,
    isAbTesting: is_ab_testing,
    sfID: sfId,
    creatives,
    creativesB: creatives_b,
    dates: {
      start: dates.start,
      end: dates.end,
      isIndefinite: dates.is_indefinite,
    },
    tracking: {
      url: tracking.url,
      eventsURL: tracking.events_url,
      destination: tracking.destination,
      click: tracking.click,
      refreshDestination: tracking.refresh_destination,
      moat: tracking.moat,
      events: (tracking.events || [])
        .filter((e) => e.name !== clickEvent.name)
        .map((e) => ({ url: e.url, name: getTrackingEventApiName(e.name) })),
    },
  };

  // bidding
  const biddingPayload = {
    replace: bidding.replace,
  };
  if (isArray(bidding.rates)) {
    biddingPayload.rates = bidding.rates.map(({ rate, geo, pub_app_id: pubAppID }) => {
      const bid = { rate };
      if (geo && geo !== '*') {
        bid.geo = geo;
      }
      if (pubAppID && pubAppID !== '*') {
        bid.pubAppID = pubAppID;
      }
      return bid;
    });
  }
  payload.bidding = biddingPayload;

  // budget
  const budgetPayload = {
    bid: budget.bid,
    type: budget.type,
    daily: budget.daily,
    total: budget.total,
    dailySpendLimitType: budget.daily_spend_limit_type,
    dailySpendLimitGeos: budget.daily_spend_limit_geos,
    pacing: budget.pacing,
  };
  if (budget.auto_pacing) {
    budgetPayload.autoPacing = {
      isEnabled: budget.auto_pacing.is_enabled,
      overage: budget.auto_pacing.overage,
    };
  }
  if (canModifyCampaignBudgetMultiplier() && budget.budget_multiplier) {
    budgetPayload.budgetMultiplier = {
      isEnabled: budget.budget_multiplier.is_enabled,
      override: budget.budget_multiplier.override,
    };
  }
  if (canModifyCampaignCvrMultiplier() && budget.bid_override) {
    budgetPayload.bidOverride = {
      isEnabled: budget.bid_override.is_enabled || false,
      value: budget.bid_override.value,
    };
  }
  if (budget.total_override) {
    budgetPayload.totalOverride = {
      isEnabled: budget.total_override.is_enabled || false,
      value: budget.total_override.value,
    };
  }
  if (budget.daily_override) {
    budgetPayload.dailyOverride = {
      isEnabled: budget.daily_override.is_enabled || false,
      value: budget.daily_override.value,
    };
  }
  if (budget.objective) {
    budgetPayload.objective = {
      type: budget.objective.type,
      bidMin: budget.objective.bid_min,
      bidMax: budget.objective.bid_max,
      targetReturn: budget.objective.target_return,
      targetOverride: budget.objective.target_override,
      targetSubsidizationMultiplier: budget.objective.targetSubsidizationMultiplier,
      cpeTargetEventName: budget.objective.cpe_target_event_name,
      horizon: budget.objective.horizon,
      events: budget.objective.events,
    };
    if (budget.objective.bid_default) {
      budgetPayload.objective.bidDefault = budget.objective.bid_default;
    }
  }
  payload.budget = budgetPayload;

  // settings
  if (settings) { // SSUI can't edit campaign.settings
    const settingsPayload = {
      adType: settings.ad_type,
      dailyViewLimit: toInteger(settings.daily_view_limit),
      dailyViewLimitEnabled: settings.daily_view_limit_enabled,
      totalViewLimit: toInteger(settings.total_view_limit),
      totalViewLimitEnabled: settings.total_view_limit_enabled,
      erpmOverride: settings.erpm_override,
      erpmOverrideEnabled: settings.erpm_override_enabled,
      rateMargin: settings.rate_margin,
      creativeOverride: settings.creative_override,
      creativeTimerOverride: settings.creative_timer_override,
      carrierTargeting: settings.carrier_targeting,
      winNoticeSource: settings.win_notice_source,
      targetedEnvironment: settings.targeted_environment,
      freeForm: settings.free_form,
      storeKitOverlayAutoShow: settings.skoverlay_auto_show,
      filterLoggingEnabledAt: settings.filter_logging_enabled_at,
    };
    if (settings.exchange) {
      settingsPayload.exchange = {
        protocol: settings.exchange.protocol || exchangeProtocol.vast,
        deliveryType: settings.exchange.delivery_type,
        exchangeUrl: settings.exchange.exchange_url,
        expiry: settings.exchange.expiry,
        maxDuration: settings.exchange.max_duration,
        minBitrate: settings.exchange.min_bitrate,
      };
    }
    payload.settings = settingsPayload;
  }

  // targeting
  const targetingPayload = {
    network: targeting.network,
    rewardType: targeting.reward_type,
    lat: targeting.lat,
    exchangesOffnetwork: targeting.exchanges_offnetwork,
    devices: targeting.devices,
    placementTypeWhitelist: targeting.placement_type_whitelist,
    account: targeting.account,
    publisher: targeting.publisher,
    placement: targeting.placement,
  };
  // type enum in graphql can't contain `-`
  if (targetingPayload.rewardType === 'non-rewarded') {
    targetingPayload.rewardType = 'nonRewarded';
  }
  if (targeting.msrp) {
    targetingPayload.msrp = {
      ...targeting.msrp,
      enabled: targeting.msrp.enabled || false,
    };
  }
  if (targeting.geo) {
    targetingPayload.geo = {
      region: targeting.geo.region,
      countries: map(targeting.geo.countries, ({ code }) => ({ code })),
      subdivisions: map(targeting.geo.subdivisions, ({ code }) => ({ code })),
      cities: map(targeting.geo.cities, (c) => ({ name: c.name, region: c.region, country: c.country })),
    };
  }
  if (targeting.versions) {
    targetingPayload.versions = {
      phoneMax: targeting.versions.phone_max,
      phoneMin: targeting.versions.phone_min,
      tabletMax: targeting.versions.tablet_max,
      tabletMin: targeting.versions.tablet_min,
    };
    if (targeting.versions.application) {
      targetingPayload.versions.application = {
        isEnabled: targeting.versions.application.is_enabled || false,
        min: targeting.versions.application.min,
        max: targeting.versions.application.max,
      };
    }
    if (targeting.versions.sdk) {
      targetingPayload.versions.sdk = {
        isEnabled: targeting.versions.sdk.is_enabled || false,
        min: targeting.versions.sdk.min,
        max: targeting.versions.sdk.max,
      };
    }
  }
  if (targeting.bundles_offnetwork) {
    targetingPayload.bundlesOffnetwork = {
      type: targeting.bundles_offnetwork.type,
      applications: (targeting.bundles_offnetwork.applications || []).map(({ bundle_id }) => ({ bundleID: bundle_id })),
    };
  }
  if (targeting.device_models) {
    targetingPayload.deviceModels = {
      type: targeting.device_models.type,
      targetNewPhoneModels: targeting.device_models.target_new_phone_models,
      targetNewTabletModels: targeting.device_models.target_new_tablet_models,
      deviceBlacklist: (targeting.device_models.device_blacklist || []).map((d) => ({ id: d.id, name: d.name, model: d.model })),
      deviceWhitelist: (targeting.device_models.device_whitelist || []).map((d) => ({ id: d.id, name: d.name, model: d.model })),
    };
  }
  if (targeting.carrier) {
    targetingPayload.carrier = {
      carrierAllowList: targeting.carrier.carrierAllowList.map((carrier) => (carrier === 'ø' ? '' : carrier)) || [],
      carrierDenyList: targeting.carrier.carrierDenyList.map((carrier) => (carrier === 'ø' ? '' : carrier)) || [],
    };
  }
  if (targeting.genre) {
    targetingPayload.genre = {
      mode: targeting.genre.mode,
      genreList: (targeting.genre.genreList || []).map(({ id }) => id),
    };
  }
  payload.targeting = targetingPayload;

  if (isCreate) {
    payload.account = { id: account.id };
    payload.application = { id: application.id };
  }
  return payload;
}

export function handleCreateCampaignGraphQLResponse({
  data: {
    createCampaign: createCampaignResponse,
  } = {},
}) {
  return {
    ok: true,
    status: 200,
    response: convertToRestFormat(createCampaignResponse),
  };
}

export function createCampaign(campaignServerObject) {
  return getClient()
    .mutate({
      mutation: CreateCampaign,
      variables: {
        input: {
          ...toGraphQLCampaignRequest(campaignServerObject, true),
          client: getServiceType(),
        },
      },
    })
    .then(handleCreateCampaignGraphQLResponse)
    .catch(handleGraphQLError);
}

export function handleModifyCampaignGraphQLResponse({
  data: {
    modifyCampaign: modifyCampaignResponse,
  } = {},
}) {
  return {
    ok: true,
    status: 200,
    response: convertToRestFormat(modifyCampaignResponse),
  };
}

export function updateCampaignAfterAbTesting(id, fields) {
  return getClient()
    .mutate({
      mutation: ModifyCampaign,
      variables: {
        id,
        input: {
          ...toGraphQLRunWinnerSet(fields),
          client: getServiceType(),
        },
      },
    })
    .then(handleModifyCampaignGraphQLResponse)
    .catch(handleGraphQLError);
}

export function updateCreativesForCampaign(campaign) {
  const id = campaign.get('id');
  const creatives = campaign.get('creatives')?.map((c) => ({ id: c.get('id') }));

  return getClient()
    .mutate({
      mutation: ModifyCampaign,
      variables: {
        id,
        input: {
          creatives,
          client: getServiceType(),
        },
      },
    })
    .then(handleModifyCampaignGraphQLResponse)
    .catch(handleGraphQLError);
}

export function modifyCampaign(id, campaignServerObject) {
  return getClient()
    .mutate({
      mutation: ModifyCampaign,
      variables: {
        id,
        input: {
          ...toGraphQLCampaignRequest(campaignServerObject),
          client: getServiceType(),
        },
      },
    })
    .then(handleModifyCampaignGraphQLResponse)
    .catch(handleGraphQLError);
}

export function campaignFromSalesforce(id) {
  return getClient()
    .query({
      query: CampaignFromSalesforce,
      variables: {
        id,
      },
    })
    .then(handleCampaignFromSalesforceGraphQLResponse)
    .catch(handleGraphQLError);
}

export function campaignsFromSalesforce() {
  return getClient()
    .query({
      query: CampaignsFromSalesforce,
    })
    .then(handleCampaignsFromSalesforceGraphQLResponse)
    .catch(handleGraphQLError);
}

export function getDynamicGeos(filters) {
  return getClient()
    .query({
      query: GetDynamicGeos,
      variables: filters,
    })
    .then((res) => res.data.dynamicGeos)
    .catch(handleGraphQLError);
}
