import React from 'react';
import classnames from 'classnames';
import { firstLetterUpper } from '../../../lib/lib';
import LiftoffRocket from '../../../assets/img/liftoff-rocket.svg';
import Button from '../Button/Button';
import Link from '../../Link/Link';
import { canModifyCampaigns, canModifyApps } from '../../../lib/helpers/authUser';
import './emptyState.scss';

const classPrefix = 'v2_component_empty_state';

const EmptyState = ({
  className,
  type,
  title = 'Welcome to Liftoff',
  description,
  children,
}) => (
  <div className={classnames(classPrefix, className)}>
    <img src={LiftoffRocket} />
    <span className="h4">{title}</span>
    <span className="p2">
      {description || (
      <span>
        This is your homepage dashboard, and the main hub where your future
        {` ${firstLetterUpper(type || '')}s `}
        will be listed.
        <br />
        Start by adding your first
        {` ${type} `}
        below.
      </span>
      )}
    </span>
    <span className="buttons_wrapper">
      {children}
    </span>
  </div>
);

function showBtn(type) {
  if (type === 'application') {
    return canModifyApps();
  }
  if (type === 'campaign') {
    return canModifyCampaigns();
  }
  return true;
}

export const ListEmptyState = ({ type, to, onClick }) => (
  <div className="v2_component_list_empty_state">
    <EmptyState type={type}>
      {(to || onClick) && showBtn(type) && (
        <Link to={to}>
          <Button type="primary" onClick={onClick}>
            {`Add ${firstLetterUpper(type || '')}`}
          </Button>
        </Link>
      )}
    </EmptyState>
  </div>
);

export const SearchEmptyState = () => (
  <div className="v2_component_search_empty_state">
    <EmptyState
      title="No Results Found"
      description={(
        <span>
          Hmm no results found based on your search.
          <br />
          Please edit your search criteria and try again.
        </span>
            )}
    />
  </div>
);

export default EmptyState;
