import React, { useEffect, useMemo, useState } from 'react';
import { FORMAT_TYPE_FULLSCREEN } from 'app/constants/campaign';
import {
  getCreativesByCreativeType,
  isCampaignObjectiveDynamic,
} from 'lib/capabilities/campaigns';
import { CollapseSection } from '../../../../components/V2';
import Toggler from '../../../../components/Form/Toggler/Toggler';
import triggerConfirm from '../../../../components/Modals/ConfirmAction/triggerConfirm';
import Group from '../../../../templates/input-group/InputGroupContainer';
import AttachedCreativesWithTesting from './AttachedCreativesWithTesting';
import AttachedCreativesWithoutTesting from './AttachedCreativesWithoutTesting';
import {
  updateCreative,
} from './helper';
import { isAdvertiserService } from '../../../../lib/serviceType';

const { Field } = Group;

const getSelectedIds = (campaign, creatives, isSetB = false) => creatives
  ?.filter((creative) => campaign.hasActiveCreative(creative, isSetB))
  .map((creative) => creative.get('id'));

const Creatives = ({
  campaign,
  creatives = [],
  creativeIds = [],
  updateSkcid,
}) => {
  const creativeType = campaign.get('creative_type');
  const schemaVersion = campaign.get('schema_version');
  const attributionMethod = campaign.get('attribution_method');
  const isAdvertiser = isAdvertiserService();
  const isAbTesting = campaign.get('is_ab_testing');
  const supportsSKANABTesting = campaign.supportsSKANABTesting();
  const [selectedCreativeIds, setSelectedCreativeIds] = useState([]);
  const [selectedCreativeBIds, setSelectedCreativeBIds] = useState([]);
  const [availableCreatives, setAvailableCreatives] = useState([]);

  const togglerDisabled = useMemo(() => {
    if (!isAbTesting) {
      const creativeCount = getCreativesByCreativeType(campaign, creativeType || FORMAT_TYPE_FULLSCREEN, creatives)?.length || 0;
      if (creativeType === 'native' && creativeCount < 1) {
        return true;
      }

      if (creativeType !== 'native' && creativeCount < 2) {
        return true;
      }
    }

    if (isAdvertiser) {
      if (supportsSKANABTesting) {
        return false;
      }
      if (!isAbTesting) {
        return true;
      }
      if (campaign.get('ab_test.status') !== 'complete') {
        return true;
      }
    }

    return false;
  }, [isAbTesting, isAdvertiser, creativeType, creatives, supportsSKANABTesting, campaign]);

  const isShowAbTestToggler = useMemo(() => {
    if (isAbTesting) {
      return true;
    }
    if (!isAdvertiser) {
      return true;
    }
    return supportsSKANABTesting;
  }, [isAbTesting, isAdvertiser, supportsSKANABTesting]);

  useEffect(() => {
    setSelectedCreativeIds(getSelectedIds(campaign, creatives));
    setSelectedCreativeBIds(getSelectedIds(campaign, creatives, true));
  }, [campaign, creatives, schemaVersion]);

  useEffect(() => {
    const shouldFilterCreatives = isAbTesting || supportsSKANABTesting || isCampaignObjectiveDynamic(campaign);
    setAvailableCreatives(
      shouldFilterCreatives
        ? getCreativesByCreativeType(campaign, creativeType, creatives)
        : creatives,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    creatives,
    creativeType,
    schemaVersion,
    attributionMethod,
    isAbTesting,
    supportsSKANABTesting,
  ]);

  useEffect(() => {
    if (!supportsSKANABTesting) {
      return;
    }
    updateCreative({
      campaign,
      creatives,
      updateSkcid,
      newSelectedIds: [],
      selectedCreativeIds,
      setSelectedCreativeIds,
    });
    updateCreative({
      campaign,
      creatives,
      updateSkcid,
      newSelectedIds: [],
      selectedCreativeIds: selectedCreativeBIds,
      setSelectedCreativeIds: setSelectedCreativeBIds,
      isSetB: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creativeType]);

  return (
    <CollapseSection
      className="views__campaigns__edit_collapse"
      title={`Attached Creatives (${([...selectedCreativeIds, ...selectedCreativeBIds]).length})`}
      id="creatives"
      description="Select creatives to attach to this Campaign."
    >
      {
        supportsSKANABTesting || isAbTesting
          ? (
            <AttachedCreativesWithTesting
              canEdit
              campaign={campaign}
              creatives={availableCreatives}
              creativeIds={creativeIds}
              updateSkcid={updateSkcid}
              selectedCreativeIds={selectedCreativeIds}
              setSelectedCreativeIds={setSelectedCreativeIds}
              selectedCreativeBIds={selectedCreativeBIds}
              setSelectedCreativeBIds={setSelectedCreativeBIds}
              allCreatives={creatives}
            />
          )
          : (
            <AttachedCreativesWithoutTesting
              canEdit
              campaign={campaign}
              creatives={availableCreatives}
              creativeIds={creativeIds}
              updateSkcid={updateSkcid}
              selectedCreativeIds={selectedCreativeIds}
              setSelectedCreativeIds={setSelectedCreativeIds}
              isMissionControl
            />
          )
      }
      {
        isShowAbTestToggler && (
          <Field name="A/B Test" description="Enable to A/B test this campaign." adminOnly={!isAdvertiser}>
            <Toggler
              disabled={togglerDisabled}
              checked={isAbTesting}
              onChange={(checked) => {
                // According to https://vungle.atlassian.net/browse/DB-8069.
                if (!campaign.get('creative_type')) {
                  campaign.set('creative_type', 'fullscreen');
                }
                if ((!selectedCreativeIds.length && checked) || (!selectedCreativeBIds.length && !checked)) {
                  campaign.set('is_ab_testing', checked);
                } else {
                  triggerConfirm({
                    type: checked ? 'ENABLE_AB_TEST_CONFIRM_ACTION' : 'DISABLE_AB_TEST_CONFIRM_ACTION',
                    header: `${checked ? 'Enable' : 'Disable'} A/B Test`,
                    message: checked
                      ? 'Are you sure you want to enable A/B Test? This will detach all current creatives.'
                      : 'Are you sure you want to disable A/B Test? This will remove Test Set B creatives.',
                    onConfirm: () => {
                      campaign.set('is_ab_testing', checked);
                      if (checked) {
                        updateCreative({
                          campaign,
                          creatives,
                          updateSkcid,
                          newSelectedIds: [],
                          selectedCreativeIds,
                          setSelectedCreativeIds,
                        });
                      } else {
                        updateCreative({
                          campaign,
                          creatives,
                          updateSkcid,
                          newSelectedIds: [],
                          selectedCreativeIds: selectedCreativeBIds,
                          setSelectedCreativeIds: setSelectedCreativeBIds,
                          isSetB: true,
                        });
                      }
                    },
                  });
                }
              }}
            />
          </Field>
        )
      }
    </CollapseSection>
  );
};

export default Creatives;
