import React, {
  useEffect,
  useState,
} from 'react';
import classnames from 'classnames';
import {
  Copy, Pulsation, RoundPlatformIcon, Tabs, Tooltip, DropdownMenu,
} from '../../../../../../../components/V2';
import { REPORTING_INCOMPLETE_BODY, REPORTS_MORE_OPTS } from '../../../../../../Templates/Reports/constant';
import { TAB_CAMPAIGNS, TAB_CREATIVES } from '../../constants';
import { isCampaignDimension } from '../../helper';
import './topBar.scss';
import { canModifyCampaigns, canModifyCreatives } from '../../../../../../../lib/helpers/authUser';

function initTabs(dimension) {
  return [TAB_CAMPAIGNS, TAB_CREATIVES].map((e) => ({
    ...e,
    selected: e.key === dimension,
  }));
}

const TopBar = ({
  node,
  dimension,
  onBack,
  onAdd,
  onTabClick,
  onDownloadCSV,
  onViewReport,
  onDropdownOptions,
}) => {
  const [optionOpen, setOptionOpen] = useState(false);
  const [tabs, setTabs] = useState(initTabs(dimension));

  const onTabsClick = ({ key }) => {
    setTabs(tabs.map((item) => ({
      ...item,
      selected: item.key === key,
    })));
    onTabClick(key);
  };

  useEffect(() => setTabs(initTabs(dimension)), [dimension]);

  const shouldShowBtn = isCampaignDimension(dimension) ? canModifyCampaigns() : canModifyCreatives();

  return (
    <div className="top_bar_container">
      <div className="info_container">
        <div className="thumbnail">
          <div className="back" onClick={onBack}>
            <i className="material-icons">arrow_back</i>
          </div>
          <span className="separator" />
          <img src={node.get('store.thumbnail')} />
          <RoundPlatformIcon platform={node.get('platform')} />
          <Pulsation status="active" />
        </div>
        <div className="info">
          <span className="p2">{node.get('name')}</span>
          <Copy value={node.get('id')} />
        </div>
      </div>
      <div className="actions_container">
        <div className="options_container">
          <div className="button" onClick={onDownloadCSV}>Download CSV</div>
          <div className="button" onClick={onViewReport}>View Report</div>
          { shouldShowBtn && (<div className="button primary" onClick={onAdd}>{isCampaignDimension(dimension) ? 'Add Campaign' : 'Add Creative'}</div>)}
          <Tooltip
            title="Reporting Information"
            body={REPORTING_INCOMPLETE_BODY}
            className="v2_reporting_page_tooltip"
          >
            <i className="material-icons">help_outline</i>
          </Tooltip>
          <DropdownMenu
            fixed
            labelKey="name"
            horizontalOffset={-176}
            data={REPORTS_MORE_OPTS}
            onChange={onDropdownOptions}
            onVisibleChange={setOptionOpen}
          >
            <i className={classnames({ 'material-icons': true, optionOpen })}>more_vert</i>
          </DropdownMenu>
        </div>
        <Tabs columns={tabs} onClick={onTabsClick} />
      </div>
    </div>
  );
};

export default TopBar;
