import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  memo,
} from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { split, isHexId } from 'lib/lib';
import EditAreaComponent from 'components/V2/EditArea/EditArea';
import { PLATFORM_KEYS, PUB_TEXTAREA_LOADING, PUB_TEXTAREA_ERROR_MAX } from '../constants';
import './editArea.scss';

function getErrorKey(platform) {
  return PLATFORM_KEYS[platform] || 'publiserTargetingIdError';
}

function EditArea({
  onSave,
  onError,
  loading,
  value,
  platform,
  onChange,
  placeholder,
}) {
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [invalidIdList, setInvalidIdList] = useState([]);
  const publisherIds = useRef();

  useEffect(() => {
    const inputChangeCallback = ({
      message = '',
      enable = false,
      ids = [],
      hasError = true,
      errorIdList = [],
    }) => {
      setError(message);
      setDisabled(!enable);
      publisherIds.current = ids;
      onError(hasError, error);
      setInvalidIdList(errorIdList);
    };

    const idList = split(value).filter((id) => typeof id === 'string' && id.length > 0);
    if (idList.length === 0) {
      inputChangeCallback({ hasError: false });
      return;
    }
    if (idList.length > 500) {
      inputChangeCallback({ message: PUB_TEXTAREA_ERROR_MAX });
      return;
    }
    const invalidHexIds = idList.filter((id) => !isHexId(id));
    if (invalidHexIds.length > 0) {
      inputChangeCallback({ message: `Invalid ids: ${invalidHexIds}`, errorIdList: invalidHexIds });
      return;
    }
    inputChangeCallback({ ids: idList, enable: true, hasError: false });
    // --> potential bug inside this useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, error]);

  const handleSave = useCallback(() => {
    onSave(publisherIds.current);
  }, [onSave]);

  return (
    <EditAreaComponent
      className="v2_component_publisher_targeting_edit"
      value={value}
      error={invalidIdList.length > 0 ? { [getErrorKey(platform)]: invalidIdList } : error}
      errorCollapse={invalidIdList.length > 0}
      loading={loading}
      loadingText={PUB_TEXTAREA_LOADING}
      disabled={disabled}
      placeholder={placeholder}
      onChange={onChange}
      onSave={handleSave}
    />
  );
}

EditArea.propTypes = {
  value: PropTypes.string.isRequired,
  platform: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  onError: PropTypes.func,
  loading: PropTypes.bool,
};

EditArea.defaultProps = {
  onSave: noop,
  onError: noop,
  platform: null,
  loading: false,
};

export default memo(EditArea);
