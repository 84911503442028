import React, {
  useEffect, useMemo, useState, useCallback,
} from 'react';
import classNames from 'classnames';
import Input from 'components/V2/Input/Input';
import InfoBar from 'components/InfoBar/InfoBar';
import Group from 'templates/input-group/InputGroupContainer';
import { numberFormat } from 'components/V2/Metrics/Metrics';
import { getReportsStatus } from 'services/Templates/AbTesting/helper';
import {
  updateCreative,
  getFormatInfosByCreativeType,
  formatCreativesA,
  formatCreativesB,
  getAvailableCreativeBIds,
  getCreativeVideoFormat,
} from './helper';
import AttachedCreativesList from './AttachedCreativesList';
import Radio from '../../../../components/V2/Radio/Radio';
import { InfoBarInProgress } from '../../AbTesting/InfoBars/InfoBars';
import './attachedCreativesTestingWrapper.scss';
import {
  STATUS_KEY_IN_PROGRESS_WITHOUT_ENOUGH_CONFIDENCE,
  STATUS_KEY_IN_PROGRESS_WITHOUT_ENOUGH_IMPRESSIONS,
} from '../../AbTesting/constants';
import ProgressBar from '../../AbTesting/Components/ProgressBar/ProgressBar';

const prefix = 'templates_campaigns_AttachedABCreatives';
const { Field } = Group;

const renderNotes = (campaign) => {
  if (!campaign.completesSKANABTestingSetB()) {
    return (
      <InfoBar
        type="warning"
        text="All available creative formats in Set B must be selected in order to run an A/B test."
      />
    );
  }
  if (!campaign.isValidSKANABTestingSetB()) {
    return (
      <InfoBar
        type="error"
        text="Set B is identical to Set A. You must replace at least one creative in order to run an A/B test."
      />
    );
  }
  return null;
};

const renderInprogressBanner = (isAbTesting, abSets) => {
  const status = getReportsStatus(abSets);
  const shouldRender = [
    STATUS_KEY_IN_PROGRESS_WITHOUT_ENOUGH_IMPRESSIONS,
    STATUS_KEY_IN_PROGRESS_WITHOUT_ENOUGH_CONFIDENCE,
  ].includes(status);
  return isAbTesting && shouldRender ? <InfoBarInProgress /> : <></>;
};

const AttachedCreativesTestingWrapper = ({
  campaign,
  creatives = [],
  updateSkcid,
  selectedCreativeIds,
  setSelectedCreativeIds,
  selectedCreativeBIds,
  setSelectedCreativeBIds,
  isAbTesting,
  allCreatives,
}) => {
  const creativeType = campaign.get('creative_type');
  const [selectedASetCreatives, setSelectedASetCreatives] = useState(selectedCreativeIds);
  const [selectedBSetCreatives, setSelectedBSetCreatives] = useState(selectedCreativeBIds);
  const abTestData = campaign.get('ab_test_data');

  const getSkanId = (setA) => {
    const app = campaign.get('application');
    const attachments = app.get('skAdNetworkMappings') || [];
    const primaryModel = setA ? 0 : 1;
    const primaryAttachment = attachments.find((a) => a.model_id === primaryModel && a.campaign?.id === campaign.get('id'));
    if (!primaryAttachment) {
      return 'Automatically Assigned';
    }
    return primaryAttachment.sk_campaign_id;
  };

  const formatInfos = useMemo(
    () => getFormatInfosByCreativeType({
      creativeType,
      creatives,
      selectedCreativeIds,
    }),
    [creativeType, creatives, selectedCreativeIds],
  );

  const formatBInfos = useMemo(
    () => getFormatInfosByCreativeType({
      creativeType,
      creatives,
      selectedCreativeIds: selectedCreativeBIds,
      isSetB: true,
      enabledCreativeIds: selectedCreativeIds,
    }),
    [creativeType, creatives, selectedCreativeIds, selectedCreativeBIds],
  );

  const selectBCreative = useCallback((newSelectedIds) => {
    updateCreative({
      campaign,
      creatives,
      updateSkcid,
      newSelectedIds,
      selectedCreativeIds: selectedCreativeBIds,
      setSelectedCreativeIds: setSelectedCreativeBIds,
      isSetB: true,
    });
  }, [campaign, creatives, selectedCreativeBIds, setSelectedCreativeBIds, updateSkcid]);

  const resetSliderData = useCallback(() => {
    setSelectedASetCreatives(selectedCreativeIds);
    setSelectedBSetCreatives(selectedCreativeBIds);
  }, [selectedCreativeIds, selectedCreativeBIds]);

  useEffect(
    () => formatCreativesA({
      creatives,
      selectedCreativeIds,
    }),
    [creatives, selectedCreativeIds],
  );

  useEffect(
    () => formatCreativesB({
      creatives,
      selectedCreativeIds,
      selectedCreativeBIds,
    }),
    [creatives, selectedCreativeIds, selectedCreativeBIds],
  );

  // when remove creative in A should remove the same type creative in B
  useEffect(() => {
    const ids = getAvailableCreativeBIds({
      creatives,
      selectedCreativeIds,
      selectedCreativeBIds,
    });
    selectBCreative(ids);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCreativeIds]);

  useEffect(() => {
    resetSliderData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCreativeIds, selectedCreativeBIds]);

  const selectCreative = (newSelectedIds) => {
    updateCreative({
      campaign,
      creatives,
      updateSkcid,
      newSelectedIds,
      selectedCreativeIds,
      setSelectedCreativeIds,
    });
  };

  const onConfirm = (selectedIds, isBSet) => {
    if (isBSet) {
      selectBCreative(selectedBSetCreatives);
    } else {
      selectCreative(selectedASetCreatives);
    }
  };

  const RadioSelector = ({ row: creative, isBSet }) => {
    const creativeId = creative.get('id');
    const checked = isBSet
      ? selectedBSetCreatives.some((c) => c === creativeId)
      : selectedASetCreatives.some((c) => c === creativeId);

    const onChange = () => {
      if (checked) return;

      if (isBSet) {
        const selectedCreatives = creatives.filter(
          (c) => selectedCreativeBIds.includes(c.get('id'))
            && getCreativeVideoFormat(c) !== getCreativeVideoFormat(creative),
        ).map((c) => c.get('id'));
        setSelectedBSetCreatives([...selectedCreatives, creative.get('id')]);
      } else {
        const selectedCreatives = creatives.filter(
          (c) => selectedCreativeIds.includes(c.get('id'))
            && getCreativeVideoFormat(c) !== getCreativeVideoFormat(creative),
        ).map((c) => c.get('id'));

        setSelectedASetCreatives([...selectedCreatives, creative.get('id')]);
      }
    };

    return (
      <Radio
        checked={checked}
        onChange={onChange}
      />
    );
  };

  return (
    <div className={prefix}>
      { renderInprogressBanner(isAbTesting, abTestData?.abSets) }
      {
        isAbTesting && (
          <div className="ab-testing-wrapper">
            <div className="ab-testing-content">
              <h4 className={`${prefix}-title`}>Set A (Control)</h4>
              <span className={`${prefix}-description`}>This is your control set of creatives. You must select at least one creative. </span>
            </div>
            <div className="ab-testing-content">
              <h4 className={`${prefix}-title`}>Set B (Test)</h4>
              <span className={`${prefix}-description`}>
                This is your test set of creatives. The creative formats must match those in your control set.
              </span>
            </div>
          </div>
        )
      }

      {
        abTestData && (
          <div className="ab-testing-wrapper">
            {
              abTestData.abSets.map(({ total, IPM, impressions }, i) => (
                <div key={i} className="ab-testing-content">
                  <ProgressBar
                    leftKey="Total Impressions"
                    rightKey="IPM"
                    tintColor="#03BDB8"
                    total={total}
                    totalText={numberFormat(total)}
                    current={impressions}
                    currentText={numberFormat(impressions)}
                    rightValue={IPM}
                  />
                </div>
              ))
            }
          </div>
        )
      }

      { renderNotes(campaign) }

      <div className="ab-testing-wrapper">
        <AttachedCreativesList
          isAbTesting={isAbTesting}
          prefix={prefix}
          className={isAbTesting ? 'is-ab-testing' : ''}
          campaign={campaign}
          creatives={creatives}
          selectedASetCreativeIds={selectedASetCreatives}
          selectedBSetCreativeIds={selectedBSetCreatives}
          creativeType={creativeType}
          formatASetInfos={formatInfos}
          formatBSetInfos={formatBInfos}
          selectASetCreative={selectCreative}
          selectBSetCreative={selectBCreative}
          renderRadio={({ row, isBSet }) => <RadioSelector row={row} isBSet={isBSet} />}
          onConfirm={(selectedIds, isBSet) => onConfirm(selectedIds, isBSet)}
          onCancel={resetSliderData}
          setSelectedASetIds={setSelectedASetCreatives}
          setSelectedBSetIds={setSelectedBSetCreatives}
          allCreatives={allCreatives}
        />
      </div>
      {
        !isAbTesting && (
          <Field name="SKAdNetwork ID" description="This ID is automatically assigned to this creative set for reporting purposes.">
            <Input disabled value={getSkanId(true)} />
          </Field>
        )
      }
      {
        isAbTesting && campaign.supportsSKANABTesting() && (
          <div className="ab-testing-wrapper skan-id">
            <div className={classNames('ab-testing-content')}>
              <span className="skan-id-type">Set A (Control) SKAdNetwork ID</span>
              <Input className="skan-id-input" type="text" value={getSkanId(true)} disabled />
            </div>
            <div className={classNames('ab-testing-content')}>
              <span className="skan-id-type">Set B (Test) SKAdNetwork ID</span>
              <Input className="skan-id-input" type="text" value={getSkanId(false)} disabled />
            </div>
          </div>
        )
      }
    </div>
  );
};

export default AttachedCreativesTestingWrapper;
