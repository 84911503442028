import React from 'react';
import { reduce } from 'lodash';
import radio from 'services/admin/views/campaigns/edit/radio';
import Group from 'templates/input-group/InputGroupContainer';
import { getAvailableCampaignCreativeFormats } from 'lib/capabilities/campaigns';
import triggerConfirm from '../Modals/ConfirmAction/triggerConfirm';

const { Field } = Group;

const confirmFormatChange = (campaign, format) => {
  if (campaign.supportsSKANABTesting() && campaign.get('creatives')?.length > 0) {
    triggerConfirm({
      type: 'CHANGE_CAMPAIGN_CREATIVE_FORMAT_CONFIRM_ACTION',
      header: 'Campaign Creative Format Change',
      message: 'Are you sure you want to change the campaign creative format? This will detach all current creatives.',
      cancelText: 'Cancel',
      onConfirm: () => {
        campaign.set('creative_type', format);
        campaign.set('creatives', []);
        campaign.set('creatives_b', []);
      },
    });
  } else {
    campaign.set('creative_type', format);
  }
};

export default function EditCampaignCreativeFormat({
  campaign,
}) {
  const creativeTypes = reduce(
    getAvailableCampaignCreativeFormats(campaign),
    (result, value, key) => {
      const {
        text,
        isAdminOnly,
      } = value;
      const fields = [key, text];

      if (isAdminOnly) {
        fields.push(true);
      }

      return [...result, fields];
    },
    [],
  );

  return campaign?.isSkadnetworkAttribution()
    ? (
      <Field
        name="Campaign Creative Format"
        description="Select the creative format you would like to run on this campaign"
      >
        {radio(campaign, 'creative_type', creativeTypes, true, false, (format) => confirmFormatChange(campaign, format), true)}
      </Field>
    )
    : null;
}
