import React, { useState, useRef, useEffect } from 'react';
import ValidatedText from '../../components/Form/ValidatedText/ValidatedText';

const MinMaxText = ({
  textType = 'Currency',
  disabled = false,
  disabledText,
  value,
  placeholder,
  onChange,
  isValid = true,
  validationMsg,
}) => {
  const [isEnabledFirstTime, setIsEnabledFirstTime] = useState(false);
  const isFirstLoad = useRef(true);
  const isNumeric = textType === 'Currency';
  useEffect(() => {
    if (!isFirstLoad.current) {
      if (!disabled && !isEnabledFirstTime) {
        setIsEnabledFirstTime(true);
      } else {
        setIsEnabledFirstTime(false);
      }
    }
    isFirstLoad.current = false;
    // --> potential bug inside this useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);
  useEffect(() => {
    setIsEnabledFirstTime(false);
  }, [value]);
  return (
    <ValidatedText
      textType={textType}
      isValid={isValid}
      value={isEnabledFirstTime ? '' : value || ''}
      noIcon
      numeric={isNumeric}
      disabledText={disabledText}
      disabled={disabled}
      onChange={(v) => {
        onChange(isNumeric ? Number(v) : v);
      }}
      placeholder={placeholder}
      validationMsg={validationMsg}
    />
  );
};

export default MinMaxText;
