import React from 'react';
import config from 'lib/config';

const SSO = ({ location }) => {
  const urlParams = new URLSearchParams(location.search);
  const targetUrl = `${window.location.origin}${urlParams.get('to') || ''}`;
  window.location.href = `${config.get('ssoUrl')}logout?original=${encodeURI(targetUrl)}`;
  return <div />;
};

export default SSO;
