const generateWebPageBlob = () => {
  const content = [...Array(10).keys()].map((indx) => {
    const theme = indx % 3 === 0 ? 'large' : indx % 2 === 0 ? 'medium' : 'small'; //eslint-disable-line
    return /*html*/`<div class="${theme}"></div>`;
  });

  const html = /*html*/`<html>
    <head>
      <style>
        body {
          margin: 0;
          padding: 0;
          background: #fff;
          border: 1px solid #ececec;
        }

        .content {
          flex-wrap: wrap;
          display: flex;
          flex-direction: row;
        }

        .content div {
          background-color: #ecf0f4;
          margin: 8px 16px;
          border-radius: 5px;
        }

        .content div.small {
          width: calc(100% - 156px);
          height: 32px;
        }

        .content div.medium {
          width: calc(100% - 96px);
          height: 32px;
        }

        .content div.large {
          width: 100%;
          height: 128px;
        }
      </style>
    </head>
    <body>
      <div class="content">
        ${content.join('')}
      </div>
    </body>
  </html>`;

  return URL.createObjectURL(new Blob([html], { type: 'text/html' }));
};

export default generateWebPageBlob;
