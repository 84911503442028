import { get as getFromSession } from 'lib/session';
import { getServiceType } from 'lib/serviceType';

// this function is using 'fetch' directly because it is downloading raw file data and the response is not a JSON
const download = (link, name) => {
  fetch(link, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${getFromSession('authUser').token}`,
      'Vungle-Version': '1',
      'Vungle-Source': getServiceType(),
    }),
  })
    .then((response) => response.blob())
    .then((blob) => {
      // download asset as a file with fake <a /> DOM (removing the <a /> once done)
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
};

export default download;
