import React from 'react';
import { Button } from 'components/V2';
import Link from 'components/Link/Link';
import { FILTER_KEY_DATE, FILTER_KEY_SEARCH } from 'app/constants/filters';
import { isFiltersEqual } from 'lib/cache/FiltersCache';
import { getCacheValue, setCacheValue } from './helper';
import ListFilter from './ListFilter';
import { ISO_8601_FORMAT } from '../../../Form/DatePicker/DatePicker';

class ListFilterContainer extends React.Component {
  onSearch = (v) => {
    const { refreshList, filterCache } = this.props;
    setCacheValue(filterCache, v, FILTER_KEY_SEARCH);
    refreshList();
  }

  onFilterChange = (v) => {
    const { refreshList, filterCache } = this.props;
    const prevCacheValue = getCacheValue(filterCache);
    setCacheValue(filterCache, v);
    if (!isFiltersEqual(prevCacheValue, v)) {
      refreshList();
    }
  }

  onDateChange = (v) => {
    const value = {
      ...v,
      startDate: v.startDate.startOf('day').format(ISO_8601_FORMAT),
      endDate: v.endDate.startOf('day').format(ISO_8601_FORMAT),
    };

    const { refreshList, filterCache } = this.props;
    setCacheValue(filterCache, value, FILTER_KEY_DATE);
    refreshList();
  };

  render() {
    const {
      to, cta, filterKeys, filterCache,
    } = this.props;
    return (
      <div className="list_filter_container">
        <ListFilter
          filterKeys={filterKeys}
          search={getCacheValue(filterCache, FILTER_KEY_SEARCH)}
          checkedDate={getCacheValue(filterCache, FILTER_KEY_DATE)}
          checkedFilters={getCacheValue(filterCache)}
          onSearchChange={this.onSearch}
          onDateChange={this.onDateChange}
          onChange={this.onFilterChange}
        />
        {to && (
          <Link to={to}>
            <Button type="primary">
              {cta}
            </Button>
          </Link>
        )}
      </div>
    );
  }
}

export default ListFilterContainer;
