import React, {
  useCallback, useEffect, useState, useRef,
} from 'react';
import { isUndefined } from 'lodash';
import Filters from '../../../../components/V2/MultiBidding/Filters/Filters';
import ErrorsCollapse from '../../../../components/V2/Collapse/ErrorsCollapse/ErrorsCollapse';
import status from '../../../../components/V2/MultiBidding/status';
import UploaderFile from '../../../../components/V2/Upload/Component/File';
import UploaderContainer from '../../../../components/V2/Upload/UploaderContainer';
import InfoBar from '../../../../components/InfoBar/InfoBar';
import EditTable from './EditTable/EditTable';
import { toInteger } from '../../../../lib/lib';
import config from '../../../../lib/config';
import { dailyGeoOption } from '../../../../lib/helpers/campaigns/campaignIndex';
import { format } from '../../../../lib/currency';
import './dailyCountryBudget.scss';

const { csvHeaders } = config.get('geoBudget');

export const geoBudgetValidator = ({ min, max }) => {
  const validator = (value) => {
    if (isUndefined(value)) {
      return true;
    }
    if (value.toString().includes('.')) {
      return false;
    }
    const castedValue = Number(value);
    if (!Number.isInteger(castedValue)) {
      return false;
    }
    if (Object.is(NaN, castedValue)) {
      return false;
    }
    if (Number(min) === 0 && castedValue === 0) {
      return true;
    }
    if (castedValue > 0 && castedValue < 100) {
      return false;
    }
    return (!max || castedValue <= max) && (!min || castedValue >= 100);
  };
  const validationMsg = `Whole numbers only, Min: ${format(100)}${max > 0 ? ` - Max: ${format(max)}` : ''}`;
  return { validator, validationMsg };
};

const getTableAllDataWithRate = (source, campaign, defaultRate) => {
  const geos = campaign.get('budget.daily_spend_limit_geos');
  return source.map((country) => {
    const geoObject = geos.find((geo) => geo.geo === country.code);
    const rate = geoObject && geoObject.rate ? geoObject.rate : defaultRate;
    return { ...country, rate };
  });
};

const handleValidateGeos = (countries, newGeos, { min, max }) => {
  const validateErrors = {};
  const expectedHeaders = ['geo', 'rate'];
  const csvHeaderColumns = Object.keys(newGeos[0]).map((column) => column);
  const unexpectedHeaders = csvHeaderColumns.filter((h) => !expectedHeaders.includes(h));
  const missingHeaders = expectedHeaders.filter((h) => !csvHeaderColumns.includes(h));

  if (missingHeaders.length > 0) {
    validateErrors.missingHeaders = missingHeaders;
  }
  if (unexpectedHeaders.length > 0) {
    validateErrors.unexpectedHeaders = unexpectedHeaders;
  }

  newGeos.forEach((newGeo) => {
    const { geo, rate } = newGeo;
    const { validator } = geoBudgetValidator({ min, max });
    if (geo && !countries.find((country) => country.code.toLowerCase() === geo.toLowerCase())) {
      if (validateErrors.invalidDailyGeoEntry) {
        validateErrors.invalidDailyGeoEntry.push(geo);
      } else {
        validateErrors.invalidDailyGeoEntry = [geo];
      }
    }
    if (geo && !validator(rate)) {
      if (validateErrors.invalidDailyRateEntry) {
        validateErrors.invalidDailyRateEntry.push(geo);
      } else {
        validateErrors.invalidDailyRateEntry = [geo];
      }
    }
  });
  return validateErrors;
};

const DailyCountryBudget = ({
  campaign,
  isMissionControl,
}) => {
  const countries = campaign.get('targeting.geo.countries');
  const { max, min, defaultRate } = dailyGeoOption(campaign, isMissionControl);
  const [tableData, setTableData] = useState([]);
  const [tableAllData, setTableAllData] = useState([]);

  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
    total: countries.length,
  });
  const [csvPercent, setCSVPercent] = useState(0);
  const [csvFile, setCsvFile] = useState();
  const [csvStatus, setCSVStatus] = useState(status.INITIAL);
  const [errors, setErrors] = useState();
  const sort = useRef();

  useEffect(() => {
    const geoBudgets = campaign.get('budget.daily_spend_limit_geos');
    geoBudgets.forEach((geo) => {
      if (min > 0 && geo.rate === 0) {
        Object.assign(geo, { rate: geo.rate });
      }
    });
    campaign.set('budget.daily_spend_limit_geos', geoBudgets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTableAllData(getTableAllDataWithRate(countries, campaign, defaultRate));
    setPagination({
      ...pagination,
      total: countries.length,
      page: 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries]);

  useEffect(() => {
    const { page, perPage } = pagination;
    const data = tableAllData.slice((page - 1) * perPage, page * perPage);
    if (page > 1 && data.length === 0) {
      setPagination({
        ...pagination,
        page: page - 1,
      });
    } else {
      setCSVStatus((tableAllData.length || countries.length) ? status.UPLOADED : status.INITIAL);
      setTableData(data);
    }
    // --> potential bug inside this useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableAllData, pagination]);

  const handleError = useCallback((file, error, isFileUploaded) => {
    if (file && error) {
      setErrors(error);
      if (!isFileUploaded) {
        setCsvFile(file);
      }
      setCSVPercent(100);
      setCSVStatus(status.ERROR);
    }
  }, []);

  const handleLoading = useCallback((file) => {
    setCsvFile(file);
    setCSVPercent(1);
    setCSVStatus(status.UPLOADING);
    setErrors(null);
  }, []);

  const handleRates = (newGeos, file) => {
    handleLoading(file);
    const validateErrors = handleValidateGeos(countries, newGeos, { min, max });
    if (Object.keys(validateErrors).length) {
      handleError(file, validateErrors, true);
    } else {
      campaign.set('budget.daily_spend_limit_geos', newGeos.filter((g) => g.geo));
      const newCountries = countries.map((country) => {
        const exist = newGeos.find((geo) => (
          country.code.toLowerCase() === geo.geo.toLowerCase()
        ));
        return { ...country, rate: exist ? exist.rate : defaultRate };
      });
      setTableAllData(newCountries);
      setPagination({ ...pagination, page: 1, total: newCountries.length });
    }
  };

  const handleCancel = () => {
    setCSVStatus(tableAllData.length ? status.UPLOADED : status.INITIAL);
  };

  const handleInlineEditSave = useCallback((geo, value) => {
    const geos = campaign.get('budget.daily_spend_limit_geos');
    const index = geos.findIndex((r) => r.geo === geo.code);
    const rate = !Number.isInteger(value) ? toInteger(value) : value;
    if (index > -1) {
      geos[index].rate = rate;
    } else {
      geos.push({ geo: geo.code, rate });
    }
    campaign.set('budget.daily_spend_limit_geos', geos);
    setTableAllData(getTableAllDataWithRate(tableAllData, campaign, defaultRate));
    // --> potential bug inside this useCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableAllData]);

  const handleSort = (sortArray, source) => {
    const geos = campaign.get('budget.daily_spend_limit_geos');
    const countriesWithRate = source.map((country) => {
      const countryWithRate = geos.find((geo) => geo.geo === country.code);
      return { ...country, rate: countryWithRate ? countryWithRate.rate : defaultRate };
    });
    const data = sortArray[0].desc
      ? countriesWithRate.sort((prev, next) => Number(next.rate) - Number(prev.rate))
      : countriesWithRate.sort((prev, next) => Number(prev.rate) - Number(next.rate));
    sort.current = sortArray;
    setTableAllData(data);
    setPagination({
      ...pagination,
      page: 1,
      total: data.length,
    });
  };

  const handleSearch = useCallback((e) => {
    const value = e.target.value ? e.target.value.toLowerCase() : '';
    const data = countries.filter((country) => {
      const { name, code } = country;
      return name.toLowerCase().includes(value) || code.toLowerCase().includes(value);
    });
    if (sort.current) {
      handleSort(sort.current, data);
    } else {
      setTableAllData(getTableAllDataWithRate(data, campaign, defaultRate));
      setPagination({
        ...pagination,
        page: 1,
        total: data.length,
      });
    }
    // --> potential bug inside this useCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort]);

  return (
    <div className="daily_country_budget_container">
      <InfoBar
        type="info"
        text={(
          <span>
            Set a target maximum spend per day. Combining a high bid and low limit may cause an overshoot of this target. &nbsp;
            {
              `(Min: ${format(min === 0 ? 0 : 100)}${max ? ` - Max: ${format(max)}` : ''})`
            }
          </span>
        )}
      />
      {
        (csvStatus !== status.UPLOADED || csvStatus === status.ERROR) ? (
          <>
            {
              (csvStatus === status.INITIAL) ? (
                <UploaderContainer type="csv" onComplete={handleRates} onError={handleError} />
              ) : (
                <>
                  <UploaderFile
                    className="upload_file"
                    file={csvFile}
                    percent={csvPercent}
                    error={!!errors}
                    onChange={(percent) => setCSVPercent(percent)}
                    onClose={handleCancel}
                  />
                  {csvStatus === status.ERROR && errors && (
                    <ErrorsCollapse errors={errors} />
                  )}
                </>
              )
            }
          </>
        ) : (
          <>
            <Filters
              className="filters"
              filename="Daily_Country_Budgets.csv"
              placeholder="Search by Country name or code..."
              data={tableAllData}
              hideAddRow
              onInputChange={handleSearch}
              headers={csvHeaders}
              onComplete={handleRates}
              onError={handleError}
            />
            <EditTable
              dailyGeoOption={dailyGeoOption}
              campaign={campaign}
              geoBudgetValidator={() => geoBudgetValidator({ min, max })}
              pagination={pagination}
              onPageSizeChange={(page, perPage) => setPagination({ ...pagination, perPage, page })}
              onSorted={(sortArray) => handleSort(sortArray, tableAllData)}
              countries={tableData}
              onSave={handleInlineEditSave}
              defaultRate={defaultRate}
            />
          </>
        )
      }
    </div>
  );
};

export default DailyCountryBudget;
