import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { debounce, isEqual } from 'lodash';
import Input from '../../../Input/Input';
import Checkbox from '../../../Checkbox/Checkbox';
import TreeSpinner from '../../../TreeSelect/TreeSpinner';
import TreeContainer from './components/TreeContainer';
import MenuContainer from './components/MenuContainer';
import SelectedPreview from './components/SelectedPreview';
import './multiTreeWrapper.scss';

export const CHECKED = 'checked';
export const UNCHECKED = 'unchecked';
export const INDETERMINATE = 'indeterminate';

const MultiTreeWrapper = ({
  prefixCls,
  wrapperCls,
  nodes,
  checkedNodes,
  checkableNodes,
  menuNodes,
  renderNode,
  renderLeaf,
  onChange,
  placeholder,
  loading,
  pagination = {},
  onLoadMore,
  tree,
  maxSelected,
  disabledTitle,
  disabledContent,
  treeChildrenKey,
  onLoadChildren,
  onLoadMenu,
  menuPagination,
  menuLoading,
}) => {
  const [keyword, setKeyword] = React.useState('');
  const searchRef = useRef();
  const selfPrefixCls = `${prefixCls}-wrapper`;
  const isBackendTree = typeof onLoadMore === 'function';
  const isSearch = typeof keyword === 'string' && keyword.length > 0;

  const toggleAll = React.useCallback((checked) => {
    if (checked) {
      onChange(keyword ? [...menuNodes] : [...checkableNodes]);
    } else {
      const menuNodeIds = menuNodes.map((n) => n.id);
      onChange(keyword ? checkedNodes.filter((n) => !menuNodeIds.includes(n.id)) : []);
    }
  }, [onChange, keyword, menuNodes, checkableNodes, checkedNodes]);

  const getAllStatus = React.useCallback(() => {
    if (checkedNodes.length === checkableNodes.length) {
      return CHECKED;
    }

    if (keyword && checkedNodes.length > 0 && isEqual(checkedNodes, menuNodes)) {
      return CHECKED;
    }

    if (checkedNodes.length === 0) {
      return UNCHECKED;
    }
    return INDETERMINATE;
  }, [checkedNodes, checkableNodes.length, keyword, menuNodes]);

  const onExpand = React.useCallback((_, expanded, { nodeData }) => {
    if (!expanded || nodeData.loading || nodeData[treeChildrenKey].length) {
      return;
    }
    onLoadChildren(nodeData);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onLoadChildren]);

  const applyLoadMenu = debounce(onLoadMenu, 300);
  const onSearch = React.useCallback((e) => {
    const { value } = e.target;
    setKeyword(value);
    if (typeof value === 'string' && value.length > 0) {
      applyLoadMenu({ search: value, page: 1 });
    }
    // --> potential bug inside this useCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onLoadMenu]);

  const onScroll = React.useCallback((e) => {
    if (!isBackendTree) return;

    const _loading = isSearch ? menuLoading : loading;
    if (_loading) return;

    const _pagination = isSearch ? menuPagination : pagination;
    if (_pagination.page >= _pagination.pages) return;

    const { offsetHeight, scrollTop, scrollHeight } = e.target;
    if (scrollTop + offsetHeight + 30 > scrollHeight) {
      const _onLoadMore = isSearch ? onLoadMenu : onLoadMore;
      _onLoadMore({ search: keyword, page: _pagination.page + 1 });
    }
    // --> potential bug inside this useCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, menuLoading, pagination, menuPagination, onLoadMore, onLoadMenu, isSearch]);

  const renderSearch = (
    <div className={`${selfPrefixCls}-search`}>
      <Input.Search
        className={`${selfPrefixCls}-search-input`}
        value={keyword}
        onChange={onSearch}
        placeholder={placeholder}
        ref={searchRef}
      />
    </div>
  );

  const allStatus = getAllStatus();
  const renderCheckAll = (
    <div className={`${selfPrefixCls}-all`}>
      <Checkbox
        onChange={toggleAll}
        label="Select All"
        className={`${selfPrefixCls}-all-checkbox`}
        indeterminate={allStatus === INDETERMINATE}
        checked={allStatus === CHECKED}
      />
    </div>
  );

  useEffect(() => {
    searchRef.current.input.focus();
  }, []);

  return (
    <div className={classnames(selfPrefixCls, { [`${selfPrefixCls}-api`]: isBackendTree }, wrapperCls)}>
      <div className={`${selfPrefixCls}-left`}>
        <div className={`${selfPrefixCls}-head`}>
          {renderSearch}
          {!isBackendTree && !maxSelected && renderCheckAll}
        </div>
        <div className={`${selfPrefixCls}-body`} onScroll={onScroll}>
          {
            isSearch
              ? (
                <MenuContainer
                  menuNodes={menuNodes}
                  renderMenu={renderLeaf}
                  maxSelected={maxSelected}
                  disabledTitle={disabledTitle}
                  disabledContent={disabledContent}
                  checkedNodes={checkedNodes}
                  onChange={onChange}
                />
              )
              : (
                <TreeContainer
                  tree={tree}
                  nodes={nodes}
                  checkedNodes={checkedNodes}
                  checkableNodes={checkableNodes}
                  renderNode={renderNode}
                  renderLeaf={renderLeaf}
                  maxSelected={maxSelected}
                  disabledTitle={disabledTitle}
                  disabledContent={disabledContent}
                  treeChildrenKey={treeChildrenKey}
                  hasMore={pagination.page < pagination.pages}
                  onLoadChildren={onLoadChildren}
                  onScroll={onScroll}
                  onExpand={onExpand}
                  onChange={onChange}
                />
              )
          }
          {
            (
              isSearch
                ? (menuPagination.page < menuPagination.pages)
                : (pagination.page < pagination.pages)
            )
            && <TreeSpinner />
          }
        </div>
      </div>
      <div className={`${selfPrefixCls}-right`}>
        <div className={`${selfPrefixCls}-head`}>
          <div className={`${selfPrefixCls}-count`}>
            (
            {checkedNodes.length}
            ) Selected
          </div>
        </div>
        <div className={`${selfPrefixCls}-body`}>
          <SelectedPreview
            checkedNodes={checkedNodes}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
};

MultiTreeWrapper.propTypes = {
  prefixCls: PropTypes.string.isRequired,
  /**
   * checked node list
   */
  checkedNodes: PropTypes.arrayOf(
    PropTypes.any,
  ).isRequired,
  /**
   * checkable node list
   */
  checkableNodes: PropTypes.arrayOf(
    PropTypes.any,
  ).isRequired,
  /**
   * tree node list
   */
  nodes: PropTypes.arrayOf(
    PropTypes.any,
  ).isRequired,
  /**
   * menu node list
   */
  menuNodes: PropTypes.arrayOf(
    PropTypes.any,
  ),
  /**
   * node render func
   */
  renderNode: PropTypes.func,
  /**
   * leaf node render func
   */
  renderLeaf: PropTypes.func.isRequired,
  /**
   * tree change
   */
  onChange: PropTypes.func.isRequired,
  /**
   * search placeholder
   */
  placeholder: PropTypes.string.isRequired,
  /**
   * Maximum nodes that allowed selected.
  */
  maxSelected: PropTypes.number,
  /**
   * Tips title message when reach maximum nodes allowed selected.
  */
  disabledTitle: PropTypes.string,
  /**
   * Tips content message when reach maximum nodes allowed selected.
  */
  disabledContent: PropTypes.string,
  /**
   * loading
   */
  loading: PropTypes.bool,
  /**
   * pagination
   */
  pagination: PropTypes.shape({
    page: PropTypes.number,
    pages: PropTypes.number,
  }),
  /**
   * handler of load more
   */
  onLoadMore: PropTypes.func,
  /**
   * handler of load menu
   */
  onLoadMenu: PropTypes.func,
  /**
   * tree view
   */
  tree: PropTypes.bool,
  /**
   * children key
   */
  treeChildrenKey: PropTypes.string,
  /**
   * handler of load children
   */
  onLoadChildren: PropTypes.func,
  /**
   * pagination
   */
  menuPagination: PropTypes.shape({
    page: PropTypes.number,
    pages: PropTypes.number,
  }),
  /**
   * menu loading
   */
  menuLoading: PropTypes.bool,
};

MultiTreeWrapper.defaultProps = {
  menuNodes: [],
  renderNode: null,
  loading: false,
  menuLoading: false,
  pagination: {},
  maxSelected: 0,
  disabledTitle: '',
  disabledContent: '',
  menuPagination: {},
  onLoadMore: null,
  onLoadMenu: null,
  tree: false,
  treeChildrenKey: 'children',
  onLoadChildren: null,
};

export default React.memo(MultiTreeWrapper);
