import React, {
  useMemo, useRef, useEffect, useState,
} from 'react';
import { noop } from 'lodash';
import {
  List, AutoSizer, CellMeasurer, CellMeasurerCache, InfiniteLoader,
} from 'react-virtualized';
import moment from 'moment';
import { NEEDS_ATTENTION } from 'app/constants/asset';
import Spinner from '../../../../../components/Spinner/Spinner';
import { historyActions } from '../config';
import HistoryItem from './HistoryItem';
import './historyList.scss';

const format = 'DD MMM YYYY';

const HistoryList = ({
  assetId, data = [], onFetchMoreData, total, refreshHistory, loading, onAssetReplacement,
}) => {
  const infiniteLoaderRef = useRef(null);
  // Because InfiniteLoader will delete items that are not in the viewport, I need to maintain an array to save expanded Items.
  const [expandedIndex, setExpandedIndex] = useState({});

  const latestQADataIndex = useMemo(() => data.findIndex((item) => item.action !== historyActions.rename),
    [data]);

  const isRowLoaded = ({ index }) => !!data[index];

  const hasMore = useMemo(() => data.length < total, [total, data]);

  useEffect(() => {
    if (latestQADataIndex < 0) return;
    //  if the status of the latest history record is “needs attention”, the drop-down should be open automatically.
    setExpandedIndex({ [latestQADataIndex]: data[latestQADataIndex].status === NEEDS_ATTENTION });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestQADataIndex, total]);

  const onExpandedChange = (index, expanded) => {
    setExpandedIndex((prev) => ({ ...prev, [index]: expanded }));
  };

  useEffect(() => {
    if (loading || !infiniteLoaderRef || !hasMore) return;
    const scrollContainer = infiniteLoaderRef.current._registeredChild.Grid._scrollingContainer;
    if ((scrollContainer.scrollHeight <= scrollContainer.offsetHeight) && hasMore) {
      onFetchMoreData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, infiniteLoaderRef]);

  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 60,
  });

  const shouldShowDate = (index, date = new Date()) => (
    index === 0 || !moment(date).isSame(moment(data[index - 1].updated), 'day')
  );

  const rowRenderer = ({
    index, parent, key, style,
  }) => {
    let nextQADataIndex = index - 1;
    while (nextQADataIndex >= 0 && data[nextQADataIndex].action === historyActions.rename) {
      nextQADataIndex -= 1;
    }
    return (
      <CellMeasurer
        key={`${key}-${data[index].updated}`}
        cache={cache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        {({ measure, registerChild }) => (
          <div style={style} className="history-list-cell" ref={registerChild}>
            {shouldShowDate(index, data[index].updated)
              && (
                <p className="date">{moment(data[index].updated).format(format)}</p>
              )}
            <HistoryItem
              isExpanded={expandedIndex[index] || false}
              onExpandedChange={(bool) => onExpandedChange(index, bool)}
              measure={measure}
              data={data[index]}
              nextQAData={nextQADataIndex >= 0 ? data[nextQADataIndex] : null}
              isLatest={index === latestQADataIndex}
              assetId={assetId}
              refreshHistory={refreshHistory}
              onAssetReplacement={onAssetReplacement}
            />
          </div>
        )}
      </CellMeasurer>
    );
  };

  return (
    <div className="creativeQA-history-list">
      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={!loading && hasMore ? () => onFetchMoreData(false) : noop}
        rowCount={total}
        ref={infiniteLoaderRef}
        threshold={1}
      >
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer>
            {({ width, height }) => (
              <List
                ref={registerChild}
                rowCount={data.length}
                width={width}
                height={height}
                rowHeight={cache.rowHeight}
                rowRenderer={rowRenderer}
                deferredMeasurementCache={cache}
                overscanRowCount={2}
                onRowsRendered={onRowsRendered}
              />
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
      {loading && (
        <div className="creativeQA-history-loading">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default HistoryList;
