import React from 'react';
import { withRouter } from 'react-router';
import { getTranslation } from '../../../lib/translator';
import { compose, namespace } from '../../../app/app';
import PreviewModel from '../../../models/Preview';
import Template from '../../../models/Template';
import { ERRORS, CHAMPA_FORMATS } from '../../DeviceFrame/settings';
import Preview from './Preview';
import './preview.scss';

const INSERT_VIDEO_PAGE = ['vungle_local'];

class PreviewContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resolution: 'landscape',
      device: 'phone',
      assetURL: null,
      mainVideo: null,
      placementType: null,
      format: null,
      replacements: null,
    };
  }

  getCreativesReplacements = async (p) => {
    const { creative } = p;
    const format = creative.get('format');

    if (['vungle_mraid'].indexOf(format) === -1) {
      return {};
    }

    const creativeReplacements = await PreviewModel.getExistingCreativeReplacements(creative.get('id'));
    const replacements = creativeReplacements.response.replacements || [];
    this.setState({ replacements });
    const tplReplacements = {};

    replacements.forEach((replacement) => {
      const isShowDownload = (val) => val === '' || val.toLowerCase() === 'download';
      if (replacement.key === 'CTA_BUTTON_TEXT' && isShowDownload(replacement.value.trim())) {
        tplReplacements[replacement.key] = getTranslation(creative.attrs['language.code']);
        return;
      }
      if (replacement.key === 'APP_LONG_DESCRIPTION' && replacement.value) {
        tplReplacements[replacement.key] = `${replacement.value.substring(0, 200)}...`;
        return;
      }
      if (replacement.key === 'APP_SCREENSHOTS' && replacement.value) {
        try {
          const screenshots = JSON.parse(replacement.value);
          tplReplacements[replacement.key] = JSON.stringify(screenshots.slice(0, 3));
        } catch (error) {
          tplReplacements[replacement.key] = '';
        }
        return;
      }
      tplReplacements[replacement.key] = replacement.value;
    });

    return tplReplacements;
  }

  getCreativesAssetUrl = async (p) => {
    const { creative } = p;
    let format = creative.get('format'); // vungle_local, vungle_short_form, vungle_mraid

    let bundleUrl = creative.get('postBundle');
    let uid = creative.get('id');

    if (['vungle_mraid'].indexOf(format) !== -1) {
      bundleUrl = creative.raw('template.cdn_url');
      const replacements = await this.getCreativesReplacements(p);
      const hasEndcard = replacements.HAS_ENDCARD && replacements.HAS_ENDCARD.toLowerCase() === 'true';
      if (hasEndcard) {
        bundleUrl = creative.get('endcardUrl');
        format = CHAMPA_FORMATS.adaptiveCreative;
      } else {
        uid = creative.raw('template.id');
        format = CHAMPA_FORMATS.dynamicTemplate;
      }
    }

    return this.getAssetUrl(uid, format, bundleUrl);
  }

  getCreativesPlacementType = (p) => {
    const { creative } = p;
    const format = creative.get('format');

    if (['vungle_mraid'].indexOf(format) !== -1) {
      return creative.raw('template.format');
    }

    return null;
  }

  getCreativeBiggestVideo = async (p) => {
    try {
      const { creative } = p;
      const uid = creative.get('id');
      const { response } = await PreviewModel.getExistingCreativeBiggestVideo(uid);

      return response.video_url || ERRORS.videoUrl;
    } catch (x) {
      return ERRORS.videoUrl;
    }
  }

  setCreativesPreview = (p) => {
    // Have to reset state, otherwise we load the previous preview
    this.setState({
      assetURL: null,
      mainVideo: null,
      placementType: this.getCreativesPlacementType(p),
      format: p.creative.get('format'),
    });

    (async () => {
      let videoUrl = null;
      let creativeReplacements = {};
      if (INSERT_VIDEO_PAGE.indexOf(p.creative.get('format')) !== -1) {
        videoUrl = await this.getCreativeBiggestVideo(p);
      }

      if (['vungle_mraid'].indexOf(p.creative.get('format')) !== -1) {
        creativeReplacements = await this.getCreativesReplacements(p);

        if (!Object.keys(creativeReplacements).length) {
          this.setState({
            assetURL: ERRORS.replacementsUrl,
          });
          return;
        }
      }

      const appPlatform = p.creative.get('application.platform');
      const replacements = encodeURIComponent(JSON.stringify(creativeReplacements));
      const params = `platform=${appPlatform}&replacements=${replacements}`;
      const assetURL = `${await this.getCreativesAssetUrl(p)}?${params}`;

      this.setState({
        assetURL,
        mainVideo: videoUrl,
      });
    })();
  }

  setTemplatePreview = async (p) => {
    const { templateId, platform, replacements } = p;

    const template = (await Template.getTemplateSummary(templateId)).response;

    this.setState({
      assetURL: null,
      mainVideo: null,
      placementType: template.format,
      format: 'vungle_mraid',
    });

    // advertisers create creative sets the default value `CTA_BUTTON_BACKGROUND`
    const tplReplacements = {};

    if (template.replacements && template.replacements.length) {
      template.replacements.forEach((replacement) => {
        tplReplacements[replacement.key] = replacement.value;
      });
    }

    replacements.forEach((replacement) => {
      tplReplacements[replacement.key] = replacement.value;
    });

    (async () => {
      const replacementsParam = encodeURIComponent(JSON.stringify(tplReplacements));
      const params = `platform=${platform}&replacements=${replacementsParam}`;
      const assetURL = `${await this.getAssetUrl(template.id, CHAMPA_FORMATS.dynamicTemplate, template.cdn_url)}?${params}`;

      this.setState({
        assetURL,
      });
    })();
  }

  getAssetUrl = async (id, format, url) => {
    if (!url) {
      return ERRORS.assetUrl;
    }

    try {
      const { response } = await PreviewModel.getCdnURL({
        id,
        format,
        url,
      });
      return response.url || ERRORS.assetUrl;
    } catch (x) {
      return ERRORS.assetUrl;
    }
  }

  UNSAFE_componentWillReceiveProps(p) {
    this.onRefresh(p);
  }

  onRefresh = (parentProps) => {
    const p = parentProps || this.props;
    if (!p.creative && !p.templateId) {
      return;
    }

    this.setState({
      assetURL: null,
    });

    if (p.creative) {
      this.setCreativesPreview(p);
      return;
    }

    if (p.templateId) {
      this.setTemplatePreview(p);
    }
  }

  render() {
    const { creative, templateId } = this.props;
    if (!creative && !templateId) {
      return null;
    }
    return (
      <Preview
        {...this.props}
        {...this.state}
        onClose={this.props.actions.close}
        onRefresh={this.onRefresh}
      />
    );
  }
}

const ns = 'components.creatives.preview';
const updateState = namespace(ns);
const reduxProps = (state) => state[ns];

const close = (() => {
  const action = () => { };
  const start = ({ state }) => ({
    ...updateState(state, {
      creative: null,

      // this are used together
      templateId: null,
      replacements: null,
      platform: null,
    }),
  });
  const end = () => { };
  return { action, start, end };
})();

const reduxActions = { close };

export default withRouter(compose(PreviewContainer, { actions: reduxActions, props: reduxProps }));
export {
  ns,
};
