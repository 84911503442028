import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { noop } from 'lodash';
import Button from '../../Button/Button';
import PickerCustom from './PickerCustom';
import PickerQuick, { QUICK_KEY_CUSTOM, QUICK_MAP } from './PickerQuick';
import './calendar.scss';

export const prefixCls = 'v2_component_report_calendar';

function Calendar({
  quickKey: quickKeyProps,
  startDate,
  endDate,
  onChange,
  onCancel,
}) {
  const [start, setStart] = React.useState(null);
  const [end, setEnd] = React.useState(null);
  const [quickKey, setQuickKey] = React.useState(null);

  React.useEffect(() => {
    const quick = QUICK_MAP[quickKeyProps];
    if (quick) {
      const range = quick.generateDate(moment());
      setQuickKey(quickKeyProps);
      setStart(range.startDate);
      setEnd(range.endDate);
      return;
    }
    if (moment.isMoment(startDate) && moment.isMoment(endDate)) {
      setStart(startDate);
      setEnd(endDate);
      setQuickKey(QUICK_KEY_CUSTOM);
    }
  }, [quickKeyProps, startDate, endDate]);

  const onQuickChange = React.useCallback((quick) => {
    setStart(quick.startDate);
    setEnd(quick.endDate);
    setQuickKey(quick.quickKey);
  }, []);

  const onCustomChange = React.useCallback((custom) => {
    setStart(custom.startDate);
    setEnd(custom.endDate);
    setQuickKey(QUICK_KEY_CUSTOM);
  }, []);

  const onApply = React.useCallback(() => {
    onChange({
      startDate: start,
      endDate: end,
      quickKey,
    });
    // --> potential bug inside this useCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, end, quickKey]);

  return (
    <div className={prefixCls}>
      <div className={`${prefixCls}-body`}>
        <PickerQuick
          onChange={onQuickChange}
          quickKey={quickKey}
        />
        <PickerCustom
          startDate={start}
          endDate={end}
          onChange={onCustomChange}
        />
      </div>
      <div className={`${prefixCls}-foot`}>
        <div className={`${prefixCls}-tips`}>
          *Data is limited to the last 6 months.
          <br />
          Time zone is based on UTC.
        </div>
        <div className={`${prefixCls}-actions`}>
          <Button
            className={`${prefixCls}-action`}
            size="small"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            className={`${prefixCls}-action`}
            size="small"
            type="ghost"
            onClick={onApply}
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
}

Calendar.propTypes = {
  startDate: PropTypes.objectOf(PropTypes.any),
  endDate: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
};

Calendar.defaultProps = {
  startDate: null,
  endDate: null,
  onChange: noop,
  onCancel: noop,
};

export default React.memo(Calendar);
