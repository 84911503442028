import React, { useState, useMemo } from 'react';
import AnimateHeight from 'react-animate-height';
import classNames from 'classnames';
import './progressBar.scss';

const prefix = 'ab-testing-progress-bar';

const ProgressBar = ({
  current,
  currentText,
  total,
  totalText,
  leftKey,
  rightKey,
  rightValue,
  tintColor,
  children,
}) => {
  const [expanded, setExpanded] = useState(false);

  const ratio = useMemo(() => {
    if (!current || !total) {
      return 0;
    }
    return Math.floor((current / total) * 100);
  }, [current, total]);

  return (
    <div className={classNames(prefix, { expanded })}>
      <div
        onClick={() => {
          if (!children) {
            return;
          }
          setExpanded(!expanded);
        }}
        className={classNames(`${prefix}-data`, { clickable: children })}
      >
        <span>
          <span>{`${leftKey}: `}</span>
          <span className={classNames(`${prefix}-data-text`)}>{`${currentText} / ${totalText}`}</span>
        </span>
        <span>
          <span>{`${rightKey}: `}</span>
          <span className={classNames(`${prefix}-data-text`)}>{ rightValue }</span>
          {
            children && (
              <i className={classNames('material-icons')}>
                keyboard_arrow_down
              </i>
            )
          }
        </span>
      </div>
      <div className={classNames(`${prefix}-container`)}>
        <div
          className={classNames(`${prefix}-container-current`)}
          style={{
            width: `${ratio}%`,
            backgroundColor: tintColor,
          }}
        />
      </div>
      <AnimateHeight
        duration={200}
        height={expanded ? 'auto' : 0}
      >
        <div className={classNames(`${prefix}-children-container`, { expanded })}>
          { children }
        </div>
      </AnimateHeight>
    </div>
  );
};

export default ProgressBar;
