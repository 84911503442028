import config from 'lib/config';
import { isFunction } from 'lib/lib';
import { format } from 'lib/currency';
import Model from './BaseModel';
import {
  getAccount,
  getAccountList,
  modifyAccount,
  addFunds,
  acceptTerms,
} from '../app/graphql/utils/account';

class Account extends Model {
  constructor(attrs) {
    super(attrs);
    this.primaryUser = null;

    this.default('address.country', 'US', ['']);
    this.default('io.signed', false);
    this.default('io.acceptEmail', '');
    this.default('io.acceptDate', null);
    this.default('io.type', 'postpay_open', ['']);
    this.default('access_grow', true, ['', null]);
    this.map('budget_pacing.total_available', 'budget_pacing.total_spent', format);
    this.default('budget_type', 'prepay', ['', null]);
    this.default('budget_pacing.is_enabled', true, ['', null]);
  }

  getRemainingFunds(limitAtZero = false) {
    const available = this.raw('budget_pacing.total_available');
    const spent = this.raw('budget_pacing.total_spent');
    if (limitAtZero) {
      return format(Math.max(available - spent, 0));
    }
    return format(available - spent);
  }

  getOverallSpend(limitAtOverall = false) {
    const available = this.raw('budget_pacing.total_available');
    const spent = this.raw('budget_pacing.total_spent');
    if (limitAtOverall && spent > available) {
      return format(available);
    }
    return format(spent);
  }

  hasPermissionForPlayable() {
    return this.get('permissions.access_playable_adtype')
      || this.get('permissions.access-playable-adtype');
  }

  hasPermissionForNative() {
    return this.get('permissions.access_native_adtype')
      || this.get('permissions.access-native-adtype');
  }

  hasRoasCampaignAccess() {
    return this.get('permissions.modify_roas_campaigns');
  }

  isROASEnabledIOS() {
    return this.get('permissions.isROASEnabledIOS');
  }

  isROASEnabledAndroid() {
    return this.get('permissions.isROASEnabledAndroid');
  }

  hasCPPUCampaignAccess() {
    return this.get('permissions.modify_cppu_campaigns');
  }

  isCPPUEnabledIOS() {
    return this.get('permissions.isCPPUEnabledIOS');
  }

  isCPPUEnabledAndroid() {
    return this.get('permissions.isCPPUEnabledAndroid');
  }

  hasCPECampaignAccess() {
    return this.get('permissions.modify_cpe_campaigns');
  }

  isCPEEnabledIOS() {
    return this.get('permissions.isCPEEnabledIOS');
  }

  isCPEEnabledAndroid() {
    return this.get('permissions.isCPEEnabledAndroid');
  }

  isBudgetPaced() {
    return this.raw('budget_pacing.is_enabled');
  }

  isPrePay() {
    return this.raw('budget_type') === 'prepay';
  }

  getPrimaryUser(users) {
    if (this.primaryUser) {
      return this.primaryUser;
    }
    const email = this.raw('contact.email');
    const index = users.findIndex((u) => u.get('email') === email);
    if (index === -1) {
      const user = users[0];
      this.primaryUser = user;
      return this.primaryUser;
    }
    this.primaryUser = users[index];
    return this.primaryUser;
  }

  setPrimaryUser(users, id) {
    const index = users.findIndex((u) => u.get('id') === id);
    this.primaryUser = users[index];
    if (isFunction(this.onChangeFn)) {
      this.onChangeFn(this);
    }
  }

  toServerObject() {
    const account = {};
    account.name = this.raw('name');
    account.type = this.raw('type');

    const contact = {};
    contact.email = this.primaryUser.get('email');
    contact.name = this.primaryUser.get('name');
    contact.phone = this.raw('contact.phone');
    account.contact = contact;

    const address = {};
    address.line1 = this.raw('address.line1');
    address.line2 = this.raw('address.line2');
    address.city = this.raw('address.city');
    address.country = this.raw('address.country');
    address.state = this.raw('address.state');
    address.postal = this.raw('address.postal');
    account.address = address;

    return account;
  }
}

const baseUrl = `${config.get('manageUrl')}accounts`;
Account.acceptTerms = (id, terms) => acceptTerms(id, terms);
Account.get = (id) => getAccount(id);
Account.getAll = (query) => getAccountList(query);
Account.list = (query) => getAccountList({ type: ['both', 'advertiser'], ...query });
Account.fund = (account, token, amount, country) => addFunds(account.get('id'), token, amount, country);
Account.save = (account) => modifyAccount(
  account.get('id'),
  account.toServerObject(),
);
Account.url = (id) => `${baseUrl}/${id}`;
Account.make = Model.make(Account);

export function getAccountCreativeMaximum(entity) {
  return entity.get('account').get('creativeMaximum') || config.get('accounts.defaultCreativeMaximum');
}

export default Account;
