import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { matchPath } from 'react-router';
import ApplicationThumbnail from '../../../../../templates/application/thumbnail';
import ApplicationCreator from '../../../../../templates/ApplicationCreator/ApplicationCreatorContainer';
import ApplicationSelector from '../../campaigns/Edit/ApplicationSelector/ApplicationSelectorContainer';
import paths from '../../../paths';
import CreateCreative from './Create/CreateContainer';
import Crumb from '../../../../../components/Crumb/Crumb';
import AttachCampaigns from './Campaigns/CampaignsContainer';
import getCreativeClassName from '../../../../../lib/helpers/getCreativeClassName/getCreativeClassName';

const {
  CREATIVE: {
    pathString: PATH_CREATIVE,
    getPath: getPathToCreative,
  },
  CREATE_CREATIVES: {
    pathString: PATH_CREATE_CREATIVE,
  },
  CREATIVE_APPLICATION: {
    pathString: PATH_CREATIVE_APPLICATION,
  },
  CREATIVE_CAMPAIGNS: {
    pathString: PATH_CREATIVE_CAMPAIGN,
  },
} = paths;

const selectSkApplication = async (application, creative, updateCreative) => {
  const skadNetworkSettings = await application.getSkadnetworkSettings();
  if (skadNetworkSettings?.usage) {
    application.set('skadnetwork_settings.usage.remaining', skadNetworkSettings.usage.remaining || 0);
    creative.set('application', application);
    updateCreative(creative);
  }
};

const CreativesEdit = ({
  creative,
  location: {
    pathname,
  },
  match: {
    params: {
      edit: id,
    },
  },
  actions: { updateCreative },
}) => {
  const applicationName = creative.get('application') ? creative.get('application').get('name') : '';
  return (
    <div className="views__advertiser__campaigns__edit">
      <div className="breadcrumbs">
        <div className="details">
          <div className="application-thumbnail">
            <ApplicationThumbnail application={creative.get('application')} />
            <p className="application-thumbnail-name">
              {
                applicationName || <div className="application-choose">Choose Application</div>
              }
            </p>
          </div>
        </div>
        <div className="crumbs">
          <Crumb
            name="Application"
            active={matchPath(pathname, { path: PATH_CREATIVE, exact: true })}
            complete={!!creative.get('application')}
          />
          <Crumb
            name="Creatives"
            active={
              matchPath(pathname, { path: PATH_CREATE_CREATIVE, exact: true })
            }
            complete={matchPath(pathname, { path: PATH_CREATIVE_CAMPAIGN, exact: true })}
          />
          <Crumb
            name="Campaigns"
            active={matchPath(pathname, { path: PATH_CREATIVE_CAMPAIGN, exact: true })}
          />
        </div>
      </div>
      <div className={getCreativeClassName(['workflow'], pathname, PATH_CREATE_CREATIVE)}>
        <div className="workflow-options">
          <Switch>
            <Route
              path={PATH_CREATIVE_APPLICATION}
              exact
              component={ApplicationCreator}
            />
            <Route
              exact
              path={PATH_CREATIVE}
              render={(...p) => (
                <ApplicationSelector
                  type="creative"
                  handleSkAppClick={selectSkApplication}
                  updateCreative={updateCreative}
                  {...p}
                />
              )}
            />
            <Route
              exact
              path={PATH_CREATE_CREATIVE}
              component={CreateCreative}
            />
            <Route
              exact
              path={PATH_CREATIVE_CAMPAIGN}
              render={(...p) => (
                <AttachCampaigns
                  handleSkAppClick={selectSkApplication}
                  updateCreative={updateCreative}
                  {...p}
                />
              )}
            />
            <Redirect to={getPathToCreative(id)} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default CreativesEdit;
