import { getServiceType } from 'lib/serviceType';
import { getClient, getSlydellClient } from '../setup';
import { GetUserConfig, SecretKey } from '../operations_user.graphql';
import handleGraphQLError from './handle-graphql-error';

const handleGetUserConfigResponse = ({
  data: {
    userConfig: configData,
  },
}) => {
  const {
    user: {
      secretKey,
      ...userRest
    },
    account,
    campaignBudget,
  } = configData;
  return {
    ok: true,
    status: 200,
    response: {
      user: {
        ...userRest,
        token: secretKey,
      },
      account: {
        id: account.id,
        name: account.name,
        tos_signed: !!account.io?.signed,
        budget_type: account.budgetType,
        permissions: {
          access_native_adtype: !!account.permissions?.accessNativeADType,
          isROASEnabledAndroid: !!account.permissions?.isROASEnabledAndroid,
          isROASEnabledIOS: !!account.permissions?.isROASEnabledIOS,
          access_playable_adtype: !!account.permissions?.accessPlayableADType,
          modify_roas_campaigns: !!account.permissions?.modifyRoasCampaigns,
          modify_cppu_campaigns: !!account.permissions?.modifyCPPUCampaigns,
          isCPPUEnabledAndroid: !!account.permissions?.isCPPUEnabledAndroid,
          isCPPUEnabledIOS: !!account.permissions?.isCPPUEnabledIOS,
          modify_cpe_campaigns: !!account.permissions?.modifyCPECampaigns,
          isCPEEnabledAndroid: !!account.permissions?.isCPEEnabledAndroid,
          isCPEEnabledIOS: !!account.permissions?.isCPEEnabledIOS,
        },
        settings: {
          campaigns: {
            budget: campaignBudget,
          },
          account_blacklist: account.accountBlackList || [],
          application_blacklist: account.appBlackList || [],
        },
      },
    },
  };
};

export const getUserConfig = (id) => getClient()
  .query({
    query: GetUserConfig,
    variables: {
      id,
      client: getServiceType(),
    },
  })
  .then(handleGetUserConfigResponse)
  .catch(handleGraphQLError);

export const handleSecretKeyResponse = ({
  data: {
    secretKey,
  } = {},
}) => ({ response: secretKey });

export const getSecretKey = (id) => getSlydellClient()
  .query({
    errorPolicy: 'all',
    query: SecretKey,
    variables: {
      id,
    },
  })
  .then(handleSecretKeyResponse)
  .catch(handleGraphQLError);

export default {};
