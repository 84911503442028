import React, {
  useState, useEffect, useCallback, useImperativeHandle, forwardRef,
} from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { ISO_8601_FORMAT } from '../../../../lib/date';
import useDebounce from '../../../../lib/hooks/useDebounce';
import MetricMenu from '../../../../components/V2/Report/MetricMenu/MetricMenu';
import { getMetricsListByTab, getMetricsGroupByTab } from '../../../../components/V2/Report/data/Metrics';
import { getReportingPageSizeCache } from '../../../../lib/cache/PageSizeCache';
import { initPagination } from '../../../../components/V2/Filters/constant';
import { getReportingDetail } from '../../../../app/graphql/utils/reporting';
import DataTable from '../../../../components/V2/Report/DataTable/DataTable';
import { REPORT_TABLE_METIC_KEY } from '../../../../lib/cache/constant';
import Loading from '../../../../components/V2/Report/Loading/Loading';
import MetricCache from '../../../../lib/cache/MetricCache';
import Tabs from '../../../../components/V2/Tabs/Tabs';
import {
  getDetailQueryNodeIds, returnMetricsList,
} from '../helper';
import './dataBreakdown.scss';

const classPrefix = 'v2_pages_data_breakdown';

const initSort = {
  sortKey: 'views', isSortAsc: false,
};

export const returnInitMetrics = (userId, tab) => {
  const metricsCache = new MetricCache(userId, REPORT_TABLE_METIC_KEY);
  const metrics = metricsCache.get();
  if (metrics) {
    return returnMetricsList({
      metricsData: metrics,
      tab,
      needCheckCPE: true,
    });
  }
  return getMetricsListByTab(tab, true).filter((i) => i.tableDefault);
};

const DataBreakdown = ({
  account,
  className,
  checkedDate,
  checkedCombine,
  tabs,
  currentTab,
  setTabs,
  initTableData,
}, ref) => {
  const authUser = useSelector((state) => state.authUser);
  const pageSizeCache = getReportingPageSizeCache(authUser.account);

  const [sort, setSort] = useState(initSort);
  const [metrics, setMetrics] = useState([]);

  const [tableData, setTableData] = useState(initTableData);
  const [tableLoading, setTableLoading] = useState(false);
  const [pagination, setPagination] = useState({
    ...initPagination,
    perPage: pageSizeCache.get(),
  });

  const tableSorted = [{ id: sort.sortKey, desc: !sort.isSortAsc }];

  const onTabClick = (col) => {
    const newCols = tabs.map((item) => ({
      ...item,
      selected: item.key === col.key,
    }));
    setPagination((prevState) => ({
      ...prevState,
      page: 1,
    }));
    setSort(initSort);
    setTabs(newCols);
  };

  const onMetricsChange = (checkedMetrics) => {
    setMetrics(returnMetricsList({
      metricsData: checkedMetrics,
      tab: currentTab,
      needCheckCPE: true,
    }));
  };

  const onPageChange = (page, perPage) => {
    pageSizeCache.save(perPage);
    setPagination((prevState) => ({
      ...prevState,
      perPage,
      page,
    }));
  };

  const onSorted = (sortedColumns) => {
    const col = sortedColumns[0];
    setSort({
      sortKey: col.id,
      isSortAsc: !col.desc,
    });
  };

  const getListData = useCallback(async () => {
    const params = {
      sortKey: sort.sortKey,
      page: pagination.page,
      perPage: pagination.perPage,
      isSortAsc: sort.isSortAsc,
      dimension: currentTab.key,
      start: checkedDate.startDate.format(ISO_8601_FORMAT),
      end: checkedDate.endDate.format(ISO_8601_FORMAT),
      ...getDetailQueryNodeIds(checkedCombine),
    };
    if (account) {
      params.accounts = [account];
    }
    setTableLoading(true);
    try {
      const resource = await getReportingDetail(params);
      if (resource && resource.data) {
        setPagination((prevState) => ({
          ...prevState,
          pages: resource.pagination.pages,
          total: resource.pagination.total,
        }));
        setTableData((prevData) => ({
          ...prevData,
          data: resource.data,
          total: { total: 'Total', ...resource.total },
          tabKey: currentTab.key,
        }));
      }
    } finally {
      setTableLoading(false);
    }
  }, [account, checkedDate, checkedCombine, pagination.page, pagination.perPage, currentTab.key, sort.sortKey, sort.isSortAsc]);

  useImperativeHandle(ref, () => ({
    onLoadTableData: () => getListData(),
  }));

  useDebounce(() => {
    getListData();
  }, 50, [getListData]);

  useEffect(() => {
    setMetrics(returnInitMetrics(authUser.id, currentTab));
  }, [currentTab]);

  return (
    <div className={classnames(classPrefix, className)}>
      <div className={`${classPrefix}_title`}>Data Breakdown</div>
      <div className={`${classPrefix}_tabs`}>
        <Tabs
          columns={tabs}
          onClick={onTabClick}
          extra={(
            <div className={`${classPrefix}_tabs_action`}>
              <MetricMenu
                defaultKey="tableDefault"
                propsRenderCount={(amount) => `Sort (${amount}) selected columns`}
                metricType={REPORT_TABLE_METIC_KEY}
                metricUserId={authUser.id}
                metricGroup={getMetricsGroupByTab(currentTab, true)}
                onMetricsChange={onMetricsChange}
              />
            </div>
          )}
        />
      </div>
      <DataTable
        account={account}
        tabKey={tableData.tabKey}
        data={tableData.data}
        totalData={tableData.total}
        metrics={metrics}
        pagination={pagination}
        onPageChange={onPageChange}
        onSorted={onSorted}
        tableSorted={tableSorted}
      />
      {tableLoading && <Loading content="Just a second, loading data..." />}
    </div>
  );
};

export default forwardRef(DataBreakdown);
