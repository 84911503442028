import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop, isEqual } from 'lodash';
import useUpdatedEffect from '../../../../lib/hooks/useUpdatedEffect';
import DropdownMenu from '../../Dropdown/DropdownMenu/DropdownMenu';
import Filter from '../../Filter/Filter';
import './combinedFilters.scss';

const prefixCls = 'v2_component_filters_combined';

function CombinedFilters({
  dropdownFixed,
  filters,
  defaultCheckedFilters,
  checkedFilters: propsCheckedFilters,
  renderLeft,
  onChange,
  onFilterListLengthChange,
}) {
  const [addVisible, setAddVisible] = useState(false);
  const [visibleFilters, setVisibleFilters] = useState({});
  const [checkedFilters, setCheckedFilters] = useState(defaultCheckedFilters);
  const addableFilters = filters.filter(({ key }) => !checkedFilters[key]);

  const onVisibleChange = useCallback((filterKey, filterVisible) => {
    setVisibleFilters((prevVisible) => ({
      ...prevVisible,
      [filterKey]: filterVisible,
    }));
  }, []);

  const onAddFilter = useCallback((item) => {
    setVisibleFilters((prevVisible) => ({
      ...prevVisible,
      [item.key]: true,
    }));
    setCheckedFilters((prevChecked) => ({
      ...prevChecked,
      [item.key]: [],
    }));
  }, []);

  const onRemoveFilter = useCallback((filterKey) => {
    const restFilters = Object.keys(checkedFilters).reduce(
      (params, key) => (key === filterKey ? params : { ...params, [key]: checkedFilters[key] }),
      {},
    );
    setCheckedFilters(restFilters);
  }, [checkedFilters]);

  const onFilterChange = useCallback((filterKey, filterData) => {
    setCheckedFilters((prevChecked) => ({
      ...prevChecked,
      [filterKey]: filterData,
    }));
  }, []);

  const renderFilters = () => Object.keys(checkedFilters).map((key) => {
    const currentFilter = filters.find((i) => i.key === key);
    if (!currentFilter) return null;
    const {
      Component, subType = '', options, searchParams, config,
    } = currentFilter;
    return React.createElement(Component, {
      key,
      subType,
      dropdownFixed,
      options,
      searchParams,
      config,
      onVisibleChange: (v) => onVisibleChange(key, v),
      onChange: (d) => onFilterChange(key, d),
      onRemove: () => onRemoveFilter(key),
      visible: visibleFilters[key] || false,
      defaultCheckedNodes: checkedFilters[key],
    });
  });

  useUpdatedEffect(() => {
    const filtersLength = Object.keys(checkedFilters).length;
    onChange(checkedFilters);
    onFilterListLengthChange(filtersLength);
  }, [onChange, checkedFilters, onFilterListLengthChange]);

  useUpdatedEffect(() => {
    if (propsCheckedFilters && !isEqual(propsCheckedFilters, checkedFilters)) {
      setCheckedFilters(propsCheckedFilters);
    }
  }, [propsCheckedFilters]);

  return (
    <div className={`${prefixCls}`}>
      {renderLeft && renderLeft()}
      {renderFilters()}
      {
        addableFilters.length > 0
        && (
        <DropdownMenu
          fixed={dropdownFixed}
          data={addableFilters}
          labelKey="name"
          onVisibleChange={setAddVisible}
          onChange={onAddFilter}
          transitionOffset={-1}
        >
          <Filter type="basic" className={`${prefixCls}-add`} open={addVisible}>
            <i className="material-icons">add</i>
            Filter
          </Filter>
        </DropdownMenu>
        )
      }
    </div>
  );
}

CombinedFilters.propTypes = {
  dropdownFixed: PropTypes.bool,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      Component: PropTypes.elementType.isRequired,
    }),
  ).isRequired,
  renderLeft: PropTypes.func,
  defaultCheckedFilters: PropTypes.objectOf(PropTypes.any),
  checkedFilters: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
  onFilterListLengthChange: PropTypes.func,
};

CombinedFilters.defaultProps = {
  dropdownFixed: false,
  defaultCheckedFilters: {},
  checkedFilters: null,
  renderLeft: noop,
  onChange: noop,
  onFilterListLengthChange: noop,
};

export default React.memo(CombinedFilters);
