import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ProgressBar from '../Upload/Component/ProgressBar';
import './topLoadingBar.scss';

const TopLoadingBar = ({
  prefixCls,
  className,
  height,
  front,
  completeBackground,
  background,
  start,
  complete,
  step,
  pendingStep,
  waitingProgress,
}) => {
  const [show, setShow] = React.useState(false);
  const [mask, setMask] = React.useState(false);

  React.useEffect(() => {
    if (start) {
      setMask(true);
    }
    setShow(start);
  }, [start]);

  React.useEffect(() => {
    if (complete) {
      setMask(false);
    }
  }, [complete]);

  const onTransitionEnd = React.useCallback(() => {
    if (!mask) {
      setShow(false);
    }
  }, [mask]);

  if (!show) {
    return null;
  }

  return (
    <div className={classnames(prefixCls, { [`${prefixCls}-mask`]: mask }, className)}>
      <ProgressBar
        height={height}
        front={front}
        completeBackground={completeBackground}
        background={background}
        start={start}
        complete={complete}
        step={step}
        pendingStep={pendingStep}
        waitingProgress={waitingProgress}
        onTransitionEnd={onTransitionEnd}
      />
    </div>
  );
};

TopLoadingBar.propTypes = {
  prefixCls: PropTypes.string,
  className: PropTypes.string,
  /**
   * height of the bar
   */
  height: PropTypes.number,
  /**
   * front color
   */
  front: PropTypes.string,
  /**
   * background color when complete
   */
  completeBackground: PropTypes.string,
  /**
   * background color
   */
  background: PropTypes.string,
  /**
   * is start to show top loading bar or not
   */
  start: PropTypes.bool,
  /**
   * is loading complete or not
   */
  complete: PropTypes.bool,
  /**
   * step for progress
   */
  step: PropTypes.number,
  /**
   * step for before progress stop
   */
  pendingStep: PropTypes.number,
  /**
   * progress for waiting
   */
  waitingProgress: PropTypes.number,
};

TopLoadingBar.defaultProps = {
  prefixCls: 'v2_component_top_loading_bar',
  className: '',
  height: 4,
  front: '#555FFF',
  completeBackground: null,
  background: '#d2f5f5',
  start: false,
  complete: false,
  step: 5,
  pendingStep: 0.05,
  waitingProgress: 95,
};

export default React.memo(TopLoadingBar);
