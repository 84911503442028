import React from 'react';

const StatusGlobe = ({ status }) => {
  switch (status) {
    case 'active':
      return <div className="status_dot status_dot--serving" />;
    case 'paused':
      return <div className="status_dot status_dot--not_serving" />;
    default:
      return <div className="pill pill--processing"><p className="p6">Pending</p></div>;
  }
};

const Checkbox = ({
  disabled = false,
  status,
  faded = false,
  id,
  creativeId,
  actions = [],
  label,
  listCreatives = false,
  inputPosition = 'relative',
  showLabel = true,
  some = false,
  checked = false,
  onChange = () => {},
  disabledTooltip = () => {},
}) => (
  <div className={`component__form__checkbox ${faded && 'component__form__checkbox--faded'} ${disabled && 'disabled'}`}>
    {actions.map(({ onClick = () => {}, icon }) => (
      <i key={icon} className="material-icons" onClick={onClick} style={{ maxWidth: 24 }}>{icon}</i>
    ))}
    <label htmlFor={id}>
      <input
        disabled={disabled}
        type="checkbox"
        id={id}
        checked={checked}
        onChange={(e) => onChange(e.target.checked, e)}
        style={{ position: inputPosition }}
      />
      {some && (
        <span className="active">
          <i className="material-icons">indeterminate_check_box</i>
        </span>
      )}
      {!some && checked && (
        <span className="active">
          <i className="material-icons">check_box</i>
        </span>
      )}
      {!some && !checked && disabled && (
        <span className="inactive-disabled">
          <i className="material-icons blank">check_box_outline_blank</i>
          { !disabledTooltip && (<i className="material-icons blank">block</i>) }
          { disabledTooltip && (disabledTooltip(<i className="material-icons block">block</i>)) }
        </span>
      )}
      {!some && !checked && !disabled && (
        <span className="inactive">
          <i className="material-icons">check_box_outline_blank</i>
        </span>
      )}
      {showLabel && label && !listCreatives && (
        <p className={`label ${!checked && 'inactive'}`}>{label}</p>
      )}
      {showLabel && label && listCreatives && (
        <div className="listCreative">
          <StatusGlobe status={status} />
          <a href={`/creatives/${creativeId}`}>
            <p className={`label ${!checked}`}>{label}</p>
          </a>
        </div>
      )}
    </label>
  </div>
);

export default Checkbox;
