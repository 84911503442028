import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import MenuContext, { MenuContextTypes } from './context';

function InnerMenuDivider({ context, color }) {
  const { prefixCls: contextPrefixCls } = context;

  const prefixCls = `${contextPrefixCls}-divider`;

  return (
    <div className={classnames(prefixCls)} style={{ backgroundColor: color }} />
  );
}

InnerMenuDivider.context = MenuContextTypes;

InnerMenuDivider.propTypes = {
  /**
   * line color
   */
  color: PropTypes.string,
};

InnerMenuDivider.defaultProps = {
  color: '#ecf0f4',
};

const MenuDivider = (props) => (
  <MenuContext.Consumer>
    {(context) => <InnerMenuDivider {...props} context={context} />}
  </MenuContext.Consumer>
);

MenuDivider.displayName = 'MenuDivider';

export default MenuDivider;
