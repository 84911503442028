import { get as getValue } from 'lodash';
import moment from 'moment';
import imgIos from 'assets/img/ios.png';
import imgAndroid from 'assets/img/android.png';
import imgWindows from 'assets/img/windows.png';
import imgAmazon from 'assets/img/amazon.png';
import {
  createApplication,
  getApplication,
  getApplicationForReporting,
  getApplications,
  getPostInstallEvents,
  getPostInstallEventCountryStats,
  getPubApps,
  getReportingPubApps,
  getApplicationsWithCampaign,
  getApplicationsWithCreative,
} from '../app/graphql/utils/application';
import { get } from '../lib/http';
import config from '../lib/config';
import Model from './BaseModel';

const PLATFORM_ANDROID = 'android';
const PLATFORM_IOS = 'ios';
const PLATFORM_WINDOWS = 'windows';
const PLATFORM_AMAZON = 'amazon';
const POST_INSTALL_STATS_START = 44;
const POST_INSTALL_STATS_END = 8;

const defaultIcon = {
  [PLATFORM_ANDROID]: imgAndroid,
  [PLATFORM_IOS]: imgIos,
  [PLATFORM_WINDOWS]: imgWindows,
  [PLATFORM_AMAZON]: imgAmazon,
};

class Application extends Model {
  constructor(attrs) {
    super(attrs);

    const platform = this.raw('platform');
    this.platformString = this.raw('platform') ? defaultIcon[platform] : imgIos;
    this.default('store.thumbnail', this.platformString, [null, '']);
    this.default('mmpIntegrationStatus', attrs?.mmpIntegrationStatus);
  }

  isValidForCampaignCreation() {
    return this.raw('store.thumbnail') !== this.platformString && !!this.raw('store.url');
  }

  isSKAdNetworkCapable = () => {
    const isCapable = this.get('skadnetwork_settings.is_capable');

    if (typeof isCapable === 'boolean') {
      return isCapable;
    }

    const platform = this.get('platform');

    return !!platform && platform.toLowerCase() === 'ios';
  }

  isSKAdNetworkEnabled = () => this.get('skadnetwork_settings.is_enabled');

  isSKAdNetworkSettingChangeable = () => this.get('skadnetwork_settings.is_changeable');

  isRedownloadIncluded = () => this.get('skadnetwork_settings.is_redownload_billed');

  skAdNetworkPostbackDestinationOptions = () => config.get('applications.skAdNetworkPostbackDestinationOptions');

  skAdNetworkPostbackDestination = () => this.get('skadnetwork_settings.skadnetwork_postback_destination');

  canAttachCampaigns = () => !this.isSKAdNetworkEnabled();

  async getRemainingSkcids() {
    const result = await Application.get(this.get('id'));
    const skadNetworkSettings = result.response.skadnetwork_settings;
    if (skadNetworkSettings && skadNetworkSettings.usage) {
      return getValue(skadNetworkSettings, 'usage.remaining', 0);
    }
    return 0;
  }

  async getSkadnetworkSettings() {
    const result = await Application.get(this.get('id'));
    return result.response.skadnetwork_settings;
  }
}

const baseUrl = `${config.get('manageUrl')}applications`;

Application.get = (id) => getApplication(id);
Application.getApplicationForReporting = (id) => getApplicationForReporting(id);
Application.getAll = (filters) => getApplications({ ...{ page: 1, perPage: 10, type: ['advertiser', 'both'] }, ...filters });

Application.getAllWithFilter = (queryString) => get(`${baseUrl}?${queryString}`);

Application.getPubs = (filters) => getPubApps({ ...{ page: 1, perPage: 50 }, ...filters });

Application.getAllPubs = (filters) => getPubApps({ ...{ page: 1 }, ...filters })
  .then((result) => ({
    ...result,
    response: result.response.map((res) => ({
      ...res,
      account: res.account.id,
    })),
  }));

Application.getReportPubs = (query) => getReportingPubApps(query);

Application.getPostInstallEvents = (applicationId, start = POST_INSTALL_STATS_START, end = POST_INSTALL_STATS_END) => getPostInstallEvents({
  applicationId,
  startDate: moment().subtract(start, 'days'),
  endDate: moment().subtract(end, 'days'),
});

Application.getPostInstallEventCountryStats = (
  applicationId,
  eventName,
  start = POST_INSTALL_STATS_START,
  end = POST_INSTALL_STATS_END,
) => getPostInstallEventCountryStats({
  applicationId,
  eventName,
  startDate: moment().subtract(start, 'days'),
  endDate: moment().subtract(end, 'days'),
});

Application.make = Model.make(Application);

Application.create = (platform, id) => createApplication({
  platform,
  store: { id: id.toString() },
  skadnetwork_settings: {},
  settings: {
    click_look_back_period: {},
    view_look_back_period: {},
  },
  targeting: {
    tags: { type: 'allow', tags: [{ value: 'managed' }] },
    publisher: {},
  },
  surge_override_settings: {},
});
Application.getCampaigns = (filters) => getApplicationsWithCampaign(filters);
Application.getCreatives = (filters) => getApplicationsWithCreative(filters);

export default Application;
