import React, {
  compose, namespace, param, Redirect,
} from '../../../../../app/app';
import Creative from '../../../../../models/Creative';
import CreativesEdit from './CreativesEdit';

const ns = 'views.creatives.edit';
const updateState = namespace(ns);
const props = (state) => state[ns];

class CreativesEditContainer extends React.Component {
  constructor(p) {
    super(p);
    this.state = {};
  }

  componentDidMount() {
    const createOrId = param(this.props, 'edit');
    if (createOrId === 'create') {
      this.props.actions.setupCreateView();
      return;
    }
    // editing
    this.props.actions.setupEditView({ id: createOrId });
  }

  componentWillUnmount() {
    this.props.actions.destroyView();
  }

  render() {
    const {
      creative, actions, location, redirect,
    } = this.props;

    if (redirect) {
      return <Redirect to="/creatives" />;
    }
    if (!creative) {
      return null;
    }
    if (!creative.get('application') && (location.pathname !== '/creatives/create' && location.pathname !== '/creatives/create/application')) {
      return <Redirect to="/creatives/create" />;
    }
    creative.onChange(actions.creativeChangeAction);
    return (
      <CreativesEdit {...this.props} setupAddAnotherView={this.props.actions.setupAddAnotherView} />
    );
  }
}

const setupCreateView = ({ state }) => updateState(state, {
  editing: false,
  creative: (state[ns] && state[ns].creative) || new Creative({
    account: { id: state.authUser.account },
  }),
});

const setupEditView = (() => {
  const action = ({ id }) => Creative.get(id);
  const end = ({ state, result }) => {
    if (!result.ok) {
      // not a valid URL
      return updateState(state, { redirect: true });
    }
    return updateState(state, { editing: true, changingValue: false, creative: new Creative(result.response) });
  };
  return { action, end };
})();

const creativeChangeAction = ({ state, values }) => updateState(state, { changingValue: state[ns] && state[ns].editing, creative: values });
const destroyView = ({ state }) => updateState(state, {
  creative: null,
  editing: false,
  redirect: false,
  changingValue: false,
});
const updateCreative = ({ state, values }) => updateState(state, { creative: values });

const actions = {
  creativeChangeAction,
  destroyView,
  setupCreateView,
  setupEditView,
  updateCreative,
};

export default compose(CreativesEditContainer, { actions, props });
