import React from 'react';
import IOSSelector from './IOSSelector';
import DeviceInfo from '../../models/DeviceInfo';
import './selector.scss';

class IOSSelectorContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: '',
      models: [],
    };
  }

  componentDidMount() {
    DeviceInfo.getIOSDevices()
      .then((models) => this.setState({ models }));
  }

  onChangeSearch = (searchValue) => {
    this.setState({ searchValue });
  }

  render() {
    return (
      <IOSSelector
        {...this.props}
        onChangeSearch={this.onChangeSearch}
        searchValue={this.state.searchValue}
        models={this.state.models}
      />
    );
  }
}

export default IOSSelectorContainer;
