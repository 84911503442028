import React from 'react';
import cn from 'classnames';
import './textarea.scss';

const TextArea = ({ value = '', onChange = () => {}, ...props }) => (
  <div
    className={cn({
      components__form__textarea: true,
      disabled: props.disabled,
    })}
  >
    <textarea
      className="p2"
      onChange={(e) => onChange(e.target.value, e)}
      value={value}
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="false"
      {...props}
    />
  </div>
);

export default TextArea;
