import { applyMiddleware, compose, createStore } from 'redux';

let storeWithMiddleWare;

const reducer = (state = {}, action = {}) => {
  const p = { result: action.result, state, props: action.props };
  const make = (name) => ({ ...state, ...action[name](p) });

  if (action.type.endsWith('ACTION') || action.type.endsWith('ACTION_FULFILLED')) {
    return make('end');
  }

  if (action.type.endsWith('ACTION_PENDING')) {
    return make('start');
  }

  if (action.type.endsWith('ACTION_REJECTED')) {
    return make('error');
  }

  return state;
};

function createAppStore(initState, logger) {
  if (storeWithMiddleWare) {
    return storeWithMiddleWare;
  }
  const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;
  storeWithMiddleWare = createStore(reducer, initState, composeEnhancers(applyMiddleware(logger)));
  return storeWithMiddleWare;
}

function getStore() {
  return storeWithMiddleWare;
}

function clearStore() {
  storeWithMiddleWare = undefined;
}

export {
  createAppStore,
  getStore,
  clearStore,
};
