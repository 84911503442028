import React from 'react';
import { isCampaignActivationDisabled } from 'services/admin/views/campaigns/shared';
import { dispatchAction } from 'app/app';
import { parseCurrency } from 'lib/currency';
import { isAfter } from 'lib/date';
import { getBidLimits } from 'lib/helpers/campaigns/campaignIndex';
import { isInteger, isFalsey } from 'lib/lib';
import { validateMacroURL } from 'services/Templates/Campaigns/MMP/helper';
import { BUDGET_OBJECTIVE_CPE, BUDGET_OBJECTIVE_CPPU, BUDGET_OBJECTIVE_ROAS } from 'app/constants/campaign';
import { archiveResource } from '../../../lib/helpers/index';
import config from '../../../lib/config';
import triggerConfirm from '../../Modals/ConfirmAction/triggerConfirm';
import triggerAlertModal from '../../Modals/Alert/triggerModal';
import ContextMenu from '../../ContextMenu/ContextMenuContainer';
import { LONG_TAIL_ERROR_MESSAGE } from '../../V2/Collapse/ErrorsCollapse/messages';
import { canModifyCampaigns } from '../../../lib/helpers/authUser';
import triggerSkanAlert from '../../Modals/SkanAlert/triggerSkanAlert';

const {
  clickEvent,
  impressionEvent,
  additionalEvents,
  skadnetworkMinOsVersion,
  cvrMultiplierCap,
} = config.get('campaigns');

const fieldDescriptions = {
  'budget.daily': 'Daily Budget',
  'budget.budget_multiplier': 'Budget Multiplier is invalid',
  category: '“Allow” or “Deny” without any categories selected is not allowed',
  carrier: '“Allow” or “Deny” without any carriers selected is not allowed',
  targeting: 'Country, Region or City Geo Targeting',
  'tracking.events': 'Tracking events are invalid',
  name: 'Campaign name must be at least 4 characters long',
  dates: 'Must be valid date and times, and end date must be at least 24 hours after the start date',
  'budget.bid': 'Bid rate is either above or below the bid rate range',
  totalSpend: 'Budget Total Spend is either above or below the spend range',
  'targeting.msrp': 'Device Retail Price minimum must be a whole number less than or equal to the maximum',
  'settings.total_view_limit': 'Total view limit must be an integer',
  'settings.daily_view_limit': 'Daily view limit must be an integer',
  // dailySpend: 'Daily spend is either above or below daily spend range',
  'settings.erpm_override': 'CVR Multiplier value is invalid',
};

export const roasTargetErrorMessage = 'Target ROAS is either above or below the range';
export const cppuTargetErrorMessage = 'Target CPPU is either above or below the range';
export const cpeTargetErrorMessage = 'Target CPE is either above or below the range';
export const roasTargetOverrideErrorMessage = 'Target ROAS Override is either above or below the range';
export const cppuTargetOverrideErrorMessage = 'Target CPPU Override is either above or below the range';
export const cpeTargetOverrideErrorMessage = 'Target CPE Override is either above or below the range';
export const maxBidErrorMessage = 'Maximum CPI Bid must be greater than or equal to the minimum';
export const defaultBidErrorMessage = 'Starting CPI Bid is either above or below the range';
export const targetSubsidizationMultiplierErrorMessage = 'Campaign Subsidization is either above or below the range';
export const cpeTargetEventNameErrorMessage = 'Target Conversion Event Name is required for CPE campaign';

export const changeCampaignArchiveStatus = (campaign) => {
  const isArchived = campaign.get('is_deleted');
  const [text, msg] = !isArchived
    ? ['Delete Campaign', 'This action will remove this Campaign from view and stop it from serving']
    : ['Restore Campaign', 'This action may enable the Campaign to serve again'];
  triggerConfirm({
    type: isArchived ? 'RESTORE_CAMPAIGN_CONFIRM_ACTION' : 'DELETE_CAMPAIGN_CONFIRM_ACTION',
    header: text,
    message: msg,
    name: campaign.get('name'),
    onConfirm: async () => {
      const { error, skanError } = await archiveResource(campaign, 'campaign');
      if (skanError) {
        triggerSkanAlert();
      }
      if (!error) {
        const actionType = isArchived ? 'RESTORE_CAMPAIGN_CONFIRM_ACTION_FULFILLED' : 'DELETE_CAMPAIGN_CONFIRM_ACTION_FULFILLED';
        dispatchAction(actionType, () => ({ 'views.campaigns.list': { refreshList: Math.random() } }));
      }
    },
  });
};

const makeArchive = (campaign) => {
  const isArchived = campaign.get('is_deleted');
  return (
    <ContextMenu.ClickOption
      text={isArchived ? 'Restore Campaign' : 'Delete Campaign'}
      onClick={() => changeCampaignArchiveStatus(campaign)}
    />
  );
};

const makePauseActivate = (campaign, changeServingStatus) => {
  const [text, status, msg] = campaign.get('status') === 'paused'
    ? ['Activate Campaign', 'activate', 'Are you sure you want to activate this Campaign?']
    : ['Pause Campaign', 'pause', 'Are you sure you want to stop this Campaign from serving?'];
  let buttonDisabled = false;
  if (isCampaignActivationDisabled(campaign)) {
    buttonDisabled = true;
  }

  return (
    <ContextMenu.ClickOption
      text={text}
      disabled={buttonDisabled}
      onClick={() => {
        if (!buttonDisabled) {
          triggerConfirm({
            type: 'CHANGE_CAMPAIGN_SERVING_CONFIRM_ACTION',
            header: text,
            message: msg,
            name: campaign.get('name'),
            onConfirm: () => changeServingStatus({ campaign, status }),
          });
        }
      }}
    />
  );
};

export const validateNewCampaign = async (campaign, saveNewCampaign) => {
  const invalidFields = [];
  const missingFields = [];
  let validated = true;

  const applicationId = campaign.get('application').get('id');
  if (!applicationId) {
    triggerAlertModal({
      title: 'Please select an application for it',
      alertText: 'Required: application',
    });
    return;
  }

  const nameIsValid = !!campaign.get('name') && campaign.get('name').length >= 4;

  const endIsValid = campaign.get('dates.is_indefinite') || (
    isAfter(campaign.get('dates.start'), campaign.get('dates.end'))
  );

  if (!endIsValid) {
    validated = false;
    invalidFields.push('dates');
  }

  if (!nameIsValid) {
    validated = false;
    invalidFields.push('name');
  }

  if (campaign.isBudgetStatic()) {
    const { max, min } = getBidLimits(campaign);
    const bid = parseCurrency(campaign.get('budget.bid'));
    if (bid > max || bid < min) {
      validated = false;
      invalidFields.push('budget.bid');
    } else {
      const valid = campaign.isValidLongTailBid();
      if (!valid) {
        const geos = campaign.getLongTailGeos();
        validated = false;
        missingFields.push(`budget.bid: ${LONG_TAIL_ERROR_MESSAGE.invalidLongTailGeos} (${geos.map((geo) => geo.code).join(', ')})`);
      }
    }
  }

  // validate dynamic IAP fields, but don't block saving on MMP Integration error
  let dynamicCampaignValidationResult;

  switch (campaign.get('budget.objective.type')) {
    case BUDGET_OBJECTIVE_ROAS:
      dynamicCampaignValidationResult = campaign.validateBudgetRoasIap();
      break;
    case BUDGET_OBJECTIVE_CPPU:
      dynamicCampaignValidationResult = campaign.validateBudgetCPPUIap();
      break;
    case BUDGET_OBJECTIVE_CPE:
      dynamicCampaignValidationResult = campaign.validateBudgetCPEIap();
      break;
    default:
      dynamicCampaignValidationResult = {};
  }

  const {
    bidDefault,
    bidMax,
    targetReturn,
    targetOverride,
    targetSubsidizationMultiplier,
    cpeTargetEventName,
  } = dynamicCampaignValidationResult || {};

  if (targetReturn) {
    validated = false;
    switch (campaign.get('budget.objective.type')) {
      case BUDGET_OBJECTIVE_ROAS:
        missingFields.push(roasTargetErrorMessage);
        break;
      case BUDGET_OBJECTIVE_CPPU:
        missingFields.push(cppuTargetErrorMessage);
        break;
      case BUDGET_OBJECTIVE_CPE:
        missingFields.push(cpeTargetErrorMessage);
        break;
      default:
        break;
    }
  }

  if (targetOverride) {
    validated = false;
    switch (campaign.get('budget.objective.type')) {
      case BUDGET_OBJECTIVE_ROAS:
        missingFields.push(roasTargetOverrideErrorMessage);
        break;
      case BUDGET_OBJECTIVE_CPPU:
        missingFields.push(cppuTargetOverrideErrorMessage);
        break;
      case BUDGET_OBJECTIVE_CPE:
        missingFields.push(cpeTargetOverrideErrorMessage);
        break;
      default:
        break;
    }
  }

  if (targetSubsidizationMultiplier) {
    validated = false;
    missingFields.push(targetSubsidizationMultiplierErrorMessage);
  }

  if (cpeTargetEventName) {
    validated = false;
    missingFields.push(cpeTargetEventNameErrorMessage);
  }

  if (bidMax) {
    validated = false;
    missingFields.push(maxBidErrorMessage);
  }

  if (bidDefault) {
    validated = false;
    missingFields.push(defaultBidErrorMessage);
  }

  const totalMin = campaign.get('account.accountConfig').campaigns.budget.total.min;
  const totalMax = campaign.get('account.accountConfig').campaigns.budget.total.max;
  const budgetTotal = parseCurrency(campaign.get('budget.total'));
  if (budgetTotal < totalMin || budgetTotal > totalMax) {
    validated = false;
    invalidFields.push('totalSpend');
  }

  if (
    (campaign.get('targeting.geo.region') === 'country' && campaign.get('targeting.geo.countries').length === 0)
    || (campaign.get('targeting.geo.region') === 'city' && campaign.get('targeting.geo.cities').length === 0)
    || (campaign.get('targeting.geo.region') === 'subdivision' && campaign.get('targeting.geo.subdivisions').length === 0)
  ) {
    validated = false;
    invalidFields.push('targeting');
  }

  if (
    campaign.get('settings.carrier_targeting') === true
    && ((campaign.get('targeting.carrier.carrierAllowList').length !== 0 && campaign.get('targeting.carrier.carrierDenyList').length !== 0)
    || (campaign.get('targeting.carrier.carrierAllowList').length === 0 && campaign.get('targeting.carrier.carrierDenyList').length === 0))
  ) {
    validated = false;
    invalidFields.push('carrier');
  }

  if (campaign.get('targeting.genre.genreList').length === 0 && campaign.get('targeting.genre.mode') !== 'none') {
    validated = false;
    invalidFields.push('category');
  }

  const value = campaign.get('budget.budget_multiplier.override');
  const multiplierEnabled = campaign.get('budget.budget_multiplier.is_enabled');
  if (multiplierEnabled && (Number.isNaN(value) || value <= 0 || value > config.get('globalSettings.budgetMultiplierCap'))) {
    validated = false;
    invalidFields.push('budget.budget_multiplier');
  }

  let campaignDailyBudget;
  if (campaign.get('budget.daily_spend_limit_type') === 'geo') {
    const limitGeos = campaign.get('budget.daily_spend_limit_geos');
    campaignDailyBudget = `$${limitGeos.reduce((prev, next) => prev + Number(next.rate), 0)}`;
  } else {
    campaignDailyBudget = parseCurrency(campaign.get('budget.daily'));
  }
  const campaignDailyMin = Number(campaign.get('account.accountConfig').campaigns.budget.daily.min);
  // const campaignDailyMax = Number(campaign.get('account.accountConfig').campaigns.budget.daily.max);
  if (campaignDailyMin !== 0 && !campaignDailyBudget) {
    validated = false;
    invalidFields.push('budget.daily');
  }

  // need to confirm if product wants this conditional
  // if (campaignDailyBudget > campaignDailyMax || campaignDailyBudget < campaignDailyMin) {
  //   validated = false;
  //   invalidFields.push('dailySpend');
  // }
  const msrpMin = campaign.get('targeting.msrp.min');
  const msrpMax = campaign.get('targeting.msrp.max');
  const msrpIntegers = isInteger(msrpMin) && isInteger(msrpMax);
  const msrpRangeValid = Number(msrpMin) <= Number(msrpMax);
  if (!isFalsey(msrpMin) && !isFalsey(msrpMax)) {
    if (!msrpRangeValid || !msrpIntegers) {
      validated = false;
      invalidFields.push('targeting.msrp');
    }
  }

  if (!campaign.validateTotalViewLimit()) {
    validated = false;
    invalidFields.push('settings.total_view_limit');
  }
  if (!campaign.validateDailyViewLimit()) {
    validated = false;
    invalidFields.push('settings.daily_view_limit');
  }

  if (campaign.isSKAdNetworkEnabled()) {
    const skadOsEnabled = campaign.get('targeting.versions.application.is_enabled');
    const skadOsMin = campaign.get('targeting.versions.application.min');
    if (skadOsEnabled && (skadOsMin < skadnetworkMinOsVersion && skadOsMin !== '')) {
      validated = false;
      missingFields.push('SKAdNetwork Campaign OS version min is invalid');
    }
    if (campaign.get('attribution_method') === 'skadnetwork' && campaign.get('is_ab_testing')) {
      if (!campaign.isValidSKANABTestingSetA()) {
        validated = false;
        missingFields.push('You must complete your Control Set A in order to run an A/B test');
      }
      if (!campaign.completesSKANABTestingSetB()) {
        validated = false;
        missingFields.push('You must complete your Test Set B in order to run an A/B test');
      }
      if (!campaign.isValidSKANABTestingSetB()) {
        validated = false;
        missingFields.push('Test Set B is identical to Control Set A. You must replace at least one creative in order to run an A/B test');
      }
    }
  }

  const publisherTargetingMessage = campaign.get('targeting.publisher.errorMessage');
  if (publisherTargetingMessage) {
    validated = false;
  }

  const accountTargetingMessage = campaign.get('targeting.account.errorMessage');
  if (accountTargetingMessage) {
    validated = false;
  }

  const multiBiddingErrorMessage = campaign.get('multibidding.errorMessage');
  if (multiBiddingErrorMessage) {
    invalidFields.push('tracking.events');
    validated = false;
  }

  const cvrMultiplierOverrideValue = campaign.get('settings.erpm_override');
  const cvrMultiplierEnabled = campaign.get('settings.erpm_override_enabled');
  if (cvrMultiplierEnabled
    && (Number.isNaN(cvrMultiplierOverrideValue) || cvrMultiplierOverrideValue <= 0 || cvrMultiplierOverrideValue > cvrMultiplierCap)) {
    validated = false;
    invalidFields.push('settings.erpm_override');
  }

  let trackingEventsError = '';
  const defaultEvents = [clickEvent, impressionEvent, ...additionalEvents];
  const platform = campaign.get('application').get('platform');
  const events = campaign.get('tracking.events');
  const toValidEvents = events.filter((a) => !!a.url && defaultEvents.findIndex((b) => a.name === b.name) !== -1);
  const isURLError = toValidEvents.some((x) => Object.keys(validateMacroURL(x.url, platform)).length);
  if (isURLError) {
    trackingEventsError = 'There are issues with the field(s) Click URL, Impression URL, Additional Events.';
    validated = false;
  }

  if (!validated) {
    missingFields.push(...invalidFields.map((invalidField) => fieldDescriptions[invalidField]));
    if (multiBiddingErrorMessage) {
      missingFields.push(`Multi Bidding: ${multiBiddingErrorMessage}`);
    }
    if (accountTargetingMessage) {
      missingFields.push(`Accounts Targeting: ${accountTargetingMessage}`);
    }
    if (publisherTargetingMessage) {
      missingFields.push(`Publisher Targeting: ${publisherTargetingMessage}`);
    }

    if (trackingEventsError) {
      missingFields.push(`Tracking Events: ${trackingEventsError}`);
    }
    triggerAlertModal({
      title: 'Please fill out all required fields',
      alertText: `Required: ${missingFields.join(';')}`,
    });
    return;
  }
  saveNewCampaign(campaign);
};

const CampaignContextMenu = ({
  campaign,
  changeServingStatus,
  changeStatus,
  edit = false,
  historyView = false,
  list = false,
  newCampaign = false,
  review = false,
  router,
  saveCampaign,
  saveNewCampaign,
  editUrl = `/campaigns/${campaign.get('id')}`,
  showArchive = true,
  showEdit = true,
  showHistory = true,
  showReview = true,
  showClone = false,
  onClone,
  isMissionControl,
}) => {
  const isArchiveDisplay = showArchive && (list || edit) && campaign.get('id');
  const isReviewDisplay = showReview && campaign.get('id') && !review && campaign.hasPendingChanges();
  const isHistoryDisplay = showHistory && !historyView && campaign.get('id');
  const userCanModifyCampaign = canModifyCampaigns();

  return (
    <ContextMenu>
      {campaign && (
        <>
          {userCanModifyCampaign && showReview && review && (
            <>
              <ContextMenu.ClickOption
                text="Approve Campaign"
                onClick={() => changeStatus({ model: campaign, status: 'approved', router })}
              />
              <ContextMenu.ClickOption
                text="Reject Campaign"
                onClick={() => changeStatus({ model: campaign, status: 'rejected', router })}
              />
              <hr />
            </>
          )}
          {userCanModifyCampaign && showClone && !edit && campaign.get('id') && (
            <ContextMenu.ClickOption
              text="Clone Campaign"
              onClick={() => onClone(campaign.get('id'))}
            />
          )}
          {showEdit && !edit && !newCampaign && (
            <ContextMenu.LinkOption
              to={editUrl}
              text="Edit Campaign"
            />
          )}
          {userCanModifyCampaign && list && changeServingStatus && makePauseActivate(campaign, changeServingStatus, isMissionControl)}
          {userCanModifyCampaign && edit && (
            <ContextMenu.ClickOption
              text="Save Campaign"
              onClick={() => validateNewCampaign(campaign, saveCampaign)}
            />
          )}
          {userCanModifyCampaign && newCampaign && (
            <ContextMenu.ClickOption
              text="Save New Campaign Campaign"
              onClick={() => validateNewCampaign(campaign, saveNewCampaign)}
            />
          )}
          {userCanModifyCampaign && isHistoryDisplay && (
            <ContextMenu.LinkOption
              to={`/campaigns/${campaign.get('id')}/history`}
              text="View Activity"
            />
          )}
          {userCanModifyCampaign && isReviewDisplay && (
            <ContextMenu.LinkOption
              to={`/campaigns/${campaign.get('id')}/review`}
              text="Review Campaign"
            />
          )}
          {userCanModifyCampaign && isArchiveDisplay && makeArchive(campaign)}
        </>
      )}
    </ContextMenu>
  );
};

export default CampaignContextMenu;
