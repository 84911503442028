import React, { Component } from 'react';
import { PREVIEW_SETTINGS } from './settings';
import Ad from './Ad';
import MraidAd from './MraidAd';
import NonMraidAd from './NonMraidAd';

const AD_FRAME_BORDER_RADIUS = '10px';
const AD_FRAME_TRANSITION = 'all 200ms ease-out';

export default class AdFrame extends Component {
  constructor(p) {
    super(p);

    this.ad = new Ad({ src: null, frame: null });

    this.state = {
      adWindowStyle: {
        width: `${this.props.screenWidth}px`,
        height: `${this.props.screenHeight}px`,
        borderRadius: AD_FRAME_BORDER_RADIUS,
        transition: AD_FRAME_TRANSITION,
      },
    };
  }

  switchOrientation = (orientation) => {
    if (!this.frame) {
      return;
    }

    this.scaleMaxArea();

    const isIn = Object.keys(PREVIEW_SETTINGS.ORIENTATIONS).filter((key) => {
      if (PREVIEW_SETTINGS.ORIENTATIONS[key] === orientation) {
        return key;
      }
      return null;
    })[0];

    if (!isIn) {
      throw new Error(`Wrong orientation passed ${orientation}`);
    }

    if (orientation === PREVIEW_SETTINGS.ORIENTATIONS.PORTRAIT) {
      this.togglePortrait();
      return;
    }

    this.toggleLandscape();
  }

  togglePortrait() {
    // we need to update the max and default width/height as well
    this.ad.togglePortrait();

    if (['fullscreen'].indexOf(this.props.placementType) !== -1) {
      this.moveAndSetContainerSizeAndPosition({
        height: this.props.adHeight,
        width: this.props.adWidth,
        x: this.props.adLeft,
        y: this.props.adTop,
      });

      this.moveAndSetMaxSizeAndPosition({
        height: this.props.adMaxHeight,
        width: this.props.adMaxWidth,
        x: this.props.adMaxLeft,
        y: this.props.adMaxTop,
      });
    }

    this.handleBannerCenter();

    this.setState({
      adWindowStyle: {
        width: `${this.props.screenWidth}px`,
        height: `${this.props.screenHeight}px`,
        borderRadius: AD_FRAME_BORDER_RADIUS,
        transition: AD_FRAME_TRANSITION,
      },
    });
  }

  toggleLandscape() {
    // we need to update the max and default width/height as well
    this.ad.toggleLandscape();

    if (['fullscreen'].indexOf(this.props.placementType) !== -1) {
      this.moveAndSetContainerSizeAndPosition({
        height: this.props.adWidth,
        width: this.props.adHeight,
        x: this.props.adLeft,
        y: this.props.adTop,
      });

      this.moveAndSetMaxSizeAndPosition({
        height: this.props.adMaxWidth,
        width: this.props.adMaxHeight,
        x: this.props.adMaxLeft,
        y: this.props.adMaxTop,
      });
    }

    this.handleBannerCenter();

    this.setState({
      adWindowStyle: {
        width: `${this.props.screenHeight}px`,
        height: `${this.props.screenWidth}px`,
        borderRadius: AD_FRAME_BORDER_RADIUS,
        transition: AD_FRAME_TRANSITION,
      },
    });
  }

  handleBannerCenter = () => {
    if (this.props.placementType.includes('banner')) {
      this.moveAndSetMaxSizeAndPosition({
        height: this.props.adMaxHeight,
        width: this.props.adMaxWidth,
        x: this.props.adMaxLeft,
        y: this.props.adMaxTop,
      });
    }
  }

  /**
   * Move adContainer's to position and set its width and height
   */
  moveAndSetMaxSizeAndPosition(position) {
    this.maxArea.style.left = `${position.x}px`;
    this.maxArea.style.top = `${position.y}px`;
    this.maxArea.style.width = `${position.width}px`;
    this.maxArea.style.height = `${position.height}px`;
  }

  /**
   * Move adContainer's to position and set its width and height
   */
  moveAndSetContainerSizeAndPosition(position) {
    this.adContainer.style.left = `${position.x}px`;
    this.adContainer.style.top = `${position.y}px`;
    this.adContainer.style.width = `${position.width}px`;
    this.adContainer.style.height = `${position.height}px`;
  }

  createMraidAd = (frame) => {
    this.ad = new MraidAd({
      src: this.props.assetURL,
      properties: {
        screenSize: {
          width: this.props.screenWidth,
          height: this.props.screenHeight,
        },
        maxSize: {
          width: this.props.adMaxWidth,
          height: this.props.adHeight,
        },
        defaultPosition: {
          x: this.props.adLeft,
          y: this.props.adTop,
          width: this.props.adWidth,
          height: this.props.adHeight,
        },
        currentPosition: {
          x: this.props.adLeft,
          y: this.props.adTop,
          width: this.props.adWidth,
          height: this.props.adHeight,
        },
        version: this.props.version,
        placementType: this.props.placementType, // inline | fullscreen,
        // offscreen: false,
        supports: this.props.supports,
      },
      frame: frame.contentWindow.document.getElementById('adFrame'),
    });

    return this.ad;
  }

  createNonMraidAd = () => {
    this.ad = new NonMraidAd({
      src: this.props.assetURL,
    });

    return this.ad;
  }

  overlayPrivacyButton = (adUrl) => ['vungle_local', 'vungle_short_form'].some((x) => adUrl.includes(x));

  writeDeviceHtml = (frame) => {
    if (!frame) {
      return;
    }

    const html = /*html*/`<html>
      <head>
        <style>
          body {
            margin: 0px;
            padding: 0px;
            background-color: #ececec;
            overflow: hidden;
          }

          #maxArea {
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
            outline: 1px solid #ececec;
          }

          #adContainer {
            position: absolute;
            padding: 0px;
            margin: 0px;
            background-color: transparent;
          }

          #adResizeContainer {
            overflow: hidden;
            padding: 0px;
            margin: 0px;
            height: 100%;
            /* padding: 100%; */
          }

          #closeEventRegion {
            height: 50px;
            width: 50px;
            display: none;
            text-decoration: none;
            position: absolute;
            top: 0;
            right: 0;
          }

          #adFrame {
            overflow: hidden;
            width: 100%;
            height: 100%;
            padding: 0px;
            margin: 0px;
            background-color: #00081C;
          }

          .hide {
            display: none;
          }

          #overlayPrivacy {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 45px;
            height: 45px;
            z-index: 2;
          }
        </style>
      </head>
      <body>
        <div id="maxArea">
          <div id="adContainer" data-isviewable="true">
            <div id="adResizeContainer">
              <a id="closeEventRegion"></a>
              <iframe
                allow="autoplay"
                id="adFrame"
                scrolling="no"
                frameBorder="0">
              </iframe>
              <div id="overlayPrivacy" class="hide">
            </div>
          </div>
        </div>
      </body>
    </html>`;

    const doc = frame.contentWindow.document;
    doc.open();
    doc.write(html);
    doc.close();
  }

  scaleMaxArea() {
    if (!this.frame) {
      return;
    }

    const maxArea = this.frame.contentWindow.document.getElementById('maxArea');
    maxArea.style.transform = '';
    maxArea.style.transformOrigin = '';

    const { scaler, orientation } = this.props;

    if (!scaler || !(orientation in scaler)) {
      return;
    }

    maxArea.style.transform = scaler[orientation].scale;
    maxArea.style.transformOrigin = '0 0';
  }

  positionAndSizeFrame() {
    if (!this.frame) {
      return;
    }

    this.maxArea = this.frame.contentWindow.document.getElementById('maxArea');
    this.adContainer = this.frame.contentWindow.document.getElementById('adContainer');
    this.adResizeContainer = this.frame.contentWindow.document.getElementById('adResizeContainer');

    this.moveAndSetMaxSizeAndPosition({
      height: this.props.adMaxHeight,
      width: this.props.adMaxWidth,
      x: this.props.adMaxLeft,
      y: this.props.adMaxTop,
    });

    this.moveAndSetContainerSizeAndPosition({
      height: this.props.adHeight,
      width: this.props.adWidth,
      x: this.props.adLeft,
      y: this.props.adTop,
    });
  }

  onDeviceLoad = (evt) => {
    this.frame = evt.target;

    this.positionAndSizeFrame();
    this.switchOrientation(this.props.orientation);

    if (!this.props.assetURL) {
      return;
    }

    this.loadAssetUrl();
  }

  loadAssetUrl() {
    if (!this.frame) {
      return;
    }

    const ad = this.props.version === PREVIEW_SETTINGS.VERSIONS.V2
      ? this.createMraidAd(this.frame) : this.createNonMraidAd(this.frame);

    const adFrame = this.frame.contentWindow.document.getElementById('adFrame');

    adFrame.src = ad.src;

    this.frame.contentWindow.document.getElementById('overlayPrivacy').style.display = this.overlayPrivacyButton(ad.src) ? 'block' : 'none';

    this.props.onLoadContent();
  }

  componentDidUpdate(prevProps) {
    if (this.props.shouldHideFrame && this.frame) {
      this.resetAdFrame();
      this.positionAndSizeFrame();
    }

    if (prevProps.assetURL !== this.props.assetURL && this.props.assetURL && !this.props.shouldHideFrame) {
      this.loadAssetUrl();
      this.positionAndSizeFrame();
      this.switchOrientation(this.props.orientation);
      return;
    }

    if (prevProps.otherAppOpened !== this.props.otherAppOpened) {
      this.ad.toggleView(!this.props.otherAppOpened);
    }

    if (prevProps.orientation !== this.props.orientation) {
      this.switchOrientation(this.props.orientation);
    }

    if (prevProps.device !== this.props.device) {
      this.positionAndSizeFrame();
      this.switchOrientation(this.props.orientation);
    }
  }

  resetAdFrame() {
    this.frame.contentWindow.document.getElementById('adFrame').src = '';
  }

  render() {
    return (
      <div className="preview-holder">
        <iframe
          title="adWindow"
          id="adWindow"
          allow="autoplay"
          scrolling="no"
          frameBorder="1"
          style={this.state.adWindowStyle}
          ref={this.writeDeviceHtml}
          onError={this.onDeviceError}
          onLoad={this.onDeviceLoad}
          data-creative={this.props.creativeId}
        />
      </div>
    );
  }
}
