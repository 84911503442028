import imgDynamicInter from 'assets/img/dynamic_interstitial.svg';
import imgSquareDynamic from 'assets/img/square_dynamic_interstitial.svg';
import imgShowcase from 'assets/img/showcase_nonbrand.svg';
import imgLooping from 'assets/img/looping.svg';
import imgCarousel from 'assets/img/carousel.svg';
import imgGradient from 'assets/img/gradient.svg';
import imgSwipe from 'assets/img/swipe.svg';

const templateFormats = {
  single_page_fullscreen: 'Single Page Fullscreen',
  multi_page_fullscreen: 'Multi Page Fullscreen',
  single_page_mrec: 'Single Page MREC',
  multi_page_mrec: 'Multi Page MREC',
  image_mrec: 'Image MREC',
  banner: 'Banner',
  native: 'Native',
};

const templateFormatFilterOptions = [
  { value: 'all', label: 'All Formats' },
  { value: 'single_page_fullscreen', label: 'Single Page Fullscreen' },
  { value: 'multi_page_fullscreen', label: 'Multi Page Fullscreen' },
  { value: 'single_page_mrec', label: 'Single Page MREC' },
  { value: 'multi_page_mrec', label: 'Multi Page MREC' },
  { value: 'image_mrec', label: 'Image MREC' },
  { value: 'banner', label: 'Banner' },
  { value: 'native', label: 'Native' },
];

const templateFormatPlacementTypeNameMap = {
  single_page_fullscreen: 'Full Screen',
  multi_page_fullscreen: 'Full Screen',
  single_page_mrec: 'MREC',
  multi_page_mrec: 'MREC',
  image_mrec: 'MREC',
  banner: 'Banner',
  native: 'Native',
};

export default [
  {
    id: '57eea58b0f747e8424000051',
    name: 'Dynamic Interstitial',
    image: imgDynamicInter,
  },
  {
    id: '58865198caedc66d7f0002c4',
    name: 'Square Dynamic Interstitial',
    image: imgSquareDynamic,
  },
  {
    id: '58c2f62c34f5e387180003fa',
    name: 'Showcase',
    image: imgShowcase,
    images: 5,
    required: 3,
  },
  {
    id: '57eea7983c5937912400002c',
    name: 'Looping',
    image: imgLooping,
  },
  {
    id: '57f812d93e4d63c13c000038',
    name: 'Carousel',
    image: imgCarousel,
    images: 5,
    required: 3,
  },
  {
    id: '57d0a0835a4630344c000083',
    name: 'Gradient',
    image: imgGradient,
    images: 2,
    required: 2,
  },
  {
    id: '58a4eb872ad35685070002e2',
    name: 'Swipe',
    image: imgSwipe,
  },
];

export {
  templateFormats,
  templateFormatFilterOptions,
  templateFormatPlacementTypeNameMap,
};
