import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';
import { ReactTableDefaults } from 'react-table';
import Resizer from './component/Resizer';
import Pagination from './component/Pagination';
import NoData from './component/NoData';
import Loading from './component/Loading';
import TableInner from './TableInner';
import { METRIC_TYPES } from '../../../lib/cache/constant';

import 'react-table/react-table.css';
import 'react-table-hoc-fixed-columns/lib/styles.css';
import '../Pagination/pagination.scss';
import './Table.scss';

const initTableDefault = ({
  disableRowSelector,
  onRowGroupProps,
  onRowClick,
}) => Object.assign(ReactTableDefaults, {
  minRows: 0,
  defaultPageSize: 10,
  collapseOnPageChange: false,
  getResizerProps: (_, __, column, that) => ({ column, that }),
  getNoDataProps: (state, _, __, that) => ({ loading: state.loading, prefixCls: that.props.prefixCls }),
  getLoadingProps: (state, _, __, that) => ({ loading: state.loading, prefixCls: that.props.prefixCls }),
  getPaginationProps: (_, __, ___, { props }) => ({ ...props }),
  getTrGroupProps: onRowGroupProps,
  getTrProps: (_, rowInfo) => ({
    onClick: () => {
      onRowClick(rowInfo);
    },
    className: {
      disabled: disableRowSelector(rowInfo.original),
    },
  }),
  getTdProps: (_, __, ___, { props }) => ({ prefixCls: props.prefixCls }),
  getTableProps: (_, __, ___, { props }) => ({ prefixCls: props.prefixCls }),
  TdComponent: function TdComponent({
    style, className, prefixCls, children, ...rest
  }) {
    return (
      <div className={classnames('rt-td', className)} style={style} role="gridcell" {...rest}>
        <div className={`${prefixCls}-td`}>
          {children}
        </div>
      </div>
    );
  },
  TableComponent: function TableComponent({
    style, className, prefixCls, children,
  }) {
    const [scrolled, setScrolled] = React.useState(false);
    const onScroll = (e) => {
      if (e.target.scrollLeft > 0) {
        if (!scrolled) {
          setScrolled(true);
        }
      } else {
        setScrolled(false);
      }
    };
    return (
      <div
        style={style}
        className={classnames(className, 'rt-table', { [`${prefixCls}-scrolled`]: scrolled })}
        onScroll={onScroll}
      >
        {children}
      </div>
    );
  },
  ResizerComponent: Resizer,
  PaginationComponent: Pagination,
  NoDataComponent: NoData,
  LoadingComponent: Loading,
});

class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortedColumns: [props.defaultSort],
      selectedRows: [],
      resized: [],
      resizerLineStyle: {},
    };
    this.tableRef = React.createRef();
  }

  static getDerivedStateFromProps(props, prevState) {
    const { selectedRows } = props;
    if (Array.isArray(selectedRows) && selectedRows !== prevState.selectedRows) {
      return { selectedRows };
    }
    return {};
  }

  setResizerLineStyle = (style) => {
    this.setState({ resizerLineStyle: style });
  }

  onSortedChange = (sortedColumns) => {
    this.setState({ sortedColumns });
    this.props.onSorted(sortedColumns);
  };

  onResizedChange = (resized) => {
    this.setState({ resized });
  }

  onSelectedChange = (selectedRows, row) => {
    const { onSelectedChange } = this.props;
    this.setState({ selectedRows });
    onSelectedChange(selectedRows, row);
  }

  onMetricsChange = (metrics) => {
    const { onMetricsChange } = this.props;
    onMetricsChange(metrics);
  }

  render() {
    const {
      prefixCls,
      height,
      containerHeight,
      tableSorted,
      disableRowSelector,
      onRowGroupProps,
      onRowClick,
    } = this.props;
    const {
      resizerLineStyle, resized, sortedColumns, selectedRows,
    } = this.state;
    initTableDefault({ disableRowSelector, onRowGroupProps, onRowClick });

    return (
      <div
        className={`${prefixCls}-container`}
        style={{ height: containerHeight || height }}
        ref={this.tableRef}
      >
        <div className={`${prefixCls}-resizer-line`} style={resizerLineStyle}>
          <i className={classnames(`${prefixCls}-resizer-icon`, 'material-icons')}>vertical_align_center</i>
        </div>
        <TableInner
          {...this.props}
          resized={resized}
          sortedColumns={tableSorted || sortedColumns}
          selectedRows={selectedRows}
          tableRef={this.tableRef}
          onSortedChange={this.onSortedChange}
          onResizedChange={this.onResizedChange}
          onSelectedChange={this.onSelectedChange}
          onMetricsChange={this.onMetricsChange}
          setResizerLineStyle={this.setResizerLineStyle}
        />
      </div>
    );
  }
}

Table.propTypes = {
  /**
   * Prefix of table className
   */
  prefixCls: PropTypes.string,

  /**
   * className of the table
   */
  className: PropTypes.string,

  /**
   * height of the table
   */
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),

  /**
   * Data record array to be displayed
   */
  data: PropTypes.arrayOf(PropTypes.object).isRequired,

  /**
   *  Columns of table
   */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Column Header component
       */
      Header: PropTypes.oneOfType([
        PropTypes.elementType,
        PropTypes.node,
      ]),

      /**
       * Column Cell component
       */
      Cell: PropTypes.elementType,

      /**
       * Column Footer component
       */
      Footer: PropTypes.elementType,

      /**
       * ID in data record for select
       */
      id: PropTypes.string,

      /**
       * Accessor in data record
       */
      accessor: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
      ]),

      /**
       * Column width
       */
      width: PropTypes.number,

      /**
       * Column sortable or not
       */
      sortable: PropTypes.bool,

      /**
       * Column default sort
       */

      defaultSort: PropTypes.bool,

      /**
       * Column resizable or not
       */
      resizable: PropTypes.bool,

      /**
       * Column fixed direction
       */
      fixed: PropTypes.oneOf(['left', 'right']),
    }),
  ).isRequired,

  /**
   * All columns are sortable or not
   */
  sortable: PropTypes.bool,

  /**
   * Callback executed when sorted column is changed
   */
  onSorted: PropTypes.func,

  /**
   * All Column are resizable or not
   */
  resizable: PropTypes.bool,

  /**
   * Table is selectable or not
   */
  selectable: PropTypes.bool,

  /**
  * Default sort column
  */
  defaultSort: PropTypes.shape({
    id: PropTypes.string,
    desc: PropTypes.bool,
  }),

  /**
   * selected rows
   */
  selectedRows: PropTypes.arrayOf(
    PropTypes.object,
  ),

  /**
   * Callback executed when columns selected or not
   */
  onSelectedChange: PropTypes.func,

  /**
   * row key for check is selected or not
   */
  rowSelectKey: PropTypes.string,

  /**
   * check handler
   */
  rowSelector: PropTypes.func,

  /**
   * when row select the handler to disable select
   */
  disableRowSelector: PropTypes.func,

  /**
   * handle table row click event
  */
  onRowClick: PropTypes.func,

  /**
   * handle table row group props
  */
  onRowGroupProps: PropTypes.func,

  /**
   * max selected row
   */
  maxSelected: PropTypes.number,

  /**
   * show pagination or not
   */
  showPagination: PropTypes.bool,

  /**
   * with action and metric setting
   */
  actionable: PropTypes.bool,

  /**
   * action row cell render
   */
  renderActionCell: PropTypes.func,

  /**
   * action list
   */
  actionList: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
        PropTypes.number,
      ]),
    ),
  ]),

  /**
   * action label key.(When use action list)
   */
  actionLabelKey: PropTypes.string,

  /**
   * action change handler.(When use action list)
   */
  onActionChange: PropTypes.func,

  /**
   * action class name
   */
  actionClassName: PropTypes.func,

  /**
   * user id for cache metrics
   */
  metricUserId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),

  /**
   * table used in the type page for cache
   */
  metricType: PropTypes.oneOf(METRIC_TYPES),

  /**
   * metric tree data
   */
  metricGroup: PropTypes.arrayOf(
    PropTypes.object,
  ),

  /**
   * width of metric overlay
   */
  metricOverlayWidth: PropTypes.number,

  /**
   * height of metric overlay
   */
  metricOverlayHeight: PropTypes.number,

  /**
   * metric apply callback
   */
  onMetricsChange: PropTypes.func,

  /**
   * ajax loading or not
   */
  loading: PropTypes.bool,

  /**
   * show nothing on no data
   */
  hideOnNoData: PropTypes.bool,
};

Table.defaultProps = {
  prefixCls: 'v2_component_table',
  className: '',
  height: 'auto',
  sortable: false,
  defaultSort: {},
  resizable: false,
  selectable: false,
  selectedRows: null,
  onSelectedChange: noop,
  rowSelectKey: null,
  rowSelector: null,
  disableRowSelector: noop,
  onRowGroupProps: noop,
  onRowClick: noop,
  maxSelected: 0,
  onSorted: noop,
  showPagination: true,
  actionable: false,
  actionList: null,
  actionLabelKey: 'label',
  onActionChange: noop,
  actionClassName: '',
  renderActionCell: (row) => row.id,
  metricUserId: 0,
  metricType: METRIC_TYPES[0],
  metricGroup: null,
  metricOverlayWidth: 700,
  metricOverlayHeight: 464,
  onMetricsChange: noop,
  loading: false,
  hideOnNoData: false,
};

export default Table;
