import React from 'react';
import {
  isBetween,
  isDirty,
  isHigherThanMax,
  isLowerThanMin,
} from 'lib/validation';
import { getBidLimits } from 'lib/helpers/campaigns/campaignIndex';
import { format } from 'lib/currency';
import config from '../../lib/config';
import ValidatedText from '../Form/ValidatedText/ValidatedText';

const bidsBudgetsAlertThresholds = config.get('bidsBudgetsAlertThresholds');

export const getValidationMsg = (min, max) => (
  max > 0
    ? `Enter minimum value of ${format(min, true)} and maximum value of ${format(max, true)}.`
    : `Enter value greater than ${format(min, true)}.`
);

const CurrencyValidator = ({
  campaign, budgetLimits, numeric, type, value, shouldValidateThreshold, ...props
}) => {
  const { max, min, rangeErrors } = type === 'bid' ? getBidLimits(campaign) : budgetLimits(type, campaign);

  const defaultMsg = getValidationMsg(min, max);
  let validationMsg = '';
  let validationErrors = null;

  const isValidRange = () => {
    const castedValue = parseFloat(value);
    if (rangeErrors) {
      const { lowerBoundMsg, upperBoundMsg } = rangeErrors || {};
      if (isLowerThanMin(min, castedValue)) {
        validationMsg = lowerBoundMsg;
        return false;
      }
      if (isHigherThanMax(max, castedValue)) {
        validationMsg = upperBoundMsg;
        return false;
      }
    }
    if (Number.isNaN(castedValue) || !isBetween(min, max, castedValue)) {
      validationMsg = getValidationMsg(min, max);
      return false;
    }
    return true;
  };

  const isValidDaily = () => {
    if (shouldValidateThreshold && type === 'daily') {
      if (!campaign.isValidDailySpend()) {
        const bidThreshold = bidsBudgetsAlertThresholds[campaign.get('budget.type')];
        const threshold = bidsBudgetsAlertThresholds.daily;
        const budgetType = campaign.get('budget.type');
        const ctaText = `Please increase the cap to over $${threshold} or lower the ${budgetType} bid below $${bidThreshold} to prevent overruns.`;
        validationMsg = `High bid and low daily cap. ${ctaText}`;
        return false;
      }
    }
    return true;
  };

  const isValidLongTail = () => {
    if (type === 'bid') {
      const valid = campaign.isValidLongTailBid();
      if (!valid) {
        const geos = campaign.getLongTailGeos();
        validationErrors = { invalidLongTailGeos: geos.map((geo) => geo.code) };
        return false;
      }
    }
    return true;
  };

  const isValid = !isDirty(value) || (isValidRange() && isValidDaily() && isValidLongTail()) || value === 0;

  return (
    <ValidatedText
      textType="Currency"
      isValid={isValid}
      numeric={numeric}
      value={value}
      defaultMsg={defaultMsg}
      validationMsg={validationMsg}
      validationErrors={validationErrors}
      {...props}
    />
  );
};

export default CurrencyValidator;
