import { useEffect, useRef } from 'react';

export default function useUpdatedEffect(fn, deps = []) {
  const didMountRef = useRef(false);
  useEffect(() => {
    if (didMountRef.current) fn();
    else didMountRef.current = true;
    // eslint-disable-next-line
  }, deps);
}
