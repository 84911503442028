import React from 'react';
import { isAdminService } from 'lib/serviceType';
import Button from '../../../../../../components/Button/Button';
import Icon from '../../../../../../components/Icon/Icon';
import Message from '../../../../../../components/V2/FlagMessage/Message';
import SideModal from '../../../../../../components/SideModal/side-modal';
import triggerConfirm from '../../../../../../components/Modals/ConfirmAction/triggerConfirm';
import Account from '../../../../../../models/Account';
import { ERROR_MSG, ERRORS } from '../errors';
import AssetDropZone from './AssetDropZone';
import AccountAndApplicationSelector from './AccountAndApplicationSelector';
import UploadProgressList from './UploadProgressList';
import CreativeBuilderDialog from '../CreativeBuilderDialog/CreativeBuilderDialog';
import './AssetUploader.scss';

class AssetUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // files that are selected for upload
      files: [],

      uploadingFilesNumber: 0,

      // account selected for upload
      account: undefined,

      // application selected for upload
      application: undefined,

      // used to let parent know that at least one asset
      // is uploaded so the parent can update the asset list
      hasUploadedAnyAsset: false,

      errors: [],

      isCBDialogShown: false,
    };
  }

  componentDidMount = () => {
    const { accountHier, asset, mode } = this.props;

    if (accountHier && 'account' in accountHier) {
      this.setState({
        account: new Account({
          id: accountHier.account,
          name: accountHier.name,
        }),
      });
    }

    if (mode === 'replacer') {
      if (!asset) {
        this.closeModal();
        return;
      }
      this.setState({
        application: asset.get('application'),
        account: new Account({
          id: asset.raw('account.id'),
          name: asset.raw('account.name'),
        }),
      });
    }
  }

  closeModal = () => {
    if (this.state.uploadingFilesNumber > 0) {
      // prevent user closing the uploader if there are pending files
      triggerConfirm({
        type: 'EXIT_ASSET_UPLOAD_CONFIRM_ACTION',
        header: 'Oh Snap',
        message: 'Heads up. You\'re exiting the asset upload process. Are you super sure you want to exit the flow?',
        onConfirm: this.onClose,
      });
      return;
    }
    this.onClose();
  };

  onClose = () => {
    if (this.props.onClose) {
      this.props.onClose(this.state.hasUploadedAnyAsset);
    }
  }

  onDiscardAsset = (discardedFile) => {
    const { files } = this.state;

    this.setState({
      files: files.filter((file) => file.name !== discardedFile.name),
    });
    if (this.props.mode === 'replacer') this.onClose();
  };

  onClearApplicationClick = () => {
    if (this.state.uploadingFilesNumber > 0) {
      return;
    }
    this.setState({ application: undefined, errors: [], files: [] });
  };

  onDrop = (selectedFiles) => {
    const { files, errors, uploadingFilesNumber } = this.state;
    const { mode } = this.props;
    // if mode === replacer, user can only upload one file
    const isExceedUploadLimit = mode === 'replacer' && (uploadingFilesNumber || files.filter((file) => !errors.includes(file.name)).length > 0);
    if (isExceedUploadLimit) {
      Message.error({
        title: 'Error',
        content: ERROR_MSG[ERRORS.ONLY_ONE_FILE],
      });
      return;
    }
    // filter out files that are already successfully uploaded
    const onlyNewFiles = selectedFiles.filter((newFile) => {
      const currentFile = files.find((file) => file.name === newFile.name);
      if (!currentFile || (currentFile && errors.indexOf(newFile.name) !== -1)) {
        return newFile;
      }
      return false;
    });

    // on drop new files, remove all files that are new and had some error when uploading
    const filterOutFiles = files.filter((file) => !onlyNewFiles.find((nFile) => nFile.name === file.name));

    this.setState({ files: [...onlyNewFiles, ...filterOutFiles] });
  }

  isAssetDropZoneVisible = ({
    account, application, mode, files,
  }) => {
    if (account && application) {
      return mode === 'replacer' ? files.length < 1 : true;
    }
    return false;
  }

  render() {
    const {
      account,
      application,
      files,
      errors,
      uploadingFilesNumber,
    } = this.state;

    const {
      asset,
      mode = 'uploader',
      acceptFileTypes = 'image/*, video/mp4, application/zip, application/x-zip-compressed',
      multiple = true,
      authUser,
    } = this.props;

    return (
      <div className="asset_uploader">

        <SideModal
          isOpen
          overlayClassName="asset_uploader_overlay"
          onRequestClose={this.closeModal}
          title=""
          ariaHideApp={false}
        >
          <div className="modal_content">
            <div className="upload_header">
              <div className="upload_header-title">
                <p className="title">
                  {mode === 'uploader' ? 'Upload Asset(s)' : 'Replace Asset'}
                </p>
                <p className="subtitle">
                  {mode === 'uploader'
                    ? 'Add assets to upload then follow their journey.'
                    : 'Replace the existing asset for your app below and submit for another review.'}
                </p>
              </div>
              <a className="link" href="https://support.vungle.com/hc/en-us/articles/360035348091-Creative-Asset-Requirements" target="blank">
                Learn More About Assets
                <Icon.OpenInNew />
              </a>
            </div>
            <div className="upload_content">

              {mode === 'uploader' && (
                <AccountAndApplicationSelector
                  showAccountFinder={isAdminService()}
                  account={account}
                  application={application}
                  onSelectAccount={(v) => this.setState({ account: v, files: [], errors: [] })}
                  onSelectApplication={(v) => this.setState({ application: v, files: [], errors: [] })}
                  disabled={!!uploadingFilesNumber}
                  authUser={authUser}
                />
              )}

              {asset && mode === 'replacer' && (
                <div className="asset_replacer_warning">
                  <i className="material-icons">warning</i>
                  <span>
                    {`Heads up, you are replacing your Playable (.zip format) entitled ${asset.get('name')}, this cannot be reverted.`}
                  </span>
                </div>
              )}

              {this.isAssetDropZoneVisible({
                account, application, mode, files,
              })
                && (
                  <>
                    <AssetDropZone
                      accept={acceptFileTypes}
                      multiple={multiple}
                      onDrop={this.onDrop}
                      dropZoneText={mode === 'replacer' ? 'Playable (.zip format)' : undefined}
                    />
                    {mode !== 'replacer' && (
                      <a className="creative_builder_button" href="#" onClick={() => this.setState({ isCBDialogShown: true })}>
                        Create your own
                        &nbsp;
                        <i className="material-icons">open_in_new</i>
                      </a>
                    )}
                  </>
                )}

              {files.length > 0
                && (
                  <UploadProgressList
                    mode={mode}
                    account={account}
                    application={application}
                    asset={asset && mode === 'replacer' ? asset : null}
                    files={files}
                    onUploadStart={() => {
                      const currentActiveUploads = uploadingFilesNumber + 1;
                      this.setState({
                        uploadingFilesNumber: currentActiveUploads,
                      });
                    }}
                    onUploadAsset={(obj) => {
                      let indx = -1;

                      if ((indx = errors.indexOf(obj.name)) !== -1) { //eslint-disable-line
                        errors.splice(indx, 1);
                      }

                      if (obj.status === 'error') {
                        errors.push(obj.name);
                      }

                      const currentActiveUploads = uploadingFilesNumber - 1;
                      this.setState({
                        errors,
                        hasUploadedAnyAsset: true,
                        uploadingFilesNumber: currentActiveUploads,
                      });
                    }}
                    onDiscardAsset={this.onDiscardAsset}
                    onCommentUpdate={mode === 'replacer' && this.onClose}
                  />
                )}
            </div>

            <div className="shadow" />

            <div className="footer">
              <Button
                cancel
                onClick={this.closeModal}
              >
                Close
              </Button>
            </div>
          </div>

          {this.state.isCBDialogShown
            && (
              <CreativeBuilderDialog
                account={account}
                application={application}
                onDismiss={(invalidate) => {
                  this.setState({ isCBDialogShown: false });

                  if (invalidate) {
                    this.props.onClose(invalidate);
                  }
                }}
              />
            )}
        </SideModal>
      </div>
    );
  }
}

export default AssetUploader;
