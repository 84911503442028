import { getUserConfig } from 'app/graphql/utils/user';
import React, { compose, splash } from '../../app/app';
import { track } from '../../lib/tracker';
import Account from '../../models/Account';
import TermsOfService, {
  currentVersionToS,
} from './TermsOfService';
import './termsOfService.scss';

const ns = 'components.terms_of_service';
const props = (state) => ({
  ...state[ns],
  appConfig: state.setup.appConfig,
});

class TermsOfServiceContainer extends React.Component {
  constructor(p) {
    super(p);
    this.state = {};
  }

  render() {
    return (
      <TermsOfService {...this.props} />
    );
  }
}

const acceptTerms = (() => {
  const action = async (c) => {
    await Account.acceptTerms(c.account.id, currentVersionToS);
    return getUserConfig();
  };
  const end = ({ state, result }) => {
    track('Accepted ToS');
    return {
      ...splash({ finish: true }),
      setup: { ...state.setup, appConfig: result.response },
    };
  };
  const start = () => splash({ start: true, text: 'Accepting' });
  return { action, end, start };
})();

const actions = {
  acceptTerms,
};

export default compose(TermsOfServiceContainer, { actions, props });
