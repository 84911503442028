import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';
import Spinner from '../Spinner/Spinner';
import './privacyFrame.scss';

class PrivacyFrame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  onPrivacyPageLoaded = () => {
    this.setState({
      loading: false,
    });
  }

  render() {
    const { privacyPage } = this.props;
    return (
      <div className="PrivacyFrame">
        {
          this.props.showClose && (
          <div className="PrivacyFrame__Header" onClick={this.props.onClose}>
            <Icon.ArrowBack />
            <div className="PrivacyFrame__Header__URLBar" />
            <div className="PrivacyFrame__Header__URLReload" />
          </div>
          )
        }
        <iframe
          title="privacyPage"
          frameBorder="0"
          style={{
            opacity: this.state.loading ? 0 : 1,
          }}
          src={privacyPage}
          onLoad={this.onPrivacyPageLoaded}
        />
        {
          this.state.loading && (<div className="PrivacyFrame__Loader"><Spinner /></div>)
        }
      </div>
    );
  }
}

PrivacyFrame.propTypes = {
  onClose: PropTypes.func,
  showClose: PropTypes.bool,
};

PrivacyFrame.defaultProps = {
  onClose: Function.prototype,
  showClose: true,
};

export default PrivacyFrame;
