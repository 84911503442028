import { dispatchAction } from '../../../app/app';
import { updateState } from './AlertContainer';

const triggerModal = ({ title, alertText, closeFunc }) => {
  dispatchAction('SHOW_ALERT_MODAL_ACTION', ({ state }) => updateState(state, {
    show: true,
    title,
    alertText,
    closeFunc,
  }));
};

export default triggerModal;
