import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import classnames from 'classnames';
import Input from '../../../../../components/V2/Input/Input';
import Table from '../../../../../components/V2/Table/Table';
import makeColumn from './columns';

const classPrefix = 'views__publishers__table';
const editCellPrefixCls = 'edit-cell';

const KEY_BOARD_ENTER = 'Enter';
const KEY_BOARD_ESC = 'Esc';

const EditableCell = ({
  onSave, original, geos, geoBudgetValidator, autoFocusProps, defaultRate,
}) => {
  const [inputValue, setInputValue] = useState();
  const [inlineEdit, setInlineEdit] = useState(false);
  const [error, setError] = useState();
  const [autoFocus, setAutoFocus] = useState(!!autoFocusProps);
  const cellRef = useRef();

  const { validator, validationMsg } = geoBudgetValidator();
  const currentGeo = geos.find((geo) => geo.geo === original.code);
  const rate = currentGeo && currentGeo.rate ? currentGeo.rate : defaultRate;

  useEffect(() => {
    const groupNode = cellRef.current.closest('.rt-tr-group');
    const errorClassName = `${classPrefix}-group-error-rate`;
    if (error) {
      groupNode.classList.add(errorClassName);
    } else {
      groupNode.classList.remove(errorClassName);
    }
  }, [error]);

  useEffect(() => {
    setInputValue(rate || '');
    // --> potential bug inside this useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [original, geos]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleOnBlur = useCallback(() => {
    setInputValue(rate);
    setInlineEdit(false);
    setError(false);
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleEnterSave = useCallback((e) => {
    if (e.key === KEY_BOARD_ENTER) {
      if (!error) {
        onSave(original, e.target.value);
      } else {
        handleOnBlur();
      }
    } else if (e.key === KEY_BOARD_ESC) {
      handleOnBlur();
    }
  });

  const classes = classnames(`${editCellPrefixCls}-currency`, {
    [`${editCellPrefixCls}-valid`]: !error,
    [`${editCellPrefixCls}-invalid`]: error,
  });
  const inputClasses = classnames(`${editCellPrefixCls}-input`, {
    [`${editCellPrefixCls}-input-invalid`]: error,
  });

  return (
    <div className={`${editCellPrefixCls}`} ref={cellRef}>
      <span className={classes}>$</span>
      {inlineEdit && (
        <Input
          className={inputClasses}
          placeholder="budget"
          value={inputValue}
          onChange={(e) => {
            const { value } = e.target;
            const isValid = validator(value);
            setError(!isValid);
            setInputValue(Number(value) === 0 ? 0 : value.replace(/^(0+)/gi, ''));
          }}
          onKeyDown={handleEnterSave}
          onBlur={handleOnBlur}
          autoFocus={autoFocus}
        />
      )}
      {!inlineEdit && (
        <span
          className={`${editCellPrefixCls}-inline-edit-item`}
          onClick={() => {
            setAutoFocus(true);
            setInlineEdit(true);
          }}
        >
          <span className={`${editCellPrefixCls}-bid`}>
            {rate}
          </span>
          <i className={`material-icons ${editCellPrefixCls}-icon`}>edit</i>
        </span>
      )}
      {error && <div className={`${editCellPrefixCls}-error`}>{validationMsg}</div>}
    </div>
  );
};

EditableCell.noWrapInTooltip = true;

const EditTable = ({
  campaign,
  countries,
  pagination,
  onPageSizeChange,
  onSave,
  onSorted,
  geoBudgetValidator,
  defaultRate,
}) => {
  const [tableData, setTableData] = useState(countries);
  const geos = campaign.get('budget.daily_spend_limit_geos');

  useEffect(() => {
    setTableData(countries);
  }, [countries]);

  return (
    <div>
      <Table
        manual
        data={tableData}
        showPagination
        page={pagination.page}
        total={pagination.total}
        pageSize={pagination.perPage}
        onSorted={onSorted}
        onPageSizeChange={onPageSizeChange}
        columns={[
          {
            ...makeColumn('Country', 'name'),
            Cell: ({ original }) => original.name,
            resizable: true,
            minResizeWidth: 100,
            className: 'country-column',
          },
          {
            ...makeColumn('Country Code', 'code'),
            Cell: ({ original }) => original.code,
            resizable: true,
            minResizeWidth: 100,
          },
          {
            ...makeColumn('Budget', 'code', 350, null, true),
            Cell: function Cell({ original }) {
              return (
                <EditableCell
                  onSave={onSave}
                  original={original}
                  geos={geos}
                  geoBudgetValidator={geoBudgetValidator}
                  defaultRate={defaultRate}
                />
              );
            },
            resizable: true,
            minResizeWidth: 100,
          },
        ]}
      />
    </div>
  );
};

export default EditTable;
