import React from 'react';
import './alert.scss';

const Alert = ({
  children, className, type = 'error', icon = 'error_outline', style = {},
}) => (
  <div className={`components__alert components__alert--${type} ${className}`} style={style}>
    <i className="material-icons">{icon}</i>
    <span>{children}</span>
  </div>
);

const Info = (p) => (<Alert type="info" icon="info_outline" {...p} />);
const Warn = (p) => (<Alert type="warn" icon="info_outline" {...p} />);
const Error = (p) => (<Alert type="error" icon="info_outline" {...p} />);
const Help = (p) => (<Alert type="help" icon="info_outline" {...p} />);

Alert.Info = Info;
Alert.Warn = Warn;
Alert.Error = Error;
Alert.Help = Help;

export default Alert;
