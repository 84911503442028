import Template from 'models/Template';
import { isCampaignObjectiveDynamic } from 'lib/capabilities/campaigns';

// eslint-disable-next-line import/prefer-default-export
export function getCategoriesOptionsForCreativeManager(campaign, creative) {
  if (campaign && isCampaignObjectiveDynamic(campaign)) {
    return Template.getCategoriesOptions().filter(Template.isCategoryFullscreen);
  }

  return Template.getCategoriesOptions()
    .filter(
      (category) => (
        !category.shouldHideInSSUI
          && (Template.isCategoryPlayable(category) ? creative.hasPermissionForPlayable() : true)
          && (Template.isCategoryNative(category) ? creative.get('account').hasPermissionForNative() : true)
      ),
    );
}
