import { hasAccess, constants } from 'accesscontrol';
import { getStore } from '../../../app/storeprovider';

// checks if the user in state has access to permissions.
function userHasAccess(...permissions) {
  const state = getStore().getState();
  return hasAccess(state.authUser?.permissions, ...permissions);
}

function canModifyCreatives() {
  return userHasAccess(constants.ModifyCreatives);
}

function canModifyCreativeTemplates() {
  return userHasAccess(constants.ModifyCreativeTemplates);
}

function canModifyCampaigns() {
  return userHasAccess(constants.ModifyCampaigns);
}

function canModifyApps() {
  return userHasAccess(constants.ModifyApps);
}

function canInviteNewUsersToReadonlyRole() {
  return userHasAccess(constants.InviteNewUsersToReadonlyRole);
}

function canToggleSkadnetwork() {
  return userHasAccess(constants.ToggleSkadnetwork);
}

function canModifyCampaignCvrMultiplier() {
  return userHasAccess(constants.ModifyCampaignCvrMultiplier);
}

function canModifyBidTypeToCpc() {
  return userHasAccess(constants.ModifyBidTypeToCpc);
}

function canArchiveCreatives() {
  return userHasAccess(constants.ArchiveCreatives);
}

function canModifyDemandGlobalSettings() {
  return userHasAccess(constants.ModifyDemandGlobalSettings);
}

function canModifyCampaignBudgetMultiplier() {
  return userHasAccess(constants.ModifyCampaignBudgetMultiplier);
}

function canModifyUsers() {
  return userHasAccess(constants.ModifyUsers);
}

function canModifyViewAccountFinancialDetails() {
  return userHasAccess(constants.ModifyViewAccountFinancialDetails);
}

function canAccessManagementApi() {
  return userHasAccess(constants.AccessToManagementApi);
}

function canAssignCustomerLevelRoles() {
  return userHasAccess(constants.AssignCustomerLevelRoles);
}

function canModifyAssets() {
  return userHasAccess(constants.ModifyAssets);
}

export {
  canModifyCreatives,
  canModifyCreativeTemplates,
  canModifyCampaigns,
  canModifyApps,
  canInviteNewUsersToReadonlyRole,
  canToggleSkadnetwork,
  canModifyCampaignCvrMultiplier,
  canModifyBidTypeToCpc,
  canArchiveCreatives,
  canModifyDemandGlobalSettings,
  canModifyCampaignBudgetMultiplier,
  canModifyUsers,
  canModifyViewAccountFinancialDetails,
  canAccessManagementApi,
  canAssignCustomerLevelRoles,
  canModifyAssets,
};
