const PREVIEW_SETTINGS = {
  VERSIONS: {
    NONE: 'none',
    V2: '2.0',
  },
  EVENTS: {
    INFO: 'info',
    READY: 'ready',
    ERROR: 'error',
    STATE_CHANGE: 'stateChange',
    ORIENTATION_CHANGE: 'orientationChange',
    VIEWABLE_CHANGE: 'viewableChange',
    SIZE_CHANGE: 'sizeChange',
    POST_MESSAGE: 'postMessage',
    MRAID_ACTION: 'mraidAction',
  },
  STATES: {
    LOADING: 'loading',
    DEFAULT: 'default',
    RESIZED: 'resized',
    EXPANDED: 'expanded',
    HIDDEN: 'hidden',
  },
  PLACEMENTS: {
    UNKNOWN: 'unknown',
    INLINE: 'inline',
    INTERSTITIAL: 'interstitial', // fullscreen
    FULLSCREEN: 'fullscreen',
    MREC: 'mrec',
    BANNER: 'banner',
  },
  ORIENTATIONS: {
    PORTRAIT: 'portrait',
    LANDSCAPE: 'landscape',
    // NONE: "none"
  },
  SUPPORTS: {
    sms: false,
    tel: false,
    calendar: false,
    storePicture: false,
    inlineVideo: false,
  },
};

const VUNGLE_PLACEMENT_TYPE_FORMATS_MAP = {
  fullscreen: [
    'single_page_fullscreen',
    'multi_page_fullscreen',
  ],
  banner: ['banner'],
  mrec: ['single_page_mrec', 'multi_page_mrec', 'image_mrec'],
};

const VUNGLE_TYPE_VERSION_MAP = {
  '2.0': [
    'vungle_mraid',
    'third_party_mraid',
  ],
  none: [
    'vungle_local',
    'vungle_short_form',
    'third_party',
  ],
};

const MRAID_ACTIONS = {
  open: 'open',
  close: 'close',
  openPrivacy: 'openPrivacy',
};

const LEGACY_ACTIONS = {
  download: 'download',
  close: 'close',
  closeVideo: 'close_video',
};

const PHONE_SCREEN_SIZE = {
  width: 320,
  height: 568,
};

const TABLET_SCREEN_SIZE = {
  width: 420,
  height: 748,
};

const MREC_SIZE = {
  width: 300,
  height: 250,
};

const BANNER_SMALL_SIZE = {
  width: 300,
  height: 50,
};

const BANNER_BIG_SIZE = {
  width: 728,
  height: 90,
  gutter: 20,
};

// 480/270 - smaller 16:9
const PHONE_DEFAULT_POSITIONS_SIZES = {
  fullscreen: {
    adWidth: PHONE_SCREEN_SIZE.width,
    adHeight: PHONE_SCREEN_SIZE.height,
    adMaxWidth: PHONE_SCREEN_SIZE.width,
    adMaxHeight: PHONE_SCREEN_SIZE.height,
    screenWidth: PHONE_SCREEN_SIZE.width,
    screenHeight: PHONE_SCREEN_SIZE.height,
    adLeft: 0,
    adTop: 0,
    adMaxLeft: 0,
    adMaxTop: 0,
    orientation: PREVIEW_SETTINGS.ORIENTATIONS.PORTRAIT,
  },
  mrec: {
    adWidth: MREC_SIZE.width,
    adHeight: MREC_SIZE.height,
    adMaxWidth: MREC_SIZE.width,
    adMaxHeight: MREC_SIZE.height,
    screenWidth: PHONE_SCREEN_SIZE.width,
    screenHeight: PHONE_SCREEN_SIZE.height,
    adLeft: 0,
    adTop: 0,
    adMaxLeft: 10,
    adMaxTop: 50,
    orientation: PREVIEW_SETTINGS.ORIENTATIONS.PORTRAIT,
  },
  banner: {
    adWidth: BANNER_SMALL_SIZE.width,
    adHeight: BANNER_SMALL_SIZE.height,
    adMaxWidth: BANNER_SMALL_SIZE.width,
    adMaxHeight: BANNER_SMALL_SIZE.height,
    screenWidth: PHONE_SCREEN_SIZE.width,
    screenHeight: PHONE_SCREEN_SIZE.height,
    adLeft: 0,
    adTop: 0,
    adMaxLeft: 10,
    adMaxTop: 5,
    orientation: PREVIEW_SETTINGS.ORIENTATIONS.PORTRAIT,
  },
};

const TABLET_DEFAULT_POSITIONS_SIZES = {
  fullscreen: {
    adWidth: TABLET_SCREEN_SIZE.width,
    adHeight: TABLET_SCREEN_SIZE.height,
    adMaxWidth: TABLET_SCREEN_SIZE.width,
    adMaxHeight: TABLET_SCREEN_SIZE.height,
    screenWidth: TABLET_SCREEN_SIZE.width,
    screenHeight: TABLET_SCREEN_SIZE.height,
    adLeft: 0,
    adTop: 0,
    adMaxLeft: 0,
    adMaxTop: 0,
    orientation: PREVIEW_SETTINGS.ORIENTATIONS.PORTRAIT,
  },
  mrec: {
    adWidth: MREC_SIZE.width,
    adHeight: MREC_SIZE.height,
    adMaxWidth: MREC_SIZE.width,
    adMaxHeight: MREC_SIZE.height,
    screenWidth: TABLET_SCREEN_SIZE.width,
    screenHeight: TABLET_SCREEN_SIZE.height,
    adLeft: 0,
    adTop: 0,
    adMaxLeft: 10,
    adMaxTop: 50,
    orientation: PREVIEW_SETTINGS.ORIENTATIONS.PORTRAIT,
  },
  banner: {
    scaler: {
      portrait: { // PREVIEW_SETTINGS.ORIENTATIONS.PORTRAIT
        // (screenWidth - (adMaxLeft * 2)) / adMaxWidth
        scale: `scale(${((TABLET_SCREEN_SIZE.width - BANNER_BIG_SIZE.gutter) / BANNER_BIG_SIZE.width).toFixed(1)})`,
      },
    },
    adWidth: BANNER_BIG_SIZE.width,
    adHeight: BANNER_BIG_SIZE.height,
    adMaxWidth: BANNER_BIG_SIZE.width,
    adMaxHeight: BANNER_BIG_SIZE.height,
    screenWidth: TABLET_SCREEN_SIZE.width,
    screenHeight: TABLET_SCREEN_SIZE.height,
    adLeft: 0,
    adTop: 0,
    adMaxLeft: 10,
    adMaxTop: 5,
    orientation: PREVIEW_SETTINGS.ORIENTATIONS.PORTRAIT,
  },
};

const ERRORS = {
  assetUrl: '404-asset-url',
  videoUrl: '404-video-url',
  replacementsUrl: '404-replacements-url',
};

const CHAMPA_FORMATS = {
  dynamicTemplate: 'vungle_mraid:dynamic_template',
  adaptiveCreative: 'vungle_mraid:adaptive_creative',
  adaptiveCreativeAsset: 'vungle_mraid:adaptive_creative_asset',
  adaptiveCreativeUrl: 'vungle_mraid:adaptive_creative_url',
};

const AC_DEFAULT_TOKENS = {
  CLOSE_BUTTON_DELAY_SECONDS: '0',
  ENDCARD_ONLY_DURATION_SECONDS: '25',
  EC_CLOSE_BUTTON_DELAY_SECONDS: '0',
  VIDEO_CLOSE_BUTTON_DELAY_SECONDS: '0',
  INCENTIVIZED_CLOSE_BUTTON_DELAY_SECONDS: '0',
  START_MUTED: 'false',
  MAIN_VIDEO: 'https://cdn-lb.vungle.com/templates/defaults/video/video.mp4',
  CTA_BUTTON_URL: '',
  FULL_CTA: 'false',
  DOWNLOAD_BUTTON_DELAY_SECONDS: '3.5',
  VIDEO_SHOW_CTA: 'true',
  VUNGLE_PRIVACY_URL: null,
};

export {
  PREVIEW_SETTINGS,
  PHONE_DEFAULT_POSITIONS_SIZES,
  TABLET_DEFAULT_POSITIONS_SIZES,
  VUNGLE_PLACEMENT_TYPE_FORMATS_MAP,
  VUNGLE_TYPE_VERSION_MAP,
  MRAID_ACTIONS,
  LEGACY_ACTIONS,
  ERRORS,
  CHAMPA_FORMATS,
  AC_DEFAULT_TOKENS,
  PHONE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
  BANNER_SMALL_SIZE,
  BANNER_BIG_SIZE,
};
