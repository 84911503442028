import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import config from '../../../../../lib/config';
import FrontendPanel from './FrontendPanel';

const { languages } = config.get('countryData');

export const nodes = languages.map(({ code, name }) => ({ id: code, name }));

const LanguagePanel = ({
  visible,
  maxSelected,
  availableNodes,
  defaultCheckedNodes,
  onVisibleChange,
  onChange,
}) => (
  <FrontendPanel
    nodes={availableNodes?.length ? availableNodes : nodes}
    visible={visible}
    placeholder="Search Languages..."
    maxSelected={maxSelected}
    disabledTitle="Limit Reached"
    disabledContent={`You have selected (${maxSelected}) languages.`}
    defaultCheckedNodes={defaultCheckedNodes}
    onVisibleChange={onVisibleChange}
    onChange={onChange}
  />
);

LanguagePanel.propTypes = {
  visible: PropTypes.bool,
  maxSelected: PropTypes.number,
  defaultCheckedNodes: PropTypes.arrayOf(PropTypes.any),
  onVisibleChange: PropTypes.func,
  onChange: PropTypes.func,
};

LanguagePanel.defaultProps = {
  visible: false,
  maxSelected: 0,
  defaultCheckedNodes: [],
  onVisibleChange: noop,
  onChange: noop,
};

export default React.memo(LanguagePanel);
