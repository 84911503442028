import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
} from 'react';
import { CSVLink } from 'react-csv';
import Button from '../Button/Button';
import InfoBar from '../../InfoBar/InfoBar';
import UploaderFile from '../Upload/Component/File';
import UploaderContainer from '../Upload/UploaderContainer';
import config from '../../../lib/config';
import {
  addMultiBiddingRates,
  getBidLimits,
  isValidLongTailGeo, validateBidRange,
} from '../../../lib/helpers/campaigns/campaignIndex';
import EditTable from './Table/EditTable';
import {
  KEY_GEO, KEY_PUB_ID, KEY_NAME, INLINE_EDIT_PLACEHOLDER, DEFAULT_PAGINATION, KEY_INVALID,
} from './constant';
import {
  campaignMultiDataValidate,
  validateBiddingRow,
  saveCampaignMultiData,
  shouldDisableAddRowButton,
  shouldShowFilter,
  filterRatesData,
  getCampaignBiddingIndex,
  isSameBid,
  getRateErrorMsg,
  handleBiddingInputWhenUpload,
  handleBiddingInputWhenUpdate,
  handleBiddingInputWhenDelete,
  handleBiddingInputWhenDeleteAll,
} from './multiBiddingHelper';
import { isEmptyObject } from '../../../lib/lib';
import usePagination from '../../../lib/hooks/usePagination';
import Filters from './Filters/Filters';
import ErrorsCollapse from '../Collapse/ErrorsCollapse/ErrorsCollapse';
import { CSV_ERROR_MESSAGE as messages } from '../Collapse/ErrorsCollapse/messages';
import status from './status';
import CollapseSection from '../Collapse/CollapseSection';
import './multiBidding.scss';

const prefixClass = 'multi-bidding';
const { csvExampleData } = config.get('multibidding');

const MultiBidding = ({
  campaign, setupMultiBiddingEditValues, onExampleCsvClick, updatePageContinue,
}) => {
  const [multiData, setMultiData] = useState([]);
  const [previousRates, setPreviousRates] = useState([...campaign.get('budget.publisher_rates')]);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [csvPercent, setCSVPercent] = useState(0);
  const [csvFile, setCsvFile] = useState();
  const [errors, setErrors] = useState();
  const [searchText, setSearchText] = useState();
  const { max = 0, min = 0 } = getBidLimits(campaign, true);
  // file uploading status.
  const [csvStatus, setCSVStatus] = useState(status.INITIAL);
  const csvStatusRef = useRef(csvStatus);
  const [tableData] = usePagination(
    multiData,
    pagination.page,
    pagination.perPage,
    (page) => setPagination({ ...pagination, page }),
  );

  useEffect(() => { csvStatusRef.current = csvStatus; }, [csvStatus]);

  useEffect(() => {
    if (campaign && campaign.get('budget.publisher_rates').length) {
      const data = campaign.get('budget.publisher_rates');
      setMultiData(data);
      validateBidRange(campaign, data.map((m) => ({ ...m, rate: Number(m.rate) })));
      setCSVStatus(status.UPLOADED);
    }
    return () => {
      updatePageContinue({ enabled: true });
    };
    // --> potential bug inside this useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign]);
  // The logic for handle enable continue button of campaign edit
  useEffect(() => {
    if (csvStatus !== status.ERROR) {
      const shouldDisableButton = shouldDisableAddRowButton(multiData);
      updatePageContinue({ enabled: !shouldDisableButton });
      if (!shouldDisableButton) {
        campaign.set('multibidding.errorMessage', null);
      }
    }
    // --> potential bug inside this useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiData, csvStatus]);

  const handleUploading = (percent) => setCSVPercent(percent);

  const handleCancel = () => {
    validateBidRange(campaign, previousRates.map((m) => ({ ...m, rate: Number(m.rate) })));
    setCSVStatus(previousRates.length ? status.UPLOADED : status.INITIAL);
    saveCampaignMultiData(previousRates, campaign);
  };

  const handleStartUpload = useCallback((file) => {
    setCsvFile(file);
    setErrors(null);
    setCSVPercent(1);
    setCSVStatus(status.UPLOADING);
    campaign.set('multibidding.isUploading', true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validRates = async ({ rates, override }) => {
    campaign.set('multibidding.errorMessage', null);
    const { campaign: newCampaign, mergedRates, csvErrors } = await addMultiBiddingRates(
      campaign,
      rates,
      setupMultiBiddingEditValues,
      override,
    );
    if (status.UPLOADING === csvStatusRef.current) {
      setCSVPercent(100);
      campaign.set('multibidding.isUploading', false);
      if (newCampaign) {
        if (!csvErrors || isEmptyObject(csvErrors)) {
          campaign.set('budget.publisher_rates', [...mergedRates]);
          setCSVStatus(status.UPLOADED);
          setPreviousRates([...mergedRates]);
          setMultiData([...mergedRates]);
          handleBiddingInputWhenUpload(campaign);
        }
        if (!isEmptyObject(csvErrors)) {
          setErrors(csvErrors);
          campaign.set('multibidding.errorMessage', Object.keys(csvErrors).map((key) => messages[key]).join(';'));
          // User can't to save this campaign if got an error
          updatePageContinue({ enabled: false });
          // Needs back to the previous rates
          saveCampaignMultiData(previousRates, campaign);
          setCSVStatus(status.ERROR);
        }
        return;
      }
      setCSVStatus(previousRates.length ? status.UPLOADED : status.INITIAL);
      return;
    }
    if (previousRates.length) {
      setCSVStatus(status.UPLOADED);
    }
    saveCampaignMultiData(previousRates, campaign);
  };

  const handleError = (file, error) => {
    if (file && error) {
      setErrors(error);
      setCsvFile(file);
      setCSVPercent(100);
      setCSVStatus(status.ERROR);
    }
  };

  const handleSearch = (event) => {
    if (!campaign || !campaign.get('budget.publisher_rates').length) {
      return;
    }
    const publisherRates = campaign.get('budget.publisher_rates');
    const keyword = event.target.value;
    setSearchText(keyword);
    if (keyword) {
      const arr = filterRatesData(publisherRates, keyword);
      setPagination({
        ...pagination,
        page: 1,
      });
      setMultiData(arr);
    } else {
      setMultiData(publisherRates);
    }
  };

  const generateNewRecord = () => {
    setMultiData([{
      [KEY_PUB_ID]: '', [KEY_GEO]: '', [KEY_NAME]: INLINE_EDIT_PLACEHOLDER[KEY_NAME], [KEY_INVALID]: true,
    }, ...multiData]);
    setCSVStatus(status.UPLOADED);
    setPagination({ ...pagination, page: 1 });
  };

  const deleteMultiBiddingData = (isAll, bid) => {
    const originalList = [...campaign.get('budget.publisher_rates')];
    let list = [];
    if (isAll) {
      if (searchText) {
        multiData.forEach((a) => originalList.splice(getCampaignBiddingIndex(originalList, a), 1));
        saveCampaignMultiData(originalList, campaign);
        handleBiddingInputWhenDelete(campaign, multiData);
        setPreviousRates(originalList);
        setMultiData([]);
        list = originalList;
      } else {
        saveCampaignMultiData([], campaign);
        handleBiddingInputWhenDeleteAll(campaign);
        setPreviousRates([]);
        setMultiData([]);
        list = [];
      }
      setPagination({ ...pagination, page: 1 });
    } else {
      const _data = [...multiData];
      // if add row, need not remove original bidding
      if (!bid[KEY_INVALID]) {
        originalList.splice(getCampaignBiddingIndex(originalList, bid), 1);
        saveCampaignMultiData(originalList, campaign);
        handleBiddingInputWhenDelete(campaign, [bid]);
        setPreviousRates(originalList);
        const deleteBidIndex = _data.findIndex((b) => {
          if (b[KEY_INVALID]) {
            return false;
          }
          return isSameBid(b, bid);
        });
        _data.splice(deleteBidIndex, 1);
      } else {
        _data.shift();
      }
      setMultiData(_data);

      list = originalList;
    }

    if (list.length) {
      validateBidRange(campaign, list.map((m) => ({ ...m, rate: Number(m.rate) })));
    } else {
      campaign.set('multibidding.range', null);
    }

    if (!list.length) {
      setCSVStatus(status.INITIAL);
    }
  };
  const updateRowData = (rowData, index) => {
    const data = [...multiData];
    Object.assign(rowData, { [KEY_NAME]: rowData[KEY_PUB_ID] === '*' ? '*' : rowData[KEY_NAME] });
    data.splice(index, 1, rowData);
    const originalList = [...campaign.get('budget.publisher_rates')];
    const originalIndex = getCampaignBiddingIndex(originalList, data[index]);
    if (originalIndex === -1) { // add row
      originalList.unshift(rowData);
    } else {
      originalList.splice(originalIndex, 1, rowData);
    }
    validateBidRange(campaign, originalList.map((m) => ({ ...m, rate: Number(m.rate) })));
    saveCampaignMultiData(originalList, campaign);
    handleBiddingInputWhenUpdate(campaign, rowData);
    setPreviousRates(originalList);
    setMultiData(data);
  };

  const inputValidator = useCallback((row, value, attr) => (
    isValidLongTailGeo(campaign, row[KEY_GEO], value)
    && campaignMultiDataValidate(value, attr, min, max)
  ), [min, max, campaign]);
  const invalidMessage = useCallback((row, value) => {
    const isLongTailValid = isValidLongTailGeo(campaign, row[KEY_GEO], value);
    return getRateErrorMsg(min, max, isLongTailValid);
  }, [min, max, campaign]);

  return (
    <CollapseSection
      id="multi-bidding"
      className={`${prefixClass}`}
      title={`Multi-Bidding Publishers Apps (${campaign.get('budget.publisher_rates').length})`}
      description="Set specific rates per Publisher Apps within your Campaign."
      tip={(
        <CSVLink
          className="download_link"
          filename={`CampaignId_${campaign.get('id')}_publisher_rates.csv`}
          data={csvExampleData}
          onClick={onExampleCsvClick}
        >
          Download Example CSV
        </CSVLink>
      )}
    >
      <InfoBar
        type="info"
        text={(
          <span>
            The publisher app IDs are passed on tracking links and are also viewable in publisher-level reporting.
            In-line editing as well as CSV upload is supported (maximum of &nbsp;
            <span>{config.get('multibidding.maxBidding') / 1000}</span>
            k rows). &nbsp;
            <a target="_blank" rel="noopener noreferrer" href="https://support.vungle.com/hc/en-us/articles/4413252621211#optional-multi-bidding-0-6">
              Learn all about multi-bidding here.
            </a>
          </span>
        )}
      />
      {(csvStatus !== status.UPLOADED || csvStatus === status.ERROR) ? (
        <div>
          {(csvStatus === status.INITIAL) ? (
            <div className="upload">
              <UploaderContainer
                type="csv"
                onStart={handleStartUpload}
                onComplete={(rates) => validRates({ rates, override: true })}
                onError={handleError}
              />
              <p className={`p2 ${prefixClass} upload-or`}>OR</p>
              <Button
                className={`${prefixClass} upload-add-row-btn`}
                type="ghost"
                onClick={generateNewRecord}
              >
                Add a Row
              </Button>
            </div>
          ) : (
            <div>
              <UploaderFile
                className="upload_file"
                file={csvFile}
                percent={csvPercent}
                error={!!errors}
                onChange={handleUploading}
                onClose={handleCancel}
              />
              {(csvStatus === status.ERROR && errors) && (
                <ErrorsCollapse errors={errors} />
              )}
            </div>
          )}
        </div>
      ) : (
        <div>
          <Filters
            className="filters"
            placeholder="Search by Publisher Name or by ID..."
            filename={`CampaignId_${campaign.get('id')}_publisher_rates.csv`}
            data={multiData}
            onInputChange={handleSearch}
            onAddRow={generateNewRecord}
            onStart={handleStartUpload}
            onComplete={validRates}
            onError={handleError}
            addRowDisable={shouldDisableAddRowButton(multiData)}
            showLinks={shouldShowFilter(multiData)}
          />
          <EditTable
            previousRates={previousRates}
            data={tableData}
            campaign={campaign}
            pagination={{ ...pagination, total: multiData.length }}
            onPageSizeChange={(page, perPage) => setPagination({ perPage, page })}
            onDelete={deleteMultiBiddingData}
            onUpdateRowData={updateRowData}
            rowValidator={(bid) => validateBiddingRow(multiData, campaign, bid, min, max)}
            inputValidator={inputValidator}
            invalidMessage={invalidMessage}
            onInlineEdit={(inline) => updatePageContinue({ enabled: !inline && !shouldDisableAddRowButton(multiData) })}
          />
        </div>
      )}
    </CollapseSection>
  );
};

export default React.memo(MultiBidding);
