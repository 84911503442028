import { useEffect } from 'react';

export default function useDebounce(fn, delay = 0, deps = []) {
  useEffect(() => {
    const handler = setTimeout(fn, delay);
    return () => clearTimeout(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
  return [];
}
