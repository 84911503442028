import React, { useState, useEffect } from 'react';
import Button from 'components/V2/Button/Button';
import { isCampaignObjectiveDynamic } from 'lib/capabilities/campaigns';
import { FORMAT_TYPE_FULLSCREEN, getCampaignCreativeFormatTypeDescription } from 'app/constants/campaign';
import AttachedCreativesBase from './AttachedCreativesBase';
import { updateCreative } from './helper';
import AttachedCreativesSlider from './AttachedCreativesSlider';
import './attatchedCreatives.scss';
import Drawer from '../../../../components/V2/Drawer/Drawer';

const AttachedCreatives = ({
  campaign,
  creatives = [],
  canEdit,
  updateSkcid,
  selectedCreativeIds,
  setSelectedCreativeIds,
  filterTypes = [],
}) => {
  const creativeType = campaign.get('creative_type');
  const modalType = isCampaignObjectiveDynamic(campaign)
    ? getCampaignCreativeFormatTypeDescription(FORMAT_TYPE_FULLSCREEN)
    : undefined;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedCreatives, setSelectedCreatives] = useState(selectedCreativeIds);

  useEffect(() => {
    setSelectedCreatives(selectedCreativeIds);
  }, [selectedCreativeIds]);

  const disableRowSelector = (creative) => {
    const id = creative?.get && creative.get('id');
    return !campaign.isMmpBilled() && campaign.get('is_skadnetwork_enabled') && !selectedCreativeIds.includes(id);
  };

  const selectCreative = (newSelectedIds) => {
    updateCreative({
      campaign,
      creatives,
      updateSkcid,
      newSelectedIds,
      selectedCreativeIds,
      setSelectedCreativeIds,
    });
  };

  const resetModal = () => {
    setSelectedCreatives(selectedCreativeIds);
  };

  const onConfirm = () => {
    selectCreative(selectedCreatives);
    setIsDrawerOpen(false);
    resetModal();
  };

  const onCancel = () => {
    setIsDrawerOpen(false);
    resetModal();
  };

  return (
    <div className="attached-creatives">
      <Drawer
        open={isDrawerOpen}
        onOpenChange={(isOpen) => {
          setIsDrawerOpen(isOpen);
          if (!isOpen) {
            onCancel();
          }
        }}
        position="right"
      >
        <AttachedCreativesSlider
          campaign={campaign}
          creatives={creatives}
          disableRowSelector={disableRowSelector}
          selectedIds={selectedCreatives}
          setSelectedIds={setSelectedCreatives}
          selectCreative={setSelectedCreatives}
          creativeType={creativeType}
          onConfirm={onConfirm}
          onCancel={onCancel}
          modalType={modalType}
        />
      </Drawer>

      <AttachedCreativesBase
        selectable={false}
        canEdit={canEdit}
        campaign={campaign}
        creatives={creatives.filter((c) => selectedCreativeIds.includes(c.get('id')))}
        disableRowSelector={disableRowSelector}
        disableUnselected={!campaign.get('schema_version') && campaign.get('is_skadnetwork_enabled') && !campaign.isMmpBilled()}
        selectedIds={selectedCreativeIds}
        selectCreative={selectCreative}
        filterTypes={filterTypes}
        deletable
        hideOnNoData
        hideFilter={selectedCreativeIds.length === 0}
      />

      <div className="attach-button-wrapper">
        <Button
          size="small"
          type="primary"
          onClick={setIsDrawerOpen}
        >
          <i className="material-icons">add_circle_outline</i>
          Attach Creatives
        </Button>
      </div>
    </div>
  );
};

export default AttachedCreatives;
