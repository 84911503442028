import React from 'react';
import './pagination.scss';

const PaginationComponent = ({
  onPageChange,
  onPageSizeChange,
  page,
  pages,
  pageSize,
  showPageSizeOptions,
  pageSizeOptions,
  sortedData,
}) => {
  const currentPage = page + 1;
  return (
    sortedData.length > 0 && pages > 1 && (
    <div className="components__table__pagination">
      <nav className="f fh fv">

        {currentPage > 1 && (
        <i className="material-icons" onClick={() => onPageChange(page - 1, pageSize)}>keyboard_arrow_left</i>
        )}

        {currentPage > 1 && (
        <div className="next-page f fh fv p2" onClick={() => onPageChange(0, pageSize)}><span>{1}</span></div>
        )}

        {(currentPage - 2) > 1 && (
        <span className="elip p2">...</span>
        )}

        {(currentPage - 1) > 1 && (
        <div className="next-page f fh fv p2" onClick={() => onPageChange(page - 1, pageSize)}><span>{currentPage - 1}</span></div>
        )}

        <div className="current-page f fh fv"><span>{currentPage}</span></div>

        {(currentPage + 1) < pages && (
        <div className="next-page f fh fv p2" onClick={() => onPageChange(page + 1, pageSize)}><span>{currentPage + 1}</span></div>
        )}

        {(currentPage + 2) < pages && (
        <span className="elip p2">...</span>
        )}

        {currentPage < pages && (
        <div className="next-page f fh fv p2" onClick={() => onPageChange(pages - 1, pageSize)}><span>{pages}</span></div>
        )}

        {currentPage < pages && (
        <i className="material-icons" onClick={() => onPageChange(page + 1, pageSize)}>keyboard_arrow_right</i>
        )}

      </nav>
      {showPageSizeOptions
      && (
      <div className="page-select">
        <select
          value={pageSize}
          onChange={(e) => {
            const w = (e.target.value.toString().length * 8) + 22;
            e.target.style.width = `${w}px`;
            onPageSizeChange(e.target.value * 1);
          }}
        >
          {pageSizeOptions.map((size) => (
            <option key={size}>{size}</option>
          ))}
        </select>
        <i className="material-icons">keyboard_arrow_down</i>
      </div>
      )}
    </div>
    )
  );
};

export default PaginationComponent;
