import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import { noop } from 'lodash';
import './pickerDate.scss';

export const prefixCls = 'v2_component_report_calendar_picker_date';

function PickerDate({
  month,
  startDate,
  endDate,
  previewDate,
  onChange,
  onMouseEnter,
  onMouseLeave,
}) {
  // check is in the left side
  const isLeftSide = React.useCallback(({ date, day, week }) => {
    if (!moment.isMoment(date)) {
      return false;
    }
    return week === 0 || day === 1;
  }, []);

  // check is in the right side
  const isRightSide = React.useCallback(({ date, day, week }) => {
    if (!moment.isMoment(date)) {
      return false;
    }
    return week === 6 || day === date.clone().endOf('month').date();
  }, []);

  // check is start date
  const isStart = React.useCallback(({ date }) => {
    if (!moment.isMoment(startDate)) {
      return false;
    }
    if (!moment.isMoment(date)) {
      return false;
    }
    return date.isSame(startDate, 'day');
  }, [startDate]);

  // check is start of selected date range
  const isSelectedRangeStart = React.useCallback(({ date }) => {
    if (!moment.isMoment(startDate) || !moment.isMoment(endDate)) {
      return false;
    }
    if (!moment.isMoment(date)) {
      return false;
    }
    return date.isSame(startDate, 'day');
  }, [startDate, endDate]);

  // check is start of preview date range
  const isPreviewRangeStart = React.useCallback(({ date }) => {
    if (!moment.isMoment(startDate) || !moment.isMoment(previewDate)) {
      return false;
    }
    if (!moment.isMoment(date)) {
      return false;
    }
    if (previewDate.isBefore(startDate)) {
      return date.isSame(previewDate, 'day');
    }
    return date.isSame(startDate, 'day');
  }, [startDate, previewDate]);

  // check is end of selected date range
  const isSelectedRangeEnd = React.useCallback(({ date }) => {
    if (!moment.isMoment(endDate)) {
      return false;
    }
    if (!moment.isMoment(date)) {
      return false;
    }
    return date.isSame(endDate, 'day');
  }, [endDate]);

  // check is end of preview date range
  const isPreviewRangeEnd = React.useCallback(({ date }) => {
    if (!moment.isMoment(startDate) || !moment.isMoment(previewDate)) {
      return false;
    }
    if (!moment.isMoment(date)) {
      return false;
    }
    if (previewDate.isAfter(startDate)) {
      return date.isSame(previewDate, 'day');
    }
    return date.isSame(startDate, 'day');
  }, [startDate, previewDate]);

  // check is in selected date range
  const isSelectedInRange = React.useCallback(({ date }) => {
    if (!moment.isMoment(startDate) || !moment.isMoment(endDate)) {
      return false;
    }
    if (!moment.isMoment(date)) {
      return false;
    }
    return date.isSameOrAfter(startDate, 'day') && date.isSameOrBefore(endDate, 'day');
  }, [startDate, endDate]);

  // check is in preview date range
  const isPreviewInRange = React.useCallback(({ date }) => {
    if (!moment.isMoment(startDate) || !moment.isMoment(previewDate)) {
      return false;
    }
    if (!moment.isMoment(date)) {
      return false;
    }
    if (previewDate.isAfter(startDate)) {
      return date.isSameOrAfter(startDate, 'day') && date.isSameOrBefore(previewDate, 'day');
    }
    return date.isSameOrAfter(previewDate, 'day') && date.isSameOrBefore(startDate, 'day');
    // --> potential bug inside this useCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, previewDate]);

  return (
    <div className={prefixCls}>
      <div className={`${prefixCls}-head`}>
        <div className={`${prefixCls}-head-weeks`}>
          <div className={`${prefixCls}-head-week`}>SUN</div>
          <div className={`${prefixCls}-head-week`}>MON</div>
          <div className={`${prefixCls}-head-week`}>TUE</div>
          <div className={`${prefixCls}-head-week`}>WED</div>
          <div className={`${prefixCls}-head-week`}>THU</div>
          <div className={`${prefixCls}-head-week`}>FRI</div>
          <div className={`${prefixCls}-head-week`}>SAT</div>
        </div>
      </div>
      {
        month.map((m, key) => (
          <div className={`${prefixCls}-month`} key={key}>
            {key !== 0 && <div className={`${prefixCls}-week`}>{m.month}</div>}
            <div className={`${prefixCls}-days`}>
              {
                m.days.map((day, dayKey) => (
                  <div
                    key={dayKey}
                    className={classnames(
                      `${prefixCls}-day`,
                      {
                        [`${prefixCls}-day-pad`]: !day.day,
                        [`${prefixCls}-day-today`]: day.today,
                        [`${prefixCls}-day-disable`]: day.disable,
                        [`${prefixCls}-day-left`]: isLeftSide(day),
                        [`${prefixCls}-day-right`]: isRightSide(day),
                        [`${prefixCls}-day-start`]: isStart(day),
                        [`${prefixCls}-day-selected-start`]: isSelectedRangeStart(day),
                        [`${prefixCls}-day-selected-range`]: isSelectedInRange(day),
                        [`${prefixCls}-day-selected-end`]: isSelectedRangeEnd(day),
                        [`${prefixCls}-day-preview-start`]: isPreviewRangeStart(day),
                        [`${prefixCls}-day-preview-range`]: isPreviewInRange(day),
                        [`${prefixCls}-day-preview-end`]: isPreviewRangeEnd(day),
                      },
                    )}
                    onMouseLeave={onMouseLeave}
                    onMouseEnter={() => onMouseEnter(day)}
                  >
                    <div
                      className={`${prefixCls}-day-wrapper`}
                      onClick={() => onChange(day)}
                    >
                      <div className={`${prefixCls}-day-text`}>
                        {day.day}
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        ))
      }
    </div>
  );
}

PickerDate.propTypes = {
  month: PropTypes.arrayOf(PropTypes.any),
  startDate: PropTypes.objectOf(PropTypes.any),
  endDate: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
  previewDate: PropTypes.objectOf(PropTypes.any),
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

PickerDate.defaultProps = {
  month: [],
  startDate: null,
  endDate: null,
  previewDate: null,
  onChange: noop,
  onMouseEnter: noop,
  onMouseLeave: noop,
};

export default React.memo(PickerDate);
