import {
  validateBidding,
  validateBidding as handleValidateBidding,
} from '../../lib/helpers/campaigns/campaignIndex';

const APPLICATION_BASE = '/applications';
const CAMPAIGN_BASE = '/campaigns/:edit';
const CREATIVES_BASE = '/creatives/:edit';
const REPORTS_BASE = '/reports';

const getRenderedPath = (path, id) => path.pathString.replace(':edit', id);

const paths = {
  ROOT: {
    pathString: '/',
    getPath: () => paths.ROOT.pathString,
  },
  ACCOUNT: {
    pathString: '/account',
    getPath: () => paths.ACCOUNT.pathString,
  },
  APPLICATIONS: {
    pathString: APPLICATION_BASE,
    getPath: () => paths.APPLICATIONS.pathString,
  },
  APPLICATION_CAMPAIGN_CREATIVE_LIST: {
    pathString: `${APPLICATION_BASE}/:id`,
    getPath: (id) => paths.APPLICATION_CAMPAIGN_CREATIVE_LIST.pathString.replace(':id', id),
  },
  APPLICATION_CAMPAIGN_TO_CREATIVE_MAPPINGS: {
    pathString: `${APPLICATION_BASE}/:appId/campaign_mappings`,
    getPath: (appId) => paths.APPLICATION_CAMPAIGN_TO_CREATIVE_MAPPINGS.pathString.replace(':appId', appId),
  },
  CAMPAIGN: {
    pathString: CAMPAIGN_BASE,
    getPath: (id = '') => getRenderedPath(paths.CAMPAIGN, id),
  },
  CAMPAIGN_CREATE: {
    pathString: CAMPAIGN_BASE,
    getPath: () => getRenderedPath(paths.CAMPAIGN_CREATE, 'create'),
  },
  CAMPAIGN_DETAILS: {
    pathString: `${CAMPAIGN_BASE}/details`,
    getPath: (id = 'create') => getRenderedPath(paths.CAMPAIGN_DETAILS, id),
  },
  CAMPAIGN_APPLICATION: {
    pathString: `${CAMPAIGN_BASE}/application`,
    getPath: (id = 'create') => getRenderedPath(paths.CAMPAIGN_APPLICATION, id),
  },
  CAMPAIGN_SKENABLEMENT: {
    pathString: `${CAMPAIGN_BASE}/skadnetwork`,
    getPath: (id = 'create') => getRenderedPath(paths.CAMPAIGN_SKENABLEMENT, id),
  },
  CAMPAIGN_TRACKING: {
    pathString: `${CAMPAIGN_BASE}/tracking`,
    getPath: (id = 'create') => getRenderedPath(paths.CAMPAIGN_TRACKING, id),
  },
  CAMPAIGN_TARGETING: {
    pathString: `${CAMPAIGN_BASE}/targeting`,
    getPath: (id = 'create') => getRenderedPath(paths.CAMPAIGN_TARGETING, id),
  },
  CAMPAIGN_BIDDING: {
    pathString: `${CAMPAIGN_BASE}/bidding`,
    getPath: (id = 'create') => getRenderedPath(paths.CAMPAIGN_BIDDING, id),
  },
  CAMPAIGN_CREATIVES: {
    pathString: `${CAMPAIGN_BASE}/creatives`,
    getPath: (id = 'create') => getRenderedPath(paths.CAMPAIGN_CREATIVES, id),
  },
  CAMPAIGN_CONFIRM: {
    pathString: `${CAMPAIGN_BASE}/confirm`,
    getPath: (id = 'create') => getRenderedPath(paths.CAMPAIGN_CONFIRM, id),
  },
  CREATIVE: {
    pathString: CREATIVES_BASE,
    getPath: (id = '') => getRenderedPath(paths.CREATIVE, id),
  },
  CREATIVE_APPLICATION: {
    pathString: `${CREATIVES_BASE}/application`,
    getPath: (id = '') => getRenderedPath(paths.CREATIVE_APPLICATION, id),
  },
  CREATE_CREATIVES: {
    pathString: `${CREATIVES_BASE}/creatives`,
    getPath: () => getRenderedPath(paths.CREATE_CREATIVES, 'create'),
  },
  CREATIVE_CAMPAIGNS: {
    pathString: `${CREATIVES_BASE}/campaigns`,
    getPath: () => getRenderedPath(paths.CREATE_CREATIVES, 'campaigns'),
  },
  TEAM: {
    pathString: '/team',
    getPath: () => paths.TEAM.pathString,
  },
  REPORT: {
    pathString: REPORTS_BASE,
    getPath: () => paths.REPORT.pathString,
  },
  ASSETS: {
    pathString: '/assets',
    getPath: () => paths.ASSETS.pathString,
  },
};

export default paths;

export const getPath = (location) => {
  const pathSegments = location.pathname.split('/');
  const path = pathSegments[3];
  return {
    path: path || 'application',
    url: `/${pathSegments[1]}/${pathSegments[2]}`,
  };
};

/**
 * Yet another one of these --> routing has been broken for existing campaigns
 *  (the path includes /create) so this will avoid disturbing that, for now
 */
export const getCampaignFlowStartPage = (application) => (
  application.isSKAdNetworkEnabled()
    ? paths.CAMPAIGN_SKENABLEMENT.getPath()
    : paths.CAMPAIGN_DETAILS.getPath()
);

export const getNextRoute = (campaign, confTo, pathname, validate) => {
  if (validate || (pathname === paths.CREATE_CREATIVES.getPath())) {
    if (!campaign.get('application')) {
      return 'application';
    }
    if (!campaign.validateTrackingEvents()) {
      return 'tracking';
    }
    if (!campaign.validateTargeting()) {
      return 'targeting';
    }
    if (!handleValidateBidding(campaign)) {
      return 'bidding';
    }
    if (!campaign.validateCreatives()) {
      return 'creatives';
    }
  }
  return confTo;
};

export const pathConfig = {
  application: {
    enabled: () => true,
    to: 'details',
  },
  skadnetwork: {
    enabled: 'isSKAdNetworkCapable',
    to: 'details',
  },
  details: {
    enabled: 'validateDetail',
    to: 'tracking',
  },
  tracking: {
    enabled: 'validateTrackingEvents',
    to: 'bidding',
  },
  bidding: {
    enabled: (c) => validateBidding(c),
    to: 'targeting',
  },
  targeting: {
    enabled: 'validateTargeting',
    to: 'creatives',
  },
  creatives: {
    enabled: 'validateCreatives',
    final: 'canConfirm',
    to: 'confirm',
  },
};
