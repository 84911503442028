import moment from 'moment';
import 'moment-timezone';
import {
  FILTER_KEY_DATE,
  FILTER_KEY_SEARCH,
  FILTER_KEY_PLATFORM,
  FILTER_KEY_APPLICATION_STATUS,
} from 'app/constants/filters';
import {
  QUICK_MAP,
  QUICK_KEY_LAST_7_DAYS,
} from 'components/V2/Report/Calendar/PickerQuick';

export const filterKeys = [
  FILTER_KEY_PLATFORM,
  FILTER_KEY_APPLICATION_STATUS,
];

export const EXTRA_QUERY_KEYS = [
  FILTER_KEY_SEARCH,
  FILTER_KEY_PLATFORM,
  FILTER_KEY_APPLICATION_STATUS,
];

export const COLUMN_APPLICATION_NAME = 'name';

export const COLUMN_APPLICATION_ID = 'id';
export const COLUMN_APPLICATION_STATUS = 'status';
export const COLUMN_APP_STORE_ID = 'storeId';
export const COLUMN_ACCOUNT = 'accountName';
export const COLUMN_CAMPAIGNS = 'campaignCount';
export const COLUMN_CREATIVES = 'creativeCount';
export const COLUMN_DATE_CREATED = 'created';
export const COLUMN_DATE_UPDATED = 'updated';

export const TABLE_ACTION_EDIT_APPLICATION = 'Edit Application';
export const TABLE_ACTION_VIEW_REPORTS = 'View Reports';

export const defaultSort = {
  id: COLUMN_DATE_CREATED,
  desc: true,
};

export const defaultFilterCache = {
  [FILTER_KEY_DATE]: {
    quickKey: QUICK_KEY_LAST_7_DAYS,
    ...QUICK_MAP[QUICK_KEY_LAST_7_DAYS].generateDate(moment()),
  },
  [FILTER_KEY_PLATFORM]: [],
  [FILTER_KEY_APPLICATION_STATUS]: [],
};
