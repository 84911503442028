import React, { useState } from 'react';
import classnames from 'classnames';
import CollapseSummary from './CollapseSummary';
import CollapseDetail from './CollapseDetail';

function CollapseBase({
  summary,
  detail,
  prefixIcon,
  expanded = false,
}) {
  const [isExpanded, setIsExpanded] = useState(expanded);

  return (
    <div>
      <CollapseSummary
        expanded={isExpanded}
        className="summary"
        prefixIcon={prefixIcon}
        onClick={setIsExpanded}
      >
        {summary}
      </CollapseSummary>
      {isExpanded && <span className="separator" />}
      <CollapseDetail
        className={classnames('p6', 'detail')}
        expanded={isExpanded}
      >
        {detail}
      </CollapseDetail>
    </div>
  );
}

export default CollapseBase;
