import React, { useState, useEffect, useCallback } from 'react';
import { noop } from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './textArea.scss';

const classPrefix = 'v2_components_textarea';

const TextArea = ({
  value, onChange, disabled, placeholder,
  error, allowClear, maxLength, onKeyUp,
}) => {
  const [innerValue, setInnerValue] = useState(value || '');
  const classes = classnames(classPrefix, {
    [`${classPrefix}-disabled`]: disabled,
    [`${classPrefix}-error`]: error,
  });
  useEffect(() => {
    setInnerValue(value || '');
  }, [value]);

  const onTextChange = useCallback((e) => {
    const { value: text } = e.target;
    setInnerValue(text);
    if (onChange) onChange(text, e);
  }, [onChange]);

  const handleReset = (e) => {
    setInnerValue('');
    if (onChange) onChange('', e);
  };

  const makeClearIcon = useCallback(() => {
    if (
      !allowClear
      || disabled
      || innerValue === undefined
      || innerValue === null
      || innerValue === ''
    ) {
      return null;
    }
    const className = `material-icons ${classPrefix}-clear-icon`;
    return <i className={className} onClick={handleReset}>clear</i>;
    // --> potential bug inside this useCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowClear, disabled, innerValue]);

  return (
    <div className={classes}>
      <textarea
        disabled={disabled}
        onChange={onTextChange}
        value={innerValue}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        placeholder={placeholder}
        maxLength={maxLength}
        onKeyUp={onKeyUp}
      />
      {makeClearIcon()}
    </div>
  );
};

TextArea.propTypes = {
  /**
   * value for the textArea
   */
  value: PropTypes.string,
  /**
   * textArea text changed callback
   */
  onChange: PropTypes.func,
  /**
   * textArea keyup callback
   */
  onKeyUp: PropTypes.func,
  /**
   * show clear button if true, otherwise hide
   */
  allowClear: PropTypes.bool,
};
TextArea.defaultProps = {
  value: null,
  onChange: noop,
  onKeyUp: noop,
  allowClear: true,
};

export default TextArea;
