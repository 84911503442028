import React, {
  compose, Switch, Route, Redirect,
} from '../../../../app/app';
import Edit from './Edit/CreativesEditContainer';
import List from './List/ListContainer';
import './index.scss';

const baseRoute = '/creatives';

const Creatives = () => (
  <div className="views__creatives">
    <Switch>
      <Route exact path={baseRoute} component={List} />
      <Route exact path={`${baseRoute}/create`} component={Edit} />
      <Redirect to={baseRoute} />
    </Switch>
  </div>
);

export default compose(Creatives);
