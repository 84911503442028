import React from 'react';
import { CSVLink } from 'react-csv';
import { triggerModal } from '../../Modals/Content/triggerModal';
import Icon from '../../Icon/Icon';
import Uploader from '../../Uploads/UploaderContainer';
import DeviceListSearchable from '../../DeviceListSearchable/DeviceListSearchable';

const DeviceUploader = ({
  whitelistedModels,
  blacklistedModels,
  onComplete,
}) => {
  const data = blacklistedModels.length > 0 ? blacklistedModels : whitelistedModels;
  return (
    <div className="uploader">
      <Uploader
        type="csv"
        onComplete={(models) => onComplete(models)}
        icon={<Icon.GroupAdd />}
      />
      <span>Upload CSV File</span>
      { (blacklistedModels.length > 0 || whitelistedModels.length > 0) && (
        <>
          <CSVLink
            data={data.map((d) => ({ model: d.model }))}
            filename="models.csv"
            className="csv-link"
            target="_blank"
          >
            {blacklistedModels.length > 0 && (
            <span>{`${blacklistedModels.length} models`}</span>
            )}
            {whitelistedModels.length > 0 && (
            <span>{`${whitelistedModels.length} models`}</span>
            )}
          </CSVLink>
          <Icon.View
            onClick={() => triggerModal({
              title: 'Device Models',
              render: (
                <DeviceListSearchable
                  deviceList={data}
                />
              ),
            })}
          />
        </>
      )}
    </div>
  );
};

export default DeviceUploader;
