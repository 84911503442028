import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { splashStart, splashFinish } from '../../../../app/app';
import Table from '../../Table/Table';
import Icon from '../../../Icon/Icon';
import Link from '../../../Link/Link';
import Pulsation from '../../Pulsation/Pulsation';
import Label from '../../Label/Label';
import TooltipCutoff from '../../Tooltip/TooltipCutoff';
import Tooltip from '../../Tooltip/Tooltip';
import { ServingStatusComponent as CampaignServingStatus } from '../../../Campaigns/ServingStatus/ServingStatus';
import CreativeServingStatus from '../../../Creatives/ServingStatus/ServingStatus';
import triggerConfirm from '../../../Modals/ConfirmAction/triggerConfirm';
import dataTabs from '../data/Tabs';
import { renderName } from './helper';
import './DataTable.scss';
import { canModifyCampaigns, canModifyCreatives } from '../../../../lib/helpers/authUser';

const makeColumn = (title, accessor) => ({
  Header: title,
  id: accessor,
  accessor: (c) => (c.raw ? c.raw(accessor) : c),
});

const returnColumnId = (key) => {
  if (key === 'application' || key === 'campaign' || key === 'creative' || key === 'publisher') {
    return 'name';
  }
  return key;
};

export const changeCampaignStatus = async ({ campaign, status }) => {
  splashStart('changing campaign state');
  try {
    await campaign.changeStatus(status);
  } finally {
    splashFinish('success!');
  }
};

export const changeCreativeStatus = async ({ creative, status }) => {
  splashStart('changing creative state');
  try {
    await creative.changeStatus(status);
  } finally {
    splashFinish('success!');
  }
};

export const renderCampaignServingStatus = (campaignModel, pulsation) => (
  <CampaignServingStatus
    campaign={campaignModel}
    showTooltip={canModifyCampaigns()}
    disableLinks={!campaignModel.isValidForEditing()}
    changeServingStatus={() => {
      triggerConfirm({
        type: 'ACTIVATE_CAMPAIGN_CONFIRM_ACTION',
        header: 'Activate Campaign',
        message: 'Are you sure you want to activate this Campaign?',
        name: campaignModel.get('name'),
        onConfirm: () => changeCampaignStatus({ campaign: campaignModel, status: 'activate' }),
      });
    }}
  >
    {pulsation}
  </CampaignServingStatus>
);

export const renderCreativeServingStatus = (creativeModel, pulsation) => (
  <CreativeServingStatus
    creative={creativeModel}
    changeStatus={() => {
      triggerConfirm({
        type: 'ACTIVATE_CREATIVE_CONFIRM_ACTION',
        header: 'Activate Creative',
        message: 'Are you sure you want to activate this Creative?',
        name: creativeModel.get('name'),
        onConfirm: () => changeCreativeStatus({ creative: creativeModel, status: 'activate' }),
      });
    }}
    showTooltip={canModifyCreatives()}
  >
    {pulsation}
  </CreativeServingStatus>
);

const DataTable = ({
  account, loading, tabKey, totalData, data = [], metrics = [], pagination, onPageChange, onSorted, tableSorted,
}) => {
  const currentTab = dataTabs.find((tab) => tab.key === tabKey);
  const handlePageSizeChange = (page, perPage) => {
    if (loading) return;
    onPageChange(page, perPage);
  };
  const dataColumns = metrics.map((metric) => ({
    ...makeColumn(metric.name, metric.key),
    defaultSortDesc: true,
    Header: (
      <Tooltip className="v2_reporting_page_tooltip" title={metric.name} body={metric.content}>
        <span className="reports_table_metric_title">{metric.name}</span>
      </Tooltip>
    ),
    Cell: function Cell({ original }) {
      const m = original.metrics || original;
      const d = metric.getter ? metric.getter(m) : m[metric.key];
      return <span>{metric.render(d)}</span>;
    },
  }));
  let columns = [
    {
      ...makeColumn(currentTab.title, tabKey),
      fixed: 'left',
      width: 220,
      id: returnColumnId(tabKey),
      Cell: function Cell({ original }) {
        if (original.total) {
          return <span>{original.total}</span>;
        }
        const currentHeadColumnData = original[tabKey] || {};
        if (tabKey === 'application' || tabKey === 'campaign' || tabKey === 'creative' || tabKey === 'publisher') {
          const {
            name, platform, thumbnail, isActive, id, dataModel,
          } = currentHeadColumnData;
          const pulsation = (
            <Pulsation
              editIcon="play_arrow"
              prefixCls="pulsation v2_component_pulsation"
              status={isActive ? 'active' : 'inactive'}
            />
          );
          const renderPulsation = () => {
            if (tabKey === 'campaign') {
              return renderCampaignServingStatus(dataModel, pulsation);
            }
            if (tabKey === 'creative') {
              return renderCreativeServingStatus(dataModel, pulsation);
            }
            return pulsation;
          };
          return (
            <div className="tabel_application">
              <div className="thumbnailWrap">
                <img
                  className="thumbnail"
                  src={thumbnail}
                />
              </div>
              <div className={`iconWrap iconWrap_${platform?.toLowerCase()}`}>
                {Icon.forPlatform(platform?.toLowerCase())}
              </div>
              {tabKey !== 'publisher' && renderPulsation()}
              <div className="table_name">
                <TooltipCutoff
                  title={name}
                  placement="bottomLeft"
                  titleWrapper={(title) => renderName(tabKey, id, title, account, original)}
                />
              </div>
            </div>
          );
        }
        if (tabKey === 'country') {
          return (
            <TooltipCutoff
              title={`${currentHeadColumnData.name} (${currentHeadColumnData.code})`}
              placement="bottomLeft"
              titleWrapper={(title) => <span className="country">{title}</span>}
            />
          );
        }
        if (tabKey === 'date') {
          return (
            <TooltipCutoff
              title={moment(currentHeadColumnData).format('DD MMM YYYY')}
              placement="bottomLeft"
              titleWrapper={(title) => <span className="date">{title}</span>}
            />
          );
        }
        if (tabKey === 'platform') {
          return (
            <div className="tabel_application">
              <div className={`iconWrap iconWrap_${currentHeadColumnData?.toLowerCase()}`}>
                {Icon.forPlatform(currentHeadColumnData?.toLowerCase())}
              </div>
              <div className="table_name">
                <TooltipCutoff
                  title={currentHeadColumnData}
                  placement="bottomLeft"
                />
              </div>
            </div>
          );
        }
      },
    },
  ];
  if (tabKey === 'publisher') {
    columns = columns.map((c) => ({
      ...c,
      Header: 'Publisher App Name',
    }));
    columns.push({
      ...makeColumn('Publisher App Vungle ID', 'id'),
      fixed: 'left',
      width: 150,
      Cell: function Cell({ original }) {
        return <TooltipCutoff title={original.publisher?.id} />;
      },
    });
  }
  const tableColumns = columns.concat(dataColumns);
  const tableData = totalData?.total ? [totalData, ...data] : data;
  return (
    <div className="reports_table">
      {tabKey === 'publisher' && (
        <Label type="option" className="reports_table-body-info">
          <div className="info-content">
            <i className={classNames('material-icons', 'info-icon')}>info_outline</i>
            <span>
              Use your Publisher level reporting to take part in Vungle&apos;s multi-bidding feature. Learn more
              {' '}
              <Link external to="https://support.vungle.com/hc/en-us/articles/4413252621211#optional-multi-bidding-0-6" className="body__link">here</Link>
              .
            </span>
          </div>
        </Label>
      )}
      <Table
        manual
        resizable
        sortable
        showPagination
        height="100%"
        loading={loading}
        page={pagination.page}
        total={pagination.total}
        pageSize={pagination.perPage}
        onPageSizeChange={handlePageSizeChange}
        onSorted={onSorted}
        data={tableData}
        columns={tableColumns}
        tableSorted={tableSorted}
      />
    </div>
  );
};

export default DataTable;
