import React, { useCallback } from 'react';
import Targeting from '../../../../../../components/V2/Targeting/Targeting';
import template from '../../../../../../components/V2/Targeting/templates/selfServeCampaignPublisher';
import { fetchPublisherPasteData } from '../../../../../../components/V2/Targeting/targetingHelper';
import {
  KEY_BLACKLIST,
  KEY_NO_TARGETING,
  KEY_WHITELIST,
  LABEL_BLACKLIST,
  LABEL_WHITELIST,
} from '../../../../../../components/V2/Targeting/constants';

function PublisherTargeting({ campaign, onPageContinue }) {
  const getAppsIds = useCallback(() => {
    if (!campaign || campaign.get('targeting.publisher.type') === 'none') {
      return [];
    }
    const idStr = campaign.get('targeting.publisher.applications.ids') || '';
    return idStr.length > 0 ? idStr.split(',') : [];
    // --> potential bug inside this useCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTypeChange = useCallback((newType) => {
    campaign.set('targeting.publisher.type', newType);
    // --> potential bug inside this useCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Targeting
      targeting="publisher"
      searchable={false}
      template={template}
      resource={campaign}
      type={campaign.get('targeting.publisher.type')}
      platform={campaign.get('application')?.get('platform')}
      onTypeChange={onTypeChange}
      getIds={getAppsIds}
      onDataChange={(apps) => campaign.set('targeting.publisher.applications.ids', apps.map((a) => a.id).join(','))}
      fetchPasteData={fetchPublisherPasteData}
      getAvailableApps={(apps) => (apps || [])}
      onPageContinue={onPageContinue}
      radioGroup={[
        { label: 'Do not target specific applications', id: KEY_NO_TARGETING },
        { label: LABEL_WHITELIST, id: KEY_WHITELIST },
        { label: LABEL_BLACKLIST, id: KEY_BLACKLIST },
      ]}
    />
  );
}

export default PublisherTargeting;
