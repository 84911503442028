import { filterLabelDicts } from './constant';

const utils = {
  isAllSelected(checkableData, checkedData, isBackend = false) {
    const selectedNum = (checkedData || []).length;
    if (selectedNum === 0) {
      return false;
    }
    return !isBackend && selectedNum === checkableData.length;
  },
  getFilterLabel(filterKey, checkableData, checkedData, isBackend = false, filterLabel) {
    const prefix = filterLabelDicts[filterKey];
    if (filterLabel) {
      return `${prefix}: ${filterLabel}`;
    }
    if (!checkedData || checkedData.length === 0) {
      return `${prefix}: All`;
    }
    if (utils.isAllSelected(checkableData, checkedData, isBackend)) {
      return `${prefix}: All`;
    }
    const selectedNum = (checkedData || []).length;
    return `${prefix}: ${selectedNum} Selected`;
  },
};

export default utils;
