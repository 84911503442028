import React, { useState, useEffect } from 'react';
import { CTRL_APPLICATION_METRIC_KEY, GROW_APPLICATION_METRIC_KEY } from 'lib/cache/constant';
import { Table } from 'components/V2';
import { defaultSort } from 'services/Templates/Applications/List/constants';
import { cleanupNonExistentColumnsFromCache } from 'components/V2/List/ListFilter/helper';
import {
  initMetrics,
  execTableAction,
  makeTableActions,
  metricsGroup,
  attributeMetrics,
  columnsFromMetrics,
  allColumns,
} from './helper';

const List = ({
  history,
  props: {
    authUser,
    applications = [],
    loadingApplications,
    pagination = {
      page: 1, pages: 0, perPage: 10, total: 0,
    },
  },
  isAdmin = false,
  refreshList,
  sortCache,
  metricCache,
  pageSizeCache,
}) => {
  const [metrics, setMetrics] = useState([]);

  useEffect(() => {
    cleanupNonExistentColumnsFromCache({
      metricCache,
      sortCache,
      columns: allColumns(isAdmin, authUser),
    });

    setMetrics(initMetrics(metricCache, attributeMetrics(isAdmin)));
  }, []);

  return (
    <Table
      className="table-with-total-container"
      maxHeight="100%"
      manual
      resizable
      loading={loadingApplications}
      showPagination
      actionable
      page={pagination.page}
      total={pagination.total}
      pageSize={pagination.perPage}
      data={applications}
      columns={columnsFromMetrics(metrics, isAdmin, authUser)}
      actionList={makeTableActions(isAdmin)}
      defaultSort={sortCache.get()[0] || defaultSort}
      metricType={isAdmin ? CTRL_APPLICATION_METRIC_KEY : GROW_APPLICATION_METRIC_KEY}
      metricGroup={metricsGroup(isAdmin)}
      metricUserId={authUser.id}
      metricOverlayHeight={560}
      onMetricsChange={setMetrics}
      onActionChange={(c, a) => execTableAction(a, c, history)}
      onPageSizeChange={(page, perPage) => {
        if (loadingApplications) {
          return;
        }
        pageSizeCache.save(perPage);
        refreshList({ page, perPage });
      }}
      onSorted={(v) => {
        sortCache.save(v);
        refreshList({ page: pagination.page });
      }}
    />
  );
};

export default List;
