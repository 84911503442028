import classnames from 'classnames';
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { contains } from '../../../lib/lib';
import Text from '../Text/TextContainer';
import Option from './Option';

const getChild = (children, value, placeholder) => {
  const child = React.Children.toArray(children).filter((c) => c.props.value === value).pop();
  if (!child) {
    return <p className="placeholder">{placeholder}</p>;
  }
  return React.cloneElement(child, {
    className: 'selector-selected',
    onClick: () => {},
  });
};

const Select = ({
  children,
  className = '',
  count,
  includeAllValue = false,
  includeSearch = false,
  onChange,
  onSearchValueChange,
  onToggle,
  optionHeight = 36,
  placeholder,
  searchValue = '',
  maxChildrenHeight = 4,
  showOptions,
  disabled = false,
  disabledText,
  value,
  isValid = true,
  isSingleOption = false,
}) => {
  const [selectedOptionHeight, setSelectedOptionHeight] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const optionContainerRef = useRef();

  const availableChildren = useMemo(() => React.Children.toArray(children).filter((child) => {
    if (!includeSearch) {
      return true;
    }
    return contains(child.props.searchValue, searchValue);
  }), [children, includeSearch, searchValue]);

  useEffect(() => {
    const options = optionContainerRef.current.querySelectorAll('.select-options-option:not(.select-options-option-hidden)');
    const visibleChildren = availableChildren.filter((x) => !x.props.hidden);
    const selectedOptionIndex = visibleChildren.findIndex((c) => c.props.value === value);
    let selectedHeight = optionHeight;
    if (selectedOptionIndex !== -1) {
      const selectedOption = options[selectedOptionIndex];
      selectedHeight = selectedOption.offsetHeight;
    }
    setSelectedOptionHeight(selectedHeight);
    if (showOptions) {
      const visibleOptionCount = visibleChildren.length;
      let optionCount = Math.min(maxChildrenHeight, visibleOptionCount);
      let height = selectedHeight;
      if (includeSearch && optionCount < maxChildrenHeight) {
        height = optionHeight * (maxChildrenHeight + 1);
      } else {
        while (optionCount > 0) {
          height += options[optionCount - 1].offsetHeight;
          optionCount -= 1;
        }
      }
      setContainerHeight(height);
    } else {
      setContainerHeight(selectedHeight);
    }
  }, [availableChildren, value, showOptions, maxChildrenHeight, optionHeight, includeSearch]);

  return (
    <div className="components__form__select" style={{ height: selectedOptionHeight }} onClick={(e) => showOptions && e.nativeEvent.stopImmediatePropagation()}>
      <div
        className={classnames(
          className,
          'select-container',
          {
            'select-container--disabled': disabled,
            'select-container--active': showOptions,
            'select-container--invalid': !isValid,
          },
        )}
        style={{ height: containerHeight }}
      >
        {disabled ? (
          <div className="selector selector--disabled" style={{ height: selectedOptionHeight }} onClick={() => !disabled && onToggle()}>
            <p className="disabled_text">{disabledText || 'Disabled'}</p>
            <i className="material-icons selector-icon-disabled">keyboard_arrow_down</i>
          </div>
        ) : (
          <div className="selector" style={{ height: selectedOptionHeight }} onClick={() => !disabled && onToggle()}>
            {value || value === null || value === '' || typeof value === 'boolean' ? (
              getChild(children, value, placeholder)
            ) : (
              <p className="placeholder">{placeholder}</p>
            )}
            {!isSingleOption && (
              <i className="material-icons selector-icon">
                {showOptions ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
              </i>
            )}
          </div>
        )}
        <div ref={optionContainerRef} className="select-options" style={{ height: containerHeight - selectedOptionHeight }}>
          <SimpleBar style={{ height: containerHeight - selectedOptionHeight }}>
            {includeAllValue && (
              <Option
                value="all"
                onClick={() => {
                  onToggle();
                  onChange();
                }}
                optionHeight={optionHeight}
              >
                <p style={{ flex: 1, textAlign: 'center' }}>-- all --</p>
              </Option>
            )}
            {includeSearch && (
              <div className="input__search">
                <Text.Search value={searchValue} onChange={onSearchValueChange} />
              </div>
            )}
            {availableChildren.map((child, index) => React.cloneElement(child, {
              key: index,
              showOptions,
              optionHeight,
              checked: child.props?.value === value,
              count: !includeAllValue ? count : count + 1,
              onClick: (v) => { onToggle(); onChange(v); },
            }))}
          </SimpleBar>
        </div>
      </div>
    </div>
  );
};

export default Select;
