import React from 'react';
import { ListSpinner } from 'components/V2/Spinner/Spinner';
import imgMagnifier from '../../assets/img/magnifier.svg';
import { isArray } from '../../lib/lib';
import ContextMenu from '../../components/ContextMenu/ContextMenuContainer';
import Link from '../../components/Link/Link';
import Checkbox from '../../components/Form/Checkbox/CheckboxContainer';
import './table.scss';

const cn = (...s) => ['template__table', ...s].join('__');
const mkStyle = (width, flex = 1, margin = 0) => ({ flex, minWidth: width, marginRight: margin });

const TableAction = ({
  icon, hover, onClick, to,
}) => (
  <div className="action" onClick={onClick}>
    <div className="hover-text"><span className="p2">{hover}</span></div>
    {to && (
      <Link to={to}><i className="material-icons">{icon}</i></Link>
    )}
    {!to && (
      <i className="material-icons">{icon}</i>
    )}
  </div>
);

const Table = ({
  allTitles, showTitles = true, rows, rowHeight = 64, loading, titles, widths, margins, flexes, checkedTitles,
  toggleTitle, noResults = false, includeStatus = true, includeActions = false, includeSettings = false,
}) => (
  <div className={cn()}>
    {noResults && (
      <div className="no-results">
        <img src={imgMagnifier} />
        <p className="h2">No Results</p>
        <p className="p2">Oh dang! Nothing found, please edit your search criteria and try again.</p>
      </div>
    )}
    {!noResults && (
      <div className={cn('container')}>
        {showTitles && (
          <div className={cn('header')}>
            <div className={cn('row')}>
              {titles.map((t, i) => (
                <div key={t} style={mkStyle(widths[i], flexes[i])} className={cn('cell')}>
                  <span className="p2">{t}</span>
                </div>
              ))}
              {includeStatus && (
                <div className={`${cn('cell')} ${cn('status')}`} style={mkStyle(80)}><span className="p2">&nbsp;</span></div>
              )}
              {includeActions && (
                <div className={`${cn('cell')}`} style={mkStyle(80)}><span className="p2">&nbsp;</span></div>
              )}
              {includeSettings && (
                <div className={`${cn('cell')} table-settings`} style={mkStyle(40, 0)}>
                  <i className="material-icons">settings</i>
                  <div className="cell-display-options">
                    {allTitles.map((t) => (
                      <Checkbox key={t} label={t} checked={checkedTitles.includes(t)} onChange={(v) => toggleTitle(v, t)} />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <div className={cn('body')}>
          {loading && <ListSpinner />}
          {!loading && rows.map(({ values, contexts = [], actions = [] }, j) => (
            <div key={j} className={cn('row')} style={{ height: rowHeight }}>
              {values.map((r, i) => (
                <div key={i} style={mkStyle(widths[i], flexes[i], margins[i])} className={cn('cell')}>
                  {React.isValidElement(r) ? r : (<span className="p2">{r}</span>)}
                </div>
              ))}
              {includeActions && (
                <div className="table-actions">
                  {actions.map(({
                    icon, hover, onClick, to,
                  }) => (
                    <TableAction key={icon} icon={icon} hover={hover} onClick={onClick} to={to} />
                  ))}
                </div>
              )}
              {includeStatus && (
                <div className={`${cn('cell')} ${cn('status')}`} style={mkStyle(80)}>
                  {contexts.length > 0 && (
                    <ContextMenu>
                      {contexts.map(({
                        text = '', onClick = () => {}, to = false, icon,
                      }) => {
                        if (to) {
                          return <ContextMenu.LinkOption key={text} {...{ icon, text, to }} />;
                        }
                        return <ContextMenu.ClickOption key={text} {...{ icon, onClick, text }} />;
                      })}
                    </ContextMenu>
                  )}
                </div>
              )}
              {includeSettings && (
                <div className={`${cn('cell')} table-settings`} style={mkStyle(40, 0)}><span className="p2">&nbsp;</span></div>
              )}
            </div>
          ))}
        </div>
      </div>
    )}
  </div>
);

class TableContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedTitles: props.titles.map(({ text }) => text),
    };
  }

  toggleTitle = (checked, title) => {
    let checkedTitles;
    if (checked) {
      checkedTitles = [...this.state.checkedTitles, title];
    } else {
      checkedTitles = this.state.checkedTitles.filter((t) => t !== title);
      if (checkedTitles.length === 0) {
        return;
      }
    }
    this.setState({ checkedTitles });
  }

  render() {
    const allTitles = [];
    const titles = [];
    const widths = [];
    const flexes = [];
    const margins = [];
    this.props.titles.forEach(({
      text = '', width = this.props.defaultCellWidth, flex = 1, margin = 0,
    }) => {
      allTitles.push(text);
      if (this.state.checkedTitles.includes(text)) {
        titles.push(text);
        widths.push(width);
        flexes.push(flex);
        margins.push(margin);
      }
    });
    const rows = !isArray(this.props.rows) ? [] : this.props.rows.map((row) => ({
      values: row.values.filter((v, i) => titles.includes(allTitles[i])),
      contexts: row.contexts,
      actions: row.actions,
    }));
    return (
      <Table
        {...this.props}
        allTitles={allTitles}
        checkedTitles={this.state.checkedTitles}
        flexes={flexes}
        margins={margins}
        rows={rows}
        titles={titles}
        toggleTitle={this.toggleTitle}
        widths={widths}
      />
    );
  }
}

TableContainer.defaultProps = {
  defaultCellWidth: 0,
  rows: [],
  titles: [],
};

export default TableContainer;
