import { getClient } from '../setup';
import {
  GetAccount,
  GetAccounts,
  ModifyAccount,
  AddAccountFund,
  AddFunds,
  AcceptTerms,
} from '../operations_account.graphql';
import { getSearchAndIDsFilter } from './helper';
import handleGraphQLError from './handle-graphql-error';

export const toAccountRestResponse = (account) => account && {
  ok: true,
  response: {
    id: account.id,
    type: account.demandType || 'developer',
    budget_settings: account.budgetSettings && {
      cpi: account.budgetSettings.cpi,
      cpm: account.budgetSettings.cpm,
      cpcv: account.budgetSettings.cpcv,
      cpv: account.budgetSettings.cpv,
      cpc: account.budgetSettings.cpc,
      cppu: account.budgetSettings.cppu,
      cpe: account.budgetSettings.cpe,
      total: account.budgetSettings.total,
      daily: account.budgetSettings.daily,
    },
    access_grow: account.permissions && account.permissions.accessGrowVungleCom,
    access_api: account.permissions && account.permissions.accessManageApi,
    access_application_maximum: account.permissions && account.permissions.accessApplicationMaximum,
    application_maximum: account.applicationMaximum,
    creativeMaximum: account.creativeMaximum,
    access_playable_adtype: account.permissions?.accessPlayableADType,
    access_native_adtype: account.permissions && account.permissions.accessNativeADType,
    modify_roas_campaigns: account.permissions?.modifyRoasCampaigns,
    isROASEnabledIOS: account.permissions?.isROASEnabledIOS,
    isROASEnabledAndroid: account.permissions?.isROASEnabledAndroid,
    modify_cppu_campaigns: account.permissions?.modifyCPPUCampaigns,
    isCPPUEnabledIOS: account.permissions?.isCPPUEnabledIOS,
    isCPPUEnabledAndroid: account.permissions?.isCPPUEnabledAndroid,
    modify_cpe_campaigns: account.permissions?.modifyCPECampaigns,
    isCPEEnabledIOS: account.permissions?.isCPEEnabledIOS,
    isCPEEnabledAndroid: account.permissions?.isCPEEnabledAndroid,
    billing_entity: account.billingEntity,
    budget_type: account.budgetType,
    name: account.name,
    notifications_email: account.notificationsEmail,
    is_approval_exempt_creatives: account.isApprovalExemptCreatives,
    contact: account.contact,
    address: account.address,
    budget_pacing: account.budgetPacing
      ? {
        is_enabled: account.budgetPacing.isEnabled,
        total_available: account.budgetPacing.totalAvailable || 0.0,
        total_spent: account.budgetPacing.totalSpent || 0.0,
      }
      : {
        is_enabled: true,
        total_available: 0.0,
        total_spent: 0.0,
      },
    io: account.io,
    applications: account.denyApplication,
    accounts: account.denyAccount,
    transactions: account.transactions || [],
  },
};

export const toGraphQLAccountRequest = (account) => {
  const {
    name,
    budget_settings: budgetSettings,
    notifications_email: notificationsEmail,
    budget_type: budgetType,
    billing_entity: billingEntity,
    contact,
    address,
    access_grow,
    access_api,
    access_application_maximum,
    access_playable_adtype,
    access_native_adtype,
    modify_roas_campaigns,
    isROASEnabledIOS,
    isROASEnabledAndroid,
    modify_cppu_campaigns,
    isCPPUEnabledIOS,
    isCPPUEnabledAndroid,
    modify_cpe_campaigns,
    isCPEEnabledIOS,
    isCPEEnabledAndroid,
    is_approval_exempt_creatives: isApprovalExemptCreatives,
    io,
    // eslint-disable-next-line camelcase
    type: demand_type,
    applications,
    accounts,
    budget_pacing,
    application_maximum,
    creativeMaximum,
  } = account;

  const denyApplication = {
    raw: applications,
  };

  const denyAccount = {
    raw: accounts,
  };

  const permissions = {
    accessGrowVungleCom: access_grow,
    accessManageApi: access_api,
    accessApplicationMaximum: access_application_maximum,
    accessPlayableADType: access_playable_adtype,
    modifyRoasCampaigns: modify_roas_campaigns,
    isROASEnabledIOS,
    isROASEnabledAndroid,
    modifyCPPUCampaigns: modify_cppu_campaigns,
    isCPPUEnabledIOS,
    isCPPUEnabledAndroid,
    modifyCPECampaigns: modify_cpe_campaigns,
    isCPEEnabledIOS,
    isCPEEnabledAndroid,
    accessNativeADType: access_native_adtype,
  };

  let budgetPacing = null;
  // eslint-disable-next-line camelcase
  if (budget_pacing) {
    budgetPacing = {
      isEnabled: budget_pacing.is_enabled,
    };
  }

  // eslint-disable-next-line camelcase
  const demandType = demand_type || 'developer';

  return {
    name,
    demandType,
    budgetSettings,
    notificationsEmail,
    budgetType,
    billingEntity,
    budgetPacing,
    contact,
    address,
    isApprovalExemptCreatives,
    denyApplication,
    denyAccount,
    permissions,
    io,
    applicationMaximum: application_maximum,
    creativeMaximum,
  };
};

export const handleAccountGraphQLResponse = ({
  data: {
    accounts,
  } = {},
}) => toAccountRestResponse(accounts.accounts[0]);

export const handleModifyAccountGraphQLResponse = ({
  data: {
    modifyAccount,
  } = {},
}) => toAccountRestResponse(modifyAccount);

export const handleAccountListResponse = ({
  data: {
    accounts,
  } = {},
}) => ({ response: accounts.accounts, ...accounts });

export const getAccount = (id) => getClient()
  .query({
    query: GetAccount,
    variables: {
      id,
    },
    errorPolicy: 'all',
  })
  .then(handleAccountGraphQLResponse)
  .catch(handleGraphQLError);

export const getAccountList = (query = {}) => getClient()
  .query({
    query: GetAccounts,
    variables: {
      ...getSearchAndIDsFilter(query),
      page: parseInt(query.page, 10),
      per_page: parseInt(query.perPage, 10),
      type: query.type,
    },
  })
  .then(handleAccountListResponse)
  .catch(handleGraphQLError);

export const modifyAccount = (id, accountServerObject) => getClient()
  .mutate({
    mutation: ModifyAccount,
    variables: {
      id,
      input: toGraphQLAccountRequest(accountServerObject),
    },
  })
  .then(handleModifyAccountGraphQLResponse)
  .catch(handleGraphQLError);

export const addAccountFund = (id, amount) => getClient()
  .mutate({
    mutation: AddAccountFund,
    variables: { id, amount },
  })
  .then(() => ({
    ok: true,
    status: 200,
    response: 'ok',
  }))
  .catch(handleGraphQLError);

export const addFunds = (id, token, amount, country) => getClient()
  .mutate({
    mutation: AddFunds,
    variables: {
      id, token, amount, country,
    },
  })
  .then(() => ({
    ok: true,
    status: 200,
    response: 'ok',
  }))
  .catch(handleGraphQLError);

export const acceptTerms = (id, terms) => getClient()
  .mutate({
    mutation: AcceptTerms,
    variables: { id, terms },
  })
  .then(() => ({
    ok: true,
    status: 200,
    response: 'ok',
  }))
  .catch(handleGraphQLError);
