import React from 'react';
import { RIEInput } from 'riek';
import { isEmptyObject } from '../../lib/lib';
import Table from '../Table/TableContainer';
import './editTable.scss';

const handleChangeEdit = (s, id, geo, ref, changeEditValue, changeRefVisibility, lockActionIcons) => {
  changeEditValue({
    id, geo, value: s.editValue, column: 'rate',
  });
  if (lockActionIcons) {
    changeRefVisibility(`${ref}-action-icons`, 'hidden');
  }
};

const makeEditableCell = ({
  id, geo, column, data, editValues, changeEditValue, changeRefVisibility, min, max,
  validateFunc, isCurrency = false, refColumns,
}, lockActionIcons) => {
  if (isEmptyObject(editValues)) {
    return null;
  }
  const ref = refColumns.map((c) => data[c]).join('|');
  return (
    <div className="edit_cell__container">
      {isCurrency && <span>$</span>}
      <RIEInput
        value={editValues[`${id + geo}`][column]}
        beforeStart={() => !lockActionIcons && changeRefVisibility(`${ref}-action-icons`, 'hidden')}
        change={(s) => handleChangeEdit(s, id, geo, ref, changeEditValue, changeRefVisibility, lockActionIcons)}
        propName="editValue"
        validate={(e) => validateFunc(e, min, max)}
        shouldRemainWhileInvalid
        classInvalid="validation_error"
      />
    </div>
  );
};

const handleGetTrProps = (state, rowInfo, column, refColumns, changeRefVisibility) => {
  const refKey = refColumns.map((c) => rowInfo.original[c]).join('|');
  return ({
    onMouseEnter: () => changeRefVisibility(`${refKey}-action-icons`, 'visible'),
    onMouseLeave: () => changeRefVisibility(`${refKey}-action-icons`, 'hidden'),
  });
};

const makeActionIcons = (actions, setRef, data, refColumns, lockActionIcons) => {
  const refKey = refColumns.map((c) => data[c]).join('|');
  return (
    <div
      className={`action_icons__container${lockActionIcons ? ' action_icons__container--locked' : ''}`}
      ref={(e) => setRef(`${refKey}-action-icons`, e)}
    >
      {actions.map(({ type, iconType, onClickHandler }) => (
        <div key={`${refKey}-${type}`} className="action_icon" onClick={() => onClickHandler(data)}>
          <i className="material-icons">{iconType}</i>
        </div>
      ))}
    </div>
  );
};

const makeColumn = (title, width = 0) => {
  const opts = {
    title,
    filterable: false,
    resizable: false,
    sortable: false,
    flex: 1,
  };
  if (width) {
    opts.minWidth = width;
  }
  return opts;
};

const EditTable = ({
  changeEditValue = () => {},
  data = [],
  editValues = {},
  editColumns = [],
  readOnly = false,
  columns = [],
  actions = [],
  refColumns = [],
  lockActionIcons = false,
  message = '',
}) => {
  const refs = {};
  const setRef = (refKey, e) => { refs[refKey] = e; };
  const changeRefVisibility = (refKey, value) => { refs[refKey].style.visibility = value; };
  const actionsData = !readOnly ? [
    {
      type: 'edit', iconType: 'edit', onClickHandler: () => {},
    },
    ...actions,
  ] : [];
  const indexedData = data.map((d, i) => ({ ...d, index: i }));
  return (
    <div className="components__edit_table">
      <Table
        defaultPageSize={1000}
        showPagination={false}
        data={indexedData}
        message={message}
        columns={[
          ...columns,
          ...editColumns.map(({
            key, label, min, max, validateFunc, isCurrency,
          }) => {
            const editableColumn = {
              ...makeColumn(label, 25),
              value: (d) => d[key],
              render: (d) => makeEditableCell({
                id: d.pub_app_id,
                geo: d.geo,
                column: key,
                data: d,
                editValues,
                changeEditValue,
                changeRefVisibility,
                min,
                max,
                validateFunc,
                isCurrency,
                refColumns,
              }, lockActionIcons),
            };
            return readOnly ? { ...makeColumn(label, 25), value: (d) => (isCurrency ? `$${d[key]}` : d[key]) } : editableColumn;
          }),
          {
            ...makeColumn('Actions', 25),
            titleHidden: true,
            render: (d) => makeActionIcons(actionsData, setRef, d, refColumns, lockActionIcons),
          },
        ]}
        getTrProps={(state, rowInfo, column) => !lockActionIcons && handleGetTrProps(state, rowInfo, column, refColumns, changeRefVisibility)}
      />
    </div>
  );
};

export default EditTable;
