import React from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { FILTER_KEY_ASSET } from 'app/constants/filters';
import MultiTreeBackend from '../Base/MultiTreeBackend/MultiTreeBackend';
import AssetPanel from '../Base/Panels/AssetPanel';
import { getDefaultCheckedNodes } from '../Base/Panels/helper';

const MultiTreeAsset = ({
  visible,
  searchParams,
  availableNodes,
  defaultCheckedNodes,
  dropdownFixed,
  onVisibleChange,
  onChange,
  onRemove,
}) => {
  const checkedNodes = getDefaultCheckedNodes(defaultCheckedNodes);
  return (
    <MultiTreeBackend
      dropdownFixed={dropdownFixed}
      visible={visible}
      filterKey={FILTER_KEY_ASSET}
      defaultCheckedNodes={checkedNodes}
      renderOverlay={() => (
        React.createElement(AssetPanel, {
          visible,
          searchParams,
          availableNodes,
          filterKey: FILTER_KEY_ASSET,
          defaultCheckedNodes: checkedNodes,
          onVisibleChange,
          onChange,
        })
      )}
      onVisibleChange={onVisibleChange}
      onChange={onChange}
      onRemove={onRemove}
    />
  );
};

MultiTreeAsset.propTypes = {
  dropdownFixed: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
  searchParams: PropTypes.objectOf(PropTypes.any),
  availableNodes: PropTypes.arrayOf(PropTypes.any),
  defaultCheckedNodes: PropTypes.arrayOf(PropTypes.any),
  onVisibleChange: PropTypes.func,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};

MultiTreeAsset.defaultProps = {
  searchParams: {},
  availableNodes: [],
  defaultCheckedNodes: [],
  dropdownFixed: false,
  onVisibleChange: noop,
  onChange: noop,
  onRemove: noop,
};

export default MultiTreeAsset;
