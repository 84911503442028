import React from 'react';
import { FILTER_KEY_CREATIVEQA_STATUS } from 'app/constants/filters';
import Multi from '../../../../../../../../components/V2/Filters/Base/Multi/Multi';
import CreativeQAStatusPanel from '../../../../../../../../components/V2/Filters/Base/Panels/CreativeQAStatusPanel';
import { assetStatuses } from '../../../../../../../../components/V2/Filters/constant';

const PlayableFilters = ({
  prefixCls,
  onChange,
}) => {
  const [selectedStatusKeys, setSelectedStatusKeys] = React.useState([]);
  const [visible, setVisible] = React.useState(false);

  const change = React.useCallback((_selectedStatuses, isAllSelected) => {
    let selectedIds = _selectedStatuses?.map((item) => item.id) || [];
    if (selectedIds.length === assetStatuses.length) {
      // select all === select none
      selectedIds = [];
    }
    setVisible(false);
    setSelectedStatusKeys(_selectedStatuses);
    onChange(selectedIds, isAllSelected);
  }, [onChange]);

  const onVisibleChange = (bool) => setVisible(bool);

  return (
    <Multi
      filterKey={FILTER_KEY_CREATIVEQA_STATUS}
      data={assetStatuses}
      visible={visible}
      onVisibleChange={onVisibleChange}
      defaultCheckedKeys={selectedStatusKeys}
      renderOverlay={() => (
        React.createElement(CreativeQAStatusPanel, {
          prefixCls,
          defaultCheckedNodes: selectedStatusKeys,
          onVisibleChange,
          onChange: change,
          data: assetStatuses,
          height: 318,
        })
      )}
    />
  );
};

export default PlayableFilters;
