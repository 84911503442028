/**
 * These constants are returned in the error response from the API.
 */
export default {
  ASSET_NAME_CONFLICT: 'asset-name-conflict',
  ASSET_BUNDLE_VALIDATION_FAILED: 'asset-bundle-validation-failed',
  ASSET_BUNDLE_UPLOAD_FAILED: 'asset-bundle-upload-failed',
  ASSET_BUNDLE_VALIDATION_SAVE_FAILED: 'asset-bundle-unable_to_save_file_to_validate',
  ASSET_BUNDLE_VALIDATION_WRONG_FORMAT_FAILED: 'asset-bundle-wrong_format',
  ASSET_BUNDLE_VALIDATION_MISSING_FORMAT_FAILED: 'asset-bundle-format_not_present',
  ASSET_BUNDLE_VALIDATION_EXTENSION_FAILED: 'asset-bundle-wrong_mime_type',
  ASSET_BUNDLE_VALIDATION_MISSING_FILE_FAILED: 'asset-bundle-no_file_present',
  ASSET_BUNDLE_VALIDATION_UNRESPONSIVE: 'asset-bundle-validation-unresponsive',
  ASPECT_RATIO_NOT_IN_RANGE_75_85: 'aspect-ratio-not-in-range-7.5-8.5',
  ASPECT_RATIO_NOT_IN_RANGE_55_65: 'aspect-ratio-not-in-range-5.5-6.5',
  UNEXPECTED: 'unexpected',
  FILE_TOO_LARGE: 'file-too-large',
  SAME_ASSET_EXISTS_UNDER_ANOTHER_ACCOUNT: 'same-asset-exists-under-another-account',
  SAME_ASSET_EXISTS_UNDER_THIS_APPLICATION: 'same-asset-exists-under-this-application',
  TEMPLATE_NO_CONFIG_FILE: 'no-config-file-found-in-the-template-zip-file',
};
