import React from 'react';
import Tooltip from '../../../../components/V2/Tooltip/Tooltip';
import './creativeTooltip.scss';

const CreativeTooltip = ({ title, body }) => (
  <Tooltip
    className="creative-builder-tooltip-dropdown"
    title={title}
    body={body}
    placement="topRight"
  >
    <i className="material-icons">help_outline</i>
  </Tooltip>
);

export default CreativeTooltip;
