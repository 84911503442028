import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { FILTER_KEY_ASSET_TYPE } from 'app/constants/filters';
import Multi from '../Base/Multi/Multi';
import AssetTypesPanel from '../Base/Panels/AssetTypesPanel';
import { assetTypesData } from '../constant';

const AssetTypes = ({
  dropdownFixed,
  prefixCls,
  visible,
  defaultCheckedNodes,
  onVisibleChange,
  onChange,
  onRemove,
}) => (
  <Multi
    data={assetTypesData}
    dropdownFixed={dropdownFixed}
    visible={visible}
    filterKey={FILTER_KEY_ASSET_TYPE}
    defaultCheckedKeys={defaultCheckedNodes}
    renderOverlay={() => (
      React.createElement(AssetTypesPanel, {
        prefixCls,
        defaultCheckedNodes,
        onVisibleChange,
        onChange,
      })
    )}
    onVisibleChange={onVisibleChange}
    onRemove={onRemove}
  />
);

AssetTypes.propTypes = {
  dropdownFixed: PropTypes.bool,
  prefixCls: PropTypes.string,
  visible: PropTypes.bool,
  defaultCheckedNodes: PropTypes.arrayOf(PropTypes.any),
  onVisibleChange: PropTypes.func,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};

AssetTypes.defaultProps = {
  dropdownFixed: false,
  prefixCls: 'v2_component_filter_panel_asset_types',
  visible: false,
  defaultCheckedNodes: [],
  onVisibleChange: noop,
  onChange: noop,
  onRemove: noop,
};

export default AssetTypes;
