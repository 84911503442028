import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import './inlineBox.scss';

const noop = () => { };

const InlineBox = (props) => {
  const {
    className,
    prefixPattern,
    validator,
    invalidMessage,
    disabled,
    required,
    value,
    inputValue,
    placeholder,
    onSave,
    onChange,
    type,
    ...other
  } = props;

  const prefixCls = 'v2_component_inline_box';
  const inputRef = React.useRef();

  const [saveDisabled, setSaveDisabled] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleEdit = () => {
    setSaveDisabled(false);
    setError(false);
    setOpen(true);
  };

  const isValid = (val) => {
    if (required && (typeof val === 'undefined' || val === '')) {
      return false;
    }
    if (validator instanceof RegExp) {
      return validator.test(val);
    }
    if (typeof validator === 'function') {
      return validator(val);
    }
    return true;
  };

  const isDirty = (val) => `${val}` !== `${inputValue}`;

  const checkErrorAndDisabled = (val) => {
    if (!isValid(val)) {
      setError(true);
      setSaveDisabled(true);
    } else {
      setError(false);
      setSaveDisabled(!isDirty(val));
    }
  };

  const handleInputChange = (event) => {
    const currentValue = event.target.value;
    checkErrorAndDisabled(currentValue);
    onChange(currentValue);
  };

  const handleSave = () => {
    const currentValue = inputRef.current.value;
    if (isDirty(currentValue) && isValid(currentValue)) {
      onSave(currentValue);
      setOpen(false);
    }
  };

  const handleRootClick = (event) => {
    event.nativeEvent.stopImmediatePropagation();
    event.stopPropagation();
  };

  const handleEnterSave = (event) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  React.useEffect(() => {
    if (!open) {
      return undefined;
    }

    // reset status and value
    checkErrorAndDisabled(inputValue);
    inputRef.current.value = inputValue;

    const handleClick = () => setOpen(false);

    const escClose = (event) => {
      if (event.key === 'Escape') {
        setOpen(false);
      }
    };

    document.addEventListener('click', handleClick);
    document.addEventListener('keyup', escClose);

    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('keyup', escClose);
    };
    // --> potential bug inside this useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, inputValue]);

  return (
    <div className={classNames(prefixCls, className)}>
      <div className={classNames(`${prefixCls}-text-button`, { [`${prefixCls}-text-button-disable`]: disabled })}>
        {!disabled && <i className="material-icons" onClick={handleEdit}>edit</i>}
        <span>{value}</span>
      </div>

      <div
        className={classNames(`${prefixCls}-popover`, {
          [`${prefixCls}-popover-disabled`]: !open,
        })}
        onClick={handleRootClick}
      >
        <div className={`${prefixCls}-popover-main`}>
          {prefixPattern && (
            <span
              className={classNames(`${prefixCls}-popover-span`, {
                [`${prefixCls}-popover-span-error`]: error,
              })}
            >
              {prefixPattern}
            </span>
          )}
          <input
            className={classNames(`${prefixCls}-popover-input`, {
              [`${prefixCls}-popover-input-error`]: error,
              [`${prefixCls}-popover-input-no-pattern`]: !prefixPattern,
            })}
            ref={inputRef}
            disabled={disabled}
            type={type}
            placeholder={placeholder}
            onChange={handleInputChange}
            onKeyUp={handleEnterSave}
            {...other.inputProps}
          />
          <Button
            className={`${prefixCls}-popover-button`}
            disabled={saveDisabled}
            type="primary"
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
        {error && <div className={`${prefixCls}-popover-invalid`}>{invalidMessage}</div>}
      </div>
    </div>
  );
};

InlineBox.propTypes = {
  /*
   * class name.
   */
  className: PropTypes.string,
  /**
   * Pattern display before input element.
   */
  prefixPattern: PropTypes.string,
  /**
   * Validate if input is correct.
   */
  validator: PropTypes.oneOfType([
    PropTypes.instanceOf(RegExp),
    PropTypes.func,
  ]),
  /**
   * invalid message
   */
  invalidMessage: PropTypes.string,
  /*
   * If `true`, content text can not be editted.
   */
  disabled: PropTypes.bool,
  /*
   * If `true`, input content can not be null.
   */
  required: PropTypes.bool,
  /*
   * Text display on label.
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.number,
  ]),
  /*
   * Placeholder display on `input`.
   */
  placeholder: PropTypes.string,
  /**
   * Call when input changed.
   */
  onChange: PropTypes.func,
  /**
   *  Trigger when click `save button`.
   *
   * @param {string} value input value.
   * @param {object} event button event.
   */
  onSave: PropTypes.func,
  /*
   * Text display on input.
   */
  inputValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.number,
  ]),
  /**
   * input type
   */
  type: PropTypes.string,
};

InlineBox.defaultProps = {
  className: null,
  prefixPattern: null,
  validator: null,
  invalidMessage: null,
  disabled: false,
  required: false,
  value: null,
  placeholder: null,
  onChange: noop,
  onSave: noop,
  inputValue: null,
  type: 'text',
};

export default InlineBox;
