import { visualQACheckBoxData } from '../../../config';
import { initValidVariations } from '../initQAState';

const downloadValues = {
  early: 'early',
  cta: 'cta',
  endscreen: 'endscreen',
  missing: 'missing',
};

const completeValues = {
  early: 'early',
  download: 'download',
  full: 'full',
  missing: 'missing',
};

const downloadValuesDisplay = {
  [downloadValues.early]: 'Download on 1st/2nd Interaction',
  [downloadValues.cta]: 'Download on CTA',
  [downloadValues.endscreen]: 'Download on Endscreen Tap',
  [downloadValues.missing]: 'Download Not Present',
};

const completeValuesDisplay = {
  [completeValues.early]: 'Complete on < 3 Interactions',
  [completeValues.download]: 'Complete triggers with download',
  [completeValues.full]: 'Complete on 3+ Interactions',
  [completeValues.missing]: 'Complete Not Present',
};

const selectData = [{
  label: 'Download Event (perceived via testing)',
  key: 'download',
  data: [{
    name: downloadValuesDisplay[downloadValues.early],
    value: downloadValues.early,
  }, {
    name: downloadValuesDisplay[downloadValues.cta],
    value: downloadValues.cta,
  }, {
    name: downloadValuesDisplay[downloadValues.endscreen],
    value: downloadValues.endscreen,
  }, {
    name: downloadValuesDisplay[downloadValues.missing],
    value: downloadValues.missing,
  }],
}, {
  label: 'Complete Event (perceived via testing)',
  key: 'complete',
  data: [{
    name: completeValuesDisplay[completeValues.early],
    value: completeValues.early,
  }, {
    name: completeValuesDisplay[completeValues.download],
    value: completeValues.download,
  }, {
    name: completeValuesDisplay[completeValues.full],
    value: completeValues.full,
  }, {
    name: completeValuesDisplay[completeValues.missing],
    value: completeValues.missing,
  }],
}];

const link = (text, href) => `<a href=${href} target='blank'>${text}</a>`;
const ASOI_LINK = link('Learn more about ASOI here.', 'https://support.vungle.com/hc/en-us/articles/360056671952-Call-the-Vungle-SDK-and-ASO');
const FSC_LINK = link('Learn more about FSC here.', 'https://support.vungle.com/hc/en-us/articles/360061060131');
const EVENTS_LINK = link('Learn more about events here.', 'https://support.vungle.com/hc/en-us/articles/360057120251-Customize-the-Adaptive-Creative-Experience');

const MAJOR_ISSUES = `
<p class='creativeQA_alert_help-line'>This asset requires one or more major corrections. 
Please fix all the issues listed above and replace the originally uploaded asset file.</p>
<p>Refer to these help articles about compliance with Vungle standards:</p>
<ul class='creativeQA_alert_help-list'>
  <li>${link('Get Started with Creative: Adaptive Creative', 'https://support.vungle.com/hc/en-us/articles/360035348091')}</li>
  <li>${link('Understand Adaptive Creative', 'https://support.vungle.com/hc/en-us/articles/360056661852')}</li>
  <li>${link('Adaptive Creative Asset Requirements', 'https://support.vungle.com/hc/en-us/articles/360061060131')}</li>
  <li>${link('Dos and Don\'ts of Adaptive Creative', 'https://support.vungle.com/hc/en-us/articles/360056663752')}</li>
  <li>${link('Customize the Adaptive Creative Experience', 'https://support.vungle.com/hc/en-us/articles/360057120251#events-0-1')}</li>
  <li>${link('Call the Vungle SDK and ASOI', 'https://support.vungle.com/hc/en-us/articles/360056671952')}</li>
</ul>
`;

const DOWNLOAD_MISSING = `Some events for this asset are missing. 
Please address the problems listed above and upload your new asset. ${EVENTS_LINK}`;

const COMPLETE_DOWNLOAD = `After testing we found that this asset triggers a double click, which does not comply with our standards. 
We can still proceed with FullQA (final stage of testing), but with reduced compatibility options in Publisher settings (ASOI Off compatible only). 
${ASOI_LINK}
`;

const DOWNLOAD_CTA_COMPLETE_FULL = 'This asset has passed the Visual QA round of testing and will now proceed to the Full QA (final stage of testing).';

const DOWNLOAD_CTA_COMPLETE_MISSING_OR_EARLY = `After testing we found that this asset does not comply with our standards. 
We can still proceed with FullQA (final stage of testing), 
but with reduced compatibility options in Publisher settings (ASOI Aggressive and ASOI Off only). 
${ASOI_LINK}
`;

const DOWNLOAD_ENDSCREEN_COMPLETE_EARLY = `After testing we found that this asset does not comply with our standards. 
We can still proceed with FullQA (final stage of testing), 
but with reduced compatibility options in Publisher settings (ASOI Aggressive and ASOI Off only). 
${ASOI_LINK}
`;

const DOWNLOAD_ENDSCREEN_COMPLETE_MISSING_OR_FULL = 'This asset has passed the Visual QA round of testing and will now proceed to the Full QA (final stage).';

const ELSE_LABEL = `
After testing we identified this asset as a Full Screen Clickable (FSC) unit. 
${FSC_LINK} 
We can still proceed with FullQA (final stage of testing), but with reduced compatibility options in Publisher settings (ASOI Off compatible only). 
${ASOI_LINK}
`;

const COMPLETE_DOWNLOAD_RESULT = {
  validVariations: {
    aggressive: false,
    complete: false,
    off: true,
  },
  tip: COMPLETE_DOWNLOAD,
  fsc: false,
};

const DOWNLOAD_MISSING_RESULT = {
  validVariations: initValidVariations,
  tip: DOWNLOAD_MISSING,
  fsc: false,
};

const DOWNLOAD_CTA_COMPLETE_MISSING_OR_EARLY_RESULT = {
  validVariations: {
    aggressive: true,
    complete: false,
    off: true,
  },
  tip: DOWNLOAD_CTA_COMPLETE_MISSING_OR_EARLY,
  fsc: false,
};

const DOWNLOAD_ENDSCREEN_COMPLETE_MISSING_OR_FULL_RESULT = {
  validVariations: {
    aggressive: true,
    complete: true,
    off: true,
  },
  tip: DOWNLOAD_ENDSCREEN_COMPLETE_MISSING_OR_FULL,
  fsc: false,
};

const OTHER_DOWNLOAD_COMPLETE_RESULT = {
  validVariations: {
    aggressive: false,
    complete: false,
    off: true,
  },
  tip: ELSE_LABEL,
  fsc: true,
};

const visualQAResultHash = {
  [downloadValues.missing]: {
    [completeValues.download]: DOWNLOAD_MISSING_RESULT,
    [completeValues.early]: DOWNLOAD_MISSING_RESULT,
    [completeValues.full]: DOWNLOAD_MISSING_RESULT,
    [completeValues.missing]: DOWNLOAD_MISSING_RESULT,
  },
  [downloadValues.cta]: {
    [completeValues.download]: COMPLETE_DOWNLOAD_RESULT,
    [completeValues.early]: DOWNLOAD_CTA_COMPLETE_MISSING_OR_EARLY_RESULT,
    [completeValues.full]: {
      validVariations: {
        aggressive: true,
        complete: true,
        off: true,
      },
      tip: DOWNLOAD_CTA_COMPLETE_FULL,
      fsc: false,
    },
    [completeValues.missing]: DOWNLOAD_CTA_COMPLETE_MISSING_OR_EARLY_RESULT,
  },
  [downloadValues.endscreen]: {
    [completeValues.download]: COMPLETE_DOWNLOAD_RESULT,
    [completeValues.early]: {
      validVariations: {
        aggressive: true,
        complete: false,
        off: true,
      },
      tip: DOWNLOAD_ENDSCREEN_COMPLETE_EARLY,
      fsc: false,
    },
    [completeValues.full]: DOWNLOAD_ENDSCREEN_COMPLETE_MISSING_OR_FULL_RESULT,
    [completeValues.missing]: DOWNLOAD_ENDSCREEN_COMPLETE_MISSING_OR_FULL_RESULT,
  },
  [downloadValues.early]: {
    [completeValues.download]: COMPLETE_DOWNLOAD_RESULT,
    [completeValues.early]: OTHER_DOWNLOAD_COMPLETE_RESULT,
    [completeValues.full]: OTHER_DOWNLOAD_COMPLETE_RESULT,
    [completeValues.missing]: OTHER_DOWNLOAD_COMPLETE_RESULT,
  },
};

export {
  visualQACheckBoxData,
  selectData,
  downloadValues,
  completeValues,
  MAJOR_ISSUES,
  DOWNLOAD_MISSING,
  COMPLETE_DOWNLOAD,
  DOWNLOAD_CTA_COMPLETE_FULL,
  DOWNLOAD_CTA_COMPLETE_MISSING_OR_EARLY,
  DOWNLOAD_ENDSCREEN_COMPLETE_EARLY,
  DOWNLOAD_ENDSCREEN_COMPLETE_MISSING_OR_FULL,
  ELSE_LABEL,
  visualQAResultHash,
  downloadValuesDisplay,
  completeValuesDisplay,
  COMPLETE_DOWNLOAD_RESULT,
};
