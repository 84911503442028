import {
  FILTER_KEY_APPLICATION,
  FILTER_KEY_CAMPAIGN,
  FILTER_KEY_CREATIVE,
  FILTER_KEY_PUBLISHER,
} from 'app/constants/filters';
import { ASSET_TYPES } from './constant';
import config from '../../../../../lib/config';
import { isEmptyArray } from '../../../../../lib/lib';
import Asset from '../../../../../models/Asset';
import Application from '../../../../../models/Application';
import Campaign from '../../../../../models/Campaign';
import Creative from '../../../../../models/Creative';

const { countries, regions } = config.get('countryData');

const panelConfigs = [
  {
    key: FILTER_KEY_APPLICATION,
    onLoadNodes: async ({ params, prevNodes }) => {
      const {
        response, pagination,
      } = await Application.getAll(params);
      const reducedResponse = response.reduce((acc, cur) => [
        ...acc,
        {
          id: cur.id,
          name: cur.name,
          platform: cur.platform,
          thumbnail: cur.store?.thumbnail,
        },
      ], prevNodes);

      return {
        pagination,
        response: reducedResponse,
      };
    },
    onLoadMenu: async ({ params, prevNodes }) => {
      const {
        response, pagination,
      } = await Application.getAll(params);
      const reducedResponse = response.reduce((acc, cur) => [
        ...acc,
        {
          id: cur.id,
          name: cur.name,
          platform: cur.platform,
          thumbnail: cur.store?.thumbnail,
        },
      ], prevNodes);

      return {
        pagination,
        response: reducedResponse,
      };
    },
  },
  {
    key: FILTER_KEY_PUBLISHER,
    onLoadNodes: async ({ params, prevNodes }) => {
      const {
        response, pagination,
      } = await Application.getReportPubs(params);
      const reducedResponse = response.reduce((acc, cur) => [
        ...acc,
        {
          id: cur.id,
          name: cur.name,
          platform: cur.platform,
          thumbnail: cur.store?.thumbnail,
        },
      ], prevNodes);

      return {
        pagination,
        response: reducedResponse,
      };
    },
    onLoadMenu: async ({ params, prevNodes }) => {
      const {
        response, pagination,
      } = await Application.getReportPubs(params);
      const reducedResponse = response.reduce((acc, cur) => [
        ...acc,
        {
          id: cur.id,
          name: cur.name,
          platform: cur.platform,
          thumbnail: cur.store?.thumbnail,
        },
      ], prevNodes);
      return {
        pagination,
        response: reducedResponse,
      };
    },
  },
  {
    key: FILTER_KEY_CAMPAIGN,
    onLoadNodes: async ({ params, prevNodes }) => {
      const {
        response, pagination,
      } = await Application.getCampaigns(params);
      const reducedResponse = response.reduce((acc, cur) => [
        ...acc,
        {
          id: cur.id,
          name: cur.name,
          platform: cur.platform,
          thumbnail: cur.store?.thumbnail,
          childrenCount: cur.campaign_count || 0,
          childrenKeys: (cur.campaigns || []).map((item) => item.id),
          children: [],
        },
      ], prevNodes);
      return {
        pagination,
        response: reducedResponse,
      };
    },
    onLoadChildren: async ({ params, node, nodes }) => {
      const { response } = await Campaign.getAll({
        ...params,
        application: [node.id],
        perPage: node.childrenCount,
      });

      const children = response.map((item) => ({
        id: item.id,
        name: item.name || item.vungle_name,
        status: (new Campaign(item)).isEligibleToServe(),
        application: {
          id: item.application.id,
          name: item.application.name,
          thumbnail: item.application.store.thumbnail,
        },
      }));
      return nodes.map((item) => {
        if (item.id === node.id) {
          return {
            ...item,
            children,
            childrenKeys: children.map((e) => e.id),
          };
        }
        return item;
      });
    },
    onLoadMenu: async ({ params, prevNodes }) => {
      const {
        response, pagination,
      } = await Campaign.getAll(params);
      const reducedResponse = response.reduce((acc, cur) => [
        ...acc,
        {
          id: cur.id,
          name: cur.name || cur.vungle_name,
          status: (new Campaign(cur)).isEligibleToServe(),
          application: {
            id: cur.application.id,
          },
        },
      ], prevNodes);

      return {
        pagination,
        response: reducedResponse,
      };
    },
  },
  {
    key: FILTER_KEY_CREATIVE,
    onLoadNodes: async ({ params, prevNodes }) => {
      const {
        response, pagination,
      } = await Application.getCreatives(params);
      const reducedResponse = response.reduce((acc, cur) => [
        ...acc,
        {
          id: cur.id,
          name: cur.name,
          platform: cur.platform,
          thumbnail: cur.store?.thumbnail,
          childrenCount: cur.creative_count || 0,
          childrenKeys: (cur.creatives || []).map((item) => item.id),
          children: [],
        },
      ], prevNodes);
      return {
        pagination,
        response: reducedResponse,
      };
    },
    onLoadChildren: async ({ params, node, nodes }) => {
      const { response } = await Creative.getAll({
        ...params,
        application: [node.id],
        perPage: node.childrenCount,
      });
      const children = response.map((item) => ({
        id: item.id,
        name: item.name || item.vungle_name,
        status: (new Creative(item)).isEligibleToServe(),
        application: {
          id: item.application.id,
          name: item.application.name,
          thumbnail: item.application.store.thumbnail,
        },
      }));
      return nodes.map((item) => {
        if (item.id === node.id) {
          return {
            ...item,
            children,
            childrenKeys: children.map((e) => e.id),
          };
        }
        return item;
      });
    },
    onLoadMenu: async ({ params, prevNodes }) => {
      const {
        response, pagination,
      } = await Creative.getAll(params);
      const reducedResponse = response.reduce((acc, cur) => [
        ...acc,
        {
          id: cur.id,
          name: cur.name || cur.vungle_name,
          status: (new Creative(cur)).isEligibleToServe(),
          application: {
            id: cur.application.id,
          },
        },
      ], prevNodes);

      return {
        pagination,
        response: reducedResponse,
      };
    },
  },
];

const findPanelConfig = (key) => panelConfigs.find((item) => item.key === key);

export const getLoadNodes = ({ filterKey, ...others }) => findPanelConfig(filterKey).onLoadNodes(others);

export const getLoadChildren = ({ filterKey, ...others }) => findPanelConfig(filterKey).onLoadChildren(others);

export const getLoadMenu = ({ filterKey, ...others }) => findPanelConfig(filterKey).onLoadMenu(others);

export const getAssetLoadMenu = async ({ params, prevNodes }) => {
  const {
    response, pagination,
  } = await Asset.getAssetsForFilter(params);
  const reducedResponse = response.reduce((acc, cur) => [
    ...acc,
    {
      id: cur.id,
      name: cur.name || cur.original_name,
    },
  ], prevNodes);

  return {
    pagination,
    response: reducedResponse,
  };
};

export const getAssetNodes = async (params) => {
  let response = [];
  const result = await Asset.getAssetsForFilter({ ...params, perPage: 1000 });
  if (result.ok) {
    response = ASSET_TYPES.map((type) => ({
      ...type,
      children: result.response.filter((item) => item.type === type.id),
    }));
  }

  return {
    response,
    // set pages to 1 to end filter loading more.
    pagination: { pages: 1 },
  };
};

export function getCountries() {
  const countryList = [];
  countries.forEach((country) => {
    const { region, code, name } = country;
    let found = countryList.find(({ id }) => id === region);
    if (!found) {
      found = regions.find(({ id }) => id === region);
      found = { ...found, children: [] };
      countryList.push(found);
    }
    found.children.push({ id: code, name });
  });
  return countryList;
}

export function getCheckedNodes(nodes) {
  const checkedNodes = [];
  nodes.forEach((cn) => {
    if (checkedNodes.findIndex((node) => node.id === cn.application.id) === -1) {
      checkedNodes.push(cn.application);
    }
  });
  return checkedNodes.map((node) => {
    const children = nodes.filter((cn) => node.id === cn.application.id);
    return {
      ...node,
      children,
      childrenKeys: children.map((e) => e.id),
      childrenCount: children.length,
    };
  });
}

export const getCheckedAssets = (nodes, checkedNodes) => {
  const assets = [];
  nodes.forEach((node) => {
    const children = node.children.filter((child) => checkedNodes.findIndex((checkedNode) => checkedNode.id === child.id) !== -1);
    if (children.length) {
      assets.push({
        ...node,
        children,
      });
    }
  });

  return assets;
};

// This function only used for CountryPanel
export function getCheckedRegionNodes(nodes, checkedNodes) {
  const checkedRegionNodes = [];
  nodes.forEach((node) => {
    const children = [];
    checkedNodes.forEach((checkedNode) => {
      if (node.children.findIndex((child) => child.id === checkedNode.id) !== -1) {
        children.push(checkedNode);
      }
    });
    if (children.length) {
      checkedRegionNodes.push({
        ...node,
        children,
      });
    }
  });

  return checkedRegionNodes;
}

export const filterNodes = (nodes, search) => {
  if (!search) {
    return nodes;
  }

  return nodes.filter(({ id, name }) => id === search || name.toLowerCase().includes(search.toLowerCase()));
};

export const filterTreeNodes = (nodes, search) => {
  if (!search) {
    return nodes;
  }

  return nodes.map((node) => node.children).flat().filter(({ id, name }) => id === search || name.toLowerCase().includes(search.toLowerCase()));
};

export function getDefaultCheckedNodes(nodes) {
  const checkedNodes = [];
  nodes.forEach((node) => {
    if (!isEmptyArray(node.children)) {
      checkedNodes.push(...node.children);
    }
  });
  return checkedNodes;
}
