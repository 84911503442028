import React from '../../../app/app';
import { getStore } from '../../../app/storeprovider';
import { translateTo } from '../../../lib/translator';
import Icon from '../../Icon/Icon';
import ContextMenu from '../../ContextMenu/ContextMenuContainer';
import './TranslationSelector.scss';

const LanguagesClickItem = ({ subMenu }) => (
  <>
    {subMenu ? (
      <ContextMenu.LinkOption text="Languages" icon="language" suffixIcon="keyboard_arrow_right" />
    ) : (
      <div>
        <span>Languages</span>
        <Icon.ArrowDown />
      </div>
    )}
  </>
);

const TranslationSelector = ({ subMenu }) => {
  let state;
  let languages;
  try {
    state = getStore().getState();
    languages = state.setup.languages || [];
  } catch (ex) {
    return null;
  }
  if (!languages || languages.length === 0) {
    return null;
  }
  return (
    <ContextMenu
      width="155px"
      position="left"
      menuWrapperClassName="transition-selector-wrapper"
      subMenu={subMenu}
      ClickItem={<LanguagesClickItem subMenu={subMenu} />}
    >
      {languages.map(({ code, name }) => (
        <ContextMenu.ClickOption
          key={code}
          {...{
            onClick: () => translateTo(code),
            text: name,
          }}
        />
      ))}
    </ContextMenu>
  );
};

export default TranslationSelector;
