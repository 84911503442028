import React from 'react';
import PropTypes from 'prop-types';
import { startAndEndTest } from '../../../lib/helpers';

const isValid = (p) => {
  const [start = '', end = ''] = p.split('=');
  if (end.indexOf('http') === 0) {
    return true;
  }
  return startAndEndTest(start, decodeURIComponent(end));
};

const prefixCls = 'v2_components_tracking_url';

export default class TrackingURL extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      urls: [],
    };
  }

  componentDidMount() {
    const { url } = this.props;
    this.formatURLs(url);
  }

  componentDidUpdate(prevProps) {
    const { url } = this.props;
    if (prevProps.url === url) return;
    this.formatURLs(url);
  }

  formatURLs = (urlString) => {
    if (typeof urlString !== 'string' || urlString.length === 0) {
      this.setState({ urls: [{ origin: '' }] });
    } else {
      const urls = urlString.split(',');
      this.setState({ urls: urls.map(this.formatURL) });
    }
    this.props.onChange(urlString);
  };

  formatURL = (urlString) => {
    try {
      const url = new URL(urlString);
      if (!url.search || url.search === '?') {
        return { origin: urlString };
      }
      // Since new URL will auto transform the special char,
      // Handle the searchParams manual
      const index = urlString.indexOf('?');
      const origin = urlString.substring(0, index);
      const searchParamString = urlString.substring(index + 1);
      const params = searchParamString.split('&');
      return {
        origin,
        params: params.map((p) => ({
          param: p,
          valid: isValid(p),
        })),
      };
    } catch (e) {
      return {
        origin: urlString,
      };
    }
  };

  render() {
    const { urls } = this.state;
    return (
      <div className={prefixCls}>
        {
          urls.map((url, index) => (
            <span key={`url-${index}`}>
              <span>{index > 0 && ','}</span>
              <span className={`${prefixCls}-valid`}>{url.origin}</span>
              {(url.params || []).map((p, i) => (
                <span className={`${prefixCls}-${p.valid ? 'valid' : 'error'}`} key={i}>
                  {`${i > 0 ? '&' : '?'}${p.param}`}
                </span>
              ))}
            </span>
          ))
        }
      </div>
    );
  }
}

TrackingURL.propTypes = {
  url: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
