import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { FILTER_KEY_AD_TYPE } from 'app/constants/filters';
import MultiTreeFrontend from '../Base/MultiTreeFrontend/MultiTreeFrontend';
import AdTypePanel from '../Base/Panels/AdTypePanel';
import { AD_TYPES } from '../Base/Panels/constant';

const MultiTreeAdType = ({
  dropdownFixed,
  visible,
  defaultCheckedNodes,
  onVisibleChange,
  onChange,
  onRemove,
}) => (
  <MultiTreeFrontend
    dropdownFixed={dropdownFixed}
    nodes={AD_TYPES}
    visible={visible}
    filterKey={FILTER_KEY_AD_TYPE}
    defaultCheckedNodes={defaultCheckedNodes}
    renderOverlay={() => (
      React.createElement(AdTypePanel, {
        visible,
        defaultCheckedNodes,
        onVisibleChange,
        onChange,
      })
    )}
    onVisibleChange={onVisibleChange}
    onChange={onChange}
    onRemove={onRemove}
  />
);

MultiTreeAdType.propTypes = {
  dropdownFixed: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
  defaultCheckedNodes: PropTypes.arrayOf(PropTypes.any),
  onVisibleChange: PropTypes.func,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};

MultiTreeAdType.defaultProps = {
  defaultCheckedNodes: [],
  dropdownFixed: false,
  onVisibleChange: noop,
  onChange: noop,
  onRemove: noop,
};

export default React.memo(MultiTreeAdType);
