import { sortBy } from 'lodash';
import isoSubdivisions from './subdivisions.json';
import cities from './cities';

const regions = [
  { id: 1, name: 'Africa' },
  { id: 2, name: 'Asia' },
  { id: 3, name: 'Europe' },
  { id: 4, name: 'Latin America & Carribean' },
  { id: 6, name: 'Oceania' },
  { id: 5, name: 'US & Canada' },
];

const countries = [
  {
    id: 1, code: 'AF', name: 'Afghanistan', region: 2,
  },
  {
    id: 2, code: 'AX', name: 'Åland Islands', region: 3,
  },
  {
    id: 3, code: 'AL', name: 'Albania', region: 3,
  },
  {
    id: 4, code: 'DZ', name: 'Algeria', region: 1,
  },
  {
    id: 5, code: 'AS', name: 'American Samoa', region: 6,
  },
  {
    id: 6, code: 'AD', name: 'Andorra', region: 3,
  },
  {
    id: 7, code: 'AO', name: 'Angola', region: 1,
  },
  {
    id: 8, code: 'AI', name: 'Anguilla', region: 4,
  },
  {
    id: 9, code: 'AQ', name: 'Antarctica', region: 6,
  },
  {
    id: 10, code: 'AG', name: 'Antigua and Barbuda', region: 4,
  },
  {
    id: 11, code: 'AR', name: 'Argentina', region: 4,
  },
  {
    id: 12, code: 'AM', name: 'Armenia', region: 2,
  },
  {
    id: 13, code: 'AW', name: 'Aruba', region: 4,
  },
  {
    id: 14, code: 'AU', name: 'Australia', region: 6,
  },
  {
    id: 15, code: 'AT', name: 'Austria', region: 3,
  },
  {
    id: 16, code: 'AZ', name: 'Azerbaijan', region: 2,
  },
  {
    id: 17, code: 'BS', name: 'Bahamas', region: 4,
  },
  {
    id: 18, code: 'BH', name: 'Bahrain', region: 2,
  },
  {
    id: 19, code: 'BD', name: 'Bangladesh', region: 2,
  },
  {
    id: 20, code: 'BB', name: 'Barbados', region: 4,
  },
  {
    id: 21, code: 'BY', name: 'Belarus', region: 3,
  },
  {
    id: 22, code: 'BE', name: 'Belgium', region: 3,
  },
  {
    id: 23, code: 'BZ', name: 'Belize', region: 4,
  },
  {
    id: 24, code: 'BJ', name: 'Benin', region: 1,
  },
  {
    id: 25, code: 'BM', name: 'Bermuda', region: 4,
  },
  {
    id: 26, code: 'BT', name: 'Bhutan', region: 2,
  },
  {
    id: 27, code: 'BO', name: 'Bolivia, Plurinational State of', region: 4,
  },
  {
    id: 28, code: 'BQ', name: 'Bonaire, Sint Eustatius and Saba', region: 4,
  },
  {
    id: 29, code: 'BA', name: 'Bosnia and Herzegovina', region: 3,
  },
  {
    id: 30, code: 'BW', name: 'Botswana', region: 1,
  },
  {
    id: 31, code: 'BV', name: 'Bouvet Island', region: 3,
  },
  {
    id: 32, code: 'BR', name: 'Brazil', region: 4,
  },
  {
    id: 33, code: 'IO', name: 'British Indian Ocean Territory', region: 2,
  },
  {
    id: 34, code: 'BN', name: 'Brunei Darussalam', region: 2,
  },
  {
    id: 35, code: 'BG', name: 'Bulgaria', region: 3,
  },
  {
    id: 36, code: 'BF', name: 'Burkina Faso', region: 1,
  },
  {
    id: 37, code: 'BI', name: 'Burundi', region: 1,
  },
  {
    id: 38, code: 'KH', name: 'Cambodia', region: 2,
  },
  {
    id: 39, code: 'CM', name: 'Cameroon', region: 1,
  },
  {
    id: 40, code: 'CA', name: 'Canada', region: 5,
  },
  {
    id: 41, code: 'CV', name: 'Cape Verde', region: 1,
  },
  {
    id: 42, code: 'KY', name: 'Cayman Islands', region: 4,
  },
  {
    id: 43, code: 'CF', name: 'Central African Republic', region: 1,
  },
  {
    id: 44, code: 'TD', name: 'Chad', region: 1,
  },
  {
    id: 45, code: 'CL', name: 'Chile', region: 4,
  },
  {
    id: 46, code: 'CN', name: 'China', region: 2,
  },
  {
    id: 47, code: 'CX', name: 'Christmas Island', region: 2,
  },
  {
    id: 48, code: 'CC', name: 'Cocos (Keeling) Islands', region: 2,
  },
  {
    id: 49, code: 'CO', name: 'Colombia', region: 4,
  },
  {
    id: 50, code: 'KM', name: 'Comoros', region: 1,
  },
  {
    id: 51, code: 'CG', name: 'Congo', region: 1,
  },
  {
    id: 52, code: 'CD', name: 'Congo, the Democratic Republic of the', region: 1,
  },
  {
    id: 53, code: 'CK', name: 'Cook Islands', region: 6,
  },
  {
    id: 54, code: 'CR', name: 'Costa Rica', region: 4,
  },
  {
    id: 55, code: 'CI', name: "Côte d'Ivoire", region: 1,
  },
  {
    id: 56, code: 'HR', name: 'Croatia', region: 3,
  },
  {
    id: 57, code: 'CU', name: 'Cuba', region: 4,
  },
  {
    id: 58, code: 'CW', name: 'Curaçao', region: 4,
  },
  {
    id: 59, code: 'CY', name: 'Cyprus', region: 3,
  },
  {
    id: 60, code: 'CZ', name: 'Czech Republic', region: 3,
  },
  {
    id: 61, code: 'DK', name: 'Denmark', region: 3,
  },
  {
    id: 62, code: 'DJ', name: 'Djibouti', region: 1,
  },
  {
    id: 63, code: 'DM', name: 'Dominica', region: 4,
  },
  {
    id: 64, code: 'DO', name: 'Dominican Republic', region: 4,
  },
  {
    id: 65, code: 'EC', name: 'Ecuador', region: 4,
  },
  {
    id: 66, code: 'EG', name: 'Egypt', region: 1,
  },
  {
    id: 67, code: 'SV', name: 'El Salvador', region: 4,
  },
  {
    id: 68, code: 'GQ', name: 'Equatorial Guinea', region: 1,
  },
  {
    id: 69, code: 'ER', name: 'Eritrea', region: 1,
  },
  {
    id: 70, code: 'EE', name: 'Estonia', region: 3,
  },
  {
    id: 71, code: 'ET', name: 'Ethiopia', region: 1,
  },
  {
    id: 72, code: 'FK', name: 'Falkland Islands (Malvinas)', region: 4,
  },
  {
    id: 73, code: 'FO', name: 'Faroe Islands', region: 3,
  },
  {
    id: 74, code: 'FJ', name: 'Fiji', region: 6,
  },
  {
    id: 75, code: 'FI', name: 'Finland', region: 3,
  },
  {
    id: 76, code: 'FR', name: 'France', region: 3,
  },
  {
    id: 77, code: 'GF', name: 'French Guiana', region: 4,
  },
  {
    id: 78, code: 'PF', name: 'French Polynesia', region: 6,
  },
  {
    id: 79, code: 'TF', name: 'French Southern Territories', region: 6,
  },
  {
    id: 80, code: 'GA', name: 'Gabon', region: 1,
  },
  {
    id: 81, code: 'GM', name: 'Gambia', region: 1,
  },
  {
    id: 82, code: 'GE', name: 'Georgia', region: 2,
  },
  {
    id: 83, code: 'DE', name: 'Germany', region: 3,
  },
  {
    id: 84, code: 'GH', name: 'Ghana', region: 1,
  },
  {
    id: 85, code: 'GI', name: 'Gibraltar', region: 3,
  },
  {
    id: 86, code: 'GR', name: 'Greece', region: 3,
  },
  {
    id: 87, code: 'GL', name: 'Greenland', region: 3,
  },
  {
    id: 88, code: 'GD', name: 'Grenada', region: 4,
  },
  {
    id: 89, code: 'GP', name: 'Guadeloupe', region: 4,
  },
  {
    id: 90, code: 'GU', name: 'Guam', region: 6,
  },
  {
    id: 91, code: 'GT', name: 'Guatemala', region: 4,
  },
  {
    id: 92, code: 'GG', name: 'Guernsey', region: 3,
  },
  {
    id: 93, code: 'GN', name: 'Guinea', region: 1,
  },
  {
    id: 94, code: 'GW', name: 'Guinea-Bissau', region: 1,
  },
  {
    id: 95, code: 'GY', name: 'Guyana', region: 4,
  },
  {
    id: 96, code: 'HT', name: 'Haiti', region: 4,
  },
  {
    id: 97, code: 'HM', name: 'Heard Island and McDonald Islands', region: 6,
  },
  {
    id: 98, code: 'VA', name: 'Holy See (Vatican City State)', region: 3,
  },
  {
    id: 99, code: 'HN', name: 'Honduras', region: 4,
  },
  {
    id: 100, code: 'HK', name: 'Hong Kong', region: 2,
  },
  {
    id: 101, code: 'HU', name: 'Hungary', region: 3,
  },
  {
    id: 102, code: 'IS', name: 'Iceland', region: 3,
  },
  {
    id: 103, code: 'IN', name: 'India', region: 2,
  },
  {
    id: 104, code: 'ID', name: 'Indonesia', region: 2,
  },
  {
    id: 105, code: 'IR', name: 'Iran, Islamic Republic of', region: 2,
  },
  {
    id: 106, code: 'IQ', name: 'Iraq', region: 2,
  },
  {
    id: 107, code: 'IE', name: 'Ireland', region: 3,
  },
  {
    id: 108, code: 'IM', name: 'Isle of Man', region: 3,
  },
  {
    id: 109, code: 'IL', name: 'Israel', region: 2,
  },
  {
    id: 110, code: 'IT', name: 'Italy', region: 3,
  },
  {
    id: 111, code: 'JM', name: 'Jamaica', region: 4,
  },
  {
    id: 112, code: 'JP', name: 'Japan', region: 2,
  },
  {
    id: 113, code: 'JE', name: 'Jersey', region: 3,
  },
  {
    id: 114, code: 'JO', name: 'Jordan', region: 2,
  },
  {
    id: 115, code: 'KZ', name: 'Kazakhstan', region: 2,
  },
  {
    id: 116, code: 'KE', name: 'Kenya', region: 1,
  },
  {
    id: 117, code: 'KI', name: 'Kiribati', region: 6,
  },
  {
    id: 118, code: 'KP', name: "Korea, Democratic People's Republic of", region: 2,
  },
  {
    id: 119, code: 'KR', name: 'Korea, Republic of', region: 2,
  },
  {
    id: 120, code: 'KW', name: 'Kuwait', region: 2,
  },
  {
    id: 121, code: 'KG', name: 'Kyrgyzstan', region: 2,
  },
  {
    id: 122, code: 'LA', name: "Lao People's Democratic Republic", region: 2,
  },
  {
    id: 123, code: 'LV', name: 'Latvia', region: 3,
  },
  {
    id: 124, code: 'LB', name: 'Lebanon', region: 2,
  },
  {
    id: 125, code: 'LS', name: 'Lesotho', region: 1,
  },
  {
    id: 126, code: 'LR', name: 'Liberia', region: 1,
  },
  {
    id: 127, code: 'LY', name: 'Libya', region: 1,
  },
  {
    id: 128, code: 'LI', name: 'Liechtenstein', region: 3,
  },
  {
    id: 129, code: 'LT', name: 'Lithuania', region: 3,
  },
  {
    id: 130, code: 'LU', name: 'Luxembourg', region: 3,
  },
  {
    id: 131, code: 'MO', name: 'Macao', region: 2,
  },
  {
    id: 132, code: 'MK', name: 'Macedonia, the former Yugoslav Republic of', region: 3,
  },
  {
    id: 133, code: 'MG', name: 'Madagascar', region: 1,
  },
  {
    id: 134, code: 'MW', name: 'Malawi', region: 1,
  },
  {
    id: 135, code: 'MY', name: 'Malaysia', region: 2,
  },
  {
    id: 136, code: 'MV', name: 'Maldives', region: 2,
  },
  {
    id: 137, code: 'ML', name: 'Mali', region: 1,
  },
  {
    id: 138, code: 'MT', name: 'Malta', region: 3,
  },
  {
    id: 139, code: 'MH', name: 'Marshall Islands', region: 6,
  },
  {
    id: 140, code: 'MQ', name: 'Martinique', region: 4,
  },
  {
    id: 141, code: 'MR', name: 'Mauritania', region: 1,
  },
  {
    id: 142, code: 'MU', name: 'Mauritius', region: 1,
  },
  {
    id: 143, code: 'YT', name: 'Mayotte', region: 1,
  },
  {
    id: 144, code: 'MX', name: 'Mexico', region: 4,
  },
  {
    id: 145, code: 'FM', name: 'Micronesia, Federated States of', region: 6,
  },
  {
    id: 146, code: 'MD', name: 'Moldova, Republic of', region: 3,
  },
  {
    id: 147, code: 'MC', name: 'Monaco', region: 3,
  },
  {
    id: 148, code: 'MN', name: 'Mongolia', region: 2,
  },
  {
    id: 149, code: 'ME', name: 'Montenegro', region: 3,
  },
  {
    id: 150, code: 'MS', name: 'Montserrat', region: 3,
  },
  {
    id: 151, code: 'MA', name: 'Morocco', region: 1,
  },
  {
    id: 152, code: 'MZ', name: 'Mozambique', region: 1,
  },
  {
    id: 153, code: 'MM', name: 'Myanmar', region: 2,
  },
  {
    id: 154, code: 'NA', name: 'Namibia', region: 1,
  },
  {
    id: 155, code: 'NR', name: 'Nauru', region: 6,
  },
  {
    id: 156, code: 'NP', name: 'Nepal', region: 2,
  },
  {
    id: 157, code: 'NL', name: 'Netherlands', region: 3,
  },
  {
    id: 158, code: 'NC', name: 'New Caledonia', region: 6,
  },
  {
    id: 159, code: 'NZ', name: 'New Zealand', region: 6,
  },
  {
    id: 160, code: 'NI', name: 'Nicaragua', region: 4,
  },
  {
    id: 161, code: 'NE', name: 'Niger', region: 1,
  },
  {
    id: 162, code: 'NG', name: 'Nigeria', region: 1,
  },
  {
    id: 163, code: 'NU', name: 'Niue', region: 6,
  },
  {
    id: 164, code: 'NF', name: 'Norfolk Island', region: 6,
  },
  {
    id: 165, code: 'MP', name: 'Northern Mariana Islands', region: 6,
  },
  {
    id: 166, code: 'NO', name: 'Norway', region: 3,
  },
  {
    id: 167, code: 'OM', name: 'Oman', region: 2,
  },
  {
    id: 168, code: 'PK', name: 'Pakistan', region: 2,
  },
  {
    id: 169, code: 'PW', name: 'Palau', region: 6,
  },
  {
    id: 170, code: 'PS', name: 'Palestinian Territory, Occupied', region: 2,
  },
  {
    id: 171, code: 'PA', name: 'Panama', region: 4,
  },
  {
    id: 172, code: 'PG', name: 'Papua New Guinea', region: 6,
  },
  {
    id: 173, code: 'PY', name: 'Paraguay', region: 4,
  },
  {
    id: 174, code: 'PE', name: 'Peru', region: 4,
  },
  {
    id: 175, code: 'PH', name: 'Philippines', region: 2,
  },
  {
    id: 176, code: 'PN', name: 'Pitcairn', region: 6,
  },
  {
    id: 177, code: 'PL', name: 'Poland', region: 3,
  },
  {
    id: 178, code: 'PT', name: 'Portugal', region: 3,
  },
  {
    id: 179, code: 'PR', name: 'Puerto Rico', region: 5,
  },
  {
    id: 180, code: 'QA', name: 'Qatar', region: 2,
  },
  {
    id: 181, code: 'RO', name: 'Romania', region: 3,
  },
  {
    id: 182, code: 'RU', name: 'Russian Federation', region: 3,
  },
  {
    id: 183, code: 'RW', name: 'Rwanda', region: 1,
  },
  {
    id: 184, code: 'RE', name: 'Réunion', region: 1,
  },
  {
    id: 185, code: 'BL', name: 'Saint Barthélemy', region: 4,
  },
  {
    id: 186, code: 'SH', name: 'Saint Helena, Ascension and Tristan da Cunha', region: 4,
  },
  {
    id: 187, code: 'KN', name: 'Saint Kitts and Nevis', region: 4,
  },
  {
    id: 188, code: 'LC', name: 'Saint Lucia', region: 4,
  },
  {
    id: 189, code: 'MF', name: 'Saint Martin (French part)', region: 4,
  },
  {
    id: 190, code: 'PM', name: 'Saint Pierre and Miquelon', region: 5,
  },
  {
    id: 191, code: 'VC', name: 'Saint Vincent and the Grenadines', region: 4,
  },
  {
    id: 192, code: 'WS', name: 'Samoa', region: 6,
  },
  {
    id: 193, code: 'SM', name: 'San Marino', region: 3,
  },
  {
    id: 194, code: 'ST', name: 'Sao Tome and Principe', region: 1,
  },
  {
    id: 195, code: 'SA', name: 'Saudi Arabia', region: 2,
  },
  {
    id: 196, code: 'SN', name: 'Senegal', region: 1,
  },
  {
    id: 197, code: 'RS', name: 'Serbia', region: 3,
  },
  {
    id: 198, code: 'SC', name: 'Seychelles', region: 1,
  },
  {
    id: 199, code: 'SL', name: 'Sierra Leone', region: 1,
  },
  {
    id: 200, code: 'SG', name: 'Singapore', region: 2,
  },
  {
    id: 201, code: 'SX', name: 'Sint Maarten (Dutch part)', region: 4,
  },
  {
    id: 202, code: 'SK', name: 'Slovakia', region: 3,
  },
  {
    id: 203, code: 'SI', name: 'Slovenia', region: 3,
  },
  {
    id: 204, code: 'SB', name: 'Solomon Islands', region: 6,
  },
  {
    id: 205, code: 'SO', name: 'Somalia', region: 1,
  },
  {
    id: 206, code: 'ZA', name: 'South Africa', region: 1,
  },
  {
    id: 207, code: 'GS', name: 'South Georgia and the South Sandwich Islands', region: 4,
  },
  {
    id: 208, code: 'SS', name: 'South Sudan', region: 1,
  },
  {
    id: 209, code: 'ES', name: 'Spain', region: 3,
  },
  {
    id: 210, code: 'LK', name: 'Sri Lanka', region: 2,
  },
  {
    id: 211, code: 'SD', name: 'Sudan', region: 1,
  },
  {
    id: 212, code: 'SR', name: 'Suriname', region: 4,
  },
  {
    id: 213, code: 'SJ', name: 'Svalbard and Jan Mayen', region: 3,
  },
  {
    id: 214, code: 'SZ', name: 'Swaziland', region: 1,
  },
  {
    id: 215, code: 'SE', name: 'Sweden', region: 3,
  },
  {
    id: 216, code: 'CH', name: 'Switzerland', region: 3,
  },
  {
    id: 217, code: 'SY', name: 'Syrian Arab Republic', region: 2,
  },
  {
    id: 218, code: 'TW', name: 'Taiwan, Province of China', region: 2,
  },
  {
    id: 219, code: 'TJ', name: 'Tajikistan', region: 2,
  },
  {
    id: 220, code: 'TZ', name: 'Tanzania, United Republic of', region: 1,
  },
  {
    id: 221, code: 'TH', name: 'Thailand', region: 2,
  },
  {
    id: 222, code: 'TL', name: 'Timor-Leste', region: 6,
  },
  {
    id: 223, code: 'TG', name: 'Togo', region: 1,
  },
  {
    id: 224, code: 'TK', name: 'Tokelau', region: 6,
  },
  {
    id: 225, code: 'TO', name: 'Tonga', region: 6,
  },
  {
    id: 226, code: 'TT', name: 'Trinidad and Tobago', region: 4,
  },
  {
    id: 227, code: 'TN', name: 'Tunisia', region: 1,
  },
  {
    id: 228, code: 'TR', name: 'Turkey', region: 2,
  },
  {
    id: 229, code: 'TM', name: 'Turkmenistan', region: 2,
  },
  {
    id: 230, code: 'TC', name: 'Turks and Caicos Islands', region: 4,
  },
  {
    id: 231, code: 'TV', name: 'Tuvalu', region: 6,
  },
  {
    id: 232, code: 'UG', name: 'Uganda', region: 1,
  },
  {
    id: 233, code: 'UA', name: 'Ukraine', region: 3,
  },
  {
    id: 234, code: 'AE', name: 'United Arab Emirates', region: 2,
  },
  {
    id: 235, code: 'GB', name: 'United Kingdom', region: 3,
  },
  {
    id: 236, code: 'US', name: 'United States of America', region: 5,
  },
  {
    id: 237, code: 'UM', name: 'United States Minor Outlying Islands', region: 6,
  },
  {
    id: 238, code: 'UY', name: 'Uruguay', region: 4,
  },
  {
    id: 239, code: 'UZ', name: 'Uzbekistan', region: 2,
  },
  {
    id: 240, code: 'VU', name: 'Vanuatu', region: 6,
  },
  {
    id: 241, code: 'VE', name: 'Venezuela, Bolivarian Republic of', region: 4,
  },
  {
    id: 242, code: 'VN', name: 'Viet Nam', region: 2,
  },
  {
    id: 243, code: 'VG', name: 'Virgin Islands, British', region: 4,
  },
  {
    id: 244, code: 'VI', name: 'Virgin Islands, U.S.', region: 4,
  },
  {
    id: 245, code: 'WF', name: 'Wallis and Futuna', region: 6,
  },
  {
    id: 246, code: 'EH', name: 'Western Sahara', region: 1,
  },
  {
    id: 247, code: 'YE', name: 'Yemen', region: 2,
  },
  {
    id: 248, code: 'ZM', name: 'Zambia', region: 1,
  },
  {
    id: 249, code: 'ZW', name: 'Zimbabwe', region: 1,
  },
];

const processSubdivision = (countryCode, fn) => {
  const { subdivisions } = isoSubdivisions[countryCode];

  const divisions = fn
    ? Object.values(subdivisions).filter(fn)
    : Object.values(subdivisions);

  return divisions.map(({ name, numeric_3_code: code }) => ({ name, code, country: countryCode }));
};

const initialSubdivisions = [
  // just the 50 US states, plus District of Columbia and Puerto Rico
  { code: 'US', fn: (s) => ['US-DC', 'US-PR'].includes(s.numeric_3_code) || s.category === 'State' },
  { code: 'IN' },
  { code: 'AU' },
  { code: 'KR' },
].reduce((cur, next) => cur.concat(processSubdivision(next.code, next.fn)), []);
const subdivisions = sortBy(initialSubdivisions, ['country', 'name']);

const languages = [
  { code: 'ar', name: 'Arabic', translations: { download: 'تحميل' } },
  { code: 'az', name: 'Azerbaijani', translations: { download: 'Yukle' } },
  { code: 'bs', name: 'Bosnian', translations: { download: 'Istovariti' } },
  { code: 'bg', name: 'Bulgarian', translations: { download: 'изтегляне' } },
  { code: 'hr', name: 'Croatian', translations: { download: 'Istovariti' } },
  { code: 'cs', name: 'Czech', translations: { download: 'Stažení' } },
  { code: 'zh', name: 'Chinese', translations: { download: '下载' } },
  { code: 'da', name: 'Danish', translations: { download: 'Downloade' } },
  { code: 'nl', name: 'Dutch', translations: { download: 'Downloaden' } },
  { code: 'en', name: 'English', translations: { download: 'Download' } },
  { code: 'et', name: 'Estonian', translations: { download: 'Alla laadima' } },
  { code: 'fi', name: 'Finnish', translations: { download: 'Download' } },
  { code: 'fr', name: 'French', translations: { download: 'Télécharger' } },
  { code: 'de', name: 'German', translations: { download: 'Runterladen' } },
  { code: 'el', name: 'Greek', translations: { download: 'Λήψη' } },
  { code: 'he', name: 'Hebrew', translations: { download: 'הורדה' } },
  { code: 'hi', name: 'Hindi', translations: { download: 'डाउनलोड' } },
  { code: 'hu', name: 'Hungarian', translations: { download: 'Letöltés' } },
  { code: 'id', name: 'Indonesian', translations: { download: 'Unduh' } },
  { code: 'it', name: 'Italian', translations: { download: 'Scaricare' } },
  { code: 'ja', name: 'Japanese', translations: { download: 'ダウンロード' } },
  { code: 'kl', name: 'Klingon', translations: { download: 'SIbIylSuq' } },
  { code: 'ko', name: 'Korean', translations: { download: '다운로드' } },
  { code: 'lv', name: 'Latvian', translations: { download: 'Lejuplādēt' } },
  { code: 'lt', name: 'Lithuanian', translations: { download: 'Parsisiųsti' } },
  { code: 'ms', name: 'Malay', translations: { download: 'muat turun' } },
  { code: 'no', name: 'Norwegian', translations: { download: 'Laste ned' } },
  { code: 'pl', name: 'Polish', translations: { download: 'Pobierz' } },
  { code: 'pt', name: 'Portuguese', translations: { download: 'Baixe' } },
  { code: 'ro', name: 'Romanian', translations: { download: 'Descărcare' } },
  { code: 'ru', name: 'Russian', translations: { download: 'скачать' } },
  { code: 'sr', name: 'Serbian', translations: { download: 'Preuzimanje' } },
  { code: 'sk', name: 'Slovak', translations: { download: 'Download' } },
  { code: 'sl', name: 'Slovenian', translations: { download: 'Prenesi' } },
  { code: 'es', name: 'Spanish', translations: { download: 'Descargar' } },
  { code: 'sv', name: 'Swedish', translations: { download: 'Hämta' } },
  { code: 'th', name: 'Thai', translations: { download: 'ดาวน์โหลด' } },
  { code: 'tr', name: 'Turkish', translations: { download: 'Indirin' } },
  { code: 'uk', name: 'Ukranian', translations: { download: 'скачати' } },
  { code: 'vi', name: 'Vietnamese', translations: { download: 'Tải về' } },
];

const longTailGeos = [
  'AE', 'AF', 'AL', 'AM', 'AO', 'AR', 'AZ', 'BA', 'BB', 'BD', 'BF', 'BG', 'BH', 'BJ', 'BN',
  'BO', 'BR', 'BS', 'BW', 'BY', 'BZ', 'CD', 'CI', 'CL', 'CM', 'CO', 'CR', 'CU', 'CY', 'CZ',
  'DO', 'DZ', 'EC', 'EE', 'EG', 'ES', 'ET', 'FI', 'FJ', 'GA', 'GE', 'GF', 'GH', 'GP', 'GR',
  'GT', 'GU', 'GY', 'HN', 'HR', 'HT', 'HU', 'ID', 'IL', 'IN', 'IQ', 'IR', 'IS', 'JM', 'JO',
  'KE', 'KG', 'KH', 'KW', 'KZ', 'LA', 'LB', 'LK', 'LT', 'LV', 'LY', 'MA', 'MD', 'ME', 'MK',
  'ML', 'MM', 'MN', 'MQ', 'MR', 'MT', 'MU', 'MV', 'MX', 'MY', 'MZ', 'NA', 'NC', 'NG', 'NI',
  'NL', 'NP', 'OM', 'PA', 'PE', 'PF', 'PH', 'PK', 'PL', 'PR', 'PS', 'PT', 'PY', 'QA', 'RE',
  'RO', 'RS', 'RU', 'RW', 'SA', 'SD', 'SI', 'SK', 'SN', 'SO', 'SR', 'SV', 'SY', 'TG', 'TH',
  'TJ', 'TN', 'TR', 'TT', 'TZ', 'UA', 'UG', 'UY', 'UZ', 'VE', 'VN', 'YE', 'ZM', 'ZW',
];

const dynamicIapEnabledCountries = [
  'AE', 'AR', 'AT', 'AU', 'BE', 'BR', 'CA', 'CH', 'CL', 'CO', 'CZ', 'DE', 'DK', 'DZ', 'EG',
  'ES', 'FI', 'FR', 'GB', 'HK', 'ID', 'IE', 'IL', 'IN', 'IT', 'JP', 'KR', 'KZ', 'LU', 'MA',
  'MX', 'MY', 'NL', 'NO', 'NZ', 'OM', 'PE', 'PH', 'PK', 'PL', 'PT', 'RO', 'SA', 'SE', 'SG',
  'TH', 'TR', 'TW', 'US', 'VN', 'ZA',
];

const maxBidForLongTail = 50.0;

export default {
  cities,
  countries,
  subdivisions,
  languages,
  regions,
  longTailGeos,
  dynamicIapEnabledCountries,
  maxBidForLongTail,
};

export {
  cities,
  countries,
  subdivisions,
  languages,
  regions,
  longTailGeos,
  dynamicIapEnabledCountries,
  maxBidForLongTail,
};
