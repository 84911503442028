import React from 'react';
import './CreativeBuilderDialog.scss';

class CreativeBuilderDialog extends React.Component {
  componentDidMount() {
    window.addEventListener('message', this.onDismissFromIframe);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.onDismissFromIframe);
  }

  onDismissFromIframe = (message) => {
    if (message.data.type === 'creativeBuilder.close') {
      this.props.onDismiss?.(message.data.isNewCreativeCreated);
    }
  }

  generateURL = () => {
    const { account, application } = this.props;
    return encodeURI(
      `https://creative-builder.liftoff.io?accountId=${account.get('id')}&accountName=${account.get('name')}&applicationId=${application.get('id')}&applicationName=${application.get('name')}&platform=direct`,
    );
  }

  render() {
    return (
      <div className="creative_builder_dialog">
        <div className="creative_builder_iframe_container">
          <iframe
            src={this.generateURL()}
            title="Creative Builder"
          />
        </div>
        <div className="close_button" onClick={() => this.props.onDismiss?.(true)}>
          <i className="material-icons">close</i>
        </div>
      </div>
    );
  }
}

export default CreativeBuilderDialog;
