import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FILTER_KEY_CREATIVE_LIST_FORMAT } from 'app/constants/filters';
import { noop, cloneDeep } from 'lodash';
import Template from 'models/Template';
import { getCreativeFormats } from 'lib/helpers/creatives/creativeIndex';
import { CREATIVE_FORMAT_MAP } from 'app/constants/campaign';
import MultiTreeFrontend from '../Base/MultiTreeFrontend/MultiTreeFrontend';
import CreativeFormatPanel from '../Base/Panels/CreativeFormatPanel';

const CreativeFormat = ({
  visible,
  filterLabel,
  disableDropdown,
  defaultCheckedNodes,
  dropdownFixed,
  onVisibleChange,
  onChange,
  onRemove,
  config,
}) => {
  const checkedNodes = defaultCheckedNodes.map((node) => node.children).flat();

  const [nodes, setNodes] = useState([]);
  const [pagination, setPagination] = useState({ page: 0, pages: 1 });

  const getTemplates = useCallback(async () => {
    const res = await Template.getAll({
      perPage: 1000,
      templateStatus: 'active',
    });
    res.response.filter((t) => {
      const template = t;
      const format = CREATIVE_FORMAT_MAP[t.format];
      if (format) {
        template.creativeFormat = format.text;
      }
      return format;
    });

    setPagination(res.pagination);

    const templates = getCreativeFormats(res.response);

    const disabledOptions = config?.disabledOptionMap[FILTER_KEY_CREATIVE_LIST_FORMAT] || [];
    const creativeFormats = cloneDeep(templates).map((opt) => {
      const category = opt;
      const disabled = disabledOptions.includes(category.id);
      category.disabled = disabled;
      if (category.children && category.children.length > 0) {
        category.children = category.children.map((t) => {
          const template = t;
          template.disabled = disabled;
          return t;
        });
      }
      return category;
    });

    setNodes(creativeFormats);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MultiTreeFrontend
      visible={visible}
      nodes={nodes}
      dropdownFixed={dropdownFixed}
      filterKey={FILTER_KEY_CREATIVE_LIST_FORMAT}
      defaultCheckedNodes={checkedNodes}
      renderOverlay={() => (
        React.createElement(CreativeFormatPanel, {
          visible,
          availableNodes: nodes,
          defaultCheckedNodes: checkedNodes,
          onVisibleChange,
          onChange,
          options: nodes,
          tree: true,
          pagination,
        })
      )}
      onVisibleChange={onVisibleChange}
      onChange={onChange}
      onRemove={onRemove}
      filterLabel={filterLabel}
      disableDropdown={disableDropdown}
    />
  );
};

CreativeFormat.propTypes = {
  visible: PropTypes.bool.isRequired,
  defaultCheckedNodes: PropTypes.arrayOf(PropTypes.any),
  dropdownFixed: PropTypes.bool,
  onVisibleChange: PropTypes.func,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  filterLabel: PropTypes.string,
  disableDropdown: PropTypes.bool,
};

CreativeFormat.defaultProps = {
  defaultCheckedNodes: [],
  dropdownFixed: false,
  onVisibleChange: noop,
  onChange: noop,
  onRemove: noop,
  filterLabel: '',
  disableDropdown: false,
};

export default React.memo(CreativeFormat);
