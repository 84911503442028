export const getPageTitle = (history) => {
  const { pathname } = history.location;
  if (!pathname) {
    return null;
  }

  const pathStr = pathname.slice(1);

  return pathStr.charAt(0).toUpperCase() + pathStr.slice(1);
};

export default {};
