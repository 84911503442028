import React from 'react';
import PropTypes from 'prop-types';
import Link from '../Link/Link';
import config from '../../lib/config';
import { getSupportedPlatforms } from '../../lib/capabilities/devices';

const SUPPORTED_PLATFORMS = getSupportedPlatforms();
const PLATFORM_URLS = config.get('deviceListCsvUrl');

const LinkDownloadNonIOSDevices = ({ platform }) => (
  <Link
    className="body__link"
    to={PLATFORM_URLS[platform]}
    external
  >
    <p
      className="body__link"
    >
      Download Full List of Devices
    </p>
  </Link>
);

LinkDownloadNonIOSDevices.propTypes = {
  platform: PropTypes.oneOf(SUPPORTED_PLATFORMS).isRequired,
};

export default LinkDownloadNonIOSDevices;
