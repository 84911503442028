import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import Dropdown from '../Dropdown';
import Input from '../../Input/Input';
import './dropdownMenu.scss';

const menuItemPrefixCls = 'v2_component_menu_item';
const menuPrefixCls = 'v2_component_menu_list';

export const DropdownMenuItem = (props) => {
  const {
    className,
    children,
    label,
    selected,
    align,
    onClick,
  } = props;

  return (
    <div
      className={classNames(
        menuItemPrefixCls,
        {
          [`${menuItemPrefixCls}-selected`]: selected,
          [`${menuItemPrefixCls}-${align}`]: align,
        },
        className,
      )}
      onClick={onClick}
    >
      <span>
        {label || children}
      </span>
    </div>
  );
};

DropdownMenuItem.propTypes = {
  /**
   * Class name.
   */
  className: PropTypes.string,
  /**
   * Label to display, or you can use children.
   */
  label: PropTypes.string,
  /**
   * Tell if item is selected.
   */
  selected: PropTypes.bool,
  /**
   * Content align style.
   */
  align: PropTypes.oneOf(['left', 'center', 'right']),
  /**
   * Called when item is clicked.
   */
  onClick: PropTypes.func,
};

DropdownMenuItem.defaultProps = {
  className: null,
  label: null,
  selected: false,
  align: 'left',
  onClick: noop,
};

const DropdownMenu = ({
  className,
  style,
  onVisibleChange,
  data,
  onChange,
  labelKey,
  children,
  horizontalOffset = 6,
  activeIndex,
  searchable,
  placeholder,
  placement,
  transitionOffset,
  fixed,
}) => {
  const [searchData, setSearchData] = useState([]);
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    if (typeof keyword === 'undefined' || keyword === '') {
      setSearchData(data);
    } else {
      setSearchData(data.filter((d) => {
        const dataValue = labelKey ? d[labelKey] : d;
        if (typeof dataValue !== 'string') return false;
        return dataValue.toUpperCase().includes(keyword.toUpperCase());
      }));
    }
  }, [data, keyword, labelKey]);

  const onSearch = useCallback((e) => {
    setKeyword(e.target.value);
  }, []);

  const renderOverlay = useCallback((toggleVisible) => {
    const handleClick = (action, index) => () => {
      toggleVisible(false);
      onChange(action, index);
    };

    const menuItems = (
      <div className={`${menuPrefixCls}-wrapper`} style={style}>
        {
          searchData.map((action, index) => {
            const dataIndex = data.findIndex((d) => {
              if (labelKey) {
                return d[labelKey] === action[labelKey];
              }
              return d === action;
            });
            return (
              <DropdownMenuItem
                key={index}
                className={classNames({ [`${menuItemPrefixCls}-active`]: activeIndex === dataIndex })}
                onClick={handleClick(action, dataIndex)}
              >
                {action && action[labelKey] ? action[labelKey] : action}
              </DropdownMenuItem>
            );
          })
        }
      </div>
    );

    if (searchable) {
      return (
        <>
          <div className={`${menuPrefixCls}-search`}>
            <Input.Search placeholder={placeholder} onChange={onSearch} value={keyword} />
          </div>
          {menuItems}
        </>
      );
    }
    return menuItems;
    // --> potential bug inside this useCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange, searchData, labelKey, activeIndex, placeholder, searchable]);

  return (
    <Dropdown
      fixed={fixed}
      transitionOffset={transitionOffset}
      className={classNames(className, menuPrefixCls)}
      onVisibleChange={onVisibleChange}
      renderOverlay={renderOverlay}
      closeOnClick={!searchable}
      horizontalOffset={horizontalOffset}
      placement={placement}
    >
      {children}
    </Dropdown>
  );
};

DropdownMenu.propTypes = {
  activeIndex: PropTypes.number,
  horizontalOffset: PropTypes.number,
  searchable: PropTypes.bool,
  placeholder: PropTypes.string,
  transitionOffset: PropTypes.number,
  fixed: PropTypes.bool,
};

DropdownMenu.defaultProps = {
  activeIndex: null,
  horizontalOffset: 6,
  searchable: false,
  placeholder: '',
  transitionOffset: 4,
  fixed: false,
};

export default DropdownMenu;
