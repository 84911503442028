import React from 'react';
import { connect, Provider } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Router, Route, Switch, Redirect,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ApolloProvider } from '@apollo/client';
import _ from 'lodash';
import { getClient } from './graphql/setup';
import {
  buildQueryObject,
  isFunction,
} from '../lib/lib';
import config from '../lib/config';
import namespace from './namespace';
import { clearStore, createAppStore, getStore } from './storeprovider';

const F = React.Fragment;

const history = createBrowserHistory();

const param = (props, field) => {
  let value;
  try {
    value = props.match.params[field];
  } catch (ex) {
    // do nothing
  }
  return value;
};

const makeContexts = (arr) => {
  const obj = {};
  arr.forEach((value) => {
    obj[value] = PropTypes.any;
  });
  return obj;
};

const splash = ({ start = false, finish = false, text = '' }) => ({
  splash: {
    text: finish && !text ? 'Success' : text,
    isLoading: !finish,
    show: start || finish,
  },
});

const create = (initState = {}, rootComponent = null, vungleSource) => {
  clearStore();
  const logger = (s) => (next) => (action) => {
    if (config.get('loggerDisabled') === 'false') {
      /* Redux logger for dev environment - ESLint disabled to remove warnings */
      /* eslint-disable */
      console.group(action.type);
      console.info('dispatching', action);
      console.log('next state', s.getState());
      console.groupEnd(action.type);
      /* eslint-enable */
    }
    const result = next(action);
    return result;
  };

  const appStore = createAppStore(initState, logger);

  const Root = ({ reduxStore }) => (
    <ApolloProvider
      client={getClient(vungleSource)}
    >
      <Provider store={reduxStore}>
        <Router history={history}>
          <Route path="/" component={rootComponent} />
        </Router>
      </Provider>
    </ApolloProvider>
  );

  return (
    <Root reduxStore={appStore} />
  );
};

const dispatchAction = (type = 'ACTION', endFn = () => {}) => {
  const store = getStore();
  store.dispatch({
    type,
    end: ({ state }) => ({
      ...state,
      ...endFn({ state }),
    }),
  });
};

const splashStart = (text = 'Processing...') => {
  dispatchAction('SHOW_SPLASH_ACTION', () => splash({ start: true, text }));
};

const splashFinish = (text = 'Success') => {
  dispatchAction('HIDE_SPLASH_ACTION', () => splash({ finish: true, text }));
};

const defaultAction = {
  start: () => ({}),
  end: () => ({}),
  error: () => ({}),
};
const makeAction = (type = 'ACTION', action = {}, result = {}, ownProps = null) => ({
  type,
  ...defaultAction,
  ...action,
  result,
  props: ownProps,
});
const defaultConfig = {
  props: () => ({}),
  actions: {},
};
const compose = (component, incomingConfig = {}) => {
  const composeConfig = { ...defaultConfig, ...incomingConfig };
  const actions = Object.keys(composeConfig.actions);
  const mapDispatchToActions = (dispatch) => {
    const mappedActions = {};
    actions.forEach((action) => {
      const actionToPerform = composeConfig.actions[action];
      mappedActions[action] = (ownProps) => {
        const actionType = `${_.snakeCase(action).toUpperCase()}_ACTION`;
        if (typeof actionToPerform === 'function') {
          dispatch(makeAction(actionType, { end: ({ state }) => actionToPerform({ state, values: ownProps }) }));
          return;
        }
        const a = actionToPerform;
        if (isFunction(a.start)) {
          dispatch(makeAction(`${actionType}_PENDING`, a, ownProps, ownProps));
        }
        const reduxState = getStore().getState();
        // Deliver reduxState to action
        Promise.resolve(actionToPerform.action(ownProps, reduxState))
          .then((result) => {
            dispatch(makeAction(`${actionType}_FULFILLED`, a, result, ownProps));
          })
          .catch((err) => {
            if (!config.get('disableErrorStackTrace')) {
              /* eslint-disable */
              console.group('async action error');
              console.error(err);
              console.groupEnd('async action error');
              /* eslint-enable */
            }
            dispatch(makeAction(`${actionType}_REJECTED`, a, err, ownProps));
          });
      };
    });
    mappedActions.actions = mappedActions;
    return mappedActions;
  };

  const mapStateToProps = (state, props) => {
    const mappedProps = composeConfig.props(state, props) || {};
    const queryParams = buildQueryObject(props.location.search);
    return {
      ...mappedProps,
      router: {
        queryParams,
        location: props.location,
        match: props.match,
        history: props.history,
      },
      props: mappedProps,
    };
  };
  const ConnectedComponent = connect(
    mapStateToProps,
    mapDispatchToActions,
  )(component);

  const ReturnComp = (props) => (
    <Route render={(routerProps) => <ConnectedComponent {...props} {...routerProps} renderProps={props} />} />
  );

  return ReturnComp;
};

export default React;
export {
  compose,
  config,
  create,
  F,
  dispatchAction,
  makeContexts,
  namespace,
  param,
  splash,
  splashFinish,
  splashStart,
  Redirect,
  Route,
  Router,
  Switch,
  history,
};
