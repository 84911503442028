import React from 'react';
import { matchPath } from 'react-router';
import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import ConfirmAndContinue from 'services/advertiser/views/campaigns/Edit/ConfirmAndContinue';
import { processCampaignSave, validateBidding } from 'lib/helpers/campaigns/campaignIndex';
import Button from '../../../../../components/Button/Button';
import ButtonComponent from '../../../../../components/V2/Button/Button';
import ConfirmBox from '../../../../../components/Modals/Confirm';
import ApplicationThumbnail from '../../../../../templates/application/thumbnail';
import ApplicationCreator from '../../../../../templates/ApplicationCreator/ApplicationCreatorContainer';
import ApplicationSelector from './ApplicationSelector/ApplicationSelectorContainer';
import Details from './Details/DetailsContainer';
import Confirm from './Confirm/ConfirmContainer';
import Creatives from './Creatives/CreativesContainer';
import Strategy from './Strategy/StrategyContainer';
import Targeting from './Targeting/Targeting';
import TrackingContainer from './Tracking/TrackingContainer';
import paths, { getPath } from '../../../paths';
import Crumb from '../../../../../components/Crumb/Crumb';
import EnableSKAdNetwork from './EnableSKAdNetwork/EnableSKAdNetwork';
import getCreativeClassName from '../../../../../lib/helpers/getCreativeClassName/getCreativeClassName';
import { canModifyCampaigns } from '../../../../../lib/helpers/authUser';

const {
  CAMPAIGN: {
    pathString: baseRoute,
    getPath: getPathToCampaign,
  },
  CAMPAIGN_DETAILS: {
    pathString: PATH_CAMPAIGN_DETAILS,
    getPath: getCampaignCreateDetailPath,
  },
  CAMPAIGN_CREATE: {
    pathString: PATH_CAMPAIGN_CREATE,
  },
  CAMPAIGN_APPLICATION: {
    pathString: PATH_CAMPAIGN_APPLICATION,
  },
  CAMPAIGN_SKENABLEMENT: {
    pathString: PATH_SKENABLMEMENT,
    getPath: getCampaignSKEnablementPath,
  },
  CAMPAIGN_TRACKING: {
    pathString: PATH_CAMPAIGN_TRACKING,
    getPath: getCampaignCreateTrackingPath,
  },
  CAMPAIGN_TARGETING: {
    pathString: PATH_CAMPAIGN_TARGETING,
    getPath: getCampaignTargetingPath,
  },
  CAMPAIGN_BIDDING: {
    pathString: PATH_CAMPAIGN_BIDDING,
    getPath: getCampaignBiddingPath,
  },
  CAMPAIGN_CREATIVES: {
    pathString: PATH_CAMPAIGN_CREATIVES,
    getPath: getCampaignCreativesPath,
  },
  CAMPAIGN_CONFIRM: {
    pathString: PATH_CAMPAIGN_CONFIRM,
    getPath: getConfirmPath,
  },
} = paths;

export const hasSeenStep = (pathForStep, visitedSteps, pathname, id) => (
  id !== 'create' || (
    visitedSteps.includes(pathForStep)
    && !matchPath(pathname, { path: pathForStep, exact: true })
  )
);

const hasSeenAppStep = (visitedSteps, pathname, id) => hasSeenStep(
  getPathToCampaign(id),
  visitedSteps,
  pathname,
  id,
);

const hasSeenSKAdNetworkStep = (visitedSteps, pathname, id) => hasSeenStep(
  getCampaignSKEnablementPath(id),
  visitedSteps,
  pathname,
  id,
);

const hasSeenTrackingStep = (visitedSteps, pathname, id) => hasSeenStep(
  getCampaignCreateTrackingPath(id),
  visitedSteps,
  pathname,
  id,
);

const hasSeenTargetingStep = (visitedSteps, pathname, id) => hasSeenStep(
  getCampaignTargetingPath(id),
  visitedSteps,
  pathname,
  id,
);

class Edit extends React.Component {
  static getDerivedStateFromProps({ location: { pathname } }, { visitedSteps }) {
    const alreadyVisited = visitedSteps.find(
      (path) => (!!matchPath(pathname, { path, exact: true })),
    );

    if (!alreadyVisited) {
      return {
        visitedSteps: visitedSteps.concat(pathname),
      };
    }

    return null;
  }

  // eslint-disable-next-line react/state-in-constructor
  state = {
    visitedSteps: [],
  };

  render() {
    const {
      props: {
        location,
        location: {
          pathname,
        },
        match: {
          params: {
            edit: id,
          },
        },
        showModal,
        router,
        props: {
          campaign, originalCampaign, editing, changingValue, pageContinue,
        },
        actions: {
          createCampaign, toggleModal, confirmExit,
        },
      },
      state: {
        visitedSteps,
      },
    } = this;
    const application = campaign.get('application');
    const showSKAdNetwork = campaign.isSKAdNetworkCapable();
    const isConfirm = getPath(location).path === 'confirm';
    const isDetailStepComplete = campaign.validateDetail();
    const pastAppStep = campaign.get('id') || hasSeenAppStep(visitedSteps, pathname, id) || application?.get('id');
    const pastSKAdNetworkStep = campaign.get('id') || hasSeenSKAdNetworkStep(visitedSteps, pathname, id);
    const isTrackingStepComplete = (campaign.get('id') || hasSeenTrackingStep(visitedSteps, pathname, id)) && campaign.validateTrackingEvents();
    const isTargetingStepComplete = (campaign.get('id') || hasSeenTargetingStep(visitedSteps, pathname, id)) && campaign.validateTargeting();
    const showConfirm = validateBidding(campaign) && campaign.canConfirm();
    const userCanModifyCampaign = canModifyCampaigns();

    return (
      <div className="views__advertiser__campaigns__edit">
        {showModal && (
          <ConfirmBox
            header="Exit Workflow"
            message="Are you sure you want to exit the Campaign create workflow?"
            onCancel={toggleModal}
            onConfirm={confirmExit}
          />
        )}
        <div className="breadcrumbs">
          <div className="details">
            <div className="application-thumbnail">
              <ApplicationThumbnail application={application} />
              <p className="application-thumbnail-app-name">{application ? application.get('name') : 'Choose Application'}</p>
            </div>
          </div>
          <div className="crumbs">
            <Crumb
              name="Application"
              active={matchPath(pathname, { path: PATH_CAMPAIGN_CREATE, exact: true })}
              complete={!!pastAppStep}
            />
            {
              showSKAdNetwork && (
                <Crumb
                  name="SKAdNetwork"
                  active={matchPath(pathname, { path: PATH_SKENABLMEMENT, exact: true })}
                  complete={!!pastSKAdNetworkStep}
                />
              )
            }
            <Crumb
              name="Details"
              active={matchPath(pathname, { path: PATH_CAMPAIGN_DETAILS, exact: true })}
              complete={isDetailStepComplete}
              to={getCampaignCreateDetailPath(id)}
            />
            <Crumb
              name="Tracking"
              active={matchPath(pathname, { path: PATH_CAMPAIGN_TRACKING, exact: true })}
              complete={isTrackingStepComplete}
              to={getCampaignCreateTrackingPath(id)}
            />
            <Crumb
              name="Bidding"
              active={matchPath(pathname, { path: PATH_CAMPAIGN_BIDDING, exact: true })}
              complete={validateBidding(campaign)}
              to={getCampaignBiddingPath(id)}
            />
            <Crumb
              name="Targeting"
              active={matchPath(pathname, { path: PATH_CAMPAIGN_TARGETING, exact: true })}
              complete={isTargetingStepComplete}
              to={getCampaignTargetingPath(id)}
            />
            <Crumb
              name="Creatives"
              active={matchPath(pathname, { path: PATH_CAMPAIGN_CREATIVES, exact: true })}
              complete={campaign.validateCreatives()}
              to={getCampaignCreativesPath(id)}
            />
            {showConfirm && (
              <Crumb
                name="Confirm"
                active={matchPath(pathname, { path: PATH_CAMPAIGN_CONFIRM, exact: true })}
                complete={campaign.validateConfirm()}
                to={getConfirmPath(id)}
              />
            )}
            {campaign.get('id') && (
              <div className="budget_remaining">
                <p className="p2">Budget Remaining</p>
                <hr />
                <p className="p2">{campaign.getBudgetRemaining()}</p>
              </div>
            )}
          </div>
          <div className="exit">
            <ButtonComponent onClick={toggleModal}>Exit</ButtonComponent>
          </div>
        </div>
        <div className={getCreativeClassName(['workflow'], pathname, PATH_CAMPAIGN_CREATIVES)}>
          <div className="workflow-options">
            <Switch>
              <Route
                exact
                path={PATH_CAMPAIGN_APPLICATION}
                component={ApplicationCreator}
              />
              <Route
                exact
                path={PATH_SKENABLMEMENT}
                component={EnableSKAdNetwork}
              />
              <Route
                exact
                path={PATH_CAMPAIGN_DETAILS}
                component={Details}
              />
              <Route
                exact
                path={PATH_CAMPAIGN_TRACKING}
                component={TrackingContainer}
              />
              <Route
                exact
                path={PATH_CAMPAIGN_BIDDING}
                component={Strategy}
              />
              <Route
                exact
                path={PATH_CAMPAIGN_TARGETING}
                component={Targeting}
              />
              <Route
                exact
                path={PATH_CAMPAIGN_CREATIVES}
                component={Creatives}
              />
              {showConfirm && (
                <Route
                  exact
                  path={PATH_CAMPAIGN_CONFIRM}
                  component={Confirm}
                />
              )}
              <Route
                exact
                path={baseRoute}
                component={ApplicationSelector}
              />
              <Redirect
                to={getPathToCampaign(id)}
              />
            </Switch>
          </div>
          {application && (
            <div className="workflow-footer">
              {!isConfirm && (
                <ConfirmAndContinue
                  location={location}
                  campaign={campaign}
                  changingValue={changingValue}
                  editing={editing}
                  router={router}
                  pageContinue={pageContinue}
                />
              )}
              {isConfirm && (
                <div className="confirm-buttons">
                  <Button
                    inverse
                    disabled={!campaign.validateConfirm() || !userCanModifyCampaign}
                    onClick={() => {
                      if (editing) {
                        processCampaignSave(campaign, originalCampaign, () => createCampaign({
                          campaign,
                          active: false,
                        }));
                      } else {
                        createCampaign({
                          campaign,
                          active: false,
                        });
                      }
                    }}
                  >
                    Save &amp; Pause
                  </Button>
                  <Button
                    continue
                    disabled={!campaign.validateConfirm() || !userCanModifyCampaign || campaign.isV0Campaign()}
                    onClick={() => {
                      if (editing) {
                        processCampaignSave(campaign, originalCampaign, () => createCampaign({
                          campaign,
                          active: true,
                        }));
                      } else {
                        createCampaign({
                          campaign,
                          active: true,
                        });
                      }
                    }}
                  >
                    Save &amp; Activate
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Edit;
