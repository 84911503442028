import React from 'react';
import { compose } from '../../../app/app';
import DeviceFrame from '../../DeviceFrame/DeviceFrame';
import NativePreview from '../NativePreview/NativePreview';

class Preview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      assetURL,
      mainVideo,
      placementType,
      format,
      onClose,
      onRefresh,
      creative,
      replacements,
      isMissionControl,
      history,
      isShowEditCreativeAction,
    } = this.props;
    const isNativeTemplate = creative.raw('template.template_category.native') === true;
    return (
      <div className="template__creative-preview">
        <div className="preview-container">
          {isNativeTemplate
            ? (
              <NativePreview
                replacements={replacements}
                videoUrl={creative.get('video')?.url}
                languageCode={creative.get('language.code')}
                creativeId={creative.get('id')}
                isMissionControl={isMissionControl}
                isShowEditCreativeAction={isShowEditCreativeAction}
                isShowCloseBtn
                onClose={onClose}
                history={history}
              />
            ) : (
              <DeviceFrame
                assetURL={assetURL}
                mainVideo={mainVideo}
                placementType={placementType}
                format={format}
                onRefresh={onRefresh}
                isShowEditCreativeAction={isShowEditCreativeAction}
                isShowCloseBtn
                onClose={onClose}
                template={{
                  isBannerTemplate: creative.raw('template.template_category.banner') === true,
                  name: creative.raw('template.name'),
                }}
                creativeId={creative.get('id')}
                isMissionControl={isMissionControl}
                history={history}
              />
            )}
        </div>
      </div>
    );
  }
}

export default compose(Preview);
