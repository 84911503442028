import React, { useEffect, useCallback } from 'react';
import Link from '../../../../../../components/Link/Link';
import ApplicationTemplate from '../../../../../../templates/application/application';
import paths from '../../../../paths';

const {
  CAMPAIGN_APPLICATION: {
    getPath: getPathToCreateApp,
  },
  CREATE_CREATIVES: {
    getPath: getPathToCreateCreatives,
  },
  CAMPAIGN_DETAILS: {
    getPath: getPathToDetailTracking,
  },
  CAMPAIGN_SKENABLEMENT: {
    getPath: getPathToSkEnablement,
  },
} = paths;

const ApplicationSelector = ({
  history,
  applications,
  campaign,
  creative,
  type,
  router,
  handleSkAppClick = () => {},
  updateCreative = () => {},
}) => {
  const { queryParams } = router;
  const { application: applicationSelectedId } = queryParams;
  const appsValidForCampaignCreation = applications.filter(
    (a) => a.isValidForCampaignCreation(),
  );
  const isCreative = type === 'creative';

  const handleApplicationClick = useCallback((application) => {
    const nextForNonCreative = application.isSKAdNetworkEnabled()
      ? getPathToSkEnablement()
      : getPathToDetailTracking();
    if (application.isSKAdNetworkEnabled()) {
      handleSkAppClick(application, creative, updateCreative);
    }

    if (isCreative) {
      creative.set('application', application, false);
    } else {
      campaign.setApplication(application, false);
    }

    history.push(
      isCreative
        ? getPathToCreateCreatives()
        : nextForNonCreative,
    );
  }, [creative, campaign, history, isCreative, handleSkAppClick, updateCreative]);

  useEffect(() => {
    if (applicationSelectedId && appsValidForCampaignCreation.length > 0) {
      const applicationSelected = appsValidForCampaignCreation.find((i) => i.get('id') === applicationSelectedId);
      if (applicationSelected) {
        handleApplicationClick(applicationSelected);
      }
    }
  }, [applicationSelectedId, appsValidForCampaignCreation, handleApplicationClick]);

  return (
    <div className="views__advertiser__campaigns__edit__application-selector">
      <div className="header">
        <p className="h3">Choose Your Application</p>
        <p className="p2">Select an application from your Vungle account, or add a new one</p>
      </div>
      <div className="applications-container">
        <div className="applications">
          {!isCreative && (
            <Link to={getPathToCreateApp()}>
              <div className="option">
                <div className="icon">
                  <i className="material-icons">add</i>
                </div>
                <div className="details">
                  <span className="add">New</span>
                </div>
              </div>
            </Link>
          )}
          {appsValidForCampaignCreation.map((application) => (
            <Link
              key={application.get('id')}
              onClick={() => handleApplicationClick(application)}
            >
              <ApplicationTemplate
                application={application}
                shadow
              />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ApplicationSelector;
