import React from 'react';
import PropTypes from 'prop-types';
import imgAndroid from 'assets/img/android.png';
import imgIos from 'assets/img/ios.png';
import imgWindows from 'assets/img/windows.png';
import imgAmazon from 'assets/img/amazon.png';
import RoundPlatformIcon from '../RoundPlatformIcon/RoundPlatformIcon';
import './thumbnail.scss';

const PLATFORM_ANDROID = 'android';
const PLATFORM_IOS = 'ios';
const PLATFORM_WINDOWS = 'windows';
const PLATFORM_AMAZON = 'amazon';

const defaultIcon = {
  [PLATFORM_ANDROID]: imgAndroid,
  [PLATFORM_IOS]: imgIos,
  [PLATFORM_WINDOWS]: imgWindows,
  [PLATFORM_AMAZON]: imgAmazon,
};

const Thumbnail = ({ prefixCls, source, platform }) => (
  <div className={prefixCls}>
    <img src={source || defaultIcon[platform]} />
    <RoundPlatformIcon className={`${prefixCls}__icon`} platform={platform} />
  </div>
);

Thumbnail.propTypes = {
  prefixCls: PropTypes.string,
  /**
   * thumbnail image source
   */
  source: PropTypes.string.isRequired,
  /**
   * platform for thumbnail
   */
  platform: PropTypes.oneOf([
    PLATFORM_ANDROID,
    PLATFORM_IOS,
    PLATFORM_WINDOWS,
    PLATFORM_AMAZON,
  ]),
};
Thumbnail.defaultProps = {
  platform: 'ios',
  prefixCls: 'v2_application_thumbnail',
};

export default Thumbnail;
