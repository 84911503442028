import {
  attempt,
  isError,
  isNumber,
} from 'lodash';
import {
  getCampaign,
  getCampaigns,
  getCampaignsForCreativeAttachment,
  changeCampaignStatus,
  deleteCampaign,
  restoreCampaign,
  createCampaign,
  modifyCampaign,
  getLargeBiddingDiff,
  updateCampaignAfterAbTesting,
  updateCreativesForCampaign,
} from 'app/graphql/utils/campaigns';
import {
  getCampaignHistories,
  getCampaignReviewHistories,
} from 'app/graphql/utils/history';
import {
  isCampaignObjectiveCPEIap,
  isCampaignObjectiveCPPUIap,
  isCampaignObjectiveRoasIap,
  getTargetGoals,
  getRateTypeSettingsByName,
} from 'lib/capabilities/campaigns';
import config from 'lib/config';
import {
  contains,
  isArray,
  isEmptyObject,
  isHexId,
  isObject,
  isUndefined,
  split,
  toNumberOrNull,
} from 'lib/lib';
import {
  add,
  isAfter,
  isValidUTC,
  locale,
} from 'lib/date';
import { format } from 'lib/currency';
import { put } from 'lib/http';
import { isValidMacroURL } from 'lib/helpers';
import { validateMacroURL } from 'services/Templates/Campaigns/MMP/helper';
import {
  BUDGET_OBJECTIVE_DYNAMIC,
  BUDGET_OBJECTIVE_STATIC,
  BUDGET_TARGET_HORIZON_DYNAMIC_IAP,
  FORMAT_TYPE_FULLSCREEN,
  PACING_METHOD_DYNAMIC,
  RATE_TYPES,
  DYNAMIC_BID_MAX_MAX,
  DYNAMIC_BID_MAX_MIN,
  CPE_TARGET_MAX,
  CPE_TARGET_MIN,
  CPPU_TARGET_MAX,
  CPPU_TARGET_MIN,
  ROAS_TARGET_MAX,
  ROAS_TARGET_MIN,
  SUBSIDIZATION_MULTIPLIER_MAX,
  SUBSIDIZATION_MULTIPLIER_MIN,
} from 'app/constants/campaign';
import {
  validateBidding as handleValidateBidding,
  setGeoBudget,
  isValidLongTailBid,
  dailyGeoOption,
} from 'lib/helpers/campaigns/campaignIndex';
import { isBetween, isLowerThanMin } from 'lib/validation';
import { trackingProviders } from 'config/campaign-tracking-providers';
import Model from './BaseModel';
import Application from './Application';
import Creative from './Creative';
import historyConfig from './campaign/historyConfig';
import triggerAlertModal from '../components/Modals/Alert/triggerModal';

const {
  countries: ALL_COUNTRIES,
  cities,
  subdivisions,
} = config.get('countryData');
const {
  defaultRateType,
  supportedEvents,
  allRateTypes: rateTypes,
  additionalEvents,
  clickEvent,
  impressionEvent,
  skadnetworkMinOsVersion,
} = config.get('campaigns');

const bidsBudgetsAlertThresholds = config.get('bidsBudgetsAlertThresholds');

const bidTypes = {
  click: 'cpc',
  complete: 'cpcv',
  impression: 'cpm',
  install: 'cpi',
};

export function isTargetSubsidizationMultiplierValid(targetSubsidizationMultiplier) {
  const hasEmptyValue = targetSubsidizationMultiplier === null || targetSubsidizationMultiplier === undefined;
  const isInValidRange = isBetween(SUBSIDIZATION_MULTIPLIER_MIN, SUBSIDIZATION_MULTIPLIER_MAX, targetSubsidizationMultiplier);
  return hasEmptyValue || isInValidRange;
}
class Campaign extends Model {
  static CAMPAIGN_SCHEMA_VERSION_V1 = 1;

  static CAMPAIGN_SCHEMA_VERSION_V0 = 0;

  constructor(attrs = {}) {
    // Don't flatten bidding since bidding will be formatted and saved in `budget.publisher_rates` fields
    const { bidding, ...restAttrs } = attrs;
    const newAttrs = { ...restAttrs };
    if (attrs.targeting && attrs.targeting.geo) {
      if (attrs.targeting.geo.countries?.length > 0) {
        newAttrs.targeting.geo.countries = attrs.targeting.geo.countries.map((c) => {
          const index = ALL_COUNTRIES.findIndex((country) => country.code === c.code);
          return { ...ALL_COUNTRIES[index] };
        });
      } else if (attrs.targeting.geo.subdivisions?.length > 0) {
        newAttrs.targeting.geo.subdivisions = attrs.targeting.geo.subdivisions.map(({ code }) => {
          const subdivision = subdivisions.find((s) => s.code === code);
          return { ...subdivision };
        });
      }
    }
    super(newAttrs);
    if (attrs.targeting && attrs.targeting.geo && attrs.targeting.geo.region === 'city') {
      this.wasCityTargeted = true;
    }
    if (attrs.budget && attrs.budget.type === 'view') {
      this.wasBudgetTypeView = true;
    }
    if (attrs.targeting && attrs.targeting.publisher && attrs.targeting.publisher.applications) {
      this.set('targeting.publisher.applications.ids', attrs.targeting.publisher.applications.map(({ id }) => id).join(','), false);
    }
    if (attrs.budget && attrs.budget.daily_spend_limit_geos) {
      if (attrs.budget.daily_spend_limit_geos.length > 0 && attrs.budget.daily_spend_limit_type === 'geo') {
        this.geoDailySpend();
      }
    }
    this.map('application', this.getRelation('application', Application).bind(this));
    this.map('creatives', this.getArrayRelation('creatives', Creative).bind(this));
    this.map('creatives_b', this.getArrayRelation('creatives_b', Creative).bind(this));
    this.map('targeting.publisher.applications', this.getArrayRelation('targeting.publisher.applications', Application).bind(this));
    this.map('dates.start', 'dates.end', locale);
    this.map('is_archived', () => (attrs.is_archived ? 'Inactive' : 'Active'));
    this.convertBiddingForClient(bidding);
    this.map('budget.total', 'budget.daily', 'budget.spent', format);
    this.map('budget.bid_override.value', 'budget.total_override.value', 'budget.daily_override.value', format);
    this.map('budget.type', this.getRateType);
    this.map('budget.bid', this.getRate.bind(this));
    this.map('tracking.events.names', this.getTrackingEventNames.bind(this));

    this.default('dates.is_indefinite', false);
    this.default('status', this.isActiveCamp());
    this.default('admin_status', 'approved', [null]);
    this.default('creatives', []);
    if (!attrs.id) {
      this.default('tracking.events', []);
    }
    this.default('budget.type', defaultRateType);
    this.default('budget.objective.type', 'static');
    this.default('budget.publisher_rates', []);
    this.default('budget.daily_spend_limit_type', 'campaign');
    this.default('budget.daily_spend_limit_geos', []);
    this.default('budget.pacing.method', PACING_METHOD_DYNAMIC);
    this.default('targeting.geo.region', 'country');
    this.default('targeting.geo.countries', []);
    this.default('targeting.geo.subdivisions', []);
    this.default('targeting.geo.cities', []);
    this.default('targeting.network', 'all');
    this.default('targeting.devices.phone', true);
    this.default('targeting.devices.tablet', true);
    this.default('targeting.device_models.type', 'none');
    this.default('targeting.device_models.device_whitelist', []);
    this.default('targeting.device_models.device_blacklist', []);
    this.default('targeting.device_models.target_new_phone_models', false);
    this.default('targeting.device_models.target_new_tablet_models', false);
    this.default('targeting.publisher.applications', []);
    this.default('targeting.publisher.type', 'none');
    this.default('tracking.url', '');
    this.default('multibidding.errorMessage', null);
    this.default('skadnetwork_changeable', typeof attrs.is_skadnetwork_enabled !== 'boolean');

    this.default('targeting.carrier.mode', 'allow');
    this.default('targeting.carrier.carrierAllowList', []);
    this.default('targeting.carrier.carrierDenyList', []);
    this.default('targeting.genre.mode', 'none');
    this.default('targeting.genre.genreList', []);
    this.default('settings.skoverlay_auto_show', false);

    // this is not sent to the server. It is a UI-only field
    this.default('dynamicGeos', []);
  }

  supportsSKANABTesting() {
    return this.isV1Campaign() && this.get('attribution_method') === 'skadnetwork';
  }

  completesSKANABTestingSetB() {
    if (!this.get('is_ab_testing')) {
      return true;
    }
    if (this.get('creatives')?.length === 0) {
      return true;
    }
    return this.get('creatives_b')?.length === this.get('creatives')?.length;
  }

  isV1Campaign() {
    return this.get('schema_version') === Campaign.CAMPAIGN_SCHEMA_VERSION_V1;
  }

  isValidSKANABTestingSetA() {
    const creatives = this.get('creatives') || [];
    return creatives.length > 0;
  }

  isValidSKANABTestingSetB() {
    const creatives = this.get('creatives') || [];
    const creativesB = this.get('creatives_b') || [];
    if (this.get('is_ab_testing') && creatives.length > 0 && creativesB.length === creatives.length) {
      const creativeIds = creatives.map((c) => c.get('id'));
      const creativeBIds = creativesB.map((c) => c.get('id'));
      return creativeBIds.some((id) => !creativeIds.includes(id));
    }
    return true;
  }

  hasBudgetRemaining() {
    return this.getNumber('budget.total') > this.getNumber('budget.spent');
  }

  getBudgetRemaining() {
    return format(this.getNumber('budget.total') - this.getNumber('budget.spent'));
  }

  getRemainingSKANIDs() {
    return this.get('application').get('skadnetwork_settings.usage.remaining') || 0;
  }

  isCityTargeted() {
    return !!this.wasCityTargeted;
  }

  isBudgetStatic() {
    return this.raw('budget.objective.type') === 'static';
  }

  isBudgetTypeView() {
    return !!this.wasBudgetTypeView;
  }

  getPlatform = () => this.get('application')?.get('platform');

  isIOS = () => this.getPlatform() === 'ios';

  isAndroid = () => this.getPlatform() === 'android';

  isTargetingSpecificDevices() {
    return (this.get('targeting.device_models.type') === 'allow'
        && this.get('targeting.device_models.device_whitelist').length !== 0)
        || (this.get('targeting.device_models.type') === 'deny'
        && this.get('targeting.device_models.device_blacklist').length !== 0);
  }

  isTargetingDevices() {
    return this.get('targeting.device_models.type') !== 'none';
  }

  isTargetingNewDevices() {
    return this.get('targeting.device_models.type') !== 'none'
    && (this.get('targeting.device_models.target_new_tablet_models')
            || this.get('targeting.device_models.target_new_phone_models'));
  }

  hasIOSDevice(device) {
    const devices = this.get('targeting.device_models.type') === 'allow'
      ? this.get('targeting.device_models.device_whitelist')
      : this.get('targeting.device_models.device_blacklist');
    return !!devices.find(({ name }) => name === device.name);
  }

  toggleIOSDevices(include, devices) {
    if (!devices) {
      return;
    }
    const targetType = this.get('targeting.device_models.type');
    let deviceList = targetType === 'allow'
      ? this.get('targeting.device_models.device_whitelist')
      : this.get('targeting.device_models.device_blacklist');
    if (include) {
      deviceList.push(...devices);
    } else {
      const deviceNames = new Set(devices.map(({ name }) => name));
      deviceList = deviceList.filter(({ name }) => !deviceNames.has(name));
    }
    if (targetType === 'allow') {
      this.set('targeting.device_models.device_whitelist', deviceList);
    } else {
      this.set('targeting.device_models.device_blacklist', deviceList);
    }
  }

  toggleAllDevices(include, models, searchValue = '') {
    let deviceList = [];
    if (include) {
      if (searchValue.length > 0) {
        deviceList = models.filter((d) => contains(d.name, searchValue));
      } else {
        deviceList = models;
      }
    }
    const targetType = this.get('targeting.device_models.type');
    if (targetType === 'allow') {
      this.set('targeting.device_models.device_whitelist', deviceList);
    } else {
      this.set('targeting.device_models.device_blacklist', deviceList);
    }
  }

  getDateString() {
    return `${this.getDate('dates.start')} - ${this.get('dates.is_indefinite') ? 'Indefinite' : this.getDate('dates.end')}`;
  }

  hasBeenDeleted = () => this.get('is_deleted') !== null && !isUndefined(this.get('is_deleted')) && this.get('is_deleted')

  getTrackingUrl(type = 'click') {
    try {
      const name = this.raw('tracking.provider.name');
      const platform = this.get('application').get('platform');
      const customerId = this.raw('tracking.provider.customer');
      const url = this.raw('tracking.provider.url');
      const siteId = this.raw('tracking.provider.site');
      const publisherId = this.raw('tracking.provider.publisher');
      const offerId = this.raw('tracking.provider.offer');
      const trackingProvider = trackingProviders[name];
      const providerUrl = trackingProvider[type][platform];
      const bidType = this.raw('budget.type');
      switch (name) {
        case 'appsflyer':
        case 'adjust':
        case 'kochava':
          return providerUrl.replace('%%%PROVIDER_ID%%%', customerId).replace('%%%BID_TYPE%%%', bidTypes[bidType]);
        case 'tune':
          return providerUrl
            .replace('%%%PROVIDER_URL%%%', url)
            .replace('%%%PROVIDER_SITE_ID%%%', siteId)
            .replace('%%%PROVIDER_PUBLISHER_ID%%%', publisherId)
            .replace('%%%PROVIDER_OFFER_ID%%%', offerId);
        default:
          return '';
      }
    } catch (ex) {
      if (type === 'click' && this.raw('tracking.url')) {
        return this.raw('tracking.url');
      }
      return '';
    }
  }

  geoDailySpend() {
    let geoObj = this.get('budget.daily_spend_limit_geos');
    const limitType = this.raw('budget.daily_spend_limit_type');

    if (geoObj.length > 0) {
      geoObj = this.get('budget.daily_spend_limit_geos').filter((obj, index) => {
        const uniqGeo = {};
        const val = {};
        if (!uniqGeo[obj.geo]) {
          uniqGeo[obj.geo] = index;
          val.geo = obj.geo;
          val.rate = obj.rate === 0 ? '' : obj.rate;
        }
        return val;
      });
    }

    if (limitType === 'geo') {
      const existingGeos = geoObj.map((g) => g.geo);
      this.raw('targeting.geo.countries').forEach((c) => {
        if (!existingGeos.includes(c.code)) {
          geoObj.push({
            geo: c.code,
            rate: '',
          });
        }
      });
      this.set('budget.daily_spend_limit_geos', geoObj);
    }
  }

  changeStatus(status) {
    return changeCampaignStatus(this.raw('id'), status);
  }

  delete() {
    if (this.get('is_deleted') === true) {
      return restoreCampaign(this.raw('id'));
    }
    return deleteCampaign(this.raw('id'));
  }

  setDestinationUrl() {
    if (!this.raw('tracking.destination')) {
      const platform = this.get('application').get('platform');
      const storeId = this.get('application').get('store.id');
      if (platform === 'windows' && storeId) {
        const destination = `ms-windows-store://pdp/?ProductId=${storeId}&cid=Vungle`;
        return destination;
      }
      if (platform === 'amazon' && storeId) {
        const destination = `amzn://apps/android?initiatePurchaseFlow=true&asin=${storeId}`;
        return destination;
      }
    }
    return this.raw('tracking.destination');
  }

  validateConfirm(returnStrings = false) {
    const nameIsValid = !!this.raw('name') && this.raw('name').length >= 4;
    const startIsValid = isValidUTC(this.raw('dates.start'));
    const endIsValid = this.raw('dates.is_indefinite') || (
      isValidUTC(this.raw('dates.end')) && isAfter(add(this.raw('dates.start'), '1 day'), this.raw('dates.end'))
    );
    if (!returnStrings) {
      return nameIsValid && startIsValid && endIsValid;
    }
    const messages = {};
    if (!startIsValid || !endIsValid) {
      messages.dates = 'Must be valid date and times, and end date must be at least 24 hours after the start date';
    }
    if (!nameIsValid) {
      messages.name = 'Must be at least 4 characters long';
    }
    return messages;
  }

  validateTrackingEvents() {
    const application = this.get('application');
    if (!application || !application.get('platform')) {
      return true;
    }
    const platform = application.get('platform');
    const isURLValid = (url) => !Object.keys(validateMacroURL(url, platform)).length;
    const trackingEvents = this.get('tracking.events') || [];
    const toBeValidClickEvent = trackingEvents.find((event) => event.name === clickEvent.name);
    if (toBeValidClickEvent && !isURLValid(toBeValidClickEvent.url)) {
      return false;
    }
    const events = [...additionalEvents, impressionEvent];
    const toValidEvents = trackingEvents.filter((e) => !!e.url && events.some((x) => x.name === e.name));
    const valid = toValidEvents.filter((e) => !!e.url && !isURLValid(e.url));
    return valid.length === 0;
  }

  validateDetail() {
    const passesCreativeTypeValidation = this.isSkadnetworkAttribution()
      ? this.get('creative_type')
      : true;

    return this.get('name')
      && this.raw('dates.start')
      && (this.raw('dates.is_indefinite') || this.raw('dates.end'))
      && passesCreativeTypeValidation;
  }

  validateTracking() {
    const eventsValid = this.get('tracking.events').filter((e) => !!e && !isValidMacroURL(e.url)).length === 0;
    return eventsValid;
  }

  validateMinOsVersion() {
    const minVersion = this.get('targeting.versions.application.min');

    // can not have more than one dot characters
    const minVersionStr = String(minVersion);
    const { length: numberOfDots } = minVersionStr.match(/\./g) || [];
    return numberOfDots <= 1;
  }

  validateGeo() {
    return this.raw('targeting.geo.countries').length > 0
      || this.raw('targeting.geo.cities').length > 0
      || this.raw('targeting.geo.subdivisions').length > 0;
  }

  validateTargeting() {
    return this.validateMinOsVersion();
  }

  isSKAdNetworkCapable() {
    const application = this.get('application');

    return application && application.isSKAdNetworkEnabled();
  }

  isSKAdNetworkCapabilityChangeable() {
    const application = this.get('application');
    return application && application.isSKAdNetworkEnabled();
  }

  isSkadnetworkAttribution() {
    return this.isSKAdNetworkEnabled() && this.get('attribution_method') === 'skadnetwork';
  }

  isMmpAttribution() {
    return this.get('attribution_method') === 'mmp';
  }

  isV0Campaign() {
    return this.get('is_skadnetwork_enabled') && !this.get('schema_version') && !this.isMmpBilled() && this.get('status') === 'paused';
  }

  enableSKAdNetwork() {
    if (this.isSKAdNetworkCapabilityChangeable()) {
      this.set('is_skadnetwork_enabled', true);
    }
  }

  restoreSKAdNetworkValues() {
    this.set('is_skadnetwork_enabled', false);
  }

  // eslint-disable-next-line class-methods-use-this
  numberOrZero(num) {
    if (isNumber(num)) {
      return num;
    }
    return 0;
  }

  validateBid(bid, bidValue) {
    if (this.raw('budget.bid_override.is_enabled')) {
      return true;
    }
    const value = Number(bidValue);
    if (Number.isNaN(value)) return false;
    if (value <= 0) return false;
    return (!bid.max || value <= bid.max) && value >= this.numberOrZero(bid.min);
  }

  validateDaily(daily, dailyValue, dailyType) {
    if (typeof dailyValue === 'undefined' || dailyValue === '') {
      return true;
    }
    if (dailyType === 'geo') {
      return true;
    }
    if (this.raw('budget.daily_override.is_enabled')) {
      return true;
    }
    const value = Number(dailyValue);
    if (Number.isNaN(value)) return false;
    return (!daily.max || value <= daily.max) && value >= daily.min;
  }

  validateTotal(total, totalValue) {
    if (this.raw('budget.total_override.is_enabled')) {
      return true;
    }
    const value = Number(totalValue);
    if (Number.isNaN(value)) return false;
    if (value <= 0) return false;
    return (!total.max || value <= total.max) && value >= total.min;
  }

  getNextRoute(confTo, location, validate) {
    if (validate || (location && location.pathname === '/campaigns/create/creatives')) {
      if (!this.get('application')) {
        return 'application';
      }
      if (!this.validateTrackingEvents()) {
        return 'tracking';
      }
      if (!this.validateTargeting()) {
        return 'targeting';
      }
      if (!handleValidateBidding(this)) {
        return 'bidding';
      }
      if (!this.validateCreatives()) {
        return 'creatives';
      }
    }
    return confTo;
  }

  // only let people on to the confirm page if this if true
  canConfirm() {
    return !!this.get('application')
      && this.validateDetail()
      && this.validateTrackingEvents()
      && this.validateTargeting()
      && this.validateGeo()
      && this.validateCreatives()
      && this.validateSkAdNetworkSettings();
  }

  validateSkAdNetworkSettings() {
    const skadOsEnabled = this.get('targeting.versions.application.is_enabled');
    const skadOsMin = this.get('targeting.versions.application.min');

    if (this.get('is_skadnetwork_enabled') && skadOsEnabled) {
      if (skadOsMin < skadnetworkMinOsVersion && skadOsMin !== '') {
        return false;
      }

      if (this.get('attribution_method') === 'skadnetwork' && this.get('is_ab_testing')) {
        if (!this.isValidSKANABTestingSetA()) {
          return false;
        }
        if (!this.completesSKANABTestingSetB()) {
          return false;
        }
        if (!this.isValidSKANABTestingSetB()) {
          return false;
        }
      }
    }
    return true;
  }

  validateCreatives() {
    return this.raw('creatives').length > 0;
  }

  isTargetOverrideEnabled() {
    const targetOverride = this.raw('budget.objective.target_override');
    return ![undefined, null, 0].includes(targetOverride);
  }

  validateBudgetRoasIap() {
    let errors;

    if (isCampaignObjectiveRoasIap(this)) {
      const cpiRateTypeSettings = getRateTypeSettingsByName(this, 'CPI');
      const targetReturn = Number(this.raw('budget.objective.target_return'));
      const targetOverride = this.isTargetOverrideEnabled()
        ? Number(this.raw('budget.objective.target_override'))
        : undefined;
      const targetSubsidizationMultiplier = this.raw('budget.objective.targetSubsidizationMultiplier');

      const bidMax = this.raw('budget.objective.bid_max');
      const bidDefault = this.raw('budget.objective.bid_default');

      if (Number.isNaN(targetReturn) || !isBetween(ROAS_TARGET_MIN, ROAS_TARGET_MAX, targetReturn)) {
        errors = {
          targetReturn: true,
        };
      }

      if (targetOverride !== undefined
        && (Number.isNaN(targetOverride) || !isBetween(ROAS_TARGET_MIN, ROAS_TARGET_MAX, targetOverride))) {
        errors = {
          ...errors,
          targetOverride: true,
        };
      }

      if (!isTargetSubsidizationMultiplierValid(targetSubsidizationMultiplier)) {
        errors = {
          ...errors,
          targetSubsidizationMultiplier: true,
        };
      }

      if (bidMax) {
        const bidMaxAsNumber = Number(bidMax);

        if (Number.isNaN(bidMaxAsNumber) || isLowerThanMin(cpiRateTypeSettings?.min, bidMaxAsNumber)) {
          errors = {
            ...errors,
            bidMax: true,
          };
        }
      }

      if (bidDefault) {
        const bidDefaultAsNumber = Number(bidDefault);

        if (!isBetween(DYNAMIC_BID_MAX_MIN, DYNAMIC_BID_MAX_MAX, bidDefaultAsNumber)) {
          errors = {
            ...errors,
            bidDefault: true,
          };
        }
      }
    }

    return errors;
  }

  validateBudgetCPPUIap() {
    let errors;

    if (isCampaignObjectiveCPPUIap(this)) {
      const cppuRateTypeSettings = getRateTypeSettingsByName(this, 'CPPU');
      const cpiRateTypeSettings = getRateTypeSettingsByName(this, 'CPI');
      const targetReturn = Number(this.raw('budget.objective.target_return'));
      const targetOverride = this.isTargetOverrideEnabled()
        ? Number(this.raw('budget.objective.target_override'))
        : undefined;
      const targetSubsidizationMultiplier = this.raw('budget.objective.targetSubsidizationMultiplier');
      const bidMax = this.raw('budget.objective.bid_max');
      const cppuTargetMin = cppuRateTypeSettings?.min || CPPU_TARGET_MIN;
      const cppuTargetMax = cppuRateTypeSettings?.max || CPPU_TARGET_MAX;

      if (Number.isNaN(targetReturn) || !isBetween(cppuTargetMin, cppuTargetMax, targetReturn)) {
        errors = {
          targetReturn: true,
        };
      }

      if (targetOverride !== undefined
        && (Number.isNaN(targetOverride) || !isBetween(cppuTargetMin, cppuTargetMax, targetOverride))) {
        errors = {
          ...errors,
          targetOverride: true,
        };
      }

      if (!isTargetSubsidizationMultiplierValid(targetSubsidizationMultiplier)) {
        errors = {
          ...errors,
          targetSubsidizationMultiplier: true,
        };
      }

      if (bidMax) {
        const bidMaxAsNumber = Number(bidMax);

        if (Number.isNaN(bidMaxAsNumber) || isLowerThanMin(cpiRateTypeSettings?.min, bidMaxAsNumber)) {
          errors = {
            ...errors,
            bidMax: true,
          };
        }
      }
    }

    return errors;
  }

  validateBudgetCPEIap() {
    let errors;

    if (isCampaignObjectiveCPEIap(this)) {
      const cpeRateTypeSettings = getRateTypeSettingsByName(this, 'CPE');
      const cpiRateTypeSettings = getRateTypeSettingsByName(this, 'CPI');
      const targetSubsidizationMultiplier = this.raw('budget.objective.targetSubsidizationMultiplier');
      const cpeTargetEventName = this.raw('budget.objective.cpe_target_event_name');
      const targetReturn = Number(this.raw('budget.objective.target_return'));
      const targetOverride = this.isTargetOverrideEnabled()
        ? Number(this.raw('budget.objective.target_override'))
        : undefined;
      const bidMax = this.raw('budget.objective.bid_max');
      const cpeTargetMin = cpeRateTypeSettings?.min || CPE_TARGET_MIN;
      const cpeTargetMax = cpeRateTypeSettings?.max || CPE_TARGET_MAX;

      if (Number.isNaN(targetReturn) || !isBetween(cpeTargetMin, cpeTargetMax, targetReturn)) {
        errors = {
          targetReturn: true,
        };
      }

      if (targetOverride !== undefined
        && (Number.isNaN(targetOverride) || !isBetween(cpeTargetMin, cpeTargetMax, targetOverride))) {
        errors = {
          ...errors,
          targetOverride: true,
        };
      }

      if (!isTargetSubsidizationMultiplierValid(targetSubsidizationMultiplier)) {
        errors = {
          ...errors,
          targetSubsidizationMultiplier: true,
        };
      }

      if (!cpeTargetEventName) {
        errors = {
          ...errors,
          cpeTargetEventName: true,
        };
      }

      if (bidMax) {
        const bidMaxAsNumber = Number(bidMax);

        if (Number.isNaN(bidMaxAsNumber) || isLowerThanMin(cpiRateTypeSettings?.min, bidMaxAsNumber)) {
          errors = {
            ...errors,
            bidMax: true,
          };
        }
      }
    }

    return errors;
  }

  route(string) {
    let extraField;
    if (this.raw('id')) {
      extraField = this.raw('id');
    } else {
      extraField = 'create';
    }
    return `/campaigns/${extraField}/${string}`;
  }

  isDSP() {
    return this.raw('settings.ad_type') === 'offnetwork';
  }

  isMultiBidding() {
    return this.raw('budget.publisher_rates') && this.get('budget.publisher_rates').length !== 0;
  }

  isActive() {
    return this.raw('status') === 'active';
  }

  isSKAdNetworkEnabled() {
    return this.get('is_skadnetwork_enabled');
  }

  addTrackingEvent(event) {
    const events = this.raw('tracking.events');
    events.push(event);
    this.set('tracking.events', events);
  }

  removeTrackingEvent(index) {
    const events = this.raw('tracking.events');
    events.splice(index, 1);
    this.set('tracking.events', events);
  }

  updateTrackingEvent(event) {
    const events = this.raw('tracking.events');
    const updateIndex = events.findIndex((e) => e.name === event.name);
    events.splice(updateIndex, 1);
    events.push(event);
    this.set('tracking.events', events);
  }

  showSkanBidOptions() {
    return (this.get('is_skadnetwork_enabled') && this.get('attribution_method') === 'skadnetwork') || this.get('budget.type') === 'TARGET_CPI';
  }

  isTcpiBidType() {
    return this.get('budget.type') === 'TARGET_CPI';
  }

  showTcpiBanner() {
    return (this.get('is_skadnetwork_enabled') && this.get('attribution_method') === 'skadnetwork') && this.get('budget.type') === 'TARGET_CPI';
  }

  showCpiOption() {
    return this.get('attribution_method') === 'mmp' || (this.get('attribution_method') === 'skadnetwork' && this.get('budget.type') === 'TARGET_CPI');
  }

  updateOptimizationType(type) {
    if (type === BUDGET_OBJECTIVE_DYNAMIC) {
      // set default target goal, getTargetGoals return 'roas' as first element in array if ROAS is enabled for the account
      const targetGoals = getTargetGoals(this);
      const defaultTargetGoal = targetGoals && targetGoals.length > 0 ? targetGoals[0] : '';
      this.set('budget.objective.type', defaultTargetGoal);
      this.set('budget.objective.horizon', BUDGET_TARGET_HORIZON_DYNAMIC_IAP);
      if (this.isSkadnetworkAttribution()) {
        this.set('budget.type', RATE_TYPES.target_cpi);
      } else {
        this.set('budget.type', RATE_TYPES.install);
      }
      this.set('creative_type', FORMAT_TYPE_FULLSCREEN);
      // this used to occur in setDynamicBid, which was only called in the render methods(!) of the Bidding views
      this.default('budget.bid', 0.02);
    }
  }

  toServerObject(active = false, create = false) {
    const campaign = {};
    campaign.name = this.raw('name');
    campaign.status = !active ? 'paused' : 'active';
    campaign.is_skadnetwork_enabled = this.get('is_skadnetwork_enabled');
    campaign.attribution_method = this.get('attribution_method');
    campaign.creative_type = this.get('creative_type') || FORMAT_TYPE_FULLSCREEN;
    campaign.is_ab_testing = this.get('is_ab_testing');
    campaign.ab_test_id = this.get('ab_test_id');
    campaign.schema_version = this.get('schema_version');
    campaign.is_archived = this.raw('is_archived') === undefined ? null : this.raw('is_archived');

    campaign.account = {
      id: this.raw('account.id'),
    };
    campaign.application = {
      id: this.get('application').raw('id'),
    };

    if (this.raw('sfid')) {
      campaign.sfId = this.raw('sfid');
    } else {
      campaign.sfId = 'false';
    }

    campaign.dates = {
      is_indefinite: this.raw('dates.is_indefinite'),
      start: this.raw('dates.start'),
      end: this.raw('dates.end'),
    };

    const budget = {};
    budget.type = this.raw('budget.type');
    budget.daily = this.raw('budget.daily') === '' ? 0 : this.raw('budget.daily');
    budget.total = this.raw('budget.total');
    budget.bid = this.raw('budget.bid');
    budget.daily_spend_limit_type = this.raw('budget.daily_spend_limit_type');
    budget.daily_spend_limit_geos = this.raw('budget.daily_spend_limit_geos')
      .filter((geo) => !['', undefined].includes(geo.rate) || !!geo.geo)
      .map((geo) => (
        { ...geo, rate: Number(geo.rate) }
      ));
    campaign.bidding = this.convertBiddingForServer();
    // when cloning an existing campaign
    if (create && this.get('budget.publisher_rates_uploaded')) {
      this.set('budget.publisher_rates', []);
    }
    budget.bid_override = {
      is_enabled: this.raw('budget.bid_override.is_enabled'),
      value: this.raw('budget.bid_override.value'),
    };
    budget.total_override = {
      is_enabled: this.raw('budget.total_override.is_enabled'),
      value: this.raw('budget.total_override.value'),
    };
    budget.daily_override = {
      is_enabled: this.raw('budget.daily_override.is_enabled'),
      value: this.raw('budget.daily_override.value'),
    };
    if (this.raw('budget.objective.type') !== 'static') {
      budget.objective = {
        type: this.raw('budget.objective.type'),
      };
      budget.objective = this.pluck(budget.objective, 'budget.objective', [
        'bid_default',
        'bid_max',
        'bid_min',
        'horizon',
        'target_return',
        'target_override',
        'targetSubsidizationMultiplier',
        'cpe_target_event_name',
        // 'training_window',
        // 'n_install_threshold',
      ]);
      if (this.isAndroid()) {
        budget.objective.horizon = 7;
      }
      budget.objective.events = this.raw('budget.objective.events')?.filter((e) => !!e).map(({ name, value }) => ({
        name,
        value: value === '' ? null : value,
      }));
    } else {
      budget.objective = {
        type: 'static',
      };
    }
    if (this.get('budget.pacing.method')) {
      budget.pacing = {
        method: this.get('budget.pacing.method'),
      };
    }
    campaign.budget = budget;

    const targeting = {};
    targeting.devices = {
      phone: this.raw('targeting.devices.phone'),
      tablet: this.raw('targeting.devices.tablet'),
    };
    const deviceTypeNone = this.get('targeting.device_models.type') === 'none';
    targeting.device_models = {
      type: this.get('targeting.device_models.type'),
      device_whitelist: deviceTypeNone ? [] : this.get('targeting.device_models.device_whitelist'),
      device_blacklist: deviceTypeNone ? [] : this.get('targeting.device_models.device_blacklist'),
      target_new_phone_models: this.get('targeting.device_models.target_new_phone_models'),
      target_new_tablet_models: this.get('targeting.device_models.target_new_tablet_models'),
    };
    targeting.network = this.raw('targeting.network');
    targeting.versions = {
      application: {
        is_enabled: this.raw('targeting.versions.application.is_enabled'),
        min: toNumberOrNull(this.raw('targeting.versions.application.min')),
        max: toNumberOrNull(this.raw('targeting.versions.application.max')),
      },
    };
    targeting.msrp = {
      enabled: this.raw('targeting.msrp.enabled'),
      min: toNumberOrNull(this.raw('targeting.msrp.min')),
      max: toNumberOrNull(this.raw('targeting.msrp.max')),
      duration: this.raw('targeting.msrp.duration'),
    };

    const targetingGeoRegion = this.raw('targeting.geo.region');
    if (targetingGeoRegion === 'city') {
      targeting.geo = {
        region: 'city',
        cities: this.raw('targeting.geo.cities'),
        subdivisions: [],
        countries: [],
      };
    } else if (targetingGeoRegion === 'subdivision') {
      targeting.geo = {
        region: 'subdivision',
        cities: [],
        subdivisions: this.raw('targeting.geo.subdivisions'),
        countries: [],
      };
    } else {
      targeting.geo = {
        region: 'country',
        cities: [],
        subdivisions: [],
        countries: this.raw('targeting.geo.countries'),
      };
    }

    targeting.publisher = {
      type: this.get('targeting.publisher.type'),
      applications: [],
    };
    if (this.get('targeting.publisher.type') !== 'none') {
      const pubIds = this.get('targeting.publisher.applications.ids') || '';
      const publishers = split(pubIds).filter(isHexId);
      targeting.publisher.applications = publishers.map((p) => ({ id: p }));
    }

    targeting.carrier = {
      carrierAllowList: this.get('targeting.carrier.carrierAllowList'),
      carrierDenyList: this.get('targeting.carrier.carrierDenyList'),
    };

    targeting.genre = {
      mode: this.get('targeting.genre.mode'),
      genreList: this.get('targeting.genre.genreList'),
    };

    campaign.targeting = targeting;

    const tracking = {};
    tracking.url = this.raw('tracking.url');
    tracking.events_url = this.raw('tracking.events_url');
    tracking.events = this.get('tracking.events');
    campaign.tracking = tracking;

    campaign.creatives = this.get('creatives').map((c) => ({ id: c.get('id') }));
    campaign.creatives_b = this.get('creatives_b').map((c) => ({ id: c.get('id') }));

    campaign.is_skadnetwork_enabled = !!this.isSKAdNetworkEnabled();
    campaign.schema_version = this.get('schema_version');
    return campaign;
  }

  convertBiddingForClient(bidding = {}) {
    // when calling the history api
    // the diffs of bidding in bidding_diff field and the bidding field is always {}
    // not need to convert bidding
    if (Object.keys(bidding || {}).length === 0) {
      return;
    }
    const multiBidding = [];
    const {
      // eslint-disable-next-line camelcase
      app_geo_rates = {},
      // eslint-disable-next-line camelcase
      app_rates = {},
      // eslint-disable-next-line camelcase
      geo_rates = {},
      // eslint-disable-next-line camelcase
      app_names = {},
    } = bidding;
    // eslint-disable-next-line camelcase
    Object.keys(app_geo_rates).forEach((pub_app_id) => {
      Object.keys(app_geo_rates[pub_app_id]).forEach((geo) => {
        multiBidding.push({
          pub_app_id, geo, rate: app_geo_rates[pub_app_id][geo], name: app_names[pub_app_id],
        });
      });
    });
    // eslint-disable-next-line camelcase
    Object.keys(app_rates).forEach((pub_app_id) => {
      multiBidding.push({
        pub_app_id, geo: '', rate: app_rates[pub_app_id], name: app_names[pub_app_id],
      });
    });
    Object.keys(geo_rates).forEach((geo) => {
      multiBidding.push({
        pub_app_id: '*', geo, rate: geo_rates[geo], name: '*',
      });
    });
    this.set('budget.publisher_rates', multiBidding);
  }

  convertBiddingForServer() {
    const rates = this.raw('budget.publisher_rates');
    const bidding = { rates };
    // upload bidding
    if (this.get('budget.publisher_rates_uploaded')) {
      bidding.replace = true;
      return bidding;
    }
    // edit/delete/add row
    const biddingInput = this.get('budget.publisher_rates_input');
    if (biddingInput) {
      bidding.replace = false;
      bidding.rates = [];
      Object.keys(biddingInput).forEach((key) => {
        // cases of the key of biddingInput
        // 1. app_geo_rates.[pub_app_id].[geo]: in this case param1=pub_app_id param2=geo
        // 2. app_rates.[pub_app_id]:           in this case param1=pub_app_id param2=undefined
        // 3. geo_rates.[geo]:                  in this case param1=geo param2=undefined
        const [type, param1, param2] = key.split('.');
        const rate = biddingInput[key];
        switch (type) {
          // when app_geo_rates, key = `app_geo_rates.[pub_app_id].[geo]`
          case 'app_geo_rates':
            bidding.rates.push({ pub_app_id: param1, geo: param2, rate });
            break;
          // when app_rates, key = `app_rates.[pub_app_id]`
          case 'app_rates':
            bidding.rates.push({ pub_app_id: param1, rate });
            break;
          // when geo_rates, key = `geo_rates.[geo]`
          case 'geo_rates':
            bidding.rates.push({ geo: param1, rate });
            break;
          default:
            break;
        }
      });
      return bidding;
    }
    return {
      rates: [],
      replace: false,
    };
  }

  isEligibleToServe() {
    const e = (s) => this.raw(`serving_status.${s}`);
    if (this.raw('status') === 'active') {
      if (e('approved') && e('creatives') && e('has_account_budget') && e('has_budget') && e('io_signed')) {
        if (e('not_expired') && e('remainingViewLimit') && e('remainingSpendLimit')) {
          return true;
        }
      }
    }
    return false;
  }

  isApproved() {
    return this.raw('admin_status') === 'approved';
  }

  isActiveCamp() {
    let campStatus;
    if (this.raw('status') === 'active' && this.raw('is_archived') === false) {
      campStatus = 'active';
    } else if (this.raw('status') === 'paused' && this.raw('is_archived') === true) {
      campStatus = 'paused';
    } else if (this.raw('status') === null && this.raw('is_archived') === true) {
      campStatus = 'paused';
    } else if (this.raw('status') === null && this.raw('is_archived') === false) {
      campStatus = 'active';
    } else if (this.raw('status') === '' && this.raw('is_archived') === true) {
      campStatus = 'paused';
    } else if (this.raw('status') === '' && this.raw('is_archived') === false) {
      campStatus = 'active';
    } else if (this.raw('status') === 'active' && this.raw('is_archived') === true) {
      campStatus = 'paused';
    } else if (this.raw('status') === 'paused' && this.raw('is_archived') === false) {
      campStatus = 'active';
    }
    return this.set('status', campStatus);
  }

  hasPendingChanges() {
    return this.raw('has_pending_changes') || this.get('admin_status') === 'pending';
  }

  isValidForEditing() {
    return !this.isDSP();
  }

  // eslint-disable-next-line class-methods-use-this
  getRateType(value) {
    const index = rateTypes.findIndex((rateType) => rateType.id === value);
    if (isObject(rateTypes[index])) {
      return rateTypes[index].name;
    }
    return rateTypes[0].name;
  }

  // eslint-disable-next-line class-methods-use-this
  getRate(value) {
    return format(value, true);
  }

  getTrackingEventNames() {
    const getDescription = (event) => {
      if (event.description) {
        return event.description;
      }
      const madeEvent = supportedEvents.filter((e) => e.name === event.name).pop();
      if (isObject(madeEvent)) {
        return madeEvent.description;
      }
      return '';
    };
    const events = this.get('tracking.events');
    return events.map((e) => getDescription(e)).join(', ');
  }

  getTrackingEvents() {
    const supportedEventNames = supportedEvents.map((e) => (e.name));
    const events = this.get('tracking.events').filter((e) => supportedEventNames.indexOf(e.name) > -1);
    return events.map(({ name, url }) => ({
      name,
      url: url || this.getTrackingUrl('impression'),
      description: supportedEvents.find((e) => e.name === name).description,
    }));
  }

  getAdditionalEvents() {
    const addtionalEventNames = additionalEvents.map((e) => e.name);
    const events = this.get('tracking.events').filter((e) => !!e.url && addtionalEventNames.includes(e.name));
    return events.map(({ name, url }) => ({
      name,
      url: url || this.getTrackingUrl('impression'),
      description: additionalEvents.find((e) => e.name === name).description,
    }));
  }

  toggleEvent(description, name, active) {
    let events = [];
    if (isArray(this.get('tracking.events'))) {
      events = this.get('tracking.events');
    }
    if (!active) {
      this.set('tracking.events', events.filter((e) => e.name !== name));
      return;
    }
    events.push({ description, name, url: this.getTrackingUrl('impression') });
    this.set('tracking.events', events);
  }

  getClickURL() {
    return this.get('tracking.url');
  }

  getEventURL(findName) {
    const events = this.get('tracking.events');
    const event = events.filter(({ name }) => name === findName);
    if (event.length > 0) {
      return this.getTrackingUrl('impression') || event[0].url;
    }
    return '';
  }

  hasActiveEvent(value) {
    const events = this.get('tracking.events');
    return isArray(events) && events.filter((e) => e.name === value).length === 1;
  }

  hasActiveCreative(creative, isSetB = false) {
    const field = isSetB ? 'creatives_b' : 'creatives';
    return this.get(field)?.filter((c) => c.get('id') === creative.get('id')).length === 1;
  }

  toggleCreative(creative, active, callOnChangeFn = true, isSetB = false) {
    const field = isSetB ? 'creatives_b' : 'creatives';
    const creatives = this.get(field) || [];
    if (!active) {
      this.set(field, creatives.filter((c) => c.get('id') !== creative.get('id')), callOnChangeFn);
      return;
    }
    if (!this.hasActiveCreative(creative, isSetB)) {
      creatives.push(creative);
    }
    this.set(field, creatives, callOnChangeFn);
  }

  getRealAttachedCreatives(creatives, isSetB = false) {
    return creatives.filter((creative) => this.hasActiveCreative(creative, isSetB));
  }

  hasCountry(country) {
    const campaignCountries = this.raw('targeting.geo.countries');
    return campaignCountries.findIndex((c) => c.id === country.id) !== -1;
  }

  toggleAllCountries(include, searchValue = '', possibleCountries) {
    if (!include) {
      this.set('targeting.geo.countries', []);
      return;
    }
    let incCountries;
    if (searchValue.length > 0) {
      incCountries = possibleCountries.filter((c) => contains(c.name, searchValue));
    } else {
      incCountries = possibleCountries;
    }
    setGeoBudget(this);
    this.set('targeting.geo.countries', incCountries);
    this.geoDailySpend();
  }

  toggleCountry(include, country) {
    let campaignCountries = this.raw('targeting.geo.countries');
    if (include) {
      campaignCountries.push(country);
    } else {
      campaignCountries = campaignCountries.filter((c) => c.id !== country.id);
    }
    campaignCountries.sort((a, b) => (a.name < b.name ? -1 : 1));
    setGeoBudget(this);
    this.geoDailySpend();
    this.set('targeting.geo.countries', campaignCountries);
  }

  // eslint-disable-next-line class-methods-use-this
  makeCityValue(city, spacer = '') {
    return `${city.name},${spacer}${city.region},${spacer}${city.country}`;
  }

  hasCity(city) {
    const campaignCities = this.raw('targeting.geo.cities');
    return campaignCities.some((c) => this.makeCityValue(c) === this.makeCityValue(city));
  }

  toggleAllCities(include, searchValue = '') {
    if (!include) {
      this.set('targeting.geo.cities', []);
      return;
    }
    const incCities = searchValue.length > 0
      ? cities.filter((c) => contains(this.makeCityValue(c), searchValue))
      : cities;
    this.set('targeting.geo.cities', incCities);
  }

  toggleCity(include, city) {
    const campaignCities = this.raw('targeting.geo.cities');
    const newCities = include
      ? [...campaignCities, city]
      : campaignCities.filter((c) => this.makeCityValue(c) !== this.makeCityValue(city));
    newCities.sort((a, b) => (a.name < b.name ? -1 : 1));
    this.set('targeting.geo.cities', newCities);
  }

  hasSubdivision(subdivision) {
    const campaignSubdivisions = this.raw('targeting.geo.subdivisions');
    return campaignSubdivisions.some((c) => c.code === subdivision.code);
  }

  toggleAllSubdivisions(include, searchValue = '') {
    if (!include) {
      this.set('targeting.geo.subdivisions', []);
      return;
    }
    const incSubdivisions = searchValue.length > 0
      ? subdivisions.filter((c) => contains(c.name, searchValue))
      : subdivisions;
    this.set('targeting.geo.subdivisions', incSubdivisions);
  }

  toggleSubdivision(include, subdivision) {
    const campaignSubdivisions = this.raw('targeting.geo.subdivisions');
    const newSubdivisions = include
      ? campaignSubdivisions.concat(subdivision)
      : campaignSubdivisions.filter((c) => c.code !== subdivision.code);
    this.set('targeting.geo.subdivisions', newSubdivisions);
  }

  getBudgetType() {
    const objectiveType = this.get('budget.objective.type');
    if (objectiveType === BUDGET_OBJECTIVE_STATIC) {
      const budgetType = this.get('budget.type');
      return budgetType === 'TARGET_CPI' ? 'Target CPI' : budgetType?.toUpperCase();
    }
    return objectiveType?.toUpperCase();
  }

  setBudgetType(v) {
    this.set([
      'budget.type',
      'budget.bid_override.is_enabled',
      'budget.daily_override.is_enabled',
      'budget.total_override.is_enabled',
    ], [v, false, false, false]);
  }

  hasBudgetOverride(type) {
    return this.get(`budget.${type}_override.is_enabled`);
  }

  disableBudgetOverride(type) {
    this.set(`budget.${type}_override.is_enabled`, false);
  }

  getActualBudget(type) {
    return this.get(`budget.${type}_override.is_enabled`) ? this.get(`budget.${type}_override.value`) : this.get(`budget.${type}`);
  }

  toggleAllCreatives(creatives, searchTerm = '') {
    const selectAllCreatives = !this.get('selectAllCreatives');
    this.set('selectAllCreatives', selectAllCreatives);
    let includedCreatives = [];
    if (selectAllCreatives) {
      if (searchTerm.length > 0) {
        includedCreatives = creatives.filter((c) => contains(c.get('name'), searchTerm));
      } else {
        includedCreatives = creatives;
      }
      this.set('creatives', includedCreatives);
      return;
    }
    this.set('creatives', includedCreatives);
  }

  saveMultiBidding(multiBiddingEditValues) {
    if (!isEmptyObject(multiBiddingEditValues)) {
      const newPublisherRates = this.get('budget.publisher_rates').map((r) => ({ ...r, rate: multiBiddingEditValues[r.pub_app_id + r.geo].rate }));
      this.set('budget.publisher_rates', newPublisherRates);
    }
  }

  saveInlineEditBudget(modifiedBudget) {
    const bid = this.raw('budget.bid');
    const daily = this.raw('budget.daily');
    const total = this.raw('budget.total');
    const type = this.raw('budget.type');
    const budget = {
      bid,
      daily,
      total,
      type,
      ...modifiedBudget,
    };
    return put(`${config.get('manageUrl')}campaigns/${this.raw('id')}/budget`, { budget });
  }

  async refreshApplication(application) {
    if (application) {
      const result = await Application.get(application.get('id'));
      this.set('application', new Application(result.response));
    }
  }

  async getSkanCampaignCount() {
    const activeSkanCampaigns = await Campaign.getAll({
      application: this.get('application').get('id'),
      isSkadnetworkEnabled: true,
      campaignStatus: 'active',
      isDeleted: false,
    });
    return activeSkanCampaigns.pagination.total;
  }

  isApplicationSKAdNetworkEnabled() {
    if (!this.isIOS()) {
      return false;
    }

    return this.get('application')?.isSKAdNetworkEnabled() || false;
  }

  async setApplication(application, callOnChangeFn) {
    this.set('application', application);

    const isChangeable = this.isSKAdNetworkCapabilityChangeable();
    // ensuring the application has mmpIntegrationStatus
    // this used to be the first line of getSkanCampaignCount() for some reason
    await this.refreshApplication(this.get('application'));

    if (isChangeable) {
      const activeSkanCampaigns = await this.getSkanCampaignCount();
      this.set('activeSkanCampaignCount', activeSkanCampaigns);
    }

    this.set('skadnetwork_changeable', isChangeable, callOnChangeFn);
  }

  getMinOSVersion() {
    return this.get('targeting.versions.application.min');
  }

  isValidDailySpend() {
    const bidThreshold = bidsBudgetsAlertThresholds[this.get('budget.type')];
    const daily = this.raw('budget.daily');
    if (this.raw('budget.daily_spend_limit_type') === 'geo') {
      return true;
    }
    // min === 0 means user is allowed to set daily as 0
    const { min = 0 } = dailyGeoOption(this);
    if (
      this.raw('budget.bid') >= bidThreshold
      && daily <= bidsBudgetsAlertThresholds.daily
      && daily !== ''
      && (min === 0 && parseFloat(daily) !== 0)
    ) {
      return false;
    }
    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  triggerDeviceError(message) {
    triggerAlertModal({
      title: 'Upload Error',
      alertText: message,
    });
  }

  addDeviceModels(csvRecords, type) {
    const [headerRow] = csvRecords;

    if (!headerRow) {
      this.triggerDeviceError('CSV is missing a titles row');
      return;
    }

    const {
      model: lowercaseColStyle,
      Model: uppercaseColStyle,
      models: pluralizedColStyle,
    } = headerRow;

    if (!lowercaseColStyle && !uppercaseColStyle && !pluralizedColStyle) {
      this.triggerDeviceError('CSV is missing the model column');
      return;
    }

    const deviceModels = attempt(
      () => csvRecords
        .map(
          (record, index) => {
            const {
              model: lowercase,
              Model: uppercase,
              models: pluralized,
            } = record;
            // catches EOF (which could have only one column)
            const isMeaningfulRecord = Object.keys(record).length > 1;

            if (!lowercase && !uppercase && !pluralized && isMeaningfulRecord) {
              const row = index + 2; // offset accounts for header row
              throw new Error(`Row ${row} is missing model information.`);
            }

            return {
              model: lowercase || uppercase || pluralized,
            };
          },
        )
        .filter(
          ({ model }) => model,
        ),
    );

    if (isError(deviceModels)) {
      const { message } = deviceModels;

      this.triggerDeviceError(message);

      return;
    }

    if (type === 'allow') {
      this.set('targeting.device_models.device_whitelist', deviceModels);
      this.set('targeting.device_models.device_blacklist', []);
    } else if (type === 'deny') {
      this.set('targeting.device_models.device_blacklist', deviceModels);
      this.set('targeting.device_models.device_whitelist', []);
    }
  }

  isPrePay() {
    return this.get('account.budget_type') === 'prepay';
  }

  isMmpBilled() {
    return this.get('attribution_method') === 'mmp';
  }

  isValidLongTailBid() {
    if (this.isPrePay() && this.get('budget.type') === 'CPI' && this.get('targeting.geo.region') === 'country') {
      const longTailGeosInCampaign = this.getLongTailGeos();
      if (longTailGeosInCampaign.length > 0) {
        const bid = this.raw('budget.bid');
        const castedValue = parseFloat(bid);
        return isValidLongTailBid(castedValue);
      }
    }
    return true;
  }

  getLongTailGeos() {
    const geos = this.get('targeting.geo.countries') || [];
    const longTailGeos = config.get('countryData.longTailGeos');
    return geos.filter((geo) => longTailGeos.some((longTailGeo) => longTailGeo === geo.code));
  }
}

Campaign.getById = (id, original) => getCampaign(id, original);
Campaign.getAll = (filters) => getCampaigns({ page: 1, perPage: 10, ...filters });
Campaign.getCampaignsForCreativeAttachment = (filters) => getCampaignsForCreativeAttachment({ page: 1, perPage: 10, ...filters });
Campaign.getReviewHistories = (id, params) => getCampaignReviewHistories(id, params);
Campaign.make = Model.make(Campaign);
Campaign.save = (campaign, active) => modifyCampaign(campaign.get('id'), campaign.toServerObject(active))
  .then((result) => {
    if (result.ok) {
      // reset publisher_rates temp attributes after saved
      campaign.set('budget.publisher_rates_uploaded', false);
      campaign.set('budget.publisher_rates_input', null);
      if (campaign.get('targeting.genre.mode') === 'none') {
        campaign.set('targeting.genre.genreList', []);
      }
    }
    return result;
  });
Campaign.updateCampaign = (campaign, fields) => updateCampaignAfterAbTesting(campaign.get('id'), fields);
Campaign.updateCreativesForCampaign = (campaign) => updateCreativesForCampaign(campaign);
Campaign.create = (campaign, active = true) => createCampaign(campaign.toServerObject(active));
Campaign.new = (campaign) => createCampaign(campaign.toServerObject());

Campaign.historySettings = {
  config: historyConfig,
  getFn: Campaign.getById,
  historyFn: (id, page) => getCampaignHistories(id, page, 100),
  biddingDiffFn: (id, biddingIdBefore, biddingIdAfter) => getLargeBiddingDiff(id, biddingIdAfter, biddingIdBefore),
};

export default Campaign;
