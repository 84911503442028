import React, {
  useState, useCallback, useEffect, useRef,
} from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FILTER_KEY_COUNTRY } from 'app/constants/filters';
import { DropdownMenuItem } from '../../../Dropdown/DropdownMenu/DropdownMenu';
import Dropdown from '../../../Dropdown/Dropdown';
import { getFilterLabel, getCheckedNodes } from './helper';
import Filter from './Filter/Filter';
import './compareFilter.scss';

const classPrefix = 'v2_component_compare_filter';
const smallHeaderFilterClass = 'multi-tree-country-small-header';

const CompareFilter = ({
  filters,
  checkedCombine,
  defaultCheckedFilter,
  searchParams,
  onChange,
  onRemove,
}) => {
  const isFirstLoadedRef = useRef(false);
  const [checkedFilter, setCheckedFilter] = useState(() => {
    if (defaultCheckedFilter && defaultCheckedFilter.key) {
      const defaultFilter = filters.find((i) => i.key === defaultCheckedFilter.key);
      return {
        ...defaultFilter,
        checkedAll: false,
        visible: true,
        data: defaultCheckedFilter.data,
        availableNodes: checkedCombine[defaultFilter.key] || [],
      };
    }
    return null;
  });
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (checkedFilter) {
      const data = getCheckedNodes(checkedFilter, checkedCombine);
      const newCheckedFilter = {
        ...checkedFilter,
        availableNodes: checkedCombine[checkedFilter.key] || [],
        data,
      };
      setCheckedFilter(newCheckedFilter);
      onChange(checkedFilter.key, data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedCombine]);

  useEffect(() => {
    if (isFirstLoadedRef.current) {
      const data = getCheckedNodes(checkedFilter, checkedCombine);
      onChange(checkedFilter.key, data);
      isFirstLoadedRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = useCallback((key, _data, checkedAll) => {
    const data = [];
    _data.forEach((item) => {
      if (item.children?.length) {
        data.push(...item.children);
      } else {
        data.push(item);
      }
    });
    setCheckedFilter({ ...checkedFilter, checkedAll, data });
    setVisible(false);
    onChange(key, data);
  }, [checkedFilter, onChange]);

  const handleClick = useCallback((item) => {
    setCheckedFilter({
      ...item,
      visible: true,
      availableNodes: checkedCombine[item.key] || [],
    });
  }, [checkedCombine]);

  const handleClear = useCallback(() => {
    setCheckedFilter(null);
    onRemove(checkedFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onRemove]);

  const renderOverlay = useCallback(() => {
    if (!checkedFilter) {
      return filters.map((item) => (
        <DropdownMenuItem
          key={item.key}
          label={item.name}
          onClick={() => handleClick(item)}
        />
      ));
    }

    const {
      key,
      data,
      availableNodes,
      visible: toggled,
      Component,
    } = checkedFilter;

    return React.createElement(Component, {
      filterKey: key,
      visible: toggled,
      maxSelected: 5,
      availableNodes,
      defaultCheckedNodes: data,
      searchParams,
      className: FILTER_KEY_COUNTRY ? smallHeaderFilterClass : undefined,
      onVisibleChange: setVisible,
      onChange: (d, checkedAll) => handleChange(key, d, checkedAll),
    });
  }, [filters, checkedFilter, handleChange, handleClick, searchParams]);

  return (
    <Dropdown
      visible={visible}
      className={classnames(
        classPrefix, {
          [`${classPrefix}-overlayOpen`]: !!checkedFilter,
        },
      )}
      renderOverlay={renderOverlay}
      onVisibleChange={setVisible}
    >
      <Filter
        open={visible}
        clearable={!!checkedFilter}
        onClear={handleClear}
      >
        {getFilterLabel(checkedFilter)}
      </Filter>
    </Dropdown>
  );
};

CompareFilter.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.any).isRequired,
  defaultCheckedFilter: PropTypes.objectOf(PropTypes.any),
  checkedCombine: PropTypes.objectOf(PropTypes.any),
  searchParams: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};

CompareFilter.defaultProps = {
  defaultCheckedFilter: null,
  checkedCombine: {},
  searchParams: {},
  onChange: noop,
  onRemove: noop,
};

export default CompareFilter;
