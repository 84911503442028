import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import './appStoreFrame.scss';

class AppStoreFrame extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="AppStoreFrame">
        {
          this.props.showClose && (<Button common onClick={this.props.onClose}>Done</Button>)
        }

        <div className="AppStoreFrame__Head">
          <div className="AppStoreFrame__Icon" />
          <div className="AppStoreFrame__Info">
            {[...Array(4).keys()].map((indx) => <div key={indx} />)}
          </div>
        </div>

        <div className="AppStoreFrame__Actions">
          { [...Array(2).keys()].map((indx) => <div key={indx} />) }
        </div>

        <div className="AppStoreFrame__Screenshots">
          { [...Array(5).keys()].map((indx) => <div key={indx} />) }
        </div>

        <div className="AppStoreFrame__Comments">
          { [...Array(6).keys()].map((indx) => <div key={indx} />) }
        </div>
      </div>
    );
  }
}

AppStoreFrame.propTypes = {
  onClose: PropTypes.func,
  showClose: PropTypes.bool,
};

AppStoreFrame.defaultProps = {
  onClose: Function.prototype,
  showClose: true,
};

export default AppStoreFrame;
