import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import ValidatedText from 'components/Form/ValidatedText/ValidatedText';
import { DYNAMIC_TARGET_MAX, DYNAMIC_TARGET_MIN } from 'app/constants/campaign';
import Campaign from 'models/Campaign';
import Group from 'templates/input-group/InputGroupContainer';
import Toggler from 'components/Form/Toggler/Toggler';
import InfoBar from 'components/InfoBar/InfoBar';
import { Label } from 'components/V2';
import './TargetReturn.scss';
import Tooltip from '../V2/Tooltip/Tooltip';
import { toUiFormat, toApiFormat } from '../../lib/helpers/percentMultiplier/percentMultiplier';

const { Field } = Group;
export const targetOverrideKey = 'budget.objective.target_override';
export const targetReturnKey = 'budget.objective.target_return';

export default function TargetReturn({
  campaign,
  isAdmin,
  name,
  description,
  type,
  min,
  max,
  metric,
  targetOverrideLabel,
  targetOverrideTooltipMessage,
}) {
  const [isTargetOverrideEnabled, setIsTargetOverrideEnabled] = useState(campaign?.isTargetOverrideEnabled());
  const targetOverrideRaw = campaign?.raw(targetOverrideKey);
  const targetOverride = type === 'PercentMultiplier' ? toUiFormat(targetOverrideRaw) : targetOverrideRaw;
  const targetReturnRaw = campaign?.raw(targetReturnKey);
  const targetReturn = type === 'PercentMultiplier' ? toUiFormat(targetReturnRaw) : targetReturnRaw;

  const handleChangeInput = useCallback((v) => campaign?.set(targetReturnKey, type === 'PercentMultiplier' ? toApiFormat(v) : v), [campaign, type]);
  const handleChangeOverrideStatus = useCallback((enabled) => {
    setIsTargetOverrideEnabled(enabled);
    campaign?.set(targetOverrideKey, enabled ? 0.01 : 0);
  }, [campaign]);
  const handleChangeOverride = useCallback((v) => campaign?.set(targetOverrideKey, type === 'PercentMultiplier' ? toApiFormat(v) : v), [campaign, type]);

  return (
    <Field
      name={name}
      description={description}
    >
      <ValidatedText
        type={type}
        noIcon
        value={targetReturn}
        onChange={handleChangeInput}
        numeric
        numericValue={targetReturn}
        limits={{
          min,
          max,
        }}
        rangeErrors={{
          lowerBoundMsg: `${name} must be >= ${min}`,
          upperBoundMsg: `${name} must be <= ${max}`,
        }}
        staticPlaceholder={metric}
        defaultMsg={`Enter minimum value of ${metric}${min} and maximum value of ${metric}${max}.`}
      />

      {isAdmin
        && (
          <div className="targetOverride">
            <div className="targetOverride__label">
              <Toggler
                checked={isTargetOverrideEnabled}
                onChange={handleChangeOverrideStatus}
                enabledLabel={`${targetOverrideLabel} Override`}
                disabledLabel={`${targetOverrideLabel} Override`}
              />

              <Tooltip body={targetOverrideTooltipMessage}>
                <i className="material-icons">help_outline</i>
              </Tooltip>

              <div className="pill">
                <Label type="option">Admin Only Feature</Label>
              </div>
            </div>

            {isTargetOverrideEnabled && (
              <>
                <div className="targetOverride__input">
                  <ValidatedText
                    type={type}
                    noIcon
                    value={targetOverride}
                    onChange={handleChangeOverride}
                    numeric
                    numericValue={targetOverride}
                    limits={{
                      min,
                      max,
                    }}
                    rangeErrors={{
                      lowerBoundMsg: `${name} Override must be >= ${min}`,
                      upperBoundMsg: `${name} Override must be <= ${max}`,
                    }}
                    staticPlaceholder={metric}
                    defaultMsg={`Enter minimum value of ${metric}${min} and maximum value of ${metric}${max}.`}
                    placeholder={`${name} Override`}
                  />
                </div>
                <InfoBar
                  type="warning"
                  text={(
                    <div className="dynamic-int-error--heading">
                      Effective
                      {' '}
                      {targetOverrideLabel}
                      {' '}
                      is now:
                      {' '}
                      {metric}
                      {targetOverride}
                    </div>
                  )}
                />
              </>
            )}
          </div>
        )}
    </Field>
  );
}

const allowedValidatedTextTypes = ['Currency', 'PercentMultiplier', 'number'];

TargetReturn.propTypes = {
  campaign: PropTypes.instanceOf(Campaign).isRequired,
  isAdmin: PropTypes.bool,
  name: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.oneOf(allowedValidatedTextTypes),
  max: PropTypes.number,
  min: PropTypes.number,
  metric: PropTypes.string,
  targetOverrideLabel: PropTypes.string,
  targetOverrideTooltipMessage: PropTypes.string,
};

TargetReturn.defaultProps = {
  isAdmin: false,
  name: 'Target Return',
  description: PropTypes.string,
  type: 'number',
  max: DYNAMIC_TARGET_MAX,
  min: DYNAMIC_TARGET_MIN,
  metric: '',
  targetOverrideLabel: 'Target',
  targetOverrideTooltipMessage: 'Override Target when actual campaign performance deviates from intended goals. This hidden metric can be adjusted to fine-tune outcomes and align with customer expectations.',
};
