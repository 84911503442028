import React, { useRef, useCallback } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { isAfter, add } from '../../../lib/date';
import Campaign from '../../../models/Campaign';
import AdminCampaign from '../../../models/AdminCampaign';
import DatePicker, { ISO_8601_FORMAT } from '../../Form/DatePicker/DatePicker';
import './startEndDate.scss';

const validDateParams = (start, end) => (end !== undefined ? isAfter(start, end) : undefined);

const validateIndefinite = (isIndefinite, campaign) => isIndefinite || validDateParams(campaign.raw('dates.start'), campaign.raw('dates.end'));

const firstDateOfEnd = (date) => add(date, '1 day');

const StartEndDate = ({ campaign, verifiable }) => {
  const today = new Date();
  const endPickerRef = useRef(null);

  const onEndClick = useCallback(() => {
    endPickerRef?.current.focus();
    const end = campaign.raw('dates.start');
    campaign.set('dates.end', end);
  }, [campaign]);

  const onEndChange = useCallback((v) => {
    if (campaign.get('dates.is_indefinite')) {
      campaign.set('dates.is_indefinite', false);
    }
    campaign.set('dates.end', v);
  }, [campaign]);

  const onIndefClick = useCallback(() => {
    campaign.set('dates.is_indefinite', true);
    campaign.set('dates.end', campaign.raw('dates.start'));
  }, [campaign]);

  const validEndDateParams = (start, end) => {
    if (start && end && !isAfter(firstDateOfEnd(start), end)) {
      onEndChange(firstDateOfEnd(start));
    }
  };
  const onRunImmediately = (v) => {
    const date = moment(v).tz('UTC').format(ISO_8601_FORMAT);
    campaign.set('dates.start', date);
    validEndDateParams(date, campaign.raw('dates.end'));
  };

  return (
    <div className="v2_component_start_end_date">
      <div className="picker-wrapper">
        <div className="picker-container">
          <div className="p8 title">Start Date</div>
          <DatePicker
            after={today}
            format="DD MMM YYYY"
            placeholder="Select start date/hour"
            value={campaign.raw('dates.start')}
            err={verifiable ? validateIndefinite(campaign.get('dates.is_indefinite'), campaign) : undefined}
            onChange={(v) => campaign.set('dates.start', v)}
          />
          <div className="p9 widget" onClick={() => onRunImmediately(today)}>
            Run Immediately
          </div>
        </div>
        <div className="picker-container">
          <div className="p8 title">End Date</div>
          {campaign.raw('dates.is_indefinite') && (
            <div className="string_date_picker_wrapper" onClick={onEndClick}>
              <div className="placeholder"><i className="material-icons">date_range</i></div>
              <span className="p2">RUN INDEFINITELY</span>
            </div>
          )}
          <DatePicker
            inputRef={endPickerRef}
            format="DD MMM YYYY"
            after={campaign.raw('dates.start')}
            placeholder="Select end date/hour"
            value={campaign.raw('dates.end')}
            err={verifiable ? validDateParams(campaign.raw('dates.start'), campaign.raw('dates.end')) : undefined}
            onChange={onEndChange}
          />
          <div className="p9 widget" onClick={onIndefClick}>
            Run Indefinitely
          </div>
        </div>
      </div>
      { verifiable
        && !campaign.get('dates.is_indefinite')
        && !validDateParams(campaign.raw('dates.start'), campaign.raw('dates.end'))
        && validDateParams(campaign.raw('dates.start'), campaign.raw('dates.end')) !== undefined && (
          <p className="dateErrMsg">Must be valid date & times, end date must be at least 24 hours after the start date.</p>
      )}
    </div>
  );
};

StartEndDate.propTypes = {
  // Campaign/AdminCampaign model
  campaign: PropTypes.oneOfType([
    PropTypes.instanceOf(Campaign),
    PropTypes.instanceOf(AdminCampaign),
  ]).isRequired,
  // Whether show error messages if validation not passed
  verifiable: PropTypes.bool,
};

StartEndDate.defaultProps = {
  verifiable: false,
};

export default StartEndDate;
