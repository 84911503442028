import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import InfoBar from '../../../../../components/InfoBar/InfoBar';
import CollapseSection from '../../../../../components/V2/Collapse/CollapseSection';
import CollapseURLValidator from '../CollapseURLValidator/CollapseURLValidator';
import { validateMacroURL } from '../helper';
import { MEASUREMENT_URL_TYPES } from '../constants';
import './measurementURLs.scss';

const classPrefix = 'views_pages_measurement_urls';

const URLValidatorContainer = ({
  type, title, value, description, link, platform, isAdmin, validatable, onChange, placeholder,
}) => {
  const [url, setURL] = useState(value);
  const [error, setError] = useState();
  useEffect(() => {
    setError(validateMacroURL(url, platform));
    setURL(value);
    // --> potential bug inside this useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, platform]);
  const handleChange = useCallback((newValue) => {
    onChange(type, newValue);
    setURL(newValue);
    setError(validateMacroURL(newValue, platform));
    // --> potential bug inside this useCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, platform]);
  return (
    <div className={classnames('url_validator_container', { 'url_validator_container-isAdmin': isAdmin })}>
      <div className="url_intro">
        <p className="p1">{title}</p>
        <p className={classnames('p6', 'description')}>{description}</p>
        {link && (
          <a
            className="p8"
            href="https://support.vungle.com/hc/en-us/sections/115001351468-Attribution"
            rel="noopener noreferrer"
            target="_blank"
          >
            {link}
          </a>
        )}
      </div>
      <div className="validator">
        {validatable ? (
          <CollapseURLValidator value={url} error={error} validatable={validatable} onChange={handleChange} placeholder={placeholder} />
        ) : (
          isAdmin && (
            <textarea
              className="p2"
              value={url}
              autoCapitalize="off"
              autoComplete="off"
              autoCorrect="off"
              spellCheck="off"
              placeholder="Enter destination URL"
              onChange={(e) => {
                onChange(type, e.target.value);
                setURL(e.target.value);
              }}
            />
          )
        )}
      </div>
    </div>
  );
};

const makeURLValidators = ({
  destinationURL, clickURL, impressionURL, isAdmin,
}) => {
  const validators = [
    {
      type: MEASUREMENT_URL_TYPES.click,
      title: 'Click URL',
      value: clickURL,
      description: 'The URL used for tracking campaign clicks. This is required for install attribution based on clicks.',
      link: 'More help here',
      validatable: true,
      placeholder: 'Enter click URL',
    },
    {
      type: MEASUREMENT_URL_TYPES.impression,
      title: 'Impression URL',
      value: impressionURL,
      // eslint-disable-next-line max-len
      description: 'The URL used for tracking campaign impressions. This is required for install attribution based on impressions. Impression is fired at the beginning of ad experience.',
      validatable: true,
      placeholder: 'Enter impression URL',
    },
  ];
  if (isAdmin) {
    validators.splice(0, 0, {
      type: MEASUREMENT_URL_TYPES.destination,
      title: 'Destination URL',
      value: destinationURL,
      description: 'Destination URL is here, feel free to edit',
      validatable: false,
    });
  }
  return validators;
};

const MeasurementURLs = ({
  destinationURL, clickURL, impressionURL, platform, isAdmin, onChange,
}) => (
  <CollapseSection
    className={classPrefix}
    title="MMP Measurement URLs"
    id="measurementURLs"
    description="Add tracking URLs from your MMP dashboard here."
    tip={(
      <a
        className="p8"
        href="https://support.vungle.com/hc/en-us/sections/115001351468-Attribution"
        rel="noopener noreferrer"
        target="_blank"
      >
        Learn all about attribution
      </a>
    )}
  >
    <InfoBar
      type="info"
      text={(
        <span>
          We support Adjust, AppsFlyer, Kochava, Singular, Tenjin, Branch/Tune. For additional options &nbsp;
          <a
            className="p3"
            href="https://support.vungle.com/hc/en-us/requests/new"
            rel="noopener noreferrer"
            target="_blank"
          >
            please contact us.
          </a>
        </span>
      )}
    />
    {makeURLValidators({
      destinationURL, clickURL, impressionURL, isAdmin,
    }).map(({
      type, title, value, description, link, validatable, placeholder,
    }) => (
      <URLValidatorContainer
        key={title}
        type={type}
        title={title}
        value={value}
        description={description}
        link={link}
        platform={platform}
        isAdmin={isAdmin}
        validatable={validatable}
        onChange={onChange}
        placeholder={placeholder}
      />
    ))}
  </CollapseSection>
);

export default MeasurementURLs;
