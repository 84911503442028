import React from 'react';
import { FILTER_KEY_SKOVERLAY_AUTO_SHOW } from 'app/constants/filters';
import CommonMultiPicker from '../CommonMultiPicker/CommonMultiPicker';
import { SKOverlayAutoShow } from '../constant';

const MultiSKOverlay = (props) => (
  <CommonMultiPicker data={SKOverlayAutoShow} filterKey={FILTER_KEY_SKOVERLAY_AUTO_SHOW} {...props} />
);

export default MultiSKOverlay;
