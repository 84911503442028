import config from 'lib/config';
import { getAuthUser, removeAuthUser } from './session';

export const ssoLogout = () => {
  window.location.href = `${config.get('ssoUrl')}logout?original=${window.location.origin}`;
};

export const unauthorizedLogout = () => {
  if (getAuthUser()) {
    removeAuthUser();
    window.location.href = `/login?to=${encodeURIComponent(`${window.location.pathname}${window.location.search}`)}`;
  }
};
