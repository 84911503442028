import React from 'react';
import NewImageIconSvg from '../../assets/img/asset_type_image.svg';
import NewVideoIconSvg from '../../assets/img/asset_type_video.svg';
import NewGameIconSvg from '../../assets/img/asset_type_game.svg';
import {
  AmazonIcon, AndroidIcon, AppleIcon, WindowsIcon, forPlatform,
} from './platforms';
import './icon.scss';

const Icon = {};

const MaterialIcon = ({
  name = '', hover = '', hoverPosition = 'bl', hoverStyle = {}, onClick,
}) => (
  <span className={`components__icon components__icon--${name}`} onClick={onClick}>
    <i className={`material-icons component__icon component__icon--${name}`}>{name}</i>
    {hover && (
      <span className={`hover-text hover-text--${hoverPosition}`} style={hoverStyle}>
        <span className="p2">{hover}</span>
      </span>
    )}
  </span>
);

const AccountIcon = (p) => (<MaterialIcon {...p} name="account_circle" hover="View Account" />);
const ApproveIcon = (p) => (<MaterialIcon {...p} name="thumb_up" hover="Approve" />);
const EditIcon = (p) => (<MaterialIcon {...p} name="edit" />);
const SaveIcon = (p) => (<MaterialIcon {...p} name="save" hover="Save" />);
const HistoryIcon = (p) => (<MaterialIcon {...p} name="history" />);
const MoreIcon = (p) => (<MaterialIcon {...p} name="more_vert" />);
const RejectIcon = (p) => (<MaterialIcon {...p} name="thumb_down" hover="Reject" />);
const DeleteIcon = (p) => (<MaterialIcon {...p} name="delete" />);
const ViewIcon = (p) => (<MaterialIcon {...p} name="remove_red_eye" />);
const ArrowDownIcon = (p) => (<MaterialIcon {...p} name="keyboard_arrow_down" />);
const ArrowBackIcon = (p) => (<MaterialIcon {...p} name="arrow_back" />);
const ArrowNextIcon = (p) => (<MaterialIcon {...p} name="arrow_forward" />);
const SettingsIcon = (p) => (<MaterialIcon {...p} name="settings" />);
const PasteIcon = (p) => (<MaterialIcon {...p} name="content_paste" />);
const BackupIcon = (p) => (<MaterialIcon {...p} name="backup" />);
const GroupAddIcon = (p) => (<MaterialIcon {...p} name="group_add" />);
const CloseIcon = (p) => (<MaterialIcon {...p} name="close" />);
const PlayIcon = (p) => (<MaterialIcon {...p} name="play_arrow" />);
const StopIcon = (p) => (<MaterialIcon {...p} name="stop" />);
const LockIcon = (p) => (<MaterialIcon {...p} name="lock" />);
const StarIcon = (p) => (<MaterialIcon {...p} name="star" />);
const StarBorderIcon = (p) => (<MaterialIcon {...p} name="star_border" />);
const StarHalfIcon = (p) => (<MaterialIcon {...p} name="star_half" />);
const CheckIcon = (p) => (<MaterialIcon {...p} name="check_circle_outline" />);
const VideoIcon = (p) => (<MaterialIcon {...p} name="videocam" />);
const ImageIcon = (p) => (<MaterialIcon {...p} name="image" />);
const CreateIcon = (p) => (<MaterialIcon {...p} name="create" hover="Create" />);
const ClearIcon = (p) => (<MaterialIcon {...p} name="clear" hover="Cancel" />);
const SearchIcon = (p) => (<MaterialIcon {...p} name="search" />);
const RefreshIcon = (p) => (<MaterialIcon {...p} name="refresh" />);
const GameIcon = (p) => (<MaterialIcon {...p} name="videogame_asset" />);
const ExpandMoreIcon = (p) => (<MaterialIcon {...p} name="expand_more" />);
const ExpandLessIcon = (p) => (<MaterialIcon {...p} name="expand_less" />);
const VolumeUpIcon = (p) => (<MaterialIcon {...p} name="volume_up" />);
const VolumeOffIcon = (p) => (<MaterialIcon {...p} name="volume_off" />);
const PauseIcon = (p) => (<MaterialIcon {...p} name="pause" />);
const InformationIcon = (p) => (<MaterialIcon {...p} name="report_problem" />);
const FileIcon = (p) => (<MaterialIcon {...p} name="insert_drive_file" />);
const PhoneIPhoneIcon = (p) => (<MaterialIcon {...p} name="phone_iphone" />);
const ErrorIcon = (p) => (<MaterialIcon {...p} name="error_outline" />);
const ErrorFilledIcon = (p) => (<MaterialIcon {...p} name="error" />);
const DownloadIcon = (p) => (<MaterialIcon {...p} name="download" />);
const HelpIcon = (p) => (<MaterialIcon {...p} name="help" />);
const HelpOutlineIcon = (p) => (<MaterialIcon {...p} name="help_outline" />);
const OpenInNewIcon = (p) => (<MaterialIcon {...p} name="open_in_new" />);
const FullscreenIcon = (p) => (<MaterialIcon {...p} name="fullscreen" />);
const FullscreenExitIcon = (p) => (<MaterialIcon {...p} name="fullscreen_exit" />);
const InfoIcon = (p) => (<MaterialIcon {...p} name="info" />);
const InfoOutlineIcon = (p) => (<MaterialIcon {...p} name="info_outline" />);
const SkipPreviousIcon = (p) => (<MaterialIcon {...p} name="skip_previous" />);
const AspectRatioIcon = (p) => (<MaterialIcon {...p} name="aspect_ratio" />);
const RotateRightIcon = (p) => (<MaterialIcon {...p} name="rotate_right" />);
const NewImageIcon = (p) => (<img {...p} src={NewImageIconSvg} />);
const NewVideoIcon = (p) => (<img {...p} src={NewVideoIconSvg} />);
const NewGameIcon = (p) => (<img {...p} src={NewGameIconSvg} />);

// copy
const copyText = (text) => {
  try {
    const textField = document.createElement('textarea');
    textField.innerText = text;
    textField.style.position = 'fixed';
    textField.style.left = 0;
    textField.style.top = 0;
    textField.style.opacity = 0;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  } catch (ex) {
    // do nothing, copy does not exist in the browser
  }
};
const CopyIcon = (p) => (<MaterialIcon {...p} name="content_copy" onClick={() => copyText(p.copyText)} />);

const flairIcons = {
  account: 'account_circle',
  application: 'apps',
  asset: 'image',
  campaign: 'format_list_bulleted',
  creative: 'photo_library',
  user: 'person_outline',
};

const forResource = (resource) => flairIcons[resource];

Icon.Account = AccountIcon;
Icon.Amazon = AmazonIcon;
Icon.Android = AndroidIcon;
Icon.Apple = AppleIcon;
Icon.Windows = WindowsIcon;
Icon.Copy = CopyIcon;
Icon.Approve = ApproveIcon;
Icon.Edit = EditIcon;
Icon.Save = SaveIcon;
Icon.History = HistoryIcon;
Icon.More = MoreIcon;
Icon.Reject = RejectIcon;
Icon.Delete = DeleteIcon;
Icon.View = ViewIcon;
Icon.ArrowDown = ArrowDownIcon;
Icon.ArrowBack = ArrowBackIcon;
Icon.ArrowNext = ArrowNextIcon;
Icon.Settings = SettingsIcon;
Icon.Paste = PasteIcon;
Icon.Backup = BackupIcon;
Icon.GroupAdd = GroupAddIcon;
Icon.Close = CloseIcon;
Icon.Play = PlayIcon;
Icon.Stop = StopIcon;
Icon.Lock = LockIcon;
Icon.Star = StarIcon;
Icon.StarBorder = StarBorderIcon;
Icon.StarHalf = StarHalfIcon;
Icon.Check = CheckIcon;
Icon.Video = VideoIcon;
Icon.Image = ImageIcon;
Icon.Create = CreateIcon;
Icon.Clear = ClearIcon;
Icon.Search = SearchIcon;
Icon.Refresh = RefreshIcon;
Icon.Game = GameIcon;
Icon.ExpandMore = ExpandMoreIcon;
Icon.ExpandLess = ExpandLessIcon;
Icon.VolumeUp = VolumeUpIcon;
Icon.VolumeOff = VolumeOffIcon;
Icon.Pause = PauseIcon;
Icon.Information = InformationIcon;
Icon.Error = ErrorIcon;
Icon.ErrorFilled = ErrorFilledIcon;
Icon.File = FileIcon;
Icon.PhoneIPhone = PhoneIPhoneIcon;
Icon.copyText = copyText;
Icon.forPlatform = forPlatform;
Icon.forResource = forResource;
Icon.DownloadIcon = DownloadIcon;
Icon.HelpIcon = HelpIcon;
Icon.HelpOutline = HelpOutlineIcon;
Icon.OpenInNew = OpenInNewIcon;
Icon.Fullscreen = FullscreenIcon;
Icon.FullscreenExit = FullscreenExitIcon;
Icon.Info = InfoIcon;
Icon.InfoOutline = InfoOutlineIcon;
Icon.SkipPrevious = SkipPreviousIcon;
Icon.AspectRatio = AspectRatioIcon;
Icon.RotateRight = RotateRightIcon;
Icon.NewImage = NewImageIcon;
Icon.NewVideo = NewVideoIcon;
Icon.NewGame = NewGameIcon;

export default Icon;
export {
  copyText,
  MaterialIcon,
};
