import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';
import './progressBar.scss';

const randomStep = (low, high) => Math.floor(Math.random() * (high - low)) + low;

const ProgressBar = ({
  prefixCls,
  className,
  height,
  background,
  error,
  start,
  complete,
  step,
  pendingStep,
  waitingProgress,
  onTransitionEnd,
  onChange,
}) => {
  const [show, setShow] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  let timer = null;

  const setProgressTimer = (offset) => {
    const random = Math.min(progress + offset, waitingProgress);
    timer = setTimeout(() => {
      setProgress(random);
      onChange(random);
    }, 200);
  };

  React.useEffect(() => {
    if (start) {
      setShow(true);
      setProgress(0);
    }
  }, [start]);

  React.useEffect(() => {
    if (start) {
      if (!complete) {
        if (progress < waitingProgress - 15) {
          setProgressTimer(randomStep(step, step + 5));
        } else {
          setProgressTimer(pendingStep);
        }
      } else {
        setProgress(100);
        onChange(100);
      }
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
    // --> potential bug inside this useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, progress]);

  if (!show) {
    return null;
  }

  return (
    <div className={classnames(prefixCls, className)}>
      <div style={{ height, background }}>
        <div
          className={classnames(`${prefixCls}-bar`, {
            [`${prefixCls}-complete-bar`]: complete && !error,
            [`${prefixCls}-error-bar`]: error,
          })}
          style={{
            width: `${complete ? 100 : progress}%`,
          }}
          onTransitionEnd={onTransitionEnd}
        />
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  prefixCls: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.bool,
  /**
   * height of the bar
   */
  height: PropTypes.number,
  /**
   * background color
   */
  background: PropTypes.string,
  /**
   * is start to show top loading bar or not
   */
  start: PropTypes.bool,
  /**
   * is loading complete or not
   */
  complete: PropTypes.bool,
  /**
   * step for progress
   */
  step: PropTypes.number,
  /**
   * step for before progress stop
   */
  pendingStep: PropTypes.number,
  /**
   * progress for waiting
   */
  waitingProgress: PropTypes.number,
  /**
   * Called when animation transition ended.
   */
  onTransitionEnd: PropTypes.func,
  /**
   * Called when progress value changed.
   */
  onChange: PropTypes.func,
};

ProgressBar.defaultProps = {
  prefixCls: 'v2_component_progress_bar',
  className: '',
  height: 4,
  background: '#D2DBE8',
  error: false,
  start: false,
  complete: false,
  step: 5,
  pendingStep: 0.05,
  waitingProgress: 95,
  onTransitionEnd: noop,
  onChange: noop,
};

export default React.memo(ProgressBar);
