import React from 'react';
import Text from 'components/Form/Text/TextContainer';
import StartEndDate from 'components/V2/StartAndEndDate/StartEndDate';
import CollapseSection from 'components/V2/Collapse/CollapseSection';
import Group from 'templates/input-group/InputGroupContainer';
import EditCampaignCreativeFormat from 'components/EditCampaignCreativeFormat/EditCampaignCreativeFormat';

const { Field } = Group;

const Details = ({ campaign }) => (
  <CollapseSection title="Details" description="">
    <Field name="Name" description="Choose a unique name for your Campaign">
      <Text
        noIcon
        placeholder="Name"
        value={campaign.get('name')}
        onChange={(v) => campaign.set('name', v)}
      />
    </Field>
    <EditCampaignCreativeFormat
      campaign={campaign}
    />
    <Field
      name="Campaign Start &amp; End Dates"
      description='Select when your campaign starts and stops serving.
          Select "Run Indefinitely" for your campaign to serve until there is no budget remaining.'
    >
      <StartEndDate campaign={campaign} />
    </Field>
  </CollapseSection>
);

export default Details;
