import { size } from 'lodash';
import React from 'react';
import { compose, namespace, splash } from 'app/app';
import { getReportingResources } from 'app/graphql/utils/reporting';
import { canModifyCreatives } from 'lib/helpers/authUser';
import { isEmptyArray, buildQueryObject } from 'lib/lib';
import { ListEmptyState } from 'components/V2/EmptyState/EmptyState';
import ListFilterContainer from 'components/V2/List/ListFilter/ListFilterContainer';
import { makeGetCreativesQuery } from 'services/Templates/Creatives/List/helper';
import { filterKeys } from 'services/Templates/Creatives/List/constants';
import List from 'services/Templates/Creatives/List/List';
import { getListCaches } from './helper';

const ns = 'data.creatives.list';
const updateState = namespace(ns);

class ListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.listCaches = getListCaches(props.authUser.id);
    this.state = {
      containsExtraQuery: false,
    };
  }

  getCreatives = (query = { page: 1 }) => {
    const { actions, authUser } = this.props;
    const [
      finalQuery,
      containsExtraQuery,
    ] = makeGetCreativesQuery(
      this.listCaches,
      {
        ...query,
        account: authUser.account,
      },
    );
    this.setState({
      containsExtraQuery,
    }, () => actions.getCreatives(finalQuery));
  };

  UNSAFE_componentWillReceiveProps(props) {
    if (props.refreshCreatives) {
      this.getCreatives({
        ...buildQueryObject(props.location.search),
        page: props.pagination.page,
      });
    }
  }

  componentDidMount() {
    this.getCreatives();
  }

  render() {
    const { creatives, loadingCreatives } = this.props;
    const { containsExtraQuery } = this.state;
    if (
      !loadingCreatives
      && !containsExtraQuery
      && isEmptyArray(creatives)
    ) {
      return (
        <ListEmptyState
          type="creative"
          {
            ...(canModifyCreatives() ? {
              to: '/creatives/create',
            } : {})
          }
        />
      );
    }

    const props = {
      ...this.props,
      ...this.listCaches,
      refreshList: this.getCreatives,
    };

    return (
      <div className="views__creatives__list">
        <ListFilterContainer
          {...props}
          filterKeys={filterKeys}
          {
            ...(canModifyCreatives() ? {
              to: '/creatives/create',
              cta: 'Add Creative',
            } : {})
          }
        />
        <List {...props} />
      </div>
    );
  }
}

const getCreatives = (() => {
  const action = (query) => getReportingResources(query)
    .then(({ data, total, ...rest }) => {
      if (size(data) > 0) {
        data.unshift({ ...total, isTotalRow: true });
      }
      return {
        ...rest,
        data,
      };
    });
  const end = ({ result, state }) => updateState(state, {
    loadingCreatives: false,
    pagination: result.pagination,
    creatives: result.data,
  });
  const start = ({ state }) => updateState(state, {
    loadingCreatives: true,
    refreshCreatives: false,
  });
  return { action, end, start };
})();

export const execChangeCreativeStatus = (() => {
  const action = ({ creative, status, shouldDetachAllCreatives }) => {
    if (status === 'approved') {
      return creative.approve();
    }
    if (status === 'pause') {
      return creative.pause(!!shouldDetachAllCreatives);
    }
    if (status === 'activate') {
      return creative.changeStatus('activate');
    }

    return creative.reject();
  };

  const start = () => splash({ start: true, text: 'changing creative state' });
  const end = ({
    state,
    result: {
      ok,
    } = {},
    props: {
      status,
      shouldDetachAllCreatives,
    },
  }) => {
    if (!ok && status === 'pause') {
      const text = shouldDetachAllCreatives
        ? 'Unable to pause creative - failed to detach creatives'
        : 'Unable to pause creative';
      return splash({ finish: true, text });
    }
    return ({
      ...updateState(state, { refreshCreatives: true }),
      ...splash({ finish: true, text: 'success!' }),
    });
  };
  return { action, end, start };
})();

const props = (state) => ({
  ...state[ns],
  authUser: state.authUser,
});

const actions = { getCreatives, execChangeCreativeStatus };

export default compose(ListContainer, { props, actions });
