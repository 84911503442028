import {
  createApplication, modifyApplication, changeStatus, refreshApplication,
} from 'app/graphql/utils/application';
import { config } from '../app/app';
import { isArray, isNumeric, isUndefined } from '../lib/lib';
import Model from './BaseModel';
import Application from './Application';

const { surgeOverrideThresholds } = config.get('applications');
const formatSurgeMultiplier = (v) => (isNumeric(v) ? (parseFloat(v) + 100).toFixed(2) : 100);

class AdminApplication extends Application {
  constructor(attrs = {}) {
    super(attrs);
    if (attrs.targeting && attrs.targeting.publisher && attrs.targeting.publisher.applications) {
      this.set('targeting.publisher.applications.ids', attrs.targeting.publisher.applications.map((a) => ({ id: a })).join(','), false);
    }
    this.map('surge_override_settings.value', formatSurgeMultiplier);
    this.map('targeting.publisher.applications', this.getArrayRelation('targeting.publisher.applications', Application).bind(this));
    this.default('targeting.publisher.applications', []);
    this.default('targeting.publisher.type', 'none');
    this.default('surge_override_settings.value', 0, [null]);
    this.default('surge_override_settings.is_enabled', false, [null]);
    this.default('platform', 'ios');
    this.default('logging', null);
    this.default('store.orientation', 'both');
    this.default('settings.click_look_back_period.is_enabled', false);
    this.default('settings.view_look_back_period.is_enabled', false);
    this.default('settings.click_look_back_period.value', 14);
    this.default('settings.view_look_back_period.value', 0);
    this.default('targeting.tags.type', 'allow');
    this.default('targeting.tags.tags', [{ value: 'managed' }]);
    this.default('tags', []);
    this.default('skadnetwork_settings.is_enabled', false);
    this.default('skadnetwork_settings.skadnetwork_postback_destination', null);
    this.default('appsFlyerEnrichedEngagements', false);

    const {
      skadnetwork_settings: {
        is_changeable: isChangeable = true,
        is_redownload_billed: isRedownloadBilled = true,
      } = {},
    } = attrs || {};

    this.default('skadnetwork_settings.is_changeable', isChangeable);
    this.default('skadnetwork_settings.is_redownload_billed', isRedownloadBilled);
  }

  toServerObject() {
    const isCreate = !this.get('id');
    const obj = {
      store: {
        orientation: this.raw('store.orientation'),
      },
      category: this.raw('category') || '',
      platform: this.raw('platform'),
      logging: this.raw('logging'),
      settings: {
        click_look_back_period: {
          value: this.raw('settings.click_look_back_period.value'),
        },
        view_look_back_period: {
          value: this.raw('settings.view_look_back_period.value'),
        },
      },
      admin_status: this.raw('admin_status'),
      name: this.raw('name'),
      tags: this.raw('tags'),
      surge_override_settings: {
        is_enabled: this.raw('surge_override_settings.is_enabled'),
        value: this.raw('surge_override_settings.value'),
      },
      skadnetwork_settings: {
        is_enabled: this.raw('skadnetwork_settings.is_enabled'),
        is_redownload_billed: this.raw('skadnetwork_settings.is_redownload_billed'),
        skadnetwork_postback_destination: this.raw('skadnetwork_settings.skadnetwork_postback_destination'),
      },
      appsFlyerEnrichedEngagements: this.raw('appsFlyerEnrichedEngagements'),
    };
    // When creating, allowed to set account/store information
    if (isCreate) {
      const {
        id,
        url,
        thumbnail,
        marketplace,
      } = this.raw('selected_application');
      obj.store.id = id.toString();
      if (this.get('platform') === 'android') {
        if (marketplace === 'samsung') {
          obj.store.thumbnail = thumbnail;
          obj.store.url = url;
          obj.store.marketplace = marketplace;
        } else {
          obj.store.marketplace = 'googleplay';
        }
      }
      obj.account = { id: this.raw('account.name').get('id') };
    }
    const targeting = {
      tags: {
        tags: this.raw('targeting.tags.tags'),
        type: this.raw('targeting.tags.type'),
      },
      publisher: {
        type: this.raw('targeting.publisher.type'),
        applications: this.raw('targeting.publisher.applications').map((a) => ({ id: a.get('id') })),
      },
    };
    obj.targeting = targeting;
    return obj;
  }

  editToServerObject() {
    const obj = {
      is_skadnetwork_enabled: this.raw('is_skadnetwork_enabled'),
      category: this.raw('category') === undefined ? null : this.raw('category'),
      store: {
        orientation: this.raw('store.orientation'),
        isCoppaRestrictedOverride: this.raw('store.is_coppa_restricted_override'),
      },
      platform: this.raw('platform'),
      admin_status: this.raw('admin_status'),
      name: this.raw('name'),
      logging: this.raw('logging'),
      tags: this.raw('tags'),
      surge_override_settings: {
        is_enabled: this.raw('surge_override_settings.is_enabled'),
        value: this.raw('surge_override_settings.value'),
      },
      appsFlyerEnrichedEngagements: this.raw('appsFlyerEnrichedEngagements'),
      skadnetwork_settings: {
        is_enabled: this.raw('skadnetwork_settings.is_enabled'),
        is_redownload_billed: this.raw('skadnetwork_settings.is_redownload_billed'),
        skadnetwork_postback_destination: this.raw('skadnetwork_settings.skadnetwork_postback_destination'),
      },
      settings: {
        click_look_back_period: {
          value: this.raw('settings.click_look_back_period.value'),
        },
        view_look_back_period: {
          value: this.raw('settings.view_look_back_period.value'),
        },
      },
    };
    const targeting = {
      tags: {
        tags: this.raw('targeting.tags.tags'),
        type: this.raw('targeting.tags.type'),
      },
      publisher: {
        type: this.raw('targeting.publisher.type'),
        applications: this.raw('targeting.publisher.applications').map((a) => ({ id: a.get('id') })),
      },
    };
    obj.targeting = targeting;
    return obj;
  }

  getParentAccountApplicationDenyListIDs() {
    // from /applications/:id
    let appDenyList = this.get('account.applications');
    // from /applications/create
    if (isUndefined(appDenyList) && !isUndefined(this.get('account.name'))) {
      appDenyList = this.get('account.name').get('applications');
    }
    if (isUndefined(appDenyList)) {
      return [];
    }
    return appDenyList.map((a) => a.id);
  }

  getParentAccountAccountDenyListIDs() {
    // from /applications/:id
    let accountDenyList = this.get('account.accounts');
    // from /applications/create
    if (isUndefined(accountDenyList) && !isUndefined(this.get('account.name'))) {
      accountDenyList = this.get('account.name').get('accounts');
    }
    if (isUndefined(accountDenyList)) {
      return [];
    }
    return accountDenyList.map((a) => a.id);
  }

  getPriceType() {
    return this.get('store.is_free') === false ? 'paid' : 'free';
  }

  // eslint-disable-next-line class-methods-use-this
  getAppStoreOptions(option) {
    return config.get('appStoreOptions')[option];
  }

  changeStatus(status) {
    return changeStatus(this.get('id'), status);
  }

  hasActiveTag(tag, targeting) {
    let tags = this.get('tags');
    if (!tags) {
      return false;
    }
    if (targeting) {
      tags = this.get('targeting.tags.tags');
    }
    const index = tags.findIndex((t) => t.value === tag);
    return index !== -1;
  }

  toggleFilterType(type) {
    this.set('targeting.tags.type', type);
    if (type === 'allow') {
      if (!this.hasActiveTag('managed', true)) {
        this.toggleTag('managed', true);
      }
    }
  }

  toggleTag(tag, targeting) {
    const targetingType = targeting ? 'targeting.tags.tags' : 'tags';
    const tags = this.get(targetingType);
    const index = tags.findIndex((t) => t.value === tag);
    if (index !== -1) {
      tags.splice(index, 1);
      this.set(targetingType, tags);
      return;
    }
    tags.push({ value: tag });
    this.set(targetingType, tags);
  }

  validateSurgeOverride = () => {
    const surgeOverrideValue = this.raw('surge_override_settings.value');
    const decimalRegex = new RegExp(/^\s*-?(\d+(\.\d{0,2})?|\.\d{0,2})\s*$/g); // TODO: Move this to a library as a validator
    if (!surgeOverrideValue) {
      return true;
    }
    if (isNumeric(surgeOverrideValue)) {
      if (surgeOverrideValue >= surgeOverrideThresholds.min && surgeOverrideValue <= surgeOverrideThresholds.max) {
        return decimalRegex.test(surgeOverrideValue);
      }
    }
    return false;
  }

  handleSurgeOverrideToggle = (v) => {
    if (!v) {
      this.set('surge_override_settings.value', 0);
    }
    this.set('surge_override_settings.is_enabled', v);
  }

  handleAEEToggle = (v) => {
    if (!v) {
      this.set('appsFlyerEnrichedEngagements', false);
    }
    this.set('appsFlyerEnrichedEngagements', v);
  }

  addTargetingPublisher(app) {
    if (isArray(app)) {
      const publishers = [...this.get('targeting.publisher.applications'), ...app];
      this.set('targeting.publisher.applications', publishers);
      return;
    }
    const publishers = this.get('targeting.publisher.applications');
    const index = publishers.findIndex((p) => p.get('id') === app.get('id'));
    if (publishers.length === 0 || index === -1) {
      publishers.push(app);
    }
    this.set('targeting.publisher.applications', publishers);
  }

  removeTargetingPublisher(app) {
    const publishers = this.get('targeting.publisher.applications');
    const index = publishers.findIndex((p) => p.get('id') === app.get('id'));
    publishers.splice(index, 1);
    this.set('targeting.publisher.applications', publishers);
  }

  setSKAdNetwork = (enabled) => {
    this.set('skadnetwork_settings.is_enabled', enabled);
  }

  setIncludeRedownload = (enabled) => {
    this.set('skadnetwork_settings.is_redownload_billed', enabled);
  }

  setSkAdNetworkPostbackDestination = (url) => {
    this.set('skadnetwork_settings.skadnetwork_postback_destination', url);
  }
}

AdminApplication.getAll = Application.getAll;
AdminApplication.make = Model.make(AdminApplication);
// AdminApplication.save = (application) => post(`${baseUrl}/${application.get('id')}`, application.editToServerObject());
AdminApplication.save = (application) => modifyApplication(
  application.get('id'),
  application.editToServerObject(),
);

AdminApplication.refresh = (application) => refreshApplication(application.get('id'), application.toServerObject());
AdminApplication.create = (platform, id, application) => createApplication(application.toServerObject());

export default AdminApplication;
