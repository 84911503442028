/* eslint react/prop-types: 0 */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './pulsation.scss';

const Pulsation = ({
  status,
  prefixCls,
  className,
  pulsationClass,
  labelText,
  handleEdit,
  children,
  editIcon,
  orbWrapper,
}) => {
  const statusCls = classNames(
    `${prefixCls}-icon`,
    `${prefixCls}-${status}`,
    pulsationClass,
  );
  const labelEl = labelText ? <span className={`${prefixCls}-label`}>{labelText}</span> : null;
  return (
    <div className={classNames(prefixCls, className)}>
      <div className={`${prefixCls}-top`}>
        {orbWrapper(<div className={statusCls} />)}
        {handleEdit && (
          <span className={`${prefixCls}-action`}>
            {labelEl}
            <i className={`material-icons ${prefixCls}-edit-icon`} onClick={handleEdit}>{editIcon}</i>
          </span>
        )}
        {!handleEdit && labelEl}
      </div>
      {children && (
        <span className={`${prefixCls}-child`}>
          {children}
        </span>
      )}
    </div>
  );
};

Pulsation.propTypes = {
  /**
   * prefix class for Pulsation
   */
  prefixCls: PropTypes.string,
  /**
   * add a class to Pulsation
   */
  className: PropTypes.string,
  /**
   * add a class to Pulsation icon
   */
  pulsationClass: PropTypes.string,
  /**
   * status for the Pulsation, will decide the icon color
   */
  status: PropTypes.oneOf(['active', 'inactive', 'test', 'not-connected']),
  /**
   * trail text for the Pulsation
   */
  labelText: PropTypes.string,
  /**
   * action for hover over
   */
  // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
  handleEdit: PropTypes.func,
  /**
   * icon for hover over
   */
  editIcon: PropTypes.string,
  /**
   * wrapper of orb
   */
  orbWrapper: PropTypes.func,
};
Pulsation.defaultProps = {
  prefixCls: 'v2_component_pulsation',
  className: null,
  pulsationClass: null,
  status: 'active',
  labelText: null,
  editIcon: 'play_arrow',
  orbWrapper: (orb) => orb,
};

export default Pulsation;
