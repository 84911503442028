import React from 'react';
import moment from 'moment';
import { ACTIVE } from 'app/constants/asset';
import {
  FILTER_KEY_APPLICATION,
  FILTER_KEY_CAMPAIGN,
  FILTER_KEY_COUNTRY,
  FILTER_KEY_CREATIVE,
  FILTER_KEY_DATE_RANGE,
  FILTER_KEY_LANGUAGE,
  FILTER_KEY_PLATFORM,
  FILTER_KEY_STATUS,
  FILTER_KEY_CAMPAIGN_STATUS,
  FILTER_KEY_CREATIVEQA_STATUS,
  FILTER_KEY_APPLICATION_STATUS,
  FILTER_KEY_MEMBER_STATUS,
  FILTER_KEY_AD_TYPE,
  FILTER_KEY_SEARCH,
  FILTER_KEY_PUBLISHER,
  FILTER_KEY_CAMPAIGN_SKADNETWORK,
  FILTER_KEY_ASSET_ORIENTATION,
  FILTER_KEY_ASSET_TYPE,
  FILTER_KEY_ASSET_STATUS,
  FILTER_KEY_CREATIVE_FORMAT,
  FILTER_KEY_CREATIVE_LIST_FORMAT,
  FILTER_KEY_BID_TYPE,
  FILTER_KEY_DELETION_STATUS,
  FILTER_KEY_NETWORK_TYPE,
  FILTER_KEY_TEMPLATE_STATUS,
  FILTER_KEY_TEMPLATE_FORMAT,
  FILTER_KEY_TEMPLATE_PROTOCOL,
  FILTER_KEY_ASSET,
  FILTER_KEY_SKOVERLAY_AUTO_SHOW,
  FILTER_KEY_VIDEO_OVERLAY_AUTO_SHOW,
  FILTER_KEY_CAMPAIGN_BUDGET_OBJECTIVES,
} from 'app/constants/filters';
import { selections } from '../../../lib/date';
import DatePickerFilter from './DatePickerFilter/DatePickerFilter';
import MultiTreeApplication from './MultiTreeApplication/MultiTreeApplication';
import MultiTreePublisher from './MultiTreePublisher/MultiTreePublisher';
import MultiTreeCampaign from './MultiTreeCampaign/MultiTreeCampaign';
import MultiTreeCreative from './MultiTreeCreative/MultiTreeCreative';
import MultiPlatform from './MultiPlatform/Platform';
import MultiStatus from './MultiStatus/Status';
import MultiCreativeQAStatus from './MultiCreativeQAStatus/CreativeQAStatus';
import MultiTreeLanguage from './MultiTreeLanguage/MultiTreeLanguage';
import MultiTreeCountry from './MultiTreeCountry/MultiTreeCountry';
import MultiTreeAdType from './MultiTreeAdType/MultiTreeAdType';
import MultiSKAdnetwork from './MultiSKAdnetwork/SKAdnetwork';
import MultiCreativeFormat from './MultiCreativeFormat/CreativeFormat';
import MultiAssetOrientation from './MultiAssetOrientation/AssetOrientation';
import MultiAssetTypes from './MultiAssetTypes/AssetTypes';
import CreativeFormatPicker from './CreativeFormatPicker/CreativeFormatPicker';
import MultiBidType from './MultiBidType/MultiBidType';
import MultiDeletionStatus from './MultiDeletionStatus/MultiDeletionStatus';
import MultiNetworkType from './MultiNetworkType/MultiNetworkType';
import MultiTemplateStatus from './MultiTemplateStatus/MultiTemplateStatus';
import MultiTemplateFormat from './MultiTemplateFormat/MultiTemplateFormat';
import MultiTemplateProtocol from './MultiTemplateProtocol/MultiTemplateProtocol';
import MultiTreeAsset from './MultiTreeAsset/MultiTreeAsset';
import MultiSKOverlay from './MultiSKOverlay/MultiSKOverlay';
import MultiCampaignBudgetObjective from './MultiCampaignBudgetObjective/MultiCampaignBudgetObjective';
import {
  ISO_8601_FORMAT,
  STATUS_MAJOR_TYPE,
  PARAMS_KEY_APPLICATION_ID,
  PARAMS_KEY_APPLICATION_NAME,
  assetStatuses,
  bidTypes,
} from './constant';

const getAllFilters = () => [
  {
    key: FILTER_KEY_DATE_RANGE,
    name: 'Date Range',
    Component: DatePickerFilter,
    getApiData: (dateRange) => {
      const { startString, endString, quickPickValue } = dateRange;
      let startDate;
      let endDate;
      if (quickPickValue === selections.CUSTOM.value) {
        startDate = moment(startString);
        endDate = moment(endString);
      } else {
        startDate = moment().subtract(quickPickValue * 1, 'days');
        endDate = quickPickValue === '0' ? moment() : moment().subtract(1, 'days');
      }
      const start = startDate.format(ISO_8601_FORMAT);
      const end = endDate.format(ISO_8601_FORMAT);
      return `filter[start:gte]=${start}&filter[end:lte]=${end}`;
    },
  },
  {
    key: FILTER_KEY_APPLICATION,
    name: 'Application',
    Component: MultiTreeApplication,
    getApiData: (applications) => {
      const ids = applications.map(({ id }) => id);
      return `filter[application.id:in]=${ids.join(',')}`;
    },
  },
  {
    key: FILTER_KEY_CAMPAIGN,
    name: 'Campaign',
    Component: MultiTreeCampaign,
    getApiData: (campaigns) => {
      const ids = campaigns.map(({ id }) => id);
      return `filter[campaign.name:in]=${ids.join(',')}`;
    },
  },
  {
    key: FILTER_KEY_COUNTRY,
    name: 'Country',
    Component: MultiTreeCountry,
    getApiData: (countries) => {
      const ids = countries.map(({ id }) => id);
      return `filter[country.name:in]=${ids.join(',')}`;
    },
  },
  {
    key: FILTER_KEY_CREATIVE,
    name: 'Creative',
    Component: MultiTreeCreative,
  },
  {
    key: FILTER_KEY_PLATFORM,
    name: 'Platform',
    Component: MultiPlatform,
    getApiData: (platformList, page = '') => {
      const ids = platformList.map((platform) => platform.id);
      if (page === 'Application') {
        return `filter[platform:search]=${ids.join(',')}`;
      }
      return `filter[application.platform:search]=${ids.join(',')}`;
    },
  },
  {
    key: FILTER_KEY_PUBLISHER,
    name: 'Publisher Application',
    Component: MultiTreePublisher,
  },
  {
    key: FILTER_KEY_STATUS,
    name: 'Status',
    Component: MultiStatus,
    getApiData: (statusList) => {
      const adminStatuses = [];
      const normalStatuses = [];
      statusList.forEach(({ type, status }) => {
        if (type === STATUS_MAJOR_TYPE) {
          normalStatuses.push(status);
        } else {
          adminStatuses.push(status);
        }
      });
      const apiData = [];
      if (adminStatuses.length > 0) {
        apiData.push(`filter[status.admin:in]=${adminStatuses.join(',')}`);
      }
      if (normalStatuses.length > 0) {
        apiData.push(`filter[status:in]=${normalStatuses.join(',')}`);
      }
      return apiData.join('&');
    },
  },
  {
    key: FILTER_KEY_CAMPAIGN_STATUS,
    name: 'Status',
    Component: MultiStatus,
    subType: FILTER_KEY_CAMPAIGN_STATUS,
    getApiData: (statusList) => {
      const adminStatuses = [];
      const normalStatuses = [];
      statusList.forEach(({ type, status }) => {
        if (type === STATUS_MAJOR_TYPE) {
          normalStatuses.push(status);
        } else {
          adminStatuses.push(status);
        }
      });

      const params = new URLSearchParams();
      if (adminStatuses.length > 0) {
        params.append('filter[status.admin:in]', adminStatuses.join(','));
      }
      if (normalStatuses.length > 0) {
        params.append('filter[status:in]', normalStatuses.join(','));
      }
      return params;
    },
  },
  {
    key: FILTER_KEY_CREATIVEQA_STATUS,
    name: 'Status',
    // eslint-disable-next-line react/display-name
    Component: (props) => <MultiCreativeQAStatus {...props} data={assetStatuses.filter(({ id }) => id !== ACTIVE)} />,
  },
  {
    key: FILTER_KEY_APPLICATION_STATUS,
    name: 'Status',
    Component: MultiStatus,
    subType: FILTER_KEY_APPLICATION_STATUS,
  },
  {
    key: FILTER_KEY_MEMBER_STATUS,
    name: 'Status',
    Component: MultiStatus,
    subType: FILTER_KEY_MEMBER_STATUS,
  },
  {
    key: FILTER_KEY_CAMPAIGN_SKADNETWORK,
    name: 'SkAdNetwork',
    Component: MultiSKAdnetwork,
    subType: 'skadnetwork',
  },
  {
    key: FILTER_KEY_CREATIVE_LIST_FORMAT,
    name: 'Creative Type',
    Component: MultiCreativeFormat,
    getApiData: (type) => {
      const ids = type.map(({ id }) => id);
      return `filter[format:in]=${ids.join(',')}`;
    },
  },
  {
    key: FILTER_KEY_LANGUAGE,
    name: 'Language',
    Component: MultiTreeLanguage,
    getApiData: (languages) => {
      const ids = languages.map(({ id }) => id);
      return `filter[language.name:in]=${ids.join(',')}`;
    },
  },
  {
    key: FILTER_KEY_ASSET_ORIENTATION,
    name: 'Orientation',
    Component: MultiAssetOrientation,
    getApiData: (languages) => {
      const ids = languages.map(({ id }) => id);
      return `filter[language.name:in]=${ids.join(',')}`;
    },
  },
  {
    key: FILTER_KEY_AD_TYPE,
    name: 'Ad Type Targeting',
    Component: MultiTreeAdType,
    getApiData: (types) => {
      const ids = types.map(({ id }) => id);
      return `filter[type.name:in]=${ids.join(',')}`;
    },
  },
  {
    key: FILTER_KEY_SEARCH,
    getApiData(searchValue) {
      return `filter[*:search]=${searchValue}`;
    },
  },
  {
    key: FILTER_KEY_ASSET_TYPE,
    name: 'Type',
    Component: MultiAssetTypes,
  },
  {
    key: FILTER_KEY_ASSET_STATUS,
    name: 'Status',
    // eslint-disable-next-line react/display-name
    Component: (props) => <MultiCreativeQAStatus {...props} data={assetStatuses} height={318} />,
  },
  {
    key: FILTER_KEY_CREATIVE_FORMAT,
    name: 'Creative Format',
    Component: CreativeFormatPicker,
    getApiData: (data) => {
      const ids = data.map(({ id }) => id);
      return `filter[creativeFormat:in]=${ids.join(',')}`;
    },
  },
  {
    key: FILTER_KEY_BID_TYPE,
    name: 'Campaign Type',
    Component: MultiBidType,
    options: bidTypes,
    getApiData: (data) => {
      const ids = data.map(({ id }) => id);
      return `filter[budget_type:in]=${ids.join(',')}`;
    },
  },
  {
    key: FILTER_KEY_DELETION_STATUS,
    name: 'Deletion Status',
    Component: MultiDeletionStatus,
    getApiData: (data) => {
      const ids = data.map(({ id }) => id);
      return `filter[deletion_status:in]=${ids.join(',')}`;
    },
  },
  {
    key: FILTER_KEY_NETWORK_TYPE,
    name: 'Network Type',
    Component: MultiNetworkType,
    getApiData: (data) => {
      const ids = data.map(({ id }) => id);
      return `filter[ad_type:in]=${ids.join(',')}`;
    },
  },
  {
    key: FILTER_KEY_TEMPLATE_STATUS,
    name: 'Template Status',
    Component: MultiTemplateStatus,
    getApiData: (data) => {
      const ids = data.map(({ id }) => id);
      return `filter[templateStatus:in]=${ids.join(',')}`;
    },
  },
  {
    key: FILTER_KEY_TEMPLATE_FORMAT,
    name: 'Template Format',
    Component: MultiTemplateFormat,
    getApiData: (data) => {
      const ids = data.map(({ id }) => id);
      return `filter[format:in]=${ids.join(',')}`;
    },
  },
  {
    key: FILTER_KEY_TEMPLATE_PROTOCOL,
    name: 'Template Protocol',
    Component: MultiTemplateProtocol,
    getApiData: (data) => {
      const ids = data.map(({ id }) => id);
      return `filter[templateProtocol:in]=${ids.join(',')}`;
    },
  },
  {
    key: FILTER_KEY_ASSET,
    name: 'Assets',
    Component: MultiTreeAsset,
  },
  {
    key: FILTER_KEY_SKOVERLAY_AUTO_SHOW,
    name: 'SKOverlay Auto Show',
    Component: MultiSKOverlay,
  },
  {
    key: FILTER_KEY_CAMPAIGN_BUDGET_OBJECTIVES,
    name: 'Optimization Type',
    Component: MultiCampaignBudgetObjective,
  },
  {
    key: FILTER_KEY_VIDEO_OVERLAY_AUTO_SHOW,
    name: 'Video Overlay Auto Show',
    Component: function render(props) {
      return (
        <MultiSKOverlay
          filterKey={FILTER_KEY_VIDEO_OVERLAY_AUTO_SHOW}
          {...props}
        />
      );
    },
  },
];

/**
 * get filters by keys
 * @param filterKeys
 * @param disabledOptionMap disabled filter options { filterKey: [disabledId1, disabledId2] }
 * @param hiddenOptionMap hide filter options { filterKey: [disabledId1, disabledId2] }
 * @param filterLabels display label pass from parent component { filterKey: labelName }
 * @param disabledDropDownFilters disable dropdown function { filterKey: true }
 * @returns {*}
 */
export function getFilters(
  filterKeys,
  disabledOptionMap = {},
  hiddenOptionMap = {},
  filterLabels = {},
  disabledDropDownFilters = {},
) {
  const availableFilters = getAllFilters().filter(({ key }) => filterKeys.includes(key));

  return availableFilters.map((filter) => {
    const { key, options } = filter;
    if (!options) {
      Object.assign(filter, {
        config: {
          disabledOptionMap,
          hiddenOptionMap,
          filterLabels,
          disabledDropDownFilters,
        },
      });
    }
    const hiddenOptions = hiddenOptionMap[key];

    if (Array.isArray(options) && hiddenOptions) {
      Object.assign(filter, { options: options.filter(({ id }) => !hiddenOptions.includes(id)) });
    }

    const disabledOptions = disabledOptionMap[key];
    if (Array.isArray(options) && disabledOptions) {
      options.forEach((opt) => {
        const disabled = disabledOptions.includes(opt.id);
        Object.assign(opt, { disabled });
        if (opt.children && opt.children.length > 0) {
          opt.children.forEach((o) => Object.assign(o, { disabled }));
        }
      });
    }

    if (filterLabels[key]) {
      Object.assign(filter, { filterLabel: filterLabels[key] });
    }

    if (disabledDropDownFilters[key]) {
      Object.assign(filter, { disableDropdown: disabledDropDownFilters[key] });
    }

    return filter;
  });
}

export function getFilter(key) {
  return getAllFilters().find((filter) => filter.key === key);
}

export function getQueryString(pageName, {
  page, perPage, account, cacheData,
}) {
  const apiData = [
    `page=${page}`,
    `per_page=${perPage}`,
    `filter[account.id:in]=${account}`,
  ];
  Object.keys(cacheData).forEach((key) => {
    const filter = getFilter(key);
    if (filter && typeof filter.getApiData === 'function') {
      apiData.push(filter.getApiData(cacheData[key], pageName));
    }
  });
  return apiData.join('&');
}

export function initFiltersByPageParams(props, cacheGenerator) {
  const { location, history, authUser } = props;
  const { search, path } = location;
  const params = new URLSearchParams(search);
  if (Array.from(params.keys()).length > 0) {
    // application handler
    const appId = params.get(PARAMS_KEY_APPLICATION_ID);
    const appName = params.get(PARAMS_KEY_APPLICATION_NAME);
    if (appId && appName) {
      const { account } = authUser;
      const filtersCache = cacheGenerator(account);
      filtersCache.save({
        [FILTER_KEY_APPLICATION]: [{
          id: appId,
          name: appName,
        }],
      });
    }
    history.replace(path);
  }
}

export const getDefaultChecked = (filterKeys) => filterKeys.reduce(
  (params, key) => ({
    ...params,
    [key]: key === FILTER_KEY_DATE_RANGE ? { quickPickValue: '7' } : [],
  }),
  {},
);
