import { getDeviceInfos } from 'app/graphql/utils/deviceinfo';
import Model from './BaseModel';

class DeviceInfo extends Model {
  toServerObject() {
    const device = {};
    device.make = this.raw('make');
    device.model = this.raw('model');
    device.release_msrp = this.raw('release_msrp');
    device.release_date = this.raw('release_date');
    device.name = this.raw('name');
    device.type = this.raw('type');
    device.platform = this.raw('platform');
    return device;
  }
}

DeviceInfo.getIOSDevices = async () => {
  const resp = await getDeviceInfos({
    perPage: 0, page: 1, platform: 'iOS', type: ['phone', 'tablet'],
  });
  let models = [];
  if (resp.status === 200 && resp.response) {
    models = resp?.response.deviceInfos;
  }
  return models;
};

export default DeviceInfo;
