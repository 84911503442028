import Cache from './Cache';
import {
  CREATIVE_QA_SORT_KEY,
  ASSET_SORT_KEY,
  GROW_CAMPAIGN_SORT_KEY,
  GROW_APPLICATION_SORT_KEY,
  GROW_CREATIVE_SORT_KEY,
  CTRL_APPLICATION_SORT_KEY,
  CTRL_CAMPAIGN_SORT_KEY,
  CTRL_CREATIVE_SORT_KEY,
} from './constant';

const defaultSort = [];

class SortCache extends Cache {
  constructor(userId, storageKey, defaultValue = defaultSort) {
    super(userId, storageKey, defaultValue);
    this.defaultSort = defaultValue;
  }

  save = (sort) => {
    localStorage.setItem(this.storageKey, JSON.stringify(sort));
  }

  get = () => {
    let sort = this.defaultValue || this.defaultSort;
    try {
      const _sort = JSON.parse(localStorage.getItem(this.storageKey));
      if (_sort && _sort.length > 0) {
        sort = _sort;
      }
    } catch (e) {
      // do nothing
    }
    return sort;
  }
}

export default SortCache;

export function getGrowApplicationSortCache(userId, defaultValue) {
  return new SortCache(userId, GROW_APPLICATION_SORT_KEY, defaultValue);
}

export function getCtrlApplicationSortCache(userId, defaultValue) {
  return new SortCache(userId, CTRL_APPLICATION_SORT_KEY, defaultValue);
}

export function getGrowCampaignSortCache(userId, defaultValue) {
  return new SortCache(userId, GROW_CAMPAIGN_SORT_KEY, defaultValue);
}

export function getCtrlCampaignSortCache(userId, defaultValue) {
  return new SortCache(userId, CTRL_CAMPAIGN_SORT_KEY, defaultValue);
}

export function getGrowCreativeSortCache(userId, defaultValue) {
  return new SortCache(userId, GROW_CREATIVE_SORT_KEY, defaultValue);
}

export function getCtrlCreativeSortCache(userId, defaultValue) {
  return new SortCache(userId, CTRL_CREATIVE_SORT_KEY, defaultValue);
}

export function getCreativeQASortCache(userId) {
  return new SortCache(userId, CREATIVE_QA_SORT_KEY);
}

export function getAssetSortCache(userId) {
  return new SortCache(userId, ASSET_SORT_KEY);
}

export function clearAllSortCache(userId) {
  getGrowApplicationSortCache(userId).remove();
  getCtrlApplicationSortCache(userId).remove();
  getGrowCampaignSortCache(userId).remove();
  getGrowCreativeSortCache(userId).remove();
  getCtrlCreativeSortCache(userId).remove();
  getCtrlCampaignSortCache(userId).remove();
  getCreativeQASortCache(userId).remove();
  getAssetSortCache(userId).remove();
}
