import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './label.scss';

const Label = (props) => {
  const {
    className,
    children,
    type,
  } = props;

  const prefixClsx = 'v2_component_label';

  return (
    <span
      className={classNames(
        prefixClsx,
        {
          [`${prefixClsx}-${type}`]: type,
        },
        className,
      )}
    >
      {children}
    </span>
  );
};

Label.propTypes = {
  /**
   * class name.
   */
  className: PropTypes.string,
  /**
   *  Type of label, default is `active`.
   */
  type: PropTypes.oneOf([
    'active',
    'pending',
    'rejected',
    'inactive',
    'option',
  ]),
};

Label.defaultProps = {
  className: null,
  type: 'active',
};

export const AdminOnlyLabel = () => <Label type="option">Admin Only Feature</Label>;

export default Label;
