// https://react-table.js.org/#/story/readme

import React from 'react';
import ReactTable, { ReactTableDefaults } from 'react-table';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import ContextMenu from '../ContextMenu/ContextMenuContainer';
import Checkbox from '../Form/Checkbox/CheckboxContainer';
import { get } from '../../lib/session';
import { SearchEmptyState } from '../V2/EmptyState/EmptyState';
import PaginationComponent from './Pagination';
import 'react-table/react-table.css';
import 'react-table-hoc-fixed-columns/lib/styles.css'; // important: this line must be placed after react-table css import
import './table.scss';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

Object.assign(ReactTableDefaults, {
  PaginationComponent,
  getTrProps: (state, rowInfo) => (rowInfo && rowInfo.original ? { original: rowInfo.original } : {}),
});

const TableCell = ({ value }) => <p className="p2">{value}</p>;
const TableHeader = ({ title }) => (
  <div className="header_content">
    <p className="p8">{title}</p>
    <i className="material-icons">sort</i>
  </div>
);
const makeTableCell = (original, value) => <TableCell value={value(original)} />;
const makeCheckBox = (original, onCheck, isChecked, faded) => (
  <Checkbox
    faded={faded}
    checked={faded || isChecked(original)}
    onChange={(v) => onCheck(original, v)}
  />
);

const Table = ({
  allChecked,
  columns,
  data,
  defaultPageSize = 10,
  defaultSort,
  fixed,
  includeActions = false,
  initialSort,
  isChecked,
  makeActions,
  makeFlags,
  onCheck,
  onCheckAll,
  selectable = false,
  someChecked,
  toggleColumn,
  type,
  campaignColumns,
  creativeColumns,
  reportColumns,
  message = '',
  ...props
}) => {
  const tableColumns = [];
  const columnType = `${type}Columns`;
  let activeColumns;
  if (type === 'campaign') {
    activeColumns = campaignColumns;
  } else if (type === 'creative') {
    activeColumns = creativeColumns;
  } else if (type === 'report') {
    activeColumns = reportColumns;
  }
  // if there's no type, then we always show the column
  const show = !type;
  if (selectable) {
    tableColumns.push({
      Cell: ({ original }) => makeCheckBox(original, onCheck, isChecked, allChecked),
      Header: <Checkbox
        inputPosition="absolute"
        faded={allChecked}
        some={allChecked ? false : someChecked}
        checked={allChecked}
        onChange={onCheckAll}
      />,
      className: 'components__table__cell components__table__cell--checkbox',
      filterable: false,
      headerClassName: 'components__table__header components__table__header--checkbox',
      resizable: false,
      sortable: false,
      width: 40,
    });
  }
  tableColumns.push(...columns.map(({
    accessor, centerContent = false, id, padding = 16, render, sortable = true, title, value, titleHidden = false, ...rest
  }) => ({
    ...rest,
    accessor,
    sortable,
    Cell: ({ original }) => (render ? render(original) : makeTableCell(original, value)),
    Header: <TableHeader title={title} sortable={sortable} />,
    className: `components__table__cell ${!padding && 'components__table__cell--no_padding'} ${centerContent && 'components__table__cell--center'}`,
    // eslint-disable-next-line max-len
    headerClassName: `components__table__header ${sortable && 'components__table__header--sortable'} ${titleHidden && 'components__table__header--hidden'}`,
    id: id || title,
    show: show || (get(columnType) ? get(columnType)[title] : activeColumns[title]),
  })));
  if (includeActions) {
    tableColumns.push({
      Cell: ({ original }) => makeActions(original, fixed),
      Header: (
        <ContextMenu reportsOptions="reports_options" ClickItem={<i className="material-icons">settings</i>}>
          {columns.map(({ title }) => (
            <ContextMenu.CheckOption
              key={title}
              checked={show || (get(columnType) ? get(columnType)[title] : activeColumns[title])}
              onChange={() => toggleColumn(title, type)}
              text={title}
            />
          ))}
        </ContextMenu>
      ),
      className: 'components__table__cell components__table__cell--actions ',
      filterable: false,
      headerClassName: 'components__table__header components__table__header--actions',
      resizable: true,
      sortable: false,
      width: 40,
    });
  }
  const defaultSorted = [];
  if (defaultSort) {
    defaultSorted.push({ id: defaultSort, desc: true });
  }
  return (
    <div className="components__table">
      {data.length === 0 && <SearchEmptyState />}
      {message && (
        <div className="table_message_container">
          <span>{message}</span>
        </div>
      )}
      {data.length > 0 && (
        <ReactTableFixedColumns
          defaultSortDesc
          fixed={fixed}
          columns={tableColumns}
          data={data}
          defaultPageSize={defaultPageSize}
          defaultSorted={defaultSorted}
          minRows={0}
          pageSizeOptions={[10, 25, 50, 100]}
          makeFlags={makeFlags}
          {...props}
        />
      )}
    </div>
  );
};

export default Table;
