import React from 'react';
import imgErrorMobile from 'assets/img/error_mobile.svg';

const ErrorContainer = ({
  error: {
    error, info, firstLine, stack,
  }, disableTrace,
}) => (
  <div className="component__error_container">
    {!disableTrace && (
      <div className="error_details">
        <p>Error:</p>
        <div className="thrown">
          <p className="h2">{error.toString()}</p>
          <p className="h2">{firstLine}</p>
        </div>
        <div className="stack">
          <p className="p2">{stack}</p>
          <p className="p2">{info.componentStack}</p>
        </div>
      </div>
    )}
    {disableTrace && (
      <>
        <img src={imgErrorMobile} />
        <div className="info">
          <p className="h2">Oh no! Something unexpected happened.</p>
          <p className="p2">Someone has been notified. Please try refreshing your browser in the mean time.</p>
        </div>
      </>
    )}
  </div>
);

export default ErrorContainer;
