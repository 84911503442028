import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import Panel from './Panel';

const FrontendPanel = ({
  tree,
  className,
  nodes,
  pagination,
  visible,
  placeholder,
  maxSelected,
  disabledTitle,
  disabledContent,
  defaultCheckedNodes,
  onVisibleChange,
  onChange,
}) => {
  const [checkedNodes, setCheckedNodes] = useState(defaultCheckedNodes || []);
  const [checkableNodes, setCheckableNodes] = useState([]);
  const [menuNodes, setMenuNodes] = useState([]);

  const getCheckableNodes = nodes.reduce(
    (prev, node) => prev.concat(
      node.children ? node.children.filter((n) => !n.disabled) : [],
    ), [],
  );

  useEffect(() => {
    if (tree) {
      setCheckableNodes(getCheckableNodes);
    } else {
      setCheckableNodes(nodes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodes]);

  const onCheckedChange = useCallback((_checkedNodes) => {
    setCheckedNodes(_checkedNodes);
    onChange(_checkedNodes);
  }, [onChange]);

  const onLoadMenu = React.useCallback(({ search }) => {
    const findFn = (name, keyword) => name.toLowerCase().includes(keyword.toLowerCase());
    if (tree) {
      setMenuNodes(checkableNodes.filter((node) => findFn(node.name, search)));
    } else {
      setMenuNodes(nodes.filter((node) => findFn(node.name, search)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodes, checkableNodes]);

  return (
    <Panel
      tree={tree}
      nodes={nodes}
      prefixCls={className}
      visible={visible}
      pagination={pagination}
      menuNodes={menuNodes}
      placeholder={placeholder}
      maxSelected={maxSelected}
      disabledTitle={disabledTitle}
      disabledContent={disabledContent}
      checkableNodes={checkableNodes}
      renderLeaf={({ name }) => name}
      defaultCheckedNodes={checkedNodes}
      onVisibleChange={onVisibleChange}
      onChange={onCheckedChange}
      onLoadMenu={onLoadMenu}
    />
  );
};

FrontendPanel.propTypes = {
  tree: PropTypes.bool,
  nodes: PropTypes.arrayOf(PropTypes.any),
  visible: PropTypes.bool,
  placeholder: PropTypes.string,
  maxSelected: PropTypes.number,
  disabledTitle: PropTypes.string,
  disabledContent: PropTypes.string,
  defaultCheckedNodes: PropTypes.arrayOf(PropTypes.any),
  onVisibleChange: PropTypes.func,
  onChange: PropTypes.func,
};

FrontendPanel.defaultProps = {
  tree: false,
  nodes: [],
  visible: false,
  placeholder: '',
  maxSelected: 0,
  disabledTitle: '',
  disabledContent: '',
  defaultCheckedNodes: [],
  onVisibleChange: noop,
  onChange: noop,
};

export default React.memo(FrontendPanel);
