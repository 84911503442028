import React from 'react';
import {
  compose,
  namespace,
  splash,
} from 'app/app';
import { getStore } from 'app/storeprovider';
import User from 'models/User';
import List from './List';
import './list.scss';
import config from '../../../../../lib/config';

const ns = 'views.team.list';
const updateState = namespace(ns);
const props = (state) => ({ ...state[ns], authUser: state.authUser });
const { accountMemberLimitation } = config.get('accounts');

class ListContainer extends React.Component {
  componentDidMount() {
    this.props.actions.getUsers({ account: this.props.authUser.account });
  }

  componentWillUnmount() {
    this.props.actions.resetState();
  }

  render() {
    return <List {...this.props} />;
  }
}

const deleteUser = ({ state, values: user }) => updateState(state, { userToDelete: user });
const resetState = ({ state }) => updateState(state, { users: undefined });

const loadUsers = (filters) => {
  const state = getStore().getState();
  const { account } = state.authUser;
  return User.getAll({
    account,
    perPage: accountMemberLimitation,
    ...filters,
  });
};

const getUsers = (() => {
  const action = (filters) => loadUsers(filters);
  const end = ({ state, result }) => updateState(state, { users: User.make(result.response) });
  const start = ({ state, result }) => updateState(state, {
    filters: {
      account: state.authUser.account,
      search: result.search,
    },
  });
  return { action, end, start };
})();

const submitDeleteUser = (() => {
  const action = async (user) => {
    await User.delete(user);
    return loadUsers();
  };
  const start = () => splash({ start: true, text: 'Deleting user' });
  const end = ({ state, result }) => ({
    ...splash({ finish: true, text: 'success' }),
    ...updateState(state, { userToDelete: null, users: User.make(result.response) }),
  });
  return { action, end, start };
})();

const actions = {
  deleteUser,
  getUsers,
  submitDeleteUser,
  resetState,
};

export default compose(ListContainer, { props, actions });
