import React from 'react';
import MultiTreeSelected from 'templates/MultiTreeSelector/MultiTreeSelected';

const prefixCls = 'selected';

const RegionSelected = ({
  selectedItems,
  onSelectedChange,
  onPaste,
}) => (
  <MultiTreeSelected
    shouldShowCopy
    shouldShowPaste
    className="region-selected-wrapper"
    prefixCls={prefixCls}
    selectedItems={selectedItems}
    onSelectedChange={onSelectedChange}
    onPaste={onPaste}
    placeholder="Paste ISO codes"
    copyMapper={(subdivision) => subdivision.code}
    deleteMapper={(change) => (selected) => selected.code !== change.code}
    filterKeyGetter={(subdivision) => subdivision.code}
  />
);

export default RegionSelected;
