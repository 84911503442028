import React, { useState, useMemo } from 'react';
import moment from 'moment';
import {
  PENDING,
  NEEDS_ATTENTION,
  VISUAL_QA,
  FULL_QA,
  ACTIVE,
} from 'app/constants/asset';
import { MiniCollapse } from '../../../../../components/V2';
import { firstLetterUpper, hyperlinkURL } from '../../../../../lib/lib';
import Message from '../../../../../components/V2/FlagMessage/Message';
import Alert from '../../../../../components/Alert/Alert';
import ServingStatus from '../../../../../components/Assets/ServingStatus/ServingStatus';
import Asset from '../../../../../models/Asset';
import {
  downloadValuesDisplay,
  completeValuesDisplay,
} from '../qaModal/inputsDefined/visualQATests/config';
import {
  functionalityCheckBoxData,
  contentCheckBoxData,
  asoiCheckBoxData,
  visualQACheckBoxData,
  historyActions,
  useFullQATestResult,
} from '../config';
import ResolvingIssues from './ResolvingIssues';
import './historyItem.scss';

export const renderUncheckItem = (valueObj, checkboxData = []) => {
  const uncheckedItems = checkboxData.filter((item) => !valueObj[item.value]);
  if (!uncheckedItems.length) {
    return null;
  }

  return (
    <div className="uncheck-items">
      <p className="content-title">Note:</p>
      {uncheckedItems.map((item) => (
        <p key={item.value} className="unchecked-item">{item.notWorkingLabel}</p>
      ))}
    </div>
  );
};

export const renderFeedback = (otherCases) => (!otherCases ? null : (
  <div className="other-cases">
    <p className="content-title">Other Cases (additional feedback):</p>
    <p className="cases-comments">{hyperlinkURL(otherCases, 'link')}</p>
  </div>
));

const renderVisualQAContent = (visualQA) => (
  <div className="needs-attention-content">
    {renderUncheckItem(visualQA, visualQACheckBoxData)}
    <div className="dropdown-event">
      <div className="dropdown">
        <p className="content-title">Download Event (perceived via testing):</p>
        <p className="content-value">{downloadValuesDisplay[visualQA.download]}</p>
      </div>
      <div className="dropdown">
        <p className="content-title">Complete Event (perceived via testing):</p>
        <p className="content-value">{completeValuesDisplay[visualQA.complete]}</p>
      </div>
    </div>
    {renderFeedback(visualQA.feedback)}
    <Alert.Help className="creativeQA_alert_help">
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: visualQA.result }} />
    </Alert.Help>
  </div>
);

const needRenderFullQAContentSection = (sectionObj) => Object.values(sectionObj).some((val) => {
  if (typeof val === 'boolean') return !val;
  if (typeof val === 'string') return !!val.trim();
  if (Array.isArray(val)) return !!val.length;
  return false;
});

export const renderFullQAContent = (fullQA, tip) => {
  const checkboxData = {
    functionality: functionalityCheckBoxData,
    content: contentCheckBoxData,
    asoi: asoiCheckBoxData,
  };

  const content = Object.keys(fullQA).map((key) => {
    if (!needRenderFullQAContentSection(fullQA[key])) {
      return null;
    }

    if (key === 'general') {
      if (fullQA.general.other?.length < 1 && fullQA.general.recordings?.length < 1) {
        return null;
      }
      return (
        <div key={key} className="needs-attention-content">
          {renderFeedback(fullQA[key].other)}
          {fullQA[key].recordings?.length > 0 && (
            <div className="recordings">
              <p className="content-title">Recordings of the Issues:</p>
              {fullQA[key].recordings?.map((record) => (
                <a key={record} className="record-item text-ellipsis" href={record} target="blank">{record}</a>
              ))}
            </div>
          )}
        </div>
      );
    }

    if (key === 'asoi') {
      return (
        <div key={key} className="needs-attention-content">
          <div className="uncheck-items">
            <p className="content-title">Note:</p>
            {checkboxData[key].map((item) => (
              <p key={item.value} className="unchecked-item">
                {fullQA[key][item.value]
                  ? `ASOI ${firstLetterUpper(item.value)} is available for this asset`
                  : `ASOI ${firstLetterUpper(item.value)} is NOT working`}
              </p>
            ))}
          </div>
          {fullQA[key].other?.length > 0 && renderFeedback(fullQA[key].other)}
        </div>
      );
    }

    return (fullQA[key].other?.length > 0
      ? (
        <div key={key} className="needs-attention-content">
          {renderUncheckItem(fullQA[key], checkboxData[key])}
          {renderFeedback(fullQA[key].other)}
        </div>
      )
      : null
    );
  });

  return (
    <div>
      {content}
      <Alert.Help className="creativeQA_alert_help">
        <span className="tip">{tip}</span>
      </Alert.Help>
    </div>
  );
};

export const renderResolvedAction = (nextQAData) => {
  if (!nextQAData) return null;
  if (nextQAData.action === historyActions.feedback) {
    return (
      <div className="action resolved-action">
        <p className="action-title">Please let us know how you would like to proceed. (256 characters maximum)</p>
        <p className="resolved-content">{nextQAData.uploadComments}</p>
        <p>You have already sent the feedback above, we’ll get back to you shortly.</p>
      </div>
    );
  }
  return (
    <div className="action resolved-action">You have already replaced your Asset to Resubmit for Another Review</div>
  );
};

const testFullQACanProceed = (fullQAValue) => Object.values(fullQAValue).filter((val) => typeof val === 'boolean').every((bool) => bool);

const HistoryItem = ({
  isExpanded,
  onExpandedChange,
  measure,
  isLatest,
  assetId,
  refreshHistory,
  nextQAData,
  onAssetReplacement,
  data: {
    status,
    action,
    description,
    updated,
    uploadComments,
    isVungleBundle,
    visualQA = {},
    fullQA = {
      functionality: {},
      content: {},
      asoi: {},
      general: {},
    },
  },
}) => {
  const [feedback, setFeedback] = useState('');
  const tip = useFullQATestResult(status, fullQA);
  const collapsible = action !== historyActions.rename
    // eslint-disable-next-line camelcase
    && ((status === PENDING && !!uploadComments) || status === NEEDS_ATTENTION || (status === ACTIVE && !isVungleBundle));
  const stage = visualQA.finished ? FULL_QA : VISUAL_QA;

  const canProceed = useMemo(() => {
    if (stage === VISUAL_QA) {
      // if stage === VISUAL_QA, one or two of the three ASOI variations are valid, this asset can proceed;
      if (!visualQA.validVariations) return false;
      return Object.values(visualQA.validVariations).filter((bool) => typeof bool === 'boolean' && bool).length >= 1;
    }
    /**
     *  if stage === FULL_QA,
     *  all of the scenarios of the checkboxes in functionality and content tests are checked,
     *  and at least one of asoi is checked,
     *  this asset can proceed;
     */
    return testFullQACanProceed(fullQA.functionality)
      && testFullQACanProceed(fullQA.content)
      && Object.values(fullQA.asoi).filter((val) => typeof val === 'boolean' && val).length >= 1;
  }, [visualQA, stage, fullQA]);

  const header = (
    <div className="header">
      <ServingStatus status={status} fixedWidth />
      <div className="info">
        <span className="time">
          {moment(updated).format('h:mm a')}
        </span>
        <i className="split" />
        <span className="message">{description}</span>
      </div>
    </div>
  );

  const needsAttentionContent = stage === VISUAL_QA ? renderVisualQAContent(visualQA) : renderFullQAContent(fullQA, tip);

  const sendFeedback = async () => {
    if (!feedback.trim()) return;
    try {
      await Asset.sendFeedback(assetId, feedback);
      Message.success({
        title: 'Success',
        content: 'Send feedback successfully.',
      });
      refreshHistory();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.dir(error);
      Message.error({
        title: 'Error',
        content: 'Send feedback failed, please try again later.',
      });
    }
  };

  const needsAttentionCollapseContent = (
    <>
      {needsAttentionContent}
      {isLatest && (
        <ResolvingIssues
          canProceed={canProceed}
          measure={measure}
          feedback={feedback}
          onAssetReplacement={onAssetReplacement}
          onFeedbackChange={(val) => setFeedback(val)}
          onSendFeedback={sendFeedback}
        />
      )}
      {!isLatest && !!nextQAData && renderResolvedAction(nextQAData)}
    </>
  );

  const activeCollapseContent = (
    <Alert.Help className="creativeQA_alert_help">
      <span className="tip">{tip}</span>
    </Alert.Help>
  );

  return (
    <div className="creativeQA-history-item">
      <MiniCollapse
        header={header}
        expanded={isExpanded}
        collapsible={collapsible}
        onAnimationEnd={measure}
        duration={0}
        onChange={onExpandedChange}
        animateHeightStyle={{ display: isExpanded ? 'block' : 'none' }}
      >
        <div className="content">
          {/* eslint-disable-next-line camelcase */}
          {status === PENDING && uploadComments}
          {status === NEEDS_ATTENTION && needsAttentionCollapseContent}
          {status === ACTIVE && activeCollapseContent}
        </div>
      </MiniCollapse>
    </div>
  );
};

export default HistoryItem;
