import React from 'react';
import './AssetPreviewerVideo.scss';
import Icon from '../../../../../../components/Icon/Icon';
import { formatDuration } from '../utils';

class AssetPreviewerVideo extends React.Component {
  constructor(props) {
    super(props);
    this.videoPlayer = React.createRef();
    this.state = {
      elapsedTimeFormatted: '',
      elapsedTimePercentage: 0,
      isMuted: true,
      playingStatus: 'pause',
    };
  }

  componentWillUnmount = () => this.pauseVideo();

  playVideo = () => {
    this.videoPlayer.current.play();
    this.setState({ playingStatus: 'pause' });
  };

  pauseVideo = () => {
    this.videoPlayer.current.pause();
    this.setState({ playingStatus: 'play' });
  };

  onVideoLoaded = () => {
    this.playVideo();
    this.setVideoVolume();
  };

  onVideoEnded = () => {
    this.setState({ playingStatus: 'refresh' });
  };

  onTimeUpdate = () => this.setState({
    elapsedTimeFormatted: formatDuration(this.videoPlayer.current.currentTime),
    elapsedTimePercentage: Math.floor((this.videoPlayer.current.currentTime * 100) / this.videoPlayer.current.duration),
  });

  isVideoLoaded = () => this.videoPlayer && this.videoPlayer.current && this.videoPlayer.current.currentTime;

  getVideoDuration = () => formatDuration(this.videoPlayer.current.duration);

  setVideoVolume = () => {
    this.videoPlayer.current.muted = this.state.isMuted;
  };

  toggleVolume = () => {
    this.setState((prevState) => ({ isMuted: !prevState.isMuted }), () => {
      this.setVideoVolume();
    });
  };

  togglePlay = () => {
    const { playingStatus } = this.state;

    if (playingStatus === 'play') {
      this.playVideo();
      return;
    }

    this.pauseVideo();
  };

  render() {
    const { asset } = this.props;
    const {
      elapsedTimePercentage, elapsedTimeFormatted, playingStatus, isMuted,
    } = this.state;

    if (!asset) {
      return null;
    }

    return (
      <div className="asset-previewer-video">
        <video
          ref={this.videoPlayer}
          onTimeUpdate={this.onTimeUpdate}
          onEnded={this.onVideoEnded}
          onClick={this.togglePlay}
          onLoadedMetadata={this.onVideoLoaded}
          muted
        >
          <source src={asset.get('url')} />
        </video>

        <div className="progress">
          <div className="elapsed-progress" style={{ width: `${elapsedTimePercentage}%` }} />
        </div>

        <div className="video-controls">
          <div className="time-container">
            {this.isVideoLoaded()
            && (
            <span className="p2">
              {elapsedTimeFormatted}
              {' '}
              /
              <span className="video-duration">{this.getVideoDuration()}</span>
            </span>
            )}
          </div>
          <div className="buttons-container">
            {playingStatus === 'pause' && <Icon.Pause onClick={this.pauseVideo} />}
            {playingStatus === 'play' && <Icon.Play onClick={this.playVideo} />}
            {playingStatus === 'refresh' && <Icon.Refresh onClick={this.playVideo} />}

            {!isMuted
              ? <Icon.VolumeUp onClick={this.toggleVolume} />
              : <Icon.VolumeOff onClick={this.toggleVolume} />}
          </div>
        </div>
      </div>
    );
  }
}

export default AssetPreviewerVideo;
