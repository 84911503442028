import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import Text from '../../../../../../components/Form/Text/TextContainer';
import Asset from '../../../../../../models/Asset';
import IconComponent from '../../../../../../components/Icon/Icon';
import ButtonComponent from '../../../../../../components/Button/Button';
import './AssetNameInput.scss';

class AssetNameInput extends Component {
  constructor(p) {
    super(p);
    this.state = {
      isEditActive: false,
      assetName: null,
      originalAssetName: null,
    };
  }

  componentDidMount = () => {
    const { asset } = this.props;

    if (!asset) {
      return;
    }

    this.setState({
      assetName: asset.get('name'),
      originalAssetName: asset.get('name'),
    });
  }

  clearError = () => {
    const { clearError } = this.props;
    if (!clearError) {
      return;
    }
    clearError();
  }

  activateEdit = () => {
    this.setState({
      isEditActive: true,
    });
  }

  discardChanges = () => {
    this.clearError();

    this.setState((prevState) => ({
      isEditActive: false,
      assetName: prevState.originalAssetName,
    }));
  }

  onChange = (v) => {
    this.clearError();

    this.setState({
      assetName: v,
    });
  }

  onError = (messages) => {
    const { asset, onError } = this.props;
    const { originalAssetName } = this.state;
    asset.set('name', originalAssetName);

    if (onError) {
      onError(messages);
    }
  }

  submitChanges = async () => {
    const { asset, onAssetNameChange } = this.props;
    const { assetName } = this.state;

    this.clearError();

    asset.set('name', assetName);

    try {
      const result = await Asset.save(asset);

      if (result.status === 200) {
        this.setState({
          isEditActive: false,
          originalAssetName: assetName,
        });
        onAssetNameChange(assetName);
        return;
      }

      if (!result.response || !('messages' in result.response)) {
        this.onError(['Unexpected error occurred.']);
        return;
      }

      this.onError(result.response.messages);
    } catch (x) {
      this.onError(['Unexpected error occurred.']);
    }
  }

  render() {
    const { assetName, isEditActive } = this.state;

    return (
      <div className="asset_name_input">
        {isEditActive && (
        <div className="asset_name_input_controller">
          <Text noIcon value={assetName} onChange={(v) => this.onChange(v)} />

          <ButtonComponent
            cancel
            className="button-discard-changes"
            onClick={this.discardChanges}
          >
            Cancel
          </ButtonComponent>

          <ButtonComponent
            className="button-submit-changes"
            onClick={this.submitChanges}
          >
            Save
          </ButtonComponent>
        </div>
        )}
        {!isEditActive && (
        <div className="asset_name_input_description">
          <div className="name">{assetName}</div>
          <IconComponent.Edit onClick={this.activateEdit} />
        </div>
        )}
      </div>
    );
  }
}

AssetNameInput.propTypes = {
  asset: PropTypes.instanceOf(Asset).isRequired,
  onAssetNameChange: PropTypes.func,
  onError: PropTypes.func,
  clearError: PropTypes.func,
};

AssetNameInput.defaultProps = {
  onAssetNameChange: noop,
  onError: noop,
  clearError: noop,
};

export default AssetNameInput;
