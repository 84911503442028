const bidsBudgets = [
  {
    id: 'cpm', name: 'CPM', min: 0.5, max: 25,
  },
  {
    id: 'cpi', name: 'CPI', min: 0.01, max: 10,
  },
  {
    id: 'cpcv', name: 'CPCV', min: 0.001, max: 0.05,
  },
  {
    id: 'cpv', name: 'CPV', min: 0.001, max: 0.05,
  },
  {
    id: 'cpc', name: 'CPC', min: 0.01, max: 0.25,
  },
  {
    id: 'cppu', name: 'CPPU', min: 0.01, max: 1000,
  },
  {
    id: 'cpe', name: 'CPE', min: 0.01, max: 1000,
  },
  {
    id: 'total', name: 'Total', min: 1000, max: 1000000000,
  },
  {
    id: 'daily', name: 'Daily Budget', min: 100, max: 1000,
  },
];

const bidsBudgetsAlertThresholds = {
  CPM: 10,
  CPI: 10,
  CPCV: 0.015,
  CPV: 0.015,
  CPC: 0.20,
  CPPU: 400,
  CPE: 400,
  daily: 500,
  TARGET_CPI: 10,
};

const maxBidding = 200000;

const bidsBudgetsAlertContent = {
  overrunDanger: {
    title: 'Bid & Budget Combination Alert',
    alertText: `Your current combination of high bid and low daily cap may lead to spend overruns.
      We recommend you increase your daily cap. If you are unable to increase your daily cap, we recommend considering to reduce your bids.`,
  },
  rateLimitReached: { title: 'Could not upload', alertText: `You can only upload ${maxBidding} multibidding rates at most` },
  publisherRates: {
    title: 'Validation Issues with Publisher Rates CSV',
    alertStubs: {
      missingHeaders: 'These expected headers were missing: ',
      unexpectedHeaders: 'These unexpected headers were found: ',
      invalidHexIDs: 'These IDs are not valid ObjectIDs: ',
      invalidPubIDs: 'These IDs do not correspond to any Publisher Apps: ',
      duplicatePubIDs: 'These IDs have duplicate entries in the CSV: ',
      invalidRateEntry: 'These IDs have rates that aren\'t valid numbers between ',
      invalidGeoEntry: 'These Geos are invalid. Either not targeted or invalid geo code ',
      invalidFilename: 'The file name must be in the following format: ',
    },
  },
};

const geoBudget = {
  csvHeaders: [
    { label: 'geo', key: 'code' },
    { label: 'rate', key: 'rate' },
  ],
};

const multibidding = {
  bidRangeValidationEnabled: false,
  maxBidding,
  editColumns: [
    { key: 'rate', label: 'Bid', isCurrency: true },
  ],
  jsonExampleData: [
    { name: 'Application name', pub_app_id: 'Mongo ObjectId', rate: 'Number with up to 2 decimal numbers' },
    { name: 'Appname123', pub_app_id: '5aea2bd3ee5aac7e46cb1805', rate: 10.50 },
  ],
  csvExampleData: [
    ['name', 'pub_app_id', 'geo', 'rate'],
    ['Application name', 'Mongo ObjectId', 'Enter ISO Alpha 2 Geo code', 'Number with up to 2 decimal numbers'],
    ['Appname123', '5aea2bd3ee5aac7e46cb1805', 'US', 10.50],
  ],
  csvBetaExampleData: [
    ['name', 'pub_app_id', 'geo', 'rate'],
    ['Application name', 'Mongo ObjectId', 'Enter ISO Alpha 2 Geo code', 'Number with up to 2 decimal numbers'],
    ['Appname123', '5aea2bd3ee5aac7e46cb1805', 'US', 10.50],
  ], // beta
  csvError: {
    title: 'Upload Error',
    alertText: 'Please ensure the file type is correct.',
  },
  csvHeaders: [
    { label: 'name', key: 'name' },
    { label: 'pub_app_id', key: 'pub_app_id' },
    { label: 'geo', key: 'geo' },
    { label: 'rate', key: 'rate' },
  ],
};

const publisherBuyouts = {
  buyoutPremium: 125,
  ordinalBuckets: [
    { key: '1', label: '1', value: '1' },
    { key: '2', label: '2', value: '2' },
    { key: '3', label: '3', value: '3' },
    { key: '4', label: '4', value: '4' },
    { key: '5', label: '5', value: '5' },
    { key: '6', label: '6', value: '6' },
    { key: '7', label: '7', value: '7' },
    { key: '8', label: '8', value: '8' },
    { key: '9', label: '9', value: '9' },
    { key: '10', label: '10', value: '10' },
    { key: '11-15', label: '11-15', value: '11-15' },
    { key: '16-20', label: '16-20', value: '16-20' },
    { key: '21-25', label: '21-25', value: '21-25' },
    { key: '26-30', label: '26-30', value: '26-30' },
    { key: '31-40', label: '31-40', value: '31-40' },
    { key: '41-50', label: '41-50', value: '41-50' },
    { key: '51-75', label: '51-75', value: '51-75' },
    { key: '76-100', label: '76-100', value: '76-100' },
    { key: '101+', label: '101+', value: '101+' },
  ],
  rewardedOptions: [
    { key: 'true', label: 'true', value: true },
    { key: 'false', label: 'false', value: false },
  ],
  mutualExclusionColumns: [
    'pub_app_id',
    'ordinalBucket',
    'is_icentivized',
  ],
  validationAlertText: {
    alreadyMultibidding: { title: 'Can\'t convert to Publisher Buyout', alertText: 'Already a Multibidding Campaign' },
    incompleteBuyouts: {
      title: 'Incomplete Publisher Buyouts',
      alertText: 'Please ensure that all Publisher Buyouts have a Bid populated by clicking the "Check" action icon for each Buyout row',
    },
    deletedCampaign: {
      title: 'Already Deleted Campaign',
      alertText: 'You cannot convert a Campaign that has already been Deleted',
    },
    activeCampaign: {
      title: 'Already Active Campaign',
      alertText: 'You cannot convert an already Active Campaign',
    },
    incompleteCampaign: {
      title: 'Incomplete Publisher Buyouts Campaign',
      alertText: 'Make sure you have a start date, an end date past today\'s date, target country selected and completed Publisher Buyouts entered',
    },
    mutualExclusionFailure: {
      title: 'These Publisher Buyout combinations are Not Available',
      alertStub: 'These combinations: ',
    },
    noCPMClearingPrice: {
      title: 'Clearing price for this Publisher Buyout is Not Available. Please check back again in 24 hrs',
      alertStub: 'Buyout Parameters: ',
    },
    selectCountryAndDates: {
      title: 'Please select a start date and end date past today\'s date and a target country',
      alertText: 'They are needed to add Publisher Buyouts',
    },
    hasDuplicateBuyouts: {
      title: 'There were duplicate Publisher Buyouts',
      alertText: 'Duplicates have been deduped',
    },
  },
};

export {
  bidsBudgets,
  bidsBudgetsAlertThresholds,
  bidsBudgetsAlertContent,
  geoBudget,
  multibidding,
  publisherBuyouts,
};

export default {
  bidsBudgets,
  bidsBudgetsAlertThresholds,
  bidsBudgetsAlertContent,
  geoBudget,
  multibidding,
  publisherBuyouts,
};
