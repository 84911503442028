import React from 'react';
import cn from 'classnames';
import IconComponent from '../../../../../components/Icon/Icon';
import './AssetLogBox.scss';

class AssetLogBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      showToggle: false,
      objectFormat: this.getFormattedMessage(props.message),
    };
  }

  /**
   * Identifies the size of BBox and shows toggle if it is needed.
   *
   * @param {*} element
   * @memberof AssetLogBox
   */
  refCallback = (element) => {
    if (!element) {
      return;
    }

    const { height } = element.getBoundingClientRect();

    if (height < 40) {
      return;
    }

    this.setState({ showToggle: true });
  };

  /**
   * changes the state of the toggle.
   *
   * @memberof AssetLogBox
   */
  toggle = () => {
    this.setState(({ expanded }) => ({ expanded: !expanded }));
  };

  /**
   * returns an object with a formatted message in case that is fully formed
   *
   * @param {*} message, string, message serialized.
   * @return {*} null if cannot be formatted, the object if it is compliant.
   * @memberof AssetLogBox
   */
  getFormattedMessage = (message) => {
    if (!message) return null;
    try {
      const objMessage = JSON.parse(message);

      const requiredProperties = [objMessage.title, objMessage.type, objMessage.code, objMessage.description, objMessage.location];

      if (requiredProperties.some((property) => property === undefined)) {
        return null;
      }
      return objMessage;
    } catch (e) {
      return null;
    }
  }

  render() {
    const { objectFormat, expanded } = this.state;
    if (objectFormat) {
      const {
        title, type, description, location,
      } = objectFormat;
      return (
        <div
          className={cn('asset-log-box', 'show-toggle', {
            'warning-box': type === 'warning',
            'error-box': type === 'error',
            'toggled-box': expanded,
          })}
        >
          <i className="material-icons">info</i>
          { expanded
            ? (
              <span>
                {title}
                <br />
                {/* eslint-disable-next-line react/no-danger */}
                <span dangerouslySetInnerHTML={{ __html: description }} />
                <br />
                {location}
              </span>
            )
            : (title)}

          <div className="toggle-box" onClick={this.toggle}>
            {expanded ? <IconComponent.ExpandLess /> : <IconComponent.ExpandMore />}
          </div>

        </div>
      );
    }

    const { showToggle } = this.state;
    const { status, message, buttonConfig } = this.props;

    return (
      <div
        ref={this.refCallback}
        className={cn({
          'asset-log-box': true,
          'warning-box': status === 'warning',
          'error-box': status === 'error',
          'toggled-box': expanded,
          'show-toggle': showToggle,
        })}
      >
        <div className="message-box">
          <i className="material-icons">info</i>
          {' '}
          <span>{message}</span>
          {showToggle && (
          <div className="toggle-box" onClick={this.toggle}>
            {expanded ? <IconComponent.ExpandLess /> : <IconComponent.ExpandMore />}
          </div>
          )}
        </div>
        { buttonConfig
          ? (
            <div>
              <span className="body__link" onClick={buttonConfig.clickHandler}>{buttonConfig.text}</span>
            </div>
          ) : ''}
      </div>
    );
  }
}

export default AssetLogBox;
