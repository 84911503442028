import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import classNames from 'classnames';
import { contains } from 'lib/lib';
import Checkbox from 'components/Form/Checkbox/CheckboxContainer';
import Input from 'components/V2/Input/Input';
import { Tree, TreeNode } from 'components/V2/TreeSelect/TreeSelect';
import { flattenMultiTreeData } from 'templates/MultiTreeSelector/treeUtils';

const MultiTreeSelector = ({
  shouldHideSelectAll,
  data,
  selectedItems,
  generateFilterAttrs,
  onChange,
  onPaste,
  onCheckAll,
  checkedKeyMapper = (checkedItem) => checkedItem.id,
  dataKeyPrefix = '',
  defaultExpandedKeys,
  titleRender,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [flattenedData, setFlattenedData] = useState([]);
  const [eligibleData, setEligibleData] = useState([]);

  useEffect(() => {
    setFlattenedData(flattenMultiTreeData(data));
  }, [data]);

  return (
    <div className="country_options">
      <div className={classNames('search-and-select', { 'select-all-disabled': shouldHideSelectAll })}>
        <div className="input__search">
          <Input.Search
            placeholder="Search"
            value={searchValue}
            onChange={(e) => {
              const { value } = e.target;
              setEligibleData(flattenedData.filter((c) => contains(c.name, value)));
              setSearchValue(value);
            }}
            onPaste={onPaste}
          />
        </div>
        {
          !shouldHideSelectAll && (
            <div className="select-all">
              <Checkbox
                label="Select All"
                some={selectedItems.length > 0 && selectedItems.length < flattenedData.length}
                checked={selectedItems.length > 0}
                onChange={(isChecked) => onCheckAll(isChecked, searchValue)}
              />
            </div>
          )
        }
      </div>
      <div className="options-wrapper">
        {searchValue.length > 0 && eligibleData
          .map((c) => (
            <Checkbox
              key={c.id}
              {...generateFilterAttrs(c)}
            />
          ))}
        {searchValue.length === 0 && (
          <Tree
            onChange={onChange}
            checkedKeys={selectedItems.map(checkedKeyMapper)}
            defaultExpandedKeys={defaultExpandedKeys}
          >
            {data.map((f) => (
              <TreeNode
                key={f.id}
                dataKey={dataKeyPrefix ? `${dataKeyPrefix}-${f.id}` : f.id}
                title={titleRender(f)}
                isLeaf={!f.children?.length}
              >
                {f.children?.map((s) => (
                  <TreeNode
                    key={s.id}
                    dataKey={s.id}
                    title={s.name}
                    isLeaf={!s.children}
                  >
                    {s.children?.map((t) => (
                      <TreeNode
                        key={t.id}
                        dataKey={t.id}
                        title={t.name}
                        isLeaf
                      />
                    ))}
                  </TreeNode>
                ))}
              </TreeNode>
            ))}
          </Tree>
        )}
      </div>
    </div>
  );
};

MultiTreeSelector.propTypes = {
  /**
   * All items for rendering all tree nodes.
   */
  data: PropTypes.arrayOf(PropTypes.object),
  /**
   * Items which should be selected in parent component.
   */
  selectedItems: PropTypes.arrayOf(PropTypes.object),
  /**
   * This generator will return checkbox attributes by current item`.
   *
   * @param {object} event object.
   */
  generateFilterAttrs: PropTypes.func,
  /**
   * This generator will return checkbox attributes by current item`.
   *
   * @param {object} event The event source of the callback.
   */
  onChange: PropTypes.func,
  /**
   * Callback fired when copying text into textarea`.
   *
   * @param {object} event object.
   */
  onPaste: PropTypes.func,
  /**
   * Callback fired when check or uncheck all`.
   *
   * @param {bool} If is all checked.
   * @param {string} searching keyword.
   */
  onCheckAll: PropTypes.func,
  /**
   * Placeholder.
   */
  placeholder: PropTypes.string,
  /**
   * Callback for processing selectedItems when filtering checked items.
   *
   * @param {object} current item in looping.
   */
  checkedKeyMapper: PropTypes.func,
  /**
   * Default expanded tree node
   *
   * @param {array} keys of tree node.
   */
  defaultExpandedKeys: PropTypes.arrayOf(PropTypes.string),
  /**
   * title render
   */
  titleRender: PropTypes.func,
};

MultiTreeSelector.defaultProps = {
  data: [],
  selectedItems: [],
  generateFilterAttrs: {},
  onChange: noop,
  onPaste: noop,
  onCheckAll: noop,
  placeholder: '',
  checkedKeyMapper: (checkedItem) => checkedItem.id,
  defaultExpandedKeys: [],
  titleRender: (node) => node.name,
};

export default MultiTreeSelector;
