import {
  every, isNumber, map, get, isEqual,
} from 'lodash';
import { mean, std } from 'mathjs';
import { diffCreatives } from 'lib/helpers/creatives/creativeIndex';
import triggerConfirm from 'components/Modals/ConfirmAction/triggerConfirm';
import { SkResetAlertHeader, SkResetAlertText } from 'services/admin/views/campaigns/constants';
import { isCampaignObjectiveDynamic } from 'lib/capabilities/campaigns';
import {
  BUDGET_OBJECTIVE_CPE, BUDGET_OBJECTIVE_CPPU, BUDGET_OBJECTIVE_ROAS, BUDGET_OBJECTIVE_STATIC,
} from 'app/constants/campaign';
import config from '../../config';
import getAppConfig from '../getAppConfig/getAppConfig';
import {
  chunk, isHexId, isEmptyArray, isEmptyObject, isArray,
} from '../../lib';
import Application from '../../../models/Application';
import { parseCurrency, format } from '../../currency';
import { getMaxLimit } from '../../budgetLimits';

const { jsonExampleData } = config.get('multibidding');
const { editColumns } = config.get('multibidding');
const longTailGeos = config.get('countryData.longTailGeos');
const bidsBudgetsAlertContent = config.get('bidsBudgetsAlertContent');
const maxBidForLongTail = config.get('countryData.maxBidForLongTail');

const getRateTypes = () => getAppConfig('account', (a) => a.get('settings.campaigns.budget.rateTypes'), []);

const getRate = (minOrMax, campaign) => {
  const type = campaign.get('budget.type');
  let campaignRateTypes = [];
  if (!campaign.get('account.accountConfig')) {
    campaignRateTypes = getRateTypes();
  } else {
    campaignRateTypes = campaign.get('account.accountConfig').campaigns.budget.rateTypes;
  }
  const confIndex = campaignRateTypes.findIndex((r) => r.name === type);
  return get(campaignRateTypes, [confIndex, minOrMax]);
};

const getBidLimits = (campaign, shouldUseDefault = false) => {
  const errMessage = campaign.get('multibidding.bidErrorMessage');
  const isUploadingMultiBidding = campaign.get('multibidding.isUploading');
  const multiBiddingRange = campaign.get('multibidding.range') || {};
  const { lowerBound, upperBound } = multiBiddingRange;
  const shouldUseMultiBiddingThreshold = Object.keys(multiBiddingRange).length > 0 && !isUploadingMultiBidding;

  const defaultMinThreshold = getRate('min', campaign);
  const defaultMaxThreshold = getRate('max', campaign);

  const min = (lowerBound && shouldUseMultiBiddingThreshold && lowerBound >= defaultMinThreshold && !shouldUseDefault)
    ? lowerBound
    : defaultMinThreshold;

  const max = (upperBound && shouldUseMultiBiddingThreshold && upperBound <= defaultMaxThreshold && !shouldUseDefault)
    ? upperBound
    : defaultMaxThreshold;

  return { max, min, rangeErrors: errMessage };
};

const getBudgetLimits = (type, campaign = null) => {
  let min = getAppConfig('account', (a) => a.get(`settings.campaigns.budget.${type}.min`), 0);
  const max = getAppConfig('account', (a) => a.get(`settings.campaigns.budget.${type}.max`), 0);
  const maxLimit = getMaxLimit(type);

  if (campaign && campaign.raw('budget.spent') && type === 'total') {
    min = Math.max(min, campaign.raw('budget.spent'));
  }

  return {
    min,
    max: Math.min(max, maxLimit),
  };
};

// sets the limit and validations for new campaign creation in mission control
const getNewCampBudgetLimit = (type, campaign = null) => {
  const budget = campaign.get('account.accountConfig').campaigns.budget[type];
  let { min } = budget;
  const { max } = budget;
  const maxLimit = getMaxLimit(type);

  if (campaign.raw('budget.spent') && type === 'total') {
    min = Math.max(min, campaign.raw('budget.spent'));
  }

  return {
    min,
    max: Math.min(max, maxLimit),
  };
};

const dailyGeoOption = (campaign, isAdmin) => {
  const daily = isAdmin
    ? getNewCampBudgetLimit('daily', campaign)
    : getBudgetLimits('daily', campaign);

  const { min } = daily;
  const max = daily.max || 100000;
  return {
    min,
    max,
    defaultRate: Number(min) === 0 ? 0 : 100,
  };
};

const setGeoBudget = (campaign, isAdmin) => {
  const geos = campaign.get('budget.daily_spend_limit_geos');
  const { min } = dailyGeoOption(campaign, isAdmin);
  const rate = min === 0 || min === '0' ? 0 : 100;
  const newGeos = campaign.get('targeting.geo.countries').map((country) => {
    const reservedGeo = geos.find((geo) => geo.geo === country.code);
    return {
      geo: country.code,
      rate: reservedGeo && reservedGeo.rate >= rate ? reservedGeo.rate : rate,
    };
  });
  campaign.set('budget.daily_spend_limit_geos', newGeos);
};

// TODO: This needs to be re-written with less complexity
const makeEditObj = ({ data, columns }) => data.reduce((values, b) => ({
  ...values,
  [b.pub_app_id + b.geo]: columns.reduce((obj, c) => ({ ...obj, [c.key]: b[c.key] }), {}),
}), {});

const getPubAppDetails = async (ids) => {
  const allResults = [];
  const chunks = chunk(ids, 100);
  chunks.forEach((chunkedIds) => {
    allResults.push(Application.getAllPubs({ idIn: chunkedIds, perPage: 100 }));
  });
  //All pub Details
  const results = (await Promise.all(allResults))
    .map((result) => Application.make(result.response))
    .reduce((a, p) => [...a, ...p], []);

  const foundIDs = results.map((r) => r.get('id'));
  // check for invalid pub ids
  const invalidPubIDs = ids.filter((id) => foundIDs.indexOf(id) === -1);
  // update valid publisher app id and name
  const pubValidObj = results.reduce((obj, r) => ({ ...obj, [r.get('id')]: r.get('name') }), {});
  pubValidObj['*'] = '*';
  return {
    pubValidObj,
    invalidPubIDs,
  };
};

const validatePublisherRate = (rate, min, max) => !Number.isNaN(rate) && rate >= Number(min) && rate <= Number(max);

const validateGeoRate = (countries, geo) => {
  const index = countries.findIndex((country) => country.code === geo);
  if (index === -1) {
    return false;
  }
  return true;
};

const isValidLongTailBid = (bid) => bid <= maxBidForLongTail;

const isValidLongTailGeo = (campaign, geo, rate) => {
  if (campaign.isPrePay() && campaign.get('budget.type') === 'CPI' && campaign.get('targeting.geo.region') === 'country') {
    if (!geo || geo === '*') {
      return true;
    }
    if (longTailGeos.includes(geo.toUpperCase())) {
      return isValidLongTailBid(rate);
    }
  }
  return true;
};

function mergePublisherRates(existingRates, uploadRates) {
  const rates = [...uploadRates];
  const uploadRatesMap = {};
  uploadRates.forEach((rate) => {
    uploadRatesMap[`${rate.pub_app_id}-${rate.geo}`] = true;
  });
  existingRates.forEach((rate) => {
    if (uploadRatesMap[`${rate.pub_app_id}-${rate.geo}`] !== true) {
      rates.push(rate);
    }
  });
  return rates;
}

const validateMultiBiddingEntries = async (campaign, pubValues, override) => {
  const invalidHexIDs = [];
  const duplicatePubIDs = [];
  const ids = [];
  const invalidRateEntry = [];
  const invalidGeoEntry = [];
  const invalidLongTailGeos = [];
  const geoIds = {}; // beta
  let validIdRateEntry = [];
  const { max, min } = getBidLimits(campaign);
  const countries = campaign.get('targeting.geo.countries');

  const validationErrors = async () => {
    try {
      // pubValues.forEach((obj, index) => { // beta
      pubValues.forEach((obj) => {
        // Fix issue: check duplicate id when the same id has same geo
        const geos = geoIds[obj.pub_app_id] || [];
        // check for valid Hex ID
        if (isHexId(obj.pub_app_id) || obj.pub_app_id === '*') {
          // check for duplicate id
          if (obj.pub_app_id === '*') {
            if (typeof obj.geo === 'undefined' || obj.geo === '') {
              invalidHexIDs.push(obj.pub_app_id);
            }
            if (geos.includes(obj.geo)) {
              duplicatePubIDs.push(obj.pub_app_id);
            }
          } else if (ids.includes(obj.pub_app_id)) {
            if (geos.includes(obj.geo)) {
              duplicatePubIDs.push(obj.pub_app_id);
            }
          } else {
            ids.push(obj.pub_app_id);
          }
          geos.push(obj.geo);
          geoIds[obj.pub_app_id] = geos;
        } else if (obj.pub_app_id || (obj.pub_app_id === '' && obj.rate)) {
          invalidHexIDs.push(obj.pub_app_id);
        }
      });

      const { pubValidObj, invalidPubIDs } = await getPubAppDetails(ids);
      validIdRateEntry = await pubValues.reduce((result, mapObj) => {
        let obj = {};

        if (mapObj.pub_app_id in pubValidObj || mapObj.pub_app_id === '*') {
          if (validatePublisherRate(mapObj.rate, min, max)) {
            if (mapObj.geo) {
              if (validateGeoRate(countries, mapObj.geo)) {
                const key = mapObj.pub_app_id;
                // Add pub Name if the entry is valid
                obj = { ...mapObj, name: pubValidObj[key] };
                result.push(obj);
              } else {
                // Invalid Geo
                invalidGeoEntry.push(mapObj.geo);
              }
            } else {
              const key = mapObj.pub_app_id;
              // Add pub Name if the entry is valid
              obj = { ...mapObj, name: pubValidObj[key] };

              result.push(obj);
            }
          } else {
            // check if rate is within limit
            invalidRateEntry.push({ ...mapObj, min, max });
          }
        }

        // valid long tail geo
        if (!isValidLongTailGeo(campaign, mapObj.geo, mapObj.rate)) {
          invalidLongTailGeos.push(mapObj.geo);
        }

        return result;
      }, []);

      const invalidEntries = {
        invalidHexIDs,
        duplicatePubIDs,
        invalidPubIDs,
        invalidRateEntry,
        invalidGeoEntry,
        invalidLongTailGeos: [...new Set(invalidLongTailGeos)],
      };

      const existingRates = campaign.get('budget.publisher_rates');

      const mergedRates = override ? validIdRateEntry : mergePublisherRates(existingRates, validIdRateEntry);
      // Exit early if there are no errors
      if (every(invalidEntries, (x) => isEmptyArray(x)) && !isEmptyArray(validIdRateEntry)) {
        return { mergedRates };
      }

      // Deal with the errors
      const keys = Object.keys(invalidEntries);
      const errors = keys.reduce((e, key) => (!isEmptyArray(invalidEntries[key]) ? { ...e, [key]: invalidEntries[key] } : e), {});
      return {
        mergedRates,
        csvErrors: errors,
        validPubIDs: validIdRateEntry,
      };
    } catch (ex) {
      // error out
    }
    return {};
  };

  return validationErrors();
};

const bidLowMessage = (lowerBound) => `Your default bid is too low. Please update your default bid to a minimum of ${format(lowerBound)}.`;

const bidHighMessage = (upperBound) => `Your default bid is too high. Please update your default bid to a maximum of ${format(upperBound)}.`;

// If mean - std <= default bid <= mean + std condition is not met, then we will error the default bid field
// For cases where there are fewer than 10 multibids, if the absolute value or average of the mulitbid(s) is greater than 5X the default bid,
// we should show an error to the user to correct the default bid and not allow the campaign to save.
// so:
// if rateEntries.length < 10, (mean/5) <= defaultBid should hold
// if rateEntries.length >= 10, (mean-std) <= defaultBid <= (min+std) should hold
const validateBidRange = (campaign, rateEntries) => {
  const defaultBid = parseCurrency(campaign.get('budget.bid'));
  // if csv has an empty line at the end we don't want to include it
  const rates = map(rateEntries, 'rate').filter(isNumber);

  const invalidResult = (message) => ({
    isValid: false,
    csvErrors: { rateOutOfRange: message },
  });
  const validResult = { isValid: true };

  if (!config.get('multibidding.bidRangeValidationEnabled')) {
    return validResult;
  }

  if (!rates.length) {
    campaign.set('multibidding.bidErrorMessage', null);
    return validResult;
  }
  const ratesMean = mean(rates);

  if (rates.length < 10) {
    const lowerBound = ratesMean / 5;
    campaign.set('multibidding.range', { lowerBound });
    if (defaultBid < lowerBound) {
      campaign.set('multibidding.bidErrorMessage', { lowerBoundMsg: bidLowMessage(lowerBound) });
      return invalidResult(bidLowMessage(lowerBound));
    }
    campaign.set('multibidding.bidErrorMessage', null);
    return validResult;
  }

  const ratesStd = std(rates, 'uncorrected');
  const lowerBound = ratesMean - ratesStd;
  const upperBound = ratesMean + ratesStd;
  campaign.set('multibidding.range', { lowerBound, upperBound });

  if (defaultBid < lowerBound) {
    campaign.set('multibidding.bidErrorMessage', {
      lowerBoundMsg: bidLowMessage(lowerBound),
      upperBoundMsg: bidHighMessage(upperBound),
    });
    return invalidResult(bidLowMessage(lowerBound));
  }

  if (defaultBid > upperBound) {
    campaign.set('multibidding.bidErrorMessage', {
      lowerBoundMsg: bidLowMessage(lowerBound),
      upperBoundMsg: bidHighMessage(upperBound),
    });
    return invalidResult(bidHighMessage(upperBound));
  }

  campaign.set('multibidding.bidErrorMessage', null);
  return validResult;
};

const addMultiBiddingRates = async (campaign, rates, setupEditValues, override, isMissionCtrl) => {
  const expectedHeaders = ['pub_app_id', 'rate', 'name', 'geo'];
  const betaHeaders = ['pub_app_id', 'rate', 'name']; // beta
  if (!isArray(rates) || rates.length === 0) {
    campaign.set('budget.publisher_rates', []);
    return {};
  }
  const csvHeaders = Object.keys(rates[0]);
  const unexpectedHeaders = csvHeaders.filter((h) => expectedHeaders.indexOf(h) === -1);
  // const missingHeaders = expectedHeaders.filter(h => csvHeaders.indexOf(h) === -1);
  const missingHeaders = betaHeaders.filter((h) => csvHeaders.indexOf(h) === -1); // beta
  const headersError = {};
  const ratesError = {};
  if (missingHeaders.length > 0) {
    headersError.missingHeaders = missingHeaders;
  }

  if (unexpectedHeaders.length > 0) {
    const unexpectedHeadersWithQuotes = unexpectedHeaders.map((i) => `"${i}"`);
    headersError.unexpectedHeaders = unexpectedHeadersWithQuotes;
  }

  const pubRates = rates.filter((i) => !jsonExampleData.some((j) => j.pub_app_id === i.pub_app_id));
  const response = await validateMultiBiddingEntries(campaign, pubRates, override).then((result) => {
    const { mergedRates, csvErrors, validPubIDs } = result;

    if (mergedRates.length > config.get('multibidding.maxBidding')) {
      ratesError.rateLimitReached = [bidsBudgetsAlertContent.rateLimitReached.alertText];
    }

    // bid range validation
    if (!csvErrors || isEmptyObject(csvErrors)) {
      validateBidRange(campaign, rates);
    }

    const validationErrors = { ...headersError, ...csvErrors };
    if (isEmptyObject(validationErrors) && validPubIDs) {
      const { min = 0, max = 0 } = getBidLimits(campaign);
      if (isMissionCtrl) {
        const validPubRates = validPubIDs.filter((p) => !!validatePublisherRate(p.rate, min, max));
        setupEditValues({ campaignData: campaign, data: validPubRates });
        return {};
      }
      const enrichedEditColumns = editColumns.map((c) => ({
        ...c, validateFunc: validatePublisherRate, min, max,
      }));
      setupEditValues({ data: pubRates, columns: enrichedEditColumns });
      return {};
    }
    return { campaign, mergedRates, csvErrors: { ...validationErrors, ...ratesError } };
  });
  return response;
};

// const getNewDailyLimits = () => getNewCampBudgetLimit('daily');
// const getNewTotalLimits = () => getNewCampBudgetLimit('total');
const getDailyLimits = () => getBudgetLimits('daily');
const getTotalLimits = () => getBudgetLimits('total');

const validateBidding = (campaign) => {
  // validate bid budget.
  if (campaign.get('budget.objective.type') === BUDGET_OBJECTIVE_STATIC
    && !campaign.validateBid(getBidLimits(campaign), campaign.raw('budget.bid'))) {
    return false;
  }
  // // validate geo targeting.
  if (!campaign.validateGeo()) {
    return false;
  }
  // validate daily budget.
  if (!campaign.validateDaily(getDailyLimits(), campaign.raw('budget.daily'), campaign.raw('budget.daily_spend_limit_type'))) {
    return false;
  }
  // validate total budget.
  if (!campaign.validateBid(getTotalLimits(), campaign.raw('budget.total'))) {
    return false;
  }
  // validate dynamic (ROAS or CPPU) IAP fields.
  if (isCampaignObjectiveDynamic(campaign)) {
    let dynamicCampaignValidationResult;

    switch (campaign.get('budget.objective.type')) {
      case BUDGET_OBJECTIVE_ROAS:
        dynamicCampaignValidationResult = campaign.validateBudgetRoasIap();
        break;
      case BUDGET_OBJECTIVE_CPPU:
        dynamicCampaignValidationResult = campaign.validateBudgetCPPUIap();
        break;
      case BUDGET_OBJECTIVE_CPE:
        dynamicCampaignValidationResult = campaign.validateBudgetCPEIap();
        break;
      default:
        dynamicCampaignValidationResult = {};
    }

    const {
      bidDefault,
      bidMax,
      targetReturn,
      cpeTargetEventName,
    } = dynamicCampaignValidationResult || {};
    const hasError = bidDefault || bidMax || targetReturn || cpeTargetEventName;
    return !hasError;
  }
  return true;
};

const getPlacementAllowList = (campaign) => (
  {
    banner: campaign.get('targeting.placement_type_whitelist.banner'),
    fullscreen: campaign.get('targeting.placement_type_whitelist.fullscreen'),
    mrec: campaign.get('targeting.placement_type_whitelist.mrec'),
  }
);

const checkCampaignUpdates = (campaign, originalCampaign) => {
  if (!campaign || !originalCampaign) {
    return false;
  }

  const c = campaign.toServerObject();
  const oc = originalCampaign;

  if (!oc.is_ab_testing || !c.is_ab_testing) {
    return false;
  }

  if (
    diffCreatives(c.creatives, oc.creatives)
    || diffCreatives(c.creatives_b, oc.creatives_b)
  ) {
    return true;
  }

  const budgetRes = Object.keys(c.budget).some((key) => {
    if (key === 'daily') {
      return false;
    }

    return !isEqual(c.budget[key], oc.budget[key]);
  });

  return budgetRes || Object.keys(c).some((key) => {
    if (
      ['budget', 'status'].includes(key)
    ) {
      return false;
    }

    return !isEqual(c[key], oc[key]);
  });
};

const processCampaignSave = (campaign, originalCampaign, cb) => {
  if (campaign.get('is_ab_testing') && checkCampaignUpdates(campaign, originalCampaign)) {
    triggerConfirm({
      type: 'RESET_SKAN_CONFIRM_ACTION',
      header: SkResetAlertHeader,
      message: SkResetAlertText,
      confirmText: 'Save & Reset',
      cancelText: 'Cancel',
      onConfirm: () => {
        cb();
      },
    });
  } else {
    cb();
  }
};

const getSelectedCarriers = (campaign) => {
  const isCarrierEnabled = campaign.get('settings.carrier_targeting');

  if (!isCarrierEnabled) {
    return [];
  }

  const carrierAllowList = campaign.get('targeting.carrier.carrierAllowList');
  const carrierDenyList = campaign.get('targeting.carrier.carrierDenyList');

  if (carrierAllowList.length > 0) {
    return carrierAllowList;
  }
  if (carrierDenyList.length > 0) {
    return carrierDenyList;
  }

  return [];
};

export {
  validateGeoRate,
  addMultiBiddingRates,
  dailyGeoOption,
  getBidLimits,
  getBudgetLimits,
  getRate,
  getNewCampBudgetLimit,
  getRateTypes,
  getPlacementAllowList,
  makeEditObj,
  validateBidding,
  validatePublisherRate,
  setGeoBudget,
  isValidLongTailBid,
  isValidLongTailGeo,
  bidLowMessage,
  bidHighMessage,
  validateBidRange,
  checkCampaignUpdates,
  processCampaignSave,
  getSelectedCarriers,
};
