import React, { useEffect, useState } from 'react';
import { Table } from 'components/V2';
import { GROW_CREATIVE_METRIC_KEY, CTRL_CREATIVE_METRIC_KEY } from 'lib/cache/constant';
import { defaultSort } from 'services/Templates/Creatives/List/constants';
import { cleanupNonExistentColumnsFromCache } from 'components/V2/List/ListFilter/helper';
import {
  initMetrics,
  execTableAction,
  makeTableActionList,
  metricsGroup,
  attributeMetrics,
  columnsFromMetrics,
  allColumns,
} from './helper';

const List = ({
  router,
  refreshList,
  isMissionControl = false,
  props: {
    authUser,
    creatives = [],
    loadingCreatives,
    pagination = {
      page: 1, pages: 0, perPage: 10, total: 0,
    },
  } = {},
  actions: {
    execChangeCreativeStatus,
  },
  sortCache,
  metricCache,
  pageSizeCache,
}) => {
  const [metrics, setMetrics] = useState([]);
  const [qrCodeCreativeId, setQrCodeCreativeId] = useState(null);

  useEffect(() => {
    cleanupNonExistentColumnsFromCache({
      metricCache,
      sortCache,
      columns: allColumns(isMissionControl, authUser, execChangeCreativeStatus),
    });

    setMetrics(initMetrics(metricCache, attributeMetrics(isMissionControl)));
  }, []);

  return (
    <Table
      className="table-with-total-container"
      actionClassName="creatives_list_action"
      maxHeight="100%"
      manual
      resizable
      loading={loadingCreatives}
      showPagination
      actionable
      page={pagination.page}
      total={pagination.total}
      pageSize={pagination.perPage}
      data={creatives}
      columns={columnsFromMetrics(metrics, isMissionControl, qrCodeCreativeId, setQrCodeCreativeId, authUser, execChangeCreativeStatus)}
      actionList={(e) => makeTableActionList(e.creative, isMissionControl, setQrCodeCreativeId)}
      defaultSort={sortCache.get()[0] || defaultSort}
      metricType={isMissionControl ? CTRL_CREATIVE_METRIC_KEY : GROW_CREATIVE_METRIC_KEY}
      metricGroup={metricsGroup(isMissionControl)}
      metricUserId={authUser.id}
      metricOverlayHeight={560}
      onMetricsChange={setMetrics}
      onActionChange={(row, key) => execTableAction(key, row, router.history, execChangeCreativeStatus, isMissionControl, setQrCodeCreativeId)}
      onPageSizeChange={(page, perPage) => {
        if (loadingCreatives) {
          return;
        }
        pageSizeCache.save(perPage);
        refreshList({ page, perPage });
      }}
      onSorted={(v) => {
        sortCache.save(v);
        refreshList({ page: pagination.page });
      }}
    />
  );
};

export default List;
