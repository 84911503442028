import triggerConfirm from '../ConfirmAction/triggerConfirm';
import { SkAlertHeader, SkAlertText } from '../../../services/admin/views/campaigns/constants';

const triggerSkanAlert = () => triggerConfirm({
  type: 'SHOW_SKAN_ALERT_ACTION',
  header: SkAlertHeader,
  message: SkAlertText,
  onConfirm: () => {},
  confirmOnly: true,
});

export default triggerSkanAlert;
