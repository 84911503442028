import React from 'react';
import classNames from 'classnames';
import Button from '../../components/Button/Button';
import Link from '../../components/Link/Link';
import { Label, Dropdown } from '../../components/V2';

const InputGroup = ({
  id,
  children,
  description = '',
  flair,
  expandable = false,
  title = 'No title',
  toggleExpando,
  style = {},
  className = '',
  link,
  info,
  icon,
  infoMessage,
  linkText,
  tipMessage,
}) => (
  <div className={`template__input-group ${className}`} style={style} id={id}>
    <div className="title">
      <p className="h4">{title}</p>
      {link && (
        <Link to={link} external>
          {linkText || 'Help desk link'}
        </Link>
      )}
      {flair && (
        <div className="title-flair">
          {flair}
        </div>
      )}
      {info && (
        <Dropdown
          className="template__input-group-dropdown"
          trigger="hover"
          horizontalOffset={16}
          renderOverlay={() => (
            <span className="info-tooltip">
              {infoMessage}
            </span>
          )}
        >
          <i className="material-icons">{icon}</i>
        </Dropdown>
      )}
    </div>
    <div className="description">
      <span className="p2">{description}</span>
    </div>
    {children}
    {expandable && (
      <div className="expando-blocker" />
    )}
    {expandable && (
      <div className="expando" onClick={toggleExpando}>
        <i className="material-icons">filter_list</i>
      </div>
    )}
    {tipMessage && (
      <div className="tip-message">
        <span>{tipMessage}</span>
      </div>
    )}
  </div>
);

const Buttons = ({
  cancelUrl = '', cancelText = 'Go Back', confirm = null, confirmText = 'Save', confirmDisabled = false, onClickCancel,
}) => (
  <div className="input-buttons">
    {cancelUrl && (
      <Link to={cancelUrl}>
        <Button inverse>{cancelText}</Button>
      </Link>
    )}
    {onClickCancel && (
      <Button onClick={onClickCancel} inverse>{cancelText}</Button>
    )}
    {confirm && (
      <Button disabled={confirmDisabled} onClick={confirm}>{confirmText}</Button>
    )}
  </div>
);

const Field = ({
  name = '',
  description = '',
  link,
  children,
  badgeGraphic = null,
  adminOnly = false,
  linkText,
  linkUrl = null,
  className = '',
}) => {
  let desc = '';
  try {
    desc = description.split('\\n').map((item, i) => (
      <p className="p6" key={i}>
        {item}
        <br />
      </p>
    ));
  } catch {
    desc = description;
  }
  return (
    <div className={classNames('input-field', className)}>
      {badgeGraphic && (
        <img className="badge" src={badgeGraphic} />
      )}
      <div className="label">
        <div className="name">
          <p className="p1">{name}</p>
        </div>
        <div className="p6">{desc}</div>
        {adminOnly && (
          <div className="pill">
            <Label type="option">Admin Only Feature</Label>
          </div>
        )}
        {linkUrl ? (
          <Link to={linkUrl} className="link" external>
            {linkText}
          </Link>
        ) : (
          link
        )}
      </div>
      <div className="input-area">
        {children}
      </div>
    </div>
  );
};

InputGroup.Buttons = Buttons;
InputGroup.Field = Field;

export default InputGroup;
