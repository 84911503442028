import React from 'react';
import isAscii from 'validator/lib/isAscii';
import isEmail from 'validator/lib/isEmail';
import { constants } from 'accesscontrol';
import Alert from '../../../../../components/Alert/Alert';
import Button from '../../../../../components/Button/Button';
import Link from '../../../../../components/Link/Link';
import Select from '../../../../../components/Form/Select/SelectContainer';
import Text from '../../../../../components/Form/Text/TextContainer';
import Group from '../../../../../templates/input-group/InputGroupContainer';
import { errorMessages } from '../../../../Templates/Users/AddMember/contants';
import { canAssignCustomerLevelRoles } from '../../../../../lib/helpers/authUser';

const { Field } = Group;
const { Option } = Select;

const InvalidInput = ({ message }) => (
  <div className="validation">
    <Alert>{message}</Alert>
  </div>
);

const Invite = ({ user, props: { validations }, actions: { inviteUser } }) => {
  const name = user.get('name');
  const email = user.get('email');
  const isNameInvalid = name && (!isAscii(name) || validations.invalidName);
  const isEmailInvalid = email && (!isEmail(email) || validations.invalidEmail);

  return (
    <div className="views__team__invite">
      <Group title="Invite Team Member">
        <Field name="Full name" description="The user's full name">
          <Text style={{ fontSize: 14 }} noIcon value={user.get('name')} onChange={(v) => user.set('name', v)} />
          {isNameInvalid && (
            <InvalidInput
              message="Please enter valid full name"
            />
          )}
        </Field>
        <Field name="Email" description="The user's email address">
          <Text style={{ fontSize: 14 }} noIcon value={user.get('email')} onChange={(v) => user.set('email', v)} />
          {isEmailInvalid && (
            <InvalidInput
              message="Please enter valid email address"
            />
          )}
          {validations && (
            <div className="validation">
              {Object.keys(validations).map((key) => <Alert key={key}>{errorMessages[key]}</Alert>)}
            </div>
          )}
        </Field>
        <Field name="Access" description="Access level for this user">
          <Select value={user.raw('role')} onChange={(v) => user.set('role', v)}>
            {canAssignCustomerLevelRoles() && <Option value={constants.CustomerAccountAdmin}>Customer Account Admin</Option>}
            {canAssignCustomerLevelRoles() && <Option value={constants.CustomerAccountManager}>Customer Account Manager</Option>}
            <Option value={constants.CustomerAccountReader}>Customer Account Reader</Option>
          </Select>
        </Field>
        <div className="invite-button">
          <Link to="/team">
            <Button cancel>Go back</Button>
          </Link>
          <Button
            disabled={!user.raw('name') || !user.raw('email') || !user.raw('role')}
            onClick={() => inviteUser(user)}
          >
            Invite
          </Button>
        </div>
      </Group>
    </div>
  );
};

export default Invite;
