export const CSV_FILTER_KEY_APPLICATION = 'application';
export const CSV_FILTER_KEY_CAMPAIGN = 'campaign';
export const CSV_FILTER_KEY_CREATIVE = 'creative';
export const CSV_FILTER_KEY_COUNTRY = 'country';
export const CSV_FILTER_KEY_DATE = 'date';
export const CSV_FILTER_KEY_PLATFORM = 'platform';
export const CSV_FILTER_KEY_PUBLISHER = 'publisher';

export const dimensions = [
  { id: CSV_FILTER_KEY_APPLICATION, text: 'Download by Application' },
  { id: CSV_FILTER_KEY_CAMPAIGN, text: 'Download by Campaign' },
  { id: CSV_FILTER_KEY_COUNTRY, text: 'Download by Country' },
  { id: CSV_FILTER_KEY_CREATIVE, text: 'Download by Creative' },
  { id: CSV_FILTER_KEY_DATE, text: 'Download by Date' },
  { id: CSV_FILTER_KEY_PLATFORM, text: 'Download by Platform' },
  { id: CSV_FILTER_KEY_PUBLISHER, text: 'Download by Publisher Application' },
];
