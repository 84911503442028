import React from 'react';
import DeviceUploader from './DeviceUploader';
import './deviceUploader.scss';

class DeviceUploaderContainer extends React.Component {
  constructor(p) {
    super(p);
    this.state = {};
  }

  render() {
    return (
      <DeviceUploader {...this.props} />
    );
  }
}

export default DeviceUploaderContainer;
