const initVisualQA = {
  endToEnd: false,
  landscape: false,
  portrait: false,
  noCloseButton: false,
  download: '',
  complete: '',
  feedback: '',
  result: '',
};

const initValidVariations = {
  aggressive: false,
  complete: false,
  off: false,
};

const initFullQA = {
  functionality: {
    endToEnd: false,
    pauseAndResume: false,
    audio: false,
    other: '',
  },
  content: {
    responsive: false,
    playable: false,
    performance: false,
    other: '',
  },
  asoi: {
    aggressive: false,
    off: false,
    complete: false,
    other: '',
  },
  general: {
    recordings: [],
    other: '',
  },
};

export {
  initVisualQA,
  initValidVariations,
  initFullQA,
};
