import React from 'react';
import classNames from 'classnames';
import Link from '../../Link/Link';
import Tooltip from '../../Tooltip/TooltipContainer';
import './creativeStatus.scss';

const ServingStatus = ({
  complete, link, rejected, onClick = () => {}, text,
}) => {
  if (complete) {
    return null;
  }
  let renderInner;
  if (link) {
    renderInner = (
      <div className="text servingMessage" onClick={onClick}>
        <p className="p6">{text}</p>
        <Link to={link}>here.</Link>
      </div>
    );
  } else {
    renderInner = (
      <div className={`text servingMessage ${rejected ? 'nolink' : null}`} onClick={onClick}>
        <p className="p6">{text}</p>
      </div>
    );
  }
  return (
    <div className="components__creatives__serving_status">
      <div className={`icon icon--${complete ? 'green' : 'red'}`}>
        <i className="material-icons">{complete ? 'check_circle' : 'error_outline'}</i>
      </div>
      {renderInner}
    </div>
  );
};

const InnerContent = ({
  creative,
  disableLinks,
  editUrl,
  textOverride,
}) => {
  const isEligible = creative.isEligibleToServe();
  const text = typeof textOverride === 'string'
    ? textOverride
    : creative.get('name') || creative.getFriendlyName();

  return (
    <div className="components__creatives__serving_status_wrapper">
      <div className={
        classNames(
          'v2_component_pulsation-icon',
          `v2_component_pulsation-${isEligible ? 'active' : 'inactive'}`,
        )
      }
      />
      <Link to={disableLinks ? '' : editUrl}>
        <p className="body__link">{text}</p>
      </Link>
    </div>
  );
};

const creativeApprovalsLink = (
  <a className="link" target="blank" href="https://support.vungle.com/hc/en-us/articles/360035210552">
    Learn more
  </a>
);

const ServingStatusComponent = ({
  creative,
  changeStatus,
  disableLinks = false,
  editUrl,
  resource = 'Creative',
  showTooltip = true,
  innerContentTextOverride,
  children,
}) => {
  const renderInnerContent = () => {
    if (React.Children.count(children) > 0) {
      return children;
    }
    return (
      <InnerContent
        creative={creative}
        disableLinks={disableLinks}
        editUrl={editUrl}
        textOverride={innerContentTextOverride}
      />
    );
  };

  if (!showTooltip || creative.isEligibleToServe()) {
    return renderInnerContent();
  }

  return (
    <Tooltip
      newStyle
      title="Inactive"
      body={(
        <div>
          <ServingStatus
            complete={creative.get('status') === 'active'}
            icon={creative.get('status') === 'active' ? 'pause' : 'play_arrow'}
            text={(
              <>
                {`${resource} is Paused.`}
                {
                  !creative.get('hasUnapprovedPlayable')
                  && <span className="link" onClick={changeStatus}>Unpause here</span>
                }
              </>
            )}
          />
          {creative.get('admin_status') !== 'rejected' && (
            <ServingStatus
              complete={creative.get('admin_status') === 'approved'}
              icon="edit"
              text={(
                <>
                  {`${resource} is awaiting approval.`}
                  {creativeApprovalsLink}
                </>
              )}
            />
          )}
          {!!creative.get('hasUnapprovedPlayable') && (
            <ServingStatus
              complete={false}
              icon="edit"
              text="Child Playable (.zip format) asset is under review."
            />
          )}
          {creative.get('admin_status') === 'rejected' && (
            <ServingStatus
              rejected
              complete={false}
              icon="edit"
              text={(
                <>
                  {`${resource} is Rejected.`}
                  {creativeApprovalsLink}
                </>
              )}
            />
          )}
        </div>
      )}
      padding={8}
    >
      <div>{renderInnerContent()}</div>
    </Tooltip>
  );
};

export default ServingStatusComponent;
