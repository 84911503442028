import _ from 'lodash';
import React, { splashStart, splashFinish } from '../../../app/app';
import Application from '../../../models/Application';
import Account from '../../../models/Account';
import AllowDenyList from './AllowDenyList';
import {
  dedupe, isHexId, split, chunk,
} from '../../../lib/lib';
import './allowDenyList.scss';

const IDQUERYLIMIT = 100;
const IDMAXLENGTH = 500;

class AllowDenyListContainer extends React.Component {
  constructor(p) {
    super(p);
    this.state = {
      visible: false,
      throwError: false,
      errorMsg: '',
    };
  }

  componentDidUpdate() {
    if (this.state.visible) {
      this.fadeOut = setTimeout(() => {
        this.setState(() => ({ visible: false }));
      }, 2000);
    }
  }

  processContent = (contentType) => async (value, onPasteSelect, excludedApplicationIDs = [],
    excludedAccountIDs = [], idMaxLength = IDMAXLENGTH, idQueryLimit = IDQUERYLIMIT) => {
    const ids = _.uniq(split(value).filter((id) => isHexId(id)));
    this.setState({ throwError: false, errorMsg: '' });
    if (ids.length === 0) {
      this.setState({
        throwError: true,
        errorMsg: `Expect 1 to ${idMaxLength} comma separated IDs`,
      });
      return;
    }
    if (ids.length > idMaxLength) {
      this.setState({
        throwError: true,
        errorMsg: `Exceeded maximum of ${idMaxLength} pasted IDs`,
      });
      return;
    }
    const idChunk = chunk(ids, idQueryLimit);
    splashStart();
    const idChunkLength = idChunk.length;
    let responseValues = [];
    const maxParallel = 5;
    let promises = [];
    for (let i = 0; i < idChunkLength; i++) {
      if (contentType === 'account') {
        promises.push(Account.getAll({
          accountType: 'publisher', fields: ['id', 'name'], idIn: idChunk[i], perPage: idMaxLength,
        }));
      } else {
        promises.push(Application.getAllPubs({ fields: ['id', 'name', 'parentAccount'], idIn: idChunk[i], perPage: idMaxLength }));
      }
      if (i + 1 === idChunkLength || (i + 1) % maxParallel === 0) {
        // if the current iterator is last or if we passed maxParallel, execute await command
        let validatedIds = [];
        // eslint-disable-next-line no-await-in-loop
        validatedIds = await Promise.all(promises);
        promises = [];
        for (let j = 0; j < validatedIds.length; j++) {
          if (this.state.throwError === false && validatedIds[j].status >= 400) {
            this.setState({
              throwError: true,
              errorMsg: 'Error occurred, please try again.',
            });
          }
          if (validatedIds[j].status === 200 && Array.isArray(validatedIds[j].response)) {
            responseValues = responseValues.concat(validatedIds[j].response);
          }
        }
      }
    }
    let models = [];
    const excludedAccounts = new Set(excludedAccountIDs);
    const excludedApplications = new Set(excludedApplicationIDs);
    if (contentType === 'account') {
      models = responseValues.map((a) => new Account(a));
      models = models.filter((acc) => !excludedAccounts.has(acc.get('id')));
    } else {
      models = responseValues.map((a) => new Application(a));
      models = models.filter((app) => !excludedAccounts.has(app.get('account')) && !excludedApplications.has(app.get('id')));
    }
    onPasteSelect(models);
    splashFinish();
  };

  processPlacementContent = (value, onPasteSelect, idMaxLength = IDMAXLENGTH) => {
    const ids = dedupe(split(value).filter((id) => isHexId(id)));
    if (ids.length > idMaxLength) {
      this.setState({
        throwError: true,
        errorMsg: `Exceeded maximum of ${idMaxLength} pasted IDs`,
      });
      return;
    }
    onPasteSelect(ids);
  };

  componentWillUnmount() {
    clearTimeout(this.fadeOut);
  }

  copyAllPubs = () => {
    let pubIds;
    if (this.props.target === 'account') {
      pubIds = this.props.selectedAccounts.map((i) => i.get('id'));
    } else if (this.props.target === 'application') {
      pubIds = this.props.selectedApplications.map((i) => i.get('id'));
    } else if (this.props.target === 'placement') {
      pubIds = this.props.selectedPlacements;
    }

    pubIds = pubIds.toString();

    if (document.getElementsByClassName('copyIds').length > 0) {
      const removeNode = document.getElementsByClassName('copyIds');
      removeNode[0].parentNode.removeChild(removeNode[0]);
    }

    const pubIdsNode = document.createElement('textArea');
    document.body.appendChild(pubIdsNode);
    pubIdsNode.value = pubIds;
    pubIdsNode.className = 'copyIds';
    pubIdsNode.select();
    document.execCommand('copy');
    this.setState({ visible: true });
  };

  render() {
    const { visible, throwError, errorMsg } = this.state;
    return (
      <AllowDenyList
        {...this.props}
        processApplicationContent={this.processContent('application')}
        processAccountContent={this.processContent('account')}
        processPlacementContent={this.processPlacementContent}
        copyPubs={this.copyAllPubs}
        visible={visible}
        throwError={throwError}
        errorMsg={errorMsg}
      />
    );
  }
}

export default AllowDenyListContainer;
