import { includes, isString, isPlainObject } from 'lodash';
import {
  TYPE_IMAGE,
  TYPE_VIDEO,
  TYPE_BUNDLE,
  PENDING,
  VISUAL_QA,
  PENDING_FULL_QA,
  FULL_QA,
  NEEDS_ATTENTION,
  ACTIVE,
} from 'app/constants/asset';
import { getClient, getUploadClient } from '../setup';
import handleGraphQLError from './handle-graphql-error';
import { getSearchAndIDsFilter, getArrayQueryVariables } from './helper';
import {
  GetAssets,
  GetAssetsForFilter,
  GetAssetsForPreview,
  GetAssetByID,
  GetAssetByVideoID,
  GetVisualQANames,
  ModifyAsset,
  DeleteAsset,
  EditAssetUploadComments,
  SubmitAssetQAFeedback,
  StartVisualQA,
  SubmitVisualQA,
  SubmitFullQA,
  UploadAsset,
  ReplaceAsset,
  UploadIssueRecords,
  SubmitScreenshots,
  AssetQAHistory,
  SendToVisualQA,
} from '../operations_asset.graphql';
import { getServiceType, isAdminService } from '../../../lib/serviceType';

export function getAssetQueryVariables(filters) {
  const variables = {
    ...getSearchAndIDsFilter(filters),
    page: Number(filters.page),
    perPage: Number(filters.perPage),
  };
  if (filters.previewURLExistence !== undefined) {
    variables.previewURLExistence = filters.previewURLExistence;
  }
  const accounts = getArrayQueryVariables(filters.accounts);
  if (accounts) {
    variables.accounts = accounts;
  }

  const applications = getArrayQueryVariables(filters.applications);
  if (applications) {
    variables.applications = applications;
  }

  const types = getArrayQueryVariables(filters.typeIn);
  if (types) {
    variables.types = types;
  } else {
    variables.types = [TYPE_IMAGE, TYPE_VIDEO, TYPE_BUNDLE];
  }

  const status = getArrayQueryVariables(filters.qaStatus);
  if (status) {
    if (includes(status, 'notApproved')) {
      variables.status = [PENDING, VISUAL_QA, PENDING_FULL_QA, FULL_QA, NEEDS_ATTENTION];
    } else {
      variables.status = status;
    }
  } else {
    variables.status = [PENDING, VISUAL_QA, PENDING_FULL_QA, FULL_QA, NEEDS_ATTENTION, ACTIVE];
  }

  if (isString(filters.sort)) {
    const [key, asc] = filters.sort.split(':');
    variables.sort = { key, asc: asc === 'asc' };
  } else {
    variables.sort = filters.sort || { key: 'created', asc: false };
  }

  variables.url = filters.assetUrl;
  variables.videoID = getArrayQueryVariables(filters.videoId);
  return variables;
}

export function convertToRestFormat(asset) {
  if (!asset) {
    return undefined;
  }
  const result = {
    ...asset,
    status: asset.status || ACTIVE,
    name: asset.title || asset.originalName,
    original_url: asset.originalUrl,
    original_name: asset.originalName,
    preview_url: asset.previewUrl,
    is_attached: asset.isAttached,
    is_deleted: asset.isDeleted,
    external_services: asset.externalServices,
    exif: asset.exif && {
      ...asset.exif,
      original_name: asset.exif.originalName,
    },
  };
  const { video, videoID } = asset;
  if (video) {
    result.video = video;
  } else if (videoID) {
    result.video = { id: videoID };
  } else {
    result.video = {};
  }
  return result;
}

export function handleAssetsGraphQLResponse({
  data: {
    assets,
  } = {},
}) {
  return {
    ok: true,
    status: 200,
    response: assets.assets.map(convertToRestFormat),
    pagination: assets.pagination,
  };
}

export function handleAssetsQAHistoryGraphQLResponse({
  data: {
    assetQAHistory: assetQALogs,
  } = {},
}) {
  return {
    ok: true,
    status: 200,
    response: assetQALogs.logs,
    pagination: assetQALogs.pagination,
  };
}
export function removeNullInObject(data) {
  if (isPlainObject(data)) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in data) {
      if (!data[key]) {
        // eslint-disable-next-line no-param-reassign
        delete data[key];
      }
    }
  }
  return data;
}

export function removeNullInResponse(data) {
  if (!data || !data.response) {
    return {};
  }
  const { response } = data;
  removeNullInObject(response);
  if (Array.isArray(response)) {
    response.forEach((item) => {
      removeNullInObject(item);
    });
  }
  return data;
}

export function tidyDescription(data) {
  if (!data || !data.response) {
    return {};
  }
  const { response } = data;
  const serviceType = isAdminService() ? 'Ctrl' : 'Grow';
  const res = response.map((log) => ({
    ...log,
    description: JSON.parse(log.description)[serviceType],
  }));
  return { ...data, response: res };
}
export function getAssets(filters = {}) {
  return getClient()
    .query({
      query: GetAssets,
      variables: getAssetQueryVariables(filters),
    })
    .then(handleAssetsGraphQLResponse)
    .catch(handleGraphQLError);
}

export function getAssetsForFilter(filters = {}) {
  return getClient()
    .query({
      query: GetAssetsForFilter,
      variables: getAssetQueryVariables(filters),
    })
    .then(handleAssetsGraphQLResponse)
    .catch(handleGraphQLError);
}

export function getAssetsForPreview(ids) {
  return getClient()
    .query({
      query: GetAssetsForPreview,
      variables: {
        ids,
      },
    })
    .then(handleAssetsGraphQLResponse)
    .catch(handleGraphQLError);
}

export function handleAssetGraphQLResponse({
  data: {
    asset,
    assetByVideoID,
  } = {},
}) {
  return {
    ok: true,
    status: 200,
    response: convertToRestFormat(asset || assetByVideoID),
  };
}

export function getAssetByID(id) {
  return getClient()
    .query({
      query: GetAssetByID,
      variables: {
        id,
      },
    })
    .then(handleAssetGraphQLResponse)
    .catch(handleGraphQLError);
}

export function getAssetByVideoID(id) {
  return getClient()
    .query({
      query: GetAssetByVideoID,
      variables: {
        id,
      },
    })
    .then(handleAssetGraphQLResponse)
    .catch(handleGraphQLError);
}

export function getVisualQANames(id) {
  return getClient()
    .query({
      query: GetVisualQANames,
      variables: {
        id,
      },
    })
    .then(({ data }) => ({
      ok: true,
      status: 200,
      response: {
        names: data.visualQANames,
      },
    }))
    .catch(handleGraphQLError);
}

export function modifyAsset(id, input) {
  return getClient()
    .mutate({
      mutation: ModifyAsset,
      variables: {
        id,
        input,
      },
    })
    .then(() => ({ ok: true, status: 200 }))
    .catch(handleGraphQLError);
}

export function deleteAsset(id) {
  return getClient()
    .mutate({
      mutation: DeleteAsset,
      variables: {
        id,
      },
    })
    .then(() => ({ ok: true, status: 200 }))
    .catch(handleGraphQLError);
}

function handleAssetUploadGraphQLResponse({ data }) {
  const asset = data.uploadAsset || data.uploadToReplaceAsset || {};
  const result = {
    id: asset.id,
    type: asset.type,
    status: asset.status,
    tags: asset.tags || [],
    isVungleBundle: asset.isVungleBundle,
    linkedAssets: asset.linkedAssets || {},
  };
  return {
    ok: true,
    status: 200,
    response: result,
  };
}

export function uploadAsset(input) {
  return getUploadClient()
    .mutate({
      mutation: UploadAsset,
      variables: {
        input: { ...input, client: getServiceType() },
      },
    })
    .then(handleAssetUploadGraphQLResponse)
    .catch(handleGraphQLError);
}

export function replaceAsset(id, input) {
  return getUploadClient()
    .mutate({
      mutation: ReplaceAsset,
      variables: {
        id,
        input,
      },
    })
    .then(handleAssetUploadGraphQLResponse)
    .catch(handleGraphQLError);
}

export function uploadIssueRecords(id, input) {
  return getUploadClient()
    .mutate({
      mutation: UploadIssueRecords,
      variables: {
        id,
        input,
      },
    })
    .then(({ data }) => ({
      ok: true,
      status: 200,
      response: data.uploadIssueRecords,
    }))
    .catch(handleGraphQLError);
}

export function editUploadComments(id, input) {
  return getClient()
    .mutate({
      mutation: EditAssetUploadComments,
      variables: {
        id,
        input,
      },
    })
    .then(() => ({ ok: true, status: 200 }))
    .catch(handleGraphQLError);
}

export function sendFeedback(id, input) {
  return getClient()
    .mutate({
      mutation: SubmitAssetQAFeedback,
      variables: {
        id,
        input,
      },
    })
    .then(() => ({ ok: true, status: 200 }))
    .catch(handleGraphQLError);
}

export function startVisualQA(id) {
  return getClient()
    .mutate({
      mutation: StartVisualQA,
      variables: {
        id,
      },
    })
    .then(() => ({ ok: true, status: 200 }))
    .catch(handleGraphQLError);
}

export function submitVisualQA(id, input) {
  return getClient()
    .mutate({
      mutation: SubmitVisualQA,
      variables: {
        id,
        input,
      },
    })
    .then(() => ({ ok: true, status: 200 }))
    .catch(handleGraphQLError);
}

export function submitFullQA(id, input) {
  return getClient()
    .mutate({
      mutation: SubmitFullQA,
      variables: {
        id,
        input,
      },
    })
    .then(() => ({ ok: true, status: 200 }))
    .catch(handleGraphQLError);
}

export function sendToVisualQA(id, input) {
  return getClient()
    .mutate({
      mutation: SendToVisualQA,
      variables: {
        id,
        input,
      },
    })
    .then(({ data }) => ({
      ok: true,
      status: 200,
      response: {
        variation: data.sendToVisualQA,
      },
    }))
    .catch(handleGraphQLError);
}

export function submitScreenshots(id, input) {
  return getClient()
    .mutate({
      mutation: SubmitScreenshots,
      variables: {
        id,
        input,
      },
    })
    .then(() => ({ ok: true, status: 200 }))
    .catch(handleGraphQLError);
}

export function assetQAHistory(id, page) {
  return getClient()
    .query({
      query: AssetQAHistory,
      variables: {
        id,
        page,
      },
    })
    .then(handleAssetsQAHistoryGraphQLResponse)
    .then(tidyDescription)
    .then(removeNullInResponse)
    .catch(handleGraphQLError);
}
