/**
 * These constants are returned in the error response from the API.
 */
const ERRORS = {
  UNEXPECTED: 'unexpected',
  FILE_TOO_LARGE: 'File too large',
  ONLY_ONE_FILE: 'Only one file',
  ASSET_INVALID_ASPECT_RATIO: 'asset-invalid-aspect-ratio',
  ASSET_INVALID_DURATION: 'asset-invalid-duration',
  ASSET_INVALID_MIME_TYPE: 'asset-invalid-mime-type',
  ASSET_NAME_CONFLICT: 'asset-name-conflict',
  ASSET_PICKED_BY_OTHERS: 'asset-picked-by-others',
  ASSET_BUNDLE_VALIDATION_FAILED: 'asset-bundle-validation-failed',
  ASSET_BUNDLE_UPLOAD_FAILED: 'asset-bundle-upload-failed',
  ASSET_BUNDLE_VALIDATION_SAVE_FAILED: 'asset-bundle-unable_to_save_file_to_validate',
  ASSET_BUNDLE_VALIDATION_WRONG_FORMAT_FAILED: 'asset-bundle-wrong_format',
  ASSET_BUNDLE_VALIDATION_MISSING_FORMAT_FAILED: 'asset-bundle-format_not_present',
  ASSET_BUNDLE_VALIDATION_EXTENSION_FAILED: 'asset-bundle-wrong_mime_type',
  ASSET_BUNDLE_VALIDATION_MISSING_FILE_FAILED: 'asset-bundle-no_file_present',
  ASSET_BUNDLE_VALIDATION_UNRESPONSIVE: 'asset-bundle-validation-unresponsive',
  ASSET_BUNDLE_VALIDATION_MISSING_MRAID_FAILED: 'asset-bundle-valid_mraid_js',
  ASSET_BUNDLE_VALIDATION_MISSING_MRAID_ACTIONS_FAILED: 'asset-bundle-valid_mraid_actions',
  ASSET_BUNDLE_VALIDATION_MISSING_AD_FAILED: 'asset-bundle-valid_ad_html',
  ASPECT_RATIO_NOT_IN_RANGE_75_85: 'aspect-ratio-not-in-range-7.5-8.5',
  ASPECT_RATIO_NOT_IN_RANGE_55_65: 'aspect-ratio-not-in-range-5.5-6.5',
};

const ERROR_MSG = {
  [ERRORS.UNEXPECTED]:
    'Unexpected error, upload service offline.',
  [ERRORS.FILE_TOO_LARGE]:
    'File size cannot exceed 100MB.',
  [ERRORS.ONLY_ONE_FILE]:
    'Only one file can be uploaded.',
  [ERRORS.ASSET_INVALID_ASPECT_RATIO]:
    'Aspect ratio is not valid.',
  [ERRORS.ASSET_INVALID_DURATION]:
    'Video duration cannot exceed 60 seconds.',
  [ERRORS.ASSET_INVALID_MIME_TYPE]:
    'File format is not supported.',
  [ERRORS.ASSET_NAME_CONFLICT]:
    'This asset name already exists, please rename your asset and re-upload it.',
  [ERRORS.ASSET_BUNDLE_VALIDATION_FAILED]:
    'Bundle (.zip file) validation failed, please try again.',
  [ERRORS.ASSET_BUNDLE_UPLOAD_FAILED]:
    'Server error, bundle (.zip file) upload failed, please try again.',
  [ERRORS.ASSET_BUNDLE_VALIDATION_SAVE_FAILED]:
    'Server error, bundle (.zip file) had saving issues, please try again.',
  [ERRORS.ASSET_BUNDLE_VALIDATION_WRONG_FORMAT_FAILED]:
    'Invalid bundle (.zip file), please change the format to the appropriate .zip file format and try again.',
  [ERRORS.ASSET_BUNDLE_VALIDATION_MISSING_FORMAT_FAILED]:
    'Missing .zip bundle format, please adjust and try again.',
  [ERRORS.ASSET_BUNDLE_VALIDATION_EXTENSION_FAILED]:
    'Only .zip files can be uploaded.',
  [ERRORS.ASSET_BUNDLE_VALIDATION_MISSING_FILE_FAILED]:
    'No file selected.',
  [ERRORS.ASSET_BUNDLE_VALIDATION_UNRESPONSIVE]:
    'Oh dang, we’re having some server issues and having trouble validating your file. Hold tight, or refresh this page and try again.',
  [ERRORS.ASSET_BUNDLE_VALIDATION_MISSING_MRAID_FAILED]:
    'Script mraid.js is not loaded, please correct this and try again.',
  [ERRORS.ASSET_BUNDLE_VALIDATION_MISSING_MRAID_ACTIONS_FAILED]:
    'Mraid open and close functions are never called, please correct this and try again.',
  [ERRORS.ASSET_BUNDLE_VALIDATION_MISSING_AD_FAILED]:
    'File ad.html is missing, please correct this and try again.',
  [ERRORS.ASPECT_RATIO_NOT_IN_RANGE_75_85]:
    'Aspect ratio must be between 7.5 and 8.5 (ideally 728x90px). Please make the edits and try again.',
  [ERRORS.ASPECT_RATIO_NOT_IN_RANGE_55_65]:
    'Aspect ratio must be between 5.5 and 6.5 (ideally 320x50px and 300x50px). Please make the edits and try again.',
};

const WARNINGS = {
  ASSET_BUNDLE_VALIDATION_ECMA_5_FAILED:
    'asset-bundle-valid_javascript_ecma',
  ASSET_BUNDLE_VALIDATION_LOCATION_FAILED:
    'asset-bundle-valid_javascript_location',
  ASSET_BUNDLE_VALIDATION_CSS_FAILED:
    'asset-bundle-valid_css',
  ASSET_IMAGE_TYPE_REQUIRED:
    'image-type-required',
};

const WARNING_MSG = {
  [WARNINGS.ASSET_BUNDLE_VALIDATION_ECMA_5_FAILED]:
    'It is recommended to make your Javascript code backwards compatible, ECMA6 is not recommended.',
  [WARNINGS.ASSET_BUNDLE_VALIDATION_LOCATION_FAILED]:
    '(location.reload, location.href,...) - Browser Location API. Changing webpage url/location with `location` is not recommended.',
  [WARNINGS.ASSET_BUNDLE_VALIDATION_CSS_FAILED]:
    'It is not recommended using vw and vh in your CSS (e.g. -  width: 80vh).',
  [WARNINGS.ASSET_IMAGE_TYPE_REQUIRED]:
    'Please appropriately identify your image asset type.',
};
export {
  ERRORS,
  ERROR_MSG,
  WARNINGS,
  WARNING_MSG,
};
