import React from 'react';
import Button from '../../../../../components/Button/Button';
import Link from '../../../../../components/Link/Link';
import { canModifyViewAccountFinancialDetails } from '../../../../../lib/helpers/authUser';

const Fund = ({
  title, value, description, cta = false, link = false, linkText,
}) => (
  <div className="fund">
    <div className="texts">
      <p className="p2">{title}</p>
      <p className="h2">{value}</p>
      <p className="p2">{description}</p>
    </div>
    {cta && link && (
      <Link to={link}>
        <Button inverse>{linkText}</Button>
      </Link>
    )}
  </div>
);

const Funds = ({ account }) => canModifyViewAccountFinancialDetails() && (
  <div className="funds">
    {account.isBudgetPaced() && (
      <Fund
        cta={account.isPrePay()}
        account={account}
        title="Available Funds"
        value={account.getRemainingFunds(true)}
        description="Funds remaining in your account are available for campaign expenditures"
      />
    )}
    {account.isBudgetPaced() && (
      <Fund
        title="Overall Funds"
        value={account.get('budget_pacing.total_available')}
        description="The amount of money this account has had to fund campaigns"
      />
    )}
    <Fund
      title="Overall Spend"
      value={account.getOverallSpend(true)}
      description="The amount of money this account has spent throughout all time"
    />
  </div>
);

export default Funds;
