import React from 'react';

import './resizer.scss';

function setResizerLineStyle(container, event, visible, _setResizerLineStyle) {
  const isTouch = event.type === 'touchend' || event.type === 'touchcancel';
  const clientX = isTouch ? event.changedTouches[0].clientX : event.clientX;
  const table = container.querySelector('.rt-table');
  const thead = container.querySelector('.rt-thead');
  _setResizerLineStyle({
    top: thead.clientHeight,
    left: clientX - container.getBoundingClientRect().left,
    height: table.clientHeight - thead.clientHeight,
    display: visible ? 'block' : 'none',
  });
}

const resizeColumnEnd = (event, that) => {
  event.stopPropagation();
  that.cleanListeners();
  const tableContainer = that.props.tableRef.current;
  setResizerLineStyle(tableContainer, event, false, that.props.setResizerLineStyle);

  const isTouch = event.type === 'touchend' || event.type === 'touchcancel';
  const { onResizedChange, column } = that.props;
  const { resized, currentlyResizing, columns } = that.getResolvedState();

  const currentColumn = columns.find((c) => c.accessor === currentlyResizing.id || c.id === currentlyResizing.id);
  const minResizeWidth = currentColumn && currentColumn.minResizeWidth != null ? currentColumn.minResizeWidth : column.minResizeWidth;
  const newResized = resized.filter((x) => x.id !== currentlyResizing.id);
  const pageX = isTouch ? event.changedTouches[0].pageX : event.pageX;
  const newWidth = Math.max((currentlyResizing.parentWidth + pageX) - currentlyResizing.startX, minResizeWidth);

  newResized.push({ id: currentlyResizing.id, value: newWidth });
  that.setStateWithData(
    { resized: newResized },
    () => {
      if (onResizedChange) {
        onResizedChange(newResized, event);
      }
    },
  );

  // prevent sorting event
  if (!isTouch) {
    that.setStateWithData({
      skipNextSort: true,
      currentlyResizing: false,
    });
  }
};

const resizeColumnMoving = (event, that) => {
  event.stopPropagation();
  const tableContainer = that.props.tableRef.current;
  setResizerLineStyle(tableContainer, event, true, that.props.setResizerLineStyle);
};

function resizeColumnStart(event, column, isTouch, that) {
  event.stopPropagation();
  const parentWidth = event.target.parentElement.getBoundingClientRect().width;
  const pageX = isTouch ? event.changedTouches[0].pageX : event.pageX;

  // add the resizer line
  const tableContainer = that.props.tableRef.current;
  setResizerLineStyle(tableContainer, event, true, that.props.setResizerLineStyle);

  const _this = that;
  _this.resizerTarget = event.currentTarget;

  // listeners
  const movingFunc = (e) => resizeColumnMoving(e, that);
  const endFunc = (e) => resizeColumnEnd(e, that);

  const removeTouchListeners = () => {
    document.removeEventListener('touchmove', movingFunc);
    document.removeEventListener('touchcancel', endFunc);
    document.removeEventListener('touchend', endFunc);
  };

  const removeMouseListeners = () => {
    document.removeEventListener('mousemove', movingFunc);
    document.removeEventListener('mouseup', endFunc);
    document.removeEventListener('mouseleave', endFunc);
  };

  _this.cleanListeners = isTouch ? removeTouchListeners : removeMouseListeners;

  that.setStateWithData(
    {
      currentlyResizing: {
        id: column.id,
        startX: pageX,
        parentWidth,
      },
    },
    () => {
      if (isTouch) {
        document.addEventListener('touchmove', movingFunc);
        document.addEventListener('touchcancel', endFunc);
        document.addEventListener('touchend', endFunc);
      } else {
        document.addEventListener('mousemove', movingFunc);
        document.addEventListener('mouseup', endFunc);
        document.addEventListener('mouseleave', endFunc);
      }
    },
  );
}

function resizerHover(event, column, isTouch, that) {
  event.stopPropagation();
  // add the resizer line
  const tableContainer = that.props.tableRef.current;
  setResizerLineStyle(tableContainer, event, true, that.props.setResizerLineStyle);
}

function resizerOut(event, column, isTouch, that) {
  event.stopPropagation();
  const tableContainer = that.props.tableRef.current;
  setResizerLineStyle(tableContainer, event, false, that.props.setResizerLineStyle);
}

const Resizer = ({ column, that }) => (
  <div
    className={`${that.props.prefixCls}-resizer`}
    onMouseOver={(event) => resizerHover(event, column, true, that)}
    onMouseDown={(event) => resizeColumnStart(event, column, false, that)}
    onTouchStart={(event) => resizeColumnStart(event, column, true, that)}
    onMouseOut={(event) => resizerOut(event, column, true, that)}
  >
    {/*<span className="material-icons">vertical_align_center</span>*/}
  </div>
);

export default Resizer;
