import React from 'react';
import { cloneDeep } from 'lodash';
import { Redirect } from 'react-router-dom';
import { shouldShowSkanAlert } from 'app/graphql/errorCodes/campaign';
import namespace from '../../../../../app/namespace';
import { compose, param, splash } from '../../../../../app/app';
import { track } from '../../../../../lib/tracker';
import Campaign from '../../../../../models/Campaign';
import triggerSkanAlert from '../../../../../components/Modals/SkanAlert/triggerSkanAlert';
import paths, { getNextRoute } from '../../../paths';
import { CREATE_CAMPAIGN_SOURCE_KEY } from './constant';
import Edit from './Edit';
import './edit.scss';
import triggerConfirm from '../../../../../components/Modals/ConfirmAction/triggerConfirm';

const ns = 'views.campaigns.edit';
const updateState = namespace(ns, { pageContinue: true });
const props = (state) => state[ns];
const {
  CAMPAIGN: {
    getPath: getPathToCampaign,
  },
  CAMPAIGN_CREATE: {
    getPath: getPathToCreateCampaign,
  },
  CAMPAIGN_APPLICATION: {
    getPath: getPathToCreateApplication,
  },
} = paths;

class EditContainer extends React.Component {
  applicationFromId = '';

  componentDidMount() {
    const createOrId = param(this.props, 'edit');
    const { application } = this.props.router.queryParams;
    if (createOrId === 'create') {
      this.props.actions.setupCreateView();
      return;
    }
    if (application) {
      this.applicationFromId = application;
    }
    // editing
    this.props.actions.setupEditView({ id: createOrId });
  }

  componentDidUpdate() {
    const { saveModalShow, errMsg } = this.props;
    if (this.props.showSkanAlert) {
      this.props.actions.deactivateModal();
      triggerSkanAlert();
    } else if (errMsg && !saveModalShow) {
      this.props.actions.showSaveFailedModal();
      triggerConfirm({
        type: 'FAIL_CAMPAIGN_SAVE_CONFIRM_ACTION',
        header: 'Unsuccessful Save',
        message: () => <span className="active">Saving Campaign Failed.</span>,
        onConfirm: () => this.props.actions.resetModal(),
        onCancel: () => this.props.actions.resetModal(),
        confirmOnly: true,
      });
    }
  }

  componentWillUnmount() {
    this.props.actions.destroyView();
  }

  render() {
    const {
      actions: actionsProps, campaignCreated, campaign, location, redirect,
    } = this.props;
    if (campaignCreated) {
      return <Redirect to={this.props[CREATE_CAMPAIGN_SOURCE_KEY] || '/campaigns/'} />;
    }

    if (redirect) {
      if (this.applicationFromId) {
        return <Redirect to={`/applications/${this.applicationFromId}`} />;
      }
      return <Redirect to={getPathToCampaign()} />;
    }

    if (!campaign) {
      return null;
    }

    const nextSection = getNextRoute(campaign, 'confirm', null, true);
    // TODO should use matchPath
    const shouldAdvanceToNextSection = !location.pathname.startsWith(getPathToCreateCampaign())
      && campaign.get('application')
      && nextSection !== 'confirm'
      && location.pathname !== `/campaigns/create/${nextSection}`;

    if (shouldAdvanceToNextSection) {
      return <Redirect to={`/campaigns/create/${nextSection}`} />;
    }

    // TODO should use matchPath
    const shouldAdvanceToCreate = !campaign.get('application')
      && (location.pathname !== getPathToCreateCampaign()
        && location.pathname !== getPathToCreateApplication()
      );

    if (shouldAdvanceToCreate) {
      return <Redirect to={getPathToCreateCampaign()} />;
    }

    campaign.onChange(actionsProps.campaignChangeAction);

    return <Edit {...this.props} />;
  }
}

const setupCreateView = ({ state }) => updateState(state, {
  editing: false,
  campaign: (state[ns] && state[ns].campaign) || new Campaign({
    account: {
      id: state.authUser.account,
      creativeMaximum: state.authUser.account_creativeMaximum,
      budget_type: state.setup.appConfig.account.budget_type,
    },
  }),
});
const setupEditView = (() => {
  const action = ({ id }) => Campaign.getById(id);
  const end = ({ state, result }) => {
    if (!result.ok) {
      // not a valid URL
      return updateState(state, { redirect: true });
    }
    const campaign = new Campaign(result.response);
    // set account.budget_type for prepay verify
    campaign.set('account.budget_type', state.setup.appConfig?.account?.budget_type);
    return updateState(state, {
      editing: true,
      changingValue: false,
      authUser: state.authUser,
      originalCampaign: cloneDeep(campaign.toServerObject()),
      campaign,
    });
  };
  return { action, end };
})();
const campaignChangeAction = ({ state, values }) => updateState(state, { changingValue: state[ns] && state[ns].editing, campaign: values });
const destroyView = ({ state }) => updateState(state, {
  campaign: null,
  campaignCreated: false,
  editing: false,
  redirect: false,
  changingValue: false,
  [CREATE_CAMPAIGN_SOURCE_KEY]: null,
});
const closeBidBudgetAlert = ({ state }) => updateState(state, { bidBudgetAlertSeen: true });
const toggleModal = ({ state }) => updateState(state, { showModal: !state[ns].showModal });
const confirmExit = ({ state }) => updateState(state, { showModal: false, redirect: true });

const resetModal = ({ state }) => updateState(state, { saveModalShow: false, errMsg: undefined });

const showSaveFailedModal = ({ state }) => updateState(state, { saveModalShow: true });

const createCampaign = (() => {
  const action = ({ campaign, active }) => {
    if (campaign.raw('id')) {
      return Campaign.save(campaign, active);
    }
    return Campaign.create(campaign, active);
  };
  const start = () => splash({ start: true, text: 'Saving campaign' });
  const end = ({ state, result }) => {
    if (shouldShowSkanAlert(result.code)) {
      return {
        ...updateState(state, { showSkanAlert: true }),
        ...splash({ finish: false }),
      };
    }
    if (result.status === 400) {
      return {
        ...splash({ show: false }),
        ...updateState(state, { validations: { generic: 'Could not validate your campaign, please ensure all data is correct.' } }),
      };
    }
    if (!result.ok) {
      return {
        ...splash({ finish: true, text: 'Failed' }),
        ...updateState(state, {
          validations: {
            generic: 'Something went wrong with our servers. Our developers have been notified. Sorry about that!',
          },
          originalCampaign: cloneDeep(state[ns].campaign.toServerObject()),
          errMsg: 'Saving campaign failed.',
        }),
      };
    }
    if (state[ns].editing) {
      track('Campaign Edited', { id: result.response.id });
    } else {
      track('Campaign Created', { id: result.response.id });
    }
    return {
      ...splash({ finish: true, text: 'Saved Successfully' }),
      ...updateState(state, { campaignCreated: true, validations: {} }),
    };
  };
  return { action, end, start };
})();

const deactivateModal = ({ state }) => updateState(state, { showSkanAlert: false });

const actions = {
  campaignChangeAction,
  createCampaign,
  destroyView,
  setupCreateView,
  setupEditView,
  closeBidBudgetAlert,
  toggleModal,
  confirmExit,
  deactivateModal,
  resetModal,
  showSaveFailedModal,
};

export default compose(EditContainer, { props, actions });
