import React from 'react';
import Link from 'components/Link/Link';
import Alert from 'components/Alert/Alert';
import { ACTIVE } from 'app/constants/asset';
import { isAdvertiserService as getAdvertiserService } from '../../../../../../../../lib/serviceType';
import Button from '../../../../../../../../components/Button/Button';
import SideModal from '../../../../../../../../components/SideModal/side-modal';
import './AssetPicker.scss';
import triggerConfirm from '../../../../../../../../components/Modals/ConfirmAction/triggerConfirm';
import AssetDropZone from '../../../../../assets/list_2/AssetUploader/AssetDropZone';
import UploadProgressList from '../../../../../assets/list_2/AssetUploader/UploadProgressList';
import AssetPickerList from './AssetPickerList';
import { Search } from '../../../../../../../../components/Form/Text/TextContainer';
import Pill from '../../../../../../../../components/Pill/Pill';
import PlayableFilters from './PlayableFilters';
import { getAssetName } from '../../../../../../../../lib/helpers/creatives/creativeIndex';

const typeLabels = {
  banner: 'Images (Banner)',
  banner_video: 'Videos (Banner)',
  bundle_adaptive_creative: 'Playable (.zip format)',
  image: 'Images',
  video: 'Video',
};

function ModalSubtitle() {
  return (
    <div
      className="modal-subtitle"
    >
      <div
        className="modal-subtitle__messaging"
      >
        Select your appropriate asset from below. If you do not have any yet, please add some.
      </div>
      <div
        className="modal-subtitle__learn-more"
      >
        <Link
          className="body__link"
          external
          to="https://support.vungle.com/hc/en-us/articles/360035348091"
        >
          Learn More About Assets
        </Link>
      </div>
    </div>
  );
}

class AssetPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // files that are selected for upload
      files: [],

      uploadingFilesNumber: 0,

      // used to let parent know that at least one asset
      // is uploaded so the parent can update the asset list
      hasUploadedAnyAsset: false,

      errors: [],

      searchValue: undefined,

      query: {
        search: '',
        type: props.type,
      },

      playableStatusFilters: [],
      isAdvertiserService: false,
    };
  }

  componentDidMount() {
    const isAdvertiserService = getAdvertiserService();
    const { type } = this.props;
    if (isAdvertiserService) {
      this.setState({
        playableStatusFilters: type === 'bundle_adaptive_creative' ? [ACTIVE] : [],
        isAdvertiserService,
      });
    }
  }

  closeModal = () => {
    if (this.state.uploadingFilesNumber > 0) {
      // prevent user closing the picker if there are pending files
      triggerConfirm({
        type: 'EXIT_ASSET_UPLOAD_CONFIRM_ACTION',
        header: 'Oh Snap',
        message: 'Heads up. You\'re exiting the asset upload process. Are you super sure you want to exit the flow?',
        onConfirm: () => this.props.onClose(this.state.hasUploadedAnyAsset),
      });
      return;
    }
    this.props.onClose(this.state.hasUploadedAnyAsset);
  };

  onDiscardAsset = (discardedFile) => {
    const { files } = this.state;

    this.setState({
      files: files.filter((file) => file.name !== discardedFile.name),
    });
  };

  onSearchAsset = (assetID) => {
    this.setState({
      searchValue: assetID,
      query: { search: assetID },
    });
  }

  onFilterChange = (val) => {
    this.setState({
      playableStatusFilters: val,
    });
  };

  render() {
    const {
      application,
      replacementKey,
      template,
      type,
    } = this.props;
    const reminderType = getAssetName(type, replacementKey);
    const {
      files, errors, uploadingFilesNumber, searchValue, query, playableStatusFilters, isAdvertiserService,
    } = this.state;
    const messagingFirstSentence = `Feel free to upload any assets below, however only ${reminderType} will be available to choose.`;
    const shouldShowBundleWarning = getAdvertiserService() && type === 'bundle_adaptive_creative';
    const assetSelectionMessaging = `${messagingFirstSentence}${
      shouldShowBundleWarning
        ? ' Keep in mind, only Active assets will be able to select, any under review assets will not show.'
        : ''
    }`;
    const titleBase = 'Upload and/or Select Asset(s)';
    const title = files.length
      ? `Assets uploading ${uploadingFilesNumber}/${files.length}`
      : titleBase;

    return (
      <div className="asset_picker">

        <SideModal
          isOpen
          overlayClassName="asset_picker_overlay"
          onRequestClose={this.closeModal}
          title={title}
          subtitle={(
            <ModalSubtitle />
          )}
          ariaHideApp={false}
        >
          <div className="asset_picker-modal_content">
            <div className="upload_content">

              <div className="filter_container">
                <div className="input__search filters_search">
                  <Search
                    placeholder="Search Assets..."
                    value={searchValue}
                    onChange={(v) => this.setState({ searchValue: v, query: { search: v } })}
                  />
                </div>
                {type && <Pill>{`Asset Type: ${typeLabels[type]}`}</Pill>}
                {type === 'bundle_adaptive_creative'
                  && !isAdvertiserService
                  && <div className="playableFilters"><PlayableFilters onChange={this.onFilterChange} /></div>}
              </div>

              <Alert.Help>
                {assetSelectionMessaging}
              </Alert.Help>

              <AssetDropZone
                onDrop={(selectedFiles) => {
                  // filter out files that are already successfully uploaded
                  const onlyNewFiles = selectedFiles.filter((newFile) => {
                    const currentFile = files.find((file) => file.name === newFile.name);
                    if (!currentFile || (currentFile && errors.indexOf(newFile.name) !== -1)) {
                      return newFile;
                    }
                    return false;
                  });

                  // on drop new files, remove all files that are new and had some error when uploading
                  const filterOutFiles = files.filter((file) => !onlyNewFiles.find((nFile) => nFile.name === file.name));

                  this.setState({ files: [...onlyNewFiles, ...filterOutFiles] });
                }}
                accept={this.props.accept}
                shouldHidePlayable={this.props.shouldHidePlayable}
              />

              {files.length > 0
              && (
                <UploadProgressList
                  application={application}
                  files={files}
                  onUploadStart={() => {
                    this.setState((prevState) => ({
                      uploadingFilesNumber: prevState.uploadingFilesNumber + 1,
                    }));
                  }}
                  onUploadAsset={(obj) => {
                    let indx = -1;

                    if ((indx = errors.indexOf(obj.name)) !== -1) { //eslint-disable-line
                      errors.splice(indx, 1);
                    }

                    if (obj.status === 'error') {
                      errors.push(obj.name);
                    }

                    const currentActiveUploads = uploadingFilesNumber - 1;
                    this.setState({
                      errors,
                      hasUploadedAnyAsset: true,
                      uploadingFilesNumber: currentActiveUploads,
                    });
                  }}
                  onDiscardAsset={this.onDiscardAsset}
                  onSearchAsset={this.onSearchAsset}
                />
              )}

              {uploadingFilesNumber === 0
                && (
                  <div className="picker_list_container">
                    <AssetPickerList
                      application={application}
                      query={query}
                      playableStatusFilters={playableStatusFilters}
                      replacementKey={replacementKey}
                      template={template}
                      type={type}
                      onSelectAsset={(asset) => this.props.onSelectAsset(asset)}
                    />
                  </div>
                )}
            </div>

            <div className="footer">
              <Button
                cancel
                onClick={this.closeModal}
              >
                Close
              </Button>
            </div>
          </div>
        </SideModal>
      </div>
    );
  }
}

export default AssetPicker;
