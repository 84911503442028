import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Filter from '../../../../Filter/Filter';
import './selectedPreview.scss';

const prefixCls = 'v2_component_filters_multiTree-wrapper-preview';

function SelectedPreview({
  checkedNodes,
  onChange,
}) {
  const [focusedLabel, setFocusedLabel] = useState();
  const removeCheckedNode = React.useCallback((node, event) => {
    event.nativeEvent.stopImmediatePropagation();
    const newCheckedNodes = [];
    if (node.id === focusedLabel) {
      setFocusedLabel(null);
    }
    checkedNodes.forEach((cn) => {
      if (cn.id !== node.id) {
        newCheckedNodes.push(cn);
      }
    });
    onChange(newCheckedNodes);
    // --> potential bug inside this useCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedNodes]);

  return (
    <div className={`${prefixCls}`}>
      {
        checkedNodes.map((checkedNode) => (
          <Filter
            className={`${prefixCls}-item`}
            type="advanced"
            subType="option"
            key={checkedNode.id}
            onClickIcon={(_, event) => removeCheckedNode(checkedNode, event)}
            focused={checkedNode.id === focusedLabel}
            setFocused={() => setFocusedLabel(checkedNode.id)}
          >
            {checkedNode.name}
          </Filter>
        ))
      }
    </div>
  );
}

SelectedPreview.propTypes = {
  /**
   * checked node list
   */
  checkedNodes: PropTypes.arrayOf(
    PropTypes.any,
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

SelectedPreview.defaultProps = {
};

export default React.memo(SelectedPreview);
