import { isFunction } from 'lodash';
import config from 'lib/config';
import {
  errNotEnoughAvailableSkanIDs,
  errTooManyUnarchivedUnpausedSkanCampaigns,
} from 'app/graphql/errorCodes/campaign';
import { dispatchAction, splash } from '../../../app/app';
import { deleteResource } from '../../http';

const archiveResource = async (resource, urlString) => {
  const toArchive = !resource.get('is_deleted');

  const pendingActionType = toArchive ? 'ARCHIVE_RESOURCE_ACTION' : 'UNARCHIVE_RESOURCE_ACTION';
  dispatchAction(pendingActionType, () => splash({ start: true, text: toArchive ? 'Archiving' : 'Unarchiving' }));

  try {
    let result;

    if (isFunction(resource.delete)) {
      result = await resource.delete();
    } else {
      const postUrl = `${config.get('manageUrl')}${urlString}s/${resource.get('id')}`;
      result = await deleteResource(postUrl);
    }

    const fulfilledActionType = toArchive ? 'ARCHIVE_RESOURCE_ACTION_FULFILLED' : 'UNARCHIVE_RESOURCE_ACTION_FULFILLED';
    dispatchAction(fulfilledActionType, () => splash({ finish: true }));

    return { error: null, result };
  } catch (error) {
    const rejectedActionType = toArchive ? 'ARCHIVE_RESOURCE_ACTION_REJECTED' : 'UNARCHIVE_RESOURCE_ACTION_REJECTED';
    dispatchAction(rejectedActionType, () => splash({}));

    if (error.status === 403 || error.code === errTooManyUnarchivedUnpausedSkanCampaigns || error.code === errNotEnoughAvailableSkanIDs) {
      // 403 error indicates skan error
      return { skanError: true };
    }

    return { error: new Error('could not un/archive resource') };
  }
};

export default archiveResource;
