import Ad from './Ad';

class MraidAd extends Ad {
  constructor({ src, properties, frame }) {
    super({ src, frame });
    this.properties = properties;
  }

  togglePortrait() {
    this.frame.contentWindow.postMessage(JSON.stringify({
      event: 'property-change',
      data: {
        screenSize: {
          height: this.properties.screenSize.height,
          width: this.properties.screenSize.width,
        },
      },
    }), '*');

    if (['fullscreen'].indexOf(this.properties.placementType) !== -1) {
      this.frame.contentWindow.postMessage(JSON.stringify({
        event: 'property-change',
        data: {
          maxSize: {
            height: this.properties.maxSize.height,
            width: this.properties.maxSize.width,
          },
        },
      }), '*');
    }
  }

  toggleLandscape() {
    this.frame.contentWindow.postMessage(JSON.stringify({
      event: 'property-change',
      data: {
        screenSize: {
          height: this.properties.screenSize.width,
          width: this.properties.screenSize.height,
        },
      },
    }), '*');

    if (['fullscreen'].indexOf(this.properties.placementType) !== -1) {
      this.frame.contentWindow.postMessage(JSON.stringify({
        event: 'property-change',
        data: {
          maxSize: {
            height: this.properties.maxSize.width,
            width: this.properties.maxSize.height,
          },
        },
      }), '*');
    }
  }

  toggleView(isViewable) {
    this.frame.contentWindow.postMessage(JSON.stringify({
      event: 'property-change',
      data: {
        isViewable,
      },
    }), '*');
  }
}

export default MraidAd;
