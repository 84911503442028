import moment from 'moment';
import {
  FILTER_KEY_DATE,
  FILTER_KEY_SEARCH,
  FILTER_KEY_PLATFORM,
  FILTER_KEY_APPLICATION,
  FILTER_KEY_CAMPAIGN_STATUS,
  FILTER_KEY_BID_TYPE,
  FILTER_KEY_NETWORK_TYPE,
  FILTER_KEY_DELETION_STATUS,
} from 'app/constants/filters';
import {
  QUICK_MAP,
  QUICK_KEY_LAST_7_DAYS,
} from 'components/V2/Report/Calendar/PickerQuick';

export const COLUMN_CAMPAIGN_NAME = 'name';
export const COLUMN_CAMPAIGN_STATUS = 'status';
export const COLUMN_CAMPAIGN_ID = 'id';
export const COLUMN_ACCOUNT = 'accountName';
export const COLUMN_APPLICATION = 'applicationName';
export const COLUMN_RUN_DATES = 'startDate';
export const COLUMN_DAILY_BUDGET = 'daily';
export const COLUMN_BID = 'bid';
export const COLUMN_BID_TYPE = 'rateType';
export const COLUMN_CREATIVES = 'creativeCount';
export const COLUMN_DATE_CREATED = 'created';
export const COLUMN_DATE_UPDATED = 'updated';
export const COLUMN_TOTAL_BUDGET = 'totalBudget';
export const COLUMN_REMAINING_BUDGET = 'remaining';
export const COLUMN_TAGS = 'tags';

export const TABLE_ACTION_EDIT_CAMPAIGN = 'Edit Campaign';
export const TABLE_ACTION_ACTIVATE_CAMPAIGN = 'Activate Campaign';
export const TABLE_ACTION_PAUSE_CAMPAIGN = 'Pause Campaign';
export const TABLE_ACTION_CLONE_CAMPAIGN = 'Clone Campaign';
export const TABLE_ACTION_VIEW_ACTIVITY = 'View Activity';
export const TABLE_ACTION_DELETE_CAMPAIGN = 'Delete Campaign';
export const TABLE_ACTION_RESTORE_CAMPAIGN = 'Restore Campaign';

export const filterKeys = [
  FILTER_KEY_APPLICATION,
  FILTER_KEY_CAMPAIGN_STATUS,
  FILTER_KEY_PLATFORM,
  FILTER_KEY_BID_TYPE,
  FILTER_KEY_NETWORK_TYPE,
  FILTER_KEY_DELETION_STATUS,
];

export const EXTRA_QUERY_KEYS = [
  ...filterKeys,
  FILTER_KEY_SEARCH,
];

export const defaultSort = {
  id: COLUMN_DATE_CREATED,
  desc: true,
};

export const defaultFilterCache = {
  [FILTER_KEY_DATE]: {
    quickKey: QUICK_KEY_LAST_7_DAYS,
    ...QUICK_MAP[QUICK_KEY_LAST_7_DAYS].generateDate(moment()),
  },
  [FILTER_KEY_APPLICATION]: [],
  [FILTER_KEY_PLATFORM]: [],
  [FILTER_KEY_CAMPAIGN_STATUS]: [],
  [FILTER_KEY_BID_TYPE]: [],
  [FILTER_KEY_NETWORK_TYPE]: [],
  [FILTER_KEY_DELETION_STATUS]: [],
};
