import React from 'react';
import { FILTER_KEY_BID_TYPE } from 'app/constants/filters';
import CommonMultiPicker from '../CommonMultiPicker/CommonMultiPicker';
import { bidTypes } from '../constant';

const MultiBidType = (props) => (
  <CommonMultiPicker data={bidTypes} filterKey={FILTER_KEY_BID_TYPE} {...props} />
);

export default MultiBidType;
