import React from 'react';
import Pulsation from '../../../Pulsation/Pulsation';
import './creativeOrCampaign.scss';

const prefixCls = 'v2_component_filters_node-coc';

function CreativeOrCampaign({ node }) {
  return (
    <div className={prefixCls}>
      <Pulsation
        className={`${prefixCls}-orb`}
        status={node.status ? 'active' : 'inactive'}
      />
      <div className={`${prefixCls}-name`}>
        <span className="text-ellipsis">
          {node.name}
        </span>
      </div>
    </div>
  );
}

export default React.memo(CreativeOrCampaign);
