const generateErrorBlob = (code, msg, closeBtn) => {
  const errorCode = code || 404;
  const closeBtnEnabled = typeof closeBtn === 'undefined' ? false : closeBtn;
  let errorMessage = msg;

  if (!errorMessage) {
    switch (errorCode) {
      default:
      case 404:
        errorMessage = 'Oh dang, something went wrong.';
        break;
    }
  }
  const html = /*html*/`<html>
    <head>
      <style>
        body {
          margin: 0;
          padding: 0;
          border: 1px solid #ececec;
          background: #fff1f1;
        }

        header {
          position: fixed;
          width: 100%;
          height: 50px;
          z-index: 101;
        }

        .hidden {
          display: none;
        }

        header .close {
          width: 25px;
          height: 25px;
          padding: 10px 10px 20px 20px;
          cursor: pointer;
          position: relative;
          float: right;
        }

        header .close svg {
          width: 100%;
          height: 100%;
        }

        #closeBtn {
          fill: #fff;
          stroke: #6C6D6D;
          stroke-width: 10;
          stroke-miterlimit: 10;
        }

        .center {
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100%;
          padding: 0 40px;
          box-sizing: border-box;
          text-align: center;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
        }

        h2 {
          color: #FF7376;
          font-size: 18px;
          font-weight: normal;
        }

        .icon-red {
          margin: 0 auto;
          background: #FF7376;
          width: 48px;
          border-radius: 50%;
          padding: 10px;
        }

        .center svg {
          width: 48px;
          height: 48px;
          fill: #fff1f1;
        }
      </style>
    </head>
    <body>
        <header class="header" ${closeBtnEnabled ? '' : 'hidden'}>
          <div id="closeBtn" class="close">
            <svg data-vgl-gesture="close-icon" class="close-btn">
              <use xlink:href="#close-icon"></use>
            </svg>
          </div>
        </header>
        <div class="center">
          <div class="icon-red">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <circle cx="12" cy="19" r="2"/>
              <path d="M10 3h4v12h-4z"/>
              <path fill="none" d="M0 0h24v24H0z"/>
            </svg>
          </div>
          <h2>${errorMessage}</h2>
        </div>
    </body>
    <div id="icons" style="display: none;" >
      <svg aria-hidden="true" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>

          <symbol id="close-icon" viewBox="0 0 525.4 525.5" style="enable-background:new 0 0 525.4 525.5;" xml:space="preserve">
          <title>close-icon</title>
          <polygon 
            class="closeBtn" 
            points="467.6,8.9 262.9,213.6 58.1,8.9 9,58 213.8,262.7 9,467 58.1,516.1 262.9,311.8 467.6,516.1     516.7,467 311.9,262.7 516.7,58"/>
          </symbol>
          </defs>
      </svg>
    </div>
    <script>
      var closeBtn = document.getElementById("closeBtn");

      function closeVideo() {
        parent.window.parent.window.postMessage('close_video', '*')
      }

      closeBtn.addEventListener("click" , closeVideo);
    </script>
  </html>`;

  return URL.createObjectURL(new Blob([html], { type: 'text/html' }));
};

const error404Blob = generateErrorBlob();
const error404VideoBlob = generateErrorBlob(404, 'Oh dang, something went wrong with video.', true);
const error404ReplacementsBlob = generateErrorBlob(404, 'Oh dang, something went wrong with gathering replacements.');

export {
  error404Blob,
  error404VideoBlob,
  error404ReplacementsBlob,
  generateErrorBlob,
};
