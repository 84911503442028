import React from 'react';
import config from '../../../../lib/config';
import AdditionalEvents from './AdditionalEvents/AdditionalEvents';
import MeasurementURLs from './MeasurementURLs/MeasurementURLs';
import { MEASUREMENT_URL_TYPES } from './constants';

const { clickEvent, impressionEvent, additionalEvents } = config.get('campaigns');

function getImpressionURL(campaign) {
  const trackingEvents = campaign.get('tracking.events');
  const event = trackingEvents.find((x) => x.name === impressionEvent.name);
  return event?.url || '';
}

function currentEvents(campaign) {
  const trackingEvents = campaign.get('tracking.events');
  const events = [];
  additionalEvents.forEach(({ name, description }) => {
    const index = trackingEvents.findIndex((x) => x.name === name);
    if (index !== -1) {
      events.push({
        ...trackingEvents[index],
        description,
      });
    }
  });
  return events;
}

export const updateMeasurementEvent = (campaign, trackingEvent, url) => {
  const events = campaign.get('tracking.events');
  const event = { name: trackingEvent.name, url };
  const index = events.findIndex((evt) => evt.name === trackingEvent.name);
  if (index !== -1) {
    if (url) {
      campaign.updateTrackingEvent(event);
    } else {
      campaign.removeTrackingEvent(index);
    }
  } else if (url) {
    campaign.addTrackingEvent(event);
  }
};

const MobileMeasurementPartner = ({ campaign, isAdmin }) => {
  const saveMeasurementURLs = (type, url) => {
    switch (type) {
      case MEASUREMENT_URL_TYPES.destination:
        campaign.set('tracking.destination', url);
        break;
      case MEASUREMENT_URL_TYPES.click:
        updateMeasurementEvent(campaign, clickEvent, url);
        campaign.set('tracking.url', url);
        break;
      case MEASUREMENT_URL_TYPES.impression:
        updateMeasurementEvent(campaign, impressionEvent, url);
        break;
      default:
        break;
    }
  };

  const saveAdditionalEvents = (events) => {
    const trackingEvents = campaign.get('tracking.events');
    additionalEvents.forEach((event) => {
      const index = trackingEvents.findIndex((x) => x.name === event.name);
      if (index !== -1) {
        trackingEvents.splice(index, 1);
      }
    });
    const newEvents = events.map(({ name, url }) => ({ name, url }));
    const saveEvents = [...trackingEvents, ...newEvents].filter((event) => !!event.url);
    campaign.set('tracking.events', saveEvents);
  };

  return (
    <>
      <MeasurementURLs
        destinationURL={campaign.setDestinationUrl()}
        clickURL={campaign.getClickURL()}
        impressionURL={getImpressionURL(campaign)}
        platform={campaign.get('application').get('platform')}
        isAdmin={isAdmin}
        onChange={saveMeasurementURLs}
      />
      <AdditionalEvents
        currentEvents={currentEvents(campaign)}
        platform={campaign.get('application').get('platform')}
        onChange={saveAdditionalEvents}
      />
    </>
  );
};

export default MobileMeasurementPartner;
