import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { pieCountryStatsForTargetCPEEventKey } from 'app/constants/campaign';
import Group from 'templates/input-group/InputGroupContainer';
import Input from 'components/V2/Input/Input';
import Application from '../../models/Application';
import './TargetEventName.scss';

const { Field } = Group;

const NAME = 'Target Conversion Event Name';
const DESCRIPTION = 'The event which you want your campaign to optimize bids towards.';
const SEARCH_PLACEHOLDER = 'Search by Conversion Event';
const cpeTargetEventNameKey = 'budget.objective.cpe_target_event_name';
const postInstallEventsKey = 'postInstallEvents';

export function TargetEventNameSelector({
  value,
  onChange,
  options,
}) {
  const [showOptions, setShowOptions] = useState(false);
  const [keyword, setKeyword] = useState('');

  const matchedOptions = options?.filter(({ eventName }) => eventName.includes(keyword));
  const hasMatchedOptions = matchedOptions.length > 0;

  const hideOptions = () => setShowOptions(false);

  const onSearch = (e) => {
    setKeyword(e.target.value);
  };

  const onEventChange = (event) => {
    hideOptions();
    onChange(event);
  };

  useEffect(() => {
    window.document.addEventListener('click', hideOptions, false);
    return () => {
      window.document.removeEventListener('click', hideOptions, false);
    };
  }, []);

  return (
    <div className="target-event-name" onClick={(e) => e.nativeEvent.stopImmediatePropagation()}>
      <div className="target-event-name__input" onClick={() => setShowOptions(true)}>
        <div className={classnames('target-event-name__input__value', { 'target-event-name__input__value--placeholder': !value })}>{value ?? SEARCH_PLACEHOLDER}</div>
        <i className="target-event-name__input__icon material-icons">
          {showOptions ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
        </i>
      </div>
      <div className={classnames('target-event-name__options', { 'target-event-name__options--visible': showOptions })}>
        <div className="target-event-name__options__search__wrapper">
          <Input.Search
            className="target-event-name__options__search"
            placeholder={SEARCH_PLACEHOLDER}
            onChange={onSearch}
          />
        </div>
        {
          hasMatchedOptions && (
            <>
              <div className="target-event-name__options__head">
                <div className="target-event-name__options__column">Conversion Event</div>
                <div className="target-event-name__options__column">Events Received (Last 45d)</div>
              </div>
              <SimpleBar className="target-event-name__options__item__wrapper">
                {
                  matchedOptions.map((event) => (
                    <div
                      className={classnames('target-event-name__options__item', { 'target-event-name__options__item--selected': event.eventName === value })}
                      key={event.eventName}
                      onClick={() => onEventChange(event)}
                    >
                      <div className="target-event-name__options__column">{event.eventName}</div>
                      <div className="target-event-name__options__column">{event.totalEvents}</div>
                    </div>
                  ))
                }
              </SimpleBar>
            </>
          )
        }
        {
          !hasMatchedOptions && (
            <div className="target-event-name__options__nodata">
              <i className="target-event-name__options__nodata__icon material-icons">search_off</i>
              <div className="target-event-name__options__nodata__text">No events have been found</div>
            </div>
          )
        }
      </div>
    </div>
  );
}

TargetEventNameSelector.propTypes = {
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      eventName: PropTypes.string.isRequired,
      totalEvents: PropTypes.number.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function TargetEventName({ campaign }) {
  const cpeTargetEventName = campaign?.raw(cpeTargetEventNameKey);
  const postInstallEvents = campaign?.raw(postInstallEventsKey) ?? [];

  const loadPostInstallCountryStats = useCallback(async (cpeCampaign, eventName) => {
    const applicationId = cpeCampaign.get('application').get('id');
    if (applicationId) {
      const countryStats = await Application.getPostInstallEventCountryStats(applicationId, eventName) ?? [];
      const dynamicGeos = cpeCampaign.get('dynamicGeos') ?? [];
      const countryStatsWithTierNames = countryStats.map((stats) => {
        const dynamicGeo = dynamicGeos.find((country) => country.code === stats.code);
        return {
          ...stats,
          tierName: dynamicGeo?.tierName,
        };
      });
      cpeCampaign.set(pieCountryStatsForTargetCPEEventKey, countryStatsWithTierNames);
    }
  }, []);

  const handleTargetEventChange = useCallback(async (event) => {
    campaign?.set(cpeTargetEventNameKey, event.eventName);
    loadPostInstallCountryStats(campaign, event.eventName);
  }, [campaign]);

  useEffect(() => {
    if (cpeTargetEventName) loadPostInstallCountryStats(campaign, cpeTargetEventName);
  }, []);

  return (
    <Field
      name={NAME}
      description={DESCRIPTION}
    >
      <TargetEventNameSelector
        value={cpeTargetEventName}
        options={postInstallEvents}
        onChange={handleTargetEventChange}
      />
    </Field>
  );
}
