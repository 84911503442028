const creativeTags = [
  '360',
  '5s',
  '10s',
  '15s',
  '30s',
  '45s',
  '360video',
  '3page',
  'alcohol',
  'app footage',
  'asoi',
  'asoi_low',
  'audio_endcard',
  'augmentedreality',
  'blood',
  'brand',
  'cinematic',
  'client',
  'comic',
  'creative_tag_1',
  'creative_tag_2',
  'creative_tag_3',
  'creative_tag_4',
  'creative_testing',
  'custommraid_endcardonly',
  'custommraid_inlinevideo',
  'custommraid_fullscreenvideo',
  'devices visible',
  'dynamic localization',
  'dynamic template',
  'endcard only',
  'extendedtimer',
  'fullscreen',
  'fullscreenclickable_video',
  'generic',
  'harry potter approved',
  'human interaction',
  'iec',
  'incentivizer',
  'landscape',
  'native',
  'nativestorekit',
  'no timer',
  'nudity',
  'NRO',
  'N00_V',
  'N01_V',
  'N02_V',
  'N03_V',
  'N04_V',
  'N05_V',
  'N06_V',
  'N07_V',
  'N08_V',
  'N09_V',
  'N10_V',
  'N00_EC',
  'N01_EC',
  'N02_EC',
  'N03_EC',
  'N04_EC',
  'N05_EC',
  'N06_EC',
  'N07_EC',
  'N08_EC',
  'N09_EC',
  'N10_EC',
  'noncoppa',
  'piec',
  'portrait',
  'square',
  'sexually suggestive',
  'storekit',
  'subscription',
  'text',
  'timer',
  'unassigned app tag 4',
  'unassigned app tag 5',
  'unassigned creative tag 1',
  'unassigned creative tag 2',
  'unassigned creative tag 3',
  'unassigned creative tag 4',
  'unassigned creative tag 5',
  'unassigned tag 1',
  'unassigned tag 2',
  'unassigned tag 3',
  'voiceover',
  'violence',
  'virtualreality',
  'vungle',
  'weapons',
];

const creativeBlurbs = {
  'Dynamic Interstitial': 'Designed for either landscape or portrait video and optimized for interstitials',
  'Square Dynamic Interstitial': 'Designed for a square video that seamlessly fits all phone orientations',
  Showcase: 'Endcard has automatic animations to shuffle through product images',
  Looping: 'Optimal for shorter, catchy videos, that allows the video to repeat infinitely',
  Carousel: 'Endcard supports multiple images encouraging users to browse images',
  Gradient: 'Simplified endcard with a full screen image and overlayed CTA',
  Swipe: 'Full screen video, encouraging user interaction without interfering video visibility',
};

export {
  creativeBlurbs,
  creativeTags,
};
