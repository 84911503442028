import React from 'react';
import { compose, namespace } from '../../../app/app';
import Alert from './Alert';
import './alert.scss';

const ns = 'components.modals.alert';
const updateState = namespace(ns);
const props = (state) => state[ns];

class AlertContainer extends React.Component {
  constructor(p) {
    super(p);
    this.state = {};
  }

  render() {
    if (!this.props.show) {
      return null;
    }
    return (
      <Alert {...this.props} hideModal={this.props.actions.hideModal} />
    );
  }
}

const hideModal = ({ state }) => updateState(state, { show: false });

const actions = { hideModal };

export default compose(AlertContainer, { actions, props });
export {
  updateState,
};
