import React from 'react';
import Payments from '../../../../../components/Payments/PaymentsContainer';

const Fund = ({ props: { account, user }, actions: { processPayment, processPaymentState } }) => (
  <div className="views__account__fund">
    <Payments
      account={account}
      user={user}
      onProcessPaymentError={() => processPaymentState({ error: true })}
      onProcessPaymentFinish={(p) => processPayment({ account, ...p })}
      onProcessPaymentStart={() => processPaymentState({ start: true })}
    />
  </div>
);

export default Fund;
