import ReactDOM from 'react-dom';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import OverlayWrapper from './DropdownBase/OverlayWrapper';
import './dropdown.scss';

const noop = () => { };
const ModelElementId = 'vug-modal-root';
const MinBoundary = 140;
/**
 * Dropdown component
 */
class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.iconElement = null;
    this.containerElement = null;
    this.timer = null;
    this.willHidden = false;
    this.triggerDomRect = null;
    this.popupContainer = document.getElementById(ModelElementId);
    if (!this.popupContainer) {
      this.popupContainer = document.createElement('div');
      this.popupContainer.id = ModelElementId;
      this.popupContainer.style.position = 'absolute';
      this.popupContainer.style.top = '0';
      this.popupContainer.style.left = '0';
      this.popupContainer.style.width = '100%';
      const mountNode = document.body;
      mountNode.appendChild(this.popupContainer);
    }
    this.state = { visible: false };
    // store the container position
    this.position = {};
  }

  onDocumentClick = (e) => {
    const { prefixCls, closeOnClick } = this.props;
    let { target } = e;
    let isMenu = false;
    while (target) {
      if (target.classList && target.classList.contains(`${prefixCls}-overlay`)) {
        isMenu = true;
        break;
      }
      target = target.parentNode;
    }
    if (!isMenu || closeOnClick) {
      this.hideDropdown();
    }
  }

  addEventListener = () => {
    document.addEventListener('click', this.onDocumentClick);
    window.addEventListener('resize', this.onResize);
  }

  removeEventListener = () => {
    document.removeEventListener('click', this.onDocumentClick);
    window.removeEventListener('resize', this.onResize);
  }

  showDropdown = () => {
    if (this.props.preventDropdown) {
      return;
    }
    const { onVisibleChange } = this.props;
    this.willHidden = false;
    if (this.timer) clearTimeout(this.timer);
    this.setState({ visible: true }, () => {
      // Here we need timer to wait for popup element ready then we can update the popup style with animation
      this.timer = setTimeout(() => {
        this.toggleOverlay();
      });
      if (onVisibleChange !== noop) onVisibleChange(true);
    });
  }

  hideDropdown = () => {
    if (this.state.visible) {
      const { onVisibleChange } = this.props;
      this.willHidden = true;
      this.toggleOverlay(false);
      if (onVisibleChange !== noop) onVisibleChange(false);
    }
  }

  toggleOverlay = (show = true) => {
    const { transitionOffset } = this.props;
    const { bottom, top } = this.position;
    const attribute = bottom || top;
    // if transition offset less than 0 then popup position should be minus 10 when hidden
    const innerOffset = !show && transitionOffset > 0 ? transitionOffset : -10;
    const offset = (attribute.replace('px', '') * 1) + (show ? transitionOffset : -innerOffset);
    this.containerElement.style[bottom ? 'bottom' : 'top'] = `${offset}px`;
    this.containerElement.style.opacity = show ? 1 : 0;
  }

  initOverlayPosition = () => {
    const { placement, fixed } = this.props;
    this.triggerDomRect = this.iconElement.getBoundingClientRect();
    const { height, top } = this.triggerDomRect;
    const pos = {};
    const directionTop = top + height + MinBoundary > window.innerHeight || placement === 'topRight' || placement === 'top';
    pos[directionTop ? 'bottom' : 'top'] = directionTop ? -top - window.scrollY : top + height + window.scrollY;
    if (fixed) {
      pos.position = 'fixed';
      pos[directionTop ? 'bottom' : 'top'] = directionTop ? -top : top + height;
    }
    return pos;
  }

  onTransitionEnd = () => {
    if (this.willHidden) {
      this.containerElement = null;
      this.setState({ visible: false }, this.removeEventListener);
    } else {
      // Fix issue click popup item doesn't work(not 100% repro), user fast click may repro
      this.addEventListener();
    }
  }

  fixOverlayPosition = () => {
    if (this.containerElement) {
      const { placement, horizontalOffset } = this.props;
      const { left, width: triggerWidth } = this.triggerDomRect;
      const { width } = this.containerElement.getBoundingClientRect();
      let offset = 0;
      if (placement === 'right' || placement === 'topRight') {
        offset = triggerWidth - width;
      }
      if (placement === 'bottom' || placement === 'top') {
        offset = -(width - triggerWidth) / 2;
      }
      const totalLeft = left + horizontalOffset + offset;
      // check if out of screen
      if (document.documentElement.offsetWidth < totalLeft + width) {
        this.containerElement.classList.add('v2_component_dropdown-fix_right');
      } else {
        this.containerElement.classList.remove('v2_component_dropdown-fix_right');
        this.containerElement.style.setProperty('--left', `${totalLeft}px`);
      }
    }
  }

  triggerOnClick = (event) => {
    this.showDropdown();
    if (event) {
      event.stopPropagation();
    }
  }

  onResize = () => {
    this.triggerDomRect = this.iconElement.getBoundingClientRect();
    this.fixOverlayPosition();
  }

  componentDidMount() {
    if (this.props.visible) {
      this.showDropdown();
    }
  }

  componentWillUnmount() {
    if (this.timer) clearTimeout(this.timer);
    // Fix window event listener error
    this.removeEventListener();
  }

  componentDidUpdate(prevProps) {
    const { visible } = this.props;
    if (prevProps.visible !== undefined) {
      if (visible !== prevProps.visible) {
        if (visible) {
          this.showDropdown();
        } else {
          this.hideDropdown();
        }
      }
    }
  }

  render() {
    const { prefixCls, children, trigger } = this.props;
    const { visible } = this.state;
    const actionProps = {};
    if (trigger === 'click') {
      actionProps.onClick = this.triggerOnClick;
    }
    if (trigger === 'hover') {
      actionProps.onMouseEnter = this.triggerOnClick;
      actionProps.onMouseLeave = this.hideDropdown;
    }
    return (
      <div
        className={`${prefixCls}`}
        ref={(node) => { this.iconElement = node; }}
        onTransitionEnd={this.onTransitionEnd}
        {...actionProps}
      >
        {children}
        {visible && ReactDOM.createPortal(this.createOverlay(), this.popupContainer)}
      </div>
    );
  }

  createOverlay() {
    const {
      renderOverlay, prefixCls, closeOnClick, style, className,
    } = this.props;
    const overlay = renderOverlay ? renderOverlay(this.hideDropdown) : null;
    return (
      <OverlayWrapper
        className={classNames(`${prefixCls}-wrapper`, className)}
        overlay={overlay}
        prefixCls={prefixCls}
        onClick={(e) => {
          e.stopPropagation();
          if (!closeOnClick) {
            if (e.nativeEvent) e.nativeEvent.stopImmediatePropagation();
          }
        }}
        style={{ ...this.initOverlayPosition(), ...style }}
        makeRef={(node) => {
          if (!this.containerElement && node) {
            this.containerElement = node;
            const { bottom, top } = node.style;
            this.position = { bottom, top };
            this.fixOverlayPosition();
          }
        }}
      />
    );
  }
}

Dropdown.propTypes = {
  /**
   * prefix class name for the dropdown
   */
  prefixCls: PropTypes.string,
  /**
   * add class name to the popup
   */
  className: PropTypes.string,
  /**
   * fixed overlay
   */
  fixed: PropTypes.bool,
  /**
   * style of drop down
   */
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  /**
   * render overlay
   */
  renderOverlay: PropTypes.func.isRequired,
  /**
   * whether close the dropdown when click overlay
   */
  closeOnClick: PropTypes.bool,
  /**
   * placement for the dropdown, eg: bottomLeft, bottomRight, bottom(center)
   */
  placement: PropTypes.oneOf(['bottomLeft', 'right', 'bottom', 'topRight', 'top']),
  /**
   * show or hide dropdown
   */
  visible: PropTypes.oneOfType([() => undefined, PropTypes.bool]),
  /**
   * transition offset when dropdown overlay display, default is 10.
   * If this value > 0, transition will move to -> bottom,
   * else if < 0, transition will move bottom -> to,
   * no transition style if = 0.
   */
  transitionOffset: PropTypes.number,
  /**
   * when visible changed will trigger this call back
   */
  onVisibleChange: PropTypes.func,
  /**
   * user can set this prop to adjust the overlay horizontal position offset
   * note: if placement is `left` so the left will be applied, otherwise the right will be applied
   */
  horizontalOffset: PropTypes.number,
  /**
   * action to show or hide popup
   */
  trigger: PropTypes.oneOf(['click', 'hover']),
  /**
   * disable the function of opening dropdown overlay
   * */
  disableDropdown: PropTypes.bool,
};
Dropdown.defaultProps = {
  prefixCls: 'v2_component_dropdown',
  style: {},
  className: null,
  fixed: false,
  closeOnClick: false,
  placement: 'bottomLeft',
  visible: undefined,
  transitionOffset: 10,
  onVisibleChange: noop,
  horizontalOffset: 0,
  trigger: 'click',
  disableDropdown: false,
};

export default Dropdown;
