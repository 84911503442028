import React from 'react';
import Radio from '../../../../../components/Form/Radio/Radio';

const radio = (model, field, options, raw = true, disabled = false, callback = () => {}, needConfirm = false, selectedValue) => (
  <Radio
    name={field.replace(/\./, '_')}
    value={selectedValue ?? (raw ? model.raw(field) : model.get(field))}
    onChange={(v) => {
      if (!disabled && !needConfirm) {
        model.set(field, v);
      }
      callback(v);
    }}
  >
    {options.map(([value, label, adminOnly]) => (
      <Radio.Option
        key={value}
        value={value}
        label={label}
        disabled={disabled}
        adminOnly={adminOnly}
      />
    ))}
  </Radio>
);

export default radio;
