import React from 'react';
import { compose } from 'app/app';
import Details from './Details';

const createNamespace = 'views.campaigns.edit';
const ns = 'views.campaigns.edit.detail';
const props = (state) => ({
  ...state[ns],
  ...state[createNamespace],
});

class ConfirmContainer extends React.Component {
  constructor(p) {
    super(p);
    this.state = {};
  }

  render() {
    return (
      <Details {...this.props} />
    );
  }
}

const actions = {};

export default compose(ConfirmContainer, { actions, props });
