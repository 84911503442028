import React from 'react';
import Button from '../../Button/Button';
import Link from '../../Link/Link';
import CombinedFiltersWithSearch from '../../V2/Filters/CombinedFiltersWithSearch/CombinedFiltersWithSearch';
import './filters.scss';

const Filters = ({
  cta,
  to,
  filters,
  onFilterChange,
  searchPlaceholderText = 'Search...',
  defaultCheckedConditions,
}) => (
  <div className="component__filters_container">
    <CombinedFiltersWithSearch
      filters={filters}
      placeholder={searchPlaceholderText}
      onChange={onFilterChange}
      defaultCheckedConditions={defaultCheckedConditions}
    />
    {cta && cta.length > 0 && (
      <div className="cta">
        <Link to={to}>
          <Button>{cta}</Button>
        </Link>
      </div>
    )}
  </div>
);

export default Filters;
