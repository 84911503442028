import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';
import './InfoBar.scss';

const InfoBar = ({
  remaining,
  text,
  type,
  icon,
  button,
}) => (
  <div className={`skcid-info-bar ${type}`}>
    <div className={button ? 'upgrade' : 'info'}>
      {icon || <Icon.InfoOutline />}
      <div className="p2">
        { text || `You have [${remaining}] 
          amount of SKAdNetwork Campaign IDs left. 
          If you’ve had more than 100 campaign/creative combinations, 
          Vungle will reuse SK Campaign IDs due to the ID limit (1-100). 
          There may be cases where installs may be attributed to current campaign/creative combo instead of the original 
          campaign/creative served due to the install postback delay.`}
      </div>
    </div>
    {button && button}
  </div>
);

InfoBar.propTypes = {
  remaining: PropTypes.number,
  text: PropTypes.element,
  type: PropTypes.oneOf(['error', 'warning', 'info']),
  icon: PropTypes.elementType,
};

InfoBar.defaultProps = {
  remaining: 0,
  text: '',
  type: 'warning',
  icon: null,
};

export default InfoBar;
