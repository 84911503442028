import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { FILTER_KEY_CAMPAIGN_SKADNETWORK } from 'app/constants/filters';
import Multi from '../Base/Multi/Multi';
import SkAdNetworkPanel from '../Base/Panels/SkAdNetworkPanel';
import { skadnetworkStatuses } from '../constant';

const SKAdnetwork = ({
  dropdownFixed,
  prefixCls,
  visible,
  defaultCheckedNodes,
  onVisibleChange,
  onChange,
  onRemove,
}) => (
  <Multi
    dropdownFixed={dropdownFixed}
    visible={visible}
    data={skadnetworkStatuses}
    filterKey={FILTER_KEY_CAMPAIGN_SKADNETWORK}
    defaultCheckedKeys={defaultCheckedNodes}
    renderOverlay={() => (
      React.createElement(SkAdNetworkPanel, {
        prefixCls,
        defaultCheckedNodes,
        onVisibleChange,
        onChange,
      })
    )}
    onVisibleChange={onVisibleChange}
    onRemove={onRemove}
  />
);

SKAdnetwork.propTypes = {
  dropdownFixed: PropTypes.bool,
  prefixCls: PropTypes.string,
  visible: PropTypes.bool,
  defaultCheckedNodes: PropTypes.arrayOf(PropTypes.any),
  onVisibleChange: PropTypes.func,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};

SKAdnetwork.defaultProps = {
  dropdownFixed: false,
  prefixCls: 'v2_component_filter_skadnetwork',
  visible: false,
  defaultCheckedNodes: [],
  onVisibleChange: noop,
  onChange: noop,
  onRemove: noop,
};

export default SKAdnetwork;
