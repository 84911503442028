import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Checkbox from '../../Checkbox/Checkbox';

import MenuContext, { MenuContextTypes } from './context';

class InnerMenuItem extends React.Component {
  renderCheckbox = (prefixCls) => {
    const {
      context, children, dataKey, disabled,
    } = this.props;

    const checked = context.checkedKeys.indexOf(dataKey) !== -1;

    return (
      <Checkbox
        className={`${prefixCls}-checkbox`}
        checked={checked}
        label={children}
        onChange={this.onChange}
        disabled={disabled}
      />
    );
  };

  onChange = () => {
    const { context, dataKey, single } = this.props;
    const { toggleItem } = context;
    toggleItem({ dataKey, single });
  };

  componentDidMount() {
    const { context, data, dataKey } = this.props;
    context.registerValue(dataKey, data);
  }

  componentDidUpdate(prevProps) {
    const { dataKey: prevDataKey } = prevProps;
    const { context, data, dataKey } = this.props;
    if (prevDataKey !== dataKey) {
      context.registerValue(dataKey, data);
      context.unregisterValue(prevDataKey);
    }
  }

  componentWillUnmount() {
    const { context, dataKey } = this.props;
    context.unregisterValue(dataKey);
  }

  render() {
    const { context, className } = this.props;
    const { prefixCls: contextPrefixCls } = context;

    const prefixCls = `${contextPrefixCls}-item`;

    return (
      <div className={classnames(prefixCls, className)}>
        {this.renderCheckbox(prefixCls)}
      </div>
    );
  }
}

InnerMenuItem.context = MenuContextTypes;

InnerMenuItem.propTypes = {
  /**
   * className of MenuItem
   */
  className: PropTypes.string,

  /**
   * data of menu item
   */
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]).isRequired,

  /**
   * unique key of the data.
   */
  dataKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,

  /**
   * is checkbox disabled
   */
  disabled: PropTypes.bool,

  /**
   * only one option can be selected at the same time.
   */
  single: PropTypes.bool,
};

InnerMenuItem.defaultProps = {
  className: '',
  disabled: false,
  single: false,
};

const MenuItem = (props) => (
  <MenuContext.Consumer>
    {(context) => <InnerMenuItem {...props} context={context} />}
  </MenuContext.Consumer>
);

MenuItem.displayName = 'MenuItem';

export default MenuItem;
