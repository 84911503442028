import config from 'lib/config';
import { deleteResource, get, post } from '../lib/http';
import Model from './BaseModel';
import {
  getSecretKey,
} from '../app/graphql/utils/user';

class User extends Model {
  toInviteObject() {
    const obj = {};
    obj.name = this.get('name');
    obj.email = this.get('email');
    obj.role = this.raw('role');
    obj.account = this.raw('account.id');
    return obj;
  }

  toEditObject() {
    const obj = {};
    obj.name = this.raw('name');
    obj.role = this.raw('role');
    return obj;
  }
}
const baseUrl = `${config.get('authUrl')}users`;

User.delete = (user) => deleteResource(`${baseUrl}/${user.get('id')}`);
User.getAll = Model.makeGetAll(baseUrl);
User.get = (id) => get(`${baseUrl}/${id}`);
User.invite = (user) => post(`${baseUrl}/invite`, user.toInviteObject());
User.reinvite = (email) => post(`${config.get('authUrl')}reinvite`, { email });
User.url = (id) => `${baseUrl}/${id}`;
User.make = Model.make(User);
User.save = (user) => post(`${baseUrl}/${user.get('id')}`, user.toEditObject());
User.regenerateSecretKey = (user) => getSecretKey(user.id);

export default User;
