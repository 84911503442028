import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { FILTER_KEY_CREATIVEQA_STATUS } from 'app/constants/filters';
import Multi from '../Base/Multi/Multi';
import CreativeQAStatusPanel from '../Base/Panels/CreativeQAStatusPanel';
import './creativeQAStatus.scss';

const CreativeQAStatus = ({
  data,
  dropdownFixed,
  height,
  prefixCls,
  visible,
  defaultCheckedNodes,
  onVisibleChange,
  onChange,
  onRemove,
}) => (
  <Multi
    data={data}
    dropdownFixed={dropdownFixed}
    visible={visible}
    filterKey={FILTER_KEY_CREATIVEQA_STATUS}
    defaultCheckedKeys={defaultCheckedNodes}
    renderOverlay={() => (
      React.createElement(CreativeQAStatusPanel, {
        prefixCls,
        defaultCheckedNodes,
        onVisibleChange,
        onChange,
        data,
        height,
      })
    )}
    onVisibleChange={onVisibleChange}
    onRemove={onRemove}
  />
);

CreativeQAStatus.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  dropdownFixed: PropTypes.bool,
  height: PropTypes.number,
  prefixCls: PropTypes.string,
  visible: PropTypes.bool,
  defaultCheckedNodes: PropTypes.arrayOf(PropTypes.any),
  onVisibleChange: PropTypes.func,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};

CreativeQAStatus.defaultProps = {
  data: [],
  dropdownFixed: false,
  height: 275,
  prefixCls: 'v2_component_filter_status',
  visible: false,
  defaultCheckedNodes: [],
  onVisibleChange: noop,
  onChange: noop,
  onRemove: noop,
};

export default CreativeQAStatus;
