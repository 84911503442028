import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { METRIC_TYPES } from '../../../../lib/cache/constant';
import MetricDropdown from '../../Table/component/MetricDropdown';
import './metricMenu.scss';

const prefixCls = 'v2_component_report-metric';

const renderCount = (amount) => (`Sort (${amount}/10) selected columns`);

const MetricMenu = ({
  metricType, metricUserId, metricGroup, onMetricsChange, maxSelected, showSelectAll, propsRenderCount, defaultKey,
}) => (
  <MetricDropdown
    searchPlaceholder="Search metrics..."
    linkText="Reset to default"
    metricOverlayWidth={700}
    metricOverlayHeight={500}
    showSelectAll={showSelectAll}
    maxSelected={maxSelected}
    dropdownClassName={prefixCls}
    metricType={metricType}
    metricUserId={metricUserId}
    metricGroup={metricGroup}
    onMetricsChange={onMetricsChange}
    renderCount={propsRenderCount || renderCount}
    defaultKey={defaultKey}
  />
);

MetricMenu.propTypes = {
  /**
   * user id for cache metrics
   */
  metricUserId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,

  /**
   * table used in the type page for cache
   */
  metricType: PropTypes.oneOf(METRIC_TYPES).isRequired,

  /**
   * metric tree data
   */
  metricGroup: PropTypes.arrayOf(
    PropTypes.object,
  ).isRequired,

  /**
   * handle metrics change
   */
  onMetricsChange: PropTypes.func,

  /**
   * limit of checked metric
   */
  maxSelected: PropTypes.number,
};

MetricMenu.defaultProps = {
  onMetricsChange: noop,
  maxSelected: 0,
};

export default MetricMenu;
