import React from 'react';
import clsx from 'classnames';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import Message from '../FlagMessage/Message';
import TooltipCutoff from '../Tooltip/TooltipCutoff';
import { COPY_SUCCESS_INFO } from './constants';
import './copy.scss';

export function copy(message, successCallback) {
  try {
    const textField = document.createElement('textarea');
    textField.innerText = message;
    textField.style.position = 'fixed';
    textField.style.left = 0;
    textField.style.top = 0;
    textField.style.opacity = 0;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    if (typeof successCallback === 'function') {
      successCallback();
    }
  } catch (ex) {
    // do nothing, copy does not exist in the browser
  }
}

const Copy = (props) => {
  const {
    className,
    value,
    disabled,
    onCopy,
  } = props;

  const prefixClsx = 'v2_component_copy';

  return (
    <div
      className={clsx(
        prefixClsx,
        className,
        {
          [`${prefixClsx}-disabled`]: disabled,
        },
      )}
      onClick={() => {
        if (!disabled) {
          Message.success(COPY_SUCCESS_INFO);
          copy(value, onCopy);
        }
      }}
    >
      <i className="material-icons">content_copy</i>
      <TooltipCutoff calculatedStyles={{ fontSize: '12px' }} title={value} titleWrapper={(v) => <span>{v}</span>} />
    </div>
  );
};

Copy.propTypes = {
  /**
   * class name.
   */
  className: PropTypes.string,
  /**
   * Text to display.
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * If `true` click event will be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * On copy action
   */
  onCopy: PropTypes.func,
};

Copy.defaultProps = {
  className: null,
  value: null,
  disabled: false,
  onCopy: noop,
};

export default Copy;
