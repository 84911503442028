/* eslint-disable react/display-name */
import React from 'react';
import { convert } from 'lib/date';
import { firstLetterUpper } from 'lib/lib';
import { canModifyCreatives } from 'lib/helpers/authUser';
import { getEditUrl } from 'lib/helpers/creatives/creativeIndex';
import Link from 'components/Link/Link';
import { Pulsation, TooltipCutoff } from 'components/V2';
import { TableNameColumn } from 'components/V2/List';
import { pauseCreative } from 'components/Modals/PauseCreative/PauseCreative';
import { LTVMetrics, standardMetrics } from 'components/V2/Metrics/Metrics';
import { makeColumn, makeWithMetricColumns } from 'components/V2/List/Columns/columns';
import triggerConfirm from 'components/Modals/ConfirmAction/triggerConfirm';
import CreativeStatus from 'components/Creatives/ServingStatus/ServingStatus';
import previewCreative from 'components/Creatives/Preview/previewCreative';
import { makeListFilterQuery } from 'components/V2/List/ListFilter/helper';
import Label from 'components/V2/Label/Label';
import CreativeIdWithQRCode from 'components/Creatives/CreativeIdWithQRCode/CreativeIdWithQRCode';
import {
  defaultSort,
  EXTRA_QUERY_KEYS,
  COLUMN_ACCOUNT,
  COLUMN_CREATIVE_NAME,
  COLUMN_CREATIVE_STATUS,
  COLUMN_APPROVAL,
  COLUMN_AD_FORMAT,
  COLUMN_LANGUAGE,
  COLUMN_APPLICATION,
  COLUMN_TEMPLATE,
  COLUMN_ATTACHED_CAMPAIGNS,
  COLUMN_DATE_CREATED,
  COLUMN_DATE_UPDATED,
  COLUMN_TAGS,
  TABLE_ACTION_APPROVE_CREATIVE,
  TABLE_ACTION_CLONE_CREATIVE,
  TABLE_ACTION_EDIT_CREATIVE,
  TABLE_ACTION_PREVIEW_CREATIVE,
  TABLE_ACTION_PREVIEW_IN_CREATIVE_TESTING_TOOL,
  TABLE_ACTION_REJECT_CREATIVE,
  TABLE_ACTION_ACTIVATE_CREATIVE,
  TABLE_ACTION_PAUSE_CREATIVE,
} from './constants';

const baseUrl = 'creatives';
export const initMetrics = (metricCache, attributeMetrics) => {
  const value = metricCache.get();
  if (value) {
    return value;
  }

  const defaultMetrics = attributeMetrics.metrics.filter((m) => m.default);
  /*
  * Save default metrics if current user not do any selection.
  * This will prevent the metric dropdown render empty selection.
  */
  metricCache.save(defaultMetrics);

  return defaultMetrics;
};

const changeServingStatus = (creative, execChangeCreativeStatus) => {
  const [
    header, status, message,
  ] = ['Activate Creative', 'activate', 'Are you sure you want to activate this Creative?'];

  if (creative.get('status') === 'active') {
    pauseCreative(creative, (args) => execChangeCreativeStatus({
      model: creative,
      ...args,
    }))();
  } else {
    triggerConfirm({
      header,
      message,
      name: creative.get('name'),
      onConfirm: () => {
        execChangeCreativeStatus({ creative, status });
      },
    });
  }
};

export const makeAttributeMetrics = (isMissionControl) => {
  const metrics = [
    {
      key: COLUMN_CREATIVE_STATUS,
      name: 'Status',
      default: true,
    },
    {
      key: COLUMN_APPLICATION,
      name: 'Application',
      default: true,
    },
    {
      key: COLUMN_TEMPLATE,
      name: 'Template',
      default: true,
    },
    {
      key: COLUMN_APPROVAL,
      name: 'Approval',
      default: false,
    },
    {
      key: COLUMN_AD_FORMAT,
      name: 'Ad Format',
      default: true,
    },
    {
      key: COLUMN_LANGUAGE,
      name: 'Language',
      default: true,
    },
    {
      key: COLUMN_ATTACHED_CAMPAIGNS,
      name: 'Attached Campaigns',
      default: true,
    },
    {
      key: COLUMN_DATE_UPDATED,
      name: 'Date Updated',
      default: true,
    },
    {
      key: COLUMN_DATE_CREATED,
      name: 'Date Created',
      default: false,
    },
  ];

  if (isMissionControl) {
    metrics.push({
      key: COLUMN_ACCOUNT,
      name: 'Account',
      default: false,
    });
    metrics.push({
      key: COLUMN_TAGS,
      name: 'Tags',
      default: true,
    });
  }

  return {
    name: 'Attributes',
    metrics,
  };
};

export const makeCreativeNameColumn = (isMissionControl, qrCodeCreativeId, setQrCodeCreativeId) => ({
  ...makeColumn({
    fixed: true,
    minWidth: 290,
    title: 'Creative',
    accessor: COLUMN_CREATIVE_NAME,
  }),
  Cell: ({ original }) => {
    const { creative, isTotalRow } = original;
    const creativeId = creative?.get('id');
    const isQRCodeVisible = creativeId === qrCodeCreativeId;
    const setIsQRCodeVisible = (isVisible) => setQrCodeCreativeId(isVisible ? creativeId : null);
    if (isTotalRow) {
      return 'Total';
    }
    return (
      <TableNameColumn
        name={creative.getFriendlyName()}
        id={creativeId}
        platform={creative.get('application.platform')}
        thumbnail={creative.get('application.store.thumbnail')}
        to={isMissionControl ? `/creatives/${creativeId}` : getEditUrl(creative)}
        customIdComponent={(
          <CreativeIdWithQRCode
            isMissionControl={isMissionControl}
            creativeId={creativeId}
            isQRCodeVisible={isQRCodeVisible}
            setIsQRCodeVisible={setIsQRCodeVisible}
            adjustLeftPosition={230}
            adjustTopPosition={-150}
          />
        )}
      />
    );
  },
});

export const makeCreativeTagsColumn = () => ({
  ...makeColumn({
    title: 'Tags',
    disableSort: true,
    accessor: COLUMN_TAGS,
  }),
  Cell: ({ original }) => {
    const { creative, isTotalRow } = original;
    if (isTotalRow) {
      return '-';
    }
    return (
      <div className="pills">
        {(creative.get('status') === 'paused' || creative.get('archived') === true) && (
          <Label className="pill pill--paused">paused</Label>
        )}
        {creative.get('admin_status') === 'pending' && (
        <Link includeLink to={`/${baseUrl}/${creative.get('id')}/review`}>
          <Label className="pill pill--pending">needs review</Label>
        </Link>
        )}
        {creative.get('admin_status') === 'rejected' && (
          <Label className="pill pill--rejected">rejected</Label>
        )}
        {creative.get('is_deleted') && (
          <Label className="pill pill--archived">deleted</Label>
        )}
      </div>
    );
  },
});

export const makeCreativeAccountColumn = () => ({
  ...makeColumn({
    title: 'Account',
    accessor: COLUMN_ACCOUNT,
  }),
  Cell: ({ original }) => {
    const { creative, isTotalRow } = original;
    if (isTotalRow) {
      return '-';
    }

    return (
      <TableNameColumn
        hidePlatform
        hideThumbnail
        id={creative.get('account.id')}
        name={creative.get('account.name')}
        to={`/accounts/${creative.get('account.id')}`}
      />
    );
  },
});

export const makeAttributeColumns = (isMissionControl, authUser, execChangeCreativeStatus) => {
  const columns = [
    {
      ...makeColumn({
        title: 'Status',
        accessor: COLUMN_CREATIVE_STATUS,
      }),
      Cell: ({ original }) => {
        const { creative, isTotalRow } = original;
        if (isTotalRow) {
          return '-';
        }
        const status = creative.isEligibleToServe() ? 'active' : 'inactive';
        return (
          <CreativeStatus
            resource="Creative"
            creative={creative}
            innerContentTextOverride={status}
            showTooltip={canModifyCreatives()}
            editUrl={getEditUrl(creative)}
            changeStatus={() => changeServingStatus(creative, execChangeCreativeStatus)}
          >
            <Pulsation status={status} labelText={firstLetterUpper(status)} />
          </CreativeStatus>
        );
      },
    },
    {
      ...makeColumn({
        minWidth: 240,
        title: 'Application',
        accessor: COLUMN_APPLICATION,
      }),
      Cell: ({ original }) => {
        const application = original?.creative?.get('application');
        const { isTotalRow } = original;
        if (isTotalRow) {
          return '-';
        }
        return (
          <TableNameColumn
            hidePlatform
            hideThumbnail
            name={application?.get('name')}
            id={application?.get('id')}
            to={`/applications/${application?.get('id')}`}
          />
        );
      },
    },
    {
      ...makeColumn({
        minWidth: 200,
        title: 'Template',
        accessor: COLUMN_TEMPLATE,
      }),
      Cell: ({ original }) => {
        const { creative, isTotalRow } = original;
        if (isTotalRow) {
          return '-';
        }
        return (
          <TooltipCutoff
            title={creative?.get('template.name')}
            titleWrapper={() => (
              <Link to={`/templates/${creative?.get('template.id')}`} className="body__link">
                {creative?.get('template.name')}
              </Link>
            )}
          />
        );
      },
    },
    {
      ...makeColumn({
        title: 'Approval',
        accessor: COLUMN_APPROVAL,
      }),
      Cell: ({ original }) => {
        const { creative, isTotalRow } = original;
        if (isTotalRow) {
          return '-';
        }
        return <TooltipCutoff title={firstLetterUpper(creative.get('admin_status'))} />;
      },
    },
    {
      ...makeColumn({
        title: 'Ad Format',
        accessor: COLUMN_AD_FORMAT,
      }),
      Cell: ({ original }) => {
        const { creative, isTotalRow } = original;
        if (isTotalRow) {
          return '-';
        }
        return <TooltipCutoff title={creative.get('format')} />;
      },
    },
    {
      ...makeColumn({
        title: 'Language',
        accessor: COLUMN_LANGUAGE,
      }),
      Cell: ({ original }) => {
        const { creative, isTotalRow } = original;
        if (isTotalRow) {
          return '-';
        }
        return <TooltipCutoff title={creative.get('language.name')} />;
      },
    },
    {
      ...makeColumn({
        title: 'Attached Campaigns',
        accessor: COLUMN_ATTACHED_CAMPAIGNS,
      }),
      Cell: ({ original }) => {
        const { creative, isTotalRow } = original;
        if (isTotalRow) {
          return '-';
        }
        const campaignIDs = creative.get('campaignIDs');
        const path = isMissionControl
          ? `/creatives/${creative.get('id')}`
          : `/creatives/${creative.get('id')}/campaigns`;
        return (
          <Link to={path}>
            <p className="body__link">
              {campaignIDs.length}
            </p>
          </Link>
        );
      },
    },
    {
      ...makeColumn({
        title: 'Date Created',
        accessor: COLUMN_DATE_CREATED,
      }),
      Cell: ({ original }) => {
        const { creative, isTotalRow } = original;
        if (isTotalRow) {
          return '-';
        }
        return creative.get('created') ? <TooltipCutoff title={convert(creative.get('created'), 'DD MMM YYYY')} /> : null;
      },
    },
    {
      ...makeColumn({
        title: 'Date Updated',
        accessor: COLUMN_DATE_UPDATED,
      }),
      Cell: ({ original }) => {
        const { creative, isTotalRow } = original;
        if (isTotalRow) {
          return '-';
        }
        return creative.get('updated') ? <TooltipCutoff title={convert(creative.get('updated'), 'DD MMM YYYY')} /> : null;
      },
    },
  ];

  if (isMissionControl) {
    columns.push(makeCreativeAccountColumn());
    columns.push(makeCreativeTagsColumn());
  }

  return columns;
};

export const makeGetCreativesQuery = (
  caches,
  query = {},
) => makeListFilterQuery({
  dimension: 'creative',
  caches,
  query,
  defaultSort,
  extraQueryKeys: EXTRA_QUERY_KEYS,
});

const getTableActionList = (setQrCodeCreativeId) => ([
  {
    key: TABLE_ACTION_APPROVE_CREATIVE,
    action: ({ creative, execChangeCreativeStatus }) => {
      execChangeCreativeStatus({
        creative,
        status: 'approved',
      });
    },
    validate: ({ isAdmin }) => isAdmin,
  },
  {
    key: TABLE_ACTION_CLONE_CREATIVE,
    validate: ({ creative, isAdmin }) => isAdmin && creative.get('format') === 'vungle_mraid',
    action: ({ creative, history }) => {
      history.push(`/creatives/create?clone=${creative.get('id')}`);
    },
  },
  {
    key: TABLE_ACTION_EDIT_CREATIVE,
    validate: ({ canModify }) => canModify,
    action: ({ creative, history, isMissionControl }) => {
      if (isMissionControl) {
        history.push(`/creatives/${creative.get('id')}`);
      } else {
        history.push(getEditUrl(creative));
      }
    },
  },
  {
    key: TABLE_ACTION_PREVIEW_CREATIVE,
    action: ({ creative }) => previewCreative(creative, true),
  },
  {
    key: TABLE_ACTION_PREVIEW_IN_CREATIVE_TESTING_TOOL,
    validate: ({ isAdmin }) => isAdmin,
    action: ({ creative }) => setQrCodeCreativeId(creative.get('id')),
  },
  {
    key: TABLE_ACTION_REJECT_CREATIVE,
    action: ({ creative, execChangeCreativeStatus }) => {
      execChangeCreativeStatus({
        creative,
        status: 'rejected',
      });
    },
    validate: ({ isAdmin }) => isAdmin,
  },
  {
    key: TABLE_ACTION_ACTIVATE_CREATIVE,
    validate: ({ creative, canModify }) => (canModify && creative.get('status') === 'paused'),
    action: ({ creative, execChangeCreativeStatus }) => changeServingStatus(creative, execChangeCreativeStatus),
  },
  {
    key: TABLE_ACTION_PAUSE_CREATIVE,
    validate: ({ creative, canModify }) => (canModify && creative.get('status') === 'active'),
    action: ({ creative, execChangeCreativeStatus }) => changeServingStatus(creative, execChangeCreativeStatus),
  },
]);

export const makeTableActionList = (creative, isAdmin, setQrCodeCreativeId) => {
  const validActions = getTableActionList(setQrCodeCreativeId).filter(({ validate }) => (
    !creative
    || !validate
    || validate({ creative, canModify: canModifyCreatives(), isAdmin })
  ));
  return validActions.map((a) => a.key);
};

export const execTableAction = (key, row, history, execChangeCreativeStatus, isMissionControl, setQrCodeCreativeId) => {
  const { creative } = row;
  const context = getTableActionList(setQrCodeCreativeId).find((a) => a.key === key);
  context.action({
    history,
    creative,
    isMissionControl,
    execChangeCreativeStatus,
  });
};

export const attributeMetrics = makeAttributeMetrics;

export const metricsGroup = (isMissionControl) => [
  attributeMetrics(isMissionControl),
  standardMetrics,
  LTVMetrics,
];

const attributeColumns = makeAttributeColumns;

export const allColumns = (isMissionControl, authUser, execChangeCreativeStatus) => makeWithMetricColumns(
  attributeColumns(isMissionControl, authUser, execChangeCreativeStatus),
);

export const columnsFromMetrics = (metrics, isMissionControl, qrCodeCreativeId, setQrCodeCreativeId, authUser, execChangeCreativeStatus) => {
  const columns = [
    makeCreativeNameColumn(isMissionControl, qrCodeCreativeId, setQrCodeCreativeId),
    ...metrics.map((m) => allColumns(isMissionControl, authUser, execChangeCreativeStatus).find((c) => (m.key === c.id))),
  ];
  return columns;
};
