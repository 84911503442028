import { dispatchAction } from '../../../app/app';
import { updateState } from './ContentContainer';

const triggerModal = ({
  title, render, link, hasButtons = false,
}) => {
  dispatchAction('SHOW_MODAL_ACTION', ({ state }) => updateState(state, {
    show: true, title, render, link, hasButtons,
  }));
};

const hideModal = () => {
  dispatchAction('HIDE_MODAL_ACTION', ({ state }) => updateState(state, { show: false, render: null }));
};

export default triggerModal;

export {
  triggerModal,
  hideModal,
};
