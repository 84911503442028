import { noop } from 'lodash';

const MessageManager = {
  list: new Map(),
  cb: noop,

  add(msg) {
    this.list.set(msg.id, msg);
    this.cb();
    if (msg.timeout > 0) {
      setTimeout(() => {
        this.delete(msg.id);
      }, msg.timeout);
    }
  },

  delete(msgid) {
    this.list.delete(msgid);
    this.cb();
  },

  reg(cb) {
    this.cb = cb;
  },

  getAll() {
    return [...this.list.values()];
  },

};

export default MessageManager;
