import React from 'react';
import Icon from '../../components/Icon/Icon';
import './thumbnail.scss';

const ApplicationThumbnail = ({ application, leftAlign = false }) => (
  <div className={`template__application-thumbnail ${leftAlign && 'template__application-thumbnail--left_align'}`}>
    {application && (
      <img src={application.get('store.thumbnail')} />
    )}
    {application && (
      <div className={`template__application-thumbnail__icon template__application-thumbnail__icon--${application.get('platform')?.toLowerCase()}`}>
        {Icon.forPlatform(application.get('platform'))}
      </div>
    )}
    {!application && (
      <div className="at-placeholder">
        <i className="material-icons">phone_iphone</i>
      </div>
    )}
  </div>
);

export default ApplicationThumbnail;
