import React from 'react';
import { set } from '../../lib/session';
import Table from './Table';
import './table.scss';

class TableContainer extends React.Component {
  constructor(p) {
    super(p);
    this.state = {
      campaignColumns: {
        App: true,
        Campaign: true,
        ID: true,
        Dates: true,
        Budget: true,
        Daily: true,
        Bid: true,
        Type: true,
        Creatives: true,
        'Remaining Budget': true,
      },
      creativeColumns: {
        App: true,
        Creative: true,
        ID: true,
        Templates: true,
        Language: true,
      },
      reportColumns: {
        Name: true,
        App: true,
        Campaign: true,
        Creative: true,
        Impressions: true,
        Clicks: true,
        Installs: true,
        Spend: true,
        eCPM: true,
        eCPC: true,
        CTR: true,
        CVR: true,
        CPI: true,
        'D1 Retention': true,
        'D3 Retention': true,
        'D7 Retention': true,
        'D1 Revenue': true,
        'D3 Revenue': true,
        'D7 Revenue': true,
        'D1 ROAS': true,
        'D3 ROAS': true,
        'D7 ROAS': true,
        'D1 Active Users': false,
        'D3 Active Users': false,
        'D7 Active Users': false,
        'D14 Active Users': false,
        'D15 Active Users': false,
        'D30 Active Users': false,
        'D14 Retention': false,
        'D15 Retention': false,
        'D30 Retention': false,
        'D1 Paying Users': false,
        'D3 Paying Users': false,
        'D7 Paying Users': false,
        'D14 Paying Users': false,
        'D15 Paying Users': false,
        'D30 Paying Users': false,
        'D14 Revenue': false,
        'D15 Revenue': false,
        'D30 Revenue': false,
        'D14 ROAS': false,
        'D15 ROAS': false,
        'D30 ROAS': false,
        // 'Win Rate': true,
      },
    };
  }

  toggleColumn = (title, type) => {
    const columnType = `${type}Columns`;
    const currentColumns = this.state[columnType];
    const changedColumn = {};
    changedColumn[title] = !currentColumns[title];
    const newDisplayColumns = { ...currentColumns, ...changedColumn };
    set(columnType, newDisplayColumns);
    this.setState({ [columnType]: newDisplayColumns });
  }

  render() {
    return (
      <Table className="this" {...this.state} {...this.props} toggleColumn={this.toggleColumn} />
    );
  }
}

export default TableContainer;
