import React, {
  compose, Switch, Route, Redirect,
} from '../../../../app/app';
import Fund from './fund/FundContainer';
import Overview from './overview/OverviewContainer';
import Payments from './payments/index';
import './index.scss';

const baseRoute = '/account';

const Account = () => (
  <div className="views__account">
    <Switch>
      <Route exact path={baseRoute} component={Overview} />
      <Route exact path={`${baseRoute}/fund`} component={Fund} />
      <Route path={`${baseRoute}/payments`} component={Payments} />
      <Redirect to={baseRoute} />
    </Switch>
  </div>
);

export default compose(Account);
