import React, { config } from '../../../app/app';
import Select from '../Select/SelectContainer';

const { languages } = config.get('countryData');
const { Option } = Select;

const LanguageSelector = ({ disabled, value, onChange }) => (
  <Select
    disabled={disabled}
    includeSearch
    value={value}
    onChange={onChange}
    count={languages.length}
  >
    {languages.map((language) => (
      <Option key={language.code} value={language.code} searchValue={language.name}>
        <span>{language.name}</span>
      </Option>
    ))}
  </Select>
);

export default LanguageSelector;
