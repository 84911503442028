import { getClient } from '../setup';
import {
  GetCampaignHistories,
  GetCampaignReviewHistories,
} from '../operations_history.graphql';
import handleGraphQLError from './handle-graphql-error';
import { convertToRestFormat as convertCampaignToRestFormat } from './campaigns';

function convertToRestFormat(history) {
  const result = {
    id: history.id,
    date: history.created,
    message: history.message,
    original: history.original,
    user: history.user,
  };
  if (history.campaign) {
    result.resource = convertCampaignToRestFormat(history.campaign);
  }
  return result;
}

export function getCampaignHistories(id, page, perPage) {
  return getClient()
    .query({
      query: GetCampaignHistories,
      variables: {
        id,
        page,
        perPage,
      },
    })
    .then(({ data: { campaignHistories: campaigns } }) => ({
      ok: true,
      status: 200,
      response: { history: campaigns.histories.map(convertToRestFormat) },
      pagination: campaigns.pagination,
    }))
    .catch(handleGraphQLError);
}

export function getCampaignReviewHistories(id, page, perPage) {
  return getClient()
    .query({
      query: GetCampaignReviewHistories,
      variables: {
        id,
        page,
        perPage,
      },
    })
    .then(({ data: { campaignReviewHistories: campaigns } }) => ({
      ok: true,
      status: 200,
      response: { history: campaigns.histories.map(convertToRestFormat) },
      pagination: campaigns.pagination,
    }))
    .catch(handleGraphQLError);
}
