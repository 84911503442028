import React from 'react';
import Group from '../../../../../../templates/input-group/InputGroupContainer';
import Radio from '../../../../../../components/Form/Radio/Radio';
import Template from '../../../../../../models/Template';
import Select from '../../../../../../components/Form/Select/SelectContainer';
import Text from '../../../../../../components/Form/ValidatedText/ValidatedText';
import LanguageSelector from '../../../../../../components/Form/LanguageSelector/LanguageSelector';
import Toggler from '../../../../../../components/Form/Toggler/Toggler';
import AdminTemplate from '../../../../../../models/AdminTemplate';
import CollapseSection from '../../../../../../components/V2/Collapse/CollapseSection';

const { Field } = Group;

/**
 * Filters the new versions of the same template to keep only the oldest active template in the list.
 * @param templates
 * @param selectedTemplateId is used to include the selected template ID
 */
export function filterByTemplateVersions(templates, selectedTemplateId) {
  const templatesByOriginalTemplateIds = {};
  templates.forEach((template) => {
    const originalTemplateId = template.get('originalTemplateID') || template.get('id');
    if (selectedTemplateId === template.get('id')) {
      // use the selected template id even though there is more appropriate template
      // pointing to the same original ID to avoid showing template dropdown empty
      templatesByOriginalTemplateIds[selectedTemplateId] = template;
    } else if (!templatesByOriginalTemplateIds[originalTemplateId]
      || templatesByOriginalTemplateIds[originalTemplateId].get('version') > template.get('version')) {
      templatesByOriginalTemplateIds[originalTemplateId] = template;
    }
  });
  return Object.keys(templatesByOriginalTemplateIds).map((id) => (templatesByOriginalTemplateIds[id]));
}

export function filterByCategories(templates, templateCategory) {
  if (templateCategory === 'experiment') {
    // return the templates that has no category
    return templates.filter((template) => Object.keys(template.get('template_category')).length === 0);
  }
  return templates.filter((template) => template.get('template_category')[templateCategory]);
}

export function filterTemplates(templates, templateCategory, selectedTemplateId) {
  return filterByTemplateVersions(filterByCategories(templates, templateCategory), selectedTemplateId);
}

function getCategoryForTemplate(template) {
  const templateCategories = template.get('template_category');
  if (Object.keys(templateCategories).length > 0) {
    // select the first category field as the template category
    const [firstCategory] = Object.keys(templateCategories);
    return firstCategory;
  }
  return AdminTemplate.CATEGORY_EXPERIMENTAL;
}

class CreativeOptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: undefined,
      selectedTemplate: undefined,
      templateCategory: undefined,
      templates: [],
      templateCategories: [],
    };
  }

  componentDidMount() {
    const { isCloningCreative, isEditingCreative } = this.props;
    if (isCloningCreative || isEditingCreative) {
      // fetch only single template that's being used by the creative
      // no need to fetch the list because template selection is disabled in clone/edit page
      this.getTemplate();
    } else {
      this.getTemplates();
    }
  }

  /**
   * Retrieves the single template with ID if it's edit/clone creative page.
   */
  async getTemplate() {
    const templateId = this.props.creative.raw('template.id');
    if (!templateId) {
      return;
    }

    const result = await Template.get(templateId).catch((error) => {
      this.setState({ error });
    });
    if (!result) {
      return;
    }
    const template = new Template(result.response);

    const templateCategory = getCategoryForTemplate(template);

    // Only show the category corresponding to the selected template
    const newTemplateCategories = [];
    AdminTemplate.getCategoriesOptions().forEach((category) => {
      const newCategory = category;
      if (category.value === templateCategory) {
        newCategory.hasActiveTemplates = true;
        newTemplateCategories.push(newCategory);
      }
    });

    this.setState(
      {
        selectedTemplate: template,
        templateCategory,
        templates: [template],
        templateCategories: newTemplateCategories,
      },
      () => this.onSelectTemplate(template),
    );
  }

  /**
   * Retrieves the active template list and filters them based on
   * the OS target in order to show the template selection options.
   */
  getTemplates() {
    Template.getAll({ perPage: 1000, templateStatus: 'active' }).then((result) => {
      const application = this.props.creative.get('application');

      // convert to Template object and filter based on application's os whitelist
      const templates = result.response
        .map((data) => new Template(data))
        .filter((template) => (
          !template.get('os_whitelist')
          || Object.keys(template.get('os_whitelist')).length === 0
          || template.get('os_whitelist')[application.get('platform')]
        ));

      // find selected template. used when viewing existing creative info
      const selectedTemplate = this.props.creative
        ? templates.find((t) => t.get('id') === this.props.creative.get('template.id'))
        : undefined;
      const templateCategory = selectedTemplate ? getCategoryForTemplate(selectedTemplate) : undefined;

      // When no templates are available for a template category, the template category should be hidden in the UI.
      const newTemplateCategories = [];
      AdminTemplate.getCategoriesOptions().forEach((category) => {
        const newCategory = category;
        newCategory.hasActiveTemplates = templates.some((template) => category.value === 'experiment'
          || template.get('template_category')[category.value] === true);
        newTemplateCategories.push(newCategory);
      });
      this.setState(
        {
          selectedTemplate,
          templateCategory,
          templates,
          templateCategories: newTemplateCategories,
        },
        () => this.onSelectTemplate(selectedTemplate),
      );
    }).catch((error) => {
      this.setState({ error });
    });
  }

  onSelectTemplate(template) {
    if (!template) return;

    this.props.onTemplateChange(template);
    this.props.onChange('template', {
      id: template.get('id'),
      name: template.get('name'),
      cdn_url: template.get('cdn_url'),
      is_custom_creative: template.get('is_custom_creative'),
      format: template.get('format'),
    });
    this.props.onChange('format', template.get('supported_template_protocol'));
  }

  render() {
    const {
      creative, errors = {}, isCloningCreative, isEditingCreative, onChange,
    } = this.props;
    const {
      error, selectedTemplate, templateCategory, templates, templateCategories,
    } = this.state;

    return (
      <CollapseSection
        description="Select your Creative type and other options."
        title="Creative Options"
      >
        {error && <p className="error">Fetching templates failed.</p>}

        <Field
          description="What template category do you wish to use?"
          name="Template Category"
        >
          <Radio
            onChange={(value) => this.setState({ templateCategory: value, selectedTemplate: undefined })}
            value={templateCategory}
          >
            {templateCategories.map((category) => (
              category.hasActiveTemplates
                && (
                <Radio.Option
                  key={category.value}
                  label={category.name}
                  value={category.value}
                  adminOnly={category.isAdminOnly}
                  disabled={templates.length === 0 || isCloningCreative || isEditingCreative}
                />
                )
            ))}
          </Radio>
        </Field>

        <Field
          adminOnly
          description="Select your creative type."
          name="Creative Type"
        >
          <Select
            value={selectedTemplate?.get('id')}
            onChange={(id) => {
              const template = templates.find((t) => t.get('id') === id);
              this.setState({ selectedTemplate: template }, () => {
                this.onSelectTemplate(template);
                creative.set('video', undefined);
              });
            }}
            disabled={!templateCategory || isCloningCreative || isEditingCreative}
            isValid={!errors.template}
            disabledText={selectedTemplate?.get('name')}
          >
            {filterTemplates(templates, templateCategory, selectedTemplate?.get('id'))
              .sort((a, b) => (a.get('name') > b.get('name') ? 1 : -1))
              .map((template) => (
                <Select.Option
                  key={template.get('id')}
                  value={template.get('id')}
                >
                  {template.get('name')}
                </Select.Option>
              ))}
          </Select>
        </Field>

        <Field
          adminOnly
          description="Vungle name to identify this creative and its attributes."
          name="Vungle Produced Name"
        >
          <Text
            noIcon
            isValid={!errors.vungleName}
            placeholder="Add an internal name"
            value={creative.get('vungle_name')}
            onChange={(v) => onChange('vungle_name', v)}
          />
        </Field>

        <Field
          description={'Client friendly name that will display as default instead of "Vungle Produced Name" above.'}
          name="Custom Name (optional)"
        >
          <Text
            noIcon
            isValid
            placeholder="Self-Serve user will see this"
            value={creative.get('name')}
            onChange={(v) => onChange('name', v)}
          />
        </Field>

        <Field
          description="Select your creative's language"
          name="Language"
        >
          <LanguageSelector
            value={creative.raw('language.code')}
            onChange={(value) => onChange('language.code', value)}
          />
        </Field>

        <Field
          adminOnly
          description="Only serve to devices set to the selected language"
          name="Enforce Language"
        >
          <Toggler
            checked={creative.raw('language.is_enforced')}
            onChange={(value) => onChange('language.is_enforced', value)}
          />
        </Field>
      </CollapseSection>
    );
  }
}

export default CreativeOptions;
