import { isEqual, omitBy, size } from 'lodash';
import Cache from './Cache';
import {
  USER_FILTERS_KEY,
  CREATIVE_QA_FILTERS_KEY,
  ASSET_FILTERS_KEY,
  TOOLS_DEALS_FILTERS_KEY,
  TOOLS_EXCHANGE_FILTERS_KEY,
  TOOLS_RTD_FILTERS_KEY,
  GROW_CAMPAIGN_FILTER_KEY,
  GROW_APPLICATION_FILTER_KEY,
  GROW_CREATIVE_FILTER_KEY,
  CTRL_APPLICATION_FILTERS_KEY,
  CTRL_CAMPAIGN_FILTERS_KEY,
  CTRL_CREATIVE_FILTERS_KEY,
} from './constant';

class FiltersCache extends Cache {
  constructor(userId, storageKey, defaultValue) {
    super(userId, storageKey, defaultValue || {});
  }

  save = (filters) => {
    localStorage.setItem(this.storageKey, JSON.stringify(filters));
  }

  get = () => {
    const storage = localStorage.getItem(this.storageKey);
    if (!storage) {
      return this.defaultValue;
    }
    let filters = {};
    try {
      const _filters = JSON.parse(storage);
      if (_filters && Object.keys(_filters).length > 0) {
        filters = _filters;
      }
    } catch (e) {
      // do nothing
    }
    return filters;
  }
}

export default FiltersCache;

export function getGrowApplicationFiltersCache(userId, defaultValue) {
  return new FiltersCache(userId, GROW_APPLICATION_FILTER_KEY, defaultValue);
}

export function getCtrlApplicationFiltersCache(userId, defaultValue) {
  return new FiltersCache(userId, CTRL_APPLICATION_FILTERS_KEY, defaultValue);
}

export function getGrowCampaignFiltersCache(userId, defaultValue) {
  return new FiltersCache(userId, GROW_CAMPAIGN_FILTER_KEY, defaultValue);
}

export function getCtrlCampaignFiltersCache(userId, defaultValue) {
  return new FiltersCache(userId, CTRL_CAMPAIGN_FILTERS_KEY, defaultValue);
}

export function getGrowCreativeFiltersCache(userId, defaultValue) {
  return new FiltersCache(userId, GROW_CREATIVE_FILTER_KEY, defaultValue);
}

export function getCtrlCreativeFiltersCache(userId, defaultValue) {
  return new FiltersCache(userId, CTRL_CREATIVE_FILTERS_KEY, defaultValue);
}

export function getUserFiltersCache(userId, defaultValue) {
  return new FiltersCache(userId, USER_FILTERS_KEY, defaultValue);
}

export function getCreativeQAFiltersCache(userId) {
  return new FiltersCache(userId, CREATIVE_QA_FILTERS_KEY);
}

export function getAssetFiltersCache(userId) {
  return new FiltersCache(userId, ASSET_FILTERS_KEY);
}

export function getUserFiltersCacheByKey(key, userId) {
  return new FiltersCache(userId, key);
}

export function getToolsDealsFiltersCache(userId) {
  return getUserFiltersCacheByKey(TOOLS_DEALS_FILTERS_KEY, userId);
}

export function getToolsExchangeFiltersCache(userId) {
  return getUserFiltersCacheByKey(TOOLS_EXCHANGE_FILTERS_KEY, userId);
}

export function getToolsRtdFiltersCache(userId) {
  return getUserFiltersCacheByKey(TOOLS_RTD_FILTERS_KEY, userId);
}

export function isFiltersEqual(prev, next) {
  const prevFilters = omitBy(prev, (v) => size(v) === 0);
  const nextFilters = omitBy(next, (v) => size(v) === 0);
  return isEqual(prevFilters, nextFilters);
}

export function clearAllFiltersCache(userId) {
  getGrowApplicationFiltersCache(userId).remove();
  getCtrlApplicationFiltersCache(userId).remove();
  getGrowCampaignFiltersCache(userId).remove();
  getCtrlCampaignFiltersCache(userId).remove();
  getGrowCreativeFiltersCache(userId).remove();
  getCtrlCreativeFiltersCache(userId).remove();
  getUserFiltersCache(userId).remove();
  getCreativeQAFiltersCache(userId).remove();
  getAssetFiltersCache(userId).remove();
  getToolsDealsFiltersCache(userId).remove();
  getToolsExchangeFiltersCache(userId).remove();
  getToolsRtdFiltersCache(userId).remove();
}
