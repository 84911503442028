import moment from 'moment-timezone';
import { toInteger } from './lib';

export const ISO_8601_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';

const timezone = moment.tz(moment.tz.guess()).format('z');

const utc = () => moment.utc();
const format = (m, output = ISO_8601_FORMAT) => m.format(output);
const add = (string, duration) => moment(string).add(...duration.split(' '));
const isAfter = (start, end) => moment(end).isAfter(moment(start));
const isValidUTC = (value) => moment(value, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid();
const locale = (isoString, form = 'L') => moment(isoString).format(form);
const localeFull = (isoString) => `${moment(isoString).format('L LT')} (${timezone})`;
const convert = (string, toFormat = moment.ISO_8601) => moment.utc(string).format(toFormat);
const now = ({ add: toAdd = null, subtract = null } = {}, nowFormat = ISO_8601_FORMAT) => {
  if (toAdd) {
    return format(utc().add(...toAdd.split(' ')), nowFormat);
  }
  if (subtract) {
    return format(utc().subtract(...subtract.split(' ')), nowFormat);
  }
  return format(utc(), nowFormat);
};
const setEndOfDay = (string) => format(moment(string).set({
  hour: 23, minute: 59, second: 59, millisecond: 999,
}));
const setStartOfDay = (string) => format(moment(string).set({
  hour: 0, minute: 0, second: 0, millisecond: 0,
}));

/** Date Range **/
const selections = {
  ALL: {
    value: 'all',
    text: 'All Date Ranges',
  },
  // TODAY: {
  //   value: '0',
  //   text: 'Today',
  // },
  YESTERDAY: {
    value: '1',
    text: 'Yesterday',
  },
  LAST_7_DAYS: {
    value: '7',
    text: 'Last 7 Days',
  },
  LAST_30_DAYS: {
    value: '30',
    text: 'Last 30 Days',
  },
  LAST_3_MONTHS: {
    value: '90',
    text: 'Last 3 Months',
  },
  LAST_6_MONTHS: {
    value: '180',
    text: 'Last 6 Months',
  },
  LAST_YEAR: {
    value: '365',
    text: 'Last Year',
  },
  CUSTOM: {
    value: 'custom',
    text: 'Custom',
  },
};

const createDateRange = (start, end, days = null) => {
  const daysInt = toInteger(days);
  const isNullDateRange = [start, end, days].every((val) => val === null);
  if (days === 'all' || isNullDateRange) {
    return {
      start: null,
      end: null,
    };
  }

  if (daysInt === 7) {
    return {
      start: setStartOfDay(moment().subtract(6, 'days').tz('UTC')),
      end: setEndOfDay(moment().tz('UTC')),
    };
  }

  return {
    start: setStartOfDay(days ? moment().subtract(daysInt, 'days').tz('UTC') : start),
    end: setEndOfDay(days ? moment().subtract(1, 'days').tz('UTC') : end),
  };
};

const convertDateInSecondsToISO8601 = (dateInSeconds) => (dateInSeconds ? moment(dateInSeconds).tz('UTC').format(ISO_8601_FORMAT) : 'all');

const convertDateInSecondsToDays = (dateInSeconds) => (
  dateInSeconds ? moment().tz('UTC').set({
    hour: 0, minute: 0, second: 0, millisecond: 0,
  })
    .diff(moment(dateInSeconds).set({
      hour: 0, minute: 0, second: 0, millisecond: 0,
    }).tz('UTC'), 'days') : 0
);

const formatDisplayDate = (dateInSeconds) => locale(moment(dateInSeconds).tz('UTC'));

const formatCustomDateRangeDisplay = (start = false, end = false) => {
  if (start && end) {
    return `${formatDisplayDate(start)} - ${formatDisplayDate(end)}`;
  }
  if (start) {
    return `Start Date: ${formatDisplayDate(start)}`;
  }
  if (end) {
    return `End Date: ${formatDisplayDate(end)}`;
  }
  return selections.ALL.text;
};

const getSelectionTextFromValue = (value) => {
  if (value === selections.CUSTOM.value) {
    return null;
  }
  let selectionText = selections.ALL.text;
  Object.keys(selections).forEach((key) => {
    if (selections[key].value === value) {
      selectionText = selections[key].text;
    }
  });
  return selectionText;
};

function setQuickPickSelection(quickPick, start, end) {
  if (quickPick) {
    return quickPick;
  }
  if (start && end) {
    return selections.CUSTOM.value;
  }
  return selections.ALL.value;
}

const initializeDates = (quickPickValue = false, startDateString = null, endDateString = null) => {
  const start = startDateString ? moment(startDateString) : null;
  const end = endDateString ? moment(endDateString) : null;
  const quickPickSelection = setQuickPickSelection(quickPickValue, start, end);

  return {
    displayedDateRange: quickPickValue ? getSelectionTextFromValue(quickPickValue)
      : formatCustomDateRangeDisplay(start, end),
    start,
    end,
    quickPickSelection,
  };
};

export default moment;
export {
  add,
  convert,
  convertDateInSecondsToISO8601,
  createDateRange,
  formatDisplayDate,
  formatCustomDateRangeDisplay,
  getSelectionTextFromValue,
  initializeDates,
  isAfter,
  isValidUTC,
  locale,
  localeFull,
  now,
  selections,
  setEndOfDay,
  setStartOfDay,
  timezone,
  moment,
  convertDateInSecondsToDays,
};
