import React from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { FILTER_KEY_PUBLISHER } from 'app/constants/filters';
import MultiTreeBackend from '../Base/MultiTreeBackend/MultiTreeBackend';
import ApplicationOrPublisherPanel from '../Base/Panels/ApplicationOrPublisherPanel';

const MultiTreePublisher = ({
  visible,
  searchParams,
  availableNodes,
  defaultCheckedNodes,
  dropdownFixed,
  onVisibleChange,
  onChange,
  onRemove,
}) => (
  <MultiTreeBackend
    dropdownFixed={dropdownFixed}
    visible={visible}
    filterKey={FILTER_KEY_PUBLISHER}
    defaultCheckedNodes={defaultCheckedNodes}
    renderOverlay={() => (
      React.createElement(ApplicationOrPublisherPanel, {
        visible,
        searchParams,
        availableNodes,
        filterKey: FILTER_KEY_PUBLISHER,
        defaultCheckedNodes,
        onVisibleChange,
        onChange,
      })
    )}
    onVisibleChange={onVisibleChange}
    onRemove={onRemove}
  />
);

MultiTreePublisher.propTypes = {
  visible: PropTypes.bool.isRequired,
  availableNodes: PropTypes.arrayOf(PropTypes.any),
  defaultCheckedNodes: PropTypes.arrayOf(PropTypes.any),
  dropdownFixed: PropTypes.bool,
  onVisibleChange: PropTypes.func,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};

MultiTreePublisher.defaultProps = {
  availableNodes: [],
  defaultCheckedNodes: [],
  dropdownFixed: false,
  onVisibleChange: noop,
  onChange: noop,
  onRemove: noop,
};

export default React.memo(MultiTreePublisher);
