import React, {
  useRef, useEffect, useState, useImperativeHandle, forwardRef,
} from 'react';
import classNames from 'classnames';
import { toCommasFormat, toNumberFormat } from './helper';

const INPUT_DEFAULT_HEIGHT = 40;
const MAX_INPUT_HEIGHT = 20 * 4 + 20;
const KEYCODE_ENTER = 13;

const Text = ({
  autoFocus,
  displayAndEdit = false,
  disabled,
  hideClearButton = false,
  icon = 'edit',
  isFocused,
  isValid = true,
  noIcon = false,
  onBlur,
  onCopy,
  onChange,
  onFocus,
  haveTipMessage,
  nameErr = '',
  numeric = false,
  staticPlaceholder = null,
  staticPlaceholderContent,
  type = 'text',
  value: valueProp,
  componentType = 'textarea',
  wrapperClassName = '',
  hasError = false,
  children,
  ...props
}, ref) => {
  const inputRef = useRef();
  const initialValue = numeric ? toCommasFormat(valueProp) : valueProp;
  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.setProperty('--height', 'auto');
      inputRef.current.style.setProperty(
        '--height',
        inputRef.current.scrollHeight > MAX_INPUT_HEIGHT ? `${MAX_INPUT_HEIGHT}px` : `${inputRef.current.scrollHeight || INPUT_DEFAULT_HEIGHT}px`,
      );
      if (autoFocus) {
        inputRef.current.focus();
      }
    }
    if (value !== initialValue && value !== valueProp) {
      setValue(valueProp);
    }
    // --> potential bug inside this useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoFocus, valueProp]);
  const onInputChange = (event) => {
    const formatValue = (event.target.value || '').replace(/(?:\r\n|\r|\n)/g, '');
    if (numeric) {
      if ((!formatValue || formatValue.match(/^(-|\.|\d)+\.?\d?$/g))) {
        setValue(formatValue);
        onChange(formatValue, event);
      }
    } else {
      setValue(formatValue);
      onChange(formatValue, event);
    }
  };
  const onInputFocus = (e) => {
    const v = e.target.value;
    if (numeric && v) {
      setValue(
        v.replace(/,+/g, ''),
      );
    }
    onFocus(e);
  };
  const onInputBlur = (e) => {
    const v = e.target.value;
    if (numeric) {
      // They actually are the same value, the difference is just the displaying.
      // commas format for displaying
      setValue(v === '0' ? '' : toCommasFormat(v));
      // to fix https://vungle.atlassian.net/browse/DB-8805
      // number format for numeric
      onBlur(toNumberFormat(v));
    } else {
      setValue(v);
      onBlur(v);
    }
  };
  const onClear = (event) => {
    onChange(numeric ? 0 : '', event);
  };
  const onKeyDown = (e) => {
    if (numeric && e.keyCode === KEYCODE_ENTER) {
      inputRef.current.blur();
    }
  };
  useImperativeHandle(ref, () => ({
    clear: () => onClear(),
  }));
  return (
    <div className={classNames({ components__form__input: true, 'components__form__input--focused': isFocused })}>
      <div className={classNames({
        input: true,
        'warn-border': haveTipMessage,
        'input--disabled': disabled,
        'input--focused': !displayAndEdit && isFocused,
        'input--display-and-edit': displayAndEdit,
        nameErr,
        'input--display-and-edit--focused': isFocused,
        'input--invalid': !isValid,
        'input--error': hasError,
      }, wrapperClassName)}
      >
        {staticPlaceholder && (
          <div className="placeholder">
            {staticPlaceholderContent}
          </div>
        )}
        {children || (
          componentType === 'input' ? (
            <input
              {...props}
              {...{
                disabled, type, value,
              }}
              ref={inputRef}
              placeholder={props.placeholder || 'Enter value'}
              className={classNames('input_component', props.className)}
              onChange={(e) => onInputChange(e)}
              onFocus={(e) => onInputFocus(e)}
              onBlur={(e) => onInputBlur(e)}
            />
          ) : (
            <textarea
              {...props}
              {...{
                disabled, type, value,
              }}
              rows={1}
              ref={inputRef}
              placeholder={props.placeholder || 'Enter value'}
              className={classNames('input_component', props.className)}
              onChange={(e) => onInputChange(e)}
              onFocus={(e) => onInputFocus(e)}
              onBlur={(e) => onInputBlur(e)}
              onKeyDown={onKeyDown}
            />
          )
        )}
        {!noIcon && (
          <div className="icon" onClick={onCopy}>
            <i className="material-icons">{icon}</i>
          </div>
        )}
        {isFocused && value.length > 0 && !disabled && !hideClearButton && (
          <div className={classNames('clear-field', noIcon ? 'no-icon' : 'with-icon')} onMouseDown={(e) => onClear(e)}>
            <i className="material-icons">clear</i>
          </div>
        )}
      </div>
    </div>
  );
};

export default forwardRef(Text);
