import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import './filter.scss';

const renderIcon = (type, clickHandler = noop, className) => (
  <i
    className={classNames('material-icons', className)}
    onClick={clickHandler}
  >
    {type}
  </i>
);

const renderPrefixIcon = (type, subType, clickHandler) => {
  switch (type) {
    case 'advanced':
      return subType === 'dropdown' ? renderIcon('add', clickHandler, 'arrow') : null;
    case 'category':
      return renderIcon('cancel', clickHandler);
    case 'date':
      return renderIcon('date_range', clickHandler);
    default:
      return null;
  }
};

const renderSuffixIcon = (type, subType, clickHandler) => {
  switch (type) {
    case 'advanced':
      return subType === 'dropdown'
        ? renderIcon('keyboard_arrow_down', null, 'arrow')
        : renderIcon('cancel', clickHandler);
    case 'basic':
    case 'category':
    case 'date':
      return renderIcon('keyboard_arrow_down', null, 'arrow');
    default:
      return null;
  }
};

const Filter = (props) => {
  const {
    filterRef,
    className,
    children,
    type,
    subType,
    open,
    disablePrefixIcon,
    disableSuffixIcon,
    onClickIcon,
    onClick,
    focused,
    setFocused,
  } = props;

  const prefixClsx = 'v2_component_filter';

  const handleClickIcon = (event) => {
    event.stopPropagation();
    onClickIcon(open, event);
  };

  const handleClick = (event) => {
    onClick(open, event);
    if (setFocused) {
      setFocused();
    }
  };

  return (
    <div
      ref={filterRef}
      className={classNames(
        prefixClsx, {
          [`${prefixClsx}-${type}-${subType}`]: type && subType,
          [`${prefixClsx}-${type}-${subType}-open`]: open,
          [`${prefixClsx}-${type}-open`]: open,
          [`${prefixClsx}-${type}`]: type,
          [`${prefixClsx}-withPrefix`]: !disablePrefixIcon,
          [`${prefixClsx}-withSuffix`]: !disableSuffixIcon,
          [`${prefixClsx}-focused`]: focused,
        },
        className,
      )}
      onClick={handleClick}
    >
      <div className={classNames(`${prefixClsx}-wrapper`)}>
        {!disablePrefixIcon && renderPrefixIcon(type, subType, handleClickIcon)}
        <span className="text">{children}</span>
        {!disableSuffixIcon && renderSuffixIcon(type, subType, handleClickIcon)}
      </div>
    </div>
  );
};

Filter.propTypes = {
  /*
   * class name.
   */
  className: PropTypes.string,
  // Filter wrapper ref.
  filterRef: PropTypes.objectOf(PropTypes.any),
  /**
   * Type of filter, default is `basic`.
   */
  type: PropTypes.oneOf(['basic', 'advanced', 'category', 'date']),
  /*
   * Subtype of filter, only avaliable when type is `advanced`, default is `dropdown`.
   */
  subType: PropTypes.oneOf(['dropdown', 'option', null]),
  /**
   * Tell if filter is open.
   */
  open: PropTypes.bool,
  /*
   * Tell if show prefix icon, default is false
   */
  disablePrefixIcon: PropTypes.bool,
  /*
   * Tell if show suffix icon, default is false
   */
  disableSuffixIcon: PropTypes.bool,
  /**
   * Called when click icon.
   *
   * @param {bool} open If the component opened.
   * @param {object} event The event source of the callback.
   */
  onClickIcon: PropTypes.func,
  /**
   * Called when user click whole component.
   *
   * @param {bool} open If the component opened.
   * @param {object} event The event source of the callback.
   */
  onClick: PropTypes.func,
};

Filter.defaultProps = {
  className: null,
  filterRef: null,
  type: 'basic',
  subType: null,
  open: false,
  disablePrefixIcon: false,
  disableSuffixIcon: false,
  onClickIcon: noop,
  onClick: noop,
};

export default Filter;
