import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import Asset from '../../../../../../models/Asset';
import IconComponent from '../../../../../../components/Icon/Icon';
import ServingStatus from '../../../../../../components/Assets/ServingStatus/ServingStatus';
import AssetNameInput from './AssetNameInput';
import AssetLogBox from '../AssetLogBox';
import './AssetInfo.scss';
import { ERROR_MSG } from '../errors';

function getErrorMessage(error) {
  return ERROR_MSG[error] || error || 'Unexpected error occurred.';
}

class AssetInfo extends React.Component {
  constructor(p) {
    super(p);
    this.state = {
      updateResponseMessages: null,
    };
  }

  clearError = () => {
    this.setState({
      updateResponseMessages: null,
    });
  }

  onError = (errors) => {
    this.setState({
      updateResponseMessages: errors,
    });
  }

  render() {
    const { asset } = this.props;
    const { updateResponseMessages } = this.state;

    if (!asset) {
      return null;
    }

    let type = 'Image';
    let assetIcon = <IconComponent.NewImage />;

    switch (asset.get('type')) {
      case 'video':
        type = 'Video';
        assetIcon = <IconComponent.NewVideo />;
        break;
      case 'bundle_adaptive_creative':
        type = 'Playable (.zip format)';
        assetIcon = <IconComponent.NewGame />;
        break;
      default:
        break;
    }

    return (
      <div className="previewer_asset_info">
        <div className="asset_info_holder">
          <div className="icon">{assetIcon}</div>
          <div className="type">{type}</div>
          <AssetNameInput
            asset={asset}
            onError={this.onError}
            clearError={this.clearError}
            onAssetNameChange={this.props.onAssetNameChange}
          />
        </div>
        {asset.get('type') === 'bundle_adaptive_creative' && (
          <div className="endcard_status">
            <span>Status</span>
            <ServingStatus status={asset.get('status')} />
          </div>
        )}

        <div className="asset_name_input_logs">
          { updateResponseMessages && updateResponseMessages.length && updateResponseMessages.map((message) => (
            <AssetLogBox key={message} status="error" message={getErrorMessage(message)} />
          ))}
        </div>
      </div>
    );
  }
}

AssetInfo.propTypes = {
  asset: PropTypes.instanceOf(Asset).isRequired,
  onAssetNameChange: PropTypes.func,
};

AssetInfo.defaultProps = {
  onAssetNameChange: noop,
};

export default AssetInfo;
