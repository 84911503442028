import React from 'react';
import { isAdminService } from 'lib/serviceType';
import TooltipCutoff from '../../../../components/V2/Tooltip/TooltipCutoff';
import './CreativePreview.scss';

const CreativePreview = ({
  previewCreative, creative, prefixCls, canEdit, resizeClassName,
}) => {
  const creativeName = creative.getFriendlyName();
  const creativeId = creative.get('id');
  const titleWrapper = (title) => (
    canEdit
      ? (
        <a
          className="creative_preview_name_text"
          href={
            isAdminService()
              ? `/creatives/${creativeId}`
              : `/creatives/${creativeId}/campaigns`
          }
          target="_blank"
          rel="noreferrer"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {title}
        </a>
      )
      : title
  );
  return (
    <div className={`${prefixCls}-preview-cell`}>
      <div className={`${prefixCls}-preview-cell-info`}>
        <i
          className="material-icons"
          onClick={(e) => {
            e.stopPropagation();
            previewCreative(creative, true);
          }}
        >
          play_circle_outline
        </i>
        <TooltipCutoff
          title={creativeName}
          titleWrapper={titleWrapper}
          resizeClassName={resizeClassName || 'rt-td'}
        />
      </div>
    </div>
  );
};

CreativePreview.noWrapInTooltip = true;

export default CreativePreview;
