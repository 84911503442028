import AccelerateLogo from '../../../assets/img/accelerate_logo.svg';
import DirectLogo from '../../../assets/img/direct_logo.svg';
import MonetizeLogo from '../../../assets/img/monetize_logo.svg';
import InfluenceLogo from '../../../assets/img/influence_logo.svg';
import IntelligenceLogo from '../../../assets/img/intelligence_logo.svg';

export const platformKeyAccelerate = 'accelerate';
export const platformKeyDirect = 'direct';
export const platformKeyMonetize = 'monetize';
export const platformKeyInfluence = 'influence';
export const platformKeyIntelligence = 'intelligence';

export const platformSelectors = (isAdminSvc) => ([
  {
    key: platformKeyAccelerate,
    selected: false,
    title: 'Accelerate',
    platform: 'Liftoff',
    logo: AccelerateLogo,
    link: 'https://app.liftoff.io',
  },
  {
    key: platformKeyDirect,
    selected: true,
    title: 'Direct',
    platform: 'Vungle',
    logo: DirectLogo,
  },
  {
    key: platformKeyMonetize,
    selected: false,
    title: 'Monetize',
    platform: 'Vungle',
    logo: MonetizeLogo,
    link: isAdminSvc ? 'https://pubadmin.vungle.com' : 'https://publisher.vungle.com',
  },
  {
    key: platformKeyInfluence,
    selected: false,
    title: 'Influence',
    platform: 'Jetfuel',
    logo: InfluenceLogo,
    link: 'https://dash.jetfuel.it',
  },
  {
    key: platformKeyIntelligence,
    selected: false,
    title: 'Intelligence',
    platform: 'GameRefinery',
    logo: IntelligenceLogo,
    link: 'https://saas.gamerefinery.com/#/register',
  },
]);
