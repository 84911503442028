import React from 'react';
import Spinner from '../Spinner/Spinner';
import './checkmark.scss';

const Checkmark = ({ complete = false }) => (
  <div className={`component__checkmark ${complete ? 'component__checkmark--drawn' : ''}`}>
    <svg
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 37 37"
      style={{ enableBackground: 'new 0 0 37 37' }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="circle path"
        d="M30.5,6.5L30.5,6.5c6.6,6.6,6.6,17.4,0,24l0,0c-6.6,6.6-17.4,6.6-24,0l0,0c-6.6-6.6-6.6-17.4,0-24l0,0C13.1-0.2,23.9-0.2,30.5,6.5z"
      />
      <polyline
        className="tick path"
        points="11.6,20 15.9,24.2 26.4,13.8 "
      />
    </svg>
    {!complete && (
      <div className="component__spinner">
        <Spinner />
      </div>
    )}
  </div>
);

export default Checkmark;
