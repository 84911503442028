import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isObject } from '../../../../lib/lib';
import CollapseSummary from '../CollapseSummary';
import CollapseDetail from '../CollapseDetail';
import messages from './messages';
import './errorsCollapse.scss';

const ErrorsCollapse = ({ className, errors: errorsProp, separator = ' ' }) => {
  const [errors, setErrors] = React.useState([]);

  useEffect(() => {
    const newErrors = Object.keys(errorsProp).map((key) => ({
      message: messages[key],
      detail: errorsProp[key].map((item) => (isObject(item) ? item.pub_app_id : item)).join(separator),
      expanded: false,
    }));
    setErrors(newErrors);
    // --> potential bug inside this useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorsProp]);

  const expandError = useCallback((index) => {
    const error = errors[index];
    setErrors([
      ...errors.slice(0, index),
      { ...error, expanded: !error.expanded },
      ...errors.slice(index + 1),
    ]);
  }, [errors]);

  return (
    <div className={classnames('views__multi-bidding__errors_collapse', className)}>
      {errors.map((error, index) => (
        <div key={index}>
          <CollapseSummary
            expanded={error.expanded}
            className={classnames('summary')}
            prefixIcon={<i className="material-icons">error_outline</i>}
            onClick={() => expandError(index)}
          >
            {error.message}
          </CollapseSummary>
          {error.expanded && <span className="separator" />}
          <CollapseDetail
            className={classnames('p6', 'detail')}
            expanded={error.expanded}
          >
            {error.detail}
          </CollapseDetail>
        </div>
      ))}
    </div>
  );
};

ErrorsCollapse.propTypes = {
  /**
   * Class name string.
   */
  className: PropTypes.string,
  /**
   *  Errors object to display.
   */
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

ErrorsCollapse.defaultProps = {
  className: null,
};

export default React.memo(ErrorsCollapse);
