import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBar } from '../../index';
import SpinnerWithText from '../../../Spinner/SpinnerWithText';
import './Loading.scss';

const prefixCls = 'reporting-loading';

const Loading = ({ content }) => (
  <div className={prefixCls}>
    <div className={`${prefixCls}-progress`}>
      <ProgressBar background="#DCE6FF" start />
    </div>
    <SpinnerWithText text={content} />
  </div>
);

Loading.propTypes = {
  /**
   * words of loading content
   */
  content: PropTypes.string,
};

Loading.defaultProps = {
  content: '',
};

export default Loading;
