import { map } from 'lodash';
import { getClient } from '../setup';
import { GetAppStoreSettings, SearchAppStoreSettings } from '../operations_appstore.graphql';
import handleGraphQLError from './handle-graphql-error';
import { getGraphQLPlatform } from './get-graphql-platform';

function convertToRestFormat(appStoreSettings) {
  const {
    company, id, originalName, thumbnail,
  } = appStoreSettings;

  return {
    company,
    id,
    name: originalName,
    thumbnail,
  };
}

export function handleAppStoreSettingsGraphQLResponse({
  data: {
    appStoreSettings,
  } = {},
}) {
  return {
    response: [convertToRestFormat(appStoreSettings)],
  };
}

export function handleAppStoreSettingsSearchGraphQLResponse({
  data: {
    appStoreSettingsSearch,
  } = {},
}) {
  return {
    response: map(appStoreSettingsSearch, convertToRestFormat),
  };
}

export function getAppStoreSettings(platform, id) {
  return getClient()
    .query({
      query: GetAppStoreSettings,
      variables: {
        platform: getGraphQLPlatform(platform),
        id,
      },
    })
    .then(handleAppStoreSettingsGraphQLResponse)
    .catch(handleGraphQLError);
}

export function searchAppStoreSettings(platform, searchKeyword) {
  return getClient()
    .query({
      query: SearchAppStoreSettings,
      variables: {
        platform: getGraphQLPlatform(platform),
        searchKeyword,
      },
    })
    .then(handleAppStoreSettingsSearchGraphQLResponse)
    .catch(handleGraphQLError);
}
