import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { selections } from '../../../lib/date';

// NOTE: There is a space(' ') before 'quick_picker_selected'.
const getSelectionClassName = (quickPickSelection, item) => `quick_picker_item${quickPickSelection === item ? ' quick_picker_selected' : ''}`;

const DateRangeQuickPicker = ({
  quickPickSelection,
  onQuickPickChange,
  prefixCls,
}) => (
  <div className={`${prefixCls}-quick-picker`}>
    {Object.keys(selections).map((key) => (
      <div
        key={key}
        className={getSelectionClassName(quickPickSelection, selections[key].value)}
        onClick={(e) => onQuickPickChange(selections[key], e)}
      >
        {selections[key].text}
      </div>
    ))}
  </div>
);
DateRangeQuickPicker.propTypes = {
  /**
   * Prefix class for DateRangeQuickPicker
   */
  prefixCls: PropTypes.string.isRequired,
  /**
   * Selected quick item
   */
  quickPickSelection: PropTypes.string,
  /**
   * On quick item click event
   */
  onQuickPickChange: PropTypes.func,
};
DateRangeQuickPicker.defaultProps = {
  quickPickSelection: null,
  onQuickPickChange: noop,
};
export default DateRangeQuickPicker;
