import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  TARGET_GOALS_DESCRIPTIONS,
  TARGET_GOALS_DETAILS,
  BUDGET_OBJECTIVE_ROAS,
  BUDGET_OBJECTIVE_CPPU,
  BUDGET_OBJECTIVE_CPE,
} from 'app/constants/campaign';
import Application from 'models/Application';
import Campaign from 'models/Campaign';
import {
  getTargetGoals,
} from 'lib/capabilities/campaigns';
import './TargetGoal.scss';

export const targetGoalKey = 'budget.objective.type';
export const cpeTargetEventNameKey = 'budget.objective.cpe_target_event_name';
export const postInstallEventsKey = 'postInstallEvents';

const getIcon = (type) => {
  switch (type) {
    case BUDGET_OBJECTIVE_ROAS:
      return 'attach_money';
    case BUDGET_OBJECTIVE_CPPU:
      return 'account_circle';
    case BUDGET_OBJECTIVE_CPE:
      return 'bolt';
    default:
      return '';
  }
};

export default function TargetGoal({ campaign }) {
  const displayableTargetGoals = getTargetGoals(campaign);
  const selectedType = campaign?.get(targetGoalKey);

  const loadPostInstallEvents = useCallback(async (cpeCampaign) => {
    const applicationId = cpeCampaign?.get('application').get('id');
    if (applicationId) {
      const events = await Application.getPostInstallEvents(applicationId);
      cpeCampaign?.set(postInstallEventsKey, events ?? []);
    }
  }, []);

  useEffect(() => {
    if (campaign?.get(targetGoalKey) === BUDGET_OBJECTIVE_CPE) {
      loadPostInstallEvents(campaign);
    }
  }, []);

  const handleChangeSelection = useCallback((type) => () => {
    campaign?.set(targetGoalKey, type);
    if (type === BUDGET_OBJECTIVE_CPE) {
      loadPostInstallEvents(campaign);
    } else {
      campaign?.set(cpeTargetEventNameKey, null);
    }
  }, [campaign]);

  return (
    <div className="targetGoal-container">
      {displayableTargetGoals.map((type) => (
        <div
          key={type}
          className={classNames('targetGoal', { 'targetGoal--selected': type === selectedType })}
          onClick={handleChangeSelection(type)}
        >
          <div className={classNames('targetGoal__icon-container', { 'targetGoal__icon-container--selected': type === selectedType })}>
            <i className={classNames('targetGoal__icon material-icons', { 'targetGoal__icon--selected': type === selectedType })}>{getIcon(type)}</i>
          </div>
          <p className={classNames('targetGoal__label', { 'targetGoal__label--selected': type === selectedType })}>
            {type}
            :
          </p>
          <p className={classNames('targetGoal__description', { 'targetGoal__description--selected': type === selectedType })}>{TARGET_GOALS_DESCRIPTIONS[type]}</p>
          <p className="targetGoal__details">{TARGET_GOALS_DETAILS[type]}</p>
        </div>
      ))}
    </div>
  );
}

TargetGoal.propTypes = {
  campaign: PropTypes.instanceOf(Campaign).isRequired,
};
