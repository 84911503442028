import React from 'react';
import PropTypes from 'prop-types';

export const TreeContextTypes = {
  /**
   * className prefix of tree
   */
  prefixCls: PropTypes.string.isRequired,

  /**
   * node expand handler
   */
  onNodeExpand: PropTypes.func.isRequired,

  /**
   * node check handler
   */
  onNodeCheck: PropTypes.func.isRequired,

  /**
   * node render wrapper, wrap checked, halfChecked, expanded, deep
   */
  renderTreeNode: PropTypes.func.isRequired,
};

const TreeContext = React.createContext(null);

export default TreeContext;
