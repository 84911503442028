import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Checkbox from '../../Checkbox/Checkbox';

import MenuContext, { MenuContextTypes } from './context';

class InnerMenuAll extends React.Component {
  renderCheckbox = (prefixCls) => {
    const { context, children } = this.props;

    const { some, checked } = context.getAllStatus();

    return (
      <Checkbox
        className={`${prefixCls}-checkbox`}
        indeterminate={some}
        checked={checked}
        label={children}
        onChange={this.onChange}
      />
    );
  };

  onChange = () => {
    const { context } = this.props;
    const { toggleAll } = context;
    toggleAll();
  };

  render() {
    const { context, className } = this.props;
    const { prefixCls: contextPrefixCls } = context;

    const prefixCls = `${contextPrefixCls}-all`;

    return (
      <div className={classnames(prefixCls, className)}>
        {this.renderCheckbox(prefixCls)}
      </div>
    );
  }
}

InnerMenuAll.context = MenuContextTypes;

InnerMenuAll.propTypes = {
  /**
   * className of MenuAll
   */
  className: PropTypes.string,
};

InnerMenuAll.defaultProps = {
  className: '',
};

const MenuAll = (props) => (
  <MenuContext.Consumer>
    {(context) => <InnerMenuAll {...props} context={context} />}
  </MenuContext.Consumer>
);

MenuAll.displayName = 'MenuAll';

export default MenuAll;
