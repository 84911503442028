import React from 'react';
import filesize from 'filesize';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';
import ProgressBar from './ProgressBar';
import './file.scss';

const classPrefix = 'v2_component_uploader_file';

const File = ({
  className, file, error, percent, onClose, onChange,
}) => {
  const complete = percent === 100;
  return (
    <div key={file.name} className={classnames(classPrefix, className)}>
      <div className={classnames('info', { 'info--error': error })}>
        <i className="material-icons">insert_drive_file</i>
        <span>{file.name && file.name.split('.').pop().toUpperCase()}</span>
        <span>{file.name}</span>
      </div>
      <div className="state">
        <span className="p8">
          {filesize(file.size)}
          <span className="divider" />
          {error ? 'Error' : `${Math.floor(percent)}%`}
        </span>
        {(error || !complete) ? (
          <i
            className={classnames('material-icons', 'close', {
              [`${classPrefix} state--complete`]: complete,
            })}
            onClick={() => onClose(file)}
          >
            close
          </i>
        ) : (
          <i className="material-icons icons-success">check</i>
        )}
      </div>
      <div className="bar">
        <ProgressBar
          error={error}
          start={percent !== 0}
          onChange={onChange}
          complete={complete}
          waitingProgress={99.9}
        />
      </div>
    </div>
  );
};

File.propTypes = {
  /**
   * Class name string.
   */
  className: PropTypes.string,
  /**
   * Progress percentage.
   */
  percent: PropTypes.number,
  /**
   * File object.
   */
  // eslint-disable-next-line react/forbid-prop-types
  file: PropTypes.object,
  /*
   * Tell if error happens.
   */
  error: PropTypes.bool,
  /**
   * Called when click close icon.
   */
  onClose: PropTypes.func,
};

File.defaultProps = {
  className: null,
  percent: 0,
  file: {},
  error: null,
  onClose: noop,
};

export default File;
