import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Tree, TreeNode } from '../../../../TreeSelect/TreeSelect';

const prefixCls = 'v2_component_filters_multiTree-wrapper-tree';

function TreeContainer({
  tree,
  nodes,
  checkableNodes,
  checkedNodes,
  renderNode,
  renderLeaf,
  maxSelected,
  disabledTitle,
  disabledContent,
  treeChildrenKey,
  onLoadChildren,
  onExpand,
  onChange,
}) {
  const onTreeChange = React.useCallback((_checkedNodes) => {
    const newCheckedNodes = [];

    // add out of tree nodes
    checkedNodes.forEach((checkedNode) => {
      if (checkableNodes.findIndex((cn) => cn.id === checkedNode.id) === -1) {
        newCheckedNodes.push(checkedNode);
      }
    });

    // add checked nodes
    _checkedNodes.forEach(({ deep, node }) => {
      if (tree && deep === 0) return;
      const { nodeData } = node.props;
      if (newCheckedNodes.findIndex((cn) => cn.id === nodeData.id) === -1) {
        newCheckedNodes.push(nodeData);
      }
    });

    onChange(newCheckedNodes);
  }, [tree, checkedNodes, checkableNodes, onChange]);

  const renderLeafNode = React.useCallback((node, parentNode) => (
    <TreeNode
      key={node.id}
      dataKey={node.id}
      nodeData={node}
      title={() => renderLeaf(node)}
      parentNode={parentNode}
      disabled={node.disabled}
      isLeaf
    />
  ), [renderLeaf]);

  return (
    <div className={`${prefixCls}`}>
      <Tree
        onChange={onTreeChange}
        maxSelected={maxSelected}
        disabledTitle={disabledTitle}
        disabledContent={disabledContent}
        checkedKeys={checkedNodes.map((cn) => cn.id)}
        onLoadChildren={onLoadChildren}
        onExpand={onExpand}
      >
        {
          nodes.map((node) => (
            tree
              ? (
                <TreeNode
                  key={node.id}
                  dataKey={node.id}
                  nodeData={node}
                  title={() => (renderNode ? renderNode(node) : renderLeaf(node))}
                  loading={node.loading}
                  disabled={node.disabled}
                >
                  {node[treeChildrenKey].map((n) => renderLeafNode(n, node))}
                </TreeNode>
              )
              : renderLeafNode(node)
          ))
        }
      </Tree>
    </div>
  );
}

TreeContainer.propTypes = {
  /**
   * checked node list
   */
  checkedNodes: PropTypes.arrayOf(
    PropTypes.any,
  ).isRequired,
  /**
   * checkable node list
   */
  checkableNodes: PropTypes.arrayOf(
    PropTypes.any,
  ).isRequired,
  /**
   * tree node list
   */
  nodes: PropTypes.arrayOf(
    PropTypes.any,
  ).isRequired,
  /**
   * node render func
   */
  renderNode: PropTypes.func,
  /**
   * leaf node render func
   */
  renderLeaf: PropTypes.func.isRequired,
  /**
   * Maximum nodes that allowed selected.
  */
  maxSelected: PropTypes.number,
  /**
    * Tips title message when reach maximum nodes allowed selected.
   */
  disabledTitle: PropTypes.string,
  /**
    * Tips content message when reach maximum nodes allowed selected.
   */
  disabledContent: PropTypes.string,
  /*
  * Load children methods, used for campaign and creative filter.
  */
  onLoadChildren: PropTypes.func,
  /**
   * tree change
   */
  onChange: PropTypes.func.isRequired,
  /**
   * tree expand
   */
  onExpand: PropTypes.func,
  /**
   * tree view
   */
  tree: PropTypes.bool,
  /**
   * children key
   */
  treeChildrenKey: PropTypes.string,
};

TreeContainer.defaultProps = {
  renderNode: null,
  tree: false,
  maxSelected: 0,
  disabledTitle: '',
  disabledContent: '',
  treeChildrenKey: 'children',
  onLoadChildren: noop,
  onExpand: noop,
};

export default React.memo(TreeContainer);
