import React, {
  compose, Switch, Route, Redirect,
} from '../../../../app/app';
import List from './List/ListContainer';
import paths from '../../paths';
import './index.scss';

const {
  APPLICATIONS: {
    pathString: baseRoute,
  },
} = paths;

const Applications = () => (
  <div className="views__applications">
    <Switch>
      <Route
        exact
        path={baseRoute}
        component={List}
      />
      <Redirect to={baseRoute} />
    </Switch>
  </div>
);

export default compose(Applications);
