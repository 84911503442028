const descriptions = {
  headerTitle: 'Publisher App Targeting',
  headerDesc: 'Choose to target specific Applications',
  warningInfo: `
    Make sure to cross-reference Account Targeting and App Targeting on the Account-level, App-Level, Campaign-level pages. 
    Higher level targeting will supercede lower level targeting. 
    (i.e. Cannot deny Angry Birds at the App-level if Rovio is already denied at the Account-Level)
  `,
  headsUp: 'Insert publisher app IDs below for targeting. '
    + 'The publisher app IDs are passed on tracking links and are also viewable in publisher-level reporting.',
  radioTitle: 'Publisher App Targeting',
  radioDesc: '',
  searchPlaceholder: 'Search by publisher name or ID...',
  inputPlaceholder: 'Comma, space, or new line separated list of Publisher App IDs. Maximum 500 IDs can be pasted at once.',
  copy: 'Copy Publisher IDs',
  columnName: 'Publisher App Name',
  columnId: 'Publisher App Vungle ID',
  deleteAllTitle: 'Remove All publishers',
  deleteAllMessage: 'Heads up, you are requesting to delete all the publisher rows. Would you like to continue with this action?',
};

export default {
  account: {
    ...descriptions,
    radioDesc: 'By selecting deny, your account will not target the specified applications.',
  },
  application: {
    ...descriptions,
    radioDesc: 'By selecting deny, your application will not target the specified applications.',
  },
  campaign: {
    ...descriptions,
    radioDesc: `
      By selecting allow, your campaign will only target the specified applications. 
      By selecting deny, your campaign will not target the specified applications. 
      You may not deny accounts when allowing applications.
    `,
  },
};
