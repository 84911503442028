import React from 'react';
import getAppConfig from '../../../../../lib/helpers/getAppConfig/getAppConfig';
import Button from '../../../../../components/Button/Button';
import Link from '../../../../../components/Link/Link';
import CountrySelect from '../../../../../components/Form/CountrySelectBox/CountrySelectBox';
import Radio from '../../../../../components/Form/Radio/Radio';
import Select from '../../../../../components/Form/Select/SelectContainer';
import Text from '../../../../../components/Form/Text/TextContainer';
import Copy from '../../../../../components/Form/Text/Copy';
import Group from '../../../../../templates/input-group/InputGroupContainer';
import { CollapseSection } from '../../../../../components/V2';
import './details.scss';

const { Option } = Select;
const { Field } = Group;

const reportAPIMessage = () => (
  <p className="report-message">
    <span>Access your reporting API Key. For help, visit the API documentation</span>
    <Link to="https://support.vungle.com/hc/en-us/articles/115003842687-Reporting-API-2-0-for-Advertisers" external>here.</Link>
  </p>
);

const i = (account, field, placeholder) => (
  <Text noIcon style={{ fontSize: 14 }} placeholder={placeholder} onChange={(v) => account.set(field, v)} value={account.get(field)} />
);

// Add below props back to Details when we GA API
// authUser, regenerateApiKey
const Details = ({
  account, includeType = false, users, saveAccount, nameDisabled = true, showButtons = true, showReportingKey = true,
}) => (
  <div className="details">
    <CollapseSection title="Details" description="Information related to your account." id="details">
      {includeType && (
        <Field name="Type" description="Demand account type, for flagging direct versus agency, etc.">
          <Radio onChange={(v) => account.set('type', v)} value={account.get('type')} name="account_details_type">
            <Radio.Option value="developer" label="Developer" />
            <Radio.Option value="agency" label="Agency" />
            <Radio.Option value="network" label="Network" />
          </Radio>
        </Field>
      )}
      <Field name="Name" description="Account name">
        <Text noIcon disabled={nameDisabled} style={{ fontSize: 14 }} value={account.get('name')} onChange={(v) => account.set('name', v)} />
      </Field>
      <Field name="Primary User" description="Main contact for account">
        {account.getPrimaryUser(users) && (
          <Select value={account.getPrimaryUser(users).get('id') || null} optionHeight={80} onChange={(v) => account.setPrimaryUser(users, v)}>
            {users.map((user) => (
              <Option key={user.get('id')} value={user.get('id')}>
                <div className="user-thumbnail f fh fv" style={{ padding: 16, paddingLeft: 0 }}>
                  <div>
                    <p className="p2" style={{ paddingLeft: 16, textTransform: 'capitilize' }}>{user.get('name')}</p>
                    <p className="p2" style={{ paddingLeft: 16 }}>{user.get('email')}</p>
                  </div>
                </div>
              </Option>
            ))}
          </Select>
        )}
      </Field>
      <Field name="Account ID" description="Your Vungle Account ID.">
        <Text noIcon disabled value={account.get('id')} />
      </Field>
      {/*<Field name="API Key" description="Access or regenerate your Vungle API Key.">
        <div className="api_key">
          <Text noIcon disabled value={authUser.token} />
          <Button
            ghost
            onClick={() => {
              triggerConfirm({
                type: 'REGENERATE_VUNGLE_API_KEY_CONFIRM_ACTION',
                header: 'Regenerate Vungle API Key',
                message: `
                  Once regenerated, all tools using the Vungle API Key must be changed to work properly.
                  Are you sure you want to regenerate your Vungle API Key?
                `,
                onConfirm: () => regenerateApiKey(authUser),
              });
            }}
          >
            Regenerate
          </Button>
        </div>
      </Field> */}
      {showReportingKey && (
        <Field name="Reporting API Key" description={reportAPIMessage()}>
          <div className="reporting_key">
            <Copy disabled value={getAppConfig('user', (u) => u.get('token'))} />
            {/* <Button
              ghost
              onClick={() => {
                triggerConfirm({
                  type: 'REGENERATE_VUNGLE_REPORTING_KEY_CONFIRM_ACTION',
                  header: 'Regenerate Vungle Reporting Key',
                  message: `
                    Once regenerated, all tools using the Vungle Reporting API Key must be changed to work properly.
                    Are you sure you want to regenerate your Reporting API Key?
                  `,
                  onConfirm: () => regenerateReportingKey(authUser),
                });
              }}
            >
              Regenerate
            </Button> */}
          </div>
        </Field>
      )}
      <Field name="Phone" description="Your account's phone number.">
        {i(account, 'contact.phone', 'Phone number')}
      </Field>
      <Field name="Street Address" description="Your account's address.">
        {i(account, 'address.line1', 'Address Line 1')}
        <br />
        {i(account, 'address.line2', 'Address Line 2')}
      </Field>
      <Field name="City" description="Your account's city.">
        {i(account, 'address.city', 'City')}
      </Field>
      <Field name="State / Province / Territory" description="Your account's state/province.">
        {i(account, 'address.state', 'State')}
      </Field>
      <Field name="Zip Code / Postal Code" description="Your account's postal code.">
        {i(account, 'address.postal', 'Zip Code')}
      </Field>
      <Field name="Country" description="Your account's country.">
        <CountrySelect value={account.raw('address.country')} onChange={(v) => account.set('address.country', v)} />
      </Field>
    </CollapseSection>
    {showButtons && (
      <div className="buttons f fh fv" style={{ justifyContent: 'center', marginTop: 32, marginBottom: 16 }}>
        <div style={{ marginRight: 16 }}>
          <Link goBack>
            <Button dull>Exit</Button>
          </Link>
        </div>
        <Button continue onClick={() => saveAccount(account)}>Save</Button>
      </div>
    )}
  </div>
);

export default Details;
