import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import classNames from 'classnames';
import closeIcon from 'assets/img/close.svg';
import './privacyDialog.scss';

const PrivacyDialog = ({ onClose, onReadPolicy, orientation }) => (
  <div className="privacy_box">
    <div className={classNames('dialog', orientation)}>
      <div className="dialog_content">
        <div className="title_text" />
        <div className="body_text_container">
          <div className="body_text">
            Vungle, Inc. understands the importance of privacy. Vungle operates a mobile ad network
            (the &#39;Ad Network&#39; or the &#39;Services&#39;) through which Vungle displays targeted, contextual ads.
          </div>
        </div>
        <div className="action_buttons">
          <div className="primary_button">
            <button className="primary" type="button" onClick={() => onClose()}>
              Close&nbsp;
              <img src={closeIcon} />
            </button>
          </div>
          <div className="secondary_button">
            <button className="secondary" type="button" onClick={() => onReadPolicy()}>
              Read Vungle&#39;s Privacy Policy
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

PrivacyDialog.propTypes = {
  onClose: PropTypes.func,
  onReadPolicy: PropTypes.func,
  orientation: PropTypes.string,
};

PrivacyDialog.defaultProps = {
  onClose: noop,
  onReadPolicy: noop,
  orientation: 'landscape',
};

export default PrivacyDialog;
