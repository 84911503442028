import triggerConfirm from 'components/Modals/ConfirmAction/triggerConfirm';

// eslint-disable-next-line import/prefer-default-export
export const pauseCreative = (creative, handler) => () => triggerConfirm({
  type: 'PAUSE_CREATIVE_CONFIRM_ACTION',
  header: 'Pause Creative',
  message: 'Are you sure you want to pause this Creative?',
  name: creative.get('name'),
  onConfirm: () => handler({
    creative,
    status: 'pause',
  }),
  onConfirmAlternate: () => handler({
    creative,
    status: 'pause',
    shouldDetachAllCreatives: true,
  }),
  confirmTextAlternate: 'Yes & detach from all campaigns',
  bodyAdditional: `
    Just a heads up: If this Creative belongs to one or more SKAdNetwork Campaign IDs and you choose to detach, 
    then detaching it will free up those SKAdNetwork ID slots.
  `,
});
