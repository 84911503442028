import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import Filter from '../../../Filter/Filter';
import Dropdown from '../../../Dropdown/Dropdown';
import utils from '../../utils';
import './multi.scss';

const Multi = ({
  data,
  dropdownFixed,
  visible,
  filterKey,
  renderOverlay,
  defaultCheckedKeys,
  onVisibleChange,
  onRemove,
}) => (
  <Dropdown
    fixed={dropdownFixed}
    transitionOffset={4}
    visible={visible}
    renderOverlay={renderOverlay}
    onVisibleChange={onVisibleChange}
  >
    <Filter
      type="category"
      subType="dropdown"
      open={visible}
      onClickIcon={onRemove}
    >
      {utils.getFilterLabel(filterKey, data, defaultCheckedKeys)}
    </Filter>
  </Dropdown>
);

Multi.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
    ]),
  ).isRequired,
  dropdownFixed: PropTypes.bool,
  visible: PropTypes.bool,
  filterKey: PropTypes.string.isRequired,
  renderOverlay: PropTypes.func.isRequired,
  defaultCheckedKeys: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.any,
    ]),
  ),
  onVisibleChange: PropTypes.func,
  onRemove: PropTypes.func,
};

Multi.defaultProps = {
  dropdownFixed: false,
  visible: false,
  defaultCheckedKeys: [],
  onVisibleChange: noop,
  onRemove: noop,
};

export default React.memo(Multi);
