import imgAdjustLogo from 'assets/img/adjust_logo.png';
import imgAppsFlyerLogo from 'assets/img/appsflyer_logo.png';
import imgKochavaLogo from 'assets/img/kochava_logo.png';
import imgTuneLogo from 'assets/img/tune_logo.png';
import imgFoxLogo from 'assets/img/fox.png';
import imgLogoPartyTrack from 'assets/img/logo_party_track_black.png';
import imgMetapsLogoRed from 'assets/img/metaps-logo-red.svg';
import imgAdInnovationLogo from 'assets/img/adinnovation_logo_green.png';
import imgLogoAdBrix from 'assets/img/logo-adbrix.svg';

const defaultReplacements = (value) => [
  { key: 'PROVIDER_ID', value },
];

const trackingProviders = [
  {
    name: 'adjust',
    image: imgAdjustLogo,
    config: {
      replacements: defaultReplacements('Tracker ID'),
      click: {
        ios: 'https://app.adjust.com/%%%PROVIDER_ID%%%?idfa={{{ifa}}}&campaign={{{campaign_name}}}&adgroup={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}&creative={{{creative}}}&custom_creative={{{custom_creative}}}&vungle_click_id={{{id}}}&s2s={{{adjust_s2s}}}&install_callback=http%3A%2F%2Fapi.vungle.com%2Fapi%2Fv3%2Fnew%3Fevent_id%3D{{{id}}}%26ifa%3D{{{ifa}}}%26app_id%3D{{{app_id}}}%26conversion%3D1%26trk%3Dadj',
        android: 'https://app.adjust.com/%%%PROVIDER_ID%%%?gps_adid={{{aaid}}}&android_id={{{isu}}}&campaign={{{campaign_name}}}&adgroup={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}&creative={{{creative}}}&custom_creative={{{custom_creative}}}&vungle_click_id={{{id}}}&install_callback=http%3A%2F%2Fapi.vungle.com%2Fapi%2Fv3%2Fnew%3Fevent_id%3D{{{id}}}%26isu%3D{{{isu}}}%26aaid%3D{{{aaid}}}%26app_id%3D{{{app_id}}}%26conversion%3D1%26trk%3Dadj',
        windows: 'https://app.adjust.com/%%%PROVIDER_ID%%%?win_adid={{{ifa}}}&campaign={{{campaign_name}}}&adgroup={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}&creative={{{creative}}}&custom_creative={{{custom_creative}}}&vungle_click_id={{{id}}}&s2s=1&install_callback=http%3A%2F%2Fapi.vungle.com%2Fapi%2Fv3%2Fnew%3Fevent_id%3D{{{id}}}%26ifa%3D{{{ifa}}}%26app_id%3D{{{app_id}}}%26conversion%3D1%26trk%3Dadj',
        amazon: 'https://app.adjust.com/%%%PROVIDER_ID%%%?fire_adid={{{aaid}}}&android_id={{{isu}}}&af_r=[appstore_url]&campaign={{{campaign_name}}}&adgroup={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}&creative={{{creative}}}&custom_creative={{{custom_creative}}}&vungle_click_id={{{id}}}&install_callback=http%3A%2F%2Fapi.vungle.com%2Fapi%2Fv3%2Fnew%3Fevent_id%3D{{{id}}}%26isu%3D{{{isu}}}%26aaid%3D{{{aaid}}}%26app_id%3D{{{app_id}}}%26conversion%3D1%26trk%3Dadj',
      },
      impression: {
        ios: 'https://view.adjust.com/impression/%%%PROVIDER_ID%%%?idfa={{{ifa}}}&campaign={{{campaign_name}}}&adgroup={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}&creative={{{creative}}}&custom_creative={{{custom_creative}}}&vungle_click_id={{{id}}}&s2s={{{adjust_s2s}}}&install_callback=http%3A%2F%2Fapi.vungle.com%2Fapi%2Fv3%2Fnew%3Fevent_id%3D{{{id}}}%26ifa%3D{{{ifa}}}%26app_id%3D{{{app_id}}}%26conversion%3D1%26trk%3Dadj',
        android: 'https://view.adjust.com/impression/%%%PROVIDER_ID%%%?gps_adid={{{aaid}}}&android_id={{{isu}}}&campaign={{{campaign_name}}}&adgroup={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}&creative={{{creative}}}&custom_creative={{{custom_creative}}}&vungle_click_id={{{id}}}&install_callback=http%3A%2F%2Fapi.vungle.com%2Fapi%2Fv3%2Fnew%3Fevent_id%3D{{{id}}}%26isu%3D{{{isu}}}%26aaid%3D{{{aaid}}}%26app_id%3D{{{app_id}}}%26conversion%3D1%26trk%3Dadj',
        windows: 'https://view.adjust.com/impression/%%%PROVIDER_ID%%%?win_adid={{{ifa}}}&campaign={{{campaign_name}}}&adgroup={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}&creative={{{creative}}}&custom_creative={{{custom_creative}}}&vungle_click_id={{{id}}}&install_callback=http%3A%2F%2Fapi.vungle.com%2Fapi%2Fv3%2Fnew%3Fevent_id%3D{{{id}}}%26ifa%3D{{{ifa}}}%26app_id%3D{{{app_id}}}%26conversion%3D1%26trk%3Dadj',
        amazon: 'https://view.adjust.com/impression/%%%PROVIDER_ID%%%?fire_adid={{{aaid}}}&android_id={{{isu}}}&campaign={{{campaign_name}}}&adgroup={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}&creative={{{creative}}}&custom_creative={{{custom_creative}}}&vungle_click_id={{{id}}}&install_callback=http%3A%2F%2Fapi.vungle.com%2Fapi%2Fv3%2Fnew%3Fevent_id%3D{{{id}}}%26isu%3D{{{isu}}}%26aaid%3D{{{aaid}}}%26app_id%3D{{{app_id}}}%26conversion%3D1%26trk%3Dadj',
      },
    },
  },
  {
    name: 'appsflyer',
    image: imgAppsFlyerLogo,
    config: {
      replacements: defaultReplacements('App Store App ID'),
      click: {
        ios: 'https://app.appsflyer.com/id%%%PROVIDER_ID%%%?pid=vungle_int&c={{{campaign_name}}}&idfa={{{ifa}}}&clickid={{{id}}}&af_siteid={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}&af_sub1={{{creative}}}&af_cost_model=%%%BID_TYPE%%%&af_cost_value={{{bid_price}}}&af_cost_currency={{{bid_currency}}}',
        android: 'https://app.appsflyer.com/%%%PROVIDER_ID%%%?pid=vungle_int&c={{{campaign_name}}}&advertising_id={{{aaid}}}&android_id={{{isu}}}&clickid={{{id}}}&af_siteid={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}&af_sub1={{{creative}}}&af_cost_model=%%%BID_TYPE%%%&af_cost_value={{{bid_price}}}&af_cost_currency={{{bid_currency}}}',
        windows: 'https://app.appsflyer.com/%%%PROVIDER_ID%%%?pid=vungle_int&c={{{campaign_name}}}&advertising_id={{{ifa}}}&clickid={{{id}}}&af_siteid={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}&af_sub1={{{creative}}}&af_cost_model=%%%BID_TYPE%%%&af_cost_value={{{bid_price}}}&af_cost_currency={{{bid_currency}}}',
        amazon: 'https://app.appsflyer.com/%%%PROVIDER_ID%%%?pid=vungle_int&c={{{campaign_name}}}&advertising_id={{{aaid}}}&android_id={{{isu}}}&af_r=[appstore_url]&clickid={{{id}}}&af_siteid={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}&af_sub1={{{creative}}}&af_cost_model=%%%BID_TYPE%%%&af_cost_value={{{bid_price}}}&af_cost_currency={{{bid_currency}}}',
      },
      impression: {
        ios: 'https://impression.appsflyer.com/id%%%PROVIDER_ID%%%?pid=vungle_int&c={{{campaign_name}}}&idfa={{{ifa}}}&clickid={{{id}}}&af_siteid={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}&af_sub1={{{creative}}}&af_cost_model=%%%BID_TYPE%%%&af_cost_value={{{bid_price}}}&af_cost_currency={{{bid_currency}}}',
        android: 'https://impression.appsflyer.com/%%%PROVIDER_ID%%%?pid=vungle_int&c={{{campaign_name}}}&advertising_id={{{aaid}}}&android_id={{{isu}}}&clickid={{{id}}}&af_siteid={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}&af_sub1={{{creative}}}&af_cost_model=%%%BID_TYPE%%%&af_cost_value={{{bid_price}}}&af_cost_currency={{{bid_currency}}}',
        windows: 'https://impression.appsflyer.com/%%%PROVIDER_ID%%%?pid=vungle_int&c={{{campaign_name}}}&advertising_id={{{ifa}}}&clickid={{{id}}}&af_siteid={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}&af_sub1={{{creative}}}&af_cost_model=%%%BID_TYPE%%%&af_cost_value={{{bid_price}}}&af_cost_currency={{{bid_currency}}}',
        amazon: 'https://impression.appsflyer.com/%%%PROVIDER_ID%%%?pid=vungle_int&c={{{campaign_name}}}&advertising_id={{{aaid}}}&android_id={{{isu}}}&clickid={{{id}}}&af_siteid={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}&af_sub1={{{creative}}}&af_cost_model=%%%BID_TYPE%%%&af_cost_value={{{bid_price}}}&af_cost_currency={{{bid_currency}}}',
      },
    },
  },
  {
    name: 'kochava',
    image: imgKochavaLogo,
    config: {
      replacements: defaultReplacements('campaign_id'),
      click: {
        ios: 'https://control.kochava.com/v1/cpi/click?campaign_id=%%%PROVIDER_ID%%%&network_id=551&creative_id={{{creative}}}&device_id={{{ifa}}}&device_id_type=idfa&event_id={{{id}}}&site_id={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}',
        android: 'https://control.kochava.com/v1/cpi/click?campaign_id=%%%PROVIDER_ID%%%&network_id=550&creative_id={{{creative}}}&device_id={{{aaid}}}&android_id={{{isu}}}&device_id_type=adid&event_id={{{id}}}&site_id={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}',
        windows: 'https://control.kochava.com/v1/cpi/click?campaign_id=%%%PROVIDER_ID%%%&network_id=3584&creative_id={{{creative}}}&device_id={{{ifa}}}&device_id_type=waid&event_id={{{id}}}&site_id={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}',
        amazon: 'https://control.kochava.com/v1/cpi/click?campaign_id=%%%PROVIDER_ID%%%&network_id=550&creative_id={{{creative}}}&device_id={{{aaid}}}&android_id={{{isu}}}&af_r=[appstore_url]&device_id_type=adid&event_id={{{id}}}&site_id={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}',
      },
      impression: {
        ios: 'https://imp.control.kochava.com/track/impression?campaign_id=%%%PROVIDER_ID%%%&network_id=551&creative_id={{{creative}}}&device_id={{{ifa}}}&device_id_type=idfa&event_id={{{id}}}&site_id={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}',
        android: 'https://imp.control.kochava.com/track/impression?campaign_id=%%%PROVIDER_ID%%%&network_id=550&creative_id={{{creative}}}&device_id={{{aaid}}}&android_id={{{isu}}}&device_id_type=adid&event_id={{{id}}}&site_id={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}',
        windows: 'https://imp.control.kochava.com/track/impression?campaign_id=%%%PROVIDER_ID%%%&network_id=3584&creative_id={{{creative}}}&device_id={{{ifa}}}&device_id_type=waid&event_id={{{id}}}&site_id={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}',
        amazon: 'https://imp.control.kochava.com/track/impression?campaign_id=%%%PROVIDER_ID%%%&network_id=550&creative_id={{{creative}}}&device_id={{{aaid}}}&android_id={{{isu}}}&device_id_type=adid&event_id={{{id}}}&site_id={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}',
      },
    },
  },
  {
    name: 'tune',
    image: imgTuneLogo,
    config: {
      replacements: [
        { key: 'PROVIDER_URL', value: 'URL' },
        { key: 'PROVIDER_SITE_ID', value: 'Site ID' },
        { key: 'PROVIDER_PUBLISHER_ID', value: 'Publisher ID' },
        { key: 'PROVIDER_OFFER_ID', value: 'Offer ID' },
      ],
      click: {
        ios: 'https://%%%PROVIDER_URL%%%/serve?action=click&site_id=%%%PROVIDER_SITE_ID%%%&publisher_id=%%%PROVIDER_PUBLISHER_ID%%%&offer_id=%%%PROVIDER_OFFER_ID%%%&ios_ifa={{{ifa}}}&tracking_id={{{id}}}&sub_site={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}&sub_ad={{{creative}}}&sub_campaign={{{campaign_name}}}',
        android: 'https://%%%PROVIDER_URL%%%/serve?action=click&site_id=%%%PROVIDER_SITE_ID%%%&publisher_id=%%%PROVIDER_PUBLISHER_ID%%%&offer_id=%%%PROVIDER_OFFER_ID%%%&google_aid={{{aaid}}}&android_id={{{isu}}}&tracking_id={{{id}}}&sub_site={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}&sub_ad={{{creative}}}&sub_campaign={{{campaign_name}}}',
        windows: 'https://%%%PROVIDER_URL%%%/serve?action=click&site_id=%%%PROVIDER_SITE_ID%%%&publisher_id=%%%PROVIDER_PUBLISHER_ID%%%&offer_id=%%%PROVIDER_OFFER_ID%%%&windows_aid={{{ifa}}}&tracking_id={{{id}}}&sub_site={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}&sub_ad={{{creative}}}&sub_campaign={{{campaign_name}}}',
        amazon: 'https://%%%PROVIDER_URL%%%/serve?action=click&site_id=%%%PROVIDER_SITE_ID%%%&publisher_id=%%%PROVIDER_PUBLISHER_ID%%%&offer_id=%%%PROVIDER_OFFER_ID%%%&google_aid={{{aaid}}}&android_id={{{isu}}}&af_r=[appstore_url]&tracking_id={{{id}}}&sub_site={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}&sub_ad={{{creative}}}&sub_campaign={{{campaign_name}}}',
      },
      impression: {
        ios: 'https://%%%PROVIDER_URL%%%/serve?action=impression&site_id=%%%PROVIDER_SITE_ID%%%&publisher_id=%%%PROVIDER_PUBLISHER_ID%%%&offer_id=%%%PROVIDER_OFFER_ID%%%&ios_ifa={{{ifa}}}&tracking_id={{{id}}}&sub_site={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}&sub_ad={{{creative}}}&sub_campaign={{{campaign_name}}}',
        android: 'https://%%%PROVIDER_URL%%%/serve?action=impression&site_id=%%%PROVIDER_SITE_ID%%%&publisher_id=%%%PROVIDER_PUBLISHER_ID%%%&offer_id=%%%PROVIDER_OFFER_ID%%%&google_aid={{{aaid}}}&android_id={{{isu}}}&tracking_id={{{id}}}&sub_site={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}&sub_ad={{{creative}}}&sub_campaign={{{campaign_name}}}',
        windows: 'https://%%%PROVIDER_URL%%%/serve?action=impression&site_id=%%%PROVIDER_SITE_ID%%%&publisher_id=%%%PROVIDER_PUBLISHER_ID%%%&offer_id=%%%PROVIDER_OFFER_ID%%%&windows_aid={{{ifa}}}&tracking_id={{{id}}}&sub_site={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}&sub_ad={{{creative}}}&sub_campaign={{{campaign_name}}}',
        amazon: 'https://%%%PROVIDER_URL%%%/serve?action=impression&site_id=%%%PROVIDER_SITE_ID%%%&publisher_id=%%%PROVIDER_PUBLISHER_ID%%%&offer_id=%%%PROVIDER_OFFER_ID%%%&google_aid={{{aaid}}}&android_id={{{isu}}}&tracking_id={{{id}}}&sub_site={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}&sub_ad={{{creative}}}&sub_campaign={{{campaign_name}}}',
      },
    },
  },
  {
    name: 'fox',
    image: imgFoxLogo,
    config: {
      replacements: [
        { key: 'SITE', value: 'Site' },
        { key: 'ARTICLE', value: 'Article' },
        { key: 'LINK', value: 'Link' },
      ],
      click: {
        android: 'https://c1.app-adforce.jp/ad/p/r?_site=%%%SITE%%%&_article=%%%ARTICLE%%%&_link=%%%LINK%%%&sad={{{id}}}&_spl={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}&adid={{{ifa}}}&anid={{{isu}}}&_nored=1',
        ios: 'https://c1.app-adforce.jp/ad/p/r?_site=%%%SITE%%%&_article=%%%ARTICLE%%%&_link=%%%LINK%%%&sad={{{id}}}&_spl={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}&adid={{{ifa}}}&anid={{{isu}}}&_nored=1',
        amazon: 'https://c1.app-adforce.jp/ad/p/r?_site=%%%SITE%%%&_article=%%%ARTICLE%%%&_link=%%%LINK%%%&sad={{{id}}}&_spl={{{site_id}}}&af_r=[appstore_url]&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}&adid={{{ifa}}}&anid={{{isu}}}&_nored=1',
      },
    },
  },
  {
    name: 'partyTrack',
    image: imgLogoPartyTrack,
    config: {
      replacements: [
        { key: 'ADV_ID', value: 'Advertisement ID' },
        { key: 'TRACKING_ID', value: 'Tracking ID' },
      ],
      click: {
        android: 'https://click.adzcore.com/1.0.%%%ADV_ID%%%c?pt_gid=%%%TRACKING_ID%%%&android_id={{{isu}}}&aaid={{{lowercase aaid}}}&click_id={{{id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}',
        ios: 'https://click.adzcore.com/1.0.%%%ADV_ID%%%c?pt_gid=%%%TRACKING_ID%%%&idfa={{{uppercase ifa}}}&app_id={{{app_id}}}&click_id={{{id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}',
        amazon: 'https://click.adzcore.com/1.0.%%%ADV_ID%%%c?pt_gid=%%%TRACKING_ID%%%&android_id={{{isu}}}&aaid={{{lowercase aaid}}}&af_r=[appstore_url]&click_id={{{id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}',
      },
      impression: {
        android: 'https://click.adzcore.com/1.0.%%%ADV_ID%%%c?pt_gid=%%%TRACKING_ID%%%&android_id={{{isu}}}&aaid={{{lowercase aaid}}}&click_id={{{id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}&direct=1&redirection=0',
        ios: 'https://click.adzcore.com/1.0.%%%ADV_ID%%%c?pt_gid=%%%TRACKING_ID%%%&idfa={{{uppercase ifa}}}&app_id={{{app_id}}}&click_id={{{id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}&direct=1&redirection=0',
        amazon: 'https://click.adzcore.com/1.0.%%%ADV_ID%%%c?pt_gid=%%%TRACKING_ID%%%&android_id={{{isu}}}&aaid={{{lowercase aaid}}}&click_id={{{id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}&direct=1&redirection=0',
      },
    },
  },
  {
    name: 'metaps',
    image: imgMetapsLogoRed,
    config: {
      replacements: [
        { key: 'CAMPAIGN_ID', value: 'Campaign ID' },
        { key: 'NETWORK_ID', value: 'Network ID' },
        { key: 'PLACEMENT_ID', value: 'Placement ID' },
      ],
      click: {
        android: 'https://t.metaps.biz/v1/cpi/click?campaign_id=%%%CAMPAIGN_ID%%%&network_id=%%%NETWORK_ID%%%&android_id={{{isu}}}&cp_name1=CPI&cp_name3={{{campaign_name}}}&cp_value1={{{bid_price}}}&cp_value2={{{site_name}}}&creative_id={{{creative}}}&device_id={{{aaid}}}&device_id_type=adid&event_id={{{id}}}&placement=%%%PLACEMENT_ID%%%&ref_id={{{isu}}}&site_id={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}',
        ios: 'https://t.metaps.biz/v1/cpi/click?campaign_id=%%%CAMPAIGN_ID%%%&network_id=%%%NETWORK_ID%%%&campaign_tier1_value={{{campaign_id}}}&creative_id={{{creative}}}&device_id={{{ifa}}}&device_id_type=idfa&event_id={{{id}}}&site_id={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}',
        amazon: 'https://t.metaps.biz/v1/cpi/click?campaign_id=%%%CAMPAIGN_ID%%%&network_id=%%%NETWORK_ID%%%&android_id={{{isu}}}&af_r=[appstore_url]&cp_name1=CPI&cp_name3={{{campaign_name}}}&cp_value1={{{bid_price}}}&cp_value2={{{site_name}}}&creative_id={{{creative}}}&device_id={{{aaid}}}&device_id_type=adid&event_id={{{id}}}&placement=%%%PLACEMENT_ID%%%&ref_id={{{isu}}}&site_id={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}',
      },
    },
  },
  {
    name: 'adinnovation',
    image: imgAdInnovationLogo,
    config: {
      replacements: [
        { key: 'ID', value: 'ID' },
      ],
      click: {
        android: 'https://api.adstapi.com/app/click?k=%%%ID%%%&_deviceid={{{aaid}}}&suid={{{id}}}&site_id={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}',
        ios: 'https://api.adstapi.com/app/click?k=%%%ID%%%&_deviceid={{{uppercase ifa}}}&suid={{{id}}}&site_id={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}',
        amazon: 'https://api.adstapi.com/app/click?k=%%%ID%%%&_deviceid={{{aaid}}}&suid={{{id}}}&af_r=[appstore_url]&site_id={{{site_id}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}',
      },
    },
  },
  {
    name: 'adbrix',
    image: imgLogoAdBrix,
    config: {
      replacements: [
        { key: 'AK_ID', value: 'AK ID' },
        { key: 'CK_ID', value: 'CK ID' },
      ],
      click: {
        android: 'https://ref.ad-brix.com/v1/referrallink?ak=%%%AK_ID%%%&ck=%%%CK_ID%%%&agreement_key={{{device_id}}}&cb_param1={{{aaid}}}&cb_param2={{{isu}}}&cb_param3={{{app_id}}}&cb_param4={{{id}}}&cb_param5={{{ifa}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}',
        ios: 'https://ref.ad-brix.com/v1/referrallink?ak=%%%AK_ID%%%&ck=%%%CK_ID%%%&agreement_key={{{device_id}}}&cb_param1={{{aaid}}}&cb_param2={{{isu}}}&cb_param3={{{app_id}}}&cb_param4={{{id}}}&cb_param5={{{ifa}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}',
        amazon: 'https://ref.ad-brix.com/v1/referrallink?ak=%%%AK_ID%%%&ck=%%%CK_ID%%%&agreement_key={{{device_id}}}&af_r=[appstore_url]&cb_param1={{{aaid}}}&cb_param2={{{isu}}}&cb_param3={{{app_id}}}&cb_param4={{{id}}}&cb_param5={{{ifa}}}&placement_id={{{placement_id}}}&ad_type={{{ad_type}}}',

      },
    },
  },
];

const validTrackingMacros = {
  validStrings: [
    ['%imei%', '%25imei%25'],
    ['boolean_number ad_tracking_enabled', 'boolean_number%20ad_tracking_enabled'],
    ['boolean_number ad_tracking_disabled', 'boolean_number%20ad_tracking_disabled'],
    ['boolean_number device_id', 'boolean_number%20device_id'],
    ['boolean_number isu', 'boolean_number%20isu'],
    ['boolean_number ifa', 'boolean_number%20ifa'],
    ['boolean_number aaid', 'boolean_number%20aaid'],
    ['boolean_number trackable_device_id', 'boolean_number%20trackable_device_id'],
    ['boolean_number device_ios', 'boolean_number%20device_ios'],
    ['boolean_number device_android', 'boolean_number%20device_android'],
    ['boolean_number device_amazon', 'boolean_number%20device_amazon'],
    ['boolean_number device_windows', 'boolean_number%20device_windows'],
    ['boolean_number adjust_s2s', 'boolean_number%20adjust_s2s'],
    ['boolean_number user_agent', 'boolean_number%20user_agent'],
    ['boolean_number browser_user_agent', 'boolean_number%20browser_user_agent'],
    ['boolean_number device_platform', 'boolean_number%20device_platform'],
    ['boolean_number connection_type', 'boolean_number%20connection_type'],
    ['boolean_number os_version', 'boolean_number%20os_version'],
    ['boolean_number device_model', 'boolean_number%20device_model'],
    ['boolean_number volume', 'boolean_number%20volume'],
    ['boolean_number sound_enabled', 'boolean_number%20sound_enabled'],
    ['boolean_number carrier_name', 'boolean_number%20carrier_name'],
    ['boolean_number screen_width', 'boolean_number%20screen_width'],
    ['boolean_number screen_height', 'boolean_number%20screen_height'],
    ['boolean_number eid', 'boolean_number%20eid'],
    ['boolean_number id', 'boolean_number%20id'],
    ['boolean_number app_id', 'boolean_number%20app_id'],
    ['boolean_number campaign_id', 'boolean_number%20campaign_id'],
    ['boolean_number video_filename', 'boolean_number%20video_filename'],
    ['boolean_number campaign_name', 'boolean_number%20campaign_name'],
    ['boolean_number bid_price', 'boolean_number%20bid_price'],
    ['boolean_number bid_type', 'boolean_number%20bid_type'],
    ['boolean_number bid_currency', 'boolean_number%20bid_currency'],
    ['boolean_number bid_price', 'boolean_number%20bid_price'],
    ['boolean_number creative', 'boolean_number%20creative'],
    ['boolean_number custom_creative', 'boolean_number%20custom_creative'],
    ['boolean_number creative_id', 'boolean_number%20creative_id'],
    ['boolean_number min_duration', 'boolean_number%20min_duration'],
    ['boolean_number max_duration', 'boolean_number%20max_duration'],
    ['boolean_number screen_orientation', 'boolean_number%20screen_orientation'],
    ['boolean_number market_id', 'boolean_number%20market_id'],
    ['boolean_number bundle_id', 'boolean_number%20bundle_id'],
    ['boolean_number timestamp', 'boolean_number%20timestamp'],
    ['boolean_number replaceable_timestamp', 'boolean_number%20replaceable_timestamp'],
    ['boolean_number language', 'boolean_number%20language'],
    ['boolean_number timezone', 'boolean_number%20timezone'],
    ['boolean_number ip', 'boolean_number%20ip'],
    ['boolean_number city', 'boolean_number%20city'],
    ['boolean_number region', 'boolean_number%20region'],
    ['boolean_number country', 'boolean_number%20country'],
    ['boolean_number country_name', 'boolean_number%20country_name'],
    ['boolean_number latitude', 'boolean_number%20latitude'],
    ['boolean_number longitude', 'boolean_number%20longitude'],
    ['boolean_number gender', 'boolean_number%20gender'],
    ['boolean_number age', 'boolean_number%20age'],
    ['boolean_number vungle_id', 'boolean_number%20vungle_id'],
    ['boolean_number pub_app_store_url', 'boolean_number%20pub_app_store_url'],
    ['boolean_number pub_app_store_category', 'boolean_number%20pub_app_store_category'],
    ['boolean_number pub_app_name', 'boolean_number%20pub_app_name'],
    ['boolean_number pub_app_id', 'boolean_number%20pub_app_id'],
    ['boolean_number pub_app_market_id', 'boolean_number%20pub_app_market_id'],
    ['boolean_number is_coppa_compliant', 'boolean_number%20is_coppa_compliant'],
    ['boolean_number site_id', 'boolean_number%20site_id'],
    ['boolean_number placement_id', 'boolean_number%20placement_id'],
    ['boolean_number ad_type', 'boolean_number%20ad_type'],
    ['boolean_number site_name', 'boolean_number%20site_name'],
    ['boolean_number sk_campaign_id', 'boolean_number%20sk_campaign_id'],
    ['boolean_string ad_tracking_enabled', 'boolean_string%20ad_tracking_enabled'],
    ['boolean_string ad_tracking_disabled', 'boolean_string%20ad_tracking_disabled'],
    ['boolean_string device_id', 'boolean_string%20device_id'],
    ['boolean_string isu', 'boolean_string%20isu'],
    ['boolean_string ifa', 'boolean_string%20ifa'],
    ['boolean_string aaid', 'boolean_string%20aaid'],
    ['boolean_string trackable_device_id', 'boolean_string%20trackable_device_id'],
    ['boolean_string device_ios', 'boolean_string%20device_ios'],
    ['boolean_string device_android', 'boolean_string%20device_android'],
    ['boolean_string device_amazon', 'boolean_string%20device_amazon'],
    ['boolean_string device_windows', 'boolean_string%20device_windows'],
    ['boolean_string adjust_s2s', 'boolean_string%20adjust_s2s'],
    ['boolean_string user_agent', 'boolean_string%20user_agent'],
    ['boolean_string browser_user_agent', 'boolean_string%20browser_user_agent'],
    ['boolean_string device_platform', 'boolean_string%20device_platform'],
    ['boolean_string connection_type', 'boolean_string%20connection_type'],
    ['boolean_string os_version', 'boolean_string%20os_version'],
    ['boolean_string device_model', 'boolean_string%20device_model'],
    ['boolean_string volume', 'boolean_string%20volume'],
    ['boolean_string sound_enabled', 'boolean_string%20sound_enabled'],
    ['boolean_string carrier_name', 'boolean_string%20carrier_name'],
    ['boolean_string screen_width', 'boolean_string%20screen_width'],
    ['boolean_string screen_height', 'boolean_string%20screen_height'],
    ['boolean_string eid', 'boolean_string%20eid'],
    ['boolean_string id', 'boolean_string%20id'],
    ['boolean_string app_id', 'boolean_string%20app_id'],
    ['boolean_string campaign_id', 'boolean_string%20campaign_id'],
    ['boolean_string video_filename', 'boolean_string%20video_filename'],
    ['boolean_string campaign_name', 'boolean_string%20campaign_name'],
    ['boolean_string bid_price', 'boolean_string%20bid_price'],
    ['boolean_string bid_type', 'boolean_string%20bid_type'],
    ['boolean_string bid_currency', 'boolean_string%20bid_currency'],
    ['boolean_string bid_price', 'boolean_string%20bid_price'],
    ['boolean_string creative', 'boolean_string%20creative'],
    ['boolean_string custom_creative', 'boolean_string%20custom_creative'],
    ['boolean_string creative_id', 'boolean_string%20creative_id'],
    ['boolean_string min_duration', 'boolean_string%20min_duration'],
    ['boolean_string max_duration', 'boolean_string%20max_duration'],
    ['boolean_string screen_orientation', 'boolean_string%20screen_orientation'],
    ['boolean_string market_id', 'boolean_string%20market_id'],
    ['boolean_string bundle_id', 'boolean_string%20bundle_id'],
    ['boolean_string timestamp', 'boolean_string%20timestamp'],
    ['boolean_string replaceable_timestamp', 'boolean_string%20replaceable_timestamp'],
    ['boolean_string language', 'boolean_string%20language'],
    ['boolean_string timezone', 'boolean_string%20timezone'],
    ['boolean_string ip', 'boolean_string%20ip'],
    ['boolean_string city', 'boolean_string%20city'],
    ['boolean_string region', 'boolean_string%20region'],
    ['boolean_string country', 'boolean_string%20country'],
    ['boolean_string country_name', 'boolean_string%20country_name'],
    ['boolean_string latitude', 'boolean_string%20latitude'],
    ['boolean_string longitude', 'boolean_string%20longitude'],
    ['boolean_string gender', 'boolean_string%20gender'],
    ['boolean_string age', 'boolean_string%20age'],
    ['boolean_string vungle_id', 'boolean_string%20vungle_id'],
    ['boolean_string pub_app_store_url', 'boolean_string%20pub_app_store_url'],
    ['boolean_string pub_app_store_category', 'boolean_string%20pub_app_store_category'],
    ['boolean_string pub_app_name', 'boolean_string%20pub_app_name'],
    ['boolean_string pub_app_id', 'boolean_string%20pub_app_id'],
    ['boolean_string pub_app_market_id', 'boolean_string%20pub_app_market_id'],
    ['boolean_string is_coppa_compliant', 'boolean_string%20is_coppa_compliant'],
    ['boolean_string site_id', 'boolean_string%20site_id'],
    ['boolean_string placement_id', 'boolean_string%20placement_id'],
    ['boolean_string ad_type', 'boolean_string%20ad_type'],
    ['boolean_string site_name', 'boolean_string%20site_name'],
    ['boolean_string sk_campaign_id', 'boolean_string%20sk_campaign_id'],
    ['lowercase ad_tracking_enabled', 'lowercase%20ad_tracking_enabled'],
    ['lowercase ad_tracking_disabled', 'lowercase%20ad_tracking_disabled'],
    ['lowercase device_id', 'lowercase%20device_id'],
    ['lowercase isu', 'lowercase%20isu'],
    ['lowercase ifa', 'lowercase%20ifa'],
    ['lowercase aaid', 'lowercase%20aaid'],
    ['lowercase trackable_device_id', 'lowercase%20trackable_device_id'],
    ['lowercase device_ios', 'lowercase%20device_ios'],
    ['lowercase device_android', 'lowercase%20device_android'],
    ['lowercase device_amazon', 'lowercase%20device_amazon'],
    ['lowercase device_windows', 'lowercase%20device_windows'],
    ['lowercase adjust_s2s', 'lowercase%20adjust_s2s'],
    ['lowercase user_agent', 'lowercase%20user_agent'],
    ['lowercase browser_user_agent', 'lowercase%20browser_user_agent'],
    ['lowercase device_platform', 'lowercase%20device_platform'],
    ['lowercase connection_type', 'lowercase%20connection_type'],
    ['lowercase os_version', 'lowercase%20os_version'],
    ['lowercase device_model', 'lowercase%20device_model'],
    ['lowercase volume', 'lowercase%20volume'],
    ['lowercase sound_enabled', 'lowercase%20sound_enabled'],
    ['lowercase carrier_name', 'lowercase%20carrier_name'],
    ['lowercase screen_width', 'lowercase%20screen_width'],
    ['lowercase screen_height', 'lowercase%20screen_height'],
    ['lowercase eid', 'lowercase%20eid'],
    ['lowercase id', 'lowercase%20id'],
    ['lowercase app_id', 'lowercase%20app_id'],
    ['lowercase campaign_id', 'lowercase%20campaign_id'],
    ['lowercase video_filename', 'lowercase%20video_filename'],
    ['lowercase campaign_name', 'lowercase%20campaign_name'],
    ['lowercase bid_price', 'lowercase%20bid_price'],
    ['lowercase bid_type', 'lowercase%20bid_type'],
    ['lowercase bid_currency', 'lowercase%20bid_currency'],
    ['lowercase bid_price', 'lowercase%20bid_price'],
    ['lowercase creative', 'lowercase%20creative'],
    ['lowercase custom_creative', 'lowercase%20custom_creative'],
    ['lowercase creative_id', 'lowercase%20creative_id'],
    ['lowercase min_duration', 'lowercase%20min_duration'],
    ['lowercase max_duration', 'lowercase%20max_duration'],
    ['lowercase screen_orientation', 'lowercase%20screen_orientation'],
    ['lowercase market_id', 'lowercase%20market_id'],
    ['lowercase bundle_id', 'lowercase%20bundle_id'],
    ['lowercase timestamp', 'lowercase%20timestamp'],
    ['lowercase replaceable_timestamp', 'lowercase%20replaceable_timestamp'],
    ['lowercase language', 'lowercase%20language'],
    ['lowercase timezone', 'lowercase%20timezone'],
    ['lowercase ip', 'lowercase%20ip'],
    ['lowercase city', 'lowercase%20city'],
    ['lowercase region', 'lowercase%20region'],
    ['lowercase country', 'lowercase%20country'],
    ['lowercase country_name', 'lowercase%20country_name'],
    ['lowercase latitude', 'lowercase%20latitude'],
    ['lowercase longitude', 'lowercase%20longitude'],
    ['lowercase gender', 'lowercase%20gender'],
    ['lowercase age', 'lowercase%20age'],
    ['lowercase vungle_id', 'lowercase%20vungle_id'],
    ['lowercase pub_app_store_url', 'lowercase%20pub_app_store_url'],
    ['lowercase pub_app_store_category', 'lowercase%20pub_app_store_category'],
    ['lowercase pub_app_name', 'lowercase%20pub_app_name'],
    ['lowercase pub_app_id', 'lowercase%20pub_app_id'],
    ['lowercase pub_app_market_id', 'lowercase%20pub_app_market_id'],
    ['lowercase is_coppa_compliant', 'lowercase%20is_coppa_compliant'],
    ['lowercase site_id', 'lowercase%20site_id'],
    ['lowercase placement_id', 'lowercase%20placement_id'],
    ['lowercase ad_type', 'lowercase%20ad_type'],
    ['lowercase site_name', 'lowercase%20site_name'],
    ['lowercase sk_campaign_id', 'lowercase%20sk_campaign_id'],
    ['md5 ad_tracking_enabled', 'md5%20ad_tracking_enabled'],
    ['md5 ad_tracking_disabled', 'md5%20ad_tracking_disabled'],
    ['md5 device_id', 'md5%20device_id'],
    ['md5 isu', 'md5%20isu'],
    ['md5 ifa', 'md5%20ifa'],
    ['md5 aaid', 'md5%20aaid'],
    ['md5 trackable_device_id', 'md5%20trackable_device_id'],
    ['md5 device_ios', 'md5%20device_ios'],
    ['md5 device_android', 'md5%20device_android'],
    ['md5 device_amazon', 'md5%20device_amazon'],
    ['md5 device_windows', 'md5%20device_windows'],
    ['md5 adjust_s2s', 'md5%20adjust_s2s'],
    ['md5 user_agent', 'md5%20user_agent'],
    ['md5 browser_user_agent', 'md5%20browser_user_agent'],
    ['md5 device_platform', 'md5%20device_platform'],
    ['md5 connection_type', 'md5%20connection_type'],
    ['md5 os_version', 'md5%20os_version'],
    ['md5 device_model', 'md5%20device_model'],
    ['md5 volume', 'md5%20volume'],
    ['md5 sound_enabled', 'md5%20sound_enabled'],
    ['md5 carrier_name', 'md5%20carrier_name'],
    ['md5 screen_width', 'md5%20screen_width'],
    ['md5 screen_height', 'md5%20screen_height'],
    ['md5 eid', 'md5%20eid'],
    ['md5 id', 'md5%20id'],
    ['md5 app_id', 'md5%20app_id'],
    ['md5 campaign_id', 'md5%20campaign_id'],
    ['md5 video_filename', 'md5%20video_filename'],
    ['md5 campaign_name', 'md5%20campaign_name'],
    ['md5 bid_price', 'md5%20bid_price'],
    ['md5 bid_type', 'md5%20bid_type'],
    ['md5 bid_currency', 'md5%20bid_currency'],
    ['md5 bid_price', 'md5%20bid_price'],
    ['md5 creative', 'md5%20creative'],
    ['md5 custom_creative', 'md5%20custom_creative'],
    ['md5 creative_id', 'md5%20creative_id'],
    ['md5 min_duration', 'md5%20min_duration'],
    ['md5 max_duration', 'md5%20max_duration'],
    ['md5 screen_orientation', 'md5%20screen_orientation'],
    ['md5 market_id', 'md5%20market_id'],
    ['md5 bundle_id', 'md5%20bundle_id'],
    ['md5 timestamp', 'md5%20timestamp'],
    ['md5 replaceable_timestamp', 'md5%20replaceable_timestamp'],
    ['md5 language', 'md5%20language'],
    ['md5 timezone', 'md5%20timezone'],
    ['md5 ip', 'md5%20ip'],
    ['md5 city', 'md5%20city'],
    ['md5 region', 'md5%20region'],
    ['md5 country', 'md5%20country'],
    ['md5 country_name', 'md5%20country_name'],
    ['md5 latitude', 'md5%20latitude'],
    ['md5 longitude', 'md5%20longitude'],
    ['md5 gender', 'md5%20gender'],
    ['md5 age', 'md5%20age'],
    ['md5 vungle_id', 'md5%20vungle_id'],
    ['md5 pub_app_store_url', 'md5%20pub_app_store_url'],
    ['md5 pub_app_store_category', 'md5%20pub_app_store_category'],
    ['md5 pub_app_name', 'md5%20pub_app_name'],
    ['md5 pub_app_id', 'md5%20pub_app_id'],
    ['md5 pub_app_market_id', 'md5%20pub_app_market_id'],
    ['md5 is_coppa_compliant', 'md5%20is_coppa_compliant'],
    ['md5 site_id', 'md5%20site_id'],
    ['md5 placement_id', 'md5%20placement_id'],
    ['md5 ad_type', 'md5%20ad_type'],
    ['md5 site_name', 'md5%20site_name'],
    ['md5 sk_campaign_id', 'md5%20sk_campaign_id'],
    ['sha1 ad_tracking_enabled', 'sha1%20ad_tracking_enabled'],
    ['sha1 ad_tracking_disabled', 'sha1%20ad_tracking_disabled'],
    ['sha1 device_id', 'sha1%20device_id'],
    ['sha1 isu', 'sha1%20isu'],
    ['sha1 ifa', 'sha1%20ifa'],
    ['sha1 aaid', 'sha1%20aaid'],
    ['sha1 trackable_device_id', 'sha1%20trackable_device_id'],
    ['sha1 device_ios', 'sha1%20device_ios'],
    ['sha1 device_android', 'sha1%20device_android'],
    ['sha1 device_amazon', 'sha1%20device_amazon'],
    ['sha1 device_windows', 'sha1%20device_windows'],
    ['sha1 adjust_s2s', 'sha1%20adjust_s2s'],
    ['sha1 user_agent', 'sha1%20user_agent'],
    ['sha1 browser_user_agent', 'sha1%20browser_user_agent'],
    ['sha1 device_platform', 'sha1%20device_platform'],
    ['sha1 connection_type', 'sha1%20connection_type'],
    ['sha1 os_version', 'sha1%20os_version'],
    ['sha1 device_model', 'sha1%20device_model'],
    ['sha1 volume', 'sha1%20volume'],
    ['sha1 sound_enabled', 'sha1%20sound_enabled'],
    ['sha1 carrier_name', 'sha1%20carrier_name'],
    ['sha1 screen_width', 'sha1%20screen_width'],
    ['sha1 screen_height', 'sha1%20screen_height'],
    ['sha1 eid', 'sha1%20eid'],
    ['sha1 id', 'sha1%20id'],
    ['sha1 app_id', 'sha1%20app_id'],
    ['sha1 campaign_id', 'sha1%20campaign_id'],
    ['sha1 video_filename', 'sha1%20video_filename'],
    ['sha1 campaign_name', 'sha1%20campaign_name'],
    ['sha1 bid_price', 'sha1%20bid_price'],
    ['sha1 bid_type', 'sha1%20bid_type'],
    ['sha1 bid_currency', 'sha1%20bid_currency'],
    ['sha1 bid_price', 'sha1%20bid_price'],
    ['sha1 creative', 'sha1%20creative'],
    ['sha1 custom_creative', 'sha1%20custom_creative'],
    ['sha1 creative_id', 'sha1%20creative_id'],
    ['sha1 min_duration', 'sha1%20min_duration'],
    ['sha1 max_duration', 'sha1%20max_duration'],
    ['sha1 screen_orientation', 'sha1%20screen_orientation'],
    ['sha1 market_id', 'sha1%20market_id'],
    ['sha1 bundle_id', 'sha1%20bundle_id'],
    ['sha1 timestamp', 'sha1%20timestamp'],
    ['sha1 replaceable_timestamp', 'sha1%20replaceable_timestamp'],
    ['sha1 language', 'sha1%20language'],
    ['sha1 timezone', 'sha1%20timezone'],
    ['sha1 ip', 'sha1%20ip'],
    ['sha1 city', 'sha1%20city'],
    ['sha1 region', 'sha1%20region'],
    ['sha1 country', 'sha1%20country'],
    ['sha1 country_name', 'sha1%20country_name'],
    ['sha1 latitude', 'sha1%20latitude'],
    ['sha1 longitude', 'sha1%20longitude'],
    ['sha1 gender', 'sha1%20gender'],
    ['sha1 age', 'sha1%20age'],
    ['sha1 vungle_id', 'sha1%20vungle_id'],
    ['sha1 pub_app_store_url', 'sha1%20pub_app_store_url'],
    ['sha1 pub_app_store_category', 'sha1%20pub_app_store_category'],
    ['sha1 pub_app_name', 'sha1%20pub_app_name'],
    ['sha1 pub_app_id', 'sha1%20pub_app_id'],
    ['sha1 pub_app_market_id', 'sha1%20pub_app_market_id'],
    ['sha1 is_coppa_compliant', 'sha1%20is_coppa_compliant'],
    ['sha1 site_id', 'sha1%20site_id'],
    ['sha1 placement_id', 'sha1%20placement_id'],
    ['sha1 ad_type', 'sha1%20ad_type'],
    ['sha1 site_name', 'sha1%20site_name'],
    ['sha1 sk_campaign_id', 'sha1%20sk_campaign_id'],
    ['uppercase ad_tracking_enabled', 'uppercase%20ad_tracking_enabled'],
    ['uppercase ad_tracking_disabled', 'uppercase%20ad_tracking_disabled'],
    ['uppercase device_id', 'uppercase%20device_id'],
    ['uppercase isu', 'uppercase%20isu'],
    ['uppercase ifa', 'uppercase%20ifa'],
    ['uppercase aaid', 'uppercase%20aaid'],
    ['uppercase trackable_device_id', 'uppercase%20trackable_device_id'],
    ['uppercase device_ios', 'uppercase%20device_ios'],
    ['uppercase device_android', 'uppercase%20device_android'],
    ['uppercase device_amazon', 'uppercase%20device_amazon'],
    ['uppercase device_windows', 'uppercase%20device_windows'],
    ['uppercase adjust_s2s', 'uppercase%20adjust_s2s'],
    ['uppercase user_agent', 'uppercase%20user_agent'],
    ['uppercase browser_user_agent', 'uppercase%20browser_user_agent'],
    ['uppercase device_platform', 'uppercase%20device_platform'],
    ['uppercase connection_type', 'uppercase%20connection_type'],
    ['uppercase os_version', 'uppercase%20os_version'],
    ['uppercase device_model', 'uppercase%20device_model'],
    ['uppercase volume', 'uppercase%20volume'],
    ['uppercase sound_enabled', 'uppercase%20sound_enabled'],
    ['uppercase carrier_name', 'uppercase%20carrier_name'],
    ['uppercase screen_width', 'uppercase%20screen_width'],
    ['uppercase screen_height', 'uppercase%20screen_height'],
    ['uppercase eid', 'uppercase%20eid'],
    ['uppercase id', 'uppercase%20id'],
    ['uppercase app_id', 'uppercase%20app_id'],
    ['uppercase campaign_id', 'uppercase%20campaign_id'],
    ['uppercase video_filename', 'uppercase%20video_filename'],
    ['uppercase campaign_name', 'uppercase%20campaign_name'],
    ['uppercase bid_price', 'uppercase%20bid_price'],
    ['uppercase bid_type', 'uppercase%20bid_type'],
    ['uppercase bid_currency', 'uppercase%20bid_currency'],
    ['uppercase bid_price', 'uppercase%20bid_price'],
    ['uppercase creative', 'uppercase%20creative'],
    ['uppercase custom_creative', 'uppercase%20custom_creative'],
    ['uppercase creative_id', 'uppercase%20creative_id'],
    ['uppercase min_duration', 'uppercase%20min_duration'],
    ['uppercase max_duration', 'uppercase%20max_duration'],
    ['uppercase screen_orientation', 'uppercase%20screen_orientation'],
    ['uppercase market_id', 'uppercase%20market_id'],
    ['uppercase bundle_id', 'uppercase%20bundle_id'],
    ['uppercase timestamp', 'uppercase%20timestamp'],
    ['uppercase replaceable_timestamp', 'uppercase%20replaceable_timestamp'],
    ['uppercase language', 'uppercase%20language'],
    ['uppercase timezone', 'uppercase%20timezone'],
    ['uppercase ip', 'uppercase%20ip'],
    ['uppercase city', 'uppercase%20city'],
    ['uppercase region', 'uppercase%20region'],
    ['uppercase country', 'uppercase%20country'],
    ['uppercase country_name', 'uppercase%20country_name'],
    ['uppercase latitude', 'uppercase%20latitude'],
    ['uppercase longitude', 'uppercase%20longitude'],
    ['uppercase gender', 'uppercase%20gender'],
    ['uppercase age', 'uppercase%20age'],
    ['uppercase vungle_id', 'uppercase%20vungle_id'],
    ['uppercase pub_app_store_url', 'uppercase%20pub_app_store_url'],
    ['uppercase pub_app_store_category', 'uppercase%20pub_app_store_category'],
    ['uppercase pub_app_name', 'uppercase%20pub_app_name'],
    ['uppercase pub_app_id', 'uppercase%20pub_app_id'],
    ['uppercase pub_app_market_id', 'uppercase%20pub_app_market_id'],
    ['uppercase is_coppa_compliant', 'uppercase%20is_coppa_compliant'],
    ['uppercase site_id', 'uppercase%20site_id'],
    ['uppercase placement_id', 'uppercase%20placement_id'],
    ['uppercase ad_type', 'uppercase%20ad_type'],
    ['uppercase site_name', 'uppercase%20site_name'],
    ['uppercase sk_campaign_id', 'uppercase%20sk_campaign_id'],
  ],
  helpers: {
    //Macro Helpers
    boolean_number: true,
    boolean_string: true,
    lowercase: true,
    md5: true,
    sha1: true,
    uppercase: true,
  },
  macros: {
    ad_tracking_enabled: true,
    ad_tracking_disabled: true,
    device_id: true,
    isu: true,
    ifa: true,
    aaid: true,
    trackable_device_id: true,
    device_ios: true,
    device_android: true,
    device_amazon: true,
    device_windows: true,
    adjust_s2s: true,
    user_agent: true,
    browser_user_agent: true,
    device_platform: true,
    connection_type: true,
    os_version: true,
    device_model: true,
    volume: true,
    sound_enabled: true,
    carrier_name: true,
    screen_width: true,
    screen_height: true,
    eid: true,
    id: true,
    app_id: true,
    campaign_id: true,
    video_filename: true,
    campaign_name: true,
    bid_price: true,
    bid_type: true,
    bid_currency: true,
    creative: true,
    custom_creative: true,
    creative_id: true,
    min_duration: true,
    max_duration: true,
    screen_orientation: true,
    market_id: true,
    bundle_id: true,
    timestamp: true,
    replaceable_timestamp: true,
    language: true,
    timezone: true,
    ip: true,
    city: true,
    region: true,
    country: true,
    country_name: true,
    latitude: true,
    longitude: true,
    gender: true,
    age: true,
    vungle_id: true,
    pub_app_store_url: true,
    pub_app_store_category: true,
    pub_app_name: true,
    pub_app_id: true,
    pub_app_market_id: true,
    is_coppa_compliant: true,
    site_id: true,
    placement_id: true,
    ad_type: true,
    site_name: true,
    sk_campaign_id: true,
  },
};

const tpatIntegrationUrl = 'https://support.vungle.com/hc/en-us/articles/115002662928-Integrating-Vungle-with-TPAT';

export { trackingProviders, validTrackingMacros, tpatIntegrationUrl };
