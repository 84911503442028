import {
  getTemplate,
  getTemplates,
  getTemplateVersions,
  createTemplate,
  modifyTemplate,
  createTemplateVersion,
} from 'app/graphql/utils/templates';
import config from 'lib/config';
import { convertValuesToString, isEmptyObject } from '../lib/lib';
import { locale } from '../lib/date';
import Model from './BaseModel';
import Template from './Template';
import Application from './Application';

const baseUrl = `${config.get('manageUrl')}templates`;

class AdminTemplate extends Template {
  static getCategoriesOptions() {
    return Template.getCategoriesOptions().concat({
      name: 'Experimental',
      value: 'experiment',
      isAdminOnly: true,
    });
  }

  constructor(attrs) {
    super(attrs);

    if (attrs.filters !== null && !isEmptyObject(attrs.filters)) {
      this.set('filters', attrs.filters);
    }
    if (attrs.dimensions !== null && !isEmptyObject(attrs.dimensions)) {
      this.set('dimensions', attrs.dimensions);
    }
    this.map('created', locale);
    this.map('updated', locale);
    this.map('applications', this.getArrayRelation('applications', Application).bind(this));
    this.default('template_category', {});
    this.default('os_whitelist', {});
  }

  toServerObject() {
    const template = super.toServerObject();

    template.is_custom_creative = this.get('is_custom_creative') || false;
    template.supported_template_protocol = this.get('supported_template_protocol') || 'vungle_mraid';
    template.mraid_url = this.get('mraid_url') || '';
    template.opportunity = this.get('opportunity') || 'video';
    template.applications = this.get('applications').map((app) => ({
      id: app.get('id'),
    }));
    template.format = this.get('format');
    template.status = this.get('status') || 'test';
    template.template_category = this.get('template_category') || {};
    template.os_whitelist = this.get('os_whitelist') || {};
    template.dimensions = {
      minHeight: this.raw('dimensions.minHeight') ? Number(this.raw('dimensions.minHeight')) : 0,
      minWidth: this.raw('dimensions.minWidth') ? Number(this.raw('dimensions.minWidth')) : 0,
    };

    if (this.get('cdn_url')) {
      // set the cdnUrl and replacements if the cdn_url is set
      // (the cdn_url is only set when a zip file is uploaded, and it is stored in the s3)
      template.cdn_url = this.get('cdn_url');
      template.replacements = this.get('replacements').map((replacement) => ({
        ...replacement,
        value: convertValuesToString(replacement.value),
      }));
    } else {
      template.cdn_url = '';
      template.replacements = [];
    }
    return template;
  }
}

AdminTemplate.make = Model.make(AdminTemplate);
AdminTemplate.get = (id) => getTemplate(id);
AdminTemplate.getAll = (filters) => getTemplates(filters);
AdminTemplate.create = (template) => createTemplate(template.toServerObject());
AdminTemplate.save = (template) => modifyTemplate(template.get('id'), template.toServerObject());
AdminTemplate.getVersions = (id) => getTemplateVersions(id);
AdminTemplate.createVersion = (id, cdnURL, description, replacements) => createTemplateVersion({
  originalTemplateID: id, cdnURL, description, replacements,
});

AdminTemplate.CATEGORY_EXPERIMENTAL = 'experiment';

export default AdminTemplate;
export {
  baseUrl,
};
