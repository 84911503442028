import Template from 'models/Template';
import {
  PENDING,
  NEEDS_ATTENTION,
  VISUAL_QA,
  PENDING_FULL_QA,
  FULL_QA,
  ACTIVE,
  creativeQAStatusDisplay,
} from 'app/constants/asset';
import {
  FILTER_KEY_APPLICATION,
  FILTER_KEY_CAMPAIGN,
  FILTER_KEY_CREATIVE,
  FILTER_KEY_COUNTRY,
  FILTER_KEY_LANGUAGE,
  FILTER_KEY_CREATIVE_TYPE,
  FILTER_KEY_AD_TYPE,
  FILTER_KEY_PLATFORM,
  FILTER_KEY_STATUS,
  FILTER_KEY_CREATIVEQA_STATUS,
  FILTER_KEY_PUBLISHER,
  FILTER_KEY_CAMPAIGN_SKADNETWORK,
  FILTER_KEY_ASSET_ORIENTATION,
  FILTER_KEY_ASSET_TYPE,
  FILTER_KEY_CREATIVE_FORMAT,
  FILTER_KEY_CREATIVE_LIST_FORMAT,
  FILTER_KEY_BID_TYPE,
  FILTER_KEY_DELETION_STATUS,
  FILTER_KEY_NETWORK_TYPE,
  FILTER_KEY_TEMPLATE_STATUS,
  FILTER_KEY_TEMPLATE_FORMAT,
  FILTER_KEY_TEMPLATE_PROTOCOL,
  FILTER_KEY_ASSET,
  FILTER_KEY_SKOVERLAY_AUTO_SHOW,
  FILTER_STATUS_ACTIVE,
  FILTER_STATUS_PAUSED,
  FILTER_STATUS_PENDING,
  FILTER_STATUS_REJECTED,
  FILTER_KEY_VIDEO_OVERLAY_AUTO_SHOW,
  FILTER_KEY_CAMPAIGN_BUDGET_OBJECTIVES,
} from 'app/constants/filters';
import {
  BUDGET_OBJECTIVE_DYNAMIC,
  BUDGET_OBJECTIVE_STATIC,
} from 'app/constants/campaign';

const { getCreativeFormats } = require('lib/helpers/creatives/creativeIndex');

export const PARAMS_KEY_APPLICATION_ID = 'appId';
export const PARAMS_KEY_APPLICATION_NAME = 'appName';

export const ISO_8601_FORMAT = 'YYYY-MM-DDT00:00:00.000[Z]';

// Panel loading status will appear when pages is greater than 1.
export const initPagination = { page: 1, perPage: 10, pages: 2 };
// This const variable tells Panel component all nodes has been loaded and no need to load more when scroll to bottom.
export const onePagePagination = {
  ...initPagination,
  pages: 1,
};

// statuses
export const STATUS_MAJOR_TYPE = 'major';
export const STATUS_MINOR_TYPE = 'minor';

export const filterLabelDicts = {
  [FILTER_KEY_APPLICATION]: 'Application',
  [FILTER_KEY_CAMPAIGN]: 'Campaign',
  [FILTER_KEY_CREATIVE]: 'Creative',
  [FILTER_KEY_COUNTRY]: 'Country',
  [FILTER_KEY_LANGUAGE]: 'Language',
  [FILTER_KEY_CREATIVE_TYPE]: 'Creative Type',
  [FILTER_KEY_AD_TYPE]: 'Ad Type Targeting',
  [FILTER_KEY_PLATFORM]: 'Platform',
  [FILTER_KEY_STATUS]: 'Status',
  [FILTER_KEY_CREATIVEQA_STATUS]: 'Status',
  [FILTER_KEY_PUBLISHER]: 'Publisher Application',
  [FILTER_KEY_CAMPAIGN_SKADNETWORK]: 'SkAdNetwork',
  [FILTER_KEY_ASSET_ORIENTATION]: 'Orientation',
  [FILTER_KEY_ASSET_TYPE]: 'Asset Type',
  [FILTER_KEY_CREATIVE_FORMAT]: 'Creative Format',
  [FILTER_KEY_CREATIVE_LIST_FORMAT]: 'Creative Type',
  [FILTER_KEY_BID_TYPE]: 'Campaign Type',
  [FILTER_KEY_DELETION_STATUS]: 'Deletion Status',
  [FILTER_KEY_NETWORK_TYPE]: 'Network Type',
  [FILTER_KEY_TEMPLATE_STATUS]: 'Template Status',
  [FILTER_KEY_TEMPLATE_FORMAT]: 'Template Format',
  [FILTER_KEY_TEMPLATE_PROTOCOL]: 'Template Protocol',
  [FILTER_KEY_ASSET]: 'Assets',
  [FILTER_KEY_SKOVERLAY_AUTO_SHOW]: 'SKOverlay Auto Show',
  [FILTER_KEY_VIDEO_OVERLAY_AUTO_SHOW]: 'Video Overlay Auto Show',
  [FILTER_KEY_CAMPAIGN_BUDGET_OBJECTIVES]: 'Optimization Type',
};

export const statuses = [
  {
    id: 'active',
    type: STATUS_MAJOR_TYPE,
    status: FILTER_STATUS_ACTIVE,
    pulsationStatus: 'active',
    text: 'Active',
  },
  {
    id: 'paused',
    type: STATUS_MAJOR_TYPE,
    status: FILTER_STATUS_PAUSED,
    pulsationStatus: 'inactive',
    text: 'Inactive',
  },
  {
    id: 'pending',
    type: STATUS_MINOR_TYPE,
    status: FILTER_STATUS_PENDING,
    pulsationStatus: 'pending',
    text: 'Pending',
  },
  {
    id: 'rejected',
    type: STATUS_MINOR_TYPE,
    status: FILTER_STATUS_REJECTED,
    pulsationStatus: 'rejected',
    text: 'Rejected',
  },
];

export const campaignStatuses = [
  {
    id: 'active',
    type: STATUS_MAJOR_TYPE,
    status: FILTER_STATUS_ACTIVE,
    pulsationStatus: 'active',
    text: 'Active',
  },
  {
    id: 'paused',
    type: STATUS_MAJOR_TYPE,
    status: FILTER_STATUS_PAUSED,
    pulsationStatus: 'inactive',
    text: 'Inactive',
  },
];

export const assetStatuses = [
  {
    id: PENDING,
    text: creativeQAStatusDisplay[PENDING],
  },
  {
    id: NEEDS_ATTENTION,
    text: creativeQAStatusDisplay[NEEDS_ATTENTION],
  },
  {
    id: VISUAL_QA,
    text: creativeQAStatusDisplay[VISUAL_QA],
  },
  {
    id: PENDING_FULL_QA,
    text: creativeQAStatusDisplay[PENDING_FULL_QA],
  },
  {
    id: FULL_QA,
    text: creativeQAStatusDisplay[FULL_QA],
  },
  {
    id: ACTIVE,
    text: creativeQAStatusDisplay[ACTIVE],
  },
];

export const applicationStatus = statuses
  .filter((i) => i.id !== 'paused')
  .map((status) => (status.id === 'active' ? { ...status, id: 'approved' } : status));

export const memberStatus = [
  {
    id: 'active',
    type: STATUS_MAJOR_TYPE,
    status: 'active',
    pulsationStatus: 'active',
    text: 'Active',
  },
  {
    id: 'pending',
    type: STATUS_MINOR_TYPE,
    status: 'pending',
    pulsationStatus: 'pending',
    text: 'Pending',
  },
];

export const skadnetworkStatuses = [
  {
    id: 'enabled',
    type: STATUS_MAJOR_TYPE,
    status: 'enabled',
    pulsationStatus: 'hidden',
    text: 'Enabled',
  },
  {
    id: 'disabled',
    type: STATUS_MAJOR_TYPE,
    status: 'disabled',
    pulsationStatus: 'hidden',
    text: 'Disabled',
  },
];

export const assetTypesData = [
  {
    id: 'image',
    text: 'Image',
  },
  {
    id: 'video',
    text: 'Video',
  },
  {
    id: 'bundle_adaptive_creative',
    text: 'Playable',
  },
];

// platforms
export const platforms = [
  { id: 'amazon', icon: 'amazon', text: 'Amazon' },
  { id: 'android', icon: 'android', text: 'Android' },
  { id: 'iOS', icon: 'ios', text: 'iOS' },
  { id: 'windows', icon: 'windows', text: 'Windows' },
];

export const creativeFormat = [
  { id: 'vungle_local', text: 'Legacy' },
  { id: 'vungle_mraid', text: 'Vungle MRAID' },
  { id: 'third_party_mraid', text: '3rd Party MRAID' },
  { id: 'vungle_short_form', text: 'Short Form' },
];

export const bidTypes = [
  { id: 'roas', text: 'ROAS' },
  { id: 'cppu', text: 'CPPU' },
  { id: 'cpe', text: 'CPE' },
  { id: 'cpi', text: 'CPI' },
  { id: 'tcpi', text: 'tCPI' },
  { id: 'cpc', text: 'CPC' },
  { id: 'cpcv', text: 'CPCV' },
  { id: 'cpm', text: 'CPM' },
  { id: 'cpv', text: 'CPV' },
];

export const deletionStatus = [
  { id: 'hide', text: 'Hide' },
  { id: 'show', text: 'Show' },
  { id: 'only', text: 'Deleted Only' },
];

export const networkTypes = [
  { id: 'vungle', text: 'Vungle Ads' },
  { id: 'exchange', text: 'Exchange' },
  { id: 'offnetwork', text: 'Off-Network' },
];

export const templateStatus = [
  { id: 'test', text: 'Test' },
  { id: 'active', text: 'Active' },
  { id: 'decommissioned', text: 'Decommissioned' },
];

export const templateFormat = [
  { id: 'single_page_fullscreen', text: 'Single Page Fullscreen' },
  { id: 'multi_page_fullscreen', text: 'Multi Page Fullscreen' },
  { id: 'single_page_flexview', text: 'Single Page Flexview' },
  { id: 'multi_page_flexview', text: 'Multi Page Flexview' },
  { id: 'single_page_flexfeed', text: 'Single Page Flexfeed' },
  { id: 'multi_page_flexfeed', text: 'Multi Page Flexfeed' },
  { id: 'single_page_mrec', text: 'Single Page MREC' },
  { id: 'multi_page_mrec', text: 'Multi Page MREC' },
  { id: 'image_mrec', text: 'Image MREC' },
  { id: 'banner', text: 'Banner' },
];

export const templateProtocol = [
  { id: 'vast', text: 'VAST' },
  { id: 'vungle_mraid', text: 'VUNGLE MRAID' },
  { id: 'mraid_2', text: 'MRAID 2.0' },
];

export const creativeListFormat = getCreativeFormats([
  ...Template.getTemplates(),
]);

export const assetOrientation = [
  { id: 'landscape', text: 'Landscape' },
  { id: 'portrait', text: 'Portrait' },
];

export const SKOverlayAutoShow = [
  { id: true, text: 'Yes' },
  { id: false, text: 'No' },
];

export const campaignObjectives = [
  { id: BUDGET_OBJECTIVE_STATIC, text: 'Manual (CPI, CPCV, CPM, CPV, CPC)' },
  { id: BUDGET_OBJECTIVE_DYNAMIC, text: 'Automated (ROAS, CPPU, CPE)' },
];
