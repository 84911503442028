export const PENDING = 'pending';
export const NEEDS_ATTENTION = 'needsAttention';
export const VISUAL_QA = 'visualQA';
export const PENDING_FULL_QA = 'pendingFullQA';
export const FULL_QA = 'fullQA';
export const ACTIVE = 'approved';

export const TYPE_IMAGE = 'image';
export const TYPE_VIDEO = 'video';
export const TYPE_BUNDLE = 'bundle_adaptive_creative';

export const creativeQAStatusDisplay = {
  [PENDING]: 'Pending Review',
  [NEEDS_ATTENTION]: 'Needs Attention',
  [VISUAL_QA]: 'Under Visual QA',
  [PENDING_FULL_QA]: 'Pending Full QA',
  [FULL_QA]: 'Under Full QA',
  [ACTIVE]: 'active',
};
