import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './button.scss';

/**
 * Button component
 */
const Button = ({
  children,
  onClick,
  size,
  round,
  disabled,
  buttonStyles,
  className,
  type,
  titleStyles,
  ...props
}) => {
  const prefixCls = 'v2_component_button';
  const classes = cn(prefixCls, className, {
    [`${prefixCls}-${type}`]: !disabled,
    [`${prefixCls}-size-${size}`]: size !== 'default',
    [`${prefixCls}-disabled`]: disabled,
    [`${prefixCls}-round`]: round,
  });
  return (
    <button
      className={classes}
      type="button"
      style={buttonStyles}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      <span className={`${prefixCls}_wrapper`} style={titleStyles}>
        {children}
      </span>
    </button>
  );
};

Button.propTypes = {
  /**
   * Function to run when the button is clicked
   */
  onClick: PropTypes.func,
  /**
   * Type of the button
   */
  type: PropTypes.oneOf(['default', 'primary', 'ghost']),

  /**
   * Size of the button
   * default: 40 px tall
   * small: 30 px tall
   */
  size: PropTypes.oneOf(['default', 'small']),

  /**
   * Special styles passed in props
   */
  buttonStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),

  /**
   * Button style round or not
   * default: false
   */
  round: PropTypes.bool,

  /**
   * Button title styles passed in props
   */
  titleStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),

  /**
   * Disable button
   */
  disabled: PropTypes.bool,

  /**
   * Add classname to button
   */
  className: PropTypes.string,
};

Button.defaultProps = {
  onClick: null,
  size: 'default',
  disabled: false,
  buttonStyles: null,
  titleStyles: null,
  className: null,
  type: 'default',
  round: false,
};

export default Button;
