import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isAdvertiserService } from 'lib/serviceType';
import { FILTER_KEY_PUBLISHER } from 'app/constants/filters';
import { ns as accountHierNs } from '../../../../../services/Templates/AccountHier/actions';
import { initPagination, onePagePagination } from '../../constant';
import { getLoadNodes, getLoadMenu, filterNodes } from './helper';
import ApplicationNode from '../Nodes/Application';
import BackendPanel from './BackendPanel';

const ApplicationOrPublisherPanel = ({
  visible,
  filterKey,
  maxSelected,
  searchParams,
  availableNodes,
  defaultCheckedNodes,
  onVisibleChange,
  onChange,
}) => {
  const [nodes, setNodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState(initPagination);

  const [menuNodes, setMenuNodes] = useState([]);
  const [menuLoading, setMenuLoading] = useState(false);
  const [menuPagination, setMenuPagination] = useState(initPagination);

  const [checkedNodes, setCheckedNodes] = useState(defaultCheckedNodes);

  const authUser = useSelector((state) => state.authUser);
  const accountHier = useSelector((state) => state[accountHierNs]) || {};

  const account = useMemo(() => (isAdvertiserService() ? authUser.account : accountHier.account),
    [accountHier, authUser]);

  const onLoadMenu = useCallback(async (params) => {
    if (availableNodes.length) {
      setMenuNodes(filterNodes(availableNodes, params.search));
      setMenuPagination(onePagePagination);
    } else {
      setMenuLoading(true);
      const result = await getLoadMenu({
        prevNodes: params.page === 1 ? [] : menuNodes,
        filterKey,
        params: {
          ...searchParams,
          ...params,
          account,
        },
      });
      setMenuNodes(result.response);
      setMenuPagination(result.pagination);
      setMenuLoading(false);
    }
  }, [menuNodes, filterKey, availableNodes, account, searchParams]);

  const onLoadNodes = useCallback(async (params) => {
    setLoading(true);
    const result = await getLoadNodes({
      filterKey,
      prevNodes: nodes,
      params: {
        ...searchParams,
        ...params,
        account,
      },
    });
    setNodes(result.response);
    setPagination(result?.pagination || onePagePagination);
    setLoading(false);
  }, [nodes, filterKey, account, searchParams]);

  useEffect(() => {
    // Only load available nodes if availableNodes contains something.
    if (availableNodes.length) {
      setNodes(availableNodes);
      setPagination(onePagePagination);
    } else if (visible) {
      onLoadNodes({ page: 1 });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, availableNodes]);

  const onCheckedChange = useCallback((_checkedNodes) => {
    setCheckedNodes(_checkedNodes);
    onChange(_checkedNodes);
  }, [onChange]);

  return (
    <BackendPanel
      visible={visible}
      nodes={nodes}
      loading={loading}
      pagination={pagination}
      menuNodes={menuNodes}
      menuLoading={menuLoading}
      menuPagination={menuPagination}
      checkableNodes={nodes}
      renderLeaf={(node) => <ApplicationNode node={node} />}
      maxSelected={maxSelected}
      disabledTitle="Limit Reached"
      disabledContent={`You have selected (${maxSelected}) ${filterKey === FILTER_KEY_PUBLISHER ? 'publishers' : 'applications'}.`}
      placeholder={`Search ${filterKey} name or id...`}
      defaultCheckedNodes={checkedNodes}
      onVisibleChange={onVisibleChange}
      onChange={onCheckedChange}
      onLoadNodes={onLoadNodes}
      onLoadMenu={onLoadMenu}
    />
  );
};

ApplicationOrPublisherPanel.propTypes = {
  visible: PropTypes.bool,
  maxSelected: PropTypes.number,
  filterKey: PropTypes.string.isRequired,
  availableNodes: PropTypes.arrayOf(PropTypes.any),
  defaultCheckedNodes: PropTypes.arrayOf(PropTypes.any),
  searchParams: PropTypes.objectOf(PropTypes.any),
  onVisibleChange: PropTypes.func,
  onChange: PropTypes.func,
};

ApplicationOrPublisherPanel.defaultProps = {
  visible: false,
  maxSelected: 0,
  searchParams: {},
  availableNodes: [],
  defaultCheckedNodes: [],
  onVisibleChange: noop,
  onChange: noop,
};

export default React.memo(ApplicationOrPublisherPanel);
