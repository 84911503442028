import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import classnames from 'classnames';
import Spinner from 'components/Spinner/Spinner';
import Button from 'components/V2/Button/Button';
import TextArea from 'components/V2/TextArea/TextArea';
import ErrorsCollapse from 'components/V2/Collapse/ErrorsCollapse/ErrorsCollapse';
import './editArea.scss';

export const prefixCls = 'v2_component_edit_area';

function EditArea({
  className,
  value,
  error,
  errorCollapse,
  loading,
  loadingText,
  disabled,
  placeholder,
  onSave,
  onChange,
}) {
  const renderError = useCallback(() => {
    if (!disabled || !error) return null;
    if (errorCollapse) {
      return <ErrorsCollapse className={`${prefixCls}-collapse`} errors={error} />;
    }
    return <div className={`${prefixCls}-error`}>{error}</div>;
  }, [disabled, error, errorCollapse]);

  return (
    <div className={classnames(prefixCls, className)}>
      <div className={`${prefixCls}-area`}>
        <TextArea
          placeholder={placeholder}
          onChange={onChange}
          error={!!error}
          value={value}
        />
        {loading && (
          <div className={`${prefixCls}-loading`}>
            <Spinner />
            <span className={`${prefixCls}-loading-tip`}>{loadingText}</span>
          </div>
        )}
      </div>
      {renderError()}
      <Button
        type="primary"
        className={`${prefixCls}-btn`}
        titleStyles={{ fontSize: 12 }}
        disabled={disabled || loading}
        onClick={onSave}
      >
        Add
      </Button>
    </div>
  );
}

EditArea.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  errorCollapse: PropTypes.bool,
  loadingText: PropTypes.string,
  placeholder: PropTypes.string,
};

EditArea.defaultProps = {
  onSave: noop,
  loading: false,
  className: '',
  error: '',
  errorCollapse: false,
  loadingText: '',
  placeholder: '',
};

export default EditArea;
