/* eslint react/prop-types: 0 */
import React, { forwardRef } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import './input.scss';

const prefixCls = 'v2_component_input';
/**
 * Input component
 */
class Input extends React.Component {
  constructor(props) {
    super(props);
    this.input = null;
    const value = typeof props.value === 'undefined' ? props.defaultValue : props.value;
    this.state = {
      value,
      focused: false,
    };
  }

  saveInput = (inputRef) => {
    this.input = inputRef;
  }

  setValue(value, callback) {
    if (!('value' in this.props)) {
      this.setState({ value }, callback);
    }
  }

  value() {
    if (!('value' in this.props)) {
      return this.state.value;
    }
    return this.props.value;
  }

  handleChange = (e) => {
    this.setValue(e.target.value);
    this.resolveOnChange(e, this.props.onChange);
  }

  handleReset = (e) => {
    this.setValue('', () => {
      this.focus();
    });
    this.resolveOnChange(e, this.props.onChange);
  };

  resolveOnChange(e, onChange) {
    if (onChange) {
      let event = e;
      if (e.type === 'click') {
        // click clear icon
        event = Object.create(e);
        event.target = this.input;
        event.currentTarget = this.input;
        // change target ref value cause e.target.value should be '' when clear input
        this.input.value = '';
        onChange(event);
        return;
      }
      onChange(event);
    }
  }

  onFocus = (e) => {
    const { onFocus } = this.props;
    this.setState({ focused: true });
    if (onFocus) {
      onFocus(e);
    }
  }

  onBlur = (e) => {
    const { onBlur } = this.props;
    this.setState({ focused: false });
    if (onBlur) {
      onBlur(e);
    }
  }

  focus = () => {
    this.input.focus();
  }

  renderClearIcon = (allowClear) => {
    const { disabled } = this.props;
    const value = this.value();
    if (
      !allowClear
      || disabled
      || value === undefined
      || value === null
      || value === ''
    ) {
      return null;
    }
    const className = `material-icons ${prefixCls}-clear-icon`;
    return <i className={className} onClick={this.handleReset}>clear</i>;
  }

  render() {
    const {
      className, addonAfter, style, search, disabled, allowClear, ...restProps
    } = this.props;
    const { focused } = this.state;
    const classes = cn(prefixCls, className, {
      [`${prefixCls}-${search ? 'search-' : ''}focus`]: focused,
      [`${prefixCls}-disabled`]: disabled,
    });
    return (
      <span
        style={style}
        className={classes}
      >
        <input
          {...restProps}
          disabled={disabled}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onChange={this.handleChange}
          ref={this.saveInput}
        />
        <span className={`${prefixCls}-suffix`}>
          {this.renderClearIcon(allowClear)}
          {addonAfter}
        </span>
      </span>
    );
  }
}

Input.propTypes = {
  /**
   * Add classname to Input
   */
  className: PropTypes.string,
  /**
   * The Input value changed event
   */
  onChange: PropTypes.func,
  /**
   * The value of the Input
   */
  // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
  value: PropTypes.string,
  /**
   * The default value of the Input
   */
  // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
  defaultValue: PropTypes.string,
  /**
   * The Input on focus event
   */
  onFocus: PropTypes.func,
  /**
   * The Input onblur event
   */
  onBlur: PropTypes.func,
  /**
   * Is it a search component
   */
  search: PropTypes.bool,
  /**
   * Disable the input
   */
  disabled: PropTypes.bool,
  /**
   * after node for inpput
   */
  addonAfter: PropTypes.node,
  /**
   * allow show clear icon
   */
  allowClear: PropTypes.bool,
};
const noop = () => { };
Input.defaultProps = {
  search: false,
  disabled: false,
  addonAfter: null,
  onChange: noop,
  onFocus: noop,
  onBlur: noop,
  allowClear: false,
  className: null,
  // autoFocus: false,
};
const Search = (p, ref) => {
  const { className, ...restProps } = p;
  return (
    <Input
      search
      allowClear
      ref={ref}
      className={cn(`${prefixCls}-search`, className)}
      addonAfter={<i className={`material-icons ${prefixCls}-search-icon`}>search</i>}
      {...restProps}
    />
  );
};
Input.Search = forwardRef(Search);

export default Input;
