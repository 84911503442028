import config from '../config';

export const getSupportedPlatforms = () => Object
  .keys(
    config.get('deviceListCsvUrl'),
  );

export const supportsDeviceTargetingCsv = (campaign) => getSupportedPlatforms()
  .includes(
    campaign?.get('application')?.get('platform'),
  );
