import React from 'react';
import Button from '../../../../components/Button/Button';
import './empty.scss';
import { canModifyCampaigns, canModifyCreatives } from '../../../../lib/helpers/authUser';

const defaultDescription = 'Your account looks a little empty. Let’s add our first campaign.';

const Empty = ({ dimension = 'campaign', onAdd, description = defaultDescription }) => (
  <div className="v2_pages_reports_empty">
    <span className="description">{description}</span>
    <div className="link_button">
      {dimension === 'campaign' ? (canModifyCampaigns() && <Button onClick={onAdd}>Add Campaign</Button>) : (
        canModifyCreatives() && (<Button onClick={onAdd}>Add Creative</Button>)
      )}
    </div>
  </div>
);

export default Empty;
