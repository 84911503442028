import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CollapseSummary from './CollapseSummary';
import CollapseDetail from './CollapseDetail';
import './collapseSection.scss';
import { AdminOnlyLabel } from '../Label/Label';

const classPrefix = 'v2_component_section';
const CollapseSection = ({
  title,
  description,
  expanded: expandedProp = true,
  children,
  className,
  adminOnly,
  tip,
  hideTipWhenCollapsed,
  hideCollapseSummary,
  id,
}) => {
  const [expanded, setExpanded] = React.useState(expandedProp);
  return (
    <div {...(id && { id })} className={classnames(`${classPrefix}`, className)}>
      {
        !hideCollapseSummary && (
          <CollapseSummary
            expanded={expanded}
            className={classnames('summary', `${classPrefix}-header`, { [`${classPrefix}-header-expand`]: !expanded })}
            onClick={(open) => setExpanded(open)}
            hiddenSeparator
          >
            <div className={`${classPrefix}-header-info`}>
              <div className={`${classPrefix}-header-wrapper`}>
                <span className="title">{title}</span>
                <div className={`${classPrefix}-header-tip`} onClick={(e) => e.stopPropagation()}>
                  {hideTipWhenCollapsed ? (expanded && tip) : tip}
                  {adminOnly && <AdminOnlyLabel />}
                </div>
              </div>
            </div>
            {expanded && (
              <div className={`${classPrefix}-header-description`}>{description}</div>
            )}
          </CollapseSummary>
        )
      }
      <CollapseDetail
        className={classnames('detail', `${classPrefix}-detail`)}
        expanded={expanded}
      >
        {expanded && children}
      </CollapseDetail>
    </div>
  );
};

CollapseSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  expanded: PropTypes.bool,
  className: PropTypes.string,
  adminOnly: PropTypes.bool,
  // Summary tip in right
  tip: PropTypes.node,
  // If hide tip when collapsed
  hideTipWhenCollapsed: PropTypes.bool,
  // Id of section
  id: PropTypes.string,
};

CollapseSection.defaultProps = {
  description: '',
  expanded: true,
  className: '',
  adminOnly: false,
  tip: null,
  hideTipWhenCollapsed: true,
  id: '',
};

export default CollapseSection;
