import React, { useEffect } from 'react';
import Modal from 'react-modal';
import classNames from 'classnames';
import IconComponent from '../Icon/Icon';
import './side-modal.scss';

const toggleBodyOverflow = (action) => {
  if (action === 'add') {
    document.body.classList.add('overflow-hidden');
  } else {
    document.body.classList.remove('overflow-hidden');
  }
};

const SideModal = ({
  overlayClassName, className, children, title = 'Recent Activity', subtitle, arrows, onPrevious, onNext, ...rest
}) => {
  useEffect(() => {
    toggleBodyOverflow(rest.isOpen ? 'add' : 'remove');
    return () => {
      toggleBodyOverflow('remove');
    };
  }, [rest.isOpen]);

  return (
    <Modal
      overlayClassName={overlayClassName}
      className={classNames('side-modal-body', className)}
      {...rest}
    >
      {(title || subtitle)
        && (
          <div className="p1 modal-title">
            {title}
            {subtitle && <span className="p2 subtitle">{subtitle}</span>}
            <span className="separator" />
          </div>
        )}
      {arrows && (
        <div className="modal-arrows-next-prev">
          <div className="prev-item" onClick={onPrevious}>
            <IconComponent.ArrowBack />
          </div>
          <div className="next-item" onClick={onNext}>
            <IconComponent.ArrowNext />
          </div>
        </div>
      )}
      <div className="modal-body">
        {children}
      </div>
    </Modal>
  );
};

const ActivityList = ({ className, children, ...rest }) => (
  <div className={classNames('modal-content', className)} {...rest}>
    <ul className="content-scroller">
      {children}
    </ul>
  </div>
);

const ActivityItem = ({ className, children, ...rest }) => (
  <li className={classNames('content-item', className)} {...rest}>
    {children}
  </li>
);

const Field = ({
  name = 'No name', description = 'No description', children, ...rest
}) => (
  <div className="input-field" {...rest}>
    <div className="input-label">
      <p className="p1">{name}</p>
      <p className="p2">{description}</p>
    </div>
    <div className="input-area">
      {children}
    </div>
  </div>
);

SideModal.ActivityList = ActivityList;
SideModal.ActivityItem = ActivityItem;
SideModal.Field = Field;

export default SideModal;
