import React from 'react';
import classNames from 'classnames';
import '../dropdown.scss';
import PropTypes from 'prop-types';

const OverlayWrapper = ({
  prefixCls, overlay, makeRef, onClick, style, className,
}) => {
  const classes = classNames(`${prefixCls}-overlay`, className);
  return (
    <div
      className={classes}
      ref={makeRef}
      style={style}
      onClick={onClick}
    >
      {overlay}
    </div>
  );
};

OverlayWrapper.propTypes = {
  /**
   * prefix class name of the overlay
   */
  prefixCls: PropTypes.string.isRequired,
  /**
   * the overlay of dropdown
   */
  overlay: PropTypes.node,
  /**
   * ref of the overlay, can use this ref to get position
   */
  makeRef: PropTypes.func,
  /**
   * The click event for overlay
   */
  onClick: PropTypes.func,
  /**
   * style of overlay wrapper
   */
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  /**
   * add class name on the overlay
   */
  className: PropTypes.string,
};
OverlayWrapper.defaultProps = {
  onClick: () => { },
  style: null,
  overlay: null,
  makeRef: null,
  className: null,
};

export default OverlayWrapper;
