import React from 'react';
import classNames from 'classnames';
import './spinner.scss';

const sizes = {
  sm: [20, 20, 10, 8],
  md: [33, 34, 17, 15],
  lg: [65, 66, 33, 30],
};
const Spinner = ({ size = 'md', className }) => (
  <svg
    className={classNames('components__spinner', className)}
    width={`${sizes[size][0]}px`}
    height={`${sizes[size][0]}px`}
    viewBox={`0 0 ${sizes[size][1]} ${sizes[size][1]}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      className="path"
      fill="none"
      strokeWidth="1"
      strokeLinecap="round"
      cx={`${sizes[size][2]}`}
      cy={`${sizes[size][2]}`}
      r={`${sizes[size][3]}`}
    />
  </svg>
);

export default Spinner;
