import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import Fuse from 'fuse.js';
import AsyncSelect from 'react-select/async';
import Option from './Option';
import Application from '../../models/Application';
import './aplicationFinder.scss';
import { isAdvertiserService } from '../../lib/serviceType';

function convertAppToSelectOption(application) {
  return {
    value: application,
    label: application.get('name'),
    platform: application.get('platform'),
    id: application.get('id'),
    thumbnail: application.get('store.thumbnail'),
    parent: application.get('account.id'),
  };
}

class ApplicationFinder extends PureComponent {
  constructor(p) {
    super(p);
    this.request = null;
  }

  asyncOptions = async (val) => {
    const {
      platform, type, supportedTemplateType, accountHier,
      excludedApplications = [],
      excludedAccounts = [],
    } = this.props;
    let fn = null;
    if (type === 'advertiser') {
      fn = Application.getAll;
    } else {
      fn = Application.getPubs;
    }
    const filterOptions = {
      keys: ['label', 'id'],
    };

    try {
      const params = {
        search: val,
        perPage: 50,
        platform,
        supportedTemplateType,
        account: accountHier?.account,
      };

      if (isAdvertiserService()) {
        params.account = this.props.authUser.account;
      }

      this.request = fn(params);
      const result = await this.request;
      const applicationArr = Application.make(result.response);
      const data = applicationArr.map((i) => (convertAppToSelectOption(i)));
      const excludedApplicationIDs = new Set(excludedApplications);
      const excludedAccountIDs = new Set(excludedAccounts);
      const filteredApps = data.filter((app) => !excludedAccountIDs.has(app.parent) && !excludedApplicationIDs.has(app.id));
      const fuse = new Fuse(filteredApps, filterOptions);
      return val ? fuse.search(val) : filteredApps;
    } catch (x) {
      return [];
    }
  };

  getOptions = (val) => new Promise((resolve) => {
    if (this.debounced) {
      this.debounced.cancel();
    }

    this.debounced = debounce(async () => {
      const result = await this.asyncOptions(val);
      resolve(result);
    }, 1e3);

    this.debounced();
  });

  render() {
    const {
      application, fetchOnMount = false, onSelect, placeholder, disabled = false,
    } = this.props;

    return (
      <AsyncSelect
        isDisabled={disabled}
        className="ApplicationFinder"
        classNamePrefix="ApplicationFinder"
        defaultOptions={fetchOnMount}
        value={application ? convertAppToSelectOption(application) : null}
        placeholder={placeholder || 'Search for an Application'}
        loadOptions={this.getOptions}
        onChange={(i) => onSelect(i.value)}
        components={{
          Option,
          IndicatorSeparator: null,
        }}
      />
    );
  }
}

ApplicationFinder.propTypes = {
  account: PropTypes.objectOf(PropTypes.any),
  placeholder: PropTypes.string,
  platform: PropTypes.string,
  type: PropTypes.string,
  onSelect: PropTypes.func,
};

ApplicationFinder.defaultProps = {
  account: undefined,
  placeholder: undefined,
  platform: '',
  type: 'advertiser',
  onSelect: Function.prototype,
};

export default ApplicationFinder;
