import React from 'react';
import './widget.scss';
import classNames from 'classnames';

class Widget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
    };
    window.zE('webWidget', 'hide');
    window.zESettings = {
      webWidget: {
        offset: { vertical: '60px' },
      },
    };
  }

  openWidget = () => {
    this.setState({ show: false });
    window.zE('webWidget', 'show');
    window.zE('webWidget', 'open');
  }

  componentDidMount() {
    window.zE('webWidget:on', 'close', () => {
      this.setState({ show: true });
      window.zE('webWidget', 'hide');
    });
  }

  render() {
    return (
      <div className={classNames('components__ZendeskWidget', { hide: !this.state.show })} onClick={this.openWidget}>
        <i className="material-icons">help_outline</i>
      </div>
    );
  }
}

export default Widget;
