import React from 'react';
import CollapseSection from '../../../../components/V2/Collapse/CollapseSection';
import '../../../../styles/_components.scss';

const Description = () => (
  <div>
    <span>Build all your creatives below, learn more about best practices&nbsp;</span>
    <a
      className="description-link"
      href="https://support.vungle.com/hc/en-us/articles/360035348091"
      onClick={(e) => e.stopPropagation()}
      target="_blank"
      rel="noreferrer"
    >
      here.
    </a>
  </div>
);

const CreativePanel = ({ children }) => (
  <div className="creative-panel">
    <CollapseSection
      title="Creative Manager"
      description={<Description />}
    >
      {children}
    </CollapseSection>
  </div>
);

export default CreativePanel;
