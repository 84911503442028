import React from 'react';
import { size } from 'lodash';
import config from 'lib/config';
import Button from 'components/V2/Button/Button';
import Alert from 'components/Alert/Alert';
import AttachedCreativesBase from 'services/Templates/Campaigns/AttachedCreatives/AttachedCreativesBase';
import './attachedCreativesSlider.scss';
import { getCreativeAttachFilters } from 'lib/capabilities/campaigns';

const prefix = 'attached-creatives-slider';

const AttachedCreativesSlider = ({
  campaign,
  creatives,
  disableRowSelector,
  selectedIds,
  selectCreative,
  creativeType,
  modalType = '',
  renderRadio,
  onConfirm,
  onCancel,
  hideOnNoData,
  setSelectedIds,
  shouldDisplayNoCreativeMessage,
}) => {
  const platform = campaign.get('application')?.get('platform');
  const filterTypes = getCreativeAttachFilters(campaign, creativeType, platform);
  const creativeMaximum = campaign.get('account.creativeMaximum') || config.get('accounts.defaultCreativeMaximum');
  const creativeLimitReached = size(selectedIds) > creativeMaximum;

  return (
    <>
      { creativeLimitReached && (
      <Alert.Error>
        Campaign Creative limit of
        {' '}
        {creativeMaximum}
        {' '}
        has been reached.
      </Alert.Error>
      )}
      <div className={prefix}>
        <div>
          <div className={`${prefix}-title`}>
            <p className="p1">Creative Attachment</p>
            <span className="p2">
              {modalType ? `Select a ${modalType} creative.` : 'Select at least one creative.'}
            </span>
          </div>
          <AttachedCreativesBase
            canEdit
            campaign={campaign}
            creatives={creatives}
            disableRowSelector={disableRowSelector}
            selectedIds={selectedIds}
            selectCreative={selectCreative}
            filterTypes={filterTypes}
            renderRadio={renderRadio}
            hideOnNoData={hideOnNoData}
            onClearAll={() => {
              const creativesIds = creatives.map((c) => c.get('id'));
              const creativesToUpdate = selectedIds.filter((s) => !creativesIds.includes(s));
              setSelectedIds(creativesToUpdate);
            }}
            shouldDisplayNoCreativeMessage={shouldDisplayNoCreativeMessage}
          />
        </div>
        <div className="button-wrapper">
          <Button onClick={onCancel}>Cancel</Button>
          <Button disabled={creativeLimitReached} onClick={() => onConfirm(selectedIds)} type="primary">OK</Button>
        </div>
      </div>
    </>

  );
};

export default AttachedCreativesSlider;
