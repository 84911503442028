import React from 'react';
import CurrencyValidator from 'components/BudgetField/CurrencyValidator';
import { getBudgetLimits } from 'lib/helpers/campaigns/campaignIndex';
import Alert from 'components/Alert/Alert';
import Group from 'templates/input-group/InputGroupContainer';

const { Field } = Group;

const BudgetOverrideField = ({
  campaign,
  description,
  disabled,
  name,
  placeholder,
  type,
  bidBudgetValuesModified,
}) => {
  const value = campaign.raw(`budget.${type}`) || '';

  return (
    <Field
      name={name}
      description={description}
    >
      <CurrencyValidator
        shouldValidateThreshold
        campaign={campaign}
        type={type}
        budgetLimits={getBudgetLimits}
        disabled={campaign.hasBudgetOverride(type) || disabled}
        disabledText={value}
        placeholder={placeholder}
        value={value}
        onChange={(v) => {
          if (bidBudgetValuesModified) {
            bidBudgetValuesModified();
          }
          campaign.set(`budget.${type}`, v);
        }}
        numeric
      />
      {campaign.hasBudgetOverride(type) && (
        <div className="warning">
          <Alert.Warn>
            {`Your ${name} has been overridden by an administrator.`}
          </Alert.Warn>
          <p className="p2">
            {`Actual ${type}: ${campaign.get(`budget.${type}_override.value`)}`}
            {`${campaign.get(`budget.${type}_override.value`)} `}
            <span
              className="body__link"
              onClick={() => campaign.disableBudgetOverride(type)}
            >
              Disable Admin Override
            </span>
          </p>
        </div>
      )}
    </Field>
  );
};

export default BudgetOverrideField;
