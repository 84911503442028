import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Tooltip from '../Tooltip/Tooltip';
import Checkbox from '../Checkbox/Checkbox';
import TreeSpinner from './TreeSpinner';

import TreeContext, { TreeContextTypes } from './context';

class InnerTreeNode extends React.Component {
  shouldHandleCheckAfterRender = false;

  onCheck = async () => {
    const {
      context, checked, isLeaf, children, onLoadChildren, nodeData,
    } = this.props;
    const { onNodeCheck } = context;

    if (!isLeaf && React.Children.count(children) === 0) {
      await onLoadChildren(nodeData);
      this.shouldHandleCheckAfterRender = true;
    } else {
      onNodeCheck(this.props, !checked);
    }
  };

  componentDidUpdate() {
    if (!this.shouldHandleCheckAfterRender) {
      return;
    }

    const { context, checked, children } = this.props;

    const { onNodeCheck } = context;
    if (React.Children.count(children) > 0) {
      this.shouldHandleCheckAfterRender = false;
      onNodeCheck(this.props, !checked);
    }
  }

  onExpand = () => {
    const { context } = this.props;
    const { onNodeExpand } = context;

    onNodeExpand(this.props);
  };

  // Switcher
  renderSwitcher = () => {
    const { context, expanded, isLeaf } = this.props;
    const { prefixCls } = context;

    if (isLeaf) {
      return null;
    }

    return (
      <span className={`${prefixCls}-node-switcher`} onClick={this.onExpand}>
        <i className="material-icons">{expanded ? 'expand_less' : 'expand_more'}</i>
      </span>
    );
  };

  // Checkbox
  renderCheckbox = () => {
    const {
      context, checked, halfChecked, disabled, disabledTitle, disabledContent,
    } = this.props;
    const { prefixCls } = context;

    return (
      <span className={`${prefixCls}-node-checkbox`}>
        {disabled && (disabledTitle || disabledContent) ? (
          <Tooltip placement="bottomLeft" title={disabledTitle} body={disabledContent}>
            <Checkbox
              disabled
              indeterminate={halfChecked}
              checked={checked}
              onChange={this.onCheck}
              label={this.renderTitle()}
            />
          </Tooltip>
        ) : (
          <Checkbox
            disabled={disabled}
            indeterminate={halfChecked}
            checked={checked}
            onChange={this.onCheck}
            label={this.renderTitle()}
          />
        )}
      </span>
    );
  };

  // Title
  renderTitle = () => {
    const { context, title, data } = this.props;
    const { prefixCls: treePrefixCls } = context;

    const prefixCls = `${treePrefixCls}-node-title`;

    return (
      <span className={prefixCls}>
        {typeof title === 'function' ? title(data) : title}
      </span>
    );
  };

  // Indent
  renderIndent = () => {
    const { context, deep } = this.props;
    const { prefixCls } = context;
    return [...Array(deep)].map((_, key) => (
      <span key={key} className={`${prefixCls}-node-indent`} />
    ));
  };

  render() {
    const {
      context, className, style, isLeaf, expanded, checked,
      halfChecked, children, dataKey, loading,
    } = this.props;
    const { prefixCls: treePrefixCls, renderTreeNode } = context;

    const prefixCls = `${treePrefixCls}-node`;

    return (
      <div
        key={dataKey}
        className={classnames(className, prefixCls, {
          [`${prefixCls}-leaf`]: isLeaf,
          [`${prefixCls}-${expanded ? 'open' : 'close'}`]: !isLeaf,
          [`${prefixCls}-checkbox-checked`]: checked,
          [`${prefixCls}-checkbox-half`]: halfChecked,
        })}
        style={style}
      >
        <div className={`${prefixCls}-head`}>
          {this.renderIndent()}
          <div className={`${prefixCls}-head-content`}>
            {this.renderCheckbox()}
            {this.renderSwitcher()}
          </div>
        </div>
        {loading && <TreeSpinner />}
        {React.Children.map(children, renderTreeNode)}
      </div>
    );
  }
}

InnerTreeNode.context = TreeContextTypes;

InnerTreeNode.propTypes = {
  /**
   * className of node
   */
  className: PropTypes.string,

  /**
   * style of node
   */
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),

  // By parent
  /**
   * expanded or not
   */
  expanded: PropTypes.bool,

  /**
   * checked or not
   */
  checked: PropTypes.bool,

  /**
   * half checked or not
   */
  halfChecked: PropTypes.bool,

  /**
   * is leaf node
   */
  isLeaf: PropTypes.bool,

  /**
   * node deep
   */
  deep: PropTypes.number,

  // By user
  /**
   * unique key of the node
   */
  dataKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,

  /**
   * node title(string or render function or node)
   */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node]).isRequired,

  /**
   * is loading or not
   */
  loading: PropTypes.bool,

  /**
   * is checkbox disabled
   */
  disabled: PropTypes.bool,

  /**
   * title of disabled tooltip
   */
  disabledTitle: PropTypes.string,

  /**
   * content of disabled tooltip
   */
  disabledContent: PropTypes.string,
};

InnerTreeNode.defaultProps = {
  className: '',
  style: null,
  expanded: false,
  checked: false,
  halfChecked: false,
  deep: 0,
  isLeaf: false,
  loading: false,
  disabled: false,
  disabledTitle: '',
  disabledContent: '',
};

const TreeNode = (props) => (
  <TreeContext.Consumer>
    {(context) => <InnerTreeNode {...props} context={context} />}
  </TreeContext.Consumer>
);

export default TreeNode;
