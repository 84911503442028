import React, { useState } from 'react';
import classnames from 'classnames';
import { DropdownMenu } from '../../../../../../../components/V2';
import MetricMenu from '../../../../../../../components/V2/Report/MetricMenu/MetricMenu';

export default (Component) => {
  const ContextMenu = ({
    original,
    onPreviewCreative,
    generateContextMenu,
    changeCampaignStatus,
    changeCreativeStatus,
    onContextMenuChange,
  }) => {
    const [visible, setVisible] = useState(false);
    if (original.total) {
      return null;
    }
    return (
      <DropdownMenu
        labelKey="name"
        onVisibleChange={setVisible}
        onChange={onContextMenuChange}
        data={generateContextMenu({
          original, onPreviewCreative, changeCampaignStatus, changeCreativeStatus,
        })}
      >
        <i className={classnames({ 'material-icons': true, visible })}>more_vert</i>
      </DropdownMenu>
    );
  };

  class ActionTable extends React.Component {
    renderHeader = () => {
      const {
        authUser, metricGroup, onMetricsChange, metricType,
      } = this.props;
      return (
        <MetricMenu
          defaultKey="tableDefault"
          propsRenderCount={(amount) => `Sort (${amount}) selected columns`}
          metricType={metricType}
          metricGroup={metricGroup}
          metricUserId={authUser.id}
          onMetricsChange={onMetricsChange}
        />
      );
    }

    renderCell = ({ original }) => {
      const {
        dimension,
        onPreviewCreative,
        generateContextMenu,
        changeCampaignStatus,
        changeCreativeStatus,
        onContextMenuChange,
      } = this.props;
      return (
        <ContextMenu
          original={original}
          dimension={dimension}
          onPreviewCreative={onPreviewCreative}
          generateContextMenu={generateContextMenu}
          changeCampaignStatus={changeCampaignStatus}
          changeCreativeStatus={changeCreativeStatus}
          onContextMenuChange={(item) => onContextMenuChange(item, original)}
        />
      );
    };

    render() {
      const { columns, ...others } = this.props;
      const isSingleColumn = columns.length === 1;
      const action = {
        Header: this.renderHeader,
        Cell: this.renderCell,
        style: { justifyContent: isSingleColumn ? 'right' : 'center' },
        headerStyle: { justifyContent: isSingleColumn ? 'right' : 'center' },
        sortable: false,
        resizable: false,
        fixed: 'right',
        width: isSingleColumn ? 'auto' : 36,
      };
      return (
        <Component {...others} columns={[...columns, action]} />
      );
    }
  }

  // Disabled render action cell tooltip.
  ContextMenu.noWrapInTooltip = true;

  return ActionTable;
};
