import { useState, useEffect } from 'react';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';

export default function useRxjsDebounce(fn, delay = 0) {
  const [subject, setSubject] = useState(null);

  useEffect(() => {
    const subject$ = new Subject();
    setSubject(subject$);
    const debounce = (source$) => source$.pipe(
      debounceTime(delay),
      distinctUntilChanged(),
    );
    const sub = debounce(subject$).subscribe(fn);

    return () => {
      sub.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return subject;
}
