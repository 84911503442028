import React from 'react';
import { cloneDeep } from 'lodash';
import { sortByName } from 'templates/MultiTreeSelector/treeUtils';
import { config } from '../../../../app/app';
import MultiTreeSelector from '../../../../templates/MultiTreeSelector/MultiTreeSelector';

const { countries, cities } = config.get('countryData');

const getCityTreeData = (resource) => cloneDeep(countries)
  .filter((country) => cities.some((city) => city.country === country.code))
  .map((country) => {
    const relatedCities = cities.filter((city) => city.country === country.code)
      .map((city) => {
        const cityValue = resource.makeCityValue(city);
        return {
          ...city, id: cityValue, name: cityValue, parentId: country.code,
        };
      });
    return Object.assign(country, { id: country.code, children: relatedCities });
  });

const CitySelector = ({
  resource,
  selectedItems,
  onSelectedChange,
  onPaste,
}) => (
  <MultiTreeSelector
    data={getCityTreeData(resource)}
    selectedItems={selectedItems}
    generateFilterAttrs={(city) => {
      const rawCity = cities.find((c) => resource.makeCityValue(c) === city.id);
      return {
        key: resource.makeCityValue(rawCity),
        checked: resource.hasCity(rawCity),
        label: city.name,
        onChange: (include) => {
          if (rawCity) {
            resource.toggleCity(include, rawCity);
          }
        },
      };
    }}
    onChange={(nodes) => {
      onSelectedChange(cities.filter((city) => nodes.some((node) => node.dataKey === resource.makeCityValue(city))).sort(sortByName));
    }}
    onPaste={onPaste}
    onCheckAll={(include, searchValue) => {
      resource.toggleAllCities(include, searchValue);
    }}
    checkedKeyMapper={(city) => resource.makeCityValue(city)}
  />
);

export default CitySelector;
