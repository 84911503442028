import React from 'react';
import classNames from 'classnames';

const ContextMenuClickOption = ({
  icon, text, onClick, disabled,
}) => (
  <div className={classNames('components__context_menu_option', { 'components__context_menu_option--disabled': disabled })} onClick={onClick}>
    {icon && (
      <i className="material-icons">{icon}</i>
    )}
    <span>{text}</span>
  </div>
);

export default ContextMenuClickOption;
