import React from 'react';

const Tooltip = ({
  newStyle = false, showTitle = true, title, makeRef, body, padding = 32, onMouseLeave, onMouseEnter,
}) => {
  if (newStyle) {
    return (
      <div className="component__tooltip--new_style" ref={makeRef} {...{ onMouseEnter, onMouseLeave }}>
        <div className="outer">
          <div className="inner">
            {showTitle && (
              <div className="header">
                <p className="p7">{title}</p>
              </div>
            )}
            <div className="body">
              {body}
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="component__tooltip" {...{ onMouseEnter, onMouseLeave }}>
      <div className="header">
        <div className="spacer" />
        <div className="text_container">
          <p className="p2">{title}</p>
        </div>
      </div>
      <div className="body" style={{ padding }}>
        {React.isValidElement(body) ? body : (
          <p className="p6">{body}</p>
        )}
      </div>
    </div>
  );
};

class TooltipContainer extends React.Component {
  componentDidMount() {
    if (!this.props.newStyle) {
      this.props.componentDidMount();
    }
  }

  render() {
    return (
      <Tooltip {...this.props} />
    );
  }
}

export default TooltipContainer;
