import React, { cloneElement, isValidElement } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { noop } from 'lodash';
import Pager from './Pager';
import DropdownMenu from '../Dropdown/DropdownMenu/DropdownMenu';

export const PAGE_SIZE_OPTIONS = ['10', '25', '50', '100'];

const defaultItemRender = (page, type, element) => element;
export const calculatePage = (pageSize, state, props) => {
  const size = typeof pageSize === 'undefined' ? state.pageSize : pageSize;
  return Math.floor((props.total - 1) / size) + 1;
};

/**
 * Pagination component
 */
class Pagination extends React.Component {
  constructor(props) {
    super(props);
    const hasOnChange = props.onChange !== noop;
    const hasCurrent = props.current;
    if (hasCurrent && !hasOnChange) {
      // do nothing
    }
    let current = props.defaultCurrent;
    if (props.current) {
      // eslint-disable-next-line prefer-destructuring
      current = props.current;
    }
    let pageSize = props.defaultPageSize;
    if (props.pageSize) {
      // eslint-disable-next-line prefer-destructuring
      pageSize = props.pageSize;
    }
    current = Math.min(current, calculatePage(pageSize, undefined, props));

    this.state = {
      current,
      pageSize,
    };
  }

  getJumpPrevPage = () => Math.max(1, this.state.current - (this.props.showLessItems ? 3 : 5));

  getJumpNextPage = () => Math.min(
    calculatePage(undefined, this.state, this.props),
    this.state.current + (this.props.showLessItems ? 3 : 5),
  );

  getItemIcon = (icon, iconName) => {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    let iconNode = icon || <i className="material-icons">{iconName}</i>;
    if (typeof icon === 'function') {
      iconNode = React.createElement(icon, { ...this.props });
    }
    return iconNode;
  }

  savePaginationNode = (node) => {
    this.paginationNode = node;
  }

  isValid = (page) => this.isInteger(page) && page !== this.state.current;

  isInteger = (value) => (
    // eslint-disable-next-line no-restricted-globals
    typeof value === 'number' && isFinite(value) && Math.floor(value) === value
  );

  changePageSize = (size) => {
    let { current } = this.state;
    const newCurrent = calculatePage(size, this.state, this.props);
    current = current > newCurrent ? newCurrent : current;
    if (newCurrent === 0) {
      // eslint-disable-next-line prefer-destructuring
      current = this.state.current;
    }
    if (!this.props.pageSize) {
      this.setState({ pageSize: size });
    }
    if (!this.props.current) {
      this.setState({ current });
    }

    this.props.onShowSizeChange(current, size);
  }

  handleChange = (p) => {
    const { disabled, current } = this.props;
    let page = p;
    if (this.isValid(page) && !disabled) {
      const currentPage = calculatePage(undefined, this.state, this.props);
      if (page > currentPage) {
        page = currentPage;
      } else if (page < 1) {
        page = 1;
      }
      if (!current) {
        this.setState({ current: page });
      }
      const { pageSize } = this.state;
      this.props.onChange(page, pageSize);

      return page;
    }
    return this.state.current;
  }

  prev = () => {
    if (this.hasPrev()) {
      this.handleChange(this.state.current - 1);
    }
  }

  next = () => {
    if (this.hasNext()) {
      this.handleChange(this.state.current + 1);
    }
  }

  jumpPrev = () => {
    this.handleChange(this.getJumpPrevPage());
  }

  jumpNext = () => {
    this.handleChange(this.getJumpNextPage());
  }

  hasPrev = () => this.state.current > 1;

  hasNext = () => this.state.current < calculatePage(undefined, this.state, this.props);

  renderPrev(prevPage) {
    const { itemRender } = this.props;
    const prevButton = itemRender(prevPage, 'prev', this.getItemIcon(null, 'keyboard_arrow_left'));
    const disabled = !this.hasPrev();
    return isValidElement(prevButton) ? cloneElement(prevButton, { disabled }) : prevButton;
  }

  renderNext(nextPage) {
    const { itemRender } = this.props;
    const nextButton = itemRender(nextPage, 'next', this.getItemIcon(null, 'keyboard_arrow_right'));
    const disabled = !this.hasNext();
    return isValidElement(nextButton) ? cloneElement(nextButton, { disabled }) : nextButton;
  }

  static getDerivedStateFromProps(props, prevState) {
    const newState = {};
    if (props.current) {
      newState.current = props.current;
    }
    if (props.pageSize && props.pageSize !== prevState.pageSize) {
      let { current } = prevState;
      const newCurrent = calculatePage(props.pageSize, prevState, props);
      current = current > newCurrent ? newCurrent : current;
      if (!props.current) {
        newState.current = current;
      }
      newState.pageSize = props.pageSize;
    }
    return newState;
  }

  render() {
    const {
      prefixCls,
      className,
      style,
      disabled,
      hideOnSinglePage,
      total,
      showLessItems,
      itemRender,
      showPrevNextJumpers,
      pageSizeOptions,
      showSizeChanger,
      pageSizeOptionsPlacement,
    } = this.props;

    const { current, pageSize } = this.state;

    // When hideOnSinglePage is true and there is only 1 page, hide the pager
    if (hideOnSinglePage === true && total <= pageSize) {
      return null;
    }

    const allPages = calculatePage(undefined, this.state, this.props);
    const pagerList = [];
    let jumpPrev = null;
    let jumpNext = null;
    let firstPager = null;
    let lastPager = null;

    const pageBufferSize = showLessItems ? 1 : 2;

    const prevPage = current - 1 > 0 ? current - 1 : 0;
    const nextPage = current + 1 < allPages ? current + 1 : allPages;
    const pagerProps = {
      rootPrefixCls: prefixCls,
      onClick: this.handleChange,
      itemRender,
      disabled,
    };
    if (allPages <= 3 + (pageBufferSize * 2)) {
      if (!allPages) {
        pagerList.push(
          <Pager {...pagerProps} key="noPager" page={allPages} disabled />,
        );
      }
      for (let i = 1; i <= allPages; i += 1) {
        const active = current === i;
        pagerList.push(
          <Pager {...pagerProps} key={i} page={i} active={active} />,
        );
      }
    } else {
      if (showPrevNextJumpers) {
        jumpPrev = (
          <button
            key="prev"
            type="button"
            onClick={this.jumpPrev}
            className={`${prefixCls}-jump-prev`}
          >
            {itemRender(this.getJumpPrevPage(), 'jump-prev', this.getItemIcon('...'))}
          </button>
        );
        jumpNext = (
          <button
            key="next"
            type="button"
            onClick={this.jumpNext}
            className={`${prefixCls}-jump-next`}
          >
            {itemRender(this.getJumpNextPage(), 'jump-next', this.getItemIcon('...'))}
          </button>
        );
      }
      lastPager = (
        <Pager {...pagerProps} key={allPages} page={allPages} active={false} />
      );
      firstPager = (
        <Pager {...pagerProps} key={1} page={1} active={false} />
      );

      let left = Math.max(1, current - pageBufferSize);
      let right = Math.min(current + pageBufferSize, allPages);

      if (current - 1 <= pageBufferSize) {
        right = 1 + (pageBufferSize * 2);
      }
      if (allPages - current <= pageBufferSize) {
        left = allPages - (pageBufferSize * 2);
      }

      for (let i = left; i <= right; i += 1) {
        const active = current === i;
        pagerList.push(
          <Pager {...pagerProps} key={i} page={i} active={active} />,
        );
      }
      if (current - 1 >= pageBufferSize * 2 && current !== 1 + 2) {
        pagerList.unshift(jumpPrev);
      }
      if (allPages - current >= pageBufferSize * 2 && current !== allPages - 2) {
        pagerList.push(jumpNext);
      }
      if (left !== 1) {
        pagerList.unshift(firstPager);
      }
      if (right !== allPages) {
        pagerList.push(lastPager);
      }
    }
    const prevDisabled = !this.hasPrev() || !allPages;
    const nextDisabled = !this.hasNext() || !allPages;
    return (
      <div
        className={classNames(prefixCls, className, {
          [`${prefixCls}-disabled`]: disabled,
        })}
        style={style}
        unselectable="unselectable"
        ref={this.savePaginationNode}
      >
        <button
          onClick={this.prev}
          type="button"
          className={classNames(`${prefixCls}-prev`, {
            [`${prefixCls}-disabled`]: prevDisabled || disabled,
          })}
          disabled={prevDisabled}
        >
          {this.renderPrev(prevPage)}
        </button>
        {pagerList}
        <button
          onClick={this.next}
          type="button"
          className={classNames(`${prefixCls}-next`, {
            [`${prefixCls}-disabled`]: nextDisabled || disabled,
          })}
          disabled={nextDisabled}
        >
          {this.renderNext(nextPage)}
        </button>
        {showSizeChanger && (
          <DropdownMenu
            data={pageSizeOptions}
            onChange={(action) => this.changePageSize(action * 1)}
            placement={pageSizeOptionsPlacement}
            horizontalOffset={-1}
            className={`${prefixCls}-dropdown`}
          >
            <div className={`${prefixCls}-page-select`}>
              <span>{pageSize}</span>
              <i className="material-icons">keyboard_arrow_down</i>
            </div>
          </DropdownMenu>
        )}
      </div>
    );
  }
}

Pagination.propTypes = {
  /**
   * The default current page index
   */
  defaultCurrent: PropTypes.number,
  /**
   * Total of the pagination
   */
  total: PropTypes.number,
  /**
   * The default page size
   */
  defaultPageSize: PropTypes.number,
  /**
   * Click page item callback
   */
  onChange: PropTypes.func,
  /**
   * Add classname to pagination
   */
  className: PropTypes.string,
  /**
   * Prefix class
   */
  prefixCls: PropTypes.string,
  /**
   * Should hidden when single page
   */
  hideOnSinglePage: PropTypes.bool,
  /**
   * Should show Previous and Next jumper, default true
   */
  showPrevNextJumpers: PropTypes.bool,
  /**
   * Should show less items(means 3) each pagegroup, default true
   */
  showLessItems: PropTypes.bool,
  /**
   * Callback of the page size changed
   */
  onShowSizeChange: PropTypes.func,
  /**
   * Style for pagination
   */
  style: PropTypes.string,
  /**
   * Custom render for each item of pagination
   */
  itemRender: PropTypes.func,
  /**
   * Pagination change size options
   */
  pageSizeOptions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
  ]),
  /**
   * The current index of selected page, if set this prop will changed from outside
   */
  current: PropTypes.number,
  /**
   * Page size of each page, if set this prop will changed from outside
   */
  pageSize: PropTypes.number,
  /**
   * Pagination disabled
   */
  disabled: PropTypes.bool,
  /**
   * Show size changer if user click option
   */
  showSizeChanger: PropTypes.bool,
  /**
   * placement for pagesize option
   */
  pageSizeOptionsPlacement: PropTypes.string,
};

Pagination.defaultProps = {
  defaultCurrent: 1,
  total: 0,
  defaultPageSize: 10,
  onChange: noop,
  className: '',
  prefixCls: 'v2_component_pagination',
  hideOnSinglePage: false,
  showPrevNextJumpers: true,
  showLessItems: true,
  onShowSizeChange: noop,
  style: null,
  itemRender: defaultItemRender,
  pageSizeOptions: PAGE_SIZE_OPTIONS,
  current: 0,
  pageSize: 0,
  disabled: false,
  showSizeChanger: true,
  pageSizeOptionsPlacement: 'topRight',
};

export default Pagination;
