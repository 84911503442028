import React from 'react';
import { FILTER_KEY_NETWORK_TYPE } from 'app/constants/filters';
import CommonMultiPicker from '../CommonMultiPicker/CommonMultiPicker';
import { networkTypes } from '../constant';

const MultiNetworkType = (props) => (
  <CommonMultiPicker data={networkTypes} filterKey={FILTER_KEY_NETWORK_TYPE} {...props} />
);

export default MultiNetworkType;
