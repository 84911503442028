import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import csv from 'papaparse';
import { isEqual } from 'lodash';
import { upload } from '../../lib/http';
import { replaceRateToNumber } from '../../lib/lib';
import { isFileSizeAboveThreshold } from '../../lib/uploader';
import Button from '../Button/Button';
import Checkmark from '../Checkmark/Checkmark';
import './uploads.scss';
import formats from './formats';
import errors from './errors';

const File = ({ file, complete, error }) => (
  <div key={file.name} className={`file ${(complete && !error) ? 'file--complete' : ''}`}>
    {file.type.indexOf('image') === 0 ? (
      <img src={file.preview} />
    ) : (
      <video src={file.preview} />
    )}
    <div className="info">
      <span className="name">{file.name}</span>
      <span className="type">{file.type}</span>
    </div>
    {!error && (
      <Checkmark complete={complete} />
    )}
    {error && (
      <i className="material-icons">error_outline</i>
    )}
  </div>
);

const Uploader = ({
  disabled,
  name = 'asset-uploader',
  type = 'default',
  onDrop,
  hasFiles,
  files,
  dragging,
  fileErrors,
  onReset,
  icon,
}) => (
  <div
    className={cn({
      component__uploader: true,
      'component__uploader--dragging': dragging,
      disabled,
    })}
  >
    {hasFiles ? (
      <div className={cn({ preview: true, disabled })}>
        {files.map(({ file, complete, error } = {}) => file && (
          <File key={file.name} file={file} complete={complete} error={error} />
        ))}
        {(fileErrors && fileErrors.length > 0) && (
          <div className="instructions">
            <p className="p10">
              {formats[type].errors
                ? (formats[type].errors[fileErrors[0]] || formats[type].error)
                : formats[type].error}
            </p>
            <Button inverse onClick={onReset} disabled={disabled}>Sounds Good</Button>
          </div>
        )}
      </div>
    ) : (
      <Dropzone onDrop={onDrop}>
        {({ getRootProps, getInputProps }) => (
          <div className={`wrapper ${dragging ? 'wrapper--dragging' : ''}`} {...getRootProps()}>
            {icon || (
              <div className="instructions">
                <i className="material-icons">cloud_upload</i>
                <p className="h2">Drag & drop</p>
                {!dragging && (
                  <p className="p6">
                    {formats[type].description}
                    <span className="body__link">browse</span>
                  </p>
                )}
              </div>
            )}
            <input name={name} {...getInputProps()} {...formats[type]} />
          </div>
        )}
      </Dropzone>
    )}
  </div>
);

class UploaderContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
    };
  }

  componentDidUpdate = (prevProps) => {
    const { validatedFiles: prevValidatedFiles } = prevProps;
    const { validatedFiles } = this.props;

    if (!isEqual(validatedFiles, prevValidatedFiles)) {
      this.setFilesAndStartUpload(validatedFiles);
    }
  }

  uploadFiles() {
    let uploadingFilesLength = this.state.files.length;
    this.state.files.forEach(async ({
      file, index, complete = false, error = null,
    }) => {
      let completed = complete;
      let errorStatus = error;
      let result = { ok: false };
      if (isFileSizeAboveThreshold(file.size)) {
        completed = true;
        errorStatus = errors.FILE_TOO_LARGE;
      }
      if (!completed) {
        completed = true;

        let uploader = () => {
          const body = new FormData();
          body.append(this.props.fileName, file);
          this.props.formData.forEach((formData) => {
            const { name, value } = formData;
            if (typeof value === 'function') {
              body.append(name, value(file));
              return;
            }
            body.append(name, value);
          });
          return upload(this.props.uploadUrl, body);
        };
        if (this.props.uploaderFunc) {
          uploader = this.props.uploaderFunc;
        }

        try {
          result = await uploader(file);
          errorStatus = this.getError(result);
        } catch (ex) {
          completed = false;
          let err;
          if (ex) {
            err = this.getError({ response: { messages: ex } });
          }
          errorStatus = err || 'unexpected';
        }
      }

      this.setState((prevState) => {
        const files = [...prevState.files];
        files[index] = { ...files[index], complete: completed, error: errorStatus || !result.ok };
        return {
          files,
        };
      });

      if (completed && result.ok) {
        setTimeout(() => {
          const newFiles = [...this.state.files];
          newFiles.splice(index, 1);
          uploadingFilesLength -= 1;
          this.setState({ files: uploadingFilesLength > 0 ? newFiles : [] }, () => {
            if (uploadingFilesLength === 0) {
              this.props.onComplete(result.response, file);
            }
          });
        }, 2250);
      }
    });
  }

  getError = (result) => {
    if (result && result.response && result.response.messages && result.response.messages.length) {
      const [error] = result.response.messages;
      return error;
    }
    return undefined;
  }

  setFilesAndStartUpload = (files) => {
    this.setState({ files }, this.uploadFiles);
  }

  onDrop = (files) => {
    const { onComplete, onError, onDropDoValidate = () => false } = this.props;
    const reader = new FileReader();
    const filesAreBeingValidated = onDropDoValidate(files);

    // if files need to be validated before they are passed along to upload api, then need to be passed in `validatedFiles` prop
    if (filesAreBeingValidated && this.props.type !== 'csv') {
      return;
    }

    if (this.props.type === 'csv') {
      reader.onload = () => {
        const { data } = csv.parse(reader.result, { header: true });
        if (!data) {
          onError();
          return;
        }
        data.forEach((pub, i) => {
          if (pub.rate) {
            data[i].rate = replaceRateToNumber(pub.rate);
          }
        });
        onComplete(data);
      };
      reader.readAsText(files[0]);
      return;
    }

    this.setFilesAndStartUpload(files.map((file, index) => ({ file, index, complete: false })));
  };

  onReset = () => {
    this.setState({ files: [] });
  };

  render() {
    const { files } = this.state;
    const fileErrors = files
      .filter((file) => !!file && !!file.error)
      .map((file) => file.error);

    return (
      <Uploader
        {...this.props}
        fileErrors={fileErrors}
        hasFiles={files.length > 0}
        files={files}
        onReset={this.onReset}
        onDrop={this.onDrop}
      />
    );
  }
}

UploaderContainer.propTypes = {
  onComplete: PropTypes.func,
  onError: PropTypes.func,
  formData: PropTypes.arrayOf(PropTypes.object),
  fileName: PropTypes.string,
  uploadUrl: PropTypes.string,
};

UploaderContainer.defaultProps = {
  fileName: 'file',
  formData: [{}],
  onComplete: () => {
  },
  onError: () => {
  },
  uploadUrl: '',
};

export default UploaderContainer;
