import React from 'react';
import { Redirect } from 'react-router-dom';
import {
  compose,
  namespace,
  splash,
} from '../../app/app';
import { searchAppStoreSettings } from '../../app/graphql/utils/appstore';
import { track } from '../../lib/tracker';
import Application from '../../models/Application';
import Campaign from '../../models/Campaign';
import ApplicationCreator, { AppSearch } from './ApplicationCreator';
import paths from '../../services/advertiser/paths';
import './creator.scss';

const createNamespace = 'views.campaigns.edit';
const ns = 'views.campaigns.edit.application.creator';
const updateState = namespace(ns);
const updateCampaignState = namespace(createNamespace);
const props = (state) => ({
  ...state[ns],
  campaign: state[createNamespace],
});

const {
  CAMPAIGN_DETAILS: {
    getPath: getPathToCampaignCreate,
  },
} = paths;

class ApplicationCreatorContainer extends React.Component {
  componentWillUnmount() {
    this.props.actions.resetState();
  }

  render() {
    const { savedApplication } = this.props;
    const destination = getPathToCampaignCreate();
    if (savedApplication) {
      return <Redirect to={destination} />;
    }
    return <ApplicationCreator {...this.props} />;
  }
}

const resetState = ({ state }) => updateState(state, {
  application: null,
  applicationNeedsApproval: false,
  couldNotSaveApplication: false,
  errMsg: undefined,
  loadingResults: false,
  savingApplication: false,
  savedApplication: false,
  searchResults: null,
  store: null,
  searchValue: '',
});

const selectStore = ({ state, values }) => updateState(state, {
  application: null,
  couldNotSaveApplication: false,
  searchResults: null,
  searchValue: '',
  store: values,
});
const selectApplication = ({ state, values }) => updateState(state, {
  application: values.id === -1 ? null : values,
  couldNotSaveApplication: false,
});

let searchingTimeout;
const searchAppStore = (() => {
  const action = ({ store, v, refresh = true }) => new Promise((resolve) => {
    if (searchingTimeout) {
      clearTimeout(searchingTimeout);
    }
    searchingTimeout = setTimeout(async () => {
      // only search if we have 3 characters or more
      if (v.length > 2 && refresh) {
        resolve(searchAppStoreSettings(store, v));
      } else {
        resolve({ status: 200, response: [], hideSearchResults: !refresh });
      }
    }, 500);
  });
  const start = ({ state, result: { v, refresh = true } }) => updateState(state, { loadingResults: refresh, searchValue: v });
  const end = ({ state, result }) => updateState(state, {
    loadingResults: false,
    searchResults: !result.hideSearchResults ? result.response : null,
  });
  return { action, start, end };
})();

const createApplication = (() => {
  const action = ({ store, application }) => Application.create(store, application.id);
  const start = () => splash({ start: true, text: 'adding your application' });
  const end = ({ state, result }) => {
    if (!result.ok) {
      return {
        ...updateState(state, {
          couldNotSaveApplication: true, errMsg: result.message, savingApplication: false, savedApplication: false,
        }),
        ...splash({ show: false }),
      };
    }
    const application = new Application(result);
    track('Application Created', { id: application.get('id') });
    if (application.get('admin_status') === 'pending') {
      return {
        ...updateState(state, { applicationNeedsApproval: true }),
        ...splash({ finish: true }),
      };
    }
    return {
      ...updateCampaignState(state, {
        campaign: new Campaign({
          account: { id: state.authUser.account },
          application: result,
        }),
      }),
      ...updateState(state, {
        savedApplication: true,
      }),
      ...splash({ finish: true }),
    };
  };
  return { action, start, end };
})();

const actions = {
  createApplication,
  resetState,
  searchAppStore,
  selectApplication,
  selectStore,
};

export default compose(ApplicationCreatorContainer, { actions, props });
export { AppSearch };
