import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { DayPickerRangeController } from 'react-dates';
import {
  convertDateInSecondsToDays,
  formatCustomDateRangeDisplay,
  initializeDates,
  selections,
  moment,
} from '../../../lib/date';
import DateRangeQuickPicker from './DateRangeQuickPicker';
import 'react-dates/lib/css/_datepicker.css';
import './dateRangePicker.scss';

/**
  * DateRangePicker component based on react-dates
  */
class DateRangePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initState(props);
  }

  initState = (props) => {
    const { startDateString } = props;
    const { endDateString } = props;
    const quickPickValue = props.quickPickValue || false;
    const {
      displayedDateRange, quickPickSelection, start, end,
    } = initializeDates(quickPickValue, startDateString, endDateString);
    return {
      showQuickPicker: true,
      displayedDateRange,
      quickPickSelection,
      startDate: start || moment().subtract(quickPickSelection * 1, 'days'),
      endDate: end || (quickPickSelection === '0' ? moment() : moment().subtract(1, 'days')),
      focusedInput: end ? 'endDate' : 'startDate',
    };
  }

  onQuickPickChange = (newSelection, e) => {
    e.stopPropagation();
    const state = {
      startDate: null,
      endDate: null,
      quickPickSelection: newSelection.value,
    };
    if (newSelection.value !== selections.CUSTOM.value) {
      state.displayedDateRange = newSelection.text;
      state.startDate = moment().subtract(newSelection.value * 1, 'days');
      state.endDate = newSelection.value === '0' ? moment() : moment().subtract(1, 'days');
      this.processDateChange({
        start: newSelection.value,
        end: newSelection.value * 1 > 0 ? 1 : 0,
        startString: state.startDate.format('YYYY-MM-DD'),
        endString: state.endDate.format('YYYY-MM-DD'),
        quickPickValue: newSelection.value,
      });
    }
    this.setState(state);
  }

  onDatesChange = ({ startDate, endDate }) => {
    const isFocusedStart = this.state.focusedInput === 'startDate';
    const quickPickSelectionValue = startDate || endDate ? selections.CUSTOM.value : selections.LAST_7_DAYS.value;
    const hasStartDateAndEndDate = isFocusedStart ? false : !!(startDate && endDate);
    const start = startDate || endDate;
    const end = isFocusedStart ? null : startDate && endDate;
    this.setState({
      startDate: start,
      endDate: end,
      quickPickSelection: quickPickSelectionValue,
      // TODO: displayedDateRange is not used AND NEEDS TO BE ADDRESSED
      // eslint-disable-next-line react/no-unused-state
      displayedDateRange: formatCustomDateRangeDisplay(start, end),
    });
    if (hasStartDateAndEndDate) {
      this.processDateChange({
        start: convertDateInSecondsToDays(startDate),
        end: convertDateInSecondsToDays(endDate),
        startString: startDate.format('YYYY-MM-DD'),
        endString: endDate.format('YYYY-MM-DD'),
        quickPickValue: quickPickSelectionValue,
      });
    }
  }

  processDateChange = (dateRange, initial = false) => {
    this.props.onDateRangeChange(dateRange, initial);
  }

  // focusedInput must be truthy in order to select dates. However, after a date range is
  // selected, clicking on another date in the calendar calls this method with focusedInput === null
  onFocusChange = (focusedInput = true) => {
    this.setState({
      focusedInput,
    });
  }

  componentDidMount() {
    const { startDate, endDate, quickPickValue } = this.state;
    this.processDateChange({
      start: convertDateInSecondsToDays(startDate),
      end: convertDateInSecondsToDays(endDate),
      startString: startDate.format('YYYY-MM-DD'),
      endString: endDate.format('YYYY-MM-DD'),
      quickPickValue,
    }, true);
  }

  render() {
    const {
      quickPickSelection, startDate, endDate, focusedInput,
    } = this.state;
    const { prefixCls, className, style } = this.props;
    return (
      <div className={cn(prefixCls, className)} style={style}>
        <DateRangeQuickPicker
          prefixCls={prefixCls}
          quickPickSelection={quickPickSelection}
          onQuickPickChange={this.onQuickPickChange}
        />
        <DayPickerRangeController
          keepOpenOnDateSelect
          initialVisibleMonth={() => startDate || moment().subtract(30, 'days')}
          navPrev={<i className="material-icons">keyboard_arrow_left</i>}
          navNext={<i className="material-icons">keyboard_arrow_right</i>}
          weekDayFormat="ddd"
          numberOfMonths={2}
          minimumNights={0}
          startDate={startDate}
          endDate={endDate}
          onDatesChange={this.onDatesChange}
          focusedInput={focusedInput}
          onFocusChange={this.onFocusChange}
          isOutsideRange={(m) => {
            const start = moment().tz('UTC').subtract(90, 'days').format('YYYY-MM-DD');
            const end = moment().tz('UTC').format('YYYY-MM-DD');
            const date = m.tz('UTC').format('YYYY-MM-DD');
            return date < start || date > end;
          }}
        />
      </div>
    );
  }
}
DateRangePicker.propTypes = {
  /**
   * Prefix class for DateRangePicker
   */
  prefixCls: PropTypes.string,
  /**
   * Add classname to DateRangePicker
   */
  className: PropTypes.string,
  /**
   * Add style to DateRangePicker
   */
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  /**
   * Add start date to show
   */
  // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
  startDateString: PropTypes.string,
  /**
   * Add end date to show
   */
  // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
  endDateString: PropTypes.string,
  /**
   * Add date change event to DateRangePicker
   */
  onDateRangeChange: PropTypes.func.isRequired,
};

DateRangePicker.defaultProps = {
  prefixCls: 'v2_component_date_range_picker',
  style: null,
  className: null,
  startDateString: null,
  endDateString: null,
};

export default DateRangePicker;
