import React, { useMemo, useEffect } from 'react';
import Button from '../../../../../components/V2/Button/Button';
import Text from '../../../../../components/Form/Text/TextContainer';
import { inputMaxLength } from '../config';

const ResolvingIssues = ({
  canProceed,
  measure,
  feedback,
  onFeedbackChange,
  onAssetReplacement,
  onSendFeedback,
}) => {
  const showMaxLengthWarning = useMemo(() => feedback.length > inputMaxLength, [feedback]);
  useEffect(measure, [feedback]);

  return (
    <div className="action">
      {canProceed && (
        <>
          <p className="action-title">Please let us know how you would like to proceed. (256 characters maximum)</p>
          <Text
            wrapperClassName="action-input"
            noIcon
            hideClearButton
            value={feedback}
            onChange={(val) => onFeedbackChange(val)}
            placeholder="Add your feedback here…"
            hasError={showMaxLengthWarning}
          />
          {showMaxLengthWarning && (
            <p className="action-input-warning">
              {`${inputMaxLength} characters maximum`}
            </p>
          )}
        </>
      )}
      <div className="action-buttons">
        {canProceed && (
          <>
            <Button
              size="small"
              type="primary"
              disabled={!feedback.trim().length || showMaxLengthWarning}
              onClick={onSendFeedback}
              className="send"
            >
              Send Feedback & Proceed
            </Button>
            <span className="or">OR</span>
          </>
        )}
        <Button size="small" type="ghost" className="replace" onClick={onAssetReplacement}>Replace Asset to Resubmit for Another Review</Button>
      </div>
    </div>
  );
};

export default ResolvingIssues;
