import React from 'react';
import {
  compose, namespace, splashStart, splashFinish,
} from 'app/app';
import Campaign from '../../../../../../models/Campaign';
import Creative from '../../../../../../models/Creative';
import { CREATE_CREATIVE_SOURCE_KEY } from '../constant';
import Campaigns from './Campaigns';
import triggerConfirm from '../../../../../../components/Modals/ConfirmAction/triggerConfirm';

const ns = 'views.creatives.edit';
const updateState = namespace(ns);
const props = (state) => state[ns];

class CampaignsContainer extends React.Component {
  constructor(p) {
    super(p);
    this.state = {
      changedCampaigns: [],
    };
  }

  componentDidMount() {
    this.props.actions.selectCampaign(this.props.creative.get('campaigns').map((c) => c.id));
  }

  componentWillUnmount() {
    this.props.actions.destroyView();
  }

  saveCreativeToCampaigns = async (createAnother = false) => {
    const campaignIds = this.props.selectedIds || [];
    const campaignsToSave = [];
    this.state.changedCampaigns.forEach((campaign) => {
      const isActive = campaignIds.includes(campaign.get('id'));
      campaign.toggleCreative(this.props.creative, isActive);
      campaignsToSave.push(campaign);
    });

    splashStart('Saving your changes');
    // TODO: we need to re-implement the loop, because splashFinish might be called before the loop is done
    for (let i = 0; i < campaignsToSave.length; i++) {
      const res = await Campaign.updateCreativesForCampaign(campaignsToSave[i]); //eslint-disable-line
      if (!res.ok) {
        this.props.actions.showModal();
        triggerConfirm({
          type: 'FAIL_CREATIVE_SAVE_CONFIRM_ACTION',
          header: 'Unsuccessful Save',
          message: () => <span className="active">Saving Creative Failed.</span>,
          onConfirm: () => this.props.actions.resetModal(),
          onCancel: () => this.props.actions.resetModal(),
          confirmOnly: true,
        });

        splashFinish('Failed.');
        return;
      }
    }

    splashFinish();

    if (createAnother) {
      const creative = new Creative();
      this.props.actions.setupAddAnotherView(creative);
      const application = this.props.creative.get('application');
      if (application.isSKAdNetworkEnabled()) {
        this.props.handleSkAppClick(
          application,
          creative,
          this.props.updateCreative,
        );
      }
      this.props.history.push('/creatives/create/creatives');
    } else {
      this.props.history.push(this.props[CREATE_CREATIVE_SOURCE_KEY] || '/creatives/');
    }
  }

  setChangedCampaigns = (campaign) => {
    if (!campaign) { // No campaign means users clears all campaigns by clicking remove all button.
      this.setState({
        changedCampaigns: this.props.creative.get('campaigns').map((c) => new Campaign(c)),
      });
      return;
    }
    const campaignId = campaign.get('id');
    const isCampaignAlreadyChanged = this.state.changedCampaigns.some((c) => c.get('id') === campaignId);

    if (!isCampaignAlreadyChanged) {
      const { changedCampaigns } = this.state;
      changedCampaigns.push(campaign);
      this.setState({
        changedCampaigns,
      });
    }
  }

  render() {
    return (
      <Campaigns
        {...this.props}
        campaigns={this.campaigns}
        saveCreativeToCampaigns={this.saveCreativeToCampaigns}
        setChangedCampaigns={this.setChangedCampaigns}
        afterFetchingCampaigns={(campaigns) => {
          this.campaigns = campaigns;
        }}
      />
    );
  }
}

const setupAddAnotherView = ({ state, values }) => {
  const application = state[ns].creative.get('application');
  const creative = values;
  creative.set('account', { id: state.authUser.account });
  creative.set('application', application);

  return updateState(state, {
    creative,
  });
};

const resetModal = ({ state }) => updateState(state, { modalShow: false, errMsg: undefined });

const showModal = ({ state }) => updateState(state, { modalShow: true });

const selectCampaign = ({ state, values }) => updateState(state, {
  selectedIds: values,
});

const destroyView = ({ state }) => updateState(state, {
  campaigns: [],
  selectedIds: [],
  [CREATE_CREATIVE_SOURCE_KEY]: null,
});

const actions = {
  selectCampaign,
  destroyView,
  setupAddAnotherView,
  resetModal,
  showModal,
};

export default compose(CampaignsContainer, { actions, props });
