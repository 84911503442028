import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { FILTER_KEY_LANGUAGE } from 'app/constants/filters';
import MultiTreeFrontend from '../Base/MultiTreeFrontend/MultiTreeFrontend';
import LanguagePanel, { nodes } from '../Base/Panels/LanguagePanel';

const MultiTreeLanguage = ({
  visible,
  defaultCheckedNodes,
  dropdownFixed,
  onVisibleChange,
  onChange,
  onRemove,
}) => (
  <MultiTreeFrontend
    dropdownFixed={dropdownFixed}
    nodes={nodes}
    visible={visible}
    filterKey={FILTER_KEY_LANGUAGE}
    defaultCheckedNodes={defaultCheckedNodes}
    renderOverlay={() => (
      React.createElement(LanguagePanel, {
        visible,
        defaultCheckedNodes,
        onVisibleChange,
        onChange,
      })
    )}
    onVisibleChange={onVisibleChange}
    onChange={onChange}
    onRemove={onRemove}
  />
);

MultiTreeLanguage.propTypes = {
  visible: PropTypes.bool.isRequired,
  defaultCheckedNodes: PropTypes.arrayOf(PropTypes.any),
  dropdownFixed: PropTypes.bool,
  onVisibleChange: PropTypes.func,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};

MultiTreeLanguage.defaultProps = {
  defaultCheckedNodes: [],
  dropdownFixed: false,
  onVisibleChange: noop,
  onChange: noop,
  onRemove: noop,
};

export default React.memo(MultiTreeLanguage);
