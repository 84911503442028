import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import Label from '../../../Label/Label';
import Pulsation from '../../../Pulsation/Pulsation';
import { STATUS_MINOR_TYPE } from '../../constant';
import MultiPanel from './MultiPanel';

const renderItemLabel = (prefixCls, { type, pulsationStatus, text }) => (
  <div className={`${prefixCls}-item`}>
    {
      type === STATUS_MINOR_TYPE
        ? <Label type={pulsationStatus}>{text}</Label>
        : (
          <Pulsation
            className={`${prefixCls}-pulsation`}
            status={pulsationStatus}
            labelText={text}
          />
        )
    }
  </div>
);

const StatusPanel = ({
  prefixCls,
  subType,
  data,
  defaultCheckedNodes,
  onVisibleChange,
  onChange,
}) => {
  const handleChange = useCallback((_selectedStatuses, isAllSelected) => {
    onVisibleChange(false);
    onChange(_selectedStatuses, isAllSelected);
  }, [onVisibleChange, onChange]);

  const isCampaignStatus = subType === 'campaignStatus';
  const className = isCampaignStatus ? 'campaign-status' : undefined;

  return (
    <MultiPanel
      className={className}
      style={{ maxHeight: '500px' }}
      data={data}
      renderItemLabel={(item) => renderItemLabel(prefixCls, item)}
      defaultCheckedKeys={(defaultCheckedNodes || []).map(({ id }) => id)}
      onVisibleChange={onVisibleChange}
      onChange={handleChange}
    />
  );
};

StatusPanel.propTypes = {
  prefixCls: PropTypes.string,
  subType: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  defaultCheckedNodes: PropTypes.arrayOf(PropTypes.any),
  onVisibleChange: PropTypes.func,
  onChange: PropTypes.func,
};

StatusPanel.defaultProps = {
  prefixCls: '',
  defaultCheckedNodes: [],
  onVisibleChange: noop,
  onChange: noop,
};

export default React.memo(StatusPanel);
