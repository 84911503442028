import {
  FILTER_KEY_APPLICATION,
  FILTER_KEY_CREATIVE_FORMAT,
  FILTER_KEY_LANGUAGE,
  FILTER_KEY_STATUS,
} from 'app/constants/filters';
import { getPrettyDiff } from 'lib/diff';
import { getServiceType } from 'lib/serviceType';
import { getClient } from '../setup';
import {
  GetCreative,
  GetCreatives,
  GetCreativeReplacements,
  GetCreativeActivity,
  GetCreativeBiggestVideo,
  ModifyCreative,
  CreateCreative,
  GetCreativeAttachment,
  ActivateCreative,
  PauseCreative,
  ApproveCreative,
  RejectCreative,
} from '../operations_creative.graphql';
import handleGraphQLError from './handle-graphql-error';
import { getSearchAndIDsFilter } from './helper';

function convertTpat(t, name) {
  const r = { name, url: '' };
  if (t != null && t.length > 0) {
    const [u] = t;
    r.url = u;
  }

  return r;
}

function convertPlayPercentageTpat(tpat) {
  const events = [];
  if (!tpat) {
    return events;
  }
  tpat.forEach((t) => {
    switch (t.checkpoint) {
      case 0:
        events.push(convertTpat(t.urls, 'play_start'));
        break;
      case 0.25:
        events.push(convertTpat(t.urls, 'play_25%'));
        break;
      case 0.5:
        events.push(convertTpat(t.urls, 'play_50%'));
        break;
      case 0.75:
        events.push(convertTpat(t.urls, 'play_75%'));
        break;
      case 1:
        events.push(convertTpat(t.urls, 'play_finish'));
        break;
      default:
        break;
    }
  });

  return events;
}

export function convertToRestFormat(creative) {
  if (!creative) {
    return undefined;
  }

  const {
    account, application, template, video, language, tpat, targeting, endCard,
  } = creative;

  const result = {
    id: creative.id,
    name: creative.name,
    status: creative.status,
    created: creative.created,
    admin_status: creative.adminStatus,
    format: creative.creativeFormat,
    vungle_name: creative.vungleName,
    updated: creative.updated,
    campaignIDs: creative.campaignIDs || [],
  };

  if (account) {
    result.account = {
      id: account.id,
      name: account.name,
      creativeMaximum: account.creativeMaximum,
    };
  }

  if (application) {
    result.application = {
      id: application.id,
      name: application.name,
      platform: application.platform.toLowerCase(),
      replacements: creative.replacements,
      admin_status: application.adminStatus,
      endcardURL: endCard?.url,
      application: {
        id: application.id,
        name: application.name,
        platform: application.platform,
      },
    };

    const { store } = application;
    if (store) {
      result.application.store = {
        thumbnail: store.thumbnail,
        id: store.id,
        url: store.url,
      };
    }
  }

  if (language) {
    result.language = {
      is_enforced: language.isEnforced,
      code: creative.language.code,
      name: creative.language.name,
    };
  }

  result.replacements = creative.replacements;
  result.campaigns = [];
  if (creative.campaigns) {
    creative.campaigns.forEach((c) => {
      result.campaigns.push({
        id: c.id,
        status: c.status,
        name: c.name,
        created: c.created,
        is_skadnetwork_enabled: c.isSkadnetworkEnabled,
        delete: c.isDeleted,
      });
    });
  }

  if (creative.tags !== undefined) {
    result.tags = [];
    creative.tags.forEach((tag) => { result.tags.push({ value: tag }); });
  }

  if (targeting) {
    result.targeting = {};
    const { devices } = targeting;
    if (devices) {
      // eslint-disable-next-line no-param-reassign
      result.targeting = {
        devices: {
          phone: devices.phone,
          tablet: devices.tablet,
        },
      };
    }
  }

  if (template?.id) {
    result.template = {
      id: template.id,
      name: template.name,
      format: template.format,
      protocol: template.supportedTemplateProtocol,
      cdn_url: template.cdnURL,
      template_category: template.templateCategory,
      replacements: template.replacements,
    };

    const { template_category } = template;
    // eslint-disable-next-line camelcase
    if (template_category) {
      result.template.template_category = {
        native: template.templateCategory.native,
        banner: template.templateCategory.banner,
      };
    }
  }

  if (video?.id) {
    result.video = {
      id: video.id,
      title: video.title,
      url: video.url,
      orientation: video.orientation,
      asset: video.asset,
    };
  }

  if (endCard?.id) {
    result.endcard = endCard.id;
    result.endcardUrl = endCard.url;
  }

  if (tpat) {
    let events = [];
    events.push(convertTpat(tpat.mute, 'mute'));
    events.push(convertTpat(tpat.unmute, 'unmute'));
    events.push(convertTpat(tpat.postrollClick, 'postroll_click'));
    events.push(convertTpat(tpat.postrollView, 'postroll_view'));
    events.push(convertTpat(tpat.videoClose, 'video_close'));
    events = events.concat(convertPlayPercentageTpat(tpat.playPercentage));
    result.tracking = {
      events,
      updated: null,
    };
  }

  return result;
}

export function handleCreativeGraphqlResponse({
  data: {
    creative,
  },
}) {
  const result = {
    ok: true,
    status: 200,
    original: creative,
    response: convertToRestFormat(creative),
  };
  return result;
}

export function handleCreativeListGraphqlResponse({
  data: {
    creatives,
  },
}) {
  const result = {
    ok: true,
    status: 200,
    original: creatives.creatives,
    response: creatives.creatives.map(convertToRestFormat),
    pagination: creatives.pagination,
  };

  return result;
}

export function handleCreativeModificationGraphqlResponse({
  data: {
    creative,
  },
}) {
  const result = {
    ok: true,
    status: 200,
    original: creative,
    response: convertToRestFormat(creative),
  };
  return result;
}

export function handleCreativeCreationGraphqlResponse({
  data,
}) {
  return handleCreativeModificationGraphqlResponse({
    data: {
      creative: data.createCreative,
    },
  });
}

export function getCreativeQueryVariables(filters) {
  const variables = {
    ...getSearchAndIDsFilter(filters),
    page: Number(filters.page),
    perPage: Number(filters.perPage),
    account: filters.account,
    applications: filters[FILTER_KEY_APPLICATION],
    languages: filters[FILTER_KEY_LANGUAGE],
    adFormat: filters[FILTER_KEY_CREATIVE_FORMAT],
  };

  const status = filters[FILTER_KEY_STATUS];
  if (status) {
    const selectedAdminStatus = [];
    const selectedStatus = [];
    status.forEach((s) => {
      if (['approved', 'rejected', 'pending'].includes(s)) {
        selectedAdminStatus.push(s);
      } else if (['active', 'paused'].includes(s)) {
        selectedStatus.push(s);
      }
    });

    if (selectedAdminStatus.length > 0) {
      variables.adminStatus = selectedAdminStatus;
    }

    if (selectedStatus.length > 0) {
      variables.status = selectedStatus;
    }
  }

  const { sort } = filters;
  if (sort) {
    variables.sort = sort;
  }

  return variables;
}

function toTPATUrl(url) {
  if (url && url.length) {
    return [url];
  }

  return [];
}

function toGraphQLCreativeTpatModifyRequest(events) {
  const percentage = [];
  const tpat = {
    postrollView: [],
    videoClose: [],
    unmute: [],
    mute: [],
    playPercentage: percentage,
  };
  if (events) {
    events.forEach((e) => {
      switch (e.name) {
        case 'play_start':
          percentage.push({
            checkpoint: 0,
            urls: toTPATUrl(e.url),
          });
          break;
        case 'play_25%':
          percentage.push({
            checkpoint: 0.25,
            urls: toTPATUrl(e.url),
          });
          break;
        case 'play_50%':
          percentage.push({
            checkpoint: 0.5,
            urls: toTPATUrl(e.url),
          });
          break;
        case 'play_75%':
          percentage.push({
            checkpoint: 0.75,
            urls: toTPATUrl(e.url),
          });
          break;
        case 'play_finish':
          percentage.push({
            checkpoint: 1,
            urls: toTPATUrl(e.url),
          });
          break;
        case 'postroll_view':
          tpat.postrollView = toTPATUrl(e.url);
          break;
        case 'video_close':
          tpat.videoClose = toTPATUrl(e.url);
          break;
        case 'mute':
          tpat.mute = toTPATUrl(e.url);
          break;
        case 'unmute':
          tpat.unmute = toTPATUrl(e.url);
          break;
        default:
          break;
      }
    });
  }

  return tpat;
}

export function toGraphQLCreativeCreationRequest(creative) {
  const {
    name, vungle_name, status, admin_status, format, tags, replacements, campaigns,
  } = creative;
  const payload = {
    name,
    vungleName: vungle_name,
    status,
    adminStatus: admin_status,
    format,
    tags: [],
    campaigns: [],
    replacements: [],
  };

  tags.forEach((tag) => { payload.tags.push(tag.value); });
  campaigns.forEach((c) => { payload.campaigns.push(c.id); });

  const { account } = creative;
  if (account) {
    payload.account = {
      id: account.id,
    };
  }

  const { application } = creative;
  if (application) {
    payload.application = {
      id: application.id,
    };
  }

  const { template } = creative;
  if (template) {
    payload.template = {
      id: template.id,
      name: template.name,
      format: template.format,
      isCustomCreative: template.is_custom_creative,
      cdnURL: template.cdn_url,
    };
  }

  const { targeting } = creative;
  if (targeting) {
    const { devices } = targeting;
    payload.targeting = {
      devices,
    };
  }

  const { endcard } = creative;
  if (endcard && endcard.length > 0) {
    payload.endcard = {
      id: endcard,
    };
  }

  const { video } = creative;
  if (video && video.id && video.id.length > 0) {
    payload.video = {
      id: video.id,
    };
  }

  const { ctaOverlay } = creative;
  if (ctaOverlay) {
    payload.ctaOverlay = {
      clickArea: ctaOverlay.click_area,
      timeShow: ctaOverlay.time_show,
      enabled: ctaOverlay.enabled,
    };
  }

  const { language } = creative;
  if (language) {
    payload.language = {
      code: language.code,
      name: language.name,
      isEnforced: language.is_enforced,
    };
  }

  replacements.forEach((r) => {
    payload.replacements.push(
      {
        key: r.key,
        name: r.name,
        value: r.value,
        priority: 10,
      },
    );
  });

  const { tracking } = creative;
  if (tracking) {
    payload.tpat = toGraphQLCreativeTpatModifyRequest(tracking.events);
  }

  return payload;
}

export function toGraphQLCreativeModifyRequest(creative) {
  const video = creative.get('video');
  const replacements = [];
  const r = creative.raw('replacements');
  r.forEach((replacement) => {
    replacements.push({
      key: replacement.key,
      name: replacement.name,
      value: replacement.value,
      priority: 10,
    });
  });
  const c = creative.get('campaigns');
  const campaigns = [];
  c.forEach((campaign) => {
    campaigns.push(campaign.id);
  });
  const tags = [];
  creative.get('tags').forEach((tag) => { tags.push(tag.value); });
  const endcard = creative.get('endcard');
  const payload = {
    name: creative.get('name'),
    vungleName: creative.get('vungle_name'),
    status: creative.get('status'),
    adminStatus: creative.get('admin_status'),
    campaignIDs: campaigns,
    format: creative.get('format'),
    videoID: video != null ? video.id : null,
    targeting: {
      devices: {
        phone: creative.get('targeting.devices.phone'),
        tablet: creative.get('targeting.devices.tablet'),
      },
    },
    language: {
      isEnforced: creative.get('language.is_enforced'),
      code: creative.get('language.code'),
      name: creative.get('language.name'),
    },
    tpat: toGraphQLCreativeTpatModifyRequest(creative.get('tracking.events')),
    tags: creative.get('tags').map((e) => e.value),
    sourceUpdate: 'admin',
    templateReplacements: replacements,
    endcardID: endcard != null && endcard.length > 0 ? endcard : null,
  };
  return payload;
}

export const getCreative = (id) => getClient()
  .query({
    query: GetCreative,
    variables: {
      id,
    },
  })
  .then(handleCreativeGraphqlResponse)
  .catch(handleGraphQLError);

export const getCreativeReplacements = (id) => getClient()
  .query({
    query: GetCreativeReplacements,
    variables: {
      id,
    },
  })
  .then(({
    data: {
      creativeReplacements: replacements,
    },
  }) => ({
    ok: true,
    status: 200,
    response: { replacements },
  }))
  .catch(handleGraphQLError);

export function modifyCreative(id, creativeServerObject) {
  return getClient().mutate({
    mutation: ModifyCreative,
    variables: {
      id,
      input: toGraphQLCreativeModifyRequest(creativeServerObject),
    },
  })
    .then(handleCreativeModificationGraphqlResponse)
    .catch(handleGraphQLError);
}

export function createCreative(creativeServerObject) {
  return getClient().mutate({
    mutation: CreateCreative,
    variables: {
      input: {
        ...toGraphQLCreativeCreationRequest(creativeServerObject),
        client: getServiceType(),
      },
    },
  })
    .then(handleCreativeCreationGraphqlResponse)
    .catch(handleGraphQLError);
}

export const getCreativeAttachmentList = (filters = {}) => getClient()
  .query({
    query: GetCreativeAttachment,
    variables: getCreativeQueryVariables(filters),
  })
  .then((res) => {
    const { creatives } = res.data;
    return {
      ok: true,
      status: 200,
      response: creatives.creatives.map(convertToRestFormat),
      pagination: creatives.pagination,
    };
  })
  .catch(handleGraphQLError);

export function handleCreativeAction(id, action, shouldDetachAllCreatives = false) {
  const creativeActionMap = {
    activate: ActivateCreative,
    pause: PauseCreative,
    approve: ApproveCreative,
    reject: RejectCreative,
  };
  const mutation = creativeActionMap[action] || '';

  return getClient()
    .mutate({
      mutation,
      variables: {
        id,
        shouldDetachAllCreatives,
      },
    })
    .then((res) => {
      const [, creative] = Object.entries(res.data);

      return {
        ok: true,
        status: 200,
        response: convertToRestFormat(creative),
      };
    })
    .catch(handleGraphQLError);
}

export const getCreatives = (filters = {}) => getClient()
  .query({
    query: GetCreatives,
    variables: getCreativeQueryVariables(filters),
  })
  .then((res) => {
    const { creatives } = res.data;
    return {
      ok: true,
      status: 200,
      response: creatives.creatives.map(convertToRestFormat),
      pagination: creatives.pagination,
    };
  })
  .catch(handleGraphQLError);

function formatActivity(creative) {
  const {
    status,
    adminStatus,
    isArchived,
    name,
    vungleName,
    language,
    targeting,
    video,
    campaignIDs,
    tags,
    replacements,
    endCardID,
    tpat,
  } = creative;

  const activity = {
    status,
    admin_status: adminStatus,
    archived: isArchived,
    name: vungleName,
    original_name: name,
    language: language?.code,
    enforceLanguage: language?.isEnforced,
    isStreaming: targeting?.isStreaming,
    video: {
      _id: video?.id,
      title: video?.title,
    },
    parentCampaigns: campaignIDs,
    tags,
    templateReplacements: replacements,
    endcard: endCardID,
    device: targeting?.devices,
    tpat: {
      play_percentage: tpat?.playPercentage?.reduce((prev, { urls, checkpoint }) => ({ ...prev, [checkpoint * 100]: urls }), {}),
    },
  };

  return activity;
}

function convertCreativeActivityToRestFormat({
  originalName, templateWhitelist, histories, ...activity
}) {
  const creativeActivity = {
    ...activity,
    original_name: originalName,
    template_whitelist: templateWhitelist,
  };
  creativeActivity.history = histories?.map(({
    updated, previous, modifiedBy, ...rest
  }) => {
    const formattedPrevious = formatActivity(previous);
    const formattedUpdated = formatActivity(updated);

    const diff = getPrettyDiff(formattedPrevious, formattedUpdated, []);

    return {
      ...rest,
      modified_by: modifiedBy,
      previous: diff.previous,
      updated: diff.updated,
    };
  }).filter((item) => (
    Object.keys(item.updated).length > 0
        || Object.keys(item.previous).length > 0
        || item.action === 'create'
  ));

  return creativeActivity;
}

export const getCreativeActivity = (filter = {}) => getClient()
  .query({
    query: GetCreativeActivity,
    variables: filter,
  })
  .then((res) => {
    const { creativeActivity } = res.data;
    return {
      ok: true,
      status: 200,
      response: convertCreativeActivityToRestFormat(creativeActivity.activity),
      pagination: creativeActivity.pagination,
    };
  })
  .catch(handleGraphQLError);

export const getCreativeBiggestVideo = (id) => getClient()
  .query({
    query: GetCreativeBiggestVideo,
    variables: {
      id,
    },
  })
  .then((res) => {
    const { creativeBiggestVideo } = res.data;
    return {
      ok: true,
      status: 200,
      response: { video_url: creativeBiggestVideo.videoUrl },
    };
  })
  .catch(handleGraphQLError);
