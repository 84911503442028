import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '../../Button/Button';

import './dropdownWrapper.scss';

function DropdownWrapper({
  prefixCls,
  className,
  style,
  width,
  height,
  children,
  cancelText,
  onCancel,
  applyText,
  applyDisabled,
  onApply,
  linkText,
  onLink,
  isLinkHidden,
}) {
  const classes = classnames(prefixCls, className);
  return (
    <div className={classes} style={{ width, height, ...style }}>
      <div className={`${prefixCls}-body`}>{children}</div>
      <div className={`${prefixCls}-foot`}>
        {!isLinkHidden && (
          <div className={`${prefixCls}-foot-left`}>
            <span className={`${prefixCls}-foot-link`} onClick={onLink}>{linkText}</span>
          </div>
        )}
        <div className={`${prefixCls}-foot-right`}>
          <Button className={`${prefixCls}-foot-cancel`} size="small" onClick={onCancel}>{cancelText}</Button>
          <Button disabled={applyDisabled} className={`${prefixCls}-foot-apply`} size="small" type="ghost" onClick={onApply}>{applyText}</Button>
        </div>
      </div>
    </div>
  );
}

function noop() { }

DropdownWrapper.propTypes = {
  /**
   * prefix of className
   */
  prefixCls: PropTypes.string,
  /**
   * className of wrapper
   */
  className: PropTypes.string,
  /**
   * style of wrapper
   */
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  /**
   * width of wrapper
   */
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  /**
   * height of wrapper
   */
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  /**
   * cancel text
   */
  cancelText: PropTypes.string,
  /**
   * cancel action
   */
  onCancel: PropTypes.func,
  /**
   * Disable apply button.
  */
  applyDisabled: PropTypes.bool,
  /**
   * apply text
   */
  applyText: PropTypes.string,
  /**
   * apply action
   */
  onApply: PropTypes.func,
  /**
   * link text
   */
  linkText: PropTypes.string,
  /**
   * link action
   */
  onLink: PropTypes.func,
};

DropdownWrapper.defaultProps = {
  prefixCls: 'v2_component_dropdown_box',
  className: '',
  style: {},
  width: 270,
  height: 'auto',
  cancelText: 'Cancel',
  onCancel: noop,
  applyText: 'Apply',
  applyDisabled: false,
  onApply: noop,
  linkText: 'Link',
  onLink: noop,
};

export default DropdownWrapper;
