import React from 'react';
import AssetUploader from '../../assets/list_2/AssetUploader/AssetUploader';

const ReplaceAsset = ({
  asset,
  onClose,
  refreshHistory,
}) => {
  const onAssetUploaderClose = (hasUploadedAnyAsset = false) => {
    if (hasUploadedAnyAsset) refreshHistory();
    onClose();
  };

  return (
    <AssetUploader
      mode="replacer"
      multiple={false}
      asset={asset}
      acceptFileTypes="application/zip, application/x-zip-compressed"
      onClose={onAssetUploaderClose}
    />
  );
};

export default ReplaceAsset;
