import { filter } from 'lodash';
import { chunk } from '../../../lib/lib';
import Application from '../../../models/Application';
import { KEY_NAME, KEY_ID } from './constants';
import Account from '../../../models/Account';

const PER_PAGE = 100;

export async function fetchPasteData(ids, api) {
  const allRequests = [];
  const chunks = chunk(ids, PER_PAGE);
  chunks.forEach((chunkedIds) => {
    allRequests.push(api(chunkedIds, PER_PAGE));
  });
  const data = await Promise.all(allRequests);
  return data.map((result) => result.response).reduce((a, p) => [...a, ...p], []);
}

export async function fetchAccountPasteData(ids) {
  const data = await fetchPasteData(ids, (idIn, perPage) => Account.getAll({
    accountType: 'publisher', fields: ['id', 'name'], idIn, perPage,
  }));
  return data;
}

export async function fetchPublisherPasteData(ids) {
  const data = await fetchPasteData(ids, (idIn, perPage) => Application.getAllPubs({
    fields: ['id', 'name', 'parentAccount'], idIn, perPage,
  }));
  return data;
}

export const filterAppsData = (data, keyword) => {
  if (!data) return [];
  if (!keyword) return data;
  const key = keyword.toUpperCase();
  return filter(data, (app) => app[KEY_ID].toUpperCase() === key || app[KEY_NAME].toUpperCase().includes(key));
};

export const getDedupeNewIds = (ids, newIds) => {
  const lowerCaseIds = ids.map((id) => id.toLowerCase());
  return [...new Set(newIds)].filter((id) => !lowerCaseIds.includes(id.toLowerCase()));
};
