import React from 'react';
import { FILTER_KEY_CREATIVE } from 'app/constants/filters';
import RoundPlatformIcon from '../../../RoundPlatformIcon/RoundPlatformIcon';
import './application.scss';

const prefixCls = 'v2_component_filters_node-application';

function Application({ filterKey, node, countable }) {
  const {
    name, platform, thumbnail, childrenCount,
  } = node;
  return (
    <div className={prefixCls}>
      <img className={`${prefixCls}-thumb`} src={thumbnail} />
      <RoundPlatformIcon className={`${prefixCls}-icon`} platform={platform} />
      <div className={`${prefixCls}-info`}>
        <span className={`${prefixCls}-name text-ellipsis`}>{name}</span>
        {
          countable
          && (
          <span className={`${prefixCls}-count`}>
            {filterKey === FILTER_KEY_CREATIVE ? `${childrenCount} Creatives` : `${childrenCount} Campaigns`}
          </span>
          )
        }
      </div>
    </div>
  );
}

export default React.memo(Application);
