import {
  FILTER_KEY_APPLICATION,
  FILTER_KEY_CAMPAIGN,
  FILTER_KEY_CREATIVE,
  FILTER_KEY_PUBLISHER,
  FILTER_KEY_PLATFORM,
  FILTER_KEY_COUNTRY,
  FILTER_KEY_ASSET_TYPE,
  FILTER_KEY_LANGUAGE,
  FILTER_KEY_ASSET,
  FILTER_KEY_CREATIVE_LIST_FORMAT,
  FILTER_KEY_CAMPAIGN_BUDGET_OBJECTIVES,
} from 'app/constants/filters';
import { isEmptyArray } from '../../../../../lib/lib';
import ApplicationOrPublisherPanel from '../../../Filters/Base/Panels/ApplicationOrPublisherPanel';
import AssetTypesPanel from '../../../Filters/Base/Panels/AssetTypesPanel';
import CampaignOrCreativePanel from '../../../Filters/Base/Panels/CampaignOrCreativePanel';
import CountryPanel from '../../../Filters/Base/Panels/CountryPanel';
import PlatformPanel from '../../../Filters/Base/Panels/PlatformPanel';
import CreativeFormatPanel from '../../../Filters/Base/Panels/CreativeFormatPanel';
import LanguagePanel from '../../../Filters/Base/Panels/LanguagePanel';
import AssetPanel from '../../../Filters/Base/Panels/AssetPanel';
import MultiCampaignBudgetObjective from '../../../Filters/MultiCampaignBudgetObjective/MultiCampaignBudgetObjective';

const FILTERS = [
  {
    key: FILTER_KEY_APPLICATION,
    name: 'Application',
    Component: ApplicationOrPublisherPanel,
  },
  {
    key: FILTER_KEY_CAMPAIGN,
    name: 'Campaign',
    Component: CampaignOrCreativePanel,
  },
  {
    key: FILTER_KEY_COUNTRY,
    name: 'Country',
    Component: CountryPanel,
  },
  {
    key: FILTER_KEY_CREATIVE,
    name: 'Creative',
    Component: CampaignOrCreativePanel,
  },
  {
    key: FILTER_KEY_PLATFORM,
    name: 'Platform',
    Component: PlatformPanel,
  },
  {
    key: FILTER_KEY_PUBLISHER,
    name: 'Publisher Application',
    Component: ApplicationOrPublisherPanel,
  },
  {
    key: FILTER_KEY_ASSET_TYPE,
    name: 'Asset Type',
    Component: AssetTypesPanel,
  },
  {
    key: FILTER_KEY_LANGUAGE,
    name: 'Language',
    Component: LanguagePanel,
  },
  {
    key: FILTER_KEY_ASSET,
    name: 'Assets',
    Component: AssetPanel,
  },
  {
    key: FILTER_KEY_CREATIVE_LIST_FORMAT,
    name: 'Creative Format',
    Component: CreativeFormatPanel,
  },
  {
    key: FILTER_KEY_CAMPAIGN_BUDGET_OBJECTIVES,
    name: 'Optimization Type',
    Component: MultiCampaignBudgetObjective,
  },
];

export function getFilter(filterKey) {
  return FILTERS.find(({ key }) => filterKey === key);
}

export function getFilters(filterKeys = []) {
  return FILTERS.filter(({ key }) => filterKeys.indexOf(key) !== -1);
}

export function getFilterLabel(checkedFilter) {
  if (!checkedFilter) {
    return 'Compare: None';
  }

  const { key, data, checkedAll } = checkedFilter;
  const filterLabel = FILTERS.find((item) => item.key === key).name;
  if (data && checkedAll) {
    return `Compare: ${filterLabel} - All`;
  }
  if (data && data.length) {
    return `Compare: ${filterLabel} - ${data.length} Selected`;
  }
  return `Compare: ${filterLabel}`;
}

export function getAvailableNodes(checkedCombine, key) {
  return checkedCombine[key] || [];
}

export function getCheckedNodes(checkedFilter, checkedCombine) {
  if (!checkedFilter) {
    return [];
  }

  const { key, data } = checkedFilter;
  const checkedNodes = checkedCombine[key];
  if (!data || !checkedNodes) {
    return [];
  }

  if (!checkedNodes.length) {
    return data;
  }

  const nodes = checkedNodes.reduce((acc, node) => acc.concat(node.children && !isEmptyArray(node.children) ? node.children : [node]), []);

  return nodes.filter((e) => data.findIndex((node) => e.id === node.id) !== -1);
}
